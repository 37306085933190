<form class="service-rates-miscellaneous-options-container" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2.5vh">
    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.miscellaneous.miscellaneousItemTypes' | translate }}</span>

        <div fxLayout="row" fxFlex="72.5">
            <mat-form-field>
                <mat-chip-list #miscellaneousItemTypesChipList>
                    <mat-chip *ngFor="let control of miscellaneousItemTypes.controls; let i = index" (removed)="miscellaneousItemTypes.removeAt(i)">
                        <app-editable-text-display *ngIf="!isReadonly" [text]="getItemTypeDisplayText(control)" (edit)="updateItemType(miscellaneousItemTypes, i, $event)"></app-editable-text-display>
                        <mat-icon *ngIf="!isReadonly" matChipRemove>cancel</mat-icon>
                        <span *ngIf="isReadonly">
                            {{ getItemTypeDisplayText(control) }}
                        </span>
                    </mat-chip>

                    <input type="text" [readonly]="isReadonly"
                        [placeholder]="translate.instant('flynnBudgetTool.shared.typeAndPressEnter') + '...'"
                        [matChipInputFor]="miscellaneousItemTypesChipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                        (matChipInputTokenEnd)="addItemType(miscellaneousItemTypes, $event, miscellaneousItemTypesChipList)" />
                </mat-chip-list>

                <mat-error *ngIf="miscellaneousItemTypes.errors?.required">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
                </mat-error>

                <mat-error *ngIf="miscellaneousItemTypes.errors?.emptyName">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.emptyText' | translate }}
                </mat-error>

                <mat-error *ngIf="miscellaneousItemTypes.errors?.duplicateName">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.duplicateValue' | translate }}
                </mat-error>

                <mat-error *ngIf="miscellaneousItemTypesChipList.errorState && !miscellaneousItemTypes.errors">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.emptyText' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.miscellaneous.qualityControlFeesPercentageRates' | translate }}</span>

        <div fxLayout="row" fxFlex="72.5">
            <mat-form-field>
                <mat-chip-list #qualityControlFeesPercentageRatesChipList>
                    <mat-chip *ngFor="let control of qualityControlFeesPercentageRates.controls; let i = index" (removed)="qualityControlFeesPercentageRates.removeAt(i)">
                        <span>{{ getQualityControlFeesPercentageRateDisplayText(control) }}</span>
                        <mat-icon *ngIf="!isReadonly" matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <input type="number"
                        [readonly]="isReadonly"
                        [placeholder]="translate.instant('flynnBudgetTool.shared.typeAndPressEnter') + '...'"
                        [matChipInputFor]="qualityControlFeesPercentageRatesChipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                        (matChipInputTokenEnd)="addQualityControlFeesPercentageRate($event)" />
                </mat-chip-list>

                <mat-error *ngIf="qualityControlFeesPercentageRates.errors?.required">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
                </mat-error>

                <mat-error *ngIf="qualityControlFeesPercentageRates.errors?.duplicateRate">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.duplicateValue' | translate }}
                </mat-error>

                <mat-error *ngIf="qualityControlFeesPercentageRates.errors?.negative">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
                </mat-error>

                <mat-error *ngIf="qualityControlFeesPercentageRatesChipList.errorState && !qualityControlFeesPercentageRates.errors">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.miscellaneous.defaultValues' | translate }}</span>

        <div formGroupName="defaultRates" fxLayout="column" fxLayoutGap="1vh" fxFlex="72.5">
            <mat-form-field>
                <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.miscellaneous.miscellaneousLaborHourlyRate' | translate }}</mat-label>
                <input type="number" matInput formControlName="miscellaneousLaborHourlyRate" />

                <mat-error *ngIf="form.get('defaultRates.miscellaneousLaborHourlyRate').errors?.required">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
                </mat-error>

                <mat-error *ngIf="form.get('defaultRates.miscellaneousLaborHourlyRate').errors?.invalidFormat">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.invalidCurrencyFormat' | translate }}
                </mat-error>

                <mat-error *ngIf="form.get('defaultRates.miscellaneousLaborHourlyRate').errors?.negative">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.miscellaneous.qualityControlFeesDollarRate' | translate }}</mat-label>
                <input type="number" matInput formControlName="qualityControlFeesDollarRate" />

                <mat-error *ngIf="form.get('defaultRates.qualityControlFeesDollarRate').errors?.required">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
                </mat-error>

                <mat-error *ngIf="form.get('defaultRates.qualityControlFeesDollarRate').errors?.invalidFormat">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.invalidCurrencyFormat' | translate }}
                </mat-error>

                <mat-error *ngIf="form.get('defaultRates.qualityControlFeesDollarRate').errors?.negative">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</form>
