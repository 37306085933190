<dialog-action-card class="budget-template-versions-list-container"
    [icon]="'filter_1'"
    [title]="translate.instant('admin.budgetTemplateManagement.versionsList.title')"
    [isLoaded]="true"
    (close)="onClose()">

    <div *ngIf="templates.length" class="budget-template-versions-list-content" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-between">
            <span class="name">{{ latestVersion.name }}</span>

            <div *ngIf="latestVersion.assignedCustomers.length"
                class="assigned-customers"
                fxLayout="column"
                fxLayoutAlign="start center"
                fxLayoutGap="5px">

                <span>{{ 'admin.budgetTemplateManagement.versionsList.assignedCustomerTitle' | translate }}</span>

                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1px">
                    <span *ngFor="let customer of latestVersion.assignedCustomers; let i = index">
                        {{ i + 1 }}. {{ customer }}
                    </span>
                </div>
            </div>
        </div>

        <kendo-grid [kendoGridBinding]="templates"
            [pageSize]="10"
            [pageable]="true"
            [scrollable]="true"
            fxFlexAlign="end">

            <kendo-grid-column [title]="translate.instant('admin.budgetTemplateManagement.versionsList.versionHeader')"
                [headerStyle]="{ width: '25%' }"
                [style]="{ width: '25%' }">

                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    {{ dataItem.version.toFixed(1) }} {{ rowIndex ? '' : ('admin.budgetTemplateManagement.versionsList.latestText' | translate) }}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column [title]="translate.instant('admin.budgetTemplateManagement.versionsList.activeStatusHeader')">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-checkbox [(ngModel)]="dataItem.isActive" (change)="onActiveStatusChange(dataItem)"></mat-checkbox>

                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <button mat-icon-button (click)="downloadTemplate(dataItem)">
                                <mat-icon>cloud_download</mat-icon>
                            </button>

                            <button mat-icon-button class="delete-button" (click)="deleteTemplate(dataItem)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</dialog-action-card>
