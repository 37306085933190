<div class="add-follow-up-log-container" fxLayout="column" fxLayoutAlign="space-between stretch" fxFill>
    <mat-form-field class="content">
       <textarea matInput
            cdkFocusInitial
            cdkTextareaAutosize
            [cdkAutosizeMinRows]="quoteEditorMode?'32':'20'"
            [cdkAutosizeMaxRows]="quoteEditorMode?'32':'20'"
            [(ngModel)]="followUpLog.followUpText" required
            #logContent>
        </textarea>

        <mat-error *ngIf="!followUpLog.followUpText">* {{ 'shared.addFollowUpLog.requiredField' | translate }}</mat-error>
    </mat-form-field>

    <div class="action" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <mat-checkbox matInput [(ngModel)]="followUpLog.isFollowUpAttempt">
            {{ 'shared.addFollowUpLog.followUp' | translate }}
        </mat-checkbox>
        <button mat-raised-button color="primary" (click)="add()">{{ 'shared.add' | translate }}</button>
        <button mat-raised-button color="primary" *ngIf="quoteEditorMode" (click)="cancel()">{{ 'shared.cancel' | translate }}</button>
    </div>
</div>
