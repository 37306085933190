import { PipeTransform } from '@angular/core';

import GridItemEditOption from '../grid-item-edit-option';
import { InformationGridItemType } from '../../enums/information-grid-item-type.enum';
import KeyValuePair from '../key-value-pair';

export default class InformationGridItem {
    public title = '';
    public key = '';
    public width = 100;
    public useTextarea = false;
    public isHtml = false;
    public type = InformationGridItemType.TextInput;
    public optionValues : KeyValuePair[] = [];
    public transform: (_: unknown) => string;
    public displayPipe: PipeTransform;
    public editOptions: GridItemEditOption;

    constructor(title: string, key: string, width = 100, useTextarea = false, isHtml = false) {
        this.title = title;
        this.key = key;
        this.width = width;
        this.useTextarea = useTextarea;
        this.isHtml = isHtml;
    }
}
