import SOAddress from './so-address';

export default class CustomerSiteDto {
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    siteAttention: string;
    storeNumber: string;
    sitePhone: string;
    siteFax: string;
    siteEmail: string;
    isBillInfo: boolean;
    customerClass: string;
    geoCode: string;
    shipToId: string;
    clientBuildingId: string;

    constructor(address: SOAddress) {
        this.name = address.name;
        this.address1 = address.address1;
        this.address2 = address.address2;
        this.city = address.city;
        this.state = address.state;
        this.country = address.country;
        this.zip = address.zip;
        this.storeNumber = address.storeNumber;
        this.siteAttention = address.contactName;
        this.siteEmail = address.contactEmail;
        this.sitePhone = address.contactNumber;
        this.geoCode = address.geoCode;
        this.isBillInfo = address.isBillAddress;
        this.siteFax = address.fax;
        this.shipToId = address.shipToId;
    }

}
