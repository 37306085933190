import LocalizedContent from './localized-content';
import TimeInfo from './time-info';
import UserPermissionDto from './dto-models/user-permission-dto';

export default class UserPermission extends UserPermissionDto {
    public names: LocalizedContent[] = [];
    public timeInfo = new TimeInfo();

    public static areSame(a: UserPermission, b: UserPermission): boolean {
        return a.type === b.type && a.actionCode === b.actionCode;
    }
}
