import { Component, Input, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import Branch from '../../../../core/data-models/branch';
import UserProfile from '../../../../core/data-models/user-profile';
import Employee from '../../../../core/data-models/employee';
import ProjectSummary from '../../../../core/data-models/summary-info/project-summary';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import { BranchHttpService } from '../../../../core/services/http/branch-http/branch-http.service';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';
import { SalesCodeConfigService } from '../../../../core/services/sales-code-config/sales-code-config.service';
import { BranchLocalTimePipe } from '../../../pipes/branch-local-time/branch-local-time.pipe';

@Component({
    selector: 'project-summary-card',
    styleUrls: ['./project-summary-card.component.scss'],
    templateUrl: './project-summary-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryCardComponent implements OnInit, OnChanges {
    @Input() public isActive = false;
    @Input() public summary: ProjectSummary;
    @Input() public viewers: UserProfile[] = [];
    @Input() public isSimplified = false;
    public readonly visibleViewers = 6;
    public branches: Branch[] = [];
    public users: { type: string; user: Employee }[] = [];
    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
                private _branchHttpService: BranchHttpService,
                private _userHttpService: UserHttpService,
                private _changeDetector: ChangeDetectorRef,
                private _salesCodeConfigService: SalesCodeConfigService,
                private _featureFlagService: FeatureFlagService,
                private _branchLocalTimePipe: BranchLocalTimePipe) { }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    get jobTypeColor(): string {
        const salesCode = this._salesCodeConfigService.getSalesCode(this.summary.projectId);

        return `var(--project-color-${salesCode})`;
    }

    get createdTime(): string {
        const { createdTime, branch } = this.summary;
        const time = this._branchLocalTimePipe.transform(new Date(createdTime), branch, this.branches);

        return this.translate.instant('shared.summaryList.projectSummaryCard.createdOn', { time });
    }

    get isKpiStarsAvailable(): boolean {
        const isProcessed = this.summary.status === 'Processed';
        const useKpiFeature = this._featureFlagService.featureFlags[FeatureFlags.UseKpiFeature];
        return useKpiFeature && isProcessed && this._salesCodeConfigService.isLeakCall(this.summary.projectId);
    }

    public async ngOnInit(): Promise<void> {
        this.refreshUsers();
        this.branches = await this._branchHttpService.getAllBranches();
        this._changeDetector.markForCheck();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.viewers) {
            this.refreshUsers();
        }
    }

    public trackUser(user: Employee): string {
        return user.preferredName;
    }

    private async refreshUsers(): Promise<void> {
        const { preferredName, foreman } = this.summary;
        const keyPrefix = 'shared.summaryList.projectSummaryCard';
        const user = await this._userHttpService.getUserInfo();
        const viewerType = this.translate.instant(`${keyPrefix}.viewerUserType`);
        const viewers = this.viewers.filter(_ => _.userName !== user.username).map(_ => ({ preferredName: _.preferredName } as Employee));

        const users = [
            { type: this.translate.instant(`${keyPrefix}.creatorUserType`), user: { preferredName } as Employee },
            { type: this.translate.instant(`${keyPrefix}.techUserType`), user: { preferredName: foreman } as Employee },
            ...viewers.map(_ => ({ type: viewerType, user: _ }))
        ];

        this.users = users.filter(_ => _.user.preferredName);
        this._changeDetector.markForCheck();
    }
}
