<div class="dialog-panel-container" fxLayout="column" fxLayoutAlign="start center">
    <div class="title-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="icon" fxLayoutAlign="center center">
            <mat-icon>{{ icon }}</mat-icon>
        </div>

        <span class="title">{{ title }}</span>
        <div fxFlex></div>

        <button *ngIf="!isModalDialog" class="close-button" mat-mini-fab (click)="close.emit()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

    <div class="content">
        <ng-content *ngIf="isLoaded"></ng-content>
    </div>
</div>
