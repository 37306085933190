import Employee from '../../../data-models/employee';

export default class UserUtility {

    public static getFullName(user: Employee): string {
        const { preferredName, givenName, surname } = user;

        if (preferredName?.trim()) {
            return preferredName.trim();
        }

        const first = givenName?.trim() ?? '';
        const last = surname?.trim() ?? '';

        return [first, last].join(' ').trim();
    }

    public static getInitials(name: string): string {
        const initials = (name ?? '').split(' ').map(_ => _ ? _[0] : '');
        const last = initials.length > 1 ? initials.slice(-1)[0] : '';

        return `${initials[0]}${last}`.toUpperCase();
    }
}
