<dialog-action-card
    [icon]="'photo_album'"
    [title]="this.translate.instant('shared.photoReportOptionsDialog.photoReportCreation')"
    [isLoaded]="true"
    (close)="onClose()">
    <div fxFill fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex class="main-photo-report-options-container">
            <div *ngIf="!drawingView" fxLayout="column" fxLayoutGap="15px" fxFill>
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="50">
                        <ng-container *ngIf="isInternal">
                            <mat-checkbox [(ngModel)]="photoReportOptions.includeCondition" *ngIf="isAuditJob" (change)="updateIncludeDrawingFlag()">
                                <span>{{ 'shared.photoReportOptionsDialog.includeConditionReport' | translate }}</span>
                            </mat-checkbox>
                        </ng-container>
                        <mat-checkbox [(ngModel)]="photoReportOptions.includeDrawing" [disabled]="photoReportOptions.includeCondition">
                            <span>{{ 'shared.photoReportOptionsDialog.includeDrawings' | translate }}</span>
                        </mat-checkbox>
                        <div fxLayout="row" *ngIf="data.drawings.length > 1">
                            <button mat-flat-button (click)="drawingView = true" color="primary" [disabled]="photoReportOptions.includeCondition">Select Drawings</button>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50">
                        <div>
                            <mat-form-field>
                                <mat-label>{{ 'shared.photoReportOptionsDialog.date' | translate }}</mat-label>
                                <mat-select [(ngModel)]="selectedDate">
                                    <mat-option value="All">{{includeAllPhotosLabel}}</mat-option>
                                    <mat-option *ngFor="let date of imageDates" [value]="date">{{date}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <mat-checkbox [(ngModel)]="photoReportOptions.showToCustomer">
                            <span>{{ 'shared.photoReportOptionsDialog.dontShowCustomer' | translate }}</span>
                        </mat-checkbox>
                        <mat-checkbox [(ngModel)]="photoReportOptions.removeCompanyLogo">
                            <span>{{ 'shared.photoReportOptionsDialog.removeCompanyLogo' | translate }}</span>
                        </mat-checkbox>
                    </div>
                </div>
                <div fxLayout="row" fxFlex>
                    <div fxLayout="column" *ngIf="data.clientLogo" fxFlex="50">
                        <mat-checkbox [(ngModel)]="photoReportOptions.clientReport">
                            <span>{{ 'shared.photoReportOptionsDialog.clientLogo' | translate }}</span>
                        </mat-checkbox>
                        <div fxLayout="column" fxFlex class="logo-image" [style.background-image]="logoImage">
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" *ngIf="hasSendByEmail">
                        <label>
                            <span>{{ 'shared.photoReportOptionsDialog.sendByEmail' | translate }}</span>
                        </label>
                        <mat-checkbox [(ngModel)]="photoReportOptions.notificationConfig.sendToTech">
                            <span>{{ 'shared.photoReportOptionsDialog.sendToTech' | translate }}</span>
                        </mat-checkbox>
                        <mat-checkbox [(ngModel)]="photoReportOptions.notificationConfig.sendToDispatcher">
                            <span>{{ 'shared.photoReportOptionsDialog.sendToDispatcher' | translate }}</span>
                        </mat-checkbox>
                        <div fxLayout="column" fxFlex class="send-by-email-list-container">
                            <mat-form-field fxFlex="50">
                                <mat-label>{{ 'shared.photoReportOptionsDialog.externalEmails' | translate }}</mat-label>
                                <mat-chip-list #chipList>
                                  <mat-chip *ngFor="let email of photoReportOptions.notificationConfig.externalEmails" (removed)="removeEmail(email)">
                                        {{email}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                  </mat-chip>
                                  <input
                                    #addEmailInput
                                    placeholder="New email..." [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addEmail($event)">
                                </mat-chip-list>
                                <mat-error>{{ 'core.validatorFactory.expectedEmailFormat' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="15px" *ngIf="drawingView" fxFill>
                <div fxLayout="row">
                    <button mat-flat-button (click)="drawingView = false" color="primary">
                        {{ 'shared.photoReportOptionsDialog.backToOptions' | translate }}
                    </button>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" class="select-drawing-hint">
                    {{ 'shared.photoReportOptionsDialog.howToIncludeDrawingInstruction' | translate }}
                </div>
                <div fxLayout="row wrap" fxFlex fxLayoutGap="10px" class="select-drawing-list">
                    <div fxLayout="column" *ngFor="let image of data.drawings" (click)="selectDrawing(image)">
                        <app-photo-card
                        [photo]="image"
                        [config]="drawingCardConfig"
                        fxFlexAlign="stretch"
                        [highlighted]="isSelectedDrawing(image)"></app-photo-card>
                    </div>
                </div>
            </div>
        </div>

        <div class="photo-report-options-actions" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
            <button mat-raised-button (click)="confirmOptions()" color="primary">
                {{ 'shared.confirm' | translate }}
            </button>
        </div>
    </div>
</dialog-action-card>
