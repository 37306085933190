<div [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start space-between">
    <div>
      <mat-icon *ngIf="notification.isSuccess">check</mat-icon>
      <mat-icon *ngIf="!notification.isSuccess">error</mat-icon>
    </div>
    <div>
    <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close"> 
        <span aria-hidden="true">&times;</span> 
      </button>
    <div>
      <div *ngIf="notification.isSuccess" role="alert" class="message"
        [class]="options.messageClass" [attr.aria-label]="message">
        {{notification.attachment.name}} - {{ 'photoReportNotification.successMessage' | translate }}
      </div>
      <div *ngIf="!notification.isSuccess && notification.errorMessage.length <= 0" role="alert" class="message"
        [class]="options.messageClass" [attr.aria-label]="message">
        {{ 'photoReportNotification.failureMessage' | translate }} <br /> {{ notification.projectId }} - {{ notification.projectName }} . {{ 'photoReportNotification.failureAction' | translate }}.
      </div>
      <div *ngIf="!notification.isSuccess && notification.errorMessage.length > 0" role="alert" class="message"
        [class]="options.messageClass" [attr.aria-label]="message">
          {{ notification.projectName }} - {{ notification.projectId }} . {{ 'photoReportNotification.failureMessage' | translate }} <br /> {{ notification.errorMessage }} 
      </div>
    </div>
    <div class="notification-actions-container" fxlayout="row" fxLayoutAlign="space-between center">
      <a *ngIf="notification.isSuccess" class="notification-action" id="downloadBtn" (click)="downloadFile($event)">
        {{ 'photoReportNotification.actions.download' | translate }}
      </a>
      <a (click)="viewReport($event)" id="viewBtn" class="notification-action">
        {{ 'photoReportNotification.actions.view' | translate }}
      </a>
    </div>
</div>
  </div>
</div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
