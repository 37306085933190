import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import LaborSummary from '../../../core/data-models/labor/labor-summary';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LaborHttpService } from '../../../core/services/http/labor-http/labor-http.service';
import LaborEdit from '../../../core/data-models/labor/labor-edit';
import LaborModelEntity from '../../../core/data-models/labor/labor-entity';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import ProjectAttributeFlags from '../../../core/data-models/labor/project-attribute-flags';
import ProjectQuestionsGrid from '../../../core/data-models/project-attributes/project-questions-grid';
import ProjectSummary from '../../../core/data-models/summary-info/project-summary';
import LaborUpdate from '../../../core/data-models/labor/labor-update';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import LaborModel from '../../../core/data-models/labor/labor-model';
import { LaborProjectValidationErrors } from '../../../core/enums/labor-project-validation-errors.enum';
import { LaborUpdateErrorCodes } from '../../../core/enums/labor-update-error-codes.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-labor-edit',
    styleUrls: ['./labor-edit.component.scss'],
    templateUrl: './labor-edit.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LaborEditComponent implements OnInit {
    public loading = true;
    public loadFailed = false;
    public labor: LaborEdit;
    public projectAttributes: ProjectAttributeFlags;
    public autoCompleteList: ProjectSummary[];
    public disableConfirm = false;
    public confirmWarning = false;
    public warningMessage = '';
    public warningMessageClass = '';
    public laborUpdateErrorMsg = '';
    public laborUpdateError = false;
    public autoCompleteSearch = '';
    public updatedLabor: LaborModelEntity;
    private projectList: ProjectSummary[];
    private requireConfirm = false;
    private targetProject: string;

    get hasMileageErrors() : boolean {
        return this.labor && this.labor.mileage < 0;
    }

    get saveDisabled() : boolean {
        return this.hasMileageErrors || this.requireConfirm && !this.confirmWarning;
    }

    public constructor(private _dialog: MatDialogRef<LaborEditComponent>,
        private _laborHttpService: LaborHttpService,
        private _projectHttpService: ProjectHttpService,
        @Inject(MAT_DIALOG_DATA) public laborRecord: LaborSummary,
        public translate: TranslateService
    ) {
        this.labor = new LaborEdit();
        this.updatedLabor = new LaborModelEntity();
        this.updatedLabor.labor = new LaborModel();
    }

    public async ngOnInit() : Promise<void> {
        this._dialog.disableClose = true;
        this._dialog.addPanelClass('labor-edit-panel');
        this.updatedLabor = await this._laborHttpService.getLaborRecord(this.laborRecord);
        if (this.updatedLabor) {
            await this.initializeLaborViewFields();
        }
        else {
            this.loadFailed = true;
        }
        this.loading = false;
    }

    public async saveLabor() : Promise<void> {
        if (this.labor.reason) {
            await this.saveLaborInternal();
        }
    }

    public projectListSearch(): void {
        this.targetProject = '';
        let query = this.autoCompleteSearch;
        if (!query || query.length < 3) {
            this.autoCompleteList = [];
            return;
        }

        query = query.toLowerCase();
        this.autoCompleteList = this.projectList.filter(s => s.name.toLowerCase().includes(query) ||
            s.projectId.toLowerCase().includes(query));
    }

    public async projectListSearchItemChange(event: MatAutocompleteSelectedEvent) : Promise<void> {
        this.targetProject = event.option.value;
        this.autoCompleteSearch = event.option.viewValue;
        if (this.targetProject) {
            const response = await this._projectHttpService.validateLaborChange(this.updatedLabor.labor.projectId,
                this.targetProject, this.laborRecord);

            if (response !== LaborProjectValidationErrors.Ok) {
                if (response === LaborProjectValidationErrors.NotAllowed) {
                    this.warningMessage = this.translate.instant('labour.edit.employeeNotPaidError');
                    this.disableConfirm = true;
                    this.warningMessageClass = 'WarningMessage';
                }
                else if (response === LaborProjectValidationErrors.InvalidStatus) {
                    this.warningMessage = this.translate.instant('labour.edit.changeNotAllowed');
                    this.disableConfirm = true;
                    this.warningMessageClass = 'WarningMessage';
                }
                else {
                    //eslint-disable-next-line
                    const message = response === LaborProjectValidationErrors.NotAllowedTarget ?
                        this.translate.instant('labour.edit.costsNegativelyAffected') :
                        response === LaborProjectValidationErrors.NotAllowedSource ?
                            this.translate.instant('labour.edit.targetProjectNoAttributes') :
                            this.translate.instant('labour.edit.confirmation');

                    this.warningMessage = message;
                    this.disableConfirm = false;
                    this.warningMessageClass = '';
                }
                this.requireConfirm = true;
            }
            else {
                this.resetWarnings();
            }
        }
        else {
            this.resetWarnings();
        }
    }

    public checkInputValueFromList() : void {
        const foundInList = this.autoCompleteList.some(_ => `${_.projectId}: ${_.name}` === this.autoCompleteSearch);
        if (!foundInList) {
            this.targetProject = '';
            this.autoCompleteSearch = '';
        }
    }

    public onClose(): void {
        this._dialog.close(false);
    }

    private checkAttributeArray(attributeArray: ProjectQuestionsGrid[], date: string) : boolean {
        if (!attributeArray || attributeArray.length === 0) {
            return false;
        }

        return attributeArray.some(x => x.effDate <= date);
    }

    //eslint-disable-next-line
    private async saveLaborInternal() : Promise<void> {
        this.updatedLabor.labor.hours = this.labor.hours;
        this.updatedLabor.labor.zoneFlag = this.labor.zone ? 1 : 0 ;
        this.updatedLabor.labor.mileageDistance = this.labor.mileage ;
        this.updatedLabor.labor.roomAndBoardflag = this.labor.roomAndBoard ? 1 : 0;
        this.updatedLabor.labor.initialAllowanceFlag = this.labor.initialAllowance ? 1 : 0;
        this.updatedLabor.labor.returnAllowanceFlag = this.labor.returnAllowance ? 1 : 0;
        this.updatedLabor.labor.mealFlag = this.labor.meal ? 1 : 0;

        const labor: LaborUpdate = {
            laborRecord: this.updatedLabor,
            reason: this.labor.reason,
            targetProject: this.targetProject
        };
        const response = await this._laborHttpService.updateLabor(labor);
        if (response) {
            if (response === LaborUpdateErrorCodes.Ok) {
                this.laborUpdateError = false;
                this._dialog.close(true);
            }
            else if (response === LaborUpdateErrorCodes.NotFound) {
                this.laborUpdateErrorMsg = this.translate.instant('labour.edit.laborRecordNotFound');
            }
            else if (response === LaborUpdateErrorCodes.InvalidLabor) {
                this.laborUpdateErrorMsg = this.translate.instant('labour.edit.laborCantBeChangedAlreadyUploadedTCM');
            }
            else if (response === LaborUpdateErrorCodes.NotAllowed) {
                this.laborUpdateErrorMsg = this.translate.instant('labour.edit.labourCantBeChangedRecordAlreadyExistsOnTarget');
            }
            else if (response === LaborUpdateErrorCodes.FailedToUpdate) {
                this.laborUpdateErrorMsg = this.translate.instant('labour.edit.failedToSave');
            }
        }
        else {
            this.laborUpdateErrorMsg = this.translate.instant('labour.edit.failedToUpdateEmptyResponse');
        }
        this.laborUpdateError = true;
    }

    private resetWarnings() : void {
        this.requireConfirm = false;
        this.disableConfirm = false;
        this.warningMessage = '';
        this.warningMessageClass = '';
    }

    private async initializeLaborViewFields() : Promise<void> {
        this.labor.hours = this.updatedLabor.labor.hours;
        if (this.updatedLabor.type !== 0) {
            return;
        }

        this.labor.zone = Boolean(this.updatedLabor.labor.zoneFlag);
        this.labor.mileage = this.updatedLabor.labor.mileageDistance;
        this.labor.roomAndBoard = Boolean(this.updatedLabor.labor.roomAndBoardflag);
        this.labor.initialAllowance = Boolean(this.updatedLabor.labor.initialAllowanceFlag);
        this.labor.returnAllowance = Boolean(this.updatedLabor.labor.returnAllowanceFlag);
        this.labor.meal = Boolean(this.updatedLabor.labor.mealFlag);

        await this.initializeProjectAttributeFlags();

        this.projectList = await this._projectHttpService.getProjectListForLaborChange(this.laborRecord.recordId);
        this.autoCompleteList = [];
    }

    private async initializeProjectAttributeFlags() : Promise<void> {
        const attributes = await this._projectHttpService.getProjectAttributes(this.updatedLabor.labor.projectId);
        this.projectAttributes = new ProjectAttributeFlags();
        if (!attributes) {
            return;
        }
        this.projectAttributes.mileage =
            this.checkAttributeArray(attributes.mileage, this.updatedLabor.labor.jobDate);
        this.projectAttributes.roomAndBoard =
            this.checkAttributeArray(attributes.roomNBoard, this.updatedLabor.labor.jobDate);
        this.projectAttributes.zone = this.checkAttributeArray(attributes.zonePay, this.updatedLabor.labor.jobDate);
        this.projectAttributes.meal = Boolean(attributes.mealAllowance);
        this.projectAttributes.returnAllowance = Boolean(attributes.intlNRetn);
        this.projectAttributes.initialAllowance= Boolean(attributes.intlNRetn);
    }

}
