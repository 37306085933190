import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import SwapProjectDialogOption from '../../../../../core/data-models/swap-project-dialog-option';
import SwapProjectDialogResult from '../../../../../core/data-models/swap-project-dialog-result';
import CustomerSelectionDto from '../../../../../core/data-models/customer-selection-dto';
import CustomerUtility from '../../../../../core/services/utility/customer-utility/customer-utility.service';
import { GenericUtilityService } from '../../../../../core/services/utility/generic-utility/generic-utility.service';
import { CustomerCreationHttpService } from '../../../../../core/services/http/customer-creation-http/customer-creation-http.service';
import { CustomerIdPlaceholderPipe } from '../../../../../shared/pipes/customer-id-placeholder/customer-id-placeholder.pipe';
import { CustomerManagementComponent } from '../../../../../shared/components/customer-management/customer-management.component';
import { FeatureFlagService } from '../../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../../core/enums/feature-flags.enum';

@Component({
    selector: 'swap-project-dialog',
    styleUrls: ['./swap-project-dialog.component.scss'],
    templateUrl: './swap-project-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SwapProjectDialogComponent implements OnInit {
    public newCustomer: CustomerSelectionDto;
    // eslint-disable-next-line
    constructor(private _customerCreationHttp: CustomerCreationHttpService,
                private _customerIdPlaceholderPipe: CustomerIdPlaceholderPipe,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialog,
                private _dialogRef: MatDialogRef<SwapProjectDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public options: SwapProjectDialogOption,
                public translate: TranslateService,
                private _featureFlagService: FeatureFlagService) { }

    get customerId(): string {
        return this._customerIdPlaceholderPipe.transform(this.newCustomer.customer.customerId);
    }

    get siteId(): string {
        return GenericUtilityService.getPlaceholder(this.newCustomer.site.shipToId);
    }

    get billingSiteId(): string {
        return GenericUtilityService.getPlaceholder(this.newCustomer.billingSite.shipToId);
    }

    public ngOnInit(): void {
        this._dialogRef.disableClose = true;
    }

    public async onCustomerSelect(): Promise<void> {
        const dialog = this._dialog.open(CustomerManagementComponent, { width: '90vw', height: '95vh', maxWidth: '95vw' });
        const selection = await dialog.afterClosed().toPromise<CustomerSelectionDto>();

        if (!selection) {
            return;
        }

        const allowSwappingInactiveSites = this._featureFlagService.featureFlags[FeatureFlags.AllowSwappingInactiveSites];
        const { customer, site } = selection;
        const isConfirmed = CustomerUtility.isConfirmedCustomer(customer.customerId);
        const shouldCheckSite = isConfirmed && !/^new-/i.test(site.shipToId);
        if (!allowSwappingInactiveSites && shouldCheckSite && !await this._customerCreationHttp.isCustomerSiteActive(customer.customerId, site.shipToId)) {
            const helpText = this.translate.instant('projects.swapProjectReasonDialog.selectedSiteInactiveHelpText');
            const ok = this.translate.instant('snackbars.ok');
            this._snackBar.open(helpText, ok);

            return;
        }

        this.newCustomer = selection;
    }

    public onClose(emit = true): void {
        const result = new SwapProjectDialogResult(this.newCustomer);
        this._dialogRef.close(emit ? result : null);
    }
}
