import { Component, OnInit } from '@angular/core';

import UserInfo from '../../../core/data-models/user-info';
import SalesCodeConfig from '../../../core/data-models/app-configs/sales-code-config';
import CodeDescription from '../../../core/data-models/code-description';
import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';
import LanguageUtility from '../../../core/services/utility/language-utility/language-utility.service';

@Component({
    selector: 'task-code-settings',
    styleUrls: ['./task-code-settings.component.scss'],
    templateUrl: './task-code-settings.component.html'
})
export class TaskCodeSettingsComponent implements OnInit {
    public salesCodes: SalesCodeConfig[] = [];
    public taskCodes: CodeDescription[] = [];
    private _user: UserInfo;

    constructor(private _appConfigHttp: AppConfigHttpService, private _userHttpService: UserHttpService) { }

    public async ngOnInit(): Promise<void> {
        const salesCodes = await this._appConfigHttp.getSalesCodeConfig();
        this.salesCodes = salesCodes.filter(_ => !ProjectIdUtility.isEmergency(_.code));
        this.taskCodes = await this._appConfigHttp.getTaskCodes();
        this._user = await this._userHttpService.getUserInfo();
    }

    public getSalesCodeName(config: SalesCodeConfig): string {
        return this._user ? LanguageUtility.getLocalizedContent(this._user.language, config.names) : '';
    }

    public onUpdate(config: SalesCodeConfig, taskCode: string): void {
        this._appConfigHttp.updateTaskCodeMapping(config.code, taskCode);
    }
}
