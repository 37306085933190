<div fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="50">
            <project-us-questions-card
            [usEstimateForm]="config.data.uSEstimateForm"
            [readOnly]="false"
            (result)="onUsProjectQuestionsResult($event)">
            </project-us-questions-card>
        </div>
        <div fxLayout="column" fxFlex="50">
            <project-us-tax-questions-card
            [budgetConfig]="budgetConfig"
            [usTaxQuestions]="config.data.uSProjectTaxQuestions"
            [readOnly]="false"
            (result)="onUsProjectTaxResult($event)">
            </project-us-tax-questions-card>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <button mat-stroked-button (click)="stepClicked.emit('previous')" color="primary">
            {{ "dispatch.usProjectQuestionsStep.previousButton" | translate }}
        </button>
        <button mat-stroked-button (click)="validateAndMoveNext()" color="primary">
            {{ "dispatch.usProjectQuestionsStep.nextButton" | translate }}
        </button>
    </div>
</div>
