import CodeDescription from '../code-description';
import LocalizedCodeDescription from '../localized-code-description';

export default class DynamicsDispatchInfo {
    public branches: string[] = [];
    public salesCode: LocalizedCodeDescription[] = [];
    public taxStatus: string[] = [];
    public contractType: string[] = [];
    public scopesOfWork: string[] = [];
    public gcOrOwner: string[] = [];
    public buildingPermit: string[] = [];
    public systems: string[] = [];
    public retentionMethods: CodeDescription[] = [];
}
