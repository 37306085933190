<div class="user-management-container" fxLayout="column" fxLayoutAlign="space-between center">
    <div class="actions" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2.5vh" fxFlex="12.5">
        <mat-form-field>
            <mat-label>{{ 'admin.users.searchBox.label' | translate }}</mat-label>

            <input type="text"
                matInput
                (input)="userSearch.next($event.target.value)"
                [placeholder]="translate.instant('admin.users.searchBox.placeholder')" />
        </mat-form-field>

        <mat-radio-group [(ngModel)]="status" (change)="resetPage()">
            <mat-radio-button *ngFor="let option of statusOptions" [value]="option.type">
                {{ option.name }}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="users" fxFlex="87.5">
        <kendo-grid [kendoGridBinding]="visibleUsers"
            [pageSize]="10"
            [pageable]="true"
            [sortable]="true"
            [loading]="isLoading"
            [style.height.%]="100">

            <kendo-grid-column field="preferredName" [title]="translate.instant('admin.users.nameHeader')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1vh">
                        <span>{{ dataItem.preferredName }}</span>

                        <mat-icon *ngIf="dataItem.isAdmin"
                            class="role-indicator"
                            [matTooltip]="translate.instant('admin.users.adminLabel')"
                            matTooltipPosition="above">

                            supervisor_account
                        </mat-icon>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="location" [title]="translate.instant('admin.users.locationHeader')"></kendo-grid-column>

            <kendo-grid-column>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1.25vh">
                        <div *ngIf="dataItem.roles"
                            class="additional-information"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            fxLayoutGap="6px">

                            <mat-icon [matTooltip]="translate.instant('admin.users.rolesLabel')" matTooltipPosition="above">
                                admin_panel_settings
                            </mat-icon>

                            <span>{{ dataItem.roles }}</span>
                        </div>

                        <div *ngIf="dataItem.subscriptions"
                            class="additional-information"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            fxLayoutGap="6px">

                            <mat-icon [matTooltip]="translate.instant('admin.users.subscriptionsLabel')" matTooltipPosition="above">
                                forward_to_inbox
                            </mat-icon>

                            <span>{{ dataItem.subscriptions }}</span>
                        </div>

                        <ng-container *ngIf="canEditUsers">
                            <button class="edit-button"
                                mat-mini-fab
                                color="primary"
                                [disabled]="isSaving || dataItem.isDeactivated"
                                [matTooltip]="translate.instant('admin.users.editLabel')"
                                matTooltipPosition="above"
                                (click)="openUserProfile(dataItem.username, true)">

                                <mat-icon>edit</mat-icon>
                            </button>

                            <button *ngIf="!dataItem.isDeactivated"
                                class="activation-toggle"
                                mat-mini-fab
                                color="primary"
                                [disabled]="isSaving"
                                [matTooltip]="translate.instant('admin.users.deactivateLabel')"
                                matTooltipPosition="above"
                                (click)="toggleUserProfileStatus(dataItem)">

                                <mat-icon>account_circle</mat-icon>
                            </button>

                            <button *ngIf="dataItem.isDeactivated"
                                class="activation-toggle"
                                mat-mini-fab
                                color="warn"
                                [disabled]="isSaving"
                                [matTooltip]="translate.instant('admin.users.activateLabel')"
                                matTooltipPosition="above"
                                (click)="toggleUserProfileStatus(dataItem)">

                                <mat-icon>no_accounts</mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>

        <button *ngIf="canEditUsers" class="add-user" mat-mini-fab color="primary" (click)="onUserCreate()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
