import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';

import TemplateFieldMeta from '../../../../core/data-models/template-field-meta';
import { AudioPlaybackService } from '../../../../core/services/audio-playback/audio-playback.service';

@Component({
    selector: 'template-display-item-card',
    styleUrls: ['./template-display-item-card.component.scss'],
    templateUrl: './template-display-item-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateDisplayItemCardComponent {
    @Input() public template: TemplateFieldMeta;

    constructor(private _audioPlaybackService: AudioPlaybackService) { }

    public getTranslatedValue(meta: TemplateFieldMeta): string {
        const { options } = meta.valueAttributes;
        const option = options.find(_ => _.original === meta.value);

        return option?.translated ?? meta.value;
    }

    public getWeatherIcon(weather: string): string {
        switch (weather.toLowerCase()) {
            case 'sunny': return 'sunnyWeatherIcon';
            case 'wind': return 'windWeatherIcon';
            case 'rain': return 'rainWeatherIcon';
            case 'shower': return 'showerWeatherIcon';
            case 'cloudy': return 'cloudyWeatherIcon';
            case 'snow': return 'snowWeatherIcon';
            default: return '';
        }
    }

    public playVoiceNote(url: string): void {
        this._audioPlaybackService.play(url);
    }
}
