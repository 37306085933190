<dialog-action-card class="quote-editor-container"
    [icon]="'attach_money'"
    [title]="title"
    [isLoaded]="quote"
    (close)="onClose(false)">

    <div *ngIf="quote" class="quote-editor-content" fxLayout="row" fxFill>
        <div class="quote-editor-content-main-panel" fxLayout="column" fxFlex="70">
            <div class="quote-editor-name" fxLayout="row" fxLayoutAlign="start center">
                <grid-display-item *ngIf="quoteNameEditOptions"
                    [content]="quoteName"
                    [editOptions]="quoteNameEditOptions"
                    (contentUpdate)="onQuoteNameChange($event)"
                    fxFlex="48">
                </grid-display-item>

                <div fxFlex></div>

                <mat-button-toggle-group [(ngModel)]="currentTab"
                    (change)="recordTabChange()">
                    <mat-button-toggle *ngFor="let tab of tabs"
                        [ngClass]="{ 'highlighted-tab': tab.isWarning, 'disabled-tab': tab.isDisabled }"
                        [value]="tab.name"
                        [disabled]="tab.isDisabled">

                        <span [matTooltip]="tab.isDisabled ? tab.disabledTooltip : ''">{{ tab.displayName }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="quote-editor-area">
                <quote-content-editor *ngIf="currentTab === 'Quote' && showTextEditor"
                    [amount]="quote.estimation.amount"
                    [taxRate]="quote.estimation.taxRate"
                    [templates]="templates"
                    [followUpPlaceholders]="followUpPlaceholders"
                    [(description)]="quote.description"
                    (descriptionChange)="isSaved = false"
                    (templateChange)="quote.spreadsheetData = $event.spreadsheetData">
                </quote-content-editor>

                <quote-editor-notes *ngIf="currentTab === 'Note'"
                    [quoteId]="quote.basicInfo.quoteId"
                    (commentsChange)="onCommentsChange()">
                </quote-editor-notes>

                <quote-followup-logs class="follow-up-logs"
                    *ngIf="currentTab === 'Follow Up Log'"
                    [activeQuoteId]="quote.basicInfo.quoteId"
                    [quoteEditorMode]="true"
                    fxFill>
                </quote-followup-logs>

                <app-quote-attachments *ngIf="currentTab === 'Attachment'"
                    [activeQuoteId]="quote.basicInfo.quoteId"
                    [customerId]="quote.customer.customerId"
                    [pendingAttachments]="pendingQuoteAttachments"
                    (localFilesChanged)="onLocalAttachmentsChanged($event)">
                </app-quote-attachments>

                <app-budget-management-container *ngIf="currentTab === 'Budget' && budgetToolConfigs"
                    [payload]="budgetToolConfigs"
                    (localExcelBudgetUpdate)="onLocalExcelBudgetUpdate($event)"
                    (webBudgetPayloadUpdate)="webBudgetPayload = $event">
                </app-budget-management-container>

                <div *ngIf="currentTab === 'Pdf Preview'" fxFill>
                    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!quotePreviewAvailable" fxFill>
                        <label>{{ 'shared.quote.editor.previewNotAvailable' | translate }}</label>
                    </div>
                    <app-quote-pdf-preview *ngIf="quotePreviewAvailable"
                        [customerSelection]="customerSelection"
                        [quote]="quote"
                        fxFill>
                    </app-quote-pdf-preview>
                </div>
            </div>
        </div>

        <div class="quote-editor-content-side-panel" fxLayout="column" fxFlex="30">
            <div class="quote-side-panel-spinner" *ngIf="!sidePanelForm" fxLayoutAlign="center center">
                <dots-loader></dots-loader>
            </div>

            <quote-information-panel *ngIf="quote"
                [hidden]="!sidePanelForm"
                [quote]="quote"
                [isEditMode]="isEditMode"
                (customerSelect)="onCustomerSelection($event)"
                (formCreated)="sidePanelForm = $event"
                (formChanged)="onFormChanged($event)"
                (branchSalesCodeChanged)="onBranchSalesCodeChange($event)"
                (onThresholdExceed)="thresholdExceedWarning = $event"
                fxFlex="90">
            </quote-information-panel>

            <div class="quote-editor-save-action"
                *ngIf="sidePanelForm"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="15px"
                fxFlex>

                <mat-checkbox [(ngModel)]="quote.basicInfo.noFollowUp" (ngModelChange)="isSaved = false">
                    {{ 'shared.quote.editor.noFollowUp' | translate }}
                </mat-checkbox>

                <button mat-raised-button color="primary" (click)="onSave()">
                    {{ isEditMode ? translate.instant('shared.quote.editor.save') : translate.instant('shared.quote.editor.create') }}
                </button>

                <button *ngIf="showAwardButton" mat-raised-button color="primary" (click)="onAward()">
                    {{ 'shared.quote.editor.award' | translate }}
                </button>

                <button *ngIf="isEditMode" mat-stroked-button color="primary" (click)="onCopy()">
                    {{ 'shared.quote.editor.copy' | translate }}
                </button>

                <div matTooltipPosition="above" [matTooltip]="isSaved ? null : translate.instant('shared.quote.editor.pleaseSave')">
                    <button *ngIf="isEditMode"
                        mat-stroked-button
                        color="primary"
                        [disabled]="!isSaved"
                        (click)="onPrint()">

                        {{ 'shared.quote.editor.print' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</dialog-action-card>
