import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Branch from '../../../core/data-models/branch';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { JobReportHttpService } from '../../../core/services/http/job-report-http/job-report-http.service';
import ReportQuery from '../../../core/data-models/data-report/report-query';
import ServiceOpsRejectionSummary from '../../../core/data-models/data-report/service-ops-rejection-summary';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-service-ops-rejection-report',
    styleUrls: ['./service-ops-rejection-report.component.scss'],
    templateUrl: './service-ops-rejection-report.component.html'
})
export class ServiceOpsRejectionReportComponent implements OnInit {
    @Input() public jobTypes: string[];
    @Input() public branches: Branch[];
    @ViewChild(GridComponent) grid: GridComponent;
    public gridViewData: ServiceOpsRejectionSummary[] = [];
    public loading: boolean;
    public gridPageSize = 15;
    public query: ReportQuery;

    public constructor(private newRelicUtility: NewRelicUtilityService,
        private userHttpService: UserHttpService,
        private jobReportHttpService: JobReportHttpService) {
        this.query = new ReportQuery('', '', '', null, null, '');
        this.allData = this.allData.bind(this);
    }

    public async ngOnInit(): Promise<void> {
        const currentUser = await this.userHttpService.getUserInfo();
        if (currentUser.role !== 'admin') {
            this.query.branch = currentUser.branch;
        }
    }

    public async loadReport(): Promise<void> {
        this.newRelicUtility.addPageAction('ReportType', 'RejectedByServiceOps', 'ReportAccess', this.query);

        this.loading = true;
        this.gridViewData = await this.jobReportHttpService.getServiceOpsRejectedJobSummary(this.query);
        this.gridPageSize = this.grid.pageSize;
        this.grid.pageChange.emit({ skip: 0, take: this.gridPageSize });
        this.loading = false;
    }

    public allData(): ExcelExportData {
        const result: ExcelExportData = {
            data: process(this.gridViewData, { }).data
        };

        return result;
    }

}
