import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import UserInfo from '../../../core/data-models/user-info';
import ProjectHistory from '../../../core/data-models/project-history';
import TimelineEvent from '../../../core/data-models/timeline-event';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';

@Component({
    selector: 'project-history-view',
    templateUrl: './project-history-view.component.html',
    styleUrls: ['./project-history-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProjectHistoryViewComponent implements OnInit {
    @Input() public data: ProjectHistory[] = [];
    public view = 'list';
    public gridPageSize = 15;
    private _events: TimelineEvent[] = null;
    private _user: UserInfo;

    get events(): TimelineEvent[] {
        return this._events;
    }

    constructor(public translate: TranslateService,
                private _userHttpService: UserHttpService,
                private _salesCodeConfigService: SalesCodeConfigService) { }

    public async ngOnInit(): Promise<void> {
        this._user = await this._userHttpService.getUserInfo();

        this._events = this.data.map(_ => {
            return {
                type: this._salesCodeConfigService.getTypeText(_.projectId, this._user.language),
                backgroundColor: this.getColor(_.projectId),
                time: _.createdTime,
                action: `${this.getProjectIdAsLink(_.projectId)}: ${_.name?? 'N/A'}`,
                icon: this._salesCodeConfigService.getJobTypeIcon(_.projectId)
            } as TimelineEvent;
        });
    }

    public getType(projectId: string): string {
        return this._user ? this._salesCodeConfigService.getTypeText(projectId, this._user.language) : '';
    }

    public getColor(projectId: string): string {
        return `var(--project-color-${this._salesCodeConfigService.getSalesCode(projectId)})`;
    }

    private getProjectIdAsLink(projectId: string): string {
        return `<a href='/projects/${projectId}/details' target='_blank'>${projectId}</a>`;
    }
}
