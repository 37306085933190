import { Component, Input, ChangeDetectionStrategy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import TimelineEvent from '../../../../core/data-models/timeline-event';
import TimelineType from '../../../../core/data-models/timeline-type';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'timeline-card',
    styleUrls: ['./timeline-card.component.scss'],
    templateUrl: './timeline-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineCardComponent implements OnInit {
    @Input() public events: TimelineEvent[] = [];
    public filters = new Set<string>();
    public targetPerformer: string = null;
    public isSelectAll = true;
    public hoveredEvent: TimelineEvent | null = null;
    private _types: TimelineType[] = [];

    get filteredEvents(): TimelineEvent[] {
        return this.events.filter(_ => {
            const performer = this.targetPerformer?.toLowerCase();

            if (performer && performer !== _.performer.toLowerCase()) {
                return false;
            }

            if (this.filters.size && !this.filters.has(_.type)) {
                return false;
            }

            return true;
        });
    }

    get types(): TimelineType[] {
        return this._types;
    }

    constructor(private _translate: TranslateService) { }

    public ngOnInit(): void {
        const types = new Set(this.events.map(_ => _.type));
        this._types = Array.from(types).map(_ => {
            const event = this.events.find(e => e.type === _);
            return {
                name: _,
                localizedName: this._translate.instant(`shared.timelineEventCard.labels.${_}`),
                checked: true,
                backgroundColor: event.backgroundColor,
                icon: event.icon
            } as TimelineType;
        });
        this.onTypeChange();
    }

    public onFilterByPerformer(name: string): void {
        this.targetPerformer = this.targetPerformer === name ? null : name;
    }

    public toggleSelectAll(change: MatCheckboxChange): void {
        for (const type of this._types) {
            type.checked = change.checked;
        }

        this.onTypeChange();
    }

    public onTypeChange(): void {
        this.isSelectAll = this._types.every(_ => _.checked);
        this.filters = new Set(this._types.filter(_ => _.checked).map(_ => _.name));
    }
}
