<dialog-action-card
    [icon]="'print'"
    title="{{ 'projects.print.customReport.title' | translate}}"
    [isLoaded]="isLoaded"
    (close)="onClose(false)">
    <div fxLayout="column" fxFill fxLayoutGap="15px" class="main-custom-report-config-container" *ngIf="reportTemplate">
        <mat-checkbox [(ngModel)]="reportPrintConfig.includeProjectPhotos">
            <span>{{ 'projects.print.customReport.includeProjectPhotos' | translate }}</span>
        </mat-checkbox>
        <div fxLayout="row" fxFlex>
            <mat-tab-group fxFill>
                <mat-tab label="{{ 'projects.print.customReport.textPlaceholders' | translate}}">
                    <div fxLayout="row" fxLayoutGap="15px" fxFill>
                        <div fxLayout="column" fxFlex="50" fxLayoutGap="5px">
                            <mat-form-field *ngFor="let textPlaceholder of textPlaceholders">
                                <mat-label>{{textPlaceholder.name}}</mat-label>
                                <input type="text" matInput [(ngModel)]="textPlaceholder.value" required/>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{ 'projects.print.customReport.imagePlaceholders' | translate}}">
                    <div fxLayout="column" fxLayoutGap="15px" fxFill>
                        <div fxLayout="row" fxLayoutAlign="center center" fxFlexOffset="15px">
                            <label>{{ 'projects.print.customReport.imagePlaceholderHelpText' | translate }}</label>
                        </div>
                        <div class="image-cards-main-container">
                            <div fxLayout="row wrap" fxLayoutGap="7px">
                                <app-photo-card *ngFor="let image of imagePlaceholders; trackBy: trackByFunction"
                                    [photo]="image.photo"
                                    [config]="image.photoConfig"
                                    (cardEvent)="doPhotoCardAction($event)">
                                </app-photo-card>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-flat-button color="primary" (click)="onClose(true)">
                {{ 'projects.print.customReport.printButton' | translate}}
            </button>
        </div>
    </div>
    <div *ngIf="!reportTemplate" fxLayout="column" fxFill fxLayoutAlign="center center">
        <label>{{ 'projects.print.customReport.failedToLoadTemplate' | translate }}</label>
    </div>
</dialog-action-card>
