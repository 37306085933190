export default class ConfirmDialogOption {
    public title = '';
    public message = '';
    public htmlMessage = '';
    public warningMessage = '';
    public isWarning = false;
    public confirmText = 'Ok';
    public cancelText = 'Cancel';

    // eslint-disable-next-line max-params
    constructor(title: string, message: string, isWarning = false, confirmText = 'Ok', cancelText = 'Cancel', htmlMessage = '') {
        this.title = title;
        this.message = message;
        this.isWarning = isWarning;
        this.confirmText = confirmText;
        this.cancelText = cancelText;
        this.htmlMessage = htmlMessage;
    }
}
