import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule, StateService } from '@uirouter/angular';

import { SharedModule } from '../../shared/shared.module';
import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import { ReadPermission } from '../../core/enums/read-permission.enum';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';
import { globalResolver } from '../../core/configs/routing/global-resolver';

import { LaborComponent } from './labor.component';
import { LaborEditComponent } from './labor-edit/labor-edit.component';
import { PopupModule } from '@progress/kendo-angular-popup';

const route: Ng2StateDeclaration[] = [{
    name: 'labor',
    url: '/labor',
    component: LaborComponent,
    resolve: [
        {
            token: 'hasAccess',
            deps: [UserPermissionService, StateService, FeatureFlagService],
            resolveFn: async(
                _userPermissionService: UserPermissionService,
                _stateService: StateService,
                _featureFlagService: FeatureFlagService
            ): Promise<void> => {
                const isInternal = _featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
                const permission = ReadPermission.ViewLaborReview;

                if (!isInternal || !await _userPermissionService.hasReadPermission(permission)) {
                    _stateService.transition.abort();
                    await _stateService.go('pageNotFound');
                }
            }
        },
        ...globalResolver
    ]
}];

@NgModule({
    imports: [
        SharedModule,
        UIRouterModule.forChild({ states: route }),
        PopupModule
    ],
    exports: [UIRouterModule, PopupModule],
    declarations: [LaborComponent,
        LaborEditComponent]
})
export class LaborModule { }
