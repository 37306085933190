import { Component, ViewChild, Output, EventEmitter, ElementRef, AfterViewInit, Input, ViewEncapsulation } from '@angular/core';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import Employee from '../../../core/data-models/employee';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { PopupSettings, DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import QuoteFollowUpReminder from '../../../core/data-models/quote-follow-up-reminder';
import ContactNameEmail from '../../../core/data-models/contact-name-email';
import { PopupService, POPUP_CONTAINER } from '@progress/kendo-angular-popup';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'add-follow-up-reminder',
    templateUrl: './add-follow-up-reminder.component.html',
    styleUrls: ['./add-follow-up-reminder.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: POPUP_CONTAINER,
        useFactory: () => {
            return { nativeElement: document.getElementById('kendoDateTime') } as ElementRef;
        }
    },
    PopupService
    ]
})

export class AddFollowUpReminderComponent implements AfterViewInit {
    @ViewChild('kendoDateTime') public KendoDateTime: DateTimePickerComponent;
    @ViewChild('contactTxt', { read: ElementRef }) contactTxt: ElementRef;
    @Input() columnMode = true;
    @Output() result = new EventEmitter<QuoteFollowUpReminder>();
    public autoCompleteList: string[] = [];
    public autoCompleteSearch = '';
    public allEstimators: Employee[];
    public allEstimatorsNames: string[];
    public itemsInChipset: string[] = [];
    public reminderDate = new Date();
    public popupSettings: PopupSettings = { appendTo: 'component' };
    public isCurrentUserInvalid = false;
    public isLoading = true;

    constructor(
        private _translate: TranslateService,
        private _userService: UserHttpService,
        private _snackbar: MatSnackBar
    ) { }

    public async ngAfterViewInit(): Promise<void> {
        const user = await this._userService.getUserInfo();
        this.allEstimators = await this._userService.getEstimators();
        this.allEstimatorsNames = this.allEstimators.map(_ => _.preferredName);

        if (this.allEstimatorsNames.includes(user.name)) {
            this.itemsInChipset = [user.name];
        }
        else {
            this.isCurrentUserInvalid = true;
        }

        if (this.columnMode) {
            this.KendoDateTime.toggle(true);
            this.KendoDateTime.close.subscribe(event => event.preventDefault());
        }

        this.isLoading = false;
    }

    get count(): number {
        return this.itemsInChipset.length;
    }

    public searchList(): void {
        const query = this.autoCompleteSearch?.toLowerCase();
        if (!query || query.length < 3) {
            this.autoCompleteList = [];
            return;
        }
        this.autoCompleteList = this.allEstimatorsNames.filter(s => s.toLowerCase().includes(query));
    }

    public disabledDates(date: Date): boolean {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return date < today;
    }

    public contactListSearchItemChange(event: MatAutocompleteSelectedEvent): void {
        this.autoCompleteSearch = '';
        this.autoCompleteList = [];

        if (this.itemsInChipset.includes(event.option.viewValue)) {
            const message = this._translate.instant('shared.addFollowUpLogReminder.estimatorExistsMessage');
            const okLabel = this._translate.instant('shared.ok');
            this._snackbar.open(message, okLabel);
            return;
        }
        this.itemsInChipset.push(event.option.viewValue);
    }

    public remove(contact: string): void {
        this.itemsInChipset = this.itemsInChipset.filter(_ => _ !== contact);
    }

    public save(): void {
        if (this.itemsInChipset.length === 0) {
            this.contactTxt.nativeElement.focus();
            return;
        }

        const followUpReminder = new QuoteFollowUpReminder();
        followUpReminder.contacts = this.allEstimators
            .filter(_ => this.itemsInChipset.includes(_.preferredName))
            .map(_ => new ContactNameEmail(_.preferredName, _.email));
        followUpReminder.date = this.reminderDate;
        this.result.emit(followUpReminder);
    }
}
