import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

import { HealthScreeningRequirement } from '../../../../core/enums/health-screening-requirement.enum';

@Component({
    selector: 'health-screening-requirement-card',
    styleUrls: ['./health-screening-requirement-card.component.scss'],
    templateUrl: './health-screening-requirement-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HealthScreeningRequirementCardComponent {
    @Input() public requirement = HealthScreeningRequirement.NotAvailable;

    get containerClass(): { [key: string]: boolean } {
        return {
            required: this.isHealthScreeningRequired,
            exempted: this.isHealthScreeningExempted,
            undetermined: this.isHealthScreeningUndetermined
        };
    }

    get isHealthScreeningRequired(): boolean {
        const requirements = [
            HealthScreeningRequirement.RequiredByCustomer,
            HealthScreeningRequirement.RequiredBySite
        ];

        return requirements.includes(this.requirement);
    }

    get isHealthScreeningExempted(): boolean {
        return this.requirement === HealthScreeningRequirement.NotRequired;
    }

    get isHealthScreeningUndetermined(): boolean {
        return !this.isHealthScreeningRequired && !this.isHealthScreeningExempted;
    }
}
