import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import CustomIcon from '../../../../../core/data-models/custom-icon';
import TimelineEvent from '../../../../../core/data-models/timeline-event';
import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';

@Component({
    selector: 'project-timeline-dialog',
    styleUrls: ['./project-timeline-dialog.component.scss'],
    templateUrl: './project-timeline-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectTimelineDialogComponent implements OnInit {
    private _events: TimelineEvent[] = null;

    constructor(private _projectHttp: ProjectHttpService,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialogRef<ProjectTimelineDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private _projectId: string,
                public translate: TranslateService) { }

    get events(): TimelineEvent[] {
        return this._events;
    }

    public async ngOnInit(): Promise<void> {
        this._events = await this._projectHttp.getTimelineEvents(this._projectId);

        if (!this._events.length) {
            const ok = this.translate.instant('snackbars.ok');
            this._snackBar.open(this.translate.instant('projects.timeline.errorMessage'), ok);
            this.onClose();

            return;
        }
        this.setEventsIconAndColor();
    }

    public onClose(): void {
        this._dialog.close();
    }

    private setEventsIconAndColor(): void {
        this._events.forEach(_ => {
            if (_.type === 'kpi') {
                _.icon = new CustomIcon('military_tech', false);
                _.backgroundColor = 'var(--context-colors-warning)';
            }
            else if (_.type === 'quote') {
                _.icon = new CustomIcon('attach_money', false);
                _.backgroundColor = 'var(--context-colors-suggestion)';
            }
            else {
                _.icon = new CustomIcon('priority_high', false);
                _.backgroundColor = 'var(--context-colors-info)';
            }
        });
    }
}
