import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import JsonUtility from '../../utility/json-utility.service';
import ExecutiveSummary from '../../../data-models/executive-summary';
import CodeDescription from '../../../data-models/code-description';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ExecutiveSummaryHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/executivesummary`;

    constructor(private _http: HttpClient, private _translate: TranslateService) { }

    public async createOrUpdateExecutiveSummary(summary: ExecutiveSummary): Promise<boolean> {
        try {
            const endpoint = `${this._api}/createorupdate`;
            return await this._http.post<boolean>(endpoint, summary).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getProjectExecutiveSummary(projectId: string): Promise<CodeDescription<ExecutiveSummary>> {
        try {
            const endpoint = `${this._api}/project/${projectId}`;
            const data = await this._http.get<ExecutiveSummary>(endpoint).toPromise();

            return new CodeDescription<ExecutiveSummary>('200', '', JsonUtility.toCamelCaseKeys(data));
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 400:
                case 401:
                case 403:
                    return new CodeDescription<ExecutiveSummary>(httpError.status.toString(), httpError.error);

                default:
                    return new CodeDescription<ExecutiveSummary>('500', internalErrorMessage);
            }
        }
    }

}
