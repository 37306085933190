import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';

import { ActionProgressState } from '../../../../core/enums/action-progress-state.enum';
import ActionProgressReporterOption from '../../../../core/data-models/action-progress-reporter-option';
import TimeUtility from '../../../../core/services/utility/time-utility/time-utility.service';
import CodeDescription from '../../../../core/data-models/code-description';

@Component({
    selector: 'action-progress-reporter',
    styleUrls: ['./action-progress-reporter.component.scss'],
    templateUrl: './action-progress-reporter.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionProgressReporterComponent implements OnInit {
    @Input() public options: ActionProgressReporterOption;
    @Output() public actionFinished = new EventEmitter<boolean>();
    @Output() public successConfirmed = new EventEmitter();
    @Output() public errorConfirmed = new EventEmitter();
    public states = ActionProgressState;
    private _state = ActionProgressState.Ongoing;

    constructor(private _changeDetectorRef: ChangeDetectorRef) { }

    get state(): ActionProgressState {
        return this._state;
    }

    get showOngoing(): boolean {
        const skipSuccess = this._state === this.states.Success && !this.options.showSuccess;

        return skipSuccess || this._state === this.states.Ongoing;
    }

    get showSuccess(): boolean {
        return this.options.showSuccess && this._state === this.states.Success;
    }

    get showError(): boolean {
        return this._state === this.states.Error;
    }

    public async ngOnInit(): Promise<void> {
        await TimeUtility.wait(this.options.waitTime);
        const result = await this.options.action;

        if (result instanceof CodeDescription) {
            this._state = result.code === '200' ? this.states.Success : this.states.Error;
            this.options.errorText = result.description;
        }
        else {
            this._state = result ? this.states.Success : this.states.Error;
        }
        await TimeUtility.wait(this.options.waitTime);
        this.actionFinished.emit(this._state === this.states.Success);
        this._changeDetectorRef.markForCheck();
    }
}
