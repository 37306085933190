import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorThemeService {

    public loadCommercialTheme(): void {
        const theme = {
            'secondary-colors-lightest': 'rgb(197, 225, 238)',
            'secondary-colors-lighter': 'rgb(152, 201, 228)',
            'secondary-colors-light': 'rgb(58, 165, 219)',
            'secondary-colors-dark': 'rgb(24, 124, 173)',
            'secondary-colors-darker': 'rgb(16, 87, 122)',
            'secondary-colors-darkest': 'rgb(12, 53, 73)',
            'context-colors-info': 'rgb(94, 123, 160)'
        };

        for (const key of Object.keys(theme)) {
            document.documentElement.style.setProperty(`--${key}`, theme[key]);
        }
    }
}
