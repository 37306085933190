import { ProjectBudget } from '../budget-estimations/project-budget';
import { ServiceOperationsBudgetData } from '../budget-estimations/service-operations-standardized-budget-estimation/service-operations-budget-data';
import { QuoteBudget } from '../budget-estimations/quote-budget';

export default class BudgetResultDto<T extends ProjectBudget<ServiceOperationsBudgetData> | QuoteBudget<ServiceOperationsBudgetData>> {
    public isError = false;
    public budget?: T;

    constructor(isError: boolean, budget: T) {
        this.isError = isError;
        this.budget = budget;
    }
}
