import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import ConditionReportOptions from '../../../../core/data-models/print/condition-report-options';
import { GenericUtilityService } from '../../../../core/services/utility/generic-utility/generic-utility.service';
import ProjectIdUtility from '../../../../core/services/utility/project-id-utility/project-id-utility.service';

@Component({
    selector: 'app-condition-report-options-dialog',
    styleUrls: ['./condition-report-options-dialog.component.scss'],
    templateUrl: './condition-report-options-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ConditionReportOptionsDialogComponent implements OnInit {
    public includePlan = false;
    public clientLogoUrl: SafeStyle;
    public customLogoUrl: SafeStyle;
    public budgetYears: string[] = [];
    public showMaintenanceTab = false;

    get useCustomLogo(): boolean {
        return Boolean(this.customLogoUrl);
    }

    get useClientLogo(): boolean {
        return Boolean(this.clientLogoUrl) && !this.useCustomLogo;
    }

    constructor(public translate: TranslateService,
                private _dialogRef: MatDialogRef<ConditionReportOptionsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ConditionReportOptions,
                private _domSanitizer: DomSanitizer) { }

    public ngOnInit(): void {
        this.showMaintenanceTab = !ProjectIdUtility.isEmergencyQuote(this.data.projectId);

        if (this.data.clientLogo) {
            this.clientLogoUrl = this._domSanitizer.bypassSecurityTrustStyle(`url('${this.data.clientLogo}')`);
        }

        if (this.showMaintenanceTab) {
            this.initBudgetYears();
        }
    }

    public onClose(emit: boolean): void {
        if (!this.includePlan) {
            this.data.sections = [];
        }

        if (this.data.logoFile) {
            this.data.clientId = '';
            this.data.clientLogo = '';
        }

        this._dialogRef.close(emit? this.data : null);
    }

    public async logoFileChanged(event: Event): Promise<void> {
        const inputElement = event.target as HTMLInputElement;
        this.data.logoFile = await GenericUtilityService.blobToBase64(inputElement.files[0]);
        this.customLogoUrl = this._domSanitizer.bypassSecurityTrustStyle(`url('${URL.createObjectURL(inputElement.files[0])}')`);
    }

    private initBudgetYears(): void {
        const budgetLabel = this.translate.instant('shared.conditionReportOptionsDialog.budget');
        const jobYear = new Date(this.data.sections[0].jobDate).getFullYear();
        this.budgetYears = [0, 1, 2, 3, 4].map(_ => `${_ + jobYear} ${budgetLabel}`);
    }
}
