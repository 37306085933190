import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import PhotoReportOptions from '../../../data-models/photo-report-options';
import ProjectAttachment from '../../../data-models/project-attachment';
import FileListRecord from '../../../data-models/file-list';
import JsonUtility from '../../utility/json-utility.service';
import ConditionReportOptions from '../../../data-models/print/condition-report-options';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReportHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/report`;

    constructor(private _http: HttpClient) { }

    public async isConditionReportExist(projectId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/content/pdf/exists/${projectId}`;

            return await this._http.get<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getPhotoReport(reportOptions: PhotoReportOptions): Promise<{ message: string, data: { fileName: string, blob: Blob } }> {
        try {
            const endpoint = `${this._api}/image/photoreport`;
            const response = await this._http.post(endpoint, reportOptions, { observe: 'response', responseType: 'arraybuffer' }).toPromise();

            if (response.status === 202) {
                return {
                    message: '202',
                    data: null
                };
            }

            const regex = /filename="(?<name>.+)"/;
            const fileName = response.headers.get('content-disposition').match(regex).groups.name;
            const blob = new Blob([response.body], { type: 'application/pdf' });

            return {
                message: '',
                data: { fileName, blob }
            };
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const errorMessage = await new Blob([httpError.error], { type: 'text/plain' }).text();

            return {
                message: errorMessage,
                data: null
            };
        }
    }

    public async photoReportLists(projectId: string): Promise<FileListRecord[]> {
        try {
            const endpoint = `${this._api}/list/photoreport/${projectId}`;
            const data = await this._http.get<FileListRecord[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<FileListRecord>();
        }
    }

    public async changeAttachmentViewableStatus(attachment: ProjectAttachment): Promise<boolean> {
        try {
            const { projectId, status } = attachment;
            const name = encodeURIComponent(attachment.name);
            const endpoint = `${this._api}/attachment/status/${projectId}?filename=${name}&status=${status}`;
            await this._http.put(endpoint, null).toPromise();
            return true;
        }
        catch {
            return false;
        }
    }

    public async changeVisibleToCustomerFlag(attachment: ProjectAttachment): Promise<boolean> {
        try {
            const headers = new HttpHeaders().set('Content-Type', 'application/json');
            const { projectId, visibleToCustomer } = attachment;
            const name = encodeURIComponent(attachment.name);

            const endpoint = `${this._api}/${projectId}/attachments/${name}/visibleToCustomer`;
            await this._http.post(endpoint, visibleToCustomer, { headers }).toPromise();
            return true;
        }
        catch {
            return false;
        }
    }

    public async deleteProjectAttachment(projectId: string, filename: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/attachment/${projectId}?filename=${encodeURIComponent(filename)}`;
            await this._http.delete(endpoint).toPromise();
            return true;
        }
        catch {
            return false;
        }
    }

    public async restoreProjectAttachment(projectId: string, fileName: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/attachment/${projectId}?filename=${encodeURIComponent(fileName)}`;
            await this._http.put(endpoint, null).toPromise();
            return true;
        }
        catch {
            return false;
        }
    }

    public async getConditionReportPdf(printConfig: ConditionReportOptions): Promise<Blob> {
        try {
            const endpoint = `${this._api}/content/pdf/`;
            const bufferData = await this._http.post(endpoint, printConfig, { responseType: 'arraybuffer' }).toPromise();

            return new Blob([bufferData], { type: 'application/pdf' });
        }
        catch {
            return null;
        }
    }

    public async getConditionReportInitialOptions(projectId: string): Promise<ConditionReportOptions> {
        try {
            const endpoint = `${this._api}/${projectId}/condition-report/initial/options`;
            const data = await this._http.get<ConditionReportOptions>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getWorkOrderPdf(projectId: string): Promise<Blob> {
        try {
            const endpoint = `${this._api}/work/${projectId}`;
            const bufferData = await this._http.get(endpoint, { responseType: 'arraybuffer' }).toPromise();

            return new Blob([bufferData], { type: 'application/pdf' });
        }
        catch {
            return null;
        }
    }

    public async getSafetyReport(projectId: string, jobId: number): Promise<string> {
        try {
            const endpoint = `${this._api}/safety/web/${projectId}/${jobId}`;
            const data = await this._http.get<{ ContentHtml: string }>(endpoint).toPromise();

            return data.ContentHtml;
        }
        catch {
            return '';
        }
    }

    public async getFieldReportContent(projectId: string, jobId: number, type: string): Promise<string> {
        try {
            const endpoint = `${this._api}/${projectId}/${jobId}/field-report/${type}`;
            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return '';
        }
    }

    public async getCovid19Content(projectId: string, jobId: number): Promise<string> {
        try {
            const endpoint = `${this._api}/covid/${projectId}/${jobId}`;
            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return '';
        }
    }
}
