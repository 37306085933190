import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StateService, TransitionService } from '@uirouter/core';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';

import { SignalrHubService } from './core/services/signalr-hub/signalr-hub.service';
import { IconRegistryService } from './core/services/icon-registry/icon-registry.service';
import { NewRelicUtilityService } from './core/services/utility/new-relic-utility.service';
import { setupService } from './core/configs/service-setup';
import { setupSite } from './core/configs/site-setup';
import { registerHooks } from './core/configs/routing/hooks';
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLockService } from './core/services/auth-lock-service/auth-lock-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

export function TranslateHttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FlexLayoutModule,
        AppRoutingModule,
        CoreModule,
        FeaturesModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateHttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AuthModule.forRoot({
            authorizationParams:
            {
                audience: environment.auth0ApiIdentifier
            },
            domain: environment.auth0Domain,
            clientId: environment.auth0ClientID,
            httpInterceptor: {
                allowedList: [`${environment.fswApiUrl}*`]
            }
        }),
        BrowserAnimationsModule,
        ToastrModule.forRoot()
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    declarations: [AppComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: setupService,
            deps: [SignalrHubService, TranslateService, AuthLockService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: setupSite,
            deps: [Title, IconRegistryService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: registerHooks,
            deps: [TransitionService, StateService, NewRelicUtilityService, AuthLockService],
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
