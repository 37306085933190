<div fxLayout="column" fxFill>
    <div fxLayout="column" fxFlex="15">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" fxFlex="50">
            <mat-form-field>
                <mat-label>{{ 'dataReport.customerReport.searchCustomer' | translate }}</mat-label>
                <input matInput [(ngModel)]="search.query">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'shared.branch' | translate }}</mat-label>
                <mat-select [(ngModel)]="search.branch">
                    <mat-option value=''>{{ 'shared.all' | translate }}</mat-option>
                    <mat-option *ngFor="let branch of branches" [value]="branch.code">{{branch.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'shared.startDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" (click)="startDate.open()" readonly [(ngModel)]="search.startDate">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'shared.endDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" (click)="endDate.open()" readonly [(ngModel)]="search.endDate">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" fxFlex="50">
            <mat-form-field>
                <mat-label>{{ 'dataReport.customerReport.searchAddress' | translate }}</mat-label>
                <input matInput [(ngModel)]="search.address">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'dataReport.customerReport.searchSiteId' | translate }}</mat-label>
                <input matInput [(ngModel)]="search.siteId">
            </mat-form-field>
            <div>
                <button mat-raised-button color="primary" (click)="loadReport()">{{ 'dataReport.customerReport.searchCustomerQuotes' | translate }}</button>
            </div>
        </div>
    </div>
    <div fxLayout="row" class="report-grid-div" fxFlex="85">
        <kendo-grid [kendoGridBinding]="gridViewData" [pageSize]="15" [pageable]="true"
        [groupable]="false" sortable="true" [group]="groups">
            <ng-template kendoGridToolbarTemplate>
                <button kendoGridExcelCommand [disabled]="!gridViewData.length">{{ 'shared.exportExcel' | translate }}</button>
            </ng-template>
            <kendo-grid-column field="quoteId" title="{{ 'shared.quoteId' | translate }}">
                <ng-template kendoGridGroupHeaderTemplate let-group="group">
                    <span>
                        {{ 'shared.branch' | translate }}: {{ group.value }}
                    </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quoteName" title="{{ 'shared.quoteName' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="custName" title="{{ 'dataReport.customerReport.customerColumn' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="address" title="{{ 'shared.address' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="siteId" title="{{ 'shared.siteId' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="quoteBranch" title="{{ 'shared.branch' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="quoteTotal" title="{{ 'shared.quoteTotal' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.quoteTotal | currency }}
                </ng-template>
                <ng-template kendoGridGroupFooterTemplate let-group="group" let-aggregates>
                    <span>
                        {{group.value}} {{ 'shared.total' | translate }}: {{aggregates.quoteTotal.sum | currency }}
                    </span>
                </ng-template>
                <ng-template kendoGridFooterTemplate  let-column>
                    {{ 'shared.grandTotal' | translate }}: {{total?.quoteTotal?.sum | currency }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quoteCreationDate" title="{{ 'shared.creationDate' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.quoteCreationDate | branchLocalTime: dataItem.quoteBranch : branches : false}}
                 </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quoteStatus" title="{{ 'shared.status' | translate }}">
            </kendo-grid-column>
            <kendo-grid-excel fileName="customerQuotesData.xlsx" [fetchData]="allData" filterable="true">
            </kendo-grid-excel>
        </kendo-grid>
    </div>
    </div>
