import { LaborExpense } from '../expenses/labor-expense';

export class ServiceOperationsLaborSection {
    public branchLaborCost: LaborExpense;
    public miscellaneousLaborCost: LaborExpense;
    public totalCost: number;

    constructor(branchLaborCost = new LaborExpense(), miscellaneousLaborCost = new LaborExpense(), totalCost = 0) {
        this.branchLaborCost = branchLaborCost;
        this.miscellaneousLaborCost = miscellaneousLaborCost;
        this.totalCost = totalCost;
    }
}
