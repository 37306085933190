/* eslint-disable max-lines */
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Validators, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { BudgetRecordPayload } from '../../../../flynn-budget-tool/core/data-models/budget-estimations/budget-record-payload';
import { ServiceOperationsBudgetData } from '../../../../flynn-budget-tool/core/data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-budget-data';
import QuoteTemplate from '../../../core/data-models/quote-template';
import NavigationTab from '../../../core/data-models/navigation-tab';
import AttachmentModel from '../../../core/data-models/attachment-model';
import QuoteGeneralInfo from '../../../core/data-models/quote-general-info/quote-general-info';
import ValidatorSet from '../../../core/data-models/validator-set';
import BudgetToolFeatureDto from '../../../core/data-models/budget-tool-feature-dto';
import CustomerSelectionDto from '../../../core/data-models/customer-selection-dto';
import GridItemEditOption from '../../../core/data-models/grid-item-edit-option';
import QuoteEditorOption from '../../../core/data-models/quote-editor-option';
import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import QuoteSaveContext from '../../../core/data-models/quote-save-context';
import CodeDescription from '../../../core/data-models/code-description';
import { QuoteEditorMode } from '../../../core/enums/quote-editor-mode.enum';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import { BranchHttpService } from '../../../core/services/http/branch-http/branch-http.service';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';
import { UserPermissionService } from '../../../core/services/user-permission/user-permission.service';
import { ActionProgressDialogComponent } from '../dialogs/action-progress-dialog/action-progress-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog-component/confirm-dialog.component';
import { DispatchProjectDialogComponent } from '../../../features/dispatch/dispatch-project-dialog/dispatch-project-dialog.component';
import DispatchDialogOptions from '../../../core/data-models/dispatch/dispatch-dialog-options';
import { ReadPermission } from '../../../core/enums/read-permission.enum';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';
import { QuoteReportOptionsDialogComponent } from '../dialogs/quote-report-options-dialog/quote-report-options-dialog.component';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';
import FollowUpQuotePlaceholderContainer from '../../../core/data-models/quote-general-info/follow-up-quote-placeholder-container';
import { QuoteCopyOptionsDialogComponent } from '../dialogs/quote-copy-options-dialog/quote-copy-options-dialog.component';
import CustomerInfo from '../../../core/data-models/project-general-info/customer-info';
import QuoteCopyOptions from '../../../core/data-models/quote-options/quote-copy-options';
import PendingQuoteAttachments from '../../../core/data-models/quote-options/pending-quote-attachments';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import BudgetManagementPayload from '../../../core/data-models/budget-management-payload';
import BranchSalesCodeChange from '../../../core/data-models/quote-general-info/branch-sales-code-change';
import { FileType } from '../../../core/enums/file-type';

@Component({
    selector: 'quote-editor',
    styleUrls: ['./quote-editor.component.scss'],
    templateUrl: './quote-editor.component.html',
    encapsulation: ViewEncapsulation.None
})
export class QuoteEditorComponent implements OnInit {
    public tabs: NavigationTab[] = [];
    public budgetTab: NavigationTab;
    public currentTab: string;
    public parentQuoteId = '';
    public quote: QuoteGeneralInfo = null;
    public customerSelection: CustomerSelectionDto;
    public templates: QuoteTemplate[] = [];
    public pendingQuoteAttachments = new PendingQuoteAttachments();
    public quoteNameEditOptions: GridItemEditOption = null;
    public sidePanelForm: UntypedFormGroup;
    public showTextEditor = false;
    public isSaved = true;
    public thresholdExceedWarning: string;
    public followUpPlaceholders: FollowUpQuotePlaceholderContainer;
    public budgetToolConfigs: BudgetManagementPayload;
    public webBudgetPayload: BudgetRecordPayload<ServiceOperationsBudgetData> | null = null;
    private _hasSavedOnce = false;
    private _unmodifiedQuote: QuoteGeneralInfo;
    private _title = '';
    private pdfPreviewSubject: Subject<string> = new Subject<string>();

    // eslint-disable-next-line max-params
    constructor(private _branchHttp: BranchHttpService,
                private _quoteHttp: QuoteHttpService,
                private _downloadHttp: DownloadHttpService,
                private _permissionService: UserPermissionService,
                private _dialog: MatDialog,
                private _dialogRef: MatDialogRef<QuoteEditorComponent>,
                private _snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) private _options: QuoteEditorOption,
                public translate: TranslateService,
                private _newrelicService: NewRelicUtilityService,
                private _salesCodeConfigService: SalesCodeConfigService,
                private _featureFlagService: FeatureFlagService) { }

    get isEditMode(): boolean {
        return this._options.mode === QuoteEditorMode.Edit;
    }

    get showAwardButton(): boolean {
        if (!this.isEditMode || !this.quote) {
            return false;
        }

        const validStatuses = ['sent to customer', 'sent to flynn customer service'];

        return validStatuses.includes(this.quote.basicInfo.status.toLowerCase());
    }

    get title(): string {
        return this._title;
    }

    get quoteName(): string {
        const name = (this.quote?.basicInfo?.name ?? '').trim();
        return name ? name : this.translate.instant('shared.quote.editor.addQuoteName');
    }

    get quotePreviewAvailable(): boolean {
        return this.isValidQuote(this.quote) &&
            (Boolean(this.customerSelection) ||
                Boolean(this.quote?.customer));
    }

    public async ngOnInit(): Promise<void> {
        const budgetTabDisabledTooltip = this.translate.instant('shared.quote.editor.budgetDisabledTooltip');
        this.budgetTab = new NavigationTab('Budget', null, null, null, this.translate.instant('shared.quote.editor.budget'), !this.isEditMode, budgetTabDisabledTooltip);

        this.tabs = [
            new NavigationTab('Quote', null, null, null, this.translate.instant('shared.quote.editor.quote')),
            this.budgetTab,
            new NavigationTab('Attachment', null, null, null, this.translate.instant('shared.quote.editor.attachment')),
            new NavigationTab('Pdf Preview', null, null, null, this.translate.instant('shared.quote.editor.pdfPreview'))
        ];

        this.currentTab = this.tabs[0].name;
        this.quote = null;
        this.customerSelection = null;
        this.showTextEditor = false;
        this._dialogRef.disableClose = true;
        this._dialogRef.addPanelClass('quote-editor-dialog');

        if (this.isEditMode) {
            await this.setupEditMode();
        }
        else if (this._options.mode === QuoteEditorMode.Copy) {
            await this.setupCopyMode();
        }
        else {
            await this.setupCreateMode();
        }

        this.quoteNameEditOptions = null;
        setTimeout(() => {
            this.showTextEditor = true;
            this.setQuoteNameEditOptions();
        });

        this.templates = (await this._quoteHttp.getQuoteTemplates() ?? []).sort((a, b) => a.title.localeCompare(b.title));
        this._unmodifiedQuote = JSON.parse(JSON.stringify(this.quote));
        this.pdfPreviewSubject.pipe(
            filter(_ => _ === 'Pdf Preview'),
            debounceTime(500)
        ).subscribe(_ => this.refreshPdfPreview());
    }

    private async setupEditMode(): Promise<void> {
        const note = this.translate.instant('shared.quote.editor.note');
        const followUpLog = this.translate.instant('shared.quote.editor.followUpLog');
        const notesTab = new NavigationTab('Note', null, null, null, note);
        const followUpTab = new NavigationTab('Follow Up Log', null, null, null, followUpLog);
        const showComments = await this._permissionService.hasReadPermission(ReadPermission.ReadComments);
        this._title = this.translate.instant('shared.quote.editor.editQuote');

        if (showComments && this.tabs.every(_ => _.name !== notesTab.name)) {
            const { comments } = await this._quoteHttp.getQuoteCounters(this._options.quoteId, 'comments');
            this.tabs = [this.tabs[0], { ...notesTab, isWarning: comments > 0 }, ...this.tabs.slice(1)];
        }

        if (this.tabs.every(_ => _.name !== followUpTab.name)) {
            this.tabs.push(followUpTab);
        }

        this.quote = await this._quoteHttp.getQuoteGeneralInfo(this._options.quoteId);
        await this.setBudgetPayload();
    }

    private async setupCopyMode(): Promise<void> {
        const { quote, customerSelection, pendingQuoteAttachments } = this._options.saveContext;
        this._title = this.translate.instant('shared.quote.editor.copyQuote');
        this.quote = quote;
        this.customerSelection = customerSelection;
        this.parentQuoteId = this._options.saveContext.parentQuoteId;
        this.pendingQuoteAttachments = pendingQuoteAttachments;
        await this.updateBudgetTabStatus();
    }

    private async setupCreateMode(): Promise<void> {
        this.quote = new QuoteGeneralInfo();
        this._title = this.translate.instant('shared.quote.editor.createQuote');

        if (!this._options.originProject) {
            return;
        }

        const { basicInfo, customer, reportingContact } = this._options.originProject;
        this.quote.basicInfo.originProjectId = basicInfo.projectId;
        this.quote.basicInfo.branch = basicInfo.branch;
        this.quote.reportingContact = reportingContact;
        this.quote.customer = customer;

        if (this._salesCodeConfigService.isLeakCall(basicInfo.projectId)) {
            this.followUpPlaceholders = await this._quoteHttp.getFollowUpPlaceholdersByProjectId(basicInfo.projectId);
        }
    }

    private setQuoteNameEditOptions(): void {
        const name = (this.quote?.basicInfo?.name ?? '').trim();
        const requiredField = this.translate.instant('forms.errors.requiredField');
        const validatorSets = [new ValidatorSet(Validators.required, requiredField)];
        const placeholder = this.translate.instant('shared.quote.editor.enterQuotePlaceholder');
        this.quoteNameEditOptions = new GridItemEditOption(name, 60, false, validatorSets, placeholder);
    }

    public onQuoteNameChange(change: string): void {
        this.quote.basicInfo.name = change;
        this.isSaved = false;
        this.pdfPreviewSubject.next(this.currentTab);
    }

    public async onFormChanged(form: UntypedFormGroup): Promise<void> {
        this.sidePanelForm = form;
        this.isSaved = false;
        this.pdfPreviewSubject.next(this.currentTab);
        await this.updateBudgetTabStatus();
    }

    public onLocalExcelBudgetUpdate(budget: [AttachmentModel, File] | null): void {
        // only one budget excel file with file type 'flynn/budgetfile' allowed
        for (const [localFile] of this.pendingQuoteAttachments.localFiles) {
            if (localFile.type === FileType.Budget) {
                this.pendingQuoteAttachments.localFiles.delete(localFile);

                break;
            }
        }

        if (budget) {
            const [attachment, file] = budget;
            this.pendingQuoteAttachments.localFiles.set(attachment, file);
        }

        this.isSaved = false;
    }

    public onLocalAttachmentsChanged(files: Map<AttachmentModel, File>): void {
        this.pendingQuoteAttachments.localFiles = files;
        this.isSaved = false;
    }

    public async onCommentsChange(): Promise<void> {
        const tab = this.tabs.find(_ => _.name === 'Note');

        if (tab) {
            const { quoteId } = this.quote.basicInfo;
            const { comments } = await this._quoteHttp.getQuoteCounters(quoteId, 'comments');
            tab.isWarning = comments > 0;
        }
    }

    public async onCopy(): Promise<void> {
        const discardMessage = this.translate.instant('shared.quote.editor.discardAndCopy');
        if (!this.isSaved && !await this.alertUnsavedChanges(discardMessage)) {
            return;
        }

        const quote = this.isSaved ? this.quote : this._unmodifiedQuote;
        const selection = this.isSaved ? this.customerSelection : null;
        const context = new QuoteSaveContext(this.copyQuote(quote), selection, this.pendingQuoteAttachments);
        context.quote.followUpLogs = [];
        const dialog = this._dialog.open(QuoteCopyOptionsDialogComponent, {
            data: { quoteId: this.quote.basicInfo.quoteId },
            maxWidth: '40vw',
            width: '40vw',
            height: '25vh'
        });
        const quoteCopyOptions = await dialog.afterClosed().toPromise() as QuoteCopyOptions;
        if (!quoteCopyOptions) {
            return;
        }

        if (!quoteCopyOptions.useParentsCustomer) {
            context.customerSelection = null;
            context.quote.customer = new CustomerInfo();
        }

        if (quoteCopyOptions.useParentsAttachments) {
            context.parentQuoteId = this.quote.basicInfo.quoteId;
            let parentAttachments = await this._quoteHttp.getQuoteAttachments(context.parentQuoteId);
            parentAttachments = parentAttachments?.filter(_ => _.type !== 'flynn/budgetfile') ?? [];
            parentAttachments.forEach(_ => {
                _.isCopy = true;
            });
            this.pendingQuoteAttachments.copiedAttachments = parentAttachments;
        }

        this._dialog.open(QuoteEditorComponent, {
            data: QuoteEditorOption.getCopyOption(context),
            maxWidth: '87.5vw',
            width: '87.5vw',
            height: '95vh'
        });
    }

    public async onPrint(): Promise<void> {
        const ongoing = this.translate.instant('shared.quote.editor.ongoing');
        const failed = this.translate.instant('shared.quote.editor.failed');
        const quoteOptionsDialog = this._dialog.open(QuoteReportOptionsDialogComponent, {
            data: { quoteCreationDate: this.quote.basicInfo.createdOn },
            width: '450px',
            height: '300px'
        });

        const reportDate = await quoteOptionsDialog.afterClosed().toPromise() as Date;
        if (!reportDate) {
            return;
        }
        const selectedDate = reportDate.toISOString();
        const { quoteId } = this.quote.basicInfo;
        const url = this._quoteHttp.getQuotePdfUrlWithDateQuery(quoteId, selectedDate);
        const fileName = GenericUtilityService.getQuotePdfNameByQuoteBasicInfo(this.quote.basicInfo);
        const action = this._downloadHttp.download(url, fileName, 'application/pdf');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const data = new ActionProgressReporterOption(action, ongoing, '', '', ok, gotIt, false);

        const dialog = this._dialog.open(ActionProgressDialogComponent, {
            data,
            width: '550px',
            height: '120px'
        });

        if (!await dialog.afterClosed().toPromise()) {
            this._snackBar.open(failed, ok);
        }
    }

    public recordTabChange(): void {
        this._newrelicService.addPageAction('TabName', this.currentTab, 'QuoteEditor', {});
    }

    private copyQuote(quote: QuoteGeneralInfo): QuoteGeneralInfo {
        const copy: QuoteGeneralInfo = JSON.parse(JSON.stringify(quote));

        copy.basicInfo = {
            ...copy.basicInfo,
            name: `${this.translate.instant('shared.quote.editor.copyOf')} ${copy.basicInfo.name}`,
            quoteId: '',
            status: '',
            destinationProjectId: ''
        };

        return copy;
    }

    public async onAward(): Promise<void> {
        if (await this.onSave(this.translate.instant('shared.quote.editor.saving'), false)) {
            const hasAnyBudget = this.budgetToolConfigs.hasExistingExcelBudget || this.budgetToolConfigs.hasExistingWebBudget;
            this.onClose();

            this._dialog.open(DispatchProjectDialogComponent, {
                data: {
                    icon: 'edit',
                    title: this.translate.instant('shared.quote.editor.awardQuote'),
                    quoteToAward: this.quote,
                    workCategory: this.quote.workCategory,
                    budgetTypeOverride: hasAnyBudget ? 'Fixed Price' : 'T&M',
                    hasWebBudget: this.budgetToolConfigs.hasExistingWebBudget
                } as DispatchDialogOptions,
                minHeight: '600px',
                maxWidth: '72vw',
                maxHeight: '95vh'
            });
        }
    }

    private async confirmQuoteThreshold(): Promise<boolean> {
        if (this.thresholdExceedWarning) {
            const title = this.translate.instant('quote.details.areYouSure');
            const proceed = this.translate.instant('quote.details.proceed');
            const data = new ConfirmDialogOption(title, this.thresholdExceedWarning, true, proceed);
            const dialog = this._dialog.open(ConfirmDialogComponent, { data });
            return await dialog.afterClosed().toPromise();
        }
        return true;
    }

    // eslint-disable-next-line complexity
    public async onSave(message = '', showSuccess = true): Promise<boolean> {
        this.sidePanelForm?.markAllAsTouched();
        const canSave = this.isValidQuote(this.quote) && await this.confirmQuoteThreshold();

        if (!canSave) {
            return false;
        }

        let action: Promise<CodeDescription>;
        const context = this.getQuoteSaveContext();

        if (this.isEditMode) {
            action = this._quoteHttp.updateQuote(context);
        }
        else {
            action = this._quoteHttp.createQuote(context);
        }

        this._dialogRef.addPanelClass('quote-editor-saving');
        const save = this.translate.instant('shared.quote.editor.save');
        const create = this.translate.instant('shared.quote.editor.create');
        const savedLabel = this.translate.instant('shared.quote.editor.saved');
        const created = this.translate.instant('shared.quote.editor.created');
        const saving = this.translate.instant('shared.quote.editor.savingVerb');
        const creating = this.translate.instant('shared.quote.editor.creatingVerb');

        const ongoing = message ? message : this.translate.instant('shared.quote.editor.pleaseWait', {
            action: this.isEditMode ? save : create
        });

        const success = this.translate.instant('shared.quote.editor.createdSuccessfully', {
            action: this.isEditMode ? savedLabel : created
        });

        const error = this.translate.instant('shared.quote.editor.somethingWentWrong', {
            action: this.isEditMode ? saving : creating
        });

        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const data = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt, showSuccess);

        const dialog = this._dialog.open(ActionProgressDialogComponent, {
            data,
            width: '550px',
            height: '120px'
        });

        const saved = await dialog.afterClosed().toPromise<boolean>();
        this.isSaved = saved ? saved : this.isSaved;
        this._hasSavedOnce = true;

        if (saved) {
            this._options = QuoteEditorOption.getEditOption((await action).data as string);
            await this.ngOnInit();
        }

        this._dialogRef.removePanelClass('quote-editor-saving');

        return saved;
    }

    public onCustomerSelection(selectedCustomer: CustomerSelectionDto): void {
        this.customerSelection = selectedCustomer;
        this.pdfPreviewSubject.next(this.currentTab);
    }

    public async onClose(emit = true, forceClose = false): Promise<void> {
        const discardMessage = this.translate.instant('shared.quote.editor.discard');
        if (forceClose || this.isSaved || await this.alertUnsavedChanges(discardMessage)) {
            this._dialogRef.close(emit || this._hasSavedOnce ? this.quote : null);
        }
    }

    private async updateBudgetTabStatus(): Promise<void> {
        const customerId = this.quote.customer.customerId;
        const { branch, salesCode } = this.quote.basicInfo;

        if (this.budgetTab.isDisabled && Boolean(customerId) && Boolean(branch) && Boolean(salesCode)) {
            this.budgetTab.isDisabled = false;
            await this.setBudgetPayload();
        }
    }

    private isValidQuote(quote: QuoteGeneralInfo): boolean {
        const name = quote.basicInfo.name ?? '';

        return this.sidePanelForm?.valid && Boolean(name.trim());
    }

    private async alertUnsavedChanges(confirmText: string): Promise<boolean> {
        const message = this.translate.instant('shared.quote.editor.discardWarning');
        const unsavedChanges = this.translate.instant('shared.quote.editor.unsavedChanges');
        const cancelText = this.translate.instant('shared.quote.editor.cancel');
        const data = new ConfirmDialogOption(unsavedChanges, message, true, confirmText, cancelText);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private refreshPdfPreview(): void {
        this.currentTab = '';

        setTimeout(() => {
            this.currentTab = 'Pdf Preview';
        }, 400);
    }

    private getQuoteSaveContext(): QuoteSaveContext {
        const context = new QuoteSaveContext(this.quote, this.customerSelection, this.pendingQuoteAttachments);

        if (!this.isEditMode) {
            context.webBudgetPayload = this.webBudgetPayload;
        }

        if (this._options.mode === QuoteEditorMode.Copy) {
            context.parentQuoteId = this.parentQuoteId;
        }

        return context;
    }

    public async onBranchSalesCodeChange(change: BranchSalesCodeChange): Promise<void> {
        if (this.budgetTab.isDisabled) {
            return;
        }

        if (!await this.confirmDiscardBudget()) {
            this.revertChanges(change);

            return;
        }

        if (this.isEditMode) {
            const previous = await this._featureFlagService.evaluateUseLegacyBudgetToolByQuoteId(this.quote.basicInfo.quoteId);
            const hasExistingBudget = previous.hasExistingWebBudget || previous.hasExistingLegacyBudget;

            if (hasExistingBudget && !await this._quoteHttp.deleteBudget(this.quote.basicInfo.quoteId)) {
                this._snackBar.open(this.translate.instant('shared.quote.editor.failedToDeleteBudget'), this.translate.instant('shared.ok'));
                this.revertChanges(change);

                return;
            }
        }
        else {
            this.onLocalExcelBudgetUpdate(null);
        }

        await this.setBudgetPayload(change.newBranchCode, change.newSalesCode);
    }

    private revertChanges(oldValues: BranchSalesCodeChange): void {
        // Since we did not approve the budget deletion, we must set the form value back to what it was before
        // So that the UI is accurately reflected.
        this.sidePanelForm.get('salesCode').setValue(oldValues.oldSalesCode, { emitEvent: false, onlySelf: true });
        this.sidePanelForm.get('branch').setValue(oldValues.oldBranchName, { emitEvent: false, onlySelf: true });
        // We also need to set back the sales code on the quote object, so when we save, the correct data is sent to the API.
        this.quote.basicInfo.salesCode = oldValues.oldSalesCode;
        this.quote.basicInfo.branch = oldValues.oldBranchName;
    }

    private async confirmDiscardBudget(): Promise<boolean> {
        const title = this.translate.instant('shared.quote.editor.confirmChangeBudgetToolDialog.title');
        const message = this.translate.instant('shared.quote.editor.confirmChangeBudgetToolDialog.message');
        const confirmText = this.translate.instant('shared.quote.editor.confirmChangeBudgetToolDialog.continue');
        const cancelText = this.translate.instant('shared.cancel');
        const data = new ConfirmDialogOption(title, message, true, confirmText, cancelText);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data, width: '40vw' });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private async setBudgetPayload(branchCodeOverride = '', salesCodeOverride = ''): Promise<void> {
        let evaluationResult: BudgetToolFeatureDto;
        const quoteId = this.quote.basicInfo.quoteId;
        const customerId = this.quote.customer.customerId;
        this.budgetToolConfigs = null;

        if (this.isEditMode) {
            evaluationResult = await this._featureFlagService.evaluateUseLegacyBudgetToolByQuoteId(quoteId, branchCodeOverride, salesCodeOverride);
            const { useLegacyBudgetTool, hasExistingLegacyBudget, hasExistingWebBudget, useSubcontractRates } = evaluationResult;
            this.budgetToolConfigs = new BudgetManagementPayload(useLegacyBudgetTool, hasExistingLegacyBudget, hasExistingWebBudget, quoteId, customerId, true, useSubcontractRates, branchCodeOverride);
        }
        else {
            const branches = await this._branchHttp.getAllBranches();
            const branchCodeForNewQuote = branches.find(branch => branch.name === this.quote.basicInfo.branch).code;
            const { originProjectId, salesCode } = this.quote.basicInfo;
            evaluationResult = await this._featureFlagService.evaluateUseLegacyBudgetToolByOriginProjectId(originProjectId, branchCodeForNewQuote, salesCode);
            const { useLegacyBudgetTool, hasExistingLegacyBudget, hasExistingWebBudget, useSubcontractRates } = evaluationResult;
            this.budgetToolConfigs = new BudgetManagementPayload(useLegacyBudgetTool, hasExistingLegacyBudget, hasExistingWebBudget, '', customerId, true, useSubcontractRates, branchCodeForNewQuote);
        }
    }
}
