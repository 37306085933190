import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { AuthService } from '@auth0/auth0-angular';
import { Auth0Lock } from 'auth0-lock';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthLockService {
    private lock: Auth0LockStatic;
    private _isLoggedIn = false;

    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    public get isAuthenticated(): Observable<boolean> {
        return this.auth.isAuthenticated$;
    }

    constructor(private auth: AuthService,
        private _state: StateService) {
    }

    public initialize(): void {
        if (this.lock) {
            return;
        }
        const auth0Options = {
            closable: false,
            rememberLastLogin: false,
            languageDictionary: {
                title: environment.websiteTitle
            },
            defaultDatabaseConnection: environment.auth0DataBase,
            auth: {
                params: { scope: 'openid profile' },
                audience: environment.auth0ApiIdentifier,
                redirect: false,
                redirectUrl: window.location.origin,
                responseType: 'token id_token',
                autoParseHash: false,
                sso: true
            },
            theme: {
                logo: environment.loginLogo,
                primaryColor: environment.loginColor
            }
        } as Auth0LockConstructorOptions;

        this.lock = new Auth0Lock(environment.auth0ClientID, environment.auth0Domain, auth0Options);

        this.lock.on('authenticated', () => {
            this.checkSuccessAuthentication();
            this.lock.hide();
        });

        this.lock.on('authorization_error', _ => {
            this._isLoggedIn = false;
        });
    }

    public login(): void {
        if (this._isLoggedIn) {
            return;
        }

        this.checkSuccessAuthentication();
    }

    public logout(): void {
        this._isLoggedIn = false;
        localStorage.removeItem('accessToken');
        this.auth.logout({
            logoutParams: {
                federated: true,
                returnTo: window.location.origin
            }
        });
    }

    private checkSuccessAuthentication(): void {
        this.auth.getAccessTokenSilently({ authorizationParams: { audience: environment.auth0ApiIdentifier } }).subscribe(value => {
            //this is needed for the customer app
            localStorage.setItem('accessToken', value);
            this._isLoggedIn = true;
        }, _ => {
            this.lock.show({
                auth: {
                    audience: environment.auth0ApiIdentifier
                }
            });
        });
    }
}
