import { Ng2StateDeclaration, StateService } from '@uirouter/angular';

import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import { UserManagementComponent } from '../../shared/components/user-management/user-management.component';
import { globalResolver } from '../../core/configs/routing/global-resolver';

import { AdminComponent } from './admin.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { BranchManagementComponent } from './branch-management/branch-management.component';
import { RolePermissionComponent } from './role-permission/role-permission.component';
import { ProjectMergeRulesComponent } from './project-merge-rules/project-merge-rules.component';
import { ProjectAttributesManagementComponent } from './project-attributes-management/project-attributes-management.component';
import { MaterialChemicalsManagementComponent } from './material-chemicals/material-chemicals-management/material-chemical-management.compontent';
import { BudgetTemplateManagementComponent } from './budget-template-management/budget-template-management.component';
import { QuoteTemplateManagementComponent } from './quote-template-management/quote-template-management.component';
import { LeakReportTemplateManagementComponent } from './leak-report-template-management/leak-report-template-management.component';
import { DisclaimerSettingsComponent } from './disclaimer-settings/disclaimer-settings.component';
import { KpiManagementComponent } from './kpi-management/kpi-management.component';
import { InvoiceManagementComponent } from './invoice-management/invoice-management.component';
import { TaskCodeSettingsComponent } from './task-code-settings/task-code-settings.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';
import { GeofenceReportingSettingsComponent } from './geofence-reporting-settings/geofence-reporting-settings.component';
import { DataMigrationComponent } from './data-migration-management/data-migration.component';
import { WorkOrderTemplateComponent } from './work-order-template/work-order-template.component';
import { ServiceRatesManagementComponent } from './service-rates-management/service-rates-management.component';
import { ReadPermission } from '../../core/enums/read-permission.enum';

export const states: Ng2StateDeclaration[] = [
    {
        name: 'settings',
        url: '/settings',
        component: AdminComponent,
        redirectTo: 'settings.general',
        resolve: [
            {
                token: 'hasAccess',
                deps: [UserPermissionService, StateService],
                resolveFn: async(permissionService: UserPermissionService, stateService: StateService): Promise<void> => {
                    if (!await permissionService.hasAdminPrivilege()) {
                        stateService.transition.abort();
                        await stateService.go('pageNotFound');
                    }
                }
            },
            ...globalResolver
        ]
    },
    {
        name: 'settings.general',
        url: '/general',
        component: GeneralSettingsComponent
    },
    {
        name: 'settings.branches',
        url: '/branches',
        component: BranchManagementComponent
    },
    {
        name: 'settings.serviceProviders',
        url: '/service-providers',
        component: BranchManagementComponent
    },
    {
        name: 'settings.rolesAndPermissions',
        url: '/role-permission',
        component: RolePermissionComponent
    },
    {
        name: 'settings.users',
        url: '/users',
        component: UserManagementComponent
    },
    {
        name: 'settings.mergeRules',
        url: '/merge-rules',
        component: ProjectMergeRulesComponent
    },
    {
        name: 'settings.projectAttributes',
        url: '/project-attributes',
        component: ProjectAttributesManagementComponent
    },
    {
        name: 'settings.materialsAndChemicals',
        url: '/materials-chemicals',
        component: MaterialChemicalsManagementComponent
    },
    {
        name: 'settings.budgetTemplates',
        url: '/budget-templates',
        component: BudgetTemplateManagementComponent
    },
    {
        name: 'settings.quoteTemplates',
        url: '/quote-templates',
        component: QuoteTemplateManagementComponent
    },
    {
        name: 'settings.leakReportTemplates',
        url: '/leak-report-templates',
        component: LeakReportTemplateManagementComponent
    },
    {
        name: 'settings.reportDisclaimers',
        url: '/report-disclaimers',
        component: DisclaimerSettingsComponent
    },
    {
        name: 'settings.kpiSettings',
        url: '/kpi-settings',
        component: KpiManagementComponent
    },
    {
        name: 'settings.invoiceSettings',
        url: '/invoice-settings',
        component: InvoiceManagementComponent
    },
    {
        name: 'settings.taskCodeSettings',
        url: '/task-code-settings',
        component: TaskCodeSettingsComponent
    },
    {
        name: 'settings.customerSettings',
        url: '/customer-settings',
        component: CustomerSettingsComponent
    },
    {
        name: 'settings.geofenceReport',
        url: '/geofence-report',
        component: GeofenceReportingSettingsComponent
    },
    {
        name: 'settings.dataMigration',
        url: '/data-migration',
        component: DataMigrationComponent
    },
    {
        name: 'settings.workOrder',
        url: '/work-order',
        component: WorkOrderTemplateComponent
    },
    {
        name: 'settings.serviceRates',
        url: '/service-rates',
        component: ServiceRatesManagementComponent,
        resolve: [
            {
                token: 'hasAccess',
                deps: [UserPermissionService, StateService],
                resolveFn: async(permissionService: UserPermissionService, stateService: StateService): Promise<void> => {
                    if (!await permissionService.hasReadPermission(ReadPermission.ViewServiceRates, null, false)) {
                        stateService.transition.abort();
                        await stateService.go('pageNotFound');
                    }
                }
            }
        ]

    }
];
