import { Component, Output, EventEmitter, Input, OnInit, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { MOMENT_DEFAULT_FORMAT } from '../../../../core/constants/moment-default-format';
import HealthScreeningOption from '../../../../core/data-models/health-screening-option';
import DispatchStepConfig from '../../../../core/data-models/dispatch/dispatch-step-config';
import DynamicsDispatchInfo from '../../../../core/data-models/dispatch/dynamics-dispatch-info';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';
import ProjectIdUtility from '../../../../core/services/utility/project-id-utility/project-id-utility.service';
import { ValidatorFactory } from '../../../../core/services/validator-factory/validator-factory.service';
import Branch from '../../../../core/data-models/branch';
import { ProjectHttpService } from '../../../../core/services/http/project-http/project-http.service';
import { UserPermissionService } from '../../../../core/services/user-permission/user-permission.service';
import ProjectIdSummary from '../../../../core/data-models/project-id-summary';
import CustomerDto from '../../../../core/data-models/customer-dto';
import CustomerUtility from '../../../../core/services/utility/customer-utility/customer-utility.service';
import { CountryName } from '../../../../core/enums/country-name.enum';
import { SalesCodeConfigService } from '../../../../core/services/sales-code-config/sales-code-config.service';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import { CreatePermission } from '../../../../core/enums/create-permission.enum';
import { WritePermission } from '../../../../core/enums/write-permission.enum';
import { CustomerHttpService } from '../../../../core/services/http/customer-http/customer-http.service';
import { WorkCategory } from '../../../../core/data-models/work-category';
import LocalizedCodeDescription from '../../../../core/data-models/localized-code-description';
import UserInfo from '../../../../core/data-models/user-info';
import LanguageUtility from '../../../../core/services/utility/language-utility/language-utility.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ServiceType } from '../../../../core/enums/service-type.enum';

@Component({
    selector: 'app-project-info-step',
    styleUrls: ['./project-info-step.component.scss'],
    templateUrl: './project-info-step.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MOMENT_DEFAULT_FORMAT }
    ]
})
export class ProjectInfoStepComponent implements OnInit, OnChanges {
    @Input() public config: DispatchStepConfig;
    @Input() public selectedCustomer: CustomerDto;
    @Input() public handOffMeetingRequired: boolean;
    @Input() public healthScreeningOption: HealthScreeningOption;
    @Output() public stepClicked = new EventEmitter<string>();
    @Output() public completed = new EventEmitter<boolean>();
    @Output() public branchChanged = new EventEmitter<Branch>();
    @Output() public budgetTypeChanged = new EventEmitter<string>();
    @Output() public jobTypeChanged = new EventEmitter<string>();
    public isInternal = false;
    public dispatchInfo: DynamicsDispatchInfo;
    public projectInfoForm: UntypedFormGroup;
    public positiveValidator = this._validatorFactory.createPositiveValidator();
    public integerValidator = this._validatorFactory.createPositiveIntegerValidator();
    public daysValidator = this._validatorFactory.createDaysValidator();
    public currencyValidator = this._validatorFactory.createCurrencyValidator();
    public nameValidator = this._validatorFactory.createNameValidator();
    public filteredOptions: Observable<ProjectIdSummary[]>;
    public allowDispatchToTech = false;
    public branches: Branch[] = [];
    public jobSubTypes: LocalizedCodeDescription[] = [];
    public customerCountry = 'All';
    public contractTypes: string[] = [];
    private allProjectIds: ProjectIdSummary[] = [];
    private _useBudgetFeature = false;
    private _user: UserInfo;
    private _defaultContractTypes: string[] = [];

    //eslint-disable-next-line
    constructor(
        private _translate: TranslateService,
        private _userService: UserHttpService,
        private _projectService: ProjectHttpService,
        private _userPermissionService: UserPermissionService,
        private _formBuilder: UntypedFormBuilder,
        private _validatorFactory: ValidatorFactory,
        private _snackBar: MatSnackBar,
        private _saleCodeConfigService: SalesCodeConfigService,
        private _featureFlagService: FeatureFlagService,
        private _customerService: CustomerHttpService) {
    }

    get branchSelectionLabel(): string {
        const key = this.isInternal ? 'branch' : 'serviceProvider';

        return this._translate.instant(`dispatch.projectInfoStep.${key}`);
    }

    get isMultiDay(): boolean {
        const id = this.getFormFieldValue('salesCode');

        return this._saleCodeConfigService.isMultiDay(id);
    }

    get isCompleteByDateAllowed(): boolean {
        const id = this.getFormFieldValue('salesCode');
        return !this.config.isConvertingProject && this._saleCodeConfigService.isCompleteByDateAllowed(id);
    }

    get retentionPercentRequired(): boolean {
        return this.config.data.retentionMethod && this.config.data.retentionMethod === 'TL';
    }

    get isDrawingRequired(): boolean {
        const id = this.getFormFieldValue('salesCode');

        return this._saleCodeConfigService.isDrawingRequired(id);
    }

    get validDispatchOptions(): boolean {
        return this.config.data.sendToLocalQueue || Boolean(this.config.data.crew) || this.config.isConvertingProject;
    }

    get isOneDayJob(): boolean {
        const days = this.getFormFieldValue('estimatedDays');

        return !days || days === 1 ;
    }

    get showRoofingSquares(): boolean {
        return this.getFormFieldValue('salesCode') === '318';
    }

    get isGreenRoof(): boolean {
        return this.getFormFieldValue('salesCode') === '321';
    }

    get useBudgetFeature(): boolean {
        return this._useBudgetFeature;
    }

    // Temporary: See Aha FSW-3056 - We want to remove 'isSubcontract' etc in favour of configurable flags from the backend.
    get isSubcontract(): boolean {
        return this.config.workCategory === WorkCategory.Subcontract;
    }

    get showCrewSelection(): boolean {
        return !this.config.isConvertingProject && this.isInternal && !this.isSubcontract;
    }

    get showEstimatedHours(): boolean {
        return this.isInternal && !this.config.isConvertingProject;
    }

    get showIsDrawingRequired(): boolean {
        return !this.config.isConvertingProject && !this.isSubcontract;
    }

    get showIsOvernightSealRequired(): boolean {
        return this.isMultiDay && !this.config.isConvertingProject && !this.isSubcontract;
    }

    get showDispatcherNotes(): boolean {
        return !this.isSubcontract;
    }

    public async ngOnInit(): Promise<void> {
        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        this._useBudgetFeature = this._featureFlagService.featureFlags[FeatureFlags.UseBudgetFeature]?? false;
        this.dispatchInfo = this.config?.dynamicsDispatchInfo;
        await this.validateUserPermission();
        this._user = await this._userService.getUserInfo();

        this.loadFormControls();

        if (!this.isInternal || this.isSubcontract) {
            this.emptyOutCrew();
        }

        if (this.config.isIntegrationRequest) {
            await this.enforceSalesCodeAndJobType();
        }

        if (this.config.budgetTypeOverride) {
            this.contractTypes = [ this.config.budgetTypeOverride ];
            this.getFormField('budgetType').setValue(this.config.budgetTypeOverride);
        }
        else {
            this.contractTypes = this.dispatchInfo.contractType;
        }

        this._defaultContractTypes = this.dispatchInfo.contractType;
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.selectedCustomer) {
            const customer = changes.selectedCustomer.currentValue;
            this.allProjectIds = await this._projectService.getAllProjectIds(customer?.customerId);
            this.getFormField('projectBillWith').setValue('');
            const country = CustomerUtility.getCustomerCountry(customer);
            this.filterBranchesByCustomer(country, changes.selectedCustomer.isFirstChange());
            this.customerCountry = country ?? 'All';
            if (customer?.customerId) {
                const defaultScope = this.dispatchInfo?.systems?.find(serviceType => serviceType.startsWith(ServiceType.NonSpecialProject));
                this.projectInfoForm?.get('scope').setValue(defaultScope);
                await this.updateNotToExceedField();
            }
        }
        else if (changes.handOffMeetingRequired?.currentValue && this.config.data.crew !== null) {
            const message = this._translate.instant('dispatch.projectInfoStep.snackbarMessage');
            this._snackBar.open(message, this._translate.instant('snackbars.close'));
            this.emptyOutCrew();
        }
    }

    public validateAndMoveNext(): void {
        this.fillFormData();
        setTimeout(() => this.stepClicked.emit('next'));
    }

    public getFormField(name: string): AbstractControl {
        return this.projectInfoForm.get(name);
    }

    public resetRetentionPercent(): void {
        const validators = this.retentionPercentRequired? [this.positiveValidator.validator, Validators.required] : [];
        this.resetControlValidators('retentionPercent', validators);
    }

    public checkInputValueFromList(): void {
        const control = this.getFormField('projectBillWith');
        const value = control.value?.replace(/-/g, '').toLowerCase().trim();
        control.setValue(this.allProjectIds.some(_ => _.id.toLowerCase() === value)? value : '');
    }

    public async updateNotToExceedField(): Promise<void> {
        if (!this.selectedCustomer || !CustomerUtility.isConfirmedCustomer(this.selectedCustomer.customerId)) {
            return;
        }

        const code = this.getFormField('salesCode').value;

        if (code) {
            const notToExceedAmount = await this._customerService.getNotToExceedAmountByCustomerIdAndSalesCode(this.selectedCustomer.customerId, code);
            this.getFormField('notToExceedAmount').setValue(notToExceedAmount > 0 ? String(notToExceedAmount) : '');
        }
    }

    private loadFormControls(): void {
        const data = this.config.data;
        this.projectInfoForm = this._formBuilder.group({
            branch: [''],
            salesCode: [''],
            scope: [''],
            retentionPercent: [''],
            name: ['', this.nameValidator.validator],
            estimatedHours: ['', this.showEstimatedHours ? [this.integerValidator.validator, Validators.required] : []],
            estimatedDays: [''],
            notToExceedAmount: ['', this.currencyValidator.validator],
            taxStatus: [''],
            projectBillWith: [''],
            budgetType: [''],
            greenRoofOption: [''],
            jobType: [''],
            completeByDate: ['']
        });

        this.projectInfoForm.markAllAsTouched();
        this.subscribeFormChanges();
        this.projectInfoForm.patchValue({
            branch: data.branch,
            salesCode: data.salesCode,
            name: data.name,
            estimatedHours: data.estimatedHours,
            estimatedDays: data.estimatedDays,
            notToExceedAmount: data.notToExceedAmount,
            budgetType: data.budgetType,
            completeByDate: data.completeByDate
        });
    }

    private resetControlValidators(controlName: string, validators: ValidatorFn[]): void {
        const control = this.getFormField(controlName);
        if (validators?.length) {
            control.setValidators(validators);
        }
        else {
            control.clearValidators();
        }
        control.setValue('');
        control.updateValueAndValidity();
    }

    private fillFormData(): void {
        const values = this.projectInfoForm.value;
        this.config.data.branch = values.branch;
        this.config.data.salesCode = values.salesCode;
        this.config.data.scope = values.scope;
        this.config.data.retentionPercent = values.retentionPercent;
        this.config.data.name = values.name;
        this.config.data.estimatedHours = values.estimatedHours;
        this.config.data.estimatedDays = values.estimatedDays;
        this.config.data.notToExceedAmount = values.notToExceedAmount;
        this.config.data.taxStatus = values.taxStatus;
        this.config.data.projectBillWith = values.projectBillWith;
        this.config.data.budgetType = values.budgetType;
        this.config.data.greenRoofOption = values.greenRoofOption;
        this.config.data.jobType = values.jobType;
        this.config.data.completeByDate = values.completeByDate;
        this.completed.emit(this.projectInfoForm.valid && this.validDispatchOptions);
    }

    private _filter(value: string): ProjectIdSummary[] {
        const filterValue = value.toLowerCase();
        const filterValueWithoutDash = filterValue.replace(/-/g, '');
        return this.allProjectIds.filter(option => option.name.toLowerCase().includes(filterValue) ||
            option.id.toLowerCase().includes(filterValueWithoutDash));
    }

    private subscribeFormChanges(): void {
        this.getFormField('salesCode').valueChanges.subscribe(async value => {
            this.config.data.drawingRequired = this._saleCodeConfigService.isDrawingRequired(value);
            this.config.data.roofingSquares = value !== '318' ? '' : this.config.data.roofingSquares;
            const validators = this._saleCodeConfigService.isMultiDay(value) && !this.config.isConvertingProject?
                [this.daysValidator.validator, Validators.required] : [];
            this.resetControlValidators('estimatedDays', validators);
            this.resetControlValidators('greenRoofOption', value === '321'? [Validators.required] : []);

            this.jobSubTypes = this.config.isConvertingProject ?
                this._saleCodeConfigService.getSubtypesForConversion(value) :
                this._saleCodeConfigService.getSubtypes(value);

            if (this.jobSubTypes.length) {
                this.resetControlValidators('jobType', [Validators.required]);
            }
            else {
                this.resetControlValidators('jobType', []);
                this.jobTypeChanged.emit(value);
            }

            if (!this.isCompleteByDateAllowed) {
                this.config.data.completeByDate = '';
                this.projectInfoForm.get('completeByDate').setValue('');
            }

            await this.updateNotToExceedField();
            this.config.data.salesCode = value;
        });

        this.getFormField('branch').valueChanges.subscribe(value => this.branchChanged.emit(value));

        this.filteredOptions = this.getFormField('projectBillWith').valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );

        this.getFormField('budgetType').valueChanges.subscribe(value => {
            this.config.data.budgetType = value;
            this.budgetTypeChanged.emit(value);
        });

        this.getFormField('estimatedDays').valueChanges.subscribe(value => {
            if (!value || value === 1) {
                this.config.data.requireOvernightSealPhoto = false;
            }
        });

        this.getFormField('jobType').valueChanges.subscribe(value => this.jobTypeChanged.emit(value));
        this.projectInfoForm.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged())
            .subscribe(() => this.fillFormData());
    }

    private async validateUserPermission(): Promise<void> {
        const canCreateProject = await this._userPermissionService.hasCreatePermission(CreatePermission.CreateProject);
        const canDispatchProject = await this._userPermissionService.hasWritePermission(WritePermission.DispatchProject);
        this.allowDispatchToTech = canCreateProject && canDispatchProject;
        this.config.data.sendToLocalQueue = !this.allowDispatchToTech;
    }

    private async filterBranchesByCustomer(country: string, shouldEmit = false): Promise<void> {
        this.branches = this.config.branches.filter(_ => {
            const areBothCanadian = country === CountryName.CA && !ProjectIdUtility.isUsProject(_.code);
            const areBothAmerican = country === CountryName.US && ProjectIdUtility.isUsProject(_.code);

            return !_.isDeactivated && (_.isDefault || areBothCanadian || areBothAmerican);
        });

        await this.setCurrentBranch(shouldEmit);
    }

    private async setCurrentBranch(shouldEmit = false): Promise<void> {
        if (!this.isInternal && this.branches.some(_ => _.isDefault)) {
            this.getFormField('branch').setValue(this.branches.find(_ => _.isDefault));
        }
        else {
            const user = await this._userService.getUserInfo();
            const userBranch = this.branches.find(_ => _.name === user.branch);
            this.getFormField('branch').setValue(userBranch? userBranch : this.branches[0]);
        }

        if (shouldEmit) {
            this.branchChanged.emit(this.getFormFieldValue('branch'));
        }
    }
    //eslint-disable-next-line
    public getFormFieldValue(controlName: string): any {
        return this.projectInfoForm?.get(controlName)?.value;
    }

    public getTranslation(translations: LocalizedCodeDescription): string {
        return LanguageUtility.getLocalizedContent(this._user.language, translations.descriptions);
    }

    public salesCodeChanged(change: MatSelectChange): void {
        if (this.config.budgetTypeOverride) {
            return;
        }

        const salesCode = change.value;
        this.updateContractTypes(salesCode);

        if (salesCode === '312') {
            this.getFormField('budgetType').setValue('T&M');
        }
    }

    // This method is just temporary to allow us to prevent selecting 'Fixed Price' on 312's.
    // This is a hardcoded hack and should not be replicated. It is just to save time.
    private updateContractTypes(salesCode: string) {
        this.contractTypes = salesCode === '312' ? [ 'T&M' ] : this._defaultContractTypes;
    }


    private emptyOutCrew(): void {
        this.config.data.crew = null;
        this.config.data.sendToLocalQueue = true;
    }

    private enforceSalesCodeAndJobType(): void {
        const sale = this._saleCodeConfigService.getSalesCodeConfig(this.config.data.salesCode);
        this.dispatchInfo.salesCode = [new LocalizedCodeDescription(this.config.data.salesCode, sale.names)];

        if (this.config.data.jobType) {
            const job = this._saleCodeConfigService.getSalesCodeConfig(this.config.data.jobType);
            this.jobSubTypes = [new LocalizedCodeDescription(this.config.data.jobType, job.names)];
            this.getFormField('jobType').setValue(this.config.data.jobType);
        }
    }
}
