import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import SelectSiteDialogOptions from '../../../../core/data-models/select-site-dialog-options';
import SOAddress from '../../../../core/data-models/so-address';

@Component({
    selector: 'app-select-site-dialog',
    styleUrls: ['./select-site-dialog.component.scss'],
    templateUrl: './select-site-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SelectSiteDialogComponent implements OnInit {

    constructor(private dialogRef: MatDialogRef<SelectSiteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SelectSiteDialogOptions) { }

    public ngOnInit(): void {
        this.dialogRef.disableClose = !this.data.enableClose;
    }

    public selectSite(site: SOAddress): void {
        this.dialogRef.close(site);
    }
}
