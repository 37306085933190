import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';

import Employee from '../../../../core/data-models/employee';
import UserUtility from '../../../../core/services/utility/user-utility/user-utility.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'user-avatar-card',
    styleUrls: ['./user-avatar-card.component.scss'],
    templateUrl: './user-avatar-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarCardComponent {
    @Input() public user: Employee;
    @Input() public allowCopy = false;
    @Input() public showBorder = true;
    @Input() public color: string;

    constructor(public clipboard: Clipboard) { }

    get containerStyle(): { [key: string]: string } {
        return {
            border: this.showBorder ? '2px solid var(--primary-colors-lightest)' : null,
            'background-color': this.color ? this.color : this.defaultColor
        };
    }

    get fullName(): string {
        return UserUtility.getFullName(this.user);
    }

    get initials(): string {
        return UserUtility.getInitials(this.fullName);
    }

    get defaultColor(): string {
        const name = this.fullName;

        if (!name) {
            return 'var(--context-colors-info)';
        }

        let hash = 0;

        for (let i = 0; i < name.length; ++i) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }

        hash %= 360;
        // ensure hash value is valid hue
        return `hsl(${hash < 0 ? hash + 360 : hash}, 45%, 65%)`;
    }

    public onCopy(element: HTMLElement): void {
        this.clipboard.copy(this.fullName);
        const name = 'text-copied';
        element.classList.remove(name);
        setTimeout(() => element.classList.add(name));
    }
}
