import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import UserComment from '../../../core/data-models/user-comment/user-comment';
import UserInfo from '../../../core/data-models/user-info';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog-component/confirm-dialog.component';
import { UserPermissionService } from '../../../core/services/user-permission/user-permission.service';
import BranchTimezone from '../../../core/data-models/branch-timezone';
import { WritePermission } from '../../../core/enums/write-permission.enum';
import { CreatePermission } from '../../../core/enums/create-permission.enum';
import UserUtility from '../../../core/services/utility/user-utility/user-utility.service';

@Component({
    selector: 'user-comments-panel',
    styleUrls: ['./user-comments-panel.component.scss'],
    templateUrl: './user-comments-panel.component.html'
})
export class UserCommentsPanelComponent implements OnInit, OnChanges {
    @Input() public comments: UserComment[] = [];
    @Input() public user: UserInfo;
    @Input() public isLoaded = true;
    @Output() public commentCreate = new EventEmitter<string>();
    @Output() public commentUpdate = new EventEmitter<[string, UserComment]>();
    @Output() public commentDelete = new EventEmitter<UserComment>();
    public isInsertMode = false;
    public editingComment: UserComment = null;
    private _colorLookup = new Map<string, string>();
    private _colorCounter = 0;
    private _canAddComment = false;
    private _canEditComment = false;
    private _canDeleteComment = false;

    private readonly _colors = [
        'var(--misc-colors-b-lighter)',
        'var(--misc-colors-c-lighter)',
        'var(--misc-colors-d-lighter)',
        'var(--misc-colors-e-lighter)'
    ];

    constructor(private _dialog: MatDialog,
                private _translate: TranslateService,
                private _permissionService: UserPermissionService) { }

    get initials(): string {
        const initials = UserUtility.getInitials(this.user?.name);

        return initials ? initials : 'N/A';
    }

    get userBranchTimeZone(): BranchTimezone {
        return this.user?.branchInfo.geoLocation.timeZone;
    }

    get canAddComment(): boolean {
        return this._canAddComment;
    }

    get canEditComment(): boolean {
        return this._canEditComment;
    }

    get canDeleteComment(): boolean {
        return this._canDeleteComment;
    }

    public async ngOnInit(): Promise<void> {
        this._canAddComment = await this._permissionService.hasCreatePermission(CreatePermission.CreateComments);
        this._canEditComment = await this._permissionService.hasWritePermission(WritePermission.UpdateComments);
        this._canDeleteComment = await this._permissionService.hasWritePermission(WritePermission.DeleteComments);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!Object.prototype.hasOwnProperty.call(changes, 'comments')) {
            return;
        }

        for (const comment of this.comments) {
            const { userName } = comment.creator;

            if (this.user?.username !== userName && !this._colorLookup.has(userName)) {
                this._colorLookup.set(userName, this._colors[this._colorCounter++ % this._colors.length]);
            }
        }
    }

    public isReadonly(comment: UserComment): boolean {
        if (this.isInsertMode) {
            return true;
        }

        return Boolean(this.editingComment) && this.editingComment.id !== comment.id;
    }

    public getColor(comment: UserComment): string {
        const { userName } = comment.creator;
        const isCurrentUser = this.user?.username === userName;

        return isCurrentUser ? 'var(--context-colors-info)' : this._colorLookup.get(userName);
    }

    public onInsert(): void {
        if (!this.editingComment) {
            this.isInsertMode = true;

            return;
        }

        const title = this._translate.instant('shared.userComments.panel.unsavedChanges');
        const message = this._translate.instant('shared.userComments.panel.saveChangesMessage');
        const ok = this._translate.instant('shared.userComments.panel.ok');
        const data = new ConfirmDialogOption(title, message, false, ok, '');
        data.warningMessage = this._translate.instant('shared.userComments.panel.commentBeingEdited');
        this._dialog.open(ConfirmDialogComponent, { data });
    }

    public onCommentAdd(content: string): void {
        this.isInsertMode = false;
        this.commentCreate.emit(content);
    }
}
