<ng-container *ngIf="!isLoading; else loading">
    <div [class]="payload.isQuote ? 'budget-preview-buttons' : 'project-budget-preview-buttons'">
        <button mat-stroked-button *ngIf="!payload.isQuote" color="primary" (click)="downloadBudget()">
            {{ 'shared.budgetPreview.downloadBudgetFile'| translate }}
        </button>

        <button *ngIf="payload.isQuote && payload.recordId && previewFile" mat-mini-fab color="primary" [matTooltip]="translate.instant('shared.budgetPreview.downloadBudgetFile')"
            matTooltipPosition="above" (click)="downloadBudget()">
            <mat-icon>download</mat-icon>
        </button>

        <button *ngIf="payload.isQuote && previewFile" mat-mini-fab color="warn" [matTooltip]="translate.instant('shared.budgetPreview.deleteBudgetFile')" matTooltipPosition="above" (click)="confirmDeleteBudget()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <app-xlsx-file-preview fxFill [file]="previewFile"></app-xlsx-file-preview>
</ng-container>

<ng-template #loading>
    <div fxLayout="column" fxFill fxLayoutAlign="center center">
        <dots-loader></dots-loader>
    </div>
</ng-template>
