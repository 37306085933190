<div class="health-screening-requirement-card-container"
    [ngClass]="containerClass"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="0.25vh">

    <ng-container *ngIf="isHealthScreeningRequired">
        <mat-icon>warning</mat-icon>
        <span>{{ 'shared.healthScreeningRequirement.required' | translate }}</span>
    </ng-container>

    <ng-container *ngIf="isHealthScreeningExempted">
        <mat-icon>check_box</mat-icon>
        <span>{{ 'shared.healthScreeningRequirement.exempted' | translate }}</span>
    </ng-container>

    <ng-container *ngIf="isHealthScreeningUndetermined">
        <mat-icon>help_center</mat-icon>
        <span>{{ 'shared.healthScreeningRequirement.undetermined' | translate }}</span>
    </ng-container>
</div>
