<div class="information-grid-card-container">
    <div class="information-grid-card-content"
        [ngStyle]="{ 'animation-duration': cardAnimationDuration }"
        fxLayout="column"
        fxLayoutGap="10px">

        <button *ngIf="hasAdditionalDetails"
            class="additional-details-button"
            mat-icon-button
            (click)="openAdditionalDetails.emit()">

            <mat-icon>info</mat-icon>
        </button>

        <div [ngStyle]="{ 'animation-delay': contentAnimationDelay, 'animation-duration': contentAnimationDuration }"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="8px">

            <mat-icon *ngIf="!useSvg">{{ icon }}</mat-icon>
            <mat-icon *ngIf="useSvg" [svgIcon]="icon"></mat-icon>
            <span>{{ title }}</span>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-between" fxFlex>
            <div class="information-grid-row" [ngStyle]="getRowStyle(row)" *ngFor="let row of config.rows" fxLayout="row">
                <ng-container *ngFor="let item of row.items">
                    <grid-display-item *ngIf="item.type === itemTypes.TextInput"
                        [ngStyle]="{ width: item.width + '%' }"
                        [title]="item.title"
                        [content]="config.data[item.key]"
                        [useTextarea]="item.useTextarea"
                        [isHtml]="item.isHtml"
                        [transform]="item.transform"
                        [displayPipe]="item.displayPipe"
                        [editOptions]="getEditOptions(item)"
                        (errorFound)="recordError(item.key, $event)"
                        (errorFixed)="removeError(item.key)"
                        (contentUpdate)="onContentUpdate(item.key, $event)"
                        (dblclick)="onItemDoubleClick(item.key)">
                    </grid-display-item>

                    <grid-datepicker *ngIf="item.type === itemTypes.Datepicker"
                        [ngStyle]="{ width: item.width + '%' }"
                        [title]="item.title"
                        [date]="config.data[item.key]"
                        (dateUpdate)="onContentUpdate(item.key, $event)">
                    </grid-datepicker>

                    <grid-pro-dropdown-item *ngIf="item.type === itemTypes.Dropdown"
                        [ngStyle]="{ width: item.width + '%' }"
                        [title]="item.title"
                        [content]="config.data[item.key]"
                        [transform]="item.transform"
                        [displayPipe]="item.displayPipe"
                        [editOptions]="getEditOptions(item)"
                        (errorFound)="recordError(item.key, $event)"
                        (errorFixed)="removeError(item.key)"
                        (contentUpdate)="onContentUpdate(item.key, $event)"
                        (dblclick)="onItemDoubleClick(item.key)"
                        [options]="item.optionValues"
                        [selected]="config.data[item.key]"
                        (selectedChange)="onContentUpdate(item.key, $event)">
                    </grid-pro-dropdown-item>
                </ng-container>
            </div>
        </div>
    </div>
</div>
