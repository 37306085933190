import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
//eslint-disable-next-line
import { FormControlUtilityService } from '../../../../../../../core/services/utility/form-control-utility-service/form-control-utility.service';

@Component({
    selector: 'hand-off-meeting-question',
    templateUrl: './hand-off-meeting-question.component.html',
    styleUrls: ['./hand-off-meeting-question.component.scss']
})

export class HandOffMeetingQuestionComponent {
    @Input() handOffQuestionFormGroup: UntypedFormGroup;
    @Input() responses: string[];
    @Input() isReadonly: boolean;

    constructor(
        private _formService: FormControlUtilityService) { }

    public isFormControlValid(controlName: string): boolean {
        return this.handOffQuestionFormGroup.get(controlName).invalid;
    }

    public getErrorMessage(controlName: string): string {
        return this._formService.getErrorMessage(this.handOffQuestionFormGroup.get(controlName));
    }

}
