import HandOffMeetingForm from '../handoff-meeting/hand-off-meeting-form';
import AttachmentModel from '../attachment-model';
import Address from '../address';
import ClientInfo from '../client-info';
import Crew from '../crew';

export default class DispatchInfoDto {

    public expectedDays: number;
    public branch: string;
    public jobType: string;
    public isContinueLeakCall: boolean;
    public projectId: string;
    public jobId: number;
    public address: Address;
    public rawWorkOrder: string;
    public workCheckList: string[];
    public notes: string;
    public notToExceedAmount: number;
    public crew: Crew;
    public name: string;
    public requireDrawing: boolean;
    public requireOvernightSealPhoto: boolean;
    public siteContact: ClientInfo;
    public attachments: AttachmentModel[];
    public isHandOffMeetingNeeded: boolean;
    public handOffMeeting: HandOffMeetingForm;

}
