<app-dialog-panel class="version-prompt-container"
    [icon]="'create'"
    [title]="translate.instant('flynnBudgetTool.versionPrompt.title')"
    [isLoaded]="true">

    <div class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.25vh">
        <mat-form-field>
            <mat-label>{{ 'flynnBudgetTool.versionPrompt.version' | translate }}</mat-label>
            <input type="text" matInput cdkFocusInitial [formControl]="version" maxLength="10" />

            <mat-error *ngIf="version.invalid && version.errors.invalidFormat">
                * {{ 'flynnBudgetTool.versionPrompt.errors.invalidFormat' | translate }}
            </mat-error>

            <mat-error *ngIf="version.invalid && version.errors.duplicateVersion">
                * {{ 'flynnBudgetTool.versionPrompt.errors.duplicateVersion' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="existingVersions.length">
            <mat-label>{{ 'flynnBudgetTool.versionPrompt.prefillDataFromVersion' | translate }}</mat-label>

            <mat-select (selectionChange)="sourceVersion = $event.value">
                <mat-option [value]="''"></mat-option>
                <mat-option *ngFor="let existingVersion of existingVersions" [value]="existingVersion">{{ existingVersion }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="onClose(true)">{{ 'flynnBudgetTool.shared.create' | translate }}</button>
        <button mat-flat-button (click)="onClose()">{{ 'flynnBudgetTool.shared.cancel' | translate }}</button>
    </div>
</app-dialog-panel>
