export default class ReportQuery {
    public role: string;
    public branch: string;
    public jobType: string;
    public startDate: Date;
    public endDate: Date;
    public projectId: string;

    // eslint-disable-next-line
    public constructor(role: string, branch: string, jobType: string, startDate: Date, endDate: Date, projectId: string) {
        this.role = role;
        this.branch = branch;
        this.jobType = jobType;
        this.startDate = startDate;
        this.endDate = endDate;
        this.projectId = projectId;
    }
}
