<div fxLayout="column" class="chemicals-management-container" fxFill>
    <kendo-grid
        [data]="view"
        [pageSize]="gridState.take"
        [skip]="gridState.skip"
        [sort]="gridState.sort"
        [pageable]="true"
        [navigable]="true"
        (dataStateChange)="onStateChange($event)"
        (pageChange)="pageChange($event)">
        <ng-template kendoGridToolbarTemplate>
            <button mat-flat-button type="button" color="primary" (click)="addHandler()">
                <mat-icon>add</mat-icon>
                {{ 'dataReport.chemicalsReport.addNew' | translate}}
            </button>
        </ng-template>
        <kendo-grid-column field="name" title="{{'dataReport.chemicalsReport.name' | translate}}" width="350">
            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                <span>
                    {{dataItem.name}}
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="phone" title="{{'dataReport.chemicalsReport.phone' | translate}}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <mat-chip-list>
                    <mat-chip
                        *ngFor="let phone of dataItem.phone">
                        {{phone | phoneDisplayFormat}}
                    </mat-chip>
                </mat-chip-list>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-command-column title="" width="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <button mat-mini-fab type="button" color="primary" (click)="update(dataItem)">
                    <mat-icon>edit</mat-icon>
                  </button>
                <button mat-mini-fab type="button" color="warn" (click)="removeHandler(dataItem)">
                  <mat-icon>delete</mat-icon>
                </button>
            </div>
        </ng-template>
        </kendo-grid-command-column>
    </kendo-grid>
</div>
