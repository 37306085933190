import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import CodeDescription from '../../../data-models/code-description';
import CustomReportPrintConfig from '../../../data-models/custom-report-template/custom-report-print-config';
import CustomReportTemplate from '../../../data-models/custom-report-template/custom-report-template';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomReportTemplateHttpService {

    private readonly _api = `${environment.fswApiUrl}api2/service/custom-report-template`;

    constructor(private _http: HttpClient,
        private _translate: TranslateService) { }

    public async getCustomReportTemplates(): Promise<CustomReportTemplate[]> {
        try {
            const endpoint = `${this._api}/templates`;
            const data = await this._http.get<CustomReportTemplate[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async addCustomReportTemplate(template :CustomReportTemplate): Promise<CodeDescription> {
        const internalErrorMessage = this._translate.instant('core.httpServices.internalError');
        try {
            const endpoint = `${this._api}/templates`;
            const data = await this._http.post<CustomReportTemplate>(endpoint, template).toPromise();
            const convertedData = JsonUtility.toCamelCaseKeys(data);
            const okLabel = this._translate.instant('core.generic.ok');

            return convertedData? new CodeDescription('200', okLabel, convertedData) : new CodeDescription('500', internalErrorMessage);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const isUnknownError = httpError.statusText === 'Unknown Error';

            switch (httpError.status) {
                case 403:
                case 400: return new CodeDescription('400', isUnknownError ? internalErrorMessage : await httpError.error.text());
                default: return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async updateCustomReportTemplate(template :CustomReportTemplate): Promise<CodeDescription> {
        const internalErrorMessage = this._translate.instant('core.httpServices.internalError');
        try {
            const endpoint = `${this._api}/templates/${template.code}`;
            const data = await this._http.put<CustomReportTemplate>(endpoint, template).toPromise();
            const convertedData = JsonUtility.toCamelCaseKeys(data);
            const okLabel = this._translate.instant('core.generic.ok');

            return convertedData? new CodeDescription('200', okLabel, convertedData) : new CodeDescription('500', internalErrorMessage);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const isUnknownError = httpError.statusText === 'Unknown Error';

            switch (httpError.status) {
                case 403:
                case 400: return new CodeDescription('400', isUnknownError ? internalErrorMessage : await httpError.error.text());
                default: return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async deleteCustomReportTemplate(code: number): Promise<boolean> {
        try {
            const endpoint = `${this._api}/templates/${code}`;

            return await this._http.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getCustomReportPreview(content: string) : Promise<Blob> {
        try {
            const endpoint = `${this._api}/template/pdf/preview`;

            const data = await this._http.post(endpoint, JSON.stringify(content), {
                headers: { 'content-type': 'application/json' },
                responseType: 'arraybuffer' }).toPromise();
            return new Blob([data], { type: 'application/pdf' });
        }
        catch {
            return null;
        }
    }

    public async getCustomReportTemplateByProjectId(projectId: string): Promise<CustomReportTemplate> {
        try {
            const endpoint = `${this._api}/template/${projectId}`;
            const data = await this._http.get<CustomReportTemplate>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getCustomReportPdf(config: CustomReportPrintConfig) : Promise<Blob> {
        try {
            const endpoint = `${this._api}/template/pdf`;

            const data = await this._http.post(endpoint, config, {
                responseType: 'arraybuffer' }).toPromise();
            return new Blob([data], { type: 'application/pdf' });
        }
        catch {
            return null;
        }
    }
}
