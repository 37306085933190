import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { debounceTime, tap, map, filter } from 'rxjs/operators';

import BudgetTemplatePayload from '../../../../core/data-models/budget-template-payload';
import { BudgetHttpService } from '../../../../core/services/http/budget-http/budget-http.service';

@Component({
    selector: 'budget-template-upload-dialog',
    styleUrls: ['./budget-template-upload-dialog.component.scss'],
    templateUrl: './budget-template-upload-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BudgetTemplateUploadDialogComponent implements OnInit {
    public name = new UntypedFormControl('', [Validators.required, this.isNonEmpty]);
    public version = new UntypedFormControl({ value: 0, disabled: true });
    public file: FileSystemFileEntry = null;
    public minVersion = 0;

    constructor(public translate: TranslateService,
                private _budgetHttp: BudgetHttpService,
                private _dialogRef: MatDialogRef<BudgetTemplateUploadDialogComponent, BudgetTemplatePayload>,
                private _snackbar: MatSnackBar) { }

    get fileName(): string {
        return this.file?.name ?? this.translate.instant('shared.uploadFileDialog.noFileSelected');
    }

    get showFileUpload(): boolean {
        if (this.file) {
            return true;
        }

        return this.name.valid && this.version.valid;
    }

    get canUpload(): boolean {
        return this.name.valid && this.version.valid && Boolean(this.file);
    }

    public ngOnInit(): void {
        this._dialogRef.disableClose = true;
        this.version.valueChanges.subscribe(() => this.version.markAsTouched());

        this.name.valueChanges
            .pipe(
                tap(() => this.version.disable()),
                debounceTime(750),
                map(_ => (_ ?? '').trim()),
                filter(_ => Boolean(_))
            )
            .subscribe(_ => this.loadNextVersion(_));
    }

    public onBudgetTemplateFileDrop(files: NgxFileDropEntry[]): void {
        const file = files[0].fileEntry;

        if (file.isFile && /\.xlsm$/i.test(file.name)) {
            this.file = file as FileSystemFileEntry;
        }
    }

    public onClose(emit = true): void {
        if (!emit) {
            this._dialogRef.close();
        }
        else if (this.canUpload) {
            this.file.file(_ => {
                const payload: BudgetTemplatePayload = {
                    name: this.name.value.trim(),
                    version: Number(this.version.value).toFixed(1),
                    file: _
                };

                this._dialogRef.close(payload);
            });
        }
    }

    private async loadNextVersion(name: string): Promise<boolean> {
        const version = await this._budgetHttp.getNextBudgetTemplateVersion(name);

        if (version === -1) {
            const message = this.translate.instant('admin.budgetTemplateManagement.uploadDialog.failedToGetNextVersion');
            this._snackbar.open(message, this.translate.instant('shared.ok'));

            return;
        }

        this.minVersion = Number(version.toFixed(1));
        this.version.setValue(this.minVersion);
        this.version.setValidators([Validators.required, Validators.pattern(/^\d+(\.\d)?$/), Validators.min(this.minVersion)]);
        this.version.updateValueAndValidity();
        this.version.enable();
    }

    private isNonEmpty(control: UntypedFormControl): null | { [key: string]: boolean } {
        return control.value?.trim() ? null : { empty: true };
    }
}
