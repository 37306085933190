import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import UserInfo from '../../../core/data-models/user-info';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';
import UserUtility from '../../../core/services/utility/user-utility/user-utility.service';
import { AuthLockService } from '../../../core/services/auth-lock-service/auth-lock-service';
import { AboutDialogComponent } from '../dialogs/about-dialog/about-dialog.component';
import Language from '../../../core/data-models/generic/language';
import LanguageService from '../../../core/services/language/language.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'app-user-menu',
    styleUrls: ['./user-menu.component.scss'],
    templateUrl: './user-menu.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit {
    public languages: Language[];
    public loading = false;
    public userLanguage = '';
    private _userInfo: UserInfo;
    private _companyName = '';
    //eslint-disable-next-line
    constructor(private _translate: TranslateService,
                private _userInfoService: UserHttpService,
                private _authService: AuthLockService,
                private _featureFlagService: FeatureFlagService,
                private _appConfigService: AppConfigHttpService,
                private _dialog: MatDialog,
                private _languageService: LanguageService,
                private _matSnackBar: MatSnackBar) { }

    get userInfoTooltip(): string {
        return `${this._translate.instant('shared.userMenu.userSettingsFor')} ${this._userInfo?.name?? 'N/A'}`;
    }

    get userInitials(): string {
        const initials = UserUtility.getInitials(this._userInfo?.name);

        return initials ? initials : 'N/A';
    }

    get userBranch(): string {
        if (!this._userInfo) {
            return 'N/A';
        }

        if (this.isInternal) {
            return `${this._userInfo.branch} ${this._translate.instant('shared.userMenu.branch')}`;
        }

        return `${this._translate.instant('shared.userMenu.serviceProvider')} - ${this._userInfo.branch}`;
    }

    get userDisplayName(): string {
        return this._userInfo?.name;
    }

    get userEmail(): string {
        return this._userInfo?.email;
    }

    get companyName(): string {
        return this._companyName;
    }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    get showAboutPage(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.ShowAboutPage];
    }

    public async ngOnInit(): Promise<void> {
        this.languages = this._languageService.getLanguages();
        this._userInfo = await this._userInfoService.getUserInfo();
        this._companyName = await this._appConfigService.getCompanyName();
        this.userLanguage = this._userInfo.language;
    }

    public async onLanguageChange(language: string): Promise<void> {
        if (!language) {
            return;
        }
        this.loading = true;

        const result = await this._userInfoService.updateUserLanguage(language);

        if (result) {
            this.userLanguage = language;
            this._matSnackBar.open(this._translate.instant('shared.userMenu.successLanguageChanged'), null, { duration: 2000 });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        else {
            this._matSnackBar.open(this._translate.instant('shared.userMenu.failedLanguageChanged'), null, { duration: 2000 });
        }

        this.loading = false;
    }

    public showAboutDialog(): void {
        this._dialog.open(AboutDialogComponent, { width: '350px', height: '450px' });
    }

    public signOut(): void {
        this._authService.logout();
    }

    public stopPropagation(event: Event): void {
        event.stopPropagation();
    }
}
