<dialog-action-card
    [icon]="'preview'"
    title="{{'templateEditor.previewDialog.title' | translate }}"
    [isLoaded]="isLoaded"
    (close)="onClose()">
    <mat-card fxLayout="column" fxFill>
        <div *ngIf="pdfUrl" class="main-pdf-preview-content">
            <pdf-viewer
                [src]="pdfUrl"
                [render-text]="true"
                [show-borders]="true"
                style="height: inherit">
            </pdf-viewer>
        </div>
        <div *ngIf="!pdfUrl" fxLayout="column" fxFill fxLayoutAlign="center center">
            <label>{{ 'templateEditor.messages.failedToGetPdfPreview' | translate }}</label>
        </div>
    </mat-card>
</dialog-action-card>
