/* eslint-disable max-lines */
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgxFileDropModule } from 'ngx-file-drop';
// import { NgImageSliderModule } from 'ng-image-slider';
import { MatLegacyFormFieldModule as MatFormFieldModule, MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule, MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { GoogleMapsModule } from '@angular/google-maps';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { KpiRolePipe } from './pipes/kpi-role/kpi-role.pipe';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AboutDialogComponent } from './components/dialogs/about-dialog/about-dialog.component';
import { ActionProgressDialogComponent } from './components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { NamePromptDialogComponent } from './components/dialogs/name-prompt-dialog/name-prompt-dialog.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog-component/confirm-dialog.component';
import { PromptDialogComponent } from './components/dialogs/prompt-dialog-component/prompt-dialog.component';
import { QuoteReportOptionsDialogComponent } from './components/dialogs/quote-report-options-dialog/quote-report-options-dialog.component';
import { QuoteCopyOptionsDialogComponent } from './components/dialogs/quote-copy-options-dialog/quote-copy-options-dialog.component';
import { ExpandableButtonComponent } from './components/buttons/expandable-button/expandable-button.component';
import { DropdownButtonComponent } from './components/buttons/dropdown-button/dropdown-button.component';
import { GridDisplayItemComponent } from './components/input-fields/grid-items/grid-display-item/grid-display-item.component';
import { GridDatepickerComponent } from './components/input-fields/grid-items/grid-datepicker/grid-datepicker.component';
import { GridItemActionsComponent } from './components/input-fields/grid-items/grid-item-actions/grid-item-actions.component';
import { GridDropdownItem } from './components/input-fields/grid-items/grid-dropdown-item/grid-dropdown-item.component';
import { GridProDropdownItem } from './components/input-fields/grid-items/grid-pro-dropdown-item/grid-pro-dropdown-item.component';
import { RatingStarsComponent } from './components/indicators/rating-stars/rating-stars.component';
import { CustomerDetailsCardComponent } from './components/cards/customer-details-card/customer-details-card.component';
import { UserAvatarCardComponent } from './components/cards/user-avatar-card/user-avatar-card.component';
import { UserAvatarsCardComponent } from './components/cards/user-avatars-card/user-avatars-card.component';
import { TimelineEventCardComponent } from './components/cards/timeline-card/timeline-event-card/timeline-event-card.component';
import { TimelineCardComponent } from './components/cards/timeline-card/timeline-card.component';
import { ExpandableTextareaCardComponent } from './components/cards/expandable-textarea-card/expandable-textarea-card.component';
import { DialogActionCardComponent } from './components/cards/dialog-action-card/dialog-action-card.component';
import { InformationGridCardComponent } from './components/cards/information-grid-card/information-grid-card.component';
import { ProjectAttributesCardComponent } from './components/project-attributes-card/project-attributes-card.component';
import { ProjectQuestionsCardComponent } from './components/project-questions-card/project-questions-card.component';
import { QuoteSummaryCardComponent } from './components/summary-list/quote-summary-card/quote-summary-card.component';
import { ProjectSummaryCardComponent } from './components/summary-list/project-summary-card/project-summary-card.component';
import { SummaryListComponent } from './components/summary-list/summary-list.component';
import { SummaryListFilterComponent } from './components/summary-list-filter/summary-list-filter.component';
import { FilterPopupPanelComponent } from './components/summary-list-filter/filter-popup-panel/filter-popup-panel.component';
import { ProjectUSQuestionsCardComponent } from './components/project-us-questions-card/project-us-questions-card.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FullAddressPipe } from './pipes/full-address/full-address.pipe';
import { SplitCamelCasePipe } from './pipes/split-camel-case/split-camel-case.pipe';
import { PercentageStringPipe } from './pipes/percentage-string/percentage-string.pipe';
import { TextWithPlaceholderPipe } from './pipes/text-with-placeholder/text-with-placeholder.pipe';
import { PhoneDisplayFormatPipe } from './pipes/phone-display-format/phone-display-format.pipe';
import { PhotoReportViewerComponent } from './components/photo-report-viewer/photo-report-viewer/photo-report-viewer.component';
import { PhotoCardComponent } from './components/cards/photo-card/photo-card.component';
import { PhotoCarouselComponent } from './components/photo-report-viewer/photo-carousel/photo-carousel.component';
import { EditPhotoComponent } from './components/photo-report-viewer/edit-photo/edit-photo.component';
import { AuthenticationErrorComponent } from './components/errors/authentication-error/authentication-error.component';
import { PageNotFoundComponent } from './components/errors/page-not-found/page-not-found.component';
import { ContentNotFoundComponent } from './components/errors/content-not-found/content-not-found.component';
import { DeletedPhotoDialogComponent } from './components/dialogs/deleted-photo-dialog/deleted-photo-dialog.component';
import { PhotoReportOptionsDialogComponent } from './components/dialogs/photo-report-options-dialog/photo-report-options-dialog.component';
import { PdfFileViewerDialogComponent } from './components/dialogs/pdf-file-viewer-dialog/pdf-file-viewer-dialog.component';
import { UploadImageDialogComponent } from './components/dialogs/upload-image-dialog/upload-image-dialog.component';
import { PhotoEditorDialogComponent } from './components/dialogs/photo-editor-dialog/photo-editor-dialog.component';
import { DrawingPanelComponent } from './components/drawing-panel/drawing-panel.component';
import { EditDrawingComponent } from './components/drawing-panel/edit-drawing/edit-drawing.component';
import { FullViewPhotoDialogComponent } from './components/dialogs/full-view-photo-dialog/full-view-photo-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { QuoteEditorComponent } from './components/quote-editor/quote-editor.component';
import { QuoteContentEditorComponent } from './components/quote-editor/quote-content-editor/quote-content-editor.component';
import { QuoteInformationPanelComponent } from './components/quote-editor/quote-information-panel/quote-information-panel.component';
import { QuoteEditorNotesComponent } from './components/quote-editor/quote-editor-notes/quote-editor-notes.component';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { AddFollowUpLogComponent } from './components/add-follow-up-log/add-follow-up-log.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { AddFollowUpReminderComponent } from './components/add-follow-up-reminder/add-follow-up-reminder.component';
import { QuoteAttachmentsComponent } from './components/quote-editor/quote-attachments/quote-attachments.component';
import { ActionProgressReporterComponent } from './components/indicators/action-progress-reporter/action-progress-reporter.component';
import { BudgetUploadComponent } from './components/budget-upload/budget-upload.component';
import { BranchLocalTimePipe } from './pipes/branch-local-time/branch-local-time.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { JobTypeTextPipe } from './pipes/job-type-text/job-type-text.pipe';
import { CustomerIdPlaceholderPipe } from './pipes/customer-id-placeholder/customer-id-placeholder.pipe';
import { QuoteFollowupLogsComponent } from './components/quote-followup-logs/quote-followup-logs.component';
import { AddFollowUpLogDialogComponent } from './components/add-follow-up-log-dialog/add-follow-up-log-dialog.component';
import { CrewSelectorComponent } from './components/crew-selector/crew-selector.component';
import { UserCommentCardComponent } from './components/user-comments-panel/user-comment-card/user-comment-card.component';
import { UserCommentEditorComponent } from './components/user-comments-panel/user-comment-editor/user-comment-editor.component';
import { UserCommentsPanelComponent } from './components/user-comments-panel/user-comments-panel.component';
import { ProjectUSTaxQuestionsCardComponent } from './components/project-us-tax-questions-card/project-us-tax-questions-card.component';
import { DotsLoaderComponent } from './components/indicators/dots-loader/dots-loader.component';
import { CustomerCreateComponent } from './components/customer-management//customer-create-management/customer-create-component/customer-create.component';
import { CustomerCreateManagementComponent } from './components/customer-management//customer-create-management/customer-create-management.component';
import { CustomerSelectComponent } from './components/customer-management//customer-select-management/customer-select-component/customer-select.component';
import { CustomerSelectionConfirmComponent } from './components/customer-management/customer-selection-confirm-component/customer-selection-confirm.component';
import { CustomerSiteSelectComponent } from './components/customer-management//customer-select-management/customer-site-select-component/customer-site-select.component';
import { CustomerSiteSuggestionsComponent } from './components/customer-management/customer-select-management/customer-site-suggestions-component/customer-site-suggestions.component';
import { CustomerSelectManagementComponent } from './components/customer-management//customer-select-management/customer-select-management.component';
import { CustomerGeoCoordinateMapComponent } from './components/customer-management//customer-site-create/customer-geo-coordinate-map-component/customer-geo-coordinate-map.component';
import { CustomerSiteCreateComponent } from './components/customer-management//customer-site-create/customer-site-create-component/customer-site-create.component';
import { CustomerManagementComponent } from './components/customer-management//customer-management.component';
import { SelectSiteDialogComponent } from './components/dialogs/select-site-dialog/select-site-dialog.component';
import { TemplateEditItemCardComponent } from './components/cards/template-edit-item-card/template-edit-item-card.component';
import { TemplateDisplayItemCardComponent } from './components/cards/template-display-item-card/template-display-item-card.component';
import { TemplateContentCardComponent } from './components/cards/template-content-card/template-content-card.component';
import { TemplateContentCollectionCardComponent } from './components/cards/template-content-collection-card/template-content-collection-card.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { CustomerDisplayFormattingService } from './components/customer-management/services/customer-display-formatting/customer-display-formatting.service';
import { XlsxFilePreviewComponent } from './components/xlsx-file-preview/xlsx-file-preview.component';
import { DashboardViewDialogComponent } from './components/dialogs/dashboard-view-dialog/dashboard-view-dialog.component';
import { BudgetTemplatesDialogComponent } from './components/dialogs/budget-templates-dialog/budget-templates-dialog.component';
import { WorkOrderStepComponent } from './components/dispatch-steps/work-order-step/work-order-step.component';
import { AttachmentStepComponent } from './components/dispatch-steps/attachment-step/attachment-step.component';
import { ConditionReportOptionsDialogComponent } from './components/dialogs/condition-report-options-dialog/condition-report-options-dialog.component';
import { ProjectReportContentComponent } from './components/project-report-content/project-report-content.component';
import { ProjectGeneralInfoPanelComponent } from './components/project-general-info-panel/project-general-info-panel.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { HealthScreeningRequirementCardComponent } from './components/cards/health-screening-requirement-card/health-screening-requirement-card.component';
import { LeakReportCardComponent } from './components/cards/leak-report-card/leak-report-card.component';
import { GridPageResizeDirective } from './directives/grid-page-resize/grid-page-resize.directive';
import { BudgetPreviewComponent } from './components/budget-preview/budget-preview.component';
import { SimplePhotoCardComponent } from './components/cards/simple-photo-card/simple-photo-card.component';
import { ImageOverlayDirective } from './directives/image-overlay/image-overlay.directive';
import { WorkOrderEditDialogComponent } from './components/dialogs/work-order-edit-dialog/work-order-edit-dialog.component';
import { QuotePdfPreviewComponent } from './components/quote-pdf-preview/quote-pdf-preview.component';
import { QuoteEditorService } from './components/quote-editor/quote-editor.service';
import { TranslateModule } from '@ngx-translate/core';
import { AttachmentTypePipe } from './pipes/attachment-type/attachment-type.pipe';
import { IntegrationRequestsDialogComponent } from './components/dialogs/integration-requests-dialog/integration-requests-dialog.component';
import { SubscriptionEditorComponent } from './components/subscription-editor/subscription-editor.component';
import { CustomReportPrintDialogComponent } from './components/dialogs/custom-report-print-dialog/custom-report-print-dialog.component';
import { SelectPhotoDialogComponent } from './components/dialogs/select-photo-dialog/select-photo-dialog.component';
import { MaintenancePlanViewerComponent } from './components/maintenance-plan-viewer/maintenance-plan-viewer.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UserProfileEditorDialogComponent } from './components/user-management/user-profile-editor-dialog/user-profile-editor-dialog.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { QuotesHistoryComponent } from './components/quotes-history/quotes-history.component';
import { ProjectHistoryViewComponent } from './components/project-history-view/project-history-view.component';
import { LoginComponent } from './components/login/login.component';
import { BudgetManagementContainerComponent } from './components/budget-management-container/budget-management-container.component';
import { SubcontractGeneralInfoPanelComponent } from './components/subcontract-general-info-panel/subcontract-general-info-panel.component';
import { FeaturesModule as FlynnBudgetToolModule } from '../../flynn-budget-tool/features/features.module';
import { VideoCardComponent } from './components/cards/video-card/video-card.component';
import { VideoViewerComponent } from './components/video-viewer/video-viewer.component';
import { SwiperModule } from 'swiper/angular';
import { PhoneMaskDirective } from 'src/app/shared/directives/mask/phone-mask.directive';
import { PhotoReportNotificationComponent } from './components/photo-report-notification/photo-report-notification.component';

@NgModule({
    declarations: [
        CustomerCreateComponent,
        CustomerCreateManagementComponent,
        CustomerSelectComponent,
        CustomerSelectionConfirmComponent,
        CustomerSiteSelectComponent,
        CustomerSiteSuggestionsComponent,
        CustomerSelectManagementComponent,
        CustomerGeoCoordinateMapComponent,
        CustomerSiteCreateComponent,
        CustomerManagementComponent,
        DotsLoaderComponent,
        AboutDialogComponent,
        ActionProgressDialogComponent,
        NamePromptDialogComponent,
        ConfirmDialogComponent,
        QuoteReportOptionsDialogComponent,
        QuoteCopyOptionsDialogComponent,
        KpiRolePipe,
        ExpandableButtonComponent,
        DropdownButtonComponent,
        GridDisplayItemComponent,
        GridDatepickerComponent,
        GridDropdownItem,
        GridProDropdownItem,
        GridItemActionsComponent,
        RatingStarsComponent,
        ExpandableTextareaCardComponent,
        DialogActionCardComponent,
        InformationGridCardComponent,
        ProjectAttributesCardComponent,
        ProjectQuestionsCardComponent,
        ProjectUSQuestionsCardComponent,
        CustomerDetailsCardComponent,
        UserAvatarCardComponent,
        UserAvatarsCardComponent,
        TimelineEventCardComponent,
        TimelineCardComponent,
        QuoteSummaryCardComponent,
        ProjectSummaryCardComponent,
        SummaryListComponent,
        SummaryListFilterComponent,
        FilterPopupPanelComponent,
        FullAddressPipe,
        SplitCamelCasePipe,
        PercentageStringPipe,
        TextWithPlaceholderPipe,
        PhoneDisplayFormatPipe,
        PromptDialogComponent,
        PhotoReportViewerComponent,
        PhotoCardComponent,
        VideoCardComponent,
        VideoViewerComponent,
        PhotoCarouselComponent,
        EditPhotoComponent,
        AuthenticationErrorComponent,
        PageNotFoundComponent,
        ContentNotFoundComponent,
        DeletedPhotoDialogComponent,
        PhotoReportOptionsDialogComponent,
        PdfFileViewerDialogComponent,
        UploadImageDialogComponent,
        PhotoEditorDialogComponent,
        DrawingPanelComponent,
        EditDrawingComponent,
        FullViewPhotoDialogComponent,
        QuoteEditorComponent,
        QuoteContentEditorComponent,
        QuoteEditorNotesComponent,
        QuoteInformationPanelComponent,
        AddFollowUpReminderComponent,
        AddFollowUpLogComponent,
        QuoteAttachmentsComponent,
        BudgetManagementContainerComponent,
        ActionProgressReporterComponent,
        BudgetUploadComponent,
        BranchLocalTimePipe,
        JobTypeTextPipe,
        AttachmentTypePipe,
        CustomerIdPlaceholderPipe,
        QuoteFollowupLogsComponent,
        AddFollowUpLogDialogComponent,
        CrewSelectorComponent,
        UserCommentCardComponent,
        UserCommentEditorComponent,
        UserCommentsPanelComponent,
        ProjectUSTaxQuestionsCardComponent,
        SelectSiteDialogComponent,
        TemplateEditItemCardComponent,
        TemplateDisplayItemCardComponent,
        TemplateContentCardComponent,
        TemplateContentCollectionCardComponent,
        UserMenuComponent,
        XlsxFilePreviewComponent,
        DashboardViewDialogComponent,
        BudgetTemplatesDialogComponent,
        WorkOrderStepComponent,
        AttachmentStepComponent,
        ConditionReportOptionsDialogComponent,
        ProjectReportContentComponent,
        ProjectGeneralInfoPanelComponent,
        SubcontractGeneralInfoPanelComponent,
        CustomIconComponent,
        HealthScreeningRequirementCardComponent,
        LeakReportCardComponent,
        GridPageResizeDirective,
        BudgetPreviewComponent,
        SimplePhotoCardComponent,
        ImageOverlayDirective,
        WorkOrderEditDialogComponent,
        QuotePdfPreviewComponent,
        IntegrationRequestsDialogComponent,
        SubscriptionEditorComponent,
        CustomReportPrintDialogComponent,
        SelectPhotoDialogComponent,
        MaintenancePlanViewerComponent,
        UserProfileEditorDialogComponent,
        UserManagementComponent,
        QuotesHistoryComponent,
        ProjectHistoryViewComponent,
        LoginComponent,
        PhoneMaskDirective,
        PhotoReportNotificationComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        DateInputsModule,
        PopupModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ClipboardModule,
        OverlayModule,
        PortalModule,
        NgxFileDropModule,
        MatFormFieldModule,
        MatListModule,
        MatRadioModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatStepperModule,
        MatCardModule,
        MatDialogModule,
        MatChipsModule,
        MatGridListModule,
        MatSelectModule,
        MatSnackBarModule,
        MatDividerModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        GoogleMapsModule,
        GridModule,
        ExcelModule,
        MatRippleModule,
        MatMenuModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatExpansionModule,
        FullCalendarModule,
        MatAutocompleteModule,
        MatTableModule,
        PdfViewerModule,
        MatListModule,
        ImageCropperModule,
        MatSlideToggleModule,
        LazyLoadImageModule,
        MatButtonToggleModule,
        MatBadgeModule,
        MatPaginatorModule,
        DateInputsModule,
        ScrollingModule,
        NgxMaskDirective,
        NgxMaskPipe,
        TranslateModule,
        InputsModule,
        ButtonsModule,
        DropDownsModule,
        MatProgressSpinnerModule,
        FlynnBudgetToolModule,
        SwiperModule
    ],
    exports: [
        DotsLoaderComponent,
        CustomerManagementComponent,
        CustomerSiteSelectComponent,
        CustomerSiteSuggestionsComponent,
        CommonModule,
        CrewSelectorComponent,
        DateInputsModule,
        PopupModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ClipboardModule,
        OverlayModule,
        PortalModule,
        NgxFileDropModule,
        MatFormFieldModule,
        MatListModule,
        MatRadioModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatTableModule,
        MatCardModule,
        MatDialogModule,
        MatGridListModule,
        MatSelectModule,
        MatSnackBarModule,
        MatDividerModule,
        MatTabsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatChipsModule,
        GoogleMapsModule,
        GridModule,
        ExcelModule,
        KpiRolePipe,
        MatRippleModule,
        MatMenuModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatExpansionModule,
        PdfViewerModule,
        MatListModule,
        ImageCropperModule,
        MatSlideToggleModule,
        LazyLoadImageModule,
        InputsModule,
        ButtonsModule,
        DropDownsModule,
        AboutDialogComponent,
        ActionProgressDialogComponent,
        NamePromptDialogComponent,
        ConfirmDialogComponent,
        ExpandableButtonComponent,
        DropdownButtonComponent,
        GridDisplayItemComponent,
        GridDatepickerComponent,
        GridDropdownItem,
        GridProDropdownItem,
        GridItemActionsComponent,
        CustomerDetailsCardComponent,
        UserAvatarCardComponent,
        UserAvatarsCardComponent,
        TimelineEventCardComponent,
        TimelineCardComponent,
        RatingStarsComponent,
        ExpandableTextareaCardComponent,
        DialogActionCardComponent,
        InformationGridCardComponent,
        FullAddressPipe,
        SplitCamelCasePipe,
        PercentageStringPipe,
        TextWithPlaceholderPipe,
        PhoneDisplayFormatPipe,
        ProjectAttributesCardComponent,
        ProjectQuestionsCardComponent,
        ProjectUSQuestionsCardComponent,
        QuoteSummaryCardComponent,
        ProjectSummaryCardComponent,
        SummaryListComponent,
        SummaryListFilterComponent,
        FilterPopupPanelComponent,
        FullCalendarModule,
        PromptDialogComponent,
        MatAutocompleteModule,
        PhotoReportViewerComponent,
        PhotoCardComponent,
        VideoViewerComponent,
        VideoCardComponent,
        PhotoCarouselComponent,
        EditPhotoComponent,
        AuthenticationErrorComponent,
        PageNotFoundComponent,
        ContentNotFoundComponent,
        DeletedPhotoDialogComponent,
        PhotoReportOptionsDialogComponent,
        PdfFileViewerDialogComponent,
        UploadImageDialogComponent,
        PhotoEditorDialogComponent,
        MatButtonToggleModule,
        DrawingPanelComponent,
        EditDrawingComponent,
        FullViewPhotoDialogComponent,
        MatBadgeModule,
        QuoteEditorComponent,
        QuoteContentEditorComponent,
        QuoteEditorNotesComponent,
        QuoteInformationPanelComponent,
        AddFollowUpReminderComponent,
        MatPaginatorModule,
        AddFollowUpLogComponent,
        DateInputsModule,
        QuoteAttachmentsComponent,
        ActionProgressReporterComponent,
        BudgetUploadComponent,
        BranchLocalTimePipe,
        ScrollingModule,
        JobTypeTextPipe,
        AttachmentTypePipe,
        CustomerIdPlaceholderPipe,
        QuoteFollowupLogsComponent,
        AddFollowUpLogDialogComponent,
        CrewSelectorComponent,
        UserCommentCardComponent,
        UserCommentEditorComponent,
        UserCommentsPanelComponent,
        ProjectUSTaxQuestionsCardComponent,
        TemplateEditItemCardComponent,
        TemplateDisplayItemCardComponent,
        TemplateContentCardComponent,
        TemplateContentCollectionCardComponent,
        UserMenuComponent,
        XlsxFilePreviewComponent,
        DashboardViewDialogComponent,
        BudgetTemplatesDialogComponent,
        WorkOrderStepComponent,
        AttachmentStepComponent,
        ConditionReportOptionsDialogComponent,
        ProjectReportContentComponent,
        ProjectGeneralInfoPanelComponent,
        SubcontractGeneralInfoPanelComponent,
        CustomIconComponent,
        NgxMaskDirective,
        NgxMaskPipe,
        HealthScreeningRequirementCardComponent,
        LeakReportCardComponent,
        GridPageResizeDirective,
        BudgetPreviewComponent,
        SimplePhotoCardComponent,
        ImageOverlayDirective,
        QuotePdfPreviewComponent,
        TranslateModule,
        IntegrationRequestsDialogComponent,
        SubscriptionEditorComponent,
        MaintenancePlanViewerComponent,
        UserManagementComponent,
        QuotesHistoryComponent,
        ProjectHistoryViewComponent,
        LoginComponent,
        MatProgressSpinnerModule,
        FlynnBudgetToolModule,
        SwiperModule,
        PhoneMaskDirective
    ],
    providers: [
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 20000,
                panelClass: 'mat-snack-bar-custom-panel'
            }
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' }
        },
        {
            provide: MAT_DATE_LOCALE, useValue: 'en-CA'
        },
        PhoneDisplayFormatPipe,
        PercentageStringPipe,
        CurrencyPipe,
        TitleCasePipe,
        JobTypeTextPipe,
        AttachmentTypePipe,
        BranchLocalTimePipe,
        CustomerIdPlaceholderPipe,
        CustomerDisplayFormattingService,
        QuoteEditorService,
        NgxMaskPipe,
        provideNgxMask()
    ]
})
export class SharedModule { }
