import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'material-chemicals-management',
    styleUrls: ['./material-chemicals-management.component.scss'],
    templateUrl: './material-chemicals-management.component.html'
})
export class MaterialChemicalsManagementComponent {
    constructor(public translate: TranslateService) {}
}
