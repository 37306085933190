import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LeakReportType } from '../../../enums/leak-report-type.enum';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LeakReportTemplateHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/leakreport`;

    constructor(private _httpClient: HttpClient) { }

    public async getTemplate(type: LeakReportType): Promise<string> {
        const url = `${this._api}/${this.getEndpoint(type)}`;

        try {
            const data = await this._httpClient.get<string>(url).toPromise();
            return data;
        }
        catch {
            return null;
        }
    }

    public async updateTemplate(type: LeakReportType, template: string): Promise<boolean> {
        const url = `${this._api}/${this.getEndpoint(type)}/save`;

        try {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            const success = await this._httpClient.post<boolean>(url, JSON.stringify(template), { headers }).toPromise();
            return success;
        }
        catch {
            return false;
        }
    }

    private getEndpoint(type: LeakReportType): string {
        switch (type) {
            case LeakReportType.TemporaryLeak:
                return 'template';
            case LeakReportType.PermanentLeak:
                return 'perm/template';
            case LeakReportType.NotRoofIssueLeak:
                return 'notroof/template';
            case LeakReportType.MultiLeak:
                return 'multi/template';
            default:
                return 'template';
        }
    }
}
