<div class="top-toolbar-container" [ngStyle]="{ 'background-color': toolbarColor }" fxLayout="row" fxLayoutAlign="space-between center">
    <a class="home-link" (click)="toHomeState()">
        <div fxLayout="row" fxLayoutAlign="start center">
            <img *ngIf="logoUrl" class="logo" src="{{logoUrl}}" aria-hidden="true" />
            <h1 class="title">{{ siteName }}</h1>
        </div>
    </a>

    <div *ngIf="isDynamicsOnHold"
        class="dynamics-hold-warning"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="0.75vh">

        <mat-icon>warning</mat-icon>
        <span>{{ 'navbar.dynamicsHoldWarning' | translate }}</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <button class="help-button"
            [ngClass]="{ 'help-button-active': showHelpWidget }"
            mat-mini-fab
            (click)="onHelp()"
            aria-label="help">
            ?
        </button>
        <app-user-menu></app-user-menu>
    </div>
</div>
