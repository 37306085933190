<div class="task-code-settings-container" fxLayout="column" fxLayoutAlign="center center">
    <label>{{ 'admin.taskCodeSettings.title' | translate }}</label>

    <div class="mappings" fxLayout="column" fxLayoutGap="0.25%">
        <div *ngFor="let salesCode of salesCodes" fxLayout="row" fxLayoutAlign="space-between center">
            <span>{{ salesCode.code }} - {{ getSalesCodeName(salesCode) }}</span>

            <select *ngIf="taskCodes.length"
                [(ngModel)]="salesCode.taskCode"
                (change)="onUpdate(salesCode, $event.target.value)">

                <option *ngFor="let taskCode of taskCodes" [value]="taskCode.code">
                    {{ taskCode.code }} - {{ taskCode.description }}
                </option>
            </select>
        </div>
    </div>

    <span>* {{ 'admin.taskCodeSettings.hint' | translate }}</span>
</div>
