<div class="timeline-event-card-container" fxLayout="column" fxLayoutGap="4px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px">
        <span class="timeline-event-card-performer"
            [style.color]="event.backgroundColor"
            [matTooltip]="tooltip"
            matTooltipPosition="above"
            (click)="filterByPerformer.emit(event.performer)">

            {{ performer }}
        </span>

        <span *ngIf="date">{{ dateString }} </span>
        <span *ngIf="date && useFullDateTime">@{{ date | date: 'h:mm a': 'UTC' }}</span>
    </div>

    <div [innerHTML]="event.action"></div>
</div>
