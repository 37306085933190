import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import CodeDescription from '../../../core/data-models/code-description';
import WorkOrderTemplate from '../../../core/data-models/work-order/work-order-template';
import { WorkOrderTemplateHttpService } from '../../../core/services/http/work-order-template-http/work-order-template-http.service';
import { EditorUtilityService } from '../../../core/services/utility/editor-utility/editor-utility.service';
import { ActionProgressDialogComponent } from '../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';

@Component({
    selector: 'app-work-order-template',
    styleUrls: ['./work-order-template.component.scss'],
    templateUrl: './work-order-template.component.html',
    encapsulation: ViewEncapsulation.None
})
export class WorkOrderTemplateComponent implements OnInit {
    public templates: WorkOrderTemplate[] = [];
    public currentTemplate: WorkOrderTemplate;
    private _isLoading = true;
    private _editorId = `work-order-template-editor-${Date.now()}`;
    private _isOldTemplate = false;
    private _isSaveDisabled = false;

    get isLoading(): boolean {
        return this._isLoading;
    }

    get isSaveDisabled(): boolean {
        return this._isSaveDisabled;
    }

    get isDeleteDisabled(): boolean {
        return !this._isOldTemplate;
    }

    get editorId(): string {
        return this._editorId;
    }

    get isOldTemplate(): boolean {
        return this._isOldTemplate;
    }

    public constructor(private _workOrderTemplateService: WorkOrderTemplateHttpService,
        private _editorUtility: EditorUtilityService,
        private _dialogService: MatDialog,
        private _translate: TranslateService) { }

    public async ngOnInit(): Promise<void> {
        await this.loadTemplates(true);
        this._isLoading = false;
        setTimeout(() => {
            const builtinOptions = {
                change: () => {
                    this.currentTemplate.content = this._editorUtility.getContent(this._editorId);
                }
            };

            this._editorUtility.setupEditor(this._editorId, builtinOptions);
        });
    }

    public async saveTemplate(): Promise<void> {
        let action: Promise<boolean | CodeDescription>;
        let error = this._translate.instant('admin.workOrderTemplate.saveError');
        const ongoing = this._translate.instant('admin.workOrderTemplate.saveOngoing');
        const success = this._translate.instant('admin.workOrderTemplate.saveSuccess');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const validationResult = this.validateTemplate();

        if (validationResult) {
            action = of(false).toPromise();
            error = validationResult;
        }
        else {
            action = this._isOldTemplate?
                this._workOrderTemplateService.updateWorkOrderTemplate(this.currentTemplate) :
                this._workOrderTemplateService.addWorkOrderTemplate(this.currentTemplate);
        }

        this._isSaveDisabled = true;
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise() && !this._isOldTemplate) {
            const templateToLoad = this.currentTemplate.title.toLowerCase().trim();
            await this.loadTemplates(false, templateToLoad);
        }

        this._isSaveDisabled = false;
    }

    public loadTemplate(event: MatSelectChange): void {
        this._isOldTemplate = true;
        this.currentTemplate = event.value;
        this._editorUtility.setContent(this._editorId, event.value.content);
    }

    public async deleteTemplate(): Promise<void> {
        this._isSaveDisabled = true;
        const action = this._workOrderTemplateService.deleteWorkOrderTemplate(this.currentTemplate.id);
        const ongoing = this._translate.instant('admin.workOrderTemplate.deleteOngoing');
        const success = this._translate.instant('admin.workOrderTemplate.deleteSuccess');
        const error = this._translate.instant('admin.workOrderTemplate.deleteError');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });
        if (await dialog.afterClosed().toPromise()) {
            await this.loadTemplates(false);
        }

        this._isSaveDisabled = false;
    }

    public clearTemplate(): void {
        this._isOldTemplate = false;
        this.currentTemplate = new WorkOrderTemplate();
        this._editorUtility.setContent(this._editorId, '');
    }

    private async loadTemplates(skipSettingContent: boolean, templateToLoad = ''): Promise<void> {
        this.templates = await this._workOrderTemplateService.getTemplates();
        this.currentTemplate = this.templates.find(_ => _.title.toLowerCase() === templateToLoad) ?? new WorkOrderTemplate();
        this._isOldTemplate = Boolean(this.currentTemplate.id);

        if (!skipSettingContent) {
            this._editorUtility.setContent(this._editorId, this.currentTemplate.content);
        }
    }

    private validateTemplate(): string {
        if (this._isOldTemplate) {
            return null;
        }

        if (!this.currentTemplate.title?.trim()) {
            return this._translate.instant('admin.workOrderTemplate.missingTemplateName');
        }

        if (this.templates.some(_ => _.title.toLowerCase().trim() === this.currentTemplate.title.toLowerCase().trim())) {
            return this._translate.instant('admin.workOrderTemplate.duplicateTemplateName');
        }

        return null;
    }
}
