import InputField from './input-field';
import ImageLayer from './image-layer';

import LanguageSet from '../language-set';

export default class ImageItem {
    public imageType: string;
    public imageUri: string;
    public imageDisplayIndex: number;
    public contentValue: string;
    public imageOrderLabel: number;
    public title: string;
    public mediaUri: string;
    public jobIndex: number;
    public fields: InputField[] = [];
    public internalImage: boolean;
    public tagSectionPage: string;
    public tagQuestion: string;
    public customerWorkOrder: boolean;
    public baseImageUri: string;
    public imageLayers: ImageLayer[] = [];
    public subCategoryField: string;
    public modifiedDate: string;
    public createdDate: string;
    public createdTime: string;
    public createdDateUTC: number;
    public index: string;
    public isNewDrawingFormat: boolean;
    public isWebUpload: boolean;
    public languageSets: LanguageSet[] = [];
}
