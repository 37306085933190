<div *ngIf="suggestedAddresses.length > 0" class="customer-site-suggerstion-container" fxLayout="column" fxLayoutAlign="center start" fxFill>
    <div fxLayout="row">
        <span>{{ 'shared.customerSiteSelect.suggestedSites' | translate }}&nbsp;</span>
        <b>{{requestedAddress | fullAddress}}</b>
    </div>

    <div class="suggestions" fxLayout="row" fxLayoutAlign="start" fxFill fxLayoutGap="15px">
        <mat-card *ngFor="let addr of suggestedAddresses" class="suggestion" (click)="siteSelected.emit(addr)">
            <div class="suggestion-title" fxLayout="column">
                <span>{{addr.name}}</span>
                <span>{{ 'shared.customerSiteSelect.table.shipToId' | translate }}:&nbsp;{{addr.shipToId}}</span>
            </div>
            <mat-card-content fxLayout="column">
                <span>{{addr.address1}}&nbsp;{{addr.address2}}</span>
                <div fxLayout="row" fxLayoutGap="5px">
                    {{addr.city}}
                    {{addr.state}}
                    {{addr.country}}
                </div>
                <span>{{addr.zip}}</span>
                <span>{{ 'shared.customerSiteSelect.table.store' | translate }}:&nbsp;{{addr.storeNumber}}</span>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div *ngIf="!suggestedAddresses.length" fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutGap="7px">
        <span>{{ 'shared.customerSiteSelect.requestedSite' | translate }}</span>
        <b>{{requestedAddress | fullAddress}}</b>
    </div>
    <div fxLayout="column" fxFill fxLayoutAlign="center center">
        <label>{{ 'shared.customerSiteSelect.noSuggestedSites' | translate }}</label>
    </div>
</div>
