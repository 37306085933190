import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import Employee from '../../../../core/data-models/employee';
import UserInfo from '../../../../core/data-models/user-info';
import Branch from '../../../../core/data-models/branch';
import ProjectMergeRule from '../../../../core/data-models/project-merge-rule';
import LanguageUtility from '../../../../core/services/utility/language-utility/language-utility.service';

@Component({
    selector: 'merge-rule-list-viewer',
    styleUrls: ['./merge-rule-list-viewer.component.scss'],
    templateUrl: './merge-rule-list-viewer.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MergeRuleListViewerComponent {
    @Input() public user: UserInfo;
    @Input() public branches: Branch[] = [];
    @Input() public rules: ProjectMergeRule[] = [];
    @Output() public addRule = new EventEmitter();
    @Output() public openRule = new EventEmitter<ProjectMergeRule>();
    @Output() public deleteRule = new EventEmitter<ProjectMergeRule>();
    public readonly visibleColumns = ['name', 'maintainers', 'summary', 'lastModified'];

    public getMaintainers(rule: ProjectMergeRule): Employee[] {
        const { creator, changes } = rule;
        const seen = new Set<string>([creator.employeeId]);
        const maintainers = [creator];

        for (const { performer } of changes.slice().reverse()) {
            if (!seen.has(performer.employeeId)) {
                maintainers.push(performer);
            }

            seen.add(performer.employeeId);
        }

        return maintainers;
    }

    public getRuleName(rule: ProjectMergeRule): string {
        return LanguageUtility.getLocalizedContent(this.user.language, rule.names);
    }
}
