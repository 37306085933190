import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import JobRedispatchData from '../../../data-models/re-dispatch/job-redispatch-data';
import DispatchInfoDto from '../../../data-models/re-dispatch/dispatch-info-dto';
import DynamicsDispatchInfo from '../../../data-models/dispatch/dynamics-dispatch-info';
import JsonUtility from '../../utility/json-utility.service';
import DispatchData from '../../../data-models/dispatch/dispatch-data';
import CodeDescription from '../../../data-models/code-description';
import { NewRelicUtilityService } from '../../utility/new-relic-utility.service';
import { environment } from '../../../../../environments/environment';
import { WorkCategory } from '../../../data-models/work-category';

@Injectable({
    providedIn: 'root'
})
export class DispatchHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/dispatch`;

    constructor(private _translate: TranslateService,
        private _http: HttpClient,
        private _newrelicService: NewRelicUtilityService) { }

    public async getDynamicsDispatchInfo(workCategory: WorkCategory): Promise<DynamicsDispatchInfo> {
        try {
            const endpoint = `${this._api}/dynamics/info?workCategory=${workCategory}`;
            const data = await this._http.get<DynamicsDispatchInfo>(endpoint).toPromise();

            const dynamicsDispatchInfo = JsonUtility.toCamelCaseKeys(data);

            return JsonUtility.cloneObject(dynamicsDispatchInfo);
        }
        catch {
            return new DynamicsDispatchInfo();
        }
    }

    public async createNewProject(data: DispatchData, files: Map<string, File[]>): Promise<CodeDescription> {
        const endpoint = `${this._api}/project/create/new`;
        return await this.createProjectInternal<DispatchData>(endpoint, data, files);
    }

    public async convertProject(data: DispatchData, files: Map<string, File[]>): Promise<CodeDescription> {
        const endpoint = `${this._api}/project/convert`;
        return await this.createProjectInternal<DispatchData>(endpoint, data, files);
    }

    private async createProjectInternal<T>(
        endpoint: string,
        data: T,
        files: Map<string, File[]>
    ): Promise<CodeDescription> {
        try {
            const formData = new FormData();
            formData.append('dispatchData', JSON.stringify(data));

            for (const key of Array.from(files.keys())) {
                const value = files.get(key);
                value.forEach(_ => formData.append(key, _, _.name));
            }

            await this._http.post(endpoint, formData, { responseType: 'blob' }).toPromise();

            const okLabel = this._translate.instant('core.generic.ok');
            return new CodeDescription('200', okLabel);
        }
        catch (error) {
            await this._newrelicService.addErrorEvent(error);
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.dispatch.internalErrorMessage');
            const isUnknownError = httpError.statusText === 'Unknown Error';
            const invalidProjectInfo = this._translate.instant('core.httpServices.dispatch.invalidProjectInfo');
            const invalidPermission = this._translate.instant('core.httpServices.dispatch.invalidPermission');

            switch (httpError.status) {
                case 406: return new CodeDescription('406', invalidProjectInfo);
                case 403: return new CodeDescription('403', invalidPermission);
                case 400: return new CodeDescription('400', isUnknownError ? internalErrorMessage : await httpError.error.text());
                default: return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async redispatchReport(job: JobRedispatchData, redispatchType: string): Promise<boolean> {
        const endpoint = `${this._api}/job/${redispatchType}`;
        try {
            await this._http.post(endpoint, job).toPromise();
            return true;
        }
        catch {
            return false;
        }
    }

    public async dispatchNewReport(newReportDto: DispatchInfoDto, files: Map<string, File[]>): Promise<CodeDescription> {
        const endpoint = `${this._api}/new/report`;
        return await this.createProjectInternal<DispatchInfoDto>(endpoint, newReportDto, files);
    }

}
