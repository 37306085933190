<div class="confirm-dialog-container">
    <h2 class="confirm-dialog-title" mat-dialog-title>{{ option.title }}</h2>

    <div mat-dialog-content fxLayout="column" fxLayoutGap="2px">
        <span *ngIf="option.warningMessage" class="confirm-dialog-warning-message">{{ option.warningMessage }}</span>
        <span class="confirm-dialog-message" *ngIf="option?.htmlMessage?.length === 0">{{ option.message }}</span>
        <div class="confirm-dialog-message" [innerHTML]="option.htmlMessage" *ngIf="option?.htmlMessage?.length > 0"></div>
    </div>

    <div class="confirm-dialog-actions" mat-dialog-actions fxLayoutAlign="end center">
        <button *ngIf="option.cancelText" mat-button [mat-dialog-close]="false">
            {{ option.cancelText }}
        </button>

        <button *ngIf="option.confirmText" mat-button [color]="colorType" [mat-dialog-close]="true" cdkFocusInitial>
            {{ option.confirmText }}
        </button>
    </div>
</div>
