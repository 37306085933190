<div class="invoice-upload-component-container" fxLayout="column" fxLayoutGap="15px">

    <div *ngIf="hasInvoice" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">
        <mat-form-field>
            <mat-label>{{ 'projects.processProjectDialog.invoiceNumber' | translate }}</mat-label>
            <input type="text" matInput cdkFocusInitial [formControl]="invoiceNumber" required>
            <mat-error *ngIf="invoiceNumber.errors?.required">* {{ 'projects.processProjectDialog.validationMessageRequiredField' | translate }}</mat-error>
            <mat-error *ngIf="invoiceNumber.errors?.pattern">* {{ 'projects.processProjectDialog.validationMessageNumeric' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'projects.processProjectDialog.draftNumber' | translate }}</mat-label>
            <input type="text" matInput [formControl]="draftNumber">
            <mat-error *ngIf="draftNumber.errors?.required">* {{ 'projects.processProjectDialog.validationMessageRequiredField' | translate }}</mat-error>
            <mat-error *ngIf="!grossAmount && draftNumber.errors?.pattern">* {{ 'projects.processProjectDialog.validationMessageDraftNumberRequired' | translate }}</mat-error>
            <mat-error *ngIf="grossAmount && draftNumber.errors?.pattern">* {{ 'projects.processProjectDialog.validationMessageAlphaNumeric' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="hasInvoice" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px">
        <mat-form-field *ngIf="grossAmount">
            <mat-label>{{ 'projects.processProjectDialog.grossAmount' | translate }}</mat-label>
            <input type="text" mask="0*.00" [dropSpecialCharacters]="false" matInput [formControl]="grossAmount"  (change)="onGrossAmountChange()" required>
            <mat-error *ngIf="grossAmount?.errors?.required">* {{ 'projects.processProjectDialog.validationMessageRequiredField' | translate }}</mat-error>
            <mat-error *ngIf="grossAmount?.errors?.pattern">* {{ 'projects.processProjectDialog.validationMessageGrossAmount' | translate }}</mat-error>
        </mat-form-field>
    </div>

    <ngx-file-drop *ngIf="enableUpload" dropZoneClassName="upload-file-drop-zone" accept=".pdf"
        (onFileDrop)="onInvoiceFileDrop($event)">

        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div class="upload-file-drop-content" fxLayout="column"
                fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                    <mat-icon>get_app</mat-icon>
                    <span>{{ 'projects.processProjectDialog.dropInvoice' | translate }}</span>
                </div>

                <div class="upload-file-upload-button" fxLayout="row" fxLayoutAlign="start center"
                    fxLayoutGap="10px">
                    <button mat-stroked-button color="primary" (click)="openFileSelector()">
                        {{ 'projects.processProjectDialog.browse' | translate }}
                    </button>

                    <span [ngClass]="{ 'upload-file-no-file': !invoiceFile }">{{ fileName }}</span>
                </div>
            </div>
        </ng-template>
    </ngx-file-drop>
</div>
