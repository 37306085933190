<div class="task-selection-dialog-container"
    fxLayout="row wrap"
    fxLayoutAlign="space-around center"
    (click)="onClose(null)">

    <div *ngFor="let task of tasks" fxLayout="column" fxLayoutAlign="end" (click)="openTask($event, task.taskName)">
        <div class="task-content" fxLayout="column" fxLayoutAlign="end">
            <div class="task-selection-dialog-description" fxLayoutAlign="center center">
                <span>{{ task.description }}</span>
            </div>

            <div class="task-selection-dialog-icon" fxLayoutAlign="center center" fxFlexAlign="center">
                <mat-icon>{{ task.icon }}</mat-icon>
            </div>

            <div fxLayoutAlign="center center">
                <span>{{ task.actionName }}</span>
            </div>
        </div>
    </div>
</div>
