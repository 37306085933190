import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';

@Component({
    selector: 'app-quote-drawings',
    styleUrls: ['./quote-drawings.component.scss'],
    templateUrl: './quote-drawings.component.html'
})
export class QuoteDrawingsComponent implements OnInit {
    @Input() public activeQuoteId: string;
    public originProjectId: string;

    public constructor(private _quoteService: QuoteHttpService,
        private _state: StateService) {
    }

    public async ngOnInit(): Promise<void> {
        const generalInfo = await this._quoteService.getQuoteGeneralInfo(this.activeQuoteId);
        this.originProjectId = generalInfo?.basicInfo.originProjectId;

        if (!ProjectIdUtility.isEmergencyQuote(this.originProjectId)) {
            this._state.go('quotes.notFound');
        }
    }

}
