<dialog-action-card class="chemical-edit-container"
    [icon]="'create'"
    [title]="translate.instant('dataReport.chemicalsReport.createChemical')"
    [isLoaded]="isLoaded"
    (close)="onClose()">

    <div *ngIf="formGroup" [formGroup]="formGroup">
        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field>
                    <mat-label>{{ 'shared.name' | translate }}</mat-label>
                    <input type="text" matInput cdkFocusInitial formControlName="name" />

                    <mat-error *ngIf="nameControl.invalid && nameControl.errors.required">
                        * {{ 'shared.emptyNameErrorText' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field>
                    <mat-label>{{ 'dataReport.chemicalsReport.phone' | translate}}</mat-label>
                    <mat-chip-list #phoneList aria-label="Phones" formControlName="phone">
                      <mat-chip *ngFor="let phone of chemical.phone"  (removed)="removePhone(phone)">
                        {{phone | phoneDisplayFormat}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                      <input formControlName="phoneItem"
                             [value]="phoneItemControl.value | phoneDisplayFormat"
                             placeholder="{{ 'dataReport.chemicalsReport.newPhone' | translate }}"
                             [matChipInputFor]="phoneList"
                             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                             [matChipInputAddOnBlur]="'true'"
                             (matChipInputTokenEnd)="addPhone($event)">
                    </mat-chip-list>
                    <mat-error *ngIf="phoneControl.invalid">
                        * {{ 'dataReport.chemicalsReport.invalidPhoneNumber' | translate }}
                    </mat-error>
                  </mat-form-field>
            </div>
        </div>

        <div class="chemical-edit-actions" fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button color="primary" (click)="onSave()" [disabled]="saveDisabled">{{ 'shared.save' | translate }}</button>
        </div>
    </div>
</dialog-action-card>
