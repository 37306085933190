import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnChanges } from '@angular/core';
import ImageItem from '../../../../core/data-models/job-template-items/image-item';
import SwiperCore, { Navigation } from 'swiper';

SwiperCore.use([Navigation]);

@Component({
    selector: 'app-photo-carousel',
    styleUrls: ['./photo-carousel.component.scss'],
    templateUrl: './photo-carousel.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PhotoCarouselComponent implements OnChanges {
    public carouselImages = [];
    public width = '100%';
    public height = '268px';
    @Input() public images: ImageItem[];
    @Output() public imageSelected = new EventEmitter<ImageItem>();

    ngOnChanges() {
        this.carouselImages = this.images.map(_ => ({
            image: _.baseImageUri,
            thumbImage: _.baseImageUri,
            title: _.title
        }));
    }
}
