<div class="role-editor-container" fxLayout="column" fxLayoutGap="15px">
    <div class="role-editor-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <button mat-icon-button color="primary" (click)="onClose()">
            <mat-icon>arrow_back_ios_new</mat-icon>
        </button>

        <div class="role-name" fxLayout="row" fxLayoutAlign="center center">
            <span>{{ role.name }}</span>
        </div>
    </div>

    <div class="role-editor-actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
        <mat-form-field class="role-editor-merge-rule-selection" appearance="outline">
            <mat-label>{{ 'admin.rolesAndPermissions.roleEditor.mergeRuleSelectionText' | translate }}</mat-label>

            <mat-select [(ngModel)]="role.projectMergeRuleCode"
                [disabled]="role.isBuiltIn"
                (selectionChange)="onMergeRuleUpdate($event.value)">

                <mat-option [value]="-1">{{ 'admin.rolesAndPermissions.roleEditor.noMergeRule' | translate }}</mat-option>
                <mat-option *ngFor="let rule of mergeRules" [value]="rule.code">{{ getMergeRuleName(rule) }}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-stroked-button color="primary" (click)="isPermissionView = !isPermissionView">
            <span *ngIf="isPermissionView">
                {{ 'admin.rolesAndPermissions.roleEditor.subscriptionViewButtonText' | translate }}
            </span>

            <span *ngIf="!isPermissionView">
                {{ 'admin.rolesAndPermissions.roleEditor.permissionViewButtonText' | translate }}
            </span>
        </button>

        <button mat-raised-button
            color="primary"
            [disabled]="savingState.isSaving || savingState.isSaved"
            (click)="onSave()">

            {{ 'admin.rolesAndPermissions.roleEditor.saveButtonText' | translate }}
        </button>
    </div>

    <permission-editor *ngIf="isPermissionView"
        [language]="user.language"
        [isDisabled]="savingState.isSaving"
        [role]="role"
        [templates]="permissionTemplates"
        [parameterOptions]="permissionParameterOptions"
        (update)="onPermissionUpdate($event)">
    </permission-editor>

    <subscription-editor *ngIf="!isPermissionView"
        [isDisabled]="savingState.isSaving || role.isBuiltIn"
        [subscribed]="role.subscriptions"
        [templates]="subscriptionTemplates"
        (update)="onSubscriptionUpdate($event)">
    </subscription-editor>
</div>
