import CustomIcon from './custom-icon';

export default class TimelineEvent {
    public type: string;
    public action: string;
    public time: string;
    public performer: string;
    public icon: CustomIcon;
    public backgroundColor: string;
}
