<div class="photo-report-disclaimer-container"
    fxLayout="column"
    fxLayoutAlign="space-between center"
    fxLayoutGap="1.5vh">

    <div class="photo-report-disclaimer-content" [ngStyle]="{ opacity: isLoaded ? 1 : 0 }">
        <textarea [id]="editorId"></textarea>

        <div class="photo-report-disclaimer-toggles">
            <mat-button-toggle-group
                 [(ngModel)]="language"
                 (ngModelChange)="onSelected()">

                <mat-button-toggle *ngFor="let languageLabel of languages" [value]="languageLabel">
                    {{ languageLabel.toUpperCase() }}
                </mat-button-toggle>
            </mat-button-toggle-group>

            <mat-button-toggle-group
                [(ngModel)]="country"
                (ngModelChange)="onSelected()">

                <mat-button-toggle *ngFor="let countryName of countries" [value]="countryName">
                    {{ countryName }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

    </div>

    <button mat-raised-button
        color="primary"
        [disabled]="!canSaveDisclaimer"
        (click)="saveDisclaimer()"
        fxFlexAlign="end">

        {{ 'admin.disclaimerSettings.photoReportDisclaimer.saveDisclaimerButtonLabel' | translate }}
    </button>
</div>
