/* eslint-disable max-lines */
import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';

import LanguageSet from '../../../../core/data-models/language-set';
import { DeletedPhotoDialogComponent } from '../../dialogs/deleted-photo-dialog/deleted-photo-dialog.component';
import { ImageHttpService } from '../../../../core/services/http/image-http/image-http.service';
import ActionMenuItemOption from '../../../../core/data-models/action-menu-item-option';
import ImageItem from '../../../../core/data-models/job-template-items/image-item';
import ImageCardConfig from '../../../../core/data-models/card-items/image-card-config';
import ImageCardEvent from '../../../../core/data-models/card-items/image-card-event';
import ProjectIdUtility from '../../../../core/services/utility/project-id-utility/project-id-utility.service';
import { PhotoReportSort } from '../../../../core/enums/photo-report-sort.enum';
import { DownloadHttpService } from '../../../../core/services/http/download-http/download-http.service';
import { GenericUtilityService } from '../../../../core/services/utility/generic-utility/generic-utility.service';
import { PhotoReportAction } from '../../../../core/enums/photo-report-action.enum';
import { PhotoReportOptionsDialogComponent } from '../../dialogs/photo-report-options-dialog/photo-report-options-dialog.component';
import PhotoReportDialogData from '../../../../core/data-models/photo-report-dialog-data';
import { ProjectHttpService } from '../../../../core/services/http/project-http/project-http.service';
import { ReportHttpService } from '../../../../core/services/http/report-http/report-http.service';
import PhotoReportOptions from '../../../../core/data-models/photo-report-options';
import ClientFastData from '../../../../core/data-models/client-fast-data';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';
import { PdfFileViewerDialogComponent } from '../../dialogs/pdf-file-viewer-dialog/pdf-file-viewer-dialog.component';
import { UploadImageDialogComponent } from '../../dialogs/upload-image-dialog/upload-image-dialog.component';
import { UploadHttpService } from '../../../../core/services/http/upload-http/upload-http.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog-component/confirm-dialog.component';
import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';
import { AppEventService } from '../../../../core/services/events/app-event/app-event.service';
import { ImageCardAction } from '../../../../core/enums/image-card-action.enum';
import BranchTimezone from '../../../../core/data-models/branch-timezone';
import TimeUtility from '../../../../core/services/utility/time-utility/time-utility.service';
import PhotoReportImageCard from '../../../../core/data-models/card-items/photo-report-image-card';
import CardActionButton from '../../../../core/data-models/card-items/card-action-button';
import ActionProgressReporterOption from '../../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import CodeDescription from '../../../../core/data-models/code-description';
import { SalesCodeConfigService } from '../../../../core/services/sales-code-config/sales-code-config.service';
import { LanguageKey } from '../../../../core/enums/language-key.enum';
import { FullViewPhotoDialogComponent } from '../../dialogs/full-view-photo-dialog/full-view-photo-dialog.component';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-photo-report-viewer',
    styleUrls: ['./photo-report-viewer.component.scss'],
    templateUrl: './photo-report-viewer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PhotoReportViewerComponent implements OnInit, OnDestroy {
    @Input() public activeProjectId = '';
    @Input() public timeZone: BranchTimezone;
    @Output() public reloadProject = new EventEmitter();
    public editMode = false;
    public loading = true;
    public images: ImageItem[] = [];
    public imageCardConfig: ImageCardConfig;
    public imageForEdit: ImageItem;
    public isAudit: boolean;
    private photoReportsDialogRef: MatDialogRef<PdfFileViewerDialogComponent>;
    public actionButtons: ActionMenuItemOption[];
    public sortingButtons: ActionMenuItemOption[];
    public currentSort: PhotoReportSort;
    public groupedImages: {groupName: string, items: PhotoReportImageCard[]}[];
    public displayImages: PhotoReportImageCard[] = [];
    public picOrder = false;
    public pageSize = 25;
    public pageSizeOptions = [10, 25, 50, 75];
    public selectedImageIndex: string;
    public sortMode = false;
    public deletedImages: ImageItem[] = [];
    public selectedIndex = 0;
    private currentPage = 0;
    private currentPageSize = 25;
    private _noDeletedPhotosMessage = '';
    public disableActionBar = false;
    private _isComponentActive = true;
    private _subscriptions = new Subscription();

    private defaultImageActions: CardActionButton[] = [
        { text: ImageCardAction.Edit, isWarn: false, icon: '', displayText: this.translate.instant('photos.edit') },
        { text: ImageCardAction.Delete, isWarn: true, icon: '', displayText: this.translate.instant('photos.delete') }
    ];

    private sortImageActions: CardActionButton[] = [
        { text: ImageCardAction.Before, isWarn: false, icon: 'west', displayText: this.translate.instant('photos.before') },
        { text: ImageCardAction.Swap, isWarn: false, icon: 'swap_horiz', displayText: this.translate.instant('photos.swap') },
        { text: ImageCardAction.After, isWarn: false, icon: 'east', displayText: this.translate.instant('photos.after') }
    ];

    get endIndex(): number {
        return this.images.length - 1;
    }

    get groupedView(): boolean {
        return this.currentSort !== PhotoReportSort.DisplayIndex;
    }

    get showPicOrder(): boolean {
        return !this.editMode && this.currentSort === PhotoReportSort.Datetime;
    }

    get deletedPhotosToolTip(): string {
        return !this.deletedImages.length ? this._noDeletedPhotosMessage : '';
    }

    get imageTitles(): LanguageSet[][] {
        const images = this.images ?? [];

        return images.map(_ => _.languageSets.length ? _.languageSets : [{ languageId: LanguageKey.EN, name: _.title }]);
    }

    //eslint-disable-next-line
    constructor(public translate: TranslateService,
                private _imageHttpService: ImageHttpService,
                private _downloadService: DownloadHttpService,
                private _projectService: ProjectHttpService,
                private _reportService: ReportHttpService,
                private _userService: UserHttpService,
                private _uploadService: UploadHttpService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _appEvents: AppEventService,
                private _salesCodeConfig: SalesCodeConfigService,
                private _changeDetector: ChangeDetectorRef) { }

    public async ngOnInit() : Promise<void> {
        this._noDeletedPhotosMessage = this.translate.instant('shared.photoReportViewer.noDeletedPhotosMessage');
        this.initializeImageCardConfig();
        this._subscriptions.add(this._appEvents.attachmentUpdated.pipe(takeWhile(() => this._isComponentActive)).subscribe(_ => this.reloadPhotoReports()));
        this.isAudit = this._salesCodeConfig.isAudit(this.activeProjectId);
        const createReportLabel = this.translate.instant('photos.reportActions.createReport');
        const viewReportLabel = this.translate.instant('photos.reportActions.viewReport');
        const downloadAllLabel = this.translate.instant('photos.reportActions.downloadAll');

        this.actionButtons = [
            { ...new ActionMenuItemOption(PhotoReportAction.CreateReport, 'photo_album'), displayName: createReportLabel },
            { ...new ActionMenuItemOption(PhotoReportAction.ViewReport, 'preview'), displayName: viewReportLabel },
            { ...new ActionMenuItemOption(PhotoReportAction.DownloadAll, 'cloud_download'), displayName: downloadAllLabel }
        ];

        const displayIndexLabel = this.translate.instant('photos.sort.displayIndex');
        const typeLabel = this.translate.instant('photos.sort.type');
        const datetimeLabel = this.translate.instant('photos.sort.datetime');
        const leakLabel = this.translate.instant('photos.sort.leak');
        const editOrderLabel = this.translate.instant('photos.sort.editOrder');

        this.sortingButtons = [
            { ...new ActionMenuItemOption(PhotoReportSort.DisplayIndex, 'collections'), displayName: displayIndexLabel },
            { ...new ActionMenuItemOption(PhotoReportSort.Type, 'assignment'), displayName: typeLabel },
            { ...new ActionMenuItemOption(PhotoReportSort.Datetime, 'access_time'), displayName: datetimeLabel },
            { ...new ActionMenuItemOption(PhotoReportSort.Leak, 'water_damage'), displayName: leakLabel },
            { ...new ActionMenuItemOption(PhotoReportSort.EditOrder, 'flip_camera_android'), displayName: editOrderLabel }
        ];

        this.currentSort = PhotoReportSort.DisplayIndex;
        await this.getImagesFromService(true);
    }

    public trackByFunction(_: number, item: PhotoReportImageCard) : string {
        return item?.photo.index;
    }

    public async doPhotoCardAction(event: ImageCardEvent): Promise<void> {
        switch (event.action) {
            case ImageCardAction.Edit: this.setEditMode(event.source);
                break;
            case ImageCardAction.Delete:
                await this.deleteImage(event.source);
                break;
            case ImageCardAction.Click: await this.handleImageClick(event.source);
                break;
            case ImageCardAction.After: await this.moveImageLocation(event.source, 0.1);
                break;
            case ImageCardAction.Before: await this.moveImageLocation(event.source, -0.1);
                break;
            case ImageCardAction.Swap: await this.swapImageLocation(event.source);
                break;
            case ImageCardAction.Save:
                await this.saveImage(event.source);
        }
    }

    public setImage(image: ImageItem) : void {
        this.imageForEdit = JSON.parse(JSON.stringify(image));
    }

    public async navigateImage(step: number) : Promise<void> {
        let index = this.images.findIndex(_ => _.index === this.imageForEdit.index);
        const oldImage = JSON.stringify(this.images[index]);
        const updatedImage = JSON.stringify(this.imageForEdit);
        if (oldImage !== updatedImage) {
            await this.saveImageAndRefreshAll(this.imageForEdit, false);
        }

        index += step;

        if (index < 0) {
            index = 0;
        }
        else if (index >= this.images.length) {
            index = this.images.length - 1;
        }

        this.setImage(this.images[index]);
        this.selectedIndex = index;
    }

    public async openDeletedPhoto(): Promise<void> {
        const dialogRef = this._dialog.open(DeletedPhotoDialogComponent, {
            data: {
                isPhotoMode: true,
                images: this.deletedImages,
                projectId: this.activeProjectId,
                dialogTitle: this.translate.instant('photos.deletedDialogTitle')
            },
            minWidth: '600px',
            minHeight: '600px',
            width: '50vw',
            height: '50vh'
        });
        if (await dialogRef.afterClosed().toPromise()) {
            await this.getImagesFromService(true);
            this.reloadCounters();
        }
    }

    public async exitEditMode(): Promise<void> {
        await this.getImagesFromService(true);
        this.editMode = false;
    }

    public async doEditAction(action: string): Promise<void> {
        switch (action) {
            case 'Delete':
                await this.deleteImage(this.imageForEdit);
                break;
            case 'Save':
                await this.saveImageAndRefreshAll(this.imageForEdit);
                break;
            case 'Download': await this.downloadImage(this.imageForEdit);
        }
    }

    public async processAction(action: string): Promise<void> {
        switch (action) {
            case PhotoReportAction.DownloadAll: await this.downloadAllFiles();
                break;
            case PhotoReportAction.CreateReport: await this.openPhotoReportCreation();
                break;
            case PhotoReportAction.ViewReport: this.openReportsViewer();
        }
    }

    public async processSorting(sort: string): Promise<void> {
        this.loading = true;
        this.sortMode = sort === PhotoReportSort.EditOrder;
        this.imageCardConfig.actionButtons = this.defaultImageActions;
        this.selectedImageIndex = '';
        switch (sort) {
            case PhotoReportSort.EditOrder:
                this.imageCardConfig.actionButtons = [];
                this.sortPhotosByDisplayIndex();
                break;
            case PhotoReportSort.DisplayIndex: this.sortPhotosByDisplayIndex();
                break;
            case PhotoReportSort.Type: await this.sortPhotosByType();
                break;
            case PhotoReportSort.Datetime: await this.sortPhotosByCreationDate();
                break;
            case PhotoReportSort.Leak: await this.sortByLeak();
        }
        this.setImagesByPage();
        this.loading = false;
    }

    public async openUploadImage(): Promise<void> {
        const dialog = this._dialog.open(UploadImageDialogComponent, {
            data: { title: this.translate.instant('photos.uploadDialogTitle') },
            height: '700px',
            width: '1000px'
        });

        const image: string = await dialog.afterClosed().toPromise();
        if (image) {
            const imageBlob = GenericUtilityService.base64toBlob(image.replace('data:image/jpeg;base64,', ''), 'image/jpeg');

            await this.uploadImageInternal(imageBlob, `${new Date().getTime()}.jpg`);
        }
    }

    public async resetOrder(): Promise<void> {
        const resetPictureOrderLabel = this.translate.instant('shared.photoReportViewer.resetPictureOrder');
        const removeLabelsQuestion = this.translate.instant('shared.photoReportViewer.removeAllOrderLabelsQuestion');
        const okLabel = this.translate.instant('shared.ok');
        const cancelLabel = this.translate.instant('shared.cancel');
        const options = new ConfirmDialogOption(resetPictureOrderLabel,
            removeLabelsQuestion, false, okLabel, cancelLabel);
        const dialog = this._dialog.open(ConfirmDialogComponent, {
            data: options
        });

        if (await dialog.afterClosed().toPromise()) {
            this.images.forEach(_ => {
                _.imageOrderLabel = 0;
            });

            await this._imageHttpService.updateAllImages(this.activeProjectId, this.images);
        }
    }

    public async uploadNewImage(newImage: Blob): Promise<void> {
        await this.uploadImageInternal(newImage, this.imageForEdit.baseImageUri.split('/').pop().split('?').shift());
        this.setImage(this.images.find(_ => _.imageUri === this.imageForEdit.imageUri));
    }

    public setPageSize(event: PageEvent) : void {
        this.currentPage = event.pageIndex;
        this.currentPageSize = event.pageSize;

        this.setImagesByPage();
    }

    private async getImagesFromService(showLoading: boolean) : Promise<void> {
        this.loading = showLoading;
        this.images = await this._imageHttpService.getPictures(this.activeProjectId);
        this.deletedImages = await this._imageHttpService.getDeletedPictures(this.activeProjectId);
        this.processSorting(this.currentSort);
        this.loading = false;
    }

    private setEditMode(image: ImageItem) : void {
        this.selectedIndex = this.images.findIndex(_ => _.index === image.index);
        this.editMode = true;
        this.setImage(image);
    }

    private initializeImageCardConfig() {
        this.imageCardConfig = new ImageCardConfig();
        this.imageCardConfig.imageSize = '200px';
        this.imageCardConfig.showDescription = true;
        this.imageCardConfig.showTags = true;
        this.imageCardConfig.actionButtons = this.defaultImageActions;
        this.imageCardConfig.editableDescription = true;
        this.imageCardConfig.timeZone = this.timeZone;
    }

    public async deleteImage(image: ImageItem): Promise<void> {
        if (await this._imageHttpService.removeImage(this.activeProjectId, image)) {
            await this.getImagesFromService(true);
            this.editMode = false;
            this.reloadCounters();
        }
        else {
            const deleteImageFailureMessage = this.translate.instant('shared.photoReportViewer.deleteImageFailureMessage');
            const closeLabel = this.translate.instant('shared.close');
            this._snackBar.open(deleteImageFailureMessage, closeLabel);
        }
    }

    public async saveImage(image: ImageItem): Promise<void> {
        if (! await this._imageHttpService.updateImage(this.activeProjectId, image)) {
            const closeLabel = this.translate.instant('shared.close');
            const failedToUpdateImageMessage = this.translate.instant('shared.photoReportViewer.failedToUpdateImageMessage');
            this._snackBar.open(failedToUpdateImageMessage, closeLabel);
        }
    }

    private async saveImageAndRefreshAll(image: ImageItem, showLoading = true): Promise<void> {
        await this.saveImage(image);
        await this.getImagesFromService(showLoading);
    }

    private async sortPhotosByCreationDate(): Promise<void> {
        this.currentSort = PhotoReportSort.Datetime;
        this.images.sort((a, b) => a.createdDateUTC - b.createdDateUTC);

        this.images.forEach((_, index) => {
            _.imageDisplayIndex = index + 1;
        });

        await this._imageHttpService.updateAllImages(this.activeProjectId, this.images);
    }

    private sortPhotosByDisplayIndex(): void {
        this.currentSort = PhotoReportSort.DisplayIndex;
        this.images.sort((a, b) => a.imageDisplayIndex - b.imageDisplayIndex);
    }

    private async sortPhotosByType(): Promise<void> {
        this.currentSort = PhotoReportSort.Type;
        this.images.sort((a, b) => a.title.localeCompare(b.title));

        this.images.forEach((_, index) => {
            _.imageDisplayIndex = index + 1;
        });

        await this._imageHttpService.updateAllImages(this.activeProjectId, this.images);
    }

    private async sortByLeak(): Promise<void> {
        this.images.sort((a, b) => {
            const arrayA = a.title.split('#');
            const arrayB = b.title.split('#');
            const leakA = arrayA.length === 1 ? 0 : parseInt(arrayA[1], 10);
            const leakB = arrayB.length === 1 ? 0 : parseInt(arrayB[1], 10);

            if (leakA < leakB) {
                return -1;
            }

            return leakA > leakB? 1 : 0;
        });

        this.images.forEach((_, index) => {
            _.imageDisplayIndex = index + 1;
        });

        this.sortPhotosByDisplayIndex();
        await this._imageHttpService.updateAllImages(this.activeProjectId, this.images);
    }

    private async downloadImage(image: ImageItem): Promise<void> {
        const url = GenericUtilityService.getImageUrl(image, false);
        let imageName = image.baseImageUri.split('/').pop();
        imageName = imageName.split('?').shift();

        await this._downloadService.download(url, imageName, 'image/jpeg');
    }

    private async downloadAllFiles(): Promise<void> {
        const url = this._imageHttpService.getDownloadAllFilesUrl(this.activeProjectId);

        await this._downloadService.download(url, `${this.activeProjectId}.zip`, 'application/zip');
    }

    private async openPhotoReportCreation(): Promise<void> {
        this.sortPhotosByDisplayIndex();

        const drawings = await this._imageHttpService.getDrawings(this.activeProjectId);
        const clientInfo = await this._projectService.getClientFastData(this.activeProjectId);
        const data = {
            projectId: this.activeProjectId,
            clientLogo: clientInfo?.logoUrl,
            images: this.images,
            drawings
        } as PhotoReportDialogData;

        const dialog = this._dialog.open(PhotoReportOptionsDialogComponent, {
            data,
            minWidth: '600px',
            minHeight: '600px',
            width: '50vw',
            height: '50vh'
        });

        const response = await dialog.afterClosed().toPromise();

        if (response) {
            await this.generatePhotoReport(response, clientInfo);
        }
    }

    private async generatePhotoReport(options: PhotoReportOptions, clientInfo: ClientFastData): Promise<void> {
        const user = await this._userService.getUserInfo();

        if (options.clientReport) {
            options.clientId = clientInfo.clientId;
        }
        options.projectId = this.activeProjectId;
        options.userType = user.roleGroup;

        const action = this.handlePhotoReportGeneration(options);
        const ongoing = this.translate.instant('shared.photoReportViewer.requestingBeingProcessedMessage');
        const success = this.translate.instant('shared.photoReportViewer.reportGenerationSuccessMessage');
        const error = this.translate.instant('shared.photoReportViewer.errorCreatingPhotoReportMessage');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt, false);
        const dialog = this._dialog.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise()) {
            this.reloadCounters();
            this.openReportsViewer();
        }
    }

    private async handlePhotoReportGeneration(options: PhotoReportOptions): Promise<CodeDescription> {
        const generatedReport = await this._reportService.getPhotoReport(options);

        if (generatedReport.message === '202') {
            const reportCreateSuccessful = this.translate.instant('shared.photoReportViewer.requestingBeingProcessedMessage');
            return new CodeDescription('200', reportCreateSuccessful);
        }

        if (generatedReport.data === null) {
            return new CodeDescription('500', generatedReport.message);
        }
        else if (this._downloadService.downloadBlob(generatedReport.data.blob, generatedReport.data.fileName)) {
            const reportCreateSuccessful = this.translate.instant('shared.photoReportViewer.reportGeneratedSuccessfullyMessage');
            return new CodeDescription('200', reportCreateSuccessful);
        }
        const downloadFailedMessage = this.translate.instant('shared.photoReportViewer.downloadFailedMessage');
        return new CodeDescription('404', downloadFailedMessage);
    }

    private async reloadPhotoReports(): Promise<void> {
        this.photoReportsDialogRef.componentInstance.data = await this._reportService.photoReportLists(this.activeProjectId);
    }

    private async openReportsViewer(): Promise<void> {
        const reports = await this._reportService.photoReportLists(this.activeProjectId);
        this.photoReportsDialogRef = this._dialog.open(PdfFileViewerDialogComponent, {
            data: reports,
            height: '90vh',
            width: '75vw'
        });
    }

    private async setOrder(image: ImageItem): Promise<void> {
        if (!image.imageOrderLabel) {
            const maxOrder = this.images.reduce((previous, current) =>
                previous.imageOrderLabel > current.imageOrderLabel?
                    previous :
                    current);

            image.imageOrderLabel = maxOrder.imageOrderLabel + 1;
            await this._imageHttpService.updateImage(this.activeProjectId, image);
        }
        else {
            const imagesToUpdate = this.images.filter(_ => _.imageOrderLabel > image.imageOrderLabel);
            imagesToUpdate.forEach(_ => _.imageOrderLabel--);
            image.imageOrderLabel = 0;
            await this._imageHttpService.updateAllImages(this.activeProjectId, [image, ...imagesToUpdate]);
        }
    }

    private async uploadImageInternal(image: Blob, imageName: string): Promise<void> {
        this.loading = true;
        const uploaded =
            await this._uploadService.uploadProjectPhoto(this.activeProjectId, image, imageName);
        if (uploaded) {
            await this.getImagesFromService(true);
            this.reloadCounters();
        }
        else {
            const uploadFailed = this.translate.instant('shared.photoReportViewer.uploadFail');
            const closeLabel = this.translate.instant('shared.close');
            this._snackBar.open(uploadFailed, closeLabel);
        }

        this.loading = false;
    }

    private setImagesByPage(): void {
        const start = this.currentPage * this.currentPageSize;
        const end = start + this.currentPageSize;

        this.displayImages = this.images.slice(start, end).map(_ => new PhotoReportImageCard(null, _));
        this.fillDisplayImagesConfig();
        this.groupedImages = [];

        if (this.currentSort === PhotoReportSort.Datetime) {
            const groups = new Set(
                this.displayImages.map(_ =>
                    TimeUtility.toBranchLocalDateTime(new Date(_.photo.createdDateUTC), this.timeZone, false))
            );
            groups.forEach(_ => {
                this.groupedImages.push({
                    groupName: _,
                    items: this.displayImages.filter(i =>
                        TimeUtility.toBranchLocalDateTime(new Date(i.photo.createdDateUTC), this.timeZone, false) === _)
                });
            });
        }
        else if (this.currentSort === PhotoReportSort.Type) {
            const groups = new Set(this.displayImages.map(_ => _.photo.title));
            groups.forEach(_ => {
                this.groupedImages.push({ groupName: _, items: this.displayImages.filter(i => i.photo.title === _) });
            });
        }
    }

    private async handleImageClick(image: ImageItem): Promise<void> {
        if (this.picOrder && this.showPicOrder) {
            await this.setOrder(image);
        }
        else if (this.sortMode) {
            this.selectedImageIndex = this.selectedImageIndex === image.index? '' : image.index;
            this.fillDisplayImagesConfig();
        }
        else {
            this.openPhotoDialog(image);
        }
    }

    private openPhotoDialog(photo: ImageItem): void {
        this._dialog.open(FullViewPhotoDialogComponent, {
            data: photo,
            height: '80vh'
        });
    }

    private async moveImageLocation(image: ImageItem, step: number): Promise<void> {
        const selectedImage = this.images.find(_ => _.index === this.selectedImageIndex);
        selectedImage.imageDisplayIndex = image.imageDisplayIndex + step;
        this.sortPhotosByDisplayIndex();

        this.images.forEach((_, index) => {
            _.imageDisplayIndex = index + 1;
        });

        await this.finalizeMoveAction();
    }

    private async swapImageLocation(image: ImageItem): Promise<void> {
        const selectedImage = this.images.find(_ => _.index === this.selectedImageIndex);
        const targetImage = this.images.find(_ => _.index === image.index);
        const displayIndex = targetImage.imageDisplayIndex;
        targetImage.imageDisplayIndex = selectedImage.imageDisplayIndex;
        selectedImage.imageDisplayIndex = displayIndex;
        this.sortPhotosByDisplayIndex();
        await this.finalizeMoveAction();
    }

    private async finalizeMoveAction(): Promise<void> {
        await this._imageHttpService.updateAllImages(this.activeProjectId, this.images);
        this.selectedImageIndex = '';
        this.setImagesByPage();
    }

    private reloadCounters(): void {
        if (ProjectIdUtility.isEmergencyQuote(this.activeProjectId)) {
            this._appEvents.setReloadQuoteCounter();
        }
        else {
            this._appEvents.setReloadProjectCounter();
        }
    }

    private fillDisplayImagesConfig(): void {
        const index = this.displayImages.findIndex(_ => _.photo.index === this.selectedImageIndex);
        const previousIndex = index > 0 ? this.displayImages[index - 1].photo.index : '';
        const nextIndex = index < 0 || index === this.displayImages.length - 1 ?
            '': this.displayImages[index + 1].photo.index;
        this.displayImages.forEach(_ => {
            _.photoConfig = { ...this.imageCardConfig };
            if (this.sortMode) {
                _.photoConfig.actionButtons = this.getImageConfigForSortMode(_.photo, previousIndex, nextIndex);
            }
        });
    }

    private getImageConfigForSortMode(image: ImageItem, previousIndex: string, nextIndex: string): CardActionButton[] {
        if (!this.selectedImageIndex || this.selectedImageIndex === image.index) {
            return [];
        }
        else if (previousIndex === image.index) {
            return this.sortImageActions.filter(_ => _.text !== ImageCardAction.After);
        }
        else if (nextIndex === image.index) {
            return this.sortImageActions.filter(_ => _.text !== ImageCardAction.Before);
        }

        return this.sortImageActions;
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
        this._subscriptions.unsubscribe();
    }
}
