import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import ImageCardConfig from '../../../../core/data-models/card-items/image-card-config';
import ImageCardEvent from '../../../../core/data-models/card-items/image-card-event';
import PhotoReportImageCard from '../../../../core/data-models/card-items/photo-report-image-card';
import CustomReportPrintConfig from '../../../../core/data-models/custom-report-template/custom-report-print-config';
import CustomReportTemplate from '../../../../core/data-models/custom-report-template/custom-report-template';
import PlaceholderValue from '../../../../core/data-models/custom-report-template/placeholder-value';
import ImageItem from '../../../../core/data-models/job-template-items/image-item';
import { ReportPlaceholderType } from '../../../../core/enums/report-placeholder-type.enum';
import { CustomReportTemplateHttpService } from '../../../../core/services/http/custom-report-template-http/custom-report-template-http.service';
import { ImageHttpService } from '../../../../core/services/http/image-http/image-http.service';
import { SelectPhotoDialogComponent } from '../select-photo-dialog/select-photo-dialog.component';

@Component({
    selector: 'app-custom-report-print-dialog',
    styleUrls: ['./custom-report-print-dialog.component.scss'],
    templateUrl: './custom-report-print-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CustomReportPrintDialogComponent implements OnInit {
    public reportTemplate: CustomReportTemplate;
    public reportPrintConfig: CustomReportPrintConfig;
    public imagePlaceholders: PhotoReportImageCard[] = [];
    private _isLoaded = false;
    private images: ImageItem[] = [];
    private projectImages: ImageItem[] = [];

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    get textPlaceholders(): PlaceholderValue[] {
        return this.reportPrintConfig.placeholders.filter(_ => _.type === ReportPlaceholderType.Text);
    }

    //eslint-disable-next-line
    public constructor(private _translate: TranslateService,
        private _customReportTemplateService: CustomReportTemplateHttpService,
        private _dialogRef: MatDialogRef<CustomReportPrintDialogComponent>,
        private _snackBar: MatSnackBar,
        private _imageService: ImageHttpService,
        private _dialogService: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: string) { }

    public async ngOnInit(): Promise<void> {
        this.reportTemplate = await this._customReportTemplateService.getCustomReportTemplateByProjectId(this.data);
        if (this.reportTemplate) {
            this.reportPrintConfig = new CustomReportPrintConfig();
            this.reportPrintConfig.templateCode = this.reportTemplate.code;
            this.reportPrintConfig.projectId = this.data;
            this.reportPrintConfig.placeholders = this.reportTemplate.placeholders.map(_ => {
                return { value: '', ..._ };
            });

            this.setImagePlaceholders();
            this.projectImages = await this._imageService.getAllImages(this.data);
        }
        this._isLoaded = true;
    }

    public onClose(emit: boolean): void {
        if (emit && !this.validateFields()) {
            const message = this._translate.instant('projects.print.customReport.allFieldsRequired');
            const close = this._translate.instant('shared.close');
            this._snackBar.open(message, close);
            return;
        }

        this._dialogRef.close(emit? this.reportPrintConfig : null);
    }

    public async doPhotoCardAction(event: ImageCardEvent): Promise<void> {
        const dialog = this._dialogService.open(SelectPhotoDialogComponent, {
            data: this.projectImages,
            width: '50vw',
            height: '65vh'
        });

        const result = await dialog.afterClosed().toPromise();
        if (result) {
            const placeholder = this.reportPrintConfig.placeholders.find(_ => _.identifier === event.source.index);
            placeholder.value = result.baseImageUri;
            event.source.baseImageUri = result.baseImageUri;
        }
    }

    public trackByFunction(_: number, item: PhotoReportImageCard) : string {
        return item?.photo.baseImageUri;
    }

    private validateFields(): boolean {
        return this.reportPrintConfig.placeholders.every(_ => Boolean(_.value) || _.type === ReportPlaceholderType.ReportContent);
    }

    private setImagePlaceholders(): void {
        const imageCardConfig = new ImageCardConfig();
        imageCardConfig.imageSize = '180px';
        imageCardConfig.showDescription = true;

        this.images = this.reportPrintConfig.placeholders
            .filter(_ => _.type === ReportPlaceholderType.Image)
            .map(_ => {
                return { title: _.name, baseImageUri: _.value, index: _.identifier } as ImageItem;
            });
        this.imagePlaceholders = this.images.map(_ => new PhotoReportImageCard(imageCardConfig, _));
    }
}
