<div fxFill fxLayout="column" fxLayoutAlign="start stretch" class="history-container" fxLayoutGap="10px">
    <mat-card>
        <mat-button-toggle-group [(ngModel)]="view">
            <mat-button-toggle value="list"><mat-icon>format_list_bulleted</mat-icon><span>{{ 'projects.history.list' | translate }}</span></mat-button-toggle>
            <mat-button-toggle value="grid"><mat-icon>grid_on</mat-icon><span>{{ 'projects.history.grid' | translate }}</span></mat-button-toggle>
            <mat-button-toggle value="timeline"><mat-icon>timeline</mat-icon><span>{{ 'projects.history.timeline' | translate }}</span></mat-button-toggle>
        </mat-button-toggle-group>
    </mat-card>

    <mat-card class="history-list" *ngIf="view==='list' && data.length>0" fxFlex>
        <kendo-grid [kendoGridBinding]="data"
            [pageSize]="gridPageSize"
            [pageable]="true"
            sortable="true"
            appGridPageResize="true" >
            <kendo-grid-column field="name" [title]="translate.instant('projects.history.projectName')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="color-div" [style.border-color]="getColor(dataItem.projectId)" fxLayout="column" fxLayoutAlign="center start">
                        {{ dataItem.name?dataItem.name:"N/A" }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="projectId" [title]="translate.instant('projects.history.projectId')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <a href="/projects/{{dataItem.projectId}}/details" target="_blank">{{dataItem.projectId}}</a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="createdTime" [title]="translate.instant('projects.history.date')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.createdTime }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="type" [title]="translate.instant('projects.history.type')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ getType(dataItem.type) }}
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </mat-card>

    <div class="history-grid-container" fxFlex *ngIf="view==='grid'" fxLayoutGap="10px">
        <div fxLayout="row wrap" fxFill>
            <mat-card class="flex-item" *ngFor="let project of data" fxFlex="15" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div [style.background-image]="'url('+ project.image + ')'" class="card-image" fxFill fxLayout="column" fxLayoutAlign="end stretch">
                    <div fxLayout="column" class="project-info" fxLayoutAlign="space-between center">
                        <a mat-button href="/projects/{{project.projectId}}/details" target="_blank">
                            {{ project.projectId }}
                        </a>
                        <div>{{ project.name?project.name:"N/A" }}</div>
                        <div>{{ project.createdTime }}</div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div *ngIf="view==='timeline'" fxLayout="column" class="project-history-timeline-content">
        <div class="project-history-timeline" fxFlex>
            <timeline-card [events]="events"></timeline-card>
        </div>
    </div>
</div>
