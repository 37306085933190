<div class="kpi-management-container" fxFill fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
    <mat-card>
        <mat-button-toggle-group [(ngModel)]="view">
            <mat-button-toggle value="holiday"><span>{{"admin.kpiSetting.mainPageTitles.holiday" | translate}}</span></mat-button-toggle>
            <mat-button-toggle value="kpi"><span>{{"admin.kpiSetting.mainPageTitles.kpi" | translate}}</span></mat-button-toggle>
        </mat-button-toggle-group>
    </mat-card>

    <div fxFlex *ngIf="view==='holiday'">
        <holiday-calendar></holiday-calendar>
    </div>

    <div *ngIf="view==='kpi'" fxFlex>
        <kpi-data-editor></kpi-data-editor>
    </div>
</div>
