<form [formGroup]="dispatchNewJobForm" class="dispatch-new-report-container" fxFill fxLayout fxLayoutAlign="center center">

    <div fxFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <div class="dispatch-new-report-main-form-container" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">

            <div fxFlexFill fxLayout="column" fxLayoutAlign="center start">
                <h4>{{ 'projects.dispatchReport.generalInformation' | translate }}</h4>
                <div fxFlexFill fxLayout fxLayoutAlign="start center" fxLayoutGap="25px">

                    <mat-form-field fxFlex="30">
                        <mat-label>{{ 'projects.dispatchReport.jobType' | translate }}</mat-label>
                        <mat-select formControlName="jobType" (selectionChange)="changeJobType($event.value)">
                            <mat-option *ngFor="let jobType of jobTypeList" [value]="jobType.code">
                                {{ jobType.desc | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex="30">
                        <mat-label>{{ 'projects.dispatchReport.siteContact' | translate }}</mat-label>
                        <input matInput formControlName="siteContact">
                    </mat-form-field>

                    <mat-form-field fxFlex="30">
                        <mat-label>{{ 'projects.dispatchReport.contactPhone' | translate }}</mat-label>
                        <input matInput formControlName="contactPhone" appPhoneMask>
                    </mat-form-field>

                </div>

                <div fxFlexFill fxLayout fxLayoutAlign="start center" fxLayoutGap="25px">

                    <mat-form-field *ngIf="isMultiDay" fxFlex="25">
                        <mat-label>{{ 'projects.dispatchReport.expectedDays' | translate }}</mat-label>
                        <input type="number" matInput formControlName="expectedDays">
                    </mat-form-field>

                    <mat-checkbox *ngIf="isLeakCall" formControlName="continueLeakCall"> {{ 'projects.dispatchReport.continueLeakCall' | translate }}
                    </mat-checkbox>
                    <mat-checkbox formControlName="drawingRequired"> {{ 'projects.dispatchReport.drawingRequired' | translate }} </mat-checkbox>
                    <mat-checkbox *ngIf="isMultiDay" formControlName="overNightSealRequired"> {{ 'projects.dispatchReport.overnightSealRequired' | translate }}
                    </mat-checkbox>

                </div>
            </div>

            <div [ngClass]="{'dispatch-new-job-error-div-outline': !dispatchNewJobForm.get('crew').valid}" fxFlexFill fxLayout fxLayoutAlign="start start" fxLayoutGap="25px">
                <crew-selector [horizontalMode]="true"
                    [initiallySelectedCrew]="handOffMeetingApprovedCrew"
                    [healthScreeningOption]="healthScreeningOption"
                    [country]="country"
                    (crewSelectedEvent)="crewSelected($event)"
                    fxFlex="75"
                    fxFlexFill>
                </crew-selector>
            </div>

            <div *ngIf="handOffMeetingRequired() && handOffMeetingApprovedCrew?.crewId" fxFlexFill fxLayout fxLayoutAlign="start center"
                class="hand-off-meeting-redispatch-notice-message">
                {{ 'projects.dispatchReport.redispatchHelpText' | translate }}
            </div>
            <div *ngIf="handOffMeetingRequired() && !handOffMeetingApprovedCrew?.crewId" fxFlexFill fxLayout fxLayoutAlign="start center"
                class="hand-off-meeting-redispatch-notice-message">
                {{ 'projects.dispatchReport.noHandoffHelpText' | translate }}
            </div>

            <div fxFlexFill fxLayout="column" fxLayoutAlign="center start">
                <h4>{{ 'projects.dispatchReport.additionalInformation' | translate }}</h4>

                <div fxFlexFill fxLayout fxLayoutAlign="start start" fxLayoutGap="25px">
                    <mat-form-field fxFlex="55">
                        <mat-label>{{ 'projects.dispatchReport.Notes' | translate }}</mat-label>
                        <textarea matInput rows="3" formControlName="notes">
                    </textarea>
                    </mat-form-field>

                    <mat-form-field fxFlex="35">
                        <mat-label>{{ 'projects.dispatchReport.notToExceedAmount' | translate }}</mat-label>
                        <input type="number" matInput formControlName="notToExceedAmount">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div fxFill fxLayout fxLayoutAlign="end center">
            <button mat-stroked-button (click)="submitBasicDispatchInfo()">{{ 'projects.dispatchReport.Next' | translate }}</button>
        </div>
    </div>
</form>
