import ProjectQuestions from './project-questions';
import ProjectUsEstimateForm from './project-us-estimate-form';
import ProjectUsTaxQuestions from '../project-us-tax-questions';

export default class ProjectAttributesWithUsCheck {
    public isUSProject = false;
    public projectQuestions = new ProjectQuestions();
    public uSEstimateForm = new ProjectUsEstimateForm();
    public uSTaxQuestions = new ProjectUsTaxQuestions();
}
