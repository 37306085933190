import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import StockLog from '../../../../../core/data-models/job-materials/stock-log';
import MaterialCost from '../../../../../core/data-models/job-materials/material-cost';
import StockLogDialogOptions from '../../../../../core/data-models/job-materials/stock-log-dialog-options';
import ProjectIdUtility from '../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import { InvoiceHttpService } from '../../../../../core/services/http/invoice-http/invoice-http.service';
import ActionProgressReporterOption from '../../../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';

@Component({
    selector: 'add-stock-log-dialog',
    styleUrls: ['./add-stock-log-dialog.component.scss'],
    templateUrl: './add-stock-log-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AddStockLogDialogComponent implements OnInit {
    private _costs: MaterialCost;
    private _isLoading = false;

    constructor(public translate: TranslateService,
        private _invoiceHttp: InvoiceHttpService,
        private _dialogService: MatDialog,
        private _dialog: MatDialogRef<AddStockLogDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public option: StockLogDialogOptions) { }

    get isLoading(): boolean {
        return !this.costs || this._isLoading;
    }

    get costs(): MaterialCost {
        return this._costs;
    }

    public async ngOnInit(): Promise<void> {
        this._dialog.disableClose = true;
        this._costs = await this._invoiceHttp.getMaterialCost(this.option.projectId);
    }

    public async addStockLog(): Promise<void> {
        const { projectId, projectName } = this.option;

        const stockLog = {
            projectId,
            projectName,
            materialCost: this._costs?.totalCost ?? 0,
            currency: ProjectIdUtility.isUsProject(projectId) ? 'USD' : 'CAD'
        } as StockLog;

        const action = this._invoiceHttp.addStockLog(stockLog);
        const ongoing = this.translate.instant('projects.details.stockLog.actions.ongoing');
        const success = this.translate.instant('projects.details.stockLog.actions.success');
        const error = this.translate.instant('projects.details.stockLog.actions.error');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise()) {
            this.onClose();
        }
    }

    public onClose(): void {
        this._dialog.close();
    }
}
