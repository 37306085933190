import { Component, OnInit, OnDestroy } from '@angular/core';
// global variable used by zendesk widget.
// declared here to tell compiler it exists.
/* eslint-disable @typescript-eslint/no-explicit-any, max-params */
declare const zE: any;

@Component({
    selector: 'authentication-error',
    styleUrls: ['./authentication-error.component.scss'],
    templateUrl: './authentication-error.component.html'
})
export class AuthenticationErrorComponent implements OnInit, OnDestroy {

    public ngOnInit(): void {
        zE('webWidget', 'show');
    }

    public ngOnDestroy(): void {
        zE('webWidget', 'hide');
    }
}
