<div fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="row" fxFlex class="work-order-main-content" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex>
            <div fxLayout="row" fxLayoutAlign="end center" *ngIf="useWorkOrderTemplates">
                <mat-form-field>
                    <mat-label>{{'shared.workOrderStep.availableTemplates' | translate }}</mat-label>
                    <mat-select (selectionChange)="loadTemplateContent($event)">
                        <mat-option *ngFor="let template of templates" [value]="template">{{template.title}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <textarea [id]="editorId"></textarea>
        </div>
        <div fxLayout="column" *ngIf="hasWorkCheckList" fxLayoutGap="15px" fxFlex="25">
            <div>
                <button mat-stroked-button (click)="addSelectedText()" color="primary" matTooltip="{{ 'shared.workOrderStep.addSelectedText' | translate }}">
                    {{ "shared.workOrderStep.addWorkOrderButton" | translate }}
                </button>
            </div>
            <mat-card fxFlex>
                <mat-card-title>
                    {{"shared.workOrderStep.workCheckList" | translate }}
                </mat-card-title>
                <mat-list>
                    <mat-list-item *ngFor="let workItem of config.workCheckList; index as i">
                        <button mat-icon-button (click)="removeWorkItem(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                        {{workItem}}
                        <mat-divider></mat-divider>
                    </mat-list-item>
                </mat-list>
            </mat-card>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <button mat-stroked-button (click)="stepClicked.emit('previous')" color="primary" *ngIf="config.hasPrevious">{{ 'dispatch.sharedStepLabels.previousButton' | translate }}</button>
        <button mat-stroked-button (click)="validateAndMoveNext()" color="primary" *ngIf="config.hasNext">{{ 'dispatch.sharedStepLabels.nextButton' | translate }}</button>
    </div>
</div>

