import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, ValidationErrors, Validators, UntypedFormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { LineItem } from '../../../../core/data-models/generic/line-item';
import { TypeRecord } from '../../../../core/data-models/generic/type-record';
import { BranchServiceRate } from '../../../../core/data-models/rates/service-rates/branch-service-rate';
import { MaterialRate } from '../../../../core/data-models/rates/service-rates/material-rate';
import { RatesEditorOptions } from '../../../../core/data-models/options/rates-editor-options';
import { ServiceRatesMetadata } from '../../../../core/data-models/rates/service-rates/service-rates-metadata';
import { ServiceRatesRevision } from '../../../../core/data-models/rates/service-rates/service-rates-revision';
import ErpTaskCode from '../../../../core/data-models/erp/erp-task-code';
import { ErpHttpService } from '../../../../core/services/http/erp-http/erp-http.service';
import { ServiceRatesEditorService } from './service-rates-editor.service';
import { ServiceRatesHttpService } from '../../../../core/services/http/service-rates-http/service-rates-http.service';
import { Branch } from '../../../../core/data-models/branch/branch';

@Component({
    selector: 'app-service-rates-editor',
    styleUrls: ['./service-rates-editor.component.scss'],
    templateUrl: './service-rates-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRatesEditorComponent implements OnInit {
    @Input() public options: RatesEditorOptions<ServiceRatesMetadata, ServiceRatesRevision>;
    @Input() public isReadonly: boolean;
    @Output() public revise = new EventEmitter<ServiceRatesRevision>();
    @Output() public toggleActive = new EventEmitter();
    @Output() public close = new EventEmitter();

    public form: UntypedFormGroup;
    public isReady = false;
    public taskList: ErpTaskCode[] = [];
    public branches: Branch[] = [];

    get branchNames(): Map<string, string> {
        const map = new Map<string, string>();

        if (!this.branches.length) {
            return map;
        }

        this.branches.forEach(_ => map.set(_.code, _.name));
        return map;
    }

    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
                private _formBuilder: UntypedFormBuilder,
                private _serviceRatesEditorService: ServiceRatesEditorService,
                private _erpHttpService: ErpHttpService,
                private _serviceRatesHttp: ServiceRatesHttpService,
                private _changeDetectorRef: ChangeDetectorRef) { }

    public async ngOnInit(): Promise<void> {
        const branches = await this._serviceRatesHttp.getActiveBranches();
        this.branches = branches.sort((a, b) => a.name.localeCompare(b.name));
        this.taskList = await this._erpHttpService.getTaskCodes();
        const { branchRates, materialRates, miscellaneousItemTypes, projectTypes, customSummaryLineItems, qualityControlFeesPercentageRates, defaultRates, tasks, leadQuestions } = this.options.revision;
        const branchRatesFields = branchRates.map(rate => this._serviceRatesEditorService.createBranchRateField(rate));
        const materialRatesFields = materialRates.map(rate => this._serviceRatesEditorService.createMaterialRateField(rate));
        const miscellaneousItemTypesFields = miscellaneousItemTypes.map(item => this._serviceRatesEditorService.createTypeRecordField(item));
        const tasksFields = tasks.map(task => this._serviceRatesEditorService.createTaskField(task));
        const projectTypesFields = projectTypes.map(project => this._serviceRatesEditorService.createTypeRecordField(project));
        const customSummaryLineItemsFields = customSummaryLineItems.map(item => this._serviceRatesEditorService.createCustomSummaryLineItemField(item));
        const qualityControlFeesFields = qualityControlFeesPercentageRates.map(rate => [rate]);
        const questionFields = leadQuestions.map(question => this._serviceRatesEditorService.createTypeRecordField(question));

        this.form = this._formBuilder.group({
            branchRates: this._formBuilder.array(branchRatesFields, [Validators.required, this.validateBranchRatesCollection.bind(this)]),
            materialRates: this._formBuilder.array(materialRatesFields, this.validateMaterialRatesCollection.bind(this)),
            miscellaneousItemTypes: this._formBuilder.array(miscellaneousItemTypesFields, this.validateItemTypesCollection),
            tasks: this._formBuilder.array(tasksFields),
            leadQuestions: this._formBuilder.array(questionFields),
            projectTypes: this._formBuilder.array(projectTypesFields, [Validators.required, this.validateItemTypesCollection]),
            customSummaryLineItems: this._formBuilder.array(customSummaryLineItemsFields, this.validateCustomSummaryLineItemsCollection.bind(this)),
            qualityControlFeesPercentageRates: this._formBuilder.array(qualityControlFeesFields, [Validators.required, this.validatePercentageRatesCollection]),
            defaultRates: this._formBuilder.group({
                miscellaneousLaborHourlyRate: this._serviceRatesEditorService.createCurrencyField(defaultRates.miscellaneousLaborHourlyRate),
                qualityControlFeesDollarRate: this._serviceRatesEditorService.createCurrencyField(defaultRates.qualityControlFeesDollarRate)
            })
        });
        if (this.isReadonly || this.options.metadata.isActive) {
            this.form.disable();
        }
        else {
            this.form.markAllAsTouched();
        }

        this.isReady = true;
        this._changeDetectorRef.markForCheck();
    }

    public toggleActiveStatus(event: Event): void {
        this.form.markAllAsTouched();
        const isFormValid = this.form.enabled && this.form.valid || this.form.disabled;
        if (!isFormValid || this.isReadonly) {
            return;
        }
        event.preventDefault();
        this.toggleActive.emit();
        if (this.form.disabled) {
            this.form.enable();
        }
        else {
            this.form.disable();
        }
    }

    public save(): void {
        if (this.form.invalid) {
            return;
        }

        const branchRatesForm = this.form.get('branchRates') as UntypedFormArray;
        branchRatesForm.patchValue(branchRatesForm.value.sort((left: BranchServiceRate, right: BranchServiceRate) => {
            const leftName = this.branchNames.get(left.branchCode);
            const rightName = this.branchNames.get(right.branchCode);

            return leftName.localeCompare(rightName);
        }));

        const revision: ServiceRatesRevision = { ...this.options.revision, ...this.form.getRawValue() };

        for (const branchRate of revision.branchRates) {
            branchRate.insurancePercentageRate /= 100;
            branchRate.indirectsPercentageRate /= 100;
            branchRate.subcontractGrossProfitPercentageRate /= 100;

            for (const rate of branchRate.grossProfitPercentageRates) {
                rate.rate /= 100;
            }
        }

        for (const item of revision.customSummaryLineItems) {
            item.percentageRate /= 100;
        }

        this.revise.emit(revision);
    }

    private validateBranchRatesCollection(control: UntypedFormArray): ValidationErrors | null {
        const rates: BranchServiceRate[] = control.value ?? [];
        const branchCodes = rates.map(_ => _.branchCode);
        const duplicateCodeIndexes = this.getDuplicateIndexes(branchCodes);

        for (let i = 0; i < control.controls.length; ++i) {
            const field = this.getRevalidatedField(control.controls[i], 'branchCode');

            if (duplicateCodeIndexes.has(i)) {
                field.setErrors({ duplicateCode: true });
            }
        }

        return null;
    }

    private validateMaterialRatesCollection(control: UntypedFormArray): ValidationErrors | null {
        const rates: MaterialRate[] = control.value ?? [];
        const names = rates.map(_ => _.type.name?.trim()?.toLowerCase() ?? '');
        const units = rates.map(_ => _.unitOfMeasurement?.trim()?.toLowerCase() ?? '');
        const namesAndUnits = names.map((_, index) => `${_}|${units[index]}`);
        const emptyNameIndexes = names.reduce((result, name, index) => name ? result : result.add(index), new Set<number>());
        const duplicateNameAndUnitsIndexes = this.getDuplicateIndexes(namesAndUnits);

        for (let i = 0; i < control.controls.length; ++i) {
            const nameField = this.getRevalidatedField(control.controls[i], 'type.name');
            const unitField = this.getRevalidatedField(control.controls[i], 'unitOfMeasurement');

            if (emptyNameIndexes.has(i)) {
                nameField.setErrors({ emptyName: true });
            }
            else if (duplicateNameAndUnitsIndexes.has(i)) {
                nameField.setErrors({ duplicateNameAndUnits: true });
                unitField.setErrors({ duplicateNameAndUnits: true });
            }
        }

        const identifiers = rates.map(_ => _.type.identifier);
        const uniqueIdentifier = new Set(identifiers).size;

        return uniqueIdentifier !== rates.length ? { duplicateIdentifier: true } : null;
    }

    private validateCustomSummaryLineItemsCollection(control: UntypedFormArray): ValidationErrors | null {
        const items: LineItem<TypeRecord>[] = control.value ?? [];
        const names = items.map(item => item.identifier.name?.trim()?.toLowerCase() ?? '');
        const emptyNameIndexes = names.reduce((result, name, index) => name ? result : result.add(index), new Set<number>());
        const duplicateNameIndexes = this.getDuplicateIndexes(names);

        for (let i = 0; i < control.controls.length; ++i) {
            const field = this.getRevalidatedField(control.controls[i], 'identifier.name');

            if (emptyNameIndexes.has(i)) {
                field.setErrors({ emptyName: true });
            }
            else if (duplicateNameIndexes.has(i)) {
                field.setErrors({ duplicateName: true });
            }
        }

        const identifiers = items.map(item => item.identifier.identifier);
        const uniqueIdentifier = new Set(identifiers).size;

        return uniqueIdentifier !== items.length ? { duplicateIdentifier: true } : null;
    }

    private validateItemTypesCollection(control: AbstractControl): ValidationErrors | null {
        const types: TypeRecord[] = control.value ?? [];
        const names = types.map(_ => _.name?.trim()?.toLowerCase() ?? '');

        if (names.some(_ => !_)) {
            return { emptyName: true };
        }

        const uniqueNames = new Set(names).size;

        if (uniqueNames !== types.length) {
            return { duplicateName: true };
        }

        const identifiers = types.map(_ => _.identifier);
        const uniqueIdentifier = new Set(identifiers).size;

        return uniqueIdentifier !== types.length ? { duplicateIdentifier: true } : null;
    }

    private validatePercentageRatesCollection(control: AbstractControl): ValidationErrors | null {
        const rates: number[] = control.value ?? [];

        if (rates.some(_ => _ < 0)) {
            return { negative: true };
        }

        const modifier = Math.pow(10, 4);
        const rounded = rates.map(_ => Math.round(_ * modifier) / modifier);
        const uniqueRates = new Set(rounded).size;

        return uniqueRates !== rates.length ? { duplicateRate: true } : null;
    }

    private getDuplicateIndexes(items: string[]): Set<number> {
        const indexes = new Map<string, number[]>();

        for (let i = 0; i < items.length; ++i) {
            if (!items[i]) {
                continue;
            }

            if (!indexes.has(items[i])) {
                indexes.set(items[i], [i]);
            }
            else {
                indexes.get(items[i]).push(i);
            }
        }

        const flattened = Array.from(indexes).reduce((result, _) => _[1].length < 2 ? result : [...result, ..._[1]], []);

        return new Set<number>(flattened);
    }

    private getRevalidatedField(control: AbstractControl, key: string): AbstractControl {
        const field = control.get(key);
        // rerun validations for control without updating parent control value to avoid infinite loop
        field.updateValueAndValidity({ onlySelf: true });
        // clear errors and update parent control validity only
        if (field.valid) {
            field.setErrors(null);
        }

        return field;
    }
}
