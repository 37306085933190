import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { TranslateService } from '@ngx-translate/core';

import ActionProgressReporterOption from '../../../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import UploadBudgetDialogOption from '../../../../../core/data-models/upload-budget-dialog-option';
import { BudgetHttpService } from '../../../../../core/services/http/budget-http/budget-http.service';

@Component({
    selector: 'upload-budget-dialog',
    styleUrls: ['./upload-budget-dialog.component.scss'],
    templateUrl: './upload-budget-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UploadBudgetDialogComponent implements OnInit {
    public budgetFile: FileSystemFileEntry = null;
    public customerId: string;
    private _isLoaded = false;

    constructor(private _budgetHttp: BudgetHttpService,
        private _dialogService: MatDialog,
        private _dialog: MatDialogRef<UploadBudgetDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public options: UploadBudgetDialogOption,
        public translate: TranslateService) { }

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        const { customerId } = this.options;
        this.customerId = customerId ? customerId : this.translate.instant('projects.updateBudget.none');
        this._isLoaded = true;
    }

    public async uploadAndProceed(): Promise<void> {
        const { projectId } = this.options;

        const action = this._budgetHttp.uploadBudget(projectId, this.budgetFile);
        const ongoing = this.translate.instant('projects.updateBudget.ongoing');
        const success = this.translate.instant('projects.updateBudget.success');
        const error = this.translate.instant('projects.updateBudget.error');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise()) {
            this.onClose(true);
        }
    }

    public onClose(value: boolean): void {
        this._dialog.close(value);
    }
}
