<div class="service-rates-editor-container" fxLayout="column" fxLayoutGap="2.5vh" *ngIf="isReady">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25vh">
        <button mat-icon-button color="primary" (click)="close.emit()">
            <mat-icon>chevron_left</mat-icon>
        </button>

        <span class="title">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.title' | translate: { version: options.metadata.version, revision: options.revision.revision } }}</span>

        <div class="active-status-toggle" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="0.75vh" fxFlex>
            <mat-slide-toggle color="primary" [checked]="options.metadata.isActive" [disabled]="form.invalid || isReadonly" (click)="toggleActiveStatus($event)"></mat-slide-toggle>
            <span class="active-text" *ngIf="options.metadata.isActive">{{ 'flynnBudgetTool.ratesEditor.versionActive' | translate }}</span>
            <span class="inactive-text" *ngIf="!options.metadata.isActive">{{ 'flynnBudgetTool.ratesEditor.versionInactive' | translate }}</span>
        </div>

        <button mat-raised-button [disabled]="form.invalid || isReadonly || form.disabled" color="primary" (click)="save()">{{ 'flynnBudgetTool.shared.save' | translate }}</button>
    </div>

    <div>
        <span class="readonly-reason-text" *ngIf="isReadonly">{{ 'flynnBudgetTool.ratesEditor.readOnlyModeWarning' | translate }}</span>
        <span class="readonly-reason-text" *ngIf="!isReadonly && options.metadata.isActive">{{ 'flynnBudgetTool.ratesEditor.readOnlyReasonForActiveTarget' | translate }}</span>
    </div>
    <mat-tab-group [dynamicHeight]="true">
        <mat-tab [label]="translate.instant('flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.questions.title')">
            <app-service-rates-questions-options [isReadonly]="isReadonly || options.metadata.isActive" [form]="form"></app-service-rates-questions-options>
        </mat-tab>
        <mat-tab [label]="translate.instant('flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.title')">
            <app-service-rates-branch-options [isReadonly]="isReadonly || options.metadata.isActive" [form]="form" [groupId]="options.metadata.groupId" [branches]="branches"></app-service-rates-branch-options>
        </mat-tab>

        <mat-tab [label]="translate.instant('flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.materials.title')">
            <app-service-rates-materials-options [isReadonly]="isReadonly || options.metadata.isActive" [form]="form"></app-service-rates-materials-options>
        </mat-tab>

        <mat-tab [label]="translate.instant('flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.miscellaneous.title')">
            <app-service-rates-miscellaneous-options [isReadonly]="isReadonly || options.metadata.isActive" [form]="form"></app-service-rates-miscellaneous-options>
        </mat-tab>

        <mat-tab [label]="translate.instant('flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.customSummaryLineItems.title')">
            <app-service-rates-summary-line-items-options [form]="form" [isReadonly]="isReadonly || options.metadata.isActive"></app-service-rates-summary-line-items-options>
        </mat-tab>

        <mat-tab [label]="translate.instant('flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.tasks.title')">
            <app-service-rates-tasks-options [form]="form" [isReadonly]="isReadonly || options.metadata.isActive" [taskList]="taskList"></app-service-rates-tasks-options>
        </mat-tab>
    </mat-tab-group>
</div>
