import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import ProjectStatus from '../../../data-models/project-status';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectStatusHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/project-status`;
    private _statusPromise: Promise<ProjectStatus[]> = null;

    constructor(private _http: HttpClient) { }

    public async getFilterableProjectStatus(useCache = true): Promise<ProjectStatus[]> {
        try {
            if (!useCache || !this._statusPromise) {
                this._statusPromise = this._http.get<ProjectStatus[]>(`${this._api}/filterable`).toPromise();
            }

            return JsonUtility.toCamelCaseKeys(await this._statusPromise);
        }
        catch {
            return [];
        }
    }
}
