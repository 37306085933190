import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Branch } from '../../../data-models/branch/branch';
import { RatesQuery } from '../../../data-models/rates/rates-query';
import { BranchServiceRateHistory } from '../../../data-models/rates/service-rates/branch-service-rate-history';
import { ServiceRatesMetadata } from '../../../data-models/rates/service-rates/service-rates-metadata';
import { ServiceRatesRevision } from '../../../data-models/rates/service-rates/service-rates-revision';
import { ServiceRatesRequest } from '../../../data-models/rates/service-rates/service-rates-request';
import { ServiceRatesResponse } from '../../../data-models/rates/service-rates/service-rates-response';
import { ServiceRatesGroupSummaryDto } from '../../../data-models/dtos/service-rates-group-summary-dto';
import { JsonUtility } from '../../../utilities/json/json.utility';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ServiceRatesHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/service-rates`;

    constructor(private _http: HttpClient) { }

    public async getServiceRatesMetadataByCustomerId(customerId: string): Promise<ServiceRatesMetadata[]> {
        try {
            const endpoint = `${this._api}/customers/${customerId}`;
            const data = await this._http.get<ServiceRatesMetadata[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getServiceRatesGroupSummaries(): Promise<ServiceRatesGroupSummaryDto[]> {
        try {
            const endpoint = `${this._api}/groups-summaries`;
            const data = await this._http.get<ServiceRatesGroupSummaryDto[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getServiceRatesMetadataByGroupId(groupId: string): Promise<ServiceRatesMetadata[]> {
        try {
            const endpoint = `${this._api}/groups/${groupId}`;
            const data = await this._http.get<ServiceRatesMetadata[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getLatestServiceRatesRevision(query: RatesQuery): Promise<ServiceRatesRevision> {
        try {
            const endpoint = `${this._api}/latest`;
            const data = await this._http.post<ServiceRatesRevision>(endpoint, query).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getBranchServiceRateHistories(groupId: string, branchCode: string): Promise<BranchServiceRateHistory[]> {
        try {
            const endpoint = `${this._api}/groups/${groupId}/branch-service-rate-histories/${branchCode}`;
            const data = await this._http.get<BranchServiceRateHistory[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getActiveBranches(): Promise<Branch[]> {
        try {
            const endpoint = `${this._api}/active-branches`;
            const data = await this._http.get<Branch[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async upsertServiceRatesGroup(group: ServiceRatesGroupSummaryDto): Promise<ServiceRatesGroupSummaryDto> {
        try {
            const endpoint = `${this._api}/groups`;
            const data = await this._http.post<ServiceRatesGroupSummaryDto>(endpoint, group).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async addServiceRatesVersion(request: ServiceRatesRequest): Promise<ServiceRatesResponse> {
        try {
            const endpoint = `${this._api}/versions`;
            const data = await this._http.post<ServiceRatesResponse>(endpoint, request).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getServiceRatesRevision(query: RatesQuery): Promise<ServiceRatesRevision> {
        try {
            const endpoint = `${this._api}/revisions/search`;
            const data = await this._http.post<ServiceRatesRevision>(endpoint, query).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async addServiceRatesRevision(request: ServiceRatesRequest): Promise<ServiceRatesResponse> {
        try {
            const endpoint = `${this._api}/revisions`;
            const data = await this._http.post<ServiceRatesResponse>(endpoint, request).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async activateServiceRatesVersion(query: RatesQuery): Promise<ServiceRatesMetadata> {
        try {
            const endpoint = `${this._api}/activate`;
            const data = await this._http.post<ServiceRatesMetadata>(endpoint, query).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async deactivateServiceRatesVersion(query: RatesQuery): Promise<ServiceRatesMetadata> {
        try {
            const endpoint = `${this._api}/deactivate`;
            const data = await this._http.post<ServiceRatesMetadata>(endpoint, query).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }
}
