<dialog-action-card class="deleted-photo-dialog-container"
    [icon]="'photo_library'"
    [title]="data.dialogTitle"
    [isLoaded]="true"
    (close)="onClose()">
    <div fxFill fxLayout="column">
        <div class="deleted-photo-photos" fxLayout="row wrap" fxLayoutGap="7px">
            <app-photo-card *ngFor="let image of data.images"
                [photo]="image"
                [config]="imageCardConfig"
                (cardEvent)="doPhotoCardAction($event)"
                fxFlexAlign="stretch"></app-photo-card>
        </div>
    </div>
</dialog-action-card>
