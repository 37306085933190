import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import ProjectRequest from '../../../../core/data-models/integration/project-request';
import { IntegrationHttpService } from '../../../../core/services/http/integration-http/integration-http.service';
import ProjectRequestViewDto from '../../../../core/data-models/integration/project-request-view-dto';
import { DispatchProjectDialogComponent } from '../../../../features/dispatch/dispatch-project-dialog/dispatch-project-dialog.component';
import DispatchDialogOptions from '../../../../core/data-models/dispatch/dispatch-dialog-options';
import { ConfirmDialogComponent } from '../confirm-dialog-component/confirm-dialog.component';
import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';

@Component({
    selector: 'app-integration-requests',
    styleUrls: ['./integration-requests-dialog.component.scss'],
    templateUrl: './integration-requests-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class IntegrationRequestsDialogComponent implements OnInit {
    public gridViewData: ProjectRequestViewDto[] = [];
    public loading: boolean;
    private requestsData: ProjectRequest[] = [];

    public constructor(
        public translate: TranslateService,
        private _integrationService: IntegrationHttpService,
        private _dialogService: MatDialog,
        private _dialog: MatDialogRef<IntegrationRequestsDialogComponent>,
        private _snackBar: MatSnackBar) {
    }

    public async ngOnInit(): Promise<void> {
        await this.loadGridViewData();
    }

    public dispatchProject(projectRequest: ProjectRequestViewDto): void {
        this.onClose();
        const dialogTitle = this.translate.instant('shared.integrationRequestDialog.dispatchDialog.title');
        const integrationRequest = this.requestsData.find(_ => _.customerId === projectRequest.customerId &&
            _.projectId === projectRequest.projectId);
        this._dialogService.open(DispatchProjectDialogComponent, {
            data: {
                icon: 'edit',
                title: dialogTitle,
                integrationRequest
            } as DispatchDialogOptions,
            minHeight: '600px',
            maxWidth: '72vw',
            maxHeight: '95vh'
        });
    }

    public async cancelProject(projectRequest: ProjectRequestViewDto): Promise<void> {
        const dialogTitle = this.translate.instant('shared.integrationRequestDialog.confirmDialog.title');
        const cancelText = this.translate.instant('shared.integrationRequestDialog.confirmDialog.cancelText');
        const confirmText = this.translate.instant('shared.integrationRequestDialog.confirmDialog.confirmText');
        const message = this.translate.instant('shared.integrationRequestDialog.confirmDialog.message');

        const dialog = this._dialogService.open(ConfirmDialogComponent, {
            data: {
                title: dialogTitle,
                cancelText,
                confirmText,
                message,
                isWarning: true
            } as ConfirmDialogOption
        });

        const response = await dialog.afterClosed().toPromise();
        if (response) {
            await this.cancelProjectRequest(projectRequest.projectId, projectRequest.customerId);
        }
    }

    public onClose(): void {
        this._dialog.close();
    }

    private async loadGridViewData(): Promise<void> {
        this.loading = true;
        this.requestsData = await this._integrationService.getPendingProjectRequests();
        this.gridViewData = this.requestsData.map(_ => new ProjectRequestViewDto(_));
        this.loading = false;
    }

    private async cancelProjectRequest(projectId: string, customerId: string): Promise<void> {
        const response = await this._integrationService.cancelInBoundRequest(projectId, customerId);
        if (response) {
            await this.loadGridViewData();
        }
        else {
            const message = this.translate.instant('shared.integrationRequestDialog.cancelFailed');
            this._snackBar.open(message);
        }
    }
}
