<div *ngIf="generalInfo" class="project-details-container" fxLayout="column" fxLayoutGap="7px">
    <project-details-action-bar *ngIf="!isActionLoading"
        [generalInfo]="generalInfo"
        [jobId]="currentJob"
        (workflowSelect)="onWorkflowSelect($event)"
        (dispatchProject)="onDispatchProject($event)"
        (actionSelect)="onActionSelect($event)"
        (jobSelect)="onJobSelect($event)"
        (sectionSelect)="onSectionSelect($event)"
        (openSectionInNewWindow)="openSectionInNewWindow($event)"
        (printOptionSelect)="onPrintOptionSelect($event)"
        (reloadProject)="reloadDetails()">
    </project-details-action-bar>

    <ng-container *ngIf="!isContentLoading">

        <subcontract-general-info-panel *ngIf="isSubcontract && activeSection === projectDetailsSection.BasicInfo"
            [generalInfo]="generalInfo"
            [actionOptions]="dialogOptions"
            (actionSelect)="$event.callback()"
            (basicInfoUpdate)="updateGeneralInfo(generalInfo.basicInfo, $event.key, $event.key, $event.value)"
            (customerUpdate)="updateGeneralInfo(generalInfo.customer, $event.key, $event.key, $event.value)"
            (dispatchInfoProjectFieldUpdate)="updateGeneralInfo(generalInfo.dispatchInfo, $event.key, $event.key, $event.value)"
            (reportingContactUpdate)="onReportingContactUpdate($event)"
            (siteContactUpdate)="updateTemplateField(generalInfo.siteContact, $event)">
        </subcontract-general-info-panel>

        <project-general-info-panel *ngIf="!isSubcontract && activeSection === projectDetailsSection.BasicInfo"
            [generalInfo]="generalInfo"
            [actionOptions]="dialogOptions"
            (actionSelect)="$event.callback()"
            (basicInfoUpdate)="updateGeneralInfo(generalInfo.basicInfo, $event.key, $event.key, $event.value)"
            (customerUpdate)="updateGeneralInfo(generalInfo.customer, $event.key, $event.key, $event.value)"
            (dispatchInfoProjectFieldUpdate)="updateGeneralInfo(generalInfo.dispatchInfo, $event.key, $event.key, $event.value)"
            (dispatchInfoTemplateFieldUpdate)="updateTemplateField(generalInfo.dispatchInfo, $event)"
            (reportingContactUpdate)="onReportingContactUpdate($event)"
            (siteContactUpdate)="updateTemplateField(generalInfo.siteContact, $event)">
        </project-general-info-panel>

        <project-work-checklist *ngIf="activeSection === projectDetailsSection.Checklist"
            [projectId]="activeProjectId"
            [jobId]="currentJob">
        </project-work-checklist>

        <project-report-content *ngIf="activeSection === projectDetailsSection.JobReport"
            [generalInfo]="generalInfo"
            [jobId]="currentJob"
            [isLeakReport]="isLeakReport">
        </project-report-content>

        <project-daily-summary *ngIf="activeSection === projectDetailsSection.DailySummary"
            [projectId]="activeProjectId"
            [jobId]="currentJob">
        </project-daily-summary>

        <project-conclusion-summary *ngIf="activeSection === projectDetailsSection.Summary"
            [projectId]="activeProjectId"
            [jobId]="currentJob"
            [isLeakReport]="isLeakReport">
        </project-conclusion-summary>

        <project-labor-panel *ngIf="activeSection === projectDetailsSection.Labor"
            [projectId]="activeProjectId" fxFlex>
        </project-labor-panel>
    </ng-container>
</div>
