import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import FallHazardReport from '../../../data-models/data-report/fall-hazard-report';
import JsonUtility from '../../utility/json-utility.service';
import ReportQuery from '../../../data-models/data-report/report-query';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FallHazardHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/fallhazard`;

    constructor(private _http: HttpClient) { }

    public async getFallHazardReport(query: ReportQuery): Promise<FallHazardReport[]> {
        const endpoint = `${this._api}/report?branch=${query.branch}&salesCode=${query.jobType}`;

        return await this.sendGetRequest<FallHazardReport[]>(endpoint) ?? new Array<FallHazardReport>();
    }

    private async sendGetRequest<T>(endpoint: string): Promise<T> {
        try {
            const data = await this._http.get<T>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }
}
