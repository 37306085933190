import ProjectRequest from '../integration/project-request';
import QuoteGeneralInfo from '../quote-general-info/quote-general-info';
import { WorkCategory } from '../work-category';
import FastClientRequest from './fast-client-request';

export default class DispatchDialogOptions {
    public title: string;
    public icon: string;
    public emergencyProjectId: string;
    public quoteToAward: QuoteGeneralInfo;
    public fastClientRequest: FastClientRequest;
    public integrationRequest: ProjectRequest;
    public workCategory: WorkCategory;
    public budgetTypeOverride = '';
    public hasWebBudget = false;
}
