import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Branch from '../../../core/data-models/branch';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import TempProjectReport from '../../../core/data-models/data-report/temp-project-report';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-temporary-project-aging-report',
    styleUrls: ['./temporary-project-aging-report.component.scss'],
    templateUrl: './temporary-project-aging-report.component.html'
})

export class TemporaryProjectAgingReportComponent implements OnInit {
    @Input() public jobTypes: string[];
    @Input() public branches: Branch[];
    public gridViewData: TempProjectReport[] = [];
    @ViewChild(GridComponent) grid: GridComponent;
    public loading: boolean;
    public gridPageSize = 15;
    public selectedBranch: string;

    public constructor(private newRelicUtility: NewRelicUtilityService,
        private _projectHttpService: ProjectHttpService,
        private _userHttpService: UserHttpService) {
        this.allData = this.allData.bind(this);
    }

    public async ngOnInit(): Promise<void> {
        this.selectedBranch = (await this._userHttpService.getUserInfo()).branch;
    }

    public async loadReport(): Promise<void> {
        this.newRelicUtility.addPageAction('ReportType', 'TempProjectAgingReport', 'ReportAccess', this.selectedBranch);

        this.loading = true;
        this.gridPageSize = this.grid.pageSize;
        this.gridViewData = await this._projectHttpService.getTempProject(this.selectedBranch);
        this.grid.pageChange.emit({ skip: 0, take: this.gridPageSize });
        this.loading = false;
    }

    public getProjectAgeClass(age: number): string {
        if (age <= 2) {
            return 'badge-success';
        }
        return age <= 5 ? 'badge-warning' : 'badge-error';
    }

    public allData(): ExcelExportData {
        const result: ExcelExportData = {
            data: process(this.gridViewData, { }).data
        };

        return result;
    }

}
