import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import UserProfile from '../../../core/data-models/user-profile';
import QuoteSummary from '../../../core/data-models/summary-info/quote-summary';
import ProjectSummary from '../../../core/data-models/summary-info/project-summary';
import SummaryQueryResult from '../../../core/data-models/summary-info/summary-query-result';
import { AppEventService } from '../../../core/services/events/app-event/app-event.service';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';

@Component({
    selector: 'summary-list',
    styleUrls: ['./summary-list.component.scss'],
    templateUrl: './summary-list.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SummaryListComponent implements OnInit, OnDestroy {
    @Input() public activeId = '';
    @Input() public isLoading = false;
    @Input() public isProject = true;
    @Input() public isSimplified = false;
    @Input() public isFocusMode = false;
    @Input() public queryResult: SummaryQueryResult<ProjectSummary | QuoteSummary>;
    @Input() public isRainyDay?: boolean = false;
    @Output() public isFocusModeChange = new EventEmitter<boolean>();
    @Output() public listRefresh = new EventEmitter();
    @Output() public pageChanged = new EventEmitter();
    @Output() public summarySelected = new EventEmitter();
    @Output() public toggleExpanded = new EventEmitter();

    private _subscriptions = new Subscription();
    private _expanded = true;
    private _expanding = false;

    constructor(public translate: TranslateService, private _appEvents: AppEventService) { }

    get expanded(): boolean {
        return this._expanded;
    }

    get focusModeText(): string {
        if (!this.activeId) {
            return '';
        }

        const id = this.isProject ? ProjectIdUtility.toDisplayFormat(this.activeId) : this.activeId;
        const typeKey = this.isProject ? 'project' : 'quote';
        const type = this.translate.instant(`shared.summaryList.${typeKey}`);

        return this.translate.instant('shared.summaryList.showingTypeOnly', { type, id });
    }

    get containerClass(): string {
        return `summary-list-${this._expanded ? 'expanded' : 'collapsed'}`;
    }

    get currentPage(): string {
        if (!this.queryResult) {
            return '0 - 0 of 0';
        }

        const { result, page, pageSize } = this.queryResult;
        const start = (page - 1) * pageSize;
        const end = start + result.length;

        return `${result.length ? start + 1 : 0} - ${end}`;
    }

    get hasPreviousPage(): boolean {
        return this.queryResult?.page > 1;
    }

    get hasNextPage(): boolean {
        return this.queryResult?.hasNext;
    }

    get expandButtonTooltip(): string {
        const show = this.translate.instant('shared.summaryList.show');
        const hide = this.translate.instant('shared.summaryList.hide');
        const project = this.translate.instant('shared.summaryList.project');
        const quote = this.translate.instant('shared.summaryList.quote');
        const type = this.isProject ? project : quote;

        const val = this.translate.instant('shared.summaryList.showOrHideList', {
            showOrHide: this._expanded ? hide : show,
            type
        });

        return val;
    }

    public ngOnInit(): void {
        this._subscriptions.add(this._appEvents.projectViewersChanged.subscribe(_ => this.onProjectViewersChanged(..._)));
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public isActiveProject(summary: ProjectSummary): boolean {
        return ProjectIdUtility.toStorageFormat(this.activeId) === summary.projectIdDBformat;
    }

    public getAnimationDelay(index: number): string {
        const delay = this._expanding ? 0.7 : 0;

        return `${index * 0.03 + delay}s`;
    }

    public onProjectSummarySelected(summary: ProjectSummary): void {
        if (!summary.isRemoved) {
            this.summarySelected.emit(summary.projectIdDBformat);
        }
    }

    public onFocusModeOff(): void {
        this.isFocusMode = false;
        this.isFocusModeChange.emit(false);
    }

    public onListRefresh(): void {
        this._expanding = false;
        this.listRefresh.emit();
    }

    public onPageChange(change: number): void {
        this._expanding = false;
        this.pageChanged.emit(Math.max(1, this.queryResult.page + change));
    }

    public toLastPage(): void {
        this._expanding = false;
        this.pageChanged.emit(-1);
    }

    public onToggleExpand(): void {
        this._expanded = !this._expanded;
        this._expanding = true;
        this.toggleExpanded.emit(this._expanded);
    }

    private onProjectViewersChanged(projectId: string, viewers: UserProfile[]): void {
        if (!this.isProject || !this.queryResult) {
            return;
        }

        const projects = this.queryResult.result as ProjectSummary[];
        const project = projects.find(_ => _.projectIdDBformat === projectId);

        if (project) {
            project.currentViewers = viewers;
        }
    }
}
