<dialog-action-card
    [icon]="'edit_note'"
    title="{{ 'projects.changeServiceProviderDialog.title' | translate }}"
    [isLoaded]="true" (close)="onClose()">
    <div class="change-service-provider-dialog-content" fxLayout="column" fxLayoutGap="10px">
        <mat-form-field fxFlexAlign="start">
            <mat-label>{{ 'projects.changeServiceProviderDialog.serviceProvider' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedProvider">
                <mat-option *ngFor="let provider of providers" [value]="provider.code">{{provider.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <span class="change-service-provider-warning" fxFlex>
            {{ 'projects.changeServiceProviderDialog.warningText' | translate }}
        </span>
        <button mat-raised-button color="primary" (click)="submit()" fxFlexAlign="end">{{ 'projects.changeServiceProviderDialog.proceed' | translate }}</button>
    </div>
</dialog-action-card>
