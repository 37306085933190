import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import KeyValuePair from '../../../../core/data-models/key-value-pair';
import InformationGridItem from '../../../../core/data-models/information-grid/information-grid-item';
import InformationGridRow from '../../../../core/data-models/information-grid/information-grid-row';
import InformationGridConfig from '../../../../core/data-models/information-grid/information-grid-config';
import GridItemEditOption from '../../../../core/data-models/grid-item-edit-option';
import { InformationGridItemType } from '../../../../core/enums/information-grid-item-type.enum';

@Component({
    selector: 'information-grid-card',
    styleUrls: ['./information-grid-card.component.scss'],
    templateUrl: './information-grid-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationGridCardComponent implements OnInit {
    @Input() public icon = 'info';
    @Input() public useSvg = false;
    @Input() public hasAdditionalDetails = false;
    @Input() public title = 'information';
    @Input() public config: InformationGridConfig;
    @Input() public allowAnimation = true;
    @Input() public animationDelay = 0.5;
    @Output() public dataUpdate = new EventEmitter<KeyValuePair>();
    @Output() public itemDoubleClick = new EventEmitter<string>();
    @Output() public openAdditionalDetails = new EventEmitter();
    public readonly itemTypes = InformationGridItemType;
    private readonly _errors = new Map<string, string>();

    constructor(private _translate: TranslateService) {}

    get cardAnimationDuration(): string {
        return this.allowAnimation ? `${this.animationDelay / 0.5}s` : '0';
    }

    get contentAnimationDelay(): string {
        return this.allowAnimation ? `${this.animationDelay / 0.5 + 0.15}s` : '0';
    }

    get contentAnimationDuration(): string {
        return this.allowAnimation ? '0.15s' : '0';
    }

    public ngOnInit(): void {
        const { data, rows } = this.config;

        for (const { items } of rows) {
            const error = items.find(_ => !Object.prototype.hasOwnProperty.call(data, _.key));

            if (error) {
                const translationKey = 'shared.informationGrid.propertyMissingError';
                const errorMessage = this._translate.instant(translationKey, { errorKey: error.key });
                throw new Error(errorMessage);
            }
        }
    }

    public getRowStyle(row: InformationGridRow): { [key: string]: string } {
        return {
            height: row.height ? `${row.height}%` : null,
            'animation-delay': this.contentAnimationDelay
        };
    }

    public getEditOptions(item: InformationGridItem): GridItemEditOption {
        const { key, editOptions } = item;
        if (editOptions) {
            editOptions.editContent = this._errors.get(key) ?? null;
        }
        return editOptions;
    }

    public recordError(key: string, value: string): void {
        this._errors.set(key, value);
    }

    public removeError(key: string): void {
        this._errors.delete(key);
    }

    public onContentUpdate(key: string, value: string): void {
        this.dataUpdate.emit({ key, value });
    }

    public onItemDoubleClick(key: string): void {
        this.itemDoubleClick.emit(key);
    }
}
