import ValidatorSet from './validator-set';

export default class GridItemEditOption {
    public editContent: string = null;
    public maxLength = 60;
    public disableEsc = false;
    public validatorSets: ValidatorSet[] = [];
    public placeholder = '';
    public inputMask = '';
    //eslint-disable-next-line
    constructor(editContent = null, maxLength = 60, disableEsc = false, validatorSets = [], placeholder = '', inputMask = '') {
        this.editContent = editContent;
        this.maxLength = maxLength;
        this.disableEsc = disableEsc;
        this.validatorSets = validatorSets;
        this.placeholder = placeholder;
        this.inputMask = inputMask;
    }
}
