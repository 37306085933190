import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

import ActionProgressReporterOption from '../../../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';
import { CustomerCreationHttpService } from '../../../../../core/services/http/customer-creation-http/customer-creation-http.service';
import { ValidatorFactory } from '../../../../../core/services/validator-factory/validator-factory.service';
import Customer from '../../../../../core/data-models/customer';
import UpdateProjectDto from '../../../../../core/data-models/dto-models/update-project-dto';
import { SelectSiteDialogComponent } from '../../../../../shared/components/dialogs/select-site-dialog/select-site-dialog.component';
import SelectSiteDialogOptions from '../../../../../core/data-models/select-site-dialog-options';
import SOAddress from '../../../../../core/data-models/so-address';
import CustomerSiteDto from '../../../../../core/data-models/customer-site-dto';
import { ConfirmDialogComponent } from '../../../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import ConfirmDialogOption from '../../../../../core/data-models/confirm-dialog-option';
import { CountryName } from '../../../../../core/enums/country-name.enum';
import ProjectIdUtility from '../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import { FeatureFlagService } from '../../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../../core/enums/feature-flags.enum';
import CodeDescription from '../../../../../core/data-models/code-description';

@Component({
    selector: 'update-project-dialog',
    styleUrls: ['./update-project-dialog.component.scss'],
    templateUrl: './update-project-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UpdateProjectDialogComponent implements OnInit {

    public projectId: string;
    public customerId: string;
    public customer: Customer;
    public projectDetail: UpdateProjectDto;
    public projectUpdateFormGroup: UntypedFormGroup;
    public jobId: number;
    public allowSyncFromDynamics = false;
    private phoneValidator = this._validatorFactory.createPhoneValidator();
    private emailValidator = this._validatorFactory.createEmailValidator();
    private nameValidator = this._validatorFactory.createNameValidator();
    private _isInternal: boolean;

    // eslint-disable-next-line
    constructor(private _featureFlagService: FeatureFlagService,
                private _cctHttpService: CustomerCreationHttpService,
                private _projectHttpService: ProjectHttpService,
                private _snackBar: MatSnackBar,
                private _validatorFactory: ValidatorFactory,
                private _formBuilder: UntypedFormBuilder,
                private _dialogService: MatDialog,
                private _dialog: MatDialogRef<UpdateProjectDialogComponent>,
                private _dialogConfirmation: MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: { projectId: string, customerId: string, jobId: number },
                public translate: TranslateService) { }

    public async ngOnInit(): Promise<void> {
        this._isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        this.allowSyncFromDynamics = this._featureFlagService.featureFlags[FeatureFlags.AllowSyncCustomerFromErp];
        this.customerId = this.data.customerId;
        this.projectId = this.data.projectId;
        this.jobId = this.data.jobId;
        this.createUpdateFormGroup();
        this.customer = await this._cctHttpService.getCustomerById(this.customerId);
        this.projectDetail = await this._projectHttpService.getProjectsCustomerInfo(this.projectId, this.jobId);
        const infoLoadFailure = !this.customer || !this.projectDetail;

        if (infoLoadFailure) {
            const ok = this.translate.instant('snackbars.ok');
            this._snackBar.open(this.translate.instant('projects.update.errorText'), ok);
            return;
        }

        if (this._isInternal) {
            // only internal users have temp sites
            this.customer.addresses = this.customer.addresses.filter(_ => !this.isTempSiteId(_.id));
        }

        this.setFormControlValue(this.projectUpdateFormGroup, 'projectName', this.projectDetail.projectName);
        this.setFormControlValue(this.projectUpdateFormGroup, 'customerId', this.projectDetail.customerId);
        this.setFormControlValue(this.projectUpdateFormGroup, 'customerPONumber', this.projectDetail.customerPONumber);
        this.setFormControlValue(this.projectUpdateFormGroup, 'projectId', this.projectId);
        this.setFormControlValue(this.billingInformation, 'siteFax', this.projectDetail.billing.siteFax);
        this.setFormControlValue(this.billingInformation, 'name', this.projectDetail.billing.name);
        this.loadProjectFormAddress(this.projectDetail.site, this.siteInformation);
        this.loadProjectFormAddress(this.projectDetail.billing, this.billingInformation);
    }

    get siteInformation(): UntypedFormGroup {
        return this.projectUpdateFormGroup.get('site') as UntypedFormGroup;
    }

    get billingInformation(): UntypedFormGroup {
        return this.projectUpdateFormGroup.get('billing') as UntypedFormGroup;
    }

    public async reselectProjectSite(): Promise<void> {
        const availableSites = this.customer.addresses;
        const newSiteAddress = await this.launchSiteSelectionDialog(availableSites, this.translate.instant('projects.update.selectSite'));
        if (!newSiteAddress) {
            return;
        }
        const newSiteDto = new CustomerSiteDto(newSiteAddress);
        this.loadProjectFormAddress(newSiteDto, this.siteInformation);
        this.projectUpdateFormGroup.markAllAsTouched();
    }

    public async reselectBillingSite(): Promise<void> {
        const billingSites = this.customer.addresses.filter(_ => _.isBillAddress);
        const newBillingAddress = await this.launchSiteSelectionDialog(billingSites, this.translate.instant('projects.update.selectBillingSite'));
        if (!newBillingAddress) {
            return;
        }
        const newBillingDto = new CustomerSiteDto(newBillingAddress);
        this.loadProjectFormAddress(newBillingDto, this.billingInformation);
        this.setFormControlValue(this.billingInformation, 'name', newBillingDto.name);
        this.setFormControlValue(this.billingInformation, 'siteFax', newBillingDto.siteFax);
        this.projectUpdateFormGroup.markAllAsTouched();
    }

    public async updateProject(): Promise<void> {
        if (await this.confirmProceedWithProjectUpdate()) {
            const updatedProjectInfo = this.projectUpdateFormGroup.value as UpdateProjectDto;
            await this.runAction(this._projectHttpService.updateProjectInfo(updatedProjectInfo, this.jobId));
        }
        else {
            this.siteInformation.markAllAsTouched();
            this.billingInformation.markAllAsTouched();
        }
    }

    public async syncFromDynamics(): Promise<void> {
        const title = this.translate.instant('projects.update.syncFromDynamics.title');
        const message = this.translate.instant('projects.update.syncFromDynamics.htmlMessage');
        const data = new ConfirmDialogOption(title, '', true, 'Yes', 'No', message);
        const dialog = this._dialogService.open(ConfirmDialogComponent, { maxWidth: '400px', data });

        if (await dialog.afterClosed().toPromise()) {
            if (await this.confirmProceedWithProjectUpdate()) {
                await this.runAction(this._projectHttpService.syncCustomerInfoFromErp(this.projectId));
            }
            else {
                this.siteInformation.markAllAsTouched();
                this.billingInformation.markAllAsTouched();
            }
        }
    }

    private async runAction(action: Promise<CodeDescription>): Promise<void> {
        const ongoing = this.translate.instant('projects.update.ongoing1');
        const success = this.translate.instant('projects.update.success1');
        const error = this.translate.instant('projects.update.error1');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise()) {
            this._dialog.close(true);
        }
    }

    private async confirmProceedWithProjectUpdate(): Promise<boolean> {
        if (!this.projectUpdateFormGroup.valid) {
            return false;
        }

        if (!this.jobSiteAndProjectBranchInSameCountry()) {
            const action = of(false).toPromise();
            const ongoing = this.translate.instant('projects.update.ongoing2');
            const error = ProjectIdUtility.isUsProject(this.projectId) ?
                this.translate.instant('projects.update.error2'):
                this.translate.instant('projects.update.error3');
            const ok = this.translate.instant('shared.ok');
            const gotIt = this.translate.instant('shared.gotIt');
            const dialogData = new ActionProgressReporterOption(action, ongoing, '', error, ok, gotIt);
            const dialog = this._dialogService.open(ActionProgressDialogComponent, {
                data: dialogData,
                width: '550px',
                height: '120px'
            });

            await dialog.afterClosed().toPromise();
            return false;

        }

        if (this.projectDetail.numberOfJobs === 1) {
            return true;
        }

        const message = this.translate.instant('projects.update.multipleVisits');
        const data = new ConfirmDialogOption(
            this.translate.instant('projects.update.confirmationQuestion'),
            message,
            true,
            this.translate.instant('projects.update.confirmationResponse')
        );
        const dialog = this._dialogService.open(ConfirmDialogComponent, { maxWidth: '400px', data });

        return await dialog.afterClosed().toPromise();

    }

    public onClose(): void {
        this._dialog.close(false);
    }

    private setFormControlValue(form: UntypedFormGroup, controlName: string, value: string): void {
        const control = form.get(controlName);
        control.setValue(value);
        control.updateValueAndValidity();
    }

    private jobSiteAndProjectBranchInSameCountry(): boolean {
        const jobSiteCountry = this.siteInformation.get('country').value;

        const bothAmerican = jobSiteCountry === CountryName.US && ProjectIdUtility.isUsProject(this.projectId);
        const bothCanadian = jobSiteCountry === CountryName.CA && !ProjectIdUtility.isUsProject(this.projectId);

        return bothAmerican || bothCanadian;
    }

    private createUpdateFormGroup(): void {
        this.projectUpdateFormGroup = this._formBuilder.group({
            projectName: ['', [this.nameValidator?.validator, Validators.required]],
            customerId: [''],
            projectId: [''],
            customerPONumber: [''],
            site: this._formBuilder.group({
                shipToId: [''],
                address1: [''],
                address2: [''],
                city: [''],
                state: [''],
                zip: [''],
                country: [''],
                siteAttention: ['', Validators.required],
                sitePhone: ['', [this.phoneValidator.validator, Validators.required]],
                siteEmail: ['', [this.emailValidator.validator]],
                storeNumber: ['']
            }),
            billing: this._formBuilder.group({
                shipToId: [''],
                name: ['', this._isInternal ? Validators.maxLength(60) : null],
                address1: [''],
                address2: [''],
                city: [''],
                state: [''],
                zip: [''],
                country: [''],
                siteAttention: [''],
                sitePhone: ['', [this.phoneValidator.validator, Validators.required ]],
                siteEmail: ['', [this.emailValidator.validator, Validators.required ]],
                siteFax: ['', this.phoneValidator.validator],
                storeNumber: ['']
            })
        });
    }

    private loadProjectFormAddress(inputAddress: CustomerSiteDto, inputForm: UntypedFormGroup): void {

        this.setFormControlValue(inputForm, 'shipToId', inputAddress.shipToId);
        this.setFormControlValue(inputForm, 'address1', inputAddress.address1);
        this.setFormControlValue(inputForm, 'address2', inputAddress.address2);
        this.setFormControlValue(inputForm, 'city', inputAddress.city);
        this.setFormControlValue(inputForm, 'state', inputAddress.state);
        this.setFormControlValue(inputForm, 'zip', inputAddress.zip);
        this.setFormControlValue(inputForm, 'country', inputAddress.country);
        this.setFormControlValue(inputForm, 'siteAttention', inputAddress.siteAttention);
        this.setFormControlValue(inputForm, 'sitePhone', inputAddress.sitePhone);
        this.setFormControlValue(inputForm, 'siteEmail', inputAddress.siteEmail);
        this.setFormControlValue(inputForm, 'storeNumber', inputAddress.storeNumber);

    }

    private async launchSiteSelectionDialog(sites: SOAddress[], title): Promise<SOAddress> {

        const selectSiteDialog = this._dialogService.open(SelectSiteDialogComponent, {
            data: {
                customerDisplayName: '',
                availableSites: sites,
                title,
                icon: 'local_atm',
                enableClose: true
            } as SelectSiteDialogOptions,
            width: '950px',
            height: '720px'
        });

        return await selectSiteDialog.afterClosed().toPromise();
    }

    private isTempSiteId(siteId: string): boolean {
        const noDashesId = siteId.replace(/-/g, '');

        return Boolean(noDashesId.match(/^0+$/));
    }

}
