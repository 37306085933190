import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import Branch from '../../../core/data-models/branch';
import Crew from '../../../core/data-models/crew';
import HealthScreeningOption from '../../../core/data-models/health-screening-option';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { CrewHttpService } from '../../../core/services/http/crew-http/crew-http.service';
import { BranchHttpService } from '../../../core/services/http/branch-http/branch-http.service';
import { CustomerHttpService } from '../../../core/services/http/customer-http/customer-http.service';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';
import { CountryName } from '../../../core/enums/country-name.enum';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { HealthScreeningRequirement } from '../../../core/enums/health-screening-requirement.enum';

@Component({
    selector: 'crew-selector',
    templateUrl: './crew-selector.component.html',
    styleUrls: ['./crew-selector.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CrewSelectorComponent implements OnInit, OnChanges {
    @Input() public horizontalMode: boolean;
    @Input() public initiallySelectedCrew: Crew;
    @Input() public branchCodeRestriction = '';
    @Input() public country: CountryName;
    @Input() public healthScreeningOption: HealthScreeningOption;
    @Input() public defaultCrewId?: number;
    @Input() public defaultBranch?: string;
    @Input() public isNotEditable: boolean;
    @Output() public crewSelectedEvent = new EventEmitter<Crew>();
    private _branchList: Branch[] = [];
    public healthScreeningRequirement = HealthScreeningRequirement.NotAvailable;
    public branchList: Branch[];
    public crewList: Crew[];
    public selectedBranch: Branch;
    public selectedCrew: Crew;
    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
                private _userHttp: UserHttpService,
                private _crewHttp: CrewHttpService,
                private _customerHttp: CustomerHttpService,
                private _branchHttp: BranchHttpService,
                private _featureFlagService: FeatureFlagService) { }

    get branchSelectionLabel(): string {
        const key = this.isInternal ? 'branch' : 'serviceProvider';

        return this.translate.instant(`shared.crewSelector.${key}`);
    }

    get loadingErrorText(): string {
        const key = this.isInternal ? 'branchLoadingError' : 'serviceProviderLoadingError';

        return this.translate.instant(`shared.crewSelector.${key}`);
    }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    public async ngOnInit(): Promise<void> {
        this._branchList = await this._branchHttp.getAllBranches();
        this.setBranchesByCountry();

        if (this.initiallySelectedCrew) {
            this.selectedBranch = this.branchList.find(_ => _.name === this.initiallySelectedCrew.foreman.locn);
            await this.loadCrewFromSap(this.selectedBranch);
            const crew = this.crewList.find(_ => _.crewId === this.initiallySelectedCrew.crewId);
            this.selectedCrew = crew;
            await this.updateCrew(crew);
        }
        else {
            const { branchInfo } = await this._userHttp.getUserInfo();
            const code = this.branchCodeRestriction ? this.branchCodeRestriction : branchInfo.code;
            if (this.defaultBranch) {
                this.selectedBranch = this.branchList.find(_ => _.name === this.defaultBranch);
            }
            else {
                this.selectedBranch = this.branchList.find(_ => _.code === code);
            }

            if (this.selectedBranch) {
                await this.loadCrewFromSap(this.selectedBranch);
            }

            if (this.defaultCrewId) {
                const crew = this.crewList.find(_ => _.crewId === this.defaultCrewId);
                this.selectedCrew = crew;
                await this.updateCrew(crew);
            }
        }

        if (this.branchCodeRestriction) {
            this._branchList = [this.selectedBranch];
            this.branchList = this._branchList;
        }
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (this.isInternal && changes.healthScreeningOption?.currentValue) {
            const { customerId, siteId } = this.healthScreeningOption;
            this.healthScreeningRequirement = await this._customerHttp.getHealthScreeningRequirement(customerId, siteId);
        }

        if (!this.branchCodeRestriction && changes.country && !changes.country.isFirstChange()) {
            this.setBranchesByCountry();

            if (!this.branchMatchSelectedCountry()) {
                this.selectedBranch = null;
                this.crewList = [];
                this.resetSelectedCrew();
            }
        }
    }

    private branchMatchSelectedCountry(): boolean {

        if (!this.selectedBranch?.code || !this.country) {
            return true;
        }

        const bothAmerican = ProjectIdUtility.isUsProject(this.selectedBranch.code) && this.country === CountryName.US;
        const bothCanadian = !ProjectIdUtility.isUsProject(this.selectedBranch.code) && this.country === CountryName.CA;

        return bothAmerican || bothCanadian;
    }

    public async loadCrewFromSap(branch: Branch): Promise<void> {
        this.resetSelectedCrew();
        this.crewList = [];
        this.crewList = await this._crewHttp.getCrewsByBranch(branch);
    }

    public async updateCrew(crew: Crew): Promise<void> {
        if (crew.workers?.length) {
            this.crewSelectedEvent.emit(crew);
            return;
        }

        this.selectedCrew.workers = await this._crewHttp.getCrewWorkersFromSapByCrewId(crew.crewId);
        this.crewSelectedEvent.emit(crew);
    }

    private resetSelectedCrew(): void {
        this.crewSelectedEvent.emit(null);
        this.selectedCrew = null;
    }

    private setBranchesByCountry(): void {
        if (this.country === CountryName.US || this.country === CountryName.CA) {
            this.branchList = this._branchList.filter(_ => {
                const isUSCode = ProjectIdUtility.isUsProject(_.code);
                return this.country === CountryName.US && isUSCode || this.country === CountryName.CA && !isUSCode;
            });
        }
        else {
            this.branchList = [...this._branchList];
        }
    }

}
