import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { UserTrackingHttpService } from '../../../../core/services/http/user-tracking-http/user-tracking-http.service';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-dashboard-view-dialog',
    styleUrls: ['./dashboard-view-dialog.component.scss'],
    templateUrl: './dashboard-view-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DashboardViewDialogComponent implements OnInit {
    public serviceDashboardUrl: SafeResourceUrl;
    public disableAcknowledge = true;
    private dialogLoadTime = new Date();

    public constructor(public translate: TranslateService,
        private _dialog: MatDialogRef<DashboardViewDialogComponent>,
        private _sanitizer: DomSanitizer,
        private _userTrackingService: UserTrackingHttpService) {
    }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        this.serviceDashboardUrl =
            this._sanitizer.bypassSecurityTrustResourceUrl(`${environment.serviceDashboardUrl}service_manager`);
        setTimeout(() => {
            this.disableAcknowledge = false;
        }, 6000);
    }

    public onClose(): void {
        const closingTime = new Date();
        this._userTrackingService.trackDashboardAcknowledgement(closingTime.getTime() - this.dialogLoadTime.getTime());
        this._dialog.close();
    }
}
