<div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" fxFill>
    <mat-card fxLayout="row" fxLayoutGap="10px" fxFill class="report-template-main-content" *ngIf="!isLoading">
        <report-template-editor fxFlex="75" [templates]="templates" [placeholderEvents]="placeholderChanges.asObservable()"
            (templateSelected)="setSelectedTemplate($event)" [selectedTemplate]="initialTemplate"></report-template-editor>
        <mat-card fxLayout="column" fxFlex="25" fxLayoutGap="15px">
            <div fxFlex>
                <placeholder-list [template]="selectedTemplate" (placeholderChanged)="onPlaceholderChange($event)"></placeholder-list>
            </div>
            <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
                <button  mat-stroked-button color="primary" (click)="previewTemplate()">
                    {{'templateEditor.controls.preview' | translate }}
                </button>
                <div fxFlex></div>
                <button  mat-flat-button color="warn" [disabled]="isDeleteDisabled" (click)="deleteTemplate()">
                    {{'templateEditor.controls.delete' | translate }}
                </button>
                <button  mat-flat-button color="primary" [disabled]="isSaveDisabled" (click)="saveTemplate()">
                    {{'templateEditor.controls.save' | translate }}
                </button>
            </div>
        </mat-card>
    </mat-card>
    <mat-card fxLayout="row" fxLayoutGap="10px" fxFill *ngIf="isLoading" fxLayoutAlign="center center">
        <dots-loader></dots-loader>
    </mat-card>
</div>
