import LocalizedContent from '../../../data-models/localized-content';
import LanguageSet from '../../../data-models/language-set';
import { LanguageKey } from '../../../enums/language-key.enum';

const thousands = ['', 'thousand', 'million', 'billion', 'trillion'];
const ones = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
const tens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
const otherTens = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

export default class LanguageUtility {

    public static getLocalizedContent(key: string, contents: LocalizedContent[]): string {
        const localized = contents.find(_ => _.languageKey === key);

        if (localized) {
            return localized.content;
        }

        return contents.find(_ => _.languageKey === 'default')?.content ?? '';
    }

    public static getLanguageSetContent(key: string, contents: LanguageSet[], fallback = ''): string {
        if (!contents || contents.length === 0) {
            return fallback;
        }

        // language set may contain language key such as 'fr-CA' and we only need first 2 letters
        const content = contents.find(_ => _.languageId.slice(0, 2) === key);

        if (content) {
            return content.name;
        }

        return contents.find(_ => _.languageId === LanguageKey.EN)?.name ?? fallback;
    }

    public static currencyToEnglish(value: number): string {
        const [integers, decimals] = Number(value).toFixed(2).split('.');
        let converted = `${this.numberToEnglish(integers)} dollars`;

        if (decimals !== '00') {
            converted += ` and ${this.numberToEnglish(decimals)} cents`;
        }

        return converted.replace(/\s+/g, ' ').trim().toUpperCase();
    }

    public static numberToEnglish(value: string): string {
        let converted = '';

        for (let i = 0, canUseThousands = false, digits = value.split(''); i < digits.length; ++i) {
            if (digits[i] !== '0') {
                canUseThousands = true;

                if ((digits.length - i) % 3 === 2) {
                    const useTens = digits[i] === '1';
                    const index = useTens ? Number(digits[i++ + 1]) : Number(digits[i]) - 2;
                    converted += `${useTens ? tens[index] : otherTens[index]} `;
                }
                else {
                    converted += `${ones[digits[i]]} ${(digits.length - i) % 3 ? '' : 'hundred '}`;
                }
            }

            if ((digits.length - i) % 3 === 1 && canUseThousands) {
                converted += `${thousands[(digits.length - i - 1) / 3]} `;
                canUseThousands = false;
            }
        }

        return converted.replace(/\s+/g, ' ').trim();
    }
}
