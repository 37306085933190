import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
// eslint-disable-next-line
import { FormControlUtilityService } from '../../../../../../core/services/utility/form-control-utility-service/form-control-utility.service';

@Component({
    selector: 'merge-project-reason',
    styleUrls: ['./merge-project-reason.component.scss'],
    templateUrl: './merge-project-reason.component.html'
})
export class MergeProjectReasonComponent implements OnInit {

    @Output() submitMergeReason = new EventEmitter<UntypedFormGroup>();
    @Input() requiresReason: boolean;
    public mergeReasonFormGroup: UntypedFormGroup;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _formService: FormControlUtilityService,
        public translate: TranslateService
    ) {
        this.mergeReasonFormGroup = this._formBuilder.group({
            conversionReason: ['', [Validators.required, Validators.minLength(10)]],
            targetProjectId: ['', Validators.required]
        });
    }

    public ngOnInit(): void {
        if (!this.requiresReason) {
            this.mergeReasonFormGroup.get('conversionReason').setValidators(null);
            this.mergeReasonFormGroup.get('conversionReason').updateValueAndValidity();
        }
    }

    public submitInfo(): void {
        if (this.mergeReasonFormGroup.valid) {
            this.submitMergeReason.emit(this.mergeReasonFormGroup);
        }
    }

    public isFormControlValid(controlName: string): boolean {
        return this.mergeReasonFormGroup.get(controlName).invalid;
    }

    public getErrorMessage(controlName: string): string {
        return this._formService.getErrorMessage(this.mergeReasonFormGroup.get(controlName));
    }


}
