<div class="condition-report-disclaimer-container"
    fxLayout="column"
    fxLayoutAlign="space-between center"
    fxLayoutGap="1.5vh">

    <div class="condition-report-disclaimer-content" [ngStyle]="{ opacity: isLoaded ? 1 : 0 }">
        <textarea [id]="editorId"></textarea>

        <mat-button-toggle-group class="condition-report-disclaimer-countries"
            [(ngModel)]="country"
            (ngModelChange)="onCountrySelected()">

            <mat-button-toggle *ngFor="let countryName of countries" [value]="countryName">
                {{ countryName }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <button mat-raised-button
        color="primary"
        [disabled]="!canSave"
        (click)="saveDisclaimer()"
        fxFlexAlign="end">

        {{ 'admin.disclaimerSettings.conditionReportDisclaimer.saveDisclaimerButtonLabel' | translate }}
    </button>
</div>
