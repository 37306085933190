import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import QuoteStatus from '../../../data-models/quote-status';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class QuoteStatusHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/quote-status`;
    private _statusPromise: Promise<QuoteStatus[]> = null;

    constructor(private _http: HttpClient) { }

    public async getAllStatuses(useCache = true): Promise<QuoteStatus[]> {
        try {
            if (!useCache || !this._statusPromise) {
                this._statusPromise = this._http.get<QuoteStatus[]>(this._api).toPromise();
            }

            return JsonUtility.toCamelCaseKeys(await this._statusPromise);
        }
        catch {
            return [];
        }
    }

    public async getTargetStatusesByEnglishName(englishName: string): Promise<QuoteStatus[]> {
        try {
            const endpoint = `${this._api}/${englishName}/target-statuses`;
            const data = await this._http.get<QuoteStatus[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }
}
