<mat-card class="project-attachment-container" fxLayout="column" fxFlex fxLayoutGap="20px" fxFlexFill>

    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="90" class="file-uploads-container">
            <ngx-file-drop dropZoneClassName="upload-file-drop-zone" dropZoneLabel="{{ 'projects.attachments.dropZoneLabel' | translate }}" (onFileDrop)="dropped($event)">
                <ng-template
                    ngx-file-drop-content-tmp
                    let-openFileSelector="openFileSelector">
                    <div class="upload-file-drop-content" fxLayout="row" fxLayoutAlign="center center">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                            <mat-icon>get_app</mat-icon>
                            <span> {{ 'projects.attachments.dragDrop' | translate }} </span>
                        </div>
                        <div class="upload-file-upload-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <button mat-stroked-button color="primary" (click)="openFileSelector()">
                                {{ 'projects.attachments.browse' | translate }}
                            </button>
                        </div>
                    </div>
                </ng-template>
            </ngx-file-drop>
        </div>
    </div>
    <div fxFlex="100">
        <kendo-grid *ngIf="!loading" fxFill [kendoGridBinding]="showDeleted? deletedAttachmentList: projectAttachmentList" [scrollable]="true" [pageSize]="10" [pageable]="true">
            <ng-template kendoGridToolbarTemplate>
                <div class="project-attachments-delete-button-container" [matTooltip]="deletedPhotosToolTip">
                    <button [disabled]="deletedAttachmentList?.length < 1 && !showDeleted" [className]="'kendo-grid-delete-attachment-button k-button'" (click)="showDeletedAttachments()">
                        {{ showDeleted ? ('projects.attachments.attachments' | translate) : ('projects.attachments.deletedAttachments' | translate) }}
                    </button>
                </div>
            </ng-template>

            <kendo-grid-column width="50" class="file-icon-div">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <div class="file-icon-text" [style.background-color]="getColorByFileType(dataItem.name)">
                        <div>
                            {{dataItem.name.split('.').pop()}}
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="500" field="name" title="{{ 'projects.attachments.table.name' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column title="{{ 'projects.attachments.table.type' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    {{ dataItem.type | attachmentType }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="viewCount" title="{{ 'projects.attachments.table.views' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column *ngIf="isInternal" title="{{ 'projects.attachments.visibleToCustomer' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <mat-checkbox *ngIf="showVisibleToCustomer(dataItem.name) && !showDeleted"
                        [(ngModel)]="dataItem.visibleToCustomer"
                        (change)="modifyVisibleToCustomerFlag(dataItem)">
                    </mat-checkbox>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{ 'projects.attachments.visibleToTech' | translate }}" *ngIf="isInternalWorkCategory">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <mat-checkbox *ngIf="!dontShowViewableCheckbox(dataItem.type) && !showDeleted"
                        [checked]="dataItem.status==='a'"
                        (change)="modifyAttachmentsViewableStatus(dataItem)">
                    </mat-checkbox>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{ 'projects.attachments.table.uploadDate' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    {{getLocalTime(dataItem.statusDate)}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="100">
                <ng-template *ngIf="!showDeleted" kendoGridCellTemplate let-dataItem="dataItem">
                    <div fxLayout="row" fxLayoutAlign="space-around center">
                        <button *ngIf="!isNotRemovable(dataItem.type)" (click)="deleteAttachment(dataItem.projectId, dataItem.name)" mat-icon-button color="warn" aria-label="delete button"
                        [matTooltip]="translate.instant('projects.attachments.tooltips.delete')">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button *ngIf="dataItem.processingStatus === 'done' || dataItem.processingStatus === null" (click)="downloadAttachment(dataItem)" mat-icon-button color="primary" aria-label="download button"
                        [matTooltip]="translate.instant('projects.attachments.tooltips.download')">
                        <mat-icon>cloud_download</mat-icon>
                    </button>
                    <div *ngIf="dataItem.processingStatus === 'processing'">
                        <div fxLayout="column" fxLayoutAlign="center center" fxFill>
                            <dots-loader></dots-loader>
                        </div>
                    </div>
                    <div *ngIf="dataItem.processingStatus === 'failed'" fxLayout="row" fxLayoutAlign="center center" mat-icon-button color="error" aria-label="error">
                        <mat-icon class="failed-report" [matTooltip]="translate.instant('projects.attachments.tooltips.failed')">error</mat-icon>
                    </div>
                    </div>
                </ng-template>
                <ng-template *ngIf="showDeleted" kendoGridCellTemplate let-dataItem="dataItem">
                        <button (click)="restoreProjectAttachment(dataItem.projectId, dataItem.name)" mat-mini-fab color="primary" aria-label="restore"
                        [matTooltip]="translate.instant('projects.attachments.tooltips.restore')">
                        <mat-icon>restore_from_trash</mat-icon>
                    </button>
                </ng-template>

            </kendo-grid-column>
        </kendo-grid>
    </div>
    <div *ngIf="loading"> <dots-loader></dots-loader> </div>
</mat-card>
