import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import ProjectGeneralInfo from '../../../../../core/data-models/project-general-info/project-general-info';
import ConfirmDialogOption from '../../../../../core/data-models/confirm-dialog-option';
import PromptDialogOption from '../../../../../core/data-models/prompt-dialog-option';
import UploadBudgetDialogOption from '../../../../../core/data-models/upload-budget-dialog-option';
import SwapProjectDialogOption from '../../../../../core/data-models/swap-project-dialog-option';
import SwapProjectData from '../../../../../core/data-models/swap-project-data';
import ProjectRequestBase from '../../../../../core/data-models/project-request-base';
import SwapProjectDialogResult from '../../../../../core/data-models/swap-project-dialog-result';
import ActionProgressReporterOption from '../../../../../core/data-models/action-progress-reporter-option';
import QuoteEditorOption from '../../../../../core/data-models/quote-editor-option';
import ProjectIdUtility from '../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import { ProjectAction } from '../../../../../core/enums/project-action.enum';
// eslint-disable-next-line
import { ConfirmDialogComponent } from '../../../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
// eslint-disable-next-line
import { UploadBudgetDialogComponent } from '../../project-details-action-bar/upload-budget-dialog/upload-budget-dialog.component';
import { SwapProjectDialogComponent } from '../../project-details-action-bar/swap-project-dialog/swap-project-dialog.component';
import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';
import { ProjectWorkflowService } from '../project-workflow/project-workflow.service';
import { PromptDialogComponent } from '../../../../../shared/components/dialogs/prompt-dialog-component/prompt-dialog.component';
import { DynamicsProjectStatus } from '../../../../../core/enums/dynamics-project-status.enum';
import { QuoteEditorComponent } from '../../../../../shared/components/quote-editor/quote-editor.component';
// eslint-disable-next-line
import { UnmergeProjectDialogComponent } from '../../project-details-action-bar/unmerge-project-dialog/unmerge-project-dialog.component';
// eslint-disable-next-line
import { MergeProjectDialogComponent } from '../../project-details-action-bar/merge-project-dialog/merge-project-dialog.component';
// eslint-disable-next-line
import { UpdateProjectDialogComponent } from '../../project-details-action-bar/update-project-dialog/update-project-dialog.component';
// eslint-disable-next-line
import { ActionProgressDialogComponent } from '../../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { DispatchProjectDialogComponent } from '../../../../dispatch/dispatch-project-dialog/dispatch-project-dialog.component';
import DispatchDialogOptions from '../../../../../core/data-models/dispatch/dispatch-dialog-options';
import { ProjectReviewHttpService } from '../../../../../core/services/http/project-review-http/project-review-http.service';
import { TranslateService } from '@ngx-translate/core';
import ProjectActionPayload from '../../../../../core/data-models/project-options/project-action-payload';
import { ChangeServiceProviderDialogComponent } from '../../project-details-action-bar/change-service-provider-dialog/change-service-provider-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ProjectActionService {

    // eslint-disable-next-line max-params
    constructor(private _projectHttp: ProjectHttpService,
                private _workflowService: ProjectWorkflowService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _projectReviewHttp: ProjectReviewHttpService,
                private _translate: TranslateService) { }

    // eslint-disable-next-line
    public async handleAction(action: string, generalInfo: ProjectGeneralInfo, options: ProjectActionPayload): Promise<boolean> {
        switch (action) {
            case ProjectAction.CreateFollowUpQuote:
                return await this.createFollowUpQuote(generalInfo);
            case ProjectAction.UploadBudget:
                return await this.uploadBudget(generalInfo);
            case ProjectAction.ConvertProject:
                return await this.convertProject(generalInfo);
            case ProjectAction.SwapProjectTo312:
            case ProjectAction.SwapProjectTo315:
                return await this.swapProject(generalInfo);
            case ProjectAction.UnmergeProject:
                return await this.unmergeProject(generalInfo);
            case ProjectAction.DeleteProject:
                return await this.deleteProject(generalInfo);
            case ProjectAction.MergeProject:
                return await this.mergeProject(generalInfo);
            case ProjectAction.UpdateProject:
                return await this.updateProject(generalInfo, options);
            case ProjectAction.ChangeServiceProvider:
                return await this.changeServiceProvider(generalInfo);
            default:
                return false;
        }
    }

    private async createFollowUpQuote(generalInfo: ProjectGeneralInfo): Promise<boolean> {
        const dialog = this._dialog.open(QuoteEditorComponent, {
            data: QuoteEditorOption.getCreateOption(generalInfo),
            maxWidth: '87.5vw',
            width: '87.5vw',
            height: '95vh'
        });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private async uploadBudget(generalInfo: ProjectGeneralInfo): Promise<boolean> {
        const { projectId } = generalInfo.basicInfo;
        const { customerId } = generalInfo.customer;
        const isTemporary = ProjectIdUtility.isTemporary(projectId);
        const status = await this._projectHttp.getDynamicsProjectStatus(projectId);

        if (status !== DynamicsProjectStatus.Active && !isTemporary) {
            this._snackBar.open(this._translate.instant('services.projectAction.cannotUploadBudgetInactiveDynamics'));

            return false;
        }

        if (await this._projectReviewHttp.isBudgetUploaded(projectId)) {
            const key = 'services.projectAction.budgetAlreadyInDynamicsMovingForwardWithApproval';
            this._snackBar.open(this._translate.instant(key));
        }
        else {
            const dialog = this._dialog.open(UploadBudgetDialogComponent, {
                data: new UploadBudgetDialogOption(projectId, customerId),
                width: '650px',
                height: '410px'
            });

            if (!await dialog.afterClosed().toPromise()) {
                return false;
            }
        }

        return await this._workflowService.approveAfterBudgetUpload(projectId);
    }

    private async convertProject(generalInfo: ProjectGeneralInfo): Promise<boolean> {
        const { projectId } = generalInfo.basicInfo;
        const willBeApproved = await this._projectHttp.willBeAutoApproved(projectId);
        const title = this._translate.instant('services.projectAction.convertIdToDynamics', {
            id: projectId
        });

        const message = this._translate.instant('services.projectAction.convertHelpText');
        let warning = this._translate.instant('services.projectAction.convertWarningText');
        warning = willBeApproved ? warning : '';
        const titleText = this._translate.instant('services.projectAction.convertTitle');

        if (await this.confirmAction(message, warning, title)) {
            const dialog = this._dialog.open(DispatchProjectDialogComponent, {
                data: {
                    icon: 'edit',
                    title: titleText,
                    emergencyProjectId: projectId,
                    workCategory: generalInfo.workCategory
                } as DispatchDialogOptions,
                minHeight: '600px',
                minWidth: '1150px',
                maxWidth: '72vw',
                maxHeight: '95vh'
            });

            return dialog.afterClosed().toPromise();
        }

        return false;
    }

    private async swapProject(generalInfo: ProjectGeneralInfo): Promise<boolean> {
        const { projectId } = generalInfo.basicInfo;
        const { siteId, customerId } = generalInfo.customer;
        const billWith = await this._projectHttp.getProjectBillWith(projectId);
        const allowCustomerChange = !billWith || ProjectIdUtility.toStorageFormat(billWith) === ProjectIdUtility.toStorageFormat(projectId);

        const swapDialog = this._dialog.open(SwapProjectDialogComponent, {
            data: new SwapProjectDialogOption(projectId, allowCustomerChange),
            width: '550px',
            height: '450px'
        });

        const result = await swapDialog.afterClosed().toPromise<SwapProjectDialogResult>();

        if (!result) {
            return false;
        }

        const data = new SwapProjectData(projectId, customerId, siteId, result.selection);
        const action = this._projectHttp.swapProject(data);
        const ongoing = this._translate.instant('services.projectAction.swapOngoing');
        const success = this._translate.instant('services.projectAction.swapSuccess');
        const error = this._translate.instant('services.projectAction.swapError');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const actionDialog = this._dialog.open(ActionProgressDialogComponent, {
            data: new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt),
            width: '550px',
            height: '120px'
        });

        return await actionDialog.afterClosed().toPromise<boolean>();
    }

    private async unmergeProject(generalInfo: ProjectGeneralInfo): Promise<boolean> {
        const { projectId } = generalInfo.basicInfo;
        const mergedProjects = await this._projectHttp.getMergedProjects(projectId);

        if (!mergedProjects.length) {
            return false;
        }

        const dialog = this._dialog.open(UnmergeProjectDialogComponent, {
            data: { activeProjectId: projectId, mergedProjects },
            width: '800px',
            height: '500px'
        });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private async mergeProject(generalInfo: ProjectGeneralInfo): Promise<boolean> {
        const { projectId } = generalInfo.basicInfo;

        const dialog = this._dialog.open(MergeProjectDialogComponent, {
            data: { activeProjectId: projectId },
            minHeight: '300px',
            minWidth: '500px',
            maxWidth: '70vw',
            maxHeight: '85vh'
        });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private async updateProject(generalInfo: ProjectGeneralInfo, options: ProjectActionPayload): Promise<boolean> {
        const projectId = generalInfo.basicInfo.projectId;
        const customerId = generalInfo.customer.customerId;

        const dialog = this._dialog.open(UpdateProjectDialogComponent, {
            data: { projectId, customerId, jobId: options.jobId },
            maxWidth: '1350px',
            maxHeight: '1000px'
        });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private async deleteProject({ basicInfo }: ProjectGeneralInfo): Promise<boolean> {
        const title = this._translate.instant('services.projectAction.deleteTitle');
        const placeholder = this._translate.instant('services.projectAction.deletePlaceholder');
        const warning = this._translate.instant('services.projectAction.deleteWarningText');
        const errorText = this._translate.instant('services.projectAction.deleteErrorText');
        const deleteText = this._translate.instant('services.projectAction.delete');
        const promptDialog = this._dialog.open(PromptDialogComponent, {
            data: new PromptDialogOption(title, warning, true, deleteText, placeholder, errorText),
            width: '550px',
            height: '450px'
        });

        const reason = await promptDialog.afterClosed().toPromise<string>();

        if (!reason) {
            return false;
        }

        const { projectId } = basicInfo;
        const request = new ProjectRequestBase(projectId, reason);
        const action = this._projectHttp.deleteProject(request);
        const ongoing = this._translate.instant('services.projectAction.deleteOngoing');
        const success = this._translate.instant('services.projectAction.deleteSuccess');
        const error = this._translate.instant('services.projectAction.deleteError');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const data = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);

        const dialog = this._dialog.open(ActionProgressDialogComponent, {
            data,
            width: '550px',
            height: '120px'
        });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private async confirmAction(message: string, warningMessage: string, title: string): Promise<boolean> {
        const proceed = this._translate.instant('services.projectAction.proceed');
        const data = new ConfirmDialogOption(title, message, true, proceed);
        data.warningMessage = warningMessage;
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        return await dialog.afterClosed().toPromise();
    }

    private async changeServiceProvider(generalInfo: ProjectGeneralInfo): Promise<boolean> {
        const warning = this._translate.instant('services.projectAction.changeServiceProviderWarning');
        const titleText = this._translate.instant('services.projectAction.changeServiceProviderTitle');

        if (!await this.confirmAction('', warning, titleText)) {
            return false;
        }

        const projectId = generalInfo.basicInfo.projectId;
        const dialog = this._dialog.open(ChangeServiceProviderDialogComponent, {
            maxWidth: '550px',
            maxHeight: '350px',
            data: projectId.substring(0, 3)
        });

        const selectedServiceProvider = await dialog.afterClosed().toPromise<string>();

        if (!selectedServiceProvider) {
            return false;
        }

        const action = this._projectHttp.changeServiceProvider(projectId, selectedServiceProvider);
        const ongoing = this._translate.instant('services.projectAction.changeServiceProviderOngoing');
        const success = this._translate.instant('services.projectAction.changeServiceProviderSuccess');
        const error = this._translate.instant('services.projectAction.changeServiceProviderError');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const data = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);

        const actionDialog = this._dialog.open(ActionProgressDialogComponent, {
            data,
            width: '550px',
            height: '120px'
        });

        return await actionDialog.afterClosed().toPromise<boolean>();
    }
}
