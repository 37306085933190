export default class CheckboxData {
    public name = '';
    public checked = false;
    public isDisabled = false;
    public disableReason = '';
    public displayText: string;

    constructor(name: string, checked = false, isDisabled = false, disableReason = '', displayText = null) {
        this.name = name;
        this.checked = checked;
        this.isDisabled = isDisabled;
        this.disableReason = disableReason;
        this.displayText = !displayText ? name: displayText;
    }
}
