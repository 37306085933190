import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import ErpTaskCode from '../../../data-models/erp/erp-task-code';
import { JsonUtility } from '../../../utilities/json/json.utility';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ErpHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/erp`;

    constructor(private _http: HttpClient) { }

    public async getTaskCodes(): Promise<ErpTaskCode[]> {
        try {
            const endpoint = `${this._api}/task-codes`;
            const results = await this._http.get<{[code: string]: ErpTaskCode}>(endpoint).toPromise();

            const keys = Object.getOwnPropertyNames(results).sort((left, right) => left.localeCompare(right));
            const taskArray = keys.map(_ => results[_]);

            return JsonUtility.toCamelCaseKeys(taskArray);
        }
        catch {
            return [];
        }
    }
}
