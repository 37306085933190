<div class="project-executive-summary-container" fxFill fxLayout="column" fxLayoutAlign="space-between stretch">
    <mat-card fxLayout="row" fxLayoutAlign="center center">
        <mat-button-toggle-group (change)="activeTab = $event.value">
            <mat-button-toggle value="buildingType" checked>{{ 'projects.executiveSummary.buildingTypeAndRoofAccess' | translate }}</mat-button-toggle>
            <mat-button-toggle value="roofComposition">{{ 'projects.executiveSummary.roofComposition' | translate }}</mat-button-toggle>
            <mat-button-toggle value="roofCondition">{{ 'projects.executiveSummary.roofCondition' | translate }}</mat-button-toggle>
            <mat-button-toggle value="recommendation">{{ 'projects.executiveSummary.recommendation' | translate }}</mat-button-toggle>
            <mat-button-toggle value="roofProjectBudget">{{ 'projects.executiveSummary.roofProjectBudget' | translate }}</mat-button-toggle>
            <mat-button-toggle value="otherNotes">{{ 'projects.executiveSummary.otherNotes' | translate }}</mat-button-toggle>
            <mat-button-toggle value="maintenancePlan" *ngIf="!isTempProject">{{ 'projects.executiveSummary.maintenancePlan' | translate }}</mat-button-toggle>
        </mat-button-toggle-group>
    </mat-card>

    <executive-summary-content *ngIf="summary && activeTab !== 'maintenancePlan'" [summary]="summary" [activeTab]="activeTab" (summarySaved)="saveSummary($event)"></executive-summary-content>

    <div fxLayout="row" fxFlex="100" *ngIf="summary && !isTempProject && activeTab === 'maintenancePlan'" class="maintenance-plan-container">
        <maintenance-plan-viewer fxFlex="100" [originProjectId]="summary.projectId"></maintenance-plan-viewer>
    </div>
</div>
