import NameAttributes from './name-attributes';
import ValueAttributes from './value-attributes';
import FieldLogic from './field-logic';

export default class TemplateFieldMeta {
    public jobId = 0;
    public mapping: string = null;
    public isEnabled = true;
    public isVisible = true;
    public isRequired = false;
    public isDisplayItem = false;
    public name = '';
    public nameAttributes = new NameAttributes();
    public value = '';
    public valueAttributes = new ValueAttributes();
    public logics: FieldLogic[] = [];
    public fields: TemplateFieldMeta[] = null;

    constructor(value = '') {
        this.value = value;
    }
}
