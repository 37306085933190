import { Component, EventEmitter, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import DictionaryEntry from '../../core/data-models/dictionary-entry';
import DictionaryEntryLanguage from '../../core/data-models/dictionary-entry-language';
import { debounceTime, switchMap } from 'rxjs/operators';
import SummaryQuery from '../../core/data-models/summary-info/summary-query';
import SummaryQueryResult from '../../core/data-models/summary-info/summary-query-result';
import { UserHttpService } from '../../core/services/http/user-http/user-http.service';
import { ProjectHttpService } from '../../core/services/http/project-http/project-http.service';
import ProjectSummary from '../../core/data-models/summary-info/project-summary';
import { TranslateService } from '@ngx-translate/core';
import CheckboxList from '../../core/data-models/checkbox-list';
import UserInfo from '../../core/data-models/user-info';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';
import Crew from '../../core/data-models/crew';
import { QueueViewComponent } from './queue-view/queue-view.component';
import { SaleCode, Status } from '../../core/enums/schedule-board';
import { CalendarViewComponent } from './calendar-view/calendar-view.component';
import ProjectStatus from '../../core/data-models/project-status';
import LanguageUtility from '../../core/services/utility/language-utility/language-utility.service';
import { ProjectStatusHttpService } from '../../core/services/http/project-status-http/project-status-http.service';
import { ScheduleBoardService } from './schedule-board.service';
import { ScheduleBoardHttpService } from '../../core/services/http/schedule-board-http/schedule-board-http.service';
import { ScheduleBoardFilterValues } from '../../core/data-models/schedule-board/schedule-board-filter';
import Branch from '../../core/data-models/branch';
import { BranchHttpService } from '../../core/services/http/branch-http/branch-http.service';
import { WeatherService } from './weather.service';

@Component({
    selector: 'scheduleboard',
    styleUrls: ['./schedule-board.component.scss'],
    templateUrl: './schedule-board.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ScheduleBoardComponent implements OnInit {
    @ViewChild(QueueViewComponent) queueViewComponent: QueueViewComponent;
    @ViewChild(CalendarViewComponent) calendarViewComponent: CalendarViewComponent;
    @Input() public activeProjectId = '';
    public isLoading = false;
    public entryIndex = 0;
    public languageIndex = 0;
    public entries: DictionaryEntry[] = [];
    public isLoaded = false;
    public salesCodeExcept312 = Object.values(SaleCode).filter(_ => _ !== SaleCode.SaleCode312);
    public query = { currentPage: 1, pageSize: 6, status: [Status.Status], salesCode: this.salesCodeExcept312, isProjectScheduled: false.toString() } as SummaryQuery;
    public queryResult: SummaryQueryResult<ProjectSummary> = null;
    public loadSummariesChange = new EventEmitter<number>();
    public hasTargetSummary = false;
    public isSummaryListLoading = false;
    public filterConfiguration: CheckboxList[] = [];
    public summaryListExpanded = true;
    private _expandTimer: number;
    private _user: UserInfo;
    private _projectStatuses: ProjectStatus[] = [];
    private _eventRunning = false;
    private readonly _expandDuration = 750;
    public crewList: Crew[];
    public isRainyDay = false;
    public userBranch: string;
    public branchInfo: Branch;
    private branchFilterRoles= ['service administrator', 'admin'];
    public isUserAllowedToFilterBranch: boolean;
    public showWeatherChart = false;

    // eslint-disable-next-line max-params
    constructor(
        private _userHttp: UserHttpService,
        private _projectHttp: ProjectHttpService,
        private _projectStatusHttp: ProjectStatusHttpService,
        private _featureFlagService: FeatureFlagService,
        private _scheduleBoardService: ScheduleBoardService,
        private _scheduleBoardHttpService: ScheduleBoardHttpService,
        private _branchHttpService: BranchHttpService,
        private _weatherChartService: WeatherService,
        public translate: TranslateService) {
    }

    get summaryListStyle(): { [key: string]: string } {
        return { transition: `width ${this._expandDuration / 1000}s` };
    }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    public async ngOnInit(): Promise<void> {
        this.getWeatherChartFlag();
        this.setupLoadSummariesChange();
        this._user = await this._userHttp.getUserInfo();
        this.userBranch = this._user.branch;
        this.branchInfo = await this._branchHttpService.getBranchByName(this.userBranch);
        this.isUserAllowedToFilterBranch = this.branchFilterRoles.some(role => this._user.groups.includes(role));
        this._projectStatuses = await this._projectStatusHttp.getFilterableProjectStatus();
    }

    getWeatherChartFlag() {
        this._weatherChartService.getWeatherChartFlag().subscribe({
            next: res => {
                this.showWeatherChart = res;
            },
            error: err => {
                console.error(err);
            }
        });
    }

    public getLocalizedContent(contents: DictionaryEntryLanguage[]): string {
        if (!contents.length) {
            return '';
        }
        // always return default translation when selected translation is not available
        return contents[this.languageIndex]?.content ?? contents[0].content;
    }

    public loadSummaries(page: number): void {
        this.hasTargetSummary = false;
        this.loadSummariesChange.emit(page);
    }

    private setupLoadSummariesChange(): void {
        this.loadSummariesChange.pipe(
            debounceTime(650),
            switchMap(page => {
                this.isSummaryListLoading = true;
                this.query.currentPage = page;
                return this._projectHttp.getSummaries(this.query);
            })
        ).subscribe(summaries => {
            const { result } = summaries;
            this.queryResult = summaries;
            this.queryResult.result = result.map(_ => this.updateStatusText(this._user, this._projectStatuses, _));
            this.isSummaryListLoading = false;
        });
    }

    private updateStatusText(user: UserInfo, statuses: ProjectStatus[], summary: ProjectSummary): ProjectSummary {
        const names = statuses.find(status => status.code === summary.statusCode)?.names ?? [];

        return { ...summary, status: LanguageUtility.getLocalizedContent(user.language, names) };
    }

    public onToggleExpand(expanded: boolean): void {
        if (this._expandTimer) {
            clearTimeout(this._expandTimer);
        }
        this.summaryListExpanded = expanded;
    }

    public async onSummarySelected(projectId: string): Promise<void> {
        if (this._eventRunning) {
            return;
        }
        this._eventRunning = true;
        if (this.isRainyDay) {
            const dispatchExistingOptions = await this._projectHttp.getDispatchExistingOptions(projectId, true);
            if (await this._scheduleBoardService.dispatchProject(dispatchExistingOptions)) {
                this.onProjectDispatched(true);
            }
        }
        else {
            const projectScheduleOptions = await this._scheduleBoardHttpService.getProjectScheduleDialogOptions(projectId);
            if (projectScheduleOptions) {
                projectScheduleOptions.branchInfo = this.branchInfo;
            }
            if (await this._scheduleBoardService.openProjectScheduleDialog(projectScheduleOptions)) {
                this.onProjectScheduled(true);
            }
        }
        this._eventRunning = false;
    }

    public onProjectDispatched(isProjectDispatched: boolean): void {
        if (isProjectDispatched) {
            this.loadSummaries(this.query.currentPage);
            if (this.isRainyDay) {
                this.queueViewComponent.loadSchedulerComponentData();
            }
            else {
                this.calendarViewComponent.loadSchedulerComponentData();
            }
        }
    }

    public onProjectScheduled(isProjectScheduled: boolean): void {
        if (isProjectScheduled) {
            this.loadSummaries(this.query.currentPage);
            // reload calendar view if its not a rainy day
            if (!this.isRainyDay) {
                this.calendarViewComponent.loadSchedulerComponentData();
            }
        }
    }

    public filterSearch(keyWord: string): void {
        this.query.searchText = keyWord;
        this.loadSummaries(1);
    }

    public async onFilter(filterValues: ScheduleBoardFilterValues) {
        const branchSearchText = filterValues?.branch?.trim().toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        this.query = {
            searchText: this.query.searchText,
            currentPage: 1,
            pageSize: 6,
            status: [Status.Status],
            salesCode: filterValues.salesCode,
            // eslint-disable-next-line no-undefined
            isProjectScheduled: this.isRainyDay ? undefined : false.toString(),
            branch: [branchSearchText || this._user?.branch]
        } as SummaryQuery;
        this.loadSummaries(this.query.currentPage);
        if (this.isUserAllowedToFilterBranch) {
            this.branchInfo = await this._branchHttpService.getBranchByName(filterValues.branch || this._user.branch);
        }
    }
}
