import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import ValidatorSet from '../../../core/data-models/validator-set';
import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';
import { DevelopHttpService } from '../../../core/services/http/develop-http/develop-http.service';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { UserPermissionService } from '../../../core/services/user-permission/user-permission.service';
import { ValidatorFactory } from '../../../core/services/validator-factory/validator-factory.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { DynamicsHoldStatus } from '../../../core/enums/dynamics-hold-status.enum';
import { WritePermission } from '../../../core/enums/write-permission.enum';

@Component({
    selector: 'general-settings',
    styleUrls: ['./general-settings.component.scss'],
    templateUrl: './general-settings.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralSettingsComponent implements OnInit {
    public signInOutNumber: UntypedFormControl;
    public budgetThreshold: UntypedFormControl;
    public geofenceRecordingTime: UntypedFormControl;
    public dynamicsHoldStatuses = DynamicsHoldStatus;
    public dynamicsHoldStatus = DynamicsHoldStatus.Unavailable;
    public isLoading = true;
    private _canUpdateDynamicsHoldStatus = false;
    private _currencyValidator = this._validatorFactory.createCurrencyValidator();
    private _secondsValidator = this._validatorFactory.createNonNegativeIntegerValidator();
    // eslint-disable-next-line max-params
    constructor(private _appConfigHttp: AppConfigHttpService,
                private _developHttp: DevelopHttpService,
                private _projectHttp: ProjectHttpService,
                private _featureFlagService: FeatureFlagService,
                private _permissionService: UserPermissionService,
                private _validatorFactory: ValidatorFactory,
                private _changeDetectorRef: ChangeDetectorRef,
                private _translate: TranslateService,
                private _snackBar: MatSnackBar) { }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    get canUpdateDynamicsHoldStatus(): boolean {
        return this._canUpdateDynamicsHoldStatus;
    }

    get currencyValidator(): ValidatorSet {
        return this._currencyValidator;
    }

    get secondsValidator(): ValidatorSet {
        return this._secondsValidator;
    }

    public async ngOnInit(): Promise<void> {
        if (this.isInternal) {
            const threshold = await this._appConfigHttp.getHandOffMeetingThreshold();
            const recordingTime = await this._developHttp.getMinimumGeofenceRecordingTime();
            await this.getDynamicsHoldStatus();
            this.budgetThreshold = new UntypedFormControl(threshold, [this._currencyValidator.validator, Validators.required]);
            this.geofenceRecordingTime = new UntypedFormControl(recordingTime, [this._secondsValidator.validator, Validators.required]);
            this._canUpdateDynamicsHoldStatus = await this._permissionService.hasWritePermission(WritePermission.UpdateDynamicsHoldStatus, null, false);
        }
        else {
            const number = await this._appConfigHttp.getSignInOutNumber();
            this.signInOutNumber = new UntypedFormControl(number);
        }

        this.isLoading = false;
        this._changeDetectorRef.markForCheck();
    }

    public async updateSignInOutNumber(): Promise<void> {
        if (this.signInOutNumber.valid) {
            const isUpdated = await this._appConfigHttp.updateSignInOutNumber(this.signInOutNumber.value);
            this.showResult(isUpdated ? 'signInOutNumberUpdated' : 'signInOutNumberUpdateFailed');
            this._changeDetectorRef.markForCheck();
        }
    }

    public async updateBudgetThreshold(): Promise<void> {
        if (this.budgetThreshold.valid) {
            const isUpdated = await this._appConfigHttp.updateHandoffMeetingThreshold(this.budgetThreshold.value);
            this.showResult(isUpdated ? 'budgetThresholdUpdated' : 'budgetThresholdUpdateFailed');
            this._changeDetectorRef.markForCheck();
        }
    }

    public async updateGeofenceRecordingTime(): Promise<void> {
        if (this.geofenceRecordingTime.valid) {
            const isUpdated = await this._developHttp.updateMinimumGeofenceRecordingTime(this.geofenceRecordingTime.value);
            this.showResult(isUpdated ? 'geofenceRecordingTimeUpdated' : 'geofenceRecordingTimeUpdateFailed');
            this._changeDetectorRef.markForCheck();
        }
    }

    public async getDynamicsHoldStatus(): Promise<void> {
        this.dynamicsHoldStatus = await this._projectHttp.getDynamicsHoldStatus();
    }

    public async updateDynamicsHoldStatus(status: boolean): Promise<void> {
        const isUpdated = await this._projectHttp.updateDynamicsHoldStatus(status);

        if (isUpdated) {
            await this.getDynamicsHoldStatus();
        }
        else {
            this.showResult('dynamicsHoldStatusUpdateFailed');
        }

        if (isUpdated && !status) {
            this._projectHttp.convertHoldProjects();
        }

        this._changeDetectorRef.markForCheck();
    }

    private showResult(messageKey: string): void {
        const message = this._translate.instant(`admin.generalSettings.${messageKey}`);
        const buttonText = this._translate.instant('shared.ok');
        this._snackBar.open(message, buttonText);
    }
}
