import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { VideoItem } from '../../../../core/data-models/job-template-items/video-item';

@Component({
    selector: 'app-video-card',
    styleUrls: ['./video-card.component.scss'],
    templateUrl: './video-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoCardComponent {
    @Input() public video: VideoItem;
}
