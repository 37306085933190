<app-dialog-panel class="service-rates-group-editor-container" [icon]="iconType" [title]="title" [isLoaded]="true">
    <form class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.25vh" [formGroup]="form">
        <mat-form-field>
            <mat-label>{{ 'flynnBudgetTool.ratesGroupEditor.name' | translate }}</mat-label>
            <input type="text" matInput [formControl]="form.get('name')" />

            <mat-error *ngIf="form.get('name').errors?.required">
                * {{ 'flynnBudgetTool.ratesGroupEditor.errors.requiredField' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'flynnBudgetTool.ratesGroupEditor.applyToCustomers' | translate }}</mat-label>

            <mat-chip-list [formControl]="form.get('customerIds')">
                <mat-chip *ngFor="let customerId of form.get('customerIds').value; let i = index" (removed)="onCustomerIdRemove(i)">
                    <span>{{ customerId }}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>

            <mat-error *ngIf="form.get('customerIds').errors?.required">
                * {{ 'flynnBudgetTool.ratesGroupEditor.errors.requiredField' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <input type="text"
                matInput
                #customerSearchBox
                [placeholder]="translate.instant('flynnBudgetTool.ratesGroupEditor.customerSearchPlaceholder')"
                [matAutocomplete]="customerSearchAutocomplete"
                (input)="search.emit($event.target.value)" />

            <mat-autocomplete autoActiveFirstOption #customerSearchAutocomplete="matAutocomplete" (optionSelected)="onCustomerSelect($event.option.value)">
                <mat-option *ngFor="let customer of customerSearchResult" [value]="customer.customerId">
                    {{ customer.name }} ({{ customer.customerId }})
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>

    <div class="actions" fxLayout="row" fxLayoutAlign="end center">
        <button *ngIf="!group" mat-raised-button color="primary" (click)="onClose(true)">{{ 'flynnBudgetTool.shared.create' | translate }}</button>
        <button *ngIf="group" mat-raised-button color="primary" (click)="onClose(true)">{{ 'flynnBudgetTool.shared.save' | translate }}</button>
        <button mat-flat-button (click)="onClose()">{{ 'flynnBudgetTool.shared.cancel' | translate }}</button>
    </div>
</app-dialog-panel>
