import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import MaintenanceSummary from '../../../core/data-models/maintenance-summary';
import SiteRoofDetail from '../../../core/data-models/site-roof-detail';
import { RoofDetailsHttpService } from '../../../core/services/http/roof-detail-http/roof-detail-http-service';

@Component({
    selector: 'maintenance-plan-viewer',
    styleUrls: ['./maintenance-plan-viewer.component.scss'],
    templateUrl: './maintenance-plan-viewer.component.html'
})
export class MaintenancePlanViewerComponent implements OnInit {

    // Used on Customer App Only.
    @Input() public customerId: string;
    @Input() public siteId: string;

    // Used on FSW Only.
    @Input() public originProjectId: string = null;

    public selectedPlan: MaintenanceSummary;
    public yearLabels: number[] = [];
    public isLoading = false;
    public displayYearWarning = false;

    private _roofDetails: SiteRoofDetail;

    constructor(private _roofDetailsHttpService: RoofDetailsHttpService, private _translate: TranslateService, private _snackbar: MatSnackBar) { }

    public async ngOnInit(): Promise<void> {
        await this.loadRoofDetails();
    }

    public async save(): Promise<boolean> {
        if (!this.isFormValid()) {
            this._snackbar.open(this._translate.instant('projects.executiveSummary.ensureMaintenancePlanValid'), this._translate.instant('shared.ok'));
            return false;
        }

        this.isLoading = true;
        let success = false;

        if (this.originProjectId) {
            success = await this._roofDetailsHttpService.createMaintenancePlanByProjectId(this.originProjectId, this.selectedPlan);
        }
        else {
            success = await this._roofDetailsHttpService.createMaintenancePlanByCustomerAndSiteId(this.customerId, this.siteId, this.selectedPlan);
        }

        const ok = this._translate.instant('shared.ok');
        if (success) {
            const message = this._translate.instant('projects.executiveSummary.successfullySavedMaintenancePlan');
            this._snackbar.open(message, ok);
            await this.loadRoofDetails();
        }
        else {
            const message = this._translate.instant('projects.executiveSummary.failedToSaveMaintenancePlan');
            this._snackbar.open(message, ok);
        }

        this.isLoading = false;
        return success;
    }

    private isFormValid(): boolean {
        return this.selectedPlan.sections.every(s => {
            const areaValid = Number(s.area) > 0;
            const budgetValid = s.budget.every(b => Number(b.immediate) > 0 && Number(b.maintenance) > 0);

            return areaValid && budgetValid;
        });
    }

    public async loadRoofDetails(): Promise<void> {
        this.isLoading = true;

        if (this.originProjectId) {
            this._roofDetails = await this._roofDetailsHttpService.getSiteRoofDetailsByProjectId(this.originProjectId);

            if (!this._roofDetails) {
                this._snackbar.open(this._translate.instant('projects.executiveSummary.cannotLoadRoofDetails'), this._translate.instant('shared.ok'));
                this.isLoading = false;
                return;
            }
        }
        else {
            this._roofDetails = await this._roofDetailsHttpService.getSiteRoofDetailsByCustomerAndSiteId(this.customerId, this.siteId);
        }

        if (!this._roofDetails.maintenanceSummaries || this._roofDetails.maintenanceSummaries.length === 0) {
            this._roofDetails.maintenanceSummaries = this.createBlankSummary();
        }

        this.selectedPlan = this._roofDetails.maintenanceSummaries[this._roofDetails.maintenanceSummaries.length - 1];
        const createdYear = Number(new Date(this.selectedPlan.createdDate).getFullYear());
        const currentYear = Number(new Date().getFullYear());
        this.yearLabels = Array.from({ length: 5 }, (_, i) => createdYear + i);
        this.displayYearWarning = createdYear < currentYear;

        this.isLoading = false;
    }

    private createBlankSummary(): MaintenanceSummary[] {
        return [{
            version: 0,
            createdDate: new Date().toISOString(),
            sections: [{
                title: 'Section A',
                area: 0,
                budget: [{
                    immediate: 0,
                    maintenance: 0
                }, {
                    immediate: 0,
                    maintenance: 0
                }, {
                    immediate: 0,
                    maintenance: 0
                }, {
                    immediate: 0,
                    maintenance: 0
                }, {
                    immediate: 0,
                    maintenance: 0
                }]
            }]
        } as MaintenanceSummary];
    }
}
