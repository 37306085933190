import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import JsonUtility from '../../utility/json-utility.service';
import LaborSummary from '../../../data-models/labor/labor-summary';
import LaborModel from '../../../data-models/labor/labor-model';
import LaborStatus from '../../../data-models/labor/labor-status';
import LaborModelEntity from '../../../data-models/labor/labor-entity';
import LaborReject from '../../../data-models/labor/labor-reject';
import LaborUpdate from '../../../data-models/labor/labor-update';
import LaborQuery from '../../../data-models/labor/labor-query';
import { LaborUpdateErrorCodes } from '../../../enums/labor-update-error-codes.enum';
import LaborSummaryWithAttributes from '../../../data-models/labor/labor-summary-with-attributes';
import { environment } from '../../../../../environments/environment';
import CodeDescription from '../../../../core/data-models/code-description';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LaborHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/labor`;

    constructor(private _http: HttpClient, private _translate: TranslateService) { }

    public async getLaborSummary(query: LaborQuery) : Promise<LaborSummary[]> {
        try {
            const branch = query.branch ?? '';
            const salesCode = query.salesCode ?? '';
            const startDate = query.startDate?.toISOString() ?? '';
            const endDate = query.endDate?.toISOString() ?? '';
            const status = query.status ?? '';
            const timeRange = `startTime=${startDate}&endTime=${endDate}`;
            const queryString = `branch=${branch}&salesCode=${salesCode}&${timeRange}&status=${status}`;
            const endpoint = `${this._api}/summary?${queryString}`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<LaborSummary>();
        }
    }

    public async retryUploadLabor(labor: LaborSummary) : Promise<LaborModel[]> {
        try {
            const endpoint = `${this._api}/timeCapture/retry`;
            const data = await this._http.post(endpoint, labor).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<LaborModel>();
        }
    }

    public async getLaborStatus() : Promise<LaborStatus[]> {
        try {
            const endpoint = `${this._api}/status`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<LaborStatus>();
        }
    }

    public async approveLabor(labor: LaborSummary) : Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/timeCapture/Approve`;
            await this._http.post(endpoint, labor).toPromise();

            return new CodeDescription('200', '');
        }
        catch (error) {
            const { status, error: errorText } = error as HttpErrorResponse;

            switch (status) {
                case 400:
                    return new CodeDescription(String(status), errorText);
                default:
                    return new CodeDescription('500', this._translate.instant('core.httpServices.internalError'));
            }
        }
    }

    public async rejectLabor(labor: LaborReject) : Promise<boolean> {
        try {
            const endpoint = `${this._api}/timeCapture/Reject`;

            return await this._http.post<boolean>(endpoint, labor).toPromise();
        }
        catch {
            return false;
        }
    }

    public async updateLabor(updatedLabor: LaborUpdate) : Promise<LaborUpdateErrorCodes> {
        try {
            const endpoint = `${this._api}/timeCapture/Update`;

            return await this._http.post<LaborUpdateErrorCodes>(endpoint, updatedLabor).toPromise();
        }
        catch {
            return LaborUpdateErrorCodes.FailedToUpdate;
        }
    }

    public async getLaborRecord(labor: LaborSummary) : Promise<LaborModelEntity> {
        try {
            const { type, recordId, employeeId, jobId, jobDate } = labor;
            const endpoint = `${this._api}/timeCapture/LaborRecord/${type}/${recordId}/${employeeId}/${jobId}/${jobDate}`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getLaborSummaryByProject(projectId: string) : Promise<LaborSummary[]> {
        try {
            const endpoint = `${this._api}/summary/${projectId}`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<LaborSummary>();
        }
    }

    public async getTotalLaborByProject(projectId: string) : Promise<number> {
        try {
            const endpoint = `${this._api}/totalhours/${projectId}`;
            return await this._http.get<number>(endpoint).toPromise();

        }
        catch {
            return -1;
        }
    }

    public async getLaborSummaryWithAttributes(query: LaborQuery) : Promise<LaborSummaryWithAttributes[]> {
        try {
            const branch = query.branch ?? '';
            const salesCode = query.salesCode ?? '';
            const startDate = query.startDate?.toISOString() ?? '';
            const endDate = query.endDate?.toISOString() ?? '';
            const status = query.status ?? '';
            const timeRange = `startTime=${startDate}&endTime=${endDate}`;
            const queryString = `branch=${branch}&salesCode=${salesCode}&${timeRange}&status=${status}`;
            const endpoint = `${this._api}/summary-with-attributes?${queryString}`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<LaborSummaryWithAttributes>();
        }
    }

    public async generateAvantiExport(query: LaborQuery) : Promise<{ fileName: string, blob: Blob }> {
        try {
            const branch = query.branch ?? '';
            const startDate = query.startDate?.toISOString() ?? '';
            const endDate = query.endDate?.toISOString() ?? '';
            const timeRange = `startTime=${startDate}&endTime=${endDate}`;
            const queryString = `branch=${branch}&${timeRange}`;
            const endpoint = `${this._api}/avanti-export?${queryString}`;
            const response = await this._http.get(endpoint, { observe: 'response', responseType: 'arraybuffer' }).toPromise();

            const regex = /filename="(?<name>.+)"/;
            const fileName = response.headers.get('content-disposition').match(regex).groups.name;
            const blob = new Blob([response.body], { type: 'text/csv' });

            return {
                fileName,
                blob
            };
        }
        catch {
            return null;
        }
    }

}
