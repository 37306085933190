<dialog-action-card [icon]="'edit'" [title]="translate.instant('projects.update.updateProject')" [isLoaded]="true" (close)="onClose()">
    <form [formGroup]="projectUpdateFormGroup">
        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px" class="project-update-scrollable-content">
            <mat-card class="project-update-information-card">
                <div fxLayout fxLayoutAlign="start start">
                    <div fxFlex="50" fxLayout fxLayoutAlign="start start">
                        <h4 style="margin-bottom: 10px;">{{ 'projects.update.projectInformation' | translate }}</h4>
                    </div>
                    <div fxFlex="50" fxLayout fxLayoutAlign="end start">
                        <button *ngIf="allowSyncFromDynamics" mat-raised-button color="primary" (click)="syncFromDynamics()">{{ 'projects.update.syncFromDynamics.title' | translate }}</button>
                    </div>
                </div>

                <div fxLayout fxLayoutAlign="start center" fxLayoutGap="15px">
                    <mat-form-field fxFlex="30">
                        <mat-label>{{ 'projects.update.projectName' | translate }}</mat-label>
                        <input matInput formControlName="projectName">
                        <mat-error *ngIf="projectUpdateFormGroup.get('projectName').errors">
                            {{ 'projects.update.noDoubleQuotesAllowed' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="30">
                        <mat-label>{{ 'projects.update.customerId' | translate }}</mat-label>
                        <input [readonly]="true" matInput rows="3" formControlName="customerId">
                    </mat-form-field>

                    <mat-form-field fxFlex="30">
                        <mat-label>{{ 'projects.update.customerPONumber' | translate }}</mat-label>
                        <input matInput formControlName="customerPONumber">
                    </mat-form-field>
                </div>
            </mat-card>

            <mat-card class="project-update-information-card">
                <div fxLayout fxLayoutAlign="start start">
                    <div fxFlex="50" fxLayout fxLayoutAlign="start start">
                        <h4 style="margin-bottom: 10px;">{{ 'projects.update.siteInformation' | translate }}</h4>
                    </div>
                    <div fxFlex="50" fxLayout fxLayoutAlign="end start">
                        <button mat-stroked-button color="primary" (click)="reselectProjectSite()">{{ 'projects.update.reSelectSite' | translate }}</button>
                    </div>
                </div>

                <update-site-information
                    [siteInformation]="siteInformation"
                    [isBillingSite]="false">
                </update-site-information>
            </mat-card>

            <mat-card class="project-update-information-card">
                <div fxLayout fxLayoutAlign="start start">
                    <div fxFlex="50" fxLayout fxLayoutAlign="start start">
                        <h4 style="margin-bottom: 10px;">{{ 'projects.update.billingInformation' | translate }}</h4>
                    </div>
                    <div fxFlex="50" fxLayout fxLayoutAlign="end start">
                        <button mat-stroked-button color="primary" (click)="reselectBillingSite()">{{ 'projects.update.reSelectBilling' | translate }}</button>
                    </div>
                </div>
                <update-site-information
                    [siteInformation]="billingInformation"
                    [isBillingSite]="true">
                </update-site-information>
            </mat-card>

        </div>

        <div class="project-update-submit-container" fxLayout fxLayoutAlign="end center">
            <button mat-raised-button
                color="primary"
                [disabled]="projectUpdateFormGroup.invalid"
                (click)="updateProject()">

                {{ 'projects.update.updateProject' | translate }}
            </button>
        </div>
    </form>
</dialog-action-card>
