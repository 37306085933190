import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';


@Component({
    selector: 'invoice-upload',
    styleUrls: ['./invoice-upload.component.scss'],
    templateUrl: './invoice-upload.component.html',
    encapsulation: ViewEncapsulation.None
})
export class InvoiceUploadComponent {

    @Input() hasInvoice: boolean;
    @Output() invoiceFileSelected = new EventEmitter<FileSystemFileEntry>();
    @Input() invoiceNumber: UntypedFormControl;
    @Input() draftNumber: UntypedFormControl;
    @Input() grossAmount: UntypedFormControl;
    public invoiceFile: FileSystemFileEntry;

    get fileName(): string {
        return this.invoiceFile?.name ?? this._translate.instant('projects.processProjectDialog.noFileSelected');
    }

    get enableUpload(): boolean {
        return this.hasInvoice && this.invoiceNumber.valid && this.draftNumber.valid && (this.grossAmount?.valid ?? true);
    }

    constructor(private _translate: TranslateService) { }

    public onInvoiceFileDrop(files: NgxFileDropEntry[]): void {
        const file = files[0].fileEntry;

        if (file.isFile && /\.pdf$/.test(file.name)) {

            this.invoiceFile = file as FileSystemFileEntry;
            this.invoiceFileSelected.emit(this.invoiceFile);
        }
    }

    public onGrossAmountChange(): void {
        if (this.grossAmount.value.trim().length > 0) {
            const val = Number(this.grossAmount.value).toFixed(2);
            this.grossAmount.setValue(val);
        }
    }
}
