import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'kpi-management',
    styleUrls: ['./kpi-management.component.scss'],
    templateUrl: './kpi-management.component.html',
    encapsulation: ViewEncapsulation.None
})
export class KpiManagementComponent {
    public view = 'holiday';
}
