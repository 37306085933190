export default class AttachmentModel {
    public name: string;
    public path: string;
    public status: string;
    public statusDate: string;
    public type: string;
    public source: string;
    public visibleToCustomer: boolean;
    public isCopy = false;
}
