<div fxLayout="row" fxFill fxLayoutGap="10px">
    <div fxFlexAlign="center">
        <button mat-icon-button color="primary" (click)="navigationClick.emit(-1)" [disabled]="selectedIndex <= 0">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
    </div>
    <div fxFlex fxLayoutGap="10px">
        <div fxLayout="column" fxFlex="50" fxLayoutGap="10px">
            <div fxFlex class="edit-photo-image-container" [style.background-image]="backgroundImage">
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
                <button mat-flat-button color="primary" (click)="actionClick.emit('Download')" fxFlex="33">
                    <mat-icon>cloud_download</mat-icon>
                    <span>{{ 'shared.download' | translate }}</span>
                </button>
                <button mat-flat-button color="primary" (click)="openPhotoEditor()" fxFlex="33">
                    <mat-icon>fullscreen</mat-icon>
                    <span>{{ 'shared.editPhoto.editImage' | translate }}</span>
                </button>
                <button mat-flat-button color="warn" (click)="actionClick.emit('Delete')" fxFlex="33">
                    <mat-icon>delete</mat-icon>
                    <span>{{ 'shared.delete' | translate }}</span>
                </button>
            </div>
        </div>
        <div fxLayout="column" fxFlex="50">
            <div fxLayoutGap="15px">
                <div fxLayout="column"  fxFlex="50">
                    <div fxLayout="column">
                        <mat-form-field>
                            <mat-label>{{ 'shared.editPhoto.imageCategory' | translate }}</mat-label>
                            <mat-select [(ngModel)]="image.title" (ngModelChange)="onImageCategoryChange($event)">
                                <mat-option *ngFor="let category of combinedImageCategories" [value]="category.original">{{ category.translated }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>{{ 'shared.editPhoto.subCategory' | translate }}</mat-label>
                            <input matInput [(ngModel)]="image.subCategoryField" />
                        </mat-form-field>
                    </div>
                    <div fxLayout="column" *ngIf="hasPageQuestions">
                        <mat-form-field>
                            <mat-label> <mat-icon color='primary'>description</mat-icon> {{ 'shared.editPhoto.tagPages' | translate }}</mat-label>
                            <mat-select [(ngModel)]="image.tagSectionPage">
                                <mat-option *ngFor="let page of pages" [value]="page">{{page}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field>
                              <mat-label> <mat-icon color='primary'>help_center</mat-icon> {{ 'shared.editPhoto.tagQuestion' | translate }}</mat-label>
                              <mat-select [(ngModel)]="image.tagQuestion" [disabled]="!image.tagSectionPage">
                                <mat-option *ngFor="let question of questions" [value]="question">{{question}}</mat-option>
                              </mat-select>
                          </mat-form-field>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="50">
                    <label>{{ 'shared.editPhoto.toggles' | translate }}</label>
                    <mat-checkbox [(ngModel)]="image.internalImage">
                        <div fxLayout="row" fxLayoutGap="5px"><mat-icon color='warn'>block</mat-icon> <span>{{ 'shared.editPhoto.notForCustomer' | translate }}</span></div>
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="image.customerWorkOrder">
                        <div fxLayout="row" fxLayoutGap="5px"><mat-icon color='accent'>build</mat-icon> <span>{{ 'shared.editPhoto.customerWorkOrder' | translate }}</span></div>
                    </mat-checkbox>
                </div>
            </div>
            <div>
                <mat-form-field fxFill>
                    <mat-label>{{ 'shared.description' | translate }}</mat-label>
                    <textarea matInput [(ngModel)]="image.contentValue" rows="5" maxlength="225"></textarea>
                </mat-form-field>
            </div>
            <div fxLayoutAlign="end center">
                <button mat-flat-button color="primary" (click)="actionClick.emit('Save')">
                    <mat-icon>save</mat-icon>
                    <span>{{ 'shared.save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
    <div fxFlexAlign="center">
        <button mat-icon-button color="primary" (click)="navigationClick.emit(1)" [disabled]="selectedIndex >= endIndex">
            <mat-icon>arrow_forward_ios</mat-icon>
        </button>
    </div>
</div>
