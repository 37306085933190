import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import DispatchExistingOptions from '../../core/data-models/project-general-info/dispatch-existing-options';
import { RedispatchManagementDialogComponent } from '../project/project-details/project-details-action-bar/dispatch/redispatch-management-dialog/redispatch-management-dialog.component';
import { ProjectScheduleDialogOptions } from '../../core/data-models/schedule-board/project-schedule-dialog-options';
import { ProjectScheduleDialogComponent } from './project-schedule-dialog/project-schedule-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ScheduleBoardService {
    constructor(private _dialog: MatDialog) { }

    public async dispatchProject(dispatchExistingOptions: DispatchExistingOptions): Promise<boolean> {
        // Always show project link for schedule board component use of the dispatch modal
        if (dispatchExistingOptions) {
            dispatchExistingOptions.showProjectLink = true;
        }
        const dialog = this._dialog.open(RedispatchManagementDialogComponent, {
            data: dispatchExistingOptions,
            minWidth: '800px',
            maxWidth: '75vw',
            minHeight: '45vh',
            maxHeight: '90vh'
        });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    public async openProjectScheduleDialog(projectScheduleDialogOptions: ProjectScheduleDialogOptions): Promise<boolean> {
        const dialog = this._dialog.open(ProjectScheduleDialogComponent, {
            data: projectScheduleDialogOptions,
            minWidth: '800px',
            maxWidth: '75vw',
            minHeight: '45vh',
            maxHeight: '90vh'
        });

        return await dialog.afterClosed().toPromise<boolean>();
    }
}