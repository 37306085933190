import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import BudgetTemplate from '../../../core/data-models/budget-template';
import { BudgetHttpService } from '../../../core/services/http/budget-http/budget-http.service';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';

@Component({
    selector: 'app-budget-upload',
    styleUrls: ['./budget-upload.component.scss'],
    templateUrl: './budget-upload.component.html'
})
export class BudgetUploadComponent implements OnChanges, OnInit {
    @Input() public selectedBudgetFileName: string;
    @Input() public customerId: string;
    @Input() public layout: string;
    @Output() public budgetFileSelected = new EventEmitter<FileSystemFileEntry>();
    public budgetFile: FileSystemFileEntry = null;
    public selectedTemplate: BudgetTemplate = null;
    public noFileSelectedMessage: string;
    private _templates: BudgetTemplate[] = [];
    private _customerId: string;
    private _useBudgetFeature = false;

    constructor(private _translate: TranslateService,
                private _budgetHttp: BudgetHttpService,
                private _downloadHttp: DownloadHttpService,
                private _snackBar: MatSnackBar,
                private _featureFlagService: FeatureFlagService) {
    }

    get templates(): BudgetTemplate[] {
        return this._templates;
    }

    get fileName(): string {
        return this.budgetFile?.name ?? this.selectedBudgetFileName;
    }

    get useBudgetFeature(): boolean {
        return this._useBudgetFeature;
    }

    public ngOnInit(): void {
        this._useBudgetFeature = this._featureFlagService.featureFlags[FeatureFlags.UseBudgetFeature]?? false;
        this.noFileSelectedMessage = this._translate.instant('shared.budgetUpload.noFileSelected');
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.customerId) {
            const noneLabel = this._translate.instant('shared.budgetUpload.none');
            this._customerId = changes.customerId.currentValue ? changes.customerId.currentValue : noneLabel;
            this._templates = await this._budgetHttp.getBudgetTemplatesByCustomerId(this._customerId);
            this.budgetFile = null;

            if (this.selectedBudgetFileName) {
                this.selectedTemplate = this._templates.find(_ => _.fileName === this.selectedBudgetFileName);
            }
        }
    }

    public async downloadTemplate(template: BudgetTemplate): Promise<void> {
        const url = this._budgetHttp.getBudgetTemplateUrl(template);
        await this._downloadHttp.download(url, template.fileName, 'application/octet-stream');
    }

    public async onBudgetFileDrop(files: NgxFileDropEntry[]): Promise<void> {
        const okLabel = this._translate.instant('shared.ok');

        const file = files[0]?.fileEntry;
        const isValidBudget = files.length === 1 && !file.isDirectory && file.isFile && /\.xlsm$/.test(file.name);
        if (!isValidBudget) {
            const invalidFileMessage = this._translate.instant('shared.budgetUpload.uploadOne');
            this._snackBar.open(invalidFileMessage, okLabel);
            return;
        }

        if (!await this._budgetHttp.checkBudgetTemplateVersion(this._customerId, file as FileSystemFileEntry)) {
            const budgetCheckMessage = this._translate.instant('shared.budgetUpload.askIfLatestVersionMessage');
            this._snackBar.open(budgetCheckMessage, okLabel);
            return;
        }

        this.budgetFile = file as FileSystemFileEntry;
        this.budgetFileSelected.emit(this.budgetFile);
    }
}
