export default class LaborModel {
    public crewId: number;
    public timeEntryId: number;
    public projectId: string;
    public projectName: string;
    public employeeId: string;
    public employeeName: string;
    public jobDate: string;
    public hours: number;
    public branch: string;
    public jobId: number;
    public day: number;
    public isSingleDay: boolean;
    public roomAndBoardflag: number;
    public mileageFlag: number;
    public initialAllowanceFlag: number;
    public returnAllowanceFlag: number;
    public mealFlag: number;
    public mileageDistance: number;
    public zoneFlag: number;
    public zoneHours: number;
}
