import DispatchBaseStepConfig from './dispatch-base-step-config';

export default class BudgetStepConfig extends DispatchBaseStepConfig {
    public customerId: string;
    public file: File;
    public budgetRequired: boolean;
    public awardingQuoteId: string;
    public useQuoteBudget: boolean;
    public usingWebBudget = false;
}
