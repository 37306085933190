import Address from '../address';
import HandOffMeetingForm from '../handoff-meeting/hand-off-meeting-form';
import Crew from '../crew';

export default class JobRedispatchData {
    public crew: Crew;
    public projectId: string;
    public name: string;
    public address: Address;
    public estimatedHours: number;
    public jobType: string;
    public jobId: number;
    public notes: string;
    public isHandOffMeetingNeeded: boolean;
    public handOffMeeting: HandOffMeetingForm;
    public branch: string;
    public estimatedTimeOfArrival = '';
}
