<div class="user-avatars-card-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="-0.5em">
    <user-avatar-card *ngFor="let user of users.slice(0, visibleUsers); let i = index"
        class="user-avatar"
        [ngStyle]="{ 'animation-delay': 0.015 * i + 's' }"
        [user]="user">
    </user-avatar-card>

    <div *ngIf="visibleUsers < users.length"
        class="user-avatar-collapsed"
        [ngStyle]="{ 'animation-delay': 0.015 * visibleUsers + 's' }"
        [matTooltip]="hiddenNames"
        matTooltipPosition="above"
        fxLayout="row"
        fxLayoutAlign="center center">

        <span>+{{ users.length - visibleUsers }}</span>
    </div>
</div>
