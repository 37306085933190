/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WeatherService {
    private readonly _api = `${environment.fswApiUrl}api2/service`;

    constructor(private http: HttpClient) {}

    public getWeatherTimelines(branch: string, startTime: string, endTime: string): Observable<any> {
        return this.http.get(`${this._api}/Forecast?branch=${branch}&startTime=${startTime}&endTime=${endTime}`);
    }

    public getWeatherChartFlag(): Observable<any> {
        return this.http.get(`${this._api}/feature-flags/show-weather-chart`);
    }
}
