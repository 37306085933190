import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileSystemFileEntry } from 'ngx-file-drop';

import BudgetTemplate from '../../../data-models/budget-template';
import BudgetTemplatePayload from '../../../data-models/budget-template-payload';
import JsonUtility from '../../utility/json-utility.service';
import { UploadHttpService } from '../upload-http/upload-http.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BudgetHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/budget`;

    constructor(private _http: HttpClient, private _uploadHttp: UploadHttpService) { }

    public async getAllTemplates(): Promise<BudgetTemplate[]> {
        try {
            const endpoint = `${this._api}/templates`;
            const data = await this._http.get<BudgetTemplate[]>(endpoint).toPromise();

            return data.map(_ => JsonUtility.toCamelCaseKeys(_));
        }
        catch {
            return [];
        }
    }

    public getBudgetTemplateUrl(template: BudgetTemplate): string {
        const name = `${template.name}-${template.version?.toFixed(1) ?? ''}`.replace(/\s/g, '').replace(/\./g, '-dot-');
        const fileName = template.fileName.replace(/\./g, '-dot-');

        return `${environment.fswApiUrl}api2/service/file/budgettemplate/${name}/${fileName}`;
    }

    public async getNextBudgetTemplateVersion(name: string): Promise<number> {
        try {
            const endpoint = `${this._api}/templates/next-version?name=${name}`;

            return await this._http.get<number>(endpoint).toPromise();
        }
        catch {
            return -1;
        }
    }

    public async getBudgetTemplatesByCustomerId(customerId: string): Promise<BudgetTemplate[]> {
        try {
            const endpoint = `${this._api}/templates/customer/${customerId}`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async uploadBudgetTemplate(payload: BudgetTemplatePayload): Promise<boolean> {
        try {
            const endpoint = `${this._api}/templates`;
            const data = new FormData();
            data.append('name', payload.name);
            data.append('version', payload.version);
            data.append('file', payload.file, payload.file.name);

            return await this._http.post<boolean>(endpoint, data).toPromise();
        }
        catch {
            return false;
        }
    }

    public async uploadBudget(projectId: string, file: FileSystemFileEntry): Promise<boolean> {
        try {
            const endpoint = `${environment.fswApiUrl}api2/service/file/excel/upload/${projectId}`;

            return await this._uploadHttp.upload(endpoint, file);
        }
        catch {
            return false;
        }
    }

    public async checkBudgetTemplateVersion(customerId: string, file: FileSystemFileEntry): Promise<boolean> {
        try {
            const endpoint = `${environment.fswApiUrl}api2/service/file/excel/upload/check/${customerId}`;

            return await this._uploadHttp.upload(endpoint, file, true);
        }
        catch {
            return false;
        }
    }

    public async getBudgetValue(budgetFile: File): Promise<number> {
        try {
            const endpoint = `${this._api}/budget-value`;
            const data = new FormData();
            data.append('file', budgetFile);

            return await this._http.post<number>(endpoint, data).toPromise();
        }
        catch {
            return -1;
        }
    }

    public async getBudgetTemplatesWithCustomer(): Promise<BudgetTemplate[]> {
        try {
            const endpoint = `${this._api}/budgets/with-customer`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getBudgetTemplateXlsxFile(template: BudgetTemplate): Promise<Blob> {
        try {
            const templateUrl = this.getBudgetTemplateUrl(template);

            const endpoint = `${templateUrl}/xlsx`;
            return await this._http.get(endpoint, { responseType: 'blob' }).toPromise();
        }
        catch {
            return null;
        }
    }

    public async getBudgetName(recordId: string, isQuote: boolean): Promise<string> {
        try {
            const endpoint = `${this._api}/${recordId}/name?isQuote=${isQuote}`;
            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return '';
        }
    }

    public async updateBudgetTemplateActiveStatus(template: BudgetTemplate): Promise<boolean> {
        try {
            const version = template.version.toFixed(1).replace(/\./g, '-dot-');
            const endpoint = `${this._api}/templates/${template.name}/${version}`;
            const payload = JSON.stringify(template.isActive);
            const headers = { 'content-type': 'application/json' };

            return await this._http.put<boolean>(endpoint, payload, { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async deleteBudgetTemplate(template: BudgetTemplate): Promise<boolean> {
        try {
            const version = template.version.toFixed(1).replace(/\./g, '-dot-');
            const endpoint = `${this._api}/budgets/del/${template.name}/${version}`;
            await this._http.get(endpoint).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async uploadWebBudget(projectId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${projectId}/upload`;

            return await this._http.post<boolean>(endpoint, null).toPromise();
        }
        catch {
            return false;
        }
    }
}
