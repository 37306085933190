<div fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="row" fxFlex fxLayoutGap="15px">
        <div fxLayout="column" fxFlex="65" [formGroup]="customerInfoForm" *ngIf="customerInfoForm" class="customer-selection-step-container">
            <div fxLayout="row" fxLayoutGap="15px">
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.customerId" | translate}}</mat-label>
                    <input type="text" matInput formControlName="customerId" readonly="true" required>
                    <mat-error *ngIf="customerInfoForm.get('customerId').invalid">
                        {{ "dispatch.customerSelectionStep.customerIdError" | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.customerSiteName" | translate}}</mat-label>
                    <input type="text" matInput formControlName="customerSiteName" readonly="true" required>
                    <mat-error *ngIf="customerInfoForm.get('customerSiteName').invalid">
                        {{ "dispatch.customerSelectionStep.customerSiteNameError" | translate}}
                    </mat-error>
                </mat-form-field>
                <div fxLayout="row" fxFlex fxFlexAlign="start" fxLayoutAlign="start center" fxFlexOffset="9" *ngIf="!config.customerPerSelected">
                    <button mat-stroked-button color="primary" (click)="openCustomerSelection()">{{ 'dispatch.customerSelectionStep.selectCustomer' | translate }}</button>
                </div>
            </div>
            <div fxLayout="column" *ngIf="!siteGeoCodeOnly">
                <div fxLayout="row" fxLayoutGap="15px">
                    <mat-form-field>
                        <mat-label>{{ "dispatch.customerSelectionStep.address1" | translate}}</mat-label>
                        <input type="text" matInput formControlName="address1" [required]="!siteGeoCodeOnly">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "dispatch.customerSelectionStep.address2" | translate}}</mat-label>
                        <input type="text" matInput formControlName="address2">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="15px">
                    <mat-form-field>
                        <mat-label>{{ "dispatch.customerSelectionStep.city" | translate}}</mat-label>
                        <input type="text" matInput formControlName="city" [required]="!siteGeoCodeOnly">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "dispatch.customerSelectionStep.province" | translate}}</mat-label>
                        <mat-select formControlName="state" [required]="!siteGeoCodeOnly">
                            <mat-option *ngFor="let state of allStates" [value]="state">{{state}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{ "dispatch.customerSelectionStep.postalCode" | translate}}</mat-label>
                        <input type="text" matInput formControlName="zipCode" [required]="!siteGeoCodeOnly">
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.siteContact" | translate }}</mat-label>
                    <input type="text" matInput formControlName="siteContact" required minlength="2">
                    <mat-error *ngIf="customerInfoForm.get('siteContact').invalid">
                        {{ "dispatch.customerSelectionStep.siteContactError" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.contactPhone" | translate }}</mat-label>
                    <input type="text" matInput formControlName="contactPhone" required appPhoneMask>
                    <mat-error *ngIf="customerInfoForm.get('contactPhone').invalid">
                        {{ "dispatch.customerSelectionStep.contactPhoneError" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.contactEmail" | translate }}</mat-label>
                    <input type="email" matInput formControlName="contactEmail" >
                    <mat-error *ngIf="customerInfoForm.get('contactEmail').invalid">
                        {{ "dispatch.customerSelectionStep.contactEmailError" | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.billingAttention" | translate }}</mat-label>
                    <input type="text" matInput formControlName="billingAttn">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.billingPhone" | translate }}</mat-label>
                    <input type="text" matInput formControlName="billingPhone" required appPhoneMask>
                    <mat-error *ngIf="customerInfoForm.get('billingPhone').invalid">
                        {{ "dispatch.customerSelectionStep.billingPhoneError" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.billingEmail" | translate }}</mat-label>
                    <input type="email" matInput formControlName="billingEmail" required>
                    <mat-error *ngIf="customerInfoForm.get('billingEmail').invalid">
                        {{ "dispatch.customerSelectionStep.billingEmailError" | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="15px">
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.reportingContact" | translate }}</mat-label>
                    <input type="text" matInput formControlName="reportingContact" required minlength="2">
                    <mat-error *ngIf="customerInfoForm.get('reportingContact').invalid">
                        {{ "dispatch.customerSelectionStep.reportingContactError" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.reportingPhone" | translate }}</mat-label>
                    <input type="text" matInput formControlName="reportingPhone" required appPhoneMask>
                    <mat-error *ngIf="customerInfoForm.get('reportingPhone').invalid">
                        {{ "dispatch.customerSelectionStep.reportingPhoneError" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.reportingContactEmail" | translate }}</mat-label>
                    <input type="email" matInput formControlName="reportingEmail" required>
                    <mat-error *ngIf="customerInfoForm.get('reportingEmail').invalid">
                        {{ "dispatch.customerSelectionStep.reportingContactEmailError" | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="15px" *ngIf="confirmedCustomer && !isSubcontract">
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.signInOutNumber" | translate }}</mat-label>
                    <input type="text" matInput formControlName="signInOutNumber" appPhoneMask>
                    <mat-error *ngIf="customerInfoForm.get('signInOutNumber').invalid">
                        {{ "dispatch.customerSelectionStep.signInOutNumberError" | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ "dispatch.customerSelectionStep.signInOutCode" | translate }}</mat-label>
                    <input type="text" matInput formControlName="signInOutCode">
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="column" fxFlex="35" fxLayoutGap="15px" class="dispatch-map-div">
            <div #map class="dispatch-map-view-div">
            </div>
            <div #streetViewMap class="dispatch-street-view-div" [hidden]="!streetViewPanorama">
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!streetViewPanorama">
                <label>{{ "dispatch.customerSelectionStep.streetViewNotAvailableLabel" | translate }}</label>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <button mat-stroked-button (click)="setCompleteAndMove()" color="primary">{{ 'dispatch.customerSelectionStep.next' | translate }}</button>
    </div>
</div>
