import { Pipe, PipeTransform } from '@angular/core';

import TimeUtility from '../../../core/services/utility/time-utility/time-utility.service';
import Branch from '../../../core/data-models/branch';

@Pipe({
    name: 'branchLocalTime'
})
export class BranchLocalTimePipe implements PipeTransform {

    public transform(value: Date | string, inputBranch: string, branches: Branch[], useFull = true): string {
        if (!value) {
            return '';
        }

        const branch = branches.find(_ => /^\d{3}$/.test(inputBranch) ? _.code === inputBranch : _.name === inputBranch);

        return TimeUtility.toBranchLocalDateTime(new Date(value), branch?.geoLocation.timeZone, useFull);
    }
}
