<div class="budget-template-management-container" fxLayout="row" fxLayoutAlign="center center">
    <kendo-grid [kendoGridBinding]="templateNames"
        [loading]="isLoading"
        [pageSize]="10"
        [pageable]="true"
        [scrollable]="true"
        fxFill>

        <kendo-grid-column [title]="translate.instant('admin.budgetTemplateManagement.templateNameHeader')"
            [headerStyle]="{ width: '35%' }"
            [style]="{ width: '35%' }">

            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                {{ getTemplateName(dataItem) }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [title]="translate.instant('admin.budgetTemplateManagement.latestVersionHeader')">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                {{ getLatestVersionText(dataItem) }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [title]="translate.instant('admin.budgetTemplateManagement.activeVersionsHeader')">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                {{ getActiveVersionsText(dataItem) }}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [title]="translate.instant('admin.budgetTemplateManagement.customerSpecificHeader')"
            [headerStyle]="{ width: '27.5%' }"
            [style]="{ width: '27.5%' }">

            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>{{ getCustomerAssignmentStatusText(dataItem) }}</span>

                    <button mat-icon-button (click)="openVersionsList(dataItem)">
                        <mat-icon>settings</mat-icon>
                    </button>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>

    <button mat-mini-fab color="primary" (click)="onTemplateAdd()">
        <mat-icon>add</mat-icon>
    </button>
</div>
