import { Component, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import CustomEditorOption from '../../../../core/data-models/custom-editor-option';
import { DevelopHttpService } from '../../../../core/services/http/develop-http/develop-http.service';
import { EditorUtilityService } from '../../../../core/services/utility/editor-utility/editor-utility.service';
import { CountryName } from '../../../../core/enums/country-name.enum';
import { LanguageKey } from '../../../../core/enums/language-key.enum';

@Component({
    selector: 'photo-report-disclaimer',
    styleUrls: ['./photo-report-disclaimer.component.scss'],
    templateUrl: './photo-report-disclaimer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PhotoReportDisclaimerComponent implements AfterViewInit {
    public readonly editorId = `photo-report-disclaimer-editor-${Date.now()}`;
    public readonly countries = [CountryName.CA, CountryName.US];
    public readonly languages = [LanguageKey.EN, LanguageKey.FR];
    public country = this.countries[0];
    public language = this.languages[0];
    public canSaveDisclaimer = false;
    public isLoaded = false;
    private readonly _translateKeyPrefix = 'admin.disclaimerSettings.photoReportDisclaimer';

    constructor(private _translate: TranslateService,
                private _developHttp: DevelopHttpService,
                private _editorUtility: EditorUtilityService,
                private _snackbar: MatSnackBar) { }

    public async ngAfterViewInit(): Promise<void> {
        const defaultOptions = {
            keyup: () => this.updateSaveStatus(),
            paste: () => this.updateSaveStatus()
        };

        const disclaimer = await this._developHttp.getPhotoReportDisclaimer(this.country, this.language);
        const customOptions: CustomEditorOption = { ...new CustomEditorOption(disclaimer), focusOnLoad: true };
        this._editorUtility.setupEditor(this.editorId, defaultOptions, customOptions);
        this.updateSaveStatus();
        this.isLoaded = true;
    }

    public async onSelected(): Promise<void> {
        this.isLoaded = false;
        const disclaimer = await this._developHttp.getPhotoReportDisclaimer(this.country, this.language);
        this._editorUtility.setContent(this.editorId, disclaimer);
        this.updateSaveStatus();
        this.isLoaded = true;
    }

    public async saveDisclaimer(): Promise<void> {
        const content = this._editorUtility.getContent(this.editorId);

        if (await this._developHttp.updatePhotoReportDisclaimer(content, this.country, this.language)) {
            const message = this._translate.instant(`${this._translateKeyPrefix}.updateDisclaimerSuccess`);
            this._snackbar.open(message, this._translate.instant('shared.ok'));
        }
        else {
            const message = this._translate.instant(`${this._translateKeyPrefix}.updateDisclaimerFailed`);
            this._snackbar.open(message, this._translate.instant('shared.ok'));
        }
    }

    private updateSaveStatus(): void {
        const content = this._editorUtility.getContent(this.editorId) ?? '';
        this.canSaveDisclaimer = Boolean(content.replace(/&nbsp;/g, '').trim());
    }
}
