import { Ng2StateDeclaration, StateService } from '@uirouter/angular';

import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import { ReadPermission } from '../../core/enums/read-permission.enum';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';
import { globalResolver } from '../../core/configs/routing/global-resolver';

import { ReportTemplateComponent } from './report-template.component';

export const states: Ng2StateDeclaration[] = [
    {
        name: 'report-template',
        url: '/report-template',
        component: ReportTemplateComponent,
        resolve: [
            {
                token: 'hasAccess',
                deps: [UserPermissionService, StateService, FeatureFlagService],
                resolveFn: async(
                    _userPermissionService: UserPermissionService,
                    _stateService: StateService,
                    _featureFlagService: FeatureFlagService,
                ): Promise<void> => {
                    const isInternal = _featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
                    const permission = ReadPermission.ViewReportTemplateEditor;

                    if (isInternal || !await _userPermissionService.hasReadPermission(permission, null, false)) {
                        _stateService.transition.abort();
                        await _stateService.go('pageNotFound');
                    }
                }
            },
            ...globalResolver
        ]
    }
];
