<kendo-button fillMode="flat" #anchor (click)="onToggleScheduleBoardFilter()">
        <mat-icon class="filter-icon">filter_alt</mat-icon>
</kendo-button>
<kendo-popup
      [anchor]="anchor.element"
      (anchorViewportLeave)="showFilter = false"
      *ngIf="showFilter"
    >
    <form (ngSubmit)="onSubmit()" [formGroup]="filterForm" fxFill>
      <div class="content">
        <div class="content-body">
            <div class="" fxLayout="row" fxLayoutAlign="space-between center">
                <span class="add-filter-ph">{{translate.instant('scheduleBoardFilter.addAFilter')}}</span>
                <button [disableRipple]="true" type="button" class="clear-button" (click)="clearAllFilters()" mat-button>{{translate.instant('scheduleBoardFilter.clearAll')}}</button>
            </div>
            <div>
                    <div formArrayName="filters" fxFill>
                        <div fxLayout="column" fxFill *ngFor="let filter of getFilters().controls; let i = index" [formGroupName]="i">
                            <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                                <mat-form-field style="padding-bottom: 0 !important;" class="filter-type">
                                    <mat-select #selectFilterType formControlName="type" (selectionChange)="filterSelection(selectFilterType.value)">
                                        <ng-container *ngFor="let filterOption of availableFilters">
                                            <mat-option *ngIf="selectFilterType.value === filterOption.type || !isFilterSelected(filterOption)"  [value]="filterOption.type">
                                                {{ filterOption.type }}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="padding-bottom: 0 !important;" class="filter-comparator">
                                    <mat-select formControlName="comparator">
                                        <mat-option [value]="comparator" *ngFor="let comparator of getFilterComparatorOptionByType(filter.value?.type)">
                                            {{ comparator }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="padding-bottom: 0 !important;" class="filter-value">
                                    <input matInput formControlName="value">
                                </mat-form-field>
                                <button type="button" mat-icon-button (click)="deleteFilterRow(filter, i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
               
                <button type="button" [disableRipple]="true" [disabled]="!filterForm.valid" (click)="addFilter()" class="add-filter-button" mat-button>
                    <mat-icon>add_circle</mat-icon>
                    {{translate.instant('scheduleBoardFilter.addFilter')}}
                </button>
            </div>
            <div class="footer" fxLayoutGap="10px" fxlayout="row" fxLayoutAlign="end center">
                <button type="button" mat-stroked-button (click)="cancelPopup()">{{translate.instant('scheduleBoardFilter.cancel')}}</button>
                <button type="submit" class="filter-button" [disabled]="!filterForm.valid" mat-flat-button>{{translate.instant('scheduleBoardFilter.filter')}}</button>
            </div>
        </div>
      </div>
    </form>
</kendo-popup>