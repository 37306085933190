import { Component, EventEmitter, Input, Output } from '@angular/core';
import KeyValuePair from '../../../../../core/data-models/key-value-pair';

@Component({
    selector: 'grid-dropdown-item',
    styleUrls: ['./grid-dropdown-item.component.scss'],
    templateUrl: './grid-dropdown-item.component.html'
})
export class GridDropdownItem {
    @Input() public title = '';
    @Input() public options: KeyValuePair[];
    @Input() public selected: string;
    @Input() public disabled = false;
    @Output() public selectedChange = new EventEmitter<string>();

    public onChange(key: string): void {
        this.selectedChange.emit(key);
    }
}
