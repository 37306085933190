import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import SubscribedTopic from '../../../core/data-models/subscribed-topic';
import SubscriptionTemplate from '../../../core/data-models/subscription-template';

@Component({
    selector: 'subscription-editor',
    styleUrls: ['./subscription-editor.component.scss'],
    templateUrl: './subscription-editor.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionEditorComponent implements OnInit {
    @Output() public update = new EventEmitter<SubscribedTopic[]>();
    @Input() public isDisabled = false;
    @Input() public isCardMode = true;
    public readonly visibleColumns = ['name', 'description', 'isGlobal', 'actions'];
    public filters: { type: number; name: string }[] = [];
    public filterValue = -1;
    private _templates: SubscriptionTemplate[] = [];
    private _subscribed: SubscribedTopic[] = [];

    @Input()
    set subscribed(val: SubscribedTopic[]) {
        this._subscribed = val ?? [];
        this.initializeTemplates();
    }

    get subscribed(): SubscribedTopic[] {
        return this._subscribed;
    }

    @Input()
    set templates(val: SubscriptionTemplate[]) {
        this._templates = val ?? [];
        this.initializeTemplates();
    }

    get templates(): SubscriptionTemplate[] {
        return this._templates;
    }

    constructor(public translate: TranslateService) { }

    get visibleTemplates(): SubscriptionTemplate[] {
        if (this.filterValue === -1) {
            return this.templates;
        }

        return this.templates.filter(_ => this.filterValue ? _.isAllowed : !_.isAllowed);
    }

    get isMasterToggleOn(): boolean {
        return this.visibleTemplates.every(_ => _.isAllowed);
    }

    public ngOnInit(): void {
        const keyPrefix = 'admin.rolesAndPermissions.subscriptionEditor';

        this.filters = [
            { type: -1, name: this.translate.instant(`${keyPrefix}.filterTypeAll`) },
            { type: 0, name: this.translate.instant(`${keyPrefix}.filterTypeUnsubscribed`) },
            { type: 1, name: this.translate.instant(`${keyPrefix}.filterTypeSubscribed`) }
        ];
    }

    public onSubscriptionToggle(template: SubscriptionTemplate): void {
        const { isAllowed, topic, ignoreAttributes } = template;

        if (isAllowed) {
            this.update.emit([...this.subscribed, { topic, ignoreAttributes }]);
        }
        else {
            template.ignoreAttributes = false;
            this.update.emit(this.subscribed.filter(_ => _.topic !== topic));
        }
    }

    public onAllSubscriptionToggle(isToggleOn: boolean): void {
        let subscriptions = this.subscribed.slice();

        for (const template of this.visibleTemplates) {
            const { topic, ignoreAttributes } = template;

            if (!template.isAllowed && isToggleOn) {
                subscriptions.push({ topic, ignoreAttributes });
            }
            else if (template.isAllowed && !isToggleOn) {
                template.ignoreAttributes = false;
                subscriptions = subscriptions.filter(_ => _.topic !== topic);
            }

            template.isAllowed = isToggleOn;
        }

        this.update.emit(subscriptions);
    }

    public onGlobalAttributeToggle(template: SubscriptionTemplate): void {
        const subscribed = this.subscribed.find(_ => _.topic === template.topic);
        subscribed.ignoreAttributes = template.ignoreAttributes;
        this.update.emit(this.subscribed);
    }

    private initializeTemplates(): void {
        this.templates.forEach(_ => {
            _.isAllowed = false;
        });

        this.templates.sort((a, b) => {
            if (a.topic === b.topic) {
                return 0;
            }

            return a.topic < b.topic ? -1 : 1;
        });

        for (const template of this.templates) {
            const topic = this.subscribed.find(_ => _.topic === template.topic);

            if (topic) {
                template.isAllowed = true;
                template.ignoreAttributes = topic.ignoreAttributes;
            }
        }
    }
}
