import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertToPercentage'
})
export class ConvertPercentagePipe implements PipeTransform {

    public transform(value: number | string, decimalPlace = 2): string {
        // eslint-disable-next-line no-undefined
        if (value === null || value === undefined || typeof value !== 'number' && typeof value !== 'string') {
            return '0';
        }
        return (Number(value) * 100).toFixed(decimalPlace);
    }
}

