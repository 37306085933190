import { Ng2StateDeclaration, StateService } from '@uirouter/angular';

import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';
import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import { ReadPermission } from '../../core/enums/read-permission.enum';
import { globalResolver } from '../../core/configs/routing/global-resolver';

import { DataReportRootViewComponent } from './root-view/data-report-root-view.component';
import { KpiReportComponent } from './kpi-report/kpi-report.component';
import { QuoteReportComponent } from './quote-report/quote-report.component';
import { ServiceOpsRejectionReportComponent } from './service-ops-rejection-report/service-ops-rejection-report.component';
import { DeletedJobReportComponent } from './deleted-job-report/deleted-job-report.component';
import { FallHazardReportComponent } from './fall-hazard-report/fall-hazard-report.component';
import { TemporaryProjectAgingReportComponent } from './temporary-project-aging-report/temporary-project-aging-report.component';
import { CustomerQuotesReportComponent } from './customer-report/customer-report.component';
import { ProjectCovidScreeningReportComponent } from './project-covid-screening-report/project-covid-screening-report.component';
import { StockLogReportComponent } from './stock-log-report/stock-log-report.component';

export const routes: Ng2StateDeclaration[] = [
    {
        name: 'reports',
        url: '/reports',
        component: DataReportRootViewComponent,
        redirectTo: 'reports.kpi-report',
        resolve: [
            {
                token: 'hasAccess',
                deps: [UserPermissionService, StateService, FeatureFlagService],
                resolveFn: async(
                    _userPermissionService: UserPermissionService,
                    _stateService: StateService,
                    _featureFlagService: FeatureFlagService
                ): Promise<void> => {
                    const isInternal = _featureFlagService.featureFlags[FeatureFlags.UseReportsFeature];
                    const permission = ReadPermission.ViewDataReports;

                    if (!isInternal || !await _userPermissionService.hasReadPermission(permission)) {
                        _stateService.transition.abort();
                        await _stateService.go('pageNotFound');
                    }
                }
            },
            ...globalResolver
        ]
    },
    {
        name: 'reports.kpi-report',
        url: '/kpi-report',
        component: KpiReportComponent
    },
    {
        name: 'reports.quotes',
        url: '/quotes',
        component: QuoteReportComponent
    },
    {
        name: 'reports.serviceops-rejection',
        url: '/serviceops-rejection',
        component: ServiceOpsRejectionReportComponent
    },
    {
        name: 'reports.deleted-jobs',
        url: '/deleted-jobs',
        component: DeletedJobReportComponent
    },
    {
        name: 'reports.fall-hazards',
        url: '/fall-hazards',
        component: FallHazardReportComponent
    },
    {
        name: 'reports.covid-screening',
        url: '/covid-screening',
        component: ProjectCovidScreeningReportComponent
    },
    {
        name: 'reports.customer-quotes-report',
        url: '/customer-quotes-report',
        component: CustomerQuotesReportComponent
    },
    {
        name: 'reports.temp-projects',
        url: '/temp-projects',
        component: TemporaryProjectAgingReportComponent
    },
    {
        name: 'reports.stockLogs',
        url: '/stock-logs',
        component: StockLogReportComponent
    }
];
