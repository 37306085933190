import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import CustomerSelectionDto from '../../../core/data-models/customer-selection-dto';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';

@Component({
    selector: 'customer-management',
    styleUrls: ['./customer-management.component.scss'],
    templateUrl: './customer-management.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CustomerManagementComponent implements OnInit {

    public selectExistingCustomerOption = false;
    public createCustomerOption = false;
    public isInternal = false;

    constructor(
        private _dialogRef: MatDialogRef<CustomerManagementComponent>,
        public translate: TranslateService,
        private _featureFlagService: FeatureFlagService,
        @Inject(MAT_DIALOG_DATA) public customerForSuggestions: CustomerSelectionDto
    ) { }

    public ngOnInit(): void {
        this._dialogRef.disableClose = true;
        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        if (this.customerForSuggestions) {
            this.selectAnExistingCustomer();
        }
        else {
            this.resizeDialogRef(false);
        }
    }

    public selectAnExistingCustomer(): void {
        this.createCustomerOption = false;
        this.resizeDialogRef(true);
        this.selectExistingCustomerOption = true;
    }

    public createCustomer(): void {
        this.selectExistingCustomerOption = false;
        this.resizeDialogRef(true);
        this.createCustomerOption = true;
    }

    public returnToOpeningPage(): void {
        this.selectExistingCustomerOption = false;
        this.createCustomerOption = false;
        this.resizeDialogRef(false);

    }

    public onClose(): void {
        this._dialogRef.close();
    }

    public createNewCustomer(newCustomer: CustomerSelectionDto): void {
        if (!newCustomer) {
            this.returnToOpeningPage();
            return;
        }
        this._dialogRef.close(newCustomer);
    }

    private resizeDialogRef(scaleUp: boolean): void {
        if (scaleUp) {
            this._dialogRef.updateSize('95vw', '95vh');
        }
        else {
            this._dialogRef.updateSize('800px', '250px');
        }
    }

}
