import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Observable, Subscription } from 'rxjs';
import CustomEditorOption from '../../../core/data-models/custom-editor-option';
import CustomReportTemplate from '../../../core/data-models/custom-report-template/custom-report-template';
import CustomReportTemplateEvent from '../../../core/data-models/custom-report-template/custom-report-template-event';
import PlaceholderEvent from '../../../core/data-models/custom-report-template/placeholder-event';
import { ReportPlaceholderEventType } from '../../../core/enums/report-placeholder-event-type.enum';
import { EditorUtilityService } from '../../../core/services/utility/editor-utility/editor-utility.service';

@Component({
    selector: 'report-template-editor',
    styleUrls: ['./report-template-editor.component.scss'],
    templateUrl: './report-template-editor.component.html'
})
export class ReportTemplateEditorComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() public templates: CustomReportTemplate[] = [];
    @Input() public placeholderEvents: Observable<PlaceholderEvent>;
    @Input() public selectedTemplate: string;
    @Output() public templateSelected = new EventEmitter<CustomReportTemplateEvent>();
    public content: string;
    public currentTemplate: CustomReportTemplate;
    private _editorId = `report-template-editor-${Date.now()}`;
    private _isOldTemplate = false;
    private _subscription: Subscription;

    get editorId(): string {
        return this._editorId;
    }

    get isOldTemplate(): boolean {
        return this._isOldTemplate;
    }

    public constructor(private _editorUtility: EditorUtilityService) { }

    public ngOnInit(): void {
        this.currentTemplate = new CustomReportTemplate();
        this.emitEvent();
        this._subscription = this.placeholderEvents.subscribe(_ => this.onNewPlaceholderEvent(_));
    }

    public ngAfterViewInit(): void {
        const builtinOptions = {
            change: () => this.syncEditorAndTemplateContent()
        };

        const customOptions = new CustomEditorOption(this.content);
        this._editorUtility.setupEditor(this._editorId, builtinOptions, customOptions);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const newValue = changes?.selectedTemplate?.currentValue;
        if (newValue) {
            const template = this.templates.find(_ => _.name === newValue);
            if (template) {
                setTimeout(() => {
                    this.loadTemplate({ value: template } as MatSelectChange);
                });
            }
        }
    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public loadTemplate(event: MatSelectChange): void {
        this._isOldTemplate = true;
        this.currentTemplate = event.value;
        this._editorUtility.setContent(this._editorId, event.value.content);
        this.emitEvent();
    }

    public clearTemplate(): void {
        this._isOldTemplate = false;
        this.currentTemplate = new CustomReportTemplate();
        this._editorUtility.setContent(this._editorId, '');
        this.emitEvent();
    }

    public emitEvent(): void {
        const event = new CustomReportTemplateEvent();
        event.action = this._isOldTemplate? 'update' : 'new';
        event.template = this.currentTemplate;
        this.templateSelected.emit(event);
    }

    private onNewPlaceholderEvent(placeholderEvent: PlaceholderEvent): void {
        const { event, placeholder } = placeholderEvent;
        const style = '-webkit-user-select:all;-moz-user-select:all;-ms-user-select:all;user-select:all';
        const handleId = `handle-${placeholder.identifier}`;
        const value = `<span class="placeholder-element" contenteditable="false" style="${style}" id="${placeholder.identifier}">{{${placeholder.name}}}</span><span id="${handleId}"></span>`;

        if (event === ReportPlaceholderEventType.Add) {
            const editor = this._editorUtility.getEditor(this._editorId);
            editor.exec('inserthtml', { value });
            this._editorUtility.focusToEnd(this._editorId, handleId);
        }
        else if (event === ReportPlaceholderEventType.Delete) {
            this._editorUtility.removeElement(this._editorId, placeholder.identifier);
            this._editorUtility.removeElement(this._editorId, handleId);
            this.syncEditorAndTemplateContent();
        }
        else if (event === ReportPlaceholderEventType.Update) {
            const newContent = `{{${placeholder.name}}}`;
            this._editorUtility.replaceElementContent(this._editorId, placeholder.identifier, newContent);
            this.syncEditorAndTemplateContent();
        }
    }

    private syncEditorAndTemplateContent(): void {
        this.currentTemplate.content = this._editorUtility.getContent(this._editorId);
        const placeholders = this._editorUtility.getElementsFromBodyBySelector(this._editorId, '.placeholder-element');
        if (placeholders.length !== this.currentTemplate.placeholders.length) {
            this.currentTemplate.placeholders = this.currentTemplate.placeholders.filter(_ => placeholders.some(p => p.id === _.identifier));
        }

        this.emitEvent();
    }
}
