import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { InvoiceFormat } from '../../../data-models/invoice-format/invoice-format';
import CodeDesc from '../../../data-models/generic/code-description';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';
import { LanguageKey } from '../../../enums/language-key.enum';

@Injectable({
    providedIn: 'root'
})
export class DevelopHttpService {
    public readonly _api = `${environment.fswApiUrl}api2/develop`;

    constructor(private _http: HttpClient) { }

    public async getMinimumGeofenceRecordingTime(): Promise<number> {
        try {
            const endpoint = `${this._api}/getGeofenceMinTime`;

            return await this._http.get<number>(endpoint).toPromise();
        }
        catch {
            return 0;
        }
    }

    public async updateMinimumGeofenceRecordingTime(seconds: number): Promise<boolean> {
        try {
            const endpoint = `${this._api}/setGeofenceMinTime/${seconds}`;
            await this._http.get(endpoint).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async getCAInvoiceCodes(): Promise<CodeDesc[]> {
        try {
            const endpoint = `${this._api}/getCAInvoiceFormatCodes`;
            const data = await this._http.get<CodeDesc[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getUSInvoiceCodes(): Promise<CodeDesc[]> {
        try {
            const endpoint = `${this._api}/getUSInvoiceFormatCodes`;
            const data = await this._http.get<CodeDesc[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getInvoiceFormats(): Promise<InvoiceFormat> {
        try {
            const endpoint = `${this._api}/invoiceFormats`;
            const data = await this._http.get<InvoiceFormat>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async saveInvoiceFormats(invoiceFormat: InvoiceFormat): Promise<boolean> {
        try {
            const endpoint = `${this._api}/invoiceFormats`;
            const result = await this._http.post<boolean>(endpoint, invoiceFormat).toPromise();

            return result;
        }
        catch {
            return false;
        }
    }

    public async getPhotoReportDisclaimer(country: string, language: string): Promise<string | null> {
        try {
            const endpoint = `${this._api}/disclaimers/photo-report/${country}?language=${language}`;

            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return null;
        }
    }

    public async updatePhotoReportDisclaimer(content: string, country: string, language: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/disclaimers/photo-report/${country}?language=${language}`;
            const headers = { 'content-type': 'application/json' };

            return await this._http.post<boolean>(endpoint, JSON.stringify(content), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getConditionReportDisclaimer(country: string, language = 'en'): Promise<string | null> {
        try {
            const endpoint = `${this._api}/disclaimers/condition-report/${country}?language=${language}`;

            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return null;
        }
    }

    public async updateConditionReportDisclaimer(content: string, country: string, language = 'en'): Promise<boolean> {
        try {
            const endpoint = `${this._api}/disclaimers/condition-report/${country}?language=${language}`;
            const headers = { 'content-type': 'application/json' };

            return await this._http.post<boolean>(endpoint, JSON.stringify(content), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getQuoteDisclaimer(country: string, language: string = LanguageKey.EN): Promise<string | null> {
        try {
            const endpoint = `${this._api}/disclaimers/quote/${country}?language=${language}`;

            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return null;
        }
    }

    public async updateQuoteDisclaimer(content: string, country: string, language: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/disclaimers/quote/${country}?language=${language}`;
            const headers = { 'content-type': 'application/json' };

            return await this._http.post<boolean>(endpoint, JSON.stringify(content), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }
}
