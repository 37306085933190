export enum ImageCardAction {
    Edit = 'Edit',
    Delete = 'Delete',
    Click = 'click',
    Swap = 'Swap',
    Before = 'Move Before',
    After = 'Move After',
    Save = 'Save'

}
