<div class="general-settings-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="3vh">
    <div *ngIf="isLoading" fxLayoutAlign="center center" fxFill>
        <dots-loader></dots-loader>
    </div>

    <ng-container *ngIf="!isLoading">
        <div *ngIf="!isInternal" class="general-settings-input-field" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field>
                <mat-label>{{ 'admin.generalSettings.signInOutNumberLabel' | translate }}</mat-label>
                <input type="text" matInput cdkFocusInitial [formControl]="signInOutNumber" appPhoneMask />
                <mat-error *ngIf="signInOutNumber.invalid">{{ 'admin.generalSettings.signInOutNumberError' | translate }}</mat-error>
            </mat-form-field>

            <button mat-raised-button
                color="primary"
                [disabled]="signInOutNumber.invalid"
                (click)="updateSignInOutNumber()">

                {{ 'admin.generalSettings.saveButtonLabel' | translate }}
            </button>
        </div>

        <ng-container *ngIf="isInternal">
            <div class="general-settings-input-field" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field>
                    <mat-label>{{ 'admin.generalSettings.budgetThresholdLabel' | translate }}</mat-label>
                    <input type="number" matInput cdkFocusInitial [formControl]="budgetThreshold" />
                    <mat-error *ngIf="budgetThreshold.invalid">{{ currencyValidator.message }}</mat-error>
                </mat-form-field>

                <button mat-raised-button
                    color="primary"
                    [disabled]="budgetThreshold.invalid"
                    (click)="updateBudgetThreshold()">

                    {{ 'admin.generalSettings.saveButtonLabel' | translate }}
                </button>
            </div>

            <div class="general-settings-input-field" fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field>
                    <mat-label>{{ 'admin.generalSettings.geofenceRecordingTimeLabel' | translate }}</mat-label>
                    <input type="number" matInput [formControl]="geofenceRecordingTime" />
                    <mat-error *ngIf="geofenceRecordingTime.invalid">{{ secondsValidator.message }}</mat-error>
                </mat-form-field>

                <button mat-raised-button
                    color="primary"
                    [disabled]="geofenceRecordingTime.invalid"
                    (click)="updateGeofenceRecordingTime()">

                    {{ 'admin.generalSettings.saveButtonLabel' | translate }}
                </button>
            </div>

            <div *ngIf="canUpdateDynamicsHoldStatus" class="general-settings-danger-zone" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.5vh">
                <span class="general-settings-danger-zone-label" fxFlexAlign="start">
                    {{ 'admin.generalSettings.dangerZoneLabel' | translate }}
                </span>

                <div class="general-settings-danger-zone-item" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="general-settings-danger-zone-item-description" fxLayout="column" fxLayoutGap="3px">
                        <span>{{ 'admin.generalSettings.dynamicsHoldStatusLabel' | translate }}</span>

                        <span *ngIf="dynamicsHoldStatus === dynamicsHoldStatuses.Released">
                            {{ 'admin.generalSettings.dynamicsReleasedStatusDescription' | translate }}
                        </span>

                        <span *ngIf="dynamicsHoldStatus === dynamicsHoldStatuses.Hold">
                            {{ 'admin.generalSettings.dynamicsHoldStatusDescription' | translate }}
                        </span>

                        <span *ngIf="dynamicsHoldStatus === dynamicsHoldStatuses.Unavailable">
                            {{ 'admin.generalSettings.dynamicsStatusUnavailableDescription' | translate }}
                        </span>
                    </div>

                    <button *ngIf="dynamicsHoldStatus === dynamicsHoldStatuses.Released"
                        mat-raised-button
                        color="warn"
                        (click)="updateDynamicsHoldStatus(true)">

                        {{ 'admin.generalSettings.dynamicsHoldStatusHoldButtonText' | translate }}
                    </button>

                    <button *ngIf="dynamicsHoldStatus === dynamicsHoldStatuses.Hold"
                        mat-raised-button
                        color="primary"
                        (click)="updateDynamicsHoldStatus(false)">

                        {{ 'admin.generalSettings.dynamicsHoldStatusReleaseButtonText' | translate }}
                    </button>

                    <button *ngIf="dynamicsHoldStatus === dynamicsHoldStatuses.Unavailable"
                        mat-raised-button
                        color="primary"
                        (click)="getDynamicsHoldStatus()">

                        {{ 'admin.generalSettings.dynamicsHoldStatusRefreshButtonText' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
