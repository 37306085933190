import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import JsonUtility from '../../utility/json-utility.service';
import AddressVerification from '../../../data-models/address-verification';
import Customer from '../../../data-models/customer';
import SOAddress from './../../../data-models/so-address';
import SiteFilter from './../../../data-models/dispatch/site-filter';
import { environment } from '../../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomerCreationHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/customercreationtool`;

    constructor(private _http: HttpClient) { }

    public async getAllCustomers(): Promise<string[]> {
        try {
            const url = `${this._api}/customer/all`;

            return await this._http.get<string[]>(url).toPromise();
        }
        catch {
            return new Array<string>();
        }
    }

    public searchCustomersByNameAndStatusAndClass(name: string, status: string, customerClass: string): Observable<Customer[]> {
        const url = `${this._api}/customer/searchbyname/${status}/${customerClass}`;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.post<Customer[]>(url, JSON.stringify(name), { headers }).pipe(
            map(result => JsonUtility.toCamelCaseKeys(result)),
            catchError(() => of(null))
        );
    }

    public searchCustomersByName(name: string): Observable<Customer[]> {
        const url = `${this._api}/customer/searchbyname`;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.post<Customer[]>(url, JSON.stringify(name), { headers }).pipe(
            map(result => JsonUtility.toCamelCaseKeys(result)),
            catchError(() => of(null))
        );
    }

    public async isCustomerSiteActive(customerId: string, siteId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/customer/${customerId}/sites/${siteId}/is-active`;

            return await this._http.get<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async isCustomerActive(customerId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/customer/${customerId}/is-active`;

            return await this._http.get<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async verifyAddress(address: AddressVerification): Promise<AddressVerification> {
        try {
            const url = `${this._api}/customer/verifyaddress`;
            const data = await this._http.post<AddressVerification>(url, address).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async verifySiteAddress(address: AddressVerification): Promise<AddressVerification> {
        try {
            const url = `${this._api}/customer/verifysiteaddress`;
            const data = await this._http.post<AddressVerification>(url, address).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getCustomerById(id: string): Promise<Customer> {
        try {
            const url = `${this._api}/customer/searchbyCustId/${id}`;
            const data = JsonUtility.toCamelCaseKeys(await this._http.get<Customer>(url).toPromise());

            if (data.status) {
                data.addresses = data.addresses.filter(_ => _.status !== 'I');
            }

            return data;
        }
        catch {
            return null;
        }
    }

    public async getSiteSuggestions(filter: SiteFilter): Promise<SOAddress[]> {
        try {
            const url = `${this._api}/customer/sites/findSuggestions`;
            const data = await this._http.post<SOAddress[]>(url, filter).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }
}
