<form [formGroup]="handOffMeetingFormGroup">

    <div fxFill fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">

        <div fxLayout fxLayoutAlign="center start" class="hand-off-meeting-scrollable-content-wrapper">
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" class="hand-off-meeting-column-size">
            <mat-card fxLayout fxLayoutAlign="center center" class="hand-off-dialog-row-1-container">

                <div fxLayout fxFlex="50" fxLayoutAlign="start center">
                    <grid-display-item fxFlex="85" [title]="translate.instant('projects.handOffMeeting.projectName')" [content]="projectName">
                    </grid-display-item>
                </div>
                <div fxLayout fxFlex="50" fxLayoutAlign="end center">
                    <grid-display-item fxFlex="35" [title]="translate.instant('projects.handOffMeeting.projectId')" [content]="projectId">
                    </grid-display-item>
                </div>

            </mat-card>

            <mat-card fxLayout fxLayoutAlign="start start" fxLayoutGap="100px" class="hand-off-dialog-row-2-container">

                <div fxLayout="column" fxFlexFill fxLayoutAlign="start start" fxFlex="65">
                    <h4>{{ 'projects.handOffMeeting.attendees' | translate }}</h4>
                    <div fxLayout fxFlexFill fxLayoutGap="100px" fxLayoutAlign="start start">

                        <mat-form-field fxFlex="50">
                            <mat-label>{{ 'projects.handOffMeeting.manager' | translate }}</mat-label>

                            <input type="text" aria-label="Number" matInput formControlName="manager"
                                [matAutocomplete]="managerAuto">
                            <mat-autocomplete #managerAuto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredManagers | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field fxFlex="50">
                            <mat-label>{{ 'projects.handOffMeeting.superintendant' | translate }}</mat-label>

                            <input type="text" aria-label="Number" matInput formControlName="superintendant"
                                [matAutocomplete]="superAuto">
                            <mat-autocomplete #superAuto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredSuperintendants | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                    </div>

                    <div fxLayout fxFlexFill fxLayoutGap="100px" fxLayoutAlign="start start">

                        <mat-form-field fxFlex="50">
                            <mat-label>{{ 'projects.handOffMeeting.estimator' | translate }}</mat-label>

                            <input type="text" aria-label="Number" matInput formControlName="estimator"
                                [matAutocomplete]="estimatorAuto">
                            <mat-autocomplete #estimatorAuto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredEstimator | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field fxFlex="50">
                            <mat-label>{{ 'projects.handOffMeeting.safetySpecialist' | translate }}</mat-label>

                            <input type="text" aria-label="Number" matInput formControlName="safetySpecialist"
                                [matAutocomplete]="safetyAuto">
                            <mat-autocomplete #safetyAuto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredSafetySpecialist | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                    </div>

                </div>


            </mat-card>

            <mat-card fxLayoutAlign="start start"
                class="hand-off-dialog-row-2-container">
                <div fxLayout="column" fxFlex="30" fxLayoutAlign="start start">
                    <h4>{{ 'projects.handOffMeeting.meetingDateTime' | translate }}</h4>
                    <kendo-datetimepicker fxFlexFill #kendoDateTime [format]="'yyyy-MM-dd HH:mm'"
                        [cancelButton]="false" formControlName="meetingTime" [popupSettings]="popupSettings">
                    </kendo-datetimepicker>
                </div>
        </mat-card>

            <mat-card fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px"
                class="hand-off-dialog-row-2-container" *ngFor="let section of handOffTemplate?.sections; let i=index">

                <div fxFlexFill>
                    <h3>{{section.name}}</h3>

                    <hand-off-meeting-question *ngIf="section.name === 'Crew'"
                        [isReadonly]="true"
                        [handOffQuestionFormGroup]="foremanQuestionFormGroup"
                        [responses]="[]">
                    </hand-off-meeting-question>

                    <hand-off-meeting-question *ngFor="let handOffQuestion of section.questions; let j=index"
                        [isReadonly]="false"
                        [handOffQuestionFormGroup]="getQuestionFormGroup(i,j,section.name)"
                        [responses]="handOffQuestion?.responses">
                    </hand-off-meeting-question>

                </div>
            </mat-card>

            <mat-card fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px"
                class="hand-off-dialog-row-2-container">
                <div fxLayout fxFlexFill fxLayoutAlign="start start" fxLayoutGap="10px">
                    <h3 fxFlex="40" fxFlexFill fxLayoutAlign="start start">
                        {{ 'projects.handOffMeeting.notes' | translate }}
                    </h3>
                    <mat-form-field fxFlex="60">
                        <textarea matInput formControlName="notes" rows="2">
                        </textarea>
                        <mat-error *ngIf="isFormControlValid('notes')">{{getErrorMessage('notes')}}</mat-error>
                    </mat-form-field>
                </div>
            </mat-card>
            </div>
        </div>

            <div fxLayout fxLayoutAlign="end center" fxLayoutGap="20px" class="hand-off-dialog-row-2-container">
                <button mat-stroked-button (click)="stepClicked.emit('back')">{{ 'projects.handOffMeeting.back' | translate }}</button>
                <button *ngIf="!hasFollowUpStep" mat-raised-button color="primary" (click)="dispatchProject()">{{ 'projects.handOffMeeting.complete' | translate }}</button>
                <button *ngIf="hasFollowUpStep" mat-stroked-button (click)="dispatchProject()">{{ 'projects.handOffMeeting.next' | translate }}</button>
            </div>

    </div>
</form>
