import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import ValidatorSet from '../../../core/data-models/validator-set';
import ProjectBasicInfo from '../../../core/data-models/project-general-info/project-basic-info';
import CustomerInfo from '../../../core/data-models/project-general-info/customer-info';
import DispatchInfo from '../../../core/data-models/project-general-info/dispatch-info';
import ContactInfo from '../../../core/data-models/contact-info';
import JobContactInfo from '../../../core/data-models/project-general-info/job-contact-info';
import ProjectGeneralInfo from '../../../core/data-models/project-general-info/project-general-info';
import KeyValuePair from '../../../core/data-models/key-value-pair';
import TemplateFieldMeta from '../../../core/data-models/template-field-meta';
import GridItemEditOption from '../../../core/data-models/grid-item-edit-option';
import ActionMenuItemOption from '../../../core/data-models/action-menu-item-option';
import InformationGridItem from '../../../core/data-models/information-grid/information-grid-item';
import InformationGridRow from '../../../core/data-models/information-grid/information-grid-row';
import InformationGridConfig from '../../../core/data-models/information-grid/information-grid-config';
import { PhoneDisplayFormatPipe } from '../../pipes/phone-display-format/phone-display-format.pipe';
import { PercentageStringPipe } from '../../pipes/percentage-string/percentage-string.pipe';
import { CustomerIdPlaceholderPipe } from '../../pipes/customer-id-placeholder/customer-id-placeholder.pipe';
import { BranchLocalTimePipe } from '../../pipes/branch-local-time/branch-local-time.pipe';
import { BranchHttpService } from '../../../core/services/http/branch-http/branch-http.service';
import { CustomerHttpService } from '../../../core/services/http/customer-http/customer-http.service';
import { ValidatorFactory } from '../../../core/services/validator-factory/validator-factory.service';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';
import CustomerUtility from '../../../core/services/utility/customer-utility/customer-utility.service';
import { InformationGridItemType } from '../../../core/enums/information-grid-item-type.enum';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import Employee from '../../../core/data-models/employee';

@Component({
    selector: 'project-general-info-panel',
    styleUrls: ['./project-general-info-panel.component.scss'],
    templateUrl: './project-general-info-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectGeneralInfoPanelComponent implements OnInit {
    @Input() public generalInfo: ProjectGeneralInfo;
    @Input() public hasAnimation = true;
    @Input() public isReadonly = false;
    @Input() public actionOptions: ActionMenuItemOption[] = [];
    @Output() public actionSelect = new EventEmitter<ActionMenuItemOption>();
    @Output() public basicInfoUpdate = new EventEmitter<KeyValuePair>();
    @Output() public customerUpdate = new EventEmitter<KeyValuePair>();
    @Output() public dispatchInfoProjectFieldUpdate = new EventEmitter<KeyValuePair>();
    @Output() public dispatchInfoTemplateFieldUpdate = new EventEmitter<KeyValuePair>();
    @Output() public reportingContactUpdate = new EventEmitter<KeyValuePair>();
    @Output() public siteContactUpdate = new EventEmitter<TemplateFieldMeta>();
    public basicInfoCardConfig: InformationGridConfig<ProjectBasicInfo>;
    public customerCardConfig: InformationGridConfig<CustomerInfo>;
    public dispatchInfoCardConfig: InformationGridConfig<DispatchInfo>;
    public rejectionReasonCardConfig: InformationGridConfig<{ reason: string }>;
    public reportingContactCardConfig: InformationGridConfig<ContactInfo>;
    public siteContactCardConfig: InformationGridConfig<JobContactInfo>;
    public billingContactCardConfig: InformationGridConfig<ContactInfo>;
    public showCustomerDetails = false;
    private accountManagers: Employee[] = [];
    private _isSignInOutNumberRequired = false;
    private _isLoaded = false;

    //eslint-disable-next-line
    constructor(public translate: TranslateService,
                private _featureFlagService: FeatureFlagService,
                private _userService: UserHttpService,
                private _salesCodeConfigService: SalesCodeConfigService,
                private _branchHttp: BranchHttpService,
                private _customerHttp: CustomerHttpService,
                private _validatorFactory: ValidatorFactory,
                private _phoneDisplayFormatPipe: PhoneDisplayFormatPipe,
                private _customerIdPlaceholderPipe: CustomerIdPlaceholderPipe,
                private _percentageStringPipe: PercentageStringPipe,
                private _branchLocalTimePipe: BranchLocalTimePipe,
                private _currencyPipe: CurrencyPipe,
                private _changeDetectorRef: ChangeDetectorRef) { }

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    public async ngOnInit(): Promise<void> {
        const { basicInfo, customer, dispatchInfo } = this.generalInfo;
        const { rejectionReason, reportingContact, siteContact, billingContact } = this.generalInfo;

        if (!this.isReadonly && CustomerUtility.isConfirmedCustomer(customer.customerId)) {
            const number = await this._customerHttp.getCustomerSignNumber(customer.customerId);
            this._isSignInOutNumberRequired = Boolean(number);
        }

        this.accountManagers = await this._userService.getBranchUsersByLocation(basicInfo.branch);
        this.basicInfoCardConfig = this.createBasicInfoCardConfig(basicInfo);
        this.customerCardConfig = this.createCustomerCardConfig(customer);
        this.dispatchInfoCardConfig = await this.createDispatchInfoCardConfig(dispatchInfo);
        this.reportingContactCardConfig = this.createContactCardConfig(reportingContact, true, true);
        this.siteContactCardConfig = this.createContactCardConfig(siteContact);
        this.billingContactCardConfig = this.createContactCardConfig(billingContact);
        this.rejectionReasonCardConfig = this.createRejectionReasonCardConfig(rejectionReason);
        this._isLoaded = true;
        this._changeDetectorRef.markForCheck();
    }

    public onDispatchInfoUpdate(event: KeyValuePair): void {
        if (['signCode', 'signNumber', 'completeByDate'].includes(event.key)) {
            this.dispatchInfoProjectFieldUpdate.emit(event);
        }
        else {
            this.dispatchInfoTemplateFieldUpdate.emit(event);
        }
    }

    public onItemDoubleClick(key: string): void {
        if (key === 'workOrder') {
            this.dispatchInfoProjectFieldUpdate.emit({ key: 'workOrder' } as KeyValuePair);
        }
        else if (key === 'dispatcherNote') {
            this.dispatchInfoTemplateFieldUpdate.emit({ key: 'dispatcherNote' } as KeyValuePair);
        }
    }

    private createBasicInfoCardConfig(basicInfo: ProjectBasicInfo): InformationGridConfig<ProjectBasicInfo> {
        const projectNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.projectName');
        const userDefinedProjectIdLabel = this.translate.instant('shared.projectGeneralInfoPanel.userDefinedProjectId');
        const projectIdLabel = this.translate.instant('shared.projectGeneralInfoPanel.projectId');
        const systemProjectIdLabel = this.translate.instant('shared.projectGeneralInfoPanel.systemProjectId');
        const followUpToLabel = this.translate.instant('shared.projectGeneralInfoPanel.followUpTo');
        const originateQuoteLabel = this.translate.instant('shared.projectGeneralInfoPanel.originalQuote');
        const address1Label = this.translate.instant('shared.projectGeneralInfoPanel.address1');
        const address2Label = this.translate.instant('shared.projectGeneralInfoPanel.address2');
        const cityLabel = this.translate.instant('shared.projectGeneralInfoPanel.city');
        const stateLabel = this.translate.instant('shared.projectGeneralInfoPanel.state');
        const countryLabel = this.translate.instant('shared.projectGeneralInfoPanel.country');
        const zipLabel = this.translate.instant('shared.projectGeneralInfoPanel.zip');
        const reviewerNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.reviewerName');
        const reviewerCommentLabel = this.translate.instant('shared.projectGeneralInfoPanel.reviewerComment');
        const accountManagerLabel = this.translate.instant('shared.projectGeneralInfoPanel.accountManager');
        const processorNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.processorName');
        const processorCommentLabel = this.translate.instant('shared.projectGeneralInfoPanel.processorComment');
        const workOrderLabel = this.translate.instant('shared.projectGeneralInfoPanel.workOrder');
        const projectName = new InformationGridItem(projectNameLabel, 'projectName', 53);
        const userDefinedProjectId = new InformationGridItem(userDefinedProjectIdLabel, 'userDefinedProjectId', 53);
        const projectId = new InformationGridItem(this.isInternal ? projectIdLabel : systemProjectIdLabel, 'projectId', 46.5);
        const followUpTo = new InformationGridItem(followUpToLabel, 'followUpTo', 53);
        const originateQuote = new InformationGridItem(originateQuoteLabel, 'originateQuote', 46.5);
        const address1 = new InformationGridItem(address1Label, 'address1', 53);
        const address2 = new InformationGridItem(address2Label, 'address2', 46.5);
        const city = new InformationGridItem(cityLabel, 'city', 26.5);
        const state = new InformationGridItem(stateLabel, 'state', 26.5);
        const country = new InformationGridItem(countryLabel, 'country', 23.25);
        const zip = new InformationGridItem(zipLabel, 'zip', 23.25);
        const reviewerName = new InformationGridItem(reviewerNameLabel, 'reviewerName', 29.75);
        const reviewerComment = new InformationGridItem(reviewerCommentLabel, 'reviewerComment', 69.5);
        const processorName = new InformationGridItem(processorNameLabel, 'processorName', 29.75);
        const processorComment = new InformationGridItem(processorCommentLabel, 'processorComment', 69.5);
        const accountManager = new InformationGridItem(accountManagerLabel, 'accountManager', 100);
        const workOrder = new InformationGridItem(workOrderLabel, 'workOrder', 100, true, true);
        const useQuoteFeatures = this._featureFlagService.featureFlags[FeatureFlags.UseQuotesFeature];

        if (!this.isReadonly) {
            userDefinedProjectId.editOptions = new GridItemEditOption();
            accountManager.editOptions = new GridItemEditOption();
            accountManager.optionValues = [{ key: 'TBD', value: this.translate.instant('shared.toBeDetermined') } as KeyValuePair, ...this.accountManagers.map(employee => ({ key: employee.preferredName, value: employee.preferredName } as KeyValuePair))];
            accountManager.type = InformationGridItemType.Dropdown;
        }

        if (this.isInternal) {
            return new InformationGridConfig(basicInfo, [
                new InformationGridRow([projectName, projectId]),
                useQuoteFeatures ? new InformationGridRow([followUpTo, originateQuote]) : null,
                new InformationGridRow([address1, address2]),
                new InformationGridRow([city, state, country, zip]),
                new InformationGridRow([reviewerName, reviewerComment]),
                new InformationGridRow([processorName, processorComment]),
                new InformationGridRow([accountManager]),
                new InformationGridRow([workOrder], 30)
            ].filter(Boolean));
        }

        return new InformationGridConfig(basicInfo, [
            new InformationGridRow([projectName]),
            new InformationGridRow([userDefinedProjectId, projectId]),
            useQuoteFeatures ? new InformationGridRow([followUpTo, originateQuote]) : null,
            new InformationGridRow([address1, address2]),
            new InformationGridRow([city, state, country, zip]),
            new InformationGridRow([reviewerName, reviewerComment]),
            new InformationGridRow([accountManager]),
            new InformationGridRow([workOrder], 40)
        ].filter(Boolean));
    }

    //eslint-disable-next-line
    private async createDispatchInfoCardConfig(dispatchInfo: DispatchInfo): Promise<InformationGridConfig<DispatchInfo>> {
        const { branch } = this.generalInfo.basicInfo;
        const branches = await this._branchHttp.getAllBranches();
        const salesCode = this._salesCodeConfigService.getSalesCode(this.generalInfo.basicInfo.projectId);
        const hasCompleteByDate = this._salesCodeConfigService.isCompleteByDateAllowed(salesCode);
        const foremanNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.foremanName');
        const estimatedTimeOfArrivalLabel = this.translate.instant('shared.projectGeneralInfoPanel.estimatedTimeOfArrival');
        const completeByDateLabel = this.translate.instant('shared.projectGeneralInfoPanel.completeByDate');
        const dispatcherNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.dispatcherName');
        const dispatcherNoteLabel = this.translate.instant('shared.projectGeneralInfoPanel.dispatcherNote');
        const dispatcherPhoneLabel = this.translate.instant('shared.projectGeneralInfoPanel.dispatcherPhone');
        const notToExceedLabel = this.translate.instant('shared.projectGeneralInfoPanel.notToExceed');
        const estimatedHoursLabel = this.translate.instant('shared.projectGeneralInfoPanel.estimatedHours');
        const signCodeLabel = this.translate.instant('shared.projectGeneralInfoPanel.signCode');
        const signNumberLabel = this.translate.instant('shared.projectGeneralInfoPanel.signNumber');
        const retentionMethodLabel = this.translate.instant('shared.projectGeneralInfoPanel.retentionMethod');
        const retentionPercentLabel = this.translate.instant('shared.projectGeneralInfoPanel.retentionPercent');
        const foremanName = new InformationGridItem(foremanNameLabel, 'foremanName', 50);
        const estimatedTimeOfArrival = new InformationGridItem(estimatedTimeOfArrivalLabel, 'estimatedTimeOfArrival');
        const completeByDate = new InformationGridItem(completeByDateLabel, 'completeByDate', 50);
        const dispatcherName = new InformationGridItem(dispatcherNameLabel, 'dispatcherName', 50);
        const dispatcherNote = new InformationGridItem(dispatcherNoteLabel, 'dispatcherNote', 100, true, true);
        const dispatcherPhone = new InformationGridItem(dispatcherPhoneLabel, 'dispatcherPhone', 50);
        const notToExceed = new InformationGridItem(notToExceedLabel, 'notToExceed', 50);
        const estimatedHours = new InformationGridItem(estimatedHoursLabel, 'estimatedHours', 50);
        const signCode = new InformationGridItem(signCodeLabel, 'signCode', 50);
        const signNumber = new InformationGridItem(signNumberLabel, 'signNumber', 50);
        const retentionMethod = new InformationGridItem(retentionMethodLabel, 'retentionMethod', 50);
        const retentionPercent = new InformationGridItem(retentionPercentLabel, 'retentionPercent', 50);
        const phoneValidator = this._validatorFactory.createPhoneValidator();
        completeByDate.type = InformationGridItemType.Datepicker;
        estimatedTimeOfArrival.transform = (_: string) => this._branchLocalTimePipe.transform(_, branch, branches);
        dispatcherPhone.displayPipe = this._phoneDisplayFormatPipe;
        notToExceed.displayPipe = this._currencyPipe;
        signNumber.displayPipe = this._phoneDisplayFormatPipe;
        retentionPercent.displayPipe = this._percentageStringPipe;

        if (!this.isReadonly) {
            signCode.editOptions = new GridItemEditOption();

            signNumber.editOptions = {
                validatorSets: [phoneValidator],
                inputMask: GenericUtilityService.getPhoneMaskStringWithExtension()
            } as GridItemEditOption;

            if (this._isSignInOutNumberRequired) {
                const requiredErrorText = this.translate.instant('shared.projectGeneralInfoPanel.requiredField');
                const requiredValidator = new ValidatorSet(Validators.required, requiredErrorText);
                signCode.editOptions.validatorSets = [requiredValidator];
                signNumber.editOptions.validatorSets = [requiredValidator, phoneValidator];
            }

            notToExceed.editOptions = {
                maxLength: 14,
                validatorSets: [this._validatorFactory.createCurrencyValidator()]
            } as GridItemEditOption;

            estimatedHours.editOptions = {
                maxLength: 5,
                validatorSets: [this._validatorFactory.createPositiveIntegerValidator()]
            } as GridItemEditOption;
        }

        if (this.isInternal) {
            return new InformationGridConfig(dispatchInfo, [
                new InformationGridRow(hasCompleteByDate ? [foremanName, completeByDate] : [foremanName]),
                new InformationGridRow([dispatcherName, dispatcherPhone]),
                new InformationGridRow([notToExceed, estimatedHours]),
                new InformationGridRow([signCode, signNumber]),
                new InformationGridRow([retentionMethod, retentionPercent]),
                new InformationGridRow([dispatcherNote], 20)
            ]);
        }

        return new InformationGridConfig(dispatchInfo, [
            new InformationGridRow([foremanName]),
            new InformationGridRow([estimatedTimeOfArrival]),
            new InformationGridRow(hasCompleteByDate ? [notToExceed, completeByDate] : [notToExceed]),
            new InformationGridRow([dispatcherName, dispatcherPhone]),
            new InformationGridRow([dispatcherNote], 35)
        ]);
    }

    private createCustomerCardConfig(customer: CustomerInfo): InformationGridConfig<CustomerInfo> {
        const customerNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.customerName');
        const siteNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.siteName');
        const customerIdLabel = this.translate.instant('shared.projectGeneralInfoPanel.customerId');
        const siteIdLabel = this.translate.instant('shared.projectGeneralInfoPanel.siteId');
        const storeNumberLabel = this.translate.instant('shared.projectGeneralInfoPanel.storeNumber');
        const customerPoLabel = this.translate.instant('shared.projectGeneralInfoPanel.customerPo');

        const customerName = new InformationGridItem(customerNameLabel, 'customerName');
        const siteName = new InformationGridItem(siteNameLabel, 'siteName');
        const customerId = new InformationGridItem(customerIdLabel, 'customerId', 50);
        const siteId = new InformationGridItem(siteIdLabel, 'siteId', 50);
        const storeNumber = new InformationGridItem(storeNumberLabel, 'storeNumber', 50);
        const customerPo = new InformationGridItem(customerPoLabel, 'customerPo', 50);
        customerId.displayPipe = this._customerIdPlaceholderPipe;

        const rows = [
            new InformationGridRow([customerName]),
            new InformationGridRow([siteName]),
            new InformationGridRow([customerId, siteId]),
            new InformationGridRow([customerPo, storeNumber])
        ];

        return new InformationGridConfig(customer, rows);
    }

    private createRejectionReasonCardConfig(reason: string): InformationGridConfig<{ reason: string }> {
        if (!reason) {
            return null;
        }

        const rejectionReason = new InformationGridItem('Reason', 'reason', 100, true);
        const rows = [new InformationGridRow([rejectionReason], 100)];

        return new InformationGridConfig({ reason }, rows);
    }

    private createContactCardConfig<T>(contact: T, isEditable = false, isMobileEditable = false): InformationGridConfig<T> {
        const nameLabel = this.translate.instant('shared.projectGeneralInfoPanel.name');
        const emailLabel = this.translate.instant('shared.projectGeneralInfoPanel.email');
        const officePhoneLabel = this.translate.instant('shared.projectGeneralInfoPanel.officePhone');
        const mobilePhoneLabel = this.translate.instant('shared.projectGeneralInfoPanel.mobilePhone');

        const name = new InformationGridItem(nameLabel, 'name');
        const email = new InformationGridItem(emailLabel, 'email');
        const officePhone = new InformationGridItem(officePhoneLabel, 'officePhone', 50);
        const mobilePhone = new InformationGridItem(mobilePhoneLabel, 'mobilePhone', 50);
        const emailValidator = this._validatorFactory.createEmailValidator();
        const phoneValidator = this._validatorFactory.createPhoneValidator();
        officePhone.displayPipe = this._phoneDisplayFormatPipe;
        mobilePhone.displayPipe = this._phoneDisplayFormatPipe;

        if (!this.isReadonly && isEditable) {
            name.editOptions = { maxLength: 40 } as GridItemEditOption;
            email.editOptions = { maxLength: 40, validatorSets: [emailValidator] } as GridItemEditOption;
            officePhone.editOptions = {
                validatorSets: [phoneValidator],
                inputMask: GenericUtilityService.getPhoneMaskStringWithExtension()
            } as GridItemEditOption;
        }

        if (!this.isReadonly && isMobileEditable) {
            mobilePhone.editOptions = {
                validatorSets: [phoneValidator],
                inputMask: GenericUtilityService.getPhoneMaskString()
            } as GridItemEditOption;
        }

        const rows = [
            new InformationGridRow([name]),
            new InformationGridRow([email]),
            new InformationGridRow([officePhone, mobilePhone])
        ];

        return new InformationGridConfig(contact, rows);
    }
}
