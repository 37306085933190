<div fxLayout="column" fxLayoutGap="20px" fxFill>
    <div fxLayout="row">
        <grid-display-item fxFlex="50" [title]="translate.instant('shared.selectedCustomer')" [content]="customerDisplayName">
        </grid-display-item>
        <div fxFlex="50" fxLayoutAlign="end center" *ngIf="allowCreate">

            <button mat-stroked-button color="primary" (click)="siteSelected.emit(null)">
                {{ 'shared.customerSiteSelect.buttons.addSite' | translate }}
            </button>

        </div>
    </div>

    <div fxLayout="row" class="site-selection-kendo-grid-div" fxFlex>
        <kendo-grid [kendoGridBinding]="availableSites" [pageSize]="10" [pageable]="true"
            [style.height.%]="100" filterable="menu" sortable="true">
            <kendo-grid-column field="shipToId" [width]="120" title="{{ 'shared.customerSiteSelect.table.shipToId' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.shipToId.startsWith('new') ? translate.instant('shared.customerSiteSelect.table.pending') : dataItem.shipToId}}
                </ng-template>
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="eq">
                        <kendo-filter-eq-operator></kendo-filter-eq-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="name" title="{{ 'shared.customerSiteSelect.table.name' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="address1" title="{{ 'shared.customerSiteSelect.table.address1' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="address2" title="{{ 'shared.customerSiteSelect.table.address2' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="140" field="city" title="{{ 'shared.customerSiteSelect.table.city' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="95" field="state" title="{{ 'shared.customerSiteSelect.table.state' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="eq">
                        <kendo-filter-eq-operator></kendo-filter-eq-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="95" field="country" title="{{ 'shared.customerSiteSelect.table.country' | translate }}" [filterable]="false">
            </kendo-grid-column>
            <kendo-grid-column [width]="95" field="storeNumber" title="{{ 'shared.customerSiteSelect.table.store' | translate }}" [filterable]="false">
            </kendo-grid-column>
            <kendo-grid-column [width]="110" [filterable]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button (click)="siteSelected.emit(dataItem)" mat-raised-button aria-label="site select button">
                        {{ 'shared.customerSiteSelect.buttons.select' | translate }}
                    </button>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</div>
