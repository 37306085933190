<customer-geo-coordinate-map *ngIf="geoCoordinateMode" (addressSelected)="selectSiteWithGeoCode($event)">
</customer-geo-coordinate-map>
<form *ngIf="!geoCoordinateMode" [formGroup]="newSiteFormGroup" fxFill>
    <div fxFill fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="start" fxFlex>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="25">
                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <h4>{{'shared.customerSiteCreate.addressOptions.title' | translate }}</h4>
                    <mat-radio-group fxLayout="column" *ngIf="showAddressSourceOptions()"
                        formControlName="addressInformationSource" aria-label="Select an option">
                        <mat-radio-button *ngIf="showCustomerSiteAddressSourceOption()" value="customer">
                            {{'shared.customerSiteCreate.addressOptions.customer' | translate }}
                        </mat-radio-button>
                        <mat-radio-button *ngIf="showBillingSiteAddressSourceOption()" value="billing">
                            {{'shared.customerSiteCreate.addressOptions.billing' | translate }}
                        </mat-radio-button>
                        <mat-radio-button value="new">
                            {{'shared.customerSiteCreate.addressOptions.newSite' | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="addressStatus?.visible && !addressCheckLoading" [ngClass]="addressStatus?.style">
                    <div *ngFor="let message of addressStatus?.messages">{{ message }}</div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" fxFill *ngIf="addressCheckLoading">
                    <dots-loader></dots-loader>
                </div>
            </div>
            <div formGroupName="siteDetails" fxLayout="column" fxLayoutAlign="start" fxFlex="50">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <mat-form-field fxFlex="70">
                        <mat-label>{{'shared.customerSiteCreate.site.name' | translate }}</mat-label>
                        <input matInput formControlName="name" maxlength="60">
                        <mat-error *ngIf="isFormControlValid('name')">{{getErrorMessage('name')}}</mat-error>
                    </mat-form-field>
                    <button *ngIf="!forceBilling && isNewSiteBeingCreated" mat-raised-button (click)="geoCoordinateMode=true">
                        {{'shared.customerSiteCreate.site.viaMapMessage' | translate }}
                    </button>
                </div>
                <div *ngIf="!siteSelectedFromGeoCoordinates" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <mat-form-field fxFlex="44">
                        <mat-label>{{'shared.customerSiteCreate.site.address1' | translate }}</mat-label>
                        <input matInput formControlName="address1" maxlength="50">
                        <mat-error *ngIf="isFormControlValid('address1')">{{getErrorMessage('address1')}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="44">
                        <mat-label>{{'shared.customerSiteCreate.site.address2' | translate }}</mat-label>
                        <input matInput formControlName="address2" maxlength="50">
                    </mat-form-field>
                </div>
                <div *ngIf="siteSelectedFromGeoCoordinates" fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field fxFlex="70">
                        <mat-label>{{'shared.customerSiteCreate.site.geoCode' | translate }}</mat-label>
                        <input matInput formControlName="geoCode">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <mat-form-field fxFlex="29">
                        <mat-label>{{'shared.customerSiteCreate.site.city' | translate }}</mat-label>
                        <input matInput formControlName="city" maxlength="50">
                        <mat-error *ngIf="isFormControlValid('city')">{{getErrorMessage('city')}}</mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="13">
                        <mat-label>{{'shared.customerSiteCreate.site.country' | translate }}</mat-label>
                        <mat-select formControlName="country">
                            <mat-option *ngFor="let country of countryList" [value]="country">
                                {{country}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="isFormControlValid('country')">{{getErrorMessage('country')}}</mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="13">
                        <mat-label>{{'shared.customerSiteCreate.site.state' | translate }}</mat-label>
                        <mat-select formControlName="state">
                            <mat-option *ngFor="let state of stateList" [value]="state">
                                {{state}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="isFormControlValid('state')">{{getErrorMessage('state')}}</mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="19" *ngIf="!siteSelectedFromGeoCoordinates">
                        <mat-label>{{'shared.customerSiteCreate.site.zip' | translate }}</mat-label>
                        <input matInput formControlName="zip" maxlength="50">
                        <mat-error *ngIf="isFormControlValid('zip')">{{getErrorMessage('zip')}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <mat-form-field fxFlex="29">
                        <mat-label>{{'shared.customerSiteCreate.site.contactName' | translate }}</mat-label>
                        <input matInput formControlName="siteAttention" maxlength="50">
                    </mat-form-field>
                    <mat-form-field fxFlex="18">
                        <mat-label>{{'shared.customerSiteCreate.site.phone' | translate }}</mat-label>
                        <input matInput formControlName="sitePhone" appPhoneMask maxlength="50">
                        <mat-error *ngIf="isFormControlValid('sitePhone')">{{getErrorMessage('sitePhone')}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="29">
                        <mat-label>{{'shared.customerSiteCreate.site.email' | translate }}</mat-label>
                        <input matInput formControlName="siteEmail" maxlength="50">
                        <mat-error *ngIf="isFormControlValid('siteEmail')">
                            {{'shared.customerSiteCreate.site.emailErrorMessage' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="18">
                        <mat-label>{{'shared.customerSiteCreate.site.fax' | translate }}</mat-label>
                        <input matInput formControlName="siteFax" [isFax]="true" maxlength="50" appPhoneMask>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <mat-form-field *ngIf="!forceBilling && isInternal" fxFlex="29">
                        <mat-label>{{'shared.customerSiteCreate.site.storeNumber' | translate }}</mat-label>
                        <input matInput formControlName="storeNumber" maxlength="50">
                    </mat-form-field>
                    <mat-form-field *ngIf="!isInternal" fxFlex="29">
                        <mat-label>{{'shared.customerSiteCreate.site.clientBuildId' | translate }}</mat-label>
                        <input matInput formControlName="clientBuildingId" maxlength="50">
                    </mat-form-field>
                    <mat-checkbox formControlName="isBillInfo">{{'shared.customerSiteCreate.site.billingCheckBox' | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end start" fxFlex="25">
                <button *ngIf="showCancelOption()" mat-stroked-button color="primary" (click)="cancelSiteCreation.emit()">
                    {{'shared.customerSiteCreate.site.existingSiteButton' | translate }}
                </button>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button mat-raised-button color="basic" (click)="handleStep.emit('back')">
                {{'shared.customerSiteCreate.site.backButton' | translate }}
            </button>
            <button *ngIf="isNewSiteBeingCreated" mat-raised-button color="warn" (click)="resetSiteCreationForm()">
                {{'shared.customerSiteCreate.site.resetButton' | translate }}
            </button>
            <button mat-raised-button color="primary" (click)="confirmSite()">
                {{'shared.customerSiteCreate.site.nextButton' | translate }}
            </button>
        </div>
    </div>
</form>
