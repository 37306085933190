import CheckboxData from './checkbox-data';

export default class CheckboxList {
    public name: string;
    public placeHolder: string;
    public items: CheckboxData[];
    public displayName: string;

    constructor(name: string, placeHolder: string, items: CheckboxData[] = [], displayName = null) {
        this.name = name;
        this.placeHolder = placeHolder;
        this.items = items;
        this.displayName = displayName;
    }
}
