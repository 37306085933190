import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
// eslint-disable-next-line
import { AddressErrorReadUtilityService } from '../../services/address-error-read-utility/address-error-read-utility.service';
// eslint-disable-next-line
import { FormControlUtilityService } from '../../../../../core/services/utility/form-control-utility-service/form-control-utility.service';
import { CountryName } from '../../../../../core/enums/country-name.enum';
import { AppConfigHttpService } from '../../../../../core/services/http/appconfig-http/appconfig-http.service';
import CustomerSiteDto from '../../../../../core/data-models/customer-site-dto';
import GoogleAddressModel from '../../../../../core/data-models/google-address-model';
import AddressStatusSummary from '../../../../../core/data-models/address-status-summary';
import { GenericUtilityService } from '../../../../../core/services/utility/generic-utility/generic-utility.service';
import { ValidatorFactory } from '../../../../../core/services/validator-factory/validator-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlags } from '../../../../../core/enums/feature-flags.enum';
import { FeatureFlagService } from '../../../../../core/services/events/feature-flags/feature-flag.service';


@Component({
    selector: 'customer-site-create',
    styleUrls: ['./customer-site-create.component.scss'],
    templateUrl: './customer-site-create.component.html'
})

export class CustomerSiteCreateComponent implements OnInit, OnChanges {

    @Input() forceBilling: boolean;
    @Input() inputAddress: CustomerSiteDto;
    @Input() inputCustomerAddress: CustomerSiteDto;
    @Input() initAddress: CustomerSiteDto;
    @Output() handleStep = new EventEmitter<string>();
    @Output() updateSiteAddress = new EventEmitter<CustomerSiteDto>();
    @Output() cancelSiteCreation = new EventEmitter();
    public addressCheckLoading = false;
    public stateList: string[];
    public countryList: string[];
    public newSiteFormGroup: UntypedFormGroup;
    public addressStatus: AddressStatusSummary;
    public geoCoordinateMode = false;
    public siteSelectedFromGeoCoordinates = false;
    public isInternal = false;
    private _emailValidator = this._validatorFactory.createEmailValidator();

    // eslint-disable-next-line
    constructor(
        private _translate: TranslateService,
        private _validatorFactory: ValidatorFactory,
        private _addressErrorService: AddressErrorReadUtilityService,
        private _appConfig: AppConfigHttpService,
        private _formService: FormControlUtilityService,
        private _formBuilder: UntypedFormBuilder,
        private _featureFlagService: FeatureFlagService) {

        this.initializeCustomerFormAndValidations();
    }

    get siteDetails(): UntypedFormGroup {
        return this.newSiteFormGroup.get('siteDetails') as UntypedFormGroup;
    }

    get isNewSiteBeingCreated(): boolean {
        return this.newSiteFormGroup.get('addressInformationSource').value === 'new';
    }

    public showCancelOption(): boolean {
        return !this.inputCustomerAddress;
    }

    public showBillingSiteAddressSourceOption(): boolean {
        return Boolean(this.inputAddress?.shipToId) && Boolean(this.inputCustomerAddress?.shipToId);
    }

    public showCustomerSiteAddressSourceOption(): boolean {
        if (this.inputAddress?.shipToId && this.inputCustomerAddress?.shipToId) {
            return this.inputAddress.shipToId !== this.inputCustomerAddress.shipToId;
        }
        return true;
    }

    public showAddressSourceOptions(): boolean {
        return Boolean(this.inputCustomerAddress);
    }

    public async ngOnInit(): Promise<void> {
        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];

        await this.loadDataForDropDownListsOnForms();
        this.subsriptionToFormToCheckAddressValidity();

        if (this.forceBilling) {
            this.siteDetails.get('sitePhone').setValidators([Validators.required]);
            this.siteDetails.get('siteEmail').setValidators([Validators.required, this._emailValidator.validator]);
            this.siteDetails.get('sitePhone').updateValueAndValidity();
            this.siteDetails.get('siteEmail').updateValueAndValidity();
            this.siteDetails.get('isBillInfo').setValue(true);
            this.siteDetails.get('isBillInfo').disable();
            this.siteDetails.get('isBillInfo').updateValueAndValidity();
        }
        else {
            this.addSubscriptionToSwitchBetweenBillingStatusOfSite();
        }

        this.addSubscriptionToAddressSource();
        this.loadInitAddress();
    }

    public ngOnChanges(changes: SimpleChanges): void {

        const sourceData = this.newSiteFormGroup.get('addressInformationSource').value;
        const isSourceDataFromCustomer = sourceData === 'customer';
        const isSourceDataFromBilling = sourceData === 'billing';
        if (isSourceDataFromCustomer && changes.inputCustomerAddress && changes.inputCustomerAddress.currentValue) {
            this.changeAddressSource(changes.inputCustomerAddress.currentValue, 'customer');
        }
        if (isSourceDataFromBilling && changes.inputAddress && changes.inputAddress.currentValue) {
            this.changeAddressSource(changes.inputAddress.currentValue, 'billing');
        }
    }

    public isFormControlValid(controlName: string): boolean {
        return this.siteDetails.get(controlName).invalid;
    }

    public getErrorMessage(controlName: string): string {
        return this._formService.getErrorMessage(this.siteDetails.get(controlName));
    }

    public confirmSite(): void {
        if (this.siteDetails.valid && this.addressStatus.verified) {
            this.updateSiteAddress.emit(this.siteDetails.getRawValue());
            setTimeout(() => this.handleStep.emit('next'));
        }
        else {
            this.addressStatus.visible = true;
            this.addressStatus.style = 'error-alert';
        }
    }

    public selectSiteWithGeoCode(googleAddress: GoogleAddressModel): void {

        this.geoCoordinateMode = false;

        if (!googleAddress) {
            return;
        }

        this.siteSelectedFromGeoCoordinates = true;
        this.siteDetails.patchValue({
            address1: googleAddress.line1,
            address2: googleAddress.line2,
            city: googleAddress.city,
            country: googleAddress.country,
            state: googleAddress.state,
            zip: googleAddress.zip,
            isBillInfo: false,
            geoCode: googleAddress.geocode
        });

        this.siteDetails.get('country').disable();
        this.siteDetails.get('state').disable();
        this.siteDetails.get('isBillInfo').disable();

    }

    public resetSiteCreationForm(): void {
        this.siteDetails.get('country').enable();
        this.siteDetails.get('state').enable();
        this.siteDetails.get('isBillInfo').enable();
        this.siteSelectedFromGeoCoordinates = false;
        this.siteDetails.reset({ shipToId: this.getIdPlaceholder() });
    }

    private initializeCustomerFormAndValidations(): void {
        const form = {
            siteDetails: this._formBuilder.group({
                name: ['', [Validators.required, Validators.maxLength(60)]],
                address1: ['', Validators.required],
                address2: [''],
                city: ['', Validators.required],
                country: ['', Validators.required],
                state: ['', Validators.required],
                zip: ['', Validators.required],
                siteAttention: ['', Validators.required],
                sitePhone: [''],
                siteEmail: ['', [ this._emailValidator.validator ] ],
                siteFax: [''],
                storeNumber: [''],
                isBillInfo: [false],
                geoCode: [''],
                shipToId: [this.getIdPlaceholder()],
                customerClass: [''],
                clientBuildingId: ['']
            }),
            addressInformationSource: ['new', [Validators.required]]
        };

        this.newSiteFormGroup = this._formBuilder.group(form);
        this.siteDetails.get('geoCode').disable();
    }

    private async loadDataForDropDownListsOnForms(): Promise<void> {
        this.stateList = await this._appConfig.getCAStates();
        this.countryList = Object.keys(CountryName).filter(_ => isNaN(Number(_)));

        this.siteDetails.get('country').valueChanges.subscribe(async country => {
            if (country === 'CA') {
                this.stateList = await this._appConfig.getCAStates();
            }
            else if (country === 'US') {
                this.stateList = await this._appConfig.getUSStates();
            }
        });

    }

    private addSubscriptionToAddressSource(): void {
        this.newSiteFormGroup.get('addressInformationSource').valueChanges.subscribe(source => {

            this.changeAddressSource(source === 'customer'? this.inputCustomerAddress: this.inputAddress, source);
        });
    }

    private changeAddressSource(sourceData: CustomerSiteDto, sourceName: string): void {
        if (sourceName === 'customer') {
            this.setAddressCheckToValid(this.siteDetails.valid);
            const oldSiteAttention = this.siteDetails.get('siteAttention').value;
            const oldEmail = this.siteDetails.get('siteEmail').value;
            this.siteDetails.get('siteAttention').enable();
            this.siteDetails.get('siteEmail').enable();
            sourceData.siteAttention = oldSiteAttention;
            sourceData.siteEmail = oldEmail;
            this.siteDetails.patchValue(sourceData);
        }
        else if (sourceName === 'billing') {
            this.setAddressCheckToValid(this.siteDetails.valid);
            this.siteDetails.setValue(sourceData);
            this.siteDetails.get('siteAttention').disable();
            this.siteDetails.get('siteEmail').disable();
            this.siteDetails.get('isBillInfo').disable();
            this.siteDetails.get('storeNumber').disable();
        }
        else {
            this.siteDetails.enable();
            this.siteDetails.get('shipToId').setValue(this.getIdPlaceholder());
        }

        if (sourceName !== 'new') {
            this.siteDetails.get('name').disable();
            this.siteDetails.get('address1').disable();
            this.siteDetails.get('address2').disable();
            this.siteDetails.get('city').disable();
            this.siteDetails.get('country').disable();
            this.siteDetails.get('state').disable();
            this.siteDetails.get('zip').disable();
            this.siteDetails.get('geoCode').disable();
            this.siteDetails.get('sitePhone').disable();
            this.siteDetails.get('siteFax').disable();
            this.siteDetails.get('customerClass').disable();
        }

        if (this.forceBilling) {
            this.siteDetails.get('isBillInfo').disable();
        }

        this.siteDetails.updateValueAndValidity();
    }

    private setAddressCheckToValid(inputValid: boolean): void {
        const message = this._translate.instant('shared.customerSiteCreate.alreadyVerifiedMessage');
        this.addressStatus = {
            messages: [message],
            style: 'success-alert',
            verified: inputValid,
            visible: true
        } as AddressStatusSummary;
    }

    private subsriptionToFormToCheckAddressValidity(): void {
        this.siteDetails.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe(async val => {
                const inputAddress = {
                    address1: val.address1,
                    address2: val.address2,
                    provice: val.state,
                    city: val.city,
                    postalcode: val.zip,
                    country: val.country,
                    phone: val.sitePhone,
                    corrections: ''
                };

                const validForm = this.siteDetails.valid;
                this.addressCheckLoading = true;

                const selectionMode = this.newSiteFormGroup.get('addressInformationSource').value;

                if (selectionMode === 'billing' || selectionMode === 'customer') {
                    this.setAddressCheckToValid(validForm);
                    this.updateSiteAddress.emit(validForm? this.siteDetails.getRawValue(): null);
                    this.addressCheckLoading = false;
                    return;
                }

                this.addressStatus = await this._addressErrorService.getAddressStatus(
                    inputAddress,
                    validForm,
                    this.siteSelectedFromGeoCoordinates,
                    val.isBillInfo || this.forceBilling
                );

                if (this.addressStatus.verified && validForm) {
                    this.updateSiteAddress.emit(this.siteDetails.getRawValue());
                }
                else {
                    this.updateSiteAddress.emit(null);
                }

                this.addressCheckLoading = false;

            });

    }

    private addSubscriptionToSwitchBetweenBillingStatusOfSite(): void {
        this.siteDetails.get('isBillInfo').valueChanges.subscribe(checked => {
            if (checked) {
                this.siteDetails.get('sitePhone').setValidators([Validators.required]);
                this.siteDetails.get('siteEmail').setValidators([Validators.required, this._emailValidator.validator]);
            }
            else {
                this.siteDetails.get('sitePhone').setValidators(null);
                this.siteDetails.get('siteEmail').setValidators([this._emailValidator.validator]);
            }
            this.siteDetails.get('sitePhone').updateValueAndValidity();
            this.siteDetails.get('siteEmail').updateValueAndValidity();
        });
    }

    private loadInitAddress(): void {
        if (!this.initAddress) {
            return;
        }

        this.initAddress.shipToId = null;
        this.siteDetails.patchValue(GenericUtilityService.removeFalsyValues(this.initAddress));
    }

    private getIdPlaceholder(): string {
        const multiplier = Math.pow(10, 10);

        return `new-${Math.floor(Math.random() * multiplier)}`.padEnd(14, '0');
    }
}
