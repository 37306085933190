<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" class="on-call-main-content" fxFill>
    <div fxLayout="column" fxLayoutAlign="center start">
        <mat-form-field>
            <mat-label>{{ 'onCall.branch' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedBranch" [disabled]="!isAdmin" (ngModelChange)="loadBranchData()">
                <mat-option *ngFor="let branch of branches" [value]="branch.name">{{branch.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
        <full-calendar class="fullcalendar" [options]="options" fxFlex="50"></full-calendar>
    </div>
</div>
