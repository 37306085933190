export enum ProjectWorkflow {
    Unassign = '0',
    ReturnToSite = '1',
    ApproveWithKpiChange = '2',
    UndoApprove = '3',
    Reject = '4',
    ReadyForProcessingWithoutBudget = '5',
    WaitingForPo = '6',
    CancelReturnToSiteVisit = '7',
    UndoReturnToSite = '8',
    ReadyForReview = '9',
    SendToServiceAdminOrServiceOps = '10',
    Approve = '11',
    Process = '12',
    WaitingForBudget = '13',
    ReadyForProcessing = '14',
    WaitingForFollowUpQuote = '15',
    Assigned = '16',
    SignedIn = '17',
    SubcontractReadyForReview = '18'
}
