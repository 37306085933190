import { TypeRecord } from '../../generic/type-record';
import { LineItem } from '../../generic/line-item';
import { TimeInfo } from '../../generic/time-info';
import ErpTaskCode from '../../erp/erp-task-code';

import { BranchServiceRate } from './branch-service-rate';
import { MaterialRate } from './material-rate';
import { DefaultServiceRates } from './default-service-rates';
import { CheckTypeRecord } from '../../budget-estimations/custom-type-records/check-type-record';

export class ServiceRatesRevision {
    public groupId: string;
    public version!: string;
    public revision!: number;
    public branchRates: BranchServiceRate[] = [];
    public materialRates: MaterialRate[] = [];
    public tasks: ErpTaskCode[] = [];
    public miscellaneousItemTypes: TypeRecord[] = [];
    public projectTypes: TypeRecord[] = [];
    public customSummaryLineItems: LineItem<TypeRecord>[] = [];
    public qualityControlFeesPercentageRates: number[] = [];
    public leadQuestions: CheckTypeRecord[] = [];
    public defaultRates = new DefaultServiceRates();
    public timeInfo = new TimeInfo();
}
