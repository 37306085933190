import { Component, OnInit, Input, ViewChild, EventEmitter } from '@angular/core';
import { debounceTime, startWith, distinctUntilChanged } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';

import UserProfileSummaryDto from '../../../core/data-models/dto-models/user-profile-summary-dto';
import UserProfileEditorOption from '../../../core/data-models/dialog-options/user-profile-editor-option';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { UserPermissionService } from '../../../core/services/user-permission/user-permission.service';
import { WritePermission } from '../../../core/enums/write-permission.enum';
import { NamePromptDialogComponent } from '../../../shared/components/dialogs/name-prompt-dialog/name-prompt-dialog.component';

import { UserProfileEditorDialogComponent } from './user-profile-editor-dialog/user-profile-editor-dialog.component';

@Component({
    selector: 'user-management',
    styleUrls: ['./user-management.component.scss'],
    templateUrl: './user-management.component.html'
})
export class UserManagementComponent implements OnInit {
    @Input() public isServiceProvider = false;
    public users: UserProfileSummaryDto[] = [];
    public userSearch = new EventEmitter<string>();
    public statusOptions: { type: string; name: string }[] = [];
    public status = 'all';
    public canEditUsers = false;
    public isSaving = false;
    public isLoading = true;
    private _searchText = '';
    @ViewChild(DataBindingDirective) private _dataBinding: DataBindingDirective;

    constructor(public translate: TranslateService,
                private _userHttpService: UserHttpService,
                private _permissionService: UserPermissionService,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialog) { }

    get visibleUsers(): UserProfileSummaryDto[] {
        if (this.status === 'all') {
            return this.users;
        }

        const showActive = this.status === 'active';

        return this.users.filter(_ => _.isDeactivated !== showActive);
    }

    public async ngOnInit(): Promise<void> {
        this.statusOptions = [
            { type: 'all', name: this.translate.instant('admin.users.statusOptions.all') },
            { type: 'active', name: this.translate.instant('admin.users.statusOptions.active') },
            { type: 'deactivated', name: this.translate.instant('admin.users.statusOptions.deactivated') }
        ];

        this.userSearch.pipe(
            startWith(this._searchText),
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(this.loadUsers.bind(this));

        this.canEditUsers = await this._permissionService.hasWritePermission(WritePermission.EditCrew);
    }

    public async onUserCreate(): Promise<void> {
        const dialog = this._dialog.open(NamePromptDialogComponent, {
            data: {
                title: this.translate.instant('admin.users.newUserTitle'),
                errorMessage: this.translate.instant('admin.users.newUserError'),
                callback: async(name: string): Promise<boolean> => {
                    const { code, data } = await this._userHttpService.getUserProfileByUsername(name.trim(), false);

                    return code === '200' && !data;
                }
            },
            width: '400px',
            height: '270px'
        });

        const username = await dialog.afterClosed().toPromise<string>();

        if (username?.trim()) {
            this.openUserProfile(username, false);
        }
    }

    public async toggleUserProfileStatus(user: UserProfileSummaryDto): Promise<void> {
        this.isSaving = true;
        const { code, description } = await this._userHttpService.toggleUserProfileStatus(user);

        if (code === '204') {
            user.isDeactivated = !user.isDeactivated;
        }

        this._snackBar.open(description, this.translate.instant('shared.close'));
        this.isSaving = false;
    }

    public resetPage(): void {
        if (this._dataBinding) {
            this._dataBinding.skip = 0;
        }
    }

    public async openUserProfile(username: string, isExisting: boolean): Promise<void> {
        const dialog = this._dialog.open(UserProfileEditorDialogComponent, {
            data: new UserProfileEditorOption(username.trim(), isExisting, this.canEditUsers, this.isServiceProvider),
            width: '65vw',
            height: '75vh'
        });

        await dialog.afterClosed().toPromise();
        await this.loadUsers(this._searchText, false);
    }

    private async loadUsers(searchText: string, resetPage = true): Promise<void> {
        this.isLoading = true;
        this._searchText = searchText;
        this.users = await this._userHttpService.getUserProfileSummaries(searchText);

        if (resetPage) {
            this.resetPage();
        }

        this.isLoading = false;
    }
}
