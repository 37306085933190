import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';

import TemplateFieldMeta from '../../../core/data-models/template-field-meta';
import ProjectSafetyTemplateMetaDto from '../../../core/data-models/dto-models/project-safety-template-meta-dto';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import ActionMenuItemOption from '../../../core/data-models/action-menu-item-option';

@Component({
    selector: 'project-safety',
    templateUrl: './project-safety.component.html',
    styleUrls: ['./project-safety.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProjectSafetyComponent implements OnInit {
    @Input() public activeProjectId = '';
    @Input() public jobId = -1;
    public loadingErrorMessage: string;
    private _templateDictionary: ProjectSafetyTemplateMetaDto;
    public jobs: ActionMenuItemOption[] = [];
    public loading = false;

    constructor(private _projectHttp: ProjectHttpService,
        private _state: StateService,
        public translate: TranslateService
    ) {
        this.loadingErrorMessage = this.translate.instant('projects.safety.noSafetyInformationFound');
    }

    get projectSafetyTemplate(): TemplateFieldMeta {
        return this._templateDictionary?.projectSafetyTemplateMeta;
    }

    get totalJobs(): number {
        return this._templateDictionary?.totalJobsInProject ?? 0;
    }

    get isMultiDay(): boolean {
        return this._templateDictionary?.isMultiDay;
    }

    public async ngOnInit(): Promise<void> {

        await this.loadSafetyTemplates(this.jobId);

        if (!this._templateDictionary) {
            this._state.go('projects.notFound');
            return;
        }

        const visitNoLabel = this.translate.instant('projects.visitNo');
        const jobs = new Array(this._templateDictionary.totalJobsInProject).fill(0).map((_, i) => `${visitNoLabel}.${i + 1}`);
        this.jobs = jobs.map(_ => new ActionMenuItemOption(_));
    }

    public async onJobSelect(selectedJob: string): Promise<void> {
        const index = this.jobs.findIndex(_ => _.text === selectedJob);
        await this.loadSafetyTemplates(index);
    }

    private async loadSafetyTemplates(jobId: number): Promise<void> {
        this.loading = true;
        this._templateDictionary = await this._projectHttp.getSafetySummaryTemplateField(this.activeProjectId, jobId);

        if (this._templateDictionary && !this._templateDictionary.projectSafetyTemplateMeta) {
            this.loadingErrorMessage = this.translate.instant('projects.safety.techNotFilledSafetySection');
        }
        this.loading = false;
    }
}
