import { Component, OnInit, Inject, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import ImageItem from '../../../../core/data-models/job-template-items/image-item';
import ImageCardConfig from '../../../../core/data-models/card-items/image-card-config';
import ImageCardEvent from '../../../../core/data-models/card-items/image-card-event';
import { ImageHttpService } from '../../../../core/services/http/image-http/image-http.service';
import { FullViewPhotoDialogComponent } from '../full-view-photo-dialog/full-view-photo-dialog.component';

@Component({
    selector: 'app-deleted-photo-dialog',
    styleUrls: ['./deleted-photo-dialog.component.scss'],
    templateUrl: './deleted-photo-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DeletedPhotoDialogComponent implements OnInit {
    @Output() isChange = new EventEmitter<boolean>();
    public imageCardConfig: ImageCardConfig;
    public requireRefresh = false;

    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
        private _dialog: MatDialogRef<DeletedPhotoDialogComponent>,
        private matdialog:MatLegacyDialog,
        private _imageHttpService: ImageHttpService,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: {images: ImageItem[], projectId: string, dialogTitle: string, isPhotoMode: false}) { }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        this.imageCardConfig = new ImageCardConfig();
        this.imageCardConfig.imageSize = '200px';
        this.imageCardConfig.showDescription = true;
        const restoreLabel = this.translate.instant('shared.restore');
        this.imageCardConfig.actionButtons = [{ text: 'Restore', isWarn: false, icon: '', displayText: restoreLabel }];
    }

    public async doPhotoCardAction(event: ImageCardEvent): Promise<void> {
        if (event.action === 'Restore') {
            this.isChange.emit(true);
            const isDeleted = await this._imageHttpService.restoreImage(this.data.projectId, event.source, this.data.isPhotoMode);
            if (isDeleted) {
                this.data.images = this.data.images.filter(_ => _.index !== event.source.index);
                this.requireRefresh = true;
            }
            else {
                this.isChange.emit(false);
                const restoreFailureMessage = this.translate.instant('shared.deletedPhotoDialog.restoreFailure');
                const closeLabel = this.translate.instant('shared.close');
                this._snackBar.open(restoreFailureMessage, closeLabel);
            }

            if (this.data.images.length === 0) {
                this.onClose();
            }
        }
        else {
            this.openPhotoDialog(event.source);
        }
    }

    private openPhotoDialog(photo: ImageItem): void {
        this.matdialog.open(FullViewPhotoDialogComponent, {
            data: photo,
            height: '80vh'
        });
    }

    public onClose(): void {
        this._dialog.close(this.requireRefresh);
    }
}
