import { Component, Input, OnChanges, Output, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import ImageItem from '../../../../core/data-models/job-template-items/image-item';
import LanguageSet from '../../../../core/data-models/language-set';
import TranslationPair from '../../../../core/data-models/generic/translation-pair';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';
import { TemplateHttpService } from '../../../../core/services/http/template-http/template-http.service';
import { ImageHttpService } from '../../../../core/services/http/image-http/image-http.service';
import { PhotoEditorDialogComponent } from '../../dialogs/photo-editor-dialog/photo-editor-dialog.component';
import { GenericUtilityService } from '../../../../core/services/utility/generic-utility/generic-utility.service';
import LanguageUtility from '../../../../core/services/utility/language-utility/language-utility.service';
import { LanguageKey } from '../../../../core/enums/language-key.enum';

@Component({
    selector: 'app-edit-photo',
    styleUrls: ['./edit-photo.component.scss'],
    templateUrl: './edit-photo.component.html'
})
export class EditPhotoComponent implements OnChanges, OnInit {
    @Input() public image: ImageItem;
    @Input() public selectedIndex: number;
    @Input() public endIndex: number;
    @Input() public hasPageQuestions: boolean;
    @Input() public imageTitles: LanguageSet[][] = [];
    @Output() public navigationClick = new EventEmitter<number>();
    @Output() public actionClick = new EventEmitter<string>();
    @Output() public imageFileUpdate = new EventEmitter<Blob>();
    public backgroundImage: SafeStyle;
    public combinedImageCategories: TranslationPair[] = [];
    private pagesAndQuestions: Map<string, string[]>;
    private _languageSetsLookup = new Map<string, LanguageSet[]>();

    get pages(): string[] {
        return Array.from(this.pagesAndQuestions?.keys()??[]);
    }

    get questions(): string[] {
        return this.pagesAndQuestions?.get(this.image.tagSectionPage)?? new Array<string>();
    }

    constructor(private sanitizer: DomSanitizer,
                private _userHttpService: UserHttpService,
                private _templateHttpService: TemplateHttpService,
                private _imageHttpService: ImageHttpService,
                private _dialog: MatDialog) { }

    public async ngOnInit(): Promise<void> {
        this.pagesAndQuestions = await this._templateHttpService.loadPageAndQuestionsFor310();
        await this.loadCombinedCategories();
    }

    public ngOnChanges(): void {
        this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(`url('${this.image.baseImageUri}')`);
    }

    public async openPhotoEditor(): Promise<void> {
        const imageUrl = GenericUtilityService.getImageUrl(this.image, false);
        const dialog = this._dialog.open(PhotoEditorDialogComponent, {
            data: imageUrl
        });

        const newImage = await dialog.afterClosed().toPromise();
        if (newImage) {
            this.imageFileUpdate.emit(newImage);
        }
    }

    public onImageCategoryChange(category: string): void {
        this.image.languageSets = this._languageSetsLookup.get(category) ?? [];
    }

    private async loadCombinedCategories(): Promise<void> {
        const { language } = await this._userHttpService.getUserInfo();
        const categories = await this._imageHttpService.getImageCategories();

        for (const languageSets of [...categories.map(_ => _.names), ...this.imageTitles]) {
            const original = LanguageUtility.getLanguageSetContent(LanguageKey.EN, languageSets);
            const translated = LanguageUtility.getLanguageSetContent(language, languageSets);

            if (!this._languageSetsLookup.has(original)) {
                this.combinedImageCategories.push({ original, translated });
                this._languageSetsLookup.set(original, languageSets);
            }
        }

        this.combinedImageCategories.sort((a, b) => a.translated.localeCompare(b.translated));
    }
}
