import Branch from '../../data-models/branch';
import { AppConfigHttpService } from '../../services/http/appconfig-http/appconfig-http.service';
import { BranchHttpService } from '../../services/http/branch-http/branch-http.service';
import ProjectIdUtility from '../../services/utility/project-id-utility/project-id-utility.service';

export const globalResolver = [
    {
        token: 'jobTypes',
        deps: [AppConfigHttpService],
        resolveFn: async(_: AppConfigHttpService): Promise<string[]> => {
            const codes = await _.getSalesCodeConfig();

            return codes.map(code => code.code).filter(code => !ProjectIdUtility.isEmergency(code));
        }
    },
    {
        token: 'branches',
        deps: [BranchHttpService],
        resolveFn: async(_: BranchHttpService): Promise<Branch[]> => await _.getAllBranches()
    }
];
