<div class="branch-service-rates-changes-display-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2.5vh">
    <div *ngIf="!changes.length" fxLayout="row" fxLayoutAlign="center center" fxFill>
        <span>{{ 'flynnBudgetTool.shared.branchServiceRatesChangesDisplay.noChangeFoundForBranch' | translate }}</span>
    </div>

    <div class="change-record" *ngFor="let change of changes" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3vh">
        <div fxFlex="10" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5vh">
            <div class="version">{{ 'flynnBudgetTool.shared.branchServiceRatesChangesDisplay.version' | translate }} {{ change.ratesQuery.version }}</div>
            <div class="revision">{{ 'flynnBudgetTool.shared.branchServiceRatesChangesDisplay.revision' | translate }} {{ change.ratesQuery.revision }}</div>
        </div>

        <div class="data-collection" fxLayout="column" fxLayoutAlign="start center" fxFlex="90">
            <div class="header" fxLayout="row" fxLayoutAlign="center center">
                <div [fxFlex]="getColumnWidth(change)">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.laborHourlyRate' | translate }}</div>
                <div [fxFlex]="getColumnWidth(change)">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.insurancePercentageRate' | translate }}</div>
                <div [fxFlex]="getColumnWidth(change)">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.indirectsPercentageRate' | translate }}</div>

                <div *ngFor="let rate of change.rates.grossProfitPercentageRates" [fxFlex]="getColumnWidth(change)">
                    {{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.grossProfitPercentageRate' | translate: { projectType: getProjectType(rate.typeIdentifier, change.projectTypes) } }}
                </div>

                <div [fxFlex]="getColumnWidth(change)">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.subcontractGrossProfitPercentageRate' | translate }}</div>
            </div>

            <div class="row" fxLayout="row" fxLayoutAlign="center center">
                <div [fxFlex]="getColumnWidth(change)">${{ change.rates.laborHourlyRate }}</div>
                <div [fxFlex]="getColumnWidth(change)">{{ change.rates.insurancePercentageRate | percent: percentPipeConfig }}</div>
                <div [fxFlex]="getColumnWidth(change)">{{ change.rates.indirectsPercentageRate | percent: percentPipeConfig }}</div>
                <div *ngFor="let rate of change.rates.grossProfitPercentageRates" [fxFlex]="getColumnWidth(change)">{{ rate.rate | percent: percentPipeConfig }}</div>
                <div [fxFlex]="getColumnWidth(change)">{{ change.rates.subcontractGrossProfitPercentageRate | percent: percentPipeConfig }}</div>
            </div>
        </div>
    </div>
</div>
