<dialog-action-card class="project-review-dialog-container"
    [icon]="'star'"
    [title]="translate.instant('projects.projectReview.title')"
    [isLoaded]="isLoaded"
    (close)="onClose(false)">

    <div class="project-review-panel" *ngIf="isLoaded && useInternalFeatures; else fieldZoomReview" fxLayout="column" fxLayoutGap="15px">
        <div class="project-review-hours" *ngIf="isInternalWorkCategory && branchReviewForm" fxLayout="column">
            <form [formGroup]="branchReviewForm" fxLayout="row" fxLayoutAlign="space-between">
                <mat-form-field fxFlex="39">
                    <mat-label>{{ 'projects.projectReview.labelText' | translate }}</mat-label>
                    <input type="number" matInput formControlName="billableHours" min="0" />
                    <mat-error *ngIf="branchReviewForm.get('billableHours').invalid">{{ 'projects.projectReview.errorText' | translate }}</mat-error>
                </mat-form-field>
                
                <mat-form-field fxFlex="25" *ngIf="showMaterialsCost">
                    <mat-label>{{ 'projects.projectReview.projectMaterialsCost' | translate }}</mat-label>
                    <input type="number" matInput formControlName="materialsCost" />
                    <mat-error *ngIf="branchReviewForm.get('materialsCost').invalid">{{ 'projects.projectReview.errorText' | translate }}</mat-error>
                </mat-form-field>
                
                <mat-form-field fxFlex="32" *ngIf="showMaterialsCost">
                    <mat-label>{{ 'projects.projectReview.recommendationMaterialsCost' | translate }}</mat-label>
                    <input type="number" matInput formControlName="recommendationMaterialsCost" />
                    <mat-error *ngIf="branchReviewForm.get('recommendationMaterialsCost').invalid">
                        {{ 'projects.projectReview.errorText' | translate }}
                    </mat-error>
                </mat-form-field>
            </form>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div *ngIf="totalLabor!==-1">
                    <span class="project-review-total-hours-label">{{ 'projects.projectReview.totalHours' | translate }}</span> <span class="project-review-total-hours"> {{totalLabor}}</span>
                </div>
                <div *ngIf="totalLabor===-1">
                    <span class="project-review-labor-loading-error-message">{{ 'projects.projectReview.errorText2' | translate }}</span>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between">
            <div *ngFor="let content of reviewContent; index as i" [ngStyle]="{ width: processorReview ? '48%' : '100%' }" fxLayout="column">

                <mat-form-field class="project-review-comment">
                    <mat-label>{{ getCommentTitle(!i) }}</mat-label>
                    <textarea class="review-textarea" matInput [(ngModel)]="content.comment" [disabled]="isDisabled(!i)"></textarea>
                </mat-form-field>
                <div *ngIf="isInternalWorkCategory && (!i || isKpiReviewRequired)" fxLayout="row wrap" [style.width]="processorReview ? '100%' : '50%'">
                    <span class="project-review-checkbox-title" fxFlex="100%">{{ getQualityReviewTitle(!i) }}</span>

                    <mat-checkbox class="project-review-checkbox" fxFlex="50%" *ngFor="let item of getChecklist(!i)"
                        matTooltipPosition="above" [matTooltip]="item.disableReason" [(ngModel)]="item.checked"
                        [disabled]="isDisabled(!i) || item.isDisabled">

                        {{ item.displayText ? item.displayText : item.name }}
                    </mat-checkbox>
                </div>
            </div>
        </div>

        <div *ngIf="isInternalWorkCategory && processorReview && isKpiReviewRequired" fxLayout="row" fxLayoutAlign="space-between">
            <span fxFlex="48%"></span>
            <div class="project-review-stars" fxFlex="48%">
                <span>{{ 'projects.projectReview.qualityStars' | translate }}</span>
                <rating-stars [stars]="stars"></rating-stars>
            </div>
        </div>
    </div>

    <ng-template #fieldZoomReview>
        <div class="minimal-review" fxLayout="row" fxLayoutAlign="space-between" *ngIf="isLoaded">
            <div class="basic-review" fxLayout="column">
                <mat-form-field class="project-review-comment">
                    <textarea class="review-textarea" matInput [(ngModel)]="branchReview.comment" [disabled]="isDisabled(true)">
                </textarea>
                </mat-form-field>
            </div>
        </div>
    </ng-template>

    <div class="project-review-dialog-actions" fxLayout="row" fxLayoutAlign="end center" fxFlex="100%">
        <button *ngIf="canSave" mat-raised-button color="primary" (click)="onClose()">
            {{ 'projects.projectReview.save' | translate }}
        </button>
    </div>

</dialog-action-card>
