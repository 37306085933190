import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import NavigationTab from '../../../core/data-models/navigation-tab';

@Component({
    selector: 'app-data-report-root-view',
    styleUrls: ['./data-report-root-view.component.scss'],
    templateUrl: './data-report-root-view.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DataReportRootViewComponent {
    public navigationTabs: NavigationTab[] = [];

    constructor(private _translateService: TranslateService) {
        this.navigationTabs = [
            {
                name: 'Kpi Report',
                state: '.kpi-report',
                displayName: this._translateService.instant('dataReport.tabs.kpi')
            },
            {
                name: 'Quotes',
                state: '.quotes',
                displayName: this._translateService.instant('dataReport.tabs.quotes')
            },
            {
                name: 'Rejected By Service Ops Report',
                state: '.serviceops-rejection',
                displayName: this._translateService.instant('dataReport.tabs.rejectedByServiceOps')
            },
            {
                name: 'Fall Hazard Report',
                state: '.fall-hazards',
                displayName: this._translateService.instant('dataReport.tabs.fallHazard')
            },
            {
                name: 'Covid Screening Report',
                state: '.covid-screening',
                displayName: this._translateService.instant('dataReport.tabs.covidScreening')
            },
            {
                name: 'Deleted Job Report',
                state: '.deleted-jobs',
                displayName: this._translateService.instant('dataReport.tabs.deletedJob')
            },
            {
                name: 'Reports By Customer',
                state: '.customer-quotes-report',
                displayName: this._translateService.instant('dataReport.tabs.customer')
            },
            {
                name: 'Temp Project Aging Report',
                state: '.temp-projects',
                displayName: this._translateService.instant('dataReport.tabs.tempProjectAging')
            },
            {
                name: 'Stock Log Report',
                state: '.stockLogs',
                displayName: this._translateService.instant('dataReport.tabs.stockLogs')
            }
        ];
    }
}
