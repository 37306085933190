import CustomerInfo from '../project-general-info/customer-info';
import QuoteFollowUpLogEntry from '../quote-follow-up-log-entry';
import ContactInfo from '../contact-info';

import QuoteBasicInfo from './quote-basic-info';
import EstimationInfo from './estimation-info';
import { WorkCategory } from '../work-category';

export default class QuoteGeneralInfo {
    public basicInfo = new QuoteBasicInfo();
    public customer = new CustomerInfo();
    public reportingContact = new ContactInfo();
    public estimation = new EstimationInfo();
    public followUpLogs: QuoteFollowUpLogEntry[] = [];
    public description = '';
    public spreadsheetData = '';
    public workCategory: WorkCategory;
}
