import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'kpiRole'
})
export class KpiRolePipe implements PipeTransform {

    // eslint-disable-next-line class-methods-use-this
    transform(value: number): string {
        switch (value) {
            case 0 : return 'Dispatcher';
            case 1 : return 'Service Ops';
            default: return '';
        }
    }

}
