import { Component, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { CustomerDisplayFormattingService } from '../services/customer-display-formatting/customer-display-formatting.service';
import CustomerSiteDto from '../../../../core/data-models/customer-site-dto';
import CustomerSelectionDto from '../../../../core/data-models/customer-selection-dto';
import CustomerDto from '../../../../core/data-models/customer-dto';
import CustomerDisplaySummary from '../../../../core/data-models/customer-selection/customer-display-summary';
import SiteDisplaySummary from '../../../../core/data-models/customer-selection/site-display-summary';
import InformationGridConfig from '../../../../core/data-models/information-grid/information-grid-config';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'customer-create-management',
    styleUrls: ['./customer-create-management.component.scss'],
    templateUrl: './customer-create-management.component.html',
    encapsulation: ViewEncapsulation.None
})

export class CustomerCreateManagementComponent {
    public newCustomerFormGroup: UntypedFormGroup;
    public newBillinsgSiteFormGroup: UntypedFormGroup;
    public newJobSiteFormGroup: UntypedFormGroup;
    public customerSiteInfo: CustomerSiteDto;
    public customerSummaryGridInfo: InformationGridConfig<CustomerDisplaySummary>;
    public jobSiteSummaryGridInfo: InformationGridConfig<SiteDisplaySummary>;
    public billingSiteSummaryGridInfo: InformationGridConfig<SiteDisplaySummary>;
    public readyToVerifyCustomerInformation = false;
    @Output() newCustomerEvent = new EventEmitter<CustomerSelectionDto>();
    @ViewChild('createCustomerStepper') stepper;

    constructor(
        public translate: TranslateService,
        private _formBuilder: UntypedFormBuilder,
        private _displayFormattingService : CustomerDisplayFormattingService
    ) {
        this.newBillinsgSiteFormGroup = this._formBuilder.group({
            billingSite: ['', [Validators.required]]
        });

        this.newJobSiteFormGroup = this._formBuilder.group({
            jobSite: ['', [Validators.required]]
        });

        this.newCustomerFormGroup = this._formBuilder.group({
            customer: ['', [Validators.required]]
        });

    }

    get customer(): CustomerDto {
        return this.newCustomerFormGroup.get('customer').value;
    }

    get billingSite(): CustomerSiteDto {
        return this.newBillinsgSiteFormGroup.get('billingSite').value;
    }

    get jobSite(): CustomerSiteDto {
        return this.newJobSiteFormGroup.get('jobSite').value;
    }

    get customerInfoCreated(): boolean {
        return Boolean(this.customer) && Boolean(this.billingSite) && Boolean(this.jobSite);
    }

    public confirmCustomerDecision(confirmed: boolean): void {
        if (confirmed) {

            this.newCustomerEvent.emit({
                customer: this.customer,
                site: this.jobSite,
                billingSite: this.billingSite
            } as CustomerSelectionDto);

        }
    }

    public mainCustomerInfoUpdated(createdCustomer: CustomerDto): void {

        this.newCustomerFormGroup.get('customer').setValue(createdCustomer);
        if (createdCustomer) {
            this.customerSiteInfo = this.getSiteFromCustomerInformation();
        }
    }

    public newBillingSiteUpdated(siteInfo: CustomerSiteDto): void {

        this.newBillinsgSiteFormGroup.get('billingSite').setValue(siteInfo);
    }

    public newJobSiteUpdated(siteInfo: CustomerSiteDto): void {
        this.newJobSiteFormGroup.get('jobSite').setValue(siteInfo);
    }

    public checkIfConfirmationPageSelected(stepperChange: StepperSelectionEvent): void {

        if (stepperChange.selectedIndex !== 3) {
            this.readyToVerifyCustomerInformation = false;
            return;
        }

        const numberOfNewSites = this.jobSite.shipToId === this.billingSite.shipToId? '1': '2';
        this.customerSummaryGridInfo =
            this._displayFormattingService.createCustomerInfoCardConfig(this.customer, numberOfNewSites);

        const billingLabel = this.translate.instant('shared.customerCreateManagement.stepperLabels.billingLabel');
        const jobLabel = this.translate.instant('shared.customerCreateManagement.stepperLabels.jobLabel');

        this.jobSiteSummaryGridInfo = this._displayFormattingService.createSiteInfoCardConfig(this.jobSite, jobLabel);
        this.billingSiteSummaryGridInfo = this._displayFormattingService.createSiteInfoCardConfig(this.billingSite, billingLabel);
        this.readyToVerifyCustomerInformation = true;
    }

    public nextStep(step: string): void {
        // return to opening page if user selected back on first page
        if (step === 'back' && this.stepper.selectedIndex === 0) {
            this.newCustomerEvent.emit(null);
        }
        else if (step === 'next') {
            this.stepper.next();
        }
        else {
            this.stepper.previous();
        }
    }

    private getSiteFromCustomerInformation(): CustomerSiteDto {

        const custInfo = this.newCustomerFormGroup.get('customer').value;
        return {
            name: custInfo.name,
            address1: custInfo.address1,
            address2: custInfo.address2,
            city: custInfo.city,
            state: custInfo.state,
            country: custInfo.country,
            zip: custInfo.zip,
            sitePhone: custInfo.phone,
            siteFax: custInfo.faxNumber,
            customerClass: custInfo.customerClass,
            geoCode: '',
            storeNumber: '',
            siteAttention: '',
            siteEmail: '',
            isBillInfo: true,
            shipToId: custInfo.customerId
        } as CustomerSiteDto;

    }

}
