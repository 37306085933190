import { Component, Input } from '@angular/core';
import CustomIcon from '../../../core/data-models/custom-icon';

@Component({
    selector: 'app-custom-icon',
    styleUrls: ['./custom-icon.component.scss'],
    templateUrl: './custom-icon.component.html'
})
export class CustomIconComponent {
    @Input() public icon: CustomIcon;
}
