import { Component, EventEmitter, Input, Output } from '@angular/core';
import UsProjectInfoStepConfig from '../../../../core/data-models/dispatch/us-project-info-step.config';
import ProjectUSQuestionsValidCheck from '../../../../core/data-models/project-us-questions-valid-check';
import ProjectUSTaxQuestionsValidCheck from '../../../../core/data-models/project-us-tax-questions-valid-check';
import BudgetStepConfig from '../../../../core/data-models/dispatch/budget-step-config';

@Component({
    selector: 'app-us-project-questions-step',
    styleUrls: ['./us-project-questions-step.component.scss'],
    templateUrl: './us-project-questions-step.component.html'
})
export class UsProjectQuestionsStepComponent {
    @Input() public budgetConfig: BudgetStepConfig;
    @Input() public config: UsProjectInfoStepConfig;
    @Output() public stepClicked = new EventEmitter<string>();
    private isValidTaxQuestions = false;
    private isValidEstimationQuestions = true;

    public onUsProjectQuestionsResult(input: ProjectUSQuestionsValidCheck): void {
        this.config.data.uSEstimateForm = input.projectUSQuestions;
        this.isValidEstimationQuestions = input.valid;
    }

    public onUsProjectTaxResult(input: ProjectUSTaxQuestionsValidCheck): void {
        this.config.data.uSProjectTaxQuestions = input.projectUsTaxQuestions;
        this.isValidTaxQuestions = input.valid;
    }

    public validateAndMoveNext(): void {
        this.config.completed = this.isValidTaxQuestions && this.isValidEstimationQuestions;
        setTimeout(() => this.stepClicked.emit('next'));
    }

}
