export default class CustomEditorOption {
    public content = '';
    public font = 'Arial';
    public fontSize = 12;
    public focusOnLoad = false;

    constructor(content = '', font = 'Arial', fontSize = 12, focusOnLoad = false) {
        this.content = content;
        this.font = font;
        this.fontSize = fontSize;
        this.focusOnLoad = focusOnLoad;
    }
}
