import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { KpiHttpService } from '../../../core/services/http/kpi-http/kpi-http.service';
import Branch from '../../../core/data-models/branch';
import KpiResult from '../../../core/data-models/kpi-result';
import ReportQuery from '../../../core/data-models/data-report/report-query';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { process } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-kpi-report',
    styleUrls: ['./kpi-report.component.scss'],
    templateUrl: './kpi-report.component.html'
})
export class KpiReportComponent implements OnInit {

    @Input() public jobTypes: string[];
    public roles = [];
    @Input() public branches: Branch[];
    @ViewChild(GridComponent) grid: GridComponent;
    public gridViewData: KpiResult[] = [];
    public loading: boolean;
    public gridPageSize = 15;
    public query: ReportQuery;

    public constructor(private kpiHttpService: KpiHttpService,
        private newRelicUtility: NewRelicUtilityService,
        private snackbar: MatSnackBar,
        private userHttpService: UserHttpService,
        private _translate: TranslateService) {
        this.query = new ReportQuery('', '', '', null, null, '');
        this.allData = this.allData.bind(this);
    }

    public async ngOnInit(): Promise<void> {
        this.query.branch = (await this.userHttpService.getUserInfo()).branch;
        this.loadRoles();
    }

    public async loadKpiSummary(): Promise<void> {
        if (Object.keys(this.query).every(_ => !this.query[_])) {
            const message = this._translate.instant('dataReport.kpiReport.missingFilterMessage');
            const action = this._translate.instant('shared.close');
            this.snackbar.open(message, action);
            return;
        }

        this.newRelicUtility.addPageAction('ReportType', 'KPI Summary', 'ReportAccess', this.query);

        this.loading = true;
        this.gridViewData = await this.kpiHttpService.getKpiByQuery(this.query);
        this.gridPageSize = this.grid.pageSize;
        this.grid.pageChange.emit({ skip: 0, take: this.gridPageSize });
        this.loading = false;
    }

    public allData(): ExcelExportData {
        const result: ExcelExportData = {
            data: process(this.gridViewData, { }).data
        };

        return result;
    }

    private loadRoles(): void {
        this.roles = [
            { title: this._translate.instant('dataReport.kpiReport.branchRole'), value: 'branch' },
            { title: this._translate.instant('dataReport.kpiReport.serviceOpsRole'), value: 'serviceops' }
        ];
    }

}
