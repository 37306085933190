<div class="service-rates-versions-view-container" fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="!editorOptions" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="2.5vh" fxFill>
        <div class="header-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25vh">
                <button mat-icon-button color="primary" (click)="close.emit()">
                    <mat-icon>chevron_left</mat-icon>
                </button>

                <span class="title">{{ group.name }}</span>
            </div>

            <mat-form-field class="search-version" fxFlex="7.5">
                <mat-label>{{ 'flynnBudgetTool.ratesVersionsView.searchVersionText' | translate }}</mat-label>
                <input matInput (input)="searchVersionText = $event.target.value" type="text" />
            </mat-form-field>

            <div *ngIf="!group.isDefault" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2.5vh">
                <span>{{ 'flynnBudgetTool.ratesVersionsView.applyToCustomers' | translate: { total: group.customerIds.length } }}</span>
                <button mat-raised-button [disabled]="isReadonly" color="primary" (click)="openGroupEditor()">{{ 'flynnBudgetTool.shared.edit' | translate }}</button>
            </div>
        </div>

        <div class="version-list" fxFlex="92.5">
            <kendo-grid [kendoGridBinding]="visibleMetadata"
                [loading]="isLoading"
                [pageSize]="15"
                [pageable]="true"
                [scrollable]="true"
                (cellClick)="openVersionEditor($event.dataItem)"
                fxFill>

                <kendo-grid-column field="version" [title]="translate.instant('flynnBudgetTool.ratesVersionsView.serviceRatesListHeaders.version')" [width]="100"></kendo-grid-column>

                <kendo-grid-column [title]="translate.instant('flynnBudgetTool.ratesVersionsView.serviceRatesListHeaders.active')" [width]="80">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <mat-icon [ngClass]="{ 'status-indicator': true, 'active-status-indicator': dataItem.isActive }">circle</mat-icon>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [title]="translate.instant('flynnBudgetTool.ratesVersionsView.serviceRatesListHeaders.activatedOn')">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <span>{{ getActivationDate(dataItem) }}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [title]="translate.instant('flynnBudgetTool.ratesVersionsView.serviceRatesListHeaders.createdOn')">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <span>{{ getCreationDate(dataItem) }}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [title]="translate.instant('flynnBudgetTool.ratesVersionsView.serviceRatesListHeaders.creator')">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <span>{{ getCreator(dataItem) }}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [title]="translate.instant('flynnBudgetTool.ratesVersionsView.serviceRatesListHeaders.revisedOn')">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <span>{{ getRevisionDate(dataItem) }}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [title]="translate.instant('flynnBudgetTool.ratesVersionsView.serviceRatesListHeaders.revisor')">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <span>{{ getRevisor(dataItem) }}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="revisions" [title]="translate.instant('flynnBudgetTool.ratesVersionsView.serviceRatesListHeaders.revisions')" [width]="100"></kendo-grid-column>
            </kendo-grid>

            <button class="add-button" mat-mini-fab color="primary" (click)="openNewVersionPrompt()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>

    <app-service-rates-editor *ngIf="editorOptions"
        [options]="editorOptions"
        [isReadonly]="isReadonly"
        (revise)="addRevision(editorOptions.metadata, $event)"
        (toggleActive)="toggleActiveStatus(editorOptions.metadata)"
        (close)="closeEditor()">
    </app-service-rates-editor>
</div>
