import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EditorUtilityService } from '../../../../core/services/utility/editor-utility/editor-utility.service';
import { LeakReportType } from '../../../../core/enums/leak-report-type.enum';
import getLeakReportTemplateEditorButtons from '../leak-report-template-editor-buttons/leak-report-template-editor-buttons';

@Component({
    selector: 'leak-report-template-editor',
    styleUrls: ['./leak-report-template-editor.component.scss'],
    templateUrl: './leak-report-template-editor.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LeakReportTemplateEditorComponent implements OnInit, AfterViewInit {
    @Input() public template = '';
    @Input() public templateId = '';
    @Output() public onTemplateUpdate = new EventEmitter<string>();

    public customButtonOptions = [];
    private _editableTemplate: string;

    public constructor(private _editorUtilityService: EditorUtilityService, private _translate: TranslateService) { }

    public ngOnInit(): void {
        this.customButtonOptions = getLeakReportTemplateEditorButtons(this._translate, this.templateId as LeakReportType);
        this.templateId = this.templateId.replace(/[^\w-]+/g, '');
    }

    public ngAfterViewInit(): void {
        this._editableTemplate = this.template;
        this._editorUtilityService.createEditor(this.templateId, { change: this.updateTemplate.bind(this) });
        this._editorUtilityService.setContent(this.templateId, this._editableTemplate);
    }

    public save(): void {
        this.updateTemplate();
        this.onTemplateUpdate.emit(this._editableTemplate);
    }

    public updateTemplate(): void {
        this._editableTemplate = this._editorUtilityService.getContent(this.templateId);
    }

    public addPlaceholder(identifier: string, placeholder: string): void {
        const id = `handle-${Date.now()}`;
        const handle = `<span id="${id}"></span>`;
        const style = '-webkit-user-select:all;-moz-user-select:all;-ms-user-select:all;user-select:all';
        const value = `<span contenteditable="false" style="${style}" class="${identifier}">{${placeholder}}</span>${handle}`;
        const editor = this._editorUtilityService.getEditor(this.templateId);
        editor.exec('inserthtml', { value });
        this._editorUtilityService.focusToEnd(this.templateId, id);
    }
}
