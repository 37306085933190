import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { PopupService, POPUP_CONTAINER } from '@progress/kendo-angular-popup';
import MaterialItem from '../../../../core/data-models/job-materials/material-item';
import { MaterialHttpService } from '../../../../core/services/http/material-http/material-http.service';
import { JobMaterialEditComponent } from '../job-material-edit/job-material-edit.component';
import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';
import { ConfirmDialogComponent } from '../../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'job-material-settings',
    styleUrls: ['./job-material-settings.component.scss'],
    templateUrl: './job-material-settings.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: POPUP_CONTAINER,
        useFactory: () => {
            return { nativeElement: document.querySelector('.job-chemical-container kendo-grid') } as ElementRef;
        }
    },
    PopupService
    ]
})
export class JobMaterialSettingsComponent implements OnInit {
    public view: GridDataResult;
    public gridState: State = {
        sort: [],
        skip: 0,
        take: 10
    };

    private materials: MaterialItem[];

    constructor(
        private _materialService: MaterialHttpService,
        private _dialog: MatDialog,
        private translate: TranslateService,
        private _snackbar: MatSnackBar,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.materials = await this._materialService.getAllMaterials();
        this.view = process(this.materials, this.gridState);
    }

    public onStateChange(state: State): void {
        this.gridState = state;
    }

    public pageChange(event: PageChangeEvent): void {
        this.gridState.skip = event.skip;
        this.view = {
            data: this.materials.slice(this.gridState.skip, this.gridState.skip + this.gridState.take),
            total: this.materials.length
        };
    }

    public async addHandler(): Promise<void> {
        const dialog = this._dialog.open(JobMaterialEditComponent, {
            data: { material: new MaterialItem() },
            minHeight: '300px',
            minWidth: '500px',
            maxWidth: '70vw',
            maxHeight: '80vh'
        });

        const result = await dialog.afterClosed().toPromise();
        const ok = this.translate.instant('shared.ok');
        if (result) {
            const successMessage = this.translate.instant('dataReport.materialsReport.succeedToAdd');
            this._snackbar.open(successMessage, ok);
            await this.refresh();
        }
        else if (result === false) {
            const failureMessage = this.translate.instant('dataReport.materialsReport.failedToAdd');
            this._snackbar.open(failureMessage, ok);
        }
    }

    public async editHandler(material: MaterialItem): Promise<void> {
        const dialog = this._dialog.open(JobMaterialEditComponent, {
            data: { material },
            minHeight: '300px',
            minWidth: '600px',
            maxWidth: '80vw',
            maxHeight: '80vh'
        });

        const result = await dialog.afterClosed().toPromise();
        const ok = this.translate.instant('shared.ok');
        if (result) {
            const successMessage = this.translate.instant('dataReport.materialsReport.succeedToEdit');
            this._snackbar.open(successMessage, ok);
            await this.refresh();
        }
        else if (result === false) {
            const failureMessage = this.translate.instant('dataReport.materialsReport.failedToEdit');
            this._snackbar.open(failureMessage, ok);
        }
    }

    public async removeHandler(material: MaterialItem): Promise<void> {
        const title = this.translate.instant('dataReport.materialsReport.areYouSure');
        const proceed = this.translate.instant('dataReport.materialsReport.proceed');
        const message = this.translate.instant('dataReport.materialsReport.materialWillBePermanentlyDeleted', { materialName: material.name });
        const data = new ConfirmDialogOption(title, message, true, proceed);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (!await dialog.afterClosed().toPromise()) {
            return;
        }

        const result = await this._materialService.deleteMaterial(material.id);
        const ok = this.translate.instant('shared.ok');
        if (result) {
            const successMessage = this.translate.instant('dataReport.materialsReport.succeedToRemove');
            this._snackbar.open(successMessage, ok);
            await this.refresh();
        }
        else {
            const failureMessage = this.translate.instant('dataReport.materialsReport.failedToRemove');
            this._snackbar.open(failureMessage, ok);
        }
    }

    private async refresh(): Promise<void> {
        this.materials = await this._materialService.getAllMaterials();
        this.gridState.skip = 0;
        this.view = process(this.materials, this.gridState);
    }
}
