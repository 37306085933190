import Crew from './crew';

export default class OnCallEvent {
    public url = '';
    public allDay = true;
    public start = '';
    public end = '';
    public title = '';
    public crewId = 0;
    public extendedProps = { eventId: '', branch: '' };
    public eventId = '';
    public crew: string;
    public crewList: Crew[] = [];
}
