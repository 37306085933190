import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import Employee from '../../../../core/data-models/employee';
import UserUtility from '../../../../core/services/utility/user-utility/user-utility.service';

@Component({
    selector: 'user-avatars-card',
    styleUrls: ['./user-avatars-card.component.scss'],
    templateUrl: './user-avatars-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarsCardComponent {
    @Input() public users: Employee[] = [];
    @Input() public visibleUsers = 5;

    get hiddenNames(): string {
        const users = this.users.slice(this.visibleUsers);

        return users.map(_ => UserUtility.getFullName(_)).join(', ');
    }
}
