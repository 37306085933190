export class JsonUtility {
    // eslint-disable-next-line
    public static toCamelCaseKeys(input: any): any {
        if (Array.isArray(input)) {
            return input.map(_ => this.toCamelCaseKeys(_));
        }

        if (input === null || input.constructor !== Object) {
            return input;
        }

        return Object.keys(input).reduce((result, key) => ({
            ...result,
            [this.toCamelCase(key)]: this.toCamelCaseKeys(input[key])
        }), {});
    }

    private static toCamelCase(key: string): string {
        const keyValue = key ? key : '';

        return keyValue.length ? keyValue[0].toLowerCase() + keyValue.slice(1) : keyValue;
    }
}
