import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserTrackingHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/useraction`;

    constructor(private _http: HttpClient) { }

    public async trackDashboardAcknowledgement(timeSpent: number): Promise<void> {
        const endPoint = `${this._api}/dashboard/acknowledge/${timeSpent}`;
        await this._http.post(endPoint, {}).toPromise();
    }
}
