<dialog-action-card class="budget-templates-dialog-container"
    [icon]="'local_atm'"
    [title]="translate.instant('shared.budgetTemplatesDialog.title')"
    [isLoaded]="isLoaded"
    (close)="onClose()">
    <div fxLayout="column" fxFill fxLayoutGap="15px" class="budget-templates-content">
        <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="20px" fxFlexOffset="2">
            <mat-form-field class="budget-template-name-field">
                <mat-label>{{ 'shared.budgetTemplatesDialog.budgetTemplates' | translate }}</mat-label>

                <mat-select [(ngModel)]="selectedTemplate" (selectionChange)="onTemplateChange($event)">
                    <mat-option *ngFor="let template of templates" [value]="template">
                        {{ template.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-stroked-button
                color="primary"
                [disabled]="!selectedTemplate"
                (click)="downloadTemplate(selectedTemplate)">
                {{ 'shared.download' | translate }}
            </button>

            <div fxLayout="column" *ngIf="assignedCustomers.length" fxFlex>
                <label>{{ 'shared.budgetTemplatesDialog.templateRestricted' | translate }}</label>
                <mat-chip-list fxLayout="row">
                    <mat-chip *ngFor="let customer of assignedCustomers" color="primary" selected>{{customer}}</mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div fxLayout="row" fxFlex *ngIf="isLoaded">
            <app-xlsx-file-preview fxFill [file]="previewFile"></app-xlsx-file-preview>
        </div>
    </div>
</dialog-action-card>
