import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { debounceTime, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import Branch from '../../../../core/data-models/branch';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import { BranchHttpService } from '../../../../core/services/http/branch-http/branch-http.service';
import { ValidatorFactory } from '../../../../core/services/validator-factory/validator-factory.service';
import AddressStatusSummary from '../../../../core/data-models/address-status-summary';
import CodeDescription from '../../../../core/data-models/code-description';
import { AppConfigHttpService } from '../../../../core/services/http/appconfig-http/appconfig-http.service';
import { AddressErrorReadUtilityService } from '../../../../shared/components/customer-management/services/address-error-read-utility/address-error-read-utility.service';

@Component({
    selector: 'branch-management',
    styleUrls: ['./new-branch-dialog.component.scss'],
    templateUrl: './new-branch-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class NewBranchDialogComponent implements OnInit {
    public addressStatus: AddressStatusSummary;
    public formGroup: UntypedFormGroup;
    public countries: { abbrev: string; fullName: string }[] = [];
    public isInternal = false;
    public isSaving = false;
    public isVerifyingAddress = false;
    public isReady = false;
    public states: string[] = [];
    public provinces: string[] = [];
    private _phoneValidator = this._validatorFactory.createPhoneValidator();
    private _emailValidator = this._validatorFactory.createEmailValidator();
    private _websiteValidator = this._validatorFactory.createWebsiteValidator();

    get isFormValid(): boolean {
        return !this.formGroup.invalid;
    }

    get cantSubmitBranch(): boolean {
        return !this.isFormValid || this.isSaving || this.isVerifyingAddress || !this.addressStatus?.verified;
    }

    get showAddressValidationContent(): boolean {
        return this.addressStatus?.visible && !this.isSaving && !this.isVerifyingAddress;
    }

    get stateList(): string[] {
        if (this.formGroup.get('address.country').value === 'CA') {
            return this.provinces;
        }
        else if (this.formGroup.get('address.country').value === 'US') {
            return this.states;
        }

        return [];
    }

    // eslint-disable-next-line max-params
    constructor(
        private _dialogRef: MatDialogRef<NewBranchDialogComponent>,
        private _featureFlagService: FeatureFlagService,
        private _formBuilder: UntypedFormBuilder,
        private _validatorFactory: ValidatorFactory,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _branchHttpService: BranchHttpService,
        private _addressValidationService: AddressErrorReadUtilityService,
        private _appConfigService: AppConfigHttpService,
        @Inject(MAT_DIALOG_DATA) public data: Branch,
    ) { }

    public async ngOnInit(): Promise<void> {
        this.countries = [
            { abbrev: 'CA', fullName: this._translateService.instant('admin.branchManagement.addBranchDialog.countries.canada') },
            { abbrev: 'US', fullName: this._translateService.instant('admin.branchManagement.addBranchDialog.countries.usa') }
        ];

        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        this.states = await this._appConfigService.getUSStates();
        this.provinces = await this._appConfigService.getCAStates();
        this.setupForm(this.data);
        this.formGroup.markAllAsTouched();
        this.isReady = true;

        if (this.data) {
            this.addressStatus = await this.validateAddress(this.data);
        }
    }

    public async submit(): Promise<void> {
        if (this.isVerifyingAddress || !this.addressStatus?.verified) {
            return;
        }

        if (!this.formGroup.valid) {
            const message = this._translateService.instant('admin.branchManagement.addBranchDialog.invalidForm');
            this._snackBar.open(message, this._translateService.instant('shared.close'));
        }
        else {
            const branch: Branch = this.formGroup.getRawValue() as Branch;

            try {
                this.isSaving = true;


                branch.geoLocation = await this._branchHttpService.getGeoLocationForBranch(branch);

                let result: CodeDescription = null;
                if (this.data && this.data.id) {
                    branch.id = this.data.id;
                    branch.code = this.data.code;
                    result = await this._branchHttpService.updateBranchInfo(branch);
                }
                else {
                    result = await this._branchHttpService.addBranchInfo(branch);
                }

                this._snackBar.open(result.description, this._translateService.instant('shared.close'));

                if (result.code === '200') {
                    this._dialogRef.close(true);
                }
            }
            catch (ex) {
                const msg = this._translateService.instant('core.httpServices.branch.internalError');
                this._snackBar.open(msg, this._translateService.instant('shared.close'));
            }
            finally {
                this.isSaving = false;
            }
        }
    }

    public cancel(): void {
        this._dialogRef.close();
    }

    private setupForm(branch: Branch): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fields: any = {
            name: ['', [Validators.required]],
            address: this._formBuilder.group({
                addr1: ['', [Validators.required]],
                addr2: [''],
                city: ['', [Validators.required]],
                state: ['', [Validators.required]],
                zip: ['', [Validators.required]],
                country: ['CA', [Validators.required]]
            }),
            phone: ['', [Validators.required, this._phoneValidator.validator]],
            fax: ['', [this._phoneValidator.validator]],
            useDaylightSaving: [branch?.useDaylightSaving?? true]
        };

        if (!this.isInternal) {
            fields.email = ['', [this._emailValidator.validator]];
            fields.website = ['', [this._websiteValidator.validator]];
        }
        else {
            fields.code = ['', [Validators.required]];
        }

        this.formGroup = this._formBuilder.group(fields);

        if (branch) {
            this.formGroup.patchValue(branch);
        }

        // Use switchMap so we don't care about prior results
        this.formGroup.get('address').valueChanges.pipe(
            debounceTime(600),
            switchMap(() => {
                this.isVerifyingAddress = true;
                return this.validateAddress(this.formGroup.getRawValue() as Branch);
            })
        ).subscribe((_: AddressStatusSummary) => {
            this.addressStatus = _;
            this.isVerifyingAddress = false;
        });
    }

    private async validateAddress(branch: Branch): Promise<AddressStatusSummary> {
        return await this._addressValidationService.getAddressStatus({
            address1: branch.address.addr1,
            address2: branch.address.addr2,
            city: branch.address.city,
            country: branch.address.country,
            provice: branch.address.state,
            postalcode: branch.address.zip,
            phone: '',
            corrections: ''
        }, true, false, false);
    }
}
