import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipList as MatChipList } from '@angular/material/legacy-chips';
import { v4 as uuidV4 } from 'uuid';

import { LineItem } from '../../../../core/data-models/generic/line-item';
import ErpTaskCode from '../../../../core/data-models/erp/erp-task-code';
import { TypeRecord } from '../../../../core/data-models/generic/type-record';
import { BranchServiceRate } from '../../../../core/data-models/rates/service-rates/branch-service-rate';
import { GrossProfitPercentageRate } from '../../../../core/data-models/rates/service-rates/gross-profit-percentage-rate';
import { MaterialRate } from '../../../../core/data-models/rates/service-rates/material-rate';
import { ValidatorService } from '../../../../core/services/validator-service/validator-service';

@Injectable({ providedIn: 'root' })
export class ServiceRatesEditorService {
    private readonly _uuidPattern = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/;

    constructor(private _formBuilder: UntypedFormBuilder, private _validatorService: ValidatorService) { }

    public createCurrencyField(value: number): UntypedFormControl {
        return new UntypedFormControl(value ?? 0, [Validators.required, this._validatorService.decimalValidator(2, 0)]);
    }

    public createBranchRateField(rate: BranchServiceRate | null = null, projectTypes: TypeRecord[] = []): UntypedFormGroup {
        const grossProfitRates = rate?.grossProfitPercentageRates ?? projectTypes.map(_ => new GrossProfitPercentageRate(_.identifier));
        const grossProfitRatesFields = grossProfitRates.map(_ => this.createGrossProfitPercentageRateField(_));

        return this._formBuilder.group({
            branchCode: [rate?.branchCode ?? null, [Validators.required, Validators.pattern(/^\d{3}$/)]],
            laborHourlyRate: [rate?.laborHourlyRate ?? 0, [Validators.required, this._validatorService.decimalValidator(2, 0)]],
            insurancePercentageRate: [this.getPercentageDisplayValue(rate?.insurancePercentageRate), [Validators.required, Validators.min(0), Validators.max(100)]],
            indirectsPercentageRate: [this.getPercentageDisplayValue(rate?.indirectsPercentageRate), [Validators.required, Validators.min(0), Validators.max(100)]],
            subcontractGrossProfitPercentageRate: [this.getPercentageDisplayValue(rate?.subcontractGrossProfitPercentageRate), [Validators.required, Validators.min(0), Validators.max(100)]],
            grossProfitPercentageRates: this._formBuilder.array(grossProfitRatesFields)
        });
    }

    public createMaterialRateField(rate: MaterialRate | null = null): UntypedFormGroup {
        return this._formBuilder.group({
            type: this.createTypeRecordField({
                identifier: rate?.type.identifier ?? uuidV4(),
                name: rate?.type.name ?? null
            }),
            unitPrice: [rate?.unitPrice ?? 0, [Validators.required, Validators.min(0.01), this._validatorService.decimalValidator(2, 0)]],
            unitOfMeasurement: [rate?.unitOfMeasurement ?? null, [Validators.required, Validators.pattern(/[a-zA-Z]/)]]
        });
    }

    public createCustomSummaryLineItemField(item: LineItem<TypeRecord> | null = null): UntypedFormGroup {
        return this._formBuilder.group({
            identifier: this.createTypeRecordField({
                identifier: item?.identifier.identifier ?? uuidV4(),
                name: item?.identifier.name ?? null
            }),
            percentageRate: [this.getPercentageDisplayValue(item?.percentageRate), [Validators.required, Validators.min(0.01), this._validatorService.decimalValidator(2, 0)]]
        });
    }

    public createTaskField(rate: ErpTaskCode): UntypedFormGroup {
        return this._formBuilder.group({
            code: [rate.code],
            description: [rate.description],
            accountCategory: [rate.accountCategory]
        });
    }

    public createTypeRecordField(type: TypeRecord | null = null): UntypedFormGroup {
        return this._formBuilder.group({
            identifier: [type?.identifier ?? uuidV4(), [Validators.required, Validators.pattern(this._uuidPattern)]],
            name: [type?.name, Validators.required]
        });
    }

    public createGrossProfitPercentageRateField(rate: GrossProfitPercentageRate): UntypedFormGroup {
        return this._formBuilder.group({
            typeIdentifier: [rate.typeIdentifier, [Validators.required, Validators.pattern(this._uuidPattern)]],
            rate: [this.getPercentageDisplayValue(rate.rate), [Validators.required, Validators.min(0), Validators.max(100)]]
        });
    }

    public getTypeRecordDisplayText(group: UntypedFormGroup): string {
        return group.controls.name.value ?? '';
    }

    public addTypeRecord(formArray: UntypedFormArray, event: MatChipInputEvent, chipList: MatChipList): string | null {
        const name = (event.value ?? '').trim();

        if (chipList) {
            chipList.errorState = !name;
        }

        if (!name) {
            return null;
        }

        if (event.input) {
            event.input.value = '';
        }

        const control = this.createTypeRecordField({ identifier: null, name });
        formArray.push(control);

        return control.value.identifier;
    }

    public updateTypeRecord(formArray: UntypedFormArray, index: number, value: string): void {
        const name = (value ?? '').trim();

        if (name) {
            formArray.at(index).get('name').setValue(name);
        }
    }

    public getPercentageDisplayValue(value: number): string {
        const converted = (value ?? 0) * 100;
        const modifier = 100;
        const rounded = Math.round(converted * modifier) / modifier;

        return rounded.toFixed(2);
    }
}
