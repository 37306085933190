import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserHttpService } from '../../../../../core/services/http/user-http/user-http.service';
import { TrackLocationHttpService } from '../../../../../core/services/http/track-location-http/track-location-http.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import GoogleAddressModel from '../../../../../core/data-models/google-address-model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'customer-geo-coordinate-map',
    styleUrls: ['./customer-geo-coordinate-map.component.scss'],
    templateUrl: './customer-geo-coordinate-map.component.html'
})
export class CustomerGeoCoordinateMapComponent implements OnInit {
    @Output() addressSelected = new EventEmitter<GoogleAddressModel>();
    @Output() cancelGeoSelection = new EventEmitter<void>();
    public showMarker = false;
    public addressLocation: google.maps.LatLngLiteral;
    public resolvedAddress: GoogleAddressModel;
    private _center: google.maps.LatLngLiteral;
    private _options: google.maps.MapOptions;

    constructor(
        private _translate: TranslateService,
        private _userHttp: UserHttpService,
        private _trackHttp: TrackLocationHttpService,
        private _snackBar: MatSnackBar
    ) { }

    public async ngOnInit(): Promise<void> {
        const centerCoordinates = (await this._userHttp.getUserInfo(true))?.branchInfo.geoLocation;
        if (!centerCoordinates) {
            const okLabel = this._translate.instant('snackbars.ok');
            const message = this._translate.instant('shared.customerGeoCoordinateMap.unableToLoadCoordinates');
            this._snackBar.open(message, okLabel);
            this.addressSelected.emit(null);
        }
        else {
            const mapTypeId = google.maps.MapTypeId.ROADMAP;
            this._center = { lat: centerCoordinates.location[1], lng: centerCoordinates.location[0] };
            this._options = { mapTypeId, zoomControl: true, panControl: false };
        }
    }

    get center(): google.maps.LatLngLiteral {
        return this._center;
    }

    get options(): google.maps.MapOptions {
        return this._options;
    }

    public toggleMarker(markerStatus: boolean): void {
        if (!this.resolvedAddress) {
            const okLabel = this._translate.instant('snackbars.ok');
            const message = this._translate.instant('shared.customerGeoCoordinateMap.noLocationSelected');
            this._snackBar.open(message, okLabel);
            return;
        }
        this.showMarker = markerStatus;
    }

    public deleteMarker(): void {
        this.showMarker = false;
        this.resolvedAddress = null;
    }

    public async addMarker(event: google.maps.MapMouseEvent): Promise<void> {
        this.showMarker = true;
        this.addressLocation = {
            lat: event?.latLng.lat(),
            lng: event?.latLng.lng()
        };

        const geoCoordFormatted = event.latLng.toString().replace(/\./g, '-dot-');
        this.resolvedAddress = await this._trackHttp.getAddress(geoCoordFormatted);
        if (!this.resolvedAddress) {
            const okLabel = this._translate.instant('snackbars.ok');
            const message = this._translate.instant('shared.customerGeoCoordinateMap.addressFromGeoCoordinatesFailed');
            this._snackBar.open(message, okLabel);
            return;
        }
        this.showMarker = true;
    }

    public selectSite(): void {
        if (!this.resolvedAddress) {
            const okLabel = this._translate.instant('snackbars.ok');
            const message = this._translate.instant('shared.customerGeoCoordinateMap.noLocationSelected');

            this._snackBar.open(message, okLabel);
            return;
        }
        this.addressSelected.emit(this.resolvedAddress);
    }

}
