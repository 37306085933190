import { Ng2StateDeclaration, Transition, StateService } from '@uirouter/angular';

import BranchTimezone from '../../core/data-models/branch-timezone';
import { BranchHttpService } from '../../core/services/http/branch-http/branch-http.service';
import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import { QuoteFollowupLogsComponent } from '../../shared/components/quote-followup-logs/quote-followup-logs.component';
import { QuoteAttachmentsComponent } from '../../shared/components/quote-editor/quote-attachments/quote-attachments.component';
import { ContentNotFoundComponent } from '../../shared/components/errors/content-not-found/content-not-found.component';
import { QuotePdfPreviewComponent } from '../../shared/components/quote-pdf-preview/quote-pdf-preview.component';
import { ReadPermission } from '../../core/enums/read-permission.enum';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { globalResolver } from '../../core/configs/routing/global-resolver';

import { QuoteComponent } from './quote.component';
import { QuoteDetailsComponent } from './quote-details/quote-details.component';
import { QuotePhotoReportComponent } from './quote-photo-report/quote-photo-report.component';
import { QuoteDrawingsComponent } from './quote-drawings/quote-drawings.component';
import { QuoteReportDetailsComponent } from './quote-report-details/quote-report-details.component';
import { SiteQuotesComponent } from './site-quotes/site-quotes.component';
import { SiteProjectHistoryComponent } from './site-project-history/site-project-history.component';
import { BudgetManagementContainerComponent } from '../../shared/components/budget-management-container/budget-management-container.component';
import { SalesCodeConfigService } from '../../core/services/sales-code-config/sales-code-config.service';
import BudgetManagementPayload from '../../core/data-models/budget-management-payload';
import { QuoteHttpService } from '../../core/services/http/quote-http/quote-http.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';

const resolve = [
    {
        token: 'activeQuoteId',
        deps: [Transition],
        resolveFn: (_: Transition): string => _.params().quoteId
    },
    {
        token: 'timeZone',
        deps: [BranchHttpService, Transition],
        resolveFn: async(branchHttpService: BranchHttpService, transition: Transition): Promise<BranchTimezone> => {
            const branches = await branchHttpService.getAllBranches();
            const quoteId = transition.params().quoteId;

            if (quoteId) {
                const quoteBranch = branches.find(_ => _.code === quoteId.substr(0, 3));
                return quoteBranch?.geoLocation.timeZone;
            }
            return null;
        }
    },
    ...globalResolver
];

export const states: Ng2StateDeclaration[] = [
    {
        name: 'quotes',
        url: '/quotes',
        component: QuoteComponent,
        resolve: [
            {
                token: 'hasAccess',
                deps: [UserPermissionService, StateService, FeatureFlagService],
                resolveFn: async(
                    _userPermissionService: UserPermissionService,
                    _stateService: StateService,
                    _featureFlagService: FeatureFlagService
                ): Promise<void> => {
                    const isInternal = _featureFlagService.featureFlags[FeatureFlags.UseQuotesFeature];
                    const permission = ReadPermission.ViewQuotes;

                    if (!isInternal || !await _userPermissionService.hasReadPermission(permission)) {
                        _stateService.transition.abort();
                        await _stateService.go('pageNotFound');
                    }
                }
            },
            ...resolve
        ]
    },
    {
        name: 'quote',
        url: '/quotes/:quoteId',
        redirectTo: 'quotes.details'
    },
    {
        name: 'quotes.details',
        url: '/:quoteId/details',
        component: QuoteDetailsComponent,
        resolve
    },
    {
        name: 'quotes.followUpLog',
        url: '/:quoteId/followUpLog',
        component: QuoteFollowupLogsComponent,
        resolve
    },
    {
        name: 'quotes.attachments',
        url: '/:quoteId/attachments',
        component: QuoteAttachmentsComponent,
        resolve
    },
    {
        name: 'quotes.notFound',
        url: '/not-found',
        component: ContentNotFoundComponent,
        resolve: [
            // eslint-disable-next-line max-len
            { token: 'title', resolveFn: (): string => 'contentNotFound.cantLocateQuote' },
            { token: 'subTitle', resolveFn: (): string => 'contentNotFound.correctIdWarning' }
        ]
    },
    {
        name: 'quotes.photos',
        url: '/:quoteId/photos',
        component: QuotePhotoReportComponent,
        resolve
    },
    {
        name: 'quotes.drawings',
        url: '/:quoteId/drawings',
        component: QuoteDrawingsComponent,
        resolve
    },
    {
        name: 'quotes.originalReport',
        url: '/:quoteId/original-report',
        component: QuoteReportDetailsComponent,
        resolve
    },
    {
        name: 'quotes.budget',
        url: '/:quoteId/budget',
        component: BudgetManagementContainerComponent,
        resolve: [
            ...resolve,
            {
                token: 'payload',
                deps: [SalesCodeConfigService, FeatureFlagService, QuoteHttpService, Transition],
                resolveFn: async(salesCodeConfigService: SalesCodeConfigService, featureFlagService: FeatureFlagService, quoteHttpService: QuoteHttpService, _: Transition): Promise<BudgetManagementPayload> => {
                    const quoteId = _.params().quoteId;
                    const customerId = await quoteHttpService.getCustomerIdByQuoteId(quoteId);
                    const { useLegacyBudgetTool, hasExistingLegacyBudget, hasExistingWebBudget, useSubcontractRates } = await featureFlagService.evaluateUseLegacyBudgetToolByQuoteId(quoteId);

                    return new BudgetManagementPayload(useLegacyBudgetTool, hasExistingLegacyBudget, hasExistingWebBudget, quoteId, customerId, true, useSubcontractRates);
                }
            }
        ]
    },
    {
        name: 'quotes.pdfPreview',
        url: '/:quoteId/pdf-preview',
        component: QuotePdfPreviewComponent,
        resolve
    },
    {
        name: 'quotes.siteQuotes',
        url: '/:quoteId/site-quotes',
        component: SiteQuotesComponent,
        resolve
    },
    {
        name: 'quotes.siteProjects',
        url: '/:quoteId/site-projects',
        component: SiteProjectHistoryComponent,
        resolve
    }
];
