import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import ParameterSet from '../../../../../core/data-models/parameter-set';
import CheckboxData from '../../../../../core/data-models/checkbox-data';

@Component({
    selector: 'parameter-selector',
    styleUrls: ['./parameter-selector.component.scss'],
    templateUrl: './parameter-selector.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParameterSelectorComponent {
    @Input() public parameterSets: ParameterSet[] = [];
    @Input() public isDisabled = false;
    @Output() public update = new EventEmitter<ParameterSet>();
    public activeIndex = 0;

    public onCheckboxChange(option: CheckboxData, parameterSet: ParameterSet): void {
        option.checked = !option.checked;
        this.update.emit(parameterSet);
    }
}
