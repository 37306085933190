<form *ngIf="branch" class="project-section-container" [formGroup]="form" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1.5vh">
    <span>{{ branchText }}</span>

    <mat-form-field>
        <mat-label>{{ 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.steps.project.projectType' | translate }}</mat-label>

        <mat-select [formControl]="form.get('projectType')" [compareWith]="isSameProjectType" [disabled]="readonly">
            <mat-option *ngFor="let type of projectTypes" [value]="type">{{ type.name }}</mat-option>
        </mat-select>

        <mat-error *ngIf="form.get('projectType').errors?.required">
            * {{ 'flynnBudgetTool.budgetEstimation.errors.requiredField' | translate }}
        </mat-error>
    </mat-form-field>
</form>
