import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../../shared/shared.module';
import { DataReportRootViewComponent } from './root-view/data-report-root-view.component';
import { routes } from './data-report.routing';
import { KpiReportComponent } from './kpi-report/kpi-report.component';
import { QuoteReportComponent } from './quote-report/quote-report.component';
//eslint-disable-next-line
import { ServiceOpsRejectionReportComponent } from './service-ops-rejection-report/service-ops-rejection-report.component';
import { DeletedJobReportComponent } from './deleted-job-report/deleted-job-report.component';
import { FallHazardReportComponent } from './fall-hazard-report/fall-hazard-report.component';
import { TemporaryProjectAgingReportComponent } from './temporary-project-aging-report/temporary-project-aging-report.component';
import { CustomerQuotesReportComponent } from './customer-report/customer-report.component';
import { ProjectCovidScreeningReportComponent } from './project-covid-screening-report/project-covid-screening-report.component';
import { StockLogReportComponent } from './stock-log-report/stock-log-report.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        UIRouterModule.forChild({ states: routes })
    ],
    declarations: [
        DataReportRootViewComponent,
        KpiReportComponent,
        QuoteReportComponent,
        ServiceOpsRejectionReportComponent,
        DeletedJobReportComponent,
        FallHazardReportComponent,
        TemporaryProjectAgingReportComponent,
        CustomerQuotesReportComponent,
        ProjectCovidScreeningReportComponent,
        StockLogReportComponent
    ]
})
export class DataReportModule { }
