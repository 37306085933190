import { Clipboard } from '@angular/cdk/clipboard';
import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'grid-item-actions',
    styleUrls: ['./grid-item-actions.component.scss'],
    templateUrl: './grid-item-actions.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridItemActionsComponent {
    @Input() public content = '';
    @Input() public canEnableEdit = false;
    @Input() public canClearContent = false;
    @Output() public editEnabled = new EventEmitter();
    @Output() public contentClear = new EventEmitter();

    constructor(public clipboard: Clipboard) {}

    public onCopy(element: HTMLElement): void {
        this.clipboard.copy(this.convertHtmlToPlainString(this.content));

        const name = 'text-copied';
        element.classList.remove(name);
        setTimeout(() => {
            element.classList.add(name);
        });
    }

    public convertHtmlToPlainString(html: string): string {
        return html.replace(/<br\s*\/?>/gi, '\n')
            .replace(/<\/p>/gi, '\n')
            .replace(/<\/li>/gi, '\n')
            .replace(/<\/?ul>/gi, '')
            .replace(/<\/?ol>/gi, '')
            .replace(/<li[^>]*>/gi, '* ')
            .replace(/<\/h[1-6]>/gi, '\n')
            .replace(/<\/?[^>]+(>|$)/g, '')
            .replace(/&nbsp;/gi, ' ')
            .replace(/\n\s*\n/g, '\n')
            .trim();
    }
}
