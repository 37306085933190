<div fxLayout="column" class="additional-info-container">
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="50">
            <project-questions-card
            [projectQuestions]="config.projectQuestions"
            [isUSProject]="config.isUsProject"
            [readOnly]="false"
            (result)="onProjectQuestionsResult($event)">
            </project-questions-card>
        </div>
        <div fxLayout="column" fxFlex="50">
            <project-attributes-card
            [projectQuestions]="config.projectQuestions"
            [isUSProject]="config.isUsProject"
            [readOnly]="false"
            (result)="onProjectAttributesResult($event)">
            </project-attributes-card>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <button mat-stroked-button (click)="stepClicked.emit('previous')" color="primary">
            {{ "dispatch.sharedStepLabels.previousButton" | translate }}
        </button>
        <button mat-stroked-button (click)="validateAndMoveNext()" color="primary">
            {{ "dispatch.sharedStepLabels.nextButton" | translate }}
        </button>
    </div>
</div>

