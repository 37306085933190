<dialog-action-card class="redispatch-management-dialog-container"
    [icon]="'business_center'"
    [title]="dispatchExistingOptions?.isDispatch ? translate.instant('projects.redispatch.title3') : (handOffMeetingNeededOnThisDispatch ? translate.instant('projects.redispatch.title1') :  translate.instant('projects.redispatch.title2'))"
    [isLoaded]="isLoaded" (close)="onClose()">

    <div class="redispatch-management-content" *ngIf="isLoaded">
        <div class="project-link-container" *ngIf="dispatchExistingOptions.showProjectLink">
            <a fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px" class="project-details-link" href="/projects/{{dispatchExistingOptions.projectId}}" target="_blank">
                <span>{{translate.instant('projects.redispatch.projectLinkText')}}</span>
                <mat-icon>open_in_new</mat-icon>
            </a>
        </div>

        <redispatch-project *ngIf="!dispatchExistingOptions.isHandOffMeetingRequired"
            (nextStepClicked)="redispatchProject()"
            [dispatchOptions]="dispatchExistingOptions"
            [config]="redispatchProjectStepConfig"
            [handOffMeetingApprovedCrew]="crewReferencedInLastHandOffMeeting"
            [isDispatch]="dispatchExistingOptions?.isDispatch"
            fxFlexFill>
        </redispatch-project>

        <div *ngIf="dispatchExistingOptions.isHandOffMeetingRequired">
            <mat-horizontal-stepper linear #dispatchStepper>
                <mat-step fxFlexFill
                    [editable]="true"
                    [label]="redispatchProjectStepConfig.label"
                    [completed]="redispatchProjectStepConfig.completed">
                    <redispatch-project
                        (nextStepClicked)="completeFirstStep($event)"
                        (selectedCrewChanged)="determineIfHandoffRequiredForDispatch($event)"
                        [dispatchOptions]="dispatchExistingOptions"
                        [config]="redispatchProjectStepConfig"
                        [handOffMeetingApprovedCrew]="crewReferencedInLastHandOffMeeting"
                        [isDispatch]="dispatchExistingOptions.isDispatch" fxFlexFill>
                    </redispatch-project>
                </mat-step>
                <mat-step *ngIf="handOffMeetingNeededOnThisDispatch"
                    [completed]="handoffMeetingStepConfig.completed"
                    [label]="handoffMeetingStepConfig.label">
                    <handoff-meeting
                        (stepClicked)="redispatchProjectWithHandoffMeeting($event)"
                        [config]="handoffMeetingStepConfig"
                        [foremanName]="redispatchProjectStepConfig?.data?.crew?.foreman?.preferredName"
                        [hasFollowUpStep]="false"
                        [projectId]="dispatchExistingOptions.projectId"
                        [projectName]="dispatchExistingOptions.projectName">
                    </handoff-meeting>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</dialog-action-card>
