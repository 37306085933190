import { Component, ChangeDetectionStrategy, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { UntypedFormGroup, UntypedFormArray, AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatLegacyChipList as MatChipList, MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile, startWith } from 'rxjs/operators';

import { ServiceRatesEditorService } from '../service-rates-editor.service';

@Component({
    selector: 'app-service-rates-miscellaneous-options',
    styleUrls: ['./service-rates-miscellaneous-options.component.scss'],
    templateUrl: './service-rates-miscellaneous-options.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRatesMiscellaneousOptionsComponent implements AfterViewInit, OnDestroy {
    @Input() public form: UntypedFormGroup;
    @Input() public isReadonly;
    @ViewChild('miscellaneousItemTypesChipList') private _miscellaneousItemTypesChipList: MatChipList;
    @ViewChild('qualityControlFeesPercentageRatesChipList') private _qualityControlFeesPercentageRatesChipList: MatChipList;
    public readonly separatorKeyCodes = [ENTER];
    private _isComponentActive = true;

    constructor(public translate: TranslateService, private _serviceRatesEditorService: ServiceRatesEditorService) { }

    get miscellaneousItemTypes(): UntypedFormArray {
        return this.form.controls.miscellaneousItemTypes as UntypedFormArray;
    }

    get qualityControlFeesPercentageRates(): UntypedFormArray {
        return this.form.controls.qualityControlFeesPercentageRates as UntypedFormArray;
    }

    public ngAfterViewInit(): void {
        this.validateChipLists();
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
    }

    public getItemTypeDisplayText(group: UntypedFormGroup): string {
        return this._serviceRatesEditorService.getTypeRecordDisplayText(group);
    }

    public getQualityControlFeesPercentageRateDisplayText(control: AbstractControl): string {
        const value = Number(control.value ?? 0) * 100;
        const modifier = Math.pow(10, 2);

        return `${Math.round(value * modifier) / modifier}%`;
    }

    public addItemType(formArray: UntypedFormArray, event: MatChipInputEvent, chipList: MatChipList): string | null {
        return this._serviceRatesEditorService.addTypeRecord(formArray, event, chipList);
    }

    public updateItemType(formArray: UntypedFormArray, index: number, value: string): void {
        this._serviceRatesEditorService.updateTypeRecord(formArray, index, value);
    }

    public addQualityControlFeesPercentageRate(event: MatChipInputEvent): void {
        const value = Number(event.value);
        const isInvalidValue = isNaN(value);

        if (this._qualityControlFeesPercentageRatesChipList) {
            this._qualityControlFeesPercentageRatesChipList.errorState = isInvalidValue;
        }

        if (isInvalidValue) {
            return;
        }

        if (event.input) {
            event.input.value = '';
        }

        const modifier = Math.pow(10, 4);
        const rounded = Math.round(value / 100 * modifier) / modifier;
        this.qualityControlFeesPercentageRates.push(new UntypedFormControl(rounded));
    }

    private validateChipLists(): void {
        // mat chip list does not set the error state for reactive form field unless we do it manually
        this.miscellaneousItemTypes.valueChanges.pipe(startWith(null), takeWhile(() => this._isComponentActive)).subscribe(() => {
            this._miscellaneousItemTypesChipList.errorState = this.miscellaneousItemTypes.invalid;
        });

        this.qualityControlFeesPercentageRates.valueChanges.pipe(startWith(null), takeWhile(() => this._isComponentActive)).subscribe(() => {
            this._qualityControlFeesPercentageRatesChipList.errorState = this.qualityControlFeesPercentageRates.invalid;
        });
    }
}
