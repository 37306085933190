<div class="video-viewer-container" fxLayout="column" fxLayoutGap="7px">
    <mat-card fxLayout="column" fxFlex>
        <mat-card-content fxLayout="column" fxFlex [hidden]="loading" class="main-report-content">
            <ng-container >
                <div class="video-viewer-cards" fxFlex>
                    <div fxLayout="row wrap" fxLayoutGap="7px">
                        <app-video-card *ngFor="let video of videos;"
                            [video]="video"
                           >
                        </app-video-card>
                    </div>

                </div>
            </ng-container>
        </mat-card-content>
        <dots-loader [hidden]="!loading" fxFill fxFlexAlign="center"></dots-loader>
    </mat-card>
</div>
