import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule, StateService } from '@uirouter/angular';

import { SharedModule } from '../../shared/shared.module';
import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';
import { ReadPermission } from '../../core/enums/read-permission.enum';
import { globalResolver } from '../../core/configs/routing/global-resolver';

import { DictionaryComponent } from './dictionary.component';

const states: Ng2StateDeclaration[] = [
    {
        name: 'dictionary',
        url: '/dictionary',
        component: DictionaryComponent,
        resolve: [
            {
                token: 'hasAccess',
                deps: [UserPermissionService, FeatureFlagService, StateService],
                resolveFn: async(
                    _userPermissionService: UserPermissionService,
                    _featureFlagService: FeatureFlagService,
                    _stateService: StateService
                ): Promise<void> => {
                    const isInternal = _featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];

                    if (!isInternal || !await _userPermissionService.hasReadPermission(ReadPermission.ViewDictionary)) {
                        _stateService.transition.abort();
                        await _stateService.go('pageNotFound');
                    }
                }
            },
            ...globalResolver
        ]
    }
];

@NgModule({
    imports: [
        SharedModule,
        UIRouterModule.forChild({ states })
    ],
    declarations: [DictionaryComponent]
})
export class DictionaryModule { }
