<div fxLayout="row" fxFill fxLayoutGap="10px">
    <div fxLayout="column" fxFlex="50" fxLayoutGap="10px"
    [style.background-image]="backgroundImage" class="drawing-image">
    </div>
    <div fxLayout="column" fxFlex="50">
        <div fxLayoutGap="15px" fxFlexOffset="15">
            <div fxLayout="column" fxFlex="50">
                <mat-checkbox [(ngModel)]="drawing.internalImage">
                    <div fxLayout="row" fxLayoutGap="5px"><mat-icon color='warn'>block</mat-icon> <span>
                        {{ 'shared.editDrawing.notForCustomer' | translate }}</span></div>
                </mat-checkbox>
            </div>
        </div>
        <div>
            <mat-form-field fxFill>
                <mat-label>{{ 'shared.description' | translate }}</mat-label>
                <textarea matInput [(ngModel)]="drawing.contentValue" rows="5" maxlength="225"></textarea>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="end center" fxLayoutGap="20px">
            <button mat-flat-button color="warn" (click)="actionClick.emit('Back')">
                <mat-icon>backspace</mat-icon>
                <span>{{ 'shared.back' | translate }}</span>
            </button>
            <button mat-flat-button color="primary" (click)="actionClick.emit('Save')">
                <mat-icon>save</mat-icon>
                <span>{{ 'shared.save' | translate }}</span>
            </button>
        </div>
    </div>
</div>
