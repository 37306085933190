import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyFormFieldModule as MatFormFieldModule, MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MatLegacySnackBarModule as MatSnackBarModule, MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { GridModule } from '@progress/kendo-angular-grid';

import { BranchServiceRatesChangesDisplayComponent } from './components/displays/branch-service-rates-changes-display/branch-service-rates-changes-display.component';
import { EditableTextDisplayComponent } from './components/displays/editable-text-display/editable-text-display.component';
import { DialogPanelComponent } from './components/panels/dialog-panel/dialog-panel.component';
import { ConfirmationPromptComponent } from './components/prompts/confirmation-prompt/confirmation-prompt.component';
import { VersionPromptComponent } from './components/prompts/version-prompt/version-prompt.component';
import { ServiceRatesBranchesDisplayComponent } from './components/displays/service-rates-branches-display/service-rates-branches-display.component';
import { ConvertPercentagePipe } from './pipes/convert-percentage/convert-percentage.pipe';

@NgModule({
    declarations: [
        BranchServiceRatesChangesDisplayComponent,
        EditableTextDisplayComponent,
        DialogPanelComponent,
        ConfirmationPromptComponent,
        VersionPromptComponent,
        ServiceRatesBranchesDisplayComponent,
        ConvertPercentagePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatCheckboxModule,
        MatChipsModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatStepperModule,
        MatDialogModule,
        TranslateModule,
        GridModule,
        MatAutocompleteModule,
        MatCardModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatCheckboxModule,
        MatChipsModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatStepperModule,
        MatDialogModule,
        TranslateModule,
        GridModule,
        BranchServiceRatesChangesDisplayComponent,
        EditableTextDisplayComponent,
        DialogPanelComponent,
        ConfirmationPromptComponent,
        VersionPromptComponent,
        MatAutocompleteModule,
        MatCardModule,
        ServiceRatesBranchesDisplayComponent,
        ConvertPercentagePipe
    ],
    providers: [
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { verticalPosition: 'top', duration: 5000 }
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' }
        },
        ConvertPercentagePipe
    ]
})
export class SharedModule { }
