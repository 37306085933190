import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import GeofenceReportQuery from '../../../data-models/data-report/geofence-report-query';
import GeofenceEvent from '../../../data-models/geofence-event';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GeofenceHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/location/geofence`;

    constructor(private _http: HttpClient) { }

    public async getGeofenceEvents(query: GeofenceReportQuery): Promise<GeofenceEvent[]> {
        try {
            const endpoint = `${this._api}/search`;
            const data = await this._http.post<GeofenceEvent[]>(endpoint, query).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }
}
