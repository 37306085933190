/* eslint-disable max-len, max-lines, max-lines-per-function */
import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';

import KeyValuePair from '../../../core/data-models/key-value-pair';
import ProjectGeneralInfo from '../../../core/data-models/project-general-info/project-general-info';
import StockLogDialogOptions from '../../../core/data-models/job-materials/stock-log-dialog-options';
import ActionMenuItemOption from '../../../core/data-models/action-menu-item-option';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import { ProjectAction } from '../../../core/enums/project-action.enum';
import { ProjectDispatchOptions } from '../../../core/enums/project-dispatch-options.enum';
import { AppEventService } from '../../../core/services/events/app-event/app-event.service';
import { ProjectPrint } from '../../../core/enums/project-print.enum';
import { ReportHttpService } from '../../../core/services/http/report-http/report-http.service';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';
import { ConditionReportOptionsDialogComponent } from '../../../shared/components/dialogs/condition-report-options-dialog/condition-report-options-dialog.component';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';
import { ProjectWorkflow } from '../../../core/enums/project-workflow.enum';
import { WorkOrderEditDialogComponent } from '../../../shared/components/dialogs/work-order-edit-dialog/work-order-edit-dialog.component';
import WorkOrderStepConfig from '../../../core/data-models/dispatch/work-order-step-config';
import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import WorkOrderUpdateDto from '../../../core/data-models/dto-models/work-order-update-dto';
import { ActionProgressDialogComponent } from '../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { UserPermissionService } from '../../../core/services/user-permission/user-permission.service';
import { InvoiceHttpService } from '../../../core/services/http/invoice-http/invoice-http.service';
import { CustomReportTemplateHttpService } from '../../../core/services/http/custom-report-template-http/custom-report-template-http.service';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { ReadPermission } from '../../../core/enums/read-permission.enum';
import { ProjectDetailsSection } from '../../../core/enums/project-details-section.enum';
import { CustomReportPrintDialogComponent } from '../../../shared/components/dialogs/custom-report-print-dialog/custom-report-print-dialog.component';

import { ProjectDetailsDispatchService } from './services/project-details-disptach/project-details-disptach.service';
import { ProjectReviewDialogComponent } from './project-general-info-dialogs/project-review-dialog/project-review-dialog.component';
import { ProjectNoteDialogComponent } from './project-general-info-dialogs/project-note-dialog/project-note-dialog.component';
import { ProjectMapDialogComponent } from './project-general-info-dialogs/project-map-dialog/project-map-dialog.component';
import { ProjectWorkflowService } from './services/project-workflow/project-workflow.service';
import { ProjectActionService } from './services/project-action/project-action.service';
import { ProjectTimelineDialogComponent } from './project-general-info-dialogs/project-timeline-dialog/project-timeline-dialog.component';
import { ProjectStockLogDialogComponent } from './project-general-info-dialogs/project-stock-log-dialog/project-stock-log-dialog.component';
import { AddStockLogDialogComponent } from './project-details-action-bar/add-stock-log-dialog/add-stock-log-dialog.component';
import { WorkCategory } from '../../../core/data-models/work-category';

@Component({
    selector: 'project-details',
    styleUrls: ['./project-details.component.scss'],
    templateUrl: './project-details.component.html'
})
export class ProjectDetailsComponent implements OnInit {
    @Input() public activeProjectId = '';
    public readonly projectDetailsSection = ProjectDetailsSection;
    public activeSection: ProjectDetailsSection;
    private _generalInfo: ProjectGeneralInfo = null;
    private _currentJob = 0;
    private _isActionLoading = false;
    private _isContentLoading = false;
    private _dialogOptions: ActionMenuItemOption[] = [];
    // eslint-disable-next-line
    constructor(private _projectHttp: ProjectHttpService,
                private _dispatchService: ProjectDetailsDispatchService,
                private _workflowService: ProjectWorkflowService,
                private _actionService: ProjectActionService,
                private _appEvents: AppEventService,
                private _permissionService: UserPermissionService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _state: StateService,
                private _reportService: ReportHttpService,
                private _downloadService: DownloadHttpService,
                private _translate: TranslateService,
                private _salesCodeConfig: SalesCodeConfigService,
                private _featureFlagService: FeatureFlagService,
                private _newrelicService: NewRelicUtilityService,
                private _invoiceHttp: InvoiceHttpService,
                private _customReportTemplateService: CustomReportTemplateHttpService) { }

    get generalInfo(): ProjectGeneralInfo {
        return this._generalInfo;
    }

    get dialogOptions(): ActionMenuItemOption[] {
        return this._dialogOptions;
    }

    get isActionLoading(): boolean {
        return this._isActionLoading;
    }

    get isContentLoading(): boolean {
        return this._isContentLoading;
    }

    get currentJob(): number {
        return this._currentJob;
    }

    get isLeakReport(): boolean {
        const { jobs } = this._generalInfo;
        const index = this._currentJob === -1 ? jobs.length - 1 : this._currentJob;

        return this._salesCodeConfig.isLeakCall(jobs[index].salesCode);
    }

    get isInternalBuild(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    // Temporary: See Aha FSW-3056 - We want to remove 'isSubcontract' etc in favour of configurable flags from the backend.
    get isSubcontract(): boolean {
        return this.generalInfo.workCategory === WorkCategory.Subcontract;
    }

    public async ngOnInit(): Promise<void> {
        const timelineLabel = this._translate.instant('projects.detailsSection.actionItems.timeline');
        const notesLabel = this._translate.instant('projects.detailsSection.actionItems.notes');
        const reviewLabel = this._translate.instant('projects.detailsSection.actionItems.review');
        const projectMapLabel = this._translate.instant('projects.detailsSection.actionItems.projectMap');
        const showReview = await this._permissionService.hasReadPermission(ReadPermission.ReadProjectReview);
        const showComments = await this._permissionService.hasReadPermission(ReadPermission.ReadComments);

        this._dialogOptions = [
            new ActionMenuItemOption('Timeline', 'timeline', false, this.openTimeline.bind(this), timelineLabel),
            showComments ? new ActionMenuItemOption('Notes', 'notes', false, this.openNotes.bind(this), notesLabel) : null,
            showReview ? new ActionMenuItemOption('Review', 'star', false, this.openReview.bind(this), reviewLabel) : null,
            new ActionMenuItemOption('Project Map', 'place', false, this.openMap.bind(this), projectMapLabel)
        ].filter(Boolean);

        await this.onJobSelect(-1);
        await this.checkProjectHasNotes();
    }

    public async onWorkflowSelect(workflow: string): Promise<void> {
        if (!await this._workflowService.handleWorkflow(workflow, this._generalInfo)) {
            return;
        }

        await this.reloadDetails();
        if (this.isInternalBuild && workflow === ProjectWorkflow.Process) {
            this._appEvents.setReloadTabs();
        }
    }

    public async onActionSelect(action: string): Promise<void> {
        this._newrelicService.addPageAction('', '', 'ProjectDetails', { ActionSelected: action });
        if (!await this._actionService.handleAction(action, this._generalInfo, { jobId: this._currentJob })) {
            return;
        }

        if (action === ProjectAction.DeleteProject) {
            this._state.go('projects');
        }
        else if (action === ProjectAction.CreateFollowUpQuote) {
            this._appEvents.setReloadProjectCounter();
        }
        else if (
            action !== ProjectAction.SwapProjectTo312 &&
            action !== ProjectAction.SwapProjectTo315 &&
            action !== ProjectAction.MergeProject &&
            action !== ProjectAction.ChangeServiceProvider) {
            await this.reloadDetails();
        }
    }

    public async onDispatchProject(dispatchOption: ProjectDispatchOptions): Promise<void> {
        const dispatchExistingOptions = await this._projectHttp.getDispatchExistingOptions(this.activeProjectId);

        if (await this._dispatchService.handleDispatchEvent(dispatchOption, dispatchExistingOptions)) {
            await this.reloadDetails();
        }
    }

    public async onPrintOptionSelect(action: ProjectPrint): Promise<void> {
        this._newrelicService.addPageAction('', '', 'ProjectDetails', { PrintOption: action });

        if (action === ProjectPrint.ConditionReport) {
            await this.onConditionReportSelect();
        }
        else if (action === ProjectPrint.WorkOrderReport) {
            await this.onWorkOrderReportSelect();
        }
        else if (action === ProjectPrint.SafetyReport) {
            await this.onSafetyReportSelect();
        }
        else if (action === ProjectPrint.FieldReport) {
            await this.onFieldReportSelect(false);
        }
        else if (action === ProjectPrint.CustomerFieldReport) {
            await this.onFieldReportSelect(true);
        }
        else if (action === ProjectPrint.Covid19AssessmentReport) {
            await this.onCovid19AssessmentReport();
        }
    }

    public async reloadDetails(): Promise<void> {
        this._isActionLoading = true;
        await this.onJobSelect(this._currentJob);
        this.onSectionSelect(ProjectDetailsSection.BasicInfo);
        this._isActionLoading = false;
    }

    public onSectionSelect(section: ProjectDetailsSection): void {
        this.activeSection = section;
        this._state.go('.', { section });
    }

    public openSectionInNewWindow(section: ProjectDetailsSection): void {
        const url = this._state.href('.', { section }, { absolute: true });
        window.open(url, '_blank');
    }

    public async onJobSelect(job: number): Promise<void> {
        this._isContentLoading = true;
        this._generalInfo = await this._projectHttp.getProjectGeneralInfo(this.activeProjectId, job);
        this._currentJob = job;
        this._isContentLoading = false;

        if (this._generalInfo) {
            this.checkDialogOptions();
        }
        else {
            this._state.go('projects.notFound');
        }
    }

    private checkDialogOptions(): void {
        const { code } = this._generalInfo.status;
        const { projectId } = this._generalInfo.basicInfo;
        const stockLogIsNotAlreadyShowing = this._dialogOptions.every(_ => _.text !== 'Stock Log');

        if (this.isInternalBuild && code === 4 && this._salesCodeConfig.isLeakCall(projectId) && stockLogIsNotAlreadyShowing) {
            const callback = this.openStockLog.bind(this);
            const stockLogLabel = this._translate.instant('projects.detailsSection.actionItems.stockLog');
            this._dialogOptions.push(new ActionMenuItemOption('Stock Log', 'construction', false, callback, stockLogLabel));
        }
    }

    public openTimeline(): void {
        this._newrelicService.addPageAction('', '', 'ProjectDetails', { DialogSelected: 'timeline' });

        this._dialog.open(ProjectTimelineDialogComponent, {
            data: this._generalInfo.basicInfo.projectId,
            minWidth: '600px',
            minHeight: '650px',
            width: '50vw',
            height: '70vh'
        });
    }

    public async openNotes(): Promise<void> {
        this._newrelicService.addPageAction('', '', 'ProjectDetails', { DialogSelected: 'notes' });

        const dialog = this._dialog.open(ProjectNoteDialogComponent, {
            data: this._generalInfo.basicInfo.projectId,
            width: '60vw',
            height: '80vh'
        });

        await dialog.afterClosed().toPromise();
        await this.checkProjectHasNotes();
    }

    public async openReview(): Promise<void> {
        const dialog = this._dialog.open(ProjectReviewDialogComponent, {
            data: this._generalInfo,
            width: '50vw',
            height: '90vh'
        });

        if (await dialog.afterClosed().toPromise<boolean>()) {
            await this.reloadDetails();
        }
    }

    public openMap(): void {
        this._newrelicService.addPageAction('', '', 'ProjectDetails', { DialogSelected: 'project-map' });

        this._dialog.open(ProjectMapDialogComponent, {
            data: this._generalInfo.basicInfo.projectId,
            width: '1000px',
            height: '700px'
        });
    }

    public async openStockLog(): Promise<void> {
        this._newrelicService.addPageAction('', '', 'ProjectDetails', { DialogSelected: 'stock-log' });
        const { projectId, projectName } = this.generalInfo.basicInfo;
        const stockLog = await this._invoiceHttp.getStockLog(projectId);

        if (!stockLog) {
            this._dialog.open(AddStockLogDialogComponent, {
                data: { projectId, projectName } as StockLogDialogOptions,
                width: '440px',
                height: '600px'
            });
        }
        else {
            this._dialog.open(ProjectStockLogDialogComponent, {
                data: new StockLogDialogOptions(projectId, projectName),
                width: '440px',
                height: '600px'
            });
        }
    }

    public async onReportingContactUpdate({ key, value }: KeyValuePair): Promise<void> {
        const keys = new Map([
            ['name', 'reportingContact'],
            ['email', 'reportingEmail'],
            ['officePhone', 'reportingOfficePhone'],
            ['mobilePhone', 'reportingMobilePhone']
        ]);

        await this.updateGeneralInfo(this._generalInfo.reportingContact, key, keys.get(key) ?? key, value);
    }

    public async updateGeneralInfo<T>(host: T, originalKey: string, mappedKey: string, value: string): Promise<void> {
        const pageActionData = { OriginalKey: originalKey, MappedKey: mappedKey, Value: value };
        this._newrelicService.addPageAction('UpdateType', 'GeneralInfo', 'ProjectDetails', pageActionData);
        const { projectId } = this._generalInfo.basicInfo;
        const originalValue = host[originalKey];
        host[originalKey] = value;

        if (originalKey === 'workOrder') {
            await this.onUpdateWorkOrder();
        }
        else if (!await this._projectHttp.updateProjectGeneralInfo(projectId, { key: mappedKey, value })) {
            // eslint-disable-next-line
            host[originalKey] = originalValue;
            const failedToUpdateField = this._translate.instant('projects.details.failedToUpdateField');
            const ok = this._translate.instant('snackbars.ok');
            this._snackBar.open(failedToUpdateField, ok);
        }
    }

    private async onDispatcherNote<T>(host: T, { key, value }: KeyValuePair): Promise<void> {
        const { jobs } = this._generalInfo;
        const index = this._currentJob === -1 ? jobs.length - 1 : this._currentJob;
        const config = new WorkOrderStepConfig('Dispatcher Note');
        config.jobType = jobs[index].salesCode;
        config.workOrderString = this._generalInfo.dispatchInfo.dispatcherNote.value;
        config.workCheckList = [];
        config.nextButtonLabel = 'Save';
        config.hasPrevious = false;
        const dialog = this._dialog.open(WorkOrderEditDialogComponent, {
            data: config,
            width: '1300px',
            height: '800px'
        });
        const result = await dialog.afterClosed().toPromise();
        if (!result) {
            return;
        }

        this._newrelicService.addPageAction('UpdateType', 'TemplateField', 'ProjectDetails', { OriginalKey: key, Value: value });
        const { projectId } = this._generalInfo.basicInfo;
        const meta = host[key];
        meta.value = result.workOrderString;
        const action = this._projectHttp.updateProjectTemplateField(projectId, meta);
        const error = this._translate.instant('projects.details.failedToUpdateField');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const pleaseWait = this._translate.instant('shared.pleaseWait');
        const actionDialog = this._dialog.open(ActionProgressDialogComponent, {
            data: new ActionProgressReporterOption(action, pleaseWait, '', error, ok, gotIt, false),
            width: '320px',
            height: '120px'
        });

        if (await actionDialog.afterClosed().toPromise()) {
            await this.reloadDetails();
        }
    }

    public async updateTemplateField<T>(host: T, { key, value }: KeyValuePair): Promise<void> {
        if (key === 'dispatcherNote') {
            await this.onDispatcherNote(host, { key, value } as KeyValuePair);
        }
        else {
            this._newrelicService.addPageAction('UpdateType', 'TemplateField', 'ProjectDetails', { OriginalKey: key, Value: value });
            const { projectId } = this._generalInfo.basicInfo;
            const meta = host[key];
            const originalValue = meta.value;
            meta.value = value;
            if (!await this._projectHttp.updateProjectTemplateField(projectId, meta)) {
                meta.value = originalValue;
                const failedToUpdateField = this._translate.instant('projects.details.failedToUpdateField');
                const ok = this._translate.instant('snackbars.ok');
                this._snackBar.open(failedToUpdateField, ok);
            }
        }
    }

    private async onConditionReportSelect(): Promise<void> {
        const blob = this.isInternalBuild? await this.getInternalConditionReport() : await this.getExternalConditionReport();
        if (blob === null) {
            const failedToGenerateConditionReport = this._translate.instant('projects.details.failedToGenerateConditionReport');
            const close = this._translate.instant('snackbars.close');
            this._snackBar.open(failedToGenerateConditionReport, close);
        }
        else if (blob) {
            this._downloadService.downloadBlob(blob, `ConditionReport_${this.activeProjectId}.pdf`);
        }
    }

    private async onWorkOrderReportSelect(): Promise<void> {
        const blob = await this._reportService.getWorkOrderPdf(this.activeProjectId);
        if (!blob) {

            const failedToGenerateWorkOrderReport = this._translate.instant('projects.details.failedToGenerateWorkOrderReport');
            const close = this._translate.instant('snackbars.close');
            this._snackBar.open(failedToGenerateWorkOrderReport, close);
            return;
        }

        this._downloadService.downloadBlob(blob, GenericUtilityService.getOrderNameByProjectBasicInfo(this._generalInfo.basicInfo));
    }

    private async onSafetyReportSelect(): Promise<void> {
        await this.printHtmlReport(this._reportService.getSafetyReport(this.activeProjectId, this.currentJob),
            this._translate.instant('projects.details.failedToGenerateSafetyReport'));
    }

    private async onFieldReportSelect(isCustomer: boolean): Promise<void> {
        const type = isCustomer? 'customer' : 'general';
        const promise = this._reportService.getFieldReportContent(this.activeProjectId, this.currentJob, type);
        const key = isCustomer ? 'failedToGenerateCustomer' : 'failedToGenerateFieldReport';
        const message = this._translate.instant(`projects.details.${key}`);
        await this.printHtmlReport(promise, message);
    }

    private async onCovid19AssessmentReport(): Promise<void> {
        await this.printHtmlReport(this._reportService.getCovid19Content(this.activeProjectId, this.currentJob),
            this._translate.instant('projects.details.failedToGenerateCovid19Report'));
    }

    private async printHtmlReport(contentPromise: Promise<string>, errorMessage: string): Promise<void> {
        const content = await contentPromise;
        if (!content) {

            const close = this._translate.instant('snackbars.close');
            this._snackBar.open(errorMessage, close);
            return;
        }

        GenericUtilityService.printHtmlContent(content);
    }

    private async checkProjectHasNotes(): Promise<void> {
        const option = this._dialogOptions.find(_ => _.text === 'Notes');

        if (option) {
            const { note } = await this._projectHttp.getProjectCounters(this.activeProjectId);
            option.isWarning = note > 0;
            this._dialogOptions = [...this._dialogOptions];
        }
    }

    private async onUpdateWorkOrder(): Promise<void> {
        const { jobs } = this._generalInfo;
        const index = this._currentJob === -1 ? jobs.length - 1 : this._currentJob;
        const config = new WorkOrderStepConfig('Work Order');
        config.jobType = jobs[index].salesCode;
        config.workOrderString = this._generalInfo.basicInfo.workOrder;
        config.workCheckList = [...this._generalInfo.basicInfo.workCheckList];
        config.nextButtonLabel = 'Save';
        config.hasPrevious = false;
        const dialog = this._dialog.open(WorkOrderEditDialogComponent, {
            data: config,
            width: '1300px',
            height: '800px'
        });
        const result = await dialog.afterClosed().toPromise();
        if (!result) {
            return;
        }

        const data = {
            workOrder: result.workOrderString,
            checkList: result.workCheckList
        } as WorkOrderUpdateDto;
        const action = this._projectHttp.updateWorkOrder(this._generalInfo.basicInfo.projectId, index, data);
        const error = this._translate.instant('projects.details.failedToUpdateWorkOrder');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const pleaseWait = this._translate.instant('shared.pleaseWait');
        const actionDialog = this._dialog.open(ActionProgressDialogComponent, {
            data: new ActionProgressReporterOption(action, pleaseWait, '', error, ok, gotIt, false),
            width: '320px',
            height: '120px'
        });

        if (await actionDialog.afterClosed().toPromise()) {
            await this.reloadDetails();
        }
    }

    private async getInternalConditionReport(): Promise<Blob | false> {
        const options = await this._reportService.getConditionReportInitialOptions(this.activeProjectId);
        if (!options) {
            const failedToGetProjectSectionDetails = this._translate.instant('projects.details.failedToGetProjectSectionDetails');
            const close = this._translate.instant('snackbars.close');
            this._snackBar.open(failedToGetProjectSectionDetails, close);
            return false;
        }

        const dialog = this._dialog.open(ConditionReportOptionsDialogComponent, {
            data: options,
            minWidth: '800px',
            height: '650px',
            maxWidth: '80vw',
            maxHeight: '80vh'
        });

        const confirmedOptions = await dialog.afterClosed().toPromise();
        if (!confirmedOptions) {
            return false;
        }

        return await this._reportService.getConditionReportPdf(confirmedOptions);
    }

    private async getExternalConditionReport(): Promise<Blob | false> {
        const dialog = this._dialog.open(CustomReportPrintDialogComponent, {
            data: this.activeProjectId,
            width: '800px',
            height: '650px'
        });

        const confirmedOptions = await dialog.afterClosed().toPromise();
        if (!confirmedOptions) {
            return false;
        }

        return await this._customReportTemplateService.getCustomReportPdf(confirmedOptions);
    }
}
