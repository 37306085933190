import ProjectRequest from './project-request';

export default class ProjectRequestViewDto {
    public name: string;
    public customerId: string;
    public projectId: string;
    public createdDate: string;
    public address1: string;
    public address2: string;
    public city: string;
    public state: string;
    public zip: string;
    public country: string;

    public constructor(projectRequest: ProjectRequest) {
        this.name = projectRequest.generalDispatchInfo.name;
        this.customerId = projectRequest.customerId;
        this.projectId = projectRequest.projectId;
        this.createdDate = projectRequest.timeInfo.createdTime;
        this.address1 = projectRequest.selectedCustomer.site.address1;
        this.address2 = projectRequest.selectedCustomer.site.address2;
        this.city = projectRequest.selectedCustomer.site.city;
        this.state = projectRequest.selectedCustomer.site.state;
        this.zip = projectRequest.selectedCustomer.site.zip;
        this.country = projectRequest.selectedCustomer.site.country;
    }
}
