export enum FeatureFlags {
    UseInternalFeatures = 'use-internal-features',
    UseLegacyCustomerCreationTool = 'use-legacy-customer-creation-tool',
    Covid19Report = 'covid-19-report-print',
    UseQuotesFeature = 'use-quotes-feature',
    UseReportsFeature = 'use-reports-feature',
    UseKpiFeature = 'use-kpi-feature',
    UseBudgetFeature = 'use-budget-feature',
    UseSafetyCheckFeature = 'use-safety-check-feature',
    UseResidentialProjectAttribute = 'show-residential-project-attribute',
    UseWorkOrderTemplates = 'use-work-order-template',
    ShowAboutPage = 'show-about-page',
    ShowExportInAvantiFormatButton = 'show-export-in-avanti-format-button',
    AllowCreatingSubcontracts = 'allow-creating-subcontracts',
    AllowSyncCustomerFromErp = 'allow-sync-customer-from-erp',
    ShowScheduleBoard = 'show-schedule-board',
    AllowSwappingInactiveSites = 'allow-swapping-inactive-sites',
    AllowRestoringProject = 'allow-restoring-projects'
}

export enum ApiFeatureFlags {
    UseLegacyBudgetTool = 'use-legacy-budget-tool'
}
