import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { ConfirmationPromptOptions } from '../../../../core/data-models/options/confirmation-prompt-options';

@Component({
    selector: 'app-confirmation-prompt',
    styleUrls: ['./confirmation-prompt.component.scss'],
    templateUrl: './confirmation-prompt.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationPromptComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public options: ConfirmationPromptOptions,
                private _dialogRef: MatDialogRef<ConfirmationPromptComponent>) { }

    get colorType(): string {
        return this.options.isWarning ? 'warn' : 'primary';
    }

    public ngOnInit(): void {
        this._dialogRef.disableClose = true;
        this._dialogRef.addPanelClass('confirmation-prompt');
    }
}
