<div class="data-migration-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Data Migration Tool</mat-card-title>
            <mat-card-subtitle>This tool should be used to migrate config between environments</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <form #migrateForm="ngForm" (ngSubmit)="migrateData()">
                <mat-grid-list cols="4" rowHeight="100px">
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Environment</mat-label>
                            <mat-select [(ngModel)]="sourceEnv" required name="sourceEnvSelect">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let env of environments" [value]="env">{{env}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Migration Option</mat-label>
                            <mat-select [(ngModel)]="migrationOption" required name="migrationOptionSelect">
                                <mat-option *ngFor="let moption of migrationOptions" [value]="moption.Value">
                                    {{moption.Text}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field *ngIf="migrationOption === 'reportTemplate'">
                            <mat-label>Job Type</mat-label>
                            <mat-select [(ngModel)]="jobType" name="jobTypeSelect">
                                <mat-option *ngFor="let job of jobTypes" [value]="job.Code">{{job.Desc}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <button type="submit" mat-raised-button color="primary"
                            [disabled]="migrateForm.invalid">Migrate</button>
                    </mat-grid-tile>
                </mat-grid-list>
            </form>
        </mat-card-content>
    </mat-card>
</div>
