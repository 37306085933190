<dialog-action-card class="holiday-edit-dialog-container"
    [icon]="'nightlife'"
    [title]="dialogTitle"
    [isLoaded]="true"
    (close)="onClose()">

    <div fxLayout="column" fxLayoutGap="10px" fxFill>
        <div fxLayout="row" fxLayoutGap="10px" class="holiday-dialog-main-content" fxFlex>
            <div fxLayout="column" fxLayoutAlign="center center">
                <mat-form-field>
                    <mat-label>{{"admin.kpiSetting.editHolidayDialog.name" | translate}}</mat-label>
                    <input matInput [(ngModel)]="newHolidayName">
                </mat-form-field>
                <!--date picker-->
                <mat-form-field appearance="standard">
                    <mat-label>{{"admin.kpiSetting.editHolidayDialog.date" | translate}}</mat-label>
                    <input matInput [matDatepicker]="picker"
                        [value]="pickedDate"
                        (dateChange)="onDatePickerValueChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center">
                <label>{{"admin.kpiSetting.editHolidayDialog.stateSelection" | translate}}</label>

                <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="10px">
                    <mat-checkbox [(ngModel)]="allCanada"
                        (change)="selectAllCanada()">
                        {{"admin.kpiSetting.editHolidayDialog.allCanada" | translate}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="allUS"
                        (change)="selectAllUS()">
                        {{"admin.kpiSetting.editHolidayDialog.allUs" | translate}}
                    </mat-checkbox>
                </div>
                <!-- list of states-->
                <ul class="holiday-state-cb-wrapper">
                    <li class="holiday-state-cb" *ngFor="let state of states">
                        <mat-checkbox [(ngModel)]="holidayStates[state]"
                            (change)="stateChange(state)">
                            {{ state }}
                        </mat-checkbox>
                    </li>
                </ul>
            </div>
        </div>

        <div fxLayout="row" class="holiday-dialog-footer">
            <button mat-flat-button (click)="delete()" *ngIf="updateDialog" color="warn">
                {{"admin.kpiSetting.editHolidayDialog.deleteButton" | translate}}
            </button>

            <div fxFlex></div>

            <button mat-flat-button (click)="addHoliday()" color="primary">
                {{ confirmButton }}
            </button>
        </div>
    </div>
</dialog-action-card>
