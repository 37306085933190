import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import ProjectRequest from '../../../data-models/integration/project-request';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class IntegrationHttpService {

    private readonly _api = `${environment.fswApiUrl}api2/service/integration`;

    constructor(private _http: HttpClient) { }

    public async getPendingProjectRequests() : Promise<ProjectRequest[]> {
        try {
            const endpoint = `${this._api}/pending-project-requests`;
            const data = await this._http.get<ProjectRequest[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<ProjectRequest>();
        }
    }

    public async cancelInBoundRequest(projectId: string, customerId: string) : Promise<boolean> {
        try {
            const endpoint = `${this._api}/cancel/${projectId}/${customerId}`;
            return await this._http.post<boolean>(endpoint, {}).toPromise();
        }
        catch {
            return false;
        }
    }
}
