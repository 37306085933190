<div *ngIf="form && selectedRates" class="service-operations-standardized-estimation-container">
    <mat-horizontal-stepper class="content" #stepper linear labelPosition="bottom">
        <mat-step *ngIf="leadQuestionsArray.length" [label]="translate.instant('flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.steps.leadQuestions.title')" [completed]="isLeadQuestionCompleted">
            <app-questions-section [form]="form" [readonly]="isReadOnly"></app-questions-section>
        </mat-step>

        <mat-step [label]="translate.instant('flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.steps.project.title')" [completed]="isProjectSectionCompleted">
            <app-project-section [form]="form" [branches]="activeBranches" [projectTypes]="selectedRates.projectTypes" [readonly]="isReadOnly"></app-project-section>
        </mat-step>

        <mat-step *ngIf="laborSection.enabled" [label]="translate.instant('flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.steps.labour')" [stepControl]="laborSection">
            <app-labor-section [form]="form" [calculationUpdated$]="calculationUpdated$" [readonly]="isReadOnly"></app-labor-section>
        </mat-step>

        <mat-step [label]="translate.instant('flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.steps.materials')" [stepControl]="materialsSection">
            <app-materials-section [form]="form" [materialRates]="selectedRates.materialRates" [calculationUpdated$]="calculationUpdated$" [readonly]="isReadOnly"></app-materials-section>
        </mat-step>

        <mat-step [label]="translate.instant('flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.steps.miscellaneous')" [stepControl]="miscellaneousSection">
            <app-miscellaneous-section [form]="form" [miscellaneousItemTypes]="selectedRates.miscellaneousItemTypes" [calculationUpdated$]="calculationUpdated$" [readonly]="isReadOnly"></app-miscellaneous-section>
        </mat-step>

        <mat-step [label]="translate.instant('flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.steps.qualityControlFees')" [stepControl]="qualityControlFeesSection">
            <app-quality-control-fees-section [form]="form" [percentageRates]="selectedRates.qualityControlFeesPercentageRates" [readonly]="isReadOnly"></app-quality-control-fees-section>
        </mat-step>

        <mat-step [label]="translate.instant('flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.steps.summary.title')" [stepControl]="summarySection">
            <app-summary-section [form]="form" [branchServiceRate]="branchServiceRate" [calculationUpdated$]="calculationUpdated$" *ngIf="branchServiceRate && form.get('projectType')?.value" [readonly]="isReadOnly"></app-summary-section>
        </mat-step>
    </mat-horizontal-stepper>

    <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2.5vh">
        <button mat-raised-button color="secondary" (click)="viewServiceRates()">{{ 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.viewServiceRates' | translate}}</button>
        <mat-form-field *ngIf="!isExisting">
            <mat-label>{{ 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.rateVersions' | translate }}</mat-label>

            <mat-select [(value)]="selectedRatesQuery" (valueChange)="onSelectedRatesChange($event)">
                <mat-option *ngFor="let query of ratesQueries" [value]="query">
                    {{ query.version }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <span *ngIf="isExisting" class="selected-version">{{ 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.rateVersions' | translate }}: {{ selectedRatesQuery.version }}</span>
        <button *ngIf="!isReadOnly && isExisting" mat-raised-button color="warn" [disabled]="isSaving" (click)="onDelete()">{{ 'flynnBudgetTool.shared.deleteDraft' | translate }}</button>
        <button *ngIf="!isReadOnly && recordId" mat-raised-button color="primary" [disabled]="form.invalid || isSaving" (click)="onSave()">{{ 'flynnBudgetTool.shared.saveDraft' | translate }}</button>
        <button *ngIf="!isReadOnly && !isQuote" mat-raised-button color="primary" [disabled]="form.invalid || isSaving || !canFinalize" (click)="onFinalize()">{{ 'flynnBudgetTool.shared.submitToDynamics' | translate }}</button>
    </div>
</div>
