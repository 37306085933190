<div class="merge-rule-editor-container" fxLayout="column" fxLayoutGap="15px">
    <div class="merge-rule-editor-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <button mat-icon-button color="primary" (click)="onClose()">
            <mat-icon>arrow_back_ios_new</mat-icon>
        </button>

        <div class="rule-name" fxLayout="row" fxLayoutAlign="center center">
            <span>{{ ruleName }}</span>
        </div>
    </div>

    <div class="merge-rule-editor-actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2vh">
        <mat-form-field class="merge-rule-editor-filter" appearance="outline">
            <mat-label>{{ 'admin.projectMergeRules.mergeRuleEditor.sourceSalesCodeFilterLabel' | translate }}</mat-label>

            <mat-select [(ngModel)]="sourceFilter">
                <mat-option [value]="''">{{ 'shared.all' | translate }}</mat-option>

                <mat-option *ngFor="let salesCode of salesCodes" [value]="salesCode.code">
                    {{ salesCode.code }} - {{ getSalesCodeName(salesCode) }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-mini-fab
            color="primary"
            [disabled]="!visibleMappings.length || !hasValidMappings"
            (click)="toggleSorting()">

            <mat-icon [ngClass]="{ 'merge-rule-sort-icon-ascending': isAscending }">sort</mat-icon>
        </button>

        <button mat-raised-button
            color="primary"
            [disabled]="savingState.isSaving || savingState.isSaved || !hasValidMappings"
            (click)="onSave()">

            {{ 'admin.projectMergeRules.mergeRuleEditor.saveButtonText' | translate }}
        </button>
    </div>

    <div class="merge-rule-mappings" fxLayout="row wrap" fxLayoutAlign="space-between">
        <merge-rule-mapping-card *ngFor="let mapping of visibleMappings; let i = index"
            class="merge-rule-mapping"
            [mapping]="mapping"
            [allMappings]="rule.mappings"
            [sourceSalesCodes]="sourceSalesCodes"
            [allSalesCodes]="salesCodes"
            [isDisabled]="!hasValidMappings && mapping.source && mapping.target"
            (update)="onMappingChange($event)"
            (delete)="onMappingDelete($event)">
        </merge-rule-mapping-card>

        <div *ngFor="let placeholder of placeholders; let i = index"
            [ngClass]="{ 'merge-rule-mapping-active-placeholder': !i && canAddMapping }"
            class="merge-rule-mapping-placeholder"
            fxLayout="row"
            fxLayoutAlign="center center"
            (click)="i ? null : onMappingSetup()">

            <mat-icon *ngIf="!i && canAddMapping">add_circle</mat-icon>
        </div>
        <!-- dummy card to make all placeholders left aligned -->
        <div *ngIf="placeholders.length === 1 && visibleMappings.length % 3 === 1" class="merge-rule-mapping"></div>
    </div>
</div>
