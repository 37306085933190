import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'name-prompt-dialog',
    styleUrls: ['./name-prompt-dialog.component.scss'],
    templateUrl: './name-prompt-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NamePromptDialogComponent {

    public errorMessage: string = null;
    public name = new UntypedFormControl('', [Validators.required, this.isNonEmpty]);
    public title = '';

    private callback: (string) => Promise<boolean> = null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    constructor(private _dialog: MatDialogRef<NamePromptDialogComponent>, @Inject(MAT_DIALOG_DATA) public parms: any, private _changeDetector: ChangeDetectorRef) {
        if (typeof parms === 'string') {
            this.title = parms;
        }
        else {
            this.title = parms.title;
            this.callback = parms.callback;
            this.errorMessage = parms.errorMessage;
        }
    }

    private isNonEmpty(control: UntypedFormControl): null | { [key: string]: boolean } {
        return control.value?.trim() ? null : { empty: true };
    }

    public async onClose(emit = false): Promise<void> {
        if (!await this.callbackSucceeded()) {
            this.name.setErrors({ callbackError: true });
            this._changeDetector.detectChanges();
            return;
        }

        if (!emit || this.name.valid) {
            const name = this.name.value.trim();
            this._dialog.close(emit ? name : null);
        }
        else {
            this.name.markAsTouched();
        }
    }

    private async callbackSucceeded(): Promise<boolean> {
        if (this.name.valid && this.callback) {
            return await this.callback(this.name.value.trim());
        }

        return true;
    }
}
