import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../../shared/shared.module';

import { PlaceholderListComponent } from './placeholder-list/placeholder-list.component';
import { ReportTemplateEditorComponent } from './report-template-editor/report-template-editor.component';
import { ReportTemplatePreviewDialogComponent } from './report-template-preview-dialog/report-template-preview-dialog.component';
import { ReportTemplateComponent } from './report-template.component';
import { states } from './report-template.routing';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states }),
        SharedModule
    ],
    declarations: [
        ReportTemplateComponent,
        ReportTemplateEditorComponent,
        ReportTemplatePreviewDialogComponent,
        PlaceholderListComponent
    ]
})
export class ReportTemplateModule { }
