import CustomerDto from '../customer-dto';

export default class CustomerDisplaySummary {

    customerId: string;
    name: string;
    address: string;
    numberOfNewSites: string;
    phone: string;
    faxNumber: string;
    currency: string;
    dataUniversalNumberingSystemNumber: string;
    proposedBusiness: string;
    customerClass: string;

    public createCustomerDisplaySummary(customer: CustomerDto): void {
        this.name = customer.name;
        this.customerId = customer.customerId;
        this.phone = customer.phone;
        this.faxNumber = customer.faxNumber;
        this.currency = customer.currency;
        this.dataUniversalNumberingSystemNumber = customer.dataUniversalNumberingSystemNumber;
        this.proposedBusiness = customer.proposedBusiness;
        this.customerClass = customer.customerClass;

        let address2 = ',';
        if (customer.address2 && customer.address2 !== '') {
            address2 = ` ${customer.address2},`;
        }

        this.address = `${customer.address1}${address2} ${customer.city},`+
                `${customer.country}, ${customer.state}, ${customer.zip}`;

    }
}
