<dialog-action-card
    [icon]="'print'"
    [title]="translate.instant('shared.quote.quoteCopyOptions.title')"
    [isLoaded]="true"
    (close)="onClose(false)">

    <div class="quote-copy-options-container" fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="column" fxLayoutGap="10px">
        <div>
          <mat-checkbox
            [(ngModel)]="quoteCopyOptions.useParentsAttachments">
            {{ 'shared.quote.quoteCopyOptions.useParentAttachments' | translate }}
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox
            [(ngModel)]="quoteCopyOptions.useParentsCustomer">
            {{ 'shared.quote.quoteCopyOptions.useSameInformation' | translate }}
          </mat-checkbox>
        </div>
      </div>
    <div fxLayoutAlign="end end">
      <button mat-raised-button color="primary" (click)="onClose(true)">
        {{ 'shared.submit' | translate }}
      </button>
    </div>
  </div>
</dialog-action-card>