/* eslint-disable max-len */
import { Ng2StateDeclaration, Transition, StateService } from '@uirouter/angular';

import BranchTimezone from '../../core/data-models/branch-timezone';
import { BranchHttpService } from '../../core/services/http/branch-http/branch-http.service';
import { ProjectHttpService } from '../../core/services/http/project-http/project-http.service';
import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import ProjectIdUtility from '../../core/services/utility/project-id-utility/project-id-utility.service';
import { ReadPermission } from '../../core/enums/read-permission.enum';
import { PhotoReportViewerComponent } from '../../shared/components/photo-report-viewer/photo-report-viewer/photo-report-viewer.component';
import { DrawingPanelComponent } from '../../shared/components/drawing-panel/drawing-panel.component';
import { ContentNotFoundComponent } from '../../shared/components/errors/content-not-found/content-not-found.component';
import { globalResolver } from '../../core/configs/routing/global-resolver';
import { ProjectDetailsSection } from '../../core/enums/project-details-section.enum';
import { BudgetManagementContainerComponent } from '../../shared/components/budget-management-container/budget-management-container.component';
import { SalesCodeConfigService } from '../../core/services/sales-code-config/sales-code-config.service';
import BudgetManagementPayload from '../../core/data-models/budget-management-payload';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';

import { ProjectComponent } from './project.component';
import { ProjectSafetyComponent } from './project-safety/project-safety.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectAttachmentsComponent } from './project-attachments/project-attachments.component';
import { ProjectInvoiceComponent } from './project-invoice/project-invoice.component';
import { ProjectAttributesComponent } from './project-attributes/project-attributes.component';
import { ProjectQuotesComponent } from './project-quotes/project-quotes.component';
import { ProjectHistoryComponent } from './project-history/project-history.component';
import { ProjectExecutiveSummaryComponent } from './project-executive-summary/project-executive-summary.component';
import { VideoViewerComponent } from '../../shared/components/video-viewer/video-viewer.component';

const resolve = [
    {
        token: 'hasAccess',
        deps: [UserPermissionService, StateService],
        resolveFn: async(_userPermissionService: UserPermissionService, _stateService: StateService): Promise<void> => {

            if (!await _userPermissionService.hasReadPermission(ReadPermission.ViewProjects)) {
                _stateService.transition.abort();
                await _stateService.go('pageNotFound');
            }
        }
    },
    {
        token: 'activeProjectId',
        deps: [Transition],
        resolveFn: (_: Transition): string => _.params().projectId
    },
    {
        token: 'timeZone',
        deps: [BranchHttpService, ProjectHttpService, Transition],
        resolveFn:
        async(branchHttpService: BranchHttpService, projectHttpService: ProjectHttpService, transition: Transition): Promise<BranchTimezone> => {
            const branches = await branchHttpService.getAllBranches();
            const projectId = transition.params().projectId;

            if (ProjectIdUtility.isEmergency(projectId)) {
                return await projectHttpService.getProjectTimeZone(projectId);
            }
            else if (projectId) {
                const projectBranch = branches.find(_ => _.code === projectId.substr(0, 3));
                return projectBranch?.geoLocation.timeZone;
            }
            return null;
        }
    },
    ...globalResolver
];

export const states: Ng2StateDeclaration[] = [
    {
        name: 'projects',
        url: '/projects',
        component: ProjectComponent,
        resolve
    },
    {
        name: 'project',
        url: '/projects/:projectId',
        redirectTo: 'projects.details'
    },
    {
        name: 'projects.details',
        url: '/:projectId/details?section',
        component: ProjectDetailsComponent,
        params: {
            section: {
                dynamic: true,
                value: ProjectDetailsSection.BasicInfo
            }
        },
        resolve
    },
    {
        name: 'projects.attachments',
        url: '/:projectId/attachments',
        component: ProjectAttachmentsComponent,
        resolve
    },
    {
        name: 'projects.attributes',
        url: '/:projectId/attributes',
        component: ProjectAttributesComponent,
        resolve
    },
    {
        name: 'projects.quotes',
        url: '/:projectId/quotes',
        component: ProjectQuotesComponent,
        resolve
    },
    {
        name: 'projects.invoice',
        url: '/:projectId/invoice',
        component: ProjectInvoiceComponent,
        resolve
    },
    {
        name: 'projects.safety',
        url: '/:projectId/safety',
        component: ProjectSafetyComponent,
        resolve
    },
    {
        name: 'projects.photos',
        url: '/:projectId/photos',
        component: PhotoReportViewerComponent,
        resolve
    },
    {
        name: 'projects.videos',
        url: '/:projectId/videos',
        component: VideoViewerComponent,
        resolve
    },
    {
        name: 'projects.history',
        url: '/:projectId/history',
        component: ProjectHistoryComponent,
        resolve
    },
    {
        name: 'projects.executiveSummary',
        url: '/:projectId/executiveSummary',
        component: ProjectExecutiveSummaryComponent,
        resolve
    },
    {
        name: 'projects.drawings',
        url: '/:projectId/drawings',
        component: DrawingPanelComponent,
        resolve
    },
    {
        name: 'projects.notFound',
        url: '/not-found',
        component: ContentNotFoundComponent,
        resolve: [
            { token: 'title', resolveFn: (): string => 'contentNotFound.cantLocateProject' },
            { token: 'subTitle', resolveFn: (): string => 'contentNotFound.correctIdWarning' }
        ]
    },
    {
        name: 'projects.budget',
        url: '/:projectId/budget',
        component: BudgetManagementContainerComponent,
        resolve: [
            ...resolve,
            {
                token: 'payload',
                deps: [SalesCodeConfigService, FeatureFlagService, ProjectHttpService, Transition],
                resolveFn: async(salesCodeConfigService: SalesCodeConfigService, featureFlagService: FeatureFlagService, projectHttpService: ProjectHttpService, _: Transition): Promise<BudgetManagementPayload> => {
                    const projectId = _.params().projectId;
                    const customerId = await projectHttpService.getCustomerIdByProjectId(projectId);
                    const { useLegacyBudgetTool, hasExistingLegacyBudget, hasExistingWebBudget, useSubcontractRates } = await featureFlagService.evaluateUseLegacyBudgetToolByProjectId(projectId);

                    return new BudgetManagementPayload(useLegacyBudgetTool, hasExistingLegacyBudget, hasExistingWebBudget, projectId, customerId, false, useSubcontractRates);
                }
            }
        ]
    }
];
