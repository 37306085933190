/* eslint-disable no-undefined */
import { Component, Input, OnChanges, Output, QueryList, ViewChildren, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { FILTER_OPTIONS, ScheduleBoardFilterOptions, ScheduleBoardFilterValues } from '../../../core/data-models/schedule-board/schedule-board-filter';
import { SaleCode } from '../../../core/enums/schedule-board';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'schedule-board-filter',
    templateUrl: './schedule-board-filter.component.html',
    styleUrls: ['./schedule-board-filter.component.scss']
})
export class ScheduleBoardFilterComponent implements OnChanges {

  @Input() isRainyDay: boolean;
  @Input() defaultBranch: string;
  @Input() isBranchFilterable: boolean;
  @Output() filterValues = new EventEmitter<ScheduleBoardFilterValues>();
  @ViewChildren('selectFilterType') filterSelect: QueryList<MatSelect>;

  public showFilter = false;
  public filterForm: UntypedFormGroup;
  public availableFilters: ScheduleBoardFilterOptions[];
  public selectedFilters = new Set<ScheduleBoardFilterOptions>();
  public currentFilterValues;

  constructor(
    private _formBuilder: FormBuilder,
    public translate: TranslateService,
  ) {

  }

  ngOnChanges(): void {
      this.availableFilters = this.isBranchFilterable ? FILTER_OPTIONS : FILTER_OPTIONS.filter(x => x.type !== 'Branch');
      this.loadFilterForm();
  }

  private loadFilterForm() {
      // defaulting the filters to calendar view and schedule view modes
      if (this.isRainyDay) {
          this.filterForm = this.isBranchFilterable ? this._formBuilder.group({
              filters: this._formBuilder.array([
                  this._formBuilder.group({
                      type: ['Branch', Validators.required],
                      comparator: ['Equals', Validators.required],
                      value: [this.defaultBranch, Validators.required]
                  }),
                  this._formBuilder.group({
                      type: ['Sales Code', Validators.required],
                      comparator: ['Equals', Validators.required],
                      value: ['312', Validators.required]
                  })
              ])
          }) : this._formBuilder.group({
              filters: this._formBuilder.array([
                  this._formBuilder.group({
                      type: ['Sales Code', Validators.required],
                      comparator: ['Equals', Validators.required],
                      value: ['312', Validators.required]
                  })
              ])
          }) ;
      }
      else {
          this.filterForm = this.isBranchFilterable ? this._formBuilder.group({
              filters: this._formBuilder.array([
                  this._formBuilder.group({
                      type: ['Branch', Validators.required],
                      comparator: ['Equals', Validators.required],
                      value: [this.defaultBranch, Validators.required]
                  }),
                  this._formBuilder.group({
                      type: ['Sales Code', Validators.required],
                      comparator: ['Is Not', Validators.required],
                      value: ['312', Validators.required]
                  })
              ])
          }) : this._formBuilder.group({
              filters: this._formBuilder.array([
                  this._formBuilder.group({
                      type: ['Sales Code', Validators.required],
                      comparator: ['Is Not', Validators.required],
                      value: ['312', Validators.required]
                  })
              ])
          });
      }
      this.getFilters().controls.forEach(filter => this.selectedFilters.add(this.availableFilters.find(x => x.type === filter.value.type)));

      // filter should be called on init to apply default values to schedule board
      this.onSubmit();
  }

  public getFilters(): FormArray {
      return this.filterForm.get('filters') as FormArray;
  }

  public createNewFilter(): FormGroup {
      return this._formBuilder.group({
          type: ['', Validators.required],
          comparator: ['', Validators.required],
          value: ['', Validators.required]
      });
  }

  public addFilter() {
      this.getFilters().push(this.createNewFilter());
  }

  public clearAllFilters() {
      this.selectedFilters.clear();
      this.getFilters().clear();
      this.loadFilterForm();
  }

  public onToggleScheduleBoardFilter() {
      this.showFilter = !this.showFilter;
  }

  public getFilterComparatorOptionByType(filterOptionType) {
      if (!filterOptionType) {
          return [];
      }
      return FILTER_OPTIONS.find(x => x.type === filterOptionType).comparators;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public filterSelection(event) {
      this.selectedFilters.clear();
      this.filterSelect.forEach(ls => {
          const selectedValue = ls.value;
          if (selectedValue && selectedValue !== 'undefined') {
              this.selectedFilters.add(this.availableFilters.find(x => x.type === selectedValue));
          }
      });
  }

  public isFilterSelected(option: ScheduleBoardFilterOptions) {
      return this.selectedFilters.has(option) && !option.isMultipleAllowed;
  }

  private rebuildFormOnCancel() {
      const filters = [];
      this.currentFilterValues.forEach(currentFilter => {
          filters.push(
              this._formBuilder.group({
                  type: [currentFilter.type],
                  comparator: [currentFilter.comparator],
                  value: [currentFilter.value]
              })
          );
      });
      this.filterForm = this._formBuilder.group({ filters: this._formBuilder.array(filters) });
      this.getFilters().controls.forEach(filter => this.selectedFilters.add(this.availableFilters.find(x => x.type === filter.value.type)));
  }

  public cancelPopup() {
      this.rebuildFormOnCancel();
      this.showFilter = false;
  }

  public filterSalesCodeFromForm(filters) {
      let filteredSalesCode = [];
      let eqSalesCode = [];
      const salesCodes = Object.values(SaleCode);
      const salesCodeFilters = filters.filter(x => x.type === 'Sales Code');
      if (salesCodeFilters.length <= 0) {
          return salesCodes;
      }
      const isNotSalesCodeFilter = salesCodeFilters.filter(x => x.comparator === 'Is Not');
      const eqSalesCodeFilter = salesCodeFilters.filter(x => x.comparator === 'Equals');
      if (isNotSalesCodeFilter.length > 0) {
          const removeSalesCode = isNotSalesCodeFilter.map(filter => filter.value);
          filteredSalesCode = salesCodes.filter(sc => !removeSalesCode.includes(sc));
      }
      if (eqSalesCodeFilter.length > 0) {
          const findExactSalesCode = eqSalesCodeFilter.map(filter => filter.value);
          if (filteredSalesCode.length > 0) {
              const checkedFilter = findExactSalesCode.filter(x => filteredSalesCode.includes(x));
              eqSalesCode = salesCodes.filter(sc => checkedFilter.includes(sc));
          }
          else {
              return salesCodes.filter(sc => findExactSalesCode.includes(sc));
          }
      }
      return eqSalesCode.length > 0 ? eqSalesCode : filteredSalesCode;
  }

  public deleteFilterRow(filter, index: number) {
      this.selectedFilters.delete(FILTER_OPTIONS.find(x => x.type === filter.value.type));
      this.getFilters().removeAt(index);
  }

  onSubmit() {
      const filters = this.filterForm.value.filters;
      const salesCodeFilter = this.filterSalesCodeFromForm(filters);
      const filteredSalesCode = salesCodeFilter.length > 0 ? salesCodeFilter : ['None'];
      const filterResultValues: ScheduleBoardFilterValues = {
          salesCode: filteredSalesCode,
          // eslint-disable-next-line no-undefined
          branch: this.isBranchFilterable ? filters.find(x => x.type === 'Branch')?.value : undefined
      };
      this.filterValues.emit(filterResultValues);
      this.currentFilterValues = this.getFilters().value;
      this.showFilter = false;
  }
}
