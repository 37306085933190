import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FeatureFlagsApi } from '../../../enums/feature-flags-api.enum';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/feature-flags`;

    constructor(private _http: HttpClient) {}

    public async evaluateFlagByBranch(feature: FeatureFlagsApi, branchCode: string): Promise<boolean> {
        const endpoint = `${this._api}/${feature}/branch/${branchCode}`;
        return await this._http.get<boolean>(endpoint).toPromise();
    }
}
