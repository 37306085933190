import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import CustomerNoteType from '../../../data-models/customer-note-type';
import CustomerContactType from '../../../data-models/customer-contact-type';
import CustomerExtensionData from '../../../data-models/customer-extension-data';
import FastClientRequest from '../../../data-models/dispatch/fast-client-request';
import JsonUtility from '../../utility/json-utility.service';
import { HealthScreeningRequirement } from '../../../enums/health-screening-requirement.enum';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomerHttpService {
    private _api = `${environment.fswApiUrl}api2/service/crm`;

    constructor(private _http: HttpClient) { }

    public async getCustomerById(customerId: string): Promise<CustomerExtensionData | null> {
        try {
            const endpoint = `${this._api}/getByCustId/${customerId}`;
            const data = await this._http.get<CustomerExtensionData | null>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getCustomerSignNumber(customerId: string): Promise<string> {
        try {
            const endpoint = `${this._api}/sign-number/${customerId}`;

            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return '';
        }
    }

    public async getPendingFastClientRequests(): Promise<FastClientRequest[]> {
        try {
            const endpoint = `${this._api}/dispatch-requests/pending/all`;

            const data = await this._http.get<FastClientRequest[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<FastClientRequest>();
        }
    }

    public async getQuoteThreshold(customerId: string): Promise<number> {
        try {
            const endpoint = `${this._api}/${customerId}/quoteThreshold`;
            return await this._http.get<number>(endpoint).toPromise();
        }
        catch {
            return 0;
        }
    }

    public async getHealthScreeningRequirement(customerId: string, siteId: string): Promise<HealthScreeningRequirement> {
        try {
            const endpoint = `${this._api}/${customerId}/sites/${siteId}/health-screening`;

            return await this._http.get<HealthScreeningRequirement>(endpoint).toPromise();
        }
        catch {
            return HealthScreeningRequirement.NotAvailable;
        }
    }

    public async getCustomerNoteTypes(): Promise<CustomerNoteType[]> {
        try {
            const endpoint = `${this._api}/noteType/get`;
            const data = await this._http.get<CustomerNoteType[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async addCustomerNoteType(type: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/noteType/save`;
            const headers = { 'content-type': 'application/json' };
            await this._http.post(endpoint, JSON.stringify(type), { headers }).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async deleteCustomerNoteType(id: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/noteType/del/${id}`;
            await this._http.delete(endpoint).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async getCustomerContactTypes(): Promise<CustomerContactType[]> {
        try {
            const endpoint = `${this._api}/contactType/get`;
            const data = await this._http.get<CustomerContactType[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async addCustomerContactType(type: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/contactType/save`;
            const headers = { 'content-type': 'application/json' };
            await this._http.post(endpoint, JSON.stringify(type), { headers }).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async deleteCustomerContactType(id: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/contactType/del/${id}`;
            await this._http.delete(endpoint).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async getNotToExceedAmountByCustomerIdAndSalesCode(customerId: string, salesCode: string): Promise<number> {
        const url = `${this._api}/${customerId}/notToExceedAmount/${salesCode}`;

        try {
            return await this._http.get<number>(url).toPromise();
        }
        catch {
            return 0;
        }
    }
}
