import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'attachmentType'
})
export class AttachmentTypePipe implements PipeTransform {

    public transform(value: string): string {
        return value.replace('flynn/', '');
    }
}
