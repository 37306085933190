<form class="customer-settings-container">
    <h4>{{ 'admin.customerSettings.noteTypesHeader' | translate }}</h4>
    <mat-form-field>
        <mat-select [(ngModel)]="selectedNoteTypeId" name="noteTypesDropDown">
            <mat-option *ngFor="let typeNote of noteTypes" [value]="typeNote.id">
                {{ typeNote.type }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button color="warn" (click)="deleteNoteType(selectedNoteTypeId)">
        {{ 'shared.delete' | translate }}
    </button>
    <br>
    <br>
    <mat-form-field class="example-full-width">
        <mat-label>{{ 'admin.customerSettings.noteTypeLabel' | translate }}</mat-label>
        <input matInput [(ngModel)]="noteTypeLabel" name="noteTypeLableName">
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="saveNoteType(noteTypeLabel)">
        {{ 'shared.add' | translate }}
    </button>

    <h4 class="contact-types">{{ 'admin.customerSettings.contactTypesHeader' | translate }}</h4>
    <mat-form-field>
        <mat-select [(ngModel)]="selectedContactTypeId" name="contactTypesDropDown">
            <mat-option *ngFor="let typeContact of contactTypes" [value]="typeContact.id">
                {{ typeContact.contactType }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button color="warn" (click)="deleteContactType(selectedContactTypeId)">
        {{ 'shared.delete' | translate }}
    </button>
    <br>
    <br>
    <mat-form-field class="example-full-width">
        <mat-label>{{ 'admin.customerSettings.contactTypeLabel' | translate }}</mat-label>
        <input matInput [(ngModel)]="contactTypeLabel" name="contactTypeLableName">
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="saveContactType(contactTypeLabel)">
        {{ 'shared.add' | translate }}
    </button>
</form>
