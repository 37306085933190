<div class="merge-rule-mapping-card-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field appearance="outline">
        <mat-label>{{ 'admin.projectMergeRules.mergeRuleMapping.sourceSalesCodeLabel' | translate }}</mat-label>

        <mat-select [formControl]="source" (selectionChange)="onSourceChange($event.value)">
            <mat-option *ngFor="let salesCode of validSourceSalesCodes" [value]="salesCode.code">
                {{ salesCode.code }}
            </mat-option>
        </mat-select>

        <mat-error *ngIf="source.invalid">
            * {{ 'admin.projectMergeRules.mergeRuleMapping.salesCodeRequired' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-icon class="merge-rule-mapping-icon"
        [ngClass]="{ 'merge-rule-active-mapping-icon': source.valid && target.valid }">

        mediation
    </mat-icon>

    <mat-form-field appearance="outline">
        <mat-label>{{ 'admin.projectMergeRules.mergeRuleMapping.targetSalesCodeLabel' | translate }}</mat-label>

        <mat-select [formControl]="target" (selectionChange)="onTargetChange($event.value)">
            <mat-option *ngFor="let salesCode of validTargetSalesCodes" [value]="salesCode.code">
                {{ salesCode.code }}
            </mat-option>
        </mat-select>

        <mat-error *ngIf="target.invalid">
            * {{ 'admin.projectMergeRules.mergeRuleMapping.salesCodeRequired' | translate }}
        </mat-error>
    </mat-form-field>

    <button class="merge-rule-mapping-delete-button"
        mat-icon-button
        color="warn"
        (click)="delete.emit(mapping)">

        <mat-icon>delete</mat-icon>
    </button>
</div>
