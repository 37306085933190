import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import JsonUtility from '../../utility/json-utility.service';
import KpiResult from '../../../data-models/kpi-result';
import KpiRecord from '../../../data-models/kpi-record';
import ProjectIdHistory from '../../../data-models/project-id-history';
import ReportQuery from '../../../data-models/data-report/report-query';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class KpiHttpService {
    private _api = `${environment.fswApiUrl}api2/service/kpi`;

    constructor(private _http: HttpClient) { }

    public async getKpiRecords(projectId: string): Promise<KpiRecord[]> {

        return await this.sendGetRequest<KpiRecord[]>(`${this._api}/record/${projectId}`);
    }

    public async getBranchKpi(projectId: string): Promise<KpiResult> {

        return await this.sendGetRequest<KpiResult>(`${this._api}/result/${projectId}/branch`);
    }

    public async getServiceOpsKpi(projectId: string): Promise<KpiResult> {

        return await this.sendGetRequest<KpiResult>(`${this._api}/result/${projectId}/serviceops`);
    }

    public async getEstimatorKpi(projectId: string): Promise<KpiResult> {

        return await this.sendGetRequest<KpiResult>(`${this._api}/result/${projectId}/estimator`);
    }

    public async getKpiByQuery(query: ReportQuery): Promise<KpiResult[]> {
        const role = query.role ?? '';
        const branch = query.branch ?? '';
        const salesCode = query.jobType ?? '';
        const start = query.startDate?.getTime() ?? 0;
        const end = query.endDate?.getTime() ?? 0;
        const baseQuery = `role=${role}&branch=${branch}&salesCode=${salesCode}`;
        const dateQuery = `&startTime=${start}&endTime=${end}`;
        const url = `${this._api}/result/all?${baseQuery}${dateQuery}`;

        return await this.sendGetRequest<KpiResult[]>(url);
    }

    public async getProjectIdHistory(projectId: string): Promise<ProjectIdHistory> {

        return await this.sendGetRequest<ProjectIdHistory>(`${this._api}/record/projectIdHistory/${projectId}`);
    }

    private async sendGetRequest<T>(endpoint: string): Promise<T> {
        try {
            const data = await this._http.get<T>(endpoint).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch (error) {
            throw error.status;
        }
    }
}
