import ProjectUsEstimateForm from './project-attributes/project-us-estimate-form';

export default class ProjectUSQuestionsValidCheck {
    public valid = true;
    public projectUSQuestions = new ProjectUsEstimateForm();

    constructor(valid: boolean, projectUSQuestions: ProjectUsEstimateForm) {
        this.valid = valid;
        this.projectUSQuestions = projectUSQuestions;
    }
}
