import { Component, OnInit, Input } from '@angular/core';
import { ExecutiveSummaryHttpService } from '../../../core/services/http/executive-summary-http/executive-summary-http.service';
import ExecutiveSummary from '../../../core/data-models/executive-summary';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';

@Component({
    selector: 'project-executive-summary',
    templateUrl: './project-executive-summary.component.html',
    styleUrls: ['./project-executive-summary.component.scss']
})

export class ProjectExecutiveSummaryComponent implements OnInit {
    @Input() public activeProjectId = '';
    public activeTab = 'buildingType';
    public summary: ExecutiveSummary = null;
    public isTempProject = true;

    public constructor(
        private _executiveSummaryService: ExecutiveSummaryHttpService,
        private _snackbar: MatSnackBar,
        private _translate: TranslateService
    ) { }

    public async ngOnInit(): Promise<void> {
        await this.loadSummary();
        this.isTempProject = this.summary && ProjectIdUtility.isTemporary(this.summary.projectId);
    }

    public async saveSummary(summary: ExecutiveSummary): Promise<void> {
        const success = await this._executiveSummaryService.createOrUpdateExecutiveSummary(summary);

        if (success) {
            await this.loadSummary();
            const message = this._translate.instant('projects.executiveSummary.successfullySaved');
            this._snackbar.open(message, this._translate.instant('shared.ok'));
        }
        else {
            const message = this._translate.instant('projects.executiveSummary.failedToSave');
            this._snackbar.open(message, this._translate.instant('shared.ok'));
        }
    }

    private async loadSummary(): Promise<void> {
        const result = await this._executiveSummaryService.getProjectExecutiveSummary(this.activeProjectId);
        if (result.code !== '200') {
            const okLabel = this._translate.instant('shared.ok');
            this._snackbar.open(result.description, okLabel);
        }

        this.summary = result.data;
    }
}
