<div class="editable-text-display-container" [ngClass]="{ 'display-mode': !isEditing }" (click)="onEditStart()">
    <span *ngIf="!isEditing">{{ text }}</span>

    <input *ngIf="isEditing"
        type="text"
        #editBox
        (blur)="onEditEnd(editBox.value)"
        (keyup.enter)="onEditEnd(editBox.value)"
        (keyup.esc)="isEditing = false"
        (keydown.space)="$event.stopImmediatePropagation()"
        (keydown.backspace)="$event.stopImmediatePropagation()" />
</div>
