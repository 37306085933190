import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import QuoteEditorOption from '../../../core/data-models/quote-editor-option';
import QuoteWithCategoryAndTotal from '../../../core/data-models/quote-with-categories-and-total';
import QuoteSummary from '../../../core/data-models/summary-info/quote-summary';
import { WorkCategory } from '../../../core/data-models/work-category';
import { AppEventService } from '../../../core/services/events/app-event/app-event.service';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';
import { QuoteEditorComponent } from '../quote-editor/quote-editor.component';

@Component({
    selector: 'quotes-history',
    templateUrl: './quotes-history.component.html',
    styleUrls: ['./quotes-history.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuotesHistoryComponent implements OnInit, OnDestroy {
    @Input() public projectOrQuoteId = '';
    public gridViewData: QuoteWithCategoryAndTotal[];
    public groups: GroupDescriptor[] = [{ field: 'rank' }];
    public isReady = false;
    private _subscription = new Subscription();
    private _category: Array<Array<string>> = [];
    private _workCategory: WorkCategory | null = null;
    private _salesCodeMap: Map<string, WorkCategory> = new Map<string, WorkCategory>();

    get isQuoteMode(): boolean {
        return /QQQ$/i.test(this.projectOrQuoteId?.trim());
    }

    get category(): Array<Array<string>> {
        return this._category;
    }

    // eslint-disable-next-line max-params
    constructor(private _quoteService: QuoteHttpService,
                private _downloadService: DownloadHttpService,
                private _appEvents: AppEventService,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialog,
                private _salesCodeUtility: SalesCodeConfigService,
                public translate: TranslateService
    ) { }

    public async ngOnInit(): Promise<void> {
        this._category = [
            ['open', this.translate.instant('projects.quotes.openQuotesForSite')],
            ['followUp', this.translate.instant('projects.quotes.followUpQuotesForProject')],
            ['project', this.translate.instant('projects.quotes.quoteForProject')],
            ['awarded', this.translate.instant('projects.quotes.awardedQuotesForSite')]
        ];

        this._salesCodeMap = await this._salesCodeUtility.getSalesCodeWorkCategoryMapping();
        await this.loadData();
        this._subscription = this._appEvents.quoteUpdated.subscribe(_ => this.onQuoteUpdated(..._));
        this.isReady = true;
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    public showAttach(dataItem: QuoteWithCategoryAndTotal): boolean {
        if (this.isQuoteMode || dataItem.category !== 'open' || ProjectIdUtility.isEmergency(dataItem.originalProjectId)) {
            return false;
        }

        return this._workCategory === this._salesCodeMap.get(dataItem.salesCode);
    }

    public showDetach(dataItem: QuoteWithCategoryAndTotal): boolean {
        if (this.isQuoteMode || dataItem.category !== 'followUp' || ProjectIdUtility.isEmergency(dataItem.originalProjectId)) {
            return false;
        }

        return true;
    }

    public async detachQuote(quote: QuoteWithCategoryAndTotal): Promise<void> {
        const detachResult = await this._quoteService.detachQuote(quote.quoteId);

        const ok = this.translate.instant('snackbars.ok');
        if (detachResult) {
            this._snackBar.open(this.translate.instant('projects.quotes.quoteDetached'), ok);
            await this.loadData();
        }
        else {
            this._snackBar.open(this.translate.instant('projects.quotes.quoteNotDetached'), ok);
        }
    }

    public isDetachQuoteDisabled(quote: QuoteWithCategoryAndTotal): boolean {
        return quote.status === 'Awarded' || quote.status === 'Lost';
    }

    public detachQuoteDisabledMessage(quote: QuoteWithCategoryAndTotal): string {
        return this.isDetachQuoteDisabled(quote)? this.translate.instant('projects.quotes.detachDisabledReason'): '';
    }

    public async attachQuote(quote: QuoteWithCategoryAndTotal): Promise<void> {
        quote.originalProjectId = this.projectOrQuoteId;
        const attachResult = await this._quoteService.attachQuote(quote);

        const ok = this.translate.instant('snackbars.ok');
        if (attachResult) {
            this._snackBar.open(this.translate.instant('projects.quotes.quoteAttached'), ok);
            await this.loadData();
        }
        else {
            this._snackBar.open(this.translate.instant('projects.quotes.quoteNotAttached'), ok);
        }
    }

    public async printQuote(quoteId: string) : Promise<void> {
        const url = this._quoteService.getQuotePdfUrl(quoteId);
        const quoteGeneralInfo = await this._quoteService.getQuoteGeneralInfo(quoteId);
        const fileName = GenericUtilityService.getQuotePdfNameByQuoteBasicInfo(quoteGeneralInfo.basicInfo);
        this._downloadService.download(url, fileName, 'application/pdf');
    }

    public disabled(projectId: string): boolean {
        return ProjectIdUtility.isEmergency(projectId);
    }

    public async onEdit(quoteId: string): Promise<void> {
        const dialog = this._dialog.open(QuoteEditorComponent, {
            data: QuoteEditorOption.getEditOption(quoteId),
            maxWidth: '87.5vw',
            width: '87.5vw',
            height: '95vh'
        });

        if (await dialog.afterClosed().toPromise()) {
            await this.loadData();
        }
    }

    private async onQuoteUpdated(_: string, summary: QuoteSummary): Promise<void> {
        if (this.gridViewData.some(data => data.quoteId === summary.quoteId)) {
            await this.loadData();
        }
    }

    private async loadData(): Promise<void> {
        const quotes = this.isQuoteMode?
            await this._quoteService.getSiteQuotesByQuoteId(this.projectOrQuoteId) :
            await this._quoteService.getProjectQuotes(this.projectOrQuoteId) ;
        this.gridViewData = quotes.map(quote => ({ ...quote, rank: this.category.findIndex(_ => _[0] === quote.category) }));

        if (!this.isQuoteMode) {
            this._workCategory = await this._salesCodeUtility.getWorkCategoryByProjectId(this.projectOrQuoteId);
        }
    }
}
