<dialog-action-card class="fast-requests-dialog-container"
    [icon]="'assignment_ind'"
    [title]="translate.instant('dispatch.fastRequestDialog.dialog.title')"
    [isLoaded]="!loading"
    (close)="onClose()">

    <div fxLayout="row" class="report-grid-div" fxFill>
        <kendo-grid [kendoGridBinding]="gridViewData"
            [pageSize]="10"
            [pageable]="true"
            [loading]="loading"
            [style.height.%]="100" >

            <kendo-grid-column field="projectName" title="{{ 'dispatch.fastRequestDialog.table.projectName' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="customerId" title="{{ 'dispatch.fastRequestDialog.table.customerId' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="customerSiteId" title="{{ 'dispatch.fastRequestDialog.table.shipToId' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="projectSubmitTime" title="{{ 'dispatch.fastRequestDialog.table.projectSubmitTime' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.projectSubmitTime | date: 'yyyy-MM-dd'}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="address.addr1" title="{{ 'dispatch.fastRequestDialog.table.address1' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="address.addr2" title="{{ 'dispatch.fastRequestDialog.table.address2' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="address.city" title="{{ 'dispatch.fastRequestDialog.table.city' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="address.state" title="{{ 'dispatch.fastRequestDialog.table.state' | translate }}" width="60">
            </kendo-grid-column>
            <kendo-grid-column field="address.country" title="{{ 'dispatch.fastRequestDialog.table.country' | translate }}" width="60">
            </kendo-grid-column>
            <kendo-grid-column>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button (click)="dispatchProject(dataItem)" mat-stroked-button aria-label="dispatch button" color="primary">
                        <mat-icon>post_add</mat-icon>
                        {{ 'dispatch.fastRequestDialog.table.dispatch' | translate }}
                    </button>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</dialog-action-card>
