import { Component, Input, OnInit, NgZone, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import UserComment from '../../../../core/data-models/user-comment/user-comment';
import UserInfo from '../../../../core/data-models/user-info';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';
import { QuoteHttpService } from '../../../../core/services/http/quote-http/quote-http.service';
import { AppEventService } from '../../../../core/services/events/app-event/app-event.service';
import { UserCommentAction } from '../../../../core/enums/user-comment-action.enum';

@Component({
    selector: 'quote-editor-notes',
    styleUrls: ['./quote-editor-notes.component.scss'],
    templateUrl: './quote-editor-notes.component.html'
})
export class QuoteEditorNotesComponent implements OnInit, OnDestroy {
    @Input() public quoteId: string;
    @Output() public commentsChange = new EventEmitter();
    private _subscription = new Subscription();
    private _comments: UserComment[] = [];
    private _currentUser: UserInfo;
    private _isLoaded = false;

    // eslint-disable-next-line max-params
    constructor(private _userHttp: UserHttpService,
                private _quoteHttp: QuoteHttpService,
                private _appEvents: AppEventService,
                private _zone: NgZone,
                private _snackBar: MatSnackBar,
                private _translate: TranslateService) { }

    get comments(): UserComment[] {
        return this._comments;
    }

    get currentUser(): UserInfo {
        return this._currentUser;
    }

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    public async ngOnInit(): Promise<void> {
        this._currentUser = await this._userHttp.getUserInfo();
        await this.loadComments();
        this._subscription = this._appEvents.quoteCommentChanged.subscribe(_ => this.onQuoteCommentChanged(..._));
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    public async onCommentCreate(content: string): Promise<void> {
        if (await this._quoteHttp.addQuoteComment(this.quoteId, content)) {
            this.commentsChange.emit();
            await this.loadComments();
        }
    }

    public async onCommentUpdate(payload: [string, UserComment]): Promise<void> {
        const [content, comment] = payload;

        if (await this._quoteHttp.updateQuoteComment(this.quoteId, comment.id, content)) {
            this.commentsChange.emit();
            await this.loadComments();
        }
    }

    public async onCommentDelete(comment: UserComment): Promise<void> {
        if (await this._quoteHttp.deleteQuoteComment(this.quoteId, comment.id)) {
            this.commentsChange.emit();
            await this.loadComments();
        }
    }

    private async loadComments(): Promise<void> {
        this._isLoaded = false;
        this._comments = await this._quoteHttp.getQuoteComments(this.quoteId);
        this._isLoaded = true;
    }

    private onQuoteCommentChanged(quoteId: string, action: UserCommentAction, comment: UserComment): void {
        this._zone.run(() => {
            const { userName, preferredName } = comment.creator;

            if (quoteId !== this.quoteId || userName === this._currentUser?.username) {
                return;
            }

            const justAddedComment = this._translate.instant('shared.quote.editor.justAddedComment');
            const justEditedComment = this._translate.instant('shared.quote.editor.justEditedComment');
            const justRemovedComment = this._translate.instant('shared.quote.editor.justRemovedComment');
            const ok = this._translate.instant('snackbars.ok');

            if (action === UserCommentAction.Add) {
                this._comments.push(comment);
                this._snackBar.open(`${preferredName} ${justAddedComment}`, ok);
            }
            else if (action === UserCommentAction.Edit && this._comments.some(_ => _.id === comment.id)) {
                const index = this._comments.findIndex(_ => _.id === comment.id);
                this._comments[index] = comment;
                this._snackBar.open(`${preferredName} ${justEditedComment}`, ok);
            }
            else if (action === UserCommentAction.Delete) {
                this._comments = this._comments.filter(_ => _.id !== comment.id);
                this._snackBar.open(`${preferredName} ${justRemovedComment}`, ok);
            }

            this._comments = this._comments.slice();
        });
    }
}
