import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule, StateService } from '@uirouter/angular';

import { SharedModule } from '../../shared/shared.module';
import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';
import { globalResolver } from '../../core/configs/routing/global-resolver';

import { ScheduleBoardComponent } from './schedule-board.component';
import { BryntumSchedulerProModule } from '@bryntum/schedulerpro-angular';
import { QueueViewComponent } from './queue-view/queue-view.component';
import { FormsModule } from '@angular/forms';
import { CalendarViewComponent } from './calendar-view/calendar-view.component';
import { ProjectScheduleDialogComponent } from './project-schedule-dialog/project-schedule-dialog.component';
import { ScheduleBoardFilterComponent } from './schedule-board-filter/schedule-board-filter.component';
import { ScheduleBoardChartComponent } from './weather-chart/schedule-board-chart.component';

const states: Ng2StateDeclaration[] = [
    {
        name: 'schedule-board',
        url: '/scheduleboard',
        component: ScheduleBoardComponent,
        resolve: [
            {
                token: 'hasAccess',
                deps: [UserPermissionService, FeatureFlagService, StateService],
                resolveFn: async(
                    _userPermissionService: UserPermissionService,
                    _featureFlagService: FeatureFlagService,
                    _stateService: StateService
                ): Promise<void> => {
                    if (!_featureFlagService.featureFlags[FeatureFlags.ShowScheduleBoard]) {
                        _stateService.transition.abort();
                        await _stateService.go('pageNotFound');
                    }
                }
            },
            ...globalResolver
        ]
    }
];

@NgModule({
    declarations: [ScheduleBoardComponent, QueueViewComponent, CalendarViewComponent, ProjectScheduleDialogComponent, ScheduleBoardFilterComponent, ScheduleBoardChartComponent],
    imports: [
        SharedModule,
        BryntumSchedulerProModule,
        FormsModule,
        UIRouterModule.forChild({ states })
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ScheduleBoardModule { }
