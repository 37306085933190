import Branch from './branch';
import UserPermissionDto from './dto-models/user-permission-dto';

export default class UserInfo {
    public name = '';
    public username = '';
    public email = '';
    public displayName = '';
    public profilePicture = '';
    public role = 'safety';
    public roleGroup = 'safety';
    public branch = '';
    public branchInfo: Branch;
    public country = '';
    public defaultStatus = '';
    public groups: string[] = [];
    public readOnly = true;
    public dynamicsUser = false;
    public language = '';
    public permissions: UserPermissionDto[] = [];
    public phoneNumber = '';
}
