import CustomerDto from './customer-dto';
import CustomerSiteDto from './customer-site-dto';

export default class CustomerSelectionDto {

    customer: CustomerDto;
    billingSite: CustomerSiteDto;
    site: CustomerSiteDto;

}
