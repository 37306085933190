import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import DispatchDialogOptions from '../../../core/data-models/dispatch/dispatch-dialog-options';
import FastClientRequest from '../../../core/data-models/dispatch/fast-client-request';
import { CustomerHttpService } from '../../../core/services/http/customer-http/customer-http.service';
import { DispatchProjectDialogComponent } from '../dispatch-project-dialog/dispatch-project-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { WorkCategory } from '../../../core/data-models/work-category';

@Component({
    selector: 'app-fast-requests',
    styleUrls: ['./fast-requests-dialog.component.scss'],
    templateUrl: './fast-requests-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class FastRequestsDialogComponent implements OnInit {
    public gridViewData: FastClientRequest[] = [];
    public loading: boolean;

    public constructor(
        public translate: TranslateService,
        private _customerHttp: CustomerHttpService,
        private _dialogService: MatDialog,
        private _dialog: MatDialogRef<FastRequestsDialogComponent>,) {
    }

    public async ngOnInit(): Promise<void> {
        this.loading = true;
        this.gridViewData = await this._customerHttp.getPendingFastClientRequests();
        this.loading = false;
    }

    public dispatchProject(fastClientRequest: FastClientRequest): void {
        this.onClose();
        const dialogTitle = this.translate.instant('dispatch.fastRequestDialog.dispatchDialog.title');
        this._dialogService.open(DispatchProjectDialogComponent, {
            data: {
                icon: 'edit',
                title: dialogTitle,
                workCategory: WorkCategory.InternalJob,
                fastClientRequest
            } as DispatchDialogOptions,
            minHeight: '600px',
            maxWidth: '72vw',
            maxHeight: '95vh'
        });
    }

    public onClose(): void {
        this._dialog.close();
    }
}
