export class ConfirmationPromptOptions {
    public title: string;
    public message: string;
    public confirmText: string;
    public cancelText: string;
    public isWarning: boolean;

    constructor(title: string, message: string, confirmText: string, cancelText: string, isWarning = false) {
        this.title = title;
        this.message = message;
        this.confirmText = confirmText;
        this.cancelText = cancelText;
        this.isWarning = isWarning;
    }
}
