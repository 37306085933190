import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeWhile } from 'rxjs/operators';

import ErpTaskCode from '../../../../../core/data-models/erp/erp-task-code';
import { ServiceRatesEditorService } from '../service-rates-editor.service';

@Component({
    selector: 'app-service-rates-tasks-options',
    styleUrls: ['./service-rates-tasks-options.component.scss'],
    templateUrl: './service-rates-tasks-options.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRatesTasksOptionsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public form: UntypedFormGroup;
    @Input() public taskList: ErpTaskCode[] = [];
    @Input() public isReadonly: boolean;

    public filteredTaskList$: Observable<ErpTaskCode[]>;
    public filterSubject: Subject<unknown> = new Subject();
    public selected: ErpTaskCode | null = null;
    public taskControl = new UntypedFormControl('');
    private _isComponentActive = true;

    get tasks(): UntypedFormArray {
        return this.form.controls.tasks as UntypedFormArray;
    }

    get unselectedOptions(): ErpTaskCode[] {
        const selectedTasks = this.tasks.value as ErpTaskCode[];
        return this.taskList.filter(_ => !selectedTasks.some(selected => _.code === selected.code));
    }

    constructor(private _serviceRatesEditorService: ServiceRatesEditorService, private _changeDetectorRef: ChangeDetectorRef) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.isReadonly) {
            const readonly = changes.isReadonly.currentValue;
            if (readonly && this.taskControl.enabled) {
                this.taskControl.disable();
            }
            else if (!readonly && this.taskControl.disabled) {
                this.taskControl.enable();
            }
        }
    }

    public ngOnInit(): void {
        if (this.isReadonly) {
            this.taskControl.disable();
        }
        this.taskControl.valueChanges.pipe(
            takeWhile(() => this._isComponentActive)
        ).subscribe(() => this.filterSubject.next());
        this.filteredTaskList$ = this.filterSubject.pipe(startWith(null), map(() => this.filter()));
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
    }

    public selectOption(ev: MatAutocompleteSelectedEvent): void {
        if (ev.option && ev.option.value) {
            this.selected = ev.option.value as ErpTaskCode;
        }
    }

    public addSelectedTask(): void {
        const control = this._serviceRatesEditorService.createTaskField(this.selected);
        this.unselect();
        this.tasks.push(control);

        this.filterSubject.next();
        this._changeDetectorRef.markForCheck();
    }

    public removeTask(index: number): void {
        this.unselect();
        this.tasks.removeAt(index);

        this.filterSubject.next();
        this._changeDetectorRef.markForCheck();
    }

    public getOptionText(task: ErpTaskCode): string {
        if (!task) {
            return '';
        }

        return `${task.code} - ${task.description} - ${task.accountCategory ? task.accountCategory : 'N/A'}`;
    }

    private unselect(): void {
        this.selected = null;
        this.taskControl.setValue('');
    }

    private filter(): ErpTaskCode[] {
        const controlValue = this.taskControl?.value;
        if (typeof controlValue !== 'string') {
            return this.unselectedOptions;
        }

        const text = this.taskControl?.value?.toLowerCase()?.trim();
        if (!text) {
            return this.unselectedOptions;
        }

        return this.unselectedOptions.filter(_ =>
            _.code.toLowerCase().trim().includes(text) ||
            _.description.toLowerCase().trim().includes(text) ||
            _.accountCategory.toLowerCase().trim().includes(text)
        );
    }
}
