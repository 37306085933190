<div class="project-merge-rules-container">
    <merge-rule-list-viewer *ngIf="!selectedRule"
        [user]="user"
        [branches]="branches"
        [rules]="rules"
        (addRule)="openNewRulePanel()"
        (openRule)="selectedRule = $event"
        (deleteRule)="deleteRule($event)">
    </merge-rule-list-viewer>

    <merge-rule-editor *ngIf="selectedRule"
        [user]="user"
        [rule]="selectedRule"
        [salesCodes]="salesCodes"
        [savingState]="savingState"
        (update)="replaceRule($event)"
        (close)="closeRule()">
    </merge-rule-editor>
</div>
