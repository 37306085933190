import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DownloadHttpService {
    private readonly _apiFile = `${environment.fswApiUrl}api2/service/file`;

    constructor(private _http: HttpClient) { }
    /* istanbul ignore next */
    public async download(url: string, fileName: string, type = 'text/plain'): Promise<boolean> {
        try {
            const data = await this._http.get(url, { responseType: 'arraybuffer' }).toPromise();
            const blob = new Blob([data], { type });
            this.createLinkAndClick(blob, fileName);

            return true;
        }
        catch {
            return false;
        }
    }

    /* istanbul ignore next */
    public downloadBlob(blob: Blob, fileName: string): boolean {
        try {
            this.createLinkAndClick(blob, fileName);
        }
        catch {
            return false;
        }

        return true;
    }

    public async getSignedUrl(entityId: string, fileName: string, mediaType: string): Promise<string> {
        try {
            const endpoint = `${this._apiFile}/getSignedURL/${entityId}?media=${mediaType}`;
            const data = JSON.stringify(fileName);
            const headers = { 'content-type': 'application/json' };

            return await this._http.post<string>(endpoint, data, { headers }).toPromise();
        }
        catch {
            return '';
        }
    }

    public async getBudgetFile(entityId: string, format: string): Promise<Blob> {
        try {
            const endpoint = `${this._apiFile}/budget/${entityId}/${format}`;

            return await this._http.get(endpoint, { responseType: 'blob' }).toPromise();
        }
        catch {
            return null;
        }
    }

    public async getUrlAsBlob(url: string, type = 'text/plain'): Promise<Blob> {
        try {
            const data = await this._http.get(url, { responseType: 'arraybuffer' }).toPromise();
            return new Blob([data], { type });
        }
        catch {
            return null;
        }
    }

    /* istanbul ignore next */
    private createLinkAndClick(blob: Blob, fileName: string): void {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = '_blank';
        link.download = fileName;
        link.click();
    }
}
