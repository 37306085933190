import { Component, OnInit, Input } from '@angular/core';

import TemplateFieldMeta from '../../../../core/data-models/template-field-meta';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import { ProjectHttpService } from '../../../../core/services/http/project-http/project-http.service';
import { FeatureFlagService } from './../../../../core/services/events/feature-flags/feature-flag.service';

@Component({
    selector: 'project-conclusion-summary',
    styleUrls: ['./project-conclusion-summary.component.scss'],
    templateUrl: './project-conclusion-summary.component.html'
})
export class ProjectConclusionSummaryComponent implements OnInit {
    @Input() public projectId = '';
    @Input() public jobId = 0;
    @Input() public isLeakReport = false;
    public useInternalFeatures: boolean;
    private _template: TemplateFieldMeta;

    constructor(
        private _projectHttp: ProjectHttpService,
        private _featureFlagService: FeatureFlagService) { }

    get template(): TemplateFieldMeta {
        return this._template;
    }

    public async ngOnInit(): Promise<void> {
        this._template = await this._projectHttp.getSummaryTemplateField(this.projectId, this.jobId);
        this.useInternalFeatures = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures]?? false;
    }
}
