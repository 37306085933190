export default class CustomerInfo {
    public customerId: string;
    public customerName: string;
    public customerPo: string;
    public customerClass: string;
    public storeNumber: string;
    public siteName: string;
    public siteId: string;
    public isTemporarySite: boolean;
    public billingSiteId: string;
}
