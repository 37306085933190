<div class="holiday-calendar-container" fxLayout="column" fxFill>
    <h1>{{ 'admin.kpiSetting.holidayCalendar.title' | translate }}</h1>

    <div class="calendar-content">
        <div class="calendar-actions" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
            <button mat-flat-button (click)="addHoliday()" color="primary">
                {{ 'admin.kpiSetting.holidayCalendar.addButton' | translate }}
            </button>
    
            <button mat-flat-button (click)="refreshCalendar()" color="primary">
                {{ 'admin.kpiSetting.holidayCalendar.refreshButton' | translate }}
            </button>
        </div>
    
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
            <full-calendar [options]="options" fxFlex="50"></full-calendar>
        </div>
    </div>
</div>
