<div class="service-rates-maintenance-container">
    <div *ngIf="!selectedGroup" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="2.5vh" fxFill>
        <mat-form-field class="search-group" fxFlex="7.5">
            <mat-label>{{ 'flynnBudgetTool.ratesMaintenance.searchGroupText' | translate }}</mat-label>
            <input matInput (input)="searchGroupText = $event.target.value" type="text" />
        </mat-form-field>

        <div class="group-list" fxFlex="92.5">
            <kendo-grid [kendoGridBinding]="visibleGroups"
                [loading]="isLoading"
                [pageSize]="15"
                [pageable]="true"
                [scrollable]="true"
                (cellClick)="selectedGroup = $event.dataItem"
                fxFill>

                <kendo-grid-column field="name" [title]="translate.instant('flynnBudgetTool.ratesMaintenance.serviceRatesGroupsListHeaders.name')"></kendo-grid-column>

                <kendo-grid-column [title]="translate.instant('flynnBudgetTool.ratesMaintenance.serviceRatesGroupsListHeaders.totalVersions')" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <span>{{ getTotalVersionsText(dataItem) }}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [title]="translate.instant('flynnBudgetTool.ratesMaintenance.serviceRatesGroupsListHeaders.totalCustomers')" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <span>{{ dataItem.customerIds.length }}</span>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>

            <button class="add-button" mat-mini-fab color="primary" (click)="openNewGroupEditor()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>

    <app-service-rates-versions-view *ngIf="selectedGroup"
        [group]="selectedGroup"
        [isReadonly]="isReadonly"
        (groupUpdate)="onGroupUpsert($event)"
        (close)="closeVersionsView()">
    </app-service-rates-versions-view>
</div>
