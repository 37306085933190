import LaborModel from './labor-model';
import LaborActionModel from './labor-action-model';
import TimeInfo from '../time-info';

export default class LaborModelEntity {
    public timeInfo: TimeInfo;
    public labor: LaborModel;
    public attempts: string[];
    public errors: string[];
    public status: string;
    public type: number;
    public glAccount: string;
    public glName: string;
    public changes: LaborActionModel[];
    public note: string;
}
