<div class="quotes-content" fxFill>
    <mat-card fxFlex>
        <kendo-grid [kendoGridBinding]="gridViewData" [pageSize]="12" [pageable]="true" [group]="groups" *ngIf="isReady">
            <kendo-grid-column field="rank" [hidden]="true">
                <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
                    <strong>{{ category[value][1] }}</strong>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quoteId" [title]="translate.instant('projects.quotes.quoteId')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.category!=='open' && dataItem.category!=='followUp'">{{dataItem.quoteId}}</span>
                    <a *ngIf="dataItem.category==='open' || dataItem.category==='followUp'" role="button" (click)="onEdit(dataItem.quoteId)" target="_blank">
                        {{dataItem.quoteId}}
                    </a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quoteName" [title]="translate.instant('projects.quotes.quoteName')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.quoteName }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="originalProjectId" [title]="translate.instant('projects.quotes.originProjectId')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="disabled(dataItem.originalProjectId)">{{dataItem.originalProjectId}}</span>
                    <a *ngIf="!disabled(dataItem.originalProjectId)" href="/projects/{{dataItem.originalProjectId}}/details" target="_blank">
                        {{dataItem.originalProjectId}}
                    </a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="destinationProjectId" [title]="translate.instant('projects.quotes.destinationProjectId')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="disabled(dataItem.destinationProjectId)">{{dataItem.destinationProjectId}}</span>
                    <a *ngIf="!disabled(dataItem.destinationProjectId)" href="/projects/{{dataItem.destinationProjectId}}/details" target="_blank">
                        {{dataItem.destinationProjectId}}
                    </a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="total" [title]="translate.instant('projects.quotes.totalQuote')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.total | currency }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div fxLayout fxLayoutAlign="start center" fxLayoutGap="10px">
                        <button *ngIf="showAttach(dataItem)" mat-raised-button color="primary" (click)="attachQuote(dataItem)"  matTooltip="{{ 'projects.quotes.tooltip' | translate }}">
                            {{ 'projects.quotes.attach' | translate }}
                        </button>

                        <button *ngIf="showDetach(dataItem)" [disabled]="isDetachQuoteDisabled(dataItem)" mat-raised-button color="primary" 
                            (click)="detachQuote(dataItem)"  
                            [matTooltip]="detachQuoteDisabledMessage(dataItem)">
                            {{ 'projects.quotes.detach' | translate }}
                        </button>

                        <button (click)="printQuote(dataItem.quoteId)" mat-icon-button color="primary" aria-label="print button">
                            <mat-icon>print</mat-icon>
                        </button>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </mat-card>
</div>
