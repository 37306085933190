<div fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-form-field>
            <mat-label>{{ 'shared.branch' | translate }}</mat-label>
            <mat-select [(ngModel)]="query.branch">
                <mat-option value=''>{{ 'shared.all' | translate }}</mat-option>
                <mat-option *ngFor="let branch of branches" [value]="branch.code">{{branch.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'shared.jobType' | translate }}</mat-label>
            <mat-select [(ngModel)]="query.jobType">
                <mat-option value=''>{{ 'shared.all' | translate }}</mat-option>
                <mat-option *ngFor="let jobType of jobTypes" [value]="jobType">{{jobType}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf='allowRestoringProject'>
            <mat-label>{{ 'shared.projectIdField.label' | translate }}</mat-label>
            <input type="text"
                matInput
                [(ngModel)]="query.projectId"
                [placeholder]="translate.instant('shared.projectIdField.placeholder')" />
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'shared.startDate' | translate }}</mat-label>
            <input matInput [matDatepicker]="startDate" (click)="startDate.open()" readonly [(ngModel)]="query.startDate">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'shared.endDate' | translate }}</mat-label>
            <input matInput [matDatepicker]="endDate" (click)="endDate.open()" readonly [(ngModel)]="query.endDate">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
        <div>
            <button mat-raised-button color="primary" (click)="loadReport()">{{ 'dataReport.deletedReport.loadReport' | translate }}</button>
        </div>
    </div>
    <div fxLayout="row" class="report-grid-div">
        <kendo-grid [kendoGridBinding]="gridViewData" [pageSize]="gridPageSize" [pageable]="true" [loading]="loading"
        [style.height.%]="100" appGridPageResize="true">
            <ng-template kendoGridToolbarTemplate>
                <button kendoGridExcelCommand [disabled]="!gridViewData.length">{{ 'shared.exportExcel' | translate }}</button>
            </ng-template>
            <kendo-grid-column field="projectId" title="{{ 'shared.project' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="creator" title="{{ 'dataReport.deletedReport.creator' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="comment" title="{{ 'dataReport.deletedReport.reason' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="createdTime" title="{{ 'dataReport.deletedReport.deleteDate' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.createdTime | date: 'yyyy-MM-dd'}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column *ngIf='allowRestoringProject' field="reinstate" title="{{ 'dataReport.deletedReport.reinstate' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div>
                        <button mat-raised-button color="primary" (click)="reinstateJob(dataItem)">{{ 'dataReport.deletedReport.reinstateJob' | translate }}</button>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel fileName="deletedJobReportData.xlsx" [fetchData]="allData" filterable="true">
            </kendo-grid-excel>
        </kendo-grid>
    </div>
    </div>
