import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';
import ScheduleBoardProjectData from '../../../data-models/schedule-board/schedule-board-project-data';
import JobRedispatchData from '../../../data-models/re-dispatch/job-redispatch-data';
import { ProjectScheduleDialogOptions } from '../../../data-models/schedule-board/project-schedule-dialog-options';
import { EditScheduleDto } from 'src/app/core/data-models/schedule-board/edit-schedule';

@Injectable({
    providedIn: 'root'
})
export class ScheduleBoardHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/schedule-board`;

    constructor(private _http: HttpClient) { }

    public async getProjectData(branch: string): Promise<ScheduleBoardProjectData> {
        try {
            const encodedBranch = branch ? encodeURIComponent(branch) : '';
            const data = await this._http.get<ScheduleBoardProjectData>(`${this._api}?branch=${encodedBranch}`).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new ScheduleBoardProjectData();
        }
    }

    public async getCalendarData(branch: string): Promise<ScheduleBoardProjectData> {
        try {
            const encodedBranch = branch ? encodeURIComponent(branch) : '';
            const data = await this._http.get<ScheduleBoardProjectData>(`${this._api}/calendar?branch=${encodedBranch}`).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new ScheduleBoardProjectData();
        }
    }

    public async reassignJob(job: JobRedispatchData, projectId: string): Promise<boolean> {
        const endpoint = `${this._api}/reassign/project/${projectId}`;
        try {
            await this._http.post(endpoint, job).toPromise();
            return true;
        }
        catch {
            return false;
        }
    }

    public async getProjectScheduleDialogOptions(projectId: string): Promise<ProjectScheduleDialogOptions> {
        try {
            const endpoint = `${this._api}/${projectId}/project-schedule-options`;
            const data = await this._http.get<ProjectScheduleDialogOptions>(endpoint).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async scheduleJob(schedule: ProjectScheduleDialogOptions): Promise<boolean> {
        const endpoint = `${this._api}/schedule`;
        try {
            await this._http.post(endpoint, schedule).toPromise();
            return true;
        }
        catch (error) {
            return false;
        }
    }

    public async editSchedule(scheduleId: number, editData: EditScheduleDto): Promise<boolean> {
        const endpoint = `${this._api}/edit-schedule/${scheduleId}`;
        try {
            await this._http.put(endpoint, editData).toPromise();
            return true;
        }
        catch (error) {
            return false;
        }
    }

    public async deleteSchedule(projectId: string, jobId: number, scheduleId: number): Promise<boolean> {
        const endpoint = `${this._api}/delete-schedule/${projectId}/${jobId}/${scheduleId}`;
        try {
            await this._http.delete(endpoint).toPromise();
            return true;
        }
        catch (error) {
            return false;
        }
    }

}
