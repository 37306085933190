<div class="template-edit-item-card-container" [ngSwitch]="template.valueAttributes.type" fxLayout="row" fxLayoutAlign="start center">
    <div class="template-edit-item-checkbox"
        *ngSwitchCase="'check_box'"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">

        <mat-checkbox [checked]="template.value === 'true'"
            [required]="template.isRequired"
            [disabled]="!template.isEnabled"
            (change)="onCheckboxChange($event)">

            <span>{{ template.nameAttributes.translatedName }}</span>
        </mat-checkbox>
    </div>

    <mat-radio-group *ngSwitchCase="'radio_buttons_fsw'" (change)="onRadioButtonSelect($event)" fxLayout="column">
        <mat-radio-button *ngFor="let field of template.fields"
            [checked]="isRadioButtonSelected(field)"
            [value]="field.name">

            <span *ngIf="field.valueAttributes.type === 'radio_button_fsw'">{{ field.nameAttributes.translatedName }}</span>

            <div *ngIf="field.valueAttributes.type !== 'radio_button_fsw'" fxLayoutAlign="start center" fxLayoutGap="5px">
                <span>{{ field.nameAttributes.translatedName }}</span>

                <mat-form-field appearance="standard">
                    <input matInput
                        type="text"
                        [(ngModel)]="field.valueAttributes.additionalValue"
                        [required]="field.isRequired"
                        [disabled]="!isRadioButtonSelected(field)" />
                </mat-form-field>
            </div>
        </mat-radio-button>
    </mat-radio-group>

    <div class="template-edit-item-list"
        *ngSwitchCase="'list'"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <span>{{ template.nameAttributes.translatedName }}:</span>

        <mat-form-field appearance="standard">
            <mat-select [(ngModel)]="template.value"
                [required]="template.isRequired"
                [disabled]="!template.isEnabled"
                (selectionChange)="valueChange.emit(template)">

                <mat-option *ngFor="let option of template.valueAttributes.options" [value]="option.original">
                    {{ option.translated }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngSwitchCase="'numeric'"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="5px">

        <span>{{ template.nameAttributes.translatedName }}</span>

        <mat-form-field appearance="standard">
            <input matInput
                type="number"
                [(ngModel)]="template.value"
                [required]="template.isRequired"
                [disabled]="!template.isEnabled"
                (change)="valueChangeEvent()"
                min="0" />
        </mat-form-field>
    </div>

    <div class="template-edit-item-list-numeric"
        *ngSwitchCase="'list_numeric'"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="5px">

        <span>{{ template.nameAttributes.translatedName }}:</span>

        <mat-form-field appearance="standard">
            <mat-select [(ngModel)]="template.value" (selectionChange)="valueChange.emit(template)">
                <mat-option *ngFor="let option of template.valueAttributes.options" [value]="option.original">
                    {{ option.translated }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <span>, </span>

        <mat-form-field appearance="standard">
            <input matInput
                type="number"
                [(ngModel)]="template.valueAttributes.additionalValue"
                (change)="valueChange.emit(template)"
                min="0" />
        </mat-form-field>

        <span> {{ template.valueAttributes.unit }}</span>
    </div>

    <div class="template-edit-item-text-multiline"
        *ngSwitchCase="'text_multiline_fsw'"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="5px">

        <span>{{ template.nameAttributes.translatedName }}:</span>

        <textarea rows="5"
            [(ngModel)]="template.value"
            [required]="template.isRequired"
            [disabled]="!template.isEnabled"
            (change)="valueChange.emit(template)">
        </textarea>
    </div>

    <div class="template-edit-item-text-multiline"
        *ngSwitchCase="'text_multiline_with_audio_fsw'"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="5px">

        <span>{{ template.nameAttributes.translatedName }}:</span>

        <textarea rows="5"
            [(ngModel)]="template.value"
            [required]="template.isRequired"
            [disabled]="!template.isEnabled"
            (change)="valueChange.emit(template)">
        </textarea>
    </div>

    <div class="template-edit-item-collection"
        *ngSwitchCase="'collection_item_entries_fsw'"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="4px">

        <span>{{ template.nameAttributes.translatedName }}:</span>

        <table>
            <tr>
                <th *ngFor="let field of getEntryTemplates(template); let i = index">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>{{ field.nameAttributes.translatedName }}</span>
                    </div>
                </th>
            </tr>

            <tr *ngFor="let displayItem of template.fields">
                <td *ngFor="let field of displayItem.fields; let i = index">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-form-field appearance="standard" *ngIf="field.valueAttributes.type === 'list'">
                            <mat-select [(ngModel)]="field.value">
                                <mat-option *ngFor="let option of field.valueAttributes.options" [value]="option.original">
                                    {{ option.translated }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="standard" *ngIf="field.valueAttributes.type === 'numeric'">
                            <input matInput type="number" [(ngModel)]="field.value" min="0" />
                        </mat-form-field>

                        <textarea rows="2" *ngIf="field.valueAttributes.type === 'text_multiline_fsw'" [(ngModel)]="field.value"></textarea>

                        <mat-form-field appearance="standard" *ngIf="isDefaultEntryType(field)">
                            <input matInput type="text" [(ngModel)]="field.value" />
                        </mat-form-field>

                        <mat-icon *ngIf="i === displayItem.fields.length - 1" (click)="onEntryDelete(template, displayItem)">delete</mat-icon>
                    </div>
                </td>
            </tr>

            <tr (click)="onEntryAdd(template)">
                <td [attr.colspan]="getEntryTemplates(template).length">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
                        <mat-icon>add_circle</mat-icon>
                        <span>{{ 'shared.addNewEntry' | translate }}</span>
                    </div>
                </td>
            </tr>
        </table>
    </div>

    <div *ngSwitchCase="'dialog_date_pick_default'"
        fxLayout="row"
        fxLayoutAlign="start end"
        fxLayoutGap="5px">

        <span>{{ template.nameAttributes.translatedName }}:</span>

        <mat-form-field appearance="standard">
            <input matInput [matDatepicker]="picker"
                [value]="template.value"
                [required]="template.isRequired"
                [disabled]="!template.isEnabled"
                (dateChange)="onDatePickerValueChange($event, template)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>

    <div *ngSwitchDefault
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="5px">

        <span>{{ template.nameAttributes.translatedName }}:</span>

        <mat-form-field appearance="standard">
            <input matInput
                type="text"
                [(ngModel)]="template.value"
                [required]="template.isRequired"
                [disabled]="!template.isEnabled"
                (change)="valueChange.emit(template)" />
        </mat-form-field>
    </div>
</div>
