import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import UserProfile from '../../../data-models/user-profile';
import UserProfileSummaryDto from '../../../data-models/dto-models/user-profile-summary-dto';
import UserRoleDto from '../../../data-models/dto-models/user-role-dto';
import UserPermission from '../../../data-models/user-permission';
import UserRole from '../../../data-models/user-role';
import UserInfo from '../../../data-models/user-info';
import Employee from '../../../data-models/employee';
import JsonUtility from '../../utility/json-utility.service';
import CodeDescription from '../../../data-models/code-description';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/userinfo`;
    private _userInfoPromise: Promise<UserInfo> = null;

    constructor(private _http: HttpClient, private _translateService: TranslateService) { }

    public async getUserInfo(useCache = true): Promise<UserInfo | null> {
        try {
            if (useCache && this._userInfoPromise) {
                const data = JsonUtility.toCamelCaseKeys(await this._userInfoPromise);
                data.branch = data.branch?.includes('St. John') ? 'St. John\'s' : data.branch;

                return data;
            }

            const endpoint = `${this._api}/getFSWStartupInfo/`;
            this._userInfoPromise = this._http.get<UserInfo>(endpoint).toPromise();

            return this.getUserInfo();
        }
        catch (error) {
            console.error('error occurred while loading user profile:', error);

            return null;
        }
    }

    public async toggleUserProfileStatus(user: UserProfileSummaryDto): Promise<CodeDescription> {
        try {
            let endpoint = '';
            if (user.isDeactivated) {
                endpoint = `${this._api}/profile/${user.userId}/activate`;
            }
            else {
                endpoint = `${this._api}/profile/${user.userId}/deactivate`;
            }

            await this._http.post<null>(endpoint, null).toPromise();
            return new CodeDescription('204', this._translateService.instant('admin.userEditor.statusSuccess'));
        }
        catch (err) {
            const httpError = err as HttpErrorResponse;

            if (httpError.status === 500) {
                const safeError = this._translateService.instant('admin.userEditor.safeError');
                return new CodeDescription('500', safeError);
            }

            return new CodeDescription(`${httpError.status}`, httpError.error);
        }
    }

    public async getUserProfileSummaries(searchText = ''): Promise<UserProfileSummaryDto[]> {
        try {
            const endpoint = `${this._api}/profiles?searchText=${searchText}`;
            const data = await this._http.get<UserProfileSummaryDto[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async upsertUserProfile(user: UserProfile, isServiceProvider = false): Promise<CodeDescription> {
        try {
            const successText = this._translateService.instant('admin.userEditor.successfullyUpdatedUser');
            const endpoint = `${this._api}/profile?isServiceProvider=${isServiceProvider}`;
            await this._http.post<UserProfile>(endpoint, user).toPromise();

            return new CodeDescription('200', successText);
        }
        catch (err) {
            const httpError = err as HttpErrorResponse;

            if (httpError.status === 400) {
                return new CodeDescription('400', httpError.error);
            }

            const failText = this._translateService.instant('admin.userEditor.failedToUpdateUser');
            return new CodeDescription(httpError.status.toString(), failText);
        }
    }

    public async getUserRoleDetails(name: string): Promise<UserRole> {
        try {
            const endpoint = `${this._api}/role/detail/${name}`;
            const data = await this._http.get<UserRole>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new UserRole();
        }
    }

    public async getUserRoles(): Promise<UserRoleDto[]> {
        try {
            const data = await this._http.get<UserRoleDto[]>(`${this._api}/roles`).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async addUserRole(role: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/role/create/${role}`;

            return await this._http.post<boolean>(endpoint, null).toPromise();
        }
        catch {
            return false;
        }
    }

    public async deleteUserRole(role: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/roles/${role}`;

            return await this._http.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getUserPermissionTemplates(): Promise<UserPermission[]> {
        try {
            const endpoint = `${this._api}/permission-templates`;
            const data = await this._http.get<UserPermission[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async updateUserRolePrivileges(role: UserRole): Promise<boolean> {
        try {
            const endpoint = `${this._api}/roles/privileges`;

            return await this._http.put<boolean>(endpoint, role).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getUsersForHandoffMeeting(): Promise<Employee[]> {
        try {
            const data = await this._http.get<Employee[]>(`${this._api}/getUsersForHandoffMeeting`).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getEstimators(): Promise<Employee[]> {
        try {
            const endpoint = `${this._api}/estimators`;
            const data = await this._http.get<Employee[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getBranchUsersByLocation(location: string): Promise<Employee[]> {
        try {
            const endpoint = `${this._api}/branch-active-employees/${location}`;
            const data = await this._http.get<Employee[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getUserProfileByUsername(username: string, checkAd = true): Promise<CodeDescription<UserProfile>> {
        try {
            const endpoint = `${this._api}/profile?username=${username}&checkAd=${checkAd}`;
            const data = await this._http.get<UserProfile>(endpoint).toPromise();

            return new CodeDescription('200', '', JsonUtility.toCamelCaseKeys(data));
        }
        catch (err) {
            const httpError = err as HttpErrorResponse;

            if (httpError.status === 500) {
                const safeError = this._translateService.instant('admin.userEditor.safeError');
                return new CodeDescription('500', safeError);
            }

            return new CodeDescription(`${httpError.status}`, httpError.error);
        }
    }

    public async resetPassword(user: UserProfile): Promise<boolean> {
        try {
            const endpoint = `${this._api}/resetPassword`;
            return await this._http.post<boolean>(endpoint, user).toPromise();
        }
        catch {
            return false;
        }
    }

    public async updateUserLanguage(language: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/profile/update-language`;
            const headers = { 'content-type': 'application/json' };
            return await this._http.post<boolean>(endpoint, JSON.stringify(language), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }
}
