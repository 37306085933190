import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class FormControlUtilityService {

    constructor(private _translate: TranslateService) {}

    public getErrorMessage(form: AbstractControl): string {

        if (form?.hasError('required')) {
            return this._translate.instant('core.formControlUtility.mustEnterValue');
        }
        if (form?.hasError('email')) {
            return this._translate.instant('core.formControlUtility.notValidEmail');
        }
        if (form?.errors?.maxlength) {
            return this._translate.instant('core.formControlUtility.inputTooLong');
        }
        if (form?.errors?.minlength) {
            return this._translate.instant('core.formControlUtility.inputNotLongEnough');
        }
        if (form?.errors?.positive) {
            return this._translate.instant('core.formControlUtility.valueMustBePositive');
        }
        return '';
    }

}
