import BranchTimezone from '../branch-timezone';
import CardActionButtons from './card-action-button';

export default class ImageCardConfig {
    public imageSize: string;
    public showDescription: boolean;
    public showTags: boolean;
    public actionButtons: CardActionButtons[] = [];
    public useThumbnails = true;
    public editableDescription = false;
    public timeZone: BranchTimezone;
}
