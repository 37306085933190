import { SaleCode } from '../../enums/schedule-board';

export const FILTER_OPTIONS: ScheduleBoardFilterOptions[] = [
    {
        type: 'Sales Code',
        isMultipleAllowed: true,
        comparators: ['Is Not', 'Equals']
    },
    {
        type: 'Branch',
        isMultipleAllowed: false,
        comparators: ['Equals']
    }
];

export interface ScheduleBoardFilterOptions {
    type: string,
    comparators: string[],
    isMultipleAllowed: boolean
}

export interface ScheduleBoardFilterValues {
    salesCode: SaleCode[],
    branch?: string
}