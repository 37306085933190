/// <reference types="@types/google.maps" />
/* istanbul ignore file */
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapsUtilityService {

    public getGeocodeByAddress(address: string): Promise<google.maps.LatLng> {
        return new Promise<google.maps.LatLng>(resolve => {
            const geoCoder = new google.maps.Geocoder();
            geoCoder.geocode({ address }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    resolve(results[0].geometry.location);
                }
                else {
                    resolve(null);
                }
            });
        });
    }

    public async getPanoramaByLocation(location: google.maps.LatLngLiteral, radius: number): Promise<string> {
        const streetViewService = new google.maps.StreetViewService();
        const streetViewRequest = {
            location,
            radius,
            preference: google.maps.StreetViewPreference.BEST,
            source: google.maps.StreetViewSource.DEFAULT
        } as google.maps.StreetViewLocationRequest;
        try {
            const response = await streetViewService.getPanorama(streetViewRequest);
            return response.data.location.pano;
        }
        catch (err) {
            if (err.code === google.maps.StreetViewStatus.ZERO_RESULTS && radius <= 250) {
                return this.getPanoramaByLocation(location, radius + 50);
            }
        }

        return '';
    }
}
