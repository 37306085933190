import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import WorkflowDto from '../../../data-models/workflow-dto';
import CodeDescription from '../../../data-models/code-description';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WorkflowHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/workflow`;

    constructor(private _http: HttpClient) { }

    public async getWorkflowByProjectId(projectId: string): Promise<WorkflowDto[]> {
        try {
            const endpoint = `${this._api}/projects/${projectId}`;
            const data = await this._http.get<WorkflowDto[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async executeWorkflow(workflowCode: number, projectId: string): Promise<boolean | CodeDescription> {
        try {
            const endpoint = `${this._api}/${workflowCode}/projects/${projectId}`;

            return await this._http.post<boolean>(endpoint, null).toPromise();
        }
        catch (error) {
            const { status, error: statusText } = error as HttpErrorResponse;

            return new CodeDescription(String(status), statusText);
        }
    }
}
