import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';

import { BudgetEstimationService } from '../budget-estimation.service';
import { ValidatorService } from '../../../../core/services/validator-service/validator-service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-labor-section',
    templateUrl: './labor-section.component.html',
    styleUrls: ['./labor-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaborSectionComponent implements OnInit, OnDestroy {
    @Input() public form: UntypedFormGroup;
    @Input() public calculationUpdated$: Subject<void>;
    @Input() public readonly = false;
    private _isComponentActive = true;
    private readonly _translationRoot = 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.laborSection';

    get branchLaborCost(): UntypedFormGroup {
        return this.form.get('laborSection.branchLaborCost') as UntypedFormGroup;
    }

    get miscellaneousLaborCost(): UntypedFormGroup {
        return this.form.get('laborSection.miscellaneousLaborCost') as UntypedFormGroup;
    }

    get totalCost(): UntypedFormControl {
        return this.form.get('laborSection.totalCost') as UntypedFormControl;
    }

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private _translate: TranslateService,
        private _budgetEstimationService: BudgetEstimationService,
        private _formBuilder: UntypedFormBuilder,
        private _validatorService: ValidatorService,
    ) { }

    public ngOnInit(): void {
        this.calculationUpdated$.pipe(
            takeWhile(() => this._isComponentActive)
        ).subscribe(() => {
            this.changeDetectorRef.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
    }

    public localize(token: string): string {
        return this._translate.instant(`${this._translationRoot}.${token}`);
    }
}
