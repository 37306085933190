<div class="summary-list-filter-filter-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFill>
    <span>{{ filterConfig.displayName ? filterConfig.displayName : filterConfig.name }}</span>

    <div #dropReference fxFlex fxLayoutAlign="start start" class="summary-filter-chips-container" (click)="showFilterPopup()">
        <mat-chip-list #chipList>
            <mat-chip *ngFor="let chipItem of selectedFilterItems"
                class="summary-list-filter-chips-item"
                [ngClass]="chipItemsColorClass"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(chipItem.name)">

                {{ chipItem.displayText }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
        <span style="position:sticky;padding-right:5px;margin-top:-5px;font-size:x-large" *ngIf="isFilterContainerOverFlowing">...</span>

    </div>

    <ng-template cdk-portal="">
        <mat-card class="filter-dropdown-matcard">
            <div class="text-filter-input-dropdown" fxLayoutAlign="start center">
                <mat-form-field fxFlex>
                    <input [(ngModel)]="dropDownFilterText" (keyup)="filterListWithSearchText()" matInput placeholder="{{filterConfig.placeHolder}}">
                </mat-form-field>

                <div fxLayoutAlign="center center">
                    <mat-icon>filter_alt</mat-icon>
                </div>
            </div>

            <div class="summary-list-dropdown-filter-items">
                <div class="summary-list-filter-check-item-wrapper"
                    *ngFor="let templateItem of filteredBySearchTextDropDownItems"
                    fxLayoutAlign="start center">

                    <mat-checkbox [(ngModel)]="templateItem.checked" (change)="filterCheckedStatusChanged()">
                       {{templateItem.displayText}}
                    </mat-checkbox>
                </div>
            </div>
        </mat-card>
    </ng-template>

</div>
