import { Component, Input, OnInit } from '@angular/core';

import ProjectAttributesWithUsCheck from '../../../core/data-models/project-attributes/project-attributes-us-check';

import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import ProjectQuestions from '../../../core/data-models/project-attributes/project-questions';
import ProjectUsEstimateForm from '../../../core/data-models/project-attributes/project-us-estimate-form';
import { WorkCategory } from '../../../core/data-models/work-category';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';

@Component({
    selector: 'project-attributes',
    templateUrl: './project-attributes.component.html',
    styleUrls: ['./project-attributes.component.scss']
})

export class ProjectAttributesComponent implements OnInit {
    @Input() public activeProjectId = '';

    public project = new ProjectAttributesWithUsCheck();
    public isLoaded = false;
    private _workCategory: WorkCategory;

    get showGeneralAttributes(): boolean {
        return this._workCategory === WorkCategory.InternalJob;
    }

    constructor(private _projectService: ProjectHttpService, private _salesCodeConfigService: SalesCodeConfigService) { }

    public async ngOnInit(): Promise<void> {
        this._workCategory = await this._salesCodeConfigService.getWorkCategoryByProjectId(this.activeProjectId);
        this.project = await this._projectService.getProjectAttributesUsCheck(this.activeProjectId);
        if (!this.project) {
            this.project = new ProjectAttributesWithUsCheck();
        }

        if (!this.project?.projectQuestions) {
            this.project.projectQuestions = new ProjectQuestions();
        }

        if (!this.project?.uSEstimateForm) {
            this.project.uSEstimateForm = new ProjectUsEstimateForm();
        }

        if (!this.project.projectQuestions.projValue) {
            this.project.projectQuestions.projValue = { codeValue: '', codeValueDesc: 'N/A' };
        }
        this.isLoaded = true;
    }
}
