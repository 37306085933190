<dialog-action-card [icon]="'call_merge'" [title]="translate.instant('projects.merge.rules.dialog.title')" [isLoaded]="true" (close)="onClose()">
    <div *ngIf="!userHasMergeRulePermission" class="merge-project-container">
        {{ 'projects.merge.rules.permissionToMergeError' | translate }}
    </div>

    <div *ngIf="userHasMergeRulePermission" class="merge-project-rules-dialog-container" fxLayout="column" fxLayoutGap="2.5vh">
        <div fxLayout="column" fxLayoutGap="0.5vh" fxFlex>
            <label>{{ 'projects.merge.rules.dialog.sourceTargetMappings' | translate }}</label>

            <div class="merge-project-rules-dialog-chips" fxLayout fxLayoutAlign="space-between center">
                <mat-chip-list fxLayout="row">
                    <mat-chip *ngFor="let codeMapping of salesCodeMappings" color="primary" selected>
                        <span>{{ codeMapping.source }}</span>
                        <mat-icon>forward</mat-icon>
                        <span>{{ codeMapping.target }}</span>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <div fxLayout="column" fxLayoutGap="0.5vh" fxFlex>
            <label>{{ 'projects.merge.rules.dialog.sourceProjectStatuses' | translate }} </label>

            <div class="merge-project-rules-dialog-chips" fxLayout fxLayoutAlign="space-between center">
                <mat-chip-list fxLayout="row">
                    <mat-chip *ngFor="let status of sourceProjectStatuses" color="primary" selected>
                        {{ status }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>

        <div fxLayout="column" fxLayoutGap="0.5vh" fxFlex>
            <label>{{ 'projects.merge.rules.dialog.targetProjectStatuses' | translate }}</label>

            <div class="merge-project-rules-dialog-chips" fxLayout fxLayoutAlign="space-between center">
                <mat-chip-list fxLayout="row">
                    <mat-chip *ngFor="let status of targetProjectStatuses" color="primary" selected>
                        {{ status }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </div>
</dialog-action-card>
