import { Component, Inject, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';

import { ActionProgressDialogComponent } from '../../../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { HandOffMeetingHttpService } from '../../../../../../core/services/http/hand-off-meeting-http/hand-off-meeting-http.service';
import { DispatchHttpService } from '../../../../../../core/services/http/dispatch-http/dispatch-http.service';
import { UserHttpService } from '../../../../../../core/services/http/user-http/user-http.service';
import { DispatchSteps } from '../../../../../../core/enums/dispatch-steps.enum';
import UserInfo from '../../../../../../core/data-models/user-info';
import ActionProgressReporterOption from '../../../../../../core/data-models/action-progress-reporter-option';
import DispatchInfoDto from '../../../../../../core/data-models/re-dispatch/dispatch-info-dto';
import AttachmentStepConfig from '../../../../../../core/data-models/dispatch/attachment-step-config';
import WorkOrderStepConfig from '../../../../../../core/data-models/dispatch/work-order-step-config';
import HandoffMeetingStepConfig from '../../../../../../core/data-models/dispatch/handoff-meeting-step-config';
import ForemanSelectionStepConfig from '../../../../../../core/data-models/re-dispatch/foreman-selection-step-config';
import ClientInfo from '../../../../../../core/data-models/client-info';
import Crew from '../../../../../../core/data-models/crew';
import HandoffMeetingUtilityService from '../services/handoff-meeting-utility.service';
import ProjectIdUtility from '../../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import DispatchExistingOptions from '../../../../../../core/data-models/project-general-info/dispatch-existing-options';

@Component({
    selector: 'dispatch-management-dialog',
    templateUrl: './dispatch-management-dialog.component.html',
    styleUrls: ['./dispatch-management-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DispatchManagementDialogComponent implements OnInit {
    public dispatchExistingOptions: DispatchExistingOptions;
    public handoffMeetingStepConfig: HandoffMeetingStepConfig;
    public attachmentStepConfig: AttachmentStepConfig;
    public workOrderStepConfig: WorkOrderStepConfig;
    public foremanSelectionStepConfig: ForemanSelectionStepConfig;
    public crewReferencedInLastHandOffMeeting: Crew;
    public dispatching = false;
    public handOffMeetingNeededOnThisDispatch = false;
    public isLoaded = false;
    public projectId: string;
    public user: UserInfo;
    @ViewChild('dispatchStepper') stepper: MatStepper;

    // eslint-disable-next-line
    constructor(
        private _dispatchHttp: DispatchHttpService,
        private _userHttp: UserHttpService,
        private _dialogService: MatDialog,
        private _handOffHttpService: HandOffMeetingHttpService,
        private _snackBar: MatSnackBar,
        private _dialogRef: MatDialogRef<DispatchManagementDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DispatchExistingOptions,
        public translate: TranslateService) {

        this.handoffMeetingStepConfig = new HandoffMeetingStepConfig(
            this.translate.instant('projects.dispatchManagement.stepperTitles.handoffMeeting')
        );

        this.attachmentStepConfig = new AttachmentStepConfig(
            this.translate.instant('projects.dispatchManagement.stepperTitles.attachments')
        );

        this.workOrderStepConfig = new WorkOrderStepConfig(
            this.translate.instant('projects.dispatchManagement.stepperTitles.workOrder')
        );

        this.foremanSelectionStepConfig = new ForemanSelectionStepConfig(
            this.translate.instant('projects.dispatchManagement.stepperTitles.dispatchBasicDetails')
        );

        this.dispatchExistingOptions = data;
    }

    public async ngOnInit(): Promise<void> {
        this.isLoaded = false;
        this.user = await this._userHttp.getUserInfo();

        if (!this.dispatchExistingOptions) {
            const failedToLoad = this.translate.instant('projects.dispatchManagement.failedToLoad');
            const ok = this.translate.instant('snackbars.ok');
            this._snackBar.open(failedToLoad, ok);
            this.onClose();
            return;
        }

        this.attachmentStepConfig.completed = true;
        this.projectId = ProjectIdUtility.toStorageFormat(this.dispatchExistingOptions.projectId);
        if (this.dispatchExistingOptions.isHandOffMeetingRequired) {
            const handOffMeeting = await this._handOffHttpService.getHandOffMeetingByProjectId(this.projectId);

            if (handOffMeeting) {
                this.crewReferencedInLastHandOffMeeting = handOffMeeting.crew;
            }
            else {
                this.handOffMeetingNeededOnThisDispatch = true;
            }
        }
        this.isLoaded = true;
    }

    public determineIfHandoffRequiredForDispatch(currentCrewSelection: Crew): void {
        const isHandoffProject = this.dispatchExistingOptions.isHandOffMeetingRequired;
        this.handOffMeetingNeededOnThisDispatch = HandoffMeetingUtilityService.handOffMeetingRequired(
            isHandoffProject, this.crewReferencedInLastHandOffMeeting, currentCrewSelection);
    }

    public goToStep(stepName: string): void {
        if (stepName === DispatchSteps.AttachmentsStep) {
            this.stepper.selectedIndex = 1;
        }
        else {
            this.stepper.selectedIndex = 0;
        }
    }

    public updateJobTypeRequirements(jobType: string): void {
        this.workOrderStepConfig = { ...this.workOrderStepConfig,
            completed: false,
            jobType } as WorkOrderStepConfig;
    }

    public async createDispatchRequest(): Promise<void> {
        this.dispatching = true;
        const newJobId = this.dispatchExistingOptions.lastJobId + 1;
        const dispatchData = new DispatchInfoDto();
        dispatchData.expectedDays = this.foremanSelectionStepConfig.data.expectedDays;
        dispatchData.branch = this.dispatchExistingOptions.branch;
        dispatchData.jobType = this.foremanSelectionStepConfig.data.jobType;
        dispatchData.isContinueLeakCall = this.foremanSelectionStepConfig.data.continueLeakCall;
        dispatchData.projectId = this.projectId;
        dispatchData.jobId = newJobId;
        dispatchData.rawWorkOrder = this.workOrderStepConfig.workOrderString;
        dispatchData.workCheckList = this.workOrderStepConfig.workCheckList;
        dispatchData.notes = this.foremanSelectionStepConfig.data.notes;
        dispatchData.notToExceedAmount = this.foremanSelectionStepConfig.data.notToExceedAmount;
        dispatchData.crew = this.foremanSelectionStepConfig.data.crew;
        dispatchData.name = this.dispatchExistingOptions.projectName;
        dispatchData.requireDrawing = this.foremanSelectionStepConfig.data.drawingRequired;
        dispatchData.requireOvernightSealPhoto = this.foremanSelectionStepConfig.data.overNightSealRequired;

        dispatchData.siteContact = {
            contactName: this.foremanSelectionStepConfig.data.siteContact,
            contactNumber: this.foremanSelectionStepConfig.data.contactPhone
        } as ClientInfo;

        dispatchData.isHandOffMeetingNeeded = this.handOffMeetingNeededOnThisDispatch;
        if (this.handOffMeetingNeededOnThisDispatch) {
            dispatchData.handOffMeeting = this.handoffMeetingStepConfig.data;
            dispatchData.handOffMeeting.crew = this.foremanSelectionStepConfig.data.crew;
            dispatchData.handOffMeeting.projectId = ProjectIdUtility.toStorageFormat(this.dispatchExistingOptions.projectId);
            dispatchData.handOffMeeting.projectName = this.dispatchExistingOptions.projectName;
            dispatchData.handOffMeeting.jobId = newJobId;
        }

        const files = new Map<string, File[]>();
        if (this.attachmentStepConfig.attachments?.length) {
            files.set('attachments', this.attachmentStepConfig.attachments);
        }

        if (this.attachmentStepConfig.roofMap) {
            files.set('roofMap', [this.attachmentStepConfig.roofMap]);
        }

        const action = this._dispatchHttp.dispatchNewReport(dispatchData, files);
        const ongoing = this.translate.instant('projects.dispatchManagement.actions.ongoing');
        const success = this.translate.instant('projects.dispatchManagement.actions.success');
        const error = this.translate.instant('projects.dispatchManagement.actions.error');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise()) {
            this._dialogRef.close(true);
        }

        this.dispatching = false;
    }

    public onClose(): void {
        this._dialogRef.close(false);
    }

    public handleStep(step: string): void {
        if (step === 'next') {
            this.stepper.next();
        }
        else {
            this.stepper.previous();
        }
    }
}
