import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouterModule, UIRouter } from '@uirouter/angular';

import { AuthenticationErrorComponent } from './shared/components/errors/authentication-error/authentication-error.component';
import { PageNotFoundComponent } from './shared/components/errors/page-not-found/page-not-found.component';
import { LoginComponent } from './shared/components/login/login.component';

const states: Ng2StateDeclaration[] = [
    {
        name: 'authError',
        url: '/authentication-error',
        component: AuthenticationErrorComponent
    },
    {
        name: 'pageNotFound',
        url: '/page-not-found',
        component: PageNotFoundComponent
    },
    {
        name: 'loginPage',
        url: '/login',
        component: LoginComponent,
        params: {
            returnState: 'projects',
            extra: {}
        }
    }
];

function config(router: UIRouter) {
    router.urlService.config.strictMode(false);
    router.urlService.rules.initial({ state: 'loginPage' });
    router.urlService.rules.otherwise({ state: 'pageNotFound' });
}

@NgModule({
    imports: [UIRouterModule.forRoot({ states, config })],
    exports: [UIRouterModule]
})
export class AppRoutingModule { }
