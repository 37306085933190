export default class ClientEvent<T> {
    public name: string;
    public clientId: string;
    public timestamp: Date;
    public data: T;

    constructor(name: string, clientId: string, timestamp: Date, data: T) {
        this.name = name;
        this.clientId = clientId;
        this.timestamp = timestamp;
        this.data = data;
    }
}
