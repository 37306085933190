export default class QuoteTemplate {
    public id = '';
    public title = '';
    public content = '';
    public spreadsheetData = '';

    public constructor(title = '', content = '', spreadsheetData = '') {
        this.title = title;
        this.content = content;
        this.spreadsheetData = spreadsheetData;
    }
}
