import CodeDescription from './code-description';

export default class ActionProgressReporterOption {
    public action: Promise<boolean | CodeDescription>;
    public ongoingText = '';
    public successText = '';
    public errorText = '';
    public successConfirmText = '';
    public errorConfirmText = '';
    public waitTime = 0.8;
    public showSuccess = true;

    //eslint-disable-next-line
    constructor(
        action: Promise<boolean | CodeDescription>,
        ongoing = 'Please wait...',
        success = 'The action was performed successfully.',
        error = 'An error occurred while performing the action.',
        successConfirmText = 'Ok',
        errorConfirmText = 'Got It',
        showSuccess = true,
        waitTime = 0.8
    ) {
        this.action = action;
        this.ongoingText = ongoing;
        this.successText = success;
        this.errorText = error;
        this.successConfirmText = successConfirmText;
        this.errorConfirmText = errorConfirmText;
        this.showSuccess = showSuccess;
        this.waitTime = waitTime;
    }
}
