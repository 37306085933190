<mat-card fxLayout="row" class="labor-main-content">
<div fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-form-field>
            <mat-label>{{ 'labour.branch' | translate }}</mat-label>
            <mat-select [(ngModel)]="query.branch">
                <mat-option value=''>{{ 'labour.all' | translate }}</mat-option>
                <mat-option *ngFor="let branch of branches" [value]="branch.name">{{branch.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'labour.jobType' | translate }}</mat-label>
            <mat-select [(ngModel)]="query.salesCode">
                <mat-option value=''>{{ 'labour.all' | translate }}</mat-option>
                <mat-option *ngFor="let jobType of jobTypes" [value]="jobType">{{jobType}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'labour.status' | translate }}</mat-label>
            <mat-select [(ngModel)]="query.status">
                <mat-option value=''>{{ 'labour.all' | translate }}</mat-option>
                <mat-option *ngFor="let status of laborStatus" [value]="status.name">{{ translateStatus(status.text) }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'labour.startDate' | translate }}</mat-label>
            <input matInput [matDatepicker]="startDate" (click)="startDate.open()" readonly [(ngModel)]="query.startDate">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'labour.endDate' | translate }}</mat-label>
            <input matInput [matDatepicker]="endDate" (click)="endDate.open()" readonly [(ngModel)]="query.endDate">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
        <div>
            <button mat-raised-button color="primary" (click)="loadLaborRecords()">{{ 'labour.search' | translate }}</button>
        </div>
    </div>
    <div fxLayout="row" class="labor-grid-div">
        <kendo-grid #grid="kendoGrid" [kendoGridBinding]="gridViewData" [pageSize]="15" [pageable]="true"
        [style.height.%]="100" [groupable]="false" sortable="true" [group]="groups" filterable="menu" [loading]="loading" [rowClass]="rowColor" [resizable]="true">
            <kendo-grid-column field="recordId" title="{{ 'labour.projectGlAccount' | translate }}" [width]="120">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="recordName" title="{{ 'labour.name' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter="filter" let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="branch" title="{{ 'labour.branch' | translate }}" [filterable]="false">
            </kendo-grid-column>
            <kendo-grid-column field="jobDate" title="{{ 'labour.date' | translate }}" [filterable]="false" [width]="100">
                <ng-template kendoGridGroupHeaderTemplate let-group  let-aggregates="aggregates">
                    <span>{{ 'labour.date' | translate }} {{ group.value }} ----- {{ 'labour.totalHours' | translate }}: {{ aggregates.hours.sum }}</span>
                 </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="employeeName" title="{{ 'labour.employee' | translate }}">
                <ng-template kendoGridGroupHeaderTemplate let-group  let-aggregates="aggregates">
                    <span>{{ 'labour.employee' | translate }} {{ group.value }} ----- {{ 'labour.totalHours' | translate }}: {{ aggregates.hours.sum }}</span>
                 </ng-template>
                 <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="hours" title="{{ 'labour.hours' | translate }}" [filterable]="false">
                <ng-template kendoGridGroupFooterTemplate let-group="group" let-aggregates>
                    <span>
                        {{ 'labour.totalHoursFor' | translate }} {{ group.value }}: {{ aggregates.hours.sum }}
                    </span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="{{ 'labour.unionOverride' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.overrides.union">{{ dataItem.overrides.union.id }} - {{ dataItem.overrides.union.name }}</span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="{{ 'labour.wageCodeOverride' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.overrides.wageCode">{{ dataItem.overrides.wageCode.code }} - {{ dataItem.overrides.wageCode.description }}</span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="note" title="{{ 'labour.notes' | translate }}" [filterable]="false">
            </kendo-grid-column>
            <kendo-grid-column field="status" title="{{ 'labour.status' | translate }}" [filterable]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ translateStatus(dataItem.status) }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="lastError" title="{{ 'labour.error' | translate }}" [filterable]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.status === 'Failed to upload to TCM/DTC' || dataItem.lastError.indexOf('Total Hours') > -1 ? dataItem.lastError : '' }}
                   <span *ngIf="dataItem.hours<=0">{{ 'labour.labourHourZero' | translate }}</span> 
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div fxLayout="row" fxLayoutAlign="space-around center" >
                    <button color="primary" *ngIf="dataItem.status === 'Failed to upload to TCM/DTC'" [disabled]="loading"
                        (click)="retryLabor(dataItem)" mat-mini-fab aria-label="retry button" matTooltip="{{ 'labour.retryUpload' | translate }}">
                        <mat-icon>cloud_upload</mat-icon>
                    </button>
                    <button color="primary" *ngIf="dataItem.status ==='Submitted By Tech' && dataItem.hours>'0'" [disabled]="loading"
                        (click)="approveLabor(dataItem)" mat-mini-fab aria-label="approve button" matTooltip="{{ 'labour.approveHours' | translate }}">
                        <mat-icon>check</mat-icon>
                    </button>
                    <button color="primary" *ngIf="dataItem.status ==='Submitted By Tech'" [disabled]="loading"
                        (click)="rejectLabor(dataItem)" mat-mini-fab aria-label="reject button" matTooltip="{{ 'labour.rejectHours' | translate }}">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button color="primary" *ngIf="canEdit(dataItem)" [disabled]="loading"
                        (click)="editLabor(dataItem)" mat-mini-fab aria-label="edit button" matTooltip="{{ 'labour.editLabor' | translate }}">
                        <mat-icon>edit</mat-icon>
                    </button>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridToolbarTemplate>
                <button kendoGridExcelCommand [disabled]="!gridViewData.length">{{ 'labour.exportToExcel' | translate }}</button>
                <button class="k-button k-grid-excel" [disabled]="!gridViewData.length" (click)="exportDetailsToExcel(grid)">{{ 'labour.exportToExcelDetailed' | translate }}</button>
                <button class="k-button k-grid-excel" [disabled]="!gridViewData.length" (click)="generateAvantiExport()" *ngIf="showAvantiExport">{{ 'labour.generateAvantiExport' | translate }}</button>
            </ng-template>
            <kendo-grid-excel fileName="laborData.xlsx" [fetchData]="allData" [collapsible]="true">
                <kendo-excelexport-column field="recordId" title="{{ 'labour.projectGlAccount' | translate }}">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="recordName" title="{{ 'labour.name' | translate }}">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="branch" title="{{ 'labour.branch' | translate }}">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="jobDate" title="{{ 'labour.date' | translate }}">
                    <ng-template kendoExcelExportGroupHeaderTemplate let-group  let-aggregates="aggregates">
                        <span>{{ 'labour.date' | translate }} {{ group.value }} ----- {{ 'labour.totalHours' | translate }}: {{ aggregates.hours.sum }}</span>
                     </ng-template>
                </kendo-excelexport-column>
                <kendo-excelexport-column field="employeeName" title="{{ 'labour.employee' | translate }}">
                    <ng-template kendoExcelExportGroupHeaderTemplate let-group  let-aggregates="aggregates">
                        <span>{{ 'labour.employee' | translate }} {{ group.value }} ----- {{ 'labour.totalHours' | translate }}: {{ aggregates.hours.sum }}</span>
                     </ng-template>
                </kendo-excelexport-column>
                <kendo-excelexport-column field="hours" title="{{ 'labour.hours' | translate }}">
                    <ng-template kendoExcelExportGroupFooterTemplate let-group="group" let-aggregates>
                        <span>
                            {{ 'labour.totalHoursFor' | translate }} {{ group.value }}: {{ aggregates.hours.sum }}
                        </span>
                    </ng-template>
                </kendo-excelexport-column>

                <kendo-excelexport-column field="unionOverride" title="{{ 'labour.unionOverride' | translate }}"></kendo-excelexport-column>
                <kendo-excelexport-column field="wageCodeOverride" title="{{ 'labour.wageCodeOverride' | translate }}"></kendo-excelexport-column>
                <kendo-excelexport-column field="note" title="{{ 'labour.notes' | translate }}">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="status" title="{{ 'labour.status' | translate }}">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="lastError" title="{{ 'labour.error' | translate }}">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="roomAndBoardFlag" title="{{ 'labour.roomAndBoard' | translate }}" *ngIf="detailsFlag">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="mileageFlag" title="{{ 'labour.mileage' | translate }}" *ngIf="detailsFlag">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="initialAllowanceFlag" title="{{ 'labour.initialAllowance' | translate }}" *ngIf="detailsFlag">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="returnAllowanceFlag" title="{{ 'labour.returnAllowance' | translate }}" *ngIf="detailsFlag">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="mealFlag" title="{{ 'labour.meal' | translate }}" *ngIf="detailsFlag">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="mileageDistance" title="{{ 'labour.mileageDistance' | translate }}" *ngIf="detailsFlag">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="zoneFlag" title="{{ 'labour.zonePay' | translate }}" *ngIf="detailsFlag">
                </kendo-excelexport-column>
            </kendo-grid-excel>
        </kendo-grid>
    </div>
    </div>
</mat-card>
