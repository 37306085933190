import TimeInfo from './time-info';
import SubscribedTopic from './subscribed-topic';
import UserPermission from './user-permission';

export default class UserRole {
    public name = '';
    public isBuiltIn = false;
    public timeInfo = new TimeInfo();
    public projectMergeRuleCode = -1;
    public subscriptions: SubscribedTopic[] = [];
    public userPermissions: UserPermission[] = [];
}
