import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BranchServiceRate } from '../../../../core/data-models/rates/service-rates/branch-service-rate';
import { ServiceRatesRevision } from '../../../../core/data-models/rates/service-rates/service-rates-revision';
import { Branch } from '../../../../core/data-models/branch/branch';

@Component({
    selector: 'app-service-rates-viewer',
    styleUrls: ['./service-rates-branches-display.component.scss'],
    templateUrl: './service-rates-branches-display.component.html'
})
export class ServiceRatesBranchesDisplayComponent {
    public readonly percentPipeConfig = '0.0-2';
    constructor(
        private _dialogRef: MatDialogRef<ServiceRatesBranchesDisplayComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { selectedRates: ServiceRatesRevision, branches: Branch[] }
    ) { }

    public getColumnWidth(rate: BranchServiceRate): number {
        const totalPredefinedFields = 4;

        return 100 / (totalPredefinedFields + rate.grossProfitPercentageRates.length);
    }

    public getBranchName(branchCode: string): string {
        return this.data.branches.find(branch => branch.code === branchCode).name;
    }

    public getProjectType(id: string): string {
        return this.data.selectedRates.projectTypes.find(project => project.identifier === id).name;
    }
}
