<div class="drawings-viewer-container" fxLayout="column" fxLayoutGap="7px">
    <div class="drawings-tool-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="!disableActionBar">
        <button
            mat-raised-button
            color="primary"
            (click)="openUploadImage()">

            {{ 'drawings.upload' | translate }}
        </button>

        <div [matTooltip]="deletedPhotosToolTip">
            <button *ngIf="!editMode"
                mat-raised-button
                color="primary"
                (click)="openDeletedPhoto()"
                [disabled]="!deletedImages.length">

                {{ 'drawings.deleted' | translate }}
            </button>
        </div>
    </div>

    <mat-card fxLayout="column" fxFill>
        <mat-card-content fxLayout="column" fxFlex [hidden]="loading">
            <div *ngIf="editMode" fxLayout="column" fxFill fxLayoutGap="15px">
                <app-edit-drawing
                    [drawing]="drawingForEdit"
                    (actionClick)="doEditAction($event)" fxFill>
                </app-edit-drawing>
            </div>
            <div [hidden]="loading" *ngIf="!editMode" class="drawings-viewer-cards">
                <div fxLayout="row wrap" fxLayoutGap="7px">
                    <app-photo-card *ngFor="let drawing of drawings"
                        [photo]="drawing"
                        [config]="drawingCardConfig"
                        (cardEvent)="doCardAction($event)">
                    </app-photo-card>
                </div>
            </div>
        </mat-card-content>
        <dots-loader [hidden]="!loading" fxFlex fxFlexAlign="center"></dots-loader>
    </mat-card>
</div>