<div id="project-attributes-card-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="40px" fxFill>
    <div fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-icon>info</mat-icon>
        <span>{{ 'shared.projectAttributesCard.additionalProjectInfo'| translate }}</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
        <button *ngIf="!readOnly" mat-raised-button color="primary" (click)="addingAttributes=true">{{ 'shared.projectAttributesCard.addProjectAttribute' | translate }}</button>

        <div *ngIf="addingAttributes" fxLayout="column" [formGroup]="formGroup">
            <mat-error *ngIf="formGroup.invalid">{{ 'shared.projectAttributesCard.requiredField'| translate }}</mat-error>
            <div fxLayoutGap="20px">
                <mat-form-field>
                    <mat-label>{{ 'shared.projectAttributesCard.type'| translate }}</mat-label>
                    <mat-select formControlName="type" required>
                        <mat-option value="mileage">{{ 'shared.projectAttributesCard.mileage'| translate }}</mat-option>
                        <mat-option value="roomNBoard">{{ 'shared.projectAttributesCard.roomAndBoard'| translate }}</mat-option>
                        <mat-option value="zonePay">{{ 'shared.projectAttributesCard.zonePay'| translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'shared.projectAttributesCard.psa'| translate }}</mat-label>
                    <mat-select formControlName="union"  required>
                        <mat-option *ngFor="let union of dropdowns.union" [value]="union">
                            {{ union.codeValueDesc }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayoutGap="20px">
                <mat-form-field fxFlex="17">
                    <mat-label>{{ 'shared.projectAttributesCard.rate'| translate }}</mat-label>
                    <input type="number" matInput min="0" formControlName="rate" required/>
                    <mat-error *ngIf="formGroup.get('rate').invalid">
                        {{ currencyValidator.message }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="15" *ngIf="type==='zonePay'">
                    <mat-label>{{ 'shared.projectAttributesCard.rateType'| translate }}</mat-label>
                    <mat-select formControlName="rateType">
                        <mat-option *ngFor="let rateType of dropdowns.rateType" [value]="rateType">
                            {{ rateType.codeValueDesc }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="25">
                    <mat-label>{{ 'shared.projectAttributesCard.effectiveDate'| translate }}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="effectiveDate" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <div fxFlexLayout="center center"><button mat-raised-button color="primary"(click)="addAttribute()">{{ 'shared.add' | translate }}</button></div>
                <div fxFlexLayout="center center"><button mat-raised-button color="primary"(click)="cancel()">{{ 'shared.cancel' | translate }}</button></div>
            </div>
        </div>

        <div *ngFor="let rows of attributes" >
            <div *ngFor="let record of rows.records" fxLayoutAlign="space-between center">
                <div fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="40">
                    <mat-icon [matTooltip]="rows.toolTip">{{ rows.icon }}</mat-icon>
                    <grid-display-item [title]="translate.instant('shared.projectAttributesCard.psa')" [content]="record.union.codeValueDesc"></grid-display-item>
                </div>

                <grid-display-item [title]="translate.instant('shared.projectAttributesCard.rate')" [content]="record.rate" [fxFlex]="!record.rateType ? 30 : 15" [editOptions]="readOnly ? null : editOptions" (contentUpdate)="updateRate(record, $event)"></grid-display-item>

                <ng-container>
                    <grid-dropdown-item *ngIf="record.rateType && !readOnly" [title]="translate.instant('shared.projectAttributesCard.rateType')" [selected]="record.rateType.codeValue" (selectedChange)="updateRateType(record, $event)" [options]="rateTypes" fxFlex="15"></grid-dropdown-item>
                    <grid-display-item *ngIf="record.rateType && readOnly" [title]="translate.instant('shared.projectAttributesCard.rateType')" [content]="record.rateType.codeValueDesc" fxFlex="15"></grid-display-item>
                </ng-container>

                <grid-display-item [title]="translate.instant('shared.projectAttributesCard.effectiveDate')" [content]="record.effDate | date: 'yyyy-MM-dd' : 'UTC'" [fxFlex]="readOnly || record.change !== createdStatus ? 30 : 20"></grid-display-item>

                <button [fxFlex]="10" *ngIf="!readOnly && record.change === createdStatus" mat-icon-button color="warn" (click)="removeAttribute(record, rows.field)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="center center" *ngIf="!infoExists && readOnly">
        <span>{{ 'shared.projectAttributesCard.noAdditionalInfoForProjectMessage' | translate }}</span>
    </div>
</div>
