import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';

import CustomerInfo from '../../../core/data-models/project-general-info/customer-info';
import EstimationInfo from '../../../core/data-models/quote-general-info/estimation-info';
import QuoteBasicInfo from '../../../core/data-models/quote-general-info/quote-basic-info';
import QuoteGeneralInfo from '../../../core/data-models/quote-general-info/quote-general-info';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import InformationGridItem from '../../../core/data-models/information-grid/information-grid-item';
import InformationGridRow from '../../../core/data-models/information-grid/information-grid-row';
import InformationGridConfig from '../../../core/data-models/information-grid/information-grid-config';
import QuoteEditorOption from '../../../core/data-models/quote-editor-option';
import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';
import { QuoteStatusService } from '../../../core/services/quote-status/quote-status.service';
import { PercentageStringPipe } from '../../../shared/pipes/percentage-string/percentage-string.pipe';
import { CustomerIdPlaceholderPipe } from '../../../shared/pipes/customer-id-placeholder/customer-id-placeholder.pipe';
import { QuoteEditorComponent } from '../../../shared/components/quote-editor/quote-editor.component';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import { ActionProgressDialogComponent } from '../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import TimeUtility from '../../../core/services/utility/time-utility/time-utility.service';
import Branch from '../../../core/data-models/branch';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';

@Component({
    selector: 'quote-details',
    styleUrls: ['./quote-details.component.scss'],
    templateUrl: './quote-details.component.html',
    encapsulation: ViewEncapsulation.None
})
export class QuoteDetailsComponent implements OnInit {
    @Input() public activeQuoteId = '';
    @Input() public readOnly = false;
    @Input() public branches: Branch[] = [];
    public basicInfoCardConfig: InformationGridConfig<QuoteBasicInfo>;
    public customerCardConfig: InformationGridConfig<CustomerInfo>;
    public estimationCardConfig: InformationGridConfig<EstimationInfo>;
    private _generalInfo: QuoteGeneralInfo = null;
    private _animationDisabled = false;
    private _isContentLoading = false;
    private _canDelete = false;
    // eslint-disable-next-line
    constructor(private _quoteHttp: QuoteHttpService,
                private _downloadHttp: DownloadHttpService,
                private _quoteStatusService: QuoteStatusService,
                private _currencyPipe: CurrencyPipe,
                private _percentageStringPipe: PercentageStringPipe,
                private _customerIdPlaceholderPipe: CustomerIdPlaceholderPipe,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _state: StateService,
                private _featureFlagService: FeatureFlagService,
                public translate: TranslateService) { }

    get generalInfo(): QuoteGeneralInfo {
        return this._generalInfo;
    }

    get isContentLoading(): boolean {
        return this._isContentLoading;
    }

    get allowAnimation(): boolean {
        return !this._animationDisabled && !this.readOnly;
    }

    get canDelete(): boolean {
        return this._canDelete;
    }

    public async ngOnInit(): Promise<void> {
        await this.loadQuoteContent();
        await this.checkDeleteStatus();
    }

    private async loadQuoteContent(): Promise<void> {
        this._isContentLoading = true;
        this._animationDisabled = false;
        this._generalInfo = await this._quoteHttp.getQuoteGeneralInfo(this.activeQuoteId);
        this._isContentLoading = false;

        if (!this._generalInfo) {
            this._state.go('quotes.notFound');

            return;
        }

        const { basicInfo, customer, estimation } = this._generalInfo;
        this.basicInfoCardConfig = await this.createBasicInfoCardConfig(basicInfo);
        this.customerCardConfig = this.createCustomerCardConfig(customer);
        this.estimationCardConfig = this.createEstimationCardConfig(estimation);

        setTimeout(() => {
            this._animationDisabled = true;
        }, 1500);
    }

    private async checkDeleteStatus(): Promise<void> {
        if (!this._generalInfo) {
            return;
        }

        const { basicInfo } = this._generalInfo;
        const status = await this._quoteHttp.getAllowedStatusForDelete();
        this._canDelete = status.includes(basicInfo.status);
    }

    private async createBasicInfoCardConfig(basicInfo: QuoteBasicInfo): Promise<InformationGridConfig<QuoteBasicInfo>> {
        const isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        const quoteIdLabel = this.translate.instant('quote.details.quoteId');
        const quoteNameLabel = this.translate.instant('quote.details.quoteName');
        const branchLabel = this.translate.instant('quote.details.branch');
        const salesCodeLabelKey = isInternal ? 'quote.details.salesCode' : 'quote.details.projectType';
        const salesCodeLabel = this.translate.instant(salesCodeLabelKey);
        const statusLabel = this.translate.instant('quote.details.status');
        const originProjectIdLabel = this.translate.instant('quote.details.originProjectId');
        const destinationProjectIdLabel = this.translate.instant('quote.details.destinationProjectId');
        const createdOnLabel = this.translate.instant('quote.details.creationTime');

        const quoteId = new InformationGridItem(quoteIdLabel, 'quoteId', 33.3);
        const name = new InformationGridItem(quoteNameLabel, 'name', 66.6);
        const branch = new InformationGridItem(branchLabel, 'branch', 33.3);
        const salesCode = new InformationGridItem(salesCodeLabel, 'salesCode', 33.3);
        const status = new InformationGridItem(statusLabel, 'status', 33.3);
        const originProjectId = new InformationGridItem(originProjectIdLabel, 'originProjectId', 33.3);
        const destinationProjectId = new InformationGridItem(destinationProjectIdLabel, 'destinationProjectId', 33.3);
        const createdOn = new InformationGridItem(createdOnLabel, 'createdOn', 33.3);

        const creationTime = new Date(basicInfo.createdOn);
        const branchTimezone = this.branches.find(_ => _.name === basicInfo.branch)?.geoLocation.timeZone;
        const branchLocalTime = TimeUtility.toBranchLocalDateTime(creationTime, branchTimezone);
        const statusText = await this._quoteStatusService.getStatusTranslationByUserLanguage(basicInfo.status);

        const rows = [
            new InformationGridRow([quoteId, name]),
            new InformationGridRow([branch, salesCode, status]),
            new InformationGridRow([originProjectId, destinationProjectId, createdOn])
        ];

        return new InformationGridConfig({ ...basicInfo, createdOn: branchLocalTime, status: statusText }, rows);
    }

    private createCustomerCardConfig(customer: CustomerInfo): InformationGridConfig<CustomerInfo> {
        const customerNameLabel = this.translate.instant('quote.details.customerName');
        const customerClassLabel = this.translate.instant('quote.details.customerClass');
        const siteNameLabel = this.translate.instant('quote.details.siteName');
        const customerIdLabel = this.translate.instant('quote.details.customerID');
        const siteIdLabel = this.translate.instant('quote.details.siteID');
        const customerName = new InformationGridItem(customerNameLabel, 'customerName', 65);
        const customerClass = new InformationGridItem(customerClassLabel, 'customerClass', 35);
        const siteName = new InformationGridItem(siteNameLabel, 'siteName');
        const customerId = new InformationGridItem(customerIdLabel, 'customerId', 65);
        const siteId = new InformationGridItem(siteIdLabel, 'siteId', 35);
        customerId.displayPipe = this._customerIdPlaceholderPipe;

        const rows = [
            new InformationGridRow([customerName, customerClass]),
            new InformationGridRow([siteName]),
            new InformationGridRow([customerId, siteId])
        ];

        return new InformationGridConfig(customer, rows);
    }

    private createEstimationCardConfig(estimation: EstimationInfo): InformationGridConfig<EstimationInfo> {
        const estimatorLabel = this.translate.instant('quote.details.estimatorName');
        const hoursLabel = this.translate.instant('quote.details.estimatedHours');
        const amountLabel = this.translate.instant('quote.details.quoteAmount');
        const taxRateLabel = this.translate.instant('quote.details.taxRate');
        const totalLabel = this.translate.instant('quote.details.totalAmount');

        const estimator = new InformationGridItem(estimatorLabel, 'estimator', 60);
        const hours = new InformationGridItem(hoursLabel, 'hours', 40);
        const amount = new InformationGridItem(amountLabel, 'amount', 60);
        const taxRate = new InformationGridItem(taxRateLabel, 'taxRate', 40);
        const total = new InformationGridItem(totalLabel, 'total');
        amount.displayPipe = this._currencyPipe;
        total.displayPipe = this._currencyPipe;
        taxRate.displayPipe = this._percentageStringPipe;

        const rows = [
            new InformationGridRow([estimator, hours]),
            new InformationGridRow([amount, taxRate]),
            new InformationGridRow([total])
        ];

        return new InformationGridConfig(estimation, rows);
    }

    public async onPrint(): Promise<void> {
        const { quoteId } = this._generalInfo.basicInfo;
        const url = this._quoteHttp.getQuotePdfUrl(quoteId);
        const fileName = GenericUtilityService.getQuotePdfNameByQuoteBasicInfo(this._generalInfo.basicInfo);
        const action = this._downloadHttp.download(url, fileName, 'application/pdf');
        const ongoing = this.translate.instant('quote.details.pleaseWait');
        const failed = this.translate.instant('quote.details.failedToPrintQuote');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const data = new ActionProgressReporterOption(action, ongoing, '', '', ok, gotIt, false);

        const dialog = this._dialog.open(ActionProgressDialogComponent, {
            data,
            width: '550px',
            height: '120px'
        });

        if (!await dialog.afterClosed().toPromise()) {
            this._snackBar.open(failed, ok);
        }
    }

    public async onEdit(): Promise<void> {
        const dialog = this._dialog.open(QuoteEditorComponent, {
            data: QuoteEditorOption.getEditOption(this.activeQuoteId),
            maxWidth: '87.5vw',
            width: '87.5vw',
            height: '95vh'
        });

        const quote = await dialog.afterClosed().toPromise<QuoteGeneralInfo>();

        if (quote?.basicInfo?.quoteId) {
            this.activeQuoteId = quote.basicInfo.quoteId;
        }

        if (quote) {
            await this.loadQuoteContent();
            await this.checkDeleteStatus();
        }
    }

    public async onDelete(): Promise<void> {
        const title = this.translate.instant('quote.details.areYouSure');
        const proceed = this.translate.instant('quote.details.proceed');
        const message = this.translate.instant('quote.details.quoteWillBePermanentlyDeleted', { quoteId: this.activeQuoteId });
        const data = new ConfirmDialogOption(title, message, true, proceed);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (!await dialog.afterClosed().toPromise()) {
            return;
        }

        if (!await this._quoteHttp.deleteQuote(this.activeQuoteId)) {
            const error = this.translate.instant('quote.details.failedToDeleteQuote');
            const buttonText = this.translate.instant('shared.ok');
            this._snackBar.open(error, buttonText);
        }
        else {
            this._state.go('quotes');
        }
    }
}
