<div class="miscellaneous-options-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh">
    <form *ngIf="!readonly" class="miscellaneous-selection-form" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2.5vh">
        <mat-form-field fxFlex="85.5">
            <mat-select
                (selectionChange)="selectOption($event)"
                [placeholder]="localize('dropdownPlaceholder')"
            >
                <mat-option *ngFor="let option of unselectedOptions" [value]="option">
                    {{ option.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button fxFlex="14" (click)="addSelectedOption()" mat-stroked-button color="primary" [disabled]="selectionDisabled">
            {{ 'flynnBudgetTool.shared.add' | translate }}
        </button>
    </form>

    <form class="miscellaneous-list-form" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh">
        <span id="total-miscellaneous-cost" *ngIf="showTotalCost">{{ localize('totalCost') }}: ${{ totalCost.value | number: '0.0-0' }}</span>

        <div class="miscellaneous-option" *ngFor="let control of miscellaneousOptions.controls; let i = index" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2.5vh">
            <mat-form-field fxFlex="2 1 0">
                <mat-label>{{ localize('miscellaneous') }}</mat-label>
                <input type="text" matInput [value]="getName(control.get('typeIdentifier').value)" readonly />
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>{{ localize('numberOfUnits') }}</mat-label>
                <input type="text" matInput [formControl]="control.get('units')" [readonly]="readonly"/>
                <mat-error *ngIf="control.get('units').invalid">* {{ localize('fieldError') }}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>{{ localize('pricePerUnit') }}</mat-label>
                <input type="text" matInput [formControl]="control.get('unitPrice')" [readonly]="readonly"/>
                <mat-error *ngIf="control.get('unitPrice').invalid">* {{ localize('fieldError') }}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex>
                <mat-label>{{ localize('lineCost') }}</mat-label>
                <input type="text" matInput [value]="control.get('totalCost')?.value | number: '0.0-0'" readonly />
            </mat-form-field>

            <button *ngIf="!readonly" mat-icon-button color="warn" (click)="removeOption(i)" type="button" (keydown.enter)="$event.preventDefault()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </form>
</div>
