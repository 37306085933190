<div class="disclaimer-settings-container">
    <mat-tab-group [(selectedIndex)]="selectedIndex">
        <mat-tab [label]="translate.instant('admin.disclaimerSettings.tabs.photoReport')">
            <photo-report-disclaimer *ngIf="selectedIndex === 0"></photo-report-disclaimer>
        </mat-tab>

        <mat-tab [label]="translate.instant('admin.disclaimerSettings.tabs.conditionReport')">
            <condition-report-disclaimer *ngIf="selectedIndex === 1"></condition-report-disclaimer>
        </mat-tab>

        <mat-tab [label]="translate.instant('admin.disclaimerSettings.tabs.quote')">
            <quote-disclaimer *ngIf="selectedIndex === 2"></quote-disclaimer>
        </mat-tab>
    </mat-tab-group>
</div>
