import { Component, ViewChild, Output, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CustomerDisplayFormattingService } from '../services/customer-display-formatting/customer-display-formatting.service';
import Customer from '../../../../core/data-models/customer';
import CustomerDto from '../../../../core/data-models/customer-dto';
import CustomerSelectionDto from '../../../../core/data-models/customer-selection-dto';
import CustomerSiteDto from '../../../../core/data-models/customer-site-dto';
import SOAddress from '../../../../core/data-models/so-address';
import CustomerDisplaySummary from '../../../../core/data-models/customer-selection/customer-display-summary';
import SiteDisplaySummary from '../../../../core/data-models/customer-selection/site-display-summary';
import InformationGridConfig from '../../../../core/data-models/information-grid/information-grid-config';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CustomerCreationHttpService } from
    '../../../../core/services/http/customer-creation-http/customer-creation-http.service';

@Component({
    selector: 'customer-select-management',
    styleUrls: ['./customer-select-management.component.scss'],
    templateUrl: './customer-select-management.component.html',
    encapsulation: ViewEncapsulation.None
})

export class CustomerSelectManagementComponent implements OnInit {
    @Input() customerForSuggestions: CustomerSelectionDto;
    @Output() customerSelectComplete = new EventEmitter<CustomerSelectionDto>();
    public customerSummaryGridInfo: InformationGridConfig<CustomerDisplaySummary>;
    public jobSiteSummaryGridInfo: InformationGridConfig<SiteDisplaySummary>;
    public billingSiteSummaryGridInfo: InformationGridConfig<SiteDisplaySummary>;
    public loadAllCustomers = true;
    public creatingCustomerSite = false;
    public creatingCustomerBillingSite = false;
    public isEditable = true;
    public allCustomerInfoSelected = false;
    public customerSelectionFormGroup: UntypedFormGroup;
    public siteSelectionFormGroup: UntypedFormGroup;
    public billingSelectionFormGroup: UntypedFormGroup;

    @ViewChild('stepper') stepper: MatStepper;

    constructor(
        public translate: TranslateService,
        private _snackBar: MatSnackBar,
        private _formBuilder: UntypedFormBuilder,
        private _displayFormattingService : CustomerDisplayFormattingService,
        private _cctHttpService: CustomerCreationHttpService) {

        this.customerSelectionFormGroup = this._formBuilder.group({
            customerSelectControl: [null, Validators.required]
        });
        this.siteSelectionFormGroup = this._formBuilder.group({
            siteSelectionControl: [null, Validators.required]
        });
        this.billingSelectionFormGroup = this._formBuilder.group({
            billingSiteSelectionControl: [null, Validators.required]
        });
    }

    get customerDisplayName(): string {
        if (!this.selectedCustomer) {
            return '';
        }

        return `${this.selectedCustomer?.name} (${this.selectedCustomer?.customerId})`;
    }

    get billingDisplayName(): string {
        return `${this.selectedBillingSite?.name} (${this.selectedBillingSite?.shipToId})`;
    }

    get billingSites(): SOAddress[] {
        if (this.selectedCustomer?.addresses?.length > 0) {
            return this.selectedCustomer.addresses.filter(_ => _.isBillAddress);
        }

        return [];
    }

    get hasAtLeastOneBillingSite(): boolean {
        return this.billingSites.length > 0;
    }

    get selectedCustomer(): Customer {
        return this.customerSelectionFormGroup.get('customerSelectControl').value as Customer;
    }

    get selectedJobSite(): CustomerSiteDto {
        return this.siteSelectionFormGroup.get('siteSelectionControl').value as CustomerSiteDto;
    }

    get selectedBillingSite(): CustomerSiteDto {
        return this.billingSelectionFormGroup.get('billingSiteSelectionControl').value as CustomerSiteDto;
    }

    public async ngOnInit(): Promise<void> {
        if (this.customerForSuggestions) {
            const customer = await this._cctHttpService.getCustomerById(this.customerForSuggestions.customer.customerId);
            this.selectCustomer(customer);
        }
    }

    public nextStepSelectedFromTopStepperNavigationBar(stepperChange: StepperSelectionEvent): void {

        if (stepperChange.selectedIndex === 2 && this.billingSites?.length === 0) {
            const message = this.translate.instant('shared.customerSelectManagement.noBillingSitesErrorMessage');
            const okLabel = this.translate.instant('snackbars.ok');
            this._snackBar.open(message, okLabel);

            return;
        }

        if (stepperChange.selectedIndex !== 3) {
            this.allCustomerInfoSelected = false;
            this.jobSiteSummaryGridInfo = null;
            this.billingSiteSummaryGridInfo = null;
            this.customerSummaryGridInfo = null;
            return;
        }

        const jobLabel = this.translate.instant('shared.job');
        const billingLabel = this.translate.instant('shared.billing');

        this.customerSummaryGridInfo = this._displayFormattingService
            .createCustomerInfoCardConfig(this.selectedCustomer, '1');
        this.jobSiteSummaryGridInfo = this._displayFormattingService
            .createSiteInfoCardConfig(this.selectedJobSite, jobLabel);
        this.billingSiteSummaryGridInfo = this._displayFormattingService
            .createSiteInfoCardConfig(this.selectedBillingSite, billingLabel);
        this.allCustomerInfoSelected = true;
    }

    public toggleSelectSite(): void {
        this.creatingCustomerSite = !this.creatingCustomerSite;
    }

    public toggleSelectBillingSite(): void {
        this.creatingCustomerBillingSite = !this.creatingCustomerBillingSite;
    }

    private handleSiteInformationUpdate(newSiteUpdate: CustomerSiteDto,
        updatedSiteForm: AbstractControl, pairSite: CustomerSiteDto, pairSiteForm: AbstractControl): void {
        if (!newSiteUpdate) {
            updatedSiteForm.setValue(null);
            return;
        }

        const newAddress = new SOAddress(newSiteUpdate);
        const pairSitesShipToId = pairSite?.shipToId;
        if (newSiteUpdate && pairSitesShipToId && newSiteUpdate.shipToId === pairSitesShipToId) {
            pairSiteForm.setValue(newSiteUpdate);
        }
        this.addAnAddressToCustomersList(newAddress);
        updatedSiteForm.setValue(newSiteUpdate);

    }

    public newSiteRequestUpdated(newSite: CustomerSiteDto): void {

        const jobSiteFormControl = this.siteSelectionFormGroup.get('siteSelectionControl');
        const billingFormControl = this.billingSelectionFormGroup.get('billingSiteSelectionControl');
        this.handleSiteInformationUpdate(newSite, jobSiteFormControl, this.selectedBillingSite, billingFormControl);
    }

    public newBillingSiteRequestUpdated(newBillingSite: CustomerSiteDto): void {

        const jobSiteFormControl = this.siteSelectionFormGroup.get('siteSelectionControl');
        const billingFormControl = this.billingSelectionFormGroup.get('billingSiteSelectionControl');
        this.handleSiteInformationUpdate(newBillingSite, billingFormControl, this.selectedJobSite, jobSiteFormControl);

    }

    public selectCustomer(customer: Customer): void {
        if (!customer) {
            this.customerSelectComplete.emit(null);
            return;
        }
        this.customerSelectionFormGroup.get('customerSelectControl').setValue(customer);
        this.siteSelectionFormGroup.get('siteSelectionControl').setValue(null);
        this.billingSelectionFormGroup.get('billingSiteSelectionControl').setValue(null);
        this.creatingCustomerBillingSite = false;
        this.creatingCustomerSite = false;

        this.stepper.next();
    }

    public selectSite(site: SOAddress): void {
        if (!site) {
            this.toggleSelectSite();
            return;
        }
        const selectedSite = new CustomerSiteDto(site);
        this.siteSelectionFormGroup.get('siteSelectionControl').setValue(selectedSite);
        this.stepper.next();

    }

    public selectBillingSite(site: SOAddress): void {
        if (!site) {
            this.toggleSelectBillingSite();
            return;
        }

        this.billingSelectionFormGroup.get('billingSiteSelectionControl').setValue(new CustomerSiteDto(site));
        this.stepper.next();
    }

    public confirmCustomerDecision(confirmed: boolean): void {
        if (confirmed) {

            const selectedCustomer = this.customerSelectionFormGroup.get('customerSelectControl').value as Customer;
            const selectedSite = this.siteSelectionFormGroup.get('siteSelectionControl').value as CustomerSiteDto;
            const selectedBillingSite =
                this.billingSelectionFormGroup.get('billingSiteSelectionControl').value as CustomerSiteDto;

            this.customerSelectComplete.emit({
                customer: new CustomerDto(selectedCustomer),
                site: selectedSite,
                billingSite: selectedBillingSite
            } as CustomerSelectionDto);

        }

    }

    public nextStep(stepCommand: string): void {

        if (stepCommand === 'next') {
            this.stepper.next();
        }
        else {
            this.stepper.previous();
        }
    }

    private addAnAddressToCustomersList(address: SOAddress): void {

        const customer = this.customerSelectionFormGroup.get('customerSelectControl').value;
        const existingIndex = customer.addresses.findIndex(_ => _.shipToId === address.shipToId);
        if (existingIndex !== -1) {
            customer.addresses[existingIndex] = address;
        }
        else {
            customer.addresses.unshift(address);
        }
        this.customerSelectionFormGroup.get('customerSelectControl').setValue(customer);

    }

}
