<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" class="receipt-container">
    <mat-card fxLayout="row" class="receipt-card">
        <kendo-grid class="receipt-grid" [data]="group" [kendoGridExpandDetailsBy]="expandDetailsBy" [(expandedDetailKeys)]="expandedDetailKeys" (cellClick)="clickedRow($event)">

            <kendo-grid-column field="uploader.fullName" title="{{ 'receipts.techName' | translate }}" [headerStyle]="{width: '95%'}" [style]="{width: '95%'}"></kendo-grid-column>
            <kendo-grid-column class="receipt-action-column" title="{{ 'receipts.actions' | translate }}" [headerStyle]="{width: '5%'}" [style]="{width: '5%'}">

                <ng-template kendoGridCellTemplate let-dataItem>
                        <button color="primary" (click)="downloadAllForTech(dataItem.uploader)" [disabled]="loading" class="mat-icon-button" matTooltip="{{ 'receipts.downloadAllForTech' | translate: { tech: dataItem.uploader.fullName } }}">
                            <mat-icon>download</mat-icon>
                        </button>
                </ng-template>

            </kendo-grid-column>
            <div *kendoGridDetailTemplate="let dataItem">
                <kendo-grid scrollable="none" [data]="dataItem.files" class="receipts-grid-inner-div">

                    <kendo-grid-column title="{{ 'receipts.fileName' | translate }}" [headerStyle]="{width: '79%'}" [style]="{width: '79%'}">

                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.path.split('/').pop() }}
                        </ng-template>

                    </kendo-grid-column>
                    <kendo-grid-column title="{{ 'receipts.creationTime' | translate }}" [headerStyle]="{width: '12%'}" [style]="{width: '12%'}">

                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{ dataItem.creationTime | branchLocalTime : currentUserBranch : branches : true }}
                        </ng-template>

                    </kendo-grid-column>
                    <kendo-grid-column class="inner-action-column" title="{{ 'receipts.actions' | translate }}" [headerStyle]="{width: '9%'}" [style]="{width: '9%'}">

                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div fxLayout="row" fxLayoutAlign="space-around center">
                                <button color="primary" [disabled]="loading" (click)="downloadByPath(dataItem.path)" mat-icon-button matTooltip="{{ 'receipts.download' | translate }}">
                                    <mat-icon>download</mat-icon>
                                </button>
                                <button color="primary" [disabled]="loading" (click)="previewByPath(dataItem.path)" mat-icon-button matTooltip="{{ 'receipts.preview' | translate }}">
                                    <mat-icon>preview</mat-icon>
                                </button>
                                <button color="warn" [disabled]="loading" (click)="deleteByPath(dataItem.path)" mat-icon-button matTooltip="{{ 'receipts.delete' | translate }}">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </ng-template>

                    </kendo-grid-column>

                </kendo-grid>
            </div>

        </kendo-grid>
    </mat-card>
</div>
