<dialog-action-card class="budget-template-upload-dialog-container"
    [icon]="'cloud_upload'"
    [title]="translate.instant('admin.budgetTemplateManagement.uploadDialog.title')"
    [isLoaded]="true"
    (close)="onClose(false)">

    <div class="budget-template-upload-dialog-content" fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field fxFlex="60">
                <mat-label>{{ 'admin.budgetTemplateManagement.uploadDialog.nameLabel' | translate }}</mat-label>
                <input type="text" matInput cdkFocusInitial [formControl]="name" />
                <mat-error *ngIf="name.invalid">* {{ 'shared.emptyNameErrorText' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="35">
                <mat-label>{{ 'admin.budgetTemplateManagement.uploadDialog.versionLabel' | translate }}</mat-label>
                <input type="number" matInput step="0.1" [formControl]="version" />

                <mat-error *ngIf="version.invalid">
                    * {{ 'admin.budgetTemplateManagement.uploadDialog.minimumVersionError' | translate: { minVersion: minVersion } }}
                </mat-error>
            </mat-form-field>
        </div>

        <ngx-file-drop *ngIf="showFileUpload"
            dropZoneClassName="upload-file-drop-zone"
            accept=".xlsm"
            (onFileDrop)="onBudgetTemplateFileDrop($event)">

            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="upload-file-drop-content" fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                        <mat-icon>get_app</mat-icon>
                        <span>{{ 'admin.budgetTemplateManagement.uploadDialog.dropFile' | translate }}</span>
                    </div>

                    <div class="upload-file-upload-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <button mat-stroked-button color="primary" (click)="openFileSelector()">
                            {{ 'shared.uploadFileDialog.browseFile' | translate }}
                        </button>

                        <span [ngClass]="{ 'upload-file-no-file': !file }">{{ fileName }}</span>
                    </div>
                </div>
            </ng-template>
        </ngx-file-drop>
    </div>

    <div class="budget-template-upload-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" [disabled]="!canUpload" (click)="onClose()">
            {{ 'admin.budgetTemplateManagement.uploadDialog.upload' | translate }}
        </button>
    </div>
</dialog-action-card>
