<dialog-action-card class="zero-safety-reason-dialog-container"
    [icon]="'notification_important'"
    [title]="translate.instant('projects.zeroSafety.title')"
    [isLoaded]="true"
    (close)="onClose(false)">

    <div class="zero-safety-reason-dialog-content" fxLayout="column" fxLayoutGap="15px">
        <mat-label>{{ message }}</mat-label>

        <mat-form-field class="zero-safety-reason-dialog-input">
            <mat-label>{{ 'projects.zeroSafety.typeReason' | translate }}</mat-label>

            <textarea matInput
                cdkFocusInitial
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="8"
                [formControl]="reasonInput"
                required>
            </textarea>

            <mat-error *ngIf="reasonInput.invalid">{{ 'projects.zeroSafety.errorHelperText' | translate}}</mat-error>
        </mat-form-field>

        <div class="zero-safety-reason-dialog-foremen-rating" *ngIf="foremanRatings.length > 1" fxLayout="column">
            <mat-label>{{ 'projects.zeroSafety.safetyQuestion' | translate }}</mat-label>

            <mat-checkbox *ngFor="let rating of foremanRatings"
                [(ngModel)]="rating.checklist[0]"
                (ngModelChange)="rating.checklist[0] = $event ? 1 : 0">

                {{ getForemanName(rating.foreman) }}
            </mat-checkbox>
        </div>
    </div>

    <div class="zero-safety-reason-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="onClose()">{{ 'projects.zeroSafety.proceed' | translate }}</button>
    </div>
</dialog-action-card>
