import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProjectDispatchOptions } from '../../../../../core/enums/project-dispatch-options.enum';
// eslint-disable-next-line
import { RedispatchManagementDialogComponent } from '../../project-details-action-bar/dispatch/redispatch-management-dialog/redispatch-management-dialog.component';
// eslint-disable-next-line
import { DispatchManagementDialogComponent } from '../../project-details-action-bar/dispatch/dispatch-management-dialog/dispatch-management-dialog.component';
import DispatchExistingOptions from '../../../../../core/data-models/project-general-info/dispatch-existing-options';

@Injectable({
    providedIn: 'root'
})
export class ProjectDetailsDispatchService {

    constructor(private _dialog: MatDialog) { }

    public async handleDispatchEvent(dispatchOption: string, dispatchExistingOptions: DispatchExistingOptions): Promise<boolean> {

        switch (dispatchOption) {
            case ProjectDispatchOptions.DispatchNewJob:
                return await this.dispatchNewReport(dispatchExistingOptions);
            case ProjectDispatchOptions.Redispatch:
                return await this.redispatchReport(dispatchExistingOptions);
            default:
                return false;
        }
    }

    private async redispatchReport(dispatchExistingOptions: DispatchExistingOptions): Promise<boolean> {
        const dialog = this._dialog.open(RedispatchManagementDialogComponent, {
            data: dispatchExistingOptions,
            minWidth: '1150px',
            maxWidth: '85vw',
            minHeight: '55vh',
            maxHeight: '95vh'
        });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private async dispatchNewReport(dispatchExistingOptions: DispatchExistingOptions): Promise<boolean> {
        const dialog = this._dialog.open(DispatchManagementDialogComponent, {
            data: dispatchExistingOptions,
            minWidth: '1150px',
            maxWidth: '85vw',
            maxHeight: '95vh'
        });

        return await dialog.afterClosed().toPromise<boolean>();
    }

}
