<div fxLayout="column" fxLayoutGap="20px" fxFill class="quote-attachment-container">
    <div fxLayout="row" class="toggle-buttons-row">
        <mat-button-toggle-group [(ngModel)]="currentOption">
            <mat-button-toggle *ngFor="let option of options" [value]="option.name">
                <span>{{ option.displayName }}</span>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div fxLayout="column" fxFlex *ngIf="currentOption !== 'Photos' && !loading" fxLayoutGap="20px">
        <div fxLayout="row" fxFlex="14" fxLayoutAlign="center center">
            <div *ngIf="currentOption === 'Upload Attachment'" fxFlex="90">
                <ngx-file-drop dropZoneClassName="upload-file-drop-zone" dropZoneLabel="{{ 'shared.quote.editor.dropFilesHere' | translate }}" (onFileDrop)="dropped($event)">
                    <ng-template
                        ngx-file-drop-content-tmp
                        let-openFileSelector="openFileSelector">
                        <div class="upload-file-drop-content" fxLayout="row" fxLayoutAlign="center center">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                                <mat-icon>get_app</mat-icon>
                                <span>{{ 'shared.quote.editor.dragAndDrop' | translate }}</span>
                            </div>
                            <div class="upload-file-upload-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <button mat-stroked-button color="primary" (click)="openFileSelector()">
                                    {{ 'shared.quote.editor.browseFiles' | translate }}
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </ngx-file-drop>
            </div>
        </div>

        <div fxFlex="85">
            <kendo-grid [kendoGridBinding]="attachments" [pageSize]="10" [pageable]="true" [style.height.%]="100" >
                <kendo-grid-column width="50" class="file-icon-div">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <div class="file-icon-text" [style.background-color]="getColorByFileType(dataItem.name)">
                            <div>
                                {{dataItem.name.split('.').pop()}}
                            </div>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="name" title="{{ 'shared.quote.editor.name' | translate }}">
                </kendo-grid-column>
                <kendo-grid-column field="type" title="{{ 'shared.quote.editor.type' | translate }}">
                </kendo-grid-column>
                <kendo-grid-column width="100">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button (click)="delete(dataItem)" mat-icon-button color="warn" aria-label="delete button" [matTooltip]="translate.instant('shared.quote.editor.delete')">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button (click)="download(dataItem)" mat-icon-button color="primary" aria-label="download button" *ngIf="!dataItem.isCopy" [matTooltip]="translate.instant('shared.quote.editor.download')">
                                <mat-icon>cloud_download</mat-icon>
                            </button>
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </div>
    <div fxLayout="column" fxFlex *ngIf="currentOption === 'Photos' && !loading" class="photo-cards-view">
        <div>
            <div class="quote-attachments-photos" fxLayout="row wrap" fxLayoutGap="7px">
                <app-photo-card *ngFor="let image of photos"
                    [photo]="image"
                    [config]="imageCardConfig"
                    fxFlexAlign="stretch">
                </app-photo-card>
            </div>
            <div fxLayout="row" *ngIf="photos.length === 0" fxFill fxLayoutAlign="center center">
                <label>{{ 'shared.quote.editor.noPhotosInQuoteAttachments' | translate }}</label>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxFlex *ngIf="loading" fxLayoutAlign="center center">
        <dots-loader></dots-loader>
    </div>
</div>
