import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { TypeRecord } from '../../../core/data-models/generic/type-record';
import { LineItemExpense } from '../../../core/data-models/budget-estimations/expenses/line-item-expense';
import { ValidatorService } from '../../../core/services/validator-service/validator-service';
import { ItemExpense } from '../../../core/data-models/budget-estimations/expenses/item-expense';
import { ServiceOperationsMiscellaneousSection } from '../../../core/data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-miscellaneous-section';
import { ServiceOperationsMaterialsSection } from '../../../core/data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-materials-section';
import { ServiceOperationsLaborSection } from '../../../core/data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-labor-section';
import { ServiceOperationsQualityControlFeesSection } from '../../../core/data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-quality-control-fees-section';
import { ServiceOperationsSummarySection } from 'src/flynn-budget-tool/core/data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-summary-section';
import { ServiceOperationsQuestionsSection } from '../../../core/data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-questions-section';
import { CheckTypeRecord } from '../../../core/data-models/budget-estimations/custom-type-records/check-type-record';
@Injectable({ providedIn: 'root' })
export class BudgetEstimationService {
    constructor(private _formBuilder: UntypedFormBuilder, private _validatorService: ValidatorService) { }

    public createMiscellaneousSection(section: ServiceOperationsMiscellaneousSection): UntypedFormGroup {
        return this._formBuilder.group({
            entries: this._formBuilder.array(section.entries.map(_ => this.createMiscellaneousFormField(_))),
            totalCost: [ section.totalCost ]
        });
    }

    public createMiscellaneousFormField(item: ItemExpense): UntypedFormGroup {
        return this._formBuilder.group({
            typeIdentifier: [item.typeIdentifier],
            units: [item.units, [ this._validatorService.decimalValidator(2, 0.01), Validators.required ] ],
            unitPrice: [item.unitPrice, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ],
            totalCost: [item.totalCost, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ]
        });
    }

    public createMaterialsSection(section: ServiceOperationsMaterialsSection): UntypedFormGroup {
        return this._formBuilder.group({
            entries: this._formBuilder.array(section.entries.map(_ => this.createMaterialsFormField(_))),
            totalCost: [ section.totalCost ],
            totalCostIntoStock: [ section.totalCostIntoStock ]
        });
    }

    public createMaterialsFormField(item: ItemExpense | null = null): UntypedFormGroup {
        const isCustom = !item || item.isCustom;

        return this._formBuilder.group({
            typeIdentifier: [item?.typeIdentifier ?? uuidV4()],
            units: [item?.units ?? 0, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ],
            unitPrice: [item?.unitPrice, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ],
            totalCost: [item?.totalCost, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ],
            isCustom: [isCustom, [ Validators.required ] ],
            customName: [item?.customName ?? null]
        });
    }

    public createLaborSection(section: ServiceOperationsLaborSection): UntypedFormGroup {
        const { branchLaborCost, miscellaneousLaborCost, totalCost } = section;

        return this._formBuilder.group({
            branchLaborCost: this._formBuilder.group({
                hours: [ branchLaborCost.hours, [ this._validatorService.decimalValidator(2, 0.01), Validators.required ] ],
                hourlyRate: [ branchLaborCost.hourlyRate, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ],
                totalCost: [ branchLaborCost.totalCost, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ]
            }),
            miscellaneousLaborCost: this._formBuilder.group({
                hours: [ miscellaneousLaborCost.hours, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ],
                hourlyRate: [ miscellaneousLaborCost.hourlyRate, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ],
                totalCost: [ miscellaneousLaborCost.totalCost, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ]
            }),
            totalCost: [ totalCost, [ this._validatorService.decimalValidator(2, 0), Validators.required ] ]
        });
    }

    public createQualityControlFeesSection(section: ServiceOperationsQualityControlFeesSection): UntypedFormGroup {
        const formGroup = this._formBuilder.group({
            hasDollarCost: [section.hasDollarCost],
            hasPercentageCost: [section.hasPercentageCost],
            totalDollarCost: [section.totalDollarCost, [this._validatorService.decimalValidator(2, 0.01), Validators.required]],
            percentageCost: this._formBuilder.group({
                rate: [section.percentageCost.rate, [Validators.required]],
                totalCost: [section.percentageCost.totalCost, [this._validatorService.decimalValidator(2, 0.01), Validators.required]]
            })
        });

        formGroup.get('hasDollarCost').valueChanges.pipe(startWith(section.hasDollarCost)).subscribe((hasDollarCost: boolean) => {
            this.setControlState(formGroup, 'totalDollarCost', hasDollarCost);
        });

        formGroup.get('hasPercentageCost').valueChanges.pipe(startWith(section.hasPercentageCost)).subscribe((hasPercentageCost: boolean) => {
            this.setControlState(formGroup, 'percentageCost.rate', hasPercentageCost);
            this.setControlState(formGroup, 'percentageCost.totalCost', hasPercentageCost);
        });

        return formGroup;
    }

    public createSummarySection(section: ServiceOperationsSummarySection): UntypedFormGroup {
        const customLineItemExpensesFields = section.customLineItemExpenses.map(expense => this.createCustomLineItemExpenseField(expense));

        return this._formBuilder.group({
            totalCostWithoutQualityControlPercentageCost: section.totalCostWithoutQualityControlPercentageCost,
            totalGrossProfit: section.totalGrossProfit,
            grossProfitPercentageOverride: section.grossProfitPercentageOverride,
            grossProfitPercentageSetByUser: section.grossProfitPercentageSetByUser,
            totalSalePrice: section.totalSalePrice,
            totalIndirectCost: section.totalIndirectCost,
            totalInsuranceCost: section.totalInsuranceCost,
            totalQualityControlPercentageCost: section.totalQualityControlPercentageCost,
            priceOverride: [section.priceOverride, [this._validatorService.decimalValidator(2, 0), Validators.required]],
            priceOverrideSetByUser: section.priceOverrideSetByUser,
            customLineItemExpenses: this._formBuilder.array(customLineItemExpensesFields)
        });
    }

    public createCustomLineItemExpenseField(expense: LineItemExpense<TypeRecord> | null = null): UntypedFormGroup {
        return this._formBuilder.group({
            identifier: this._formBuilder.group({
                identifier: expense?.identifier.identifier,
                name: expense?.identifier.name
            }),
            percentageRate: expense?.percentageRate,
            totalCost: expense?.totalCost
        });
    }

    public createQuestionsSection(section: ServiceOperationsQuestionsSection): UntypedFormGroup {
        const questions = section.questions.map(question => this.createLeadQuestionField(question));
        return this._formBuilder.group({
            questions: this._formBuilder.array(questions)
        });
    }

    public createLeadQuestionField(question: CheckTypeRecord): UntypedFormGroup {
        return this._formBuilder.group({
            identifier: [question.identifier],
            name: [question.name],
            checked: [question.checked, [Validators.requiredTrue]]
        });
    }

    private setControlState(formGroup: UntypedFormGroup, key: string, isEnabled: boolean): void {
        if (isEnabled) {
            formGroup.get(key).enable();
        }
        else {
            formGroup.get(key).disable();
        }
    }
}
