import ForemanRating from './project-review/foreman-rating';

export default class ZeroSafetyReason {
    public comment = '';
    public foremanRatings: ForemanRating[] = [];

    constructor(comment: string, foremanRatings: ForemanRating[]) {
        this.comment = comment;
        this.foremanRatings = foremanRatings;
    }
}
