import { Component, Input, ChangeDetectionStrategy, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';

import DisableOption from '../../../../core/data-models/disable-option';
import JobBasicInfo from '../../../../core/data-models/project-general-info/job-basic-info';
import ProjectGeneralInfo from '../../../../core/data-models/project-general-info/project-general-info';
import ActionMenuItemOption from '../../../../core/data-models/action-menu-item-option';
import { ProjectDispatchOptions } from '../../../../core/enums/project-dispatch-options.enum';
import { ProjectAction } from '../../../../core/enums/project-action.enum';
import { UserPermissionService } from '../../../../core/services/user-permission/user-permission.service';
import { ProjectPrint } from '../../../../core/enums/project-print.enum';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import { WorkflowHttpService } from '../../../../core/services/http/workflow-http/workflow-http.service';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';
import LanguageUtility from '../../../../core/services/utility/language-utility/language-utility.service';
import TimeUtility from '../../../../core/services/utility/time-utility/time-utility.service';
import { BranchHttpService } from '../../../../core/services/http/branch-http/branch-http.service';
import { ProjectHttpService } from '../../../../core/services/http/project-http/project-http.service';
import { ReadPermission } from '../../../../core/enums/read-permission.enum';
import { WritePermission } from '../../../../core/enums/write-permission.enum';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import { AppEventService } from '../../../../core/services/events/app-event/app-event.service';
import ProjectSummary from '../../../../core/data-models/summary-info/project-summary';
import ProjectIdUtility from '../../../../core/services/utility/project-id-utility/project-id-utility.service';
import { ProjectStatusHttpService } from '../../../../core/services/http/project-status-http/project-status-http.service';
import ProjectStatus from '../../../../core/data-models/project-status';
import { ProjectDetailsSection } from '../../../../core/enums/project-details-section.enum';
import { MergeProjectRulesDialogComponent } from './merge-project-rules-dialog/merge-project-rules-dialog.component';

@Component({
    selector: 'project-details-action-bar',
    styleUrls: ['./project-details-action-bar.component.scss'],
    templateUrl: './project-details-action-bar.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDetailsActionBarComponent implements OnInit, OnDestroy {
    @Input() public generalInfo: ProjectGeneralInfo;
    @Input() public jobId = 0;
    @Output() public workflowSelect = new EventEmitter<string>();
    @Output() public dispatchProject = new EventEmitter<ProjectDispatchOptions>();
    @Output() public actionSelect = new EventEmitter<string>();
    @Output() public jobSelect = new EventEmitter<number>();
    @Output() public sectionSelect = new EventEmitter<ProjectDetailsSection>();
    @Output() public openSectionInNewWindow = new EventEmitter<ProjectDetailsSection>();
    @Output() public printOptionSelect = new EventEmitter<string>();
    @Output() public reloadProject = new EventEmitter();
    public readonly projectDetailsSection = ProjectDetailsSection;
    public hasPendingLocalChange = false;
    public showMergeRules = false;
    public disableActionBar = false;
    public selectedMenuItem: ProjectDetailsSection;
    private _workflows: ActionMenuItemOption[] = [];
    private _actions: ActionMenuItemOption[] = [];
    private _dispatchOptions: ActionMenuItemOption[] = [];
    private _jobOptions: ActionMenuItemOption[] = [];
    private _printOptions: ActionMenuItemOption[] = [];
    private _isComponentActive = true;
    private _projectStatuses: ProjectStatus[];

    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
                private _permission: UserPermissionService,
                private _changeDetector: ChangeDetectorRef,
                private _branchHttp: BranchHttpService,
                private _workflowHttp: WorkflowHttpService,
                private _userHttp: UserHttpService,
                private _projectHttp: ProjectHttpService,
                private _state: StateService,
                private _featureFlagService: FeatureFlagService,
                private _dialog: MatDialog,
                private _appEvents: AppEventService,
                private _projectStatusHttp: ProjectStatusHttpService) { }

    get workflows(): ActionMenuItemOption[] {
        return this._workflows;
    }

    get actions(): ActionMenuItemOption[] {
        return this._actions;
    }

    get dispatchOptions(): ActionMenuItemOption[] {
        return this._dispatchOptions;
    }

    get jobOptions(): ActionMenuItemOption[] {
        return this._jobOptions;
    }

    get printOptions(): ActionMenuItemOption[] {
        return this._printOptions;
    }

    get showToggles(): boolean {
        const job = this.currentJob;
        return job.hasWorkChecklist || job.hasContentReport || job.hasDailySummary || job.hasSummary;
    }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    get showLabor(): boolean {
        if (!this.isInternal) {
            return false;
        }

        return this.currentJob.hasSummary || this.currentJob.hasDailySummary;
    }

    get currentJob(): JobBasicInfo {
        if (this.jobId === -1) {
            return this.generalInfo.jobs.slice(-1)[0];
        }

        return this.generalInfo.jobs[this.jobId];
    }

    get currentJobOption(): string {
        if (this.jobId === -1) {
            return this.jobOptions.slice(-1)[0].text;
        }

        return this.jobOptions[this.jobId].text;
    }

    public async ngOnInit(): Promise<void> {
        this._appEvents.projectUpdated.pipe(takeWhile(() => this._isComponentActive)).subscribe(_ => this.reloadActionBar(..._));
        const { section } = this._state.params;
        const selection = this.isValidSectionView(section) ? section : ProjectDetailsSection.BasicInfo;
        this.changeProjectContentSelection(selection);
        this._projectStatuses = await this._projectStatusHttp.getFilterableProjectStatus();

        this._featureFlagService.featureFlagsChange.pipe(
            takeWhile(() => this._isComponentActive)
        ).subscribe(async() => {
            this._printOptions = await this.getAllowedPrintOptions();
            this._actions = await this.getAllowedActions();
            this._changeDetector.markForCheck();
        });

        await this.initActionBar();
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
    }

    public onJobOptionSelect(option: string): void {
        this.jobSelect.emit(this._jobOptions.findIndex(_ => _.text === option));
        this.changeProjectContentSelection(ProjectDetailsSection.BasicInfo);
    }

    public changeProjectContentSelection(selection: ProjectDetailsSection): void {
        this.selectedMenuItem = selection;
        this.sectionSelect.emit(selection);
    }

    public openProjectContentInNewWindow(event: Event, selection: ProjectDetailsSection): void {
        event.stopPropagation();
        this.openSectionInNewWindow.emit(selection);
    }

    public onMergeRulesClicked() : void {
        this._dialog.open(MergeProjectRulesDialogComponent, {
            minHeight: '300px',
            minWidth: '500px',
            maxWidth: '50vw',
            maxHeight: '80vh'
        });
    }

    public async initActionBar(): Promise<void> {
        this.showMergeRules = this.isInternal && !await this._permission.hasAdminPrivilege();
        this._workflows = await this.getAllowedWorkflows();
        this._dispatchOptions = (await this.getAllowedDispatchOptions()).filter(Boolean);
        this._actions = await this.getAllowedActions();
        this._printOptions = await this.getAllowedPrintOptions();
        await this.getJobOptions();
        this._changeDetector.markForCheck();
    }

    public onWorkflowSelect(workflow: string): void {
        this.hasPendingLocalChange = true;
        this.workflowSelect.emit(workflow);
    }

    public onDispatch(options: ProjectDispatchOptions): void {
        this.hasPendingLocalChange = true;
        this.dispatchProject.emit(options);
    }

    private isValidSectionView(section: ProjectDetailsSection): boolean {
        if (section === ProjectDetailsSection.Checklist) {
            return this.currentJob.hasWorkChecklist;
        }

        if (section === ProjectDetailsSection.JobReport) {
            return this.currentJob.hasContentReport;
        }

        if (section === ProjectDetailsSection.DailySummary) {
            return this.currentJob.hasDailySummary;
        }

        if (section === ProjectDetailsSection.Summary) {
            return this.currentJob.hasSummary;
        }

        if (section === ProjectDetailsSection.Labor) {
            return this.showLabor;
        }

        return section === ProjectDetailsSection.BasicInfo;
    }

    private async getJobOptions(): Promise<void> {
        const visitNoLabel = this.translate.instant('projects.visitNo');
        const { branchInfo } = await this._userHttp.getUserInfo();
        const { timeZone } = branchInfo.geoLocation;

        this._jobOptions = this.generalInfo.jobs.map((_, i) => {
            const date = TimeUtility.toBranchLocalDateTime(new Date(_.creationDate), timeZone, false);

            return new ActionMenuItemOption(`${visitNoLabel}.${i + 1} (${date})`);
        });
    }

    private async getAllowedWorkflows(): Promise<ActionMenuItemOption[]> {
        const { projectId } = this.generalInfo.basicInfo;
        const workflows = await this._workflowHttp.getWorkflowByProjectId(projectId);
        const user = await this._userHttp.getUserInfo();

        return workflows.map(_ => {
            const name = LanguageUtility.getLocalizedContent(user.language, _.names);
            const tooltip = LanguageUtility.getLocalizedContent(user.language, _.tooltips);
            const option = new ActionMenuItemOption(`${_.code}`, '', false, null, name);

            return { ...option, disableOption: new DisableOption(_.isDisabled, tooltip) };
        });
    }

    private async getAllowedDispatchOptions(): Promise<ActionMenuItemOption[]> {
        const { projectId, branch } = this.generalInfo.basicInfo;
        const branches = await this._branchHttp.getAllBranches();
        const projectBranch = branches.find(_ => _.name === branch);

        if (projectBranch?.isDefault) {
            return [];
        }

        const options: ActionMenuItemOption[] = [];
        const dispatchOptions = await this._projectHttp.getProjectDispatchOptions(projectId);
        const canDispatchProject = await this._permission.hasWritePermission(WritePermission.DispatchProject);

        if (dispatchOptions.hasDispatchNewVisit && canDispatchProject) {
            const label = this.translate.instant(ProjectDispatchOptions.DispatchNewJob);
            options.push(new ActionMenuItemOption(ProjectDispatchOptions.DispatchNewJob, null, null, null, label));
        }

        if (dispatchOptions.hasRedispatch && canDispatchProject) {
            const label = this.translate.instant(ProjectDispatchOptions.Redispatch);
            options.push(new ActionMenuItemOption(ProjectDispatchOptions.Redispatch, null, null, null, label));
        }

        return options;
    }

    private async getAllowedActions(): Promise<ActionMenuItemOption[]> {
        const { projectId } = this.generalInfo.basicInfo;
        const options = await this._projectHttp.getProjectActionOptions(projectId);

        const optionsMapping = [
            { type: ProjectAction.CreateFollowUpQuote, ...options.followUpQuoteCreation },
            { type: ProjectAction.SwapProjectTo312, ...options.swapTo312 },
            { type: ProjectAction.SwapProjectTo315, ...options.swapTo315 },
            { type: ProjectAction.ConvertProject, ...options.convertProject },
            { type: ProjectAction.UpdateProject, ...options.updateProject },
            { type: ProjectAction.UploadBudget, ...options.budgetUpload },
            { type: ProjectAction.MergeProject, ...options.mergeProject },
            { type: ProjectAction.UnmergeProject, ...options.unmergeProject },
            { type: ProjectAction.DeleteProject, ...options.deleteProject },
            { type: ProjectAction.ChangeServiceProvider, ...options.changeServiceProvider }
        ];

        return optionsMapping.filter(_ => _.isAllowed).map(_ => {
            const icon = this.getActionIcon(_.type);
            const label = this.translate.instant(_.type);
            const isWarning = _.type === ProjectAction.DeleteProject;

            const item = new ActionMenuItemOption(_.type, icon, isWarning, null, label);
            item.disableOption = new DisableOption(_.isDisabled, _.tooltip);

            return item;
        });
    }

    private async getAllowedPrintOptions(): Promise<ActionMenuItemOption[]> {
        if (!await this._permission.hasReadPermission(ReadPermission.ReadProjectReport)) {
            return [];
        }

        const { projectId } = this.generalInfo.basicInfo;
        const options = await this._projectHttp.getProjectReportPrintingOptions(projectId);

        const optionsMapping = [
            { type: ProjectPrint.ConditionReport, isAllowed: options.hasConditionReport },
            { type: ProjectPrint.FieldReport, isAllowed: options.hasFieldReport },
            { type: ProjectPrint.CustomerFieldReport, isAllowed: options.hasCustomerFieldReport },
            { type: ProjectPrint.SafetyReport, isAllowed: options.hasSafetyReport },
            { type: ProjectPrint.WorkOrderReport, isAllowed: options.hasWorkOrderReport },
            { type: ProjectPrint.Covid19AssessmentReport, isAllowed: options.hasCovid19AssessmentReport }
        ];

        return optionsMapping.filter(_ => _.isAllowed).map(_ => {
            const label = this.translate.instant(_.type);

            return new ActionMenuItemOption(_.type, null, null, null, label);
        });
    }

    //eslint-disable-next-line
    private getActionIcon(action: ProjectAction): string {
        switch (action) {
            case ProjectAction.CreateFollowUpQuote: return 'request_quote';
            case ProjectAction.ConvertProject: return 'autorenew';
            case ProjectAction.UploadBudget: return 'cloud_upload';
            case ProjectAction.UpdateProject: return 'create';
            case ProjectAction.DeleteProject: return 'delete';
            case ProjectAction.UnmergeProject: return 'call_split';
            case ProjectAction.MergeProject: return 'call_merge';
            case ProjectAction.SwapProjectTo312:
            case ProjectAction.SwapProjectTo315:
                return 'swap_horiz';
            case ProjectAction.ChangeServiceProvider: return 'edit_note';
            default: return '';
        }
    }

    private reloadActionBar(eventProjectId: string, summary: ProjectSummary): void {
        const oldStatus = this.generalInfo.status;
        const newStatus = this._projectStatuses.find(_ => _.code === summary?.statusCode);
        const oldId = ProjectIdUtility.toStorageFormat(this.generalInfo.basicInfo.projectId);
        const newId = ProjectIdUtility.toStorageFormat(eventProjectId);

        // Ensure that any change like updating a text field doesn't trigger action bar re-init.
        if (!this.hasPendingLocalChange && newStatus && oldId === newId && oldStatus.code !== newStatus.code) {
            this.disableActionBar = true;
            this._changeDetector.markForCheck();
        }
    }
}
