<dialog-action-card class="user-profile-editor-dialog-container"
    [icon]="'person'"
    [title]="translate.instant('admin.userEditor.userInformation')"
    [isLoaded]="!isLoading"
    (close)="onClose()">

    <div *ngIf="formGroup" class="content" fxLayout="row" fxLayoutAlign="space-around">
        <ng-container *ngIf="isInformationView">
            <form [formGroup]="formGroup" fxLayout="column" fxFlex="55">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field fxFlex="47" *ngIf="isInternal">
                        <mat-label>{{ 'admin.userEditor.employeeIdLabel' | translate }}</mat-label>
                        <input type="text" matInput formControlName="employeeId"  [readonly]="!options.isEditable"/>
                        <mat-error *ngIf="formGroup.controls.employeeId.invalid">{{ 'admin.userEditor.employeeIdError' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="47">
                        <mat-label>{{ 'admin.userEditor.userNameLabel' | translate }}</mat-label>
                        <input type="text" matInput formControlName="userName" readonly/>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field fxFlex="47">
                        <mat-label>{{ 'admin.userEditor.preferredNameLabel' | translate }}</mat-label>
                        <input type="text" matInput formControlName="preferredName" required [readonly]="!options.isEditable"/>
                        <mat-error *ngIf="formGroup.controls.preferredName.invalid">{{ 'admin.userEditor.requiredError' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="47">
                        <mat-label>{{ 'admin.userEditor.locationLabel' | translate }}</mat-label>
                        <mat-select formControlName="location">
                            <mat-option *ngFor="let branch of branches" [value]="branch.name">
                                {{branch.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.location.invalid">{{ 'admin.userEditor.requiredError' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field fxFlex="47">
                        <mat-label>{{ 'admin.userEditor.firstNameLabel' | translate }}</mat-label>
                        <input type="text" matInput formControlName="firstName" required [readonly]="!options.isEditable" />
                        <mat-error *ngIf="formGroup.controls.firstName.invalid">{{ 'admin.userEditor.requiredError' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="47">
                        <mat-label>{{ 'admin.userEditor.lastNameLabel' | translate }}</mat-label>
                        <input type="text" matInput formControlName="lastName" required [readonly]="!options.isEditable" />
                        <mat-error *ngIf="formGroup.controls.lastName.invalid">{{ 'admin.userEditor.requiredError' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field fxFlex="47">
                        <mat-label>{{ 'admin.userEditor.emailAddressLabel' | translate }}</mat-label>
                        <input type="email" matInput formControlName="emailAddress" required [readonly]="!options.isEditable" />
                        <mat-error *ngIf="formGroup.controls.emailAddress.invalid">{{ 'admin.userEditor.emailError' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="47">
                        <mat-label>{{ 'admin.userEditor.phoneNumberLabel' | translate }}</mat-label>
                        <input type="text" matInput formControlName="phoneNumber" [readonly]="!options.isEditable" appPhoneMask />
                        <mat-error *ngIf="formGroup.controls.phoneNumber.invalid">{{ 'admin.userEditor.phoneError' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-form-field fxFlex="47">
                        <mat-label>{{ 'admin.userEditor.languageLabel' | translate }}</mat-label>
                        <mat-select formControlName="language" required [disabled]="!options.isEditable">
                            <mat-option *ngFor="let lang of languages" [value]="lang.language">{{lang.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.language.invalid">{{ 'admin.userEditor.requiredError' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </form>

            <div fxLayout="column" fxFlex="35">
                <mat-error *ngIf="noRolesAssigned">* {{ 'admin.userEditor.roleError' | translate }}</mat-error>

                <div class="role-options" fxLayout="column">
                    <mat-checkbox *ngFor="let role of roles"
                        [checked]="role.checked"
                        [disabled]="!options.isEditable"
                        (change)="role.checked = !role.checked">

                        {{ role.name }}
                    </mat-checkbox>
                </div>
            </div>
        </ng-container>

        <subscription-editor *ngIf="!isInformationView"
            [subscribed]="userSubscriptions"
            [templates]="subscriptionTemplates"
            [isDisabled]="!options.isEditable"
            [isCardMode]="false"
            (update)="userSubscriptions = $event">
        </subscription-editor>
    </div>

    <div *ngIf="formGroup" class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1.25vh">
        <button mat-stroked-button color="primary" (click)="isInformationView = !isInformationView">
            <span *ngIf="isInformationView">{{ 'admin.userEditor.subscriptionViewButtonText' | translate }}</span>
            <span *ngIf="!isInformationView">{{ 'admin.userEditor.informationViewButtonText' | translate }}</span>
        </button>

        <div fxFlex></div>

        <button *ngIf="canResetPassword"
            mat-raised-button
            color="warn"
            (click)="resetPassword()"
            fxFlex="12.5">

            {{ 'admin.userEditor.resetPasswordLabel' | translate }}
        </button>

        <button *ngIf="options.isEditable"
            mat-raised-button
            color="warn"
            (click)="resetForm()"
            fxFlex="10">

            {{ 'admin.userEditor.resetLabel' | translate }}
        </button>

        <button *ngIf="options.isEditable"
            mat-raised-button
            color="primary"
            [disabled]="formGroup.invalid || noRolesAssigned"
            (click)="saveUser()"
            fxFlex="10">

            {{ 'admin.userEditor.saveLabel' | translate }}
        </button>
    </div>
</dialog-action-card>
