import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';

import Branch from '../../../core/data-models/branch';
import UserInfo from '../../../core/data-models/user-info';
import StockLog from '../../../core/data-models/job-materials/stock-log';
import StockLogQuery from '../../../core/data-models/data-report/stock-log-query';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { InvoiceHttpService } from '../../../core/services/http/invoice-http/invoice-http.service';
import { UserPermissionService } from '../../../core/services/user-permission/user-permission.service';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { ReadPermission } from '../../../core/enums/read-permission.enum';

@Component({
    selector: 'stock-log-report',
    styleUrls: ['./stock-log-report.component.scss'],
    templateUrl: './stock-log-report.component.html'
})
export class StockLogReportComponent implements OnInit {
    @Input() public branches: Branch[];
    @ViewChild(DataBindingDirective) private _dataBinding: DataBindingDirective;
    public readonly pageSize = 15;
    public user: UserInfo;
    public data: StockLog[] = [];
    public query = new StockLogQuery();
    public isBranchFilterVisible = false;
    public isLoading = false;

    constructor(public translate: TranslateService,
                private _userHttp: UserHttpService,
                private _invoiceHttp: InvoiceHttpService,
                private _permissionService: UserPermissionService,
                private _newRelicUtility: NewRelicUtilityService) { }

    public async ngOnInit(): Promise<void> {
        this.getExcelData = this.getExcelData.bind(this);
        this.user = await this._userHttp.getUserInfo();
        this.isBranchFilterVisible = await this._permissionService.hasReadPermission(ReadPermission.ViewAllBranchData);
        this.query.branchCode = this.isBranchFilterVisible ? '' : this.user.branchInfo.code;
        this.query.timeInterval.startTime = new Date(new Date().setDate(1));
    }

    public getExcelData(): ExcelExportData {
        const raw = this.data.map(_ => ({ ..._, createdTime: _.timeInfo.createdTime }));

        return { data: process(raw, {}).data };
    }

    public async loadStockLogs(): Promise<void> {
        this.isLoading = true;
        this._dataBinding.skip = 0;
        this._dataBinding.pageSize = this.pageSize;
        this.data = await this._invoiceHttp.getStockLogs(this.query);
        this.isLoading = false;
        this._newRelicUtility.addPageAction('ReportType', 'Stock Log Report', 'ReportAccess', this.query);
    }
}
