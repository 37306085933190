import { Injectable } from '@angular/core';
// eslint-disable-next-line
import { CustomerCreationHttpService } from '../../../../../core/services/http/customer-creation-http/customer-creation-http.service';
import AddressVerification from '../../../../../core/data-models/address-verification';
import AddressStatusSummary from '../../../../../core/data-models/address-status-summary';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AddressErrorReadUtilityService {

    constructor(private _cctHttp: CustomerCreationHttpService,
        private _translate: TranslateService) { }

    public async getAddressStatus(
        address: AddressVerification,
        isFormValid: boolean,
        isGeoLocated: boolean,
        isBillingAddress: boolean
    ): Promise<AddressStatusSummary> {
        const summary = new AddressStatusSummary();

        if (isGeoLocated) {
            summary.verified = true;
            summary.messages = [this.getTranslatedMessage('addressStatus.geoLocated')];
            summary.visible = true;
            summary.style = 'warning-alert';
            return summary;
        }
        if (!isFormValid) {
            summary.verified = false;
            summary.messages = [this.getTranslatedMessage('addressStatus.formInvalid')];
            summary.visible = false;
            summary.style = 'warning-alert';
            return summary;
        }

        let addressCheck: AddressVerification;
        if (isBillingAddress) {
            addressCheck = await this._cctHttp.verifyAddress(address);
        }
        else {
            addressCheck = await this._cctHttp.verifySiteAddress(address);
        }

        summary.visible = true;
        if (!addressCheck) {
            summary.style = 'warning-alert';
            summary.messages = [this.getTranslatedMessage('addressStatus.inputAddressError')];
            summary.verified = false;
        }
        else if (!addressCheck.corrections &&
            (addressCheck.phone === address.phone || !isBillingAddress && !this.notNullOrEmpty(address.phone))) {
            summary.verified = true;
            summary.style = 'success-alert';
            summary.messages = this.showAddressValidatedMessage(address.phone);
        }
        else {
            summary.verified = false;
            summary.style = 'error-alert';
            summary.messages = this.getErrorMessage(addressCheck, Boolean(address.phone));
        }
        return summary;
    }

    public getErrorMessage(inputAddress: AddressVerification, hasPhone: boolean): string[] {
        const corrections = inputAddress.corrections.split(',');
        let message = Array<string>();

        if (hasPhone && inputAddress.phone === 'Phone is not correct') {
            message.push(this.getTranslatedMessage('errorMessage.phone'));
        }

        if (inputAddress.country === 'CA') {

            message = message.concat(this.getCAAddressRecommendation(corrections, inputAddress));

            if (message.length) {
                return message;
            }
        }

        if (inputAddress.corrections !== '') {
            let address2 = ',';
            if (this.notNullOrEmpty(inputAddress.address2)) {
                address2 = ` ${inputAddress.address2},`;
            }

            return [`${this.getTranslatedMessage('errorMessage.suggestedAddress')} `+
                `${inputAddress.address1}${address2} ${inputAddress.city},`+
                `${inputAddress.country}, ${inputAddress.provice}, ${inputAddress.postalcode}`].concat(message);
        }

        return message;
    }

    private getCAAddressRecommendation(corrections: string[], inputAddress: AddressVerification): string[] {
        const message = new Set<string>();
        corrections.forEach(correction => {
            if (correction.startsWith('POSTAL_CODE')) {
                message.add(`${this.getTranslatedMessage('addressRecommendation.postalCode')} ${inputAddress.postalcode}`);
            }
            else if (correction.startsWith('STREET_NAME') ||
                correction.startsWith('STREET_DIRECTIONAL') || correction.startsWith('STREET_SUFFIX')) {
                message.add(`${this.getTranslatedMessage('addressRecommendation.address1')} ${inputAddress.address1}`);
                if (this.notNullOrEmpty(inputAddress.address2)) {
                    message.add(`${this.getTranslatedMessage('addressRecommendation.address2')} ${inputAddress.address2}`);
                }
            }
            else if (correction.startsWith('MUNICIPALITY_NAME')) {
                message.add(`${this.getTranslatedMessage('addressRecommendation.city')} ${inputAddress.city}`);
            }
            else if (correction.startsWith('PROVINCE_NAME')) {
                message.add(`${this.getTranslatedMessage('addressRecommendation.state')} ${inputAddress.provice}`);
            }
        });

        return Array.from(message.values());
    }

    private showAddressValidatedMessage(phone: string): string[] {
        if (this.notNullOrEmpty(phone)) {
            return [this.getTranslatedMessage('validationMessage.addressAndPhoneVerified')];
        }

        return [this.getTranslatedMessage('validationMessage.addressVerified')];
    }

    private notNullOrEmpty(input: string): boolean {
        return input && input !== '';
    }

    private getTranslatedMessage(lookUpKey: string): string {
        return this._translate.instant(`shared.addressErrorReadUtility.${lookUpKey}`);
    }

}
