<div class="attributions-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2.5vh">
    <h2>{{ 'legal.attributions.title' | translate }}</h2>
    <span>{{ 'legal.attributions.notice' | translate }}</span>

    <div class="attributions" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.5vh">
        <div class="attribution" *ngFor="let attribution of attributions; let i = index" fxLayout="row" fxLayoutGap="2.5vh">
            <span>{{ i + 1 }}. </span>
            <span *ngIf="!attribution.packageUrl" fxFlex="40">{{ attribution.packageName }}</span>
            <a *ngIf="attribution.packageUrl" [href]="attribution.packageUrl" target="_blank" fxFlex="40">{{ attribution.packageName }}</a>
            <span *ngIf="attribution.author" fxFlex="30">{{ 'legal.attributions.createdBy' | translate }}: {{ attribution.author }}</span>
            <span *ngIf="!attribution.licenseUrl" fxFlex="10">{{ 'legal.attributions.licenseType' | translate }}: {{ attribution.licenseType }}</span>
            <span *ngIf="attribution.licenseUrl" fxFlex="10">{{ 'legal.attributions.licenseType' | translate }}: <a [href]="attribution.licenseUrl" target="_blank">{{ attribution.licenseType }}</a></span>
        </div>
    </div>
</div>
