<div class="action-progress-reporter-container">
    <div class="action-progress-reporter-ongoing" *ngIf="showOngoing" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <dots-loader></dots-loader>
        <span>{{ options.ongoingText }}</span>
    </div>

    <div class="action-progress-reporter-success" *ngIf="showSuccess" fxLayout="column" fxLayoutAlign="space-around center">
        <div class="action-progress-reporter-shade"></div>
        <mat-icon svgIcon="successCheckmark" aria-hidden="true"></mat-icon>
        <span>{{ 'shared.actionProgressReporter.success' | translate }}</span>
        <span>{{ options.successText }}</span>

        <button class="action-progress-reporter-success-button" mat-raised-button color="primary" (click)="successConfirmed.emit()">
            {{ options.successConfirmText }}
        </button>
    </div>

    <div class="action-progress-reporter-error" *ngIf="showError" fxLayout="column" fxLayoutAlign="space-around center">
        <div class="action-progress-reporter-shade"></div>
        <mat-icon svgIcon="errorCrossmark" aria-hidden="true"></mat-icon>
        <span>{{ 'shared.actionProgressReporter.ohNo' | translate }}</span>
        <div class="message">{{ options.errorText }}</div>

        <button class="action-progress-reporter-error-button" mat-raised-button color="warn" (click)="errorConfirmed.emit()">
            {{ options.errorConfirmText }}
        </button>
    </div>
</div>
