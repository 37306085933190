<div class="role-permission-container">
    <role-list-viewer *ngIf="!roleEditorOption"
        [user]="user"
        [roles]="roles"
        [branches]="branches"
        (addRole)="openNewRolePanel()"
        (openRole)="openRole($event.name)"
        (deleteRole)="deleteRole($event)">
    </role-list-viewer>

    <role-editor *ngIf="roleEditorOption"
        [user]="user"
        [role]="roleEditorOption.role"
        [permissionTemplates]="roleEditorOption.permissionTemplates"
        [permissionParameterOptions]="permissionParameterOptions"
        [subscriptionTemplates]="roleEditorOption.subscriptionTemplates"
        [mergeRules]="roleEditorOption.mergeRules"
        [savingState]="savingState"
        (update)="updateRole($event)"
        (close)="closeRole()">
    </role-editor>
</div>
