import { Ng2StateDeclaration, StateService } from '@uirouter/angular';

import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import { ReadPermission } from '../../core/enums/read-permission.enum';
import { UserManagementComponent } from '../../shared/components/user-management/user-management.component';
import { globalResolver } from '../../core/configs/routing/global-resolver';

const resolves = [
    {
        token: 'isServiceProvider',
        resolveFn: (): boolean => true
    },
    {
        token: 'hasAccess',
        deps: [UserPermissionService, StateService],
        resolveFn: async(userService: UserPermissionService, state: StateService): Promise<void> => {
            if (!await userService.hasReadPermission(ReadPermission.ViewServiceProviderCrewManagement)) {
                state.transition.abort();
                await state.go('pageNotFound');
            }
        }
    },
    ...globalResolver
];

export const states: Ng2StateDeclaration[] = [
    {
        name: 'users',
        url: '/users',
        component: UserManagementComponent,
        resolve: resolves
    }
];
