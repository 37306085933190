import DispatchProjectInfoData from './dispatch-project-info-data';
import DispatchBaseStepConfig from './dispatch-base-step-config';
import DynamicsDispatchInfo from './dynamics-dispatch-info';
import Branch from '../branch';

export default class DispatchStepConfig extends DispatchBaseStepConfig {
    public data: DispatchProjectInfoData;
    public branches: Branch[];
    public dynamicsDispatchInfo: DynamicsDispatchInfo;
    public isConvertingProject: boolean;
    public isIntegrationRequest: boolean;
    public budgetTypeOverride = '';
}
