/* eslint-disable @typescript-eslint/no-explicit-any, max-params */
import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    inject
} from '@angular/core';
import { WeatherService } from '../weather.service';
import 'chartjs-adapter-moment';
import * as moment from 'moment-timezone';
import {
    Chart,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    registerables,
    ChartConfiguration
} from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartJsService } from './chart-js.service';
import { Subject, Subscription, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CustomArrowForWindDirectionPlugin } from './CustomArrowForWindDirection';
import Branch from '../../../core/data-models/branch';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'schedule-board-chart',
    templateUrl: './schedule-board-chart.component.html',
    styleUrls: ['./schedule-board-chart.component.scss']
})
export class ScheduleBoardChartComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('weatherChart') weatherChartRef?: ElementRef<HTMLCanvasElement>;
    @Input() branchInfo: Branch;
    destroy$ = new Subject<void>();
    weatherService = inject(WeatherService);
    chartJsService = inject(ChartJsService);
    translate = inject(TranslateService);
    isLoading = false;

    selectedIndex = {
        index: 0,
        label: this.translate.instant(
            'weatherChart.weatherStats.precipitation'
        ),
        type: 'Precipitation'
    };

    labels = [];
    dataSet = {
        temperature: [],
        humidity: [],
        visibility: [],
        windGust: [],
        windSpeed: [],
        windDirection: [],
        precipitation: [],
        cloudCover: []
    };

    chart: any;
    weatherStats = [
        {
            value: '--',
            label: this.translate.instant(
                'weatherChart.weatherStats.precipitation'
            ),
            type: 'Precipitation'
        },
        {
            value: '--',
            label: this.translate.instant(
                'weatherChart.weatherStats.temperature'
            ),
            type: 'Temperature'
        },
        {
            value: '--',
            label: this.translate.instant('weatherChart.weatherStats.humidity'),
            type: 'Humidity'
        },
        {
            value: '--',
            label: this.translate.instant(
                'weatherChart.weatherStats.visibility'
            ),
            type: 'Visibility'
        },
        {
            value: '--',
            label: this.translate.instant(
                'weatherChart.weatherStats.windSpeed'
            ),
            type: 'Wind Speed'
        },
        {
            value: '--',
            label: this.translate.instant('weatherChart.weatherStats.windGust'),
            type: 'Wind Gust'
        },
        {
            value: '--',
            label: this.translate.instant(
                'weatherChart.weatherStats.windDirection'
            ),
            type: 'Wind Direction'
        },
        {
            value: '--',
            label: this.translate.instant(
                'weatherChart.weatherStats.cloudCover'
            ),
            type: 'Cloud Cover'
        }
    ];

    isArrowVisible = true;
    timeZoneId: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    public timerSubscription: Subscription;

    constructor() {
        this.isLoading = true;
        const fifteenMinutesInterval = interval(60000);
        this.timerSubscription = fifteenMinutesInterval.subscribe(() => {
            this.getWeatherData(this.branchInfo, true);
        });
    }

    ngOnInit(): void {
        const customPlugin = new CustomArrowForWindDirectionPlugin();

        Chart.register(
            LineController,
            LineElement,
            PointElement,
            LinearScale,
            CategoryScale,
            ChartDataLabels,
            Title,
            Tooltip,
            customPlugin,
            annotationPlugin,
            ...registerables
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.branchInfo.currentValue) {
            if (
                !this.areObjectsEqual(
                    changes.branchInfo.currentValue,
                    changes.branchInfo.previousValue
                )
            ) {
                this.getWeatherData(this.branchInfo, false);
                this.timeZoneId = !this.branchInfo ? Intl.DateTimeFormat().resolvedOptions().timeZone : this.branchInfo?.geoLocation?.timeZone?.timeZoneId;
            }
        }
        else {
            this.createChart(
                { Data: { timelines: [{ Intervals: [] }] } },
                Intl.DateTimeFormat().resolvedOptions().timeZone
            );
        }
    }

    areObjectsEqual(obj1: any, obj2: any): boolean {
        if (obj1 === obj2) {
            return true;
        }
        if (
            typeof obj1 !== 'object' ||
            obj1 === null ||
            typeof obj2 !== 'object' ||
            obj2 === null
        ) {
            return false;
        }
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            if (!keys2.includes(key) || !this.areObjectsEqual(obj1[key], obj2[key])) {
                return false;
            }
        }
        return true;
    }

    createChart(apiResponse, timeZoneId: string) {
        if (this.chart) {
            this.chart.destroy();
        }

        const hasIntervals = apiResponse.Data.timelines[0].Intervals.length > 0;

        const defaultTimes = this.chartJsService.getStartAndEndTime(
            Intl.DateTimeFormat().resolvedOptions().timeZone
        );

        const startTime = this.chartJsService.time(
            hasIntervals ? apiResponse.Data.timelines[0].StartTime : defaultTimes.startTime
        );

        const endTime = this.chartJsService.time(
            hasIntervals ? apiResponse.Data.timelines[0].EndTime : defaultTimes.endTime
        );

        const intervals = hasIntervals ? apiResponse.Data.timelines[0].Intervals : [];

        const dataAvailable = !(intervals.length > 0);
        this.labels = this.chartJsService.getLabels(startTime, endTime);
        this.setDataSet(intervals);

        const ctx = this.weatherChartRef?.nativeElement.getContext('2d');
        if (ctx) {
            const gradients = this.chartJsService.getGradients(ctx);
            const chartData = this.chartJsService.getChartData(
                this.labels,
                this.dataSet,
                gradients,
                this.selectedIndex
            );

            const chartOptions = this.chartJsService.getChartOptions(
                startTime,
                endTime,
                timeZoneId,
                this.weatherStats,
                this.dataSet,
                dataAvailable,
                this.isArrowVisible
            );

            const configuration = {
                type: 'line',
                data: chartData,
                options: chartOptions,
                plugins: [ChartDataLabels]
            } as unknown as ChartConfiguration<'line'>;

            const graph = new Chart(ctx, configuration);

            this.chart = graph;
            this.chart.update();
        }
    }

    getCurrentDateTime(timeZone: string) {
        return moment.tz(timeZone).format('MMMM D, YYYY, H:mm A');
    }

    setDataSet(intervals) {
        this.dataSet.temperature = intervals.map(
            interval => interval.Values.Temperature
        );
        this.dataSet.windSpeed = intervals.map(
            interval => interval.Values.WindSpeed
        );
        this.dataSet.windGust = intervals.map(
            interval => interval.Values.WindGust
        );

        this.dataSet.windDirection = intervals.map(
            interval => interval.Values.WindDirection
        );

        this.dataSet.visibility = intervals.map(
            interval => interval.Values.Visibility
        );

        this.dataSet.precipitation = intervals.map(
            interval => interval.Values.PrecipitationIntensity
        );

        this.dataSet.cloudCover = intervals.map(
            interval => interval.Values.CloudCover
        );

        this.dataSet.humidity = intervals.map(
            interval => interval.Values.Humidity
        );
    }

    getWeatherData(branchInfo: Branch, isTimer: boolean) {
        this.isLoading = true;
        if (!branchInfo) {
            this.weatherStats.forEach(obj => {
                obj.value = '--';
            });
            this.isLoading = false;
            this.createChart(
                { Data: { timelines: [{ Intervals: [] }] } },
                Intl.DateTimeFormat().resolvedOptions().timeZone
            );
            return;
        }

        const formatedDate = this.chartJsService.getStartAndEndTime(
            branchInfo?.geoLocation?.timeZone?.timeZoneId
        );

        const startTime = formatedDate.startTime;
        const endTime = formatedDate.endTime;

        this.weatherService.getWeatherTimelines(branchInfo.name, startTime, endTime)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: response => {
                    if (!isTimer) {
                        this.selectedIndex = {
                            index: 0,
                            label: this.translate.instant(
                                'weatherChart.weatherStats.precipitation'
                            ),
                            type: 'Precipitation'
                        };
                    }
                    this.isLoading = false;
                    this.createChart(
                        response,
                        branchInfo?.geoLocation?.timeZone?.timeZoneId
                    );
                },
                error: _ => {
                    this.isLoading = false;
                    this.createChart(
                        { Data: { timelines: [{ Intervals: [] }] } },
                        branchInfo?.geoLocation?.timeZone?.timeZoneId
                    );
                }
            });
    }

    toggleWeatherData(label: string, type: string, index: number): void {
        this.selectedIndex.index = index;
        this.selectedIndex.label = label.replace(/\s/g, '').toLocaleLowerCase();
        this.selectedIndex.type = type;
        if (!this.chart && !this.chart.data) {
            return;
        }
        this.chart.data.datasets.forEach((dataset: { label: string }, i) => {
            if (!dataset.label) {
                return;
            }
            if (
                dataset.label.replace(/\s/g, '').toLocaleLowerCase() ===
                label.replace(/\s/g, '').toLocaleLowerCase()
            ) {
                this.chart.setDatasetVisibility(i, true);
            }
            else {
                this.chart.setDatasetVisibility(i, false);
            }
        });
        this.chart.update();
    }

    ngOnDestroy() {
        if (this.destroy$) {
            this.destroy$.next();
            this.destroy$.complete();
        }

        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        if (this.chart) {
            this.chart.destroy();
        }

        const ctx = this.weatherChartRef?.nativeElement.getContext('2d');
        ctx.clearRect(0, 0, this.weatherChartRef?.nativeElement.width, this.weatherChartRef?.nativeElement.height);
        this.weatherChartRef?.nativeElement.remove();
    }
}
