import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import CustomerDto from '../../../../../core/data-models/customer-dto';
// eslint-disable-next-line
import { FormControlUtilityService } from '../../../../../core/services/utility/form-control-utility-service/form-control-utility.service';
import AddressStatusSummary from '../../../../../core/data-models/address-status-summary';
// eslint-disable-next-line
import { AddressErrorReadUtilityService } from '../../services/address-error-read-utility/address-error-read-utility.service';
import { UserHttpService } from '../../../../../core/services/http/user-http/user-http.service';
import { CustomerClass } from '../../../../../core/enums/customer-class.enum';
import { ExchangeCurrency } from '../../../../../core/enums/exchange-currency.enum';
import { CountryName } from '../../../../../core/enums/country-name.enum';
import { AppConfigHttpService } from '../../../../../core/services/http/appconfig-http/appconfig-http.service';
import { ValidatorFactory } from '../../../../../core/services/validator-factory/validator-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagService } from '../../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../../core/enums/feature-flags.enum';


@Component({
    selector: 'customer-create',
    styleUrls: ['./customer-create.component.scss'],
    templateUrl: './customer-create.component.html'
})

export class CustomerCreateComponent implements OnInit {
    public isInternal = false;
    public newCustomerFormGroup: UntypedFormGroup;
    @Output() newCustomerInfoUpdated = new EventEmitter<CustomerDto>();
    @Output() handleStep = new EventEmitter<string>();
    public addressCheckLoading = false;
    public stateList: string[];
    public countryList: string[];
    public currencyList: string[];
    public customerClassList: string[];
    public addressStatus: AddressStatusSummary;
    public userDisplayName: string;
    public requestedDate: string;

    // eslint-disable-next-line
    constructor(
        public translate: TranslateService,
        private _userHttp: UserHttpService,
        private _appConfig: AppConfigHttpService,
        private _validatorFactory: ValidatorFactory,
        private _addressErrorService: AddressErrorReadUtilityService,
        private _formService: FormControlUtilityService,
        private _formBuilder: UntypedFormBuilder,
        private _featureFlagService: FeatureFlagService
    ) { }

    public async ngOnInit(): Promise<void> {
        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        this.initializeCustomerFormAndValidations();
        await this.loadDataForDropDownListsOnForms();
        this.addSubsriptionToFormToCheckAddressValidity();
        const userInfo = await this._userHttp.getUserInfo();
        this.userDisplayName = `${userInfo.displayName}`;
        this.requestedDate = new Date().toJSON().slice(0, 10);
    }

    public getControlValue(formName: string): string {
        return this.newCustomerFormGroup.get(formName).value;
    }

    public isFormControlValid(controlName: string): boolean {
        return this.newCustomerFormGroup.get(controlName).invalid;
    }

    public getErrorMessage(controlName: string): string {
        return this._formService.getErrorMessage(this.newCustomerFormGroup.get(controlName));
    }

    public resetCustomerCreationForm(): void {
        this.newCustomerFormGroup.reset();
    }

    public confirmCustomer(): void {
        if (this.newCustomerFormGroup.valid && this.addressStatus?.verified) {
            this.handleStep.emit('next');
        }
    }

    private async loadDataForDropDownListsOnForms(): Promise<void> {
        this.stateList = await this._appConfig.getCAStates();
        this.countryList = Object.keys(CountryName).filter(_ => isNaN(Number(_)));
        this.currencyList = Object.keys(ExchangeCurrency).filter(_ => isNaN(Number(_)));
        this.customerClassList = Object.keys(CustomerClass).filter(_ => isNaN(Number(_)));

        this.newCustomerFormGroup.get('country').valueChanges.subscribe(async country => {
            if (country === 'CA') {
                this.stateList = await this._appConfig.getCAStates();
            }
            else if (country === 'US') {
                this.stateList = await this._appConfig.getUSStates();
            }
        });

    }

    private initializeCustomerFormAndValidations(): void {
        const currencyValidator = this._validatorFactory.createCurrencyValidator();
        const positiveValidator = this._validatorFactory.createPositiveValidator();

        const formGroup = {
            customerId: [ `new-${Date.now()}`],
            name: ['', [Validators.required, Validators.maxLength(60)]],
            address1: ['', Validators.required],
            address2: [''],
            city: ['', Validators.required],
            country: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', Validators.required],
            phone: ['', Validators.required],
            faxNumber: [''],
            currency: ['', Validators.required],
            proposedBusiness: ['', [Validators.required, currencyValidator.validator, positiveValidator.validator]],
            dataUniversalNumberingSystemNumber: [''],
            customerClass: ['', this.isInternal? Validators.required : []]
        };

        this.newCustomerFormGroup = this._formBuilder.group(formGroup);
    }

    private addSubsriptionToFormToCheckAddressValidity(): void {
        this.newCustomerFormGroup.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe(async val => {
                const validForm = this.newCustomerFormGroup.valid;
                const inputAddress = {
                    address1: val.address1,
                    address2: val.address2,
                    provice: val.state,
                    city: val.city,
                    postalcode: val.zip,
                    country: val.country,
                    phone: val.phone,
                    corrections: ''
                };
                this.addressCheckLoading = true;
                this.addressStatus = await this._addressErrorService.getAddressStatus(inputAddress, validForm, false, true);
                if (this.addressStatus.verified && validForm) {
                    this.newCustomerInfoUpdated.emit(this.newCustomerFormGroup.value);
                }
                else {
                    this.newCustomerInfoUpdated.emit(null);
                }
                this.addressCheckLoading = false;
            });
    }

}
