import Crew from '../../../../../../core/data-models/crew';

export default class HandoffMeetingUtilityService {

    public static handOffMeetingRequired(isRequirementMet: boolean, mostRecentCrew: Crew, selectedCrew: Crew): boolean {

        const hasCrews = mostRecentCrew?.crewId && selectedCrew?.crewId;
        if (!isRequirementMet) {
            return false;
        }

        return !hasCrews || mostRecentCrew.crewId !== selectedCrew?.crewId;
    }

}
