// eslint-disable-next-line
import { Component, Input, ChangeDetectionStrategy, OnInit, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import UserComment from '../../../../core/data-models/user-comment/user-comment';
import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';
import CustomEditorOption from '../../../../core/data-models/custom-editor-option';
import { EditorUtilityService } from '../../../../core/services/utility/editor-utility/editor-utility.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog-component/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'user-comment-editor',
    styleUrls: ['./user-comment-editor.component.scss'],
    templateUrl: './user-comment-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCommentEditorComponent implements OnInit, AfterViewInit {
    @Input() public comment = new UserComment(null);
    @Output() public editSaved = new EventEmitter<string>();
    @Output() public editCancelled = new EventEmitter();
    private readonly _maxCharacters = 1000;
    private _currentCharacters = 0;
    private _editorId = '';

    constructor(
        private _editorUtility: EditorUtilityService,
        private _changeDetector: ChangeDetectorRef,
        private _dialog: MatDialog,
        private _translate: TranslateService
    ) { }

    get maxCharacters(): number {
        return this._maxCharacters;
    }

    get currentCharacters(): number {
        return this._currentCharacters;
    }

    get editorId(): string {
        return this._editorId;
    }

    get isLimitReached(): boolean {
        return this._currentCharacters > this._maxCharacters;
    }

    public ngOnInit(): void {
        this._editorId = `user-comment-editor-${this.comment.id ?? Date.now()}`;
    }

    public ngAfterViewInit(): void {
        const builtinOptions = {
            tools: this._editorUtility.basicTools,
            keyup: this.onKeyup.bind(this),
            paste: () => setTimeout(this.onKeyup.bind(this))
        };

        const customOptions = new CustomEditorOption(this.comment.content);
        this._editorUtility.setupEditor(this._editorId, builtinOptions, { ...customOptions, focusOnLoad: true });
        this.onKeyup();
    }

    public onSave(): void {
        const isEmpty = this._editorUtility.isEmptyOrWhiteSpace(this._editorId);

        if (!isEmpty && !this.isLimitReached) {
            this.editSaved.emit(this._editorUtility.getContent(this._editorId));

            return;
        }

        // eslint-disable-next-line max-len
        const maxCharacterMessage = this._translate.instant('shared.userComments.editor.maxCharacterWarning', { maxNumber: this._maxCharacters });
        const noContent = this._translate.instant('shared.userComments.editor.noContent');
        const characterLimitExceeded = this._translate.instant('shared.userComments.editor.characterLimitExceeded');
        const emptyEntriesMessage = this._translate.instant('shared.userComments.editor.emptyEntriesNotAllowed');
        const ok = this._translate.instant('shared.userComments.editor.ok');

        const title = isEmpty ? noContent : characterLimitExceeded;
        const message = isEmpty ? emptyEntriesMessage : maxCharacterMessage;
        const data = new ConfirmDialogOption(title, message, false, ok, '');
        this._dialog.open(ConfirmDialogComponent, { data });
    }

    public async onCancel(): Promise<void> {
        const isEmpty = this._editorUtility.isEmptyOrWhiteSpace(this._editorId);
        const content = this._editorUtility.getContent(this._editorId);

        if (isEmpty || content === this.comment.content) {
            this.editCancelled.emit();

            return;
        }

        const title = this._translate.instant('shared.userComments.editor.unsavedChanges');
        const message = this._translate.instant('shared.userComments.editor.discardChangesMessage');
        const discard = this._translate.instant('shared.userComments.editor.discard');
        const data = new ConfirmDialogOption(title, message, true, discard);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (await dialog.afterClosed().toPromise()) {
            this.editCancelled.emit();
        }
    }

    private onKeyup(): void {
        this._currentCharacters = this._editorUtility.getCharacterCount(this._editorId);
        this._changeDetector.detectChanges();
    }
}
