import ClientInfo from '../client-info';

import BranchReviewChecklist from './branch-review-checklist';

export default class BranchReview {
    public projectId: string;
    public contactInfo = new ClientInfo();
    public billableHours = 0;
    public comment = '';
    public returnToSiteReason = '';
    public showBillableHours = false;
    public isFabricationRequired = false;
    public checklist = new BranchReviewChecklist();
    public materialsCost: number | null;
    public recommendationMaterialsCost: number | null;

    constructor(projectId: string) {
        this.projectId = projectId;
    }
}
