import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { LaborSectionComponent } from './service-operations-standardized-estimation/labor-section/labor-section.component';
import { MaterialsSectionComponent } from './service-operations-standardized-estimation/materials-section/materials-section.component';
import { MiscellaneousSectionComponent } from './service-operations-standardized-estimation/miscellaneous-section/miscellaneous-section.component';
import { ProjectSectionComponent } from './service-operations-standardized-estimation/project-section/project-section.component';
import { QualityControlFeesSectionComponent } from './service-operations-standardized-estimation/quality-control-fees-section/quality-control-fees-section.component';
import { ServiceOperationsStandardizedEstimationComponent } from './service-operations-standardized-estimation/service-operations-standardized-estimation.component';
import { SummarySectionComponent } from './service-operations-standardized-estimation/summary-section/summary-section.component';
import { QuestionsSectionComponent } from './service-operations-standardized-estimation/questions-section/questions-section.component';

@NgModule({
    declarations: [
        ProjectSectionComponent,
        LaborSectionComponent,
        MaterialsSectionComponent,
        MiscellaneousSectionComponent,
        QualityControlFeesSectionComponent,
        SummarySectionComponent,
        ServiceOperationsStandardizedEstimationComponent,
        QuestionsSectionComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        ServiceOperationsStandardizedEstimationComponent
    ]
})
export class BudgetEstimationModule { }
