<div class="summary-list-container" [ngClass]="containerClass" fxLayout="column">
    <div *ngIf="isLoading" fxLayout="column" fxLayoutAlign="center center" fxFill>
        <dots-loader></dots-loader>
    </div>

    <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="isProject">
            <project-summary-card *ngFor="let summary of queryResult?.result; index as i"
                class="loading-summary-card"
                [ngStyle]="{ 'animation-delay': getAnimationDelay(i), height: 93.45 / queryResult.pageSize + '%' }"
                [isActive]="isActiveProject(summary)"
                [summary]="summary"
                [viewers]="summary.currentViewers"
                (click)="onProjectSummarySelected(summary)"
                [isSimplified]="isSimplified">
            </project-summary-card>
        </ng-container>

        <ng-container *ngIf="!isProject">
            <quote-summary-card *ngFor="let summary of queryResult?.result; index as i"
                class="loading-summary-card"
                [ngStyle]="{ 'animation-delay': getAnimationDelay(i) }"
                [isActive]="activeId === summary.quoteId"
                [summary]="summary"
                (click)="summarySelected.emit(summary.quoteId)">
            </quote-summary-card>
        </ng-container>

        <div class="summary-list-no-record"
            *ngIf="queryResult && !queryResult.result.length"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="6px"
            fxFill>

            <span>{{ 'shared.summaryList.noRecordsAvailable' | translate }}</span>
            <span>{{ 'shared.summaryList.tryDifferentFilters' | translate }}</span>
        </div>

        <div class="summary-list-focus-mode"
            *ngIf="isFocusMode && queryResult?.result.length === 1"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="6px"
            fxFill>

            <span>{{ focusModeText }}</span>
            <span>{{ 'shared.summaryList.clickRestoreOrChangeFilters' | translate }}</span>
            <button mat-raised-button color="primary" (click)="onFocusModeOff()">{{ 'shared.summaryList.restore' | translate }}</button>
        </div>
    </ng-container>

    <div class="summary-control" fxLayout="row" fxLayoutAlign="center center">
        <button mat-icon-button class="refresh-button" (click)="onListRefresh()">
            <mat-icon>refresh</mat-icon>
        </button>

        <div class="summary-pager" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
            <button mat-icon-button [disabled]="!hasPreviousPage" (click)="onPageChange(1 - queryResult.page)">
                <mat-icon>first_page</mat-icon>
            </button>

            <button mat-icon-button [disabled]="!hasPreviousPage" (click)="onPageChange(-1)">
                <mat-icon>chevron_left</mat-icon>
            </button>

            <span>{{ currentPage }}</span>

            <button mat-icon-button [disabled]="!hasNextPage" (click)="onPageChange(1)">
                <mat-icon>chevron_right</mat-icon>
            </button>

            <button mat-icon-button [disabled]="!hasNextPage" (click)="toLastPage()">
                <mat-icon>last_page</mat-icon>
            </button>
        </div>

        <button mat-mini-fab
            class="summary-list-collapse-button"
            [matTooltip]="expandButtonTooltip"
            (click)="onToggleExpand()">

            <mat-icon>chevron_left</mat-icon>
        </button>
    </div>
</div>
