import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import DialogHeaderOption from '../../../core/data-models/dialog-header-option';
import { Subject } from 'rxjs';

@Component({
    selector: 'add-follow-up-log-dialog',
    templateUrl: './add-follow-up-log-dialog.component.html',
    styleUrls: ['./add-follow-up-log-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddFollowUpLogDialogComponent {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    public dataSubject = new Subject<any>;
    constructor(public _matDialogRef: MatDialogRef<AddFollowUpLogDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public options: DialogHeaderOption) { }

    public onClose<T>(output: T): void {
        this.dataSubject.next(output);
    }
}
