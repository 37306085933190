export default class TaskOptionItem {
    public taskName = '';
    public actionName = '';
    public description = '';
    public icon = 'assignment';

    constructor(taskName: string, actionName: string, description: string, icon: string) {
        this.taskName = taskName;
        this.actionName = actionName;
        this.description = description;
        this.icon = icon;
    }
}
