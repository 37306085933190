import { QueueViewComponent } from './queue-view.component';

/* eslint-disable no-mixed-spaces-and-tabs */
export const schedulerProConfig={
    type: 'resourceInfo',
    viewPreset: {
        base: 'hourAndDay',
        fillTicks: true,
        columnLinesFor: 0,
        mainHeaderLevel: 1,
        headers: [
            {
                unit: 'd',
                align: 'center',
                dateFormat: 'ddd DD MMM',
                renderer: () => ''
            },
            {
                unit: 'h',
                align: 'center',
                dateFormat: 'HH',
                renderer: () => ''
            }
        ]
    },
    forceFit: false,
    eventColor: null,
    columns: [
        { type: 'resourceInfo', text: null, field: 'name', width: 280, showEventCount: false, useNameAsImageName: true, resizable: false }
    ],
    eventEditFeature: {
        // Add extra widgets to the event editor
        items: {
            descriptionField: {
                type: 'text',
                name: 'desc',
                label: 'Description',
                weight: 100
            }
        }
    },
    // This allows the timeline to be scrolled infinitely in time.
    infiniteScroll: true,
    startDate: '',
    endDate: '',
    listeners: {
        beforeEventEdit() {
            return false;
        },
        beforeEventDrag({ eventRecord }) {
            // Don't allow dragging events that have already started
            if (QueueViewComponent.allowedProjectStatuses.includes(eventRecord.projectStatus.toString())) {
                return true;
            }
            return false;
        }
    },
    headerMenuFeature: false,
    zoomOnTimeAxisDoubleClick: false,
    zoomOnMouseWheel: false,
    createEventOnDblClick: false,
    eventMenuFeature: false,
    scheduleTooltipFeature: false,
    taskEditFeature: {
        triggerEvent: ''
    },
    dependenciesFeature: {
        allowCreate: false
    },
    eventResizeFeature: false,
    eventDragFeature: {
        DragEvent: false,
        constrainDragToTimeline: false,
        showTooltip: true, // Disable job drag tooltip.
        // Custom tooltip for when an event is dragged
        tooltipTemplate: ({ eventRecord }) => {
            return `<dl>
                ${eventRecord.get('name') ? `<dd>${eventRecord.name}</dd>` : ''}
                ${eventRecord.get('projectName') ? `<dd>${eventRecord.projectName}</dd>` : ''}
                ${eventRecord.get('desc') ? `<dd>${eventRecord.desc}</dd>` : ''}
                ${eventRecord.get('projectStatusDesc') ? `<dd>${QueueViewComponent.projectStatusDesc} : ${eventRecord.projectStatusDesc}</dd>` : `<dd>${QueueViewComponent.projectStatusDesc} : </dd>`}
                ${eventRecord.get('estimatedHours') ? `<dd>${QueueViewComponent.estimatedHours} : ${eventRecord.estimatedHours}</dd>` : `<dd>${QueueViewComponent.estimatedHours} : </dd>`}
            </dl>`;
        }
    },
    eventTooltipFeature: {
        allowOver: true,
        showOnHover: true,
        showOnClick: true,
        // Tooltip configs can be used here
        align: 'l-r', // Align left to right,
        // A custom HTML template
        template: data => `<dl>
                 ${data.eventRecord.get('name') ? `<dd>${data.eventRecord.name}</dd>` : ''}
                 ${data.eventRecord.get('projectName') ? `<dd>${data.eventRecord.projectName}</dd>` : ''}
                 ${data.eventRecord.get('desc') ? `<dd>${data.eventRecord.desc}</dd>` : ''}
                 ${data.eventRecord.get('projectStatusDesc') ? `<dd>${QueueViewComponent.projectStatusDesc} : ${data.eventRecord.projectStatusDesc}</dd>` : `<dd>${QueueViewComponent.projectStatusDesc} : </dd>`}
                 ${data.eventRecord.get('estimatedHours') ? `<dd>${QueueViewComponent.estimatedHours} : ${data.eventRecord.estimatedHours}</dd>` : `<dd>${QueueViewComponent.estimatedHours} : </dd>`}

             </dl>`
    },
    scheduleMenu: {
        items: {
            addEvent: {
                disabled: true
            }
        }
    },
    cellMenuFeature: {
        disabled: true
    },
    dragFeature: false,
    eventDragCreate: false
};