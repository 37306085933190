/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import BranchReview from '../../../../../core/data-models/project-review/branch-review';
import ProcessorReview from '../../../../../core/data-models/project-review/processor-review';
import ProjectGeneralInfo from '../../../../../core/data-models/project-general-info/project-general-info';
import ZeroSafetyReason from '../../../../../core/data-models/zero-safety-reason';
import ZeroSafetyReasonDialogOption from '../../../../../core/data-models/zero-safety-reason-dialog-option';
import ReturnToSiteReason from '../../../../../core/data-models/return-to-site-reason';
import ReturnToSiteReasonDialogOption from '../../../../../core/data-models/return-to-site-reason-dialog-option';
import ConfirmDialogOption from '../../../../../core/data-models/confirm-dialog-option';
import PromptDialogOption from '../../../../../core/data-models/prompt-dialog-option';
import ProjectIdUtility from '../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import ProcessInvoiceData from '../../../../../core/data-models/process-invoice-data';
import ProjectReviewOptions from '../../../../../core/data-models/project-review/project-review-options';
import StockLogDialogOptions from '../../../../../core/data-models/job-materials/stock-log-dialog-options';
import KpiChangeRequestDialogOption from '../../../../../core/data-models/kpi-change-request-dialog-option';
import ActionProgressReporterOption from '../../../../../core/data-models/action-progress-reporter-option';
import CodeDescription from '../../../../../core/data-models/code-description';
// eslint-disable-next-line
import { ConfirmDialogComponent } from '../../../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import { PromptDialogComponent } from '../../../../../shared/components/dialogs/prompt-dialog-component/prompt-dialog.component';
// eslint-disable-next-line
import { RejectionReasonDialogComponent } from '../../project-details-action-bar/rejection-reason-dialog/rejection-reason-dialog.component';
// eslint-disable-next-line
import { ReturnToSiteReasonDialogComponent } from '../../project-details-action-bar/return-to-site-reason-dialog/return-to-site-reason-dialog.component';
// eslint-disable-next-line
import { ZeroSafetyReasonDialogComponent } from '../../project-details-action-bar/zero-safety-reason-dialog/zero-safety-reason-dialog.component';
// eslint-disable-next-line
import { KpiChangeRequestDialogComponent } from '../../project-details-action-bar/kpi-change-request-dialog/kpi-change-request-dialog.component';
// eslint-disable-next-line
import { ProcessProjectDialogComponent } from '../../project-details-action-bar/process-project-dialog/process-project-dialog.component';
import { AddStockLogDialogComponent } from '../../project-details-action-bar/add-stock-log-dialog/add-stock-log-dialog.component';
// eslint-disable-next-line
import { ActionProgressDialogComponent } from '../../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
// eslint-disable-next-line
import { ReadyForProcessingWithoutBudgetReasonDialogComponent } from '../../project-details-action-bar/ready-for-processing-without-budget-reason-dialog/ready-for-processing-without-budget-reason-dialog.component';
import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';
import { ProjectReviewHttpService } from '../../../../../core/services/http/project-review-http/project-review-http.service';
import { ReportHttpService } from '../../../../../core/services/http/report-http/report-http.service';
import { ProjectWorkflow } from '../../../../../core/enums/project-workflow.enum';
import { SalesCodeConfigService } from '../../../../../core/services/sales-code-config/sales-code-config.service';
import { WorkflowHttpService } from '../../../../../core/services/http/workflow-http/workflow-http.service';
import { FeatureFlagService } from '../../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../../core/enums/feature-flags.enum';
import { WorkCategory } from '../../../../../core/data-models/work-category';

@Injectable({
    providedIn: 'root'
})
export class ProjectWorkflowService {
    private readonly _multiJobProjectStatus = [6, 8, 19];
    // eslint-disable-next-line
    constructor(private _projectHttp: ProjectHttpService,
                private _projectReviewHttp: ProjectReviewHttpService,
                private _reportHttp: ReportHttpService,
                private _workflowHttp: WorkflowHttpService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _translate: TranslateService,
                private _featureFlagService: FeatureFlagService,
                private _salesCodeConfig: SalesCodeConfigService) { }

    get useInternalFeatures(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    // Note: this method shall be removed when workflow service can handle logic in user defined workflows.
    // eslint-disable-next-line
    public async handleWorkflow(code: string, generalInfo: ProjectGeneralInfo): Promise<boolean> {
        const { projectId } = generalInfo.basicInfo;
        if (!this.useInternalFeatures) {
            return await this.executeWorkflow(code, projectId);
        }

        const options = { review: { projectId } } as ProjectReviewOptions;

        switch (code) {
            case ProjectWorkflow.Unassign:
                return await this.unassign(generalInfo, code);
            case ProjectWorkflow.ReturnToSite:
                return await this.returnToSite(generalInfo, code);
            case ProjectWorkflow.UndoReturnToSite:
                return await this.undoReturnToSite(projectId, code);
            case ProjectWorkflow.CancelReturnToSiteVisit:
                return await this.cancelReturnToSiteVisit(projectId, code);
            case ProjectWorkflow.ReadyForReview:
                return await this.readyForReview(generalInfo, code);
            case ProjectWorkflow.Approve:
                return await this.approve(generalInfo, code);
            case ProjectWorkflow.ApproveWithKpiChange:
                return await this.approveWithKpiChange(generalInfo, code);
            case ProjectWorkflow.UndoApprove:
                return await this.reject(generalInfo, code, true);
            case ProjectWorkflow.Reject:
                return await this.reject(generalInfo, code);
            case ProjectWorkflow.SendToServiceAdminOrServiceOps:
                return await this.sendToService(projectId, code);
            case ProjectWorkflow.WaitingForPo:
                return await this.executeProcessorReviewAction('wait_for_PO', 'waiting for PO', options, code);
            case ProjectWorkflow.WaitingForBudget:
                return await this.executeProcessorReviewAction('waiting_for_budget', 'waiting for budget', options, code);
            case ProjectWorkflow.WaitingForFollowUpQuote:
                return await this.waitingForFollowUpQuote(generalInfo, code);
            case ProjectWorkflow.ReadyForProcessing:
                return await this.executeProcessorReviewAction('ready_for_processing', 'ready for processing', options, code);
            case ProjectWorkflow.ReadyForProcessingWithoutBudget:
                return await this.readyForProcessingWithoutBudget(generalInfo, code);
            case ProjectWorkflow.Process:
                return await this.process(generalInfo, code);
            case ProjectWorkflow.SubcontractReadyForReview: // This is the only hardcoded subcontract workflow.
                return await this.executeNonReviewAction('subcontract-ready-for-review', 'ready for review', projectId, code);
            default:
                // Subcontracts must follow the hardcoded workflows, as they should follow business rules.
                // However, there are some custom workflows (e.g. Unassigned --> Assigned --> Signed In).
                // These will not match the above switch, and we don't hardcode it because we want to get away from the hardcoded functions.
                // Hence, for the custom workflows and if it is subcontract, we will use the new workflow service.
                if (generalInfo.workCategory === WorkCategory.Subcontract) {
                    return await this.executeWorkflow(code, projectId, this.getWorkflowText(code));
                }

                return false;
        }
    }

    public async approveAfterBudgetUpload(projectId: string, isSilent = false): Promise<boolean> {
        const review = {
            projectId
        } as BranchReview;
        const action = 'upload_budget';

        return await this.executeBranchReviewAction(action, 'approved', review, ProjectWorkflow.Approve, isSilent);
    }

    private async executeWorkflow(code: string, projectId: string, workflowName = ''): Promise<boolean> {
        const action = this._workflowHttp.executeWorkflow(Number(code), projectId);

        return await this.showActionProgress(action, projectId, workflowName);
    }

    private async unassign(generalInfo: ProjectGeneralInfo, workflowCode: string): Promise<boolean> {
        const { basicInfo, isHandOffMeetingRequired } = generalInfo;
        const { projectId } = basicInfo;
        const warning = this._translate.instant('services.projectWorkflow.assigningProjectDifferentTech');

        if (isHandOffMeetingRequired && !await this.confirmAction(warning)) {
            return false;
        }

        return await this.executeNonReviewAction('unassign', this.getWorkflowText(workflowCode), projectId, workflowCode);
    }

    private async returnToSite(generalInfo: ProjectGeneralInfo, workflowCode: string): Promise<boolean> {
        const { projectId } = generalInfo.basicInfo;
        const review = await this._projectReviewHttp.getBranchReview(projectId) ?? new BranchReview(projectId);
        const comment = review.returnToSiteReason;
        const isFabricationRequired = review.isFabricationRequired;

        const dialog = this._dialog.open(ReturnToSiteReasonDialogComponent, {
            data: {
                reason: { comment, isFabricationRequired },
                showFabricationCheckbox: this._salesCodeConfig.isFabricationRequired(projectId)
            } as ReturnToSiteReasonDialogOption,
            width: '500px',
            height: '450px'
        });

        const reason = await dialog.afterClosed().toPromise<ReturnToSiteReason>();

        if (!reason) {
            return false;
        }

        review.returnToSiteReason = reason.comment;
        review.isFabricationRequired = reason.isFabricationRequired;

        return await this.executeBranchReviewAction('return_to_site', 'return to site', review, workflowCode);
    }

    private async undoReturnToSite(projectId: string, workflowCode: string): Promise<boolean> {
        const actionText = this._translate.instant('services.projectWorkflow.projectMovedBackToReadyForReview');

        if (!await this.confirmAction(actionText)) {
            return false;
        }

        return await this.executeNonReviewAction('undo-return-to-site', 'ready for review', projectId, workflowCode);
    }

    private async cancelReturnToSiteVisit(projectId: string, workflowCode: string): Promise<boolean> {
        const actionText = this._translate.instant('services.projectWorkflow.projectMovedBackToReadyForReview');

        if (!await this.confirmAction(actionText)) {
            return false;
        }

        return await this.executeNonReviewAction('multi-job-ready-for-review', 'ready for review', projectId, workflowCode);
    }

    private async readyForReview(generalInfo: ProjectGeneralInfo, workflowCode: string): Promise<boolean> {
        const actionText = this._translate.instant('services.projectWorkflow.projectMovedBackToReadyForReview');

        if (!await this.confirmAction(actionText)) {
            return false;
        }

        const { basicInfo, status } = generalInfo;

        if (status.code !== 20) {
            return false;
        }

        const { projectId } = basicInfo;
        const workflow = 'ready for review';
        const options = { review: { projectId } } as ProjectReviewOptions;

        return await this.executeProcessorReviewAction('ready_for_review', workflow, options, workflowCode);
    }

    private async approve(generalInfo: ProjectGeneralInfo, workflowCode: string): Promise<boolean> {
        const { basicInfo, status } = generalInfo;
        const review = await this._projectReviewHttp.getBranchReview(basicInfo.projectId);
        const ok = this._translate.instant('snackbars.ok');

        if (!review) {
            const snackbarText = this._translate.instant('services.projectWorkflow.completeBranchReviewBeforeApproving');
            this._snackBar.open(snackbarText, ok);

            return false;
        }

        const { showBillableHours, billableHours } = review;
        const isForceApprove = this._multiJobProjectStatus.includes(status.code);
        const action = isForceApprove ? 'force_approve' : 'approve';
        const message = this._translate.instant('services.projectWorkflow.currentReturnToSiteJobWillBeDeleted');

        if (isForceApprove && !await this.confirmAction(message)) {
            return false;
        }

        if (showBillableHours && !billableHours) {
            this._snackBar.open(this._translate.instant('services.projectWorkflow.pleaseIncludeBillableHours'), ok);

            return false;
        }

        return await this.executeBranchReviewAction(action, 'approved', review, workflowCode);
    }

    private async approveWithKpiChange(generalInfo: ProjectGeneralInfo, workflowCode: string): Promise<boolean> {
        const { basicInfo, jobs } = generalInfo;

        const dialog = this._dialog.open(KpiChangeRequestDialogComponent, {
            data: new KpiChangeRequestDialogOption(basicInfo.projectId, jobs.length),
            width: '800px',
            height: '600px'
        });

        const requested = await dialog.afterClosed().toPromise();

        if (requested) {
            return await this.approve(generalInfo, workflowCode);
        }

        if (requested !== null) {
            const ok = this._translate.instant('snackbars.ok');
            this._snackBar.open(this._translate.instant('services.projectWorkflow.unableToInitKpiChangeRequest'), ok);
        }

        return false;
    }

    private async reject(generalInfo: ProjectGeneralInfo, workflowCode: string, undoApprove = false): Promise<boolean> {
        const reason = undoApprove ? await this.getUndoApproveReason() : await this.getRejectionReason();

        if (!reason) {
            return false;
        }

        const { projectId } = generalInfo.basicInfo;
        const review = await this._projectReviewHttp.getProcessorReview(projectId) ?? new ProcessorReview(projectId);
        review.rejectionReason += `${review.rejectionReason ? ', ' : ''}${reason}`;

        return await this.executeProcessorReviewAction('reject', 'rejected', { review } as ProjectReviewOptions, workflowCode);
    }

    private async getUndoApproveReason(): Promise<string> {
        const title = this._translate.instant('services.projectWorkflow.enterReason');
        const message = this._translate.instant('services.projectWorkflow.projectWillBeMovedToRejectedByServiceOps');
        const placeholder = this._translate.instant('services.projectWorkflow.typeYourReason');
        const errorText = this._translate.instant('services.projectWorkflow.reasonMustBeLongerThan10Chars');

        const proceed = this._translate.instant('services.projectWorkflow.proceed');
        const dialog = this._dialog.open(PromptDialogComponent, {
            data: new PromptDialogOption(title, message, false, proceed, placeholder, errorText),
            width: '550px',
            height: '450px'
        });

        const reason = await dialog.afterClosed().toPromise<string>();

        return reason ? `${this._translate.instant('services.projectWorkflow.branchUsersUndoApproving')}, ${reason}` : '';
    }

    private async getRejectionReason(): Promise<string> {
        const dialog = this._dialog.open(RejectionReasonDialogComponent, {
            width: '800px',
            height: '600px'
        });

        return await dialog.afterClosed().toPromise<string>();
    }

    private async sendToService(projectId: string, workflowCode: string): Promise<boolean> {
        const serviceOpsLabel = this._translate.instant('services.projectWorkflow.serviceOps');
        const serviceAdminLabel = this._translate.instant('services.projectWorkflow.serviceAdmin');
        const toServiceOps = this._salesCodeConfig.isLeakCall(projectId);
        const receiver = toServiceOps ? serviceOpsLabel : serviceAdminLabel;
        const options = { review: { projectId } } as ProjectReviewOptions;
        const sentTo = this._translate.instant('services.projectWorkflow.projectWillBeSentToReceiver', { receiver });
        const sentToReceiver = this._translate.instant('services.projectWorkflow.sentToReceiver', { receiver });

        if (!await this.confirmAction(sentTo)) {
            return false;
        }

        return await this.executeProcessorReviewAction('send_to_so', sentToReceiver, options, workflowCode);
    }

    private async waitingForFollowUpQuote(generalInfo: ProjectGeneralInfo, workflowCode: string): Promise<boolean> {
        const { projectId } = generalInfo.basicInfo;
        const review = await this._projectReviewHttp.getBranchReview(projectId);

        if (!review) {
            const ok = this._translate.instant('snackbars.ok');
            this._snackBar.open(this._translate.instant('services.projectWorkflow.makeSureBranchReviewCompleted'), ok);

            return false;
        }

        if (!await this.confirmAction(this._translate.instant('services.projectWorkflow.verifyYourProjectReview'))) {
            return false;
        }

        const { showBillableHours, billableHours } = review;

        if (showBillableHours && !billableHours) {
            const ok = this._translate.instant('snackbars.ok');
            this._snackBar.open(this._translate.instant('services.projectWorkflow.includeBillableHours'), ok);

            return false;
        }

        return this.executeBranchReviewAction('wait_for_follow_up_quote', 'waiting for follow up quote', review, workflowCode);
    }

    private async readyForProcessingWithoutBudget(generalInfo: ProjectGeneralInfo, workflowCode: string): Promise<boolean> {
        const dialog = this._dialog.open(ReadyForProcessingWithoutBudgetReasonDialogComponent, {
            width: '500px',
            height: '450px'
        });

        const { projectId } = generalInfo.basicInfo;
        const review = await this._projectReviewHttp.getBranchReview(projectId);
        const ok = this._translate.instant('snackbars.ok');

        if (!review) {
            this._snackBar.open(this._translate.instant('services.projectWorkflow.makeSureBranchReviewCompleted'), ok);

            return false;
        }

        const { showBillableHours, billableHours } = review;
        const reason = await dialog.afterClosed().toPromise();

        if (!reason) {
            return false;
        }

        if (!await this._projectReviewHttp.addNoBudgetReason(projectId, reason)) {
            this._snackBar.open(this._translate.instant('services.projectWorkflow.failedToSaveReasonForMissingBudget'), ok);
            return false;
        }

        if (showBillableHours && !billableHours) {
            this._snackBar.open(this._translate.instant('services.projectWorkflow.includeBillableHoursInProjectReview'), ok);

            return false;
        }

        return await this.executeBranchReviewAction('approveB', 'ready for processing', review, workflowCode);
    }

    // eslint-disable-next-line complexity
    private async process(generalInfo: ProjectGeneralInfo, workflowCode: string): Promise<boolean> {
        const { basicInfo, isLateFillIn } = generalInfo;
        const { projectId, projectName } = basicInfo;
        const isAudit = this._salesCodeConfig.isAudit(projectId);
        const ok = this._translate.instant('snackbars.ok');

        if (isAudit && !await this._reportHttp.isConditionReportExist(projectId)) {
            this._snackBar.open(this._translate.instant('services.projectWorkflow.conditionReportMustBeGenerated'), ok);
            return false;
        }

        const currentReview = await this._projectReviewHttp.getProcessorReview(projectId);

        if (!currentReview || (currentReview.checklist ?? []).length === 0) {
            this._snackBar.open(this._translate.instant('services.projectWorkflow.makeSureReviewCompleted'), ok);
            return false;
        }

        const completedReview = this._salesCodeConfig.isLeakCall(projectId) ?
            await this.completeProcessorReview(currentReview, isLateFillIn) :
            { ...currentReview };

        if (!completedReview) {
            return false;
        }

        const isProcessed = await this.finalizeProcess(projectId, completedReview, workflowCode);

        if (isProcessed && this.useInternalFeatures && this._salesCodeConfig.isLeakCall(projectId)) {
            this._dialog.open(AddStockLogDialogComponent, {
                data: { projectId, projectName } as StockLogDialogOptions,
                width: '440px',
                height: '600px'
            });
        }

        return isProcessed;
    }

    private async completeProcessorReview(review: ProcessorReview, isLateFillIn: boolean): Promise<ProcessorReview> {
        const copy: ProcessorReview = JSON.parse(JSON.stringify(review));
        const { projectId, checklist } = copy;
        const foremen = await this._projectHttp.getForemenOnProject(projectId);

        if (checklist[4]) {
            return { ...copy, foremanRatings: foremen.map(_ => ({ foreman: _, checklist })) };
        }

        const dialog = this._dialog.open(ZeroSafetyReasonDialogComponent, {
            data: { foremen, isLateFillIn } as ZeroSafetyReasonDialogOption,
            width: '600px',
            height: '600px'
        });

        const reason = await dialog.afterClosed().toPromise<ZeroSafetyReason>();
        // allow dialog to be fully closed before opening other dialogs
        await new Promise(_ => setTimeout(_, 300));

        if (!reason) {
            return null;
        }

        for (const rating of reason.foremanRatings) {
            rating.checklist = [...copy.checklist.slice(0, 4), ...rating.checklist];
        }

        const { foremanRatings, comment } = reason;

        return { ...copy, foremanRatings, zeroSafetyReason: comment };
    }

    private async finalizeProcess(projectId: string, review: ProcessorReview, workflowCode: string): Promise<boolean> {
        const dialog = this._dialog.open(ProcessProjectDialogComponent, {
            data: projectId,
            width: '600px',
            height: '600px'
        });

        const invoiceData = await dialog.afterClosed().toPromise<ProcessInvoiceData>();

        if (!invoiceData) {
            return false;
        }

        const { invoiceNumber, draftNumber, invoiceFile, transferId, grossAmount, includeInvoice } = invoiceData;

        const processOptions = {
            review,
            processWithInvoice: includeInvoice,
            fileName: invoiceFile?.name ?? '',
            invoiceNumber,
            draftNumber,
            grossAmount,
            transferredProjectId: transferId
        } as ProjectReviewOptions;

        const promise = this._projectReviewHttp.processProject(processOptions, invoiceFile, workflowCode);

        return await this.showActionProgress(promise, review.projectId, 'processed');
    }

    private async confirmAction(message: string): Promise<boolean> {
        const proceed = this._translate.instant('services.projectWorkflow.proceed');
        const areYouSure = this._translate.instant('services.projectWorkflow.areYouSure');
        const cancel = this._translate.instant('shared.cancel');
        const data = new ConfirmDialogOption(areYouSure, message, true, proceed, cancel);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        return await dialog.afterClosed().toPromise();
    }

    private async executeBranchReviewAction(
        action: string,
        workflowName: string,
        review: BranchReview,
        workflowCode: string,
        isSilent = false
    ): Promise<boolean> {
        const promise = this._projectReviewHttp.executeBranchReviewAction(action, review, workflowCode);

        if (isSilent) {
            const result = await promise;
            return result.code === '200';
        }

        return await this.showActionProgress(promise, review.projectId, workflowName);
    }

    private async executeProcessorReviewAction(
        action: string,
        workflowName: string,
        option: ProjectReviewOptions,
        workflowCode: string
    ): Promise<boolean> {
        const promise = this._projectReviewHttp.executeProcessorReviewAction(action, option, workflowCode);

        return await this.showActionProgress(promise, option.review.projectId, workflowName);
    }

    private async executeNonReviewAction(
        action: string,
        workflowName: string,
        projectId: string,
        workflowCode: string
    ): Promise<boolean> {
        const promise = this._projectReviewHttp.executeNonReviewAction(projectId, action, workflowCode);

        return await this.showActionProgress(promise, projectId, workflowName);
    }

    private async showActionProgress(
        action: Promise<boolean | CodeDescription>,
        projectId = '',
        workflowName = ''
    ): Promise<boolean> {
        let [success, error] = ['', ''];
        const pleaseWait = this._translate.instant('shared.pleaseWait');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');

        if (this.useInternalFeatures) {
            const parameters = { id: ProjectIdUtility.toDisplayFormat(projectId), workflow: workflowName };
            success = this._translate.instant('services.projectWorkflow.projectIsNowWorkflow', parameters);
            error = this._translate.instant('services.projectWorkflow.unableToMoveToWorkflow', parameters);
        }
        else {
            success = this._translate.instant('services.projectWorkflow.workflowExecutionSuccess');
            error = this._translate.instant('services.projectWorkflow.workflowExecutionFailure');
        }

        const data = new ActionProgressReporterOption(action, pleaseWait, success, error, ok, gotIt);
        const dialog = this._dialog.open(ActionProgressDialogComponent, { data, width: '320px', height: '120px' });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private getWorkflowText(code: string) {
        switch (code) {
            case ProjectWorkflow.Assigned:
                return this._translate.instant('services.projectWorkflow.workflow.assigned');
                break;
            case ProjectWorkflow.SignedIn:
                return this._translate.instant('services.projectWorkflow.workflow.signedIn');
                break;
            case ProjectWorkflow.Unassign:
                return this._translate.instant('services.projectWorkflow.workflow.unassigned');
                break;
            default:
                return '';
                break;
        }
    }
}
