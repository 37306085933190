import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { ServiceRatesMaintenanceComponent } from './service-rates-maintenance/service-rates-maintenance.component';
import { ServiceRatesVersionsViewComponent } from './service-rates-maintenance/service-rates-versions-view/service-rates-versions-view.component';
import { ServiceRatesGroupEditorComponent } from './service-rates-maintenance/service-rates-group-editor/service-rates-group-editor.component';
import { ServiceRatesEditorComponent } from './service-rates-maintenance/service-rates-editor/service-rates-editor.component';
import { ServiceRatesBranchOptionsComponent } from './service-rates-maintenance/service-rates-editor/service-rates-branch-options/service-rates-branch-options.component';
import { ServiceRatesMaterialsOptionsComponent } from './service-rates-maintenance/service-rates-editor/service-rates-materials-options/service-rates-materials-options.component';
import { ServiceRatesSummaryLineItemsOptionsComponent } from './service-rates-maintenance/service-rates-editor/service-rates-summary-line-items-options/service-rates-summary-line-items-options.component';
import { ServiceRatesMiscellaneousOptionsComponent } from './service-rates-maintenance/service-rates-editor/service-rates-miscellaneous-options/service-rates-miscellaneous-options.component';
import { ServiceRatesTasksOptionsComponent } from './service-rates-maintenance/service-rates-editor/service-rates-tasks-options/service-rates-tasks-options.component';
import { ServiceRatesQuestionsOptionsComponent } from './service-rates-maintenance/service-rates-editor/service-rates-questions-options/service-rates-questions-options.component';

@NgModule({
    declarations: [
        ServiceRatesMaintenanceComponent,
        ServiceRatesVersionsViewComponent,
        ServiceRatesGroupEditorComponent,
        ServiceRatesEditorComponent,
        ServiceRatesBranchOptionsComponent,
        ServiceRatesMaterialsOptionsComponent,
        ServiceRatesSummaryLineItemsOptionsComponent,
        ServiceRatesMiscellaneousOptionsComponent,
        ServiceRatesQuestionsOptionsComponent,
        ServiceRatesTasksOptionsComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        ServiceRatesMaintenanceComponent,
        ServiceRatesVersionsViewComponent,
        ServiceRatesGroupEditorComponent,
        ServiceRatesEditorComponent,
        ServiceRatesBranchOptionsComponent,
        ServiceRatesMaterialsOptionsComponent,
        ServiceRatesSummaryLineItemsOptionsComponent,
        ServiceRatesMiscellaneousOptionsComponent,
        ServiceRatesQuestionsOptionsComponent,
        ServiceRatesTasksOptionsComponent
    ]
})
export class RatesMaintenanceModule { }
