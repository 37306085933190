import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateService } from '@ngx-translate/core';
import BudgetTemplate from '../../../../core/data-models/budget-template';
import { BudgetHttpService } from '../../../../core/services/http/budget-http/budget-http.service';
import { DownloadHttpService } from '../../../../core/services/http/download-http/download-http.service';

@Component({
    selector: 'app-budget-templates-dialog',
    styleUrls: ['./budget-templates-dialog.component.scss'],
    templateUrl: './budget-templates-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BudgetTemplatesDialogComponent {
    public isLoaded = false;
    public templates: BudgetTemplate[] = [];
    public selectedTemplate: BudgetTemplate;
    public previewFile: Promise<Blob>;

    get assignedCustomers(): string[] {
        return this.selectedTemplate?.assignedCustomers?? [];
    }

    public constructor(public translate: TranslateService,
        private _dialog: MatDialogRef<BudgetTemplatesDialogComponent>,
        private _budgetHttp: BudgetHttpService,
        private _downloadHttp: DownloadHttpService) {
    }

    public async ngOnInit(): Promise<void> {
        this.templates = await this._budgetHttp.getBudgetTemplatesWithCustomer();
        this.isLoaded = true;
    }

    public onClose(): void {
        this._dialog.close();
    }

    public async downloadTemplate(template: BudgetTemplate): Promise<void> {
        const url = this._budgetHttp.getBudgetTemplateUrl(template);
        await this._downloadHttp.download(url, template.fileName, 'application/octet-stream');
    }

    public onTemplateChange(event: MatSelectChange): void {

        this.previewFile = this._budgetHttp.getBudgetTemplateXlsxFile(event.value);
    }
}
