<div class="quote-summary-card-container" [ngClass]="{ 'active-summary-card': isActive }" fxLayout="row">
    <div class="quote-type-indicator" [ngClass]="{ 'qqq-quote-indicator': isQQQ }"></div>

    <div class="summary-content" fxLayout="column" matRipple>
        <div class="quote-customer-and-estimator" fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="2px" fxFlexAlign="end">
            <span>{{ customerId }}</span>
            <span>{{ estimator }}</span>
        </div>

        <span class="quote-name">{{ summary.name ? summary.name : 'N/A' }}</span>
        <span class="quote-id">{{ quoteId }}</span>
        <span class="quote-customer-name">{{ summary.customerName }}</span>
        <div fxLayout="row" fxLayoutAlign="center end" class="main-icons-container">
            <span class="quote-summary-icon" *ngIf="summary.budgetUploaded"
                matTooltip="{{ 'shared.quote.summary.budgetUpload' | translate }}" matTooltipPosition="right">
                <mat-icon>attach_money</mat-icon>
            </span>
            <span class="quote-summary-icon warning" *ngIf="summary.isUrgent"
                matTooltip="{{ 'shared.quote.summary.priorityFlag' | translate }}" matTooltipPosition="right">
                <mat-icon>warning</mat-icon>
            </span>
        </div>
        <div fxFlex></div>
        <span class="quote-create-time">{{ 'shared.summaryList.card.createdOn' | translate }} {{ summary.createdTime | branchLocalTime: summary.branch : branches }}</span>

        <span class="quote-status"
            [ngClass]="{ 'lost-quote': summary.status === 'Lost' }"
            fxFlexAlign="flex-end">

            {{ status }}
        </span>
    </div>
</div>
