export class LaborExpense {
    public hours = 0;
    public hourlyRate = 0;
    public totalCost = 0;

    constructor(hours = 0, hourlyRate = 0, totalCost = 0) {
        this.hours = hours;
        this.hourlyRate = hourlyRate;
        this.totalCost = totalCost;

    }
}
