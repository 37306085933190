<ng-container *ngIf="template.isDisplayItem">
    <template-edit-item-card *ngIf="isEditMode" [template]="template" (valueChange)="editChange.emit($event)"></template-edit-item-card>
    <template-display-item-card *ngIf="!isEditMode" [template]="template"></template-display-item-card>
</ng-container>

<div class="template-content-card-parent-container"
    *ngIf="!template.isDisplayItem"
    [ngClass]="containerStyle"
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="15px">

    <div *ngIf="isEmptyRoot" fxLayoutAlign="center center" fxFlex>
        <span>{{ 'shared.templateContentCard.sectionNotAvailable' | translate }}</span>
    </div>

    <ng-container *ngIf="!isEmptyRoot">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
            <app-custom-icon *ngIf="level === 0" [icon]="icon"></app-custom-icon>
            <span>{{ template.nameAttributes.translatedName }}</span>

            <div *ngIf="allowEdit && !isLoading" fxLayoutAlign="start center" fxLayoutGap="-2px">
                <button *ngIf="!isEditMode"
                    mat-icon-button
                    class="template-content-card-action-button"
                    (click)="editStart.emit(template)"
                    matTooltip="Edit Report"
                    matTooltipPosition="above">

                    <mat-icon>create</mat-icon>
                </button>

                <button *ngIf="isEditMode"
                    mat-icon-button
                    class="template-content-card-action-button template-content-card-done-button"
                    (click)="editSave.emit(template)"
                    matTooltip="Save Changes"
                    matTooltipPosition="above">

                    <mat-icon>done</mat-icon>
                </button>

                <button *ngIf="isEditMode"
                    mat-icon-button
                    class="template-content-card-action-button template-content-card-cancel-button"
                    (click)="editCancel.emit()"
                    matTooltip="Cancel Edit"
                    matTooltipPosition="above">

                    <mat-icon>clear</mat-icon>
                </button>
            </div>
        </div>

        <div *ngIf="!hasDisplayItem && !isEmptySection" fxLayout="row wrap" fxLayoutAlign="space-between stretch">
            <template-content-card class="template-content-card-sections"
                *ngFor="let field of template.fields; let i = index"
                [level]="level + 1"
                [template]="field"
                [index]="i"
                [isEditMode]="isEditMode"
                [isLoading]="isLoading">
            </template-content-card>
        </div>

        <div class="template-content-card-display-items"
            *ngIf="hasDisplayItem || isEmptySection"
            [ngStyle]="{ 'animation-delay': (index + 1) * 0.025 + 's' }"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxLayoutGap="5px">

            <dots-loader *ngIf="isLoading"></dots-loader>

            <ng-container *ngIf="!isLoading">
                <div class="template-content-pending-input" *ngIf="!template.fields.length" fxLayout="row" fxLayoutAlign="start center">
                    <span>{{ 'shared.templateContentCard.techEnteredNothing' | translate }}</span>
                </div>

                <template-content-card *ngFor="let field of getVisibleFields(template.fields); let i = index"
                    [level]="level + 1"
                    [template]="field"
                    [index]="i"
                    [isEditMode]="isEditMode"
                    (editChange)="onFieldValueChange($event)"
                    fxFlexAlign="flex-start">
                </template-content-card>
            </ng-container>
        </div>
    </ng-container>
</div>
