import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import HealthScreeningOption from '../../../../../../../core/data-models/health-screening-option';
import DispatchExistingOptions from '../../../../../../../core/data-models/project-general-info/dispatch-existing-options';
import { ValidatorFactory } from '../../../../../../../core/services/validator-factory/validator-factory.service';
import ProjectIdUtility from '../../../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import Crew from '../../../../../../../core/data-models/crew';
import ForemanSelectionStepConfig from '../../../../../../../core/data-models/re-dispatch/foreman-selection-step-config';
import HandoffMeetingUtilityService from '../../services/handoff-meeting-utility.service';
import { CountryName } from '../../../../../../../core/enums/country-name.enum';
import { SalesCodeConfigService } from '../../../../../../../core/services/sales-code-config/sales-code-config.service';

@Component({
    selector: 'dispatch-report',
    templateUrl: './dispatch-report.component.html',
    styleUrls: ['./dispatch-report.component.scss']
})
export class DispatchReportComponent implements OnInit {
    public dispatchNewJobForm: UntypedFormGroup;
    public isLeakCall = false;
    public isMultiDay = true;
    public country: CountryName;
    @Input() handOffMeetingApprovedCrew: Crew;
    @Input() dispatchExistingOptions: DispatchExistingOptions;
    @Input() config: ForemanSelectionStepConfig;
    @Output() stepClicked = new EventEmitter<string>();
    @Output() jobTypeChanged = new EventEmitter<string>();
    @Output() selectedCrewChanged = new EventEmitter<Crew>();
    public healthScreeningOption: HealthScreeningOption;
    public jobTypeList = [{ desc: 'projects.dispatchReport.jobTypes.permanentRepairs', code: '311' }];
    private _daysValidator = this._validatorFactory.createPositiveValidator();
    private _moneyValidator = this._validatorFactory.createCurrencyValidator();

    constructor(
        private _validatorFactory: ValidatorFactory,
        private _formBuilder: UntypedFormBuilder,
        private _salesCodeConfigService: SalesCodeConfigService) {
    }

    public ngOnInit(): void {
        this.dispatchNewJobForm = this._formBuilder.group({
            jobType: [null, Validators.required],
            continueLeakCall: [false],
            siteContact: ['', Validators.required],
            contactPhone: ['', Validators.required],
            drawingRequired: [false],
            overNightSealRequired: [false],
            crew: [null, Validators.required],
            notes: [''],
            notToExceedAmount: ['', this._moneyValidator.validator],
            expectedDays: [1, this._daysValidator.validator]
        });

        const { projectId, customerId, siteId, siteContact } = this.dispatchExistingOptions;
        const isAuditJob = this._salesCodeConfigService.isAudit(projectId);
        const isLeakCall = this._salesCodeConfigService.isLeakCall(projectId);
        this.country = ProjectIdUtility.isUsProject(projectId)? CountryName.US: CountryName.CA;

        if (customerId && siteId) {
            this.healthScreeningOption = new HealthScreeningOption(customerId, siteId);
        }

        if (siteContact) {
            this.dispatchNewJobForm.get('siteContact').setValue(siteContact.name.value);
            this.dispatchNewJobForm.get('contactPhone').setValue(siteContact.officePhone.value);
        }

        if (isAuditJob || isLeakCall) {
            this.jobTypeList = [
                { desc: 'projects.dispatchReport.jobTypes.leakInvestigation', code: '312' },
                { desc: 'projects.dispatchReport.jobTypes.permanentRepairs', code: '311' }
            ];
            this.isLeakCall = true;
            this.isMultiDay = false;
        }
        else {
            this.jobTypeList = [{ desc: 'projects.dispatchReport.jobTypes.permanentRepairs', code: '311' }];
        }

        this.onChanges();
    }

    public crewSelected(selectedCrew: Crew): void {
        this.selectedCrewChanged.emit(selectedCrew);
        this.dispatchNewJobForm.get('crew').setValue(selectedCrew);
    }

    public handOffMeetingRequired(): boolean {
        const currentCrew = this.dispatchNewJobForm.get('crew').value;
        // eslint-disable-next-line max-len
        return HandoffMeetingUtilityService.handOffMeetingRequired(this.dispatchExistingOptions.isHandOffMeetingRequired, this.handOffMeetingApprovedCrew, currentCrew);
    }

    public changeJobType(jobType: string): void {
        this.isLeakCall = this._salesCodeConfigService.isLeakCall(jobType);
        this.isMultiDay = this._salesCodeConfigService.isMultiDay(jobType);
        this.jobTypeChanged.emit(jobType);
    }

    public submitBasicDispatchInfo(): void {
        if (this.dispatchNewJobForm.valid) {
            this.stepClicked.emit('next');
        }
    }

    private onChanges(): void {
        this.dispatchNewJobForm.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(dispatchState => {
            this.config.data = dispatchState;
            this.config.completed = this.dispatchNewJobForm.valid;
        });
    }
}


