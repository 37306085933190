import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import AttachmentModel from '../../../../core/data-models/attachment-model';
import { QuoteHttpService } from '../../../../core/services/http/quote-http/quote-http.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { DownloadHttpService } from '../../../../core/services/http/download-http/download-http.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UploadHttpService } from '../../../../core/services/http/upload-http/upload-http.service';
import NavigationTab from '../../../../core/data-models/navigation-tab';
import ImageItem from '../../../../core/data-models/job-template-items/image-item';
import ImageCardConfig from '../../../../core/data-models/card-items/image-card-config';
import { GenericUtilityService } from '../../../../core/services/utility/generic-utility/generic-utility.service';
import { AppEventService } from '../../../../core/services/events/app-event/app-event.service';
import { TranslateService } from '@ngx-translate/core';
import PendingQuoteAttachments from '../../../../core/data-models/quote-options/pending-quote-attachments';
import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog-component/confirm-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-quote-attachments',
    styleUrls: ['./quote-attachments.component.scss'],
    templateUrl: './quote-attachments.component.html',
    encapsulation: ViewEncapsulation.None
})
export class QuoteAttachmentsComponent implements OnInit {
    @Input() public activeQuoteId: string;
    @Input() public customerId: string;
    @Input() public pendingAttachments: PendingQuoteAttachments;
    @Output() public localFilesChanged = new EventEmitter<Map<AttachmentModel, File>>();
    public attachments: AttachmentModel[] = [];
    public photos: ImageItem[] = [];
    public options: NavigationTab[];
    // eslint-disable-next-line
    public currentOption: string;
    public imageCardConfig: ImageCardConfig;
    public loading = false;
    private activeQuoteOptions: NavigationTab[];

    // eslint-disable-next-line max-params
    constructor(private _quoteService: QuoteHttpService,
                private _downloadService: DownloadHttpService,
                private _uploadService: UploadHttpService,
                private _snackBar: MatSnackBar,
                private _appEvents: AppEventService,
                public translate: TranslateService,
                private _dialog: MatDialog) { }

    public async ngOnInit(): Promise<void> {
        const uploadAttachment = this.translate.instant('shared.quote.editor.uploadAttachment');
        const photos = this.translate.instant('shared.quote.editor.photos');
        this.options = [ new NavigationTab('Upload Attachment', 'attachment', null, null, uploadAttachment) ];
        this.activeQuoteOptions = [ new NavigationTab('Photos', 'photo_library', null, null, photos) ];
        this.currentOption = this.options[0].name;
        await this.loadAttachments();
        this.imageCardConfig = new ImageCardConfig();
        this.imageCardConfig.imageSize = '200px';
        this.imageCardConfig.useThumbnails = true;
        if (this.activeQuoteId) {
            this.options = [...this.options, ...this.activeQuoteOptions];
            if (!this.customerId) {
                this.customerId = (await this._quoteService.getQuoteGeneralInfo(this.activeQuoteId)).customer.customerId;
            }
        }
    }

    public async dropped(files: NgxFileDropEntry[]) : Promise<void> {
        this.loading = true;
        const uploadTasks = [];
        const filteredFiles = files.filter(_ => _.fileEntry.isFile);
        for (const droppedFile of filteredFiles) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            const fileType = fileEntry.name.split('.').pop().toLowerCase();
            if (!GenericUtilityService.allowedFileTypes().some(_ => _.fileType === fileType)) {
                const fileTypeNotAllowed = this.translate.instant('shared.quote.editor.fileTypeNotAllowed');
                const close = this.translate.instant('snackbars.close');
                this._snackBar.open(`${fileTypeNotAllowed}: ${fileEntry.name}`, close);
                this.loading = false;
                return;
            }
            fileEntry.file((file: File) => {
                const fileAttachment = new AttachmentModel();
                fileAttachment.name = file.name;
                fileAttachment.type = file.type;
                if (!this.activeQuoteId) {
                    fileAttachment.source = 'local';
                    this.addLocalFile(file, fileAttachment);
                }
                else {
                    uploadTasks.push(this.uploadFile(file, fileAttachment));
                }
            });
        }
        await Promise.all(uploadTasks);
        this._appEvents.setReloadQuoteCounter();
        this.loading = false;
    }

    public async download(file: AttachmentModel): Promise<void> {
        if (file.source === 'local') {
            const localFile = this.pendingAttachments.localFiles.get(file);
            this._downloadService.downloadBlob(localFile, file.name);
        }
        else {
            const signedUrl = await this._downloadService.getSignedUrl(this.activeQuoteId, file.name, file.type);
            if (signedUrl) {
                await this._downloadService.download(signedUrl, file.name, file.type);
            }
            else {
                const failedToDownload = this.translate.instant('shared.quote.editor.failedToDownload');
                const close = this.translate.instant('snackbars.close');
                this._snackBar.open(`${failedToDownload} ${file.name}`, close);
            }
        }
    }

    public async delete(file: AttachmentModel): Promise<void> {

        if (!await this.confirmDeleteAttachment(file.name)) {
            return;
        }

        this.loading = true;
        if (file.source === 'local') {
            this.pendingAttachments.localFiles.delete(file);
            this.localFilesChanged.emit(this.pendingAttachments.localFiles);
            this.attachments = this.attachments.filter(_ => _.name !== file.name);
        }
        else if (!this.activeQuoteId) {
            this.pendingAttachments.copiedAttachments = this.pendingAttachments.copiedAttachments.filter(_ => _.name !==file.name);
            this.attachments = this.attachments.filter(_ => _.name !== file.name);
        }
        else if (! await this._quoteService.deleteQuoteAttachment(this.activeQuoteId, file)) {
            const failedToDelete = this.translate.instant('shared.quote.editor.failedToDelete');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(`${failedToDelete} ${file.name}`, close);
        }
        else {
            await this.loadAttachments();
            this._appEvents.setReloadQuoteCounter();
        }

        this.loading = false;
    }

    private async confirmDeleteAttachment(fileName: string): Promise<boolean> {
        const title = this.translate.instant('shared.attachments.confirmDeleteAttachmentDialog.title', { fileName });
        const message = this.translate.instant('shared.attachments.confirmDeleteAttachmentDialog.message');
        const confirmText = this.translate.instant('shared.attachments.confirmDeleteAttachmentDialog.continue');
        const cancelText = this.translate.instant('shared.cancel');

        const data = new ConfirmDialogOption(title, message, true, confirmText, cancelText);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data, width: '40vw' });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    public getColorByFileType(fileName: string): string {
        return GenericUtilityService.getFileIconColorByFileType(fileName);
    }

    private async loadAttachments(): Promise<void> {
        if (this.activeQuoteId) {
            this.attachments = (await this._quoteService.getQuoteAttachments(this.activeQuoteId))?.filter(_ => _.type !== 'flynn/budgetfile') ?? [];
            this.photos = await this._quoteService.getQuotePhotos(this.activeQuoteId);
            return;
        }

        // below cases are for quotes that are not saved on backend so we just sync attachments with the pending attachments of the front end
        if (this.pendingAttachments && this.pendingAttachments.localFiles) {
            this.pendingAttachments.localFiles.forEach((_: File, attachment: AttachmentModel) => this.attachments.push(attachment));
        }

        if (this.pendingAttachments && this.pendingAttachments.copiedAttachments) {
            this.pendingAttachments.copiedAttachments.forEach((attachment: AttachmentModel) => this.attachments.push(attachment));
        }
    }

    private addLocalFile(file: File, fileAttachment: AttachmentModel): void {
        const localFileNames = Array.from(this.pendingAttachments.localFiles.keys());
        const currentFile = localFileNames.find(_ => _.name === fileAttachment.name);
        if (currentFile) {
            this.pendingAttachments.localFiles.delete(currentFile);
            this.attachments = this.attachments.filter(_ => _.name !== currentFile.name);
        }

        this.pendingAttachments.localFiles.set(fileAttachment, file);
        this.localFilesChanged.emit(this.pendingAttachments.localFiles);
        this.attachments = [...this.attachments, fileAttachment];
    }

    private async uploadFile(file: File, fileAttachment: AttachmentModel): Promise<void> {
        if (await this._uploadService.uploadQuoteAttachment(this.activeQuoteId, fileAttachment, file)) {
            await this.loadAttachments();
        }
        else {
            const failedToUpload = this.translate.instant('shared.quote.editor.failedToUpload');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(`${failedToUpload} ${file.name}`, close);
        }
    }
}
