export default class BudgetManagementPayload {
    public useLegacyBudgetTool: boolean;
    public hasExistingExcelBudget: boolean;
    public hasExistingWebBudget: boolean;
    public recordId: string;
    public customerId: string;
    public branchCodeOverride: string;
    public isQuote: boolean;
    public isSubcontract: boolean;

    // eslint-disable-next-line max-params
    constructor(useLegacyBudgetTool: boolean, hasExistingExcelBudget: boolean, hasExistingWebBudget: boolean, recordId: string, customerId: string, isQuote: boolean, isSubcontract: boolean, branchCodeOverride = '') {
        this.useLegacyBudgetTool = useLegacyBudgetTool;
        this.hasExistingExcelBudget = hasExistingExcelBudget;
        this.hasExistingWebBudget = hasExistingWebBudget;
        this.recordId = recordId;
        this.customerId = customerId;
        this.isQuote = isQuote;
        this.isSubcontract = isSubcontract;
        this.branchCodeOverride = branchCodeOverride;
    }
}
