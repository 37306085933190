import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import ImageItem from '../../../../core/data-models/job-template-items/image-item';
import ImageCardConfig from '../../../../core/data-models/card-items/image-card-config';
import ImageCardEvent from '../../../../core/data-models/card-items/image-card-event';

@Component({
    selector: 'app-select-photo-dialog',
    styleUrls: ['./select-photo-dialog.component.scss'],
    templateUrl: './select-photo-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SelectPhotoDialogComponent implements OnInit {
    public imageCardConfig: ImageCardConfig;
    public requireRefresh = false;

    constructor(public translate: TranslateService,
        private _dialog: MatDialogRef<SelectPhotoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ImageItem[]) { }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        this.imageCardConfig = new ImageCardConfig();
        this.imageCardConfig.imageSize = '200px';
        this.imageCardConfig.showDescription = true;
        const restoreLabel = this.translate.instant('shared.select');
        this.imageCardConfig.actionButtons = [{ text: 'select', isWarn: false, icon: '', displayText: restoreLabel }];
    }

    public doPhotoCardAction(event: ImageCardEvent): void {
        if (event.action === 'select') {
            this._dialog.close(event.source);
        }
    }

    public onClose(): void {
        this._dialog.close(null);
    }
}
