<div class="quote-container">
    <summary-list-filter
        [filterConfigs]="filterConfiguration"
        [filterCheckBox]="qqqCheckBox"
        (onFilterDropDownChecked)="onFilterApplied($event)"
        (onKeyWordFilterSearch)="onFilterSearch($event)"
        (onCheckBoxStatusToggled)="onqqqCheckBoxToggled($event)">
    </summary-list-filter>

    <div class="quote-bottom-wrapper" fxLayout="row">
        <summary-list [ngClass]="{ 'quote-summary-list-collapsed': !summaryListExpanded }"
            [ngStyle]="summaryListStyle"
            [isProject]="false"
            [isLoading]="!isSummaryListLoaded"
            [queryResult]="queryResult"
            [activeId]="activeQuoteId"
            [(isFocusMode)]="hasTargetSummary"
            (isFocusModeChange)="loadQuoteSummaries(query.currentPage)"
            (listRefresh)="loadQuoteSummaries(query.currentPage)"
            (pageChanged)="loadQuoteSummaries($event)"
            (summarySelected)="openQuote($event)"
            (toggleExpanded)="onToggleExpand($event)">
        </summary-list>

        <div class="quote-content-area" fxFlex>
            <nav class="tabs"
                #matTabNavBar
                mat-tab-nav-bar
                [ngClass]="{ 'quote-tabs-hidden': !activeQuoteId }"
                [disablePagination]="true"
                (window:resize)="resizeTabs()">

                <a mat-tab-link *ngFor="let tab of visibleTabs"
                    [uiSref]="tab.state"
                    [uiParams]="{ quoteId: activeQuoteId }"
                    uiSrefActive="active"
                    [active]="tabRef.classList.contains('active')"
                    #tabRef
                    class="tab-nav-with-badge">

                    <span [ngClass]="{ 'updated-badge': updatedTabs.has(tab) }" [matBadge]="tab.badge">{{ tab.displayName }}</span>
                </a>

                <button *ngIf="hiddenTabs.length" mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_horiz</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                    <button *ngFor="let tab of hiddenTabs"
                        mat-menu-item
                        [uiSref]="tab.state"
                        [uiParams]="{ quoteId: activeQuoteId }"
                        (click)="onHiddenTabSelected(tab.state)"
                        class="menu-nav-with-badge">

                        <span [matBadge]="tab.badge">{{ tab.displayName }}</span>
                    </button>
                </mat-menu>
            </nav>

            <div class="quote-content" *ngIf="activeQuoteId">
                <ui-view></ui-view>
            </div>

            <div class="blank-quote-content" *ngIf="!activeQuoteId" fxLayout="column" fxLayoutAlign="center center">
                <mat-icon *ngIf="isInternal" svgIcon="flynnTruckAnimated" aria-hidden="true"></mat-icon>
                <img *ngIf="!isInternal" src="../../../assets/images/field_zoom_logo.png" alt="empty state image" />

                <span class="quote-notice-message">{{ 'quote.nothingSelectedYet' | translate }}</span>

                <div class="quote-instruction-message" fxLayoutAlign="start center" fxLayoutGap="6px">
                    <mat-icon aria-hidden="true">double_arrow</mat-icon>
                    <span>{{ 'quote.selectQuote' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
