import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import ImageItem from '../../../core/data-models/job-template-items/image-item';
import { ImageHttpService } from '../../../core/services/http/image-http/image-http.service';
import ImageCardConfig from '../../../core/data-models/card-items/image-card-config';
import ImageCardEvent from '../../../core/data-models/card-items/image-card-event';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FullViewPhotoDialogComponent } from '../dialogs/full-view-photo-dialog/full-view-photo-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';
import { UploadImageDialogComponent } from '../dialogs/upload-image-dialog/upload-image-dialog.component';
import { UploadHttpService } from '../../../core/services/http/upload-http/upload-http.service';
import { DeletedPhotoDialogComponent } from '../dialogs/deleted-photo-dialog/deleted-photo-dialog.component';
import { AppEventService } from '../../../core/services/events/app-event/app-event.service';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';

@Component({
    selector: 'app-drawing-panel',
    styleUrls: ['./drawing-panel.component.scss'],
    templateUrl: './drawing-panel.component.html'
})
export class DrawingPanelComponent implements OnInit {
    @Input() public activeProjectId = '';
    public drawings: ImageItem[] = [];
    public drawingCardConfig: ImageCardConfig;
    public loading: boolean;
    public editMode: boolean;
    public drawingForEdit: ImageItem;
    public deletedImages: ImageItem[] = [];
    private _noDeletedPhotosMessage = '';
    public disableActionBar = false;

    get deletedPhotosToolTip(): string {
        return !this.deletedImages.length ? this._noDeletedPhotosMessage : '';
    }

    //eslint-disable-next-line
    public constructor(
        private _translate: TranslateService,
        private _imageHttpService: ImageHttpService,
        private _uploadService: UploadHttpService,
        private _appEvents: AppEventService,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _changeDetector: ChangeDetectorRef) {
    }

    public async ngOnInit() : Promise<void> {
        this.initializeCardConfig();
        this._noDeletedPhotosMessage = this._translate.instant('drawings.noDeletedPhotosMessage');
        await this.getDrawingsFromService();
    }

    public doCardAction(event: ImageCardEvent): void {
        switch (event.action) {
            case 'Delete':
                this.deleteImage(event.source);
                break;
            case 'Edit': this.setEditDrawing(event.source);
                break;
            case 'click':
                this.openDrawingDialog(event.source);
                break;
            case 'Save':
                this.drawingForEdit = JSON.parse(JSON.stringify(event.source));
                this.doEditAction(event.action);
                break;
        }
    }

    public async doEditAction(action: string): Promise<void> {
        switch (action) {
            case 'Save':
                await this.saveImage(this.drawingForEdit);
                break;
            case 'Back': this.editMode = false;
                break;
        }
    }

    private async deleteImage(image: ImageItem): Promise<void> {
        const isPhoto = false;
        if (await this._imageHttpService.removeImage(this.activeProjectId, image, isPhoto)) {
            await this.getDrawingsFromService();
            this.editMode = false;
            this.reloadCounters();
        }
        else {
            const deleteImageFailureMessage = this._translate.instant('drawings.deleteImageFailureMessage');
            const closeLabel = this._translate.instant('shared.close');
            this._snackBar.open(deleteImageFailureMessage, closeLabel);
        }
    }

    private setEditDrawing(drawing: ImageItem): void {
        this.editMode = true;
        this.drawingForEdit = JSON.parse(JSON.stringify(drawing));
    }

    private openDrawingDialog(drawing: ImageItem): void {
        this._dialog.open(FullViewPhotoDialogComponent, {
            data: drawing,
            height: '80vh'
        });
    }

    public async openUploadImage(): Promise<void> {
        const dialog = this._dialog.open(UploadImageDialogComponent, {
            data: { title: this._translate.instant('drawings.uploadDialogTitle') },
            height: '700px',
            width: '1000px'
        });

        const image: string = await dialog.afterClosed().toPromise();
        if (image) {
            const imageBlob = GenericUtilityService.base64toBlob(image.replace('data:image/jpeg;base64,', ''), 'image/jpeg');
            await this.uploadImageInternal(imageBlob, `${new Date().getTime()}.jpg`);
        }
    }

    private async uploadImageInternal(image: Blob, imageName: string): Promise<void> {
        this.loading = true;
        const isPhotoUpload = false;
        const uploaded =
            await this._uploadService.uploadProjectPhoto(this.activeProjectId, image, imageName, isPhotoUpload);
        if (uploaded) {
            await this.getDrawingsFromService();
            this.reloadCounters();
        }
        else {
            const uploadFailed = this._translate.instant('drawings.uploadFail');
            const closeLabel = this._translate.instant('shared.close');
            this._snackBar.open(uploadFailed, closeLabel);
        }
        this.loading = false;
    }

    public async openDeletedPhoto(): Promise<void> {
        const dialogRef = this._dialog.open(DeletedPhotoDialogComponent, {
            data: {
                isPhotoMode: false,
                images: this.deletedImages,
                projectId: this.activeProjectId,
                dialogTitle: this._translate.instant('drawings.deletedDialogTitle')
            },
            minWidth: '600px',
            minHeight: '600px',
            width: '50vw',
            height: '50vh'
        });

        if (await dialogRef.afterClosed().toPromise()) {
            await this.getDrawingsFromService();
            this.reloadCounters();
        }
    }

    private reloadCounters(): void {
        if (ProjectIdUtility.isEmergencyQuote(this.activeProjectId)) {
            this._appEvents.setReloadQuoteCounter();
        }
        else {
            this._appEvents.setReloadProjectCounter();
        }
    }

    private initializeCardConfig(): void {
        this.drawingCardConfig = new ImageCardConfig();
        this.drawingCardConfig.imageSize = '250px';
        this.drawingCardConfig.showDescription = true;
        this.drawingCardConfig.showTags = true;
        this.drawingCardConfig.actionButtons = [{
            text: 'Edit',
            isWarn: false,
            icon: '',
            displayText: this._translate.instant('drawings.edit')
        },
        {
            text: 'Delete',
            isWarn: true,
            icon: '',
            displayText: this._translate.instant('drawings.delete')
        }];
        this.drawingCardConfig.useThumbnails = false;
    }

    private async getDrawingsFromService() : Promise<void> {
        this.loading = true;
        this.drawings = await this._imageHttpService.getDrawings(this.activeProjectId);
        this.deletedImages = await this._imageHttpService.getDeletedDrawings(this.activeProjectId);
        this.loading = false;
    }

    private async saveImage(drawing: ImageItem): Promise<void> {
        if (await this._imageHttpService.updateImage(this.activeProjectId, drawing)) {
            await this.getDrawingsFromService();
            this.editMode = false;
        }
        else {
            const drawingUpdateFailMessage = this._translate.instant('shared.drawingPanel.drawingUpdateFail');
            const closeLabel = this._translate.instant('shared.close');
            this._snackBar.open(drawingUpdateFailMessage, closeLabel);
        }
    }
}