import { Component, Input, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
import { process } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';

import Branch from '../../../core/data-models/branch';
import TranslationPair from '../../../core/data-models/generic/translation-pair';
import QuoteReport from '../../../core/data-models/data-report/quote-report';
import QuoteEditorOption from '../../../core/data-models/quote-editor-option';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import { QuoteStatusHttpService } from '../../../core/services/http/quote-status-http/quote-status-http.service';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { QuoteEditorComponent } from '../../../shared/components/quote-editor/quote-editor.component';
import TimeUtility from '../../../core/services/utility/time-utility/time-utility.service';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { LanguageKey } from '../../../core/enums/language-key.enum';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';
import { QuoteReportOptionsDialogComponent } from '../../../shared/components/dialogs/quote-report-options-dialog/quote-report-options-dialog.component';
import LanguageUtility from '../../../core/services/utility/language-utility/language-utility.service';

@Component({
    selector: 'app-quote-report',
    styleUrls: ['./quote-report.component.scss'],
    templateUrl: './quote-report.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: POPUP_CONTAINER,
        useFactory: () => {
            return { nativeElement: document.querySelector('.report-grid-div kendo-grid.quotes-report-grid') } as ElementRef;
        }
    },
    PopupService
    ]
})
export class QuoteReportComponent implements OnInit {
    @Input() public branches: Branch[];
    public gridViewData: QuoteReport[] = [];
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    public loading: boolean;
    public gridPageSize = 15;
    public query: string;
    public branchId: string;
    public filterableStatuses: TranslationPair[] = [];
    public selectedStatuses: string[] = [];
    public defaultStatuses: string[] = [];
    public isInternal = false;
    public currentSkip = 0;
    public excelExportColumns = [];
    private _allowedStatusesForDelete: string[] = [];

    //eslint-disable-next-line
    constructor(private _newRelicUtility: NewRelicUtilityService,
                private _userHttpService: UserHttpService,
                private _quoteHttpService: QuoteHttpService,
                private _quoteStatusHttpService: QuoteStatusHttpService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _downloadService: DownloadHttpService,
                private _translateService: TranslateService,
                private _featureFlagService: FeatureFlagService) {
        this.allData = this.allData.bind(this);
    }

    public async ngOnInit(): Promise<void> {
        const { branchInfo, language } = await this._userHttpService.getUserInfo();
        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        this.branchId = branchInfo.code;
        await this.loadQuoteStatuses(language);
        this.loadExcelColumns();
    }

    public hasProjectUrl(projectId: string): boolean {
        return /^\d/g.test(projectId);
    }

    public canDelete(quote: QuoteReport) : boolean {
        if (!quote.status) {
            return false;
        }
        return this._allowedStatusesForDelete.some((_: string) => _ === quote.status);
    }

    public async deleteQuote(quoteId: string) : Promise<void> {
        if (!await this._quoteHttpService.deleteQuote(quoteId)) {
            const error = this._translateService.instant('dataReport.quoteReport.failedToDeleteQuote');
            const buttonText = this._translateService.instant('shared.ok');
            this._snackBar.open(error, buttonText);
        }
        else {
            await this.loadReport(this.currentSkip);
        }
    }

    public async editQuote(quoteId: string) : Promise<void> {
        const dialog = this._dialog.open(QuoteEditorComponent, {
            data: QuoteEditorOption.getEditOption(quoteId),
            maxWidth: '87.5vw',
            width: '87.5vw',
            height: '95vh'
        });

        if (await dialog.afterClosed().toPromise()) {
            this.loadReport(this.currentSkip);
        }
    }

    public async printQuote(quoteReport: QuoteReport) : Promise<void> {
        const quoteId = quoteReport.quoteId;
        this._newRelicUtility.addPageAction('ReportType', 'QuoteReport', 'ReportAccess', {
            command: 'print',
            attribute: quoteId
        });

        const dialog = this._dialog.open(QuoteReportOptionsDialogComponent, {
            data: { quoteCreationDate: quoteReport.quoteCreationDate },
            width: '450px',
            height: '300px'
        });

        const selectedDate = await dialog.afterClosed().toPromise() as Date;
        if (!selectedDate) {
            return;
        }

        const date = selectedDate.toISOString();

        const url = this._quoteHttpService.getQuotePdfUrlWithDateQuery(quoteId, date);
        const match = this.gridViewData.find(_ => _.quoteId === quoteId);
        const fileName = GenericUtilityService.getQuotePdfName(quoteId, match.quoteName, match.address, match.destProjId);

        if (!await this._downloadService.download(url, fileName, 'application/pdf')) {
            const message = this._translateService.instant('dataReport.quoteReport.printError');
            this._snackBar.open(message, this._translateService.instant('shared.close'));
        }
    }

    public async printAllQuotes() : Promise<void> {
        this._newRelicUtility.addPageAction('ReportType', 'QuoteReport', 'ReportAccess', {
            command: 'PrintAll'
        });
        const quoteIds = this.gridViewData.map(_ => _.quoteId);
        const success = this._translateService.instant('dataReport.quoteReport.printAllAction.success');
        const error = this._translateService.instant('dataReport.quoteReport.printAllAction.error');
        const message = await this._quoteHttpService.generateCombinedPdf(quoteIds)?
            success :
            error ;
        this._snackBar.open(message, this._translateService.instant('shared.close'));
    }

    public async loadReport(skipAmount: number): Promise<void> {
        this.currentSkip = skipAmount;

        this._newRelicUtility.addPageAction('ReportType', 'QuoteReport', 'ReportAccess', {
            branch: this.branchId,
            status: this.selectedStatuses,
            query: this.query
        });

        const statusList = this.selectedStatuses.length === 0 ?
            this.defaultStatuses.join(';') :
            this.selectedStatuses.join(';');

        this.loading = true;
        const data = await this._quoteHttpService.getFilteredQuotesWithStatus(this.branchId, statusList, this.query);

        this.gridViewData = data.map(_ => {
            const translation = this.filterableStatuses.find(pair => pair.original === _.status);
            const status = translation?.translated ?? _.status;

            return { ..._, status };
        });

        this.dataBinding.skip = this.currentSkip;
        this.loading = false;
    }

    public allData(): ExcelExportData {
        const data = this.gridViewData.map(_ => {
            const branchTimeZone = this.branches.find(b => b.name === _.quoteBranch)?.geoLocation.timeZone;
            const quoteCreationDate = TimeUtility.toBranchLocalDateTime(new Date(_.quoteCreationDate), branchTimeZone, false);
            const lastFollowAttempt = /^0001/.test(_.lastFollowAttempt) ? '' :
                TimeUtility.toBranchLocalDateTime(new Date(_.lastFollowAttempt), branchTimeZone, false);
            return { ..._, quoteCreationDate, lastFollowAttempt };
        });
        const result: ExcelExportData = {
            data: process(data, { }).data
        };

        return result;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    public pageChange(ev: any): void {
        this.currentSkip = ev.skip;
    }

    private async loadQuoteStatuses(language: string): Promise<void> {
        const allStatuses = await this._quoteStatusHttpService.getAllStatuses();
        const filterableStatuses = allStatuses.filter(_ => _.filterFlags.isQuoteReportFilterAllowed);
        const defaultStatuses = filterableStatuses.filter(_ => _.filterFlags.isQuoteReportFilterDefault);
        this.defaultStatuses = defaultStatuses.map(_ => LanguageUtility.getLocalizedContent(LanguageKey.EN, _.names));
        this._allowedStatusesForDelete = await this._quoteHttpService.getAllowedStatusForDelete();

        this.filterableStatuses = filterableStatuses.map(_ => {
            const original = LanguageUtility.getLocalizedContent(LanguageKey.EN, _.names);
            const translated = LanguageUtility.getLocalizedContent(language, _.names);

            return { original, translated } as TranslationPair;
        });
    }

    private loadExcelColumns(): void {
        const salesCodeKey = this.isInternal ? 'dataReport.quoteReport.salesCode' : 'dataReport.quoteReport.projectType';

        this.excelExportColumns = [
            { field: 'quoteId', title: this._translateService.instant('shared.quoteId') },
            { field: 'quoteName', title: this._translateService.instant('shared.quoteName') },
            { field: 'custName', title: this._translateService.instant('dataReport.quoteReport.customerColumn') },
            { field: 'custId', title: this._translateService.instant('shared.customerId') },
            { field: 'custSiteName', title: this._translateService.instant('dataReport.quoteReport.siteColumn') },
            { field: 'siteId', title: this._translateService.instant('dataReport.quoteReport.siteId') },
            { field: 'quoteBranch', title: this._translateService.instant('shared.branch') },
            { field: 'quoteSalesCode', title: this._translateService.instant(salesCodeKey) },
            { field: 'origProjId', title: this._translateService.instant('dataReport.quoteReport.originProject') },
            { field: 'destProjId', title: this._translateService.instant('dataReport.quoteReport.destinationProject') },
            { field: 'quoteTotal', title: this._translateService.instant('shared.quoteTotal') },
            { field: 'quoteCreationDate', title: this._translateService.instant('shared.creationDate') },
            { field: 'estimator', title: this._translateService.instant('dataReport.quoteReport.estimator') },
            { field: 'customerClass', title: this._translateService.instant('dataReport.quoteReport.customerClass') },
            { field: 'status', title: this._translateService.instant('shared.status') },
            { field: 'noFollowUp', title: this._translateService.instant('dataReport.quoteReport.noFollowUp') },
            { field: 'followUpLogsCount', title: this._translateService.instant('dataReport.quoteReport.followUpAttempts') },
            { field: 'lastFollowAttempt', title: this._translateService.instant('dataReport.quoteReport.followUpDate') },
            { field: 'reportingContact.contactName', title: this._translateService.instant('dataReport.quoteReport.contact') },
            { field: 'reportingContact.email', title: this._translateService.instant('dataReport.quoteReport.email') },
            { field: 'reportingContact.contactNumber', title: this._translateService.instant('dataReport.quoteReport.phone') },
            { field: 'followUpLogsList', title: this._translateService.instant('dataReport.quoteReport.followUpLog') }
        ];
    }
}
