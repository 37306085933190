import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges, AfterViewInit, OnInit, ViewEncapsulation } from '@angular/core';
import WorkOrderStepConfig from '../../../../core/data-models/dispatch/work-order-step-config';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { EditorUtilityService } from '../../../../core/services/utility/editor-utility/editor-utility.service';
import { SalesCodeConfigService } from '../../../../core/services/sales-code-config/sales-code-config.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkOrderTemplateHttpService } from '../../../../core/services/http/work-order-template-http/work-order-template-http.service';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import WorkOrderTemplate from '../../../../core/data-models/work-order/work-order-template';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

@Component({
    selector: 'app-work-order-step',
    styleUrls: ['./work-order-step.component.scss'],
    templateUrl: './work-order-step.component.html',
    encapsulation: ViewEncapsulation.None
})
export class WorkOrderStepComponent implements AfterViewInit, OnChanges, OnInit {
    @Input() config: WorkOrderStepConfig;
    @Output() public stepClicked = new EventEmitter<string>();
    public editorId = 'work-order-editor';
    public templates: WorkOrderTemplate[] = [];
    private _useWorkOrderTemplates = false;

    public get hasWorkCheckList(): boolean {
        return this._salesCodeConfigService.requireWorkCheckList(this.config?.jobType);
    }

    get requireWorkOrder(): boolean {
        return this._salesCodeConfigService.isWorkOrderRequired(this.config?.jobType);
    }

    get useWorkOrderTemplates(): boolean {
        return this._useWorkOrderTemplates && Boolean(this.templates.length);
    }

    //eslint-disable-next-line
    public constructor(private _editorUtilityService: EditorUtilityService,
        private _salesCodeConfigService: SalesCodeConfigService,
        private _snackBar: MatSnackBar,
        private _translate: TranslateService,
        private _workOrderTemplateService: WorkOrderTemplateHttpService,
        private _featureFlagService: FeatureFlagService) {
    }

    public async ngOnInit(): Promise<void> {
        this._useWorkOrderTemplates = this._featureFlagService.featureFlags[FeatureFlags.UseWorkOrderTemplates];
        this.templates = await this._workOrderTemplateService.getTemplates();
    }

    public ngAfterViewInit(): void {
        const options = {
            change: () => {
                this.clearWorkCheckItemsFromEditor();
            },
            ...this._editorUtilityService.defaultEditorOptions };

        setTimeout(() => {
            this._editorUtilityService.createEditor(this.editorId, options);
            if (this.config.workOrderString) {
                this._editorUtilityService.getEditor(this.editorId).value(this.config.workOrderString);
                this.addWorkItemMarker(this.config.workCheckList);
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.config &&
            changes.config.currentValue?.jobType &&
            !this._salesCodeConfigService.requireWorkCheckList(changes.config.currentValue.jobType)) {
            this.config.workCheckList = [];
            this.clearWorkCheckItemsFromEditor(true);
        }
    }

    public validateAndMoveNext(): void {
        const validContent = !this.requireWorkOrder || this._editorUtilityService.getContent(this.editorId)?.length > 10;
        const validWorkCheckList = this.hasWorkCheckList && this.config.workCheckList.length > 0 || !this.hasWorkCheckList;
        this.config.completed = validContent && validWorkCheckList;
        if (!validContent) {
            const message = this._translate.instant('shared.workOrderStep.invalidContentMessage');
            this._snackBar.open(message, this._translate.instant('snackbars.close'));
        }
        else if (!validWorkCheckList) {
            const message = this._translate.instant('shared.workOrderStep.invalidCheckListMessage');
            this._snackBar.open(message, this._translate.instant('snackbars.close'));
        }

        this.clearWorkCheckItemsFromEditor();
        setTimeout(() => this.stepClicked.emit('next'));
    }

    public removeWorkItem(index: number): void {
        const editor = this._editorUtilityService.getEditor(this.editorId);
        const removedItem = this.config.workCheckList.splice(index, 1);
        const hashCode = this._editorUtilityService.getHashFromString(removedItem[0]);
        let currentContent = editor.value();
        const workItemSpan = new RegExp(`<span[^>]*class="checkedItem workOrder${hashCode}"[^<]*>✅</span>`);
        currentContent = currentContent.replace(workItemSpan, '');
        editor.value(currentContent);
    }

    public addSelectedText(): void {
        const editor = this._editorUtilityService.getEditor(this.editorId);
        const selection = editor.getSelection();
        const text = this.removeInvalidCharactersFromString(selection.toString());
        const lines = text.split('\n')
            .filter(_ => _.trim());

        if (lines.some(_ => _.trim().length < 10)) {
            const message = this._translate.instant('shared.workOrderStep.tooShortMessage');
            this._snackBar.open(message, this._translate.instant('snackbars.close'));
            return;
        }

        let currentContent = editor.value();
        for (let i =0; i < lines.length; i++) {
            if (!this.config.workCheckList.includes(lines[i])) {
                this.config.workCheckList.push(lines[i]);
                const hashCode = this._editorUtilityService.getHashFromString(lines[i]);
                const regExp = this._editorUtilityService.getRegExpFromString(lines[i]);
                currentContent = currentContent.replace(regExp,
                    `<span contenteditable="false" class="checkedItem workOrder${hashCode}">✅</span>
                    ${lines[i].replace(/\$/g, '$$$$')}`);
            }
        }
        editor.value(currentContent);
    }

    public loadTemplateContent(event: MatSelectChange): void {
        this._editorUtilityService.setContent(this.editorId, event.value.content);
        this.config.workCheckList = [];
    }

    private clearWorkCheckItemsFromEditor(updateEditor = false): void {
        const editor = this._editorUtilityService.getEditor(this.editorId);
        if (!editor) {
            return;
        }

        const workItemSpan = /<span[^>]*class="checkedItem workOrder[^<]*>✅<\/span>/g;
        let currentContent = editor.value();
        currentContent = currentContent.replace(workItemSpan, '');
        this.config.workOrderString = currentContent;
        if (updateEditor) {
            editor.value(currentContent);
        }
    }

    private removeInvalidCharactersFromString(value: string): string {
        return value.replace(/\uFEFF|✅/g, '');
    }

    private addWorkItemMarker(items: string[]): void {
        if (!items?.length) {
            return;
        }
        const editor = this._editorUtilityService.getEditor(this.editorId);
        let currentContent = editor.value();
        for (let i =0; i < items.length; i++) {
            const hashCode = this._editorUtilityService.getHashFromString(items[i]);
            const regExp = this._editorUtilityService.getRegExpFromString(items[i]);
            currentContent = currentContent.replace(regExp,
                `<span contenteditable="false" class="checkedItem workOrder${hashCode}">✅</span>${items[i]}`);
        }
        editor.value(currentContent);
    }
}
