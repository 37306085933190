import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from
    '../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';

import { CrewHttpService } from '../../../core/services/http/crew-http/crew-http.service';
import OnCallEvent from '../../../core/data-models/on-call-events';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'remove-on-call',
    templateUrl: './remove-on-call.component.html',
    styleUrls: ['./remove-on-call.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RemoveOnCallComponent implements OnInit {
    public start: Date;
    public end = new Date();
    public id = '';

    constructor(
        private _crewHttpSerice: CrewHttpService,
        private _dialogService: MatDialog,
        private _dialogRef: MatDialogRef<RemoveOnCallComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OnCallEvent,
        public translate: TranslateService
    ) { }

    public ngOnInit(): void {
        this.start = new Date(this.data.start);
        this.end = new Date(this.data.end);
        this.end.setDate(this.end.getDate() - 1);
        this.id = this.data.extendedProps.eventId;
    }

    public async remove(): Promise<void> {

        const action = this._crewHttpSerice.removeOnCallCrew(this.id);
        const ongoing = this.translate.instant('onCall.remove.ongoing');
        const success = this.translate.instant('onCall.remove.success');
        const error = this.translate.instant('onCall.remove.error');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise()) {
            this._dialogRef.close();
        }
    }
}
