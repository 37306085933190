import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import ProjectAttributesWithUsCheck from '../../../core/data-models/project-attributes/project-attributes-us-check';
import ProjectQuestionValidCheck from '../../../core/data-models/project-questions-valid-check';
import ProjectUSQuestionsValidCheck from '../../../core/data-models/project-us-questions-valid-check';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import EditAttributesDto from '../../../core/data-models/project-attributes/edit-attributes-dto';
import { ProjectQuestionsGridChange } from '../../../core/data-models/project-attributes/project-questions-grid-change';
import ProjectQuestionsGrid from '../../../core/data-models/project-attributes/project-questions-grid';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import {
    PromptDialogComponent
} from '../../../shared/components/dialogs/prompt-dialog-component/prompt-dialog.component';
import PromptDialogOption from '../../../core/data-models/prompt-dialog-option';
import CodeDescription from '../../../core/data-models/code-description';
import { DynamicsHoldStatus } from '../../../core/enums/dynamics-hold-status.enum';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';
import { WorkCategory } from '../../../core/data-models/work-category';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';

@Component({
    selector: 'project-attributes-management',
    styleUrls: ['./project-attributes-management.component.scss'],
    templateUrl: './project-attributes-management.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectAttributesManagementComponent implements OnInit {
    public projectId = '';
    public projectAttributes: ProjectAttributesWithUsCheck;
    public editAttributes: EditAttributesDto;
    public isSaving = false;
    public isDynamicsOnHold = false;
    public isSubcontract = false;
    public formValidStates = [false, false, false];

    get formValid(): boolean {
        return this.formValidStates.every(_ => _);
    }

    get isSaveDisabled(): boolean {
        return this.isSaving || !this.formValid || this.isDynamicsOnHold;
    }

    public constructor(
        private _projectHttpService: ProjectHttpService,
        private _snackbar: MatSnackBar,
        private _dialog: MatDialog,
        private _translate: TranslateService,
        private _salesCodeConfigService: SalesCodeConfigService
    ) { }

    public async ngOnInit(): Promise<void> {
        this.isDynamicsOnHold = await this._projectHttpService.getDynamicsHoldStatus() === DynamicsHoldStatus.Hold;
    }

    public onProjectQuestionsChange(questions: ProjectQuestionValidCheck): void {
        const { mileage, zonePay, roomNBoard } = this.editAttributes.projectQuestions;
        this.editAttributes.projectQuestions = { ...questions.projectQuestions, mileage, zonePay, roomNBoard };
        this.formValidStates[0] = questions.valid;
    }

    public onAttributesChange(attributes: ProjectQuestionValidCheck): void {
        const { mileage, zonePay, roomNBoard } = attributes.projectQuestions;
        this.editAttributes.projectQuestions = { ...this.editAttributes.projectQuestions, mileage, zonePay, roomNBoard };
        this.formValidStates[1] = attributes.valid;
    }

    public onUSProjectQuestionsChange(attributes: ProjectUSQuestionsValidCheck): void {
        this.editAttributes.usProjectQuestions = attributes.projectUSQuestions;
        this.formValidStates[2] = attributes.valid;
    }

    public async search(): Promise<void> {
        const isUSProject = ProjectIdUtility.isUsProject(this.projectId);
        this.isSubcontract = await this._salesCodeConfigService.getWorkCategoryByProjectId(this.projectId) === WorkCategory.Subcontract;
        if (this.isSubcontract && !isUSProject) {
            this._snackbar.open(this._translate.instant('shared.projectAttributesManagement.canadianSubcontractError'), this._translate.instant('shared.ok'));
            return;
        }

        const result = await this._projectHttpService.getProjectAttributesUsCheck(this.projectId);
        if (!result) {
            this._snackbar.open(this._translate.instant('shared.projectAttributesManagement.searchError'), this._translate.instant('shared.ok'));
            return;
        }

        this.editAttributes = {
            projectId: this.projectId,
            isUS: result.isUSProject,
            usProjectQuestions: result.uSEstimateForm,
            usTaxQuestions: result.uSTaxQuestions,
            projectQuestions: result.projectQuestions
        } as EditAttributesDto;

        this.editAttributes.projectQuestions.zonePay.forEach(_ => {
            _.change = ProjectQuestionsGridChange.None;
        });

        this.editAttributes.projectQuestions.roomNBoard.forEach(_ => {
            _.change = ProjectQuestionsGridChange.None;
        });

        this.editAttributes.projectQuestions.mileage.forEach(_ => {
            _.change = ProjectQuestionsGridChange.None;
        });

        this.projectAttributes = result;

        if (!this.editAttributes.isUS) {
            this.formValidStates[2] = true;
        }

        if (this.isSubcontract) {
            this.formValidStates[0] = true;
            this.formValidStates[1] = true;
        }
    }

    // eslint-disable-next-line complexity
    public async save(): Promise<void> {
        const titleText = this._translate.instant('shared.projectAttributesManagement.enterReason');
        const helperText = this._translate.instant('shared.projectAttributesManagement.enterReasonText');

        const dialog = this._dialog.open(PromptDialogComponent, {
            width: '550px',
            height: '450px',
            data: new PromptDialogOption(titleText, helperText)
        });

        const reason = await dialog.afterClosed().toPromise();
        if (!reason) {
            return;
        }

        const okLabel = this._translate.instant('shared.ok');
        try {
            this.isSaving = true;
            const projectId = this.editAttributes.projectId;

            const newMileage = this.editAttributes.projectQuestions.mileage.filter(_ => _.change === ProjectQuestionsGridChange.Created);
            if (newMileage.length) {
                await this.executeAttributeUpsert(newMileage, 'addMileage', projectId, reason);
            }

            const updatedMileage = this.editAttributes.projectQuestions.mileage.filter(_ => _.change === ProjectQuestionsGridChange.Updated);
            if (updatedMileage.length) {
                await this.executeAttributeUpsert(updatedMileage, 'updateMileage', projectId, reason);
            }

            const newRoomNBoard = this.editAttributes.projectQuestions.roomNBoard.filter(_ => _.change === ProjectQuestionsGridChange.Created);
            if (newRoomNBoard.length) {
                await this.executeAttributeUpsert(newRoomNBoard, 'addRoomNBoard', projectId, reason);
            }

            const updatedRoomNBoard = this.editAttributes.projectQuestions.roomNBoard.filter(_ => _.change === ProjectQuestionsGridChange.Updated);
            if (updatedRoomNBoard.length) {
                await this.executeAttributeUpsert(updatedRoomNBoard, 'updateRoomNBoard', projectId, reason);
            }

            const newZonePay = this.editAttributes.projectQuestions.zonePay.filter(_ => _.change === ProjectQuestionsGridChange.Created);
            if (newZonePay.length) {
                await this.executeAttributeUpsert(newZonePay, 'addZonePay', projectId, reason);
            }

            const updatedZonePay = this.editAttributes.projectQuestions.zonePay.filter(_ => _.change === ProjectQuestionsGridChange.Updated);
            if (updatedZonePay.length) {
                await this.executeAttributeUpsert(updatedZonePay, 'updateZonePay', projectId, reason);
            }

            await this.saveIndividualAttribute(this.editAttributes, reason);
        }
        catch (error) {
            const codeDescription = error as CodeDescription;
            this._snackbar.open(codeDescription.description, okLabel);
            return;
        }
        finally {
            this.isSaving = false;
        }

        this._snackbar.open(this._translate.instant('shared.projectAttributesManagement.successfullySavedAllAttributes'), okLabel);
        await this.search();
    }

    public async executeAttributeUpsert(attributes: ProjectQuestionsGrid[], methodName: string, projectId: string, reason: string): Promise<void> {
        for (let i = 0; i < attributes.length; i++) {
            const result: CodeDescription = await this._projectHttpService[methodName](attributes[i], projectId, reason); // eslint-disable-line no-await-in-loop

            if (result.code !== '200') {
                const key = `${methodName[0].toUpperCase()}${methodName.slice(1)}`;
                const error = this._translate.instant(`shared.projectAttributesManagement.failedTo${key}`);
                result.description = result.description ? result.description : error;
                throw result;
            }
        }
    }

    public async saveIndividualAttribute(attributes: EditAttributesDto, reason: string): Promise<void> {
        const result = await this._projectHttpService.saveAttributes(attributes, reason);

        if (result.code !== '200') {
            const error = this._translate.instant('shared.projectAttributesManagement.failedToSaveAttributes');
            result.description = result.description ? result.description : error;
            throw result;
        }
    }
}
