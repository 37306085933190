<div fxLayout="column" class="geofence-report-container" fxLayoutGap="15px" fxFill *ngIf="isLoaded">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" fxFlexOffset="15px">
        <mat-form-field fxFlex="30%">
            <mat-label>{{ 'dataReport.geofenceReport.typeLabel' | translate }}</mat-label>
            <mat-select [(value)]="getGeofenceReportQuery.type" (selectionChange)="resetQuery($event)">
                <mat-option value='all'>{{ 'dataReport.geofenceReport.all' | translate }}</mat-option>
                <mat-option value='Branch'>{{ 'dataReport.geofenceReport.typeBranch' | translate }}</mat-option>
                <mat-option value='Project'>{{ 'dataReport.geofenceReport.typeProject' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="30%">
            <mat-label>{{ 'shared.startDate' | translate}}</mat-label>
            <input matInput [matDatepicker]="start" [(ngModel)]="startDate">
            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
            <mat-datepicker #start></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex="30%">
            <mat-label>{{ 'shared.endDate' | translate}}</mat-label>
            <input matInput [matDatepicker]="end" [(ngModel)]="endDate">
            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
            <mat-datepicker #end></mat-datepicker>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" *ngIf="getGeofenceReportQuery.type === 'Project'">
        <mat-form-field fxFlex="30%">
            <mat-label>{{ 'dataReport.geofenceReport.projectNameIdLabel' | translate }}</mat-label>
            <input type="text" matInput [matAutocomplete]="auto" [(ngModel)]="projectId" (ngModelChange)="filterProjectNamesAndIds()">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let project of filteredProjectNamesAndIds" [value]="project">
                    {{project}}
                </mat-option>
            </mat-autocomplete>

        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
        <ng-container *ngIf="getGeofenceReportQuery.type === 'Branch'">
            <mat-form-field fxFlex="30%">
                <mat-label>{{ 'shared.branch' | translate}}</mat-label>
                <mat-select [(value)]="getGeofenceReportQuery.branch" (selectionChange)="reloadCrews($event)">
                    <mat-option [value]="''">{{ 'dataReport.geofenceReport.all' | translate }}</mat-option>
                    <mat-option *ngFor="let branch of branches" [value]="branch.name">{{branch.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="30%">
                <mat-label>{{ 'dataReport.geofenceReport.workerLabel' | translate }}</mat-label>
                <mat-select [(value)]="getGeofenceReportQuery.employeeId">
                    <mat-option [value]="''">{{'dataReport.geofenceReport.allWorkerLabel' | translate}}</mat-option>
                    <mat-option *ngFor="let crew of crews" [value]="crew.foreman.employeeId">{{crew.crewName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <div fxFlex></div>

        <button class="get-geofence-data-button" mat-raised-button color="primary" (click)="getGeofenceEvents()">
            {{ 'dataReport.geofenceReport.getGeofenceData' | translate }}
        </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" class="report-grid-div" fxFlex="75">
        <kendo-grid [kendoGridBinding]="geofenceEvents" [pageSize]="15" [pageable]="true"
        [groupable]="true" [sortable]="true" [filterable]="true">
             <ng-template kendoGridToolbarTemplate>
                <button kendoGridExcelCommand icon="file-excel">{{ 'shared.exportExcel' | translate }}</button>
            </ng-template>
           <kendo-grid-column field="employeeName" title="{{ 'dataReport.geofenceReport.employee' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="employeeBranch" title="{{ 'dataReport.geofenceReport.employeeBranch' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="type" title="{{ 'dataReport.geofenceReport.type' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="location" title="{{ 'dataReport.geofenceReport.location' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="event" title="{{ 'dataReport.geofenceReport.event' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="localRecordedTime" title="{{ 'dataReport.geofenceReport.date' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span>{{dataItem.localRecordedTime | branchLocalTime: null : []}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="deviceId" title="{{ 'dataReport.geofenceReport.device' | translate }}">
            </kendo-grid-column>
            <kendo-grid-excel fileName="geofence_report.xlsx" [fetchData]="allData" filterable="true"></kendo-grid-excel>
        </kendo-grid>
    </div>
</div>
<div fxLayout="column" fxLayoutAlign="center center"  fxFill *ngIf="!isLoaded">
    <dots-loader></dots-loader>
</div>
