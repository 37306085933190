<div class="service-rates-tasks-options-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh" *ngIf="filteredTaskList$">
    <form class="search-form" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2.5vh">
        <mat-form-field fxFlex="85.5">
            <input type="text" placeholder="Select a Task" matInput [formControl]="taskControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText" (optionSelected)="selectOption($event)">
                <mat-option *ngFor="let option of filteredTaskList$ | async" [value]="option">
                    {{ getOptionText(option) }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <button fxFlex="14" (click)="addSelectedTask()" mat-stroked-button color="primary" [disabled]="!selected || isReadonly">
            {{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.tasks.addTask' | translate }}
        </button>
    </form>

    <form class="task-list-form" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh">
        <div class="task-entry" *ngFor="let control of tasks.controls; let i = index" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2.5vh">
            <mat-form-field fxFlex="23.5">
                <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.tasks.taskCode' | translate }}
                </mat-label>
                <input type="text" matInput [formControl]="control.get('code')" readonly />
            </mat-form-field>

            <mat-form-field fxFlex="38">
                <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.tasks.taskDescription' | translate }}
                </mat-label>
                <input type="text" matInput [formControl]="control.get('description')" readonly />
            </mat-form-field>

            <mat-form-field fxFlex="38">
                <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.tasks.accountCategory' | translate }}
                </mat-label>
                <input type="text" matInput [formControl]="control.get('accountCategory')" readonly />
            </mat-form-field>

            <button [disabled]="isReadonly" mat-icon-button color="warn" (click)="removeTask(i)" type="button" (keydown.enter)="$event.preventDefault()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </form>
</div>
