import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { UntypedFormGroup, UntypedFormArray, AbstractControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyChipList as MatChipList, MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile, startWith } from 'rxjs/operators';

import { Branch } from '../../../../../core/data-models/branch/branch';
import { TypeRecord } from '../../../../../core/data-models/generic/type-record';
import { ConfirmationPromptOptions } from '../../../../../core/data-models/options/confirmation-prompt-options';
import { ServiceRatesHttpService } from '../../../../../core/services/http/service-rates-http/service-rates-http.service';
import { BranchServiceRatesChangesDisplayComponent } from '../../../../../shared/components/displays/branch-service-rates-changes-display/branch-service-rates-changes-display.component';
import { ConfirmationPromptComponent } from '../../../../../shared/components/prompts/confirmation-prompt/confirmation-prompt.component';
import { ServiceRatesEditorService } from '../service-rates-editor.service';

@Component({
    selector: 'app-service-rates-branch-options',
    styleUrls: ['./service-rates-branch-options.component.scss'],
    templateUrl: './service-rates-branch-options.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRatesBranchOptionsComponent implements AfterViewInit, OnDestroy {
    @Input() public form: UntypedFormGroup;
    @Input() public groupId: string;
    @Input() public isReadonly: boolean;
    @Input() public branches: Branch[] = [];
    @ViewChild('projectTypesChipList') private _projectTypesChipList: MatChipList;
    public readonly separatorKeyCodes = [ENTER];
    private _isComponentActive = true;

    constructor(public translate: TranslateService,
                private _serviceRatesEditorService: ServiceRatesEditorService,
                private _serviceRatesHttp: ServiceRatesHttpService,
                private _dialog: MatDialog,
                private _changeDetectorRef: ChangeDetectorRef) { }

    get branchRates(): UntypedFormArray {
        return this.form.controls.branchRates as UntypedFormArray;
    }

    get projectTypes(): UntypedFormArray {
        return this.form.controls.projectTypes as UntypedFormArray;
    }

    public ngAfterViewInit(): void {
        this.validateChipLists();
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
    }

    public getItemTypeDisplayText(group: UntypedFormGroup): string {
        return this._serviceRatesEditorService.getTypeRecordDisplayText(group);
    }

    public addProjectType(event: MatChipInputEvent): void {
        const typeIdentifier = this._serviceRatesEditorService.addTypeRecord(this.projectTypes, event, this._projectTypesChipList);

        if (!typeIdentifier) {
            return;
        }

        for (const control of this.branchRates.controls) {
            const rates = control.get('grossProfitPercentageRates') as UntypedFormArray;
            const newControl = this._serviceRatesEditorService.createGrossProfitPercentageRateField({ typeIdentifier, rate: 0 });
            rates.push(newControl);
        }
    }

    public updateItemType(formArray: UntypedFormArray, index: number, value: string): void {
        this._serviceRatesEditorService.updateTypeRecord(formArray, index, value);
    }

    public removeProjectType(index: number): void {
        const { identifier } = this.projectTypes.at(index).value;
        this.projectTypes.removeAt(index);

        for (const control of this.branchRates.controls) {
            const rates = control.get('grossProfitPercentageRates') as UntypedFormArray;
            const targetIndex = rates.controls.findIndex(ratesControl => ratesControl.value.typeIdentifier === identifier);
            rates.removeAt(targetIndex);
        }
    }

    public async openChangeHistory(branchCode: string): Promise<void> {
        const [width, height] = ['75vw', '85vh'];
        const data = await this._serviceRatesHttp.getBranchServiceRateHistories(this.groupId, branchCode);
        this._dialog.open(BranchServiceRatesChangesDisplayComponent, { data, width, height });
    }

    public toPercentageDisplayValue(control: AbstractControl): void {
        const rawValue = isNaN(control.value) ? 0 : Number(control.value) / 100;
        const displayValue = this._serviceRatesEditorService.getPercentageDisplayValue(rawValue);
        control.setValue(displayValue);
    }

    public getGrossProfitPercentageRates(control: AbstractControl): UntypedFormArray {
        return control.get('grossProfitPercentageRates') as UntypedFormArray;
    }

    public getProjectType(control: AbstractControl): string {
        const identifier = control.value.typeIdentifier;
        const types: TypeRecord[] = this.projectTypes.value;

        return types.find(type => type.identifier === identifier).name;
    }

    public addBranchRateRow(index: number | null = null): void {
        const types: TypeRecord[] = this.projectTypes.value;
        const control = this._serviceRatesEditorService.createBranchRateField(null, types);

        if (index === null || index < 0 || index >= this.branchRates.length) {
            this.branchRates.push(control);
            return;
        }

        this.branchRates.insert(index, control);
    }

    public copyBranchRate(index: number): void {
        this.addBranchRateRow(index + 1);
        const source = this.branchRates.controls[index];
        const target = this.branchRates.controls[index + 1];
        target.patchValue({ ...source.value, branchCode: null });
    }

    public async deleteBranchRate(index: number): Promise<void> {
        const source = this.branchRates.controls[index] as UntypedFormGroup;
        if (source.pristine && !source.get('branchCode').value) {
            this.branchRates.removeAt(index);
            return;
        }

        const branchName: string = this.branches.find(branch => branch.code === source.get('branchCode').value)?.name;

        const message = branchName ?
            this.translate.instant('flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.confirmDeleteText', { branchName }) :
            this.translate.instant('flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.confirmDeleteTextNoBranch');

        const title = this.translate.instant('flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.confirmDeleteTitle');
        const confirmText = this.translate.instant('flynnBudgetTool.shared.proceed');
        const cancelText = this.translate.instant('flynnBudgetTool.shared.cancel');
        const data = new ConfirmationPromptOptions(title, message, confirmText, cancelText, true);
        const dialog = this._dialog.open(ConfirmationPromptComponent, { data });

        if (await dialog.afterClosed().toPromise()) {
            this.branchRates.removeAt(index);
            this._changeDetectorRef.markForCheck();
        }
    }

    private validateChipLists(): void {
        // mat chip list does not set the error state for reactive form field unless we do it manually
        this.projectTypes.valueChanges.pipe(startWith(null), takeWhile(() => this._isComponentActive)).subscribe(() => {
            this._projectTypesChipList.errorState = this.projectTypes.invalid;
        });
    }
}
