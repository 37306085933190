import { Component, Input, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import HealthScreeningOption from '../../../../../../../core/data-models/health-screening-option';
import DispatchExistingOptions from '../../../../../../../core/data-models/project-general-info/dispatch-existing-options';
import { ValidatorFactory } from '../../../../../../../core/services/validator-factory/validator-factory.service';
import HandoffMeetingUtilityService from '../../services/handoff-meeting-utility.service';
import JobRedispatchData from '../../../../../../../core/data-models/re-dispatch/job-redispatch-data';
import Crew from '../../../../../../../core/data-models/crew';
import RedispatchProjectStepConfig from '../../../../../../../core/data-models/dispatch/redispatch-project-step-config';
import ProjectIdUtility from '../../../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import { CountryName } from '../../../../../../../core/enums/country-name.enum';
import { FeatureFlagService } from '../../../../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../../../../core/enums/feature-flags.enum';

@Component({
    selector: 'redispatch-project',
    templateUrl: './redispatch-project.component.html',
    styleUrls: ['./redispatch-project.component.scss'],
    providers: [
        {
            provide: POPUP_CONTAINER,
            useFactory: () => {
                return { nativeElement: document.querySelector('.redispatch-date-time-picker') } as ElementRef;
            }
        },
        PopupService
    ]
})
export class RedispatchProjectComponent implements OnInit {
    @Input() public dispatchOptions: DispatchExistingOptions;
    @Input() public handOffMeetingApprovedCrew: Crew;
    @Input() public isDispatch = false;
    @Input() public config: RedispatchProjectStepConfig;
    @Output() public selectedCrewChanged = new EventEmitter<Crew>();
    @Output() public nextStepClicked = new EventEmitter<string>();
    public healthScreeningOption: HealthScreeningOption;
    public redispatchFormGroup: UntypedFormGroup;
    public country: CountryName;
    public integerValidator = this._validatorFactory.createPositiveIntegerValidator();

    constructor(
        private _validatorFactory: ValidatorFactory,
        private _snackBar: MatSnackBar,
        private _formBuilder: UntypedFormBuilder,
        private _translate: TranslateService,
        private _featureFlagService: FeatureFlagService
    ) { }

    get branchCodeRestriction(): string {
        return this.isInternal ? '' : this.dispatchOptions.projectId.slice(0, 3);
    }

    get isRequiredFieldProvided(): boolean {
        if (this.redispatchFormGroup.get('crew').invalid) {
            return false;
        }

        return this.isInternal || this.redispatchFormGroup.get('estimatedTimeOfArrival').valid;
    }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    public ngOnInit(): void {
        this.loadFormGroup();
        const { projectId, customerId, siteId } = this.dispatchOptions;
        this.country = ProjectIdUtility.isUsProject(projectId) ? CountryName.US : CountryName.CA;

        if (customerId && siteId) {
            this.healthScreeningOption = new HealthScreeningOption(customerId, siteId);
        }
    }

    public redispatch(): void {
        if (this.redispatchFormGroup.valid) {
            this.nextStepClicked.emit('next');
        }
        else {
            const helpText = this._translate.instant('projects.redispatch.formFieldHelpText');
            const ok = this._translate.instant('snackbars.ok');
            this._snackBar.open(helpText, ok);
        }
    }

    public handOffMeetingRequired(): boolean {
        const currentCrew = this.redispatchFormGroup.get('crew').value;
        const approvedCrew = this.handOffMeetingApprovedCrew;
        const { isHandOffMeetingRequired } = this.dispatchOptions;

        return HandoffMeetingUtilityService.handOffMeetingRequired(isHandOffMeetingRequired, approvedCrew, currentCrew);
    }

    public crewSelected(crew: Crew): void {
        this.redispatchFormGroup.get('crew').setValue(crew);
        this.selectedCrewChanged.emit(crew);
    }

    private loadFormGroup(): void {
        this.redispatchFormGroup = this._formBuilder.group({
            crew: [null, Validators.required],
            estimatedHours: [
                this.config?.data?.estimatedHours,
                this.isInternal ? [Validators.required, this.integerValidator.validator] : []
            ],
            notes: [this.config?.data?.notes]
        });

        if (!this.isInternal) {
            this.redispatchFormGroup.addControl('estimatedTimeOfArrival', new UntypedFormControl(null, Validators.required));
        }

        this.redispatchFormGroup.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(redispatch => {
            this.config.data = redispatch as JobRedispatchData;
            this.config.completed = this.redispatchFormGroup.valid;
        });
    }

}
