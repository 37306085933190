export const environment = {
    auth0ClientID: 'dTNrivZpsGLTzuxAUw0UhxPfsTFc3fio',
    auth0Domain: 'flynn.auth0.com',
    auth0ApiIdentifier: 'https://flynn.auth0.com/api/v2/',
    auth0DataBase: 'ServiceAppNonProd',
    serviceDashboardUrl: 'https://dashboard.flynnservicenonprod.com/',
    featureFlagKey: '5f9ac5905ba9050865b462ae',
    serviceMapUrl: 'https://map.flynnservice.com/',
    isDebugMode: true,
    isWalkMeProduction: false,
    isInternalBuild: false,
    websiteTitle: 'Flynn Service App',
    loginColor: 'rgb(12, 83, 52)',
    fswUrl: '',
    cloudfrontUrl: '',
    fswApiUrl: '',
    signalrUrl: '',
    loginLogo: '',
    toolbarColor: ''
};
