<div class="container" fxLayout="column" fxLayoutGap="0px">
    <div *ngIf="isLoading" class="overlay">
        <dots-loader></dots-loader>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="0px" class="weather-stats">
        <div class="region-date">
            <div class="region-name">{{branchInfo ? branchInfo?.name : 'NA'}}</div>
            <div class="current-date">{{getCurrentDateTime(branchInfo?.geoLocation?.timeZone?.timeZoneId)}}</div>
        </div>
        <div *ngFor="let stat of weatherStats; let i = index"
            [ngClass]="selectedIndex.index === i ? 'selected' : 'stat'"
            (click)="toggleWeatherData(stat.label, stat.type, i)" fxLayout="row" fxLayoutAlign="start center">
            <span [ngClass]="selectedIndex.index === i ? 'weather-icon': 'weather-icon fade-context'">
                <img *ngIf="stat.label === 'Temperature'"
                    src="../../../../assets/icons/temperature.svg">
                <img *ngIf="stat.label === 'Wind Gust'"
                    src="../../../../assets/icons/windgust.svg">
                <img *ngIf="stat.label === 'Precipitation'"
                    src="../../../../assets/icons/precipitation.svg">
                <img *ngIf="stat.label === 'Humidity'"
                    src="../../../../assets/icons/humidity.svg">
                <img *ngIf="stat.label === 'Visibility'"
                    src="../../../../assets/icons/visibility.svg">
                <img *ngIf="stat.label === 'Wind Speed'"
                    src="../../../../assets/icons/windspeed.svg">
                <img *ngIf="stat.label ==='Wind Direction'"
                    src="../../../../assets/icons/winddirection.svg">
                <img *ngIf="stat.label === 'Cloud Cover'"
                    src="../../../../assets/icons/cloudcover.svg">
            </span>
            <div fxFlex="nogrow" class="weather-info">
                <div [ngClass]="selectedIndex.index === i ? 'weather-label': 'weather-label fade-context'">{{ stat.label }}
                </div>
                <div [ngClass]="selectedIndex.index === i ? 'weather-value': 'weather-value fade-context'">{{ stat.value }}
                </div>
                <div class="weather-underline" *ngIf="selectedIndex.index === i"></div>
            </div>
        </div>
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="chart-container">
        <canvas #weatherChart></canvas>
    </div>
</div>