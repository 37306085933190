import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../../shared/shared.module';
import { FeaturesModule as FlynnBudgetToolFeaturesModule } from '../../../flynn-budget-tool/features/features.module';

import { states } from './admin.routing';
import { AdminComponent } from './admin.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { BudgetTemplateManagementComponent } from './budget-template-management/budget-template-management.component';
import { BudgetTemplateUploadDialogComponent } from './budget-template-management/budget-template-upload-dialog/budget-template-upload-dialog.component';
import { BudgetTemplateVersionsListComponent } from './budget-template-management/budget-template-versions-list/budget-template-versions-list.component';
import { MergeRuleListViewerComponent } from './project-merge-rules/merge-rule-list-viewer/merge-rule-list-viewer.component';
import { MergeRuleMappingCardComponent } from './project-merge-rules/merge-rule-editor/merge-rule-mapping-card/merge-rule-mapping-card.component';
import { MergeRuleEditorComponent } from './project-merge-rules/merge-rule-editor/merge-rule-editor.component';
import { ProjectMergeRulesComponent } from './project-merge-rules/project-merge-rules.component';
import { TaskCodeSettingsComponent } from './task-code-settings/task-code-settings.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';
import { DataMigrationComponent } from './data-migration-management/data-migration.component';
import { ParameterSelectorComponent } from './role-permission/role-editor/parameter-selector/parameter-selector.component';
import { PermissionEditorComponent } from './role-permission/role-editor/permission-editor/permission-editor.component';
import { RoleListViewerComponent } from './role-permission/role-list-viewer/role-list-viewer.component';
import { RoleEditorComponent } from './role-permission/role-editor/role-editor.component';
import { RolePermissionComponent } from './role-permission/role-permission.component';
import { BranchManagementComponent } from './branch-management/branch-management.component';
import { NewBranchDialogComponent } from './branch-management/new-branch-dialog/new-branch-dialog.component';
import { KpiDataEditorComponent } from './kpi-management/kpi-data-editor/kpi-data-editor.component';
import { LeakReportTemplateManagementComponent } from './leak-report-template-management/leak-report-template-management.component';
import { LeakReportTemplateEditorComponent } from './leak-report-template-management/leak-report-template-editor/leak-report-template-editor.component';
import { HolidayEditDialogComponent } from './kpi-management/holiday-edit-dialog/holiday-edit-dialog.component';
import { HolidayCalendarComponent } from './kpi-management/holiday-calendar/holiday-calendar.component';
import { KpiManagementComponent } from './kpi-management/kpi-management.component';
import { GeofenceReportingSettingsComponent } from './geofence-reporting-settings/geofence-reporting-settings.component';
import { InvoiceManagementComponent } from './invoice-management/invoice-management.component';
import { PhotoReportDisclaimerComponent } from './disclaimer-settings/photo-report-disclaimer/photo-report-disclaimer.component';
import { ConditionReportDisclaimerComponent } from './disclaimer-settings/condition-report-disclaimer/condition-report-disclaimer.component';
import { DisclaimerSettingsComponent } from './disclaimer-settings/disclaimer-settings.component';
import { QuoteTemplateEditorComponent } from './quote-template-management/quote-template-editor/quote-template-editor.component';
import { QuoteTemplateManagementComponent } from './quote-template-management/quote-template-management.component';
import { MaterialChemicalsManagementComponent } from './material-chemicals/material-chemicals-management/material-chemical-management.compontent';
import { JobMaterialSettingsComponent } from './material-chemicals/job-material-settings/job-material-settings.component';
import { JobMaterialEditComponent } from './material-chemicals/job-material-edit/job-material-edit.component';
import { ChemicalsSettingsComponent } from './material-chemicals/chemicals-settings/chemicals-settings.component';
import { ChemicalEditComponent } from './material-chemicals/chemical-edit/chemical-edit.component';
import { ProjectAttributesManagementComponent } from './project-attributes-management/project-attributes-management.component';
import { QuoteDisclaimerComponent } from './disclaimer-settings/quote-disclaimer/quote-disclaimer.component';
import { WorkOrderTemplateComponent } from './work-order-template/work-order-template.component';
import { ServiceRatesManagementComponent } from './service-rates-management/service-rates-management.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states }),
        SharedModule,
        FlynnBudgetToolFeaturesModule,
        CommonModule
    ],
    declarations: [
        AdminComponent,
        GeneralSettingsComponent,
        BudgetTemplateManagementComponent,
        BudgetTemplateUploadDialogComponent,
        BudgetTemplateVersionsListComponent,
        MergeRuleListViewerComponent,
        MergeRuleMappingCardComponent,
        MergeRuleEditorComponent,
        ProjectMergeRulesComponent,
        TaskCodeSettingsComponent,
        CustomerSettingsComponent,
        DataMigrationComponent,
        ParameterSelectorComponent,
        PermissionEditorComponent,
        RoleListViewerComponent,
        RoleEditorComponent,
        RolePermissionComponent,
        BranchManagementComponent,
        NewBranchDialogComponent,
        KpiDataEditorComponent,
        LeakReportTemplateManagementComponent,
        LeakReportTemplateEditorComponent,
        HolidayEditDialogComponent,
        HolidayCalendarComponent,
        KpiManagementComponent,
        GeofenceReportingSettingsComponent,
        ProjectAttributesManagementComponent,
        PhotoReportDisclaimerComponent,
        ConditionReportDisclaimerComponent,
        QuoteDisclaimerComponent,
        DisclaimerSettingsComponent,
        QuoteTemplateManagementComponent,
        QuoteTemplateEditorComponent,
        MaterialChemicalsManagementComponent,
        JobMaterialSettingsComponent,
        JobMaterialEditComponent,
        ChemicalsSettingsComponent,
        ChemicalEditComponent,
        InvoiceManagementComponent,
        WorkOrderTemplateComponent,
        ServiceRatesManagementComponent
    ]
})
export class AdminModule { }
