/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import CustomEditorOption from '../../../data-models/custom-editor-option';

declare const $: any;

@Injectable({
    providedIn: 'root'
})
export class EditorUtilityService {

    get basicTools(): string[] {
        return [
            'fontName', 'fontSize', 'bold', 'italic', 'underline', 'strikethrough',
            'insertUnorderedList', 'insertOrderedList', 'indent', 'outdent',
            'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'
        ];
    }

    get defaultEditorOptions(): { [key: string]: any } {
        return {
            pasteCleanup: {
                all: false,
                css: false,
                keepNewLines: false,
                msAllFormatting: false,
                msConvertLists: true,
                msTags: true,
                none: false,
                span: false,
                custom(html) {
                    return html.replace(/<img[^>]*>/, '');
                }
            },
            tools: [
                {
                    name: 'undo-button',
                    tooltip: 'Undo',
                    exec() {
                        const editor = $(this).data('kendoEditor');
                        editor.exec('undo');
                    }
                },
                {
                    name: 'redo-button',
                    tooltip: 'Redo',
                    exec() {
                        const editor = $(this).data('kendoEditor');
                        editor.exec('redo');
                    }
                },
                ...this.basicTools,
                {
                    name: 'page-break-button',
                    tooltip: 'Insert Page Break',
                    exec() {
                        const editor = $(this).data('kendoEditor');
                        editor.exec('inserthtml', { value: '<p class="page-break-class" style="page-break-before: always"></p>' });
                    }
                },
                'createTable', 'createLink'
            ]
        };
    }

    public setupEditor(editorId: string, builtinOptions: { [key: string]: any }, customOptions = new CustomEditorOption()): void {
        const { content, font, fontSize, focusOnLoad } = customOptions;
        this.createEditor(editorId, { ...this.defaultEditorOptions, ...builtinOptions });
        this.setFonts(editorId, font, fontSize);
        this.getBody(editorId).attr('data-gramm', false);

        if (content) {
            this.setContent(editorId, content);
        }

        if (focusOnLoad) {
            setTimeout(() => this.focusToEnd(editorId));
        }
    }

    public createEditor(editorId: string, options: { [key: string]: any }): void {
        $(this.getSelector(editorId)).kendoEditor(options);
    }

    public getEditor(editorId: string): any {
        return $(this.getSelector(editorId)).getKendoEditor();
    }

    public setFonts(editorId: string, family = 'Arial', size = 12): void {
        const body = this.getBody(editorId);
        body.css('font-family', family);
        body.css('font-size', `${size}pt`);
    }

    public getContent(editorId: string): string {
        return this.getEditor(editorId).value();
    }

    public getContentText(editorId: string): string {
        const content = this.getContent(editorId);

        try {
            // append new line feed to </p>, </li> and <br /> tags
            return $(content.replace(/(<\/p>)|(<\/li>)|(<br*\s\/?>)/g, '$1\n')).text();
        }
        catch {
            return content;
        }
    }

    public setContent(editorId: string, content: string): void {
        this.getEditor(editorId).value(content);
    }

    public focusToEnd(editorId: string, elementId = ''): void {
        const editor = this.getEditor(editorId);
        const range = editor.getRange() || editor.createRange();
        const node = elementId ? $(editor.body).find(`#${elementId}`)[0] : editor.body;
        range.selectNodeContents(node);
        range.collapse(false);
        editor.selectRange(range);
    }

    public getCharacterCount(editorId: string): number {
        return this.getText(editorId).length;
    }

    public isEmptyOrWhiteSpace(editorId: string): boolean {
        return !this.getText(editorId).trim();
    }

    public getHashFromString(str: string): number {
        let hash = 0;
        if (str.length === 0) {
            return hash;
        }
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash<<5)-hash + char;
            hash = hash & hash;
        }

        return hash;
    }

    public getRegExpFromString(value: string): RegExp {
        const regexForTags = '(</?[^<>]*>)*';
        //eslint-disable-next-line
        const regexString = value.split('').map(x => {
            if (x === ' ' || x.charCodeAt(0) === 160) {
                return '(&nbsp;|\\s)';
            }
            else if (x === '$') {
                return '\\$';
            }
            else if (x === '^' ||
                x === '*' ||
                x === '+' ||
                x === '?' ||
                x === '.' ||
                x === '(' ||
                x === ')' ||
                x === '[' ||
                x === ']' ||
                x === '{' ||
                x === '}' ||
                x === '|' ||
                x === '\\') {
                return `\\${x}`;
            }
            return x;
        }).join(regexForTags);
        return new RegExp(regexString);
    }

    public removeElement(editorId: string, elementId: string): void {
        const editor = this.getEditor(editorId);
        const editorBody = $(editor.body);
        editorBody.find(`#${elementId}`)[0]?.remove();

        editor.value(editorBody.innerHTML);
    }

    public replaceElementContent(editorId: string, elementId: string, newContent: string): void {
        const editor = this.getEditor(editorId);
        const editorBody = $(editor.body);
        const element = editorBody.find(`#${elementId}`)[0];

        if (element) {
            element.innerHTML = newContent;
            editor.value(editorBody.innerHTML);
        }
    }

    public getElementsFromBodyBySelector(editorId: string, selector: string): any[] {
        const editorBody = this.getBody(editorId);
        return editorBody.find(selector).toArray();
    }

    private getBody(editorId: string): any {
        const editor = this.getEditor(this.getSelector(editorId));

        return $(editor.body);
    }

    private getText(editorId: string): string {
        return this.getBody(editorId).text() ?? '';
    }

    private getSelector(id: string): string {
        return /^#/.test(id) ? id : `#${id}`;
    }
}
