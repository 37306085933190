<dialog-action-card class="unmerge-project-dialog-container" [icon]="'call_split'" [title]="translate.instant('projects.unmerge.title')"
    [isLoaded]="true" (close)="onClose()">

    <div *ngIf="projectDetails" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="7px" fxFlex>
        <project-general-info-panel [generalInfo]="generalInfo"
            [isReadonly]="true"
            [hasAnimation]="false">
        </project-general-info-panel>

        <div class="unmerge-project-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button color="primary" (click)="back()">{{ 'projects.unmerge.back' | translate }}</button>
        </div>
    </div>

    <div *ngIf="!projectDetails" class="cards-content" fxLayout="column" fxLayoutAlign="start center" fxFill>
        <div class="cards-container" fxLayout="row wrap" fxLayoutGap="20px">
            <mat-card class="unmerge-cards flex-item" [style.background-color]="getColor(project.projectId)"
                *ngFor="let project of data.mergedProjects" (click)="selectProject(project.projectId)" fxLayout="column"
                fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="40">

                <div class="project-id" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="checkbox-icon" *ngIf="selectedProject === project.projectId">check_box</mat-icon>

                    <button mat-button color="primary" (click)="showProject(project.projectId)" fxFlex>
                        {{ project.projectId }}
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                </div>

                <div class="address">{{ project.address}}</div>

                <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="10px">
                    <mat-card class="time-card" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                        fxFlex="48">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <mat-icon>access_time</mat-icon>
                            <div class="time-card-title">{{ 'projects.unmerge.projectCreation' | translate }}</div>
                        </div>

                        <div class="time-card-date">{{ project.creationDate }}</div>
                        <div class="time-card-time">{{ project.creationTime }}</div>
                    </mat-card>

                    <mat-card class="time-card" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                        fxFlex="48">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <mat-icon>access_time</mat-icon>
                            <div class="time-card-title">{{ 'projects.unmerge.readyForReview' | translate }}</div>
                        </div>

                        <div class="time-card-date">{{project.readyForReviewDate}}</div>
                        <div class="time-card-time">{{project.readyForReviewTime}}</div>
                    </mat-card>
                </div>
            </mat-card>
        </div>

        <div class="unmerge-project-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button color="primary" [disabled]="!selectedProject || isUnmerging" (click)="unmerge()">
                {{ 'projects.unmerge.unmerge' | translate }}
            </button>
        </div>
    </div>
</dialog-action-card>
