<div class="project-covid-screening-report-container" fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-form-field *ngIf="isBranchFilterVisible">
            <mat-label>{{ 'dataReport.covidReport.branchField.label' | translate }}</mat-label>

            <mat-select [(ngModel)]="query.branchName">
                <mat-option value="">{{ 'dataReport.covidReport.branchField.optionAll' | translate }}</mat-option>
                <mat-option *ngFor="let branch of branches" [value]="branch.name">{{ branch.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'dataReport.covidReport.customerIdField.label' | translate }}</mat-label>

            <input type="text"
                matInput
                [(ngModel)]="query.customerId"
                [placeholder]="translate.instant('dataReport.covidReport.customerIdField.placeholder')" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'dataReport.covidReport.projectIdField.label' | translate }}</mat-label>

            <input type="text"
                matInput
                [(ngModel)]="query.projectId"
                [placeholder]="translate.instant('dataReport.covidReport.projectIdField.placeholder')" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'shared.startDate' | translate }}</mat-label>

            <input type="text"
                matInput
                readonly
                [matDatepicker]="startDate"
                [(ngModel)]="query.timeInterval.startTime"
                (click)="startDate.open()" />

            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'shared.endDate' | translate }}</mat-label>

            <input type="text"
                matInput
                readonly
                [matDatepicker]="endDate"
                [(ngModel)]="query.timeInterval.endTime"
                (click)="endDate.open()" />

            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="loadScreeningResult()">
            {{ 'dataReport.covidReport.loadReport' | translate }}
        </button>

        <mat-checkbox class="filter-toggle" [(ngModel)]="query.onlyShowResultsContainYes">
            {{ 'dataReport.covidReport.resultsContainYesToggle' | translate }}
        </mat-checkbox>
    </div>

    <div class="report-grid-div" fxLayout="row">
        <kendo-grid [kendoGridBinding]="data"
            [pageSize]="pageSize"
            [pageable]="true"
            [loading]="isLoading"
            [isDetailExpanded]="isRowExpanded"
            [style.height.%]="100"
            (excelExport)="onExcelExport($event)">

            <ng-template kendoGridToolbarTemplate>
                <button kendoGridExcelCommand [disabled]="!data.length">{{ 'shared.exportExcel' | translate }}</button>
            </ng-template>

            <kendo-grid-column field="projectId" [title]="translate.instant('dataReport.covidReport.projectIdHeader')"></kendo-grid-column>
            <kendo-grid-column field="branchName" [title]="translate.instant('dataReport.covidReport.branchNameHeader')"></kendo-grid-column>
            <kendo-grid-column field="techName" [title]="translate.instant('dataReport.covidReport.techNameHeader')"></kendo-grid-column>

            <kendo-grid-column field="completion" [title]="translate.instant('dataReport.covidReport.completionHeader')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.completion | branchLocalTime: user.branch : branches : false }}
                </ng-template>
            </kendo-grid-column>

            <ng-template kendoGridDetailTemplate let-dataItem>
                <div *ngFor="let result of dataItem.results; let i = index">
                    <span class="answer-body" [ngClass]="{ 'warning-answer': result.answer?.toLowerCase() === 'yes' }">
                        {{ i + 1 }}. {{ result.question }} - {{ result.answer }}
                    </span>
                </div>
            </ng-template>

            <kendo-grid-excel [fetchData]="getExcelData"
                fileName="covid_screening_report.xlsx"
                filterable="true">
            </kendo-grid-excel>
        </kendo-grid>
    </div>
</div>
