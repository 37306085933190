import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import ForemanKpiDataDto from '../../../data-models/kpi-data/foreman-kpi-data-dto';
import KpiData from '../../../data-models/kpi-data/kpi-data';
import ProjectKpiDataDto from '../../../data-models/kpi-data/project-kpi-data-dto';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class KpiSettingService {
    public readonly _api = `${environment.fswApiUrl}api2/service/kpisetting/admin`;

    constructor(private _http: HttpClient) { }

    public async getKpiDataFromPastMonths(months: number): Promise<KpiData[]> {
        try {
            const endpoint = `${this._api}/kpidata/past?months=${months}`;
            const data = await this._http.get<KpiData[]>(endpoint).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<KpiData>();
        }
    }

    public async getMissingForeman(projectId: string): Promise<ForemanKpiDataDto[]> {
        try {
            const endpoint = `${this._api}/kpidata/missingforeman/${projectId}`;
            const data = await this._http.get<ForemanKpiDataDto[]>(endpoint).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<ForemanKpiDataDto>();
        }
    }

    public async updateKpiData(data: ProjectKpiDataDto): Promise<boolean> {
        try {
            const endpoint = `${this._api}/update/kpidata`;
            return await this._http.post<boolean>(endpoint, data).toPromise();
        }
        catch {
            return false;
        }
    }

}
