<dialog-action-card
    [icon]="'assignment_turned_in'"
    [title]="data.label === 'Dispatcher Note'  ? translate.instant('shared.dispatcherNoteEditDialog.dispatcherNoteTitle') : translate.instant('shared.workEditDialog.title')"
    [isLoaded]="true"
    (close)="onClose(false)">
    <div fxFill fxLayout="column">
        <app-work-order-step
            [config]="data"
            (stepClicked)="onStepClicked($event)">
        </app-work-order-step>
    </div>
</dialog-action-card>
