import ConfirmDialogOption from './confirm-dialog-option';

export default class PromptDialogOption extends ConfirmDialogOption {
    public placeholder = '';
    public errorText = '';
    // eslint-disable-next-line
    constructor(
        title: string,
        message: string,
        isWarning = false,
        confirmText = 'Ok',
        placeholder = '',
        errorText = ''
    ) {
        super(title, message, isWarning, confirmText);
        this.placeholder = placeholder;
        this.errorText = errorText;
    }
}
