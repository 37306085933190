<mat-card class="app-video-card" fxLayout="column" fxFill [style.background-color]="'var(--secondary-colors-lightest)'">
    <div *ngIf="video.videoOrderLabel > 0" class="pic-order-overlay">
        {{video.videoOrderLabel}}
    </div>
    <div class="video-card-image-container">
        <a href="{{ video.videoUri }}" target="_blank">
        <img src="../../../../../assets/images/defaultVideo.png" alt="video" width="200" height="200" class="video-card-image"
        fxFlexAlign="center">
        </a>
    </div>

    <mat-card-content fxLayout="row"  class="video-card-image-content" fxFlex>
        <div fxLayout="column">
            <label>
                {{ video.title }}
            </label>
        </div>
    </mat-card-content>
</mat-card>
