<div class="grid-dropdown-container" fxLayout="column" fxLayoutAlign="start">
    <div class="grid-dropdown-title-bar" fxLayout="row" fxLayoutAlign="start center">
        <span class="grid-dropdown-item-title">{{ title }}</span>
    </div>

    <div class="grid-dropdown-item-content" fxLayout="row" fxLayoutAlign="start center">

        <select class="grid-dropdown-items" [disabled]="disabled" [value]="selected" *ngIf="options" (change)="onChange($event.target.value)">
            <option *ngFor="let option of options" [value]="option.key">{{ option.value }}</option>
        </select>
    </div>
</div>
