<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" class="data-report-main-content">
    <nav mat-tab-nav-bar>
        <a mat-tab-link *ngFor="let tab of navigationTabs"
           [uiSref]="tab.state"
           uiSrefActive="active"
           [active]="tabRef.classList.contains('active')"
           #tabRef> {{tab.displayName}} </a>
    </nav>
    <mat-card fxLayout="row" class="data-report-card-content">
        <ui-view>
        </ui-view>
    </mat-card>
</div>

