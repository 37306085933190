<div fxLayout="column" id="new-branch-container" fxFill *ngIf="isReady">
    <form fxFlex="80%" id="new-branch-form" [formGroup]="formGroup" *ngIf="formGroup">
        <div fxLayout="row">
            <mat-form-field [fxFlex]="!isInternal ? '98%' : '70%'">
                <mat-label *ngIf="isInternal">{{ 'admin.branchManagement.addBranchDialog.officeNameLabel' | translate }}</mat-label>
                <mat-label *ngIf="!isInternal">{{ 'admin.branchManagement.addBranchDialog.serviceProviderNameLabel' | translate }}</mat-label>
                <input matInput name="officeName" formControlName="name" [readonly]="data" />
                <mat-error>* {{ 'admin.branchManagement.addBranchDialog.requiredField' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="28%" *ngIf="isInternal">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.branchCodeLabel' | translate }}</mat-label>
                <input matInput name="code" formControlName="code" [readonly]="data" />
                <mat-error>* {{ 'admin.branchManagement.addBranchDialog.requiredField' | translate }}</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" formGroupName="address">
            <mat-form-field fxFlex="49%">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.addressLine1Label' | translate }}</mat-label>
                <input matInput name="addr1" formControlName="addr1" />
                <mat-error>* {{ 'admin.branchManagement.addBranchDialog.requiredField' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="49%">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.addressLine2Label' | translate }}</mat-label>
                <input matInput name="addr2" formControlName="addr2"/>
            </mat-form-field>
        </div>

        <div fxLayout="row" formGroupName="address">
            <mat-form-field fxFlex="49%">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.cityLabel' | translate }}</mat-label>
                <input matInput name="city" formControlName="city" />
                <mat-error>* {{ 'admin.branchManagement.addBranchDialog.requiredField' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="49%">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.provinceLabel' | translate }}</mat-label>
                <mat-select formControlName="state">
                    <mat-option *ngFor="let state of stateList" [value]="state">
                        {{state}}
                    </mat-option>
                </mat-select>
                <mat-error>* {{ 'admin.branchManagement.addBranchDialog.requiredField' | translate }}</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" formGroupName="address">
            <mat-form-field fxFlex="49%">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.postalCodeLabel' | translate }}</mat-label>
                <input matInput name="zip" formControlName="zip" />
                <mat-error>* {{ 'admin.branchManagement.addBranchDialog.requiredField' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="49%">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.countryLabel' | translate }}</mat-label>
                <mat-select name="country" formControlName="country">
                    <mat-option *ngFor="let country of countries" [value]="country.abbrev">
                        {{ country.fullName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex="49%">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.phoneLabel' | translate }}</mat-label>
                <input matInput name="phone" formControlName="phone" appPhoneMask />
                <mat-error>* {{ 'admin.branchManagement.addBranchDialog.requiredField' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="49%">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.faxLabel' | translate }}</mat-label>
                <input matInput name="fax" formControlName="fax" [isFax]="true" appPhoneMask/>
                <mat-error>* {{ 'admin.branchManagement.addBranchDialog.invalidFax' | translate }}</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" *ngIf="!isInternal">
            <mat-form-field fxFlex="49%">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.emailLabel' | translate }}</mat-label>
                <input matInput name="email" formControlName="email" />
                <mat-error>* {{ 'admin.branchManagement.addBranchDialog.requiredField' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="49%">
                <mat-label>{{ 'admin.branchManagement.addBranchDialog.websiteLabel' | translate }}</mat-label>
                <input matInput name="website" formControlName="website" />
                <mat-error>* {{ 'admin.branchManagement.addBranchDialog.invalidWebsite' | translate }}</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row">
                <mat-checkbox formControlName="useDaylightSaving" fxFlex="49%">{{ 'admin.branchManagement.useDaylightSaving' | translate }}</mat-checkbox>
        </div>
    </form>

    <div fxFlex="13%">
        <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-around center">
            <div *ngIf="showAddressValidationContent" [ngClass]="addressStatus?.style">
                <div *ngFor="let message of addressStatus?.messages">{{ message }}</div>
            </div>
            <div *ngIf="isVerifyingAddress">
                {{ 'admin.branchManagement.addBranchDialog.validatingAddress' | translate }}
            </div>
        </div>
    </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="end end">
        <button mat-raised-button
            color="warning"
            (click)="cancel()"
            id="new-branch-cancel-button">

            {{ 'shared.cancel' | translate }}
        </button>

        <button mat-raised-button
            color="primary"
            (click)="submit()"
            id="new-branch-submit-button"
            [disabled]="cantSubmitBranch">

            {{ (data ? 'shared.edit' : 'shared.add') | translate }}
        </button>
    </div>
</div>
