<mat-card fxLayout="row" fxFill class="project-labor-main-container">
    <kendo-grid [kendoGridBinding]="gridViewData" [pageSize]="15" [pageable]="true"
    [style.height.%]="100" [groupable]="false" sortable="true" appGridPageResize="true" *ngIf="!loading">
        <kendo-grid-column field="jobDate" title="{{ 'projects.labour.dateTitle' | translate }}">
        </kendo-grid-column>
        <kendo-grid-column field="employeeName" title="{{ 'projects.labour.employeeTitle' | translate }}">
        </kendo-grid-column>
        <kendo-grid-column field="hours" title="{{ 'projects.labour.hoursTitle' | translate }}">
            <ng-template kendoGridFooterTemplate let-column="column">
                {{ 'projects.labour.total' | translate }} {{column.title}}: {{total["hours"]?.sum}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="note" title="{{ 'projects.labour.notesTitle' | translate }}">
        </kendo-grid-column>

        <kendo-grid-column title="{{ 'projects.labour.statusTitle' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <span *ngIf="dataItem.isSalaried">{{ 'projects.labour.salariedLabor' | translate }}</span>
                <span *ngIf="!dataItem.isSalaried">{{ translateStatus(dataItem.status) }}</span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</mat-card>
