import { Component, ChangeDetectionStrategy, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { MappingPair } from '../../../../core/data-models/generic/mapping-pair';
import { RatesAction } from '../../../../core/enums/rates-action.enum';
import { RatesQuery } from '../../../../core/data-models/rates/rates-query';
import { RatesEditorOptions } from '../../../../core/data-models/options/rates-editor-options';
import { ServiceRatesMetadata } from '../../../../core/data-models/rates/service-rates/service-rates-metadata';
import { ServiceRatesRevision } from '../../../../core/data-models/rates/service-rates/service-rates-revision';
import { ServiceRatesGroupSummaryDto } from '../../../../core/data-models/dtos/service-rates-group-summary-dto';
import { ServiceRatesHttpService } from '../../../../core/services/http/service-rates-http/service-rates-http.service';
import { VersionPromptComponent } from '../../../../shared/components/prompts/version-prompt/version-prompt.component';
import { ServiceRatesGroupEditorComponent } from '../service-rates-group-editor/service-rates-group-editor.component';

@Component({
    selector: 'app-service-rates-versions-view',
    styleUrls: ['./service-rates-versions-view.component.scss'],
    templateUrl: './service-rates-versions-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRatesVersionsViewComponent implements OnInit {
    @Input() public group: ServiceRatesGroupSummaryDto;
    @Input() public isReadonly: boolean;
    @Output() public groupUpdate = new EventEmitter<ServiceRatesGroupSummaryDto>();
    @Output() public close = new EventEmitter();
    public metadata: ServiceRatesMetadata[] = [];
    public isLoading = false;
    public editorOptions: RatesEditorOptions<ServiceRatesMetadata, ServiceRatesRevision> | null = null;
    public searchVersionText = '';

    constructor(public translate: TranslateService,
                private _serviceRatesHttp: ServiceRatesHttpService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _changeDetectorRef: ChangeDetectorRef) { }

    get visibleMetadata(): ServiceRatesMetadata[] {
        const text = (this.searchVersionText ?? '').trim();

        return this.metadata.filter(meta => meta.version.includes(text));
    }

    public async ngOnInit(): Promise<void> {
        await this.loadRatesMetadata();
    }

    public getActivationDate(metadata: ServiceRatesMetadata): string {
        if (!metadata.isActive) {
            return '';
        }

        const actions = metadata.actions.filter(action => action.type === RatesAction.Activate);

        return actions.slice(-1)[0].time;
    }

    public getCreationDate(metadata: ServiceRatesMetadata): string {
        return metadata.actions.find(action => action.type === RatesAction.Create).time;
    }

    public getCreator(metadata: ServiceRatesMetadata): string {
        const record = metadata.actions.find(action => action.type === RatesAction.Create);

        return record.performer.preferredName;
    }

    public getRevisionDate(metadata: ServiceRatesMetadata): string {
        const actions = metadata.actions.filter(action => action.type === RatesAction.Revise);

        return actions.length ? actions.slice(-1)[0].time : '';
    }

    public getRevisor(metadata: ServiceRatesMetadata): string {
        const actions = metadata.actions.filter(action => action.type === RatesAction.Revise);

        return actions.length ? actions.slice(-1)[0].performer.preferredName : '';
    }

    public async toggleActiveStatus(metadata: ServiceRatesMetadata, htmlEvent: Event | null = null): Promise<void> {
        if (htmlEvent) {
            htmlEvent.preventDefault();
            htmlEvent.stopPropagation();
        }

        let updated: ServiceRatesMetadata;
        const query = { groupId: this.group.groupId, version: metadata.version } as RatesQuery;

        if (metadata.isActive) {
            updated = await this._serviceRatesHttp.deactivateServiceRatesVersion(query);
        }
        else {
            updated = await this._serviceRatesHttp.activateServiceRatesVersion(query);
        }

        if (!updated) {
            const key = metadata.isActive ? 'failedToDeactivateVersion' : 'failedToActivateVersion';
            const error = this.translate.instant(`flynnBudgetTool.ratesVersionsView.errors.${key}`, { version: metadata.version });
            this._snackBar.open(error, this.translate.instant('flynnBudgetTool.shared.ok'));
        }
        else {
            const key = updated.isActive ? 'versionActivated' : 'versionDeactivated';
            const message = this.translate.instant(`flynnBudgetTool.ratesVersionsView.messages.${key}`, { version: metadata.version });
            this._snackBar.open(message, this.translate.instant('flynnBudgetTool.shared.ok'));
            this.updateMetadata(updated);
        }

        this._changeDetectorRef.markForCheck();
    }

    public async openGroupEditor(): Promise<void> {
        const [width, minHeight, maxHeight] = ['30vw', '500px', '55vh'];
        const dialog = this._dialog.open(ServiceRatesGroupEditorComponent, { data: this.group, width, minHeight, maxHeight });
        const group = await dialog.afterClosed().toPromise<ServiceRatesGroupSummaryDto>();

        if (group) {
            this.groupUpdate.emit(group);
        }

        this._changeDetectorRef.markForCheck();
    }

    public async openVersionEditor(metadata: ServiceRatesMetadata): Promise<boolean> {
        if (this.isReadonly && !this.isLatestActiveVersion(metadata)) {
            const errorMessage = this.translate.instant('flynnBudgetTool.ratesVersionsView.errors.failedToOpenVersionEditorInReadonlyMode');
            this._snackBar.open(errorMessage, this.translate.instant('flynnBudgetTool.shared.ok'));

            return false;
        }

        const query = { groupId: this.group.groupId, version: metadata.version } as RatesQuery;
        const revision = await this._serviceRatesHttp.getLatestServiceRatesRevision(query);

        if (!revision) {
            const error = this.translate.instant('flynnBudgetTool.ratesVersionsView.errors.failedToOpenVersion', { version: metadata.version });
            this._snackBar.open(error, this.translate.instant('flynnBudgetTool.shared.ok'));
            this._changeDetectorRef.markForCheck();

            return false;
        }

        this.editorOptions = { metadata, revision } as RatesEditorOptions<ServiceRatesMetadata, ServiceRatesRevision>;
        this._changeDetectorRef.markForCheck();

        return true;
    }

    public async openNewVersionPrompt(): Promise<void> {
        const [width, minHeight, maxHeight] = ['400px', '270px', '380px'];
        const data = this.metadata.map(meta => meta.version);
        const dialog = this._dialog.open(VersionPromptComponent, { data, width, minHeight, maxHeight });
        const mapping = await dialog.afterClosed().toPromise<MappingPair<string> | null>();

        if (!mapping) {
            return;
        }

        const { source, target } = mapping;
        const ratesQuery = { groupId: this.group.groupId, version: target } as RatesQuery;
        const newRevision = source ? await this._serviceRatesHttp.getLatestServiceRatesRevision({ groupId: this.group.groupId, version: source }) : new ServiceRatesRevision();
        const error = this.translate.instant('flynnBudgetTool.ratesVersionsView.errors.failedToAddVersion', { version: target });

        if (!newRevision) {
            this._snackBar.open(error, this.translate.instant('flynnBudgetTool.shared.ok'));

            return;
        }

        const response = await this._serviceRatesHttp.addServiceRatesVersion({ ratesQuery, newRevision });

        if (!response) {
            this._snackBar.open(error, this.translate.instant('flynnBudgetTool.shared.ok'));

            return;
        }

        if (await this.openVersionEditor(response.metadata)) {
            await this.loadRatesMetadata();
        }
    }

    public async addRevision(metadata: ServiceRatesMetadata, revision: ServiceRatesRevision): Promise<void> {
        const { version } = metadata;
        const ratesQuery = { groupId: this.group.groupId, version } as RatesQuery;
        const response = await this._serviceRatesHttp.addServiceRatesRevision({ ratesQuery, newRevision: revision });

        if (response) {
            this.updateMetadata(response.metadata);
            this.editorOptions = { ...this.editorOptions, revision: response.affectedRevision };
        }

        const key = response ? 'messages.revisionSaved' : 'errors.failedToSaveRevision';
        const message = this.translate.instant(`flynnBudgetTool.ratesEditor.${key}`, { version });
        this._snackBar.open(message, this.translate.instant('flynnBudgetTool.shared.ok'));
        this._changeDetectorRef.markForCheck();
    }

    public closeEditor(): void {
        this.editorOptions = null;
        this.searchVersionText = '';
    }

    private async loadRatesMetadata(): Promise<void> {
        this.isLoading = true;
        this.metadata = await this._serviceRatesHttp.getServiceRatesMetadataByGroupId(this.group.groupId);
        this.isLoading = false;
        this._changeDetectorRef.markForCheck();
    }

    private updateMetadata(updated: ServiceRatesMetadata): void {
        const index = this.metadata.findIndex(meta => meta.version === updated.version);

        if (index !== -1) {
            this.metadata = [...this.metadata.slice(0, index), updated, ...this.metadata.slice(index + 1)];
        }

        if (this.editorOptions?.metadata.version === updated.version) {
            this.editorOptions = { ...this.editorOptions, metadata: updated };
        }
    }

    private isLatestActiveVersion(metadata: ServiceRatesMetadata): boolean {
        if (!metadata.isActive) {
            return false;
        }

        return !this.metadata.some(meta => meta.isActive && meta.version > metadata.version);
    }
}
