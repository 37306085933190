import { Injectable } from '@angular/core';

import CustomIcon from '../../data-models/custom-icon';
import ConversionConfig from '../../data-models/app-configs/conversion-config';
import SubtypeConfig from '../../data-models/app-configs/subtype-config';
import SalesCodeConfig from '../../data-models/app-configs/sales-code-config';
import JobCodeModel from '../../data-models/job-code-model';
import { AppConfigHttpService } from '../http/appconfig-http/appconfig-http.service';
import ProjectIdUtility from '../utility/project-id-utility/project-id-utility.service';
import { WorkCategory } from '../../data-models/work-category';
import LanguageUtility from '../utility/language-utility/language-utility.service';

@Injectable({
    providedIn: 'root'
})
export class SalesCodeConfigService {
    private _salesCodes: SalesCodeConfig[] = [];

    constructor(private _appConfigService: AppConfigHttpService) { }

    public async initialize(): Promise<void> {
        this._salesCodes = await this._appConfigService.getSalesCodeConfig();
    }

    public async getJobCodes(includeEmergency: boolean, language: string): Promise<JobCodeModel[]> {
        await this.initialize();

        const salesCodeConfigs = includeEmergency ?
            this._salesCodes :
            this._salesCodes.filter(salesCode => !ProjectIdUtility.isEmergency(salesCode.code));

        const existingCodes = new Set<string>();
        const jobCodes: JobCodeModel[] = [];
        salesCodeConfigs.filter(_ => _.subtypes?.length).forEach(config => {
            config.subtypes.forEach(type => {
                if (!existingCodes.has(type.code)) {
                    existingCodes.add(type.code);
                    jobCodes.push(new JobCodeModel(type.code, LanguageUtility.getLocalizedContent(language, type.descriptions)));
                }
            });
        });

        salesCodeConfigs.filter(_ => !_.subtypes?.length).forEach(config => {
            if (!existingCodes.has(config.code)) {
                existingCodes.add(config.code);
                jobCodes.push(new JobCodeModel(config.code, LanguageUtility.getLocalizedContent(language, config.names)));
            }
        });

        return jobCodes.sort((prev, cur) => prev.code.localeCompare(cur.code));
    }

    public isSubcontract(id = ''): boolean {
        return ProjectIdUtility.isSubcontract(id);
    }

    public getSalesCode(id = '', includeEmergency = true): string {
        if (!id?.trim()) {
            return id;
        }

        if (!ProjectIdUtility.isEmergency(id)) {
            return id.slice(-3);
        }

        const code = id.slice(0, 3);

        return includeEmergency ? code : this.toNonEmergencySalesCode(code);
    }

    public toNonEmergencySalesCode(code = ''): string {
        const targets = this.getEmergencyConversionTargets(code);

        return targets.find(_ => _.isDefaultTarget)?.code ?? code;
    }

    public getTypeText(id: string, language: string): string {
        const names = this.getSalesCodeConfig(id)?.names ?? [];

        return LanguageUtility.getLocalizedContent(language, names);
    }

    public isMultiDay(id: string): boolean {
        return this.getSalesCodeConfig(id)?.isMultiDay ?? false;
    }

    public isCompleteByDateAllowed(id: string): boolean {
        return this.getSalesCodeConfig(id)?.isCompleteByDateAllowed ?? false;
    }

    public isWorkOrderRequired(id: string): boolean {
        return this.getSalesCodeConfig(id)?.isWorkOrderRequired ?? false;
    }

    public requireWorkCheckList(id: string): boolean {
        return this.getSalesCodeConfig(id)?.requireCheckList ?? false;
    }

    public isHandOffMeetingJob(id: string): boolean {
        return this.getSalesCodeConfig(id)?.isHandOffMeetingType ?? false;
    }

    /* istanbul ignore next */
    //eslint-disable-next-line
    public getJobTypeIcon(jobType: string): CustomIcon {
        const convertedJobType = this.getSalesCode(jobType);

        switch (convertedJobType) {
            case '310' : return new CustomIcon('roofing', false);
            case '312' : return new CustomIcon('water_damage', false);
            case '313' : return new CustomIcon('grading', false);
            case '317' : return new CustomIcon('metalMaterialIcon', true);
            case '319' :
            case '330' :
            case '331' :
            case '332' :
            case '334' :
                return new CustomIcon('glazingIcon', true);
            case '321' : return new CustomIcon('eco', false);
            default : return new CustomIcon('assignment', false);
        }
    }

    public getEmergencyConversionTargets(emergencyCode: string): ConversionConfig[] {
        return this.getSalesCodeConfig(emergencyCode)?.emergencyConversionTargets ?? [];
    }

    public getSubtypes(salesCode: string): SubtypeConfig[] {
        return this.getSalesCodeConfig(salesCode)?.subtypes ?? [];
    }

    public getSubtypesForConversion(salesCode: string): SubtypeConfig[] {
        return this.getSubtypes(salesCode).filter(_ => _.isConversionTarget);
    }

    public isLeakCall(id: string): boolean {
        const salesCode = this.getSalesCodeConfig(id);
        if (!salesCode) {
            return false;
        }

        return salesCode.workTypes.some(_ => _ === 'leak');
    }

    public isAudit(id: string): boolean {
        const salesCode = this.getSalesCodeConfig(id);
        if (!salesCode) {
            return false;
        }

        return salesCode.workTypes.some(_ => _ === 'audit');
    }

    public isFabricationRequired(id: string): boolean {
        const salesCode = this.getSalesCodeConfig(id);

        return Boolean(salesCode?.isFabricationRequired);
    }

    public isDrawingRequired(id: string): boolean {

        return this.getSalesCodeConfig(id)?.isDrawingRequired ?? false;
    }

    public async getNonSubcontractSalesCodes(): Promise<string[]> {
        await this.initialize();
        return this._salesCodes.filter(
            config => config.workCategory === WorkCategory.InternalJob && !ProjectIdUtility.isEmergency(config.code)).map(_ => _.code);
    }

    public async getWorkCategoryBySalesCode(salesCode: string): Promise<WorkCategory> {
        await this.initialize();
        const salesCodeConfig = this._salesCodes.find(config => config.code === salesCode.trim());
        return salesCodeConfig.workCategory;
    }

    public async getWorkCategoryByProjectId(projectId: string): Promise<WorkCategory> {
        await this.initialize();
        const salesCode = this.getSalesCodeConfig(projectId, false);
        return salesCode.workCategory;
    }

    public async getSalesCodeWorkCategoryMapping(): Promise<Map<string, WorkCategory>> {
        await this.initialize();
        const salesCodes = await this._appConfigService.getSalesCodeConfig();
        const salesCodeMap = new Map<string, WorkCategory>();

        for (const salesCode of salesCodes) {
            salesCodeMap.set(salesCode.code, salesCode.workCategory);
        }

        return salesCodeMap;
    }

    public getSalesCodeConfig(id: string, includeEmergency = true): SalesCodeConfig {
        return this._salesCodes.find(_ => _.code === this.getSalesCode(id, includeEmergency));
    }
}
