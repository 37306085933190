import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog-component/confirm-dialog.component';
import BudgetManagementPayload from '../../../core/data-models/budget-management-payload';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';

@Component({
    selector: 'app-budget-preview',
    styleUrls: ['./budget-preview.component.scss'],
    templateUrl: './budget-preview.component.html'
})
export class BudgetPreviewComponent implements OnInit {
    @Input() public payload: BudgetManagementPayload;
    @Input() public previewFile: Promise<Blob> = null;
    @Output() public deleteBudget = new EventEmitter();
    public isLoading = false;

    public constructor(public translate: TranslateService,
        private _downloadService: DownloadHttpService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog) {
    }

    public async ngOnInit(): Promise<void> {
        if (this.payload.recordId) {
            await this.getLatestBudget();
        }
    }

    public async downloadBudget(): Promise<void> {
        const file = await this._downloadService.getBudgetFile(this.payload.recordId, 'xlsm');
        const type = this.payload.isQuote ? 'Quote' : 'Project';
        const id = this.payload.isQuote ? this.payload.recordId : ProjectIdUtility.toDisplayFormat(this.payload.recordId);
        const fileName = `${type} - ${id}.xlsm`;

        if (!(file && this._downloadService.downloadBlob(file, fileName))) {
            const downloadFailMessage = this.translate.instant('shared.budgetPreview.budgetDownloadFailed');
            const closeLabel = this.translate.instant('shared.close');
            this._snackBar.open(downloadFailMessage, closeLabel);
        }
    }

    public async confirmDeleteBudget(): Promise<void> {
        if (!this.payload.isQuote) {
            return;
        }

        const title = this.translate.instant('shared.budgetPreview.deleteBudgetLabel');
        const message = this.translate.instant('shared.budgetPreview.deleteBudgetText');
        const confirmText = this.translate.instant('shared.delete');
        const cancelText = this.translate.instant('shared.cancel');
        const data = new ConfirmDialogOption(title, message, true, confirmText, cancelText);
        const dialog = this._dialog.open(ConfirmDialogComponent, { maxWidth: '400px', data });

        if (!await dialog.afterClosed().toPromise()) {
            return;
        }

        this.deleteBudget.emit();
    }

    private async getLatestBudget(): Promise<void> {
        this.isLoading = true;
        const promise = this._downloadService.getBudgetFile(this.payload.recordId, 'xlsx');
        const result = await promise;
        if (result) {
            setTimeout(() => {
                this.previewFile = promise;
            }, 1);
        }

        this.isLoading = false;
    }
}
