import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../../shared/shared.module';

import { states } from './quote.routing';
import { QuoteComponent } from './quote.component';
import { QuoteDetailsComponent } from './quote-details/quote-details.component';
import { QuotePhotoReportComponent } from './quote-photo-report/quote-photo-report.component';
import { QuoteDrawingsComponent } from './quote-drawings/quote-drawings.component';
import { QuoteReportDetailsComponent } from './quote-report-details/quote-report-details.component';
import { SiteQuotesComponent } from './site-quotes/site-quotes.component';
import { SiteProjectHistoryComponent } from './site-project-history/site-project-history.component';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states }),
        SharedModule
    ],
    declarations: [
        QuoteComponent,
        QuoteDetailsComponent,
        QuotePhotoReportComponent,
        QuoteDrawingsComponent,
        QuoteReportDetailsComponent,
        SiteQuotesComponent,
        SiteProjectHistoryComponent
    ]
})
export class QuoteModule { }
