import { Component, OnInit, Input } from '@angular/core';

import TemplateFieldMeta from '../../../../core/data-models/template-field-meta';
import { ProjectHttpService } from '../../../../core/services/http/project-http/project-http.service';

@Component({
    selector: 'project-work-checklist',
    styleUrls: ['./project-work-checklist.component.scss'],
    templateUrl: './project-work-checklist.component.html'
})
export class ProjectWorkChecklistComponent implements OnInit {
    @Input() public projectId = '';
    @Input() public jobId = 0;
    private _template: TemplateFieldMeta;

    constructor(private _projectHttp: ProjectHttpService) { }

    get template(): TemplateFieldMeta {
        return this._template;
    }

    public async ngOnInit(): Promise<void> {
        this._template = await this._projectHttp.getWorkChecklistTemplateField(this.projectId, this.jobId);
    }
}
