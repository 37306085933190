import { Component, Input } from '@angular/core';

@Component({
    selector: 'project-quotes',
    templateUrl: './project-quotes.component.html',
    styleUrls: ['./project-quotes.component.scss']
})

export class ProjectQuotesComponent {
    @Input() public activeProjectId = '';
}
