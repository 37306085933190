import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import Employee from '../../../../../core/data-models/employee';
import ForemanRating from '../../../../../core/data-models/project-review/foreman-rating';
import ZeroSafetyReason from '../../../../../core/data-models/zero-safety-reason';
import ZeroSafetyReasonDialogOption from '../../../../../core/data-models/zero-safety-reason-dialog-option';

@Component({
    selector: 'zero-safety-reason-dialog',
    styleUrls: ['./zero-safety-reason-dialog.component.scss'],
    templateUrl: './zero-safety-reason-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ZeroSafetyReasonDialogComponent implements OnInit {
    public reasonInput: UntypedFormControl;
    public foremanRatings: ForemanRating[] = [];

    constructor(private _dialog: MatDialogRef<ZeroSafetyReasonDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public option: ZeroSafetyReasonDialogOption,
                public translate: TranslateService) { }

    get message(): string {
        if (this.option.isLateFillIn) {
            return this.translate.instant('projects.zeroSafety.warningMessage1');
        }
        return this.translate.instant('projects.zeroSafety.warningMessage2');
    }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        const { foremen, isLateFillIn } = this.option;
        const reason = this.translate.instant('projects.zeroSafety.reason');
        const value = isLateFillIn ? reason : '';
        const disabled = this.option.isLateFillIn;
        const validators = [Validators.required, Validators.pattern(/\w+/)];
        this.reasonInput = new UntypedFormControl({ value, disabled }, validators);
        this.foremanRatings = foremen.map(_ => ({ foreman: _, checklist: [0] }));
    }

    public getForemanName(foreman: Employee): string {
        return `${foreman.preferredName} (${foreman.employeeId})`;
    }

    public onClose(emit = true): void {
        if (!emit) {
            this._dialog.close();
        }
        else if (this.option.isLateFillIn || this.reasonInput.valid) {
            const reason = new ZeroSafetyReason(this.reasonInput.value, this.foremanRatings);
            this._dialog.close(reason);
        }
    }
}
