<form [formGroup]="redispatchFormGroup" class="redispatch-project-container" fxFill fxLayout="column" fxLayoutAlign="center center">
    <div class="redispatch-project-main-form-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="35px">
        <div [ngClass]="{ 'redispatch-new-job-error-div-outline': !isRequiredFieldProvided }"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="1.5vh"
            fxFill>

            <crew-selector [horizontalMode]="true"
                [healthScreeningOption]="healthScreeningOption"
                [country]="country"
                [initiallySelectedCrew]="handOffMeetingApprovedCrew"
                [branchCodeRestriction]="branchCodeRestriction"
                (crewSelectedEvent)="crewSelected($event)"
                [defaultCrewId]="dispatchOptions.crewId"
                [defaultBranch]="dispatchOptions.branch"
                [isNotEditable]="dispatchOptions.isNotEditable"
                fxFill>
            </crew-selector>

            <div *ngIf="!isInternal" fxLayout="column" fxLayoutGap="0.75vh" fxFlexAlign="start">
                <span class="arrival-time-title">{{ 'projects.redispatch.estimatedTimeOfArrival' | translate }}</span>

                <kendo-datetimepicker formControlName="estimatedTimeOfArrival"
                    [format]="'yyyy-MM-dd HH:mm'"
                    [cancelButton]="false"
                    [popupSettings]="{ appendTo: 'component' }"
                    fxFill>
                </kendo-datetimepicker>
            </div>
        </div>

        <div fxFill fxLayout="column" fxLayoutAlign="center start">
            <h4 style="margin-bottom: 10px;">{{ 'projects.redispatch.additionalInformation' | translate }}</h4>
            <mat-form-field *ngIf="isInternal" fxFlex="40">
                <mat-label>{{ 'projects.redispatch.estimatedHours' | translate }}</mat-label>
                <input [readonly]="dispatchOptions.isNotEditable" min="0" type="number" matInput formControlName="estimatedHours">
                <mat-error *ngIf="redispatchFormGroup.get('estimatedHours').invalid">
                    {{ integerValidator.message }}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlexFill>
                <mat-label>{{ 'projects.redispatch.notes' | translate }}</mat-label>
                <textarea [readonly]="dispatchOptions.isNotEditable" class="redispatch-notes" matInput rows="10" formControlName="notes"></textarea>
            </mat-form-field>

            <div *ngIf="handOffMeetingRequired() && handOffMeetingApprovedCrew?.crewId" fxFlexFill fxLayout fxLayoutAlign="start center"
                class="hand-off-meeting-redispatch-notice-message">
                {{ 'projects.redispatch.redispatchHelpText' | translate }}
            </div>

            <div *ngIf="handOffMeetingRequired() && !handOffMeetingApprovedCrew?.crewId"
                class="hand-off-meeting-redispatch-notice-message"
                fxFlexFill
                fxLayout
                fxLayoutAlign="start center">
                {{ 'projects.redispatch.noRecordsHelpText' | translate }}
            </div>
        </div>
    </div>

    <div class="redispatch-project-actions" fxLayout fxLayoutAlign="center center" fxFlexAlign="end">
        <button [disabled]="dispatchOptions.isNotEditable" mat-raised-button color="primary" *ngIf="!handOffMeetingRequired()"
            (click)="redispatch()">{{ (isDispatch ? 'projects.redispatch.dispatch' : 'projects.redispatch.redispatch') | translate }}</button>
        <button *ngIf="handOffMeetingRequired()" mat-stroked-button (click)="redispatch()">{{ 'projects.redispatch.next' | translate }}</button>
    </div>
</form>
