import { TranslateService } from '@ngx-translate/core';
import QuotePlaceholder from './quote-placeholder';
import { QuoteEditorConstants } from '../../../../core/data-models/quote-general-info/quote-editor-constants';

export default function getQuoteTemplateEditorButtons(translate: TranslateService): QuotePlaceholder[] {
    return [{
        name: translate.instant('shared.quote.editor.insertAmount'),
        placeholder: translate.instant('shared.quote.templateEditor.quoteAmountPlaceholder'),
        identifier: QuoteEditorConstants.QuoteAmountIdentifier,
        isEditable: false
    }, {
        name: translate.instant('shared.quote.editor.insertTax'),
        placeholder: translate.instant('shared.quote.templateEditor.quoteTaxPlaceholder'),
        identifier: QuoteEditorConstants.QuoteTaxIdentifier,
        isEditable: false
    }, {
        name: translate.instant('shared.quote.editor.insertTotal'),
        placeholder: translate.instant('shared.quote.templateEditor.quoteTotalPlaceholder'),
        identifier: QuoteEditorConstants.QuoteTotalIdentifier,
        isEditable: false
    }, {
        name: translate.instant('shared.quote.editor.insertPhrase'),
        placeholder: translate.instant('shared.quote.templateEditor.quotePhrasePlaceholder'),
        identifier: QuoteEditorConstants.QuotePhraseIdentifier,
        isEditable: false
    }, {
        name: translate.instant('shared.quote.editor.insertTotalPhrase'),
        placeholder: translate.instant('shared.quote.templateEditor.quoteTotalPhrasePlaceholder'),
        identifier: QuoteEditorConstants.QuoteTotalPhraseIdentifier,
        isEditable: false
    }, {
        name: translate.instant('shared.quote.editor.insertRecommendation'),
        placeholder: translate.instant('shared.quote.templateEditor.quoteRecommendationPlaceholder'),
        identifier: QuoteEditorConstants.QuoteRecommendationIdentifier,
        isEditable: true
    }];
}
