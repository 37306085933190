<dialog-action-card class="return-to-site-reason-dialog-container"
    [icon]="'notification_important'"
    [title]="translate.instant('projects.returnToSiteReasonDialog.enterReason')"
    [isLoaded]="true"
    (close)="onClose(false)">

    <div class="return-to-site-reason-dialog-content" fxLayout="column" fxLayoutGap="15px">
        <mat-checkbox *ngIf="option.showFabricationCheckbox" [(ngModel)]="option.reason.isFabricationRequired">
            {{ 'projects.returnToSiteReasonDialog.requireMetalFabrication' | translate }}
        </mat-checkbox>

        <mat-form-field class="return-to-site-reason-dialog-input">
            <mat-label>{{ 'projects.returnToSiteReasonDialog.typeReason' | translate }}</mat-label>

            <textarea matInput
                cdkFocusInitial
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="8"
                [formControl]="reasonInput"
                required>
            </textarea>

            <mat-error *ngIf="reasonInput.invalid">{{ 'projects.returnToSiteReasonDialog.errorHelpText' | translate }}</mat-error>
        </mat-form-field>
    </div>

    <div class="return-to-site-reason-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="onClose()">{{ 'projects.returnToSiteReasonDialog.proceed' | translate }}</button>
    </div>
</dialog-action-card>
