import { Component, Input, ViewChild, HostListener, Output, EventEmitter, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortalDirective } from '@angular/cdk/portal';
import CheckboxList from '../../../../core/data-models/checkbox-list';
import CheckboxData from '../../../../core/data-models/checkbox-data';

@Component({
    selector: 'filter-popup-panel',
    styleUrls: ['./filter-popup-panel.component.scss'],
    templateUrl: './filter-popup-panel.component.html',
    encapsulation: ViewEncapsulation.None
})

export class FilterPopupPanelComponent implements OnInit {
    @Input() public filterConfig: CheckboxList;
    @Input() public chipItemsColorClass: string;
    @Output() public onFilterItemChecked = new EventEmitter<CheckboxList>();
    @ViewChild(TemplatePortalDirective) public contentTemplate: TemplatePortalDirective;
    @ViewChild('dropReference') public reference: ElementRef;
    public filteredBySearchTextDropDownItems: CheckboxData[];

    public overlayRef: OverlayRef;
    public dropDownFilterText = '';
    public isFilterContainerOverFlowing = false;
    public removable = true;
    public selectable = true;

    constructor(private overlay: Overlay) { }

    get selectedFilterItems(): CheckboxData[] {
        return this.filterConfig.items.filter(_ => _.checked);
    }

    public ngOnInit(): void {
        this.filterListWithSearchText();
    }

    public filterCheckedStatusChanged(): void {
        this.onFilterItemChecked.emit(this.filterConfig);

        this.updateOverFlowFlag();
    }

    public remove(chipItem: string): void {
        const index = this.filterConfig.items.findIndex(_ => _.name === chipItem);
        if (index !== -1) {
            this.filterConfig.items[index].checked = false;
            this.onFilterItemChecked.emit(this.filterConfig);
            this.updateOverFlowFlag();
        }
    }

    /* istanbul ignore next */
    public showFilterPopup(): void {
        this.overlayRef = this.overlay.create(this.getOverlayConfig());
        this.overlayRef.attach(this.contentTemplate);
        this.syncWidth();
        this.overlayRef.backdropClick().subscribe(() => this.hide());
    }

    /* istanbul ignore next */
    public hide(): void {
        this.dropDownFilterText = '';
        this.filterListWithSearchText();
        // eslint-disable-next-line
        this.overlayRef?.detach();
    }

    /* istanbul ignore next */
    @HostListener('window:resize')
    public onWinResize(): void {
        this.hide();
        this.updateOverFlowFlag();
    }

    /* istanbul ignore next */
    @HostListener('scroll')
    public onWinScroll(): void {
        this.hide();
    }

    public filterListWithSearchText(): void {
        const filterValue = this.dropDownFilterText?.toLowerCase();

        if (filterValue) {
            this.filteredBySearchTextDropDownItems =
                this.filterConfig.items.filter(_ => _.displayText.toLowerCase().indexOf(filterValue) !== -1);
        }
        else {
            this.filteredBySearchTextDropDownItems = this.filterConfig.items.slice();
        }
    }

    private updateOverFlowFlag(): void {
        setTimeout(_ => {
            this.isFilterContainerOverFlowing = this.reference?.nativeElement?.firstChild?.offsetHeight > 25;
        });

    }

    /* istanbul ignore next */
    private syncWidth(): void {
        if (!this.overlayRef) {
            return;
        }

        this.overlayRef.updateSize({ width: this.reference.nativeElement.offsetWidth });

    }

    /* istanbul ignore next */
    private getOverlayConfig(): OverlayConfig {
        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(this.reference)
            .withPush(false)
            .withPositions([{
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top'
            }, {
                originX: 'start',
                originY: 'top',
                overlayX: 'start',
                overlayY: 'bottom'
            }]);

        return new OverlayConfig({
            positionStrategy,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop'
        });
    }
}
