import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CalendarOptions, EventContentArg, EventClickArg, DateSelectArg } from '@fullcalendar/core';
import { TranslateService } from '@ngx-translate/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import Crew from '../../core/data-models/crew';
import Branch from '../../core/data-models/branch';
import { BranchHttpService } from '../../core/services/http/branch-http/branch-http.service';
import { CrewHttpService } from '../../core/services/http/crew-http/crew-http.service';
import { HolidayHttpService } from '../../core/services/http/holiday-http/holiday-http.service';
import { UserHttpService } from '../../core/services/http/user-http/user-http.service';
import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import HolidayEvent from '../../core/data-models/holiday-event';

import { AddOnCallComponent } from './add-on-call/add-on-call.component';
import { RemoveOnCallComponent } from './remove-on-call/remove-on-call.component';

@Component({
    selector: 'on-call',
    templateUrl: './on-call.component.html',
    styleUrls: ['./on-call.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OnCallComponent implements OnInit {
    public branches: Branch[];
    public selectedBranch = '';
    public isAdmin = false;
    public holidays: HolidayEvent[] = [];
    public crews: Crew[] = [];

    public options: CalendarOptions = {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        selectable: true,
        firstDay: 1,
        events: [],
        locale: '',
        select: this.addOnCall.bind(this),
        eventClick: this.removeOnCall.bind(this),
        dayCellDidMount: this.dayRender.bind(this),
        eventContent: this.eventRender.bind(this),
        headerToolbar: {
            left: '',
            center: 'prev title next',
            right: 'today'
        }
    };

    // eslint-disable-next-line max-params
    constructor(private _dialog: MatDialog,
                private _userHttp: UserHttpService,
                private _branchHttpService: BranchHttpService,
                private _crewHttpService: CrewHttpService,
                private _holidayHttpService: HolidayHttpService,
                private _permissionService: UserPermissionService,
                private _translate: TranslateService) { }

    public async ngOnInit(): Promise<void> {
        const user = await this._userHttp.getUserInfo(false);
        this.branches = await this._branchHttpService.getAllBranches();
        this.holidays = await this._holidayHttpService.getHolidayEvents();
        this.isAdmin = await this._permissionService.hasAdminPrivilege();
        this.selectedBranch = user.branch;
        this.options.locale = user?.language ?? 'en';
        this.options.buttonText = { today: this._translate.instant('fullCalendar.todayLabel') };
        await this.loadBranchData();
    }

    public dayRender(content): void {
        if (!this.holidays.length) {
            return;
        }

        const holidayDate = new Date(content.date).toISOString().substring(0, 10);
        const holiday = this.holidays.find(_ => _.start.substring(0, 10) === holidayDate);

        if (holiday) {
            content.el.innerHTML = '<img class="on-call-holiday-icon" src="../../../assets/icons/holiday_icon.svg" />';
        }
    }

    public eventRender(content: EventContentArg): { html: string } {
        const worker = content.event.title.replace(/\s+crew$/i, '');
        const name = this._translate.instant('onCall.workersCrew', { worker });

        return { html: `<div class="fc-content" title="${name}"><span class="on-call-crew-name fc-title">${name}</span></div>` };
    }

    public async loadBranchData(): Promise<void> {
        const { crews, events } = await this._crewHttpService.getOnCallCrewsAndEvents(this.selectedBranch);
        this.crews = crews;

        this.options.events = events.map((_, i) => ({
            ..._,
            classNames: ['event-style', `event-background${i % 5}`]
        }));
    }

    public addOnCall({ start, end }: DateSelectArg): void {
        const dialogRef = this._dialog.open(AddOnCallComponent, {
            width: '800px',
            height: '300px',
            data: { crewList: this.crews, start, end }
        });

        dialogRef.afterClosed().subscribe(() => {
            this.loadBranchData();
        });
    }

    public removeOnCall({ event }: EventClickArg): void {
        const dialogRef = this._dialog.open(RemoveOnCallComponent, {
            width: '600px',
            height: '300px',
            data: {
                crew: event.title,
                start: event.start,
                end: event.end,
                extendedProps: { eventId: event.extendedProps.eventId }
            }
        });

        dialogRef.afterClosed().subscribe(() => {
            this.loadBranchData();
        });
    }
}
