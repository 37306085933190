<mat-card fxFill>
    <div fxLayout="column" fxFill class="template-editor-content">
        <div fxLayout="row" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutAlign="center" class="mat-form-field-wrapper">
                <button mat-flat-button color="primary" (click)="clearTemplate()">
                    <mat-icon>add</mat-icon>
                    {{'templateEditor.editor.newTemplate' | translate }}
                </button>
            </div>
            <mat-form-field fxFlex="30">
                <mat-label>{{'templateEditor.editor.templateName' | translate }}</mat-label>
                <input matInput [(ngModel)]="currentTemplate.name" [disabled]="isOldTemplate" (ngModelChange)="emitEvent()">
            </mat-form-field>
            <div fxFlex></div>
            <mat-form-field>
                <mat-label>{{'templateEditor.editor.availableTemplates' | translate }}</mat-label>
                <mat-select (selectionChange)="loadTemplate($event)" [value]="currentTemplate">
                    <mat-option *ngFor="let template of templates" [value]="template">{{template.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <textarea [id]="editorId"></textarea>
    </div>
</mat-card>
