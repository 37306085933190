import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LaborStatusService {
    constructor(private _translate: TranslateService) {}

    public getTranslation(statusName: string): string {
        const key = this.getKeyForTranslation(statusName);
        return this._translate.instant(`labour.statuses.${key}`);
    }

    private getKeyForTranslation(statusName: string): string {
        const lowerCaseStatusName = statusName.toLowerCase();
        switch (lowerCaseStatusName) {
            case 'submitted by tech':
                return 'submitted';
            case 'uploaded to tcm/dtc':
                return 'uploaded';
            case 'failed to upload to tcm/dtc':
                return 'failed';
            default:
                return lowerCaseStatusName;
        }
    }
}
