import SubscribedTopic from './subscribed-topic';

export default class UserProfile {
    public firstName = '';
    public lastName = '';
    public preferredName = '';
    public language = 'en';
    public location = '';
    public phoneNumber = '';
    public employeeId = '';
    public emailAddress = '';
    public userName = '';
    public userRoles: string[] = [];
    public subscriptions: SubscribedTopic[] = [];
    public isDeactivated = false;

    constructor(userName = '') {
        this.userName = userName;
    }
}
