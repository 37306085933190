import CodeDesc from '../generic/code-description';
import ProjectQuestionsGrid from './project-questions-grid';

export default class ProjectQuestions {
    public swingRate = false;
    public pitchRate = false;
    public wgSchdOverride = false;
    public heightPay = false;
    public weldingPay = false;
    public projValue: CodeDesc = null;
    public otWeekly = 0;
    public otDaily = 0;
    public dtWeekly = 0;
    public dtDaily = 0;
    public mealAllowance = 0;
    public intlNRetn = 0;
    public zonePay: ProjectQuestionsGrid[] = [];
    public roomNBoard: ProjectQuestionsGrid[] = [];
    public mileage: ProjectQuestionsGrid[] = [];
    public epsca = false;
    public foremanPremium = 0;
    public residential = false;
}
