import ProjectAction from './project-action';

export default class ProjectActionOptions {
    public followUpQuoteCreation = new ProjectAction();
    public swapTo312 = new ProjectAction();
    public swapTo315 = new ProjectAction();
    public convertProject = new ProjectAction();
    public updateProject = new ProjectAction();
    public budgetUpload = new ProjectAction();
    public invoiceUpload = new ProjectAction();
    public invoiceViewer = new ProjectAction();
    public mergeProject = new ProjectAction();
    public unmergeProject = new ProjectAction();
    public deleteProject = new ProjectAction();
    public changeServiceProvider = new ProjectAction();
}
