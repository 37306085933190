<div class="attributes-container" fxLayout="column">
    <div fxLayout="row" fxFlex="11">
        <mat-card id="attribute-search-card" fxFlex="40">
            <mat-card-content>
                <div fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px">
                        <mat-form-field fxFlex id="search-crew-form-field">
                            <input type="text" matInput [(ngModel)]="projectId" />
                            <mat-label>{{ 'shared.projectAttributesManagement.projectId' | translate }}</mat-label>
                        </mat-form-field>

                        <button class="search-button" fxFlex="80px" mat-raised-button color="primary" (click)="search()" [disabled]="isDynamicsOnHold"><mat-icon>search</mat-icon></button>
                    </div>
                    <small *ngIf="isDynamicsOnHold" class="dynamics-hold-text">{{ 'shared.projectAttributesManagement.dynamicsOnHoldError' | translate }}</small>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div id="attributes-management-body" fxLayout="row" fxFlex="82">
        <mat-card id="attribute-card" *ngIf="projectAttributes" fxFlex="100">
            <mat-card-content fxLayout="column">
                <mat-tab-group fxFlex="100">
                    <mat-tab label="{{ 'shared.projectAttributesManagement.projectQuestions' | translate }}" *ngIf="!isSubcontract">
                        <div fxLayout="column" class="attribute-tab-content">
                            <div fxLayout="row" fxFlex="100">
                                <project-questions-card fxFlex="50" (result)="onProjectQuestionsChange($event)" [projectQuestions]="projectAttributes.projectQuestions" [isUSProject]="projectAttributes.isUSProject" [readOnly]="false"></project-questions-card>
                                <project-attributes-card class="attribute-container" fxFlex="50" (result)="onAttributesChange($event)" [projectQuestions]="projectAttributes.projectQuestions" [isUSProject]="projectAttributes.isUSProject" [readOnly]="false"></project-attributes-card>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'shared.projectAttributesManagement.usProjectQuestions' | translate }}" *ngIf="projectAttributes.isUSProject">
                        <div fxLayout="column" class="attribute-tab-content">
                            <div fxLayout="row" fxFlex="100">
                                <project-us-questions-card (result)="onUSProjectQuestionsChange($event)" [usEstimateForm]="projectAttributes.uSEstimateForm" [readOnly]="false"></project-us-questions-card>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>

    <div fxLayout="row" fxFlex="7" fxLayoutAlign="end center" *ngIf="projectAttributes">
        <button class="save-button" fxFlex="80px" mat-raised-button color="primary" (click)="save()" [disabled]="isSaveDisabled">{{ 'shared.save' | translate }}</button>
    </div>
</div>
