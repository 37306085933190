import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import MobileScanFileGroup from '../../../data-models/mobile-scan-file/mobile-scan-file-group';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MobileScanFileHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/mobile-scan-file`;

    constructor(private _httpClient: HttpClient) { }

    public async getFilesByLocationCode(locationCode: string): Promise<MobileScanFileGroup[]> {
        try {
            const endpoint = `${this._api}/locations/${locationCode}/files`;
            const data = await this._httpClient.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getBlobByLocationCodeAndUploaderId(locationCode: string, uploaderId: string): Promise<Blob> {
        try {
            const endpoint = `${this._api}/locations/${locationCode}/uploaders/${uploaderId}/files/download`;
            const data = await this._httpClient.get(endpoint, { responseType: 'arraybuffer' }).toPromise();
            return new Blob([data], { type: 'application/zip' });
        }
        catch {
            return null;
        }
    }

    public async deleteFileByPath(path: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/files?path=${encodeURIComponent(path)}`;
            return await this._httpClient.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getSignedUrl(path: string): Promise<string> {
        try {
            const endpoint = `${this._api}/pre-signed-url?path=${encodeURIComponent(path)}`;
            return await this._httpClient.get<string>(endpoint).toPromise();
        }
        catch {
            return '';
        }
    }
}
