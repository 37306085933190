<div class="quote-report-details-container" fxLayout="column" fxLayoutGap="7px">
    <div class="quote-report-details-pager-wrapper">
        <div class="quote-report-details-pager" fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button color="primary" (click)="onPushToDetails()" fxLayoutAlign="center center">
                <mat-icon>save_alt</mat-icon>
                <span>{{ 'quote.report.pushToDetails' | translate }}</span>
            </button>

            <div fxFlex></div>

            <dropdown-button *ngIf="printOptions.length"
                [icon]="'print'"
                [text]="translate.instant('quote.print.title')"
                [options]="printOptions"
                [position]="'before'"
                (optionSelect)="onPrintOptionSelect($event)">
           </dropdown-button>

           <mat-button-toggle-group class="quote-report-details-tabs">
                <mat-button-toggle (click)="activeSection = 'basicInfo'" checked>
                    {{ 'quote.report.details' | translate }}
                </mat-button-toggle>

                <mat-button-toggle (click)="activeSection = 'jobReport'">
                    {{ 'quote.report.report' | translate }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <ng-container *ngIf="generalInfo">
        <project-general-info-panel *ngIf="activeSection === 'basicInfo'"
            [generalInfo]="generalInfo"
            [isReadonly]="true">
        </project-general-info-panel>

        <project-report-content *ngIf="activeSection === 'jobReport'" [generalInfo]="generalInfo"></project-report-content>
    </ng-container>
</div>
