import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import Branch from '../../../data-models/branch';
import BranchTimezone from '../../../data-models/branch-timezone';
import JsonUtility from '../../utility/json-utility.service';
import CodeDescription from '../../../data-models/code-description';
import { FeatureFlagService } from '../../events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../enums/feature-flags.enum';
import GeoLocation from '../../../data-models/geo-location';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BranchHttpService {
    private _api = `${environment.fswApiUrl}api2/service/branch`;
    public branches: Branch[] = null;

    constructor(
        private _http: HttpClient,
        private _translateService: TranslateService,
        private _featureFlagService: FeatureFlagService
    ) {
    }

    public async getBranchByName(name: string): Promise<Branch> {
        try {
            // encode the dot in St.Jones in api url
            const encoded = name.replace('.', '%2E');
            const endpoint = `${this._api}/name/${encoded}/`;
            const data = await this._http.get<Branch>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getAllBranches(useCache = true): Promise<Branch[]> {
        try {
            if (!useCache || !this.branches) {
                const endpoint = `${this._api}/info`;
                const data = await this._http.get<Branch[]>(endpoint).toPromise();
                this.branches = JsonUtility.toCamelCaseKeys(data);
            }

            return JsonUtility.cloneObject(this.branches);
        }
        catch {
            return [];
        }
    }

    public async getAllBranchNames(): Promise<{ locn: string, code: string }[]> {
        try {
            const endpoint = `${this._api}/info/names`;
            const data = await this._http.get<{ locn: string, code: string }[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getBranchTimeZone(): Promise<BranchTimezone[]> {
        try {
            const endpoint = `${this._api}/branchTimeZone`;
            const data = await this._http.get<BranchTimezone[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async activateServiceProvider(branchCode: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/serviceprovider/activate/${branchCode}`;
            const message = this._translateService.instant('admin.branchManagement.successfullyActivatedServiceProvider');
            await this._http.post<boolean>(endpoint, null).toPromise();

            return new CodeDescription('200', message);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;

            if (httpError.status === 400) {
                return new CodeDescription('400', httpError.error);
            }

            return new CodeDescription('500', this._translateService.instant('core.httpServices.branch.internalError'));
        }
    }

    public async deactivateServiceProvider(branchCode: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/serviceprovider/deactivate/${branchCode}`;
            const message = this._translateService.instant('admin.branchManagement.successfullyDeactivatedServiceProvider');
            await this._http.post<boolean>(endpoint, null).toPromise();

            return new CodeDescription('200', message);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;

            if (httpError.status === 400) {
                return new CodeDescription('400', httpError.error);
            }

            return new CodeDescription('500', this._translateService.instant('core.httpServices.branch.internalError'));
        }
    }

    public async getBranchGeocodeFromGoogle(addressStr: string): Promise<CodeDescription> {
        try {
            const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressStr}&key=AIzaSyAZ17B4AlH4G11uut-469dxznmqGBLsUOg`;
            const message = this._translateService.instant('admin.branchManagement.successfullyGotBranchGeocode');
            const data = await this._http.get(endpoint).toPromise();

            return new CodeDescription('200', message, data);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;

            if (httpError.status === 400) {
                return new CodeDescription('400', httpError.error);
            }

            return new CodeDescription('500', this._translateService.instant('core.httpServices.branch.internalError'));
        }
    }

    public async getGeoLocationForBranch(branch: Branch): Promise<GeoLocation> {
        const { addr1, city, state, country } = branch.address;
        const address = `${addr1}, ${city}, ${state}, ${country}`;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let result: any = await this.getBranchGeocodeFromGoogle(address);

        const geocode = result.data.results[0].geometry.location;
        result = await this.getBranchTimeZoneFromGoogle(geocode.lat, geocode.lng);
        const timezone = result.data as BranchTimezone;

        const geoLocation: GeoLocation = {
            ...branch.geoLocation,
            location: [geocode.lng as number, geocode.lat as number],
            latitude: geocode.lat,
            longitude: geocode.lng,
            timeZone: {
                ...timezone,
                timeZoneAbbr: this.processTimeZoneAbbr(timezone.timeZoneName)
            }
        };

        return geoLocation;
    }

    public async getBranchTimeZoneFromGoogle(lat: string, lon: string): Promise<CodeDescription> {
        try {
            const endpoint = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lon}&timestamp=1&key=AIzaSyAZ17B4AlH4G11uut-469dxznmqGBLsUOg`;
            const message = this._translateService.instant('admin.branchManagement.successfullyGotBranchTimezone');
            const result = await this._http.get(endpoint).toPromise();

            return new CodeDescription('200', message, result);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;

            if (httpError.status === 400) {
                return new CodeDescription('400', httpError.error);
            }

            return new CodeDescription('500', this._translateService.instant('core.httpServices.branch.internalError'));
        }
    }

    public async addBranchInfo(branchInfo: Branch): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/admin`;
            const isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
            const key = isInternal ? 'successfullyAddedBranch' : 'successfullyAddedServiceProvider';
            const message = this._translateService.instant(`admin.branchManagement.${key}`);
            await this._http.post(endpoint, branchInfo).toPromise();

            return new CodeDescription('200', message);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;

            if (httpError.status === 400) {
                return new CodeDescription('400', httpError.error);
            }

            return new CodeDescription('500', this._translateService.instant('core.httpServices.branch.internalError'));
        }
    }

    public async updateBranchInfo(branchInfo: Branch, scope: string | null = null): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/admin`;
            const isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
            const key = scope ?? (isInternal ? 'branch' : 'serviceProvider');
            const message = this._translateService.instant(`admin.branchManagement.updateSuccessMessages.${key}`);
            await this._http.put(endpoint, branchInfo).toPromise();

            return new CodeDescription('200', message);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;

            if (httpError.status === 400) {
                return new CodeDescription('400', httpError.error);
            }

            return new CodeDescription('500', this._translateService.instant('core.httpServices.branch.internalError'));
        }
    }

    public async updateFollowUpGoal(branchInfo: Branch): Promise<CodeDescription> {
        try {
            const message = this._translateService.instant('admin.branchManagement.successfullyUpdatedFollowUpGoal');
            const endpoint = `${this._api}/updateFollowUpGoal`;
            await this._http.post(endpoint, branchInfo).toPromise();

            return new CodeDescription('200', message);
        }
        catch (err) {
            const httpError = err as HttpErrorResponse;

            if (httpError.status === 400) {
                return new CodeDescription('400', httpError.error);
            }

            const safeError = this._translateService.instant('core.httpServices.branch.internalError');
            return new CodeDescription('500', safeError);
        }
    }

    public processTimeZoneAbbr(timezone: string): string {
        const words = timezone.split(' ');
        return words.map(_ => _[0]).join();
    }
}
