<dialog-action-card class="ready-for-processing-without-budget-reason-dialog-container"
    [icon]="'notification_important'"
    [title]="translate.instant('projects.readyForProcessingWithoutBudgetReason.enterReason')"
    [isLoaded]="true"
    (close)="onClose(false)">

    <div class="ready-for-processing-without-budget-reason-dialog-content" fxLayout="column" fxLayoutGap="15px">
        <mat-form-field class="ready-for-processing-without-budget-reason-dialog-input">
            <mat-label>{{ 'projects.readyForProcessingWithoutBudgetReason.typeReason' | translate }}</mat-label>

            <textarea matInput
                cdkFocusInitial
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="8"
                [formControl]="reasonInput"
                required>
            </textarea>

            <mat-error *ngIf="reasonInput.invalid">{{ 'projects.readyForProcessingWithoutBudgetReason.errorHelpText' | translate }}</mat-error>
        </mat-form-field>
    </div>

    <div class="ready-for-processing-without-budget-reason-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="onClose()">{{ 'projects.readyForProcessingWithoutBudgetReason.proceed' | translate }}</button>
    </div>
</dialog-action-card>
