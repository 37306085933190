<dialog-action-card class="upload-image-dialog-container"
    [icon]="'photo_album'"
    [title]="data.title"
    [isLoaded]="true"
    (close)="onClose(false)">
    <div class="upload-image-content" fxFill fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="row">
            <div fxLayout="column" fxFill>
                <ngx-file-drop dropZoneClassName="upload-file-drop-zone" dropZoneLabel="Drop Photo here" (onFileDrop)="photoDropped($event)"
                accept=".jpeg, .jpg, .png" multiple="false">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <div class="upload-file-drop-content" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                                <mat-icon>get_app</mat-icon>
                                <span>{{ 'shared.uploadImageDialog.dropPhotoHere' | translate }}</span>
                            </div>
                            <div class="upload-file-upload-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <button mat-stroked-button color="primary" (click)="openFileSelector()">
                                    {{ 'shared.uploadImageDialog.browseFiles' | translate }}
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </ngx-file-drop>
            </div>
        </div>
        <div fxLayout="row" fxFlex fxLayoutGap="15px" fxLayoutAlign="end start">
            <div fxLayout="column" fxFlex="50" class="image-cropper-container" *ngIf="selectedImage">
                <image-cropper
                    [imageFile]="selectedImage"
                    [maintainAspectRatio]="true"
                    [aspectRatio]="1 / 1"
                    [resizeToWidth]="1200"
                    [cropperMinWidth]="128"
                    [onlyScaleDown]="true"
                    [roundCropper]="false"
                    [imageQuality]="100"
                    format="jpeg"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()">
                </image-cropper>
            </div>
            <div fxLayout="column" fxFlex="50">
                <div fxLayout="column">
                    <label>
                        {{ 'shared.uploadImageDialog.preview' | translate }}
                    </label>
                    <div>
                        <img class="preview-image" *ngIf="showCroppedImage" [src]="croppedImage" />
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
            <button mat-raised-button (click)="onClose(true)" color="primary">Upload</button>
        </div>
    </div>
</dialog-action-card>
