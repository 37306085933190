<form fxFlexFill [formGroup]="handOffQuestionFormGroup">

    <div fxLayout fxFlexFill fxLayoutAlign="start start" fxLayoutGap="10px">


        <div fxFlex="30" fxFlexFill fxLayoutAlign="start start">
            {{handOffQuestionFormGroup.get('question').value}}
        </div>
        <div fxFlex="30" fxFlexFill fxLayoutAlign="center start">
            <mat-form-field fxFlex="80" *ngIf="responses?.length > 0">
                <mat-select formControlName="answer">
                    <mat-option *ngFor="let response of responses" [value]="response">
                        {{response}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="isFormControlValid('answer')">{{getErrorMessage('answer')}}</mat-error>
            </mat-form-field>
        </div>

        <mat-form-field fxFlex="40">
            <textarea [readonly]="isReadonly" matInput formControlName="comment" rows="5">
        </textarea>
        <mat-error *ngIf="isFormControlValid('comment')">{{getErrorMessage('comment')}}</mat-error>
        </mat-form-field>
    </div>
</form>
