import { Component, Inject, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { DispatchHttpService } from '../../../../../../core/services/http/dispatch-http/dispatch-http.service';
import ProjectIdUtility from '../../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import ActionProgressReporterOption from '../../../../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
//eslint-disable-next-line
import { HandOffMeetingHttpService } from '../../../../../../core/services/http/hand-off-meeting-http/hand-off-meeting-http.service';
import Crew from '../../../../../../core/data-models/crew';
import HandoffMeetingUtilityService from '../services/handoff-meeting-utility.service';
import HandoffMeetingStepConfig from '../../../../../../core/data-models/dispatch/handoff-meeting-step-config';
import RedispatchProjectStepConfig from '../../../../../../core/data-models/dispatch/redispatch-project-step-config';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import JobRedispatchData from '../../../../../../core/data-models/re-dispatch/job-redispatch-data';
import { SalesCodeConfigService } from '../../../../../../core/services/sales-code-config/sales-code-config.service';
import { TranslateService } from '@ngx-translate/core';
import DispatchExistingOptions from '../../../../../../core/data-models/project-general-info/dispatch-existing-options';
import { ScheduleBoardHttpService } from '../../../../../../core/services/http/schedule-board-http/schedule-board-http.service';

@Component({
    selector: 'redispatch-management-dialog',
    templateUrl: './redispatch-management-dialog.component.html',
    styleUrls: ['./redispatch-management-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RedispatchManagementDialogComponent implements OnInit {
    public dispatchExistingOptions: DispatchExistingOptions;
    public handoffMeetingStepConfig = new HandoffMeetingStepConfig('Handoff Meeting');
    public redispatchProjectStepConfig = new RedispatchProjectStepConfig('Foreman Selection');
    public crewReferencedInLastHandOffMeeting: Crew;
    public handOffMeetingNeededOnThisDispatch = false;
    public isLoaded = false;
    public actionData: Promise<boolean>;

    @ViewChild('dispatchStepper') stepper: MatStepper;

    // eslint-disable-next-line
    constructor(
        private _snackBar: MatSnackBar,
        private _dispatchHttp: DispatchHttpService,
        private _handOffHttpService: HandOffMeetingHttpService,
        private _dialogService: MatDialog,
        private _dialogRef: MatDialogRef<RedispatchManagementDialogComponent>,
        private _salesCodeConfigService: SalesCodeConfigService,
        @Inject(MAT_DIALOG_DATA) public data: DispatchExistingOptions,
        private _scheduleBoardHttpService: ScheduleBoardHttpService,
        public translate: TranslateService) {
        this.dispatchExistingOptions = data;
    }

    public async ngOnInit(): Promise<void> {

        this.isLoaded = false;

        if (!this.dispatchExistingOptions) {
            const failedToLoad = this.translate.instant('projects.redispatch.failedToLoad');
            const ok = this.translate.instant('snackbars.ok');
            this._snackBar.open(failedToLoad, ok);
            this.onClose();
            return;
        }

        this.redispatchProjectStepConfig.data = new JobRedispatchData();
        this.redispatchProjectStepConfig.data.estimatedHours = Number(this.dispatchExistingOptions.estimatedHours);
        this.redispatchProjectStepConfig.data.notes = this.dispatchExistingOptions.dispatcherNote;

        if (this.dispatchExistingOptions.isHandOffMeetingRequired) {
            const projectId = ProjectIdUtility.toStorageFormat(this.dispatchExistingOptions.projectId);
            const handOffMeeting = await this._handOffHttpService.getHandOffMeetingByProjectId(projectId);

            if (handOffMeeting) {
                this.crewReferencedInLastHandOffMeeting = handOffMeeting.crew;
            }
            else {
                this.handOffMeetingNeededOnThisDispatch = true;
            }
        }
        this.isLoaded = true;
    }

    public async redispatchProject(): Promise<void> {
        const dispatchType = 'redispatch';
        await this.callRedispatch(dispatchType);
    }

    public determineIfHandoffRequiredForDispatch(currentCrewSelection: Crew): void {
        const isHandoffProject = this.dispatchExistingOptions.isHandOffMeetingRequired;
        this.handOffMeetingNeededOnThisDispatch = HandoffMeetingUtilityService.handOffMeetingRequired(
            isHandoffProject, this.crewReferencedInLastHandOffMeeting, currentCrewSelection);
    }

    public async completeFirstStep(submit: string): Promise<void> {

        if (submit === 'next' && !this.handOffMeetingNeededOnThisDispatch) {
            const dispatchType = 'redispatch';
            await this.callRedispatch(dispatchType);
            return;
        }

        if (submit === 'next') {
            this.stepper.next();
        }
    }

    public async redispatchProjectWithHandoffMeeting(nextStep: string): Promise<void> {
        if (nextStep === 'back') {
            this.stepper.previous();
            return;
        }
        this.handoffMeetingStepConfig.data.crew = this.redispatchProjectStepConfig.data.crew;
        this.handoffMeetingStepConfig.data.projectId = ProjectIdUtility.toStorageFormat(this.dispatchExistingOptions.projectId);
        this.handoffMeetingStepConfig.data.projectName = this.dispatchExistingOptions.projectName;
        this.handoffMeetingStepConfig.data.jobId = this.dispatchExistingOptions.lastJobId;
        this.redispatchProjectStepConfig.data.handOffMeeting = this.handoffMeetingStepConfig.data;
        const dispatchType = 'handoffMeeting';
        await this.callRedispatch(dispatchType);
    }

    public onClose(): void {
        this._dialogRef.close(false);
    }

    // eslint-disable-next-line max-lines-per-function, complexity
    private async callRedispatch(dispatchType: string): Promise<void> {

        // eslint-disable-next-line max-len
        this.redispatchProjectStepConfig.data.projectId = ProjectIdUtility.toStorageFormat(this.dispatchExistingOptions.projectId);
        this.redispatchProjectStepConfig.data.jobId = this.dispatchExistingOptions.lastJobId;
        // eslint-disable-next-line max-len
        this.redispatchProjectStepConfig.data.jobType = this._salesCodeConfigService.getSalesCode(this.dispatchExistingOptions.projectId);
        this.redispatchProjectStepConfig.data.name = this.dispatchExistingOptions.projectName;
        this.redispatchProjectStepConfig.data.branch = this.dispatchExistingOptions.branch;

        if (this.dispatchExistingOptions.isScheduleBoardDispatch === true) {
            if (this.dispatchExistingOptions.isNotEditable===false) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                this.actionData = this._scheduleBoardHttpService.reassignJob(this.redispatchProjectStepConfig.data, this.redispatchProjectStepConfig.data.projectId);
            }
            else {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                this.actionData = this._dispatchHttp.redispatchReport(this.redispatchProjectStepConfig.data, dispatchType);
            }
            const action = this.actionData;
            const ongoing = this.translate.instant('projects.redispatch.actions.ongoing');
            const success = this.dispatchExistingOptions.isDispatch ? this.translate.instant('projects.redispatch.actions.success_dispatch') : this.translate.instant('projects.redispatch.actions.success');
            const error = this.dispatchExistingOptions.isDispatch ? this.translate.instant('projects.redispatch.actions.error_dispatch') : this.translate.instant('projects.redispatch.actions.error');
            const ok = this.translate.instant('shared.ok');
            const gotIt = this.translate.instant('shared.gotIt');
            const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
            const dialog = this._dialogService.open(ActionProgressDialogComponent, {
                data: dialogData,
                width: '550px',
                height: '120px'
            });

            if (await dialog.afterClosed().toPromise()) {
                this._dialogRef.close(true);
            }
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.actionData = this._dispatchHttp.redispatchReport(this.redispatchProjectStepConfig.data, dispatchType);
            const action = this.actionData;
            const ongoing = this.translate.instant('projects.redispatch.actions.ongoing');
            const success = this.dispatchExistingOptions.isDispatch ? this.translate.instant('projects.redispatch.actions.success_dispatch') : this.translate.instant('projects.redispatch.actions.success');
            const error = this.dispatchExistingOptions.isDispatch ? this.translate.instant('projects.redispatch.actions.error_dispatch') : this.translate.instant('projects.redispatch.actions.error');
            const ok = this.translate.instant('shared.ok');
            const gotIt = this.translate.instant('shared.gotIt');
            const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
            const dialog = this._dialogService.open(ActionProgressDialogComponent, {
                data: dialogData,
                width: '550px',
                height: '120px'
            });

            if (await dialog.afterClosed().toPromise()) {
                this._dialogRef.close(true);
            }
        }
    }
}
