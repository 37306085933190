<div class="queue-view" fxFill>
    <div *ngIf="isLoading" fxLayout="column" fxLayoutAlign="center center" fxFill>
        <dots-loader></dots-loader>
    </div>
    <div [hidden]="isLoading" fxFill>
        <bryntum-project-model
            #project
        ></bryntum-project-model>

        <bryntum-scheduler-pro
        #schedulerpro
        [startDate] = "schedulerProConfig.startDate"
        [endDate] = "schedulerProConfig.endDate"
        [viewPreset] = "schedulerProConfig.viewPreset"
        [forceFit] = "schedulerProConfig.forceFit"
        [columns] = "schedulerProConfig.columns"
        [project] = "project"
        [eventColor] = "schedulerProConfig.eventColor"
        [eventTooltipFeature] = "schedulerProConfig.eventTooltipFeature"
        [taskEditFeature] = "schedulerProConfig.taskEditFeature"
        [scheduleTooltipFeature] = "schedulerProConfig.scheduleTooltipFeature"
        [zoomOnMouseWheel]= "schedulerProConfig.zoomOnMouseWheel"
        [zoomOnTimeAxisDoubleClick] = "schedulerProConfig.zoomOnTimeAxisDoubleClick"
        [createEventOnDblClick] = "schedulerProConfig.createEventOnDblClick"
        [scheduleMenuFeature] = "false"
        [eventMenuFeature] = "false"
        [eventRenderer] = "eventRenderer"
        [eventResizeFeature] = "schedulerProConfig.eventResizeFeature"
        (onEventClick) = "onEventClick($event)"
        [dependenciesFeature] = "schedulerProConfig.dependenciesFeature"
        [eventDragFeature] = "schedulerProConfig.eventDragFeature"
        [listeners] = "schedulerProConfig.listeners"
        [cellMenuFeature] = "schedulerProConfig.cellMenuFeature"
        [eventDragCreateFeature] = "schedulerProConfig.eventDragCreate"
        (onBeforeEventDropFinalize) = "beforeEventDropFinalize($event)"
        [destroyStores]="true"
        ></bryntum-scheduler-pro>
    </div>
</div>