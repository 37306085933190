<ng-container *ngIf="!isLoading; else loading">
    <div fxLayout="column" fxFill *ngIf="payload.useLegacyBudgetTool; else showNewTool">
        <app-budget-upload
            [customerId]="payload.customerId"
            [selectedBudgetFileName]="excelBudgetName"
            (budgetFileSelected)="onBudgetFileSelected($event)"
        ></app-budget-upload>

        <app-budget-preview fxFlex
            [payload]="payload"
            [previewFile]="localExcelBudgetPromise"
            (deleteBudget)="onBudgetDelete()"
        ></app-budget-preview>
    </div>
</ng-container>
<ng-template #showNewTool>
    <div fxLayout="column" fxFill>
        <app-service-operations-standardized-estimation
            [isQuote]="payload.isQuote"
            [useSubcontractRatesForDrafts]="payload.isSubcontract"
            [customerId]="payload.customerId"
            [recordId]="payload.recordId"
            [branchCodeOverride]="payload.branchCodeOverride"
            [canFinalize]="canFinalizeWebBudget"
            [uploadBudgetCallback]="uploadWebBudget.bind(this)"
            (budgetPayloadUpdate)="webBudgetPayloadUpdate.emit($event)">
        </app-service-operations-standardized-estimation>
    </div>
</ng-template>

<ng-template #loading>
    <div fxLayout="column" fxFill fxLayoutAlign="center center">
        <dots-loader></dots-loader>
    </div>
</ng-template>
