
<dialog-action-card class="upload-budget-dialog-container"
    [icon]="'cloud_upload'"
    [title]="translate.instant('projects.updateBudget.title')"
    [isLoaded]="isLoaded"
    (close)="onClose(false)">

    <div fxLayout="row" fxFlex="95" fxFlexAlign="center" fxLayoutAlign="center center">
        <app-budget-upload
            [customerId]="customerId"
            [layout]="'column'"
            (budgetFileSelected)="budgetFile=$event">
        </app-budget-upload>
    </div>

    <div class="upload-budget-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" [disabled]="!budgetFile" (click)="uploadAndProceed()">
            {{ 'projects.updateBudget.approveProject' | translate }}
        </button>
    </div>
</dialog-action-card>
