import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import QuoteCopyOptions from '../../../../core/data-models/quote-options/quote-copy-options';

@Component({
    selector: 'app-quote-report-options-dialog',
    styleUrls: ['./quote-copy-options-dialog.component.scss'],
    templateUrl: './quote-copy-options-dialog.component.html'
})
export class QuoteCopyOptionsDialogComponent {
    public quoteCopyOptions = new QuoteCopyOptions();
    constructor(public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: { quoteId: string },
        private _dialogRef: MatDialogRef<QuoteCopyOptionsDialogComponent>) { }

    public onClose(emit: boolean): void {
        this._dialogRef.close(emit? this.quoteCopyOptions: null);
    }
}