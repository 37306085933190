<div class="template-content-collection-card-container"
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="15px">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="18px">
        <span>{{ currentPage }}</span>

        <button mat-mini-fab
            *ngIf="canChangePage"
            (click)="changePage(-1)"
            [disabled]="!hasPreviousPage">

            <mat-icon>chevron_left</mat-icon>
        </button>

        <div fxLayoutAlign="start center" fxLayoutGap="8px">
            <app-custom-icon *ngIf="icon" [icon]="icon"></app-custom-icon>
            <span>{{ title }}</span>
        </div>

        <button mat-mini-fab
            *ngIf="canChangePage"
            (click)="changePage(1)"
            [disabled]="!hasNextPage">

            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>

    <template-content-card [level]="1"
        [template]="editField ? editField : fields[page]"
        [allowEdit]="allowEdit"
        [isEditMode]="editField"
        [isLoading]="isLoading"
        (editStart)="editStart.emit($event)"
        (editSave)="editSave.emit($event)"
        (editCancel)="editCancel.emit()">
    </template-content-card>
</div>
