<div class="customer-geo-coordinate-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1%">
    <div class="customer-geo-coordinate-google-map-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1%">
        <div>
            {{ 'shared.customerGeoCoordinateMap.mapInstructions' | translate }}
        </div>
        <google-map (mapClick)="addMarker($event)" [width]="1000" [height]="500" [center]="center" [zoom]="12"
            [options]="options">

            <map-marker [position]="center" [title]="'job site'" [options]="{ icon: '../../../../../../assets/images/site_icon_s.png' }">
            </map-marker>

            <map-marker *ngIf="showMarker" [position]="addressLocation" [title]="'job site'">
            </map-marker>

        </google-map>
    </div>
    <div class="customer-geo-coordinate-navigation-buttons-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1%">
        <div fxLayout fxLayoutAlign="center center" fxLayoutGap="10px">

            <button mat-raised-button (click)="toggleMarker(false)">
                {{ 'shared.customerGeoCoordinateMap.buttons.hideMarker' | translate }}
            </button>
            <button mat-raised-button (click)="toggleMarker(true)">
                {{ 'shared.customerGeoCoordinateMap.buttons.showMarker' | translate }}
            </button>
            <button mat-raised-button (click)="deleteMarker()">
                {{ 'shared.customerGeoCoordinateMap.buttons.deleteMarker' | translate }}
            </button>
        </div>
        <div>
            {{ 'shared.customerGeoCoordinateMap.geoCode' | translate }}: {{resolvedAddress?.geocode}}
        </div>
        <div>
            {{ 'shared.customerGeoCoordinateMap.address' | translate }}: {{resolvedAddress?.fullAddress}}
        </div>
        <div fxLayout fxLayoutAlign="center center" fxLayoutGap="10px">
            <button mat-raised-button (click)="selectSite()">
                {{ 'shared.customerGeoCoordinateMap.buttons.selectSite' | translate }}
            </button>
            <button mat-raised-button (click)="addressSelected.emit(null)">
                {{ 'shared.customerGeoCoordinateMap.buttons.back' | translate }}
            </button>
        </div>
    </div>
</div>
