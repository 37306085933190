export enum WritePermission {
    UploadInvoice = 0,
    SwapLeakAndInHouseSalesProjects = 1,
    UpdateProject = 2,
    DispatchProject = 3,
    UploadBudget = 4,
    UnmergeProject = 5,
    DeleteProject = 6,
    MergeProject = 7,
    EditProjectReview = 8,
    ProcessProject = 9,
    UpdateQuote = 10,
    DeleteQuote = 11,
    UpdateComments = 12,
    DeleteComments = 13,
    EditProcessorReview = 14,
    EditBranchInfo = 15,
    EditCrew = 16,
    SendPhotoReportByEmail = 17,
    DeleteMobileScanFile = 18,
    UpdateCustomReportTemplate = 19,
    DeleteCustomReportTemplate = 20,
    EditStockLog = 21,
    UpdateWorkOrderTemplate = 22,
    DeleteWorkOrderTemplate = 23,
    UpdateDisclaimer = 24,
    ChangeServiceProvider = 25,
    UpdateDynamicsHoldStatus = 26,
    UpdateServiceRates = 27
}
