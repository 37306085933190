import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import UserInfo from '../../../../core/data-models/user-info';
import UserComment from '../../../../core/data-models/user-comment/user-comment';
import TimeUtility from '../../../../core/services/utility/time-utility/time-utility.service';
import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog-component/confirm-dialog.component';
import BranchTimezone from '../../../../core/data-models/branch-timezone';

@Component({
    selector: 'user-comment-card',
    styleUrls: ['./user-comment-card.component.scss'],
    templateUrl: './user-comment-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCommentCardComponent {
    @Input() public user: UserInfo;
    @Input() public comment: UserComment;
    @Input() public color = 'var(--context-colors-info)';
    @Input() public isReadonly = false;
    @Input() public canEditComment = false;
    @Input() public canDeleteComment = false;
    @Input() public branchTimeZone: BranchTimezone;
    @Output() public commentEditStart = new EventEmitter<UserComment>();
    @Output() public commentEditEnd = new EventEmitter<UserComment>();
    @Output() public commentEditSave = new EventEmitter<string>();
    @Output() public commentDeleted = new EventEmitter();
    private _isEditMode = false;

    constructor(private _dialog: MatDialog, private _translate: TranslateService) { }

    get isEditMode(): boolean {
        return this._isEditMode;
    }

    get canEdit(): boolean {
        return this.canEditComment && this.user.username === this.comment.creator.userName;
    }

    get canDelete(): boolean {
        return this.canDeleteComment && this.user.username === this.comment.creator.userName;
    }

    get showActions(): boolean {
        return !this.isEditMode && !this.isReadonly && (this.canEdit || this.canDelete);
    }

    get initials(): string {
        const { preferredName } = this.comment.creator;

        if (!preferredName?.trim()) {
            return 'N/A';
        }

        const names = preferredName.split(' ');
        const lastNameInitial = names.length < 2 ? '' : names.slice(-1)[0][0];

        return (names[0][0] + lastNameInitial).toUpperCase();
    }

    get lastHistory(): string {
        const lastHistory = this.comment.histories.slice(-1)[0];
        const date = new Date(lastHistory.modifiedTime);

        const dateString = TimeUtility.toBranchLocalDateTime(date, this.branchTimeZone);
        const params = {
            dateTime: dateString,
            name: lastHistory.performer.preferredName
        };

        return this._translate.instant('shared.userComments.card.editHistory', params);
    }

    public getCreationTime(isRelative = true): string {
        const date = new Date(this.comment.timeInfo.createdTime);

        return isRelative ? TimeUtility.toRelativeTime(date) : TimeUtility.toBranchLocalDateTime(date, this.branchTimeZone);
    }

    public onEditStart(): void {
        this._isEditMode = true;
        this.commentEditStart.emit(this.comment);
    }

    public onEditSave(content: string): void {
        this._isEditMode = false;
        this.commentEditEnd.emit(this.comment);
        this.commentEditSave.emit(content);
    }

    public onEditCancel(): void {
        this._isEditMode = false;
        this.commentEditEnd.emit(this.comment);
    }

    public async onDelete(): Promise<void> {
        const title = this._translate.instant('shared.userComments.card.areYouSure');
        const message = this._translate.instant('shared.userComments.card.commentWillBePermanentlyDeleted');
        const proceed = this._translate.instant('shared.userComments.card.proceed');
        const cancel = this._translate.instant('shared.cancel');
        const data = new ConfirmDialogOption(title, message, true, proceed, cancel);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (await dialog.afterClosed().toPromise()) {
            this.commentDeleted.emit();
        }
    }
}
