import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import ProjectGeneralInfo from '../../../core/data-models/project-general-info/project-general-info';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { QuotePrint } from '../../../core/enums/quote-print.enum';
import ActionMenuItemOption from '../../../core/data-models/action-menu-item-option';
import { ReportHttpService } from '../../../core/services/http/report-http/report-http.service';
// eslint-disable-next-line max-len
import { ConditionReportOptionsDialogComponent } from '../../../shared/components/dialogs/condition-report-options-dialog/condition-report-options-dialog.component';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';


@Component({
    selector: 'quote-report-details',
    styleUrls: ['./quote-report-details.component.scss'],
    templateUrl: './quote-report-details.component.html',
    encapsulation: ViewEncapsulation.None
})
export class QuoteReportDetailsComponent implements OnInit {
    @Input() public activeQuoteId: string;
    public activeSection = 'basicInfo';
    private _generalInfo: ProjectGeneralInfo;
    private _originProjectId = '';
    private _printOptions: ActionMenuItemOption[] = [];

    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
                private _quoteHttp: QuoteHttpService,
                private _projectHttp: ProjectHttpService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _reportService: ReportHttpService,
                private _newrelicService: NewRelicUtilityService,
                private _downloadHttp: DownloadHttpService) { }

    get printOptions(): ActionMenuItemOption[] {
        return this._printOptions;
    }

    get generalInfo(): ProjectGeneralInfo {
        return this._generalInfo;
    }

    public async ngOnInit(): Promise<void> {
        const quote = await this._quoteHttp.getQuoteGeneralInfo(this.activeQuoteId);
        this._originProjectId = quote?.basicInfo?.originProjectId;

        if (ProjectIdUtility.isEmergencyQuote(this._originProjectId)) {
            this._generalInfo = await this._projectHttp.getProjectGeneralInfo(this._originProjectId, -1);
        }

        this._printOptions = await this.getAllowedPrintOptions();
    }

    public async onPushToDetails(): Promise<void> {
        const message = this.translate.instant('quote.report.overwrittenMessage');
        const areYouSure = this.translate.instant('quote.report.areYouSure');
        const proceed = this.translate.instant('quote.report.proceed');
        const cancel = this.translate.instant('shared.cancel');
        const successfullyPushed = this.translate.instant('quote.report.successfullyPushed');
        const failedToPush = this.translate.instant('quote.report.failedToPush');
        const ok = this.translate.instant('snackbars.ok');

        const data = new ConfirmDialogOption(areYouSure, message, true, proceed, cancel);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (!await dialog.afterClosed().toPromise()) {
            return;
        }

        if (await this._quoteHttp.updateDetailsFromEmergencyQuote(this._originProjectId, this.activeQuoteId)) {
            this._snackBar.open(successfullyPushed, ok);
        }
        else {
            this._snackBar.open(failedToPush, ok);
        }
    }

    public async onPrintOptionSelect(action: QuotePrint): Promise<void> {
        this._newrelicService.addPageAction('', '', 'QuoteDetails', { PrintOption: action });

        if (action === QuotePrint.ConditionReport) {
            await this.onConditionReportSelect();
        }
    }

    private async getAllowedPrintOptions(): Promise<ActionMenuItemOption[]> {
        const options = await this._quoteHttp.getEmergencyQuoteReportPrintingOptions(this.activeQuoteId);

        const optionsMapping = [
            { type: QuotePrint.ConditionReport, isAllowed: options.hasConditionReport }
        ];

        return optionsMapping.filter(_ => _.isAllowed).map(_ => {
            const label = this.translate.instant(_.type);

            return new ActionMenuItemOption(_.type, null, null, null, label);
        });
    }

    private async onConditionReportSelect(): Promise<void> {
        const options = await this._reportService.getConditionReportInitialOptions(this._originProjectId);
        if (!options) {

            const failedToGetQuoteSectionDetails = this.translate.instant('quote.print.failedToGetConditionReportOptions');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(failedToGetQuoteSectionDetails, close);
            return;
        }
        const dialog = this._dialog.open(ConditionReportOptionsDialogComponent, {
            data: options,
            minWidth: '800px',
            height: '650px',
            maxWidth: '80vw',
            maxHeight: '80vh'
        });
        const confirmedOptions = await dialog.afterClosed().toPromise();
        if (!confirmedOptions) {
            return;
        }
        const blob = await this._reportService.getConditionReportPdf(confirmedOptions);
        if (!blob) {

            const failedToGenerateConditionReport = this.translate.instant('quote.print.failedToGenerateConditionReport');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(failedToGenerateConditionReport, close);
            return;
        }
        this._downloadHttp.downloadBlob(blob, `ConditionReport_${this.activeQuoteId}.pdf`);
    }
}
