import Customer from './customer';

export default class CustomerDto {
    customerId: string;
    name: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    phone: string;
    faxNumber: string;
    currency: string;
    dataUniversalNumberingSystemNumber: string;
    proposedBusiness: string;
    customerClass: string;

    constructor(customer: Customer) {
        this.customerId = customer.customerId;
        this.name = customer.name;
        this.address1 = customer.address1;
        this.address2 = customer.address2;
        this.city = customer.city;
        this.state = customer.state;
        this.country = customer.country;
        this.zip = customer.zip;
        this.phone = customer.phone;
        this.faxNumber = customer.faxNumber;
        this.currency = customer.currency;
        this.dataUniversalNumberingSystemNumber = customer.dataUniversalNumberingSystemNumber;
        this.proposedBusiness = customer.proposedBusiness;
        this.customerClass = customer.customerClass;
    }

}
