import { Component, Input, OnInit } from '@angular/core';
import CustomerSelectionDto from '../../../core/data-models/customer-selection-dto';
import QuoteGeneralInfo from '../../../core/data-models/quote-general-info/quote-general-info';
import QuoteSaveContext from '../../../core/data-models/quote-save-context';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';

@Component({
    selector: 'app-quote-pdf-preview',
    styleUrls: ['./quote-pdf-preview.component.scss'],
    templateUrl: './quote-pdf-preview.component.html'
})
export class QuotePdfPreviewComponent implements OnInit {
    @Input() public activeQuoteId: string;
    @Input() public quote: QuoteGeneralInfo;
    @Input() public customerSelection: CustomerSelectionDto;
    public pdfUrl: string;
    public isLoaded = false;
    private pdfBlob: Blob;
    private _saveAllowed = true;

    get saveAllowed(): boolean {
        return this._saveAllowed;
    }

    public constructor(private _quoteService: QuoteHttpService,
        private _downloadHttp: DownloadHttpService) {
    }

    public async ngOnInit(): Promise<void> {
        if (!this.quote) {
            this.quote = await this._quoteService.getQuoteGeneralInfo(this.activeQuoteId);
            await this.initFromActiveQuote();
        }
        else {
            await this.initFromQuoteContext();
        }
    }

    public downloadFile(): void {
        if (this.pdfBlob) {
            const fileName = GenericUtilityService.getQuotePdfNameByQuoteBasicInfo(this.quote.basicInfo);
            this._downloadHttp.downloadBlob(this.pdfBlob, fileName);
        }
    }

    private async initFromActiveQuote(): Promise<void> {
        const url = this._quoteService.getQuotePdfUrl(this.activeQuoteId);
        this.pdfBlob = await this._downloadHttp.getUrlAsBlob(url, 'application/pdf');
        if (this.pdfBlob) {
            this.pdfUrl = URL.createObjectURL(this.pdfBlob);
        }
        this.isLoaded = true;
    }

    private async initFromQuoteContext(): Promise<void> {
        this._saveAllowed = false;
        const context = new QuoteSaveContext(this.quote, this.customerSelection);
        this.pdfBlob = await this._quoteService.getQuotePdfPreview(context);
        if (this.pdfBlob) {
            this.pdfUrl = URL.createObjectURL(this.pdfBlob);
        }
        this.isLoaded = true;
    }
}
