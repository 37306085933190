import { Component, AfterViewInit, OnDestroy, ViewChild, Input, Output, ElementRef, EventEmitter, ViewEncapsulation } from '@angular/core';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';

import CheckboxList from '../../../core/data-models/checkbox-list';
import CheckboxData from '../../../core/data-models/checkbox-data';

@Component({
    selector: 'summary-list-filter',
    styleUrls: ['./summary-list-filter.component.scss'],
    templateUrl: './summary-list-filter.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SummaryListFilterComponent implements AfterViewInit, OnDestroy {
    @Input() filterConfigs: CheckboxList[] = [];
    @Input() filterCheckBox: CheckboxData;
    @Input() showBorderLine? = true;
    @Output() onKeyWordFilterSearch = new EventEmitter<string>();
    @Output() onFilterDropDownChecked = new EventEmitter<CheckboxList>();
    @Output() onCheckBoxStatusToggled = new EventEmitter<CheckboxData>();
    @ViewChild('listKeyWordSearchFilter') public listKeyWordSearchFilter: ElementRef;

    public readonly chipItemColors = [
        'summary-list-chip-color-first',
        'summary-list-chip-color-second',
        'summary-list-chip-color-third',
        'summary-list-chip-color-forth',
        'summary-list-chip-color-fifth'
    ];

    private searchFilterSubscription: Subscription;
    private _hasSearchText = false;

    get hasSearchText(): boolean {
        return this._hasSearchText;
    }

    get filterFlexValue(): number {
        return Math.floor(80 / this.filterConfigs.length - 2);
    }

    public ngAfterViewInit(): void {
        // eslint-disable-next-line
        const inputSearchFilter = fromEvent<any>(this.listKeyWordSearchFilter.nativeElement, 'keyup')
            .pipe(
                tap(event => {
                    this._hasSearchText = Boolean(event.target.value);
                }),
                map(event => event.target.value),
                distinctUntilChanged()
            );

        this.searchFilterSubscription = inputSearchFilter.subscribe((_: string) => {
            this.onKeyWordFilterSearch.emit((_ ?? '').trim());
        });
    }

    public ngOnDestroy(): void {
        // eslint-disable-next-line
        this.searchFilterSubscription?.unsubscribe();
    }

    public clearSearch(): void {
        this.listKeyWordSearchFilter.nativeElement.value = null;
        this.listKeyWordSearchFilter.nativeElement.dispatchEvent(new Event('keyup'));
    }
}
