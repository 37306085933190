import { BudgetRecordPayload } from '../../../flynn-budget-tool/core/data-models/budget-estimations/budget-record-payload';
import { ServiceOperationsBudgetData } from '../../../flynn-budget-tool/core/data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-budget-data';

import QuoteGeneralInfo from './quote-general-info/quote-general-info';
import CustomerSelectionDto from './customer-selection-dto';
import PendingQuoteAttachments from './quote-options/pending-quote-attachments';

export default class QuoteSaveContext {
    public quote: QuoteGeneralInfo;
    public customerSelection: CustomerSelectionDto;
    public pendingQuoteAttachments = new PendingQuoteAttachments();
    public webBudgetPayload: BudgetRecordPayload<ServiceOperationsBudgetData> | null;
    public parentQuoteId = '';

    constructor(quote: QuoteGeneralInfo, customerSelection: CustomerSelectionDto, pendingQuoteAttachments = new PendingQuoteAttachments()) {
        this.quote = quote;
        this.customerSelection = customerSelection;
        this.pendingQuoteAttachments = pendingQuoteAttachments;
    }
}
