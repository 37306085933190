import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'app-editable-text-display',
    styleUrls: ['./editable-text-display.component.scss'],
    templateUrl: './editable-text-display.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableTextDisplayComponent {
    @Input() public text: string;
    @Output() public edit = new EventEmitter<string>();
    @ViewChild('editBox') private _editBox: ElementRef;
    public isEditing = false;

    public onEditStart(): void {
        this.isEditing = true;
        /* istanbul ignore next */
        setTimeout(() => this._editBox?.nativeElement?.focus());
    }

    public onEditEnd(text: string): void {
        this.isEditing = false;
        this.edit.emit(text);
    }
}
