import JobContactInfo from './job-contact-info';

export default class DispatchExistingOptions {
    public estimatedHours: number;
    public siteContact: JobContactInfo;
    public isHandOffMeetingRequired: boolean;
    public projectId: string;
    public customerId: string;
    public siteId: string;
    public lastJobId: number;
    public branch: string;
    public projectName: string;
    public dispatcherNote: string;
    public isDispatch: boolean;
    public crewId?: number;
    public isNotEditable: boolean;
    public showProjectLink: boolean;
    public crewName?: string;
    public isScheduleBoardDispatch?: boolean;
}
