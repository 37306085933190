import { Injectable } from '@angular/core';

import { UserHttpService } from '../http/user-http/user-http.service';
import { UserPermissionType } from '../../enums/user-permission-type.enum';
import { ReadPermission } from '../../enums/read-permission.enum';
import { WritePermission } from '../../enums/write-permission.enum';
import { CreatePermission } from '../../enums/create-permission.enum';

@Injectable({
    providedIn: 'root'
})
export class UserPermissionService {

    constructor(private _userHttp: UserHttpService) { }

    public async hasAdminPrivilege(): Promise<boolean> {
        const { groups } = await this._userHttp.getUserInfo();

        return groups.includes('admin');
    }

    public async hasReadPermission(
        code: ReadPermission,
        parameters: { [key: string]: string } = null,
        checkAdmin = true
    ): Promise<boolean> {
        return await this.isActionPermitted(UserPermissionType.Read, code, parameters, checkAdmin);
    }

    public async hasWritePermission(
        code: WritePermission,
        parameters: { [key: string]: string } = null,
        checkAdmin = true
    ): Promise<boolean> {
        return await this.isActionPermitted(UserPermissionType.Write, code, parameters, checkAdmin);
    }

    public async hasCreatePermission(code: CreatePermission): Promise<boolean> {
        return await this.isActionPermitted(UserPermissionType.Create, code);
    }

    //eslint-disable-next-line
    public async isActionPermitted(
        type: UserPermissionType,
        code: number,
        parameters: { [key: string]: string } = null,
        checkAdmin = true
    ): Promise<boolean> {
        try {
            if (checkAdmin && await this.hasAdminPrivilege()) {
                return true;
            }

            const { permissions } = await this._userHttp.getUserInfo();
            const permission = permissions.find(_ => _.type === type && _.actionCode === code);
            const permissionParameters = Object.keys(permission?.parameters ?? {});

            if (!parameters || permissionParameters.length === 0) {
                return Boolean(permission);
            }

            if (!permission || permissionParameters.length !== Object.keys(parameters).length) {
                return false;
            }

            for (let i = 0; i < permissionParameters.length; i++) {
                const value = parameters[permissionParameters[i]];
                const permissionValues = permission.parameters[permissionParameters[i]];

                if (permissionValues.length && !(value && permissionValues.some(_ => _ === value))) {
                    return false;
                }
            }

            return true;
        }
        catch {
            return false;
        }
    }
}
