<dialog-action-card class="process-project-dialog-container"
    [icon]="'verified'"
    [title]="title"
    [isLoaded]="isReady"
    (close)="onClose(false)">

    <div class="process-project-dialog-content" fxLayout="column" fxLayoutGap="15px">
        <mat-checkbox [(ngModel)]="hasInvoice" [disabled]="!useErpInvoice">{{ 'projects.processProjectDialog.hasInvoice' | translate }}</mat-checkbox>
        <mat-checkbox *ngIf="showTransfer" [(ngModel)]="isTransfer">
            {{ 'projects.processProjectDialog.jobCancelled' | translate }}
        </mat-checkbox>
        <invoice-upload
            (invoiceFileSelected)="invoiceFile = $event"
            [hasInvoice]="hasInvoice"
            [invoiceNumber]="invoiceNumber"
            [draftNumber]="draftNumber"
            [grossAmount]="grossAmount">
        </invoice-upload>
        <mat-form-field *ngIf="showTransfer && isTransfer">
            <mat-label>{{ 'projects.processProjectDialog.transferProject' | translate }}</mat-label>
            <input type="text" matInput [formControl]="transferId" required>
            <mat-error *ngIf="transferId.invalid">{{ 'projects.processProjectDialog.requiredFieldSmallText' | translate }}</mat-error>
        </mat-form-field>
        <p class="dynamics-hold-warning" *ngIf="showOnHoldWarning">{{ 'projects.processProjectDialog.dynamicsNotAvailable' | translate }}</p>
    </div>

    <div fxLayout="row" class="process-project-dialog-actions" fxLayoutAlign="end center">
        <button
            mat-raised-button
            color="primary"
            [disabled]="!enableProcess"
            (click)="onClose()">
            {{ 'projects.processProjectDialog.process' | translate }}
        </button>
    </div>

</dialog-action-card>
