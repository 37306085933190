import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    ViewChild,
    ElementRef,
    OnDestroy,
    EventEmitter,
    ViewEncapsulation,
    ChangeDetectorRef
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, switchMap, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import UserInfo from '../../core/data-models/user-info';
import ProjectAccessEventData from '../../core/data-models/client-events/project-access-event-data';
import NavigationTab from '../../core/data-models/navigation-tab';
import ProjectSummary from '../../core/data-models/summary-info/project-summary';
import SummaryQuery from '../../core/data-models/summary-info/summary-query';
import SummaryQueryResult from '../../core/data-models/summary-info/summary-query-result';
import CheckboxList from '../../core/data-models/checkbox-list';
import CheckboxData from '../../core/data-models/checkbox-data';
import ProjectIdUtility from '../../core/services/utility/project-id-utility/project-id-utility.service';
import { UserHttpService } from '../../core/services/http/user-http/user-http.service';
import { ProjectHttpService } from '../../core/services/http/project-http/project-http.service';
import { AppEventService } from '../../core/services/events/app-event/app-event.service';
import { BranchHttpService } from '../../core/services/http/branch-http/branch-http.service';
import { AppConfigHttpService } from '../../core/services/http/appconfig-http/appconfig-http.service';
import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import { SalesCodeConfigService } from '../../core/services/sales-code-config/sales-code-config.service';
import { ProjectStatusHttpService } from '../../core/services/http/project-status-http/project-status-http.service';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';
import LanguageUtility from '../../core/services/utility/language-utility/language-utility.service';
import { ReadPermission } from '../../core/enums/read-permission.enum';
import ProjectStatus from '../../core/data-models/project-status';
import { WorkCategory } from '../../core/data-models/work-category';

@Component({
    selector: 'project',
    styleUrls: ['./project.component.scss'],
    templateUrl: './project.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public activeProjectId = '';
    @ViewChild('matTabNavBar', { read: ElementRef }) public matTabNavBar: ElementRef;
    public visibleTabs: NavigationTab[] = [];
    public hiddenTabs: NavigationTab[] = [];
    public notaRoofIssueCheckBox: CheckboxData;
    public query = { currentPage: 1, pageSize: 5 } as SummaryQuery;
    public queryResult: SummaryQueryResult<ProjectSummary> = null;
    public hasTargetSummary = false;
    public summaryListExpanded = true;
    public isSummaryListLoading = false;
    public filterConfiguration: CheckboxList[] = [];
    public loadSummariesChange = new EventEmitter<number>();
    private _isInternalWorkCategory = false;
    private readonly _expandDuration = 750;
    private readonly _updatedTabs = new Set<NavigationTab>();
    private _subscriptions = new Subscription();
    private _expandTimer: number;
    private _user: UserInfo;
    private _projectStatuses: ProjectStatus[] = [];
    private _tabs: NavigationTab[] = [];
    private _canViewBranchFilter = false;
    private _isComponentActive = true;

    //eslint-disable-next-line
    constructor(private _userHttp: UserHttpService,
                private _projectHttp: ProjectHttpService,
                private _branchHttp: BranchHttpService,
                private _appConfigHttp: AppConfigHttpService,
                private _projectStatusHttp: ProjectStatusHttpService,
                private _userPermissionService: UserPermissionService,
                private _state: StateService,
                private _appEvents: AppEventService,
                private _salesCodeConfigService: SalesCodeConfigService,
                private _featureFlagService: FeatureFlagService,
                private _cdr: ChangeDetectorRef,
                public translate: TranslateService) { }

    get summaryListStyle(): { [key: string]: string } {
        return { transition: `width ${this._expandDuration / 1000}s` };
    }

    get updatedTabs(): Set<NavigationTab> {
        return this._updatedTabs;
    }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    public async ngOnInit(): Promise<void> {
        this.setupLoadSummariesChange();
        this._canViewBranchFilter = await this._userPermissionService.hasReadPermission(ReadPermission.ViewBranchFilter);
        this._user = await this._userHttp.getUserInfo();
        this._projectStatuses = await this._projectStatusHttp.getFilterableProjectStatus();
        await this.loadSummaryListFilters(this._user.language);

        if (this.activeProjectId) {
            this._isInternalWorkCategory = await this._salesCodeConfigService.getWorkCategoryByProjectId(this.activeProjectId) === WorkCategory.InternalJob;
            await this.loadTargetSummary(this.activeProjectId);
            await this.loadNavigationTabs(this.activeProjectId);
            this.syncFilterListsWithProjectQuery();
        }
        else {
            this.syncFilterListsWithProjectQuery();
            this.loadSummaries(1);
        }

        this._subscriptions.add(this._appEvents.serverConnectionChanged.pipe(filter(Boolean)).subscribe(() => this.notifyProjectOpen(this.activeProjectId)));
        this._subscriptions.add(this._appEvents.projectCreated.subscribe(() => this.loadSummaries(1)));
        this._subscriptions.add(this._appEvents.projectUpdated.subscribe(_ => this.onProjectUpdated(..._)));
        this._subscriptions.add(this._appEvents.reloadTabs.subscribe(() => this.loadNavigationTabs(this.activeProjectId)));
        this._subscriptions.add(this._appEvents.reloadProjectCounter.subscribe(() => this.loadCounters(false)));
        this.ensureNotifyProjectOpen();
    }

    public ngAfterViewInit(): void {
        this.resizeTabs(this.matTabNavBar);
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
        this._subscriptions.unsubscribe();
        this.notifyProjectClose(this.activeProjectId);
    }

    private setupLoadSummariesChange(): void {
        this.loadSummariesChange.pipe(
            debounceTime(650),
            switchMap(page => {
                this.isSummaryListLoading = true;
                this.query.currentPage = page;

                return this._projectHttp.getSummaries(this.query);
            })
        ).subscribe(summaries => {
            const { result } = summaries;
            this.queryResult = summaries;
            this.queryResult.result = result.map(_ => this.updateStatusText(this._user, this._projectStatuses, _));
            this.isSummaryListLoading = false;
        });
    }

    private async loadSummaryListFilters(language: string): Promise<void> {
        if (this.isInternal) {
            const text = this.translate.instant('projects.notRoofIssue');
            this.notaRoofIssueCheckBox = new CheckboxData('notaRoofIssue', false, false, '', text);
        }

        const salesCodes = await this._appConfigHttp.getProjectJobTypesInProjectSummaryFilter();
        const statusOptions = this.getStatusFilterOptions(this._user);

        const codesOptions = salesCodes.map(_ => ({
            name: _,
            displayText: `${_} ${this._salesCodeConfigService.getTypeText(_, language)}`
        }));

        const statusLabel = this.translate.instant('projects.nav.status');
        const statusPlaceholder = this.translate.instant('projects.filters.status');
        const salesCodeLabelKey = this.isInternal ? 'projects.nav.salesCodes' : 'projects.nav.projectTypes';
        const salesCodesLabel = this.translate.instant(salesCodeLabelKey);
        const salesCodeFilterKey = this.isInternal ? 'projects.filters.salesCode' : 'projects.filters.projectType';
        const salesCodePlaceholder = this.translate.instant(salesCodeFilterKey);

        this.filterConfiguration = [
            new CheckboxList('Status', statusPlaceholder, statusOptions, statusLabel),
            new CheckboxList('Sales Codes', salesCodePlaceholder, codesOptions as CheckboxData[], salesCodesLabel)
        ];

        if (this._canViewBranchFilter) {
            const branches = await this._branchHttp.getAllBranches();
            const branchOptions = branches.map(_ => new CheckboxData(_.name, _.name === this._user.branch));
            const branchKeyVariant = this.isInternal ? 'branch' : 'serviceProvider';
            const branchLabel = this.translate.instant(`projects.nav.${branchKeyVariant}`);
            const branchPlaceholder = this.translate.instant(`projects.filters.${branchKeyVariant}`);
            this.filterConfiguration.unshift(new CheckboxList('Branch', branchPlaceholder, branchOptions, branchLabel));
        }
    }

    private getStatusFilterOptions(user: UserInfo): CheckboxData[] {
        return this._projectStatuses.map(_ => {
            const displayText = LanguageUtility.getLocalizedContent(user.language, _.names);
            const isChecked = user.defaultStatus?.toLowerCase() === displayText.toLowerCase();

            return new CheckboxData(`${_.code}`, isChecked, false, '', displayText);
        }).sort((a, b) => a.displayText.localeCompare(b.displayText));
    }


    // eslint-disable-next-line complexity
    private async loadNavigationTabs(projectId: string): Promise<void> {
        this._tabs = [
            new NavigationTab('Details', null, null, '.details', this.translate.instant('projects.tabs.details')),
            new NavigationTab('Photos', null, null, '.photos', this.translate.instant('projects.tabs.photos')),
            new NavigationTab('Videos', null, null, '.videos', this.translate.instant('projects.tabs.videos')),
            new NavigationTab('Drawings', null, null, '.drawings', this.translate.instant('projects.tabs.drawings')),
            new NavigationTab('Attachments', null, null, '.attachments', this.translate.instant('projects.tabs.attachments'))
        ];

        const isUSProjectId = ProjectIdUtility.isUsProject(projectId);
        const isEmergency = ProjectIdUtility.isEmergency(projectId);
        const { invoiceViewer: { isAllowed: hasInvoiceViewer } } = await this._projectHttp.getProjectActionOptions(this.activeProjectId);
        const summary = this.queryResult?.result?.find(_ => _.projectIdDBformat === projectId);
        const useSafety = this._isInternalWorkCategory && this._featureFlagService.featureFlags[FeatureFlags.UseSafetyCheckFeature];
        const useQuote = this._featureFlagService.featureFlags[FeatureFlags.UseQuotesFeature];
        const useInvoice = hasInvoiceViewer && await this._userPermissionService.hasReadPermission(ReadPermission.ReadInvoice);
        const useAttributes = this.isInternal && !isEmergency && (this._isInternalWorkCategory || isUSProjectId);
        const useExecutiveSummary = this.isInternal && !isEmergency && this._salesCodeConfigService.isAudit(projectId);
        const { useLegacyBudgetTool } = await this._featureFlagService.evaluateUseLegacyBudgetToolByProjectId(projectId);
        const useBudgetFeature = this._featureFlagService.featureFlags[FeatureFlags.UseBudgetFeature];
        const useBudget = useBudgetFeature && (summary?.budgetUploaded || !useLegacyBudgetTool);

        if (useSafety) {
            const label = this.translate.instant('projects.tabs.safety');
            this._tabs.push(new NavigationTab('Safety', null, null, '.safety', label));
        }

        if (useQuote) {
            const label = this.translate.instant('projects.tabs.quotes');
            this._tabs.push(new NavigationTab('Quotes', null, null, '.quotes', label));
        }

        if (this.isInternal) {
            const label = this.translate.instant('projects.tabs.history');
            this._tabs.push(new NavigationTab('History', null, null, '.history', label));
        }

        if (useInvoice) {
            const label = this.translate.instant('projects.tabs.invoice');
            this._tabs.push(new NavigationTab('Invoice', null, null, '.invoice', label));
        }

        if (useAttributes) {
            const label = this.translate.instant('projects.tabs.attributes');
            this._tabs.push(new NavigationTab('Attributes', null, null, '.attributes', label));
        }

        if (useExecutiveSummary) {
            const label = this.translate.instant('projects.tabs.executiveSummary');
            this._tabs.push(new NavigationTab('Executive Summary', null, null, '.executiveSummary', label));
        }

        if (useBudget) {
            const label = this.translate.instant('projects.tabs.budget');
            this._tabs.push(new NavigationTab('Budget', null, null, '.budget', label));
        }

        this.resizeTabs(this.matTabNavBar);
        await this.loadCounters();
    }

    private async loadTargetSummary(projectId: string): Promise<void> {
        this.isSummaryListLoading = true;
        this.query.currentPage = 1;
        this.query.branch = this._canViewBranchFilter ? [] : [this._user.branch];
        this.queryResult = await this._projectHttp.getSummaries({ ...this.query, searchText: projectId });
        this.queryResult.result = this.queryResult.result.map(_ => this.updateStatusText(this._user, this._projectStatuses, _));
        this.ensureValidTargetSummary();
        this.isSummaryListLoading = false;
        this.hasTargetSummary = true;
    }

    private ensureValidTargetSummary(): void {
        const { result } = this.queryResult;
        const id = ProjectIdUtility.toStorageFormat(this.activeProjectId);

        if (result.length !== 1 || result[0].projectIdDBformat !== id) {
            this.queryResult = new SummaryQueryResult<ProjectSummary>();
        }
    }

    private ensureNotifyProjectOpen(): void {
        if (!this._isComponentActive) {
            return;
        }

        setTimeout(() => {
            this.notifyProjectOpen(this.activeProjectId);
            this.ensureNotifyProjectOpen();
        }, 1000 * 60 * 15);
    }

    private syncFilterListsWithProjectQuery(): void {
        this.query.branch = this._canViewBranchFilter ? this.retrieveCheckedFilterItems('Branch') : [this._user.branch];
        this.query.status = this.retrieveCheckedFilterItems('Status');
        this.query.salesCode = this.retrieveCheckedFilterItems('Sales Codes');
    }

    private retrieveCheckedFilterItems(name: string): string[] {
        const selected = this.filterConfiguration.find(_ => _.name === name)?.items ?? [];

        return selected.filter(_ => _.checked).map(_ => _.name);
    }

    private onProjectUpdated(originalId: string, summary: ProjectSummary): void {
        if (summary) {
            this.handleListRefresh(originalId, summary);
            this.handleContentRefresh(originalId, summary);
        }
    }

    private handleListRefresh(originalId: string, summary: ProjectSummary): void {
        const summaries = this.queryResult?.result ?? [];
        const dbFormatId = ProjectIdUtility.toStorageFormat(originalId);
        const index = summaries.findIndex(_ => _.projectIdDBformat === dbFormatId);
        const isSameProject = summary.projectIdDBformat === dbFormatId;
        // when both merge source and merge target are visible in the list
        const shouldRemoveMergeSource = summaries.some(_ => _.projectIdDBformat === summary.projectIdDBformat) && !isSameProject;

        if (index === -1) {
            return;
        }

        if (summary.status === 'Deleted' || shouldRemoveMergeSource) {
            summaries[index] = { ...summaries[index], isRemoved: true };
        }
        else {
            summaries[index] = this.updateStatusText(this._user, this._projectStatuses, summary);
        }
    }

    private updateStatusText(user: UserInfo, statuses: ProjectStatus[], summary: ProjectSummary): ProjectSummary {
        const names = statuses.find(status => status.code === summary.statusCode)?.names ?? [];

        return { ...summary, status: LanguageUtility.getLocalizedContent(user.language, names) };
    }

    private handleContentRefresh(originalId: string, summary: ProjectSummary): void {
        const { projectId, status } = summary;
        const activeId = ProjectIdUtility.toStorageFormat(this.activeProjectId);
        const dbFormatId = ProjectIdUtility.toStorageFormat(originalId);
        const updatedId = ProjectIdUtility.toStorageFormat(projectId);

        if (activeId !== dbFormatId && activeId !== updatedId) {
            return;
        }

        if (status === 'Deleted') {
            this.activeProjectId = '';
            this._state.go('projects');
        }
        else if (dbFormatId !== updatedId) {
            this.openProject(updatedId);
        }
    }

    public loadSummaries(page: number): void {
        this.hasTargetSummary = false;
        this.loadSummariesChange.emit(page);
    }

    public filterSearch(keyWord: string): void {
        this.query.searchText = keyWord;
        this.loadSummaries(1);
    }

    public onNotaRoofIssueCheckBoxToggled(roofIssueBox: CheckboxData) : void {
        this.query.notRoofIssue = roofIssueBox.checked;
        this.loadSummaries(1);
    }

    public filterProjectListByCheckBox(data: CheckboxList): void {
        const { name, items } = data;
        const content = items.filter(_ => _.checked).map(_ => _.name);

        if (name === 'Sales Codes') {
            this.query.salesCode = content;
        }
        else if (name === 'Branch') {
            this.query.branch = content;
        }
        else if (name === 'Status') {
            this.query.status = content;
        }

        this.loadSummaries(1);
    }

    public async openProject(projectId: string): Promise<void> {
        if (projectId === this.activeProjectId) {
            return;
        }

        if (this.activeProjectId) {
            this.notifyProjectClose(this.activeProjectId);
        }

        this.activeProjectId = projectId;
        this.notifyProjectOpen(projectId);
        this._isInternalWorkCategory = await this._salesCodeConfigService.getWorkCategoryByProjectId(this.activeProjectId) === WorkCategory.InternalJob;
        await this.loadNavigationTabs(projectId);
        this._state.go('project', { projectId }, { inherit: false });
    }

    public resizeTabs(element: ElementRef | null = null): void {
        const target = element ?? this.matTabNavBar;

        if (!target) {
            return;
        }
        const iconWidth = 40;
        const offsetWidth = target.nativeElement.offsetWidth;
        const visibleTabs = Math.floor((offsetWidth - iconWidth) / 160);
        this.visibleTabs = this._tabs.slice(0, visibleTabs);
        this.hiddenTabs = this._tabs.slice(visibleTabs);
    }

    public onToggleExpand(expanded: boolean): void {
        if (this._expandTimer) {
            clearTimeout(this._expandTimer);
        }
        this.summaryListExpanded = expanded;
        this._expandTimer = setTimeout(() => this.resizeTabs(this.matTabNavBar), this._expandDuration) as unknown as number;
    }

    public onHiddenTabSelected(state: string): void {
        const index = this.hiddenTabs.findIndex(_ => _.state === state);

        if (index === -1) {
            return;
        }
        const tab = this.hiddenTabs.splice(index, 1)[0];

        if (this.visibleTabs.length) {
            this.hiddenTabs.push(this.visibleTabs.pop());
        }
        this.visibleTabs.push(tab);
    }

    private async loadCounters(skipAnimation = true): Promise<void> {
        if (!this.activeProjectId) {
            return;
        }

        const counters = await this._projectHttp.getProjectCounters(this.activeProjectId);

        const lookup = new Map<string, number>([
            ['Photos', counters.photo],
            ['Videos', counters.video],
            ['Drawings', counters.drawing],
            ['Attachments', counters.attachment],
            ['Quotes', counters.quote]
        ]);

        for (const tab of this._tabs.filter(_ => lookup.has(_.name))) {
            const badgeText = `${lookup.get(tab.name) ?? ''}`;

            if (tab.badge !== badgeText && !skipAnimation) {
                this._updatedTabs.add(tab);
            }

            tab.badge = badgeText;
        }

        setTimeout(() => this._updatedTabs.clear(), 1000);
    }

    private notifyProjectOpen(projectId: string): void {
        if (this._isComponentActive && projectId && this._user) {
            const data = new ProjectAccessEventData(ProjectIdUtility.toStorageFormat(projectId), this._user);
            this._appEvents.notifyProjectAccessClientEvent('projectOpenedByClient', data);
        }
    }

    private notifyProjectClose(projectId: string): void {
        if (projectId && this._user) {
            const data = new ProjectAccessEventData(ProjectIdUtility.toStorageFormat(projectId), this._user);
            this._appEvents.notifyProjectAccessClientEvent('projectClosedByClient', data);
        }
    }
}
