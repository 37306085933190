import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import QuoteTemplate from '../../../core/data-models/quote-template';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import { NamePromptDialogComponent } from '../../../shared/components/dialogs/name-prompt-dialog/name-prompt-dialog.component';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

@Component({
    selector: 'quote-template-management-component',
    styleUrls: ['./quote-template-management.component.scss'],
    templateUrl: './quote-template-management.component.html'
})
export class QuoteTemplateManagementComponent implements OnInit {
    public templates: QuoteTemplate[] = [];
    public template: QuoteTemplate;
    public selectedIndex = 0;
    public loaded = false;
    public disableSelect = false;
    public newTemplateName = '';
    private _isNew = false;
    private _originalContent = '';
    private _originalSpreadsheet = '';

    public get canAddTemplate(): boolean {
        return this.loaded && !this.template && Boolean(this.newTemplateName.trim());
    }

    public get canDeleteTemplate(): boolean {
        return this.loaded && Boolean(this.template);
    }

    public get canEnterTemplateName(): boolean {
        return this.loaded && !this.template;
    }

    public get canCopyTemplate(): boolean {
        return this.loaded && Boolean(this.template) && !this._isNew;
    }

    public get canSave(): boolean {
        return this.loaded && Boolean(this.template);
    }

    private get _templateChanged(): boolean {
        return this.template && (this.template.content !== this._originalContent || this.template.spreadsheetData !== this._originalSpreadsheet);
    }

    public constructor(
        private _quoteHttpService: QuoteHttpService,
        private _snackbar: MatSnackBar,
        private _translate: TranslateService,
        private _dialog: MatDialog,
        private _changeDetector: ChangeDetectorRef
    ) { }

    public async ngOnInit(): Promise<void> {
        await this.loadTemplates();
        this.loaded = true;
    }

    public async loadTemplates(): Promise<void> {
        const result = await this._quoteHttpService.getQuoteTemplates();
        if (result) {
            this.templates = result;
        }
        else {
            this._snackbar.open(this._translate.instant('shared.quote.templateEditor.failedToGetTemplates'), this._translate.instant('shared.ok'));
        }
    }

    public updateContent(content: string): void {
        this.template.content = content;
    }

    public async deleteTemplate(): Promise<void> {
        const title = this._translate.instant('shared.quote.templateEditor.deleteTemplate');
        const message = this._translate.instant('shared.quote.templateEditor.deleteTemplateDesc');
        const yes = this._translate.instant('shared.yes');
        const no = this._translate.instant('shared.no');
        const data = new ConfirmDialogOption(title, message, true, yes, no);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });
        if (!await dialog.afterClosed().toPromise()) {
            return;
        }


        if (this._isNew) {
            this.templates = this.templates.filter(_ => _.title !== this.template.title);
            this.template = null;
            this._snackbar.open(this._translate.instant('shared.quote.templateEditor.successfullyDeletedTemplate'), this._translate.instant('shared.ok'));
            this._isNew = false;
            return;
        }


        const result = await this._quoteHttpService.deleteQuoteTemplate(this.template.id);

        if (result) {
            this._snackbar.open(this._translate.instant('shared.quote.templateEditor.successfullyDeletedTemplate'), this._translate.instant('shared.ok'));
            await this.loadTemplates();
            this.template = null;
        }
        else {
            this._snackbar.open(this._translate.instant('shared.quote.templateEditor.failedToDelete'), this._translate.instant('shared.ok'));
        }
    }

    public addTemplateToList(): void {
        const trimmedLowerNewName = this.newTemplateName.toLowerCase().trim();

        if (this.templates.some(_ => _.title.toLowerCase().trim() === trimmedLowerNewName)) {
            this._snackbar.open(this._translate.instant('shared.quote.templateEditor.nameAlreadyUsed'), this._translate.instant('shared.ok'));
            return;
        }

        const template = new QuoteTemplate(this.newTemplateName, '', '');
        this.templates = [ ...this.templates, template ];
        this.templates.sort((a, b) => a.title.localeCompare(b.title));
        this.newTemplateName = '';
        this.setTemplate(template, true);
    }

    public async copyTemplate(): Promise<void> {
        if (this._templateChanged && !await this.confirmUnsavedChanges()) {
            return;
        }

        const [width, height] = ['400px', '270px'];
        const data = {
            title: this._translate.instant('shared.quote.templateEditor.pleaseEnterName'),
            callback: (name: string): boolean => !this.templates.some(_ => _.title.toLowerCase().trim() === name.toLowerCase().trim()),
            errorMessage: this._translate.instant('shared.quote.templateEditor.nameAlreadyUsed')
        };

        const dialog = this._dialog.open(NamePromptDialogComponent, { data, width, height });
        const name = await dialog.afterClosed().toPromise();

        if (name) {
            const content = this.template.content;
            const spreadsheet = this.template.spreadsheetData;
            this.newTemplateName = name;
            this.addTemplateToList();
            this.template.content = content;
            this.template.spreadsheetData = spreadsheet;
            this.createTemplate();
        }
    }

    public async saveTemplate(): Promise<void> {
        if (this._isNew) {
            await this.createTemplate();
        }
        else {
            await this.updateTemplate();
        }
    }

    public async changeSelection(selection: MatSelectChange): Promise<void> {
        if ((this._isNew || this._templateChanged) && !await this.confirmUnsavedChanges()) {
            return;
        }

        if (this._isNew) {
            this.templates = this.templates.filter(_ => _.title !== this.template.title);
        }

        this.setTemplate(selection.value as QuoteTemplate, false);
    }

    private setTemplate(template: QuoteTemplate, isNew: boolean) {
        this.selectedIndex = 0;
        this.template = template;
        this._originalContent = template?.content;
        this._originalSpreadsheet = template?.spreadsheetData;
        this._isNew = isNew;
    }

    private async confirmUnsavedChanges(): Promise<boolean> {
        const templateNotSaved = this._translate.instant('shared.quote.templateEditor.unsavedChanges');
        const message = this._translate.instant('shared.quote.templateEditor.unsavedChangesDesc');
        const discard = this._translate.instant('shared.quote.templateEditor.discard');
        const cancel = this._translate.instant('shared.quote.templateEditor.cancel');
        const data = new ConfirmDialogOption(templateNotSaved, message, true, discard, cancel);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (!await dialog.afterClosed().toPromise()) {
            const originalTemplate = this.template;
            this.template = null;
            this._changeDetector.detectChanges();
            this.template = originalTemplate;
            return false;
        }

        this.template.content = this._originalContent;
        this.template.spreadsheetData = this._originalSpreadsheet;
        return true;
    }

    private async createTemplate(): Promise<void> {
        this.template.title = this.template.title.trim();
        if (!this.template.title) {
            this._snackbar.open(this._translate.instant('shared.quote.templateEditor.enterTitle'), this._translate.instant('shared.ok'));
            return;
        }

        if (!this.template.content) {
            this._snackbar.open(this._translate.instant('shared.quote.templateEditor.enterContent'), this._translate.instant('shared.ok'));
            return;
        }

        const result = await this._quoteHttpService.createQuoteTemplate(this.template);

        if (result.code === '200') {
            this._snackbar.open(this._translate.instant('shared.quote.templateEditor.successfullyCreatedTemplate'), this._translate.instant('shared.ok'));
            await this.loadTemplates();

            this.template = this.templates.find(_ => _.title === this.template.title);
            this.setTemplate(this.template, false);
        }
        else {
            this._snackbar.open(result.description, this._translate.instant('shared.ok'));
        }
    }

    private async updateTemplate(): Promise<void> {
        const result = await this._quoteHttpService.updateQuoteTemplate(this.template);

        if (result) {
            this._snackbar.open(this._translate.instant('shared.quote.templateEditor.successfullyUpdatedTemplate'), this._translate.instant('shared.ok'));
            this.setTemplate(this.template, false);
        }
        else {
            this._snackbar.open(this._translate.instant('shared.quote.templateEditor.failedToUpdate'), this._translate.instant('shared.ok'));
        }
    }
}
