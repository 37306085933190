import ProjectQuestions from '../project-attributes/project-questions';
import { WorkCategory } from '../work-category';
import DispatchBaseStepConfig from './dispatch-base-step-config';

export default class AdditionalInfoStepConfig extends DispatchBaseStepConfig {
    public projectQuestions: ProjectQuestions;
    public isUsProject = false;
    public isValid = false;

    public constructor(label: string, completed = false, editable = true, hasNext = true, hasPrevious = true) {
        super(label, completed, editable, hasNext, hasPrevious);
        this.workCategory = WorkCategory.InternalJob;
    }
}
