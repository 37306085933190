import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CustomerDisplaySummary from '../../../../core/data-models/customer-selection/customer-display-summary';
import SiteDisplaySummary from '../../../../core/data-models/customer-selection/site-display-summary';
import InformationGridConfig from '../../../../core/data-models/information-grid/information-grid-config';

@Component({
    selector: 'customer-selection-confirm',
    styleUrls: ['./customer-selection-confirm.component.scss'],
    templateUrl: './customer-selection-confirm.component.html'
})

export class CustomerSelectionConfirmComponent {
    @Input() customerInfo: InformationGridConfig<CustomerDisplaySummary>;
    @Input() jobSiteInfo: InformationGridConfig<SiteDisplaySummary>;
    @Input() billingSiteInfo: InformationGridConfig<SiteDisplaySummary>;
    @Output() confirmSummaryDetails = new EventEmitter<boolean>();
    @Output() handleStep = new EventEmitter<string>();

    constructor(public translate: TranslateService) { }
}
