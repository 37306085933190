import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import PromptDialogOption from '../../../../core/data-models/prompt-dialog-option';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-prompt-dialog',
    styleUrls: ['./prompt-dialog.component.scss'],
    templateUrl: './prompt-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PromptDialogComponent implements OnInit {
    public input: UntypedFormControl;
    private _typeHereLabel: string;
    private _requiredFieldLabel: string;

    constructor(private _translate: TranslateService,
        private _dialog: MatDialogRef<PromptDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public option: PromptDialogOption) {
        this._typeHereLabel = this._translate.instant('shared.promptDialog.typeHere');
        this._requiredFieldLabel = this._translate.instant('shared.promptDialog.requiredField');
    }

    get placeholder(): string {
        return this.option.placeholder ? this.option.placeholder : this._typeHereLabel;
    }

    get errorText(): string {
        return this.option.errorText ? this.option.errorText : this._requiredFieldLabel;
    }

    get colorType(): string {
        return this.option.isWarning ? 'warn' : 'primary';
    }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        const validators = [Validators.required, Validators.pattern(/(\S|\w).{8,}(\S|\w)/)];
        this.input = new UntypedFormControl('', validators);
    }

    public onClose(emit = true): void {
        if (!emit) {
            this._dialog.close();
        }
        else if (this.input.valid) {
            this._dialog.close(String(this.input.value).trim());
        }
    }
}
