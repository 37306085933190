import { NgModule } from '@angular/core';
import { DispatchProjectDialogComponent } from './dispatch-project-dialog/dispatch-project-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { ProjectInfoStepComponent } from './dispatch-steps/project-info-step/project-info-step.component';
import { AdditionalInfoStepComponent } from './dispatch-steps/additional-info-step/additional-info-step.component';
import { UsProjectQuestionsStepComponent } from './dispatch-steps/us-project-questions-step/us-project-questions-step.component';
import { HistoryStepComponent } from './dispatch-steps/history-step/history-step.component';
import { BudgetStepComponent } from './dispatch-steps/budget-step/budget-step.component';
import { CustomerSelectionStepComponent } from './dispatch-steps/customer-selection-step/customer-selection-step.component';
import { FastRequestsDialogComponent } from './fast-requests-dialog/fast-requests-dialog.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        DispatchProjectDialogComponent,
        ProjectInfoStepComponent,
        AdditionalInfoStepComponent,
        UsProjectQuestionsStepComponent,
        HistoryStepComponent,
        BudgetStepComponent,
        CustomerSelectionStepComponent,
        FastRequestsDialogComponent
    ]
})
export class DispatchModule { }
