/* eslint-disable @typescript-eslint/no-explicit-any, max-params */
import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateAdapter } from '@angular/material/core';

import { environment } from '../environments/environment';

import UserInfo from './core/data-models/user-info';
import { UserHttpService } from './core/services/http/user-http/user-http.service';
import { ColorThemeService } from './core/services/color-theme/color-theme.service';
import { FeatureFlagService } from './core/services/events/feature-flags/feature-flag.service';
import { SalesCodeConfigService } from './core/services/sales-code-config/sales-code-config.service';
import { ProjectStatusHttpService } from './core/services/http/project-status-http/project-status-http.service';
import { NewRelicUtilityService } from './core/services/utility/new-relic-utility.service';
import { UserPermissionService } from './core/services/user-permission/user-permission.service';
import { ReadPermission } from './core/enums/read-permission.enum';
import { FeatureFlags } from './core/enums/feature-flags.enum';
import { AuthLockService } from './core/services/auth-lock-service/auth-lock-service';
import { DashboardViewDialogComponent } from './shared/components/dialogs/dashboard-view-dialog/dashboard-view-dialog.component';

import * as moment from 'moment-timezone';
import 'moment/locale/fr';
import { AppEventService } from './core/services/events/app-event/app-event.service';
import PhotoReportNotification from './core/data-models/photo-report-notification';
import { ToastrService } from 'ngx-toastr';
import { PhotoReportNotificationComponent } from './shared/components/photo-report-notification/photo-report-notification.component';
import AppNotificationOptions from './core/data-models/app-notification-options';

// global variable used by zendesk widget.
// declared here to tell compiler it exists.
declare const zE: any;

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    private _isReady = false;
    private _userInfo: UserInfo;
    constructor(private _state: StateService,
                private _colorThemeService: ColorThemeService,
                private _dialogService: MatDialog,
                private _userInfoService: UserHttpService,
                private _salesCodeConfigService: SalesCodeConfigService,
                private _featureFlagService: FeatureFlagService,
                private _translate: TranslateService,
                private _projectStatusHttp: ProjectStatusHttpService,
                private _dateAdapter: DateAdapter<any>,
                private _newrelicService: NewRelicUtilityService,
                private _permissionService: UserPermissionService,
                private _authService: AuthLockService,
                private _appEvent: AppEventService,
                private _toastr: ToastrService
    ) { }

    get showAbstractView(): boolean {
        const { name } = this._state.$current;

        return name === 'authError' || name === 'pageNotFound' || name === 'loginPage';
    }

    get showSettingsView(): boolean {
        return /^settings\.\w+$/.test(this._state.$current.name);
    }

    get isReady(): boolean {
        return this._isReady;
    }

    public ngOnInit(): void {
        this.executeZendeskCommand('hide', {});
        this._authService.isAuthenticated.subscribe(async isAuthenticated => {
            if (isAuthenticated) {
                this._userInfo = await this._userInfoService.getUserInfo();

                if (this._userInfo) {
                    await this.initAppAfterLogin(this._userInfo);
                    await this.displayDashboardPopupDate(this._userInfo);
                }
                else {
                    this._state.transition?.abort();
                    this._state.go('authError');
                }
            }
            else {
                this._isReady = false;
            }
        });
        this._appEvent.photoReportGenerated.subscribe(_ => {
            this.showPhotoReportNotification(..._);
        });
    }

    private showPhotoReportNotification(userEmail: string, photoReportNotification: PhotoReportNotification) {
        if (userEmail === this._userInfo.email) {
            this._toastr.show('', '', {
                toastComponent: PhotoReportNotificationComponent,
                payload: photoReportNotification,
                toastClass: photoReportNotification.isSuccess ? 'photo-report-success' : 'photo-report-failure',
                timeOut: 15000,
                disableTimeOut: !photoReportNotification.isSuccess,
                ...AppNotificationOptions
            }, photoReportNotification.isSuccess ? 'success' : 'error');
        }
    }

    private async initAppAfterLogin(user: UserInfo): Promise<void> {
        this._salesCodeConfigService.initialize();
        this.setFullStoryVariables(user);
        this.setWalkMeVariables(user);
        await this._projectStatusHttp.getFilterableProjectStatus();
        await this._featureFlagService.clientReady;

        if (!this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures]) {
            this._colorThemeService.loadCommercialTheme();
        }

        if (user.language) {
            await this._translate.use(user.language).toPromise();
            moment.locale(user.language);
            this.executeZendeskCommand('setLocale', user.language);
            this._dateAdapter.setLocale(user.language);
        }

        const userBasicInfo = {
            name: {
                value: user.name,
                readOnly: true
            },
            email: {
                value: user.email,
                readOnly: true
            }
        };

        this.executeZendeskCommand('prefill', userBasicInfo);
        this._isReady = true;
    }

    private async displayDashboardPopupDate(user: UserInfo): Promise<void> {
        await this._featureFlagService.initializeFeatureFlagClient(user);
        const isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        const isPermitted = await this._permissionService.hasReadPermission(ReadPermission.ViewBranchPerformanceAcknowledgement, null, false);

        if (isPermitted && isInternal) {
            const lastDashboardPopup = localStorage.getItem('lastDashboardPopupDate');
            const today = new Date().toISOString().substring(0, 10);

            if (!lastDashboardPopup || lastDashboardPopup < today) {
                this._dialogService.open(DashboardViewDialogComponent, {
                    height: '90vh',
                    width: '85vw'
                });
                localStorage.setItem('lastDashboardPopupDate', today);
            }
        }
    }

    private setFullStoryVariables(user: UserInfo): void {
        // eslint-disable-next-line dot-notation
        const fullStory = window['FS'];

        if (!environment.isDebugMode && fullStory) {
            fullStory.identify(user.email, {
                displayName: user.name,
                email: user.email,
                role: user.role,
                branch: user.branch,
                root: window.location.origin
            });
        }
    }

    private setWalkMeVariables(user: UserInfo): void {
        // eslint-disable-next-line dot-notation
        window['FswWalkMe'] = {
            username: user.username.toLowerCase(),
            email: user.email.toLowerCase(),
            roles: user.groups.map(_ => _.toLowerCase()),
            branch: user.branchInfo.name.toLowerCase(),
            country: user.country.toLowerCase(),
            language: user.language?.toLowerCase() ?? 'en'
        };
    }

    private executeZendeskCommand(command: string, data: any): void {
        if (zE && typeof zE === 'function') {
            zE('webWidget', command, data);
        }
    }
}
