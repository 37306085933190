import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import ProjectMergeRule from '../../../data-models/project-merge-rule';
import JsonUtility from '../../utility/json-utility.service';
import CodeMapping from '../../../data-models/generic/code-mapping';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectMergeHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/project-merge`;

    constructor(private _http: HttpClient) { }

    public async getMergeRules(): Promise<ProjectMergeRule[]> {
        try {
            const endpoint = `${this._api}/rules`;
            const data = await this._http.get<ProjectMergeRule[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getConsolidatedMergeRulesMappings(): Promise<CodeMapping<string>[]> {
        try {
            const endpoint = `${this._api}/consolidated-rules`;
            const data = await this._http.get<CodeMapping<string>[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async addMergeRule(name: string): Promise<ProjectMergeRule | null> {
        try {
            const endpoint = `${this._api}/rules`;
            const headers = { 'content-type': 'application/json' };
            const payload = JSON.stringify(name);
            const data = await this._http.post<ProjectMergeRule | null>(endpoint, payload, { headers }).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async replaceMergeRule(rule: ProjectMergeRule): Promise<boolean> {
        try {
            const endpoint = `${this._api}/rules`;

            return await this._http.put<boolean>(endpoint, rule).toPromise();
        }
        catch {
            return false;
        }
    }

    public async deleteMergeRule(code: number): Promise<boolean> {
        try {
            const endpoint = `${this._api}/rules/${code}`;

            return await this._http.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }
}
