import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { BranchHttpService } from '../../../core/services/http/branch-http/branch-http.service';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import UserInfo from '../../../core/data-models/user-info';
import Branch from '../../../core/data-models/branch';
import CodeDescription from '../../../core/data-models/code-description';

import { NewBranchDialogComponent } from './new-branch-dialog/new-branch-dialog.component';

@Component({
    selector: 'branch-management',
    styleUrls: ['./branch-management.component.scss'],
    templateUrl: './branch-management.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BranchManagementComponent implements OnInit {
    public branches = new Array<Branch>();
    public isDeactivateLoading = false;
    public isLoaded = false;
    private userInfo: UserInfo;

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags?.[FeatureFlags.UseInternalFeatures];
    }

    // eslint-disable-next-line max-params
    constructor(
        private _branchHttpService: BranchHttpService,
        private _featureFlagService: FeatureFlagService,
        private _userHttpService: UserHttpService,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService
    ) { }

    public async ngOnInit(): Promise<void> {
        this.userInfo = await this._userHttpService.getUserInfo();
        await this.loadBranchList();

        this.isLoaded = true;
    }

    get isAdmin(): boolean {
        return this.userInfo.groups.includes('admin');
    }

    public async updateGeofence(branch: Branch): Promise<void> {
        const result = await this._branchHttpService.updateBranchInfo(branch, 'geofence');
        this._snackBar.open(result.description, this._translateService.instant('shared.close'));
    }

    public async updateBranchTimezone(branch: Branch): Promise<void> {
        // eslint-disable-next-line require-atomic-updates
        branch.geoLocation = await this._branchHttpService.getGeoLocationForBranch(branch);
        const result = await this._branchHttpService.updateBranchInfo(branch, 'timezone');
        this._snackBar.open(result.description, this._translateService.instant('shared.close'));
    }

    public async setDeactivatedStatus(branch: Branch, shouldDeactivate: boolean): Promise<void> {
        this.isDeactivateLoading = true;

        let promise: Promise<CodeDescription> = null;
        if (shouldDeactivate) {
            promise = this._branchHttpService.deactivateServiceProvider(branch.code);
        }
        else {
            promise = this._branchHttpService.activateServiceProvider(branch.code);
        }

        const result = await promise;
        this._snackBar.open(result.description, this._translateService.instant('shared.close'));

        if (result.code === '200' || result.code === '204') {
            // eslint-disable-next-line require-atomic-updates
            branch.isDeactivated = shouldDeactivate;
            await this.loadBranchList(false);
        }

        this.isDeactivateLoading = false;
    }

    public async updateFollowUpGoals(branch: Branch): Promise<void> {
        if (branch.followUpGoalThisYear < 0 || branch.followUpGoalThisYear > 100) {
            branch.followUpGoalThisYear = 0;
            const message = this._translateService.instant('admin.branchManagement.invalidFollowUpGoal');
            this._snackBar.open(message, this._translateService.instant('shared.close'));
            return;
        }

        const result = await this._branchHttpService.updateFollowUpGoal(branch);
        this._snackBar.open(result.description, this._translateService.instant('shared.close'));
    }

    public async addOrUpdateBranch(branch: Branch): Promise<void> {
        const dialog = this._dialog.open(NewBranchDialogComponent, {
            width: '550px',
            height: '680px',
            data: branch
        });

        const success: boolean = await dialog.afterClosed().toPromise();
        if (success) {
            await this.loadBranchList(false);
        }

    }

    private async loadBranchList(useCache = true): Promise<void> {
        this.branches = await this._branchHttpService.getAllBranches(useCache);

        if (!this.branches || !this.branches.length) {
            const message = this._translateService.instant('admin.branchManagement.failedToLoadBranches');
            this._snackBar.open(message, this._translateService.instant('shared.close'));
        }
        else {
            const year = new Date().getFullYear().toString();
            for (const branch of this.branches) {
                branch.followUpGoalThisYear = 0;

                if (branch?.followUpGoals?.length) {
                    const goal = branch.followUpGoals.find(_ => _.year === year);
                    branch.followUpGoalThisYear = goal ? goal.goal : 0;
                }
            }
        }
    }
}
