import CodeDesc from '../generic/code-description';
import { ProjectQuestionsGridChange } from './project-questions-grid-change';

export default class ProjectQuestionsGrid {
    public union: CodeDesc;
    public rateType: CodeDesc;
    public rate: number;
    public effDate: string;
    public change: ProjectQuestionsGridChange;
}
