import { Ng2StateDeclaration, StateService } from '@uirouter/angular';

import { UserHttpService } from '../../core/services/http/user-http/user-http.service';

import { AttributionsComponent } from './attributions/attributions.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';

const resolve = [
    {
        token: 'hasAccess',
        deps: [UserHttpService, StateService],
        resolveFn: async(_userHttp: UserHttpService, _stateService: StateService): Promise<void> => {
            const user = await _userHttp.getUserInfo();

            if (!user?.groups.length) {
                _stateService.transition.abort();
                await _stateService.go('pageNotFound');
            }
        }
    }
];

export const states: Ng2StateDeclaration[] = [
    {
        name: 'attributions',
        url: '/attributions',
        component: AttributionsComponent,
        resolve
    },
    {
        name: 'termsOfService',
        url: '/termsOfService',
        component: TermsOfServiceComponent,
        resolve
    }
];
