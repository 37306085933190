<div fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-form-field>
            <mat-label>{{ 'shared.branch' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedBranch">
                <mat-option value='*'>{{ 'shared.all' | translate }}</mat-option>
                <mat-option *ngFor="let branch of branches" [value]="branch.name">{{branch.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="loadReport()">{{ 'dataReport.tempProjectAgeReport.loadReport' | translate }}</button>
    </div>
    <div fxLayout="row" class="report-grid-div">
        <kendo-grid [kendoGridBinding]="gridViewData" [pageSize]="gridPageSize" [pageable]="true" [loading]="loading"
        [style.height.%]="100" appGridPageResize="true">
            <ng-template kendoGridToolbarTemplate>
                <button kendoGridExcelCommand [disabled]="!gridViewData.length">{{ 'shared.exportExcel' | translate }}</button>
            </ng-template>
            <kendo-grid-column field="projectId" title="{{ 'dataReport.tempProjectAgeReport.projectId' | translate }}" width="150">
            </kendo-grid-column>
            <kendo-grid-column field="projectName" title="{{ 'dataReport.tempProjectAgeReport.projectName' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="branch" title="{{ 'shared.branch' | translate }}" width="150">
            </kendo-grid-column>
            <kendo-grid-column field="createdDate" title="{{ 'shared.creationDate' | translate }}" width="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.createdDate | branchLocalTime: dataItem.branch : branches : false}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="projectAge" title="{{ 'dataReport.tempProjectAgeReport.age' | translate }}" width="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="badge {{getProjectAgeClass(dataItem.projectAge)}}"
                            style="min-width: 25px;">
                            {{dataItem.projectAge}}
                        </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel fileName="tempProjectAgingReportData.xlsx" [fetchData]="allData" filterable="true">
            </kendo-grid-excel>
        </kendo-grid>
    </div>
    </div>
