import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import TaskOptionItem from '../../../../core/data-models/task-option-item';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import { UserPermissionService } from '../../../../core/services/user-permission/user-permission.service';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import { CreatePermission } from '../../../../core/enums/create-permission.enum';

@Component({
    selector: 'task-selection-dialog',
    styleUrls: ['./task-selection-dialog.component.scss'],
    templateUrl: './task-selection-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TaskSelectionDialogComponent implements OnInit {
    public tasks: TaskOptionItem[] = [];

    constructor(private _dialog: MatDialogRef<TaskSelectionDialogComponent>,
                private _translate: TranslateService,
                private _permissionService: UserPermissionService,
                private _featureFlagService: FeatureFlagService) { }

    public async ngOnInit(): Promise<void> {
        this._dialog.addPanelClass('task-selection-dialog');
        const isQuoteFeatureAvailable = this._featureFlagService.featureFlags[FeatureFlags.UseQuotesFeature];
        const useBudgetFeature = this._featureFlagService.featureFlags[FeatureFlags.UseBudgetFeature]?? false;
        const canCreateQuote = await this._permissionService.hasCreatePermission(CreatePermission.CreateQuote);
        const canCreateProject = await this._permissionService.hasCreatePermission(CreatePermission.CreateProject);

        if (isQuoteFeatureAvailable && canCreateQuote) {
            this.tasks.push(new TaskOptionItem(
                'quote',
                this._translate.instant('sideMenu.taskSelection.createNewQuote'),
                this._translate.instant('sideMenu.taskSelection.createNewQuoteDesc'),
                'attach_money'
            ));
        }

        if (canCreateProject) {
            await this.initProjectRelatedButtons();
        }

        if (useBudgetFeature) {
            this.tasks.push(new TaskOptionItem(
                'download',
                this._translate.instant('sideMenu.taskSelection.downloadTemplates'),
                this._translate.instant('sideMenu.taskSelection.downloadTemplatesDesc'),
                'cloud_download'
            ));
        }
        this.tasks = this.tasks.filter(_ => _ !== null);
    }

    public openTask(event: MouseEvent, task: string): void {
        event.stopPropagation();
        this.onClose(task);
    }

    public onClose(task: string): void {
        this._dialog.close(task);
    }

    private initProjectRelatedButtons(): void {
        const isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        const canCreateSubcontract = this._featureFlagService.featureFlags[FeatureFlags.AllowCreatingSubcontracts];
        const dispatchNewProjectText = isInternal ? 'dispatchNewProjectToInternal' : 'dispatchNewProjectToServiceProvider';

        this.tasks.push(new TaskOptionItem(
            'project',
            this._translate.instant('sideMenu.taskSelection.createNewProject'),
            this._translate.instant(`sideMenu.taskSelection.${dispatchNewProjectText}`),
            'assignment'
        ));

        if (canCreateSubcontract) {
            this.tasks.push(new TaskOptionItem(
                'subcontract',
                this._translate.instant('sideMenu.taskSelection.createNewSubcontractProject'),
                this._translate.instant('sideMenu.taskSelection.createNewSubcontractProjectDesc'),
                'assignment'
            ));
        }

        this.tasks.push(new TaskOptionItem(
            'fast-request',
            this._translate.instant('sideMenu.taskSelection.dispatchFastRequest'),
            this._translate.instant('sideMenu.taskSelection.dispatchFastRequestDesc'),
            'assignment_ind'
        ));

        if (isInternal) {
            this.tasks.push(new TaskOptionItem(
                'integration-request',
                this._translate.instant('sideMenu.taskSelection.dispatchIntegrationRequest'),
                this._translate.instant('sideMenu.taskSelection.dispatchIntegrationRequestDesc'),
                'assignment_late'
            ));
        }
    }
}
