import { NgModule } from '@angular/core';

import { SiteLayoutModule } from './site-layout/site-layout.module';
import { LegalModule } from './legal/legal.module';
import { ProjectModule } from './project/project.module';
import { QuoteModule } from './quote/quote.module';
import { AdminModule } from './admin/admin.module';
import { DataReportModule } from './data-report/data-report.module';
import { OnCallModule } from './on-call/on-call.module';
import { LaborModule } from './labor/labor.module';
import { DictionaryModule } from './dictionary/dictionary.module';
import { DispatchModule } from './dispatch/dispatch.module';
import { UserModule } from './user/user.module';
import { ReceiptModule } from './receipt/receipt.module';
import { ReportTemplateModule } from './report-template/report-template.module';
import { ScheduleBoardModule } from './schedule-board/schedule-board.module';

@NgModule({
    imports: [
        LegalModule,
        ProjectModule,
        QuoteModule,
        ReceiptModule,
        AdminModule,
        DataReportModule,
        OnCallModule,
        LaborModule,
        DictionaryModule,
        DispatchModule,
        ReportTemplateModule,
        UserModule,
        ScheduleBoardModule
    ],
    exports: [
        SiteLayoutModule,
        DataReportModule,
        OnCallModule,
        ScheduleBoardModule
    ]
})
export class FeaturesModule { }
