<div class="user-comment-editor-container">
    <div fxLayout="column">
        <textarea class="user-comment-editor-placeholder" [id]="editorId"></textarea>
    </div>

    <div class="user-comment-editor-actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="6px">
        <div>
            <span [ngStyle]="{ color: isLimitReached ? 'var(--context-colors-warning)' : null }">
                {{ currentCharacters }}
            </span>

            <span>/ {{ maxCharacters }} {{ 'shared.userComments.editor.characters' | translate }}</span>
        </div>

        <button mat-stroked-button color="primary" (click)="onSave()">{{ 'shared.userComments.editor.save' | translate }}</button>
        <button mat-flat-button (click)="onCancel()">{{ 'shared.userComments.editor.cancel' | translate }}</button>
    </div>
</div>
