<div fxLayout="column" fxLayoutAlign="center start" fxFlexFill fxLayoutGap="10px">

    <information-grid-card class="merge-project-review-project-information-container" [icon]="'projects'"
        [allowAnimation]="false" [animationDelay]="0.05" [useSvg]="true" [title]="translate.instant('projects.merge.review.title')"
        [config]="projectInfoCardConfig">
    </information-grid-card>

    <mat-card class="merge-project-review-container" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">

        <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
            <span class="merge-project-review-title">{{ 'projects.merge.review.review' | translate }}</span>
        </div>

        <mat-checkbox [(ngModel)]="reviewSelected" (change)="reviewSelectionChanged.emit(reviewSelected)">
            {{ 'projects.merge.review.select' | translate }}
        </mat-checkbox>

        <form [formGroup]="jobReviewFormGroup" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>

            <div fxLayout="column" fxLayoutAlign="center start" fxFlexFill fxLayoutGap="10px">

                <div fxLayout fxLayoutAlign="center start" fxFlexFill fxLayoutGap="10px">
                    <div fxFlex="50" fxLayout fxLayoutAlign="start start">

                        <mat-form-field>
                            <mat-label>{{ 'projects.merge.review.comment' | translate }}</mat-label>
                            <textarea formControlName="comment" matInput rows="3">
                            </textarea>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50" fxLayout fxLayoutAlign="start start">

                        <mat-form-field>
                            <mat-label>{{ 'projects.merge.review.billableHours' | translate }}</mat-label>
                            <input type="number" matInput formControlName="billableHours">
                        </mat-form-field>
                    </div>
                </div>

                <div formGroupName="contactInfo" fxLayout="column" fxLayoutAlign="start start" fxFlexFill
                    fxLayoutGap="10px">
                    <div fxLayout fxLayoutAlign="center start" fxFlexFill fxLayoutGap="10px">
                        <div fxFlex="50" fxLayout fxLayoutAlign="start start">
                            <mat-form-field>
                                <mat-label>{{ 'projects.merge.review.clientName' | translate }}</mat-label>
                                <input matInput formControlName="contactName">
                            </mat-form-field>
                        </div>

                        <div fxFlex="50" fxLayout fxLayoutAlign="start start">

                            <mat-form-field>
                                <mat-label>{{ 'projects.merge.review.clientPhone' | translate }}</mat-label>
                                <input matInput formControlName="contactNumber">
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-form-field>
                        <mat-label>{{ 'projects.merge.review.clientEmail' | translate }}</mat-label>
                        <input matInput formControlName="email">
                    </mat-form-field>
                </div>

                <div formGroupName="checklist" fxLayout="column" fxLayoutAlign="center start" fxFlexFill
                    fxLayoutGap="10px">
                    <mat-checkbox formControlName="contactInformation">
                        {{ 'projects.merge.review.contactInformation' | translate }}
                    </mat-checkbox>

                    <mat-checkbox formControlName="findings">
                        {{ 'projects.merge.review.findings' | translate }}
                    </mat-checkbox>

                    <mat-checkbox formControlName="recommendations">
                        {{ 'projects.merge.review.recommendations' | translate }}
                    </mat-checkbox>

                    <mat-checkbox formControlName="photos">
                        {{ 'projects.merge.review.photos' | translate }}
                    </mat-checkbox>

                    <mat-checkbox formControlName="safety">
                        {{ 'projects.merge.review.safety' | translate }}
                    </mat-checkbox>

                    <mat-checkbox formControlName="labourReviewedAndApproved">
                        {{ 'projects.merge.review.labour' | translate }}
                        {{ 'projects.merge.review.reviewedAndApproved' | translate }}
                    </mat-checkbox>

                    <mat-checkbox formControlName="isAReRoofQuoteRequired">Is a
                        {{ 'projects.merge.review.reroof' | translate }}
                        {{ 'projects.merge.review.quoteRequired' | translate }}
                    </mat-checkbox>
                </div>
            </div>
        </form>
    </mat-card>


</div>
