import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import KpiChangeRequest from '../../../../../core/data-models/kpi-change-request';
import KpiChangeRequestDialogOption from '../../../../../core/data-models/kpi-change-request-dialog-option';
import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';

@Component({
    selector: 'kpi-change-request-dialog',
    styleUrls: ['./kpi-change-request-dialog.component.scss'],
    templateUrl: './kpi-change-request-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class KpiChangeRequestDialogComponent implements OnInit {
    public reasonInput: UntypedFormControl;
    public jobs: number[] = [];
    public selectedJob = 0;
    private _isLoaded = true;

    constructor(
        public translate: TranslateService,
        private _projectHttp: ProjectHttpService,
        private _dialog: MatDialogRef<KpiChangeRequestDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public options: KpiChangeRequestDialogOption) { }

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        const validators = [Validators.required, Validators.pattern(/(\S|\w).{8,}(\S|\w)/)];
        this.reasonInput = new UntypedFormControl('', validators);
        this.jobs = new Array(this.options.totalJobs).fill(0).map((_, i) => i);
        this.selectedJob = this.jobs.length - 1;
    }

    public async sendRequest(): Promise<void> {
        if (!this.reasonInput.valid) {
            return;
        }
        this._isLoaded = false;
        const projectId = this.options.projectId;
        const reason = this.reasonInput.value.trim();
        const request = new KpiChangeRequest(projectId, this.selectedJob, reason);
        const succeeded = await this._projectHttp.requestKpiChange(request);
        this._isLoaded = true;
        this.onClose(succeeded);
    }

    public onClose(value: boolean | null): void {
        this._dialog.close(value);
    }
}
