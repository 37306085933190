<dialog-action-card
    [icon]="'print'"
    [title]="translate.instant('shared.conditionReportOptionsDialog.title')"
    [isLoaded]="true"
    (close)="onClose(false)">
    <div fxLayout="column" fxFill fxLayoutGap="15px" class="main-options-container">
        <div fxLayout="row" fxFlex>
            <mat-tab-group fxFill>
                <mat-tab label="{{ 'shared.conditionReportOptionsDialog.mainOptions' | translate }}">
                    <div fxLayout="row" fxLayoutGap="15px" fxFill>
                        <div fxLayout="column" fxFlex="50" fxLayoutGap="15px">
                            <mat-form-field>
                                <mat-label>{{ 'shared.conditionReportOptionsDialog.preparedFor' | translate }}</mat-label>
                                <input type="text" matInput [(ngModel)]="data.preparedFor"/>
                            </mat-form-field>
                            <mat-checkbox [(ngModel)]="data.removeCompanyLogo">
                                <span>{{ 'shared.conditionReportOptionsDialog.removeCompanyLogo' | translate }}</span>
                            </mat-checkbox>
                        </div>
                        <div fxLayout="column" fxFlex="50" fxLayoutGap="15px">
                            <div fxLayout="row" class="upload-logo-button">
                                <button mat-flat-button color="primary" (click)="fileInput.click()">
                                    <mat-icon>cloud_upload</mat-icon>
                                    <span>{{ 'shared.conditionReportOptionsDialog.uploadLogo' | translate }}</span>
                                    <input type="file" (change)="logoFileChanged($event)" #fileInput accept="image/*">
                                </button>
                            </div>
                            <div *ngIf="useClientLogo" fxFlex fxLayout="column"
                                [style.background-image]="clientLogoUrl" class="logo-image-container">
                            </div>
                            <div *ngIf="useCustomLogo" fxFlex fxLayout="column"
                                [style.background-image]="customLogoUrl" class="logo-image-container">
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{ 'shared.conditionReportOptionsDialog.maintenancePlan' | translate }}" *ngIf="showMaintenanceTab">
                    <div fxLayout="column" fxLayoutGap="15px" fxFill>
                        <div>
                            <mat-checkbox [(ngModel)]="includePlan">
                                <span>{{ 'shared.conditionReportOptionsDialog.includeMaintenancePlan' | translate }}</span>
                            </mat-checkbox>
                        </div>
                        <div fxLayout="column" *ngIf="includePlan" class="maintenance-plan-summary-container" fxFlex>
                            <mat-grid-list cols="7" rowHeight="40px" gutterSize="10px">
                                <mat-grid-tile>
                                    <label>{{ 'shared.conditionReportOptionsDialog.section' | translate }}</label>
                                </mat-grid-tile>
                                <mat-grid-tile>
                                    <label>{{ 'shared.conditionReportOptionsDialog.roofArea' | translate }}</label>
                                </mat-grid-tile>
                                <mat-grid-tile
                                    *ngFor="let budget of budgetYears">
                                  {{budget}}
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2"></mat-grid-tile>
                                <mat-grid-tile
                                    *ngFor="let budget of budgetYears">
                                  <div fxLayout="row">
                                    <div fxFlex="50">{{ 'shared.conditionReportOptionsDialog.immediateRepairs' | translate }}</div>
                                    <div fxFlex="50">{{ 'shared.conditionReportOptionsDialog.maintenance' | translate }}</div>
                                  </div>
                                </mat-grid-tile>
                                <ng-container *ngFor="let section of data.sections">
                                    <mat-grid-tile *ngIf="section.jobDate" colspan="7">
                                        <div fxLayout="row" fxLayoutAlign="start center" fxFill>
                                            <label>{{section.jobDate | date : 'yyyy-MM-dd'}}</label>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <label>{{section.title}}</label>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <div fxLayout="row" fxLayoutAlign="start center" class="number-input-container" fxFill>
                                            <input type="number" matInput [(ngModel)]="section.area" min="0"/>
                                        </div>
                                    </mat-grid-tile>
                                    <mat-grid-tile *ngFor="let budgetLine of section.budget">
                                        <div fxLayout="row" fxFill>
                                            <div fxFlex="50">
                                                <div fxLayout="row" fxLayoutAlign="start center" class="number-input-container" fxFill>
                                                    <input type="number" matInput [(ngModel)]="budgetLine.immediate" min="0"/>
                                                </div>
                                            </div>
                                            <div fxFlex="50">
                                                <div fxLayout="row" fxLayoutAlign="start center" class="number-input-container" fxFill>
                                                    <input type="number" matInput [(ngModel)]="budgetLine.maintenance" min="0"/>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-grid-tile>
                                </ng-container>
                            </mat-grid-list>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-flat-button color="primary" (click)="onClose(true)">
                {{ 'shared.conditionReportOptionsDialog.print' | translate }}
            </button>
        </div>
    </div>
</dialog-action-card>
