import { Component, Input, AfterViewInit, ViewChild, ElementRef, OnInit, OnDestroy, EventEmitter, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import NavigationTab from '../../core/data-models/navigation-tab';
import CheckboxList from '../../core/data-models/checkbox-list';
import QuoteSummary from '../../core/data-models/summary-info/quote-summary';
import SummaryQuery from '../../core/data-models/summary-info/summary-query';
import SummaryQueryResult from '../../core/data-models/summary-info/summary-query-result';
import CheckboxData from '../../core/data-models/checkbox-data';
import ProjectIdUtility from '../../core/services/utility/project-id-utility/project-id-utility.service';
import { UserHttpService } from '../../core/services/http/user-http/user-http.service';
import { QuoteHttpService } from '../../core/services/http/quote-http/quote-http.service';
import { QuoteStatusHttpService } from '../../core/services/http/quote-status-http/quote-status-http.service';
import { AppConfigHttpService } from '../../core/services/http/appconfig-http/appconfig-http.service';
import { BranchHttpService } from '../../core/services/http/branch-http/branch-http.service';
import { AppEventService } from '../../core/services/events/app-event/app-event.service';
import { SalesCodeConfigService } from '../../core/services/sales-code-config/sales-code-config.service';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';
import { LanguageKey } from '../../core/enums/language-key.enum';
import LanguageUtility from '../../core/services/utility/language-utility/language-utility.service';

@Component({
    selector: 'quote',
    styleUrls: ['./quote.component.scss'],
    templateUrl: './quote.component.html',
    encapsulation: ViewEncapsulation.None
})
export class QuoteComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public activeQuoteId = '';
    @ViewChild('matTabNavBar', { read: ElementRef }) public matTabNavBar: ElementRef;
    public visibleTabs: NavigationTab[] = [];
    public hiddenTabs: NavigationTab[] = [];
    public filterConfiguration: CheckboxList[] = [];
    public qqqCheckBox: CheckboxData;
    public query = { currentPage: 1, pageSize: 7 } as SummaryQuery;
    public queryResult: SummaryQueryResult<QuoteSummary> = null;
    public hasTargetSummary = false;
    public summaryListExpanded = true;
    public isSummaryListLoaded = true;
    public updatedTabs = new Set<NavigationTab>();
    private readonly _filterChange = new EventEmitter<number>();
    private readonly _expandDuration = 750;
    private _subscriptions = new Subscription();
    private _expandTimer: number;
    private _tabs: NavigationTab[] = [];

    //eslint-disable-next-line
    constructor(private _userHttp: UserHttpService,
                private _quoteHttp: QuoteHttpService,
                private _quoteStatusHttp: QuoteStatusHttpService,
                private _appConfigHttp: AppConfigHttpService,
                private _branchHttp: BranchHttpService,
                private _state: StateService,
                private _appEvents: AppEventService,
                private _salesCodeConfigService: SalesCodeConfigService,
                private _translate: TranslateService,
                private _featureFlagService: FeatureFlagService) { }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    get summaryListStyle(): { [key: string]: string } {
        return { transition: `width ${this._expandDuration / 1000}s` };
    }

    public async ngOnInit(): Promise<void> {
        this.qqqCheckBox = new CheckboxData('qqq', false, false, '', this._translate.instant('quote.qqqQuotesOnly'));
        this._filterChange.pipe(debounceTime(650)).subscribe(this.loadQuoteSummaries.bind(this));
        await this.loadSummaryListFilters();

        if (this.activeQuoteId) {
            await this.loadTargetSummary(this.activeQuoteId);
            this.syncFilterListsWithQuoteQuery();
            await this.loadNavigationTabs();
        }
        else {
            this.syncFilterListsWithQuoteQuery();
            this.loadQuoteSummaries(1);
        }

        this._subscriptions.add(this._appEvents.quoteCreated.subscribe(this.onQuoteCreated.bind(this)));
        this._subscriptions.add(this._appEvents.quoteUpdated.subscribe(_ => this.onQuoteUpdated(..._)));
        this._subscriptions.add(this._appEvents.reloadQuoteCounter.subscribe(() => this.loadCounters(false)));
    }

    public ngAfterViewInit(): void {
        this.resizeTabs(this.matTabNavBar);
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    private async loadTargetSummary(quoteId: string): Promise<void> {
        this.query.searchText = quoteId;
        await this.loadQuoteSummaries(1);
        this.query.searchText = null;
        this.hasTargetSummary = true;
    }

    private async loadSummaryListFilters(): Promise<void> {
        const { branch, language } = await this._userHttp.getUserInfo();
        const branches = await this._branchHttp.getAllBranches();
        const statuses = await this._quoteStatusHttp.getAllStatuses();
        const salesCodes = await this._appConfigHttp.getQuoteSalesCode('filter');
        const branchOptions = branches.map(_ => new CheckboxData(_.code, _.name === branch, false, '', _.name));
        // eslint-disable-next-line max-len
        const codesOptions = salesCodes.map(salesCode => ({
            name: salesCode.code,
            displayText: `${salesCode.code} ${this._salesCodeConfigService.getTypeText(salesCode.code, language)}`
        }));

        const statusOptions = statuses.filter(_ => _.filterFlags.isWorkOnHandFilterAllowed).map(_ => {
            const defaultName = LanguageUtility.getLocalizedContent(LanguageKey.EN, _.names);
            const displayName = LanguageUtility.getLocalizedContent(language, _.names);

            return new CheckboxData(defaultName, false, false, '', displayName);
        });

        const branchLabel = this._translate.instant('quote.branch');
        const branchDescription = this._translate.instant('quote.branchDescription');
        const statusLabel = this._translate.instant('quote.status');
        const statusDescription = this._translate.instant('quote.statusDescription');
        const salesCodeLabelKey = this.isInternal ? 'quote.salesCodes' : 'quote.projectTypes';
        const salesCodeLabel = this._translate.instant(salesCodeLabelKey);
        const salesCodeFilterKey = this.isInternal ? 'quote.salesCodeDescription' : 'quote.projectTypesFilter';
        const salesCodeLabelDescription = this._translate.instant(salesCodeFilterKey);

        this.filterConfiguration = [
            new CheckboxList('Branch', branchDescription, branchOptions, branchLabel),
            new CheckboxList('Status', statusDescription, statusOptions, statusLabel),
            new CheckboxList('Sales Codes', salesCodeLabelDescription, codesOptions as CheckboxData[], salesCodeLabel)
        ];
    }

    private syncFilterListsWithQuoteQuery(): void {
        this.query.branch = this.retrieveCheckedFilterItems('Branch');
        this.query.status = this.retrieveCheckedFilterItems('Status');
        this.query.salesCode = this.retrieveCheckedFilterItems('Sales Codes');
    }

    private retrieveCheckedFilterItems(name: string): string[] {
        const selected = this.filterConfiguration.find(_ => _.name === name)?.items ?? [];

        return selected.filter(_ => _.checked).map(_ => _.name);
    }

    public onFilterSearch(text: string): void {
        this.query.searchText = text;
        this._filterChange.emit(1);
    }

    public onqqqCheckBoxToggled(qqqCheckBox: CheckboxData): void {
        this.query.emergencyQuote = qqqCheckBox.checked;
        this._filterChange.emit(1);
    }

    public onFilterApplied(data: CheckboxList): void {
        const { name, items } = data;
        const content = items.filter(_ => _.checked).map(_ => _.name);

        if (name === 'Sales Codes') {
            this.query.salesCode = content;
        }
        else if (name === 'Branch') {
            this.query.branch = content;
        }
        else if (name === 'Status') {
            this.query.status = content;
        }

        this._filterChange.emit(1);
    }

    public async loadQuoteSummaries(page: number): Promise<void> {
        this.isSummaryListLoaded = false;
        this.query.currentPage = page;
        this.queryResult = await this._quoteHttp.getSummaries(this.query);
        this.hasTargetSummary = false;
        this.isSummaryListLoaded = true;
    }

    private onQuoteCreated(): void {
        if (this.query.currentPage === 1) {
            this.loadQuoteSummaries(1);
        }
    }

    private onQuoteUpdated(originalId: string, summary: QuoteSummary): void {
        this.handleListRefresh(originalId, summary);
        this.handleContentRefresh(originalId, summary);
    }

    private handleListRefresh(originalId: string, summary: QuoteSummary): void {
        const summaries = this.queryResult?.result ?? [];
        const index = summaries.findIndex(_ => _.quoteId === originalId);

        if (index === -1) {
            return;
        }

        if (summary.status === 'Deleted' || summary.status === 'Awarded') {
            this.loadQuoteSummaries(this.query.currentPage);
        }
        else {
            summaries[index] = summary;
        }
    }

    private handleContentRefresh(originalId: string, summary: QuoteSummary): void {
        if (this.activeQuoteId !== originalId) {
            return;
        }

        const { quoteId, status } = summary;

        if (status === 'Deleted' || summary.status === 'Awarded') {
            this.activeQuoteId = '';
        }
        else if (originalId !== quoteId) {
            this.openQuote(quoteId);
        }
    }

    public resizeTabs(element: ElementRef | null = null): void {
        const target = element ?? this.matTabNavBar;

        if (!target) {
            return;
        }
        const iconWidth = 40;
        const offsetWidth = target.nativeElement.offsetWidth;
        const visibleTabs = Math.floor((offsetWidth - iconWidth) / 160);
        this.visibleTabs = this._tabs.slice(0, visibleTabs);
        this.hiddenTabs = this._tabs.slice(visibleTabs);
    }

    public async openQuote(quoteId: string): Promise<void> {
        if (quoteId !== this.activeQuoteId) {
            this.activeQuoteId = quoteId;
            await this.loadNavigationTabs();
            this._state.go('quote', { quoteId });
        }
    }

    public onHiddenTabSelected(state: string): void {
        const index = this.hiddenTabs.findIndex(_ => _.state === state);

        if (index === -1) {
            return;
        }
        const tab = this.hiddenTabs.splice(index, 1)[0];

        if (this.visibleTabs.length) {
            this.hiddenTabs.push(this.visibleTabs.pop());
        }
        this.visibleTabs.push(tab);
    }

    public onToggleExpand(expanded: boolean): void {
        if (this._expandTimer) {
            clearTimeout(this._expandTimer);
        }
        this.summaryListExpanded = expanded;
        this._expandTimer = setTimeout(() => this.resizeTabs(this.matTabNavBar), this._expandDuration) as unknown as number;
    }

    private async loadNavigationTabs(): Promise<void> {
        this._tabs = [
            new NavigationTab('Details', null, null, '.details', this._translate.instant('quote.detailsLabel')),
            new NavigationTab('Attachments', null, null, '.attachments', this._translate.instant('quote.attachments')),
            new NavigationTab('Follow-up Log', null, null, '.followUpLog', this._translate.instant('quote.followUpLog')),
            new NavigationTab('Quote Pdf Preview', null, null, '.pdfPreview', this._translate.instant('quote.quotePdfPreview')),
            new NavigationTab('Site Quotes', null, null, '.siteQuotes', this._translate.instant('quote.siteQuotes')),
            new NavigationTab('Site Project History', null, null, '.siteProjects', this._translate.instant('quote.siteProjects'))
        ];

        const summary = this.queryResult.result.find(_ => _.quoteId === this.activeQuoteId);

        if (summary && ProjectIdUtility.isEmergencyQuote(summary.originProjectId)) {
            const tabs = [
                new NavigationTab('Photos', null, null, '.photos', this._translate.instant('quote.photos')),
                new NavigationTab('Drawings', null, null, '.drawings', this._translate.instant('quote.drawings')),
                new NavigationTab('Original Report', null, null, '.originalReport', this._translate.instant('quote.originalReport'))
            ];

            this._tabs.push(...tabs);
        }

        this._tabs.push(new NavigationTab('Budget', null, null, '.budget', this._translate.instant('quote.budgetTabLabel')));
        this.resizeTabs(this.matTabNavBar);
        await this.loadCounters();
    }

    private async loadCounters(skipAnimation = true): Promise<void> {
        if (!this.activeQuoteId) {
            return;
        }

        const counters = await this._quoteHttp.getQuoteCounters(this.activeQuoteId);

        const lookup = new Map<string, number>([
            ['Photos', counters.photo],
            ['Drawings', counters.drawing],
            ['Attachments', counters.attachment],
            ['Follow-up Log', counters.followUpLog ],
            ['Site Quotes', counters.siteQuotes ]
        ]);

        for (const tab of this._tabs.filter(_ => lookup.has(_.name))) {
            const badgeText = `${lookup.get(tab.name) ?? ''}`;

            if (tab.badge !== badgeText && !skipAnimation) {
                this.updatedTabs.add(tab);
            }

            tab.badge = badgeText;
        }

        setTimeout(() => this.updatedTabs.clear(), 1000);
    }
}
