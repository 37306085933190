export default class ProjectIdUtility {

    public static isEmergencyQuote(id = ''): boolean {
        return /^qqq/.test(id);
    }

    public static isEmergency(id = ''): boolean {
        return !id? false: /^\D/.test(id.trim());
    }

    public static isSubcontract(id = ''): boolean {
        return !id? false: /-316|-333/.test(id);
    }

    public static isTemporary(id = ''): boolean {
        return /s/i.test(id);
    }

    public static isUsProject(id = ''): boolean {
        return /^(8|9)/.test(id);
    }

    public static toStorageFormat(id = ''): string {
        if (!id?.trim() || this.isEmergency(id)) {
            return id;
        }
        return id.replace(/-/g, '');
    }

    public static toDisplayFormat(id = ''): string {
        if (!id?.trim() || this.isEmergency(id)) {
            return id;
        }
        return id.replace(/\W/g, '').replace(/^(\d{3})(.*)(\d{3})/, '$1-$2-$3');
    }

    public static getBranchCode(id = ''): string {
        if (!id?.trim() || ProjectIdUtility.isEmergency(id) || id.trim().length < 3) {
            return '';
        }
        return id.substr(0, 3);
    }
}
