<div class="parameter-selector-container" fxLayout="column" fxLayoutGap="12px">
    <div *ngIf="!parameterSets.length" fxLayout="row" fxLayoutAlign="center center" fxFlex>
        <span>{{ 'admin.rolesAndPermissions.parameterSelector.noParameterText' | translate }}</span>
    </div>

    <ng-container *ngIf="parameterSets.length">
        <div fxLayout="row" fxLayoutGap="12px">
            <div *ngFor="let parameterSet of parameterSets; let i = index"
                class="parameter-name"
                [ngClass]="{ 'active-set': activeIndex === i, 'disabled-group': isDisabled }"
                (click)="activeIndex = isDisabled ? activeIndex : i"
                fxLayout="row"
                fxLayoutAlign="center center">

                <span>{{ (parameterSet.key | splitCamelCase).toLowerCase() }}</span>
            </div>
        </div>

        <div class="parameter-checkboxes" fxLayout="row wrap" fxLayoutGap="20px">
            <mat-checkbox class="parameter-checkbox"
                *ngFor="let option of parameterSets[activeIndex].options"
                [checked]="option.checked"
                [disabled]="isDisabled"
                (change)="onCheckboxChange(option, parameterSets[activeIndex])">

                {{ option.name }} - {{ option.displayText }}
            </mat-checkbox>
        </div>
    </ng-container>
</div>
