import ProjectQuestions from './project-attributes/project-questions';

export default class ProjectQuestionValidCheck {
    public valid = true;
    public projectQuestions = new ProjectQuestions();

    constructor(valid: boolean, projectQuestions: ProjectQuestions) {
        this.valid = valid;
        this.projectQuestions = projectQuestions;
    }
}
