import ImageItem from './job-template-items/image-item';
import PhotoReportNotificationConfig from './photo-report-notification-config';

export default class PhotoReportOptions {
    public projectId: string;
    public userType: string;
    public includeDrawing = true;
    public clientReport: boolean;
    public clientId: string;
    public showToCustomer: boolean;
    public includeCondition: boolean;
    public removeCompanyLogo: boolean;
    public selectedDrawings: string[] = [];
    public items: ImageItem[] = [];
    public notificationConfig: PhotoReportNotificationConfig;
}
