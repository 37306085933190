<div class="admin-container" fxLayout="row">
    <div class="admin-tabs" fxLayout="column">
        <button *ngFor="let tab of tabs"
            class="menu-tab"
            [ngClass]="{ 'active-menu-tab': isActiveState(tab.state) }"
            (click)="openTab(tab)">

            <div class="selection-indicator"></div>
            <div class="tab-name">{{ tab.displayName }}</div>
        </button>
    </div>

    <ui-view class="admin-content-view"></ui-view>
</div>
