/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { UserHttpService } from '../http/user-http/user-http.service';
// eslint-disable-next-line
declare const newrelic: any;

@Injectable({
    providedIn: 'root'
})
export class NewRelicUtilityService {

    public constructor(private _userHttpService: UserHttpService) { }

    public async addPageAction<T>(
        customAttributeName: string,
        customAttribute: string,
        pageActionName: string,
        pageActionData: T,
        addConstants = true
    ): Promise<void> {

        if (typeof newrelic === 'undefined') {
            return;
        }

        if (customAttributeName) {
            newrelic.setCustomAttribute(customAttributeName, customAttribute);
        }

        if (addConstants) {
            const user = await this._userHttpService.getUserInfo();
            newrelic.setCustomAttribute('AppConstants', user);
        }
        newrelic.addPageAction(pageActionName, pageActionData);
    }

    public async addErrorEvent<T>(errorData: T) : Promise<void> {
        if (typeof newrelic === 'undefined') {
            return;
        }

        const user = await this._userHttpService.getUserInfo();
        newrelic.noticeError(errorData, user);
    }
}
