import { ValidatorFn } from '@angular/forms';

export default class ValidatorSet {
    public validator: ValidatorFn;
    public message = '';

    constructor(validator: ValidatorFn, message = '') {
        this.validator = validator;
        this.message = message;
    }
}
