import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import Branch from '../../../../core/data-models/branch';
import UserInfo from '../../../../core/data-models/user-info';
import UserProfile from '../../../../core/data-models/user-profile';
import UserProfileEditorOption from '../../../../core/data-models/dialog-options/user-profile-editor-option';
import SubscriptionTopic from '../../../../core/data-models/subscription-topic';
import SubscribedTopic from '../../../../core/data-models/subscribed-topic';
import { BranchHttpService } from '../../../../core/services/http/branch-http/branch-http.service';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';
import { SubscriptionHttpService } from '../../../../core/services/http/subscription-http/subscription-http.service';
import { ValidatorFactory } from '../../../../core/services/validator-factory/validator-factory.service';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import { UserPermissionService } from '../../../../core/services/user-permission/user-permission.service';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import Language from '../../../../core/data-models/generic/language';
import LanguageService from '../../../../core/services/language/language.service';

@Component({
    selector: 'user-profile-editor',
    styleUrls: ['./user-profile-editor-dialog.component.scss'],
    templateUrl: './user-profile-editor-dialog.component.html'
})
export class UserProfileEditorDialogComponent implements OnInit {
    public formGroup: UntypedFormGroup;
    public user: UserProfile;
    public roles: { name: string, checked: boolean }[] = [];
    public branches: Branch[];
    public subscriptionTemplates: SubscriptionTopic[] = [];
    public userSubscriptions: SubscribedTopic[] = [];
    public isInternal = false;
    public isInformationView = true;
    public isLoading = true;
    public languages: Language[] = [];
    private _rolesToResetTo: string;
    private _subscriptionsToResetTo: string;
    private _userToResetTo: string;
    private _performer: UserInfo;
    private _phoneValidator = this._validatorFactory.createPhoneValidator();
    private _emailValidator = this._validatorFactory.createEmailValidator();
    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) public options: UserProfileEditorOption,
                private _dialog: MatDialogRef<UserProfileEditorDialogComponent>,
                private _formBuilder: UntypedFormBuilder,
                private _userHttpService: UserHttpService,
                private _branchHttpService: BranchHttpService,
                private _subscriptionHttpService: SubscriptionHttpService,
                private _validatorFactory: ValidatorFactory,
                private _permissionService: UserPermissionService,
                private _featureFlagService: FeatureFlagService,
                private _snackBar: MatSnackBar,
                private _languageService: LanguageService) { }

    get noRolesAssigned(): boolean {
        return this.roles.every(_ => !_.checked);
    }

    get canResetPassword(): boolean {
        const { isExisting, isEditable } = this.options;
        const isFlynnUser = this.isInternal && this.user.emailAddress.toLowerCase().includes('@flynncompanies.com');

        return !isFlynnUser && isExisting && isEditable;
    }

    public async ngOnInit(): Promise<void> {
        this.languages = this._languageService.getLanguages();
        this._dialog.disableClose = true;
        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        this.subscriptionTemplates = await this._subscriptionHttpService.getSubscriptionTopics();
        this._performer = await this._userHttpService.getUserInfo();
        this.branches = (await this._branchHttpService.getAllBranches()).filter(_ => !_.isDeactivated);

        if (!await this._permissionService.hasAdminPrivilege()) {
            this.branches = this.branches.filter(_ => _.code === this._performer.branchInfo.code);
        }

        await this.loadUser();
    }

    public resetForm(): void {
        this.user = JSON.parse(this._userToResetTo);
        this.roles = JSON.parse(this._rolesToResetTo);
        this.userSubscriptions = JSON.parse(this._subscriptionsToResetTo);
        this.setupForm();
    }

    public async saveUser(): Promise<void> {
        this.user = {
            ...this.formGroup.getRawValue(),
            subscriptions: this.userSubscriptions.slice(),
            userRoles: this.roles.filter(_ => _.checked).map(_ => _.name)
        };

        this.isLoading = true;
        const { code, description } = await this._userHttpService.upsertUserProfile(this.user, this.options.isServiceProvider);

        if (code === '200') {
            this.options.isExisting = true;
            await this.loadUser();
        }

        this._snackBar.open(description, this.translate.instant('shared.close'));
        this.isLoading = false;
    }

    public async resetPassword(): Promise<void> {
        this.isLoading = true;

        if (await this._userHttpService.resetPassword(this.user)) {
            const message = this.translate.instant('admin.userEditor.passwordResetSuccess');
            this._snackBar.open(message, this.translate.instant('shared.close'));
        }
        else {
            const message = this.translate.instant('admin.userEditor.passwordResetFailure');
            this._snackBar.open(message, this.translate.instant('shared.close'));
        }

        this.isLoading = false;
    }

    public onClose(): void {
        this._dialog.close();
    }

    private async loadUser(): Promise<void> {
        this.isLoading = true;
        const { username, isExisting } = this.options;
        const { code, data, description } = await this._userHttpService.getUserProfileByUsername(username, !isExisting);

        if (code !== '200' && isExisting) {
            this._snackBar.open(description, this.translate.instant('shared.close'));
            this.onClose();

            return;
        }

        if (isExisting) {
            this.user = data;
        }
        else {
            this.user = data ?? new UserProfile(username);
        }

        await this.setupForm();
        this.isLoading = false;
    }

    private async setupForm(): Promise<void> {
        await this.assignRoles();
        // AD users have a phone number like 1.123.456.7890
        // In that case, it will show the number is invalid upon loading the user.
        // Therefore, we trim '1.' to conform to the 10 digit expectation.
        // Also remove spaces, dashes, and dots.
        this.user.phoneNumber = this.user.phoneNumber?.replace(/(^1\.)|\.|\s|-/g, '');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formFields: any = {
            firstName: [this.user.firstName, [Validators.required]],
            lastName: [this.user.lastName, [Validators.required]],
            preferredName: [this.user.preferredName, [Validators.required]],
            language: [this.user.language, [Validators.required]],
            location: [this.user.location, [Validators.required]],
            phoneNumber: [this.user.phoneNumber, [this._phoneValidator.validator]],
            emailAddress: [this.user.emailAddress, [Validators.required, this._emailValidator.validator]],
            userName: [this.user.userName, [Validators.required]],
            employeeId: [this.user.employeeId]
        };

        this.formGroup = this._formBuilder.group(formFields);
        this.userSubscriptions = this.user.subscriptions;
        this._userToResetTo = JSON.stringify(this.user);
        this._rolesToResetTo = JSON.stringify(this.roles);
        this._subscriptionsToResetTo = JSON.stringify(this.userSubscriptions);

        if (this.isInternal) {
            this.formGroup.get('employeeId').setValidators(Validators.required);
            this.formGroup.updateValueAndValidity();
        }

        if (!this.user.location) {
            this.formGroup.get('location').setValue(this._performer.branchInfo.name);
        }
        else if (!this.branches.some(_ => _.name === this.user.location)) {
            this.formGroup.get('location').setValue(null);
        }

        this.formGroup.markAllAsTouched();
    }

    private async assignRoles(): Promise<void> {
        let names = (await this._userHttpService.getUserRoles()).map(_ => _.name);

        if (!await this._permissionService.hasAdminPrivilege()) {
            const assigned = new Set(this._performer.groups);
            names = names.filter(_ => assigned.has(_.toLowerCase()));
            names = names.includes('Tech') ? names : ['Tech', ...names];
        }

        this.roles = names.map(_ => ({ name: _, checked: this.user.userRoles.some(role => role.toLowerCase() === _.toLowerCase()) }));
    }
}
