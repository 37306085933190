<form [formGroup]="searchForm" fxFill>
    <div fxLayout="column" fxLayoutGap="15px" fxFill>
        <div fxLayout="column" fxLayoutGap="15px" fxFlex>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1%" class="customer-select-select-search-bar">
                <div fxFlex="50" fxLayout="row" fxLayoutAlign="center center">
                    <mat-form-field fxFill>
                        <mat-label>{{ 'shared.customerSelect.searchLabel' | translate }}</mat-label>
                        <input type="text" placeholder="{{ 'dispatch.customerSelectionStep.startSearchPlaceholder' | translate }}" matInput formControlName="query" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedOption = $event.option.value">

                            <mat-option disabled *ngIf="defaultOptionText">
                                <mat-spinner *ngIf="canAutoComplete && isLoading" diameter="20" id="search-loading-spinner"></mat-spinner>
                                <span id="search-loading-text">{{ defaultOptionText }}</span>
                            </mat-option>

                            <ng-container *ngIf="!defaultOptionText && customers !== null">
                                <mat-option *ngFor="let customer of customers" [value]="customer.name">
                                    {{ customer.name }}
                                </mat-option>
                            </ng-container>

                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <button class="select-customer-button-container" mat-raised-button color="primary"
                    (click)="search()" [disabled]="!canSearch">
                    {{ 'shared.customerSelect.searchButton' | translate }} <mat-icon>search</mat-icon>
                </button>
            </div>

            <div *ngIf="errorLoadingContent" fxLayout fxLayoutAlign="center start" class="customer-select-no-results-found-container">
                {{ 'shared.customerSelect.noResultsErrorMessage' | translate }}
            </div>
            <div *ngIf="isLoadingContent" fxLayoutAlign="center center" fxFlex>
                <dots-loader></dots-loader>
            </div>
            <div fxLayout="row" fxLayoutAlign="center start" class="customer-selection-kendo-grid" fxFlex>
                <kendo-grid *ngIf="showResults && gridContent?.length > 0" [kendoGridBinding]="gridContent" [pageSize]="10"
                    [pageable]="true" [style.height.%]="100" filterable="menu" sortable="true">
                    <kendo-grid-column field="custId" title="{{ 'shared.customerSelect.table.customerId' | translate }}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.customerId}}
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                            <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                                <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="name" title="{{ 'shared.customerSelect.table.name' | translate }}">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                            <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                                <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="address1" title="{{ 'shared.customerSelect.table.address1' | translate }}">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                            <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="address2" title="{{ 'shared.customerSelect.table.address2' | translate }}">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                            <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="140" field="city" title="{{ 'shared.customerSelect.table.city' | translate }}">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                            <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="95" field="state" title="{{ 'shared.customerSelect.table.state' | translate }}">
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                            <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="eq">
                                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="95" field="country" title="{{ 'shared.customerSelect.table.country' | translate }}" [filterable]="false">
                    </kendo-grid-column>
                    <kendo-grid-column [width]="110" [filterable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <button matStepperNext (click)="selectCustomer(dataItem)" mat-raised-button
                                aria-label="customer select button">
                                {{ 'shared.customerSelect.table.select' | translate }}
                            </button>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>

        </div>
        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-stroked-button color="primary" class="select-customer-button-container"
                (click)="selectCustomer(null)">
                {{ 'shared.customerSelect.backButton' | translate }}
            </button>
        </div>
    </div>

</form>