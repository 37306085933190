import { Component, Inject, OnInit, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import UserInfo from '../../../../../core/data-models/user-info';
import UserComment from '../../../../../core/data-models/user-comment/user-comment';
import { UserHttpService } from '../../../../../core/services/http/user-http/user-http.service';
import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';
import { AppEventService } from '../../../../../core/services/events/app-event/app-event.service';
import { UserCommentAction } from '../../../../../core/enums/user-comment-action.enum';

@Component({
    selector: 'project-note-dialog',
    styleUrls: ['./project-note-dialog.component.scss'],
    templateUrl: './project-note-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectNoteDialogComponent implements OnInit, OnDestroy {
    private _subscription = new Subscription();
    private _comments: UserComment[] = [];
    private _currentUser: UserInfo;
    private _isLoaded = false;
    // eslint-disable-next-line
    constructor(private _userHttp: UserHttpService,
                private _projectHttp: ProjectHttpService,
                private _appEvents: AppEventService,
                private _zone: NgZone,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialogRef<ProjectNoteDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private _projectId: string,
                public translate: TranslateService) { }

    get comments(): UserComment[] {
        return this._comments;
    }

    get currentUser(): UserInfo {
        return this._currentUser;
    }

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    public async ngOnInit(): Promise<void> {
        this._dialog.disableClose = true;
        this._dialog.backdropClick().subscribe(() => this.onClose());
        this._currentUser = await this._userHttp.getUserInfo();
        await this.loadComments();
        this._subscription = this._appEvents.projectCommentChanged.subscribe(_ => this.onProjectCommentChanged(..._));
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    public async onCommentCreate(content: string): Promise<void> {
        if (await this._projectHttp.addProjectComment(this._projectId, content)) {
            await this.loadComments();
        }
    }

    public async onCommentUpdate(payload: [string, UserComment]): Promise<void> {
        const [content, comment] = payload;

        if (await this._projectHttp.updateProjectComment(this._projectId, comment.id, content)) {
            await this.loadComments();
        }
    }

    public async onCommentDelete(comment: UserComment): Promise<void> {
        if (await this._projectHttp.deleteProjectComment(this._projectId, comment.id)) {
            await this.loadComments();
        }
    }

    private async loadComments(): Promise<void> {
        this._isLoaded = false;
        this._comments = await this._projectHttp.getProjectComments(this._projectId);
        this._isLoaded = true;
    }

    private onProjectCommentChanged(projectId: string, action: UserCommentAction, comment: UserComment): void {
        this._zone.run(() => {
            const { userName, preferredName } = comment.creator;

            if (projectId !== this._projectId || userName === this._currentUser?.username) {
                return;
            }

            const ok = this.translate.instant('snackbars.ok');
            if (action === UserCommentAction.Add) {
                this._comments.push(comment);
                const addedComment = this.translate.instant('projects.notes.addedComment');
                this._snackBar.open(`${preferredName} ${addedComment}`, ok);
            }
            else if (action === UserCommentAction.Edit && this._comments.some(_ => _.id === comment.id)) {
                const index = this._comments.findIndex(_ => _.id === comment.id);
                this._comments[index] = comment;
                const editedComment = this.translate.instant('projects.notes.editedComment');
                this._snackBar.open(`${preferredName} ${editedComment}`, ok);
            }
            else if (action === UserCommentAction.Delete) {
                this._comments = this._comments.filter(_ => _.id !== comment.id);
                const deletedComment = this.translate.instant('projects.notes.deletedComment');
                this._snackBar.open(`${preferredName} ${deletedComment}`, ok);
            }

            this._comments = this._comments.slice();
        });
    }

    public onClose(): void {
        this._dialog.close();
    }
}
