/* eslint-disable max-lines-per-function */
import { Component, OnInit } from '@angular/core';
import { StateService, TransitionService, Transition } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import NavigationTab from '../../core/data-models/navigation-tab';
import { FeatureFlagService } from '../../core/services/events/feature-flags/feature-flag.service';
import { NewRelicUtilityService } from '../../core/services/utility/new-relic-utility.service';
import { FeatureFlags } from '../../core/enums/feature-flags.enum';
import { UserPermissionService } from '../../core/services/user-permission/user-permission.service';
import { WritePermission } from '../../core/enums/write-permission.enum';
import { UserPermissionType } from '../../core/enums/user-permission-type.enum';
import { ReadPermission } from '../../core/enums/read-permission.enum';

@Component({
    selector: 'admin',
    styleUrls: ['./admin.component.scss'],
    templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {
    public tabs: NavigationTab[] = [];
    public activeState = '';

    // eslint-disable-next-line max-params
    constructor(private _state: StateService,
                private _transition: TransitionService,
                private _translate: TranslateService,
                private _featureFlagService: FeatureFlagService,
                private _newrelicService: NewRelicUtilityService,
                private _userPermissionService: UserPermissionService) { }

    public async ngOnInit(): Promise<void> {
        const isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        const isQuoteEnabled = this._featureFlagService.featureFlags[FeatureFlags.UseQuotesFeature];
        const isBudgetEnabled = this._featureFlagService.featureFlags[FeatureFlags.UseBudgetFeature];
        const isKpiEnabled = this._featureFlagService.featureFlags[FeatureFlags.UseKpiFeature];
        const isWorkOrderTemplatesEnabled = this._featureFlagService.featureFlags[FeatureFlags.UseWorkOrderTemplates];
        const isDisclaimersEnabled = isInternal && await this._userPermissionService.isActionPermitted(UserPermissionType.Write, WritePermission.UpdateDisclaimer, null, false);

        this.tabs = [
            {
                name: 'General',
                displayName: this._translate.instant('admin.tabs.general'),
                state: 'settings.general',
                isVisible: true
            },
            {
                name: 'Branch Info',
                displayName: this._translate.instant('admin.tabs.branches'),
                state: 'settings.branches',
                isVisible: isInternal
            },
            {
                name: 'Service Provider Info',
                displayName: this._translate.instant('admin.tabs.serviceProviders'),
                state: 'settings.serviceProviders',
                isVisible: !isInternal
            },
            {
                name: 'Roles & Permissions',
                displayName: this._translate.instant('admin.tabs.rolesAndPermissions'),
                state: 'settings.rolesAndPermissions',
                isVisible: true
            },
            {
                name: 'Users',
                displayName: this._translate.instant('admin.tabs.users'),
                state: 'settings.users',
                isVisible: true
            },
            {
                name: 'Merge Rules',
                displayName: this._translate.instant('admin.tabs.mergeRules'),
                state: 'settings.mergeRules',
                isVisible: isInternal
            },
            {
                name: 'Service Rates',
                displayName: this._translate.instant('admin.tabs.serviceRates'),
                state: 'settings.serviceRates',
                isVisible: await this.showServiceRatesTab(isInternal)
            },
            {
                name: 'Project Attributes',
                displayName: this._translate.instant('admin.tabs.projectAttributes'),
                state: 'settings.projectAttributes',
                isVisible: isInternal
            },
            {
                name: 'Materials & Chemicals',
                displayName: this._translate.instant('admin.tabs.materialsAndChemicals'),
                state: 'settings.materialsAndChemicals',
                isVisible: isInternal
            },
            {
                name: 'Budget Templates',
                displayName: this._translate.instant('admin.tabs.budgetTemplates'),
                state: 'settings.budgetTemplates',
                isVisible: isBudgetEnabled
            },
            {
                name: 'Quote Templates',
                displayName: this._translate.instant('admin.tabs.quoteTemplates'),
                state: 'settings.quoteTemplates',
                isVisible: isQuoteEnabled
            },
            {
                name: 'Leak Report Templates',
                displayName: this._translate.instant('admin.tabs.leakReportTemplates'),
                state: 'settings.leakReportTemplates',
                isVisible: isInternal
            },
            {
                name: 'Report Disclaimers',
                displayName: this._translate.instant('admin.tabs.reportDisclaimers'),
                state: 'settings.reportDisclaimers',
                isVisible: isDisclaimersEnabled
            },
            {
                name: 'Kpi Settings',
                displayName: this._translate.instant('admin.tabs.kpiSettings'),
                state: 'settings.kpiSettings',
                isVisible: isKpiEnabled
            },
            {
                name: 'Invoice Settings',
                displayName: this._translate.instant('admin.tabs.invoiceSettings'),
                state: 'settings.invoiceSettings',
                isVisible: isInternal
            },
            {
                name: 'Task Code Settings',
                displayName: this._translate.instant('admin.tabs.taskCodeSettings'),
                state: 'settings.taskCodeSettings',
                isVisible: isInternal
            },
            {
                name: 'Customer Settings',
                displayName: this._translate.instant('admin.tabs.customerSettings'),
                state: 'settings.customerSettings',
                isVisible: isInternal
            },
            {
                name: 'Geofence Report',
                displayName: this._translate.instant('admin.tabs.geofenceReport'),
                state: 'settings.geofenceReport',
                isVisible: isInternal
            },
            {
                name: 'Data Migration',
                displayName: this._translate.instant('admin.tabs.dataMigration'),
                state: 'settings.dataMigration',
                isVisible: isInternal
            },
            {
                name: 'Work Order Templates',
                displayName: this._translate.instant('admin.tabs.workOrderTemplate'),
                state: 'settings.workOrder',
                isVisible: isWorkOrderTemplatesEnabled
            }
        ].filter(_ => _.isVisible);

        this.watchActiveState();
    }

    public isActiveState(state: string): boolean {
        return Boolean(this.activeState) && this.activeState === state;
    }

    public openTab(tab: NavigationTab): void {
        this._newrelicService.addPageAction('', '', 'SettingsNavigation', { Name: tab.name });

        if (tab.state && tab.state !== this.activeState) {
            this._state.go(tab.state);
        }
    }

    private watchActiveState(): void {
        this.activeState = this._state.$current.name;

        this._transition.onSuccess({}, (transition: Transition) => {
            this.activeState = transition.to().name;
        });
    }

    private async showServiceRatesTab(isInternal: boolean): Promise<boolean> {
        const hasServiceRatesReadPermission = await this._userPermissionService.hasReadPermission(ReadPermission.ViewServiceRates, null, false);
        const hasServiceRatesWritePermission = await this._userPermissionService.hasWritePermission(WritePermission.UpdateServiceRates, null, false);
        return isInternal && (hasServiceRatesReadPermission || hasServiceRatesWritePermission);
    }
}
