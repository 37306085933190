<div *ngIf="formGroup" [formGroup]="formGroup" class="quote-information-panel-container" fxLayout="column">
    <div class="quote-editor-basic-info" fxLayout="column" fxLayoutAlign="space-around center" fxFlex="40.5">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5%" fxFlexAlign="stretch">
            <mat-form-field *ngIf="isEditMode" fxFlex="45">
                <mat-label>{{ 'shared.quote.editor.quoteStatus' | translate }}</mat-label>

                <mat-select formControlName="status">
                    <mat-option *ngFor="let status of statuses" [value]="status.original">
                        {{ status.translated }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="!isEditMode" fxFlex="45"></div>

            <button mat-stroked-button color="primary" (click)="onCustomerSelect()" fxFlex="40">
                {{ 'shared.quote.editor.selectCustomer' | translate }}
            </button>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5%" fxFlexAlign="stretch">
            <mat-form-field fxFlex="42.5">
                <mat-label>{{ 'shared.quote.editor.quoteId' | translate }}</mat-label>
                <input matInput formControlName="quoteId" />
            </mat-form-field>

            <mat-form-field fxFlex="42.5">
                <mat-label>{{ 'shared.quote.editor.projectId' | translate }}</mat-label>
                <input matInput formControlName="originProjectId" />
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5%" fxFlexAlign="stretch">
            <mat-form-field fxFlex="55">
                <mat-label>{{ 'shared.quote.editor.customerName' | translate }}</mat-label>
                <input matInput formControlName="customerName" [readonly]="true" />

                <mat-error *ngIf="getFormField('customerName').invalid">
                    {{ 'shared.quote.editor.customerMustBeSelected' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="30">
                <mat-label>{{ 'shared.quote.editor.customerClass' | translate }}</mat-label>
                <input matInput formControlName="customerClass" [readonly]="true" />

                <mat-error *ngIf="getFormField('customerClass').invalid">
                    {{ 'shared.quote.editor.customerMustBeSelected' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>{{ 'shared.quote.editor.siteName' | translate }}</mat-label>
            <input matInput formControlName="siteName" [readonly]="true" />

            <mat-error *ngIf="getFormField('siteName').invalid">
                {{ 'shared.quote.editor.customerMustBeSelected' | translate }}
            </mat-error>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5%" fxFlexAlign="stretch">
            <mat-form-field fxFlex="42.5">
                <mat-label>{{ 'shared.quote.editor.customerId' | translate }}</mat-label>
                <input matInput formControlName="customerId" [readonly]="true" />

                <mat-error *ngIf="getFormField('customerId').invalid">
                    {{ 'shared.quote.editor.customerMustBeSelected' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="42.5">
                <mat-label>{{ 'shared.quote.editor.siteId' | translate }}</mat-label>
                <input matInput formControlName="siteId" [readonly]="true" />

                <mat-error *ngIf="getFormField('siteId').invalid">
                    {{ 'shared.quote.editor.customerMustBeSelected' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="quote-editor-additional-info" fxLayout="column" fxLayoutAlign="space-around center" fxFlex="35">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5%" fxFlexAlign="stretch">
            <mat-form-field fxFlex="50">
                <mat-label>{{ 'shared.quote.editor.branch' | translate }}</mat-label>

                <mat-select formControlName="branch">
                    <mat-option *ngFor="let branch of branches" [value]="branch.locn">
                        {{ branch.locn }}
                    </mat-option>
                </mat-select>

                <mat-error *ngIf="getFormField('branch').invalid">
                    {{ 'shared.quote.editor.requiredField' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="35">
                <mat-label>{{ (isInternal ? 'shared.quote.editor.salesCode' : 'shared.quote.editor.projectType') | translate }}</mat-label>

                <mat-select formControlName="salesCode">
                    <mat-option *ngFor="let salesCode of salesCodes" [value]="salesCode">
                        {{ getSalesCodeDescription(salesCode) }}
                    </mat-option>
                </mat-select>

                <mat-error *ngIf="getFormField('salesCode').invalid">
                    {{ 'shared.quote.editor.requiredField' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <mat-form-field>
            <mat-label>{{ 'shared.quote.editor.reportingContact' | translate }}</mat-label>
            <input matInput formControlName="reportingContact" />

            <mat-error *ngIf="getFormField('reportingContact').invalid">
                {{ 'shared.quote.editor.requiredField' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'shared.quote.editor.reportingPhone' | translate }}</mat-label>
            <input matInput formControlName="reportingPhone" appPhoneMask/>

            <mat-error *ngIf="getFormField('reportingPhone')?.errors?.required">
                {{ 'shared.quote.editor.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="getFormField('reportingPhone')?.errors?.pattern">
                * {{ phoneValidator.message }}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'shared.quote.editor.reportingEmail' | translate }}</mat-label>
            <input matInput formControlName="reportingEmail" />

            <mat-error *ngIf="getFormField('reportingEmail')?.errors?.required">
                {{ 'shared.quote.editor.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="getFormField('reportingEmail')?.errors?.email">
                * {{ emailValidator.message }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="quote-editor-estimate-info" fxLayout="column" fxLayoutAlign="space-around center" fxFlex="24.5">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5%" fxFlexAlign="stretch">
            <mat-form-field fxFlex="42.5">
                <mat-label>{{ 'shared.quote.editor.estimatedHours' | translate }}</mat-label>
                <input type="number" matInput formControlName="hours" min="0" />

                <mat-error *ngIf="getFormField('hours').invalid">
                    {{ hoursValidator.message }}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="42.5">
                <mat-label>{{ 'shared.quote.editor.estimator' | translate }}</mat-label>

                <mat-select formControlName="estimator">
                    <mat-option [value]="tbdPlaceholder">{{ 'shared.toBeDetermined' | translate }}</mat-option>
                    <mat-option *ngFor="let estimator of estimators" [value]="estimator">
                        {{ estimator }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5%" fxFlexAlign="stretch">
            <mat-form-field fxFlex="42.5">
                <mat-label>{{ 'shared.quote.editor.quoteAmount' | translate }}</mat-label>
                <input type="number" matInput formControlName="amount" min="0" />

                <mat-error *ngIf="getFormField('amount').invalid">
                    {{ 'shared.quote.editor.expectsPositiveNumber' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="42.5">
                <mat-label>{{ 'shared.quote.editor.taxRate' | translate }}</mat-label>
                <input type="number" matInput formControlName="taxRate" min="0" max="100" />

                <mat-error *ngIf="getFormField('taxRate').invalid">
                    {{ 'shared.quote.editor.expectsNumberBetweenZeroAndOneHundred' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%" fxFlexAlign="stretch">
            <span fxFlex="42.5" class="total">
                {{ 'shared.quote.editor.total' | translate }}: ${{ total }}
            </span>

            <div *ngIf="quoteThreshold" fxLayout="column"  fxFlex="42.5" class="quote-threshold">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-label>{{ 'shared.quote.editor.quoteThreshold' | translate }}</mat-label>
                    <mat-icon [matTooltip]="quoteThresholdInfo">info_outline</mat-icon>
                </div>
                <span>${{ quoteThreshold | mask: 'separator.2': {thousandSeparator: ',' } }}</span>
            </div>
        </div>
    </div>
</div>
