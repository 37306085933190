import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import Branch from 'src/app/core/data-models/branch';
import { BranchHttpService } from '../../../../../core/services/http/branch-http/branch-http.service';

@Component({
    selector: 'app-change-service-provider-dialog',
    templateUrl: './change-service-provider-dialog.component.html',
    styleUrls: ['./change-service-provider-dialog.component.scss']
})
export class ChangeServiceProviderDialogComponent implements OnInit {
    public selectedProvider = '';
    public providers: Branch[] = [];

    constructor(private _dialogRef: MatDialogRef<ChangeServiceProviderDialogComponent>,
        private _branchService: BranchHttpService,
        @Inject(MAT_DIALOG_DATA) public oldProviderCode: string) { }

    public async ngOnInit(): Promise<void> {
        this.providers = (await this._branchService.getAllBranches()).filter(_ => !_.isDeactivated && _.code !== this.oldProviderCode);
    }

    public onClose(): void {
        this._dialogRef.close('');
    }

    public submit(): void {
        if (this.selectedProvider) {
            this._dialogRef.close(this.selectedProvider);
        }
    }
}
