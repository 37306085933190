import { GrossProfitPercentageRate } from './gross-profit-percentage-rate';

export class BranchServiceRate {
    public branchCode!: string;
    public laborHourlyRate!: number;
    public insurancePercentageRate!: number;
    public indirectsPercentageRate!: number;
    public subcontractGrossProfitPercentageRate!: number;
    public grossProfitPercentageRates: GrossProfitPercentageRate[] = [];
}
