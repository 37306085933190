<div class="project-details-action-bar-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" *ngIf="!disableActionBar">
    <dropdown-button *ngIf="dispatchOptions.length"
        [icon]="'local_shipping'"
        [text]="translate.instant('projects.detailsActionBar.dispatch')"
        [options]="dispatchOptions"
        (optionSelect)="onDispatch($event)">
    </dropdown-button>

    <dropdown-button *ngIf="workflows.length"
        [icon]="'account_tree'"
        [text]="translate.instant('projects.detailsActionBar.workflow')"
        [options]="workflows"
        (optionSelect)="onWorkflowSelect($event)">
    </dropdown-button>

    <dropdown-button *ngIf="jobOptions.length > 1"
        class="visit-selection-button"
        [icon]="'table_view'"
        [text]="currentJobOption"
        [options]="jobOptions"
        (optionSelect)="onJobOptionSelect($event)">
    </dropdown-button>

    <div fxFlex></div>

    <button *ngIf="showMergeRules" mat-raised-button color="primary" (click)="onMergeRulesClicked()">
        {{"projects.detailsActionBar.mergeRules" | translate}}
    </button>

    <dropdown-button *ngIf="printOptions.length"
        [icon]="'print'"
        [text]="translate.instant('projects.detailsActionBar.print')"
        [options]="printOptions"
        [position]="'before'"
        (optionSelect)="printOptionSelect.emit($event)">
    </dropdown-button>

    <dropdown-button class="actions-button"
        [disabled]="!actions.length"
        [icon]="'widgets'"
        [text]="translate.instant('projects.detailsActionBar.actions')"
        [options]="actions"
        [position]="'before'"
        (optionSelect)="actionSelect.emit($event)">
    </dropdown-button>

    <mat-button-toggle-group class="project-page-toggles" [value]="selectedMenuItem">
        <mat-button-toggle *ngIf="showToggles"
            [value]="projectDetailsSection.BasicInfo"
            (click)="changeProjectContentSelection(projectDetailsSection.BasicInfo)">

            {{ 'projects.detailsActionBar.details' | translate }}
        </mat-button-toggle>

        <mat-button-toggle *ngIf="currentJob.hasWorkChecklist"
            [value]="projectDetailsSection.Checklist"
            (click)="changeProjectContentSelection(projectDetailsSection.Checklist)">

            {{ 'projects.detailsActionBar.checklist' | translate }}
        </mat-button-toggle>

        <mat-button-toggle *ngIf="currentJob.hasContentReport"
            [value]="projectDetailsSection.JobReport"
            (click)="changeProjectContentSelection(projectDetailsSection.JobReport)">

            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
                <span>{{ 'projects.detailsActionBar.report' | translate }}</span>

                <button class="new-window-button"
                    matTooltipPosition="above"
                    [matTooltip]="translate.instant('shared.openInNewWindow')"
                    (click)="openProjectContentInNewWindow($event, projectDetailsSection.JobReport)"
                    fxLayout="row"
                    fxLayoutAlign="center center">

                    <mat-icon>open_in_new</mat-icon>
                </button>
            </div>
        </mat-button-toggle>

        <mat-button-toggle *ngIf="currentJob.hasDailySummary"
            [value]="projectDetailsSection.DailySummary"
            (click)="changeProjectContentSelection(projectDetailsSection.DailySummary)">

            {{ 'projects.detailsActionBar.dailySummary' | translate }}
        </mat-button-toggle>

        <mat-button-toggle *ngIf="currentJob.hasSummary"
            [value]="projectDetailsSection.Summary"
            (click)="changeProjectContentSelection(projectDetailsSection.Summary)">

            {{ 'projects.detailsActionBar.summary' | translate }}
        </mat-button-toggle>

        <mat-button-toggle *ngIf="showLabor"
            [value]="projectDetailsSection.Labor"
            (click)="changeProjectContentSelection(projectDetailsSection.Labor)">

            {{ 'projects.detailsActionBar.labour' | translate }}
        </mat-button-toggle>
    </mat-button-toggle-group>
</div>

<div class="project-details-action-bar-container" id="disabled-project-action-bar" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="disableActionBar">
    <h4>{{ 'projects.detailsActionBar.refreshNeededMessage' | translate }}</h4>
    <button mat-raised-button (click)="reloadProject.emit()">
        <mat-icon>refresh</mat-icon>&nbsp;Refresh
    </button>
</div>
