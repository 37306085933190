<div class="stock-log-report-container" fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-form-field *ngIf="isBranchFilterVisible">
            <mat-label>{{ 'dataReport.stockLogsReport.branchField.label' | translate }}</mat-label>

            <mat-select [(ngModel)]="query.branchCode">
                <mat-option value="">{{ 'dataReport.stockLogsReport.branchField.optionAll' | translate }}</mat-option>
                <mat-option *ngFor="let branch of branches" [value]="branch.code">{{ branch.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'shared.startDate' | translate }}</mat-label>

            <input type="text"
                matInput
                readonly
                [matDatepicker]="startDate"
                [(ngModel)]="query.timeInterval.startTime"
                (click)="startDate.open()" />

            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'shared.endDate' | translate }}</mat-label>

            <input type="text"
                matInput
                readonly
                [matDatepicker]="endDate"
                [(ngModel)]="query.timeInterval.endTime"
                (click)="endDate.open()" />

            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="loadStockLogs()">
            {{ 'dataReport.stockLogsReport.loadReport' | translate }}
        </button>
    </div>

    <div class="report-grid-div" fxLayout="row">
        <kendo-grid [kendoGridBinding]="data"
            [pageSize]="pageSize"
            [pageable]="true"
            [loading]="isLoading"
            [style.height.%]="100">

            <ng-template kendoGridToolbarTemplate>
                <button kendoGridExcelCommand [disabled]="!data.length">{{ 'shared.exportExcel' | translate }}</button>
            </ng-template>

            <kendo-grid-column field="projectId" [title]="translate.instant('dataReport.stockLogsReport.projectIdHeader')"></kendo-grid-column>
            <kendo-grid-column field="projectName" [title]="translate.instant('dataReport.stockLogsReport.projectNameHeader')" width="450"></kendo-grid-column>
            <kendo-grid-column field="materialCost" [title]="translate.instant('dataReport.stockLogsReport.materialCostHeader')"></kendo-grid-column>
            <kendo-grid-column field="currency" [title]="translate.instant('dataReport.stockLogsReport.currencyHeader')"></kendo-grid-column>
            <kendo-grid-column field="branchCode" [title]="translate.instant('dataReport.stockLogsReport.branchCodeHeader')"></kendo-grid-column>
            <kendo-grid-column field="salesCode" [title]="translate.instant('dataReport.stockLogsReport.salesCodeHeader')"></kendo-grid-column>

            <kendo-grid-column field="createdTime" [title]="translate.instant('dataReport.stockLogsReport.createdTimeHeader')">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.timeInfo.createdTime | branchLocalTime: user.branch : branches }}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-excel [fetchData]="getExcelData"
                [fileName]="translate.instant('dataReport.stockLogsReport.excelFileName') + '.xlsx'"
                filterable="true">
            </kendo-grid-excel>
        </kendo-grid>
    </div>
</div>
