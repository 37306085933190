import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import JobCodeModel from '../../../../core/data-models/job-code-model';
import MaterialItem from '../../../../core/data-models/job-materials/material-item';
import ValidatorSet from '../../../../core/data-models/validator-set';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';
import { MaterialHttpService } from '../../../../core/services/http/material-http/material-http.service';
import { SalesCodeConfigService } from '../../../../core/services/sales-code-config/sales-code-config.service';
import { ValidatorFactory } from '../../../../core/services/validator-factory/validator-factory.service';

@Component({
    selector: 'job-material-edit',
    styleUrls: ['./job-material-edit.component.scss'],
    templateUrl: './job-material-edit.component.html',
    encapsulation: ViewEncapsulation.None
})
export class JobMaterialEditComponent implements OnInit {
    public formGroup: UntypedFormGroup;
    public title: string;
    public allJobCodes: JobCodeModel[];
    public remainingJobCodes: JobCodeModel[];
    public currentJobCodes: JobCodeModel[];
    public filteredRemainingJobCodes: JobCodeModel[];
    public readonly separatorKeysCodes = [ENTER, COMMA] as const;
    public isLoaded = false;
    private _currencyValidator = this._validatorFactory.createCurrencyValidator();

    // eslint-disable-next-line max-params
    constructor(
        private _dialog: MatDialogRef<JobMaterialEditComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _salesCodeConfigService: SalesCodeConfigService,
        private _userHttpService: UserHttpService,
        private _materialService: MaterialHttpService,
        private _validatorFactory: ValidatorFactory,
        public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: {material: MaterialItem}
    ) {
    }

    get nameControl(): UntypedFormControl {
        return this.formGroup.get('name') as UntypedFormControl;
    }

    get jobCodeControl(): UntypedFormControl {
        return this.formGroup.get('jobCode') as UntypedFormControl;
    }

    get valueControl(): UntypedFormControl {
        return this.formGroup.get('value') as UntypedFormControl;
    }

    get currencyValidator(): ValidatorSet {
        return this._currencyValidator;
    }

    get saveDisabled(): boolean {
        return this.formGroup.invalid || !this.currentJobCodes?.length;
    }

    public async ngOnInit(): Promise<void> {
        const user = await this._userHttpService.getUserInfo();
        const editMaterial = this.translate.instant('dataReport.materialsReport.editMaterial');
        const createMaterial = this.translate.instant('dataReport.materialsReport.createMaterial');
        this.title = this.data.material.id ? editMaterial : createMaterial;
        this.allJobCodes = await this._salesCodeConfigService.getJobCodes(true, user.language);
        const codes = this.data.material.jobCodes.map(_ => _.code);
        this.currentJobCodes = this.allJobCodes.filter(_ => codes.includes(_.code));
        this.remainingJobCodes = this.allJobCodes.filter(_ => !codes.includes(_.code));

        this.formGroup = this._formBuilder.group({
            name: new UntypedFormControl(this.data.material.name, [Validators.required]),
            value: new UntypedFormControl(this.data.material.value, [this._currencyValidator.validator, Validators.required]),
            unit: new UntypedFormControl(this.data.material.unit, [Validators.required]),
            jobCodes: new UntypedFormControl(this.data.material.jobCodes, []),
            note: new UntypedFormControl(this.data.material.note, []),
            jobCode: new UntypedFormControl('', [])
        });

        this.jobCodeControl.valueChanges
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                startWith(null)
            )
            .subscribe((x: string | null) => {
                this.filteredRemainingJobCodes = x && typeof x === 'string' ? this.filterByCodeOrName(x) : this.remainingJobCodes.slice();
            });

        this.isLoaded = true;
    }

    public onJobCodeSelected(event: MatAutocompleteSelectedEvent): void {
        const jobCode = event.option.value as JobCodeModel;
        if (this.currentJobCodes.some(_ => _.code === jobCode.code)) {
            return;
        }

        this.remainingJobCodes = this.remainingJobCodes
            .filter(_ => _.code !== jobCode.code)
            .sort((a, b) => a.code.localeCompare(b.code));
        this.filteredRemainingJobCodes = this.remainingJobCodes.slice();

        this.currentJobCodes.push(jobCode);
        this.currentJobCodes = this.currentJobCodes.sort((a, b) => a.code.localeCompare(b.code));
    }

    public onChipTokenAdded(event: MatChipInputEvent): void {
        event.input.value = null;
    }

    public onJobCodeRemoved(jobCode: JobCodeModel): void {
        this.currentJobCodes = this.currentJobCodes.filter(p => p.code !== jobCode.code);
        this.remainingJobCodes.push(jobCode);
        this.remainingJobCodes = this.remainingJobCodes.sort((a, b) => a.code.localeCompare(b.code));
        this.filteredRemainingJobCodes = this.remainingJobCodes.slice();
    }

    public async onSave(): Promise<void> {
        if (this.formGroup.invalid) {
            return;
        }

        const request = {
            ...this.data.material,
            jobCodes: this.currentJobCodes.slice(),
            name: this.formGroup.value.name,
            value: this.formGroup.value.value,
            unit: this.formGroup.value.unit,
            note: this.formGroup.value.note
        } as MaterialItem;

        let result;
        if (this.data.material.id) {
            result = await this._materialService.updateMaterial(request);
        }
        else {
            result = await this._materialService.createMaterial(request);
        }
        this._dialog.close(result);
    }

    public onClose(): void {
        this._dialog.close();
    }

    private filterByCodeOrName(value: string): JobCodeModel[] {
        const lowerCaseValue = value.toLowerCase();
        return this.remainingJobCodes
            .filter(jobCode =>
                jobCode.code.toLowerCase().includes(lowerCaseValue) ||
                jobCode.name.toLowerCase().includes(lowerCaseValue)
            );
    }
}
