import JobCodeModel from '../job-code-model';

export default class MaterialItem {
    public id: string;
    public name: string;
    public value: number;
    public unit: string;
    public note: string;
    public lastModifiedTimeMillis: string;
    public jobCodes: JobCodeModel[] = [];
}
