import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-upload-image-dialog',
    styleUrls: ['./upload-image-dialog.component.scss'],
    templateUrl: './upload-image-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UploadImageDialogComponent {
    public selectedImage: Blob;
    public croppedImage: string;
    public showCroppedImage = false;

    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: {title: string},
        public translate: TranslateService,
        private _dialog: MatDialogRef<UploadImageDialogComponent>) {
    }

    /* istanbul ignore next */
    public photoDropped(files: NgxFileDropEntry[]): void {
        const file = files[0];
        if (file.fileEntry.isFile) {
            const fileEntry = file.fileEntry as FileSystemFileEntry;

            fileEntry.file(_ => {
                this.selectedImage = _;
            });
        }
    }

    public imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
    }

    public imageLoaded(): void {
        this.showCroppedImage = true;
    }

    public onClose(emit: boolean): void {
        this._dialog.close(emit? this.croppedImage: null);
    }
}
