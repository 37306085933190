import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import WorkOrderStepConfig from '../../../../core/data-models/dispatch/work-order-step-config';

@Component({
    selector: 'app-work-order-edit-dialog',
    styleUrls: ['./work-order-edit-dialog.component.scss'],
    templateUrl: './work-order-edit-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class WorkOrderEditDialogComponent {

    public constructor(public translate: TranslateService,
        private _dialog: MatDialogRef<WorkOrderEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: WorkOrderStepConfig,) {
    }

    public onStepClicked(step: string): void {
        if (step === 'next' && this.data.completed) {
            this.onClose(true);
        }
    }

    public onClose(emit: boolean): void {
        this._dialog.close(emit? this.data : null);
    }
}
