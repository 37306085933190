import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import ReportQuery from '../../../data-models/data-report/report-query';
import DeletedReportSummary from '../../../data-models/data-report/deleted-report-summary';
import ServiceOpsRejectionSummary from '../../../data-models/data-report/service-ops-rejection-summary';
import JsonUtility from '../../../services/utility/json-utility.service';
import { environment } from '../../../../../environments/environment';
import CodeDescription from '../../../data-models/code-description';
@Injectable({
    providedIn: 'root'
})
export class JobReportHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/job/report`;

    constructor(private _http: HttpClient) { }

    public async getDeletedJobSummary(query: ReportQuery): Promise<DeletedReportSummary[]> {
        const branch = query.branch ?? '';
        const salesCode = query.jobType ?? '';
        const start = query.startDate?.getTime() ?? 0;
        const end = query.endDate?.getTime() ?? 0;
        const projectId = query.projectId ?? '';
        const queryString = `branch=${branch}&salesCode=${salesCode}&startTime=${start}&endTime=${end}&projectId=${projectId}`;
        const endpoint = `${this._api}/deletedjobs/summary?${queryString}`;

        return await this.sendGetRequest<DeletedReportSummary[]>(endpoint);
    }

    public async getServiceOpsRejectedJobSummary(query: ReportQuery): Promise<ServiceOpsRejectionSummary[]> {
        const queryString = `branch=${query.branch ?? ''}&salesCode=${query.jobType ?? ''}`;
        const endpoint = `${this._api}/service/rejected/summary?${queryString}`;

        return await this.sendGetRequest<ServiceOpsRejectionSummary[]>(endpoint);
    }
    // eslint-disable-next-line
    public async reinstateDeletedJob(projectId: string): Promise<any> {
        try {
            const url = `${environment.fswApiUrl}api2/service/project/${projectId}/restore-project`;
            // eslint-disable-next-line
            const data = await this._http.post<any>(url, null).toPromise();
            return new CodeDescription('200', data);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            return new CodeDescription(httpError.status.toString(), httpError.error);
        }
    }

    private async sendGetRequest<T>(endpoint: string): Promise<T> {
        try {
            const data = await this._http.get<T>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch (error) {
            throw error.status;
        }
    }
}
