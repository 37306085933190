import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'rating-stars',
    styleUrls: ['./rating-stars.component.scss'],
    templateUrl: './rating-stars.component.html',
    encapsulation: ViewEncapsulation.None
})
export class RatingStarsComponent {
    @Input() public size = 24;
    @Input() public total = 5;
    @Input() public stars = 0;

    get starsArray(): number[] {
        return new Array(this.total).fill(0).map((_, i) => i);
    }

    get starStyle(): { [key: string]: string } {
        const size = `${this.size}px`;

        return { width: size, height: size, 'font-size': size };
    }
}
