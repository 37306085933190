import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import ProjectQuestions from '../../../core/data-models/project-attributes/project-questions';
import ProjectAttributesDropdown from '../../../core/data-models/project-attributes-dropdowns';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import CodeDesc from '../../../core/data-models/generic/code-description';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { ValidatorFactory } from '../../../core/services/validator-factory/validator-factory.service';
import ProjectQuestionValidCheck from '../../../core/data-models/project-questions-valid-check';
import { TranslateService } from '@ngx-translate/core';
import ProjectQuestionCheckbox from '../../../core/data-models/project-questions/project-question-checkbox';
import ProjectQuestionContainer from '../../../core/data-models/project-questions/project-question-container';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';

@Component({
    selector: 'project-questions-card',
    styleUrls: ['./project-questions-card.component.scss'],
    templateUrl: './project-questions-card.component.html'
})

export class ProjectQuestionsCardComponent implements OnInit, OnChanges {
    @Input() public projectQuestions: ProjectQuestions;
    @Input() public isUSProject = true;
    @Input() public readOnly = true;
    @Output() result = new EventEmitter<ProjectQuestionValidCheck>();
    public projectValues: CodeDesc[] = [];
    public formGroup: UntypedFormGroup;
    public currencyValidator = this._validatorFactory.createCurrencyValidator();
    public checkboxes: ProjectQuestionCheckbox[];
    public numbers: ProjectQuestionContainer[];
    private dropdowns: ProjectAttributesDropdown;
    private _isInit = false;

    constructor(
        private _validatorFactory: ValidatorFactory,
        private _formBuilder: UntypedFormBuilder,
        private _projectService: ProjectHttpService,
        private _featureFlagService: FeatureFlagService,
        public translate: TranslateService
    ) { }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!this._isInit) {
            return;
        }

        if (!this.readOnly && changes.projectQuestions?.currentValue) {
            await this.initializeFormGroup(changes.projectQuestions.currentValue);
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public async ngOnInit(): Promise<void> {
        const showResidentialAttribute = this._featureFlagService.featureFlags[FeatureFlags.UseResidentialProjectAttribute] ?? 'none';

        this.checkboxes = [
            {
                model: 'heightPay',
                label: this.translate.instant('shared.projectQuestions.heightPremiumLabel'),
                toolTip: this.translate.instant('shared.projectQuestions.heightPremiumTooltip'),
                show: 'ca'
            },
            {
                model: 'pitchRate',
                label: this.translate.instant('shared.projectQuestions.pitchPremiumLabel'),
                toolTip: this.translate.instant('shared.projectQuestions.pitchPremiumTooltip'),
                show: 'all'
            },
            {
                model: 'swingRate',
                label: this.translate.instant('shared.projectQuestions.swingPremiumLabel'),
                toolTip: this.translate.instant('shared.projectQuestions.swingPremiumTooltip'),
                show: 'all'
            },
            {
                model: 'residential',
                label: this.translate.instant('shared.projectQuestions.residentialLabel'),
                toolTip: this.translate.instant('shared.projectQuestions.residentialTooltip'),
                show: showResidentialAttribute
            },
            {
                model: 'epsca',
                label: this.translate.instant('shared.projectQuestions.epscaLabel'),
                toolTip: this.translate.instant('shared.projectQuestions.epscaTooltip'),
                show: 'ca'
            },
            {
                model: 'weldingPay',
                label: this.translate.instant('shared.projectQuestions.weldingPremiumLabel'),
                toolTip: this.translate.instant('shared.projectQuestions.weldingPremiumTooltip'),
                show: 'us'
            }
        ];

        this.numbers = [
            {
                content: 'otWeekly',
                title: this.translate.instant('shared.projectQuestions.otWeeklyThresholdTitle'),
                toolTip: this.translate.instant('shared.projectQuestions.otWeeklyThresholdTooltip')
            },
            {
                content: 'otDaily',
                title: this.translate.instant('shared.projectQuestions.otDailyThresholdTitle'),
                toolTip: this.translate.instant('shared.projectQuestions.otDailyThresholdTooltip')
            },
            {
                content: 'dtWeekly',
                title: this.translate.instant('shared.projectQuestions.dtWeeklyThresholdTitle'),
                toolTip: this.translate.instant('shared.projectQuestions.dtWeeklyThresholdTooltip')
            },
            {
                content: 'dtDaily',
                title: this.translate.instant('shared.projectQuestions.dtDailyThresholdTitle'),
                toolTip: this.translate.instant('shared.projectQuestions.dtDailyThresholdTooltip')
            },
            {
                content: 'mealAllowance',
                title: this.translate.instant('shared.projectQuestions.mealAllowanceTitle'),
                toolTip: this.translate.instant('shared.projectQuestions.mealAllowanceTooltip')
            },
            {
                content: 'intlNRetn',
                title: this.translate.instant('shared.projectQuestions.initialAndReturnTitle'),
                toolTip: this.translate.instant('shared.projectQuestions.initialAndReturnTooltip')
            },
            {
                content: 'foremanPremium',
                title: this.translate.instant('shared.projectQuestions.foremanPremiumTitle'),
                toolTip: this.translate.instant('shared.projectQuestions.foremanPremiumTooltip')
            }
        ];

        if (!this.readOnly) {
            await this.initializeFormGroup(this.projectQuestions);
        }

        this._isInit = true;
    }

    public show(item: { model: string, label: string, show: string }): boolean {
        return item.show === 'all' || item.show === 'us' && this.isUSProject || item.show === 'ca' && !this.isUSProject;
    }

    public getFormField(name: string): AbstractControl {
        return this.formGroup.get(name);
    }

    private async initializeFormGroup(projectQuestions: ProjectQuestions): Promise<void> {
        this.formGroup = this._formBuilder.group({
            otWeekly: [projectQuestions[this.numbers[0].content], this.currencyValidator.validator],
            otDaily: [projectQuestions[this.numbers[1].content], this.currencyValidator.validator],
            dtWeekly: [projectQuestions[this.numbers[2].content], this.currencyValidator.validator],
            dtDaily: [projectQuestions[this.numbers[3].content], this.currencyValidator.validator],
            mealAllowance: [
                projectQuestions[this.numbers[4].content], this.currencyValidator.validator],
            intlNRetn: [
                projectQuestions[this.numbers[5].content], this.currencyValidator.validator],
            foremanPremium: [projectQuestions[this.numbers[6].content], this.currencyValidator.validator]
        });

        this.formGroup.valueChanges.subscribe(() => this.syncFormFields());

        const type = this.isUSProject ? 'US' : 'CA';
        this.dropdowns = await this._projectService.getProjectAttributesDropdowns(type);
        this.projectValues = this.dropdowns.projectValue;
        this.formGroup.markAllAsTouched();
        this.checkAndSendResult();
    }

    public checkAndSendResult(): void {
        this.result.emit(new ProjectQuestionValidCheck(this.formGroup.valid, this.projectQuestions));
    }

    public updatePrimeContractValue(ev: MatSelectChange): void {
        this.projectQuestions.projValue = this.projectValues.find(_ => _.codeValue === ev.value);
        this.result.emit(new ProjectQuestionValidCheck(!this.formGroup.invalid, this.projectQuestions));
    }

    private syncFormFields(): void {
        const values = this.formGroup.value;
        this.projectQuestions = { ...this.projectQuestions, ...values } as ProjectQuestions;
    }
}
