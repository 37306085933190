<div class="timeline-card-container" fxLayout="row">
    <div class="project-timeline-legend" fxFlex="17" fxLayout="column">
        <mat-checkbox [checked]="isSelectAll" (change)="toggleSelectAll($event)">
            <span class="project-timeline-legend-type">{{ 'projects.timeline.selectAll' | translate }}</span>
        </mat-checkbox>
        <mat-checkbox *ngFor="let type of types" [(ngModel)]="type.checked" (change)="onTypeChange()">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                <div class="project-timeline-legend-icon"
                    [style.background-color]="type.backgroundColor"
                    fxLayoutAlign="center center">
                    <app-custom-icon [icon]="type.icon"></app-custom-icon>
                </div>
                <span class="project-timeline-legend-type">{{ type.localizedName }}</span>
            </div>
        </mat-checkbox>
    </div>
    <div fxLayout="row" class="timeline-events-container" fxFlex>
        <div class="timeline-card-outer-splitter"></div>

        <div class="timeline-event-cards-wrapper">
            <div *ngIf="targetPerformer" class="timeline-event-cards-target-performer" fxLayoutAlign="center center">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px">
                    <span>{{ 'projects.timeline.usersActions' | translate: { user: (targetPerformer | titlecase) } }}</span>

                    <div fxLayoutAlign="center center">
                        <mat-icon (click)="targetPerformer = null">clear</mat-icon>
                    </div>
                </div>
            </div>

            <div *ngFor="let event of filteredEvents; index as i"
                class="timeline-event-cards-content"
                fxLayoutAlign="start center">

                <div *ngIf="!(i % 2)" fxFlex></div>

                <timeline-event-card [ngStyle]="{ 'animation-delay': i * 0.02 + 0.3 + 's' }"
                    [ngClass]="{ 'timeline-card-event-hovered': event === hoveredEvent }"
                    [event]="event"
                    [hasTargetPerformer]="targetPerformer"
                    (filterByPerformer)="onFilterByPerformer($event)">
                </timeline-event-card>

                <div *ngIf="i % 2" fxFlex></div>
                <div class="timeline-card-inner-splitter"></div>

                <div class="timeline-card-icons"
                    [ngStyle]="{ 'animation-delay': i * 0.02 + 's' }"
                    [style.background-color]="event.backgroundColor"
                    fxLayoutAlign="center center"
                    (mouseover)="hoveredEvent = event"
                    (mouseout)="hoveredEvent = null">

                    <app-custom-icon [icon]="event.icon"></app-custom-icon>
                </div>
            </div>
        </div>
    </div>
</div>
