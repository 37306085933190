<div class="quote-template-editor-container">
    <div fxLayout="column">
        <textarea [id]="editorId"></textarea>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
        <button *ngFor="let option of customButtonOptions" (click)="addPlaceholder(option.identifier, option.placeholder)">
            {{ option.name }}
        </button>
        <small id="template-helper-text" *ngIf="!content">{{ 'shared.quote.templateEditor.helperText' | translate }} </small>
    </div>
</div>
