<mat-expansion-panel #matExpand
    class="expandable-textarea-card-container"
    fxLayout="column"
    [disabled]="!allowExpand"
    [expanded]="!allowExpand"
    [hideToggle]="!allowExpand">

    <mat-expansion-panel-header>
        <mat-panel-title>
            <div class="expandable-textarea-card-title" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
                <mat-icon>{{ icon }}</mat-icon>
                <span>{{ getCardTitle(matExpand.expanded) }}</span>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="expandable-textarea-wrapper">
        <grid-display-item [title]="contentTitle"
            [content]="content"
            [isHtml]="isHtml"
            [useTextarea]="true"
            [editOptions]="editOptions"
            (contentUpdate)="contentUpdate.emit($event)">
        </grid-display-item>
    </div>
</mat-expansion-panel>
