<dialog-action-card class="add-follow-up-log-dialog-container"
    [icon]="options.icon"
    [title]="options.title"
    [isLoaded]="true"
    (close)="onClose(null)">

    <add-follow-up-log *ngIf="options.icon === 'forum'"
        (result)="onClose($event)"
        fxFlex>
    </add-follow-up-log>

    <add-follow-up-reminder *ngIf="options.icon === 'timer'"
        (result)="onClose($event)"
        fxFlex>
    </add-follow-up-reminder>
</dialog-action-card>
