import Branch from '../branch';
import Crew from '../crew';
import GeoCoordinate from '../geo-coordinate';

export default class DispatchProjectInfoData {
    public branch: Branch;
    public salesCode: string;
    public scope: string;
    public estimatedHours: number;
    public estimatedDays: number;
    public name: string;
    public customerPurchaseOrder: string;
    public notToExceedAmount: number;
    public dispatcherNotes: string;
    public sendToLocalQueue: boolean;
    public crew: Crew;
    public drawingRequired: boolean;
    public retentionMethod: string;
    public retentionPercent: number;
    public projectBillWith: string;
    public taxStatus: string;
    public budgetType: string;
    public geoCode: GeoCoordinate;
    public requireOvernightSealPhoto: boolean;
    public roofingSquares: string;
    public greenRoofOption: string;
    public jobType: string;
    public completeByDate: string;
    public accountManager: string;
}
