import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TemplateHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service`;
    private pageAndQuestions: Map<string, string[]>;

    constructor(private _http: HttpClient) { }

    public async loadPageAndQuestionsFor310(): Promise<Map<string, string[]>> {
        return await this.loadPageAndQuestions('310');
    }

    private async loadPageAndQuestions(jobType: string): Promise<Map<string, string[]>> {
        try {
            if (!this.pageAndQuestions) {
                const endpoint = `${this._api}/jobType/questions/pages/${jobType}`;
                const data = await this._http.get(endpoint).toPromise();
                this.pageAndQuestions = new Map<string, string[]>(Object.entries(data));
            }

            return this.pageAndQuestions;
        }
        catch {
            return new Map<string, string[]>();
        }
    }
}
