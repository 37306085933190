/* eslint-disable max-params */
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import CustomIcon from '../../../core/data-models/custom-icon';
import TemplateFieldMeta from '../../../core/data-models/template-field-meta';
import ProjectGeneralInfo from '../../../core/data-models/project-general-info/project-general-info';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { FeatureFlagService } from './../../../core/services/events/feature-flags/feature-flag.service';

@Component({
    selector: 'project-report-content',
    styleUrls: ['./project-report-content.component.scss'],
    templateUrl: './project-report-content.component.html'
})
export class ProjectReportContentComponent implements OnInit {
    @Input() public generalInfo: ProjectGeneralInfo;
    @Input() public jobId = 0;
    @Input() public isLeakReport = false;
    public icon: CustomIcon;
    public isEditStateChanging = false;
    public currentPage = 0;
    public useInternalFeatures: boolean;
    private _readonlyTemplate: TemplateFieldMeta;
    private _editableTemplate: TemplateFieldMeta;

    constructor(private _projectHttp: ProjectHttpService,
        private _snackBar: MatSnackBar,
        private _salesCodeConfigService: SalesCodeConfigService,
        private _translate: TranslateService,
        private _featureFlagService: FeatureFlagService,
    ) { }

    get readonlyTemplate(): TemplateFieldMeta {
        return this._readonlyTemplate;
    }

    get editableTemplate(): TemplateFieldMeta {
        return this._editableTemplate;
    }

    get isMultiPage(): boolean {
        return this._readonlyTemplate.fields.every(_ => /^[^.]+\.subsections/i.test(_.mapping));
    }

    get allowEdit(): boolean {
        const { jobs } = this.generalInfo;
        const index = this.jobId === -1 ? jobs.length - 1 : this.jobId;

        return jobs[index].isContentReportEditable;
    }

    public async ngOnInit(): Promise<void> {
        const { projectId } = this.generalInfo.basicInfo;
        this._readonlyTemplate = await this._projectHttp.getJobReportTemplateField(projectId, this.jobId);
        this._editableTemplate = null;

        const jobIndex = this.jobId < 0 ? this.generalInfo.jobs.length - 1 : this.jobId;
        const jobType = this.generalInfo.jobs[jobIndex].salesCode;
        this.icon = this._salesCodeConfigService.getJobTypeIcon(jobType);

        this.useInternalFeatures = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures]?? false;
    }

    public async onEdit(template: TemplateFieldMeta): Promise<void> {
        const { projectId } = this.generalInfo.basicInfo;
        this.isEditStateChanging = true;
        this._editableTemplate = await this._projectHttp.getEditableTemplateField(projectId, template);
        this.isEditStateChanging = false;
    }

    public async onSave(template: TemplateFieldMeta): Promise<void> {
        const { projectId } = this.generalInfo.basicInfo;
        this.isEditStateChanging = true;

        if (await this._projectHttp.updateProjectTemplateField(projectId, template)) {
            await this.ngOnInit();
        }
        else {
            const failedToSave = this._translate.instant('shared.projectReport.failedToSave');
            const ok = this._translate.instant('snackbars.ok');
            this._snackBar.open(failedToSave, ok);
        }

        this.isEditStateChanging = false;
    }

    public onCancel(): void {
        this._editableTemplate = null;
    }
}
