<dialog-action-card class="rejection-reason-dialog-container"
    [icon]="'notification_important'"
    [title]="translate.instant('projects.rejectionReasonDialog.chooseRejectionReasons')"
    [isLoaded]="true"
    (close)="onClose(false)">

    <div class="rejection-reason-dialog-content" fxLayout="column">
        <mat-checkbox *ngFor="let option of options" [(ngModel)]="option.checked">
            {{ option.name }}
        </mat-checkbox>

        <mat-checkbox [(ngModel)]="showOther">{{ 'projects.rejectionReasonDialog.other' | translate }}</mat-checkbox>

        <mat-form-field *ngIf="showOther">
            <mat-label>{{ 'projects.rejectionReasonDialog.typeReason' | translate }}</mat-label>

            <textarea matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="8"
                [(ngModel)]="other">
            </textarea>
        </mat-form-field>
    </div>

    <div class="rejection-reason-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="onClose()" [disabled]="!canReject">{{ 'projects.rejectionReasonDialog.reject' | translate }}</button>
    </div>
</dialog-action-card>
