import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import FileListRecord from '../../../../core/data-models/file-list';
import { DownloadHttpService } from '../../../../core/services/http/download-http/download-http.service';

@Component({
    selector: 'app-pdf-file-viewer-dialog',
    styleUrls: ['./pdf-file-viewer-dialog.component.scss'],
    templateUrl: './pdf-file-viewer-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PdfFileViewerDialogComponent implements OnInit {
    public pdfSrc: string;

    constructor(public translate: TranslateService,
        private _dialog: MatDialogRef<PdfFileViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FileListRecord[],
        private _downloadService: DownloadHttpService,
        private _snackBar: MatSnackBar,
    ) { }

    public ngOnInit(): void {
        this.pdfSrc = this.data[0].fileUrl;
    }

    public loadPdfFile(selected: MatSelectionListChange): void {
        this.pdfSrc = selected.options[0].value;
    }

    public onClose(): void {
        this._dialog.close();
    }

    public async downloadAttachment(item: FileListRecord) {
        const downloaded = await this._downloadService.download(item.fileUrl, item.fileName);
        if (!downloaded) {
            const failedToUpload = this.translate.instant('projects.attachments.failedToDownload');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(`${failedToUpload} ${item.fileUrl}`, close);
        }
    }

}
