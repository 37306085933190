import { AbstractControl, Validators } from '@angular/forms';
import Crew from '../crew';
import Branch from '../branch';

export class ProjectScheduleDialogOptions {
    public branch: string;
    public branchInfo: Branch;
    public jobId: number;
    public projectId: string;
    public note: string;
    public crew: Crew;
    public isNotEditable: boolean;
    public customerId: string;
    public siteId: string;
    public scheduleDates: ScheduleDate[];
    public isEditingSchedule: boolean;
    public defaultCrewId?: number;
    public startDate?: string;
    public endDate?: string;
    public scheduleId?: number;
}


export interface ScheduleDate {
    startDate: string,
    endDate: string
}

export function ValidateDateFields(control: AbstractControl) {
    // Function checks if multiple dates have been added if it has start date and end date fields should not be mandatory
    if (!control.parent) {
        return null;
    }

    const scheduleDates = control.parent.get('scheduleDates').value;

    if (scheduleDates?.length <= 0) {
        return Validators.required(control);
    }

    return null;
}