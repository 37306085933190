import { Component, OnInit, Input, ViewChild, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AttachmentHttpService } from '../../../core/services/http/attachment-http/attachment-http.service';
import { ReportHttpService } from '../../../core/services/http/report-http/report-http.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { UploadHttpService } from '../../../core/services/http/upload-http/upload-http.service';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';
import { AppEventService } from '../../../core/services/events/app-event/app-event.service';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';
import { AttachmentConstants } from '../../../core/enums/attachment-constants.enum';
import ProjectAttachment from '../../../core/data-models/project-attachment';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import BranchTimezone from '../../../core/data-models/branch-timezone';
import TimeUtility from '../../../core/services/utility/time-utility/time-utility.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { WorkCategory } from '../../../core/data-models/work-category';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'project-attachments',
    styleUrls: ['./project-attachments.component.scss'],
    templateUrl: './project-attachments.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectAttachmentsComponent implements OnInit, OnDestroy {

    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    @Input() activeProjectId = '';
    @Input() timeZone: BranchTimezone;
    public projectAttachmentList: ProjectAttachment[];
    public deletedAttachmentList: ProjectAttachment[];
    public attachmentMode = 'web';
    public showDeleted = false;
    public loading = false;
    public isInternal = false;
    public skip = 0;
    public isInternalWorkCategory = false;
    public disableActionBar = false;
    private _isComponentActive = true;
    private _subscriptions = new Subscription();

    // eslint-disable-next-line
    constructor(
        public translate: TranslateService,
        private _attachmentService: AttachmentHttpService,
        private _reportService: ReportHttpService,
        private _uploadService: UploadHttpService,
        private _downloadService: DownloadHttpService,
        private _appEvents: AppEventService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        private _featureFlagService: FeatureFlagService,
        private _salesCodeConfigService: SalesCodeConfigService,
        private _changeDetector: ChangeDetectorRef,
    ) { }

    get deletedPhotosToolTip(): string {
        return this.deletedAttachmentList?.length === 0 &&
            !this.showDeleted ? this.translate.instant('projects.attachments.tooltips.notAnyDeleted') : '';
    }

    public async ngOnInit(): Promise<void> {
        this._subscriptions.add(this._appEvents.attachmentUpdated.pipe(takeWhile(() => this._isComponentActive)).subscribe(_ => {
            if (this.activeProjectId === _[0] && this._isComponentActive) {
                this.reloadAttachments();
            }
        }));
        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        const workCategory = await this._salesCodeConfigService.getWorkCategoryByProjectId(this.activeProjectId);
        this.isInternalWorkCategory = workCategory === WorkCategory.InternalJob;
        await this.loadProjectAttachmentList();
    }

    public async dropped(files: NgxFileDropEntry[]): Promise<void> {
        const filteredFiles = files.filter(_ => _.fileEntry.isFile);
        const filteredFileNames = filteredFiles.map(f => f.fileEntry.name);
        const hasDuplicateNames = this.projectAttachmentList
            .some(attachment => filteredFileNames.includes(attachment.name));

        if (hasDuplicateNames) {
            const message = this.translate.instant('projects.attachments.warningFileExists.message');
            const title = this.translate.instant('projects.attachments.warningFileExists.title');
            const cancel = this.translate.instant('projects.attachments.warningFileExists.cancel');
            const confirm = this.translate.instant('projects.attachments.warningFileExists.proceed');
            const data = new ConfirmDialogOption(title, message, true, confirm, cancel);
            const dialog = this._dialog.open(ConfirmDialogComponent, { maxWidth: '400px', data });

            const proceed = await dialog.afterClosed().toPromise<boolean>();

            if (!proceed) {
                return;
            }
        }

        for (const droppedFile of filteredFiles) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            const fileType = fileEntry.name.split('.').pop().toLowerCase();
            if (!GenericUtilityService.allowedFileTypes().some(_ => _.fileType === fileType)) {
                const fileTypeNotAllowed = this.translate.instant('projects.attachments.fileTypeNotAllowed');
                const close = this.translate.instant('snackbars.close');

                this._snackBar.open(`${fileTypeNotAllowed}: ${fileEntry.name}`, close);
                return;
            }

            //eslint-disable-next-line
            await (await new Promise(resolve => {
                fileEntry.file((file: File) => {
                    const fileAttachment = new ProjectAttachment();
                    fileAttachment.name = file.name;
                    fileAttachment.type = file.type;
                    fileAttachment.status = AttachmentConstants.Web;
                    fileAttachment.projectId = this.activeProjectId;
                    fileAttachment.statusDate = new Date().toUTCString();
                    resolve(this.uploadFile(file, fileAttachment));
                });
            }));
        }

        await this.reloadAttachments();
    }

    public getColorByFileType(fileType: string): string {
        return GenericUtilityService.getFileIconColorByFileType(fileType);
    }

    public showDeletedAttachments(): void {
        this.showDeleted = !this.showDeleted;
        this.attachmentMode = this.showDeleted ? 'deleted' : 'web';
        this.dataBinding.skip = 0;
    }

    public async downloadAttachment(attachment: ProjectAttachment): Promise<void> {
        const signedUrl = await this._downloadService.getSignedUrl(this.activeProjectId, attachment.name, attachment.type);
        let downloaded = false;
        if (signedUrl) {
            downloaded = await this._downloadService.download(signedUrl, attachment.name, attachment.type);
        }

        if (!downloaded) {
            const failedToUpload = this.translate.instant('projects.attachments.failedToDownload');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(`${failedToUpload} ${attachment.name}`, close);
        }
    }

    public async deleteAttachment(projectId: string, fileName: string): Promise<void> {
        if (!await this.confirmDeleteAttachment(fileName)) {
            return;
        }
        const deleted = await this._reportService.deleteProjectAttachment(projectId, fileName);
        if (deleted) {
            await this.reloadAttachments();
        }
        else {
            const failedToDelete = this.translate.instant('projects.attachments.failedToDelete');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(`${failedToDelete} ${fileName}`, close);
        }
    }

    private async confirmDeleteAttachment(fileName: string): Promise<boolean> {
        const title = this.translate.instant('shared.attachments.confirmDeleteAttachmentDialog.title', { fileName });
        const message = this.translate.instant('shared.attachments.confirmDeleteAttachmentDialog.message');
        const confirmText = this.translate.instant('shared.attachments.confirmDeleteAttachmentDialog.continue');
        const cancelText = this.translate.instant('shared.cancel');

        const data = new ConfirmDialogOption(title, message, true, confirmText, cancelText);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data, width: '40vw' });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    public async restoreProjectAttachment(projectId: string, fileName: string): Promise<void> {
        const restored = await this._reportService.restoreProjectAttachment(projectId, fileName);
        if (restored) {
            await this.reloadAttachments();
        }
        else {
            const failedToRestore = this.translate.instant('projects.attachments.failedToRestore');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(`${failedToRestore} ${fileName}`, close);
        }
    }

    public isNotRemovable(type: string): boolean {
        return ['flynn/photoReport', 'flynn/conditionReport', 'flynn/budgetfile', 'flynn/HandoffMeetingReport'].includes(type);
    }


    public dontShowViewableCheckbox(type: string): boolean {
        return ['flynn/photoReport', 'flynn/conditionReport', 'flynn/budgetfile'].includes(type);
    }

    public showVisibleToCustomer(fileName: string): boolean {
        const expression = /(?:\.([^.]+))?$/;
        const extension = expression.exec(fileName)[1];

        if (!extension) {
            return false;
        }

        const allowedFileTypes = GenericUtilityService.allowedFileTypes();
        return allowedFileTypes.some(_ => _.isCustomerEligibleToView && _.fileType === extension.toLowerCase());
    }

    public async modifyAttachmentsViewableStatus(attachment: ProjectAttachment): Promise<void> {
        const attachmentChanged = await this._reportService.changeAttachmentViewableStatus(attachment);
        if (attachmentChanged) {
            attachment.status = attachment.status === AttachmentConstants.Mobile ? AttachmentConstants.Web : AttachmentConstants.Mobile;
        }
        else {
            const close = this.translate.instant('snackbars.close');
            const failedToChangeViewableStatus = this.translate.instant('projects.attachments.failedToChangeViewableStatus');
            this._snackBar.open(`${failedToChangeViewableStatus} ${attachment.name}`, close);
        }
    }

    public async modifyVisibleToCustomerFlag(attachment: ProjectAttachment): Promise<void> {
        const flagUpdated = await this._reportService.changeVisibleToCustomerFlag(attachment);
        if (!flagUpdated) {
            attachment.visibleToCustomer = !attachment.visibleToCustomer;
            const close = this.translate.instant('snackbars.close');
            const failedToChangeViewableStatus = this.translate.instant('projects.attachments.failedToChangeViewableStatus');
            this._snackBar.open(`${failedToChangeViewableStatus} ${attachment.name}`, close);
        }
    }

    public getLocalTime(utcTime: string): string {
        const utcDate = new Date(utcTime);

        return TimeUtility.toBranchLocalDateTime(utcDate, this.timeZone);
    }

    private async uploadFile(file: File, fileAttachment: ProjectAttachment): Promise<void> {
        if (await this._uploadService.uploadProjectAttachment(fileAttachment, file)) {
            this.projectAttachmentList = [...this.projectAttachmentList, fileAttachment];
        }
        else {
            const failedToUpload = this.translate.instant('projects.attachments.failedToUpload');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(`${failedToUpload} ${file.name}`, close);
        }
    }

    private async loadProjectAttachmentList(): Promise<void> {
        this.loading = true;

        const attachmentList = await this._attachmentService.getProjectAttachmentsWithCount(this.activeProjectId, this.attachmentMode);
        this.projectAttachmentList = attachmentList.filter(_ => _.status === 'w' || _.status === 'a');
        this.deletedAttachmentList = attachmentList.filter(_ => _.status === 'd');

        this.loading = false;
    }

    private async reloadAttachments(): Promise<void> {
        await this.loadProjectAttachmentList();
        await this._appEvents.setReloadProjectCounter();
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
        this._subscriptions.unsubscribe();
    }
}