import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import Chemical from '../../../../core/data-models/chemical';
import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';
import { ChemicalsHttpService } from '../../../../core/services/http/chemicals-http/chemicals-http.service';
import { ConfirmDialogComponent } from '../../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import { ChemicalEditComponent } from '../chemical-edit/chemical-edit.component';

@Component({
    selector: 'chemicals-settings',
    styleUrls: ['./chemicals-settings.component.scss'],
    templateUrl: './chemicals-settings.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ChemicalsSettingsComponent implements OnInit {
    public view: GridDataResult;
    public gridState: State = {
        sort: [],
        skip: 0,
        take: 10
    };

    public chemicals: Chemical[];

    constructor(
        private _dialog: MatDialog,
        private _chemicalsHttpService: ChemicalsHttpService,
        private _snackbar: MatSnackBar,
        private translate: TranslateService,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        const chemicals = await this._chemicalsHttpService.getAllChemicals();
        if (!chemicals) {
            this.chemicals = [];
            const ok = this.translate.instant('shared.ok');
            const successMessage = this.translate.instant('dataReport.chemicalsReport.failedToLoad');
            this._snackbar.open(successMessage, ok);
        }
        else {
            this.chemicals = chemicals;
        }
        this.view = process(this.chemicals, this.gridState);
    }

    public onStateChange(state: State): void {
        this.gridState = state;
    }

    public pageChange(event: PageChangeEvent): void {
        this.gridState.skip = event.skip;
        this.view = {
            data: this.chemicals.slice(this.gridState.skip, this.gridState.skip + this.gridState.take),
            total: this.chemicals.length
        };
    }

    public async addHandler(): Promise<void> {
        const dialog = this._dialog.open(ChemicalEditComponent, {
            minHeight: '300px',
            minWidth: '500px',
            maxWidth: '70vw',
            maxHeight: '80vh'
        });

        const result = await dialog.afterClosed().toPromise();
        const ok = this.translate.instant('shared.ok');
        if (result) {
            const successMessage = this.translate.instant('dataReport.chemicalsReport.succeedToAdd');
            this._snackbar.open(successMessage, ok);
            await this.refresh();
        }
        else if (result === false) {
            const failureMessage = this.translate.instant('dataReport.chemicalsReport.failedToAdd');
            this._snackbar.open(failureMessage, ok);
        }
    }

    public async removeHandler(chemical: Chemical): Promise<void> {
        const title = this.translate.instant('dataReport.chemicalsReport.areYouSure');
        const proceed = this.translate.instant('dataReport.chemicalsReport.proceed');
        const message = this.translate.instant('dataReport.chemicalsReport.chemicalWillBePermanentlyDeleted', { chemicalName: chemical.name });
        const data = new ConfirmDialogOption(title, message, true, proceed);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (!await dialog.afterClosed().toPromise()) {
            return;
        }

        const result = await this._chemicalsHttpService.removeChemical(chemical);
        const ok = this.translate.instant('shared.ok');
        if (result) {
            const successMessage = this.translate.instant('dataReport.chemicalsReport.succeedToRemove');
            this._snackbar.open(successMessage, ok);
            await this.refresh();
        }
        else {
            const failureMessage = this.translate.instant('dataReport.chemicalsReport.failedToRemove');
            this._snackbar.open(failureMessage, ok);
        }
    }

    public async update(chemical: Chemical): Promise<void> {
        const dialog = this._dialog.open(ChemicalEditComponent, {
            data: chemical,
            minHeight: '300px',
            minWidth: '500px',
            maxWidth: '70vw',
            maxHeight: '80vh'
        });

        const result = await dialog.afterClosed().toPromise();
        const ok = this.translate.instant('shared.ok');
        if (result) {
            const successMessage = this.translate.instant('dataReport.chemicalsReport.succeedToUpdate');
            this._snackbar.open(successMessage, ok);
            await this.refresh();
        }
        else if (result === false) {
            const failureMessage = this.translate.instant('dataReport.chemicalsReport.failedToUpdate');
            this._snackbar.open(failureMessage, ok);
        }
    }

    private async refresh(): Promise<void> {
        const chemicals = await this._chemicalsHttpService.getAllChemicals();
        if (!chemicals) {
            this.chemicals = [];
            const ok = this.translate.instant('shared.ok');
            const successMessage = this.translate.instant('dataReport.chemicalsReport.failedToLoad');
            this._snackbar.open(successMessage, ok);
        }
        else {
            this.chemicals = chemicals;
        }
        this.gridState.skip = 0;
        this.view = process(this.chemicals, this.gridState);
    }
}
