import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { SimplePhotoCardComponent } from '../../components/cards/simple-photo-card/simple-photo-card.component';

@Directive({
    selector: '[appImageOverlay]'
})
export class ImageOverlayDirective implements OnInit {

    @Input('appImageOverlay') image = '';
    private overlayRef: OverlayRef;

    public constructor(private overlay: Overlay,
            private overlayPositionBuilder: OverlayPositionBuilder,
            private elementRef: ElementRef) {
    }

    public ngOnInit(): void {
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([{
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom',
                offsetY: -8
            }]);

        this.overlayRef = this.overlay.create({ positionStrategy });
    }

    @HostListener('mouseenter')
    public show(): void {
        const componentRef: ComponentRef<SimplePhotoCardComponent> =
            this.overlayRef.attach(new ComponentPortal(SimplePhotoCardComponent));
        componentRef.instance.image = this.image;
    }

    @HostListener('mouseout')
    public hide(): void {
        this.overlayRef.detach();
    }

}
