import CustomerSelectionDto from './customer-selection-dto';

export default class SwapProjectData {
    public projectId: string;
    public originalCustomerId: string;
    public originalSiteId: string;
    public customerSelection: CustomerSelectionDto;

    constructor(projectId: string, originalCustomerId: string, originalSiteId: string, customerSelection: CustomerSelectionDto) {
        this.projectId = projectId;
        this.originalCustomerId = originalCustomerId;
        this.originalSiteId = originalSiteId;
        this.customerSelection = customerSelection;
    }
}
