/* eslint-disable max-len */
import { NgModule } from '@angular/core';

import { AudioPlaybackService } from './services/audio-playback/audio-playback.service';
import { ColorThemeService } from './services/color-theme/color-theme.service';
import { UserHttpService } from './services/http/user-http/user-http.service';
import { ProjectReviewHttpService } from './services/http/project-review-http/project-review-http.service';
import { ProjectHttpService } from './services/http/project-http/project-http.service';
import { BranchHttpService } from './services/http/branch-http/branch-http.service';
import { TrackLocationHttpService } from './services/http/track-location-http/track-location-http.service';
import { CustomerHttpService } from './services/http/customer-http/customer-http.service';
import { CustomerCreationHttpService } from './services/http/customer-creation-http/customer-creation-http.service';
import { AppConfigHttpService } from './services/http/appconfig-http/appconfig-http.service';
import { DevelopHttpService } from './services/http/develop-http/develop-http.service';
import { UploadHttpService } from './services/http/upload-http/upload-http.service';
import { DownloadHttpService } from './services/http/download-http/download-http.service';
import { KpiHttpService } from './services/http/kpi-http/kpi-http.service';
import { AttachmentHttpService } from './services/http/attachment-http/attachment-http.service';
import { ValidatorFactory } from './services/validator-factory/validator-factory.service';
import { IconRegistryService } from './services/icon-registry/icon-registry.service';
import { NewRelicUtilityService } from './services/utility/new-relic-utility.service';
import { FormControlUtilityService } from './services/utility/form-control-utility-service/form-control-utility.service';
import { CrewHttpService } from './services/http/crew-http/crew-http.service';
import { HolidayHttpService } from './services/http/holiday-http/holiday-http.service';
import { JobReportHttpService } from './services/http/job-report-http/job-report-http.service';
import { FallHazardHttpService } from './services/http/fall-hazard-http/fall-hazard-http.service';
import { QuoteHttpService } from './services/http/quote-http/quote-http.service';
import { ReportHttpService } from './services/http/report-http/report-http.service';
import { UserPermissionService } from './services/user-permission/user-permission.service';
import { LaborHttpService } from './services/http/labor-http/labor-http.service';
import { InvoiceHttpService } from './services/http/invoice-http/invoice-http.service';
import { TemplateHttpService } from './services/http/template-http/template-http.service';
import { ImageHttpService } from './services/http/image-http/image-http.service';
import { BudgetHttpService } from './services/http/budget-http/budget-http.service';
import { AppEventService } from './services/events/app-event/app-event.service';
import { DispatchHttpService } from './services/http/dispatch-http/dispatch-http.service';
import { GoogleMapsUtilityService } from './services/utility/google-maps-utility/google-maps-utility.service';
import { EditorUtilityService } from './services/utility/editor-utility/editor-utility.service';
import { UserTrackingHttpService } from './services/http/user-tracking-http/user-tracking-http.service';
import { FeatureFlagService } from './services/events/feature-flags/feature-flag.service';
import { LeakReportHttpService } from './services/http/leak-report-http/leak-report-http.service';
import { SalesCodeConfigService } from './services/sales-code-config/sales-code-config.service';
import { WorkflowHttpService } from './services/http/workflow-http/workflow-http.service';
import { ProjectStatusHttpService } from './services/http/project-status-http/project-status-http.service';
import { ProjectMergeHttpService } from './services/http/project-merge-http/project-merge-http.service';
import { SubscriptionHttpService } from './services/http/subscription-http/subscription-http.service';
import { IntegrationHttpService } from './services/http/integration-http/integration-http.service';
import { AddressErrorReadUtilityService } from '../shared/components/customer-management/services/address-error-read-utility/address-error-read-utility.service';
import { MobileScanFileHttpService } from './services/http/mobile-scan-file-http/mobile-scan-file-http.service';
import { RoofDetailsHttpService } from './services/http/roof-detail-http/roof-detail-http-service';
import { KpiSettingService } from './services/http/kpi-setting-http/kpi-setting.service';
import { GeofenceHttpService } from './services/http/geofence-http/geofence-http.service';
import { LeakReportTemplateHttpService } from './services/http/leak-report-template-http/leak-report-template-http.service';
import { MaterialHttpService } from './services/http/material-http/material-http.service';
import { ChemicalsHttpService } from './services/http/chemicals-http/chemicals-http.service';
import { DictionaryHttpService } from './services/http/dictionary-http/dictionary-http.service';
import { CustomReportTemplateHttpService } from './services/http/custom-report-template-http/custom-report-template-http.service';
import { WorkOrderTemplateHttpService } from './services/http/work-order-template-http/work-order-template-http.service';
import { AuthLockService } from './services/auth-lock-service/auth-lock-service';
import { TranslateService } from '@ngx-translate/core';

@NgModule({
    providers: [
        AudioPlaybackService,
        ColorThemeService,
        AttachmentHttpService,
        UserHttpService,
        TrackLocationHttpService,
        CustomerHttpService,
        CustomerCreationHttpService,
        ProjectReviewHttpService,
        ProjectHttpService,
        BranchHttpService,
        AppConfigHttpService,
        DevelopHttpService,
        UploadHttpService,
        DownloadHttpService,
        KpiHttpService,
        ValidatorFactory,
        IconRegistryService,
        NewRelicUtilityService,
        FormControlUtilityService,
        CrewHttpService,
        DispatchHttpService,
        HolidayHttpService,
        JobReportHttpService,
        FallHazardHttpService,
        QuoteHttpService,
        ReportHttpService,
        UserPermissionService,
        LaborHttpService,
        InvoiceHttpService,
        TemplateHttpService,
        ImageHttpService,
        BudgetHttpService,
        AppEventService,
        DispatchHttpService,
        GoogleMapsUtilityService,
        EditorUtilityService,
        UserTrackingHttpService,
        FeatureFlagService,
        LeakReportHttpService,
        RoofDetailsHttpService,
        SalesCodeConfigService,
        WorkflowHttpService,
        ProjectStatusHttpService,
        ProjectMergeHttpService,
        SubscriptionHttpService,
        IntegrationHttpService,
        MobileScanFileHttpService,
        AddressErrorReadUtilityService,
        KpiSettingService,
        GeofenceHttpService,
        MaterialHttpService,
        ChemicalsHttpService,
        DictionaryHttpService,
        LeakReportTemplateHttpService,
        CustomReportTemplateHttpService,
        WorkOrderTemplateHttpService,
        AuthLockService,
        TranslateService
    ]
})
export class CoreModule { }
