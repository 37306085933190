import * as moment from 'moment-timezone';


const currStartDate = moment().startOf('week');
const currEndDate = moment().endOf('week');

export const calendarViewConfig={
    type: 'resourceInfo',
    viewPreset: {
        base: 'hourAndDay',
        tickWidth: 100,
        fillTicks: true,
        columnLinesFor: 1,
        shiftIncrement: 1,
        headers: [
            {
                unit: 'month',
                dateFormat: 'YYYY MMMM',
                align: 'center'
            },
            {
                unit: 'day',
                dateFormat: 'ddd DD',
                align: 'center'
            }
        ]
    },
    timeResolution: {
        unit: 'day',
        increment: 1
    },
    visibleDate: {
        date: new Date(),
        block: 'center'
    },
    forceFit: false,
    eventColor: null,
    columns: [
        { type: 'resourceInfo', text: null, field: 'name', width: 280, showEventCount: false, useNameAsImageName: true }
    ],
    eventEditFeature: {
        // Add extra widgets to the event editor
        items: {
            descriptionField: {
                type: 'text',
                name: 'desc',
                label: 'Description',
                weight: 100
            }
        }
    },
    startDate: currStartDate.toDate(),
    endDate: currEndDate.toDate(),
    listeners: {
        beforeEventEdit() {
            return false;
        }
    },
    zoomOnTimeAxisDoubleClick: false,
    rowHeight: 80,
    barMargin: 10,
    zoomOnMouseWheel: false,
    createEventOnDblClick: false,
    eventTooltipFeature: {
        allowOver: true,
        showOnHover: true,
        showOnClick: true
    },
    eventDragFeature: {
        DragEvent: false,
        constrainDragToTimeline: false,
        showTooltip: true
    },
    features: {
        eventMenu: false,
        scheduleTooltip: false,
        taskEdit: {
            triggerEvent: ''
        },
        dependencies: {
            allowCreate: false
        },
        scheduleMenu: {
            items: {
                addEvent: {
                    disabled: true
                }
            }
        },
        timeRanges: {
            showCurrentTimeLine: {
                type: 'date',
                dateFormat: 'DD/MM/YY',
                timeZone: 'Europe/Stockholm'
            },
            showHeaderElements: true
        },
        columnLines: true,
        nonWorkingTime: true,
        dragFeature: false,
        eventDragCreate: false
    },
    eventResizeFeature: false,
    cellMenuFeature: {
        disabled: true
    }
};


