import CustomerDto from '../../../data-models/customer-dto';
import { CountryName } from '../../../enums/country-name.enum';

export default class CustomerUtility {

    public static isCanadianCustomer(id = ''): boolean {
        return /^C/i.test(id ?? '');
    }

    public static isUsaCustomer(id = ''): boolean {
        return /^U/i.test(id ?? '');
    }

    public static isConfirmedCustomer(id = ''): boolean {
        return this.isCanadianCustomer(id) || this.isUsaCustomer(id);
    }

    public static getCustomerCountry(customer: CustomerDto): string {
        if (!customer) {
            return '';
        }

        if (this.isCanadianCustomer(customer.customerId)) {
            return CountryName.CA;
        }

        if (this.isUsaCustomer(customer.customerId)) {
            return CountryName.US;
        }

        return customer.country;
    }
}
