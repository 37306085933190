import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ready-for-processing-without-budget-reason-dialog',
    styleUrls: ['./ready-for-processing-without-budget-reason-dialog.component.scss'],
    templateUrl: './ready-for-processing-without-budget-reason-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ReadyForProcessingWithoutBudgetReasonDialogComponent implements OnInit {
    public reasonInput: UntypedFormControl;

    constructor(
        private _dialog: MatDialogRef<ReadyForProcessingWithoutBudgetReasonDialogComponent>,
        public translate: TranslateService) { }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        this.reasonInput = new UntypedFormControl('', [Validators.required, Validators.pattern(/\w+/)]);
    }

    public onClose(emit = true): void {
        if (!emit) {
            this._dialog.close();
        }
        else if (this.reasonInput.valid) {
            this._dialog.close(this.reasonInput.value);
        }
    }
}
