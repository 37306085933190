<div class="quote-disclaimer-container" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="1.5vh">
    <div class="placeholder-container" fxLayout="column" fxLayoutGap="0.75vh">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1vh">
            <button *ngFor="let option of reportNameTemplateOptions" mat-stroked-button color="primary" (click)="insertPlaceholder(option.key, option.value)">
                {{ option.value }}
            </button>
        </div>
    </div>
    <div class="quote-disclaimer-content" [ngStyle]="{ opacity: isLoaded ? 1 : 0 }">
        <textarea [id]="editorId"></textarea>
        <div class="quote-disclaimer-toggles">
            <mat-button-toggle-group [(ngModel)]="language" (ngModelChange)="onLanguageCountrySelected()">
                <mat-button-toggle *ngFor="let language of languages" [value]="language">
                    {{ language.language.toUpperCase() }}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-button-toggle-group  [(ngModel)]="country" (ngModelChange)="onLanguageCountrySelected()">
                <mat-button-toggle *ngFor="let countryName of countries" [value]="countryName">
                    {{ countryName }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <button mat-raised-button color="primary" [disabled]="!canSave" (click)="saveDisclaimer()" fxFlexAlign="end">
        {{ 'admin.disclaimerSettings.quoteDisclaimer.saveDisclaimerButtonLabel' | translate }}
    </button>
</div>
