import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import CustomerInfo from '../../../../core/data-models/project-general-info/customer-info';
import CustomerExtensionData from '../../../../core/data-models/customer-extension-data';
import InformationGridItem from '../../../../core/data-models/information-grid/information-grid-item';
import InformationGridRow from '../../../../core/data-models/information-grid/information-grid-row';
import InformationGridConfig from '../../../../core/data-models/information-grid/information-grid-config';
import { CustomerHttpService } from '../../../../core/services/http/customer-http/customer-http.service';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import CustomerUtility from '../../../../core/services/utility/customer-utility/customer-utility.service';
import { CustomerClass } from '../../../../core/enums/customer-class.enum';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';

@Component({
    selector: 'customer-details-card',
    styleUrls: ['./customer-details-card.component.scss'],
    templateUrl: './customer-details-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerDetailsCardComponent implements OnInit {
    @Input() public customer: CustomerInfo;
    @Output() public close = new EventEmitter();
    public extensionData: CustomerExtensionData | null;
    public gridConfig: InformationGridConfig | null = null;
    public isLoading = true;

    constructor(public translate: TranslateService,
                private _customerHttp: CustomerHttpService,
                private _featureFlagService: FeatureFlagService,
                private _changeDetectorRef: ChangeDetectorRef) { }

    get isStrategicCustomer(): boolean {
        return CustomerClass[this.customer.customerClass] === CustomerClass.STRTGC;
    }

    get isTemporaryCustomer(): boolean {
        const isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];

        return isInternal && !CustomerUtility.isConfirmedCustomer(this.customer.customerId);
    }

    public async ngOnInit(): Promise<void> {
        await this.loadExtensionData();
        this.isLoading = false;
        this._changeDetectorRef.markForCheck();
    }

    private async loadExtensionData(): Promise<void> {
        if (this.isTemporaryCustomer) {
            return;
        }

        this.extensionData = await this._customerHttp.getCustomerById(this.customer.customerId);

        if (!this.extensionData) {
            return;
        }

        const notToExceedLabel = this.translate.instant('shared.customerDetailsCard.notToExceed');
        const generalRelationshipLabel = this.translate.instant('shared.customerDetailsCard.generalRelationship');
        const billingProceduresLabel = this.translate.instant('shared.customerDetailsCard.billingProcedures');
        const specialSafetyProceduresLabel = this.translate.instant('shared.customerDetailsCard.specialSafetyProcedures');
        const notToExceed = new InformationGridItem(notToExceedLabel, 'nTE');
        const generalRelationship = new InformationGridItem(generalRelationshipLabel, 'generalRelationship', 100, true);
        const billingProcedures = new InformationGridItem(billingProceduresLabel, 'billingProcedures', 100, true);
        const specialSafetyProcedures = new InformationGridItem(specialSafetyProceduresLabel, 'specialSafetyProcedures', 100, true);

        const rows = [
            new InformationGridRow([notToExceed]),
            new InformationGridRow([generalRelationship], 26),
            new InformationGridRow([billingProcedures], 26),
            new InformationGridRow([specialSafetyProcedures], 26)
        ];

        this.gridConfig = new InformationGridConfig(this.extensionData, rows);
    }
}
