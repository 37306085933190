<form class="service-rates-branch-options-container" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh">
    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <div fxFlex="30" fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="1.5vh">
            <span class="section-title">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.projectTypes' | translate }}</span>
            <span class="section-description">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.projectTypesDescription' | translate }}</span>
        </div>

        <div fxLayout="row" fxFlex="70">
            <mat-form-field>
                <mat-chip-list #projectTypesChipList>
                    <mat-chip *ngFor="let control of projectTypes.controls; let i = index" (removed)="removeProjectType(i)">
                        <app-editable-text-display *ngIf="!isReadonly" [text]="getItemTypeDisplayText(control)" (edit)="updateItemType(projectTypes, i, $event)"></app-editable-text-display>
                        <mat-icon *ngIf="!isReadonly"matChipRemove>cancel</mat-icon>
                        <span *ngIf="isReadonly">
                            {{ getItemTypeDisplayText(control) }}
                        </span>
                    </mat-chip>

                    <input type="text"
                        [readonly]="isReadonly"
                        [placeholder]="translate.instant('flynnBudgetTool.shared.typeAndPressEnter') + '...'"
                        [matChipInputFor]="projectTypesChipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                        (matChipInputTokenEnd)="addProjectType($event)" />
                </mat-chip-list>

                <mat-error *ngIf="projectTypes.errors?.required">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
                </mat-error>

                <mat-error *ngIf="projectTypes.errors?.emptyName">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.emptyText' | translate }}
                </mat-error>

                <mat-error *ngIf="projectTypes.errors?.duplicateName">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.duplicateValue' | translate }}
                </mat-error>

                <mat-error *ngIf="projectTypesChipList.errorState && !projectTypes.errors">
                    * {{ 'flynnBudgetTool.ratesEditor.errors.emptyText' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="branch-entry" *ngFor="let control of branchRates.controls; let i = index" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1.5vh">
        <button mat-icon-button color="primary" [disabled]="control.get('branchCode').invalid" (click)="openChangeHistory(control.get('branchCode').value)" type="button" (keydown.enter)="$event.preventDefault()">
            <mat-icon>history</mat-icon>
        </button>

        <mat-form-field fxFlex="20">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.branch' | translate }}</mat-label>

            <mat-select [formControl]="control.get('branchCode')">
                <mat-option *ngFor="let branch of branches" [value]="branch.code">
                    {{ branch.code }} - {{ branch.name }}
                </mat-option>
            </mat-select>

            <mat-error *ngIf="control.get('branchCode').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('branchCode').errors?.duplicateCode">
                * {{ 'flynnBudgetTool.ratesEditor.errors.duplicateValue' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="9">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.laborHourlyRate' | translate }}</mat-label>
            <input type="number" matInput [formControl]="control.get('laborHourlyRate')" />

            <mat-error *ngIf="control.get('laborHourlyRate').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('laborHourlyRate').errors?.invalidFormat">
                * {{ 'flynnBudgetTool.ratesEditor.errors.invalidCurrencyFormat' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('laborHourlyRate').errors?.negative">
                * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="9">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.insurancePercentageRate' | translate }}</mat-label>
            <input type="number" matInput [formControl]="control.get('insurancePercentageRate')" (blur)="toPercentageDisplayValue(control.get('insurancePercentageRate'))" />

            <mat-error *ngIf="control.get('insurancePercentageRate').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('insurancePercentageRate').errors?.min">
                * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('insurancePercentageRate').errors?.max">
                * {{ 'flynnBudgetTool.ratesEditor.errors.cannotExceedOneHundred' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="9">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.indirectsPercentageRate' | translate }}</mat-label>
            <input type="number" matInput [formControl]="control.get('indirectsPercentageRate')" (blur)="toPercentageDisplayValue(control.get('indirectsPercentageRate'))" />

            <mat-error *ngIf="control.get('indirectsPercentageRate').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('indirectsPercentageRate').errors?.min">
                * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('indirectsPercentageRate').errors?.max">
                * {{ 'flynnBudgetTool.ratesEditor.errors.cannotExceedOneHundred' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="10" *ngFor="let grossProfitPercentageRateControl of getGrossProfitPercentageRates(control).controls">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.grossProfitPercentageRate' | translate: { projectType: getProjectType(grossProfitPercentageRateControl) } }}</mat-label>
            <input type="number" matInput [formControl]="grossProfitPercentageRateControl.get('rate')" (blur)="toPercentageDisplayValue(grossProfitPercentageRateControl.get('rate'))" />

            <mat-error *ngIf="grossProfitPercentageRateControl.get('rate').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="grossProfitPercentageRateControl.get('rate').errors?.min">
                * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
            </mat-error>

            <mat-error *ngIf="grossProfitPercentageRateControl.get('rate').errors?.max">
                * {{ 'flynnBudgetTool.ratesEditor.errors.cannotExceedOneHundred' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="11">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.subcontractGrossProfitPercentageRate' | translate }}</mat-label>
            <input type="number" matInput [formControl]="control.get('subcontractGrossProfitPercentageRate')" (blur)="toPercentageDisplayValue(control.get('subcontractGrossProfitPercentageRate'))" />

            <mat-error *ngIf="control.get('subcontractGrossProfitPercentageRate').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('subcontractGrossProfitPercentageRate').errors?.min">
                * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('subcontractGrossProfitPercentageRate').errors?.max">
                * {{ 'flynnBudgetTool.ratesEditor.errors.cannotExceedOneHundred' | translate }}
            </mat-error>
        </mat-form-field>

        <div>
            <button mat-icon-button color="primary" [disabled]="isReadonly" (click)="copyBranchRate(i)" type="button" (keydown.enter)="$event.preventDefault()">
                <mat-icon>content_copy</mat-icon>
            </button>

            <button mat-icon-button color="warn" [disabled]="isReadonly" (click)="deleteBranchRate(i)" type="button" (keydown.enter)="$event.preventDefault()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>

    <div class="action-buttons" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1vh">
        <button [disabled]="isReadonly" mat-stroked-button color="primary" (click)="addBranchRateRow()" type="button" (keydown.enter)="$event.preventDefault()">
            {{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.addBranch' | translate }}
        </button>
    </div>
</form>
