<div fxLayout="column" *ngIf="!loading && projectSafetyTemplate" fxLayoutGap="8px" class="project-safety-container">
    <div *ngIf="totalJobs > 1" class="project-safety-action-bar-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <dropdown-button
            class="project-safety-visit-selection-button"
            [icon]="'table_view'"
            [text]="translate.instant('projects.safety.visits') + ' (' + totalJobs + ')'"
            [options]="jobs"
            (optionSelect)="onJobSelect($event)">
        </dropdown-button>
    </div>

    <div class="project-safety-tab-content-container" fxLayout="column" fxLayoutGap="10px">
        <template-content-card fxFill
            [icon]="{ isSvg: false, name: 'fact_check' }"
            [template]="projectSafetyTemplate">
        </template-content-card>
    </div>
</div>

<mat-card *ngIf="!projectSafetyTemplate && !loading" fxFill>

    <div fxLayout="column" class="project-safety-no-safety-data-uploaded-view-container" fxLayoutAlign="center center">
        <span>{{loadingErrorMessage}} </span>

    </div>

</mat-card>

