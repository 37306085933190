import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../../shared/shared.module';

import { states } from './user.routing';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        UIRouterModule.forChild({ states })
    ]
})
export class UserModule { }
