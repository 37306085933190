import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import LeakReportTemplate from '../../../core/data-models/leak-report-template';
import { LeakReportType } from '../../../core/enums/leak-report-type.enum';
import { LeakReportTemplateHttpService } from '../../../core/services/http/leak-report-template-http/leak-report-template-http.service';

@Component({
    selector: 'leak-report-template-management',
    styleUrls: ['./leak-report-template-management.component.scss'],
    templateUrl: './leak-report-template-management.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LeakReportTemplateManagementComponent implements OnInit {
    public templates: LeakReportTemplate[] = [];
    public selectedIndex = 0;
    public isReady = false;
    private defaultHtml = '<p style="text-align: center;">{{text}}</p>';

    public constructor(
        private _leakReportTemplateService: LeakReportTemplateHttpService,
        private _matSnackBar: MatSnackBar,
        private _translate: TranslateService
    ) { }

    public async ngOnInit(): Promise<void> {
        const defaultText = this._translate.instant('admin.leakReportTemplateEditor.createTemplateFromScratch');
        this.defaultHtml.replace('{{text}}', defaultText);

        this.templates = await Promise.all(Object.values(LeakReportType).map(async _ => ({
            label: _,
            content: await this._leakReportTemplateService.getTemplate(_) ?? this.defaultHtml
        })));

        this.isReady = true;
    }

    public async save(type: LeakReportType, content: string): Promise<void> {
        const success = await this._leakReportTemplateService.updateTemplate(type, content);
        const okLabel = this._translate.instant('shared.ok');

        if (success) {
            this._matSnackBar.open(this._translate.instant('admin.leakReportTemplateEditor.successfullySavedTemplate'), okLabel);
            this.templates.find(_ => _.label === type).content = content;
        }
        else {
            this._matSnackBar.open(this._translate.instant('admin.leakReportTemplateEditor.failedToSaveTemplate'), okLabel);
        }
    }
}
