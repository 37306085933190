import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import BranchReview from '../../../../../core/data-models/project-review/branch-review';
import ProcessorReview from '../../../../../core/data-models/project-review/processor-review';
import ProjectReviewOptions from '../../../../../core/data-models/project-options/project-review-options';
import CheckboxData from '../../../../../core/data-models/checkbox-data';
import ProjectIdUtility from '../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import ProjectGeneralInfo from '../../../../../core/data-models/project-general-info/project-general-info';
import { ProjectReviewHttpService } from '../../../../../core/services/http/project-review-http/project-review-http.service';
import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';
import { ProjectRejectReason } from '../../../../../core/enums/project-reject-reason.enum';
import { LaborHttpService } from '../../../../../core/services/http/labor-http/labor-http.service';
import { SalesCodeConfigService } from '../../../../../core/services/sales-code-config/sales-code-config.service';
import { FeatureFlagService } from '../../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../../core/enums/feature-flags.enum';
import { WorkCategory } from '../../../../../core/data-models/work-category';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'project-review-dialog',
    styleUrls: ['./project-review-dialog.component.scss'],
    templateUrl: './project-review-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectReviewDialogComponent implements OnInit {
    public branchReviewForm: UntypedFormGroup;
    public branchReview: BranchReview;
    public processorReview: ProcessorReview;
    public totalLabor: number;
    public useInternalFeatures = false;
    private _reviewOptions = new ProjectReviewOptions();
    private _branchList: CheckboxData[] = [];
    private _processorList: CheckboxData[] = [];
    private _isLoaded = false;
    private _isLeak = false;
    private _isSubcontract = false;
    // eslint-disable-next-line max-params
    constructor(private _projectReviewHttp: ProjectReviewHttpService,
                private _projectHttp: ProjectHttpService,
                private _laborHttpService: LaborHttpService,
                private _dialog: MatDialogRef<ProjectReviewDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private _generalInfo: ProjectGeneralInfo,
                public translate: TranslateService,
                private _salesCodeConfig: SalesCodeConfigService,
                private _featureFlagService: FeatureFlagService,
                private _formBuilder: UntypedFormBuilder,
                private _snackbar: MatSnackBar
    ) { }

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    get billableHoursInput(): AbstractControl {
        return this.branchReviewForm?.get('billableHours');
    }

    get reviewContent(): { comment: string }[] {
        return [this.branchReview, this.processorReview].filter(Boolean);
    }

    get canSave(): boolean {
        return this._reviewOptions.isBranchReviewEditable || this._reviewOptions.isProcessorReviewEditable;
    }

    get stars(): number {
        const hasSafety = this._processorList.some(_ => _.name === 'safety' && _.checked);

        return hasSafety ? this._processorList.filter(_ => _.checked).length : 0;
    }

    get isKpiReviewRequired(): boolean {
        return this._isLeak;
    }

    get showMaterialsCost(): boolean {
        return !this._isSubcontract;
    }

    get processorRole(): string {
        return this.isKpiReviewRequired ?
            this.translate.instant('projects.projectReview.serviceOps') :
            this.translate.instant('projects.projectReview.serviceAdmin');
    }

    get isInternalWorkCategory(): boolean {
        return this._generalInfo.workCategory === WorkCategory.InternalJob;
    }

    public async ngOnInit(): Promise<void> {
        const { projectId } = this._generalInfo.basicInfo;
        this._reviewOptions = await this._projectHttp.getProjectReviewOptions(projectId);
        this._isLeak = this._salesCodeConfig.isLeakCall(projectId);
        this._isSubcontract = this._salesCodeConfig.isSubcontract(projectId);
        this.useInternalFeatures = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        if (this.useInternalFeatures) {
            if (this.isInternalWorkCategory) {
                await this.loadTotalLabor();
            }

            await Promise.all([this.getBranchReview(), this.getProcessorReview()]);
            await this.setupBranchReviewForm();
        }
        else {
            await this.getBranchReview();
        }

        this._isLoaded = true;
    }

    public isDisabled(isBranch: boolean): boolean {
        return isBranch ? !this._reviewOptions.isBranchReviewEditable : !this._reviewOptions.isProcessorReviewEditable;
    }

    public getChecklist(isBranch: boolean): CheckboxData[] {
        return isBranch ? this._branchList : this._processorList;
    }

    public getCommentTitle(isBranch: boolean): string {
        return `${isBranch ?
            this.translate.instant('projects.projectReview.branchReviewer') :
            this.processorRole} ${this.translate.instant('projects.projectReview.comment')}`;
    }

    public getQualityReviewTitle(isBranch: boolean): string {
        const role = isBranch ? this.translate.instant('projects.projectReview.branch') : this.processorRole;
        return `${role} ${this.translate.instant('projects.projectReview.qualityReview')}`;
    }

    private async setupBranchReviewForm(): Promise<void> {
        if (!this.branchReview) {
            return;
        }

        const { customerId } = this._generalInfo.customer;
        const isRequired = customerId ? await this._projectReviewHttp.isBillableHoursRequired(customerId) : false;
        const isBranchReviewDisabled = this.isDisabled(true);
        const disabled = isRequired ? false : isBranchReviewDisabled;

        this.branchReview.showBillableHours = this.isInternalWorkCategory && (isRequired || this.branchReview.showBillableHours);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let formObject: any = {
            billableHours: [{ value: this.branchReview.billableHours, disabled }, [Validators.min(0)]]
        };

        formObject = {
            ...formObject,
            materialsCost: [{ value: this.branchReview.materialsCost, disabled: isBranchReviewDisabled }, [Validators.min(0), Validators.max(1000000000)]],
            recommendationMaterialsCost: [{ value: this.branchReview.recommendationMaterialsCost, disabled: isBranchReviewDisabled }, [Validators.min(0), Validators.max(1000000000)]]
        };

        this.branchReviewForm = this._formBuilder.group(formObject);
        this.branchReviewForm.markAllAsTouched();
        if (this._isLeak) {
            this.branchReviewForm.get('materialsCost').setValidators(Validators.required);
            this.branchReviewForm.get('recommendationMaterialsCost').setValidators(Validators.required);
        }
    }

    private async loadTotalLabor(): Promise<void> {
        const projectId = this._generalInfo.basicInfo.projectId;
        this.totalLabor = await this._laborHttpService.getTotalLaborByProject(projectId);
    }

    private async getBranchReview(): Promise<void> {
        const { projectId } = this._generalInfo.basicInfo;
        const isEmergency = ProjectIdUtility.isEmergency(projectId);
        this.branchReview = await this._projectReviewHttp.getBranchReview(projectId);

        if (!this.branchReview) {
            this.branchReview = new BranchReview(projectId);
            this.branchReview.billableHours = this.totalLabor === -1 ? 0 : this.totalLabor;
        }

        if (this.useInternalFeatures) {
            const { checklist } = this.branchReview;

            const contactInfoLabel = this.translate.instant('projects.review.contactInfo');
            const findingsLabel = this.translate.instant('projects.review.findings');
            const recommendationsLabel = this.translate.instant('projects.review.recommendations');
            const photosLabel = this.translate.instant('projects.review.photos');
            const safetyLabel = this.translate.instant('projects.review.safety');
            const labourReviewedLabel = this.translate.instant('projects.review.labourReviewed');

            this._branchList = [
                new CheckboxData('contact info', checklist.contactInformation, null, null, contactInfoLabel),
                new CheckboxData('findings', checklist.findings, null, null, findingsLabel),
                new CheckboxData('recommendations', checklist.recommendations, null, null, recommendationsLabel),
                new CheckboxData('photos', checklist.photos, null, null, photosLabel),
                new CheckboxData('safety', checklist.safety, null, null, safetyLabel),
                new CheckboxData('labour reviewed', checklist.labourReviewedAndApproved, null, null, labourReviewedLabel)
            ];

            if (!isEmergency && this._isLeak) {
                const name = this.translate.instant('projects.review.reroofQuoteRequired');
                this._branchList.push(new CheckboxData(name, checklist.isAReRoofQuoteRequired));
            }
        }
    }

    private async getProcessorReview(): Promise<void> {
        if (!this._reviewOptions.isProcessorReviewVisible) {
            return;
        }

        const { isLateFillIn, basicInfo } = this._generalInfo;
        const review = await this._projectReviewHttp.getProcessorReview(basicInfo.projectId);
        this.processorReview = review ?? new ProcessorReview(basicInfo.projectId);
        const { checklist, rejectionReason } = this.processorReview;
        const photoRejectReasons = [ProjectRejectReason.MissingPhotoTitle, ProjectRejectReason.MissingFrontOfBuilding];

        const contactInfoLabel = this.translate.instant('projects.review.contactInfo');
        const findingsLabel = this.translate.instant('projects.review.findings');
        const recommendationsLabel = this.translate.instant('projects.review.recommendations');
        const photosLabel = this.translate.instant('projects.review.photos');
        const safetyLabel = this.translate.instant('projects.review.safety');

        this._processorList = [
            this.getCheckboxData(
                'contact info',
                Boolean(checklist[0]),
                Boolean(rejectionReason) && rejectionReason.includes(ProjectRejectReason.MissingContact),
                this.translate.instant('projects.projectReview.disableReason1'),
                contactInfoLabel
            ),
            new CheckboxData('findings', Boolean(checklist[1]), null, null, findingsLabel),
            this.getCheckboxData(
                'recommendations',
                Boolean(checklist[2]),
                Boolean(rejectionReason) && rejectionReason.includes(ProjectRejectReason.MissingTimeMaterialCost),
                this.translate.instant('projects.projectReview.disableReason1'),
                recommendationsLabel
            ),
            this.getCheckboxData(
                'photos',
                Boolean(checklist[3]),
                Boolean(rejectionReason) && photoRejectReasons.some(_ => rejectionReason.includes(_)),
                this.translate.instant('projects.projectReview.disableReason1'),
                photosLabel
            ),
            this.getCheckboxData(
                'safety',
                Boolean(checklist[4]),
                isLateFillIn,
                this.translate.instant('projects.projectReview.disableReason2'),
                safetyLabel
            )
        ];
    }

    private getCheckboxData(
        name: string, checked: boolean, isDisabled: boolean, disableReason: string, displayText: string): CheckboxData {
        return new CheckboxData(
            name,
            isDisabled ? false : checked,
            isDisabled,
            isDisabled ? disableReason : '',
            displayText
        );
    }

    // eslint-disable-next-line complexity
    public async onClose(save = true): Promise<void> {
        this.branchReviewForm.markAllAsTouched();
        const { isBranchReviewEditable, isProcessorReviewEditable } = this._reviewOptions;
        const shouldSaveBranchReview = save && this.branchReview && isBranchReviewEditable;
        const shouldSaveProcessorReview = save && this.processorReview && isProcessorReviewEditable;

        // Commercial build does not use the form group, it changes the comment via ngModel
        // so no need to check the form.
        const isInvalidBranchReviewForm = this.useInternalFeatures && this.branchReviewForm.invalid;

        if (shouldSaveBranchReview && isInvalidBranchReviewForm) {
            const snackbarText = this.translate.instant('projects.projectReview.invalidForm');
            this._snackbar.open(snackbarText, this.translate.instant('shared.ok'));
            return;
        }

        if (shouldSaveBranchReview) {
            await this.updateBranchReview();
        }

        if (shouldSaveProcessorReview) {
            await this.updateProcessorReview();
        }

        this._dialog.close(shouldSaveBranchReview || shouldSaveProcessorReview);
    }

    private async updateBranchReview(): Promise<void> {
        if (this.useInternalFeatures) {
            this.branchReview.billableHours = this.billableHoursInput.value;

            if (this.showMaterialsCost) {
                this.branchReview.materialsCost = this.branchReviewForm.get('materialsCost')?.value;
                this.branchReview.recommendationMaterialsCost = this.branchReviewForm.get('recommendationMaterialsCost')?.value;
            }

            const { checklist } = this.branchReview;
            checklist.contactInformation = this._branchList[0].checked;
            checklist.findings = this._branchList[1].checked;
            checklist.recommendations = this._branchList[2].checked;
            checklist.photos = this._branchList[3].checked;
            checklist.safety = this._branchList[4].checked;
            checklist.labourReviewedAndApproved = this._branchList[5].checked;
            checklist.isAReRoofQuoteRequired = this._branchList[6]?.checked;
        }

        await this._projectReviewHttp.updateBranchReview(this.branchReview);
    }

    private async updateProcessorReview(): Promise<void> {
        this.processorReview.rating = this.stars;
        this.processorReview.checklist = this._processorList.map(_ => _.checked ? 1 : 0);
        await this._projectReviewHttp.updateProcessorReview(this.processorReview);
    }
}
