import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BudgetRecordPayload } from '../../../data-models/budget-estimations/budget-record-payload';
import { ServiceOperationsBudgetData } from '../../../data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-budget-data';
import { ProjectBudget } from '../../../data-models/budget-estimations/project-budget';
import { QuoteBudget } from '../../../data-models/budget-estimations/quote-budget';
import { JsonUtility } from '../../../utilities/json/json.utility';
import { environment } from '../../../../../environments/environment';
import BudgetResultDto from '../../../data-models/dtos/budget-result-dto';

@Injectable({ providedIn: 'root' })
export class ServiceOperationsStandardizedBudgetsHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/service-operations-standardized-budgets`;

    constructor(private _http: HttpClient) { }

    public async calculate(payload: BudgetRecordPayload<ServiceOperationsBudgetData>): Promise<ServiceOperationsBudgetData> {
        try {
            const endpoint = `${this._api}/calculate`;
            const data = await this._http.post<ServiceOperationsBudgetData>(endpoint, payload).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getProjectBudgetRecord(projectId: string): Promise<BudgetResultDto<ProjectBudget<ServiceOperationsBudgetData>>> {
        try {
            const endpoint = `${this._api}/projects/${projectId}`;
            const data = await this._http.get<ProjectBudget<ServiceOperationsBudgetData>>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new BudgetResultDto(true, null);
        }
    }

    public async getQuoteBudgetRecord(quoteId: string): Promise<BudgetResultDto<QuoteBudget<ServiceOperationsBudgetData>>> {
        try {
            const endpoint = `${this._api}/quotes/${quoteId}`;
            const data = await this._http.get<QuoteBudget<ServiceOperationsBudgetData>>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new BudgetResultDto(true, null);
        }
    }

    public async saveProjectBudgetRecord(projectId: string, payload: BudgetRecordPayload<ServiceOperationsBudgetData>): Promise<boolean> {
        try {
            const endpoint = `${this._api}/projects/${projectId}`;

            return await this._http.post<boolean>(endpoint, payload).toPromise();
        }
        catch {
            return false;
        }
    }

    public async saveQuoteBudgetRecord(quoteId: string, payload: BudgetRecordPayload<ServiceOperationsBudgetData>): Promise<boolean> {
        try {
            const endpoint = `${this._api}/quotes/${quoteId}`;

            return await this._http.post<boolean>(endpoint, payload).toPromise();
        }
        catch {
            return false;
        }
    }

    public async finalizeBudgetRecord(projectId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${projectId}/finalize`;

            return await this._http.post<boolean>(endpoint, null).toPromise();
        }
        catch {
            return false;
        }
    }

    public async deleteQuoteBudget(quoteId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/quotes/${quoteId}`;

            return await this._http.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async deleteProjectBudget(projectId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/projects/${projectId}`;

            return await this._http.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }
}
