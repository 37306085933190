import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'content-not-found',
    styleUrls: ['./content-not-found.component.scss'],
    templateUrl: './content-not-found.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ContentNotFoundComponent {
    @Input() public title = '';
    @Input() public subTitle = '';
}
