<div class="side-menu-container" fxLayout="column">
    <ng-container *ngIf="showTaskSelection">
        <button class="menu-tab"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="2px"
            (click)="openTaskSelection()">

            <mat-icon aria-hidden="true">post_add</mat-icon>
            <span class="tab-name">{{ 'sideMenu.create' | translate }}</span>
        </button>

        <mat-divider></mat-divider>
    </ng-container>

    <ng-container *ngFor="let tab of navigationTabs">
        <button class="menu-tab"
            [ngClass]="{ 'active-menu-tab': isActiveState(tab.state), 'settings': tab.name === 'Settings' }"
            (click)="openTab(tab)">

            <div class="selection-indicator"></div>

            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2px">
                <ng-container>
                    <mat-icon *ngIf="tab.type === 'svg'"
                        [svgIcon]="tab.icon"
                        aria-hidden="true">
                    </mat-icon>

                    <mat-icon *ngIf="tab.type === 'material'"
                        aria-hidden="true">
                        {{ tab.icon }}
                    </mat-icon>

                    <mat-icon *ngIf="tab.type === 'font-awesome'"
                        [class]="tab.icon"
                        aria-hidden="true">
                    </mat-icon>
                </ng-container>

                <span class="tab-name">{{ tab.displayName }}</span>
            </div>
        </button>
    </ng-container>
</div>
