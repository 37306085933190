import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import CodeDescription from '../../../core/data-models/code-description';
import CustomReportTemplate from '../../../core/data-models/custom-report-template/custom-report-template';
import Placeholder from '../../../core/data-models/custom-report-template/placeholder';
import PlaceholderEvent from '../../../core/data-models/custom-report-template/placeholder-event';
import GridItemEditOption from '../../../core/data-models/grid-item-edit-option';
import ValidatorSet from '../../../core/data-models/validator-set';
import { ReportPlaceholderEventType } from '../../../core/enums/report-placeholder-event-type.enum';
import { ReportPlaceholderReportContent } from '../../../core/enums/report-placeholder-report-content.enum';
import { ReportPlaceholderType } from '../../../core/enums/report-placeholder-type.enum';

@Component({
    selector: 'placeholder-list',
    styleUrls: ['./placeholder-list.component.scss'],
    templateUrl: './placeholder-list.component.html'
})
export class PlaceholderListComponent implements OnInit {
    @Input() public template: CustomReportTemplate;
    @Output() public placeholderChanged: EventEmitter<PlaceholderEvent> = new EventEmitter<PlaceholderEvent>();
    public editOptions: GridItemEditOption;
    private _placeholderIcons: CodeDescription[] = [];

    public constructor(private _translate: TranslateService) { }

    public ngOnInit(): void {
        const errorMessage = this._translate.instant('forms.errors.requiredField');
        this.editOptions = {
            validatorSet: new ValidatorSet(Validators.required, errorMessage)
        } as unknown as GridItemEditOption;

        this._placeholderIcons.push(new CodeDescription('calendar_today', ReportPlaceholderReportContent.TodayDate));
        this._placeholderIcons.push(new CodeDescription('edit_calendar', ReportPlaceholderReportContent.JobDate));
        this._placeholderIcons.push(new CodeDescription('numbers', ReportPlaceholderReportContent.ProjectId));
        this._placeholderIcons.push(new CodeDescription('place', ReportPlaceholderReportContent.ProjectAddress));
        this._placeholderIcons.push(new CodeDescription('format_align_left', ReportPlaceholderReportContent.FieldReport));
        this._placeholderIcons.push(new CodeDescription('format_list_bulleted', ReportPlaceholderReportContent.RoofSections));
        this._placeholderIcons.push(new CodeDescription('person', ReportPlaceholderReportContent.TechName));
        this._placeholderIcons.push(new CodeDescription('apartment', ReportPlaceholderReportContent.ClientBuildingId));
        this._placeholderIcons.push(new CodeDescription('text_fields', 'text'));
        this._placeholderIcons.push(new CodeDescription('image', 'image'));
    }

    public addFieldReportPlaceholder(): void {
        const id = this.getUniqueId(ReportPlaceholderType.ReportContent);
        const placeHolder = {
            identifier: `${ReportPlaceholderReportContent.FieldReport}-${id}`,
            name: this._translate.instant('templateEditor.placeholderList.fieldReportContent'),
            type: ReportPlaceholderType.ReportContent
        } as Placeholder;

        this.addPlaceholder(placeHolder);
    }

    public addRoofSectionsPlaceholder(): void {
        const id = this.getUniqueId(ReportPlaceholderType.ReportContent);
        const placeHolder = {
            identifier: `${ReportPlaceholderReportContent.RoofSections}-${id}`,
            name: this._translate.instant('templateEditor.placeholderList.roofSections'),
            type: ReportPlaceholderType.ReportContent
        } as Placeholder;

        this.addPlaceholder(placeHolder);
    }

    public addTextPlaceholder(): void {
        const id = this.getUniqueId(ReportPlaceholderType.Text);
        const prefix = this._translate.instant('templateEditor.placeholderList.text');
        const placeHolder = {
            identifier: id,
            name: `${prefix} ${id}`,
            type: ReportPlaceholderType.Text
        } as Placeholder;

        this.addPlaceholder(placeHolder);
    }

    public addImagePlaceholder(): void {
        const id = this.getUniqueId(ReportPlaceholderType.Image);
        const prefix = this._translate.instant('templateEditor.placeholderList.image');
        const placeHolder = {
            identifier: id,
            name: `${prefix} ${id}`,
            type: ReportPlaceholderType.Image
        } as Placeholder;

        this.addPlaceholder(placeHolder);
    }

    public addTodayPlaceholder(): void {
        const id = this.getUniqueId(ReportPlaceholderType.ReportContent);
        const placeHolder = {
            identifier: `${ReportPlaceholderReportContent.TodayDate}-${id}`,
            name: this._translate.instant('templateEditor.placeholderList.today'),
            type: ReportPlaceholderType.ReportContent
        } as Placeholder;

        this.addPlaceholder(placeHolder);
    }

    public addProjectIdPlaceholder(): void {
        const id = this.getUniqueId(ReportPlaceholderType.ReportContent);
        const placeHolder = {
            identifier: `${ReportPlaceholderReportContent.ProjectId}-${id}`,
            name: this._translate.instant('templateEditor.placeholderList.projectId'),
            type: ReportPlaceholderType.ReportContent
        } as Placeholder;

        this.addPlaceholder(placeHolder);
    }

    public addProjectAddressPlaceholder(): void {
        const id = this.getUniqueId(ReportPlaceholderType.ReportContent);
        const placeHolder = {
            identifier: `${ReportPlaceholderReportContent.ProjectAddress}-${id}`,
            name: this._translate.instant('templateEditor.placeholderList.projectAddress'),
            type: ReportPlaceholderType.ReportContent
        } as Placeholder;

        this.addPlaceholder(placeHolder);
    }

    public addTechNamePlaceholder(): void {
        const id = this.getUniqueId(ReportPlaceholderType.ReportContent);
        const placeHolder = {
            identifier: `${ReportPlaceholderReportContent.TechName}-${id}`,
            name: this._translate.instant('templateEditor.placeholderList.techName'),
            type: ReportPlaceholderType.ReportContent
        } as Placeholder;

        this.addPlaceholder(placeHolder);
    }

    public addJobDatePlaceholder(): void {
        const id = this.getUniqueId(ReportPlaceholderType.ReportContent);
        const placeHolder = {
            identifier: `${ReportPlaceholderReportContent.JobDate}-${id}`,
            name: this._translate.instant('templateEditor.placeholderList.jobDate'),
            type: ReportPlaceholderType.ReportContent
        } as Placeholder;

        this.addPlaceholder(placeHolder);
    }

    public addClientBuildingIdPlaceholder(): void {
        const id = this.getUniqueId(ReportPlaceholderType.ReportContent);
        const placeHolder = {
            identifier: `${ReportPlaceholderReportContent.ClientBuildingId}-${id}`,
            name: this._translate.instant('templateEditor.placeholderList.clientBuildingId'),
            type: ReportPlaceholderType.ReportContent
        } as Placeholder;

        this.addPlaceholder(placeHolder);
    }

    public deletePlaceholder(placeholder: Placeholder): void {
        const event = new PlaceholderEvent();
        event.event = ReportPlaceholderEventType.Delete;
        event.placeholder = placeholder;

        this.placeholderChanged.emit(event);
    }

    public updatePlaceholder(placeholder: Placeholder, newName: string): void {
        const event = new PlaceholderEvent();
        event.event = ReportPlaceholderEventType.Update;
        event.placeholder = {
            identifier: placeholder.identifier,
            name: newName,
            type: placeholder.type
        };

        this.placeholderChanged.emit(event);
    }

    public getEditOptions(placeholder: Placeholder): GridItemEditOption {
        return placeholder.type !== ReportPlaceholderType.ReportContent? this.editOptions : null;
    }

    public getPlaceholderIcon(placeholderId: string): string {
        return this._placeholderIcons.find(_ => placeholderId.startsWith(_.description))?.code;
    }

    private addPlaceholder(placeholder: Placeholder): void {
        const event = new PlaceholderEvent();
        event.event = ReportPlaceholderEventType.Add;
        event.placeholder = placeholder;

        this.placeholderChanged.emit(event);
    }

    private getUniqueId(type: ReportPlaceholderType): string {

        if (type === ReportPlaceholderType.Image) {
            return `image-${new Date().getTime()}`;
        }

        if (type === ReportPlaceholderType.Text) {
            return `text-${new Date().getTime()}`;
        }

        return `${new Date().getTime()}`;
    }
}
