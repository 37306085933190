import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import LeakReportData from '../../../data-models/leak-report/leak-report-data';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LeakReportHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/leakreport`;

    constructor(private _http: HttpClient) { }

    public async getLeakReport(leakReport: LeakReportData, isSummary: boolean): Promise<string> {
        try {
            const endpoint = `${this._api}/${isSummary? 'multi/' : ''}getleakreport`;

            return await this._http.post<string>(endpoint, leakReport).toPromise();
        }
        catch {
            return '';
        }
    }

    public async resetLeakReport(leakReport: LeakReportData, isSummary: boolean): Promise<string> {
        try {
            const endpoint = `${this._api}/${isSummary? 'multi/' : ''}resetleakreport`;

            return await this._http.post<string>(endpoint, leakReport).toPromise();
        }
        catch {
            return '';
        }
    }

    public async saveLeakReport(leakReport: LeakReportData, isSummary: boolean): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${isSummary? 'multi/' : ''}saveleakreport`;

            return await this._http.post<boolean>(endpoint, leakReport).toPromise();
        }
        catch {
            return false;
        }
    }
}
