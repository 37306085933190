import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CustomIcon from '../../../../core/data-models/custom-icon';

import TemplateFieldMeta from '../../../../core/data-models/template-field-meta';

@Component({
    selector: 'template-content-collection-card',
    styleUrls: ['./template-content-collection-card.component.scss'],
    templateUrl: './template-content-collection-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateContentCollectionCardComponent implements OnInit {
    @Input() public icon: CustomIcon;
    @Input() public allowEdit = false;
    @Input() public isLoading = false;
    @Input() public title = '';
    @Input() public editField: TemplateFieldMeta;
    @Input() public fields: TemplateFieldMeta[] = [];
    @Output() public editStart = new EventEmitter<TemplateFieldMeta>();
    @Output() public editSave = new EventEmitter<TemplateFieldMeta>();
    @Output() public editCancel = new EventEmitter();
    @Output() public pageChanged = new EventEmitter<number>();
    public page = 0;
    private _currentPage = '';

    get hasPreviousPage(): boolean {
        return this.page > 0;
    }

    get hasNextPage(): boolean {
        return this.page < this.fields.length - 1;
    }

    get currentPage(): string {
        return this._currentPage;
    }

    get canChangePage(): boolean {
        return !this.isLoading && !this.editField && this.fields.length > 1;
    }

    constructor(private _translate: TranslateService) { }

    public ngOnInit(): void {
        this.setCurrentPage();
    }

    public changePage(step: number): void {
        this.page += step;
        this.setCurrentPage();
        this.pageChanged.emit(this.page);
    }

    private setCurrentPage(): void {
        const messageKey = 'shared.templateContentCollection.viewingPageMessage';
        const parameters = { pageNumber: this.page + 1, totalPages: this.fields.length };
        this._currentPage = this._translate.instant(messageKey, parameters);
    }
}
