<dialog-action-card
    class="project-schedule-container"
    [icon]="'calendar_month'"
    [title]="translate.instant(projectScheduleOptions.isEditingSchedule ? 'scheduleProjectDialog.editTitle' : 'scheduleProjectDialog.title')"
    [isLoaded]="isLoaded"
    (close)="onClose()"
>
    <div class="project-schedule-content" *ngIf="isLoaded">
        <div class="project-link-container">
            <a fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px" class="project-details-link" href="/projects/{{data.projectId}}" target="_blank">
                <span>{{translate.instant('projects.redispatch.projectLinkText')}}</span>
                <mat-icon>open_in_new</mat-icon>
            </a>
        </div>
        <form [formGroup]="projectScheduleFormGroup" fxFill fxLayout="column" fxLayoutAlign="center center">
            <div class="project-schedule-main-form-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">

                <div fxFill>
                    <h4 style="margin-bottom: 10px;">{{ "scheduleProjectDialog.scheduleDates" | translate }}</h4>
                <div class="" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="1.5vh" fxFill>
                    <div class="schedule-date">
                        <kendo-datetimepicker [popupSettings]="popupSettings" [steps]="{ hour: 1, minute: 15, second: 15}" [placeholder]="translate.instant('labour.startDate')" class="date-picker"  #startDate calendarType="classic" format="MM/dd/yyyy hh:mm a" matInput ngDefaultControl formControlName="startDate"></kendo-datetimepicker>
                    </div>
                    <div class="schedule-date">
                        <kendo-datetimepicker [popupSettings]="popupSettings" [steps]="{ hour: 1, minute: 15, second: 15}" [placeholder]="translate.instant('labour.endDate')" [min]="startDate.value" class="date-picker" #endDate calendarType="classic" format="MM/dd/yyyy hh:mm a" matInput ngDefaultControl formControlName="endDate"></kendo-datetimepicker>
                    </div>
                    <button [disabled]="checkIfDateIsNotValid() || !startDate.value || !endDate.value" *ngIf="!projectScheduleOptions.isEditingSchedule" (click)="onAddScheduledTime()" mat-mini-fab color="primary" type="button">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <mat-error *ngIf="checkIfDateIsNotValid() && startDate.value && endDate.value">{{ 'calendarView.errors.invalidDateRange' | translate }}</mat-error>
                <ul class="schedule-dates" fxFill fxLayout="column" fxLayoutGap=".1em"  *ngIf="projectScheduleFormGroup.value.scheduleDates.length > 0 && !projectScheduleOptions.isEditingSchedule">
                    <li fxFill *ngFor="let date of projectScheduleFormGroup.value.scheduleDates" flexLayout="row" fxLayoutAlign="space-between center">
                        <div flexLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
                            <span class="scheduled-date">{{formatDate(date.startDate)}}</span>
                            <span>To</span>
                            <span class="scheduled-date">{{formatDate(date.endDate)}}</span>
                        </div>
                        <button (click)="onDeleteScheduledDate(date.id)" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </li>
                </ul>
            </div>

                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1.5vh" fxFill>
                    <crew-selector [horizontalMode]="true"
                        [healthScreeningOption]="healthScreeningOption"
                        [country]="country"
                        [defaultCrewId]="projectScheduleOptions?.crew?.crewId || projectScheduleOptions?.defaultCrewId"
                        [defaultBranch]="this.data?.branchInfo?.name || this.data?.branch"
                        (crewSelectedEvent)="crewSelected($event)"
                        [isNotEditable]="projectScheduleOptions.isNotEditable"
                        fxFill>
                    </crew-selector>
                </div>



                <div fxFill fxLayout="column" fxLayoutAlign="center start">
                    <h4 style="margin-bottom: 10px;">{{ 'scheduleProjectDialog.additionalInformation' | translate }}</h4>
                    <mat-form-field fxFlexFill>
                        <mat-label>{{ 'projects.redispatch.notes' | translate }}</mat-label>
                        <textarea [readonly]="projectScheduleOptions.isNotEditable" class="schedule-notes" matInput rows="10" formControlName="note"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="schedule-project-actions" fxLayout fxLayoutAlign="center center" fxFlexAlign="end" fxLayoutGap="1em">
                <button *ngIf="projectScheduleOptions.isEditingSchedule" (click)="onDeleteSchedule()" mat-raised-button color="warn">
                    <mat-icon>delete</mat-icon>
                    {{ "scheduleProjectDialog.delete" | translate }}
                </button>
                <button [disabled]="projectScheduleOptions.isNotEditable || !projectScheduleFormGroup.valid || checkIfDateIsNotValid()" mat-raised-button color="primary"
                    (click)="onScheduleProject()">{{ (projectScheduleOptions.isEditingSchedule ? 'scheduleProjectDialog.editCta' : 'scheduleProjectDialog.cta') | translate }}</button>
            </div>
        </form>
    </div>
</dialog-action-card>

