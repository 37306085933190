/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * custom plugin created for wind direction display as arrows
 */
import { Chart, ChartArea, Plugin, PointElement } from 'chart.js';
import { ChartJsService } from './chart-js.service';

export class CustomArrowForWindDirectionPlugin implements Plugin<'line'> {
    id = 'customArrowForWindDirection';

    afterDraw(chart: Chart): void {
        const chartArea: ChartArea = chart.chartArea;
        const topY = chartArea.top;

        const { ctx, data: { datasets } } = chart;
        const customArrowEnabled = (chart.options.plugins as any).customArrowForWindDirection?.enabled;

        if (!customArrowEnabled) {
            return;
        }

        const windDirectionLabel = ChartJsService.translate('weatherChart.weatherStats.windDirection').replace(/\s/g, '').toLocaleLowerCase();

        datasets.forEach((dataset, i) => {
            const label = dataset.label.replace(/\s/g, '').toLocaleLowerCase();
            if (label === windDirectionLabel && chart.isDatasetVisible(i)) {
                const meta = chart.getDatasetMeta(i);
                meta.data.forEach((point, index) => {
                    const dataValue = dataset.data[index];
                    if (dataValue !== null && typeof dataValue === 'number') {
                        const { x } = (point as PointElement).getCenterPoint();
                        const direction = dataValue as number;
                        ctx.save();
                        ctx.translate(x, topY);
                        ctx.rotate((direction - 90) * Math.PI / 180);
                        const lineLength = 15;
                        ctx.beginPath();
                        ctx.moveTo(0, 0);
                        ctx.lineTo(lineLength, 0);
                        ctx.strokeStyle = 'grey';
                        ctx.lineWidth = 2;
                        ctx.stroke();
                        ctx.beginPath();
                        ctx.moveTo(lineLength, 0);
                        ctx.lineTo(lineLength - 5, -5);
                        ctx.lineTo(lineLength - 5, 5);
                        ctx.fillStyle = 'grey';
                        ctx.fill();
                        ctx.restore();
                    }
                });
            }
        });
    }
}