import { environment as base } from './environment.base';

export const environment = {
    ...base,
    fswApiUrl: 'https://devapi.flynnservicenonprod.com/',
    signalrUrl: 'https://devapi.flynnservicenonprod.com/signalr',
    fswUrl: 'https://dev.flynnservicenonprod.com/',
    cloudfrontUrl: 'https://d1abyn1ajgxp2j.cloudfront.net/',
    toolbarColor: 'rgb(255, 0, 0)',
    isInternalBuild: true,
    loginLogo: '../assets/images/flynn_logo_large.png'
};
