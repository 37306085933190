import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';

@Component({
    selector: 'project-map-dialog',
    styleUrls: ['./project-map-dialog.component.scss'],
    templateUrl: './project-map-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectMapDialogComponent implements OnInit {
    private _center: google.maps.LatLngLiteral;
    private _options: google.maps.MapOptions;
    private _isLoaded = false;

    constructor(private _projectHttp: ProjectHttpService,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialogRef<ProjectMapDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private _projectId: string,
                public translate: TranslateService) { }

    get center(): google.maps.LatLngLiteral {
        return this._center;
    }

    get options(): google.maps.MapOptions {
        return this._options;
    }

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    public async ngOnInit(): Promise<void> {
        const location = await this._projectHttp.getProjectGeoLocation(this._projectId);

        if (!location) {
            const ok = this.translate.instant('snackbars.ok');
            this._snackBar.open(this.translate.instant('projects.projectMap.errorMsg'), ok);
            this.onClose();

            return;
        }
        const mapTypeId = google.maps.MapTypeId.ROADMAP;
        this._center = { lat: location.latitude, lng: location.longitude };
        this._options = { mapTypeId, zoomControl: true, panControl: false };
        this._isLoaded = true;
    }

    public onClose(): void {
        this._dialog.close();
    }
}
