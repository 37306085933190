import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';

import UserInfo from '../../../../core/data-models/user-info';
import HistoryStepConfig from '../../../../core/data-models/dispatch/history-step-config';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';

@Component({
    selector: 'app-history-step',
    styleUrls: ['./history-step.component.scss'],
    templateUrl: './history-step.component.html',
    encapsulation: ViewEncapsulation.None
})
export class HistoryStepComponent implements OnInit {
    @Input() public config: HistoryStepConfig;
    @Output() public stepClicked = new EventEmitter<string>();
    public user: UserInfo;

    constructor(private _userHttpService: UserHttpService) { }

    public async ngOnInit(): Promise<void> {
        this.user = await this._userHttpService.getUserInfo();
    }

    public setCompleteAndMove(): void {
        this.config.completed = true;
        setTimeout(() => this.stepClicked.emit('next'));
    }
}
