import { Component, Output, Input, EventEmitter, ViewEncapsulation } from '@angular/core';
import AttachmentStepConfig from '../../../../core/data-models/dispatch/attachment-step-config';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { GenericUtilityService } from '../../../../core/services/utility/generic-utility/generic-utility.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { DownloadHttpService } from '../../../../core/services/http/download-http/download-http.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-attachment-step',
    styleUrls: ['./attachment-step.component.scss'],
    templateUrl: './attachment-step.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AttachmentStepComponent {
    @Input() public config: AttachmentStepConfig;
    @Output() public stepClicked = new EventEmitter<string>();
    public roofMapUrl: SafeStyle;

    public constructor(
        public translate: TranslateService,
        private _downloadService: DownloadHttpService,
        private _domSanitizer: DomSanitizer,
        private _snackBar: MatSnackBar) {
    }

    public dropped(files: NgxFileDropEntry[]) : void {
        const filteredFiles = files.filter(_ => _.fileEntry.isFile);
        for (const droppedFile of filteredFiles) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            const fileType = fileEntry.name.split('.').pop().toLowerCase();
            if (!GenericUtilityService.allowedFileTypes().some(_ => _.fileType === fileType)) {
                const message = this.translate.instant('shared.attachmentStep.snackBarMessage');
                const close = this.translate.instant('snackbars.close');
                this._snackBar.open(`${message} ${fileEntry.name}`, close);
                return;
            }
            fileEntry.file((file: File) => {
                this.delete(file);
                this.config.attachments = [...this.config.attachments, file];
            });
        }
    }

    public download(file: File): void {
        this._downloadService.downloadBlob(file, file.name);
    }

    public delete(file: File): void {
        this.config.attachments = this.config.attachments.filter(_ => _.name !== file.name);
    }

    public roofMapFileChanged(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.config.roofMap = inputElement.files[0];
        this.roofMapUrl = this._domSanitizer.bypassSecurityTrustStyle(`url('${URL.createObjectURL(this.config.roofMap)}')`);
    }
}
