import { ImageFormat, ExportFormat, Tool } from 'photoeditorsdk';

export default class PhotoEditorConstants {

    public static properties = {
        //eslint-disable-next-line
        license: '{"api_token":"MHhAd8Fadzo88bOdqZW1OA","app_identifiers":["127.0.0.1","localhost","*.flynndevawapps.com","*.flynnuatawapps.com","*.flynnatawapps.com","*.flynntrnawapps.com","*.flynnawapps.com","*.flynncompanies.com","*.flynnservicenonprod.com","*.flynnservice.com","*.fieldzoom.com","*.fieldzoomnonprod.com"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","brush","sticker","text","transform"],"issued_at":1647368689,"minimum_sdk_version":"1.0","owner":"Alan Zych","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"hz9n38BDb3n0tgfUCccYJf9nLkE7S29HB9cGLxOuSNzmbbHIwJdXkPEI6I8ADdJVsknP8xEA0TerRB/QVGKktyomC8AUK6FFKHYJZA4j0T070xVneiYt8dNddF0JyFSfvCIaqWszXrgxhcIDgWqxGvKJvzYzdmfjB9T5W0z8Qe11lcqrdfYYt6jlcEDx+2OahURR77FfOX9Jux5v6KxELPYQw3w+1lU4rRzDG333n+/EASyMWMF1Ye/i+GST2Sghn07t56grrm7CT190DTgTofQgNAEN8mNxg/Vhdy2KY7UyPUUyRXwdxCB99kCmglVjgsnD0BkCBQiMNzdEDiZUaRSYRQw7NsIqyVDlA843DNpu5TQBiZxHQ5SwOUm3FvwFzVPrrY0mnSlU/gJg9O2k1LsvsK4D1PEpxTYfv5pGtLHieUfBajt0hDKryRL9Kd6JfowMCbSMBfiw0Q4kbrsMmJ7tCN0vH6gC/D/mFR0H1I526C3cHsq8TSe/7m3EMB+tE6s6LuDvrVcRIS5o/6SmSgBdsb6v+7tzac+MDHZfyXxTwIaEWhrWH7VcXVuF/qobDQcn41w13kLMUGiATnMWcVmAn1T208vrB5oYhY6IW6GH2kraAj9pu9uCd6a4SoyQwqCJtI8HA69oegRB21hg6jeEiCrpVQj+RqouSBJvSHk="}',
        layout: 'basic',
        theme: 'light',
        assetBaseUrl: '/assets/photoeditorsdk',
        export: {
            image: {
                format: ImageFormat.JPEG,
                exportType: ExportFormat.BLOB,
                quality: 1.0,
                enableDownload: false
            }
        },
        tools: [Tool.TRANSFORM, Tool.ADJUSTMENT, Tool.TEXT, Tool.STICKER, Tool.BRUSH],
        defaultTool: Tool.BRUSH,
        sticker: {
            flattenCategories: true,
            categories: [
                {
                    identifier: 'imgly_sticker_category_shapes',
                    items: [
                        { identifier: 'imgly_sticker_shapes_arrow_02' },
                        { identifier: 'imgly_sticker_shapes_arrow_03' },
                        { identifier: 'imgly_sticker_shapes_badge_01' },
                        { identifier: 'imgly_sticker_shapes_badge_04' },
                        { identifier: 'imgly_sticker_shapes_badge_06' },
                        { identifier: 'imgly_sticker_shapes_badge_08' },
                        { identifier: 'imgly_sticker_shapes_badge_11' },
                        { identifier: 'imgly_sticker_shapes_badge_12' },
                        { identifier: 'imgly_sticker_shapes_badge_15' },
                        { identifier: 'imgly_sticker_shapes_badge_20' },
                        { identifier: 'imgly_sticker_shapes_badge_28' },
                        { identifier: 'imgly_sticker_shapes_badge_32' },
                        { identifier: 'imgly_sticker_shapes_badge_35' },
                        { identifier: 'imgly_sticker_shapes_spray_01' }
                    ]
                }
            ]
        },
        transform: {
            flattenCategories: true,
            categories: [
                {
                    identifier: 'imgly_transforms_common',
                    items: [
                        { identifier: 'imgly_transform_common_square' }
                    ]
                }
            ]
        },
        custom: {
            languages: {
                en: {
                    mainCanvasActions: {
                        buttonExport: 'Save Changes'
                    }
                }
            }
        }
    };
}
