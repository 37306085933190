<mat-card fxFill fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start start">
    <div fxFlexAlign="end" *ngIf="invoiceUploadAction.isAllowed" [matTooltip]="invoiceUploadAction.tooltip">
        <button mat-stroked-button color="primary"
            (click)="launchUploadInvoiceDialog()"
            [disabled]="invoiceUploadAction.isDisabled">
            {{ 'projects.invoice.uploadInvoice' | translate }}
        </button>
    </div>

    <div fxFill fxLayoutAlign="center center" *ngIf="isLoading">
        <dots-loader></dots-loader>
    </div>

    <ng-container *ngIf="!isLoading">
        <div *ngIf="!isProcessedWithInvoice"
            class="project-no-invoice-uploaded-view-container"
            fxLayoutAlign="center center">

            <span>{{ 'projects.invoice.processedWithoutInvoice' | translate }}</span>
        </div>

        <div *ngIf="isProcessedWithInvoice && !hasAnUploadedInvoice"
            class="project-no-invoice-uploaded-view-container"
            fxLayout="column"
            fxLayoutAlign="center center">

            <mat-icon svgIcon="fileNotFound" aria-hidden="true"></mat-icon>
            <span class="invoice-not-found-notice-message">{{ 'projects.invoice.noInvoiceFound' | translate }}</span>
        </div>

        <div *ngIf="isProcessedWithInvoice && hasAnUploadedInvoice"
            class="project-invoice-pdf-view-container"
            fxLayoutAlign="center center">

            <pdf-viewer style="height: 100%; width: 100%" class="project-invoice-pdf-view" [src]="invoiceUrl"></pdf-viewer>
        </div>
    </ng-container>
</mat-card>
