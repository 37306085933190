<div class="project-container">
    <summary-list-filter
        [filterConfigs]="filterConfiguration"
        [filterCheckBox]="notaRoofIssueCheckBox"
        (onFilterDropDownChecked)="filterProjectListByCheckBox($event)"
        (onKeyWordFilterSearch)="filterSearch($event)"
        (onCheckBoxStatusToggled)="onNotaRoofIssueCheckBoxToggled($event)">
    </summary-list-filter>

    <div class="project-bottom-wrapper" fxLayout="row">
        <summary-list [ngClass]="{ 'project-summary-list-collapsed': !summaryListExpanded }"
            [ngStyle]="summaryListStyle"
            [isLoading]="isSummaryListLoading"
            [queryResult]="queryResult"
            [activeId]="activeProjectId"
            [(isFocusMode)]="hasTargetSummary"
            (isFocusModeChange)="loadSummaries(query.currentPage)"
            (listRefresh)="loadSummaries(query.currentPage)"
            (pageChanged)="loadSummaries($event)"
            (summarySelected)="openProject($event)"
            (toggleExpanded)="onToggleExpand($event)">
        </summary-list>

        <div class="project-content-area" fxFlex>
            <nav class="tabs"
                #matTabNavBar
                mat-tab-nav-bar
                [ngClass]="{ 'project-tabs-hidden': !activeProjectId }"
                [disablePagination]="true"
                (window:resize)="resizeTabs()">

                <a mat-tab-link *ngFor="let tab of visibleTabs"
                    [uiSref]="tab.state"
                    [uiParams]="{ projectId: activeProjectId }"
                    uiSrefActive="active"
                    [active]="tabRef.classList.contains('active')"
                    #tabRef
                    class="tab-nav-with-badge">

                    <span [ngClass]="{ 'updated-badge': updatedTabs.has(tab) }" [matBadge]="tab.badge">
                        {{ tab.displayName }}
                    </span>
                </a>

                <button *ngIf="hiddenTabs.length" mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_horiz</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                    <button *ngFor="let tab of hiddenTabs"
                        mat-menu-item
                        [uiSref]="tab.state"
                        [uiParams]="{ projectId: activeProjectId }"
                        (click)="onHiddenTabSelected(tab.state)"
                        class="menu-nav-with-badge">

                        <span [matBadge]="tab.badge">{{ tab.displayName }}</span>
                    </button>
                </mat-menu>
            </nav>

            <div class="project-content" *ngIf="activeProjectId">
                <ui-view></ui-view>
            </div>

            <div class="blank-project-content" *ngIf="!activeProjectId" fxLayout="column" fxLayoutAlign="center center">
                <mat-icon *ngIf="isInternal" svgIcon="flynnTruckAnimated" aria-hidden="true"></mat-icon>
                <img *ngIf="!isInternal" src="../../../assets/images/field_zoom_logo.png" alt="empty state image" />

                <span class="project-notice-message">{{ 'projects.nothingSelectedYet' | translate }}</span>

                <div class="project-instruction-message" fxLayoutAlign="start center" fxLayoutGap="6px">
                    <mat-icon aria-hidden="true">double_arrow</mat-icon>
                    <span>{{ 'projects.selectProject' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
