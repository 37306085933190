import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import BudgetTemplate from '../../../../core/data-models/budget-template';
import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';
import { BudgetHttpService } from '../../../../core/services/http/budget-http/budget-http.service';
import { DownloadHttpService } from '../../../../core/services/http/download-http/download-http.service';
// eslint-disable-next-line max-len
import { ConfirmDialogComponent } from '../../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';

@Component({
    selector: 'budget-template-versions-list',
    styleUrls: ['./budget-template-versions-list.component.scss'],
    templateUrl: './budget-template-versions-list.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BudgetTemplateVersionsListComponent implements OnInit {
    public templates: BudgetTemplate[] = [];
    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
                private _budgetHttp: BudgetHttpService,
                private _downloadHttp: DownloadHttpService,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialog,
                private _dialogRef: MatDialogRef<BudgetTemplateVersionsListComponent>,
                @Inject(MAT_DIALOG_DATA) public data: BudgetTemplate[] = []) { }

    get latestVersion(): BudgetTemplate {
        return this.templates[0];
    }

    public ngOnInit(): void {
        this.templates = this.data.slice().sort((a, b) => b.version - a.version);
    }

    public onActiveStatusChange(template: BudgetTemplate): void {
        this._budgetHttp.updateBudgetTemplateActiveStatus(template);
    }

    public async downloadTemplate(template: BudgetTemplate): Promise<void> {
        const url = this._budgetHttp.getBudgetTemplateUrl(template);

        if (!await this._downloadHttp.download(url, template.fileName, 'application/octet-stream')) {
            const message = this.translate.instant('admin.budgetTemplateManagement.versionsList.failedToDownloadTemplate');
            this._snackBar.open(message, this.translate.instant('shared.ok'));
        }
    }

    public async deleteTemplate(template: BudgetTemplate): Promise<void> {
        const title = this.translate.instant('admin.budgetTemplateManagement.versionsList.deleteDialog.title');
        const message = this.translate.instant('admin.budgetTemplateManagement.versionsList.deleteDialog.message');
        const confirmText = this.translate.instant('shared.delete');
        const cancelText = this.translate.instant('shared.cancel');
        const data = new ConfirmDialogOption(title, message, true, confirmText, cancelText);
        const dialog = this._dialog.open(ConfirmDialogComponent, { maxWidth: '400px', data });

        if (!await dialog.afterClosed().toPromise()) {
            return;
        }

        if (await this._budgetHttp.deleteBudgetTemplate(template)) {
            this.templates = this.templates.filter(_ => _.version !== template.version);
        }
        else {
            const error = this.translate.instant('admin.budgetTemplateManagement.versionsList.failedToDeleteTemplate');
            this._snackBar.open(error, this.translate.instant('shared.ok'));
        }

        if (!this.templates.length) {
            this.onClose();
        }
    }

    public onClose(): void {
        this._dialogRef.close();
    }
}
