import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import CustomIcon from '../../../../core/data-models/custom-icon';
import TemplateFieldMeta from '../../../../core/data-models/template-field-meta';

@Component({
    selector: 'template-content-card',
    styleUrls: ['./template-content-card.component.scss'],
    templateUrl: './template-content-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateContentCardComponent {
    @Input() public level = 0;
    @Input() public index = 0;
    @Input() public icon: CustomIcon;
    @Input() public useSvg = false;
    @Input() public allowEdit = false;
    @Input() public isEditMode = false;
    @Input() public isLoading = false;
    @Input() public template: TemplateFieldMeta;
    @Output() public editStart = new EventEmitter<TemplateFieldMeta>();
    @Output() public editSave = new EventEmitter<TemplateFieldMeta>();
    @Output() public editCancel = new EventEmitter();
    @Output() public editChange = new EventEmitter<TemplateFieldMeta>();

    get hasDisplayItem(): boolean {
        return this.template.fields.some(_ => _.isDisplayItem);
    }

    get isEmptyRoot(): boolean {
        return this.level === 0 && !this.template.fields.length;
    }

    get isEmptySection(): boolean {
        return this.level > 0 && !this.template.fields.length;
    }

    get containerStyle(): { [key: string]: boolean } {
        return {
            'template-content-card-root-container': this.level === 0,
            'template-content-card-display-items-container': this.hasDisplayItem || this.isEmptySection
        };
    }

    public getVisibleFields(fields: TemplateFieldMeta[]): TemplateFieldMeta[] {
        return this.isEditMode ? fields.filter(_ => _.isVisible) : fields;
    }

    public onFieldValueChange(field: TemplateFieldMeta): void {
        for (const logic of field.logics) {
            const { name, targetFields } = logic;
            const { matchingValue, matchingTargetValue, unMatchingTargetValue } = logic;

            for (const fieldName of targetFields) {
                const value = field.value === matchingValue ? matchingTargetValue : unMatchingTargetValue;
                const target = this.template.fields.find(_ => _.nameAttributes.originalName === fieldName);

                if (name === 'ToggleVisibility') {
                    target.isVisible = value === 'true';
                }
                else if (name === 'ToggleRequired') {
                    target.isRequired = value === 'true';
                }
                else if (name === 'ToggleEnabled') {
                    target.isEnabled = value === 'true';
                }
            }
        }
    }
}
