import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textWithPlaceholder'
})
export class TextWithPlaceholderPipe implements PipeTransform {
    public transform(text: string, placeholder = 'N/A'): string {
        return text ? text : placeholder;
    }
}
