export enum SaleCode {
    SaleCode312='312',
    SaleCode307='307',
    SaleCode310='310',
    SaleCode311='311',
    SaleCode313='313',
    SaleCode314='314',
    SaleCode315='315',
    SaleCode316='316',
    SaleCode317='317',
    SaleCode318='318',
    SaleCode319='319',
    SaleCode321='321',
    SaleCode330='330',
    SaleCode331='331',
    SaleCode332='332',
    SaleCode333='333',
    SaleCode334='334',
    SaleCodeaaa='aaa',
    SaleCodeeee='eee',
    SaleCodeggg='ggg',
    SaleCodemmm='mmm'
}

export enum Status {
    Status='6'
}
