<div fxLayout="column" fxLayoutGap="20px" class="branch-management-container" *ngIf="isLoaded">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" class="branch-buttons-container">
        <button color="primary" (click)="addOrUpdateBranch(null)" mat-raised-button>
            {{ (isInternal ? 'admin.branchManagement.addBranchButtonText' : 'admin.branchManagement.addServiceProviderButtonText') | translate }}
        </button>
    </div>

    <div fxLayout="row" [fxLayoutGap]="isInternal ? '50px' : '20px'" class="branch-cards-container">
        <mat-card [ngClass]="{ 'built-in-branch': branch.isBuiltIn, 'internal-card': isInternal, 'commercial-card': !isInternal, 'branch-info-card': true }" *ngFor="let branch of branches" fxLayoutGap="10px">
            <div fxLayout="column" fxFill fxLayoutGap="10px">
                <i class="fa fa-clock-o update-timezone-button" title="Click to update timezone" (click)="updateBranchTimezone(branch)"></i>
                <div [fxFlex]="isInternal ? '60%' : '30%'">
                    <div class="branch-card-title">
                        {{ branch.name }}
                    </div>
                    <div class="branch-card-subtitle">
                        <ng-container *ngIf="branch.address.addr2 && branch.address.addr2.trim().length">{{ branch.address.addr2 }}<br></ng-container>
                        {{ branch.address.addr1 }}<br>
                        {{ branch.address.city }}, {{ branch.address.state }}, {{ branch.address.zip }}
                    </div>
                </div>

                <div fxFlex="60%" class="branch-card-contact-details">
                    <p *ngIf="isInternal"><i class="fa fa-building branch-helper-icon"></i>{{ 'admin.branchManagement.branchCodeLabel' | translate }}: {{ branch.code || ('shared.valueNotSet' | translate) }}</p>
                    <p><i class="fa fa-phone branch-helper-icon"></i>{{ branch.phone || ('shared.valueNotSet' | translate) }}</p>
                    <p><i class="fa fa-fax branch-helper-icon"></i>{{ branch.fax || ('shared.valueNotSet' | translate) }}</p>
                    <p *ngIf="!isInternal"><i class="fa fa-envelope branch-helper-icon"></i>{{ branch.email || ('shared.valueNotSet' | translate) }}</p>
                    <p *ngIf="!isInternal"><i class="fa fa-globe branch-helper-icon"></i>{{ branch.website || ('shared.valueNotSet' | translate) }}</p>
                    <p><i class="fa fa-clock-o branch-helper-icon"></i>{{ (branch.useDaylightSaving? 'admin.branchManagement.useDaylightSaving' : 'admin.branchManagement.doesNotUseDaylightSaving') | translate}}</p>
                    <p>
                        <mat-icon [ngClass]="{'branch-helper-mat-icon': true, 'branch-done-icon': branch.useErpBudget, 'branch-close-icon': !branch.useErpBudget}">{{ branch.useErpBudget ? 'done' : 'close' }}</mat-icon>{{ (branch.useErpBudget ? 'admin.branchManagement.useErpBudget' : 'admin.branchManagement.doesNotUseErpBudget') | translate }}
                    </p>
                    <p>
                        <mat-icon [ngClass]="{'branch-helper-mat-icon': true, 'branch-done-icon': branch.useErpInvoice, 'branch-close-icon': !branch.useErpInvoice}">{{ branch.useErpInvoice ? 'done' : 'close' }}</mat-icon>{{(branch.useErpInvoice ? 'admin.branchManagement.useErpInvoice' : 'admin.branchManagement.doesNotUseErpInvoice') | translate }}
                    </p>
                </div>

                <div *ngIf="isInternal" fxFlex="20%">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="100%">
                            <mat-label>{{ 'admin.branchManagement.geofenceAreaLabel' | translate }}</mat-label>
                            <input type="number" [(ngModel)]="branch.geoLocation.fenceArea" (change)="updateGeofence(branch)" name="fenceArea" step="50" min="500" max="5000" [disabled]="branch.isBuiltIn" matInput />
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="isInternal" fxFlex="20%">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="100%">
                            <mat-label>{{ 'admin.branchManagement.followUpGoalLabel' | translate }}</mat-label>
                            <input type="number" [(ngModel)]="branch.followUpGoalThisYear" (change)="updateFollowUpGoals(branch)" [disabled]="branch.isBuiltIn" matInput />
                        </mat-form-field>
                    </div>
                </div>

                <div fxFlex fxLayout="column" fxLayoutAlign="end center">
                    <div fxLayout="row" id="deactivate-button-row" fxLayoutGap="5px" fxLayoutAlign="end end">
                        <button *ngIf="!isInternal" color="warn" fxFlex="35%" (click)="setDeactivatedStatus(branch, !branch.isDeactivated)" [hidden]="!isAdmin" [disabled]="isDeactivateLoading || branch.isBuiltIn" mat-raised-button>
                            <ng-container *ngIf="branch.isDeactivated">{{ 'admin.branchManagement.activateButtonText' | translate }}</ng-container>
                            <ng-container *ngIf="!branch.isDeactivated">{{ 'admin.branchManagement.deactivateButtonText' | translate }}</ng-container>
                        </button>
                        <button color="primary" fxFlex="25%" [disabled]="isDeactivateLoading || branch.isBuiltIn" (click)="addOrUpdateBranch(branch)" mat-raised-button>
                            {{ 'shared.edit' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
