<mat-card class="follow-up-logs-container" fxFill>
    <div fxLayout="column" *ngIf="!addMode" fxFill>
        <kendo-grid [kendoGridBinding]="gridViewData" pageSize="15" pageable="true" sortable="true">
            <ng-template kendoGridToolbarTemplate>
                <div fxLayoutGap="10px" style="width: 100%">
                    <button class="mat-flat-button mat-primary flat-button" kendoGridExcelCommand [disabled]="!gridViewData.length">{{ 'shared.quoteFollowUpLogs.exportToExcel' | translate }}</button>
                    <button *ngIf="!quoteEditorMode" mat-flat-button color="primary" (click)="addFollowUpReminder()">
                        {{ 'shared.quoteFollowUpLogs.reminder' | translate }}
                    </button>
                    <div fxFlex></div>
                    <div fxLayoutAlign="end center">
                        <button mat-mini-fab color="primary" (click)="showAdd()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-template>
            <kendo-grid-column field="followUpText" title="{{ 'shared.quoteFollowUpLogs.followUpLog' | translate }}">
            </kendo-grid-column>

            <kendo-grid-column title="{{ 'shared.quoteFollowUpLogs.isAttempt' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.isFollowUpAttempt ? 
                        translate.instant('shared.quoteFollowUpLogs.yes') : 
                        translate.instant('shared.quoteFollowUpLogs.no') 
                    }}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="creatorName" title="{{ 'shared.quoteFollowUpLogs.creatorName' | translate }}">
            </kendo-grid-column>

            <kendo-grid-column title="{{ 'shared.quoteFollowUpLogs.creationTime' | translate }}" field="followUpDatetime">
            </kendo-grid-column>

            <kendo-grid-excel fileName="QuoteFollowUpReport.xlsx" [fetchData]="allData">
            </kendo-grid-excel>
        </kendo-grid>
        <add-follow-up-reminder *ngIf="quoteEditorMode" [columnMode]="false" (result)="addFollowUpReminder($event)">
        </add-follow-up-reminder>
    </div>
    <add-follow-up-log *ngIf="addMode" [quoteEditorMode]="quoteEditorMode" (result)="processFollowUpLog($event)" fxFill>
    </add-follow-up-log>
</mat-card>
