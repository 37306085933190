import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import ImageItem from '../../../../core/data-models/job-template-items/image-item';
import ImageCardConfig from '../../../../core/data-models/card-items/image-card-config';
import ImageCardEvent from '../../../../core/data-models/card-items/image-card-event';
import { GenericUtilityService } from '../../../../core/services/utility/generic-utility/generic-utility.service';
import { ImageCardAction } from '../../../../core/enums/image-card-action.enum';
import TimeUtility from '../../../../core/services/utility/time-utility/time-utility.service';
import LanguageUtility from '../../../../core/services/utility/language-utility/language-utility.service';
import UserInfo from '../../../../core/data-models/user-info';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';

@Component({
    selector: 'app-photo-card',
    styleUrls: ['./photo-card.component.scss'],
    templateUrl: './photo-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoCardComponent implements OnInit {
    @Input() public photo: ImageItem;
    @Input() public config: ImageCardConfig;
    @Input() public highlighted: boolean;
    @Output() public cardEvent = new EventEmitter<ImageCardEvent>();
    public imageUrl: string;
    public ImageCardAction: typeof ImageCardAction = ImageCardAction;
    public user: UserInfo;

    get backgroundColor(): string {
        return this.highlighted? 'var(--secondary-colors-lightest)': '';
    }

    get localCreatedDate(): string {
        return TimeUtility.toBranchLocalDateTime(new Date(this.photo.createdDateUTC), this.config.timeZone);
    }

    get localizedTitle(): string {
        if (!this.user) {
            return '';
        }

        return LanguageUtility.getLanguageSetContent(this.user.language, this.photo.languageSets, this.photo.title);
    }

    constructor(public _userHttpService: UserHttpService, private _changeDetectorRef: ChangeDetectorRef) { }

    public async ngOnInit() : Promise<void> {
        this.imageUrl = GenericUtilityService.getImageUrl(this.photo, this.config.useThumbnails);
        this.user = await this._userHttpService.getUserInfo();

        this._changeDetectorRef.markForCheck();
    }

    public onDescriptionChange(): void {
        this.cardEvent.emit({
            action: ImageCardAction.Save,
            source: this.photo
        });
    }
}
