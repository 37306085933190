import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { TypeRecord } from 'src/flynn-budget-tool/core/data-models/generic/type-record';
import { BudgetEstimationService } from '../budget-estimation.service';
import { ItemExpense } from '../../../../core/data-models/budget-estimations/expenses/item-expense';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ValidatorService } from '../../../../core/services/validator-service/validator-service';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-miscellaneous-section',
    templateUrl: './miscellaneous-section.component.html',
    styleUrls: ['./miscellaneous-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiscellaneousSectionComponent implements OnInit, OnDestroy {
    @Input() public form: UntypedFormGroup;
    @Input() public calculationUpdated$: Subject<void>;
    @Input() public miscellaneousItemTypes: TypeRecord[];
    @Input() public readonly = false;
    public selectedOption: TypeRecord;
    public isReady = false;
    private _isComponentActive = true;

    get miscellaneousOptions(): UntypedFormArray {
        return this.form.get('miscellaneousSection.entries') as UntypedFormArray;
    }

    get totalCost(): UntypedFormControl {
        return this.form.get('miscellaneousSection.totalCost') as UntypedFormControl;
    }

    get unselectedOptions(): TypeRecord[] {
        const selectedOptions = this.miscellaneousOptions.value as ItemExpense[] ?? [];
        if (selectedOptions.length === 0) {
            return this.miscellaneousItemTypes;
        }

        return this.miscellaneousItemTypes.filter(itemType => !selectedOptions.some(selected => itemType.identifier === selected.typeIdentifier));
    }

    get showTotalCost(): boolean {
        return this.miscellaneousOptions.length > 0;
    }

    get selectionDisabled(): boolean {
        return !this.selectedOption;
    }

    constructor(
        private _translate: TranslateService,
        private _budgetEstimationService: BudgetEstimationService,
        private _formBuilder: UntypedFormBuilder,
        private _validatorService: ValidatorService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    public ngOnInit(): void {
        this.isReady = true;

        this.calculationUpdated$.pipe(
            takeWhile(() => this._isComponentActive)
        ).subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
    }

    public selectOption(ev: MatSelectChange): void {
        this.selectedOption = ev?.value as TypeRecord;
    }

    public addSelectedOption(): void {
        const control = this._budgetEstimationService.createMiscellaneousFormField(ItemExpense.fromTypeRecord(this.selectedOption));
        this.selectedOption = null;
        this.miscellaneousOptions.push(control);

        this._changeDetectorRef.markForCheck();
    }

    public removeOption(index: number): void {
        this.miscellaneousOptions.removeAt(index);

        this._changeDetectorRef.markForCheck();
    }

    public getName(identifier: string): string {
        return this.miscellaneousItemTypes.find(itemType => itemType.identifier === identifier)?.name ?? '';
    }

    public localize(token: string): string {
        return this._translate.instant(`flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.miscellaneousSection.${token}`);
    }
}
