import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import StockLogQuery from '../../../data-models/data-report/stock-log-query';
import InvoiceData from '../../../data-models/invoice-data';
import MaterialCost from '../../../data-models/job-materials/material-cost';
import StockLog from '../../../data-models/job-materials/stock-log';
import JsonUtility from '../../utility/json-utility.service';
import { UploadHttpService } from '../upload-http/upload-http.service';
import CodeDescription from '../../../data-models/code-description';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InvoiceHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/invoice`;

    constructor(private _http: HttpClient, private _uploadHttp: UploadHttpService, private _translate: TranslateService) { }

    public async uploadInvoice(projectId: string, invoice: InvoiceData): Promise<boolean> {
        const { invoiceNumber, draftNumber, grossAmount, invoiceFile } = invoice;
        const api = `${environment.fswApiUrl}api2/cloud/blob/upload`;
        const query = `invoiceNumber=${invoiceNumber}&draftNumber=${draftNumber}&grossAmount=${grossAmount}`;
        const endpoint = `${api}/invoice/${projectId}?${query}`;

        return await this._uploadHttp.upload(endpoint, invoiceFile, true);
    }

    public async getSignedProjectInvoiceUrl(projectId: string): Promise<string> {
        try {
            const baseApi = `${environment.fswApiUrl}api2/service/file`;
            const endpoint = `${baseApi}/invoice/getSignedURL/${projectId}`;
            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return '';
        }
    }


    public async getMaterialCost(projectId: string): Promise<MaterialCost> {
        try {
            const endpoint = `${this._api}/cost/material/${projectId}`;
            const data = await this._http.get<MaterialCost>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new MaterialCost();
        }
    }

    public async addStockLog(stockLog: StockLog): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/record/sharepoint/stocklog`;
            await this._http.post(endpoint, stockLog).toPromise();

            return new CodeDescription('200', '');
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('projects.details.stockLog.actions.error');
            switch (httpError.status) {
                case 400:
                case 401:
                case 403:
                    return new CodeDescription(httpError.status.toString(), httpError.error);

                default:
                    return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async getStockLogs(query: StockLogQuery): Promise<StockLog[]> {
        try {
            const endpoint = `${this._api}/stocklogs`;
            const data = await this._http.post<StockLog[]>(endpoint, query).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getStockLog(projectId: string): Promise<StockLog> {
        try {
            const endpoint = `${this._api}/stocklog/${projectId}`;
            const data = await this._http.get<StockLog>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new StockLog();
        }
    }

    public async updateStockLog(stocklog: StockLog): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/stocklog/update`;
            await this._http.put<boolean>(endpoint, stocklog).toPromise();

            return new CodeDescription('200', '');
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('projects.details.stockLog.actions.error');
            switch (httpError.status) {
                case 400:
                case 401:
                case 403:
                    return new CodeDescription(httpError.status.toString(), httpError.error);

                default:
                    return new CodeDescription('500', internalErrorMessage);
            }
        }
    }
}
