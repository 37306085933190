import { Component, AfterViewInit, ViewChild, Inject, ViewEncapsulation } from '@angular/core';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PhotoEditorSDKUI, EditorApi, UIEvent, Configuration } from 'photoeditorsdk';
import PhotoEditorConstants from './photo-editor-const';

import { GenericUtilityService } from '../../../../core/services/utility/generic-utility/generic-utility.service';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ReactDom: any;
    }
}

window.React = window.React || React;
window.ReactDom = window.ReactDom || ReactDom;

@Component({
    selector: 'app-photo-editor-dialog',
    styleUrls: ['./photo-editor-dialog.component.scss'],
    templateUrl: './photo-editor-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PhotoEditorDialogComponent implements AfterViewInit {
    @ViewChild('psdkContainer', { static: false }) container;
    private editor: EditorApi;

    constructor(private _translate: TranslateService,
        private dialogRef: MatDialogRef<PhotoEditorDialogComponent>,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: string) { }

    public async ngAfterViewInit(): Promise<void> {
        await this.instantiateEditor();
    }

    /* istanbul ignore next */
    private async instantiateEditor(): Promise<void> {
        try {
            const options = PhotoEditorConstants.properties as unknown as Configuration;
            options.container = this.container.nativeElement;

            const blob = await fetch(this.data).then(r => r.blob());
            options.image = await GenericUtilityService.blobToBase64(blob, true);

            this.editor = await PhotoEditorSDKUI.init(options);

            this.editor.on(UIEvent.EXPORT, (imageFile: Blob) => {
                this.dialogRef.close(imageFile);
            });
            this.editor.on(UIEvent.CLOSE, () => {
                this.dialogRef.close();
            });
        }
        catch {
            const failedOpenEditorMessage = this._translate.instant('shared.photoEditorDialog.failedOpenEditor');
            const close = this._translate.instant('snackbars.close');
            this.snackBar.open(failedOpenEditorMessage, close);
            this.dialogRef.close();
        }
    }
}
