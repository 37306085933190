export default class SiteFilter {
    public customerId: string;
    public address1: string;
    public address2: string;
    public city: string;
    public postalcode: string;
    public province: string;
    public country: string;

    // eslint-disable-next-line max-params
    constructor(customerId: string, address1: string, address2: string, city: string, postalcode: string, province: string, country: string) {
        this.customerId = customerId;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.postalcode = postalcode;
        this.province = province;
        this.country = country;
    }
}
