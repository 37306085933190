<mat-horizontal-stepper (selectionChange)="nextStepSelectedFromTopStepperNavigationBar($event)" fxFill linear #stepper fxLayout="column" class="customer-management-stepper">
    <mat-step
        [stepControl]="customerSelectionFormGroup"
        [editable]="!customerForSuggestions"
        [label]="translate.instant('shared.customerSelectManagement.stepperLabels.customer')">
        <customer-select (customerSelection)="selectCustomer($event)" *ngIf="!customerForSuggestions">
        </customer-select>
    </mat-step>
    <mat-step [stepControl]="siteSelectionFormGroup"
        [editable]="isEditable"
        [label]="translate.instant('shared.customerSelectManagement.stepperLabels.site')">
        <div *ngIf="!creatingCustomerSite" fxLayout="column" fxFill>
            <customer-site-suggestions *ngIf="customerForSuggestions"
                [customerId]="customerForSuggestions.customer?.customerId"
                [siteInfo]="customerForSuggestions.site"
                (siteSelected)="selectSite($event)" fxFlex="33%">
            </customer-site-suggestions>
            <customer-site-select [customerDisplayName]="customerDisplayName"
                [availableSites]="selectedCustomer?.addresses"
                (createNewSiteEvent)="toggleSelectSite()"
                (siteSelected)="selectSite($event)" fxFlex>
            </customer-site-select>
        </div>
        <customer-site-create *ngIf="creatingCustomerSite"
            [forceBilling]="false"
            (updateSiteAddress)="newSiteRequestUpdated($event)"
            (handleStep)="nextStep($event)"
            (cancelSiteCreation)="creatingCustomerSite=false"
            [initAddress]="customerForSuggestions?.site">
        </customer-site-create>
    </mat-step>
    <mat-step [stepControl]="billingSelectionFormGroup"
        [label]="translate.instant('shared.customerSelectManagement.stepperLabels.billingSite')">
        <div *ngIf="!creatingCustomerBillingSite" fxLayout="column" fxFill>
            <customer-site-suggestions *ngIf="customerForSuggestions"
                [customerId]="customerForSuggestions.customer?.customerId"
                [siteInfo]="customerForSuggestions.billingSite"
                [showBillingOnly]="true"
                (siteSelected)="selectBillingSite($event)" fxFlex="33%">
            </customer-site-suggestions>
            <customer-site-select
                [availableSites]="billingSites"
                [customerDisplayName]="customerDisplayName"
                (siteSelected)="selectBillingSite($event)"
                (createNewSiteEvent)="toggleSelectBillingSite()" fxFlex>
            </customer-site-select>
        </div>
        <customer-site-create *ngIf="creatingCustomerBillingSite"
            [forceBilling]="true"
            (updateSiteAddress)="newBillingSiteRequestUpdated($event)"
            (handleStep)="nextStep($event)"
            (cancelSiteCreation)="creatingCustomerBillingSite=false"
            [initAddress]="customerForSuggestions?.billingSite">
        </customer-site-create>

    </mat-step>
    <mat-step [label]="translate.instant('shared.customerSelectManagement.stepperLabels.confirmation')">
        <customer-selection-confirm *ngIf="allCustomerInfoSelected"
            [customerInfo]="customerSummaryGridInfo"
            [jobSiteInfo]="jobSiteSummaryGridInfo"
            [billingSiteInfo]="billingSiteSummaryGridInfo"
            (handleStep)="nextStep($event)"
            (confirmSummaryDetails)="confirmCustomerDecision($event)">
        </customer-selection-confirm>
    </mat-step>

</mat-horizontal-stepper>
