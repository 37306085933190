import Employee from './employee';
import ChangeRecord from './generic/change-record';
import CodeMapping from './generic/code-mapping';
import LocalizedContent from './localized-content';

export default class ProjectMergeRule {
    public code: number;
    public creator: Employee;
    public changes: ChangeRecord[] = [];
    public names: LocalizedContent[] = [];
    public mappings: CodeMapping<string>[] = [];
}
