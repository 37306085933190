import Address from '../address';
import UserProfile from '../user-profile';

export default class ProjectSummary {
    public name = '';
    public projectId = '';
    public projectIdDBformat = '';
    public createdTime = '';
    public preferredName = '';
    public branch = '';
    public status = '';
    public statusCode: number;
    public foreman = '';
    public rating = 0;
    public address: Address;
    public notRoofIssue = false;
    public budgetUploaded = false;
    public requireFabrication = false;
    public isRemoved = false;
    public currentViewers: UserProfile[] = [];
    public isProjectScheduled?: boolean;
}
