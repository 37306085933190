import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ValidatorService {
    public decimalValidator(maxDecimalPlaces = 0, minValueInclusive = 0): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const testValue = control.value;

            // Forbid a leading zero when it's unnecessary.
            // allow negative (checked later).
            // allow many decimal points (checked later).
            const regex = /^-?(?!0[0-9]+)[0-9]+(\.[0-9]+)?$/;

            if (!regex.test(testValue?.toString() ?? '') || isNaN(testValue)) {
                return { invalidFormat: true };
            }

            const numValue = Number(testValue);

            // Forbids -0
            if (1 / testValue === -Infinity) {
                return { negative: true };
            }

            const decimalPlaces = numValue.toString().split('.')[1]?.length || 0;
            if (decimalPlaces > maxDecimalPlaces) {
                return { allowedDecimalPlacesExceeded: true };
            }

            if (numValue < minValueInclusive) {
                return { belowMinimumValue: true };
            }

            return null;
        };
    }
}
