import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import HolidayEvent from '../../../../core/data-models/holiday-event';
import HolidayEventEntity from '../../../../core/data-models/kpi-data/holiday-event-entity';
import { AppConfigHttpService } from '../../../../core/services/http/appconfig-http/appconfig-http.service';
import { Moment } from 'moment-timezone';
import { MOMENT_DEFAULT_FORMAT } from '../../../../core/constants/moment-default-format';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import TimeUtility from '../../../../core/services/utility/time-utility/time-utility.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-holiday-edit-dialog',
    styleUrls: ['./holiday-edit-dialog.component.scss'],
    templateUrl: './holiday-edit-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MOMENT_DEFAULT_FORMAT }
    ]
})
export class HolidayEditDialogComponent implements OnInit {
    public newHolidayName = '';
    public confirmButton = '';
    public states = [];
    public holidayStates = {};
    public holidayObject: HolidayEventEntity = null;
    public pickedDate: Date = null;
    public updateDialog = false;
    public allCanada = false;
    public allUS = false;
    public statesCA = [];
    public statesUS = [];
    public dialogTitle = '';
    private closeString = '';

    constructor(private _appConfigService: AppConfigHttpService,
        private _snackbar: MatSnackBar,
        private _dialog: MatDialogRef<HolidayEditDialogComponent>,
        private _translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public holidayEvent: HolidayEvent) { }

    public async ngOnInit(): Promise<void> {
        this.holidayObject = new HolidayEventEntity();
        this.statesCA = await this._appConfigService.getCAStates();
        this.statesUS = await this._appConfigService.getUSStates();
        this.states = [...this.statesCA, ...this.statesUS];

        if (this.holidayEvent) {
            this.newHolidayName = this.holidayEvent.title;
            this.pickedDate = new Date(this.holidayEvent.start);
            this.holidayObject.id = this.holidayEvent.objectId;
            this.holidayObject.date = TimeUtility.toBranchLocalDateTime(this.pickedDate, null, false);
            this.dialogTitle = this._translateService.instant('admin.kpiSetting.editHolidayDialog.updateTitle');
            this.confirmButton = this._translateService.instant('admin.kpiSetting.editHolidayDialog.updateButton');
            this.updateDialog = true;

            for (const state of this.holidayEvent.states) {
                if (this.states.includes(state)) {
                    this.holidayStates[state] = true;
                }
            }
        }
        else {
            this.dialogTitle = this._translateService.instant('admin.kpiSetting.editHolidayDialog.addTitle');
            this.confirmButton = this._translateService.instant('admin.kpiSetting.editHolidayDialog.addButton');
            this.updateDialog = false;
        }

        this.closeString = this._translateService.instant('shared.close');
    }

    public addHoliday(): void {
        if (!this.newHolidayName) {
            const message = this._translateService.instant('admin.kpiSetting.editHolidayDialog.missingName');
            this._snackbar.open(message, this.closeString);
            return;
        }

        if (!this.holidayObject.date) {
            const message = this._translateService.instant('admin.kpiSetting.editHolidayDialog.missingDate');
            this._snackbar.open(message, this.closeString);
            return;
        }
        //create the holiday object
        this.holidayObject.name = this.newHolidayName;
        //add states
        const states = Object.keys(this.holidayStates);
        this.holidayObject.states = states.filter(_ => this.holidayStates[_]);
        if (!this.holidayObject.states.length) {
            const message = this._translateService.instant('admin.kpiSetting.editHolidayDialog.missingState');
            this._snackbar.open(message, this.closeString);
            return;
        }

        this._dialog.close({ data: this.holidayObject, action: 'add' });
    }

    public delete(): void {
        this._dialog.close({ data: this.holidayObject, action: 'delete' });
    }

    public selectAllCanada(): void {
        for (const state of this.states) {
            if (this.searchInCanada(state)) {
                this.holidayStates[state] = this.allCanada;
            }
        }
    }

    public selectAllUS(): void {
        for (const state of this.states) {
            if (this.searchInUS(state)) {
                this.holidayStates[state] = this.allUS;
            }
        }
    }

    public stateChange(state: string): void {
        if (this.searchInCanada(state)) {
            this.allCanada = false;
        }
        else {
            this.allUS = false;
        }
    }

    public onClose(): void {
        this._dialog.close();
    }

    public onDatePickerValueChange(event: MatDatepickerInputEvent<Moment>) : void {
        if (!event?.value) {
            return;
        }

        this.holidayObject.date = event.value.format('YYYY-MM-DD');
    }

    private searchInCanada(state: string): boolean {
        return this.statesCA.some(_ => _ === state);
    }

    private searchInUS(state: string): boolean {
        return this.statesUS.some(_ => _ === state);
    }

}
