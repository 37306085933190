import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceFormat } from '../../../core/data-models/invoice-format/invoice-format';
import CodeDesc from '../../../core/data-models/generic/code-description';
import { DevelopHttpService } from '../../../core/services/http/develop-http/develop-http.service';

@Component({
    selector: 'invoice-management',
    styleUrls: ['./invoice-management.component.scss'],
    templateUrl: './invoice-management.component.html'
})
export class InvoiceManagementComponent implements OnInit {
    public invoiceCodesCA: CodeDesc[] = [];
    public invoiceCodesUS: CodeDesc[] = [];
    public invoiceFormat: InvoiceFormat = new InvoiceFormat();

    constructor(
        private developHttpService: DevelopHttpService,
        private snackbar: MatSnackBar,
        public translate: TranslateService
    ) {
    }

    public async ngOnInit(): Promise<void> {
        const invoiceCodesCA = await this.developHttpService.getCAInvoiceCodes();
        if (!invoiceCodesCA) {
            const errorMessage = this.translate.instant('admin.invoiceManagement.failedToLoadInvoiceCodesCA');
            const ok = this.translate.instant('shared.ok');
            this.snackbar.open(errorMessage, ok);
        }
        else {
            this.invoiceCodesCA = invoiceCodesCA;
        }

        const invoiceCodesUS = await this.developHttpService.getUSInvoiceCodes();
        if (!invoiceCodesUS) {
            const errorMessage = this.translate.instant('admin.invoiceManagement.failedToLoadInvoiceCodesUS');
            const ok = this.translate.instant('shared.ok');
            this.snackbar.open(errorMessage, ok);
        }
        else {
            this.invoiceCodesUS = invoiceCodesUS;
        }

        const invoiceFormat = await this.developHttpService.getInvoiceFormats();
        if (!invoiceFormat) {
            const errorMessage = this.translate.instant('admin.invoiceManagement.failedToLoadInvoiceFormats');
            const ok = this.translate.instant('shared.ok');
            this.snackbar.open(errorMessage, ok);
        }
        else {
            this.invoiceFormat = invoiceFormat;
        }
    }

    public async updateInvoiceFormat(): Promise<void> {
        const result = await this.developHttpService.saveInvoiceFormats(this.invoiceFormat);
        const ok = this.translate.instant('shared.ok');

        if (result) {
            const successMessage = this.translate.instant('admin.invoiceManagement.successSavedInvoice');
            this.snackbar.open(successMessage, ok);
        }
        else {
            const failureMessage = this.translate.instant('admin.invoiceManagement.failedToSaveInvoice');
            this.snackbar.open(failureMessage, ok);
        }
    }

    public compareFunction(option: CodeDesc, value: CodeDesc): boolean {
        return option?.codeValue === value?.codeValue;
    }
}
