<div class="labor-options-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh">
<form class="labor-form" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh">
    <span id="total-labor-cost">{{ localize('totalCost') }}: ${{ totalCost.value | number : '0.0-0' }}</span>

    <div class="labor-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="2.5vh">
        <mat-form-field fxFlex="20">
            <mat-label>{{ localize('laborType') }}</mat-label>
            <input type="text" matInput [value]="localize('projectLabor')" readonly />
        </mat-form-field>

        <mat-form-field fxFlex="32">
            <mat-label>{{ localize('hours') }}</mat-label>
            <input type="text" matInput [formControl]="branchLaborCost.get('hours')" [readonly]="readonly"/>
            <mat-error *ngIf="branchLaborCost.get('hours').invalid">* {{ localize('fieldError') }}</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="22">
            <mat-label>{{ localize('hourlyRate') }}</mat-label>
            <input type="text" matInput [formControl]="branchLaborCost.get('hourlyRate')" readonly />
        </mat-form-field>

        <mat-form-field fxFlex="22">
            <mat-label>{{ localize('totalCost') }}</mat-label>
            <input type="text" matInput [value]="branchLaborCost.get('totalCost')?.value | number: '0.0-0'" readonly />
        </mat-form-field>
    </div>

    <div class="labor-row" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="2.5vh">
        <mat-form-field fxFlex="20">
            <mat-label>{{ localize('laborType') }}</mat-label>
            <input type="text" matInput [value]="localize('miscellaneousLabor')" readonly />
        </mat-form-field>

        <mat-form-field fxFlex="32">
            <mat-label>{{ localize('hours') }}</mat-label>
            <input type="text" matInput [formControl]="miscellaneousLaborCost.get('hours')" [readonly]="readonly"/>
            <mat-error *ngIf="miscellaneousLaborCost.get('hours').invalid">* {{ localize('fieldError') }}</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="22">
            <mat-label>{{ localize('hourlyRate') }}</mat-label>
            <input type="text" matInput [formControl]="miscellaneousLaborCost.get('hourlyRate')" readonly />
        </mat-form-field>

        <mat-form-field fxFlex="22">
            <mat-label>{{ localize('totalCost') }}</mat-label>
            <input type="text" matInput [value]="miscellaneousLaborCost.get('totalCost')?.value | number: '0.0-0'" readonly />
        </mat-form-field>
    </div>
</form>
</div>
