import { Component, ChangeDetectionStrategy, Inject, OnInit, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, map, filter } from 'rxjs/operators';

import Customer from '../../../../../app/core/data-models/customer';
import { ServiceRatesGroupSummaryDto } from '../../../../core/data-models/dtos/service-rates-group-summary-dto';
import { CustomerCreationHttpService } from '../../../../../app/core/services/http/customer-creation-http/customer-creation-http.service';

@Component({
    selector: 'app-service-rates-group-editor',
    styleUrls: ['./service-rates-group-editor.component.scss'],
    templateUrl: './service-rates-group-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRatesGroupEditorComponent implements OnInit {
    @ViewChild('customerSearchBox') private _customerSearchBox: ElementRef;
    public form: UntypedFormGroup;
    public customerSearchResult: Customer[] = [];
    public search = new EventEmitter<string>();
    // eslint-disable-next-line max-params
    constructor(@Inject(MAT_DIALOG_DATA) public group: ServiceRatesGroupSummaryDto,
                public translate: TranslateService,
                private _customerCreationHttpService: CustomerCreationHttpService,
                private _formBuilder: UntypedFormBuilder,
                private _dialogRef: MatDialogRef<ServiceRatesGroupEditorComponent, ServiceRatesGroupSummaryDto>,
                private _changeDetectorRef: ChangeDetectorRef) { }

    get iconType(): string {
        return this.group ? 'edit' : 'create';
    }

    get title(): string {
        const key = this.group ? 'editModeTitle' : 'creationModeTitle';

        return this.translate.instant(`flynnBudgetTool.ratesGroupEditor.${key}`);
    }

    public ngOnInit(): void {
        this.form = this._formBuilder.group({
            name: [this.group?.name ?? '', [Validators.required, Validators.pattern(/\w+/)]],
            customerIds: [this.group?.customerIds.slice() ?? [], [Validators.required]]
        });

        this.registerCustomerSearch();
    }

    public onCustomerIdRemove(index: number): void {
        const control = this.form.get('customerIds');
        control.value.splice(index, 1);
        control.updateValueAndValidity();
    }

    public onCustomerSelect(customerId: string): void {
        const control = this.form.get('customerIds');

        if (!control.value.includes(customerId)) {
            control.value.push(customerId);
            control.updateValueAndValidity();
        }

        if (this._customerSearchBox?.nativeElement) {
            this._customerSearchBox.nativeElement.value = '';
        }
    }

    public onClose(emit = false): void {
        if (!emit || this.form.valid) {
            this._dialogRef.close(emit ? { ...this.group, ...this.form.getRawValue() } : null);
        }
        else {
            this.form.markAllAsTouched();
        }
    }

    private registerCustomerSearch(): void {
        this.search.pipe(
            debounceTime(750),
            distinctUntilChanged(),
            map(text => (text ?? '').trim()),
            filter(text => text.length >= 3)
        ).subscribe(async text => {
            const isCustomerId = /^(C|U)\d+$/i.test(text);

            if (isCustomerId) {
                const customer = await this._customerCreationHttpService.getCustomerById(text);
                this.customerSearchResult = customer ? [customer] : [];
            }
            else {
                this.customerSearchResult = await this._customerCreationHttpService.searchCustomersByName(text).toPromise();
            }

            this._changeDetectorRef.markForCheck();
        });
    }
}
