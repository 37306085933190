import { Component, ElementRef, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import { PopupService, POPUP_CONTAINER } from '@progress/kendo-angular-popup';
import KpiData from '../../../../core/data-models/kpi-data/kpi-data';
import ProjectKpiDataDto from '../../../../core/data-models/kpi-data/project-kpi-data-dto';
import { KpiSettingService } from '../../../../core/services/http/kpi-setting-http/kpi-setting.service';
import ProjectIdUtility from '../../../../core/services/utility/project-id-utility/project-id-utility.service';

@Component({
    selector: 'kpi-data-editor',
    styleUrls: ['./kpi-data-editor.component.scss'],
    templateUrl: './kpi-data-editor.component.html',
    providers: [{
        provide: POPUP_CONTAINER,
        useFactory: () => {
            return { nativeElement: document.querySelector('.kpi-edit-project-list kendo-grid') } as ElementRef;
        }
    },
    PopupService]
})
export class KpiDataEditorComponent implements OnInit {
    public project: ProjectKpiDataDto;
    public editProjectKpi = '';
    public saving = false;
    public loading = false;
    public disableProjectSafety = false;
    public disableForemanSafety = false;
    public gridData: KpiData[] = [];
    private closeString = 'Close';

    constructor(private _kpiSettingService: KpiSettingService,
        private _snackBar: MatSnackBar,
        private _translate: TranslateService) {}

    public async ngOnInit(): Promise<void> {
        this.closeString = this._translate.instant('shared.close');
        await this.loadKpiData();
    }

    public async onGridChange(args: SelectionEvent): Promise<void> {
        const selected = args.selectedRows;
        if (selected && selected.length) {
            await this.editKpiRating(selected[0].dataItem);
        }
    }

    public updateSafetyCheckBoxes(): void {
        const hasMultipleForemen = this.project.foremanData.length > 1;
        this.disableProjectSafety = hasMultipleForemen;
        this.disableForemanSafety = !hasMultipleForemen;
    }

    public changeSafetyForForeman(): void {
        if (!this.disableProjectSafety) {
            for (const foremanData of this.project.foremanData) {
                foremanData.safety = this.project.safety;
            }
        }
    }

    public changeSafetyForProject(): void {
        if (!this.disableForemanSafety) {
            this.project.safety = this.project.foremanData.every(_ => _.safety);
        }
    }

    public async updateKpiData(): Promise<void> {
        if (!this.project.reason) {
            const message = this._translate.instant('admin.kpiSetting.kpiUpdate.missingUpdateReason');
            this._snackBar.open(message, this.closeString);
            return;
        }

        this.saving = true;

        const result = await this._kpiSettingService.updateKpiData(this.project);
        if (!result) {
            const message = this._translate.instant('admin.kpiSetting.kpiUpdate.failedToUpdate');
            this._snackBar.open(message, this.closeString);
            this.saving = false;
            return;
        }

        const message = this._translate.instant('admin.kpiSetting.kpiUpdate.updateSuccess', { projectId: this.editProjectKpi });
        this._snackBar.open(message, this.closeString);
        this.editProjectKpi = '';
        this.project = null;
        this.saving = false;
        await this.loadKpiData();
    }

    private async editKpiRating(item: KpiData): Promise<void> {
        this.loading = true;
        this.editProjectKpi = ProjectIdUtility.toDisplayFormat(item.projectID);

        this.project = {
            contactInfo: Boolean(item.contactInfo),
            findings: Boolean(item.findings),
            recommendations: Boolean(item.recommendations),
            photos: Boolean(item.photos),
            safety: Boolean(item.safety),
            reason: '',
            projectId: item.projectID
        } as ProjectKpiDataDto;

        if (!item.foremanScore || item.foremanScore.length === 0) {
            const data = await this._kpiSettingService.getMissingForeman(item.projectID);
            this.project.foremanData = data;
        }
        else {
            this.project.foremanData = item.foremanScore.map(_ => ({
                contactInfo: Boolean(_.contactInfo),
                findings: Boolean(_.findings),
                recommendations: Boolean(_.recommendations),
                photos: Boolean(_.photos),
                safety: Boolean(_.safety),
                foreman: _.foreman.preferredName,
                foremanId: _.foreman.employeeId
            }));
        }

        this.updateSafetyCheckBoxes();
        this.loading = false;
    }

    private async loadKpiData(): Promise<void> {
        this.gridData = await this._kpiSettingService.getKpiDataFromPastMonths(3);
    }
}
