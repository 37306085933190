import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import LanguageUtility from '../../../core/services/utility/language-utility/language-utility.service';
import FollowUpQuotePlaceholderContainer from '../../../core/data-models/quote-general-info/follow-up-quote-placeholder-container';
import { QuoteEditorConstants } from '../../../core/data-models/quote-general-info/quote-editor-constants';

@Injectable({
    providedIn: 'root'
})
export class QuoteEditorService {
    constructor(private _currencyPipe: CurrencyPipe) { }

    public replacePlaceholders(content: string, amount: number, taxRate: number, removeEmptySpan: boolean): string {
        let replaced = content;
        const total = amount * (taxRate / 100 + 1);
        const quoteAmount = this._currencyPipe.transform(amount) ?? '0.00';
        const quoteTax = this._currencyPipe.transform(amount * taxRate / 100) ?? '0.00';
        const quoteTotal = this._currencyPipe.transform(total) ?? '0.00';

        if (removeEmptySpan) {
            replaced = this.removeEmptySpans(replaced);
        }

        replaced = this.replacePlaceholder(replaced, QuoteEditorConstants.QuoteAmountIdentifier, quoteAmount);
        replaced = this.replacePlaceholder(replaced, QuoteEditorConstants.QuoteTaxIdentifier, quoteTax);
        replaced = this.replacePlaceholder(replaced, QuoteEditorConstants.QuoteTotalIdentifier, quoteTotal);
        replaced = this.replacePlaceholder(replaced, QuoteEditorConstants.QuotePhraseIdentifier, LanguageUtility.currencyToEnglish(amount));
        replaced = this.replacePlaceholder(replaced, QuoteEditorConstants.QuoteTotalPhraseIdentifier, LanguageUtility.currencyToEnglish(total));

        return replaced;
    }

    public replaceEditablePlaceholders(content: string, followUpPlaceholders: FollowUpQuotePlaceholderContainer, removeEmptySpan: boolean): string {
        let replaced = content;

        if (removeEmptySpan) {
            replaced = this.removeEmptySpans(replaced);
        }

        replaced = this.replaceEditablePlaceholder(replaced, QuoteEditorConstants.QuoteRecommendationIdentifier, followUpPlaceholders?.recommendationPlaceholder?.recommendation ?? '');

        return replaced;
    }

    private replacePlaceholder(content: string, identifier: string, toReplace: string): string {
        const regex = new RegExp(`(class=['"]${identifier}['"][^>]*>)[^<]*(</span>)([&nbsp;]*)`, 'g');

        return content.replace(regex, ` contenteditable="false" $1${toReplace.replace(/\$/g, '$$$$')}$2&nbsp;`);
    }

    private replaceEditablePlaceholder(content: string, identifier: string, toReplace: string): string {
        let replaced = content;

        // Find any span with the identifier we specify. We assume the class is the last attribute for the span.
        // [^<]* means match as many characters as we need to, until we see a < character (i.e. before end of span tag)
        // (?:[^\\"]|\\.)*" means to match everything that is not \ or "  or \. where . is any character as many times as possible between style="<expr>".
        // Then, ensure the class is what we expected via the identifier.
        // Then, parse until the end of the span tag.
        // Then, parse the span with id handle followed by a 13 digit timestamp.
        const regex = new RegExp(`<span contenteditable="false" style="(?:[^\\\\"]|\\\\.)*" class="${identifier}">[^<]*</span>`, 'g');

        replaced = replaced.replace(regex, toReplace);

        return replaced;
    }

    private removeEmptySpans(content: string): string {
        const regex = /<span[^>]*>[&nbsp;]*<\/span>/g;

        return content.replace(regex, '');
    }
}
