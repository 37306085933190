<div fxLayout="column" fxLayoutGap="15px" class="main-budget-step-content">
    <ng-container *ngIf="config.usingWebBudget; else legacyBudget">
        <p>{{ 'dispatch.budgetStep.webBudgetEnabledTitle' | translate }}</p>
        <p>{{ 'dispatch.budgetStep.webBudgetEnabledDescription' | translate }}</p>
    </ng-container>

    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="15px" fxFlex fxFlexAlign="end">
        <button mat-stroked-button (click)="stepClicked.emit('previous')" color="primary">
            {{ "dispatch.sharedStepLabels.previousButton" | translate}}
        </button>
        <button mat-stroked-button (click)="setCompleteAndMove()" color="primary">
            {{ "dispatch.sharedStepLabels.nextButton" | translate}}
        </button>
    </div>
</div>

<ng-template #legacyBudget>
    <div fxLayout="row" *ngIf="config.awardingQuoteId">
        <mat-checkbox [(ngModel)]="config.useQuoteBudget" (change)="onChangeQuoteBudget($event)">
            {{ "dispatch.budgetStep.checkBox" | translate}}
        </mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start start" *ngIf="!config.useQuoteBudget">
        <app-budget-upload
            [customerId]="config.customerId"
            [layout]="'row'"
            (budgetFileSelected)="addBudgetFile($event)" fxFlex>
        </app-budget-upload>
    </div>
    <div fxLayout="row" *ngIf="handOffMeetingRequired" class="handoff-meeting-warning">
        <label>
            {{ "dispatch.budgetStep.label" | translate}}
        </label>
    </div>
    <div fxLayout="row" fxFlex>
        <app-xlsx-file-preview fxFill [file]="previewFile"></app-xlsx-file-preview>
    </div>
</ng-template>
