<div fxLayout="column" fxFill fxLayoutGap="7px">
    <template-content-card *ngIf="template"
        class="project-conclusion-summary-container"
        [icon]="{ isSvg: false, name: 'fact_check' }"
        [template]="template" fxFlex>
    </template-content-card>

    <leak-report-card *ngIf="useInternalFeatures && isLeakReport"
        [projectId]="projectId"
        [jobId]="jobId"
        [isSummary]="true">
    </leak-report-card>
</div>

