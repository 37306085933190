import TimeInfo from '../time-info';
import UserProfile from '../user-profile';

import CommentEditHistory from './comment-edit-history';

export default class UserComment {
    public id: string;
    public content = '';
    public creator: UserProfile;
    public timeInfo: TimeInfo;
    public histories: CommentEditHistory[] = [];

    constructor(id: string, content = '') {
        this.id = id;
        this.content = content;
    }
}
