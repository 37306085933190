import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import JsonUtility from '../../utility/json-utility.service';
import HolidayEvent from '../../../data-models/holiday-event';
import HolidayEventEntity from '../../../data-models/kpi-data/holiday-event-entity';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HolidayHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/kpisetting/admin`;

    constructor(private _http: HttpClient) { }

    public async getHolidayEvents(): Promise<HolidayEvent[]> {
        try {
            const endpoint = `${this._api}/holiday`;
            const data = await this._http.get<HolidayEvent[]>(endpoint).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<HolidayEvent>();
        }
    }

    public async addHoliday(holiday: HolidayEventEntity): Promise<HolidayEvent> {
        try {
            const endpoint = `${this._api}/holiday`;
            const data = await this._http.post<HolidayEvent>(endpoint, holiday).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async deleteHoliday(id: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/holiday/${id}`;
            await this._http.delete(endpoint).toPromise();
            return true;
        }
        catch {
            return false;
        }
    }
}
