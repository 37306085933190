<div class="customer-details-card-container" fxLayout="column" fxLayoutAlign="space-between">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <mat-icon>supervisor_account</mat-icon>
        <span>{{ 'shared.customerDetailsCard.title' | translate }}</span>
    </div>

    <div *ngIf="isLoading" fxLayout="column" fxLayoutAlign="center center" fxFill>
        <dots-loader></dots-loader>
    </div>

    <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="extensionData">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px">
                <div class="customer-class"
                    [ngClass]="{ 'strategic-label': isStrategicCustomer }"
                    fxLayout="row"
                    fxLayoutAlign="center center">

                    <span>{{ customer.customerClass }}</span>
                </div>

                <span>{{ customer.customerName }}</span>
            </div>

            <div *ngFor="let row of gridConfig.rows"
                class="grid-row"
                [ngStyle]="{ height: row.height ? row.height + '%' : null }"
                fxLayout="row">

                <grid-display-item *ngFor="let item of row.items"
                    [ngStyle]="{ width: item.width + '%' }"
                    [title]="item.title"
                    [content]="gridConfig.data[item.key]"
                    [useTextarea]="item.useTextarea">
                </grid-display-item>
            </div>
        </ng-container>

        <div *ngIf="!extensionData && !isTemporaryCustomer"
            class="customer-not-found-message"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="6px">

            <span>{{ 'shared.customerDetailsCard.customerNotFoundTitle' | translate }}</span>
            <span>{{ 'shared.customerDetailsCard.customerNotFoundMessage' | translate }}</span>
        </div>

        <div *ngIf="!extensionData && isTemporaryCustomer"
            class="temporary-customer-message"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="6px">

            <span>{{ 'shared.customerDetailsCard.temporaryCustomerTitle' | translate }}</span>
            <span>{{ 'shared.customerDetailsCard.temporaryCustomerMessage' | translate }}</span>
        </div>
    </ng-container>

    <button class="close-button" mat-icon-button (click)="close.emit()">
        <mat-icon>clear</mat-icon>
    </button>
</div>
