import { QuoteEditorMode } from '../enums/quote-editor-mode.enum';

import ProjectGeneralInfo from './project-general-info/project-general-info';
import QuoteSaveContext from './quote-save-context';

export default class QuoteEditorOption {
    public mode = QuoteEditorMode.Create;
    public originProject?: ProjectGeneralInfo;
    public quoteId?: string;
    public saveContext?: QuoteSaveContext;

    public static getCreateOption(originProject: ProjectGeneralInfo = null): QuoteEditorOption {
        return { mode: QuoteEditorMode.Create, originProject } as QuoteEditorOption;
    }

    public static getEditOption(quoteId: string): QuoteEditorOption {
        return { mode: QuoteEditorMode.Edit, quoteId } as QuoteEditorOption;
    }

    public static getCopyOption(saveContext: QuoteSaveContext): QuoteEditorOption {
        return { mode: QuoteEditorMode.Copy, saveContext } as QuoteEditorOption;
    }
}
