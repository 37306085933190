<swiper
[slidesPerView]="'auto'"
[spaceBetween]="10"
[navigation]="true"
[watchOverflow]="true"
>
<ng-container *ngFor="let photo of carouselImages; index as i">
    <ng-template class="carousel-image-container" swiperSlide>
        <mat-card (click)="imageSelected.emit(images[i])" class="carousel-image-container">
            <mat-card-footer class="carousel-image-title">{{photo.title}}</mat-card-footer>
            <img mat-card-image class="carousel-image" src="{{ photo.image }}" alt="{{photo.title}}" />
        </mat-card>
    </ng-template>
</ng-container>
</swiper>