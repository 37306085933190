import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import CustomerNoteType from '../../../core/data-models/customer-note-type';
import CustomerContactType from '../../../core/data-models/customer-contact-type';
import { CustomerHttpService } from '../../../core/services/http/customer-http/customer-http.service';

@Component({
    selector: 'customer-settings',
    styleUrls: ['./customer-settings.component.scss'],
    templateUrl: './customer-settings.component.html'
})
export class CustomerSettingsComponent implements OnInit {
    public noteTypes: CustomerNoteType[] = [];
    public selectedNoteTypeId: string;
    public noteTypeLabel = '';
    public contactTypes: CustomerContactType[] = [];
    public selectedContactTypeId: string;
    public contactTypeLabel = '';

    constructor(private _translate: TranslateService,
                private _customerHttp: CustomerHttpService,
                private _snackBar: MatSnackBar) { }

    public ngOnInit(): void {
        this.loadNoteTypes();
        this.loadContactTypes();
    }

    private async loadNoteTypes(): Promise<void> {
        this.noteTypes = await this._customerHttp.getCustomerNoteTypes();
        this.selectedNoteTypeId = this.noteTypes[0].id;
    }

    public async deleteNoteType(id: string): Promise<void> {
        if (await this._customerHttp.deleteCustomerNoteType(id)) {
            await this.loadNoteTypes();
        }
        else {
            const message = this._translate.instant('admin.customerSettings.failedToDeleteNoteType');
            this._snackBar.open(message, this._translate.instant('shared.ok'));
        }
    }

    public async saveNoteType(noteType: string): Promise<void> {
        if (!noteType) {
            const message = this._translate.instant('admin.customerSettings.noteTypeMustNotBeEmpty');
            this._snackBar.open(message, this._translate.instant('shared.ok'));

            return;
        }

        if (await this._customerHttp.addCustomerNoteType(noteType)) {
            await this.loadNoteTypes();
        }
        else {
            const message = this._translate.instant('admin.customerSettings.failedToAddNoteType');
            this._snackBar.open(message, this._translate.instant('shared.ok'));
        }
    }

    private async loadContactTypes(): Promise<void> {
        this.contactTypes = await this._customerHttp.getCustomerContactTypes();
        this.selectedContactTypeId = this.contactTypes[0].id;
    }

    public async deleteContactType(contactType: string): Promise<void> {
        if (await this._customerHttp.deleteCustomerContactType(contactType)) {
            await this.loadContactTypes();
        }
        else {
            const message = this._translate.instant('admin.customerSettings.failedToDeleteContactType');
            this._snackBar.open(message, this._translate.instant('shared.ok'));
        }
    }

    public async saveContactType(contactType: string): Promise<void> {
        if (!contactType) {
            const message = this._translate.instant('admin.customerSettings.contactTypeMustNotBeEmpty');
            this._snackBar.open(message, this._translate.instant('shared.ok'));

            return;
        }

        if (await this._customerHttp.addCustomerContactType(contactType)) {
            await this.loadContactTypes();
        }
        else {
            const message = this._translate.instant('admin.customerSettings.failedToAddContactType');
            this._snackBar.open(message, this._translate.instant('shared.ok'));
        }
    }
}
