/* eslint-disable max-lines */
/* eslint-disable func-style */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any, max-params */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartData, ChartOptions } from 'chart.js';
import * as moment from 'moment-timezone';

interface CustomPluginOptions {
    customArrowForWindDirection?: {
        enabled: boolean;
    };
}

@Injectable({
    providedIn: 'root'
})
export class ChartJsService {
    static translateHolderService: TranslateService;
    colorLabel =
        'linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8))';

    constructor(public translate: TranslateService) {
        ChartJsService.translateHolderService = translate;
    }

    static translate(key: string): string {
        return ChartJsService.translateHolderService.instant(key);
    }

    getStartAndEndTime(timeZoneId: string) {
        const startTime = moment
            .tz(timeZoneId)
            .subtract(3, 'hours')
            .toISOString();
        const endTime = moment.tz(timeZoneId).add(20, 'hours').toISOString();
        return { startTime, endTime };
    }

    time(str: string) {
        return moment(str);
    }

    getLabels(startTime: moment.Moment, endTime: moment.Moment) {
        const labels = [];
        //eslint-disable-next-line prefer-const
        let current = startTime.clone();
        while (current <= endTime) {
            labels.push(moment(current).format('YYYY-MM-DD HH'));
            current.add(1, 'hours');
        }
        return labels;
    }

    getChartData(
        labels: any[],
        dataSet: any,
        gradients: any[],
        selectedIndex: {
            index: number;
            label: string;
        }
    ) {
        const chartData: ChartData<'line'> = {
            labels,
            datasets: this.getdataSet(gradients, dataSet, selectedIndex)
        };
        return chartData;
    }

    getdataSet(gradients: any[], dataSet: any, selectedIndex: any) {
        const datasets: any[] = [
            {
                label: this.translate.instant('weatherChart.weatherStats.temperature'),
                data: dataSet.temperature,
                fill: true,
                borderColor: 'rgba(255, 0, 0, 0.5)',
                backgroundColor: gradients[0],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 5,
                tension: 0.4,
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
                hidden: !(selectedIndex.type === this.translate.instant('weatherChart.weatherStats.temperature'))
            },
            {
                label: this.translate.instant('weatherChart.weatherStats.windSpeed'),
                data: dataSet.windSpeed,
                fill: true,
                borderColor: gradients[4],
                backgroundColor: gradients[4],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 5,
                tension: 0.4,
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
                hidden: !(selectedIndex.type === this.translate.instant('weatherChart.weatherStats.windSpeed'))
            },
            {
                label: this.translate.instant('weatherChart.weatherStats.windGust'),
                data: dataSet.windGust,
                fill: true,
                borderColor: gradients[5],
                backgroundColor: gradients[5],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 5,
                tension: 0.4,
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
                hidden: !(selectedIndex.type === this.translate.instant('weatherChart.weatherStats.windGust'))
            },
            {
                label: this.translate.instant('weatherChart.weatherStats.windDirection'),
                data: dataSet.windDirection,
                fill: true,
                backgroundColor: gradients[2],
                tension: 0.4,
                pointRadius: 2,
                pointHoverRadius: 5,
                borderWidth: 0,
                cubicInterpolationMode: 'monotone',
                hidden: !(selectedIndex.type === this.translate.instant('weatherChart.weatherStats.windDirection'))
            },
            {
                label: this.translate.instant('weatherChart.weatherStats.visibility'),
                data: dataSet.visibility,
                fill: true,
                borderColor: gradients[3],
                backgroundColor: gradients[3],
                tension: 0.4,
                pointRadius: 2,
                pointHoverRadius: 5,
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
                hidden: !(selectedIndex.type === this.translate.instant('weatherChart.weatherStats.visibility'))
            },
            {
                label: this.translate.instant('weatherChart.weatherStats.precipitation'),
                data: dataSet.precipitation,
                fill: true,
                borderColor: gradients[1],
                backgroundColor: gradients[1],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 5,
                tension: 0.4,
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
                hidden: !(selectedIndex.type === this.translate.instant('weatherChart.weatherStats.precipitation'))
            },
            {
                label: this.translate.instant('weatherChart.weatherStats.cloudCover'),
                data: dataSet.cloudCover,
                fill: true,
                borderColor: gradients[6],
                backgroundColor: gradients[6],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 5,
                tension: 0.4,
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
                hidden: !(selectedIndex.type === this.translate.instant('weatherChart.weatherStats.cloudCover'))
            },
            {
                label: this.translate.instant('weatherChart.weatherStats.humidity'),
                data: dataSet.humidity,
                fill: true,
                borderColor: gradients[7],
                backgroundColor: gradients[7],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 5,
                tension: 1,
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
                hidden: !(selectedIndex.type === this.translate.instant('weatherChart.weatherStats.humidity'))
            }
        ];
        return datasets;
    }

    getGradients(ctx: CanvasRenderingContext2D) {
        const gradients = [
            ctx.createLinearGradient(0, 0, 0, 400),
            ctx.createLinearGradient(0, 0, 0, 400),
            ctx.createLinearGradient(0, 0, 0, 400),
            ctx.createLinearGradient(0, 0, 0, 400),
            ctx.createLinearGradient(0, 0, 0, 400),
            ctx.createLinearGradient(0, 0, 0, 400),
            ctx.createLinearGradient(0, 0, 0, 400),
            ctx.createLinearGradient(0, 0, 0, 400)
        ];

        this.applyGradients(gradients);
        return gradients;
    }

    applyGradients(gradients: any[]) {
        gradients[0].addColorStop(0, 'rgba(255, 0, 0, 1)');
        gradients[0].addColorStop(0.3, 'rgba(255, 0, 0, 0.5)');
        gradients[0].addColorStop(0.6, 'rgba(255, 0, 0, 0)');
        gradients[0].addColorStop(0.1, 'rgba(255, 0, 0, 0.5)');
        gradients[0].addColorStop(1, 'rgba(200, 0, 0, 1)');

        gradients[1].addColorStop(0, 'rgba(67, 97, 238, 0.5)');
        gradients[1].addColorStop(0.7, 'rgba(67, 97, 238, 0.9)');
        gradients[1].addColorStop(0.4, 'rgba(67, 97, 238, 0.8)');
        gradients[1].addColorStop(0.6, 'rgba(67, 97, 238, 0.6)');
        gradients[1].addColorStop(1, 'rgba(67, 97, 238, 0)');

        gradients[2].addColorStop(0, 'rgba(204, 153, 0, 0.1)');
        gradients[2].addColorStop(0.7, 'rgba(204, 153, 0, 0.9)');
        gradients[2].addColorStop(0.4, 'rgba(204, 153, 128, 0.8)');
        gradients[2].addColorStop(0.6, 'rgba(204, 153, 180, 0.6)');
        gradients[2].addColorStop(1, 'rgba(204, 153, 255, 0)');

        gradients[3].addColorStop(0, 'rgba(0, 100, 0, 1)');
        gradients[3].addColorStop(0.7, 'rgba(0, 100, 0, 0.9)');
        gradients[3].addColorStop(0.4, 'rgba(0, 100, 0, 0.8)');
        gradients[3].addColorStop(0.6, 'rgba(0, 100, 0, 0.6)');
        gradients[3].addColorStop(1, 'rgba(0, 100, 0, 0)');

        gradients[4].addColorStop(0, 'rgba(0, 0, 100, 0.5)');
        gradients[4].addColorStop(0.7, 'rgba(0, 0, 100, 0.9)');
        gradients[4].addColorStop(0.4, 'rgba(0, 0, 100, 0.8)');
        gradients[4].addColorStop(0.6, 'rgba(0, 0, 100, 0.6)');
        gradients[4].addColorStop(1, 'rgba(0, 0, 100, 0)');

        gradients[5].addColorStop(0, 'rgba(153, 102, 0, 0.5)');
        gradients[5].addColorStop(0.7, 'rgba(153, 102, 0, 0.9)');
        gradients[5].addColorStop(0.4, 'rgba(153, 102, 0, 0.8)');
        gradients[5].addColorStop(0.6, 'rgba(153, 102, 0, 0.6)');
        gradients[5].addColorStop(1, 'rgba(153, 102, 0, 0)');

        gradients[6].addColorStop(0, 'rgba(70, 70, 70, 1)');
        gradients[6].addColorStop(0.7, 'rgba(70, 70, 70, 0.9)');
        gradients[6].addColorStop(0.4, 'rgba(70, 70, 70, 0.8)');
        gradients[6].addColorStop(0.6, 'rgba(70, 70, 70, 0.6)');
        gradients[6].addColorStop(1, 'rgba(70, 70, 70, 0)');

        gradients[7].addColorStop(0, 'rgba(181, 126, 113, 1)');
        gradients[7].addColorStop(0.7, 'rgba(181, 126, 113, 0.9)');
        gradients[7].addColorStop(0.4, 'rgba(181, 126, 113, 0.8)');
        gradients[7].addColorStop(0.6, 'rgba(181, 126, 113, 0.6)');
        gradients[7].addColorStop(1, 'rgba(181, 126, 113, 0)');
    }

    getChartOptions(
        startTime: moment.Moment,
        endTime: moment.Moment,
        timeZoneId: any,
        weatherStats: { value: string; label: string; type: string }[],
        dataSet: any,
        dataAvailable: boolean,
        isArrowVisible: boolean
    ) {
        const chartOptions = {
            scales: {
                x: {
                    grid: {
                        display: false,
                        drawOnChartArea: true,
                        color: 'rgba(0, 0, 0, 0.8)',
                        lineWidth: 1.5
                    },
                    type: 'time',
                    min: moment(startTime)
                        .tz(timeZoneId)
                        .toDate()
                        .toDateString(),
                    max: moment(endTime).tz(timeZoneId).toDate().toDateString(),
                    time: {
                        parser: 'YYYY-MM-DD HH',
                        tooltipFormat: 'll HH:mm',
                        unit: 'hour',
                        displayFormats: {
                            hour: 'HH'
                        }
                    },
                    ticks: {
                        stepSize: 1,
                        callback: (value, index, values) => {
                            const now = moment.tz(timeZoneId);
                            const tickTime = moment(values[index].value).tz(
                                timeZoneId
                            );
                            const diff = tickTime.diff(now, 'hours');
                            let callBackTime = '--';
                            if (diff === 0) {
                                weatherStats.forEach(obj => {
                                    obj.value = this.getValueByLabel(obj.type, dataSet, index);
                                });
                                callBackTime = tickTime.format('h:mmA');
                            }
                            else {
                                let formattedDiff;
                                if (diff > 0) {
                                    formattedDiff = `+${diff}h`;
                                }
                                else {
                                    formattedDiff = `${diff}h`;
                                }
                                callBackTime = formattedDiff;
                            }
                            return callBackTime;
                        },
                        color: context => {
                            if (context.tick.label === 'Now') {
                                return 'white';
                            }
                            return '#666';
                        },
                        font: context => {
                            return context.tick.label === 'Now' ? { weight: 'bold' } : '';
                        }
                    }
                },
                y: {
                    beginAtZero: false,
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: false
                    }
                }
            },
            elements: {
                line: {
                    borderWidth: 1
                },
                point: {
                    radius: 0
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                intersect: false,
                mode: 'index'
            },
            plugins: {
                datalabels: {
                    color: this.colorLabel,
                    anchor: 'end',
                    align: 'top',
                    formatter: (value, context) => {
                        const val = context.dataset.label === this.translate.instant('weatherChart.weatherStats.windDirection');
                        return val ? '' : context.dataset.data[context.dataIndex];
                    }
                },
                title: {
                    display: dataAvailable,
                    text: this.translate.instant('weatherChart.noDataMessage'),
                    font: {
                        size: 18
                    },
                    color: '#d9534f'
                },
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        title: tooltipItems => {
                            return moment(tooltipItems[0].parsed.x)
                                .tz(timeZoneId)
                                .format('MMMM D, YYYY, H:mm A');
                        },
                        label: context => {
                            let val;
                            if (context.dataset.label === this.translate.instant('weatherChart.weatherStats.windDirection')) {
                                const degreeValue = context.raw;
                                const compassDirection = this.degreesToCompass(degreeValue);
                                val = `${this.translate.instant('weatherChart.weatherStats.windDirection')}:  ${context.raw} ${compassDirection}`;
                            }
                            else {
                                val = `${context.dataset.label}: ${context.raw}${this.getMetric(context.dataset.label)}`;
                            }
                            return val;
                        }
                    },
                    position: 'average'
                },
                annotation: {
                    annotations: {
                        nowLine: {
                            type: 'line',
                            xMin: moment.tz(timeZoneId).valueOf(),
                            xMax: moment.tz(timeZoneId).valueOf(),
                            borderColor: 'black',
                            borderWidth: 2,
                            display: !dataAvailable,
                            label: {
                                display: !dataAvailable,
                                backgroundColor: 'black',
                                borderColor: 'black',
                                borderWidth: 2,
                                content: 'Now',
                                position: 'end',
                                yAdjust: -6
                            }
                        }
                    }
                },
                customArrowForWindDirection: {
                    enabled: isArrowVisible
                },
                nowLinePlugin: { enabled: true }
            },
            layout: {
                padding: {
                    top: 40
                }
            }
        } as ChartOptions & { plugins: CustomPluginOptions };
        return chartOptions;
    }

    degreesToCompass(degrees) {
        const compassSectors = [
            'N', 'NNE', 'NE', 'ENE',
            'E', 'ESE', 'SE', 'SSE',
            'S', 'SSW', 'SW', 'WSW',
            'W', 'WNW', 'NW', 'NNW'
        ];
        const index = Math.floor((degrees + 11.25) / 22.5);
        return compassSectors[index % 16];
    }

    formatFunctions: { [dkey: string]: (index: number, dataSet) => string } = {
        'temperature': (index, dataSet) => dataSet.temperature[index] ? `${dataSet.temperature[index]}°C` : '--',
        'precipitation': (index, dataSet) => dataSet.precipitation[index] === 0 || dataSet.precipitation[index] ? `${dataSet.precipitation[index]}mm/hr` : '--',
        'windspeed': (index, dataSet) => dataSet.windSpeed[index] ? `${dataSet.windSpeed[index]}m/s` : '--',
        'windgust': (index, dataSet) => dataSet.windGust[index] ? `${dataSet.windGust[index]}m/s` : '--',
        'winddirection': (index, dataSet) => dataSet.windDirection[index] ? `${this.degreesToCompass(dataSet.windDirection[index])}` : '--',
        'humidity': (index, dataSet) => dataSet.humidity[index] ? `${dataSet.humidity[index]}%` : '--',
        'visibility': (index, dataSet) => dataSet.visibility[index] ? `${dataSet.visibility[index]}km` : '--',
        'cloudcover': (index, dataSet) => dataSet.cloudCover[index] === 0 || dataSet.cloudCover[index] ? `${dataSet.cloudCover[index]}%` : '--'
    };

    getValueByLabel(label: string, dataSet, index: number): string {
        const normalizedLabel = label.replace(/\s/g, '').toLocaleLowerCase();
        const formatFunction = this.formatFunctions[normalizedLabel];
        return formatFunction ? formatFunction(index, dataSet) : '--';
    }

    getMetric(label: string) {
        let val = '';
        switch (label) {
            case this.translate.instant(
                'weatherChart.weatherStats.windDirection'
            ):
                break;
            case this.translate.instant(
                'weatherChart.weatherStats.precipitation'
            ):
                val = 'mm/hr';
                break;
            case this.translate.instant(
                'weatherChart.weatherStats.temperature'
            ):
                val = '°C';
                break;
            case this.translate.instant('weatherChart.weatherStats.visibility'):
                val = 'km';
                break;
            case this.translate.instant('weatherChart.weatherStats.windSpeed'):
            case this.translate.instant('weatherChart.weatherStats.windGust'):
                val = 'm/s';
                break;
            case this.translate.instant('weatherChart.weatherStats.humidity'):
            case this.translate.instant('weatherChart.weatherStats.cloudCover'):
                val = '%';
        }
        return val;
    }
}