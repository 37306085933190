<div class="on-call-remove-dialog-container" fxLayout="column" fxLayoutAlign="start stretch" fxFill>
    <div class="title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="25">
        <div fxLayoutAlign="center center">
            <mat-icon>delete</mat-icon>
        </div>
        <div>{{ 'onCall.remove.removeOnCallCrew' | translate }}</div>
        <div fxFlex></div>
        <button class="close-button" mat-mini-fab mat-dialog-close>
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <div class="content" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
        <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="40px" fxFlex>
            <span>{{ 'onCall.remove.areYouSure' | translate }}</span>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <grid-display-item [title]="translate.instant('onCall.remove.onCallTech')" [content]=" data.crew">
                </grid-display-item>
                <grid-display-item [title]="translate.instant('onCall.remove.startDate')" [content]="start | date: 'yyyy-MM-dd'">
                </grid-display-item>
                <grid-display-item [title]="translate.instant('onCall.remove.endDate')" [content]="end | date: 'yyyy-MM-dd'">
                </grid-display-item>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxFlex="25">
            <button class="remove-button" mat-raised-button color="primary" (click)="remove()">{{ 'onCall.remove.remove' | translate }}</button>
        </div>
    </div>
</div>
