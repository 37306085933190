import * as moment from 'moment-timezone';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import ConfirmDialogOption from '../../../../../core/data-models/confirm-dialog-option';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog-component/confirm-dialog.component';

@Component({
    selector: 'grid-datepicker',
    styleUrls: ['./grid-datepicker.component.scss'],
    templateUrl: './grid-datepicker.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridDatepickerComponent {
    @Input() public title = '';
    @Input() public date: string | null = null;
    @Output() public dateUpdate = new EventEmitter<string | null>();

    constructor(private _translate: TranslateService, private _dialog: MatDialog) { }

    get displayDate(): string {
        return this.date?.slice(0, 10) ?? '';
    }

    public async onDateUpdate(date: Date | null): Promise<void> {
        if (date) {
            this.dateUpdate.emit(moment(date).format('YYYY-MM-DD'));

            return;
        }

        const title = this._translate.instant('shared.gridDatepicker.promptDialog.title');
        const message = this._translate.instant('shared.gridDatepicker.promptDialog.message');
        const discardLabel = this._translate.instant('shared.discard');
        const data = new ConfirmDialogOption(title, message, true, discardLabel);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (await dialog.afterClosed().toPromise()) {
            this.dateUpdate.emit('');
        }
    }
}
