import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import JsonUtility from '../../utility/json-utility.service';
import CrewsAndEvents from '../../../data-models/crews-and-events';
import OnCallEvent from '../../../data-models/on-call-events';
import Branch from '../../../data-models/branch';
import Crew from '../../../data-models/crew';
import CrewWorker from '../../../data-models/crew-worker';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CrewHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/crew`;

    constructor(private _http: HttpClient) { }

    public async getOnCallCrewsAndEvents(branch: string): Promise<CrewsAndEvents> {
        try {
            const encodedBranch = branch.replace(/\./g, '-dot-');
            const endpoint = `${this._api}/on-call/crews-events/${encodedBranch}`;
            const data = await this._http.get<CrewsAndEvents>(endpoint).toPromise();
            const result = JsonUtility.toCamelCaseKeys(data);
            return result;
        }
        catch {
            return new CrewsAndEvents();
        }
    }

    public async addOnCallCrew(eventToAdd: OnCallEvent): Promise<boolean> {
        try {
            const endpoint = `${this._api}/on-call`;
            return await this._http.post<boolean>(endpoint, eventToAdd).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getCrewsByBranch(branch: Branch): Promise<Crew[]> {
        try {
            const encodedBranch = branch.name.replace(/\./g, '-dot-');
            const data = await this._http.get<Crew[]>(`${this._api}?branch=${encodedBranch}`).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<Crew>();
        }
    }

    public async removeOnCallCrew(eventId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/on-call/${eventId}`;

            return await this._http.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    // This endpoint only calls SAP.
    public async getCrewWorkersFromSapByCrewId(crewId: number): Promise<CrewWorker[]> {
        try {
            const data = await this._http.get<CrewWorker[]>(`${this._api}/sap/crew/workers/${crewId.toString()}`).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }
}
