import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import DictionaryEntry from '../../core/data-models/dictionary-entry';
import DictionaryEntryLanguage from '../../core/data-models/dictionary-entry-language';
import { DictionaryHttpService } from '../../core/services/http/dictionary-http/dictionary-http.service';

@Component({
    selector: 'dictionary',
    styleUrls: ['./dictionary.component.scss'],
    templateUrl: './dictionary.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DictionaryComponent implements OnInit {
    public entryIndex = 0;
    public languageIndex = 0;
    public entries: DictionaryEntry[] = [];
    public isLoaded = false;

    constructor(private _dictionaryHttp: DictionaryHttpService) { }

    public async ngOnInit(): Promise<void> {
        this.entries = await this._dictionaryHttp.getDictionaryEntries();
        this.isLoaded = true;
    }

    public getLocalizedContent(contents: DictionaryEntryLanguage[]): string {
        if (!contents.length) {
            return '';
        }
        // always return default translation when selected translation is not available
        return contents[this.languageIndex]?.content ?? contents[0].content;
    }
}
