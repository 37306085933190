<div class="project-us-questions-card-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="30px" fxFill>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-icon>help</mat-icon>
        <span>{{ 'shared.usQuestions.questions' | translate }}</span>
    </div>

    <div [fxLayout]="checkBoxesLayout" [fxLayoutAlign]="checkBoxesLayoutAlign" fxLayoutGap="5px">
        <div *ngFor="let item of checkboxes" class="us-project-question-checkbox-div">
            <mat-checkbox class="project-data-row"
                [disabled]="readOnly"
                [(ngModel)]="usEstimateForm[item.model]"
                [matTooltip]="item.toolTip"
                (change)="checkAndSendResult()">
                {{ item.label }}
            </mat-checkbox>
        </div>
    </div>
    <div *ngIf="usEstimateForm.davisBacon || usEstimateForm.prevailingWage">
        <grid-display-item *ngIf="readOnly"
            class="project-data-row"
            [title]="translate.instant('shared.usQuestions.calculationMethod')"
            [content]="usEstimateForm.prevWgCalcMthd.codeValueDesc">
        </grid-display-item>

        <mat-form-field *ngIf="!readOnly">
            <mat-label>{{ 'shared.usQuestions.calculationMethod' | translate }}</mat-label>
            <mat-select [required]="true" [formControl]="calculationMethod" (selectionChange)="updateCalculationMethod($event)" matTooltip="{{ 'shared.usQuestions.calculationMethodTooltip' | translate }}">
                <mat-option *ngFor="let item of wageCalculationMethods" [value]="item.codeValue">
                    {{ item.codeValueDesc }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="calculationMethod.invalid">
                {{ 'shared.usQuestions.requiredField' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
</div>
