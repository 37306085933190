import ForemanRating from './foreman-rating';

export default class ProcessorReview {
    public projectId: string;
    public comment = '';
    public zeroSafetyReason = '';
    public rejectionReason = '';
    public rating = 0;
    public checklist: number[] = [];
    public foremanRatings: ForemanRating[] = [];

    constructor(projectId: string) {
        this.projectId = projectId;
    }
}
