<div class="about-dialog-container" fxLayout="column">
    <div class="logo" fxLayout="row" fxLayoutAlign="center center" fxFlex="50">
        <img *ngIf="logoUrl" [src]="logoUrl" />
    </div>

    <div class="content" fxLayout="column" fxLayoutAlign="space-betwen" fxFlex="50">
        <div class="description" fxLayout="column" fxFlex="87.5">
            <span class="header">{{ 'shared.aboutDialog.aboutHeader' | translate }} {{ siteName }}</span>
            <span class="copyright">{{copyrightInfo}}</span>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1vh" fxFlex="10">
            <button mat-stroked-button color="primary" (click)="showTermsOfService()">
                {{ 'shared.aboutDialog.termsOfService' | translate }}
            </button>

            <button mat-stroked-button color="primary" (click)="showAttributions()">
                {{ 'shared.aboutDialog.attributions' | translate }}
            </button>
        </div>
    </div>
</div>
