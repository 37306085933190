import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Branch from '../../../core/data-models/branch';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { JobReportHttpService } from '../../../core/services/http/job-report-http/job-report-http.service';
import ReportQuery from '../../../core/data-models/data-report/report-query';
import DeletedReportSummary from '../../../core/data-models/data-report/deleted-report-summary';
import { process } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import CodeDescription from '../../../core/data-models/code-description';
import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';

@Component({
    selector: 'app-deleted-job-report',
    styleUrls: ['./deleted-job-report.component.scss'],
    templateUrl: './deleted-job-report.component.html'
})
export class DeletedJobReportComponent implements OnInit {
    @Input() public jobTypes: string[];
    @Input() public branches: Branch[];
    @ViewChild(GridComponent) grid: GridComponent;
    public gridViewData: DeletedReportSummary[] = [];
    public loading: boolean;
    public gridPageSize = 15;
    public query: ReportQuery;
    public allowRestoringProject = false;

    // eslint-disable-next-line
    public constructor(private newRelicUtility: NewRelicUtilityService,
        private jobReportHttpService: JobReportHttpService,
        private userHttpService: UserHttpService,
        public translate: TranslateService,
        private _dialogService: MatDialog,
        private _featureFlagService: FeatureFlagService) {
        this.query = new ReportQuery('', '', '', null, null, '');
        this.allData = this.allData.bind(this);
    }

    public async ngOnInit(): Promise<void> {
        this.allowRestoringProject = this._featureFlagService.featureFlags[FeatureFlags.AllowRestoringProject];
        this.query.branch = (await this.userHttpService.getUserInfo()).branchInfo.code;
    }

    public async loadReport(): Promise<void> {
        this.newRelicUtility.addPageAction('ReportType', 'DeletedJobSummary', 'ReportAccess', this.query);

        this.loading = true;
        this.gridViewData = await this.jobReportHttpService.getDeletedJobSummary(this.query);
        this.gridPageSize = this.grid.pageSize;
        this.grid.pageChange.emit({ skip: 0, take: this.gridPageSize });
        this.loading = false;
    }

    public allData(): ExcelExportData {
        const result: ExcelExportData = {
            data: process(this.gridViewData, {}).data
        };

        return result;
    }

    public async reinstateJob(job: DeletedReportSummary): Promise<void> {
        const title = this.translate.instant('dataReport.deletedReport.reinstateDeletedJob.title');
        const message = this.translate.instant('dataReport.deletedReport.reinstateDeletedJob.htmlMessage');
        const data = new ConfirmDialogOption(title, '', true, 'Yes', 'No', message);
        const dialog = this._dialogService.open(ConfirmDialogComponent, { maxWidth: '400px', data });

        if (await dialog.afterClosed().toPromise()) {
            await this.runAction(this.jobReportHttpService.reinstateDeletedJob(job.projectId));
        }
    }

    private async runAction(action: Promise<CodeDescription>): Promise<void> {
        const ongoing = this.translate.instant('projects.update.ongoing1');
        const success = this.translate.instant('dataReport.deletedReport.success1');
        const error = this.translate.instant('projects.update.error1');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise()) {
            this.loadReport();
        }
    }
}
