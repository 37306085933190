<ng-container *ngIf="readonlyTemplate">
    <div fxLayout="column" fxFill fxLayoutGap="7px">
        <template-content-card *ngIf="!isMultiPage"
            [icon]="icon"
            [template]="editableTemplate ? editableTemplate : readonlyTemplate"
            [allowEdit]="allowEdit"
            [isEditMode]="editableTemplate"
            [isLoading]="isEditStateChanging"
            (editStart)="onEdit($event)"
            (editSave)="onSave($event)"
            (editCancel)="onCancel()"
            fxFlex>
        </template-content-card>

        <template-content-collection-card *ngIf="isMultiPage"
            [icon]="icon"
            [title]="readonlyTemplate.nameAttributes.translatedName"
            [fields]="readonlyTemplate.fields"
            [allowEdit]="allowEdit"
            [isLoading]="isEditStateChanging"
            [editField]="editableTemplate"
            (editStart)="onEdit($event)"
            (editSave)="onSave($event)"
            (editCancel)="onCancel()"
            (pageChanged)="currentPage = $event"
            fxFlex>
        </template-content-collection-card>

        <leak-report-card *ngIf="useInternalFeatures && isLeakReport"
            [projectId]="generalInfo.basicInfo.projectId"
            [jobId]="jobId"
            [leakIndex]="currentPage + 1"
            [isSummary]="false">
        </leak-report-card>
    </div>
</ng-container>
