import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';

@Component({
    selector: 'app-data-migration-management',
    styleUrls: ['./data-migration.component.scss'],
    templateUrl: './data-migration.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DataMigrationComponent implements OnInit {
    public migrationForm: UntypedFormGroup;
    public environments: string[];
    public sourceEnv: string;
    public migrationOption: string;
    public jobType: string;

    public migrationOptions = [
        { Text: 'Email Templates', Value: 'emailTemplates' },
        { Text: 'Permissions', Value: 'permissions' },
        { Text: 'User Roles', Value: 'userRoles' },
        { Text: 'Topic Subscriptions', Value: 'subscriptions' },
        { Text: 'Branch Info', Value: 'branchInfo' },
        { Text: 'Leak Report Templates', Value: 'leakReportTemplates' },
        { Text: 'Handoff Meeting Template', Value: 'handoffMeetingTemplate' },
        { Text: 'Materials', Value: 'materials' },
        { Text: 'Project Templates', Value: 'reportTemplate' },
        { Text: 'Budget Estimate Templates', Value: 'budgetTemplate' }
    ];

    public jobTypes = [
        { Desc: '307 Spray Products', Code: '307' },
        { Desc: '310 Maintenance Audits', Code: '310' },
        { Desc: '311 Preventative Maintenance', Code: '311' },
        { Desc: '312 Emergency Leak Repair', Code: '312' },
        { Desc: '313 Warranty Compliance', Code: '313' },
        { Desc: '314 Alterations', Code: '314' },
        { Desc: '317 Metal Project', Code: '317' },
        { Desc: '318 Special Service Projects', Code: '318' },
        { Desc: '319 Glass Investigation', Code: '319' },
        { Desc: '321 Green Roof', Code: '321' },
        { Desc: 'eee Emergency Leak', Code: 'eee' },
        { Desc: 'aaa Emergency Audit', Code: 'aaa' },
        { Desc: 'ggg Emergency Glass', Code: 'ggg' },
        { Desc: 'mmm Emergency Metal', Code: 'mmm' },
        { Desc: 'qqq Emergency Quote', Code: 'qqq' }
    ];

    private readonly snackBarDuration: number = 5 * 1000;

    constructor(private _appConfigService: AppConfigHttpService,
                private _snakeBar: MatSnackBar,
                private _dialog: MatDialog) { }

    public async ngOnInit(): Promise<void> {
        this.environments = await this._appConfigService.getAvailableSources();
    }

    public async migrateData(): Promise<void> {
        if (this.migrationOption === 'reportTemplate') {
            if (!this.jobType) {
                this._snakeBar.open('Please select Job type', 'Close', {
                    duration: this.snackBarDuration
                });
                return;
            }
        }
        else {
            this.jobType = '';
        }

        const answer = await this.confirmDialog();
        if (!answer) {
            return;
        }

        try {
            const result = await this._appConfigService.sendDataMigration(this.sourceEnv, this.migrationOption, this.jobType);
            const successMessage = 'Data Migration completed successfully.';
            const failureMessage = 'Failed to do data migration. please check the logs.';

            this._snakeBar.open(result ? successMessage : failureMessage, 'Close', {
                duration: this.snackBarDuration
            });
        }
        catch (error) {
            this._snakeBar.open('Failed to call data migration API.', 'Close', {
                duration: this.snackBarDuration
            });
        }
    }

    private confirmDialog(): Promise<boolean> {
        const message = 'This will overwrite current data, Are you sure you want to do this?';
        const data = new ConfirmDialogOption('Confirm Data Migration', message);
        const dialog = this._dialog.open(ConfirmDialogComponent, { maxWidth: '400px', data });

        return dialog.afterClosed().toPromise();
    }
}
