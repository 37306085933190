/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewEncapsulation, ViewChild, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import DictionaryEntry from '../../../core/data-models/dictionary-entry';
import DictionaryEntryLanguage from '../../../core/data-models/dictionary-entry-language';
import { TranslateService } from '@ngx-translate/core';
import { BryntumSchedulerProComponent } from '@bryntum/schedulerpro-angular';
import { ColumnStore, SchedulerPro, StringHelper } from '@bryntum/schedulerpro';
import { schedulerProConfig } from './queue-view.config';
import Branch from '../../../core/data-models/branch';
import Crew from '../../../core/data-models/crew';
import { ScheduleBoardHttpService } from '../../../core/services/http/schedule-board-http/schedule-board-http.service';
import { ScheduleBoardService } from '../../../features/schedule-board/schedule-board.service';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';

@Component({
    selector: 'queue-view',
    styleUrls: ['./queue-view.component.scss'],
    templateUrl: './queue-view.component.html',
    encapsulation: ViewEncapsulation.None
})
export class QueueViewComponent implements OnChanges, AfterViewInit {
    @ViewChild(BryntumSchedulerProComponent) schedulerProComponent: BryntumSchedulerProComponent;
    @Input() public activeProjectId = '';
    @Input() public isLoading = false;
    @Output() public projectDispatched: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public branch: Branch;
    public entryIndex = 0;
    public languageIndex = 0;
    public entries: DictionaryEntry[] = [];
    public isLoaded = false;
    private schedulerPro: SchedulerPro;
    private _projectId: string;
    private _projectStatus: string;
    static allowedProjectStatuses: string[] = ['0', '1'];
    schedulerProConfig = schedulerProConfig;
    //eslint-disable-next-line
    public crewList: Crew[];
    private _crewName: string;
    private _crewId = 0;
    private _showRedispatchModal = false;
    private _disableDispatchView = false;
    private isRedispatchCancel = false;
    private _disableEventDrag = false;
    static estimatedHours: any;
    static currentEventData: any ;
    static projectStatusDesc = '';
    // eslint-disable-next-line max-params
    constructor(
        private _scheduleBoardHttpService: ScheduleBoardHttpService,
        public translate: TranslateService,
        private _projectHttp: ProjectHttpService,
        private _scheduleBoardService: ScheduleBoardService) {
    }

    eventRenderer = ({ eventRecord }: { eventRecord: any }): string => {
        return `
            <div class="info">
                <div class="desc">${StringHelper.encodeHtml(eventRecord.projectName)}</div>
                <div class="desc">${StringHelper.encodeHtml(eventRecord.desc)}</div>
            </div>
        `;
    };

    onEventClick(e : {[key:string] : any}) : void {
        const data = e.eventRecord;
        const resourceData = e.resourceRecord;
        this._projectId = data.originalData.name;
        this._crewId = data.originalData.crewId;
        this._projectStatus = data.originalData.projectStatus;
        this._crewName = resourceData.originalData.name;

        if (this._showRedispatchModal===true && QueueViewComponent.allowedProjectStatuses.includes(this._projectStatus.toString())) {
            this._disableDispatchView = true;
            this.showDispatchModal(this._projectId, this._crewId, this._crewName);
            this._showRedispatchModal = false;
        }
        else if (this._showRedispatchModal===true) {
            this._showRedispatchModal = false;
            this.showDispatchModal(this._projectId, this._crewId, this._crewName, '', true);
            this._showRedispatchModal = false;
        }
    }


    beforeEventDropFinalize(e : {[key:string] : any}) {
        const contextData = e.context;
        const data = e.context.eventRecords[0];
        this._projectId = data.originalData.name;
        this._projectStatus = data.originalData.projectStatus;
        this._crewId = contextData.newResource.originalData.id;
        this._crewName = contextData.newResource.originalData.name;
        this.isRedispatchCancel = true;
        this._showRedispatchModal = true;
        e.context.async = true;
        if (this._showRedispatchModal===true && QueueViewComponent.allowedProjectStatuses.includes(this._projectStatus.toString())) {
            this._disableDispatchView = true;
            this.showDispatchModal(this._projectId, this._crewId, this._crewName, e.context);
            this._showRedispatchModal = false;
        }
        else if (this._showRedispatchModal===true) {
            this._showRedispatchModal = false;
            this.showDispatchModal(this._projectId, this._crewId, this._crewName, e.context, true);
            this._showRedispatchModal = false;
        }
    }

    public async showDispatchModal(_projectId: string, _crewId: number, _crewName: string, _context: any='', _isNotEditable=false): Promise<void> {
        const dispatchExistingOptions = await this._projectHttp.getDispatchExistingOptions(this._projectId, true);
        dispatchExistingOptions.crewId = this._crewId;
        dispatchExistingOptions.crewName = _crewName;
        dispatchExistingOptions.isDispatch = false;
        dispatchExistingOptions.isNotEditable = _isNotEditable;
        dispatchExistingOptions.isScheduleBoardDispatch = true;
        if (await this._scheduleBoardService.dispatchProject(dispatchExistingOptions)) {
            this.projectDispatched.emit(true);
            if (_context !== '') {
                _context.finalize(true);
            }
            await this.loadSchedulerComponentData();
        }
        else {
            // eslint-disable-next-line no-lonely-if
            if (this.isRedispatchCancel === true) {
                if (_context !== '') {
                    _context.finalize(false);
                }
            }
        }

        this._showRedispatchModal = true;
        // eslint-disable-next-line require-atomic-updates
        this.isRedispatchCancel = false;
    }

    public ngAfterViewInit() {
        this.isLoading = true;
        this._showRedispatchModal = true;
        QueueViewComponent.estimatedHours = this.translate.instant('queueView.estimatedHours');
        QueueViewComponent.projectStatusDesc = this.translate.instant('queueView.projectStatus');
    }


    async ngOnChanges() {
        if (this.isLoading === false) {
            await this.loadSchedulerComponentData();
        }
    }


    public async loadSchedulerComponentData() {
        this.isLoading = true;
        const crewLabel: string = this.translate.instant('queueView.crews');
        const scheduleBoardData = await this._scheduleBoardHttpService.getProjectData(this.branch.name);
        this.schedulerPro = this.schedulerProComponent.instance;
        this.schedulerProConfig.startDate = this.getCurrentDateValue();
        this.schedulerProConfig.endDate = this.getCurrentDateValue();
        (this.schedulerPro.columns as ColumnStore).get('name').text = crewLabel;
        this.schedulerPro.readOnly = false;
        this.schedulerPro.project.inlineData = scheduleBoardData;
        this.isLoading = false;
    }

    ngOnDestroy(): void {
        this.schedulerPro?.destroy();
    }


    public getLocalizedContent(contents: DictionaryEntryLanguage[]): string {
        if (!contents.length) {
            return '';
        }
        // always return default translation when selected translation is not available
        return contents[this.languageIndex]?.content ?? contents[0].content;
    }

    public getCurrentDateValue(): string {
        return `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`;
    }
}
