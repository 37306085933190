import { Component, Input } from '@angular/core';

@Component({
    selector: 'site-quotes',
    templateUrl: './site-quotes.component.html',
    styleUrls: ['./site-quotes.component.scss']
})

export class SiteQuotesComponent {
    @Input() public activeQuoteId = '';
}
