<div fxLayout="column" [formGroup]="projectInfoForm" *ngIf="projectInfoForm" fxLayoutGap="15px">
    <div fxLayout="column" class="dispatch-step-content">
        <div fxLayout="row" fxLayoutGap="15px">
            <mat-form-field>
                <mat-label>{{ branchSelectionLabel }}</mat-label>
                <mat-select formControlName="branch" required>
                    <mat-option *ngFor="let branch of branches" [value]="branch">{{branch.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ (isInternal ? "dispatch.projectInfoStep.salesCode" : "dispatch.projectInfoStep.projectType") | translate }}</mat-label>
                <mat-select formControlName="salesCode" required (selectionChange)="salesCodeChanged($event)">
                    <mat-option *ngFor="let salesCode of dispatchInfo.salesCode" [value]="salesCode.code">{{salesCode.code}} {{getTranslation(salesCode)}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="isInternal">
                <mat-label>{{ "dispatch.projectInfoStep.scope" | translate }}</mat-label>
                <mat-select formControlName="scope" required>
                    <mat-option *ngFor="let scope of dispatchInfo.systems" [value]="scope">{{scope}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="isGreenRoof">
                <mat-label>{{ "dispatch.projectInfoStep.inspection.label" | translate }}</mat-label>
                <mat-select formControlName="greenRoofOption">
                    <mat-option value="Green_Roof_Spring">{{ "dispatch.projectInfoStep.inspection.spring" | translate }}</mat-option>
                    <mat-option value="Green_Roof_Summer">{{ "dispatch.projectInfoStep.inspection.summer" | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="jobSubTypes.length">
                <mat-label>{{ "dispatch.projectInfoStep.jobType" | translate }}</mat-label>
                <mat-select formControlName="jobType">
                    <mat-option *ngFor="let jobType of jobSubTypes" [value]="jobType.code">{{ getTranslation(jobType) }}</mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="isInternal">
                <mat-form-field>
                    <mat-label>{{ "dispatch.projectInfoStep.retentionMethod" | translate }}</mat-label>
                    <mat-select [(ngModel)]="config.data.retentionMethod"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="resetRetentionPercent()">
                        <mat-option *ngFor="let retentionMethod of dispatchInfo.retentionMethods" [value]="retentionMethod.code">{{retentionMethod.description}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="retentionPercentRequired">
                    <mat-label>{{ "dispatch.projectInfoStep.retentionPercent" | translate }}</mat-label>
                    <input type="number" matInput formControlName="retentionPercent"
                        min="0.0" step="0.1" [required]="retentionPercentRequired">
                    <mat-error *ngIf="getFormField('retentionPercent').invalid">
                        {{ positiveValidator.message }}
                    </mat-error>
                </mat-form-field>
            </ng-container>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
            <mat-form-field class="dispatch-job-name-field">
                <mat-label>{{ "dispatch.projectInfoStep.jobName" | translate }}</mat-label>
                <input matInput formControlName="name" required maxlength="60">
                <mat-error *ngIf="getFormField('name').errors">
                    {{ 'projects.update.noDoubleQuotesAllowed' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="showEstimatedHours">
                <mat-label>{{ "dispatch.projectInfoStep.estimatedHours" | translate }}</mat-label>
                <input type="number" matInput formControlName="estimatedHours"
                    min="0" step="1" [required]="!config.isConvertingProject">
                    <mat-error *ngIf="getFormField('estimatedHours').invalid">
                        {{ integerValidator.message }}
                    </mat-error>
            </mat-form-field>
            <div fxLayout="column">
                <div fxLayout="row" fxLayoutGap="15px">
                    <mat-form-field *ngIf="isMultiDay && !config.isConvertingProject">
                        <mat-label>{{ "dispatch.projectInfoStep.estimatedDays" | translate }}</mat-label>
                        <input type="number" matInput formControlName="estimatedDays"
                        min="1" step="1" [required]="isMultiDay && !config.isConvertingProject">
                        <mat-error *ngIf="getFormField('estimatedDays').invalid">
                            {{ daysValidator.message }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="useBudgetFeature">
                        <mat-label>{{ "dispatch.projectInfoStep.budgetType" | translate }}</mat-label>
                        <mat-select formControlName="budgetType" required>
                            <mat-option *ngFor="let type of contractTypes" [value]="type">{{type}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="showRoofingSquares">
                        <mat-label>{{ "dispatch.projectInfoStep.roofingSquares" | translate }}</mat-label>
                        <input type="number" matInput [(ngModel)]="config.data.roofingSquares"
                        [ngModelOptions]="{standalone: true}" min="0" step="1">
                    </mat-form-field>
                </div>

                <div class="ten-day-warning" *ngIf="getFormFieldValue('estimatedDays') > 10">{{ 'dispatch.projectInfoStep.tenDayWarning' | translate: { numDays: getFormFieldValue('estimatedDays') } }}</div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
            <mat-form-field *ngIf="isInternal" class="dispatch-project-auto-complete-field">
                <mat-label>{{ "dispatch.projectInfoStep.projectBillWith" | translate }}</mat-label>
                <input type="text" matInput formControlName="projectBillWith" [matAutocomplete]="auto"
                    (blur)="checkInputValueFromList()">
                <mat-autocomplete #auto="matAutocomplete">
                    <cdk-virtual-scroll-viewport class="dispatch-project-auto-complete-viewport" itemSize="50" minBufferPx="250" maxBufferPx="500">
                        <mat-option *cdkVirtualFor="let project of filteredOptions | async" [value]="project.id" class="dispatch-project-auto-complete-item">
                            {{project.id}} - {{project.name}}
                        </mat-option>
                    </cdk-virtual-scroll-viewport>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field *ngIf="isInternal">
                <mat-label>{{ "dispatch.projectInfoStep.taxableStatus" | translate }}</mat-label>
                <mat-select formControlName="taxStatus" required>
                    <mat-option *ngFor="let status of dispatchInfo.taxStatus" [value]="status">{{status}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-checkbox [(ngModel)]="config.data.drawingRequired" *ngIf="showIsDrawingRequired"
                [ngModelOptions]="{standalone: true}" [disabled]="isDrawingRequired">
                {{ "dispatch.projectInfoStep.drawingRequired" | translate }}
            </mat-checkbox>
            <mat-checkbox [(ngModel)]="config.data.requireOvernightSealPhoto" [ngModelOptions]="{standalone: true}" [disabled]="isOneDayJob" *ngIf="showIsOvernightSealRequired">
                {{ "dispatch.projectInfoStep.requireOvernightSeal" | translate }}
            </mat-checkbox>
        </div>
        <div fxLayout="row" fxLayoutGap="15px">
            <mat-card fxFlex="{{showCrewSelection ? 75 : 100}}">
                <mat-card-title>{{ "dispatch.projectInfoStep.projectNotes" | translate }}</mat-card-title>
                <mat-card-content>
                    <div fxLayout="row" fxLayoutGap="15px">
                        <div fxLayout="column" fxFlex="50">
                            <mat-form-field>
                                <mat-label>{{ "dispatch.projectInfoStep.customerPo" | translate }}</mat-label>
                                <input matInput [(ngModel)]="config.data.customerPurchaseOrder" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>{{ "dispatch.projectInfoStep.notToExceedAmount" | translate }}</mat-label>
                                <input type="number" matInput formControlName="notToExceedAmount" min="0" step="0.5">
                                <mat-error *ngIf="getFormField('notToExceedAmount').invalid">
                                    {{ currencyValidator.message }}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="isCompleteByDateAllowed">
                                <mat-label>{{ 'projects.dispatchReport.completeByDate' | translate }}</mat-label>
                                <input matInput [matDatepicker]="picker" formControlName="completeByDate" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50" *ngIf="showDispatcherNotes">
                            <mat-form-field class="dispatch-dispatcher-notes-field">
                                <mat-label>{{ "dispatch.projectInfoStep.dispatcherNotes" | translate }}</mat-label>
                                <textarea matInput [(ngModel)]="config.data.dispatcherNotes" rows="5"
                                    [ngModelOptions]="{standalone: true}"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card fxFlex="25" [ngClass]="{'error-div-outline': !validDispatchOptions}" *ngIf="showCrewSelection">
                <mat-card-title>{{ "dispatch.projectInfoStep.dispatchOptions" | translate }}</mat-card-title>
                <mat-card-content>
                    <div fxLayout="column">
                        <mat-checkbox [(ngModel)]="config.data.sendToLocalQueue" [ngModelOptions]="{standalone: true}"
                            [disabled]="!allowDispatchToTech || handOffMeetingRequired" (change)="config.data.crew = null">
                            {{ "dispatch.projectInfoStep.sendToLocalQueue" | translate }}
                        </mat-checkbox>
                        <div *ngIf="!config.data.sendToLocalQueue">
                            <crew-selector [horizontalMode]="false"
                                [healthScreeningOption]="healthScreeningOption"
                                [country]="customerCountry"
                                (crewSelectedEvent)="config.data.crew = $event">
                            </crew-selector>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <button mat-stroked-button (click)="stepClicked.emit('previous')" color="primary">
            {{ "dispatch.projectInfoStep.previousButton" | translate }}
        </button>
        <button mat-stroked-button (click)="validateAndMoveNext()" color="primary">
            {{ "dispatch.projectInfoStep.nextButton" | translate }}
        </button>
    </div>
</div>
