<div fxFill fxLayout="column" fxLayoutGap="15px">
    <div fxLayout="column" fxLayoutAlign="start start" class="customer-selection-confirm-container">
            <information-grid-card *ngIf="customerInfo" class="customer-selection-main-info-display"
                [icon]="'supervisor_account'"
                [allowAnimation]="true"
                [animationDelay]="0.05"
                [title]="translate.instant('shared.customerCreateManagement.confirmationStep.customerInformation')"
                [config]="customerInfo">
            </information-grid-card>

            <information-grid-card *ngIf="billingSiteInfo" class="customer-selection-site-info-display"
                [icon]="'contact_phone'"
                [allowAnimation]="true"
                [animationDelay]="0.05"
                [title]="translate.instant('shared.customerCreateManagement.confirmationStep.billingSite')"
                [config]="billingSiteInfo">
            </information-grid-card>

            <information-grid-card *ngIf="jobSiteInfo" class="customer-selection-site-info-display"
                [icon]="'contact_phone'"
                [allowAnimation]="true"
                [animationDelay]="0.05"
                [title]="translate.instant('shared.customerCreateManagement.confirmationStep.jobSite')"
                [config]="jobSiteInfo">
            </information-grid-card>

    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
        <button mat-stroked-button color="primary" (click)="handleStep.emit('back')">
            {{ 'shared.customerSelectionConfirm.back' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="confirmSummaryDetails.emit(true)">
            {{ 'shared.customerSelectionConfirm.submit' | translate }}
        </button>
    </div>
</div>
