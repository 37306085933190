/* eslint-disable max-lines */
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';

import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import { AppEventService } from '../../../core/services/events/app-event/app-event.service';
import BranchTimezone from '../../../core/data-models/branch-timezone';
import { VideoHttpService } from '../../../core/services/http/video-http/video-http.service';
import { VideoItem } from '../../../core/data-models/job-template-items/video-item';

@Component({
    selector: 'app-video-viewer',
    styleUrls: ['./video-viewer.component.scss'],
    templateUrl: './video-viewer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class VideoViewerComponent implements OnInit {
    @Input() public activeProjectId = '';
    @Input() public timeZone: BranchTimezone;
    public loading = true;
    public videos: VideoItem[] = [];
    public picOrder = false;
    public pageSize = 25;
    public pageSizeOptions = [10, 25, 50, 75];
    public selectedVideoIndex: string;
    public sortMode = false;
    public selectedIndex = 0;
    private currentPage = 0;
    private currentPageSize = 25;


    get endIndex(): number {
        return this.videos.length - 1;
    }

    //eslint-disable-next-line
    constructor(public translate: TranslateService,
                private _videoHttpService: VideoHttpService,
                private _projectService: ProjectHttpService,
                private _appEvents: AppEventService) { }

    public async ngOnInit() : Promise<void> {
        await this.getProjectVideos(true);
    }

    public setPageSize(event: PageEvent) : void {
        this.currentPage = event.pageIndex;
        this.currentPageSize = event.pageSize;
        //this.setImagesByPage();
    }

    private async getProjectVideos(showLoading: boolean) : Promise<void> {
        this.loading = showLoading;
        this.videos = await this._videoHttpService.getProjectVideos(this.activeProjectId);
        this.loading = false;
    }

    private reloadCounters(): void {
        if (ProjectIdUtility.isEmergencyQuote(this.activeProjectId)) {
            this._appEvents.setReloadQuoteCounter();
        }
        else {
            this._appEvents.setReloadProjectCounter();
        }
    }
}
