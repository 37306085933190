import Address from './address';
import GeoLocation from './geo-location';
import Employee from './employee';
import FollowUpGoal from './follow-up-goal';

export default class Branch {
    public id = '';
    public code = '';
    public name = '';
    public companyName = '';
    public address = new Address();
    public geoLocation = new GeoLocation();
    public followUpGoals = new Array<FollowUpGoal>();
    public servicesOps = new Array<Employee>();
    public followUpGoalThisYear = 0;
    public iconUri = '';
    public isDeactivated = false;
    public isBuiltIn = false;
    public isDefault = false;
    public website = '';
    public phone = '';
    public fax = '';
    public email = '';
    public useDaylightSaving = true;
    public useErpBudget = true;
    public useErpInvoice = true;
}
