/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, Input, OnChanges, SimpleChange, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;

@Component({
    selector: 'app-xlsx-file-preview',
    styleUrls: ['./xlsx-file-preview.component.scss'],
    templateUrl: './xlsx-file-preview.component.html',
    encapsulation: ViewEncapsulation.None
})
export class XlsxFilePreviewComponent implements OnChanges, AfterViewInit {
    @Input() file: Promise<Blob>;
    public loadingMessage: string;
    private _id = `spread-sheet-view-${new Date().getTime()}`;

    constructor(private _translate: TranslateService) { }

    get sheetId(): string {
        return this._id;
    }

    get elementId(): string {
        return `#${this._id}`;
    }

    public ngOnChanges(change: SimpleChanges): void {
        if (change.file) {
            this.handleFileChange(change.file);
        }
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            $(this.elementId).kendoSpreadsheet();
        });
    }

    private async handleFileChange(change: SimpleChange): Promise<void> {
        if (!change.currentValue) {
            this.loadingMessage = this._translate.instant('shared.xlsxFilePreview.selectFile');
            return;
        }

        $(this.elementId).getKendoSpreadsheet().fromJSON({ sheets: [{ name: 'Sheet1' }] });

        this.loadingMessage = this._translate.instant('shared.xlsxFilePreview.loadingPreview');

        const result = await this.file;
        if (result) {
            setTimeout(async() => {
                this.loadingMessage = '';
                await $(this.elementId).getKendoSpreadsheet().fromFile(result);
                this.disableSpreadSheet();
            });
        }
        else {
            this.loadingMessage = this._translate.instant('shared.xlsxFilePreview.failedToLoad');
        }
    }

    // eslint-disable-next-line
    public disableSpreadSheet(): void {
        const spreadSheet = $(this.elementId).getKendoSpreadsheet();
        $(this.elementId).find('.k-spreadsheet-fixed-container').off('contextmenu');
        spreadSheet.sheets().forEach(sheet => {
            sheet.range(0, 0, sheet._rows._count, sheet._columns._count).enable(false);
        });
    }
}
