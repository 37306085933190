import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Branch from '../../../core/data-models/branch';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import CustomerQuotesReportQuery from '../../../core/data-models/data-report/customer-quotes-report-query';
import { aggregateBy, AggregateResult, GroupDescriptor, process } from '@progress/kendo-data-query';
import CustomerQuotesReport from '../../../core/data-models/data-report/customer-quotes-report';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-customer-report',
    styleUrls: ['./customer-report.component.scss'],
    templateUrl: './customer-report.component.html'
})
export class CustomerQuotesReportComponent implements OnInit {
    @Input() public jobTypes: string[];
    @Input() public branches: Branch[];
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    public gridViewData: CustomerQuotesReport[] = [];
    public loading: boolean;
    public gridPageSize = 15;
    public search: CustomerQuotesReportQuery;
    public total: AggregateResult;
    public groups: GroupDescriptor[] = [{ field: 'quoteBranch', aggregates: [{ field: 'quoteTotal', aggregate: 'sum' }] }];

    public constructor(private newRelicUtility: NewRelicUtilityService,
        private _quoteHttpService: QuoteHttpService,
        private _userHttpService: UserHttpService) {
        this.search = new CustomerQuotesReportQuery();
        this.allData = this.allData.bind(this);
    }

    public async ngOnInit(): Promise<void> {
        this.search.branch = (await this._userHttpService.getUserInfo()).branchInfo.code;
    }

    public async loadReport(): Promise<void> {
        this.newRelicUtility.addPageAction('ReportType', 'CustomerReports', 'ReportAccess', this.search);
        this.loading = true;
        this.dataBinding.skip = 0;
        this.gridViewData = await this._quoteHttpService.getCustomerReportQuotes(this.search);
        this.total = aggregateBy(this.gridViewData, this.groups[0].aggregates);
        this.loading = false;
    }

    public allData(): ExcelExportData {
        const result: ExcelExportData = {
            data: process(this.gridViewData, { group: this.groups }).data,
            group: this.groups
        };

        return result;
    }

}
