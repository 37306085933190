<dialog-action-card
    [icon]="data.icon"
    [title]="data.title"
    [isLoaded]="true"
    [isModalDialog]="true">
    <div fxLayout="row" fxFill>
        <customer-site-select
        [customerDisplayName]="data.customerDisplayName"
        [availableSites]="data.availableSites"
        [allowCreate]="false"
        (siteSelected)="selectSite($event)">

        </customer-site-select>
    </div>
</dialog-action-card>
