export enum DispatchSteps {
    CustomerSelectionStep = 'projects.dispatch.steps.customerSelectionStep',
    ProjectInfoStep = 'projects.dispatch.steps.projectInfoStep',
    WorkOrderStep = 'projects.dispatch.steps.workOrderStep',
    AdditionalInfoStep = 'projects.dispatch.steps.additionalInfoStep',
    UsProjectQuestionsStep = 'projects.dispatch.steps.usProjectQuestionsStep',
    HistoryStep = 'projects.dispatch.steps.historyStep',
    AttachmentsStep = 'projects.dispatch.steps.attachmentsStep',
    BudgetStep = 'projects.dispatch.steps.budgetStep',
    SummaryStep = 'projects.dispatch.steps.summaryStep'
}
