import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AudioPlaybackService {
    private readonly _audio = document.createElement('audio');

    public play(url: string): void {
        this._audio.src = url;
        this._audio.play();
    }
}
