import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import { MappingPair } from '../../../../core/data-models/generic/mapping-pair';

@Component({
    selector: 'app-version-prompt',
    styleUrls: ['./version-prompt.component.scss'],
    templateUrl: './version-prompt.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionPromptComponent {
    public version = new UntypedFormControl('', [this.validateVersion.bind(this)]);
    public sourceVersion = '';

    constructor(public translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) public existingVersions: string[],
                private _dialogRef: MatDialogRef<VersionPromptComponent, MappingPair<string> | null>) { }

    public onClose(emit = false): void {
        if (!emit || this.version.valid) {
            this._dialogRef.close(emit ? { source: this.sourceVersion, target: this.version.value.trim() } : null);
        }
        else {
            this.version.markAsTouched();
        }
    }

    private validateVersion(control: UntypedFormControl): null | { [key: string]: boolean } {
        const version = control.value?.trim() ?? '';

        if (!/^\d+\.\d+$/.test(version)) {
            return { invalidFormat: true };
        }

        return this.existingVersions.includes(version) ? { duplicateVersion: true } : null;
    }
}
