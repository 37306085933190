import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UserPermissionService } from '../../../core/services/user-permission/user-permission.service';
import { WritePermission } from '../../../core/enums/write-permission.enum';

@Component({
    selector: 'app-service-rates-management',
    styleUrls: ['./service-rates-management.component.scss'],
    templateUrl: './service-rates-management.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRatesManagementComponent implements OnInit {
    public isReadonly: boolean;

    constructor(private _permissionService: UserPermissionService) { }

    public async ngOnInit(): Promise<void> {
        this.isReadonly = !await this._permissionService.hasWritePermission(WritePermission.UpdateServiceRates, null, false);
    }
}
