import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';

@Component({
    selector: 'app-terms-of-service',
    templateUrl: './terms-of-service.component.html',
    styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {
    public pdfUrl: string;
    public isLoaded = false;

    public constructor(private _appConfigService: AppConfigHttpService,
        private _stateService: StateService) {
    }

    public async ngOnInit(): Promise<void> {
        const pdfBlob = await this._appConfigService.getTermsOfServicePdf();
        if (pdfBlob) {
            this.pdfUrl = URL.createObjectURL(pdfBlob);
            this.isLoaded = true;
        }
        else {
            await this._stateService.go('pageNotFound');
        }
    }
}
