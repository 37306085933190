import ProjectUsTaxQuestions from './project-us-tax-questions';

export default class ProjectUSTaxQuestionsValidCheck {
    public valid = true;
    public projectUsTaxQuestions = new ProjectUsTaxQuestions();

    constructor(valid: boolean, projectUSTaxQuestions: ProjectUsTaxQuestions) {
        this.valid = valid;
        this.projectUsTaxQuestions = projectUSTaxQuestions;
    }
}
