<div class="grid-item-actions-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1px">
    <button *ngIf="canEnableEdit"
        mat-icon-button
        matTooltip="{{ 'shared.edit' | translate }}"
        matTooltipPosition="above"
        (click)="editEnabled.emit()">

        <mat-icon>edit</mat-icon>
    </button>

    <button *ngIf="canClearContent"
        class="grid-item-actions-clear-button grid-item-actions-secondary-button"
        mat-icon-button
        matTooltip="{{ 'shared.clear' | translate }}"
        matTooltipPosition="above"
        (click)="contentClear.emit()">

        <mat-icon>clear</mat-icon>
    </button>

    <div class="grid-item-actions-copy-button grid-item-actions-secondary-button"
        #copyText
        fxLayout="column"
        fxLayoutAlign="center center">

        <div class="grid-item-actions-copy-tooltip" fxLayoutAlign="center center">
            <span>{{ 'shared.copied' | translate }}</span>
        </div>

        <button mat-icon-button (click)="onCopy(copyText)">
            <mat-icon fontSet="material-icons-outlined">
                file_copy
            </mat-icon>
        </button>
    </div>
</div>
