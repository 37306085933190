import { Component, ElementRef, Inject, OnInit, SecurityContext, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import PhotoReportDialogData from '../../../../core/data-models/photo-report-dialog-data';
import PhotoReportOptions from '../../../../core/data-models/photo-report-options';
import ImageCardConfig from '../../../../core/data-models/card-items/image-card-config';
import ImageItem from '../../../../core/data-models/job-template-items/image-item';
import { TranslateService } from '@ngx-translate/core';
import { SalesCodeConfigService } from '../../../../core/services/sales-code-config/sales-code-config.service';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import PhotoReportNotificationConfig from '../../../../core/data-models/photo-report-notification-config';
import { COMMA, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipList as MatChipList } from '@angular/material/legacy-chips';
import { UntypedFormControl } from '@angular/forms';
import { ValidatorFactory } from '../../../../core/services/validator-factory/validator-factory.service';
import { UserPermissionService } from '../../../../core/services/user-permission/user-permission.service';
import { WritePermission } from '../../../../core/enums/write-permission.enum';
import ProjectIdUtility from '../../../../core/services/utility/project-id-utility/project-id-utility.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-photo-report-options-dialog',
    styleUrls: ['./photo-report-options-dialog.component.scss'],
    templateUrl: './photo-report-options-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PhotoReportOptionsDialogComponent implements OnInit {
    public drawingView = false;
    public photoReportOptions: PhotoReportOptions;
    public drawingCardConfig: ImageCardConfig;
    public imageDates: string[];
    public selectedDate: string;
    public includeAllPhotosLabel: string;
    public isInternal = false;
    public readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, TAB];
    public externalEmailsField: UntypedFormControl;
    public logoImage = '';
    private _isAuditJob = false;
    private _hasSendByEmail = false;
    private _isEmergencyQuote = false;

    @ViewChild('chipList') public chipList: MatChipList;
    @ViewChild('addEmailInput') public addEmailInput: ElementRef;

    get isAuditJob(): boolean {
        return this._isAuditJob;
    }

    get hasSendByEmail(): boolean {
        return this._hasSendByEmail && !this._isEmergencyQuote;
    }

    //eslint-disable-next-line
    constructor(public translate: TranslateService,
        private _dialog: MatDialogRef<PhotoReportOptionsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PhotoReportDialogData,
        private _salesCodeConfig: SalesCodeConfigService,
        private _featureFlagService: FeatureFlagService,
        private _validatorFactory: ValidatorFactory,
        private _userPermissionService: UserPermissionService,
        private _domSanitizer: DomSanitizer) {
        this.includeAllPhotosLabel = this.translate.instant('shared.photoReportOptionsDialog.all');
    }

    public async ngOnInit(): Promise<void> {
        this.photoReportOptions = new PhotoReportOptions();
        this.photoReportOptions.selectedDrawings = [];
        this.photoReportOptions.notificationConfig = new PhotoReportNotificationConfig();
        this.drawingCardConfig = new ImageCardConfig();
        this.drawingCardConfig.imageSize = '200px';
        this.drawingCardConfig.showDescription = true;
        this.selectedDate = this.includeAllPhotosLabel;
        this.imageDates = Array.from(new Set(this.data.images.map(_ => _.createdDate)));
        this._isAuditJob = this._salesCodeConfig.isAudit(this.data.projectId);
        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        this.externalEmailsField = new UntypedFormControl('', [this._validatorFactory.createEmailValidator().validator]);
        const salesCode = this._salesCodeConfig.getSalesCode(this.data.projectId, false);
        this._isEmergencyQuote = ProjectIdUtility.isEmergencyQuote(this.data.projectId);
        this._hasSendByEmail = await this._userPermissionService.hasWritePermission(WritePermission.SendPhotoReportByEmail,
            {
                'projectType': salesCode
            });
        if (this.data.clientLogo) {
            this.logoImage = this._domSanitizer.sanitize(SecurityContext.STYLE, `url(${this.data.clientLogo})`);
        }
    }

    public selectDrawing(image: ImageItem): void {
        if (this.photoReportOptions.selectedDrawings.includes(image.index)) {
            this.photoReportOptions.selectedDrawings =
                this.photoReportOptions.selectedDrawings.filter(_ => _ !== image.index);
        }
        else {
            this.photoReportOptions.selectedDrawings.push(image.index);
        }

        this.photoReportOptions.selectedDrawings.sort();
    }

    public isSelectedDrawing(image: ImageItem): boolean {
        return this.photoReportOptions.selectedDrawings.includes(image.index);
    }

    public updateIncludeDrawingFlag(): void {
        if (this.photoReportOptions.includeCondition) {
            this.photoReportOptions.includeDrawing = false;
        }
    }

    public confirmOptions(): void {
        if (this.selectedDate !== this.includeAllPhotosLabel) {
            this.photoReportOptions.items = this.data.images.filter(_ => _.createdDate === this.selectedDate);
        }
        else {
            this.photoReportOptions.items = this.data.images;
        }

        if (this.photoReportOptions.includeDrawing && this.photoReportOptions.selectedDrawings.length === 0) {
            this.photoReportOptions.selectedDrawings = this.data.drawings.map(_ => _.index);
        }

        if (this.hasSendByEmail) {
            const emailField = this.addEmailInput.nativeElement.value;
            if (emailField && !this.addEmail({ value: emailField } as MatChipInputEvent)) {
                return;
            }
        }

        this._dialog.close(this.photoReportOptions);
    }

    public onClose(): void {
        this._dialog.close();
    }

    public addEmail(event: MatChipInputEvent): boolean {
        const value = event.value?.trim()?.toLowerCase() ?? '';
        const externalEmails = this.photoReportOptions.notificationConfig.externalEmails;

        this.externalEmailsField.setValue(value);
        this.externalEmailsField.updateValueAndValidity();
        this.chipList.errorState = this.externalEmailsField.invalid;

        if (value && !externalEmails.includes(value) && this.externalEmailsField.valid) {
            externalEmails.push(value);
        }

        this.addEmailInput.nativeElement.value = '';
        this.chipList._markAsTouched();

        return this.externalEmailsField.valid;
    }

    public removeEmail(email: string): void {
        this.photoReportOptions.notificationConfig.externalEmails =
            this.photoReportOptions.notificationConfig.externalEmails.filter(_ => _ !== email);
    }
}
