import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../../shared/shared.module';

import { states } from './legal.routing';
import { AttributionsComponent } from './attributions/attributions.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states }),
        SharedModule
    ],
    declarations: [
        AttributionsComponent,
        TermsOfServiceComponent
    ]
})
export class LegalModule { }
