import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '../../shared/shared.module';
import { ReceiptComponent } from './receipt.component';

import { states } from './receipt.routing';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states }),
        SharedModule
    ],
    declarations: [
        ReceiptComponent
    ]
})
export class ReceiptModule { }
