<form class="service-rates-summary-line-items-options-container" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh">
    <div class="line-item-entry" *ngFor="let control of customSummaryLineItems.controls; let i = index" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2.5vh">
        <mat-form-field fxFlex="75">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.customSummaryLineItems.name' | translate }}</mat-label>
            <input type="text" matInput [formControl]="control.get('identifier.name')" />

            <mat-error *ngIf="control.get('identifier.name').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('identifier.name').errors?.emptyName">
                * {{ 'flynnBudgetTool.ratesEditor.errors.emptyText' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('identifier.name').errors?.duplicateName">
                * {{ 'flynnBudgetTool.ratesEditor.errors.duplicateName' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="25">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.customSummaryLineItems.percentageRate' | translate }}</mat-label>
            <input type="number" matInput [formControl]="control.get('percentageRate')" (blur)="toPercentageDisplayValue(control.get('percentageRate'))" />

            <mat-error *ngIf="control.get('percentageRate').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('percentageRate').errors?.negative || control.get('percentageRate').errors?.min">
                * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
            </mat-error>
        </mat-form-field>

        <button [disabled]="isReadonly" mat-icon-button color="warn" (click)="customSummaryLineItems.removeAt(i)" type="button" (keydown.enter)="$event.preventDefault()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <button class="add-button" mat-stroked-button color="primary" [disabled]="isReadonly" (click)="addCustomSummaryLineItemRow()" type="button" (keydown.enter)="$event.preventDefault()">
        {{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.customSummaryLineItems.addCustomSummaryLineItem' | translate }}
    </button>
</form>
