import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import ImageItem from '../../../data-models/job-template-items/image-item';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';
import { VideoItem } from '../../../data-models/job-template-items/video-item';

@Injectable({
    providedIn: 'root'
})
export class VideoHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/video`;

    constructor(private _http: HttpClient) { }

    public async getProjectVideos(projectId: string) : Promise<VideoItem[]> {
        try {
            const endpoint = `${this._api}/${projectId}`;
            const data = await this._http.get<VideoItem[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<ImageItem>();
        }
    }
}
