/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import ClientEvent from '../../data-models/client-events/client-event';
import JsonUtility from '../utility/json-utility.service';
import { environment } from '../../../../environments/environment';

declare const $: any;

@Injectable({
    providedIn: 'root'
})
export class SignalrHubService {
    private _proxy: any = null;
    private _connectionChanged = new BehaviorSubject(false);

    get connectionChanged(): Observable<boolean> {
        return this._connectionChanged.asObservable();
    }

    get connectionId(): string {
        return this._proxy?.connection.id ?? '';
    }

    /* istanbul ignore next */
    public initialize(): void {
        const connection = $.hubConnection(environment.signalrUrl, { useDefaultPath: false });
        connection.logging = true;
        connection.error(_ => console.log(`SignalR error: ${_}`));

        connection.disconnected(() => {
            this._connectionChanged.next(false);
            console.log('SignalR connection lost, reconnecting in 5 seconds...');
            setTimeout(() => this.connect(connection), 5000);
        });

        this._proxy = connection.createHubProxy('notification');
        // must register at least one event handler before calling connection.start() in order for the server to invoke client side methods
        this._proxy.on('dummyEvent', console.log);
        this.connect(connection);
    }

    /* istanbul ignore next */
    private connect(connection: any): void {
        connection.start()
            .done(() => {
                this._connectionChanged.next(true);
                console.log('SignalR connection established.');
            })
            .fail(() => {
                this._connectionChanged.next(false);
                console.log('SignalR connection failed.');
            });
    }

    public subscribe(name: string, callback: (..._: any[]) => void): void {
        this._proxy.off(name);

        this._proxy.on(name, (...data: any[]) => {
            const inputs = data.map(_ => JsonUtility.toCamelCaseKeys(_));

            return callback(...inputs);
        });
    }

    public emit<T>(name: string, event: ClientEvent<T>): void {
        this._proxy.invoke(name, event);
    }
}
