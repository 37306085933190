import { Directive, HostListener, Input } from '@angular/core';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';

@Directive({
    selector: '[appGridPageResize]'
})
export class GridPageResizeDirective extends DataBindingDirective {
    @Input('appGridPageResize') enableOnResize = true;
    private mainElement: HTMLElement;
    private timeoutHandler: number;

    @HostListener('window:resize')
    onResize(): void {
        if (!this.enableOnResize) {
            return;
        }

        clearTimeout(this.timeoutHandler);
        this.timeoutHandler = window.setTimeout(_ => {
            this.updatePageSize();
        }, 750);
    }

    constructor(private element: GridComponent) {
        super(element);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        setTimeout(_ => {
            this.mainElement = this.getMainGridElement(this.element.wrapper.nativeElement.children);
            this.updatePageSize();
        });
    }

    private updatePageSize(): void {
        if (!this.mainElement) {
            return;
        }

        this.element.loading = true;
        const rowHeight = this.mainElement.querySelector('tr:not(.k-grouping-row)').clientHeight;
        const height = this.mainElement.clientHeight;
        const numberOfRecords = Math.floor(height/rowHeight);
        this.element.pageSize = numberOfRecords < 5 ? 5 : numberOfRecords;
        setTimeout(_ => {
            this.element.loading = false;
            this.element.pageChange.emit({ skip: this.skip, take: this.element.pageSize });
        });
    }

    private getMainGridElement(elements: HTMLCollectionOf<HTMLElement>): HTMLElement {
        if (!(elements && elements.length)) {
            return null;
        }

        for (let index = 0; index < elements.length; index++) {
            const element = elements.item(index);
            if (element.localName === 'kendo-grid-list') {
                return element;
            }

            const childElement = this.getMainGridElement(element.children as HTMLCollectionOf<HTMLElement>);

            if (childElement !== null) {
                return childElement;
            }
        }

        return null;
    }
}
