<div class="calendar-view" fxFill>
    <div *ngIf="isLoading" fxLayout="column" fxLayoutAlign="center center" fxFill>
        <dots-loader></dots-loader>
    </div>

    <div [hidden]="isLoading" fxFill>
        <div class="action-bar" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="date-toggles" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <button class="today" (click)="setCalendarDateToNow()" mat-button>
                    <mat-icon>calendar_month</mat-icon>
                    {{ "calendarView.today" | translate }}
                </button>
                <button class="toggle-button" (click)="onMoveCalendarDateBack()" mat-icon-button>
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <button class="toggle-button" (click)="onMoveCalendarDateForward()" mat-icon-button>
                    <mat-icon>chevron_right</mat-icon>
                </button>

                <span class="date-label">{{currStartDate.format('DD MMM')}} &mdash; {{currEndDate.format('DD MMM')}}</span>

                <span class="date-label-timezone">{{"calendarView.timezone" | translate }}: {{ getTimeZoneFromBranch() }}</span>
            </div>
            
        </div>
        <bryntum-project-model
            #project
        ></bryntum-project-model>

        <bryntum-scheduler-pro
        #schedulerpro
        [startDate]="schedulerProConfig.startDate"
        [endDate]="schedulerProConfig.endDate"
        [viewPreset]="schedulerProConfig.viewPreset"
        [forceFit]="schedulerProConfig.forceFit"
        [columns]="schedulerProConfig.columns"
        [project]="project"
        [eventColor]="schedulerProConfig.eventColor"
        [eventTooltipFeature]="eventToolTipFeature"
        [taskEditFeature]="schedulerProConfig.features.taskEdit"
        [scheduleTooltipFeature]="schedulerProConfig.features.scheduleTooltip"
        [zoomOnMouseWheel]="schedulerProConfig.zoomOnMouseWheel"
        [zoomOnTimeAxisDoubleClick]="schedulerProConfig.zoomOnTimeAxisDoubleClick"
        [createEventOnDblClick]="schedulerProConfig.createEventOnDblClick"
        [eventRenderer]="eventRenderer"
        [visibleDate]="schedulerProConfig.visibleDate"
        [timeRangesFeature]="schedulerProConfig.features.timeRanges"
        [timeResolution]="schedulerProConfig.timeResolution"
        (onEventClick)="onEditSchedule($event)"
        [listeners]="schedulerProConfig.listeners"
        [eventDragFeature]="schedulerProConfig.eventDragFeature"
        [eventDragCreateFeature]="schedulerProConfig.features.eventDragCreate"
        (onBeforeEventDropFinalize)="onDragDropSchedule($event)"
        [eventResizeFeature]="schedulerProConfig.eventResizeFeature"
        [dependenciesFeature]="schedulerProConfig.features.dependencies"
        [cellMenuFeature]="schedulerProConfig.cellMenuFeature"
        [destroyStores]="true"
        [tooltipTemplate]="tooltipTemplate"
        [scheduleMenuFeature]="false"
        [eventMenuFeature]="false"
        ></bryntum-scheduler-pro>
    </div>
</div>