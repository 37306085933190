import InformationGridItem from './information-grid-item';

export default class InformationGridRow {
    public items: InformationGridItem[] = [];
    public height?: number;

    constructor(items: InformationGridItem[], height?: number) {
        this.items = items;
        this.height = height;
    }
}
