import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitCamelCase'
})
export class SplitCamelCasePipe implements PipeTransform {

    public transform(camelCaseText: string): string {
        return (camelCaseText ?? '').replace(/([A-Z]|\d+)/g, ' $1').trim();
    }
}
