import { TypeRecord } from '../../generic/type-record';

import { ServiceOperationsLaborSection } from './service-operations-labor-section';
import { ServiceOperationsMaterialsSection } from './service-operations-materials-section';
import { ServiceOperationsMiscellaneousSection } from './service-operations-miscellaneous-section';
import { ServiceOperationsQualityControlFeesSection } from './service-operations-quality-control-fees-section';
import { ServiceOperationsSummarySection } from './service-operations-summary-section';
import { ServiceOperationsQuestionsSection } from './service-operations-questions-section';

export class ServiceOperationsBudgetData {
    public projectType = new TypeRecord();
    public branchCode: string;
    public isSubcontract: boolean;
    public laborSection = new ServiceOperationsLaborSection();
    public materialsSection = new ServiceOperationsMaterialsSection();
    public miscellaneousSection = new ServiceOperationsMiscellaneousSection();
    public qualityControlFeesSection = new ServiceOperationsQualityControlFeesSection();
    public summarySection = new ServiceOperationsSummarySection();
    public questionsSection = new ServiceOperationsQuestionsSection();
}
