import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import Chemical from '../../../data-models/chemical';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ChemicalsHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/chemicals`;

    constructor(private _http: HttpClient) { }

    public async getAllChemicals(): Promise<Chemical[]> {
        try {
            const endpoint = `${this._api}/getAllChemicals`;
            const data = await this._http.get<Chemical[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async addChemical(chemical: Chemical): Promise<boolean> {
        try {
            const endpoint = `${this._api}/addChemical`;
            const result = await this._http.post<boolean>(endpoint, chemical).toPromise();

            return result;
        }
        catch {
            return false;
        }
    }

    public async removeChemical(chemical: Chemical): Promise<boolean> {
        try {
            const endpoint = `${this._api}/removeChemical`;
            const result = await this._http.post<boolean>(endpoint, chemical).toPromise();

            return result;
        }
        catch {
            return false;
        }
    }

    public async updateChemical(chemical: Chemical): Promise<boolean> {
        try {
            const endpoint = `${this._api}/updateChemical`;
            return await this._http.post<boolean>(endpoint, chemical).toPromise();
        }
        catch {
            return false;
        }
    }
}
