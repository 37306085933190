import { NgModule } from '@angular/core';

import { BudgetEstimationModule } from './budget-estimation/budget-estimation.module';
import { RatesMaintenanceModule } from './rates-maintenance/rates-maintenance.module';

@NgModule({
    imports: [
        BudgetEstimationModule,
        RatesMaintenanceModule
    ],
    exports: [
        BudgetEstimationModule,
        RatesMaintenanceModule
    ]
})
export class FeaturesModule { }
