  <div fxLayout="column" class="job-chemical-container" fxFill>
    <kendo-grid
      [data]="view"
      [pageSize]="gridState.take"
      [skip]="gridState.skip"
      [sort]="gridState.sort"
      [pageable]="true"
      [sortable]="true"
      [navigable]="true"
      (dataStateChange)="onStateChange($event)"
      (pageChange)="pageChange($event)">
      <ng-template kendoGridToolbarTemplate>
        <button mat-flat-button type="button" color="primary" (click)="addHandler()">
            <mat-icon>add</mat-icon>
            {{ 'dataReport.materialsReport.addNew' | translate}}
        </button>
      </ng-template>
      <kendo-grid-column field="name" title="{{'dataReport.materialsReport.name' | translate}}" width="150">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <span>
            {{dataItem.name}}
          </span>
        </ng-template>
      </kendo-grid-column>
    <kendo-grid-column field="value" editor="numeric" title="{{'dataReport.materialsReport.unitPrices' | translate}}" width="130">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <span>
            {{dataItem.value}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="unit" title="{{'dataReport.materialsReport.unit' | translate}}" width="100">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <span>
            {{dataItem.unit}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="jobCodes" title="{{'dataReport.materialsReport.jobCodes' | translate}}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <mat-chip-list>
              <mat-chip
                  *ngFor="let jobCode of dataItem.jobCodes">
                  {{jobCode.name}} ({{jobCode.code}})
              </mat-chip>
          </mat-chip-list>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="note" title="{{'dataReport.materialsReport.note' | translate }}">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <span>
            {{dataItem.note}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="" width="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <button mat-mini-fab type="button" color="primary" (click)="editHandler(dataItem)">
                    <mat-icon>edit</mat-icon>
                  </button>
                <button mat-mini-fab type="button" color="warn" (click)="removeHandler(dataItem)">
                  <mat-icon>delete</mat-icon>
                </button>
            </div>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
