import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { ServiceRatesGroupSummaryDto } from '../../../core/data-models/dtos/service-rates-group-summary-dto';
import { ServiceRatesHttpService } from '../../../core/services/http/service-rates-http/service-rates-http.service';

import { ServiceRatesGroupEditorComponent } from './service-rates-group-editor/service-rates-group-editor.component';

@Component({
    selector: 'app-service-rates-maintenance',
    styleUrls: ['./service-rates-maintenance.component.scss'],
    templateUrl: './service-rates-maintenance.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRatesMaintenanceComponent implements OnInit {
    @Input() public isReadonly: boolean;
    public summaries: ServiceRatesGroupSummaryDto[] = [];
    public selectedGroup: ServiceRatesGroupSummaryDto;
    public searchGroupText = '';
    public isLoading = false;

    constructor(public translate: TranslateService,
                private _serviceRatesHttp: ServiceRatesHttpService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _changeDetectorRef: ChangeDetectorRef) { }

    get visibleGroups(): ServiceRatesGroupSummaryDto[] {
        const text = (this.searchGroupText ?? '').trim().toLowerCase();

        return this.summaries.filter(summary => summary.name.toLowerCase().includes(text));
    }

    public async ngOnInit(): Promise<void> {
        await this.loadGroupSummaries();
    }

    public async openNewGroupEditor(): Promise<void> {
        const [width, minHeight, maxHeight] = ['30vw', '500px', '55vh'];
        const dialog = this._dialog.open(ServiceRatesGroupEditorComponent, { width, minHeight, maxHeight });
        const group = await dialog.afterClosed().toPromise<ServiceRatesGroupSummaryDto>();

        if (group) {
            await this.onGroupUpsert(group);
        }

        this._changeDetectorRef.markForCheck();
    }

    public async onGroupUpsert(group: ServiceRatesGroupSummaryDto): Promise<void> {
        const result = await this._serviceRatesHttp.upsertServiceRatesGroup(group);

        if (result) {
            this.selectedGroup = result;
        }

        const key = result ? 'messages.groupSaved' : 'errors.failedToSaveGroup';
        const message = this.translate.instant(`flynnBudgetTool.ratesMaintenance.${key}`);
        this._snackBar.open(message, this.translate.instant('flynnBudgetTool.shared.ok'));
        this._changeDetectorRef.markForCheck();
    }

    public getTotalVersionsText(summary: ServiceRatesGroupSummaryDto): string {
        const activeText = this.translate.instant('flynnBudgetTool.shared.active');
        const totalActive = summary.metadata.filter(meta => meta.isActive).length;

        return `${summary.metadata.length} (${totalActive} ${activeText})`;
    }

    public async closeVersionsView(): Promise<void> {
        this.selectedGroup = null;
        await this.loadGroupSummaries();
    }

    private async loadGroupSummaries(): Promise<void> {
        this.isLoading = true;
        this.summaries = await this._serviceRatesHttp.getServiceRatesGroupSummaries();
        this.isLoading = false;
        this._changeDetectorRef.markForCheck();
    }
}
