<div class="permission-editor-container" fxLayout="column">
    <div class="permission-editor-filter" fxLayout="row" fxLayoutAlign="center center">
        <mat-radio-group [(ngModel)]="filterValue">
            <mat-radio-button *ngFor="let filter of filters" [value]="filter.type">
                {{ filter.name }}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="permission-editor-content">
        <div class="mat-elevation-z8 permission-list-wrapper">
            <table mat-table multiTemplateDataRows [dataSource]="visibleTemplates">
                <ng-container matColumnDef="name">
                    <th class="permission-name" mat-header-cell *matHeaderCellDef>
                        <span>{{ 'admin.rolesAndPermissions.permissionEditor.permissionHeaderText' | translate }}</span>
                        <span *ngIf="visibleTemplates.length">&nbsp;({{ visibleTemplates.length }})</span>
                    </th>

                    <td class="permission-name" mat-cell *matCellDef="let template">
                        {{ getPermissionName(template) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="summary">
                    <th class="permission-summary" mat-header-cell *matHeaderCellDef></th>

                    <td class="permission-summary" mat-cell *matCellDef="let template">
                        <div *ngIf="getRestrictions(template).length" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
                            <span>{{ 'admin.rolesAndPermissions.permissionEditor.restrictionSummaryText' | translate }}</span>

                            <div *ngFor="let restriction of getRestrictions(template)"
                                class="restriction-summary"
                                fxLayout="row"
                                fxLayoutAlign="center center">

                                <span>{{ (restriction | splitCamelCase).toLowerCase() }}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th class="permission-action" mat-header-cell *matHeaderCellDef>
                        <mat-slide-toggle *ngIf="!role.isBuiltIn"
                            color="primary"
                            [checked]="isMasterToggleOn"
                            [disabled]="isDisabled"
                            (change)="onAllPermissionToggle($event.checked)"
                            (click)="$event.stopPropagation()">
                        </mat-slide-toggle>
                    </th>

                    <td class="permission-action" mat-cell *matCellDef="let template">
                        <mat-slide-toggle *ngIf="!role.isBuiltIn"
                            color="primary"
                            [disabled]="isDisabled"
                            [(ngModel)]="template.isAllowed"
                            (ngModelChange)="onPermissionToggle(template)"
                            (click)="$event.stopPropagation()">
                        </mat-slide-toggle>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedRow">
                    <td mat-cell *matCellDef="let template" [attr.colspan]="visibleColumns.length">
                        <div class="expanded-row" [@detailExpand]="getAnimationState(template)">
                            <parameter-selector *ngIf="getAnimationState(template) === 'expanded'"
                                [isDisabled]="isDisabled"
                                [parameterSets]="getParameterSets(template)"
                                (update)="onParameterChange(template, $event)">
                            </parameter-selector>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>

                <tr class="display-row"
                    [ngClass]="{ 'active-row': row === expandedTemplate, 'disabled-row': !row.isAllowed || isDisabled }"
                    mat-row
                    *matRowDef="let row; columns: visibleColumns;"
                    (click)="toggleExpand(row)">
                </tr>

                <tr class="expand-row" mat-row *matRowDef="let row; columns: ['expandedRow']"></tr>
            </table>
        </div>
        <!-- cover the scrollbar on table header row -->
        <div class="scrollbar-cover"></div>
    </div>
</div>
