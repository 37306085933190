import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FileSystemFileEntry } from 'ngx-file-drop';

import AttachmentModel from '../../../core/data-models/attachment-model';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import { UploadHttpService } from '../../../core/services/http/upload-http/upload-http.service';
import { BudgetRecordPayload } from '../../../../flynn-budget-tool/core/data-models/budget-estimations/budget-record-payload';
import { ServiceOperationsBudgetData } from '../../../../flynn-budget-tool/core/data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-budget-data';
import BudgetManagementPayload from '../../../core/data-models/budget-management-payload';
import { BudgetHttpService } from '../../../core/services/http/budget-http/budget-http.service';
import { FileType } from '../../../core/enums/file-type';
import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../dialogs/action-progress-dialog/action-progress-dialog.component';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import { ProjectWorkflowService } from '../../../features/project/project-details/services/project-workflow/project-workflow.service';
import CodeDescription from '../../../core/data-models/code-description';
import { DynamicsHoldStatus } from '../../../core/enums/dynamics-hold-status.enum';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';

@Component({
    selector: 'app-budget-management-container',
    styleUrls: ['./budget-management-container.component.scss'],
    templateUrl: './budget-management-container.component.html'
})
export class BudgetManagementContainerComponent implements OnInit {
    @Input() public payload: BudgetManagementPayload;
    @Output() public localExcelBudgetUpdate = new EventEmitter<[AttachmentModel, File] | null>();
    @Output() public webBudgetPayloadUpdate = new EventEmitter<BudgetRecordPayload<ServiceOperationsBudgetData> | null>();
    public isLoading = true;
    public localExcelBudgetPromise: Promise<File>;
    public excelBudgetName = '';

    get canFinalizeWebBudget(): boolean {
        if (this.payload.isQuote) {
            return false;
        }

        return !ProjectIdUtility.isTemporary(this.payload.recordId) && !ProjectIdUtility.isEmergency(this.payload.recordId);
    }

    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
                private _quoteService: QuoteHttpService,
                private _uploadService: UploadHttpService,
                private _snackBar: MatSnackBar,
                private _budgetHttpService: BudgetHttpService,
                private _projectService: ProjectHttpService,
                private _projectWorkflowService: ProjectWorkflowService,
                private _dialog: MatDialog) { }

    public async ngOnInit(): Promise<void> {
        if (this.payload.useLegacyBudgetTool && this.payload.recordId) {
            this.excelBudgetName = await this._budgetHttpService.getBudgetName(this.payload.recordId, this.payload.isQuote);
        }

        this.isLoading = false;
    }

    public async uploadWebBudget(projectId: string): Promise<boolean> {
        const ok = this.translate.instant('shared.ok');
        const success = this.translate.instant('shared.quote.editor.uploadWebBudgetSuccess');
        const ongoing = this.translate.instant('shared.pleaseWait');
        const action = this.getUploadWebBudgetAction(projectId);
        const data = new ActionProgressReporterOption(action, ongoing, success, null, ok, ok);

        const dialog = this._dialog.open(ActionProgressDialogComponent, {
            data,
            width: '550px',
            height: '120px'
        });

        return await dialog.afterClosed().toPromise();
    }

    private async getUploadWebBudgetAction(projectId: string): Promise<CodeDescription> {
        const success = this.translate.instant('shared.quote.editor.uploadWebBudgetSuccess');
        const failed = this.translate.instant('shared.quote.editor.uploadWebBudgetFailure');
        const failedDynamicsHold = this.translate.instant('shared.quote.editor.uploadWebBudgetFailureDynamicsHold');
        const isDynamicsOnHold = await this._projectService.getDynamicsHoldStatus() === DynamicsHoldStatus.Hold;
        const projectStatus = await this._projectService.getProjectStatus(projectId);

        if (isDynamicsOnHold) {
            return new CodeDescription('400', failedDynamicsHold);
        }

        if (!await this._budgetHttpService.uploadWebBudget(projectId)) {
            return new CodeDescription('400', failed);
        }

        // This is temporary to fix FSW-6699
        if (projectStatus === 18) {
            await this._projectWorkflowService.approveAfterBudgetUpload(projectId, true);
        }

        return new CodeDescription('200', success);
    }

    public async onBudgetFileSelected(budgetFileEntry: FileSystemFileEntry) : Promise<void> {
        if (!this.payload.isQuote) {
            return;
        }

        this.isLoading = true;
        const budgetFile = await new Promise<File>((resolve, _error) => budgetFileEntry.file((file: File) => resolve(file)));
        const attachment = this.getAttachment(budgetFile.name);

        if (!this.payload.recordId) {
            this.localExcelBudgetPromise = Promise.resolve(budgetFile);
            this.excelBudgetName = budgetFile.name;
            this.localExcelBudgetUpdate.emit([attachment, budgetFile]);
        }
        else {
            await this.uploadExcelBudget(attachment, budgetFile);
        }

        this.isLoading = false;
    }

    public async onBudgetDelete(): Promise<void> {
        if (!this.payload.isQuote) {
            return;
        }

        this.isLoading = true;

        if (!this.payload.recordId) {
            this.localExcelBudgetPromise = null;
            this.excelBudgetName = '';
            this.localExcelBudgetUpdate.emit(null);
        }
        else {
            await this.deleteBudget();
        }

        this.isLoading = false;
    }

    private async uploadExcelBudget(attachment: AttachmentModel, file: File): Promise<void> {
        const success = await this._uploadService.uploadQuoteAttachment(this.payload.recordId, attachment, file);

        if (success) {
            this.excelBudgetName = file.name;
        }
        else {
            const failedToUpload = this.translate.instant('shared.quote.editor.failedToUpload');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(`${failedToUpload} ${file.name}`, close);
        }
    }

    private async deleteBudget(): Promise<void> {
        if (await this._quoteService.deleteBudget(this.payload.recordId)) {
            this.excelBudgetName = '';
            this.payload.hasExistingExcelBudget = false;
        }
        else {
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(this.translate.instant('shared.quote.editor.failedToDelete'), close);
        }
    }

    private getAttachment(fileName: string): AttachmentModel {
        const budgetAttachment = new AttachmentModel();
        budgetAttachment.name = fileName;
        budgetAttachment.type = FileType.Budget;
        return budgetAttachment;
    }
}
