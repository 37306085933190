import ProjectRequestBase from './project-request-base';

export default class KpiChangeRequest extends ProjectRequestBase {
    public jobId: number;

    constructor(projectId: string, jobId: number, reason: string) {
        super(projectId, reason);
        this.jobId = jobId;
    }
}
