<dialog-action-card class="invoice-upload-dialog-container"
    [icon]="'verified'"
    [title]="translate.instant('projects.invoice.title')"
    [isLoaded]="true"
    (close)="onClose(false)">

    <div class="invoice-upload-dialog-content" fxLayout="column" fxLayoutGap="15px">

        <invoice-upload
            (invoiceFileSelected)="invoiceFile=$event"
            [hasInvoice]="true"
            [invoiceNumber]="invoiceNumber"
            [draftNumber]="draftNumber"
            [grossAmount]="grossAmount">
        </invoice-upload>

    </div>

    <div class="invoice-upload-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button
            color="primary"
            [disabled]="!enableUpload"
            (click)="onClose()">
            {{ 'projects.invoice.upload' | translate }}
        </button>
    </div>
</dialog-action-card>
