import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import ImageItem from '../../../../core/data-models/job-template-items/image-item';

@Component({
    selector: 'app-edit-drawing',
    styleUrls: ['./edit-drawing.component.scss'],
    templateUrl: './edit-drawing.component.html'
})
export class EditDrawingComponent implements OnChanges {
    @Input() public drawing: ImageItem;
    @Output() public actionClick = new EventEmitter<string>();
    public backgroundImage: SafeStyle;

    public constructor(private sanitizer: DomSanitizer) { }

    public ngOnChanges(): void {
        this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(`url('${this.drawing.baseImageUri}')`);
    }
}
