import TimeInfo from '../time-info';

export default class StockLog {
    public projectId: string;
    public projectName: string;
    public materialCost: number;
    public currency: string;
    public branchCode: string;
    public salesCode: string;
    public timeInfo: TimeInfo;
}
