<dialog-action-card class="swap-project-dialog-container"
    [icon]="'swap_horiz'"
    [title]="translate.instant('projects.swapProjectReasonDialog.title') + options.projectId"
    [isLoaded]="true"
    (close)="onClose(false)">

    <div class="swap-project-dialog-content" fxLayout="column">
        <div *ngIf="options.allowCustomerChange" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <button mat-stroked-button color="primary" (click)="onCustomerSelect()">
                {{ 'projects.swapProjectReasonDialog.changeCustomer' | translate }}
            </button>

            <button *ngIf="newCustomer" mat-stroked-button color="warn" (click)="newCustomer = null">
                {{ 'projects.swapProjectReasonDialog.reset' | translate }}
            </button>
        </div>

        <div *ngIf="newCustomer" class="swap-project-dialog-customer" fxLayout="column" fxLayoutGap="3px">
            <span>{{ 'projects.swapProjectReasonDialog.customerLabel' | translate }} {{ newCustomer.customer.name }} <span>({{ customerId }})</span></span>
            <span>{{ 'projects.swapProjectReasonDialog.siteLabel' | translate }} {{ newCustomer.site.name }} <span>({{ siteId }})</span></span>
            <span>{{ 'projects.swapProjectReasonDialog.billingSiteLabel' | translate }} {{ newCustomer.billingSite.name }} <span>({{ billingSiteId }})</span></span>
        </div>

        <span>{{ 'projects.swapProjectReasonDialog.swapHelpterText' | translate }}</span>
        <span>
            {{ 'projects.swapProjectReasonDialog.process.fragment1' | translate }} 
            <span>{{ 'projects.swapProjectReasonDialog.process.fragment2' | translate }}</span>.
        </span>
    </div>

    <div class="swap-project-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="onClose()">{{ 'projects.swapProjectReasonDialog.proceed' | translate }}</button>
    </div>
</dialog-action-card>
