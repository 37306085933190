<div *ngIf="isLoaded" class="dictionary-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="dictionary-list" fxFlex="20">
        <mat-list>
            <mat-list-item *ngFor="let entry of entries; let i = index">
                <button mat-button
                    [ngClass]="{ 'dictionary-entry-active': entryIndex === i }"
                    class="dictionary-list-button"
                    (click)="entryIndex = i">

                    {{ getLocalizedContent(entry.name) }}
                </button>
            </mat-list-item>
        </mat-list>
    </div>

    <mat-card class="dictionary-entry-card"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="2.5vh"
        fxFlex="45">

        <mat-card-header fxLayout="row" fxLayoutAlign="center center">
            <mat-card-title class="dictionary-entry-card-title">
                <h1>{{ getLocalizedContent(entries[entryIndex].name) }}</h1>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>{{ getLocalizedContent(entries[entryIndex].description) }}</mat-card-content>

        <mat-card-footer *ngIf="entries[entryIndex].imageUrl" fxLayout="row" fxLayoutAlign="center center">
            <img class="dictionary-entry-image" [src]="entries[entryIndex].imageUrl" />
        </mat-card-footer>
    </mat-card>

    <mat-card class="dictionary-language-selection"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="1.5vh"
        fxFlex="15"
        fxFlexAlign="start">

        <mat-card-header fxLayout="row" fxLayoutAlign="center center">
            <h3>{{ 'dictionary.languageSelection.title' | translate }}</h3>
        </mat-card-header>

        <mat-card-content>
            <mat-button-toggle-group #group="matButtonToggleGroup"
                [value]="languageIndex"
                (change)="languageIndex = group.value">

                <mat-button-toggle [value]="0">
                    {{ 'dictionary.languageSelection.options.english' | translate }}
                </mat-button-toggle>

                <mat-button-toggle [value]="1">
                    {{ 'dictionary.languageSelection.options.spanish' | translate }}
                </mat-button-toggle>

                <mat-button-toggle [value]="2">
                    {{ 'dictionary.languageSelection.options.french' | translate }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </mat-card-content>
    </mat-card>
</div>
