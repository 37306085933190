import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import InformationGridRow from '../../../../../../core/data-models/information-grid/information-grid-row';
import InformationGridItem from '../../../../../../core/data-models/information-grid/information-grid-item';
import InformationGridConfig from '../../../../../../core/data-models/information-grid/information-grid-config';
import ProjectBasicInfo from '../../../../../../core/data-models/project-general-info/project-basic-info';
import ProjectSumDto from '../../../../../../core/data-models/dto-models/project-sum-dto';
import BranchReview from '../../../../../../core/data-models/project-review/branch-review';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'merge-project-review-select',
    styleUrls: ['./merge-project-review-select.component.scss'],
    templateUrl: './merge-project-review-select.component.html'
})
export class MergeProjectReviewSelectComponent implements OnInit {

    @Input() projectInfo: ProjectSumDto;
    @Input() reviewSelected: boolean;
    @Input() jobReview: BranchReview;
    @Output() reviewModelChanged = new EventEmitter<UntypedFormGroup>();
    @Output() reviewSelectionChanged = new EventEmitter<boolean>();
    public projectInfoCardConfig: InformationGridConfig<ProjectBasicInfo>;
    public jobReviewFormGroup: UntypedFormGroup;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        public translate: TranslateService) {

        this.jobReviewFormGroup = this._formBuilder.group({
            comment: [''],
            billableHours: [0],
            contactInfo: this._formBuilder.group({
                contactName: [''],
                contactNumber: [''],
                email: ['']
            }),
            checklist: this._formBuilder.group({
                contactInformation: [false],
                findings: [false],
                recommendations: [false],
                photos: [false],
                safety: [false],
                labourReviewedAndApproved: [false],
                isAReRoofQuoteRequired: [false]
            })
        });
    }

    public ngOnInit(): void {

        if (this.jobReview) {
            this.jobReviewFormGroup.setValue({
                comment: this.jobReview.comment,
                billableHours: this.jobReview.billableHours,
                contactInfo: {
                    contactName: this.jobReview.contactInfo?.contactName,
                    contactNumber: this.jobReview.contactInfo?.contactNumber,
                    email: this.jobReview.contactInfo?.email
                },
                checklist: this.jobReview.checklist
            });
        }

        this.reviewModelChanged.emit(this.jobReviewFormGroup);

        if (!this.reviewSelected) {
            this.jobReviewFormGroup.disable();
        }

        this.projectInfoCardConfig = this.createProjectInfoCardConfig();
    }

    public activeReviewChanged(): void {
        this.reviewSelectionChanged.emit(this.reviewSelected);
    }

    private createProjectInfoCardConfig(): InformationGridConfig<ProjectBasicInfo> {
        const projectNameLabel = this.translate.instant('projects.merge.review.projectName');
        const projectIdLabel = this.translate.instant('projects.merge.review.projectID');
        const address1Label = this.translate.instant('projects.merge.review.addressLine1');
        const address2Label = this.translate.instant('projects.merge.review.addressLine2');
        const cityLabel = this.translate.instant('projects.merge.review.city');
        const provinceStateLabel = this.translate.instant('projects.merge.review.provinceState');
        const postalZipLabel = this.translate.instant('projects.merge.review.postalZip');

        const projectName = new InformationGridItem(projectNameLabel, 'projectName', 53);
        const projectId = new InformationGridItem(projectIdLabel, 'projectId', 46.5);
        const address1 = new InformationGridItem(address1Label, 'address1', 53);
        const address2 = new InformationGridItem(address2Label, 'address2', 46.5);
        const city = new InformationGridItem(cityLabel, 'city', 53);
        const state = new InformationGridItem(provinceStateLabel, 'state', 46.5);
        const postalCode = new InformationGridItem(postalZipLabel, 'zip', 26.5);

        const basicInfo = {
            projectName: this.projectInfo.name,
            projectId: this.projectInfo.projectId,
            address1: this.projectInfo.address.addr1,
            address2: this.projectInfo.address.addr2,
            city: this.projectInfo.address.city,
            state: this.projectInfo.address.state,
            country: this.projectInfo.address.country,
            zip: this.projectInfo.address.zip
        } as ProjectBasicInfo;

        const rows = [
            new InformationGridRow([projectName, projectId]),
            new InformationGridRow([address1, address2]),
            new InformationGridRow([city, state]),
            new InformationGridRow([postalCode])
        ];

        return new InformationGridConfig(basicInfo, rows);
    }

}
