import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import CheckboxData from '../../../../../core/data-models/checkbox-data';
import { ProjectRejectReason } from '../../../../../core/enums/project-reject-reason.enum';

@Component({
    selector: 'rejection-reason-dialog',
    styleUrls: ['./rejection-reason-dialog.component.scss'],
    templateUrl: './rejection-reason-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class RejectionReasonDialogComponent implements OnInit {
    public showOther = false;
    public other = '';

    public readonly options: CheckboxData[] = [
        new CheckboxData(ProjectRejectReason.MissingContact),
        new CheckboxData(ProjectRejectReason.MissingDocument),
        new CheckboxData(ProjectRejectReason.MissingPhotoTitle),
        new CheckboxData(ProjectRejectReason.MissingFrontOfBuilding),
        new CheckboxData(ProjectRejectReason.MissingTimeMaterialCost),
        new CheckboxData(ProjectRejectReason.RequiresReRoof),
        new CheckboxData(ProjectRejectReason.RequestByBranch)
    ];

    get canReject(): boolean {
        return this.options.some(_ => _.checked) || this.hasOther;
    }

    get hasOther(): boolean {
        return this.showOther && Boolean(this.other.trim());
    }

    constructor(
        private _dialog: MatDialogRef<RejectionReasonDialogComponent>,
        public translate: TranslateService) { }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
    }

    public onClose(emit = true): void {
        if (!emit) {
            this._dialog.close();

            return;
        }

        const checked = this.options.filter(_ => _.checked);
        const other = this.hasOther ? this.other.trim() : '';
        const items = [...checked.map(_ => _.name), other];
        this._dialog.close(items.filter(Boolean).join(', '));
    }
}
