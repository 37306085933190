import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import HolidayEvent from '../../../../core/data-models/holiday-event';
import HolidayEventEntity from '../../../../core/data-models/kpi-data/holiday-event-entity';
import { HolidayHttpService } from '../../../../core/services/http/holiday-http/holiday-http.service';
import { HolidayEditDialogComponent } from '../holiday-edit-dialog/holiday-edit-dialog.component';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';

@Component({
    selector: 'holiday-calendar',
    styleUrls: ['./holiday-calendar.component.scss'],
    templateUrl: './holiday-calendar.component.html',
    encapsulation: ViewEncapsulation.None
})
export class HolidayCalendarComponent implements OnInit {
    public options: CalendarOptions = {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        selectable: true,
        firstDay: 1,
        events: [],
        locale: '',
        eventClick: this.onCalendarEventClick.bind(this),
        headerToolbar: {
            left: '',
            center: 'prev title next',
            right: 'today'
        }
    };

    private _closeString = '';

    constructor(private _holidayService: HolidayHttpService,
                private _userHttp: UserHttpService,
                private _dialogService: MatDialog,
                private _snackbar: MatSnackBar,
                private _translateService: TranslateService) { }

    public async ngOnInit(): Promise<void> {
        const user = await this._userHttp.getUserInfo(false);
        this.options.locale = user?.language ?? 'en';
        this.options.buttonText = { today: this._translateService.instant('fullCalendar.todayLabel') };
        this._closeString = this._translateService.instant('shared.close');
        await this.loadHolidayEvents();
    }

    public async addHoliday(): Promise<void> {
        await this.openHolidayDialog(null);
    }

    public async refreshCalendar(): Promise<void> {
        await this.loadHolidayEvents();
    }

    public async onCalendarEventClick({ event }: EventClickArg): Promise<void> {
        const holidayEvent: HolidayEvent = {
            ...new HolidayEvent(),
            allDay: event.allDay,
            start: event.start.toUTCString(),
            title: event.title,
            objectId: event.extendedProps.objectId,
            states: event.extendedProps.states
        };

        await this.openHolidayDialog(holidayEvent);
    }

    private async openHolidayDialog(event: HolidayEvent): Promise<void> {
        const dialog = this._dialogService.open(HolidayEditDialogComponent, {
            width: '1000px',
            height: '500px',
            data: event
        });

        const result = await dialog.afterClosed().toPromise();
        if (result) {
            const { data } = result;
            const isAdd = result.action === 'add';
            if (isAdd) {
                await this.sendHolidayToServer(data);
            }
            else {
                await this.deleteHolidayEvent(data.id);
            }

            await this.loadHolidayEvents();
        }
    }

    private async loadHolidayEvents(): Promise<void> {
        const events = await this._holidayService.getHolidayEvents();

        this.options.events = events.map(_ => {
            _.start = _.start.substr(0, 10);
            return _;
        });
    }

    private async sendHolidayToServer(holiday: HolidayEventEntity): Promise<void> {
        const result = await this._holidayService.addHoliday(holiday);
        const events = this.options.events as HolidayEvent[];
        const index = events.findIndex(_ => _.objectId === result.objectId);

        if (index !== -1) {
            const message = this._translateService.instant('admin.kpiSetting.holidayCalendar.updateSuccess');
            this._snackbar.open(message, this._closeString);
        }
    }

    private async deleteHolidayEvent(id: string): Promise<void> {
        const result = await this._holidayService.deleteHoliday(id);
        const message = result?
            this._translateService.instant('admin.kpiSetting.holidayCalendar.deleteSuccess') :
            this._translateService.instant('admin.kpiSetting.holidayCalendar.deleteFailed');

        this._snackbar.open(message, this._closeString);
    }
}
