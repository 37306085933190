import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import CustomReportTemplate from '../../../core/data-models/custom-report-template/custom-report-template';
import { CustomReportTemplateHttpService } from '../../../core/services/http/custom-report-template-http/custom-report-template-http.service';

@Component({
    selector: 'app-report-template-preview-dialog',
    styleUrls: ['./report-template-preview-dialog.component.scss'],
    templateUrl: './report-template-preview-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ReportTemplatePreviewDialogComponent implements OnInit {
    public isLoaded = false;
    public pdfUrl = '';

    constructor(private _customReportTemplateService: CustomReportTemplateHttpService,
        private _dialog: MatDialogRef<ReportTemplatePreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public template: CustomReportTemplate) { }

    public async ngOnInit(): Promise<void> {
        const pdfBlob = await this._customReportTemplateService.getCustomReportPreview(this.template.content);
        this.pdfUrl = pdfBlob ? URL.createObjectURL(pdfBlob) : '';
        this.isLoaded = true;
    }

    public onClose(): void {
        this._dialog.close();
    }
}
