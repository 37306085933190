import CustomerSiteDto from '../customer-site-dto';

export default class SiteDisplaySummary {

    shipToId: string;
    name: string;
    address: string;
    siteAttention: string;
    siteEmail: string;
    isBillingSite: string;
    geoCode: string;

    public createSiteDisplaySummary(site: CustomerSiteDto): void {
        this.name = site.name;
        this.shipToId = site.shipToId.startsWith('new')? 'Pending...': site.shipToId;
        this.siteAttention = site.siteAttention;
        this.isBillingSite = site.isBillInfo? 'Yes': 'No';
        this.geoCode = site.geoCode;
        this.siteEmail = site.siteEmail;

        let address2 = ',';
        if (site.address2 && site.address2 !== '') {
            address2 = ` ${site.address2},`;
        }

        this.address = `${site.address1}${address2} ${site.city},`+
                `${site.country}, ${site.state}, ${site.zip}`;
    }
}
