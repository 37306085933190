<form class="quality-control-fees-section-container" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh">
    <div class="quality-control-fees-option" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2.5vh">
        <mat-checkbox [formControl]="qualityControlFeesSection.get('hasDollarCost')" [disabled]="readonly">
            {{ 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.qualityControlFeesSection.dollarRateToggle' | translate }}
        </mat-checkbox>

        <mat-form-field>
            <mat-label>{{ 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.qualityControlFeesSection.rate' | translate }}</mat-label>
            <input type="number" matInput [formControl]="qualityControlFeesSection.get('totalDollarCost')" [readonly]="readonly" />

            <mat-error *ngIf="qualityControlFeesSection.get('totalDollarCost').errors">
                * {{ 'flynnBudgetTool.budgetEstimation.errors.currencyFormat' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.qualityControlFeesSection.total' | translate }}</mat-label>
            <input type="text" matInput [value]="qualityControlFeesSection.get('totalDollarCost')?.value | number: '0.0-0'" readonly />
        </mat-form-field>
    </div>

    <div class="quality-control-fees-option" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2.5vh">
        <mat-checkbox [formControl]="qualityControlFeesSection.get('hasPercentageCost')" [disabled]="readonly">
            {{ 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.qualityControlFeesSection.dollarPercentageToggle' | translate }}
        </mat-checkbox>

        <mat-form-field>
            <mat-label>{{ 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.qualityControlFeesSection.rate' | translate }}</mat-label>

            <mat-select [formControl]="qualityControlFeesSection.get('percentageCost.rate')" [disabled]="readonly">
                <mat-option *ngIf="!qualityControlFeesSection.get('hasPercentageCost').value" [value]="0">0.00%</mat-option>

                <mat-option *ngFor="let rate of sortedRates" [value]="rate">
                    {{ rate | percent: '0.2-2' }}
                </mat-option>
            </mat-select>

            <mat-error *ngIf="qualityControlFeesSection.get('percentageCost.rate').errors?.required">
                * {{ 'flynnBudgetTool.budgetEstimation.errors.requiredField' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.qualityControlFeesSection.total' | translate }}</mat-label>
            <input type="text" matInput [value]="qualityControlFeesSection.get('percentageCost.totalCost')?.value | number: '0.0-0'" readonly />
        </mat-form-field>
    </div>
</form>
