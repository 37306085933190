import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Branch from '../../../core/data-models/branch';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { FallHazardHttpService } from '../../../core/services/http/fall-hazard-http/fall-hazard-http.service';
import FallHazardReport from '../../../core/data-models/data-report/fall-hazard-report';
import ReportQuery from '../../../core/data-models/data-report/report-query';
import { process } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';

@Component({
    selector: 'app-fall-hazard-report',
    styleUrls: ['./fall-hazard-report.component.scss'],
    templateUrl: './fall-hazard-report.component.html'
})
export class FallHazardReportComponent implements OnInit {
    @Input() public branches: Branch[];
    @ViewChild(GridComponent) grid: GridComponent;
    public gridViewData: FallHazardReport[] = [];
    public nonSubContractJobTypes: string[] = [];
    public loading: boolean;
    public gridPageSize = 15;
    public query: ReportQuery;

    public constructor(private newRelicUtility: NewRelicUtilityService,
        private _fallHazardHttpService: FallHazardHttpService,
        private _userHttpService: UserHttpService,
        private _salesCodeConfigService: SalesCodeConfigService) {
        this.query = new ReportQuery('', '', '', null, null, '');
        this.allData = this.allData.bind(this);
    }

    public async ngOnInit(): Promise<void> {
        this.nonSubContractJobTypes = await this._salesCodeConfigService.getNonSubcontractSalesCodes();
        this.query.branch = (await this._userHttpService.getUserInfo()).branch;
    }

    public async loadReport(): Promise<void> {
        this.newRelicUtility.addPageAction('ReportType', 'fallHazard', 'ReportAccess', this.query);

        this.loading = true;
        this.gridViewData = await this._fallHazardHttpService.getFallHazardReport(this.query);
        this.gridPageSize = this.grid.pageSize;
        this.grid.pageChange.emit({ skip: 0, take: this.gridPageSize });
        this.loading = false;
    }

    public allData(): ExcelExportData {
        const result: ExcelExportData = {
            data: process(this.gridViewData, { }).data
        };

        return result;
    }

}
