/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewEncapsulation, ViewChild, Output, EventEmitter, OnDestroy, OnChanges, OnInit } from '@angular/core';
import DictionaryEntry from '../../../core/data-models/dictionary-entry';
import DictionaryEntryLanguage from '../../../core/data-models/dictionary-entry-language';
import { TranslateService } from '@ngx-translate/core';
import { BryntumSchedulerProComponent } from '@bryntum/schedulerpro-angular';
import { ColumnStore, SchedulerPro, StringHelper } from '@bryntum/schedulerpro';
import { calendarViewConfig } from './calendar-view.config';
import Branch from '../../../core/data-models/branch';
import Crew from '../../../core/data-models/crew';
import { ScheduleBoardHttpService } from '../../../core/services/http/schedule-board-http/schedule-board-http.service';
import * as moment from 'moment-timezone';
import { ScheduleBoardService } from '../schedule-board.service';

@Component({
    selector: 'calendar-view',
    styleUrls: ['./calendar-view.component.scss'],
    templateUrl: './calendar-view.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CalendarViewComponent implements OnDestroy, OnChanges, OnInit {
    @ViewChild(BryntumSchedulerProComponent) schedulerProComponent: BryntumSchedulerProComponent;
    @Input() public activeProjectId = '';
    @Input() public isLoading = false;
    @Output() public projectScheduled: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public branch: Branch;
    public entryIndex = 0;
    public languageIndex = 0;
    public entries: DictionaryEntry[] = [];
    public isLoaded = false;
    private schedulerPro: SchedulerPro;
    public schedulerProConfig = calendarViewConfig;
    //eslint-disable-next-line
    public crewList: Crew[];
    public currStartDate = moment().startOf('week');
    public currEndDate = moment().endOf('week');
    public showScheduleModal = false;
    static scheduleStartDateDesc = '';
    static scheduleEndDateDesc = '';
    static scheduleProjectStatus = '';
    public eventToolTipFeature;

    // eslint-disable-next-line max-params
    constructor(
        private _scheduleBoardHttpService: ScheduleBoardHttpService,
        public translate: TranslateService,
        private _scheduleBoardService: ScheduleBoardService) {
    }

    async ngOnChanges() {
        if (this.isLoading === false) {
            await this.loadSchedulerComponentData();
        }
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventRenderer = ({ eventRecord }: { eventRecord: any }): string => {
        return `
            <div class="info">
                <div class="name">${StringHelper.encodeHtml(eventRecord.projectName)}</div>
                <div class="desc">${StringHelper.encodeHtml(eventRecord.desc)}</div>
            </div>
        `;
    };

    tooltipTemplate = ({ eventRecord }) => {
        return `<dl>
        ${eventRecord.get('name') && `<dd>${eventRecord.name}</dd>`}
        ${eventRecord.get('desc') && `<dd>${eventRecord.desc}</dd>`}
        ${eventRecord.get('startDate') && `<dd>${this.translate.instant('calendarView.startDate')}: ${this.transformTime(eventRecord.originalData.startDate, 'YYYY-MM-DD h:mm:ss A z')}</dd>`}
        ${eventRecord.get('endDate') && `<dd>${this.translate.instant('calendarView.endDate')}: ${this.transformTime(eventRecord.originalData.endDate, 'YYYY-MM-DD h:mm:ss A z')}</dd>`}
        ${eventRecord.get('projectStatusDesc') && `<dd>${this.translate.instant('calendarView.projectStatus')}: ${eventRecord.projectStatusDesc}</dd>`}
    </dl>`;
    };

    public ngOnInit() {
        this.eventToolTipFeature = {
            // Tooltip configs can be used here
            align: 'l-r', // Align left to right,
            template: this.tooltipTemplate
        };
        this.isLoading = true;
    }

    ngAfterViewInit(): void {
        // SchedulerPro instance
        this.schedulerPro = this.schedulerProComponent.instance;
    }

    public async loadSchedulerComponentData() {
        this.isLoading = true;
        const crewLabel: string = this.translate.instant('queueView.crews');
        const scheduleBoardData = await this._scheduleBoardHttpService.getCalendarData(this.branch?.name);
        this.schedulerPro.timeZone = this.branch?.geoLocation?.timeZone?.timeZoneId;
        (this.schedulerPro.columns as ColumnStore).get('name').text = crewLabel;
        this.schedulerPro.readOnly = false;
        this.schedulerPro.project.inlineData = scheduleBoardData;
        this.setCalendarDateToNow();
        this.isLoading = false;
    }


    public getLocalizedContent(contents: DictionaryEntryLanguage[]): string {
        if (!contents.length) {
            return '';
        }
        // always return default translation when selected translation is not available
        return contents[this.languageIndex]?.content ?? contents[0].content;
    }

    ngOnDestroy(): void {
        this.schedulerPro?.destroy();
    }

    public onMoveCalendarDateBack() {
        this.currStartDate = this.currStartDate.subtract(1, 'weeks').startOf('week');
        this.currEndDate = this.currEndDate.subtract(1, 'weeks').endOf('week');
        this.schedulerPro.startDate = this.currStartDate.toDate();
        this.schedulerPro.endDate = this.currEndDate.toDate();
    }

    public setCalendarDateToNow() {
        this.currStartDate = moment().startOf('week');
        this.currEndDate = moment().endOf('week');
        this.schedulerPro.startDate = this.currStartDate.toDate();
        this.schedulerPro.endDate = this.currEndDate.toDate();
    }

    public onMoveCalendarDateForward() {
        this.currStartDate = this.currStartDate.add(1, 'weeks').startOf('week');
        this.currEndDate = this.currEndDate.add(1, 'weeks').endOf('week');
        this.schedulerPro.startDate = this.currStartDate.toDate();
        this.schedulerPro.endDate = this.currEndDate.toDate();
    }

    public async onEditSchedule(e : {[key:string] : any}) {
        this.showScheduleModal = true;
        const data = e.eventRecord.originalData;
        await this.showEditScheduleModal(data, null, null, true);
    }

    public async onDragDropSchedule(e : {[key:string] : any}) {
        const contextData = e.context;
        const originalData = e.context.eventRecords[0].originalData;
        const newData = {
            crewId: contextData.newResource.originalData.id,
            startDate: contextData.dropData.events[0].startDate,
            endDate: contextData.dropData.events[0].endDate
        };
        this.showScheduleModal = true;
        e.context.async = true;
        await this.showEditScheduleModal(originalData, contextData, newData, true);
    }

    public async showEditScheduleModal(originalData: any, _context?: any, newData?: any, isEditingSchedule = false) {
        const { crewId, startDate, endDate, note, scheduleId, name } = originalData;
        if (this.showScheduleModal === true) {
            const scheduleOptions = await this._scheduleBoardHttpService.getProjectScheduleDialogOptions(name);
            // prefilling the options to the data from the calendar view
            scheduleOptions.isEditingSchedule = isEditingSchedule;
            scheduleOptions.defaultCrewId = newData?.crewId || crewId;
            scheduleOptions.startDate = newData?.startDate ||startDate;
            scheduleOptions.endDate = newData?.endDate || endDate;
            scheduleOptions.note = note;
            scheduleOptions.scheduleId = scheduleId;
            scheduleOptions.branchInfo = this.branch;
            if (await this._scheduleBoardService.openProjectScheduleDialog(scheduleOptions)) {
                this.projectScheduled.emit(true);
                if (_context) {
                    _context.finalize(true);
                }
                await this.loadSchedulerComponentData();
            }
            else {
                this.showScheduleModal = false;
                if (_context) {
                    _context.finalize(false);
                }
            }
        }
    }

    private transformTime(date: string, format: string): string {
        return moment.tz(date, this.branch?.geoLocation?.timeZone?.timeZoneId).format(format);
    }

    public getTimeZoneFromBranch() {
        return moment.tz(new Date(), this.branch?.geoLocation?.timeZone?.timeZoneId).format('z');
    }
}