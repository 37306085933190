import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

import { ServiceRatesEditorService } from '../service-rates-editor.service';

@Component({
    selector: 'app-service-rates-questions-options',
    styleUrls: ['./service-rates-questions-options.component.scss'],
    templateUrl: './service-rates-questions-options.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRatesQuestionsOptionsComponent {
    @Input() public form: UntypedFormGroup;
    @Input() public isReadonly: boolean;

    constructor(private _serviceRatesEditorService: ServiceRatesEditorService) { }

    get questions(): UntypedFormArray {
        return this.form.controls.leadQuestions as UntypedFormArray;
    }

    public addQuestionRow(): void {
        const control = this._serviceRatesEditorService.createTypeRecordField();
        this.questions.push(control);
    }
}
