<div class="grid-dropdown-container" fxLayout="column" fxLayoutAlign="start">
    <div class="grid-dropdown-title-bar"
         [ngStyle]="{ height: title ? '20px': '0' }"
         fxLayout="row"
         fxLayoutAlign="start center">

        <div [ngClass]="errorMessageClass" *ngIf="error">* {{ error }}</div>

        <ng-container *ngIf="title">
            <span class="grid-dropdown-title">{{ title }}</span>

            <grid-item-actions *ngIf="!isEditModeOn"
                               [content]="displayContent | textWithPlaceholder"
                               [canEnableEdit]="canEnableEdit"
                               (editEnabled)="enableContentEdit()">
            </grid-item-actions>
        </ng-container>
    </div>

    <div class="grid-dropdown-content"
         [ngStyle]="{ height: title ? 'calc(100% - 20px)': '100%' }"
         fxLayout="row"
         fxLayoutAlign="start center">

        <input type="text"
               class="grid-edit-box"
               *ngIf="!isEditModeOn"
               #editBox
               [ngClass]="inputBoxClasses"
               [disabled]="!isEditModeOn"
               [(ngModel)]="editedContent"
               [maxlength]="editOptions?.maxLength || 60"
               [placeholder]="editOptions?.placeholder || ''"
               (ngModelChange)="contentEdit.emit($event)"
               (blur)="onContentUpdate()"
               (keyup.esc)="onReset()"
               (keyup.enter)="onContentUpdate()"
               [mask]="editOptions?.inputMask" />

        <select #editBox
                *ngIf="isEditModeOn"
                [ngClass]="inputBoxClasses"
                [disabled]="!isEditModeOn"
                [(ngModel)]="editedContent"
                (ngModelChange)="contentEdit.emit($event)"
                (blur)="onContentUpdate()"
                (change)="onContentUpdate()"
                (keyup.esc)="onReset()"
                (keyup.enter)="onContentUpdate()">
            <option *ngFor="let option of options" [value]="option.key">{{ option.value }}</option>
        </select>

        <div
             class="grid-display-box"
             #displayBox
             [ngClass]="displayBoxClasses"
             [matTooltip]="tooltip"
             matTooltipPosition="above"
             (click)="enableContentEdit()">

            {{ displayContent | textWithPlaceholder }}
        </div>
    </div>
</div>
