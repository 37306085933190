import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { FileSystemFileEntry } from 'ngx-file-drop';

import BranchReview from '../../../data-models/project-review/branch-review';
import ProcessorReview from '../../../data-models/project-review/processor-review';
import ProjectReviewOptions from '../../../data-models/project-review/project-review-options';
import CodeDescription from '../../../data-models/code-description';
import JsonUtility from '../../utility/json-utility.service';
import { NewRelicUtilityService } from '../../utility/new-relic-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectReviewHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/review`;
    private readonly _permissionErrorKey = 'core.httpServices.projectReview.permissionError';
    private readonly _internalErrorKey = 'core.httpServices.projectReview.internalError';

    constructor(private _translate: TranslateService,
        private _http: HttpClient,
        private _newrelicService: NewRelicUtilityService) { }

    public async executeNonReviewAction(projectId: string, action: string, workflowCode: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/projects/${projectId}/${action}/${workflowCode}`;
            await this._http.post(endpoint, null).toPromise();

            return new CodeDescription('200', '');
        }
        catch (error) {
            await this._newrelicService.addErrorEvent(error);
            const { status, error: statusText } = error as HttpErrorResponse;
            const permissionError = this._translate.instant(this._permissionErrorKey);
            const internalError = this._translate.instant(this._internalErrorKey);

            switch (status) {
                case 400: case 403:
                    return new CodeDescription(String(status), statusText);
                case 406:
                    return new CodeDescription(String(status), permissionError);
                default:
                    return new CodeDescription('500', internalError);
            }
        }
    }

    public async executeBranchReviewAction(action: string, review: BranchReview, workflowCode: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/job/${action}/${workflowCode}`;
            await this._http.post(endpoint, review).toPromise();

            return new CodeDescription('200', '');
        }
        catch (error) {
            await this._newrelicService.addErrorEvent(error);
            const { status, error: statusText } = error as HttpErrorResponse;
            const permissionError = this._translate.instant(this._permissionErrorKey);
            const internalError = this._translate.instant(this._internalErrorKey);

            switch (status) {
                case 400: case 403:
                    return new CodeDescription(String(status), statusText);
                case 406:
                    return new CodeDescription(String(status), permissionError);
                default:
                    return new CodeDescription('500', internalError);
            }
        }
    }

    public async executeProcessorReviewAction(
        action: string,
        options: ProjectReviewOptions,
        workflowCode: string
    ): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/project/${action}/${workflowCode}`;
            await this._http.post(endpoint, options).toPromise();

            return new CodeDescription('200', '');
        }
        catch (error) {
            await this._newrelicService.addErrorEvent(error);
            const { status, error: statusText } = error as HttpErrorResponse;
            const permissionError = this._translate.instant(this._permissionErrorKey);
            const internalError = this._translate.instant(this._internalErrorKey);

            switch (status) {
                case 400: case 403:
                    return new CodeDescription(String(status), statusText);
                case 406:
                    return new CodeDescription(String(status), permissionError);
                default:
                    return new CodeDescription('500', internalError);
            }
        }
    }

    public async processProject(
        options: ProjectReviewOptions,
        invoiceFile: FileSystemFileEntry,
        workflowCode: string
    ): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/project/process/${workflowCode}`;
            const formData = new FormData();
            formData.append('reviewOptions', JSON.stringify(options));

            if (options.processWithInvoice) {
                const fileBlob = await new Promise(resolve => invoiceFile.file(resolve)) as Blob;
                formData.append('invoice', fileBlob, invoiceFile.name);
            }

            await this._http.post(endpoint, formData).toPromise();

            return new CodeDescription('200', '');
        }
        catch (error) {
            const { status, error: statusText } = error as HttpErrorResponse;

            return new CodeDescription(String(status), statusText);
        }
    }

    public async addNoBudgetReason(projectId: string, reason: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/job/explanation/${projectId}`;
            await this._http.post(endpoint, { explanation: reason }).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async isBillableHoursRequired(customerId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/IncludeBillableHours/${customerId}`;

            return await this._http.get<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getBranchReview(projectId: string): Promise<BranchReview> {
        try {
            const endpoint = `${this._api}/branch/${projectId}`;
            const data = await this._http.get<BranchReview>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async updateBranchReview(review: BranchReview): Promise<boolean> {
        try {
            return await this._http.put<boolean>(`${this._api}/branch`, review).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getProcessorReview(projectId: string): Promise<ProcessorReview> {
        try {
            const endpoint = `${this._api}/processor/${projectId}`;
            const data = await this._http.get<ProcessorReview>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async updateProcessorReview(review: ProcessorReview): Promise<boolean> {
        try {
            return await this._http.put<boolean>(`${this._api}/processor`, review).toPromise();
        }
        catch {
            return false;
        }
    }

    public async isBudgetUploaded(projectId: string): Promise<boolean> {
        try {
            return await this._http.get<boolean>(`${this._api}/isbudgupload/${projectId}`).toPromise();
        }
        catch {
            return false;
        }
    }

}
