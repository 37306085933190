import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import MergedProjectData from '../../../../../core/data-models/merged-project-data';
import ProjectGeneralInfo from '../../../../../core/data-models/project-general-info/project-general-info';
import ActionProgressReporterOption from '../../../../../core/data-models/action-progress-reporter-option';
import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';
import { ActionProgressDialogComponent } from '../../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';

@Component({
    selector: 'unmerge-project-dialog',
    styleUrls: ['./unmerge-project-dialog.component.scss'],
    templateUrl: './unmerge-project-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UnmergeProjectDialogComponent {
    public generalInfo: ProjectGeneralInfo;
    public projectDetails = false;
    public isUnmerging = false;
    public selectedProject = '';
    public projectToShow = '';

    constructor(private _projectService: ProjectHttpService,
                private _dialog: MatDialog,
                private _dialogRef: MatDialogRef<UnmergeProjectDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {activeProjectId: string, mergedProjects: MergedProjectData[]},
                public translate: TranslateService) { }

    public selectProject(projectId: string): void {
        this.selectedProject = this.selectedProject !== projectId ? projectId:'';
    }

    public getColor(projectId: string): string {
        return this.selectedProject === projectId ? 'var(--secondary-colors-lightest)' : '';
    }

    public async showProject(projectId: string): Promise<void> {
        this.projectToShow = projectId;
        this.generalInfo = await this._projectService.getMergedProjectGeneralInfo(this.projectToShow);
        this._dialogRef.updateSize('1200px', '800px');
        this.projectDetails = true;
    }

    public back(): void {
        this._dialogRef.updateSize('800px', '450px');
        this.projectDetails = false;
    }

    public async unmerge(): Promise<void> {
        this.isUnmerging = true;
        const action = this._projectService.unmergeProjects(this.selectedProject, this.data.activeProjectId);
        const ongoingText = this.translate.instant('projects.unmerge.dialog.ongoing');
        const successText = this.translate.instant('projects.unmerge.dialog.success');
        const errorText = this.translate.instant('projects.unmerge.dialog.error');
        const successConfirmText = this.translate.instant('shared.ok');
        const errorConfirmText = this.translate.instant('shared.gotIt');
        const data = new ActionProgressReporterOption(action, ongoingText, successText, errorText, successConfirmText, errorConfirmText);
        const dialog = this._dialog.open(ActionProgressDialogComponent, { data, width: '550px', height: '120px' });

        if (await dialog.afterClosed().toPromise()) {
            this.onClose(true);
        }
        else {
            this.isUnmerging = false;
        }
    }

    public onClose(unmerged = false): void {
        this._dialogRef.close(unmerged);
    }
}
