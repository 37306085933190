<div class="invoice-management-container">
    <div layout="row">
        <label>{{'shared.labelCanada' | translate}}</label>
    </div>

    <div layout="row">
        <div fxLayout="column">
            <mat-form-field class="invoice-management-select">
                <mat-label>{{'admin.invoiceManagement.trade' | translate}}</mat-label>
                <mat-select [(value)]="invoiceFormat.canada.trade" [compareWith]="compareFunction">
                  <mat-option *ngFor="let code of invoiceCodesCA" [value]="code">
                    {{ code.codeValue }} - {{ code.codeValueDesc }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="column">
            <mat-form-field class="invoice-management-select">
                <mat-label>{{'admin.invoiceManagement.strtgc' | translate}}</mat-label>
                <mat-select [(value)]="invoiceFormat.canada.strtgc" [compareWith]="compareFunction">
                  <mat-option *ngFor="let code of invoiceCodesCA" [value]="code">
                    {{ code.codeValue }} - {{ code.codeValueDesc }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div layout="row">
        <label>{{'shared.labelUS' | translate}}</label>
    </div>

    <div layout="row">
        <div fxLayout="column">
        <mat-form-field class="invoice-management-select">
            <mat-label>{{'admin.invoiceManagement.trade' | translate}}:</mat-label>
            <mat-select [(value)]="invoiceFormat.uS.trade" [compareWith]="compareFunction">
              <mat-option *ngFor="let code of invoiceCodesUS" [value]="code">
                {{ code.codeValue }} - {{ code.codeValueDesc }}
              </mat-option>
            </mat-select>
        </mat-form-field>
        </div>

        <div fxLayout="column">
            <mat-form-field class="invoice-management-select">
                <mat-label>{{'admin.invoiceManagement.strtgc' | translate}}:</mat-label>
                <mat-select [(value)]="invoiceFormat.uS.strtgc" [compareWith]="compareFunction">
                  <mat-option *ngFor="let code of invoiceCodesUS" [value]="code">
                    {{ code.codeValue }} - {{ code.codeValueDesc }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div layout="row" fxLayoutAlign="start center">
        <button mat-raised-button color="primary" (click)="updateInvoiceFormat()">{{ 'shared.save' | translate }}</button>
    </div>
</div>
