import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'disclaimer-settings',
    styleUrls: ['./disclaimer-settings.component.scss'],
    templateUrl: './disclaimer-settings.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DisclaimerSettingsComponent {
    public selectedIndex = 0;

    constructor(public translate: TranslateService) { }
}
