import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FileSystemFileEntry } from 'ngx-file-drop';

import ProcessInvoiceData from '../../../../../core/data-models/process-invoice-data';
import { TranslateService } from '@ngx-translate/core';
import { SalesCodeConfigService } from '../../../../../core/services/sales-code-config/sales-code-config.service';
import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';
import { DynamicsHoldStatus } from '../../../../../core/enums/dynamics-hold-status.enum';
import ProjectIdUtility from '../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import { FeatureFlagsHttpService } from '../../../../../core/services/http/feature-flags-http/feature-flags-http.service';
import { FeatureFlagsApi } from '../../../../../core/enums/feature-flags-api.enum';
import { ValidatorService } from '../../../../../core/services/validator-service/validator-service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'process-project-dialog',
    styleUrls: ['./process-project-dialog.component.scss'],
    templateUrl: './process-project-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProcessProjectDialogComponent implements OnInit {
    public invoiceNumber: UntypedFormControl;
    public draftNumber: UntypedFormControl;
    public grossAmount: UntypedFormControl;
    public transferId: UntypedFormControl;
    public invoiceFile: FileSystemFileEntry = null;
    public hasInvoice = true;
    public isTransfer = false;
    public isDynamicsOnHold = false;
    public isReady = false;
    public useErpInvoice: boolean;

    // eslint-disable-next-line max-params
    constructor(private _dialog: MatDialogRef<ProcessProjectDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public projectId: string,
                private _translate: TranslateService,
                private _salesCodeConfig: SalesCodeConfigService,
                private _projectHttp: ProjectHttpService,
                private _featureFlagHttp: FeatureFlagsHttpService,
                private _validatorService: ValidatorService,
                private _snackBar: MatSnackBar) { }

    private _processLabel: string;
    private _isLeakCall = false;

    private async setUseErpInvoiceFromFeatureFlagService(): Promise<boolean> {
        try {
            const branchCode = ProjectIdUtility.getBranchCode(this.projectId);
            this.useErpInvoice = await this._featureFlagHttp.evaluateFlagByBranch(FeatureFlagsApi.UseErpInvoice, branchCode);
            return this.useErpInvoice !== null;
        }
        catch {
            return false;
        }
    }

    get showOnHoldWarning(): boolean {
        return this.isDynamicsOnHold && this.hasInvoice;
    }

    get title(): string {
        return `${this._processLabel} ${this.projectId}`;
    }

    get fileName(): string {
        return this.invoiceFile?.name ?? this._translate.instant('projects.processProjectDialog.noFileSelected');
    }

    get showTransfer(): boolean {
        return this._isLeakCall && !this.hasInvoice;
    }

    get includeInvoice(): boolean {
        return !this.transferId.value && this.invoiceNumber.valid && this.draftNumber.valid && Boolean(this.invoiceFile);
    }

    get enableProcess(): boolean {
        const isValidFile = this.invoiceNumber.valid && this.draftNumber.valid && this.invoiceFile;
        const isValidInvoice = !this.hasInvoice || isValidFile && !this.isDynamicsOnHold;
        const isValidTransfer = !this.isTransfer || this.transferId.valid;
        const isValidAmount = this.useErpInvoice || this.grossAmount?.valid;
        return isValidInvoice && isValidTransfer && isValidAmount;
    }

    public async ngOnInit(): Promise<void> {
        this._dialog.disableClose = true;
        if (!await this.setUseErpInvoiceFromFeatureFlagService()) {
            this._snackBar.open(this._translate.instant('projects.processProjectDialog.errGettingFeatureFlag'), this._translate.instant('snackbars.ok'));
            this.onClose(false);

            return;
        }
        this.invoiceNumber = this.useErpInvoice ?
            new UntypedFormControl('', [Validators.required, Validators.pattern(/\w+/)]) :
            new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-9]+$/)]);
        this.draftNumber = this.useErpInvoice ?
            new UntypedFormControl('', [Validators.required, Validators.pattern(/^\w{10,}/)]) :
            new UntypedFormControl('', [Validators.pattern(/^[a-zA-Z0-9]+$/)]);
        this.grossAmount = this.useErpInvoice ?
            null :
            new UntypedFormControl('', [Validators.required, this._validatorService.decimalValidator(2, 0)]);
        this.transferId = new UntypedFormControl('', [Validators.required, Validators.pattern(/\w+/)]);
        this._processLabel = this._translate.instant('projects.processProjectDialog.process');
        this._isLeakCall = this._salesCodeConfig.isLeakCall(this.projectId);
        this.isDynamicsOnHold = await this._projectHttp.getDynamicsHoldStatus() === DynamicsHoldStatus.Hold;
        this.isReady = true;
        if (!this.useErpInvoice) {
            this.hasInvoice = true;
        }
    }

    public onClose(emit = true): void {
        if (!emit) {
            this._dialog.close();
        }
        else if (this.enableProcess) {
            const data: ProcessInvoiceData = {
                invoiceNumber: this.invoiceNumber.value,
                draftNumber: this.draftNumber.value,
                transferId: this.transferId.value,
                invoiceFile: this.invoiceFile,
                grossAmount: this.grossAmount?.value,
                includeInvoice: this.includeInvoice
            };

            this._dialog.close(data);
        }
    }
}
