import { Component, Input, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import Branch from '../../../../core/data-models/branch';
import QuoteSummary from '../../../../core/data-models/summary-info/quote-summary';
import { BranchHttpService } from '../../../../core/services/http/branch-http/branch-http.service';
import { QuoteStatusService } from '../../../../core/services/quote-status/quote-status.service';
import { CommonPlaceholder } from '../../../../core/enums/common-placeholder.enum';
import CustomerUtility from '../../../../core/services/utility/customer-utility/customer-utility.service';

@Component({
    selector: 'quote-summary-card',
    styleUrls: ['./quote-summary-card.component.scss'],
    templateUrl: './quote-summary-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteSummaryCardComponent implements OnInit {
    @Input() public isActive = false;
    @Input() public summary: QuoteSummary;
    public status: string;
    public branches: Branch[] = [];

    constructor(private _branchHttpService: BranchHttpService,
                private _quoteStatusService: QuoteStatusService,
                private _changeDetector: ChangeDetectorRef,
                private _translate: TranslateService) { }

    get isQQQ(): boolean {
        return /^qqq/i.test(this.summary.originProjectId ?? '');
    }

    get customerId(): string {
        const { customerId } = this.summary;
        const pending = this._translate.instant('shared.quote.summary.pending');

        return CustomerUtility.isConfirmedCustomer(customerId) ? customerId : pending;
    }

    get estimator(): string {
        return this.summary.estimator === CommonPlaceholder.TBD ? this._translate.instant('shared.toBeDetermined') : this.summary.estimator;
    }

    get quoteId(): string {
        const { quoteId, originProjectId } = this.summary;
        const suffix = originProjectId ? ` ${this._translate.instant('shared.from')} ${originProjectId}` : '';

        return quoteId + suffix;
    }

    public async ngOnInit(): Promise<void> {
        this.branches = await this._branchHttpService.getAllBranches();
        this.status = await this._quoteStatusService.getStatusTranslationByUserLanguage(this.summary.status);
        this._changeDetector.markForCheck();
    }
}
