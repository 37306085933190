import { Component, OnInit, Input } from '@angular/core';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import ProjectHistory from '../../../core/data-models/project-history';

@Component({
    selector: 'site-project-history',
    templateUrl: './site-project-history.component.html',
    styleUrls: ['./site-project-history.component.scss']
})

export class SiteProjectHistoryComponent implements OnInit {
    @Input() public activeQuoteId = '';
    public gridViewData: ProjectHistory[] = [];
    private _isLoaded = false;

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    public constructor(private _projectService: ProjectHttpService) { }

    public async ngOnInit(): Promise<void> {
        this.gridViewData = await this._projectService.getProjectHistoryByQuoteId(this.activeQuoteId);
        this._isLoaded = true;
    }
}
