<div class="user-avatar-card-container"
    [ngStyle]="containerStyle"
    [matTooltip]="fullName"
    matTooltipPosition="above"
    fxLayout="row"
    fxLayoutAlign="center center">

    <span>{{ initials }}</span>

    <div *ngIf="allowCopy"
        class="user-avatar-card-copy-button"
        #copyText
        fxLayout="column"
        fxLayoutAlign="center center">

        <div class="user-avatar-card-copy-tooltip" fxLayout="row" fxLayoutAlign="center center">
            <span>{{ 'shared.copied' | translate }}</span>
        </div>

        <button mat-icon-button (click)="onCopy(copyText)" fxLayout="row" fxLayoutAlign="center center">
            <mat-icon fontSet="material-icons-outlined" [cdkCopyToClipboard]="fullName">
                file_copy
            </mat-icon>
        </button>
    </div>
</div>
