import Crew from '../crew';

export default class BasicRedispatchInfo {
    public expectedDays: number;
    public jobType: string;
    public continueLeakCall: boolean;
    public notes: string;
    public notToExceedAmount: number;
    public crew: Crew;
    public drawingRequired: boolean;
    public overNightSealRequired: boolean;
    public siteContact: string;
    public contactPhone: string;
}
