import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
    providedIn: 'root'
})
export class IconRegistryService {

    constructor(private _sanitizer: DomSanitizer, private _registry: MatIconRegistry) { }

    public registerSvg(name: string, url: string): void {
        const trustedUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url);
        this._registry.addSvgIcon(name, trustedUrl);
    }
}
