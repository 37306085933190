<dialog-action-card [icon]="'call_merge'" [title]="translate.instant('projects.merge.dialog.title')" [isLoaded]="isLoaded" (close)="onClose()">
    <div *ngIf="!isSourceProjectInactive" fxLayout fxLayoutAlign="center center"
        class="project-merge-error-message-container">
        <div fxLayout fxLayoutAlign="center center">
            <h4 class="project-merge-error-message-text">
                {{ 'projects.merge.dialog.sourceProjFragment1' | translate }}
                <br />
                {{ 'projects.merge.dialog.sourceProjFragment2' | translate }}
            </h4>
        </div>
    </div>

    <div *ngIf="isSourceProjectInactive" fxLayout="column" class="merge-project-target-merge-container" fxLayoutGap="10px">
        <div fxLayout="column" fxLayoutGap="10px" >
            <label>{{ 'projects.merge.rules.targetSalesCodesLabel' | translate }}</label>
            <mat-chip-list fxLayout="row">
                <mat-chip *ngFor="let code of targetSalesCodes" color="primary" selected>{{code}}</mat-chip>
            </mat-chip-list>
        </div>
        <mat-horizontal-stepper linear #stepper>
            <mat-step [stepControl]="selectTargetProjectFormGroup" [editable]="true" [label]="translate.instant('projects.merge.dialog.selectTarget')">
                <form [formGroup]="selectTargetProjectFormGroup">

                    <merge-project-reason [requiresReason]="isEmergencySourceProject"
                        (submitMergeReason)="submitReasonForUsingMerge($event)"></merge-project-reason>

                </form>
            </mat-step>
            <mat-step [label]="translate.instant('projects.merge.dialog.selectReview')">
                <div  *ngIf="onSelectReviewPage" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
                    <div class="merge-project-review-select-container">
                        <div fxLayout fxLayoutAlign="center center" fxLayoutGap="50px">

                            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                                <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                                    <span class="merge-project-review-title">{{ 'projects.merge.dialog.source' | translate }}</span>
                                </div>
                                <merge-project-review-select *ngIf="targetProjectSelected"
                                    (reviewModelChanged)="sourceJobReviewChanged($event)"
                                    [reviewSelected]="!targetJobReviewSelected" [jobReview]="sourceJobReview"
                                    (reviewSelectionChanged)="sourceReviewSelectionChanged($event)"
                                    [projectInfo]="sourceProjectInfo">
                                </merge-project-review-select>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                                <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                                    <span class="merge-project-review-title">{{ 'projects.merge.dialog.targetProject' | translate }}</span>
                                </div>
                                <merge-project-review-select *ngIf="targetProjectSelected" [projectInfo]="targetProjectInfo"
                                    [reviewSelected]="targetJobReviewSelected" [jobReview]="targetJobReview"
                                    (reviewSelectionChanged)="targetReviewSelectionChanged($event)"
                                    (reviewModelChanged)="targetJobReviewChanged($event)">
                                </merge-project-review-select>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                        <div fxFlex="50" fxLayout fxLayoutAlign="start center" fxFlexFill>
                            <mat-checkbox *ngIf="exceededAutoApproveTime" [(ngModel)]="acknowledgedAutoApproveRequirement">
                                {{ 'projects.merge.dialog.sourceProjError' | translate }}
                            </mat-checkbox>
                        </div>

                        <div fxFlex="50" fxLayout fxLayoutAlign="end center" fxFlexFill>
                            <button mat-raised-button
                                color="primary"
                                [disabled]="!acknowledgedAutoApproveRequirement && exceededAutoApproveTime"
                                (click)="submitMergeRequest()">

                                {{ 'projects.merge.dialog.submit' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</dialog-action-card>
