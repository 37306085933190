<div fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-form-field>
            <mat-label>{{ 'shared.branch' | translate }}</mat-label>
            <mat-select [(ngModel)]="query.branch">
                <mat-option value=''>{{ 'shared.all' | translate }}</mat-option>
                <mat-option *ngFor="let branch of branches" [value]="branch.name">{{branch.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'shared.jobType' | translate }}</mat-label>
            <mat-select [(ngModel)]="query.jobType">
                <mat-option value=''>{{ 'shared.all' | translate }}</mat-option>
                <mat-option *ngFor="let jobType of jobTypes" [value]="jobType">{{jobType}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <button mat-raised-button color="primary" (click)="loadReport()">{{ 'dataReport.serviceOpsRejectionReport.loadReport' | translate }}</button>
        </div>
    </div>
    <div fxLayout="row" class="report-grid-div">
        <kendo-grid [kendoGridBinding]="gridViewData" [pageSize]="gridPageSize" [pageable]="true" [loading]="loading"
        [style.height.%]="100" appGridPageResize="true">
            <ng-template kendoGridToolbarTemplate>
                <button kendoGridExcelCommand [disabled]="!gridViewData.length">{{ 'shared.exportExcel' | translate }}</button>
            </ng-template>
            <kendo-grid-column field="projectId" title="{{ 'shared.project' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="branch" title="{{ 'shared.branch' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="rejectedReason" title="{{ 'dataReport.serviceOpsRejectionReport.rejectionReason' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="rejectedDate" title="{{ 'dataReport.serviceOpsRejectionReport.rejectionDate' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.rejectedDate | branchLocalTime: dataItem.branch : branches : false}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="rejectedBy" title="{{ 'dataReport.serviceOpsRejectionReport.rejectionBy' | translate }}">
            </kendo-grid-column>
            <kendo-grid-excel fileName="rejectedByServiceOpsData.xlsx" [fetchData]="allData" filterable="true">
            </kendo-grid-excel>
        </kendo-grid>
    </div>
    </div>
