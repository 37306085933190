import LocalizedContent from './localized-content';

export default class LocalizedCodeDescription<T = unknown> {
    public code: string;
    public descriptions: LocalizedContent[] = [];
    public data?: T;

    public constructor(code: string, descriptions: LocalizedContent[], data = null) {
        this.code = code;
        this.descriptions = descriptions;
        this.data = data;
    }
}
