import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import ActionProgressReporterOption from '../../../../core/data-models/action-progress-reporter-option';

@Component({
    selector: 'action-progress-dialog',
    styleUrls: ['./action-progress-dialog.component.scss'],
    templateUrl: './action-progress-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ActionProgressDialogComponent implements OnInit {

    constructor(private _dialog: MatDialogRef<ActionProgressDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public options: ActionProgressReporterOption) { }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        this._dialog.addPanelClass('action-progress-dialog');
    }

    public onActionFinished(result: boolean): void {
        if (result && !this.options.showSuccess) {
            this.onClose(result);
        }
        else {
            this._dialog.updateSize('300px', '400px');
        }
    }

    public onClose(result: boolean): void {
        this._dialog.close(result);
    }
}
