<dialog-action-card
    [icon]="'preview'"
    [title]="translate.instant('shared.pdfFileViewerDialog.photoReports')"
    [subTitle]="data[0].status === 'processing' && translate.instant('shared.pdfFileViewerDialog.photoReportsBackground')"
    [isLoaded]="true"
    (close)="onClose()">
    <div fxLayout="row" fxFill class="pdf-viewer-main-container">
        <div fxLayout="row" fxFill *ngIf="data.length">
            <div fxLayout="column" fxFlex="35">
                <mat-selection-list class="options-container" style="width: 100%" [multiple]="false" (selectionChange)="loadPdfFile($event)">
                    <mat-list-option class="list-container" *ngFor="let item of data" [value]="item.fileUrl">
                        <div fxLayout="row" fxLayout="center center" >
                            <div [matTooltip]="item.fileName" class="list-details" fxFlex>
                                <p class="file-name">{{item.fileName}}</p>
                                <span> {{item.lastModifiedDate | date}} </span>
                            </div>
                            <div class="list-actions" fxFlex="10">
                                <button *ngIf="item.status === 'done' || !item.status" (click)="downloadAttachment(item)" class="list-action" mat-icon-button color="primary" aria-label="download button"[matTooltip]="translate.instant('projects.attachments.tooltips.download')">
                                    <mat-icon>cloud_download</mat-icon>
                                </button>
                                <div class="list-action" style="padding: .2em;" *ngIf="item.status === 'processing'">
                                    <div fxLayout="column" fxLayoutAlign="center center" fxFill>
                                        <dots-loader></dots-loader>
                                    </div>
                                </div>
                                <div *ngIf="item.status === 'failed'" class="list-action" fxLayout="row" fxLayoutAlign="center center" mat-icon-button color="error" aria-label="error">
                                    <mat-icon class="failed-report" [matTooltip]="translate.instant('projects.attachments.tooltips.failed')">error</mat-icon>
                                </div>
                            </div>
                    </div>
                    </mat-list-option>
                  </mat-selection-list>
            </div>
            <div fxLayout="column" fxFlex>
                <div style="height: 100%" >
                    <pdf-viewer
                        [src]="pdfSrc"
                        [render-text]="true"
                        [show-borders]="true"
                        style='height: inherit;'
                        >
                    </pdf-viewer>
                </div>
            </div>
        </div>
        <div *ngIf="!data.length" fxLayout="column" fxFill fxLayoutAlign="center center" class="pdf-viewer-empty-container">
            <label>{{ 'shared.pdfFileViewerDialog.noPhotoReportIndicator' | translate }}</label>
        </div>
    </div>
</dialog-action-card>
