<mat-card fxFill>
    <mat-card-content fxLayout="column" fxFill>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" *ngIf="saveAllowed">
            <button mat-raised-button color="primary" (click)="downloadFile()" fxLayoutAlign="center center" [disabled]="!pdfUrl">
                <mat-icon>download</mat-icon>
                <span>{{ 'shared.quotePdfPreview.save' | translate }}</span>
            </button>
        </div>
        <div fxLayout="column" fxFlex fxFill *ngIf="isLoaded" class="quote-pdf-viewer-main-container">
            <div fxFill style="height: 100%" *ngIf="pdfUrl">
                <pdf-viewer fxFill class="pdf-viewer"
                    [src]="pdfUrl"
                    [render-text]="true"
                    [show-borders]="true"
                    style="height: inherit">
                </pdf-viewer>
            </div>
            <div *ngIf="!pdfUrl" fxLayout="column" fxFill fxLayoutAlign="center center">
                <label>{{ 'shared.quotePdfPreview.failedToGetQuotePdf' | translate }}</label>
            </div>
        </div>
        <div fxLayout="column" fxFlex *ngIf="!isLoaded" fxLayoutAlign="center center">
            <dots-loader></dots-loader>
        </div>
    </mat-card-content>
</mat-card>
