<dialog-action-card class="dispatch-project-dialog-container"
    [icon]="options.icon"
    [title]="options.title"
    [isLoaded]="true"
    (close)="onClose()">

    <div fxLayout="column" class="dispatch-stepper-content">
        <mat-horizontal-stepper fxFill linear labelPosition="bottom" *ngIf="!loading" #stepper>
            <mat-step label="{{customerSelectionStepConfig.label | translate}}" [completed]="customerSelectionStepConfig.completed">
                <app-customer-selection-step
                    [config]="customerSelectionStepConfig"
                    (stepClicked)="handleStep($event)"
                    (siteSelected)="loadSiteHistory($event)"
                    (customerSelected)="updateSelectedCustomer($event)">
                </app-customer-selection-step>
            </mat-step>
            <mat-step label="{{projectInfoStep.label | translate}}" [completed]="projectInfoStep.completed">
                <app-project-info-step [config]="projectInfoStep"
                    [selectedCustomer]="selectedCustomer"
                    [healthScreeningOption]="healthScreeningOption"
                    [handOffMeetingRequired]='handOffMeetingRequired'
                    (completed)="projectInfoStep.completed = $event"
                    (stepClicked)="handleStep($event)"
                    (branchChanged)="validateBranchRequirements($event)"
                    (budgetTypeChanged)="updateBudgetRequirements($event)"
                    (jobTypeChanged)="updateJobTypeRequirements($event)">
                </app-project-info-step>
            </mat-step>
            <mat-step label="{{ workOrderStepConfig.label | translate}}" [completed]="workOrderStepConfig.completed" *ngIf="!isConvertingProject">
                <app-work-order-step
                    [config]="workOrderStepConfig"
                    (stepClicked)="handleStep($event)">
                </app-work-order-step>
            </mat-step>
            <ng-container *ngIf="showAdditionalInfoStep">
                <mat-step label="{{ additionalInfoStepConfig.label | translate}}" [completed]="additionalInfoStepConfig.completed">
                    <app-additional-info-step
                        [config]="additionalInfoStepConfig"
                        (stepClicked)="handleStep($event)">
                    </app-additional-info-step>
                </mat-step>
            </ng-container>
            <ng-container *ngIf="showUSProjectInfoStep">
                <mat-step label="{{ usProjectInfoStepConfig.label | translate}}" [completed]="usProjectInfoStepConfig.completed">
                    <app-us-project-questions-step
                        [budgetConfig]="budgetStepConfig"
                        [config]="usProjectInfoStepConfig"
                        (stepClicked)="handleStep($event)">
                    </app-us-project-questions-step>
                </mat-step>
            </ng-container>
            <mat-step label="{{ historyStepConfig.label | translate}}" [completed]="historyStepConfig.completed" *ngIf="!isConvertingProject">
                <app-history-step
                    [config]="historyStepConfig"
                    (stepClicked)="handleStep($event)">
                </app-history-step>
            </mat-step>
            <mat-step label="{{ attachmentStepConfig.label | translate}}" *ngIf="!isConvertingProject">
                <app-attachment-step
                    [config]="attachmentStepConfig"
                    (stepClicked)="handleStep($event)">
                </app-attachment-step>
            </mat-step>
            <mat-step label="{{ budgetStepConfig.label | translate}}" [completed]="budgetStepConfig.completed" *ngIf="budgetStepConfig.budgetRequired">
                <app-budget-step
                    [config]="budgetStepConfig"
                    [handOffMeetingRequired]='handOffMeetingRequired'
                    (stepClicked)="handleStep($event)"
                    (budgetValueChanged)="budgetValue = $event">
                </app-budget-step>
            </mat-step>
            <mat-step [label]="isSubcontract ? translate.instant('projects.dispatch.steps.subcontractSummaryStep') : translate.instant('projects.dispatch.steps.summaryStep')">
                <div fxLayout="column" fxLayoutGap="15px" class="dispatch-summary-content">
                    <div fxLayout="column" fxFlex fxLayoutGap="15px" class="dispatch-summary-main-content">
                        <mat-card *ngIf="customerSelectionStepConfig.completed">
                            <mat-card-title>
                                {{ "dispatch.dispatchProjectDialog.customerInformationSummary.title" | translate }}
                            </mat-card-title>
                            <mat-card-content>
                                <div>
                                    <ng-container *ngIf="!isSubcontract">
                                        {{"dispatch.dispatchProjectDialog.customerInformationSummary.summary.fragment1" | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="isSubcontract">
                                        {{"dispatch.dispatchProjectDialog.customerInformationSummary.summary.subcontractCreationText" | translate }}
                                    </ng-container>

                                    <span class="summary-info-span">
                                        {{customerSelectionStepConfig.customerData.customer.name}} ({{customerSelectionStepConfig.customerData.customer.customerId}})
                                    </span>,

                                    {{"dispatch.dispatchProjectDialog.customerInformationSummary.summary.fragment2" | translate }}

                                    <span class="summary-info-span">
                                        {{customerSelectionStepConfig.customerData.site.name}} ({{customerSelectionStepConfig.customerData.site.shipToId}})
                                    </span>,

                                    {{"dispatch.dispatchProjectDialog.customerInformationSummary.summary.fragment3" | translate }}

                                    <span class="summary-info-span">
                                        {{customerSelectionStepConfig.customerData.billingSite.name}} ({{customerSelectionStepConfig.customerData.billingSite.shipToId}})
                                    </span>
                                </div>
                            </mat-card-content>
                            <mat-card-actions fxLayout="row" fxLayoutAlign="end center" >
                            <button mat-button color="primary" (click)="goToStep(customerSelectionStepConfig.label)">{{ 'projects.dispatch.update' | translate }}</button>
                            </mat-card-actions>
                        </mat-card>
                        <mat-card *ngIf="projectInfoStep.completed">
                            <mat-card-title>
                                {{ "dispatch.dispatchProjectDialog.projectInformationSummary.title" | translate }}
                            </mat-card-title>
                            <mat-card-content>
                                <div>
                                    {{"dispatch.dispatchProjectDialog.projectInformationSummary.summary.fragment1" | translate}}
                                    <span class="summary-info-span">{{ projectInfoStep.data.salesCode | jobTypeText: user?.language }} </span>
                                    {{"dispatch.dispatchProjectDialog.projectInformationSummary.summary.fragment2" | translate}}
                                    <span class="summary-info-span">{{projectInfoStep.data.branch.name}} </span>.
                                    <span *ngIf="!isConvertingProject && !isSubcontract">
                                        <span *ngIf="projectInfoStep.data.sendToLocalQueue">{{ sendToLocalQueueText }}</span>

                                        <span *ngIf="!projectInfoStep.data.sendToLocalQueue">
                                            {{"dispatch.dispatchProjectDialog.projectInformationSummary.summary.fragment4" | translate}}
                                        <span class="summary-info-span">{{projectInfoStep.data.crew?.crewName}}</span>
                                        </span>
                                    </span>
                                </div>
                                <div *ngIf="!isConvertingProject">
                                    <span *ngIf="projectInfoStep.data.estimatedDays || isInternal">
                                        {{ "dispatch.dispatchProjectDialog.projectInformationSummary.summary.fragment5" | translate }}
                                    </span>

                                    <span *ngIf="projectInfoStep.data.estimatedDays" class="summary-info-span">
                                        {{ projectInfoStep.data.estimatedDays }} {{ "dispatch.dispatchProjectDialog.projectInformationSummary.summary.fragment6" | translate }}
                                    </span>

                                    <span *ngIf="isInternal && projectInfoStep.data.estimatedDays">
                                        {{ "dispatch.dispatchProjectDialog.projectInformationSummary.summary.fragment7" | translate }}
                                    </span>

                                    <span *ngIf="isInternal" class="summary-info-span">
                                        {{ projectInfoStep.data.estimatedHours }} {{ "dispatch.dispatchProjectDialog.projectInformationSummary.summary.fragment8" | translate }}
                                    </span>
                                </div>
                            </mat-card-content>
                            <mat-card-actions fxLayout="row" fxLayoutAlign="end center" >
                                <button mat-button color="primary" (click)="goToStep(projectInfoStep.label)">
                                    {{"dispatch.dispatchProjectDialog.projectInformationSummary.buttonLabel" | translate}}
                                </button>
                            </mat-card-actions>
                            </mat-card>
                            <mat-card *ngIf="!isConvertingProject">
                                <mat-card-title>
                                    {{"dispatch.dispatchProjectDialog.attachments.title" | translate}}
                                </mat-card-title>
                                <mat-card-content>
                                    <label>
                                        {{"dispatch.dispatchProjectDialog.attachments.youHaveSelected" | translate}}
                                    </label>
                                    <ul>
                                        <li *ngIf="attachmentStepConfig.attachments.length"><span class="summary-info-span">{{attachmentStepConfig.attachments.length}}</span>
                                            {{"dispatch.dispatchProjectDialog.attachments.list.someAttachments" | translate}}
                                        </li>
                                        <li *ngIf="!attachmentStepConfig.attachments.length">
                                            {{"dispatch.dispatchProjectDialog.attachments.list.none" | translate}}
                                        </li>
                                        <li *ngIf="attachmentStepConfig.roofMap">
                                            {{"dispatch.dispatchProjectDialog.attachments.list.roofPlan" | translate}}
                                        </li>
                                    </ul>
                                </mat-card-content>
                                <mat-card-actions fxLayout="row" fxLayoutAlign="end center" >
                                <button mat-button color="primary" (click)="goToStep(attachmentStepConfig.label)">
                                    {{"dispatch.dispatchProjectDialog.attachments.buttonLabel" | translate}}
                                </button>
                                </mat-card-actions>
                            </mat-card>
                            <mat-card *ngIf="budgetStepConfig.file">
                                <mat-card-title>
                                    {{"dispatch.dispatchProjectDialog.budget.title" | translate}}
                                </mat-card-title>
                                <mat-card-content>
                                    <label>
                                        {{"dispatch.dispatchProjectDialog.budget.buttonLabel" | translate}}
                                        <span class="summary-info-span"> {{budgetStepConfig.file.name}} </span>
                                    </label>
                                </mat-card-content>
                                <mat-card-actions fxLayout="row" fxLayoutAlign="end center" >
                                <button mat-button color="primary" (click)="goToStep(budgetStepConfig.label)">
                                    {{"dispatch.dispatchProjectDialog.budget.buttonLabel" | translate}}
                                </button>
                                </mat-card-actions>
                            </mat-card>
                            <mat-card *ngIf="!isConvertingProject">
                                <mat-card-title>
                                    {{"dispatch.dispatchProjectDialog.history.title" | translate}}
                                </mat-card-title>
                                <mat-card-content>
                                    <label *ngIf="selectedHistory.length">
                                        {{"dispatch.dispatchProjectDialog.history.includingHistory.fragment1" | translate}}
                                        <span class="summary-info-span"> {{selectedHistory.length}} </span>
                                        {{"dispatch.dispatchProjectDialog.history.includingHistory.fragment2" | translate}}
                                    </label>
                                    <label *ngIf="!selectedHistory.length">
                                        {{"dispatch.dispatchProjectDialog.history.notIncludingHistory" | translate}}
                                    </label>
                                </mat-card-content>
                                <mat-card-actions fxLayout="row" fxLayoutAlign="end center" >
                                <button mat-button color="primary" (click)="goToStep(historyStepConfig.label)">
                                    {{"dispatch.dispatchProjectDialog.history.buttonLabel" | translate}}
                                </button>
                                </mat-card-actions>
                            </mat-card>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                        <button mat-button matStepperPrevious>
                            {{"dispatch.dispatchProjectDialog.backButtonLabel" | translate}}
                        </button>
                        <button mat-flat-button (click)="createProject()" [disabled]="dispatching" color="primary">
                            {{"dispatch.dispatchProjectDialog.confirmButtonLabel" | translate}}
                        </button>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>

        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="loading" class="dispatch-loading">
            <dots-loader></dots-loader>
        </div>
    </div>
</dialog-action-card>
