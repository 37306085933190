import { Directive, ElementRef, HostListener, Renderer2, Self, Optional, OnInit, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[appPhoneMask]'
})
export class PhoneMaskDirective implements OnInit {
    @Input() isFax = false;
    private valueChangesSubscription: Subscription;
    private lastKeyEvent = '';

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        @Optional() @Self() private ngControl: NgControl
    ) { }

    ngOnInit() {
        this.valueChangesSubscription = this.ngControl.control?.valueChanges.subscribe(value => {
            if (value === null) {
                return;
            }
            const newVal = this.handleValue(value, this.lastKeyEvent === 'Backspace');
            this.renderer.setProperty(this.el.nativeElement, 'value', newVal);
            if (value !== newVal.replace(/\D/g, '')) {
                this.ngControl.control?.setValue(newVal.replace(/\D/g, ''));
            }
        });
    }

    @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
        const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'End', 'Home'];
        if (allowedKeys.includes(event.key) || /^[0-9]$/.test(event.key)) {
            this.lastKeyEvent = event.key;
            if (this.ngControl.control?.value === null) {
                return;
            }
            const newVal = this.handleValue(this.ngControl.control?.value, this.lastKeyEvent === 'Backspace');
            this.ngControl.control?.setValue(newVal.replace(/\D/g, ''));
            this.renderer.setProperty(this.el.nativeElement, 'value', newVal);
        }
        else {
            event.preventDefault();
        }
    }

    private handleValue(value: string, backspace: boolean): string {
        let newVal = value.replace(/\D/g, '');
        if (backspace && newVal.length <= 6) {
            newVal = newVal.substring(0, newVal.length - 1);
        }

        if (newVal.length === 0) {
            newVal = '';
        }
        else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, '($1)');
        }
        else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        }
        else if (newVal.length <= 10) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }
        else if (this.isFax) {
            newVal = newVal.substring(0, 10);
        }
        else {
            newVal = newVal.substring(0, 14);
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})(\d+)/, '($1) $2-$3, $4');
        }

        return newVal;
    }

    ngOnDestroy() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
    }
}