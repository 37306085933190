<dialog-action-card
    [icon]="'print'"
    [title]="translate.instant('shared.quoteReportOptionsDialog.quoteReportPrintOptions')"
    [isLoaded]="true"
    (close)="onClose(false)">

    <div fxLayout="column" fxFill>
        <div fxFlex="75" fxLayout fxLayoutAlign="center center">
            <div fxFlex="75" fxLayout="column" fxLayoutGap="20px">
            <label>{{ 'shared.quoteReportOptionsDialog.pickADateForReport' | translate }}</label>
            
            <mat-form-field>
                <mat-label>{{ 'shared.submit' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" (click)="startDate.open()" [min]="minDate" [max]="maxDate" readonly [(ngModel)]="quoteDate">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
            </div>
        </div>

        <div  fxFlex="25" fxLayoutAlign="end end" class="quote-report-submit-button-container">
            <button (click)="onClose(true)"  mat-raised-button color="primary">{{ 'shared.submit' | translate }}</button>
        </div>
    </div>
    
</dialog-action-card>