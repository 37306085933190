import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import ProjectQuestions from '../../../core/data-models/project-attributes/project-questions';
import AttributeGrid from '../../../core/data-models/project-attributes/attribute-grid';
import ProjectQuestionsGrid from '../../../core/data-models/project-attributes/project-questions-grid';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import ProjectAttributesDropdown from '../../../core/data-models/project-attributes-dropdowns';
import { ValidatorFactory } from '../../../core/services/validator-factory/validator-factory.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import ProjectQuestionValidCheck from '../../../core/data-models/project-questions-valid-check';
import { TranslateService } from '@ngx-translate/core';
import { ProjectQuestionsGridChange } from '../../../core/data-models/project-attributes/project-questions-grid-change';
import GridItemEditOption from '../../../core/data-models/grid-item-edit-option';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import KeyValuePair from '../../../core/data-models/key-value-pair';

@Component({
    selector: 'project-attributes-card',
    styleUrls: ['./project-attributes-card.component.scss'],
    templateUrl: './project-attributes-card.component.html'
})
export class ProjectAttributesCardComponent implements OnInit, OnChanges {
    @Input() public projectQuestions = new ProjectQuestions();
    @Input() public readOnly = true;
    @Input() public isUSProject = true;
    @Output() result = new EventEmitter<ProjectQuestionValidCheck>();
    public addingAttributes = false;
    public attributeToAdd = new ProjectQuestionsGrid();
    public type = '';
    public dropdowns: ProjectAttributesDropdown;
    public effectiveDate: Date;
    public formGroup: UntypedFormGroup;
    public currencyValidator = this._validatorFactory.createCurrencyValidator();
    public editOptions = new GridItemEditOption();
    public createdStatus = ProjectQuestionsGridChange.Created;
    public rateTypes: KeyValuePair[];
    private _attributes: AttributeGrid[] = [];

    constructor(
        public translate: TranslateService,
        private _validatorFactory: ValidatorFactory,
        private _formBuilder: UntypedFormBuilder,
        private _projectService: ProjectHttpService,
        private _snackbar: MatSnackBar) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.projectQuestions) {
            this.showAttributes();
        }
    }

    public async ngOnInit(): Promise<void> {
        this.showAttributes();
        if (!this.readOnly) {
            this.initializeFormGroup();
            const type = this.isUSProject ? 'US' : 'CA';
            this.dropdowns = await this._projectService.getProjectAttributesDropdowns(type);
            this.rateTypes = this.dropdowns.rateType.map(_ => ({
                key: _.codeValue,
                value: _.codeValueDesc
            }));

            this.result.emit(new ProjectQuestionValidCheck(true, this.projectQuestions));
        }
    }

    private initializeFormGroup(): void {
        const positiveValidator = this._validatorFactory.createPositiveValidator();
        this.editOptions.validatorSets = [positiveValidator];

        this.formGroup = this._formBuilder.group({
            rate: [this.attributeToAdd.rate, [this.currencyValidator.validator, positiveValidator.validator]],
            type: [this.type],
            union: [this.attributeToAdd.union],
            rateType: [this.attributeToAdd.rateType],
            effectiveDate: [this.effectiveDate]
        });

        this.formGroup.get('type').valueChanges.subscribe(value => {
            this.type = value;
            const rateTypeControl = this.formGroup.get('rateType');
            if (value === 'zonePay') {
                rateTypeControl.setValidators([Validators.required]);
            }
            else {
                rateTypeControl.setValue(null);
                rateTypeControl.setValidators([]);
            }
            rateTypeControl.updateValueAndValidity();
        });
    }

    private syncFormFields(): void {
        this.attributeToAdd.rate = this.formGroup.get('rate').value;
        this.type = this.formGroup.get('type').value;
        this.attributeToAdd.union = this.formGroup.get('union').value;
        this.attributeToAdd.rateType = this.formGroup.get('rateType').value;
        this.effectiveDate = this.formGroup.get('effectiveDate').value;
    }

    private showAttributes(): void {
        const { mileage, roomNBoard, zonePay } = this.projectQuestions;

        const mileageLabel = this.translate.instant('shared.projectAttributesCard.mileage');
        const roomAndBoardLabel = this.translate.instant('shared.projectAttributesCard.roomAndBoard');
        const zonePayLabel = this.translate.instant('shared.projectAttributesCard.zonePay');
        this._attributes = [
            { toolTip: mileageLabel, icon: 'room', records: mileage, field: 'mileage' },
            { toolTip: roomAndBoardLabel, icon: 'hotel', records: roomNBoard, field: 'roomNBoard' },
            { toolTip: zonePayLabel, icon: 'map', records: zonePay, field: 'zonePay' }
        ];
    }

    get attributes(): AttributeGrid[] {
        return this._attributes;
    }

    get infoExists(): boolean {
        const { mileage, roomNBoard, zonePay } = this.projectQuestions;
        return mileage?.length > 0 || roomNBoard?.length > 0 || zonePay?.length > 0;
    }

    public cancel(): void {
        this.addingAttributes = false;
        this.attributeToAdd = new ProjectQuestionsGrid();
        this.formGroup.reset();
    }

    public addAttribute(): void {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.invalid) {
            return;
        }

        this.syncFormFields();
        this.attributeToAdd.effDate = this.effectiveDate.toISOString().substring(0, 10);
        const existingAttributeIndex = this.projectQuestions[this.type].findIndex(_ => _.union.codeValue === this.attributeToAdd.union.codeValue && _.effDate.substring(0, 10) === this.attributeToAdd.effDate);

        if (existingAttributeIndex >= 0) {
            this._snackbar.open(this.translate.instant('shared.projectAttributesCard.sameAttributeExists'), this.translate.instant('shared.ok'));
            return;
        }

        this.attributeToAdd.change = ProjectQuestionsGridChange.Created;
        this.projectQuestions[this.type].push(this.attributeToAdd);
        this.showAttributes();
        this.cancel();
        this.result.emit(new ProjectQuestionValidCheck(true, this.projectQuestions));
    }

    public removeAttribute(grid: ProjectQuestionsGrid, attribute: string): void {
        if (!grid || grid.change !== ProjectQuestionsGridChange.Created) {
            return;
        }

        this.projectQuestions[attribute] = this.projectQuestions[attribute].filter(_ => _ !== grid);
        this.showAttributes();
        this.result.emit(new ProjectQuestionValidCheck(true, this.projectQuestions));
    }

    public updateRate(row: ProjectQuestionsGrid, value: string): void {
        row.rate = Number(value);
        if (row.change !== ProjectQuestionsGridChange.Created) {
            row.change = ProjectQuestionsGridChange.Updated;
        }

        this.result.emit(new ProjectQuestionValidCheck(true, this.projectQuestions));
    }

    public updateRateType(row: ProjectQuestionsGrid, key: string): void {
        row.rateType = this.dropdowns.rateType.find(_ => _.codeValue === key);
        if (row.change !== ProjectQuestionsGridChange.Created) {
            row.change = ProjectQuestionsGridChange.Updated;
        }

        this.result.emit(new ProjectQuestionValidCheck(true, this.projectQuestions));
    }
}
