<div class="project-questions-card-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px" fxFill>
    <div fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-icon>help</mat-icon>
        <span>{{ 'shared.projectQuestions.projectQuestions' | translate }}</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="center start">
        <div *ngFor="let item of checkboxes">
            <mat-checkbox *ngIf="show(item)"
                [disabled]="readOnly"
                [(ngModel)]="projectQuestions[item.model]"
                [matTooltip]="item.toolTip"
                (change)="checkAndSendResult()">
                {{ item.label }}
            </mat-checkbox>
        </div>
    </div>

    <div *ngIf="readOnly" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div *ngFor="let number of numbers" fxFlex="40" class="project-questions-numbers-div">
            <grid-display-item [title]="number.title" [content]="projectQuestions[number.content]">
            </grid-display-item>
        </div>
    </div>

    <div *ngIf="!readOnly" fxLayout="row wrap"  [formGroup]="formGroup" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div *ngFor="let number of numbers" fxFlex="40" class="project-questions-numbers-div">
            <mat-form-field>
                <mat-label>{{number.title}}</mat-label>
                <input type="number"
                    matInput min="0"
                    (change)="checkAndSendResult()"
                    [formControlName]="number.content"
                    [matTooltip]="number.toolTip"/>
                <mat-error *ngIf="getFormField(number.content).invalid">
                    {{ currencyValidator.message }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <grid-display-item *ngIf="!isUSProject && readOnly" [title]="translate.instant('shared.projectQuestions.primeContractValueTitle')" [content]="projectQuestions.projValue ? projectQuestions.projValue.codeValueDesc : null"></grid-display-item>
    <mat-form-field *ngIf="!isUSProject && !readOnly">
        <mat-label>{{ 'shared.projectQuestions.primeContractValueTitle' | translate }}</mat-label>
        <mat-select [value]="projectQuestions.projValue ? projectQuestions.projValue.codeValue : null" (selectionChange)="updatePrimeContractValue($event)" matTooltip="{{ 'shared.projectQuestions.primeContractValueTooltip' | translate }}">
            <mat-option [value]="null">N/A</mat-option>
            <mat-option *ngFor="let projectValue of projectValues" [value]="projectValue.codeValue">
                {{ projectValue.codeValueDesc }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
