<div fxLayout="column" fxLayoutGap="15px" fxFill>
    <mat-card fxLayout="row" fxLayoutGap="10px" fxFill *ngIf="isLoading" fxLayoutAlign="center center">
        <dots-loader></dots-loader>
    </mat-card>
    <div fxLayout="column" *ngIf="!isLoading" class="work-editor-main-content">
        <div fxLayout="row" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutAlign="center">
                <button mat-flat-button color="primary" (click)="clearTemplate()" [disabled]="!isOldTemplate">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <mat-form-field fxFlex="30">
                <mat-label>{{'admin.workOrderTemplate.templateName' | translate }}</mat-label>
                <input matInput [(ngModel)]="currentTemplate.title" [disabled]="isOldTemplate">
            </mat-form-field>
            <div fxFlex></div>
            <mat-form-field>
                <mat-label>{{'admin.workOrderTemplate.availableTemplates' | translate }}</mat-label>
                <mat-select (selectionChange)="loadTemplate($event)" [value]="currentTemplate">
                    <mat-option *ngFor="let template of templates" [value]="template">{{template.title}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <button  mat-flat-button color="warn" [disabled]="isDeleteDisabled" (click)="deleteTemplate()">
                    {{'admin.workOrderTemplate.deleteTemplate' | translate }}
                </button>
                <button  mat-flat-button color="primary" [disabled]="isSaveDisabled" (click)="saveTemplate()">
                    {{'admin.workOrderTemplate.saveTemplate' | translate }}
                </button>
            </div>
        </div>
        <textarea [id]="editorId"></textarea>
    </div>
</div>
