import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { AppConfigHttpService } from '../../../../core/services/http/appconfig-http/appconfig-http.service';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'about-dialog',
    styleUrls: ['./about-dialog.component.scss'],
    templateUrl: './about-dialog.component.html'
})
export class AboutDialogComponent implements OnInit {
    public logoUrl = '';
    public copyrightInfo = '';

    constructor(private _state: StateService,
                private _appConfigHttp: AppConfigHttpService,
                private _dialog: MatDialogRef<AboutDialogComponent>) { }

    get siteName(): string {
        return environment.websiteTitle ?? '';
    }

    public async ngOnInit(): Promise<void> {
        this._dialog.addPanelClass('about-dialog');
        this.logoUrl = await this._appConfigHttp.getLogoUrl();
        this.copyrightInfo = await this._appConfigHttp.getCopyrightInfo();
    }

    public showTermsOfService(): void {
        this._state.go('termsOfService');
        this._dialog.close();
    }

    public showAttributions(): void {
        this._state.go('attributions');
        this._dialog.close();
    }
}
