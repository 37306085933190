<form [formGroup]="newCustomerFormGroup" fxFill>
    <div fxFill fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="start" fxFlex>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="25">
                <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
                    <grid-display-item fxFlex="100" [title]="translate.instant('shared.customerCreate.requestedDate')" [content]="requestedDate">
                    </grid-display-item>

                    <grid-display-item fxFlex="100" [title]="translate.instant('shared.customerCreate.requestedBy')" [content]="userDisplayName">
                    </grid-display-item>
                </div>

                <div *ngIf="addressStatus?.visible && !addressCheckLoading" [ngClass]="addressStatus?.style">
                    <div *ngFor="let message of addressStatus?.messages">{{ message }}</div>
                </div>

                <div fxLayout="column" fxLayoutAlign="center center" fxFlex *ngIf="addressCheckLoading">
                    <dots-loader></dots-loader>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="50">
                <div fxLayout="row">
                    <mat-form-field fxFlex="90">
                        <mat-label>{{ 'shared.customerCreate.customerName' | translate }}</mat-label>
                        <input matInput formControlName="name" maxlength="60">
                        <mat-error *ngIf="isFormControlValid('name')">{{getErrorMessage('name')}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="2%">
                    <mat-form-field fxFlex="44">
                        <mat-label>{{ 'shared.customerCreate.address1' | translate }}</mat-label>
                        <input matInput formControlName="address1" maxlength="50">
                        <mat-error *ngIf="isFormControlValid('address1')">{{getErrorMessage('address1')}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="44">
                        <mat-label>{{ 'shared.customerCreate.address2' | translate }}</mat-label>
                        <input matInput formControlName="address2" maxlength="50">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="2%">
                    <mat-form-field fxFlex="34">
                        <mat-label>{{ 'shared.customerCreate.city' | translate }}</mat-label>
                        <input matInput formControlName="city" maxlength="50">
                        <mat-error *ngIf="isFormControlValid('city')">{{getErrorMessage('city')}}</mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="13">
                        <mat-label>{{ 'shared.customerCreate.country' | translate }}</mat-label>
                        <mat-select formControlName="country">
                            <mat-option *ngFor="let country of countryList" [value]="country">
                                {{country}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="isFormControlValid('country')">{{getErrorMessage('country')}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="13">
                        <mat-label>{{ 'shared.customerCreate.state' | translate }}</mat-label>
                        <mat-select formControlName="state">
                            <mat-option *ngFor="let state of stateList" [value]="state">
                                {{state}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="isFormControlValid('state')">{{getErrorMessage('state')}}</mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="24">
                        <mat-label>{{ 'shared.customerCreate.zip' | translate }}</mat-label>
                        <input matInput formControlName="zip" maxlength="50">
                        <mat-error *ngIf="isFormControlValid('zip')">{{getErrorMessage('zip')}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="2%">
                    <mat-form-field fxFlex="44">
                        <mat-label>{{ 'shared.customerCreate.phone' | translate }}</mat-label>
                        <input matInput formControlName="phone" appPhoneMask maxlength="20">
                        <mat-error *ngIf="isFormControlValid('phone')">{{getErrorMessage('phone')}}</mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="44">
                        <mat-label>{{ 'shared.customerCreate.fax' | translate }}</mat-label>
                        <input matInput formControlName="faxNumber" [isFax]="true" maxlength="20" appPhoneMask>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="2%" *ngIf="isInternal">
                    <mat-form-field fxFlex="59">
                        <mat-label>{{ 'shared.customerCreate.dataUniversalNumberingSystemNumber' | translate }}</mat-label>
                        <input matInput formControlName="dataUniversalNumberingSystemNumber" maxlength="50">
                    </mat-form-field>
                    <mat-form-field fxFlex="29">
                        <mat-label>{{ 'shared.customerCreate.customerClass' | translate }}</mat-label>
                        <mat-select formControlName="customerClass">
                            <mat-option *ngFor="let custClass of customerClassList" [value]="custClass">
                                {{custClass}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="isFormControlValid('customerClass')">
                            {{getErrorMessage('customerClass')}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="2%">
                    <mat-form-field fxFlex="29">
                        <mat-label>{{ 'shared.customerCreate.currency' | translate }}</mat-label>
                        <mat-select formControlName="currency">
                            <mat-option *ngFor="let currency of currencyList" [value]="currency">
                                {{currency}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="isFormControlValid('currency')">{{getErrorMessage('currency')}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="59">
                        <mat-label>{{ 'shared.customerCreate.proposedBusiness' | translate }}</mat-label>
                        <input matInput formControlName="proposedBusiness" maxlength="50">
                        <mat-error *ngIf="isFormControlValid('proposedBusiness')">
                            {{getErrorMessage('proposedBusiness')}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button mat-raised-button (click)="handleStep.emit('back')">
                {{ 'shared.customerCreate.backButton' | translate }}
            </button>
            <button mat-raised-button color="warn" (click)="resetCustomerCreationForm()">
                {{ 'shared.customerCreate.resetButton' | translate }}
            </button>
            <button mat-raised-button color="primary" (click)="confirmCustomer()">
                {{ 'shared.customerCreate.nextButton' | translate }}
            </button>
        </div>
    </div>
</form>
