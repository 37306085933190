import { TranslateService } from '@ngx-translate/core';
import { AuthLockService } from '../services/auth-lock-service/auth-lock-service';

import { SignalrHubService } from '../services/signalr-hub/signalr-hub.service';

export function setupService(signalrHubService: SignalrHubService, translateService: TranslateService, authService: AuthLockService) {
    return async(): Promise<void> => {
        const language = 'en';
        authService.initialize();
        signalrHubService.initialize();
        translateService.setDefaultLang(language);
        await translateService.getTranslation(language).toPromise();
        checkBrowser(translateService);
    };
}

function checkBrowser(translateService: TranslateService) {
    const { userAgent } = window.navigator;

    const checks = [
        { target: 'MSIE ', pattern: 'MSIE ' },
        { target: 'Trident/', pattern: 'rv:' },
        { target: 'Edge/', pattern: 'Edge/' }
    ];

    for (const { target, pattern } of checks) {
        if (!userAgent.includes(target)) {
            continue;
        }

        const index = userAgent.indexOf(target);
        const start = index + pattern.length;
        const end = userAgent.indexOf('.', index);
        const version = parseInt(userAgent.substring(start, end), 10);

        if (version && version < 12) {
            // eslint-disable-next-line no-alert
            alert(translateService.instant('configs.unsupportedBrowser', { version }));

            return;
        }
    }
}
