import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileSystemFileEntry } from 'ngx-file-drop';

import AttachmentModel from '../../../data-models/attachment-model';
import ProjectAttachment from '../../../data-models/project-attachment';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UploadHttpService {
    private readonly _apiFile = `${environment.fswApiUrl}api2/service/file`;
    private readonly _apiCloud = `${environment.fswApiUrl}api2/cloud`;

    constructor(private _http: HttpClient) { }

    public async uploadProjectPhoto(projectId: string, image: Blob, name: string, isPhotoUpload = true): Promise<boolean> {
        try {
            const endpoint = `${this._apiFile}/image/serviceapp/update/aws/${projectId}?name=${name}&isPhotoUpload=${isPhotoUpload}`;
            const data = new FormData();
            data.append('file', image, name);
            await this._http.put(endpoint, data, { responseType: 'blob' }).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    /* istanbul ignore next */
    public async upload(url: string, file: FileSystemFileEntry, hasResult = false): Promise<boolean> {
        return await new Promise(resolve => {
            file.file(async _ => {
                try {
                    const data = new FormData();
                    data.append('file', _, _.name);
                    const result = await this._http.post<boolean>(url, data).toPromise();
                    resolve(hasResult ? result : true);
                }
                catch {
                    resolve(false);
                }
            });
        });
    }

    public async uploadQuoteAttachment(quoteId: string, attachment: AttachmentModel, file: Blob): Promise<boolean> {
        try {
            const endpoint = `${this._apiCloud}/blob/upload/quoteattachment/${quoteId}`;
            const data = new FormData();
            data.append('file', file, attachment.name);
            data.append('attachment', JSON.stringify(attachment));
            await this._http.post(endpoint, data, { responseType: 'blob' }).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async uploadProjectAttachment(attachment: ProjectAttachment, file: Blob, mode = 'web'): Promise<boolean> {
        try {
            const endpoint = `${this._apiCloud}/blob/upload/project/attachment/${attachment.projectId}?mode=${mode}`;
            const data = new FormData();
            data.append('file', file, attachment.name);
            data.append('attachment', JSON.stringify(attachment));
            await this._http.post(endpoint, data, { responseType: 'blob' }).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async convertXlsmToXlsx(file: File): Promise<Blob> {
        try {
            const endpoint = `${this._apiFile}/convert/xlsm/xlsx`;
            const data = new FormData();
            data.append('file', file, 'xlsmFile.xlsm');

            return await this._http.post(endpoint, data, { responseType: 'blob' }).toPromise();
        }
        catch {
            return null;
        }
    }
}
