<dialog-action-card class="integration-requests-dialog-container"
    [icon]="'assignment_ind'"
    [title]="translate.instant('shared.integrationRequestDialog.dialog.title')"
    [isLoaded]="!loading"
    (close)="onClose()">

    <div fxLayout="row" class="report-grid-div" fxFill>
        <kendo-grid [kendoGridBinding]="gridViewData"
            [pageSize]="10"
            [pageable]="true"
            [loading]="loading"
            [style.height.%]="100" >

            <kendo-grid-column field="name" title="{{ 'shared.integrationRequestDialog.table.projectName' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="customerId" title="{{ 'shared.integrationRequestDialog.table.customerId' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="createdDate" title="{{ 'shared.integrationRequestDialog.table.projectSubmitTime' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.createdDate | date: 'yyyy-MM-dd'}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="address1" title="{{ 'shared.integrationRequestDialog.table.address1' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="address2" title="{{ 'shared.integrationRequestDialog.table.address2' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="city" title="{{ 'shared.integrationRequestDialog.table.city' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="state" title="{{ 'shared.integrationRequestDialog.table.state' | translate }}" width="60">
            </kendo-grid-column>
            <kendo-grid-column field="zip" title="{{ 'shared.integrationRequestDialog.table.zip' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="country" title="{{ 'shared.integrationRequestDialog.table.country' | translate }}" width="60">
            </kendo-grid-column>
            <kendo-grid-column>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div fxLayout="row" fxLayoutGap="5px">
                        <button (click)="dispatchProject(dataItem)" mat-icon-button aria-label="dispatch button" color="primary"
                        matTooltip="{{ 'shared.integrationRequestDialog.table.dispatch' | translate }}">
                            <mat-icon>post_add</mat-icon>
                        </button>
                        <button (click)="cancelProject(dataItem)" mat-icon-button aria-label="dispatch button" color="warn"
                        matTooltip="{{ 'shared.integrationRequestDialog.table.cancel' | translate }}">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</dialog-action-card>
