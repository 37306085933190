import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import CustomEditorOption from '../../../../core/data-models/custom-editor-option';
import { DevelopHttpService } from '../../../../core/services/http/develop-http/develop-http.service';
import { EditorUtilityService } from '../../../../core/services/utility/editor-utility/editor-utility.service';
import { CountryName } from '../../../../core/enums/country-name.enum';
import KeyValuePair from '../../../../core/data-models/key-value-pair';
import Language from '../../../../core/data-models/generic/language';
import LanguageService from '../../../../core/services/language/language.service';
import { LanguageKey } from '../../../../core/enums/language-key.enum';

@Component({
    selector: 'quote-disclaimer',
    styleUrls: ['./quote-disclaimer.component.scss'],
    templateUrl: './quote-disclaimer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class QuoteDisclaimerComponent implements OnInit, AfterViewInit {
    public readonly editorId = `quote-disclaimer-editor-${Date.now()}`;
    public readonly countries = [CountryName.CA, CountryName.US];
    public country = this.countries[0];
    public canSave = false;
    public isLoaded = false;
    public reportNameTemplateOptions: KeyValuePair[];
    public languages: Language[];
    public language: Language;
    private _translateKeyPrefix = 'admin.disclaimerSettings.quoteDisclaimer';

    constructor(private _translate: TranslateService,
                private _developHttp: DevelopHttpService,
                private _editorUtility: EditorUtilityService,
                private _snackbar: MatSnackBar,
                private _languageService: LanguageService) { }

    public async ngOnInit(): Promise<void> {
        this.languages = this._languageService.getLanguages();
        this.language = this.languages.find(_ => _.language === LanguageKey.EN);
        await this.loadTemplateOptions();
    }

    public async ngAfterViewInit(): Promise<void> {
        const defaultOptions = {
            keyup: () => this.updateSaveStatus(),
            paste: () => this.updateSaveStatus()
        };

        const disclaimer = await this._developHttp.getQuoteDisclaimer(this.country);
        const customOptions: CustomEditorOption = { ...new CustomEditorOption(disclaimer), focusOnLoad: true };
        this._editorUtility.setupEditor(this.editorId, defaultOptions, customOptions);
        this.updateSaveStatus();
        this.isLoaded = true;
    }

    public async onLanguageCountrySelected(): Promise<void> {
        await this.loadDisclaimer();
    }

    private async loadTemplateOptions(): Promise<void> {
        const translations = await this._translate.getTranslation(this.language.language).toPromise();
        this.reportNameTemplateOptions = [{
            key: 'customer',
            value: translations.admin.disclaimerSettings.quoteDisclaimer.reportNameTemplateOptions.customer
        }, {
            key: 'issuer',
            value: translations.admin.disclaimerSettings.quoteDisclaimer.reportNameTemplateOptions.issuer
        }];

    }

    private async loadDisclaimer(): Promise<void> {
        this.isLoaded = false;
        const disclaimer = await this._developHttp.getQuoteDisclaimer(this.country, this.language.language);
        this._editorUtility.setContent(this.editorId, disclaimer);
        this.updateSaveStatus();
        this.isLoaded = true;
        await this.loadTemplateOptions();
    }

    public async saveDisclaimer(): Promise<void> {
        const content = this._editorUtility.getContent(this.editorId);

        if (await this._developHttp.updateQuoteDisclaimer(content, this.country, this.language.language)) {
            const message = this._translate.instant(`${this._translateKeyPrefix}.updateDisclaimerSuccess`);
            this._snackbar.open(message, this._translate.instant('shared.ok'));
        }
        else {
            const message = this._translate.instant(`${this._translateKeyPrefix}.updateDisclaimerFailed`);
            this._snackbar.open(message, this._translate.instant('shared.ok'));
        }
    }

    public insertPlaceholder(identifier: string, name: string): void {
        const id = `handle-${Date.now()}`;
        const handle = `<span id="${id}"></span>`;
        const style = '-webkit-user-select:all;-moz-user-select:all;-ms-user-select:all;user-select:all';
        const value = `<span contenteditable="false" style="${style}" class="${identifier}">{{${name}}}</span>${handle}`;
        const editor = this._editorUtility.getEditor(this.editorId);
        editor.exec('inserthtml', { value });
        this._editorUtility.focusToEnd(this.editorId, id);
    }

    private updateSaveStatus(): void {
        const content = this._editorUtility.getContent(this.editorId) ?? '';
        this.canSave = Boolean(content.replace(/&nbsp;/g, '').trim());
    }
}
