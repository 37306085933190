import { TypeRecord } from '../../generic/type-record';

export class ItemExpense {
    public typeIdentifier: string;
    public units = 0;
    public unitPrice = 0;
    public totalCost = 0;
    public isCustom = false;
    public customName: string;

    // eslint-disable-next-line max-params
    constructor(typeIdentifier: string, units = 0, unitPrice = 0, totalCost = 0, isCustom = false, customName = '') {
        this.typeIdentifier = typeIdentifier;
        this.units = units;
        this.unitPrice = unitPrice;
        this.totalCost = totalCost;
        this.isCustom = isCustom;
        this.customName = customName;
    }

    public static fromTypeRecord(record: TypeRecord): ItemExpense {
        return new ItemExpense(record.identifier);
    }
}
