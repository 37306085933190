// eslint-disable-next-line max-classes-per-file
import { Component, OnInit, Output, Input, EventEmitter, ElementRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder as FormBuilder, FormGroup } from '@angular/forms';
import { PopupService, POPUP_CONTAINER } from '@progress/kendo-angular-popup';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, switchMap, filter, tap } from 'rxjs/operators';
import { forkJoin, of, ReplaySubject } from 'rxjs';

import Customer from '../../../../../core/data-models/customer';
import { FeatureFlagService } from '../../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../../core/enums/feature-flags.enum';
import { CustomerCreationHttpService } from '../../../../../core/services/http/customer-creation-http/customer-creation-http.service';

@Component({
    selector: 'customer-select',
    styleUrls: ['./customer-select.component.scss'],
    templateUrl: './customer-select.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: POPUP_CONTAINER,
        useFactory: () => {
            return {
                nativeElement: document.querySelector('.customer-selection-kendo-grid, customer-select-management')
            } as ElementRef;
        }
    },
    PopupService
    ]
})
export class CustomerSelectComponent implements OnInit {
    @Input() searchForm: FormGroup;
    @Output() customerSelection = new EventEmitter<Customer>();
    public isInternal = false;
    public isLoadingAutoComplete = false;
    public isLoadingContent = false;
    public showResults = false;
    public customers: Customer[] = [];
    public gridContent: Customer[] = [];
    public searchSubject: ReplaySubject<[string, number]> = new ReplaySubject();
    public selectedOption = '';
    private _minCharactersToSearch = 1;
    private _minCharactersToAutocomplete = 3;

    get useLegacyCustomerCreationTool(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseLegacyCustomerCreationTool];
    }

    get queryValue(): string {
        return this.searchForm.get('query').value.trim();
    }

    get canSearch(): boolean {
        return !this.isInternal ? true : this.queryValue.length >= this._minCharactersToSearch;
    }

    get canAutoComplete(): boolean {
        return !this.isInternal ? true : this.queryValue.length >= this._minCharactersToAutocomplete;
    }

    get errorLoadingContent(): boolean {
        return this.showResults && (this.gridContent === null || this.gridContent.length === 0);
    }

    get isLoading(): boolean {
        return this.isLoadingAutoComplete || this.isLoadingContent;
    }

    get defaultOptionText(): string {
        if (!this.canAutoComplete) {
            const parameters = { minimumCharacters: this._minCharactersToAutocomplete };

            return this._translate.instant('shared.customerSelect.minimumCharactersToAutocomplete', parameters);
        }

        if (this.isLoading) {
            return this._translate.instant('shared.customerSelect.loading');
        }

        if (this.customers === null) {
            return this._translate.instant('shared.customerSelect.errorSearchingCustomer');
        }

        if (this.customers.length === 0) {
            return this._translate.instant('shared.customerSelect.noResultForAutocomplete');
        }

        return '';
    }

    constructor(private _translate: TranslateService,
                private _customerSiteHttp: CustomerCreationHttpService,
                private _formBuilder: FormBuilder,
                private _featureFlagService: FeatureFlagService) { }

    public ngOnInit(): void {
        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];

        this.setupForm();

        if (!this.isInternal) {
            this.searchSubject.next(['', 0]);
        }
    }

    public search(): void {
        this.isLoadingAutoComplete = false;
        this.isLoadingContent = false;
        this.showResults = false;
        this.searchSubject.next([this.queryValue, this._minCharactersToSearch]);
    }

    public async selectCustomer(customer: Customer): Promise<void> {
        let customerWithSites = customer;

        if (customerWithSites) {
            customerWithSites = await this._customerSiteHttp.getCustomerById(customer.customerId);
        }

        this.customerSelection.emit(customerWithSites);
    }

    private setupForm(): void {
        this.searchForm = this._formBuilder.group({ query: [''] });

        this.searchForm.get('query').valueChanges.pipe(
            debounceTime(10),
            filter(() => this.canAutoComplete),
        ).subscribe((value: string) => {
            const charsToSearch = this.selectedOption === value ? this._minCharactersToSearch : this._minCharactersToAutocomplete;
            if (charsToSearch === this._minCharactersToSearch) {
                this.showResults = false;
            }

            this.searchSubject.next([value, charsToSearch]);
        });

        this.searchSubject.pipe(
            tap(([_query, minChars]: [string, number]) => {
                if (minChars === this._minCharactersToAutocomplete) {
                    this.isLoadingAutoComplete = true;
                }
                else if (minChars === this._minCharactersToSearch) {
                    this.isLoadingContent = true;
                }
            }),
            debounceTime(500),
            filter(([_, minChars]: [string, number]) => this.canContinueSearch([this.queryValue, minChars])),
            switchMap(([query, minChars]: [string, number]) => {
                return forkJoin([of(query), of(minChars), this._customerSiteHttp.searchCustomersByName(query)]);
            })
        ).subscribe(_ => this.setResults(_));
    }

    private setResults([_query, minChars, customers]: [string, number, Customer[]]): void {
        if (!this.canContinueSearch([this.queryValue, minChars])) {
            return;
        }

        this.customers = customers === null ? null : customers.map(_ => ({
            ..._,
            addresses: _.addresses ?? []
        }));

        if (minChars === 0 || minChars === 1) {
            this.gridContent = customers;
            this.showResults = true;
        }

        this.isLoadingAutoComplete = false;
        this.isLoadingContent = false;
    }

    private canContinueSearch([query, minChars]: [string, number]): boolean {
        const isEnoughCharacters = query.trim().length >= minChars;

        if (!isEnoughCharacters && minChars === this._minCharactersToAutocomplete) {
            this.isLoadingAutoComplete = false;
        }
        else if (!isEnoughCharacters && minChars === this._minCharactersToSearch) {
            this.isLoadingContent = false;
        }

        return isEnoughCharacters;
    }
}
