import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import ImageTitle from '../../../data-models/image-title';
import ImageItem from '../../../data-models/job-template-items/image-item';
import { PhotoTypes } from '../../../enums/photo-type.enum';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ImageHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/image`;
    private imageTitles: string[];

    constructor(private _http: HttpClient) { }

    public async getImageCategories(): Promise<ImageTitle[]> {
        try {
            const endpoint = `${this._api}/categories`;
            const data = await this._http.get<ImageTitle[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async removeImage(projectId: string, image: ImageItem, isPhoto = true) : Promise<boolean> {
        try {
            const endpoint = `${this._api}/${projectId}?isPhoto=${isPhoto}`;
            await this._http.request('DELETE', endpoint, { body: image }).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async restoreImage(projectId: string, image: ImageItem, isPhoto: boolean) : Promise<boolean> {
        try {
            const endpoint = `${this._api}/restore/${projectId}?isPhoto=${isPhoto}`;
            await this._http.put(endpoint, image).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async updateImage(projectId: string, image: ImageItem) : Promise<boolean> {
        try {
            const endpoint = `${this._api}/update/${projectId}`;
            await this._http.put(endpoint, image).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async updateAllImages(projectId: string, images: ImageItem[]): Promise<boolean> {
        try {
            const endpoint = `${this._api}/update/all/${projectId}`;
            await this._http.post(endpoint, images).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async getPictures(projectId: string) : Promise<ImageItem[]> {
        return await this.getPhotos(projectId, PhotoTypes.Picture);
    }

    public async getDrawings(projectId: string) : Promise<ImageItem[]> {
        return await this.getPhotos(projectId, PhotoTypes.Drawing);
    }

    public async getDeletedDrawings(projectId: string): Promise<ImageItem[]> {
        return await this.getPhotos(projectId, PhotoTypes.DeletedDrawings);
    }

    public async getDeletedPictures(projectId: string) : Promise<ImageItem[]> {
        return await this.getPhotos(projectId, PhotoTypes.DeletedPicture);
    }

    public getDownloadAllFilesUrl(projectId: string) : string {
        return `${environment.fswApiUrl}api2/service/image/all/${projectId}`;
    }

    public async getAllImages(projectId: string) : Promise<ImageItem[]> {
        try {
            const endpoint = `${this._api}/${projectId}/images/all`;
            const data = await this._http.get<ImageItem[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<ImageItem>();
        }
    }

    private async getPhotos(projectId: string, type: string) : Promise<ImageItem[]> {
        try {
            const endpoint = `${this._api}/${projectId}/${type}`;
            const data = await this._http.get<ImageItem[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<ImageItem>();
        }
    }
}
