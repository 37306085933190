<dialog-action-card
    [icon]="'edit'"
    [title]="translate.instant('labour.edit.laborHoursUpdate')"
    [isLoaded]="!loading"
    (close)="onClose()">
    <div fxLayout="column" fxFill class="labor-edit-main-container">
        <div fxLayout="column" fxLayoutGap="10px" fxFlex>
            <div *ngIf="!loadFailed">
                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field fxFlex="80">
                        <label>{{ 'labour.edit.reasonForUpdating' | translate }}</label>
                        <textarea matInput [(ngModel)]="labor.reason" attr.aria-label="{{ 'labour.edit.enterReason' | translate }}" required>
                        </textarea>
                        <mat-hint *ngIf="!labor.reason" align="start">
                            {{ 'labour.edit.fillInReason' | translate }}
                        </mat-hint>
                    </mat-form-field>
                    <mat-form-field fxFlex="20">
                        <label>{{ 'labour.edit.totalHours' | translate }}</label>
                        <input matInput disabled type="number" [(ngModel)]="labor.hours"/>
                    </mat-form-field>
                </div>

                <div *ngIf="updatedLabor.type === 0" fxLayout="column" class="auto-complete-list">
                    <mat-form-field class="labor-edit-auto-complete-field">
                        <input type="text" placeholder="{{ 'labour.edit.changeLabourRecord' | translate }}" (blur)="checkInputValueFromList()"
                               matInput [matAutocomplete]="auto" [(ngModel)]="autoCompleteSearch" (ngModelChange)="projectListSearch()">
                        <mat-autocomplete (optionSelected)="projectListSearchItemChange($event)" #auto="matAutocomplete">
                          <cdk-virtual-scroll-viewport itemSize="50" minBufferPx="250" maxBufferPx="500" class="labor-edit-auto-complete-viewport">
                                <mat-option *cdkVirtualFor="let option of autoCompleteList" [value]="option.projectId" class="labor-edit-auto-complete-item">
                                    {{ option.projectId }}: {{option.name}}
                                </mat-option>
                            </cdk-virtual-scroll-viewport>
                        </mat-autocomplete>
                      </mat-form-field>
                    <mat-checkbox *ngIf="warningMessage" aria-label="warning message" [(ngModel)]="confirmWarning" [disabled]="disableConfirm">
                      <span [ngClass]="warningMessageClass">
                      {{warningMessage}}
                    </span>
                    </mat-checkbox>
                </div>
                <div *ngIf="projectAttributes">
                    <div *ngIf="projectAttributes.mileage" fxLayout="row">
                        <mat-form-field>
                            <mat-label>{{ 'labour.edit.mileage' | translate }}</mat-label>
                            <input matInput type="number" min="0" step="0.5" [(ngModel)]="labor.mileage">
                            <mat-hint *ngIf="hasMileageErrors" align="start">
                                {{ 'labour.edit.hoursWarning' | translate }}
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div *ngIf="projectAttributes.zone">
                        <mat-checkbox [(ngModel)]="labor.zone">
                            {{ 'labour.edit.zonePay' | translate }}
                        </mat-checkbox>
                    </div>
                    <div *ngIf="projectAttributes.roomAndBoard">
                        <mat-checkbox [(ngModel)]="labor.roomAndBoard">
                            {{ 'labour.edit.roomAndBoard' | translate }}
                        </mat-checkbox>
                    </div>
                    <div *ngIf="projectAttributes.initialAllowance">
                        <mat-checkbox [(ngModel)]="labor.initialAllowance">
                            {{ 'labour.edit.initialAllowance' | translate }}
                        </mat-checkbox>
                    </div>
                    <div *ngIf="projectAttributes.returnAllowance">
                        <mat-checkbox [(ngModel)]="labor.returnAllowance">
                            {{ 'labour.edit.returnAllowance' | translate }}
                        </mat-checkbox>
                    </div>
                    <div *ngIf="projectAttributes.meal">
                        <mat-checkbox [(ngModel)]="labor.meal">
                            {{ 'labour.edit.meal' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
                <div *ngIf="laborUpdateError" class="error-messages">
                    <h3>{{laborUpdateErrorMsg}}</h3>
                </div>
            </div>
            <div *ngIf="loadFailed">
                <h3>{{ 'labour.edit.failedToLoadWarning' | translate }}</h3>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
            <button mat-stroked-button color="primary" (click)="saveLabor()" [disabled]="saveDisabled">
                {{ 'labour.edit.save' | translate }}
            </button>
        </div>
    </div>
</dialog-action-card>
