import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import JsonUtility from '../../utility/json-utility.service';
import AppSettingEntity from '../../../data-models/app-setting-entity';
import SalesCodeConfig from '../../../data-models/app-configs/sales-code-config';
import CodeDescription from '../../../data-models/code-description';
import DependencyAttribution from '../../../data-models/dependency-attribution';
import ProjectTypeSalesCodeMapping from '../../../data-models/app-configs/project-type-sales-code-mapping';
import { environment } from '../../../../../environments/environment';
import QuoteSalesCodeDto from '../../../data-models/quote-general-info/quote-sales-code-dto';

@Injectable({
    providedIn: 'root'
})
export class AppConfigHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/appsettings`;
    private _jobTypesShownInProjectFilter: string[];
    private _quoteStatus: string[];
    private _statesCA: string[];
    private _statesUS: string[];
    private _salesCodeConfig: SalesCodeConfig[] = [];
    private _featureFlags: Map<string, boolean> = new Map<string, boolean>();

    constructor(private _http: HttpClient) { }

    public async getAvailableSources(): Promise<string[]> {
        return await this._http.get<string[]>(`${this._api}/getEnvironments`).toPromise();
    }

    public async sendDataMigration(source: string, option: string, jobType: string) : Promise<boolean> {
        const url = `${this._api}/migrate/${option}/${source}${jobType ? `/${jobType}` : ''}`;

        return await this._http.put<boolean>(url, '').toPromise();
    }

    public async getProjectJobTypesInProjectSummaryFilter(useCache = true): Promise<string[]> {
        try {
            if (!(useCache && this._jobTypesShownInProjectFilter)) {
                const endpoint = `${this._api}/filter-display/project-codes`;
                this._jobTypesShownInProjectFilter = await this._http.get<string[]>(endpoint).toPromise();
            }

            return [...this._jobTypesShownInProjectFilter];
        }
        catch {
            return [];
        }
    }

    public async getUSStates(useCache = true): Promise<string[]> {
        try {
            if (!(useCache && this._statesUS)) {
                const endpoint = `${this._api}/us/states`;
                this._statesUS = await this._http.get<string[]>(endpoint).toPromise();
            }

            return [...this._statesUS];
        }
        catch {
            return new Array<string>();
        }
    }

    public async getCAStates(useCache = true): Promise<string[]> {
        try {
            if (!(useCache && this._statesCA)) {
                const endpoint = `${this._api}/ca/states`;
                this._statesCA = await this._http.get<string[]>(endpoint).toPromise();
            }

            return [...this._statesCA];
        }
        catch {
            return new Array<string>();
        }
    }

    public async getNAStates(useCache = true): Promise<string[]> {
        const promises = [this.getUSStates(useCache), this.getCAStates(useCache)];
        const [usStates, caStates] = await Promise.all(promises);

        return [...usStates, ...caStates].sort();
    }

    public async getQuoteSalesCode(action: string): Promise<QuoteSalesCodeDto[]> {
        try {
            const endpoint = `${this._api}/quote-sales-code/${action}`;
            const response = await this._http.get<QuoteSalesCodeDto[]>(endpoint).toPromise();
            return JsonUtility.toCamelCaseKeys(response);
        }
        catch {
            return [];
        }
    }

    public async getHandOffMeetingThreshold(): Promise<number> {
        try {
            const endpoint = `${this._api}/getByName/HandOffMeetingThreshold`;
            const data = await this._http.get<AppSettingEntity>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data).value;
        }
        catch {
            return 0;
        }
    }

    public async updateHandoffMeetingThreshold(threshold: number): Promise<boolean> {
        try {
            const endpoint = `${this._api}/hand-off-meeting-threshold`;
            const headers = { 'content-type': 'application/json' };

            return await this._http.put<boolean>(endpoint, JSON.stringify(threshold), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getFeatureFlag(name: string): Promise<boolean> {
        try {
            if (!this._featureFlags.has(name)) {
                const endpoint = `${this._api}/getByName/${name}`;
                const data = await this._http.get<AppSettingEntity>(endpoint).toPromise();
                const value = Boolean(JsonUtility.toCamelCaseKeys(data).value);
                this._featureFlags.set(name, value);
            }

            return this._featureFlags.get(name);
        }
        catch {
            return false;
        }
    }

    public async getTaskCodes(): Promise<CodeDescription[]> {
        try {
            const endpoint = `${this._api}/task-codes`;
            const codes = await this._http.get<CodeDescription[]>(endpoint).toPromise();

            return codes.map(_ => JsonUtility.toCamelCaseKeys(_));
        }
        catch {
            return [];
        }
    }

    public async getSalesCodeConfig(useCache = true): Promise<SalesCodeConfig[]> {
        try {
            if (!(useCache && this._salesCodeConfig.length)) {
                const endpoint = `${this._api}/sales-codes`;
                const data = await this._http.get<SalesCodeConfig[]>(endpoint).toPromise();
                this._salesCodeConfig = JsonUtility.toCamelCaseKeys(data);
            }

            return [...this._salesCodeConfig];
        }
        catch {
            return [];
        }
    }

    public async updateTaskCodeMapping(salesCode: string, taskCode: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/sales-codes/${salesCode}/task-code`;
            const headers = { 'content-type': 'application/json' };

            return await this._http.put<boolean>(endpoint, JSON.stringify(taskCode), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getLogoUrl(): Promise<string> {
        try {
            const endpoint = `${this._api}/logo`;
            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return '';
        }
    }

    public async getSignInOutNumber(): Promise<string> {
        try {
            const endpoint = `${this._api}/sign-in-out-number`;

            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return '';
        }
    }

    public async updateSignInOutNumber(phoneNumber: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/sign-in-out-number`;
            const headers = { 'content-type': 'application/json' };

            return await this._http.put<boolean>(endpoint, JSON.stringify(phoneNumber), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getCompanyName(): Promise<string> {
        try {
            const endpoint = `${this._api}/getByName/CompanyName`;
            const data = await this._http.get<AppSettingEntity>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data).literalValue;
        }
        catch {
            return '';
        }
    }

    public async getProjectTypeSalesCodeMapping(): Promise<ProjectTypeSalesCodeMapping[]> {
        try {
            const endpoint = `${this._api}/sales-codes/mappings`;
            const data = await this._http.get<ProjectTypeSalesCodeMapping[]>(endpoint).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getDependencyAttributions(): Promise<DependencyAttribution[]> {
        try {
            const endpoint = `${this._api}/attributions`;
            const data = await this._http.get<DependencyAttribution[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getCopyrightInfo(): Promise<string> {
        try {
            const endpoint = `${this._api}/copyright-info`;
            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return `Copyright © ${new Date().getFullYear()}`;
        }
    }

    public async getTermsOfServicePdf() : Promise<Blob> {
        try {
            const endpoint = `${this._api}/terms-of-service`;

            const data = await this._http.get(endpoint, {
                headers: { 'content-type': 'application/json' },
                responseType: 'arraybuffer' }).toPromise();
            return new Blob([data], { type: 'application/pdf' });
        }
        catch {
            return null;
        }
    }
}
