import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { AuthLockService } from '../../../core/services/auth-lock-service/auth-lock-service';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(private _authService: AuthLockService,
        private _state: StateService,
        private _userHttpService: UserHttpService,
        private _featureFlagService: FeatureFlagService) {

    }

    public ngOnInit(): void {
        this._authService.login();
        this._authService.isAuthenticated.subscribe(async isAuthenticated => {
            if (isAuthenticated) {
                const user = await this._userHttpService.getUserInfo();
                await this._featureFlagService.initializeFeatureFlagClient(user);

                const returnState = this._state.params.returnState;
                const extraParameters = this._state.params.extra;
                this._state.transition?.abort();
                this._state.go(returnState? `${returnState}` : 'projects', extraParameters);
            }
        });
    }
}
