<div fxLayout="column" fxLayoutGap="15px" class="history-grid-content">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="config.historyList.length" fxFlex>
        <kendo-grid [kendoGridBinding]="config.historyList" [pageSize]="7" [pageable]="true" [style.height.%]="100">
            <kendo-grid-column field="selected" title="" width="75px">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <mat-checkbox [(ngModel)]="dataItem.selected">
                    </mat-checkbox>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="name" title="{{ 'dispatch.historyStep.table.projectName' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="projectId" title="{{ 'dispatch.historyStep.table.projectId' | translate }}" width="175px">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <a mat-button href="{{'/projects/' + dataItem.projectId}}" target="_blank" color="primary">
                        {{ dataItem.projectId }}
                        <mat-icon>
                            open_in_new
                        </mat-icon>
                    </a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="createdTime" title="{{ 'dispatch.historyStep.table.date' | translate }}" width="150px">
            </kendo-grid-column>
            <kendo-grid-column field="type" title="{{ 'dispatch.historyStep.type' | translate }}" width="300px">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.type | jobTypeText: user?.language }}
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!config.historyList.length" fxFlex>
        <label>
            {{ 'dispatch.historyStep.noPreviousProjectsFound' | translate }}
        </label>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <button mat-stroked-button (click)="stepClicked.emit('previous')" color="primary">{{ 'dispatch.sharedStepLabels.previousButton' | translate }}</button>
        <button mat-stroked-button (click)="setCompleteAndMove()" color="primary">{{ 'dispatch.sharedStepLabels.nextButton' | translate }}</button>
    </div>
</div>
