import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ItemExpense } from '../../../../core/data-models/budget-estimations/expenses/item-expense';
import { ValidatorService } from '../../../../core/services/validator-service/validator-service';
import { MaterialRate } from '../../../../core/data-models/rates/service-rates/material-rate';
import { TypeRecord } from '../../../../core/data-models/generic/type-record';
import { BudgetEstimationService } from '../budget-estimation.service';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-materials-section',
    templateUrl: './materials-section.component.html',
    styleUrls: ['./materials-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialsSectionComponent implements OnInit, OnDestroy {
    @Input() public form: UntypedFormGroup;
    @Input() public calculationUpdated$: Subject<void>;
    @Input() public materialRates: MaterialRate[];
    @Input() public readonly = false;
    public selectedOption: TypeRecord;
    public isReady = false;
    private _isComponentActive = true;
    private readonly _translationRoot = 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.materialsSection';
    get materialsOptions(): UntypedFormArray {
        return this.form.get('materialsSection').get('entries') as UntypedFormArray;
    }

    get totalCost(): UntypedFormControl {
        return this.form.get('materialsSection').get('totalCost') as UntypedFormControl;
    }

    get intoStock(): UntypedFormControl {
        return this.form.get('materialsSection').get('totalCostIntoStock') as UntypedFormControl;
    }

    get materialsItemTypes(): TypeRecord[] {
        return this.materialRates.map(rate => rate.type);
    }

    get unselectedOptions(): TypeRecord[] {
        const selectedOptions = this.materialsOptions.value as ItemExpense[] ?? [];
        if (selectedOptions.length === 0) {
            return this.materialsItemTypes;
        }

        return this.materialsItemTypes.filter(itemType => !selectedOptions.some(selected => itemType.identifier === selected.typeIdentifier));
    }

    get showTotalCost(): boolean {
        return this.materialsOptions.length > 0;
    }

    get addButtonDisabled(): boolean {
        return !this.selectedOption;
    }

    constructor(
        private _translate: TranslateService,
        private _budgetEstimationService: BudgetEstimationService,
        private _validatorService: ValidatorService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    public ngOnInit(): void {
        this.isReady = true;

        const customMaterials = this.materialsOptions.controls.filter(control => Boolean(control.get('isCustom').value));
        for (let i = 0; i < customMaterials.length; i++) {
            const control = customMaterials[i].get('customName');
            this.setValidatorsOnCustomMaterial(control as UntypedFormControl);
        }

        this.calculationUpdated$.pipe(
            takeWhile(() => this._isComponentActive)
        ).subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });
    }

    public addSelectedOption(): void {
        const control = this._budgetEstimationService.createMaterialsFormField(ItemExpense.fromTypeRecord(this.selectedOption));
        this.selectedOption = null;
        this.materialsOptions.push(control);

        this._changeDetectorRef.markForCheck();
    }

    public addCustomMaterial(): void {
        const form = this._budgetEstimationService.createMaterialsFormField();
        this.setValidatorsOnCustomMaterial(form.get('customName') as UntypedFormControl);

        this.materialsOptions.push(form);
        form.markAllAsTouched();

        this._changeDetectorRef.markForCheck();
    }

    public removeOption(index: number): void {
        this.materialsOptions.removeAt(index);
        this.selectedOption = null;

        this._changeDetectorRef.markForCheck();
    }

    public getName(identifier: string): string {
        return this.materialsItemTypes.find(itemType => itemType.identifier === identifier)?.name ?? '';
    }

    public localize(token: string): string {
        return this._translate.instant(`${this._translationRoot}.${token}`);
    }

    public typeRecordComparer(index: number, item: TypeRecord): string {
        return item.identifier;
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
    }

    private isValidValue(control: UntypedFormControl): boolean {
        const value = (control?.value ?? '').toLowerCase().trim();
        const parentForm = control?.parent;
        const identifier = parentForm?.get('typeIdentifier')?.value;
        if (!value || !identifier) {
            return false;
        }

        const knownNames = this.materialsItemTypes
            .filter(itemType => Boolean(itemType?.name?.trim() ?? ''))
            .map(itemType => itemType.name.trim().toLowerCase());

        const customNames = this.materialsOptions.controls
            .filter((material: UntypedFormGroup) => {
                const isCustom = material.get('isCustom')?.value;

                if (!isCustom) {
                    return false;
                }

                const customName = material.get('customName').value;
                if (!customName) {
                    return false;
                }

                const typeIdentifier = material.get('typeIdentifier')?.value;
                if (!typeIdentifier) {
                    return false;
                }

                return typeIdentifier !== identifier;
            }).map((material: UntypedFormGroup) => material.get('customName').value.trim().toLowerCase());

        const setOfNames = new Set([ ...knownNames, ...customNames ]);

        return !setOfNames.has(value);
    }

    private setValidatorsOnCustomMaterial(control: UntypedFormControl): void {
        control.setValidators([Validators.required, this._validatorService.callbackValidator(this.isValidValue.bind(this), 'forbiddenValue')]);
        control.updateValueAndValidity();
    }
}
