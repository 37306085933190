import { Pipe, PipeTransform } from '@angular/core';

import Address from '../../../core/data-models/address';

@Pipe({
    name: 'fullAddress'
})
export class FullAddressPipe implements PipeTransform {

    public transform(address: Address): string {
        const address2 = address.addr2 ? `, ${address.addr2}` : '';

        return `${address.addr1}${address2}, ${address.city}, ${address.state}, ${address.zip}`;
    }
}
