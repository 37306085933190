import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

import TimelineEvent from '../../../../../core/data-models/timeline-event';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'timeline-event-card',
    styleUrls: ['./timeline-event-card.component.scss'],
    templateUrl: './timeline-event-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineEventCardComponent implements OnInit {
    @Input() public event: TimelineEvent;
    @Input() public hasTargetPerformer = false;
    @Output() public filterByPerformer = new EventEmitter<string>();
    private _date: Date = null;
    private _useFullDateTime = true;
    private _performerActionMessage = '';
    private _todayLabel = '';

    constructor(
        private _translate: TranslateService,
        private _titleCasePipe: TitleCasePipe) { }

    get tooltip(): string {
        return this.hasTargetPerformer ? '' : this._performerActionMessage;
    }

    get performer(): string {
        return this._titleCasePipe.transform(this.event.performer);
    }

    get date(): Date {
        return this._date;
    }

    get dateString(): string {
        if (!this.date) {
            return '';
        }
        const date = this.date.toISOString().substring(0, 10);
        const today = new Date().toISOString().substring(0, 10);
        return date === today ? this._todayLabel : date;
    }

    get useFullDateTime(): boolean {
        return this._useFullDateTime;
    }

    public ngOnInit(): void {

        const performerTranslateKey = 'shared.timelineEventCard.showPerformersActions';
        const performerParameters = { performer: this.performer };
        this._performerActionMessage = this._translate.instant(performerTranslateKey, performerParameters);
        this._todayLabel = this._translate.instant('shared.timelineEventCard.today');
        this._date = this.event.time ? new Date(this.event.time) : null;
        this._useFullDateTime = this.event.time?.length > 10 ;
    }
}
