import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { aggregateBy, AggregateDescriptor, AggregateResult } from '@progress/kendo-data-query';
import LaborSummary from '../../../../core/data-models/labor/labor-summary';
import { LaborHttpService } from '../../../../core/services/http/labor-http/labor-http.service';
import { LaborStatusService } from '../../../../core/services/labor-status/labor-status.service';

@Component({
    selector: 'project-labor-panel',
    styleUrls: ['./project-labor-panel.component.scss'],
    templateUrl: './project-labor-panel.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectLaborPanelComponent implements OnInit {
    @Input() public projectId: string;
    public gridViewData: LaborSummary[] = [];
    public loading: boolean;
    public aggregates: AggregateDescriptor[] = [{ field: 'hours', aggregate: 'sum' }];
    public total: AggregateResult;

    public constructor(
        private _laborHttpService: LaborHttpService,
        private _laborStatusService: LaborStatusService) {
    }

    public async ngOnInit(): Promise<void> {
        this.loading = true;
        this.gridViewData = await this._laborHttpService.getLaborSummaryByProject(this.projectId);
        this.total = aggregateBy(this.gridViewData, this.aggregates);
        this.loading = false;
    }

    public translateStatus(status: string): string {
        return this._laborStatusService.getTranslation(status);
    }

}
