import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import UserInfo from '../../../../core/data-models/user-info';
import UserRoleDto from '../../../../core/data-models/dto-models/user-role-dto';
import Branch from '../../../../core/data-models/branch';

@Component({
    selector: 'role-list-viewer',
    styleUrls: ['./role-list-viewer.component.scss'],
    templateUrl: './role-list-viewer.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleListViewerComponent {
    @Input() public user: UserInfo;
    @Input() public roles: UserRoleDto[] = [];
    @Input() public branches: Branch[] = [];
    @Output() public addRole = new EventEmitter();
    @Output() public openRole = new EventEmitter<UserRoleDto>();
    @Output() public deleteRole = new EventEmitter<UserRoleDto>();
    public readonly visibleColumns = ['name', 'summary', 'lastModified'];
}
