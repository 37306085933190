import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-simple-photo-card',
    styleUrls: ['./simple-photo-card.component.scss'],
    templateUrl: './simple-photo-card.component.html'
})
export class SimplePhotoCardComponent {
    @Input() public image = '';
}
