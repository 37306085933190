import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentageString'
})
export class PercentageStringPipe implements PipeTransform {

    public transform(percentage: number, decimal = 2): string {
        return `${Number(percentage).toFixed(decimal)}%`;
    }
}
