import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Branch } from '../../../../core/data-models/branch/branch';
import { TypeRecord } from '../../../../core/data-models/generic/type-record';

@Component({
    selector: 'app-project-section',
    styleUrls: ['./project-section.component.scss'],
    templateUrl: './project-section.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSectionComponent implements OnInit {
    @Input() public form: UntypedFormGroup;
    @Input() public projectTypes: TypeRecord[] = [];
    @Input() public branches: Branch[] = [];
    @Input() public readonly = false;
    public branch: Branch;

    constructor(private _translate: TranslateService,
                private _changeDetectorRef: ChangeDetectorRef) { }

    get branchText(): string {
        const key = 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.steps.project.branch';
        const parameters = { name: this.branch.name, code: this.branch.code };

        return this._translate.instant(key, parameters);
    }

    public ngOnInit(): void {
        const code = this.form.get('branchCode').value;
        this.branch = this.branches.find(branch => branch.code === code);
        this._changeDetectorRef.markForCheck();
    }

    public isSameProjectType(option: TypeRecord, value: TypeRecord): boolean {
        return option?.identifier === value?.identifier;
    }
}
