import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-quote-report-options-dialog',
    styleUrls: ['./quote-report-options-dialog.component.scss'],
    templateUrl: './quote-report-options-dialog.component.html'
})
export class QuoteReportOptionsDialogComponent implements OnInit {
    public quoteDate: Date;
    public useCustomDate = false;
    public minDate: Date;
    public maxDate: Date;

    constructor(public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: { quoteCreationDate: string },
        private _dialogRef: MatDialogRef<QuoteReportOptionsDialogComponent>) { }

    public ngOnInit(): void {
        this.quoteDate = new Date(this.data.quoteCreationDate);
        this.minDate = this.quoteDate;
        this.maxDate = new Date();
    }

    public onClose(emit: boolean): void {
        this._dialogRef.close(emit? this.quoteDate : null);
    }
}