import { TranslateService } from '@ngx-translate/core';
import { LeakReportType } from '../../../../core/enums/leak-report-type.enum';

export default function getLeakReportTemplateEditorButtons(translate: TranslateService, scope: LeakReportType): { name: string, placeholder: string, identifier: string, scope: LeakReportType[] }[] {
    const options = [{
        name: translate.instant('admin.leakReportTemplateEditor.leakActivityLabel'),
        placeholder: translate.instant('admin.leakReportTemplateEditor.leakActivityPlaceholder'),
        identifier: 'lac',
        scope: [ LeakReportType.TemporaryLeak, LeakReportType.PermanentLeak ]
    }, {
        name: translate.instant('admin.leakReportTemplateEditor.leakAreaLabel'),
        placeholder: translate.instant('admin.leakReportTemplateEditor.leakAreaPlaceholder'),
        identifier: 'lar',
        scope: [ LeakReportType.TemporaryLeak, LeakReportType.PermanentLeak ]
    }, {
        name: translate.instant('admin.leakReportTemplateEditor.workDoneLabel'),
        placeholder: translate.instant('admin.leakReportTemplateEditor.workDonePlaceholder'),
        identifier: 'wd',
        scope: [ LeakReportType.TemporaryLeak, LeakReportType.PermanentLeak ]
    }, {
        name: translate.instant('admin.leakReportTemplateEditor.findingsLabel'),
        placeholder: translate.instant('admin.leakReportTemplateEditor.findingsPlaceholder'),
        identifier: 'fnd',
        scope: [ LeakReportType.TemporaryLeak, LeakReportType.PermanentLeak ]
    }, {
        name: translate.instant('admin.leakReportTemplateEditor.locationLabel'),
        placeholder: translate.instant('admin.leakReportTemplateEditor.locationPlaceholder'),
        identifier: 'loc',
        scope: [ LeakReportType.TemporaryLeak, LeakReportType.PermanentLeak ]
    }, {
        name: translate.instant('admin.leakReportTemplateEditor.tempRepairLabel'),
        placeholder: translate.instant('admin.leakReportTemplateEditor.tempRepairPlaceholder'),
        identifier: 'tmp',
        scope: [ LeakReportType.TemporaryLeak, LeakReportType.PermanentLeak ]
    }, {
        name: translate.instant('admin.leakReportTemplateEditor.roofSystemsLabel'),
        placeholder: translate.instant('admin.leakReportTemplateEditor.roofSystemsPlaceholder'),
        identifier: 'rs',
        scope: [ LeakReportType.TemporaryLeak, LeakReportType.PermanentLeak ]
    }, {
        name: translate.instant('admin.leakReportTemplateEditor.dateLabel'),
        placeholder: translate.instant('admin.leakReportTemplateEditor.datePlaceholder'),
        identifier: 'dt',
        scope: [ LeakReportType.TemporaryLeak, LeakReportType.PermanentLeak ]
    }, {
        name: translate.instant('admin.leakReportTemplateEditor.leakReportsLabel'),
        placeholder: translate.instant('admin.leakReportTemplateEditor.leakReportsPlaceholder'),
        identifier: 'lr',
        scope: [ LeakReportType.MultiLeak ]
    }];

    return options.filter(_ => _.scope.includes(scope));
}