import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import CodeDescription from '../../../data-models/code-description';
import WorkOrderTemplate from '../../../data-models/work-order/work-order-template';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WorkOrderTemplateHttpService {
    public readonly _api = `${environment.fswApiUrl}api2/service/work-order`;

    constructor(private _http: HttpClient, public _translate: TranslateService) { }

    public async getTemplates(): Promise<WorkOrderTemplate[]> {
        try {
            const endpoint = `${this._api}/templates`;
            const data = await this._http.get<WorkOrderTemplate[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async addWorkOrderTemplate(template :WorkOrderTemplate): Promise<CodeDescription> {
        const internalErrorMessage = this._translate.instant('core.httpServices.internalError');
        try {
            const endpoint = `${this._api}/template/new`;
            const data = await this._http.post<WorkOrderTemplate>(endpoint, template).toPromise();
            const convertedData = JsonUtility.toCamelCaseKeys(data);
            const okLabel = this._translate.instant('core.generic.ok');

            return convertedData? new CodeDescription('200', okLabel, convertedData) : new CodeDescription('500', internalErrorMessage);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const isUnknownError = httpError.statusText === 'Unknown Error';
            const unauthorizedErrorMessage = this._translate.instant('core.httpServices.forbidden');

            switch (httpError.status) {
                case 403: return new CodeDescription('400', isUnknownError ? internalErrorMessage : unauthorizedErrorMessage);
                default: return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async updateWorkOrderTemplate(template :WorkOrderTemplate): Promise<CodeDescription> {
        const internalErrorMessage = this._translate.instant('core.httpServices.internalError');
        try {
            const endpoint = `${this._api}/template/update`;
            const data = await this._http.post<WorkOrderTemplate>(endpoint, template).toPromise();
            const convertedData = JsonUtility.toCamelCaseKeys(data);
            const okLabel = this._translate.instant('core.generic.ok');

            return convertedData? new CodeDescription('200', okLabel, convertedData) : new CodeDescription('500', internalErrorMessage);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const isUnknownError = httpError.statusText === 'Unknown Error';
            const unauthorizedErrorMessage = this._translate.instant('core.httpServices.forbidden');

            switch (httpError.status) {
                case 403: return new CodeDescription('400', isUnknownError ? internalErrorMessage : unauthorizedErrorMessage);
                default: return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async deleteWorkOrderTemplate(templateId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/template/delete/${templateId}`;

            return await this._http.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }
}
