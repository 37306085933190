import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

import GridItemEditOption from '../../../../core/data-models/grid-item-edit-option';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'expandable-textarea-card',
    styleUrls: ['./expandable-textarea-card.component.scss'],
    templateUrl: './expandable-textarea-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableTextareaCardComponent {
    @Input() public icon = '';
    @Input() public title = '';
    @Input() public content = '';
    @Input() public isHtml = false;
    @Input() public allowExpand = true;
    @Input() public allowEdit = false;
    @Output() public contentUpdate = new EventEmitter<string>();
    private _editInstruction: string;
    private _copyInstruction: string;
    private _clickTo: string;
    private _collapse: string;
    private _expand: string;

    get contentTitle(): string {
        return this.allowEdit ? this._editInstruction: this._copyInstruction;
    }

    get editOptions(): GridItemEditOption {
        return this.allowEdit ? new GridItemEditOption() : null;
    }

    constructor(private _translate: TranslateService) {
        this._editInstruction = this._translate.instant('shared.expandableTextareaCard.editContentInstruction');
        this._copyInstruction = this._translate.instant('shared.expandableTextareaCard.copyContentInstruction');

        this._clickTo = this._translate.instant('shared.expandableTextareaCard.clickTo');
        this._collapse = this._translate.instant('shared.expandableTextareaCard.collapse');
        this._expand = this._translate.instant('shared.expandableTextareaCard.expand');
    }

    public getCardTitle(expanded: boolean): string {
        if (!this.allowExpand) {
            return this.title;
        }

        return `${this.title} (${this._clickTo} ${expanded ? this._collapse: this._expand})`;
    }
}
