<div class="user-comments-panel-container" fxLayout="column" fxLayoutAlign="start center">
    <div *ngIf="!isLoaded" fxFlex="80" fxLayoutAlign="center center">
        <dots-loader></dots-loader>
    </div>

    <div *ngIf="isLoaded && comments.length"
        class="user-comments-panel-items"
        fxLayout="column"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        [fxFlex]="isInsertMode ? 70 : 80">

        <user-comment-card *ngFor="let comment of comments"
            [user]="user"
            [comment]="comment"
            [color]="getColor(comment)"
            [isReadonly]="isReadonly(comment)"
            [canEditComment]="canEditComment"
            [canDeleteComment]="canDeleteComment"
            [branchTimeZone]="userBranchTimeZone"
            (commentEditStart)="editingComment = $event"
            (commentEditEnd)="editingComment = null"
            (commentEditSave)="commentUpdate.emit([$event, comment])"
            (commentDeleted)="commentDelete.emit(comment)">
        </user-comment-card>
    </div>

    <div class="user-comments-panel-no-record"
        *ngIf="isLoaded && !comments.length"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="6px"
        fxFlex="80">

        <span>{{ 'shared.userComments.panel.nothingAddedYet' | translate }}</span>
        <span *ngIf="canAddComment">{{ 'shared.userComments.panel.addNotesMessage' | translate }}</span>
    </div>

    <div class="user-comments-panel-editor"
        *ngIf="!isInsertMode && canAddComment"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="20px"
        fxFlex="20">

        <div class="user-comments-panel-editor-avatar" fxLayoutAlign="center center">
            <span>{{ initials }}</span>
        </div>

        <div class="user-comments-panel-editor-trigger" (click)="onInsert()">{{ 'shared.userComments.panel.addNotesMessage2' | translate }}</div>
    </div>

    <div class="user-comments-panel-editor"
        *ngIf="isInsertMode"
        [ngClass]="{ 'user-comments-panel-editor-insert': isInsertMode }"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="20px"
        fxFlex="30">

        <div class="user-comments-panel-editor-avatar" fxLayoutAlign="center center" fxFlexAlign="start">
            <span>{{ initials }}</span>
        </div>

        <user-comment-editor (editSaved)="onCommentAdd($event)"
            (editCancelled)="isInsertMode = false"
            fxFlexAlign="start">
        </user-comment-editor>
    </div>
</div>
