import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import JsonUtility from '../../utility/json-utility.service';
import ProjectAttachment from '../../../data-models/project-attachment';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AttachmentHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/attachment`;

    constructor(private _http: HttpClient) { }

    public async getProjectAttachmentsWithCount(projectId: string, mode: string): Promise<ProjectAttachment[]> {
        try {
            const endpoint = `${this._api}/count/${projectId}?mode=${mode}`;
            const data = await this._http.get<ProjectAttachment[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }
}
