import { Component, Input, Output, EventEmitter, ElementRef, ViewEncapsulation } from '@angular/core';
import { PopupService, POPUP_CONTAINER } from '@progress/kendo-angular-popup';
import SOAddress from '../../../../../core/data-models/so-address';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'customer-site-select',
    styleUrls: ['./customer-site-select.component.scss'],
    templateUrl: './customer-site-select.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: POPUP_CONTAINER,
        useFactory: () => {
            return {
                nativeElement: document.querySelector('.site-selection-kendo-grid-div, customer-select-management')
            } as ElementRef;
        }
    },
    PopupService
    ]
})

export class CustomerSiteSelectComponent {

    @Input() customerDisplayName: string;
    @Input() availableSites: SOAddress[];
    @Input() allowCreate = true;
    @Output() siteSelected = new EventEmitter<SOAddress>();

    constructor(public translate: TranslateService) {}
}
