import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import UserInfo from '../../../../core/data-models/user-info';
import CodeMapping from '../../../../core/data-models/generic/code-mapping';
import SalesCodeConfig from '../../../../core/data-models/app-configs/sales-code-config';
import ProjectMergeRule from '../../../../core/data-models/project-merge-rule';
import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';
import LanguageUtility from '../../../../core/services/utility/language-utility/language-utility.service';
import { ConfirmDialogComponent } from '../../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';

@Component({
    selector: 'merge-rule-editor',
    styleUrls: ['./merge-rule-editor.component.scss'],
    templateUrl: './merge-rule-editor.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MergeRuleEditorComponent implements OnInit {
    @Input() public user: UserInfo;
    @Input() public rule: ProjectMergeRule;
    @Input() public salesCodes: SalesCodeConfig[] = [];
    @Input() public savingState = { isSaving: false, isSaved: true };
    @Output() public update = new EventEmitter<ProjectMergeRule>();
    @Output() public close = new EventEmitter();
    public sourceFilter = '';
    private readonly _maxPlaceholders = 18;
    private _isAscending = true;

    constructor(private _translate: TranslateService, private _dialog: MatDialog) { }

    get isAscending(): boolean {
        return this._isAscending;
    }

    get ruleName(): string {
        return LanguageUtility.getLocalizedContent(this.user.language, this.rule.names);
    }

    get visibleMappings(): CodeMapping<string>[] {
        return this.rule.mappings.filter(_ => {
            if (!_.source || !_.target) {
                return true;
            }

            return !this.sourceFilter || this.sourceFilter === _.source;
        });
    }

    get placeholders(): number[] {
        const total = this._maxPlaceholders - this.visibleMappings.length;

        return new Array(Math.max(1, total)).fill(0);
    }

    get sourceSalesCodes(): SalesCodeConfig[] {
        const used = new Map<string, number>();

        for (const { source } of this.rule.mappings) {
            const total = used.has(source) ? used.get(source) : 0;
            used.set(source, total + 1);
        }

        return this.salesCodes.filter(_ => !used.has(_.code) || used.get(_.code) < this.salesCodes.length);
    }

    get hasValidMappings(): boolean {
        return this.rule.mappings.every(_ => _.source && _.target);
    }

    get canAddMapping(): boolean {
        return this.rule.mappings.length < Math.pow(this.salesCodes.length, 2) && this.hasValidMappings;
    }

    public ngOnInit(): void {
        this.sortMappings();
    }

    public getSalesCodeName(config: SalesCodeConfig): string {
        return LanguageUtility.getLocalizedContent(this.user.language, config.names);
    }

    public toggleSorting(): void {
        this._isAscending = !this._isAscending;
        this.sortMappings();
    }

    public onMappingSetup(): void {
        if (this.canAddMapping) {
            this.rule.mappings.push(new CodeMapping<string>('', ''));
            this.savingState.isSaved = false;
        }
    }

    public onMappingChange(mappings: [CodeMapping<string>, CodeMapping<string>]): void {
        const [previous, current] = mappings;
        const { source, target } = previous;
        const index = this.rule.mappings.findIndex(_ => _.source === source && _.target === target);
        this.rule.mappings[index] = current;
        this.rule.mappings = this.rule.mappings.slice();
        this.savingState.isSaved = false;
    }

    public onMappingDelete(mapping: CodeMapping<string>): void {
        const { source, target } = mapping;
        this.rule.mappings = this.rule.mappings.filter(_ => _.source !== source || _.target !== target);
        this.savingState.isSaved = false;
    }

    public async onClose(): Promise<void> {
        if (this.savingState.isSaved) {
            this.close.emit();

            return;
        }

        const title = this._translate.instant('shared.gridDisplayItem.unsavedChanges');
        const message = this._translate.instant('shared.gridDisplayItem.keepChangesQuestion');
        const buttonText = this._translate.instant('shared.discard');
        const data = new ConfirmDialogOption(title, message, true, buttonText);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (await dialog.afterClosed().toPromise()) {
            this.close.emit();
        }
    }

    public onSave(): void {
        this.savingState.isSaving = true;
        this.update.emit(this.rule);
    }

    private sortMappings(): void {
        const order = this._isAscending ? 1 : -1;

        this.rule.mappings = this.rule.mappings.sort((a, b) => {
            if (a.source === b.source) {
                return a.target < b.target ? -order : order;
            }

            return a.source < b.source ? -order : order;
        });
    }
}
