<div class="upload-budget-class" id="upload-budget-content" [fxLayout]="layout" fxLayoutGap="15px" fxFill fxLayoutAlign="start center">
    <ng-container *ngIf="useBudgetFeature; else noBudgetFeature">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" >
            <mat-form-field fxFlex>
                <mat-label>{{ 'shared.budgetUpload.budgetTemplate' | translate }}</mat-label>

                <mat-select [(ngModel)]="selectedTemplate">
                    <mat-option *ngFor="let template of templates" [value]="template">
                        {{ template.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-stroked-button
                color="primary"
                [disabled]="!selectedTemplate"
                (click)="downloadTemplate(selectedTemplate)">
                {{ 'shared.download' | translate }}
            </button>
        </div>
        <div fxFlex="50" fxFlexAlign="stretch">
        <ngx-file-drop dropZoneClassName="upload-file-drop-zone" accept=".xlsm" (onFileDrop)="onBudgetFileDrop($event)">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="upload-file-drop-content" fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                        <mat-icon>get_app</mat-icon>
                        <span>{{ 'shared.budgetUpload.dropBudget' | translate }}</span>
                    </div>

                    <div class="upload-file-upload-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <button mat-stroked-button color="primary" (click)="openFileSelector()">
                            {{ 'shared.budgetUpload.browseFiles' | translate }}
                        </button>

                        <span [ngClass]="{ 'upload-file-no-file': !fileName }">{{ fileName ? fileName : noFileSelectedMessage }}</span>
                    </div>
                </div>
            </ng-template>
        </ngx-file-drop>
        </div>
    </ng-container>
    <ng-template #noBudgetFeature>
        <label>{{ 'shared.budgetUpload.featureUnavailable' | translate }}</label>
    </ng-template>
</div>
