import { WorkCategory } from '../work-category';

export default class DispatchBaseStepConfig {
    public label: string;
    public completed: boolean;
    public editable: boolean;
    public hasNext: boolean;
    public hasPrevious: boolean;
    public workCategory: WorkCategory;
    public nextButtonLabel = 'Next';
    public previousButtonLabel = 'Previous';

    public constructor(label: string, completed = false, editable = true, hasNext = true, hasPrevious = true) {
        this.label = label;
        this.completed = completed;
        this.editable = editable;
        this.hasNext = hasNext;
        this.hasPrevious = hasPrevious;
    }
}
