import { Component, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import CustomEditorOption from '../../../../core/data-models/custom-editor-option';
import { DevelopHttpService } from '../../../../core/services/http/develop-http/develop-http.service';
import { EditorUtilityService } from '../../../../core/services/utility/editor-utility/editor-utility.service';
import { CountryName } from '../../../../core/enums/country-name.enum';

@Component({
    selector: 'condition-report-disclaimer',
    styleUrls: ['./condition-report-disclaimer.component.scss'],
    templateUrl: './condition-report-disclaimer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ConditionReportDisclaimerComponent implements AfterViewInit {
    public readonly editorId = `condition-report-disclaimer-editor-${Date.now()}`;
    public readonly countries = [CountryName.CA, CountryName.US];
    public country = this.countries[0];
    public canSave = false;
    public isLoaded = false;

    constructor(private _translate: TranslateService,
                private _developHttp: DevelopHttpService,
                private _editorUtility: EditorUtilityService,
                private _snackbar: MatSnackBar) { }

    public async ngAfterViewInit(): Promise<void> {
        const defaultOptions = {
            keyup: () => this.updateSaveStatus(),
            paste: () => this.updateSaveStatus()
        };

        const disclaimer = await this._developHttp.getConditionReportDisclaimer(this.country);
        const customOptions: CustomEditorOption = { ...new CustomEditorOption(disclaimer), focusOnLoad: true };
        this._editorUtility.setupEditor(this.editorId, defaultOptions, customOptions);
        this.updateSaveStatus();
        this.isLoaded = true;
    }

    public async onCountrySelected(): Promise<void> {
        this.isLoaded = false;
        const disclaimer = await this._developHttp.getConditionReportDisclaimer(this.country);
        this._editorUtility.setContent(this.editorId, disclaimer);
        this.updateSaveStatus();
        this.isLoaded = true;
    }

    public async saveDisclaimer(): Promise<void> {
        const content = this._editorUtility.getContent(this.editorId);
        const prefix = 'admin.disclaimerSettings.conditionReportDisclaimer';

        if (await this._developHttp.updateConditionReportDisclaimer(content, this.country)) {
            const message = this._translate.instant(`${prefix}.updateDisclaimerSuccess`);
            this._snackbar.open(message, this._translate.instant('shared.ok'));
        }
        else {
            const message = this._translate.instant(`${prefix}.updateDisclaimerFailed`);
            this._snackbar.open(message, this._translate.instant('shared.ok'));
        }
    }

    private updateSaveStatus(): void {
        const content = this._editorUtility.getContent(this.editorId) ?? '';
        this.canSave = Boolean(content.replace(/&nbsp;/g, '').trim());
    }
}
