import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';

@Component({
    selector: 'app-confirm-dialog',
    styleUrls: ['./confirm-dialog.component.scss'],
    templateUrl: './confirm-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements OnInit {

    constructor(private _dialog: MatDialogRef<ConfirmDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public option: ConfirmDialogOption) { }

    get colorType(): string {
        return this.option.isWarning ? 'warn' : 'primary';
    }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        this._dialog.addPanelClass('confirm-dialog');
    }
}
