import LaborOverrides from './labor-overrides';

export default class LaborSummary {
    public employeeId: string;
    public employeeName: string;
    public jobDate: string;
    public hours: number;
    public branch: string;
    public jobId: number;
    public recordId: string;
    public recordName: string;
    public timeEntryId: number;
    public status: string;
    public lastError: string;
    public type: number;
    public note: string;
    public isSalaried = false;
    public overrides = new LaborOverrides();
}
