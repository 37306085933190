<div fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-form-field>
            <mat-label>{{ 'shared.branch' | translate }}</mat-label>
            <mat-select [(ngModel)]="query.branch">
                <mat-option value=''>{{ 'shared.all' | translate }}</mat-option>
                <mat-option *ngFor="let branch of branches" [value]="branch.name">{{branch.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'shared.jobType' | translate }}</mat-label>
            <mat-select [(ngModel)]="query.jobType">
                <mat-option value=''>{{ 'shared.all' | translate }}</mat-option>
                <mat-option *ngFor="let jobType of nonSubContractJobTypes" [value]="jobType">{{jobType}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <button mat-raised-button color="primary" (click)="loadReport()">{{ 'dataReport.fallHazardReport.loadReport' | translate }}</button>
        </div>
    </div>
    <div fxLayout="row" class="report-grid-div">
        <kendo-grid [kendoGridBinding]="gridViewData" [pageSize]="gridPageSize" [pageable]="true" [loading]="loading"
        [style.height.%]="100" appGridPageResize="true">
            <ng-template kendoGridToolbarTemplate>
                <button kendoGridExcelCommand [disabled]="!gridViewData.length">{{ 'shared.exportExcel' | translate }}</button>
            </ng-template>
            <kendo-grid-column field="projectId" title="{{ 'shared.project' | translate }}" width="150">
            </kendo-grid-column>
            <kendo-grid-column field="name" title="{{ 'shared.name' | translate }}">
            </kendo-grid-column>
            <kendo-grid-column field="branch" title="{{ 'shared.branch' | translate }}" width="150">
            </kendo-grid-column>
            <kendo-grid-column field="createdDate" title="{{ 'shared.date' | translate }}" width="150">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.createdDate | branchLocalTime: dataItem.branch : branches : false}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="foreman" title="{{ 'shared.tech' | translate }}" width="200">
            </kendo-grid-column>
            <kendo-grid-column field="data" title="{{ 'dataReport.fallHazardReport.dataColumn' | translate }}">
            </kendo-grid-column>
            <kendo-grid-excel fileName="fallHazardReportData.xlsx" [fetchData]="allData" filterable="true">
            </kendo-grid-excel>
        </kendo-grid>
    </div>
    </div>
