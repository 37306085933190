import { Title } from '@angular/platform-browser';

import { IconRegistryService } from '../services/icon-registry/icon-registry.service';
import { environment } from '../../../environments/environment';

export function setupSite(title: Title, iconRegistryService: IconRegistryService) {
    return (): void => {
        title.setTitle(environment.websiteTitle);
        iconRegistryService.registerSvg('glazingIcon', '../../../assets/icons/glazing_icon.svg');
        iconRegistryService.registerSvg('glassMaterialIcon', '../../../assets/icons/glass_material_icon.svg');
        iconRegistryService.registerSvg('metalMaterialIcon', '../../../assets/icons/metal_material_icon.svg');
        iconRegistryService.registerSvg('projects', '../../../assets/icons/projects-icon.svg');
        iconRegistryService.registerSvg('successCheckmark', '../../../assets/icons/success_checkmark_icon.svg');
        iconRegistryService.registerSvg('errorCrossmark', '../../../assets/icons/error_crossmark_icon.svg');
        iconRegistryService.registerSvg('fileNotFound', '../../../assets/icons/file_not_found_icon.svg');
        iconRegistryService.registerSvg('flynnTruckAnimated', '../../../assets/icons/flynn_truck_icon.svg');
        iconRegistryService.registerSvg('sunnyWeatherIcon', '../../../assets/icons/sun_icon.svg');
        iconRegistryService.registerSvg('windWeatherIcon', '../../../assets/icons/winds_icon.svg');
        iconRegistryService.registerSvg('rainWeatherIcon', '../../../assets/icons/rain_icon.svg');
        iconRegistryService.registerSvg('showerWeatherIcon', '../../../assets/icons/shower_icon.svg');
        iconRegistryService.registerSvg('cloudyWeatherIcon', '../../../assets/icons/cloudy_icon.svg');
        iconRegistryService.registerSvg('snowWeatherIcon', '../../../assets/icons/snow_icon.svg');
    };
}
