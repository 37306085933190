<mat-card class="leak-editor-container" fxLayout="column">
    <mat-card-title fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px">
            <mat-icon>report</mat-icon>
            <span class="leak-editor-title">{{ title }}</span>

            <div class="copy-button-container" #copyContainer fxLayout="column" fxLayoutAlign="center center">
                <div class="copy-tooltip" fxLayoutAlign="center center">
                    <span>{{ 'shared.copied' | translate }}</span>
                </div>

                <button mat-icon-button (click)="onCopy(copyContainer)">
                    <mat-icon fontSet="material-icons-outlined">file_copy</mat-icon>
                </button>
            </div>
        </div>
    </mat-card-title>

    <mat-card-content class="leak-editor-content" fxFlex>
        <div fxLayout="column" fxFill>
            <textarea [id]="editorId"></textarea>
        </div>

        <div fxLayout="row" fxLayoutGap="7px" class="leak-editor-buttons-container">
            <button mat-stroked-button color="primary" (click)="printContent()" *ngIf="isSummary">
                <mat-icon>print</mat-icon>
                <span>{{ 'shared.leakReportCard.print' | translate }}</span>
            </button>

            <button mat-stroked-button color="warn" (click)="resetContent()">Reset Report</button>
        </div>
    </mat-card-content>
</mat-card>

