import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'grid-item-actions',
    styleUrls: ['./grid-item-actions.component.scss'],
    templateUrl: './grid-item-actions.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridItemActionsComponent {
    @Input() public content = '';
    @Input() public canEnableEdit = false;
    @Input() public canClearContent = false;
    @Output() public editEnabled = new EventEmitter();
    @Output() public contentClear = new EventEmitter();

    constructor(public clipboard: Clipboard) { }

    public onCopy(element: HTMLElement): void {
        this.clipboard.copy(this.content);
        const name = 'text-copied';
        element.classList.remove(name);
        setTimeout(() => {
            element.classList.add(name);
        });
    }
}
