import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { TopToolbarComponent } from './top-toolbar/top-toolbar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TaskSelectionDialogComponent } from './side-menu/task-selection-dialog/task-selection-dialog.component';

@NgModule({
    imports: [SharedModule],
    declarations: [
        TopToolbarComponent,
        SideMenuComponent,
        TaskSelectionDialogComponent
    ],
    exports: [
        TopToolbarComponent,
        SideMenuComponent
    ]
})
export class SiteLayoutModule { }
