<dialog-action-card class="kpi-change-request-dialog-container"
    [icon]="'notification_important'"
    [title]="translate.instant('projects.kpi.requestKpiChange')"
    [isLoaded]="isLoaded"
    (close)="onClose(null)">

    <div class="kpi-change-request-dialog-content" fxLayout="column" fxLayoutGap="15px">
        <span>{{ 'projects.kpi.returnToSiteHelpText' | translate }}</span>

        <mat-radio-group [(ngModel)]="selectedJob">
            <mat-radio-button *ngFor="let job of jobs" [value]="job">
                {{ 'projects.kpi.visit' | translate }} {{ job + 1 }}
            </mat-radio-button>
        </mat-radio-group>

        <mat-form-field class="kpi-change-request-dialog-input">
            <mat-label>{{ 'projects.kpi.reasonHelpText' | translate }}</mat-label>

            <textarea matInput
                cdkFocusInitial
                cdkTextareaAutosize
                cdkAutosizeMinRows="8"
                cdkAutosizeMaxRows="10"
                [formControl]="reasonInput"
                required>
            </textarea>

            <mat-error *ngIf="reasonInput.invalid">{{ 'projects.kpi.reasonErrorHelpText' | translate }}</mat-error>
        </mat-form-field>
    </div>

    <div class="kpi-change-request-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="sendRequest()">{{ 'projects.kpi.send' | translate }}</button>
    </div>
</dialog-action-card>
