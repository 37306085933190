import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import SOAddress from './../../../../../core/data-models/so-address';
import CustomerSiteDto from './../../../../../core/data-models/customer-site-dto';
// eslint-disable-next-line max-len
import { CustomerCreationHttpService } from './../../../../../core/services/http/customer-creation-http/customer-creation-http.service';
import SiteFilter from './../../../../../core/data-models/dispatch/site-filter';
import Address from '../../../../../core/data-models/address';

@Component({
    selector: 'customer-site-suggestions',
    styleUrls: ['./customer-site-suggestions.component.scss'],
    templateUrl: './customer-site-suggestions.component.html'
})
export class CustomerSiteSuggestionsComponent implements OnInit {
  @Input() siteInfo: CustomerSiteDto;
  @Input() customerId: string;
  @Input() showBillingOnly: boolean;
  @Output() siteSelected = new EventEmitter<SOAddress>();
  public suggestedAddresses: SOAddress[] = [];
  public requestedAddress: Address;

  constructor(
    public translate: TranslateService,
    private _cctHttp: CustomerCreationHttpService) { }

  public async ngOnInit(): Promise<void> {
      this.requestedAddress = {
          addr1: this.siteInfo.address1,
          addr2: this.siteInfo.address2,
          city: this.siteInfo.city,
          country: this.siteInfo.country,
          state: this.siteInfo.state,
          zip: this.siteInfo.zip
      } as Address;

      if (this.customerId && this.siteInfo) {
          const filter = new SiteFilter(
              this.customerId,
              this.siteInfo.address1,
              this.siteInfo.address2,
              this.siteInfo.city,
              this.siteInfo.zip,
              this.siteInfo.state,
              this.siteInfo.country
          );

          this.suggestedAddresses = await this._cctHttp.getSiteSuggestions(filter);

          if (this.showBillingOnly) {
              this.suggestedAddresses = this.suggestedAddresses.filter(_ => _.isBillAddress);
          }
      }
  }
}
