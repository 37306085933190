export default class Employee {
    public account: string;
    public employeeId: string;
    public locn: string;
    public locnCode: string;
    public surname: string;
    public givenName: string;
    public fullName: string;
    public preferredName: string;
    public groupNames: string[];
    public group: string;
    public email: string;
    public phone: string;
    public position: string;
    public profilePicture: string;
    public unionCode: string;
    public dailyGoals: string;
    public weeklyGoals: string;
}
