import Address from '../address';

export default class ProjectSumDto {
    projectId: string;
    name: string;
    branch: string;
    status: string;
    customerId: string;
    siteId: string;
    salesCode: string;
    isQuoting: boolean;
    notRoofIssue: boolean;
    requireFabrication: boolean;
    address: Address;
}
