<div fxLayout="column" fxLayoutGap="15px" class="main-attachment-step-content">
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start stretch" fxFlex>
        <div fxLayout="column" fxFlex="60" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="90">
                        <ngx-file-drop dropZoneClassName="upload-file-drop-zone" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
                            <ng-template
                                ngx-file-drop-content-tmp
                                let-openFileSelector="openFileSelector">
                                <div class="upload-file-drop-content" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-around center">
                                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
                                        <mat-icon>get_app</mat-icon>
                                        <span>{{ "shared.attachmentStep.fileDrop.dragnDrop" | translate }}</span>
                                    </div>
                                    <div class="upload-file-upload-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                        <button mat-stroked-button color="primary" (click)="openFileSelector()">
                                            {{ "shared.attachmentStep.fileDrop.browseFiles" | translate }}
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-file-drop>
                    </div>
                </div>
                <div fxLayout="row" fxFlex>
                    <kendo-grid [kendoGridBinding]="config.attachments" [pageSize]="5" [pageable]="true" [scrollable]="true">
                        <kendo-grid-column field="name" title="{{ 'shared.attachmentStep.name' | translate }}">
                        </kendo-grid-column>
                        <kendo-grid-column width="100">
                            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                                <div fxLayout="row" fxLayoutAlign="center center">
                                    <button (click)="delete(dataItem)" mat-icon-button color="warn" aria-label="delete button"
                                        [matTooltip]="translate.instant('shared.attachmentStep.table.deleteToolTip')">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <button (click)="download(dataItem)" mat-icon-button color="primary" aria-label="download button"
                                    [matTooltip]="translate.instant('shared.attachmentStep.table.downloadToolTip')">
                                        <mat-icon>cloud_download</mat-icon>
                                    </button>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
        </div>
        <div fxLayout="column" fxFlex="40" class="roof-map-div" fxLayoutGap="15px">
            <div fxLayout="row">
                <button mat-flat-button color="primary" (click)="fileInput.click()">
                    <mat-icon>cloud_upload</mat-icon>
                    <span>{{ "shared.attachmentStep.uploadRoofPlan" | translate }}</span>
                    <input type="file" (change)="roofMapFileChanged($event)" #fileInput accept="image/*">
                </button>
            </div>
            <div *ngIf="config.roofMap" fxFlex fxLayout="column"
                [style.background-image]="roofMapUrl" class="roof-map-image-container">
            </div>
            <div *ngIf="!config.roofMap" fxFlex fxLayout="column" fxLayoutAlign="center center" class="roof-map-placeholder-container">
                <label>{{ "shared.attachmentStep.roofPlanPreview" | translate }}</label>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
        <button mat-stroked-button (click)="stepClicked.emit('previous')" color="primary">
            {{ "shared.attachmentStep.previousButton" | translate }}
        </button>
        <button mat-stroked-button (click)="stepClicked.emit('next')" color="primary">
            {{ "shared.attachmentStep.nextButton" | translate }}
        </button>
    </div>
</div>
