import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NgxMaskPipe } from 'ngx-mask';

import UserInfo from '../../../../core/data-models/user-info';
import TranslationPair from '../../../../core/data-models/generic/translation-pair';
import QuoteGeneralInfo from '../../../../core/data-models/quote-general-info/quote-general-info';
import CustomerSelectionDto from '../../../../core/data-models/customer-selection-dto';
import CustomerUtility from '../../../../core/services/utility/customer-utility/customer-utility.service';
import { GenericUtilityService } from '../../../../core/services/utility/generic-utility/generic-utility.service';
import { BranchHttpService } from '../../../../core/services/http/branch-http/branch-http.service';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';
import { QuoteStatusHttpService } from '../../../../core/services/http/quote-status-http/quote-status-http.service';
import { CustomerCreationHttpService } from '../../../../core/services/http/customer-creation-http/customer-creation-http.service';
import { AppConfigHttpService } from '../../../../core/services/http/appconfig-http/appconfig-http.service';
import { QuoteStatusService } from '../../../../core/services/quote-status/quote-status.service';
import { CustomerIdPlaceholderPipe } from '../../../pipes/customer-id-placeholder/customer-id-placeholder.pipe';
import { ValidatorFactory } from '../../../../core/services/validator-factory/validator-factory.service';
import { CustomerManagementComponent } from '../../customer-management/customer-management.component';
import { QuoteEditorService } from '../quote-editor.service';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import { LanguageKey } from './../../../../core/enums/language-key.enum';
import { CommonPlaceholder } from './../../../../core/enums/common-placeholder.enum';
import { CustomerHttpService } from './../../../../core/services/http/customer-http/customer-http.service';
import LanguageUtility from './../../../../core/services/utility/language-utility/language-utility.service';
import BranchSalesCodeChange from '../../../../core/data-models/quote-general-info/branch-sales-code-change';
import { WorkCategory } from '../../../../core/data-models/work-category';
import SalesCodeConfig from '../../../../core/data-models/app-configs/sales-code-config';

@Component({
    selector: 'quote-information-panel',
    styleUrls: ['./quote-information-panel.component.scss'],
    templateUrl: './quote-information-panel.component.html'
})
export class QuoteInformationPanelComponent implements OnInit {
    @Input() public quote: QuoteGeneralInfo = null;
    @Input() public isEditMode = false;
    @Output() public customerSelect = new EventEmitter<CustomerSelectionDto>();
    @Output() public formCreated = new EventEmitter<UntypedFormGroup>();
    @Output() public formChanged = new EventEmitter<UntypedFormGroup>();
    @Output() public branchSalesCodeChanged = new EventEmitter<BranchSalesCodeChange>();
    @Output() public onThresholdExceed = new EventEmitter<string>();
    public isInternal = false;
    public formGroup: UntypedFormGroup;
    public statuses: TranslationPair[] = [];
    public branches: { locn: string, code: string }[] = [];
    public salesCodes: string[] = [];
    public estimators: string[] = [];
    public quoteThreshold = 0;
    public emailValidator = this._validatorFactory.createEmailValidator();
    public phoneValidator = this._validatorFactory.createPhoneValidator();
    public hoursValidator = this._validatorFactory.createPositiveIntegerValidator();
    private _taxRateValidator = this._validatorFactory.createNonNegativeValidator();
    private readonly _idPlaceholder = 'Pending';
    private _salesCodesConfig: SalesCodeConfig[] = [];
    private _userInfo : UserInfo = null;

    // eslint-disable-next-line
    constructor(private _customerHttp: CustomerHttpService,
                private _quoteEditorService: QuoteEditorService,
                private _quoteStatusHttp: QuoteStatusHttpService,
                private _quoteStatusService: QuoteStatusService,
                private _branchHttp: BranchHttpService,
                private _customerCreationHttp: CustomerCreationHttpService,
                private _userHttp: UserHttpService,
                private _appConfigHttp: AppConfigHttpService,
                private _customerIdPlaceholderPipe: CustomerIdPlaceholderPipe,
                private _formBuilder: UntypedFormBuilder,
                private _snackBar: MatSnackBar,
                private _validatorFactory: ValidatorFactory,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                private _featureFlagService: FeatureFlagService,
                private _maskService: NgxMaskPipe) { }

    get quoteThresholdInfo(): string {
        return this._translate.instant('shared.quote.editor.quoteThresholdInfo', {
            quoteThreshold: this._maskService.transform(this.quoteThreshold.toString(), 'separator.2')
        });
    }

    get total(): string {
        if (!this.formGroup) {
            return '0';
        }
        const amount = this.getFormField('amount').value;
        const taxRate = this.getFormField('taxRate').value;
        const total = amount * (taxRate / 100 + 1);

        if (this.quoteThreshold && amount && amount > this.quoteThreshold) {
            const confirm = this._translate.instant('shared.quote.editor.quoteAmountConfirm');
            const warningMessage = `${this.quoteThresholdInfo} ${confirm}`;
            this.onThresholdExceed.emit(warningMessage);
        }
        else {
            this.onThresholdExceed.emit(null);
        }

        return total.toFixed(2);
    }

    get tbdPlaceholder(): string {
        return CommonPlaceholder.TBD;
    }

    public async ngOnInit(): Promise<void> {
        this._userInfo = await this._userHttp.getUserInfo();
        this.isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        await this.loadSelections();
        this.loadFormGroup();
    }

    private async loadSelections(): Promise<void> {
        const user = await this._userHttp.getUserInfo();
        this.branches = await this._branchHttp.getAllBranchNames();
        const canCreateSubcontract = this._featureFlagService.featureFlags[FeatureFlags.AllowCreatingSubcontracts];
        this._salesCodesConfig = await this._appConfigHttp.getSalesCodeConfig();
        const quoteSalesCodes = await this._appConfigHttp.getQuoteSalesCode('dispatch');
        this.salesCodes = canCreateSubcontract ?
            quoteSalesCodes.map(dto => dto.code) :
            quoteSalesCodes.filter(dto => dto.workCategory !== WorkCategory.Subcontract).map(dto => dto.code);

        this.quoteThreshold = await this._customerHttp.getQuoteThreshold(this.quote.customer.customerId);
        await this.loadEstimators(user);

        if (this.isEditMode) {
            await this.loadStatuses(user.language);
        }
        else if (user.groups.includes('branch') && this.branches.some(branch => branch.locn === user.branch)) {
            this.quote.basicInfo.branch = user.branch;
        }
    }

    public getSalesCodeDescription(code: string): string {
        const salesCodeConfig = this._salesCodesConfig.find(salesCode => salesCode.code === code);

        if (!salesCodeConfig) {
            return code;
        }

        const nameEntry = salesCodeConfig.names.find(name => name.languageKey === this._userInfo.language);

        if (!nameEntry) {
            return code;
        }

        return `${salesCodeConfig.code} - ${nameEntry.content}`;
    }

    private async loadEstimators(user: UserInfo): Promise<void> {
        const { name, groups } = user;
        const estimators = await this._userHttp.getEstimators();
        const names = estimators.map(_ => _.preferredName);
        const placeholder = this.tbdPlaceholder;
        const isEstimator = groups.includes('estimator') && names.includes(name);
        this.estimators = [...Array.from(new Set(names))];

        if (!this.quote.estimation.estimator) {
            this.quote.estimation.estimator = isEstimator ? name : placeholder;
        }
        else if (this.quote.estimation.estimator !== placeholder && !this.estimators.includes(this.quote.estimation.estimator)) {
            this.estimators = [this.quote.estimation.estimator, ...Array.from(new Set(names))];
        }
    }

    private async loadStatuses(language: string): Promise<void> {
        const { basicInfo } = this.quote;
        const currentStatusTranslation = await this._quoteStatusService.getStatusTranslationByUserLanguage(basicInfo.status);
        const currentStatusPair = { original: basicInfo.status, translated: currentStatusTranslation } as TranslationPair;
        const targetStatuses = await this._quoteStatusHttp.getTargetStatusesByEnglishName(basicInfo.status);

        const targetStatusesPairs = targetStatuses.map(_ => {
            const original = LanguageUtility.getLocalizedContent(LanguageKey.EN, _.names);
            const translated = LanguageUtility.getLocalizedContent(language, _.names);

            return { original, translated } as TranslationPair;
        });

        this.statuses = [...targetStatusesPairs, currentStatusPair];
    }

    private loadFormGroup(): void {
        const { basicInfo, customer, reportingContact, estimation } = this.quote;
        const currencyValidator = this._validatorFactory.createCurrencyValidator();
        const positiveValidator = this._validatorFactory.createPositiveValidator();
        const customerId = this._customerIdPlaceholderPipe.transform(customer.customerId, this._idPlaceholder);

        this.formGroup = this._formBuilder.group({
            status: [basicInfo.status],
            quoteId: [{ value: basicInfo.quoteId, disabled: true }],
            originProjectId: [{ value: basicInfo.originProjectId, disabled: true }],
            customerName: [customer.customerName, Validators.required],
            customerClass: [customer.customerClass, Validators.required],
            siteName: [customer.siteName, Validators.required],
            customerId: [customerId, Validators.required],
            siteId: [this.getSiteIdDisplayValue(customer.siteId), Validators.required],
            branch: [{ value: basicInfo.branch, disabled: Boolean(basicInfo.destinationProjectId) }, Validators.required],
            salesCode: [this.salesCodes.find(_ => _ === basicInfo.salesCode), Validators.required],
            reportingContact: [reportingContact.name, Validators.required],
            reportingPhone: [reportingContact.officePhone, [this.phoneValidator.validator, Validators.required]],
            reportingEmail: [reportingContact.email, [this.emailValidator.validator, Validators.required]],
            hours: [estimation.hours, this.hoursValidator.validator],
            estimator: [estimation.estimator],
            amount: [estimation.amount, [currencyValidator.validator, positiveValidator.validator]],
            taxRate: [estimation.taxRate, [this._taxRateValidator.validator, Validators.max(100)]]
        });

        this.formGroup.valueChanges.subscribe(() => this.syncFormFields());
        this.formGroup.controls.amount.valueChanges.subscribe(this.replacePlaceholders.bind(this));
        this.formGroup.controls.taxRate.valueChanges.subscribe(this.replacePlaceholders.bind(this));
        this.formCreated.emit(this.formGroup);
    }

    private replacePlaceholders(): void {
        const amount = this.getFormField('amount').value;
        const taxRate = this.getFormField('taxRate').value;

        this.quote.description = this._quoteEditorService.replacePlaceholders(this.quote.description, amount, taxRate, true);
    }

    public getFormField(name: string): AbstractControl {
        return this.formGroup.get(name);
    }

    public async onCustomerSelect(): Promise<void> {
        const dialog = this._dialog.open(CustomerManagementComponent, { width: '90vw', height: '95vh', maxWidth: '95vw' });
        const selection = await dialog.afterClosed().toPromise<CustomerSelectionDto>();

        if (!selection) {
            return;
        }

        const { customer } = selection;
        const isConfirmed = CustomerUtility.isConfirmedCustomer(customer.customerId);

        if (isConfirmed && !await this._customerCreationHttp.isCustomerActive(customer.customerId)) {
            const warning = this._translate.instant('shared.quote.editor.selectedCustomerCurrentlyInactive');
            const ok = this._translate.instant('snackbars.ok');
            this._snackBar.open(this._translate.instant(warning), ok);

            return;
        }

        this.quoteThreshold = await this._customerHttp.getQuoteThreshold(customer.customerId);

        this.syncCustomerFields(selection);
        this.customerSelect.emit(selection);
    }

    private syncFormFields(): void {
        const { basicInfo, customer, reportingContact, estimation } = this.quote;
        const oldSalesCode = basicInfo.salesCode;
        const newSalesCode = this.getFormField('salesCode').value;
        const oldBranchName = basicInfo.branch;
        const newBranchName = this.getFormField('branch').value;

        basicInfo.salesCode = newSalesCode;
        basicInfo.status = this.getFormField('status').value;
        basicInfo.branch = newBranchName;
        customer.customerName = this.getFormField('customerName').value;
        customer.customerClass = this.getFormField('customerClass').value;
        customer.siteName = this.getFormField('siteName').value;

        if (this.getFormField('customerId').value !== this._idPlaceholder) {
            customer.customerId = this.getFormField('customerId').value;
        }

        if (this.getFormField('siteId').value !== this._idPlaceholder) {
            customer.siteId = this.getFormField('siteId').value;
        }

        reportingContact.name = this.getFormField('reportingContact').value;
        reportingContact.email = this.getFormField('reportingEmail').value;
        reportingContact.officePhone = this.getFormField('reportingPhone').value;
        estimation.hours = this.getFormField('hours').value;
        estimation.estimator = this.getFormField('estimator').value;
        estimation.amount = this.getFormField('amount').value;
        estimation.taxRate = this.getFormField('taxRate').value;
        this.formChanged.emit(this.formGroup);
        const isSalesCodeChanged = Boolean(oldSalesCode) && Boolean(newSalesCode) && oldSalesCode !== newSalesCode;
        const isBranchNameChanged = Boolean(oldBranchName) && Boolean(newBranchName) && oldBranchName !== newBranchName;

        if (isSalesCodeChanged || isBranchNameChanged) {
            const oldBranchCode = this.branches.find(_ => _.locn === oldBranchName).code;
            const newBranchCode = this.branches.find(_ => _.locn === newBranchName).code;
            this.branchSalesCodeChanged.emit({ oldBranchCode, newBranchCode, oldBranchName, newBranchName, oldSalesCode, newSalesCode });
        }
    }

    private syncCustomerFields(selection: CustomerSelectionDto): void {
        const { customer, site } = selection;
        const currentCustomer = this.quote.customer;
        const customerId = customer.customerId;
        const siteId = site.shipToId;
        this.getFormField('customerId').setValue(this._customerIdPlaceholderPipe.transform(customerId, this._idPlaceholder));
        this.getFormField('customerName').setValue(customer.name);
        this.getFormField('customerClass').setValue(customer.customerClass);
        this.getFormField('siteName').setValue(site.name);
        this.getFormField('siteId').setValue(this.getSiteIdDisplayValue(siteId));
        currentCustomer.customerId = customerId;
        currentCustomer.customerName = customer.name;
        currentCustomer.customerClass = customer.customerClass;
        currentCustomer.storeNumber = site.storeNumber;
        currentCustomer.siteName = site.name;
        currentCustomer.siteId = siteId;
        this.formChanged.emit(this.formGroup);
    }

    private getSiteIdDisplayValue(id: string): string {
        const { customerName, siteName } = this.quote.customer;
        const hasCustomer = Boolean(customerName) && Boolean(siteName);

        return hasCustomer ? GenericUtilityService.getPlaceholder(id, this._idPlaceholder) : id;
    }
}
