<mat-card class="app-photo-card" fxLayout="column" fxFill [style.background-color]="backgroundColor">
    <div *ngIf="photo.imageOrderLabel > 0" class="pic-order-overlay">
        {{photo.imageOrderLabel}}
    </div>
    <div [style.width]="config.imageSize" [style.height]="config.imageSize" class="photo-card-image-container"  (click)="cardEvent.emit({ action: ImageCardAction.Click, source: this.photo })">
        <img [lazyLoad]="imageUrl" [defaultImage]="'../../../../../assets/images/defaultImage.png'" alt="photo" class="photo-card-image"
        fxFlexAlign="center">
    </div>

    <mat-card-content fxLayout="row" *ngIf="config.showDescription" class="photo-card-image-content" fxFlex>
        <div fxLayout="column">
            <label>
                {{ localizedTitle }}
            </label>
            <div fxLayout="row" fxLayoutGap="5px" *ngIf="config.showTags">
                <mat-icon *ngIf="photo.internalImage" color='warn' matTooltip="Not for Customer">block</mat-icon>
                <mat-icon *ngIf="photo.customerWorkOrder" color='accent' matTooltip="Customer Work Order">build</mat-icon>
                <mat-icon *ngIf="photo.tagSectionPage" color='primary' matTooltip="{{photo.tagSectionPage}}">description</mat-icon>
                <mat-icon *ngIf="photo.tagQuestion" color='primary' matTooltip="{{photo.tagQuestion}}">help_center</mat-icon>
            </div>
            <p class="photo-description" *ngIf="!config.editableDescription">
                {{photo.contentValue}}
            </p>
            <textarea class="photo-description" [(ngModel)]="photo.contentValue"
                (change)="onDescriptionChange()" *ngIf="config.editableDescription">
            </textarea>
        </div>
    </mat-card-content>
    <mat-checkbox *ngIf="config.actionButtons.length > 1" [(ngModel)]="photo.internalImage" (change)="cardEvent.emit({ source: photo, action: 'Save' })">
        <span>{{ 'shared.editPhoto.notForCustomer' | translate }}</span>
    </mat-checkbox>
    <mat-card-actions fxLayout="row" *ngIf="config.actionButtons && !highlighted">
        <button mat-button *ngFor="let button of config.actionButtons" [color]="button.isWarn? 'warn' : 'primary'"
        fxLayout="row" fxLayoutAlign="center center" (click)="cardEvent.emit({ source: photo, action: button.text })"
        [matTooltip]="button.displayText ? button.displayText : button.text">

        <mat-icon *ngIf="button.icon; else textOnly">{{ button.icon }}</mat-icon>
        <ng-template #textOnly>{{ button.displayText ? button.displayText : button.text }}</ng-template>
        </button>
    </mat-card-actions>
    <mat-card-footer fxLayout="row" fxLayoutAlign="end center" *ngIf="config.timeZone">
        {{localCreatedDate}}
    </mat-card-footer>
</mat-card>
