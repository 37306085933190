import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, map, takeWhile } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';

import { Branch } from '../../../core/data-models/branch/branch';
import { RatesQuery } from '../../../core/data-models/rates/rates-query';
import { BudgetRecord } from '../../../core/data-models/budget-estimations/budget-record';
import { BudgetRecordPayload } from '../../../core/data-models/budget-estimations/budget-record-payload';
import { ServiceRatesRevision } from '../../../core/data-models/rates/service-rates/service-rates-revision';
import { ConfirmationPromptOptions } from '../../../core/data-models/options/confirmation-prompt-options';
import { ServiceOperationsBudgetData } from '../../../core/data-models/budget-estimations/service-operations-standardized-budget-estimation/service-operations-budget-data';
import { ServiceRatesHttpService } from '../../../core/services/http/service-rates-http/service-rates-http.service';
import { ServiceOperationsStandardizedBudgetsHttpService } from '../../../core/services/http/service-operations-standardized-budgets-http/service-operations-standardized-budgets-http.service';
import { ConfirmationPromptComponent } from '../../../shared/components/prompts/confirmation-prompt/confirmation-prompt.component';
import { RecordIdUtility } from '../../../core/utilities/record-id/record-id.utility';
import { BudgetStatus } from '../../../core/data-models/budget-estimations/enums/budget-status';
import { BranchServiceRate } from '../../../core/data-models/rates/service-rates/branch-service-rate';
import { ServiceRatesBranchesDisplayComponent } from '../../../shared/components/displays/service-rates-branches-display/service-rates-branches-display.component';
import { BudgetEstimationService } from './budget-estimation.service';
import { CheckTypeRecord } from '../../../core/data-models/budget-estimations/custom-type-records/check-type-record';

@Component({
    selector: 'app-service-operations-standardized-estimation',
    styleUrls: ['./service-operations-standardized-estimation.component.scss'],
    templateUrl: './service-operations-standardized-estimation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceOperationsStandardizedEstimationComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public recordId = '';
    @Input() public customerId = '';
    @Input() public branchCodeOverride = '';
    @Input() public isQuote = false;
    @Input() public canFinalize = false;
    @Input() public uploadBudgetCallback: (recordId: string) => Promise<boolean>;
    @Input() public useSubcontractRatesForDrafts = false;
    @Output() public budgetPayloadUpdate = new EventEmitter<BudgetRecordPayload<ServiceOperationsBudgetData> | null>();
    @ViewChild('stepper') private _stepper: MatStepper;
    public form: UntypedFormGroup;
    public ratesQueries: RatesQuery[] = [];
    public branchServiceRate: BranchServiceRate;
    public selectedRates: ServiceRatesRevision;
    public selectedRatesQuery: RatesQuery;
    public isExisting = false;
    public isErrorState = false;
    public isReadOnly = false;
    public isSaving = false;
    public calculationUpdated$ = new Subject();
    public activeBranches: Branch[] = [];
    private _isComponentActive = true;
    private _hasUnsavedChanges = false;
    private _calculationToken = '';

    get isProjectSectionCompleted(): boolean {
        return this.form.get('projectType').valid && this.form.get('branchCode').valid;
    }

    get isLeadQuestionCompleted(): boolean {
        return this.leadQuestionsArray.value.every((question: CheckTypeRecord) => question.checked);
    }

    get laborSection(): UntypedFormGroup {
        return this.form.get('laborSection') as UntypedFormGroup;
    }

    get miscellaneousSection(): UntypedFormGroup {
        return this.form.get('miscellaneousSection') as UntypedFormGroup;
    }

    get materialsSection(): UntypedFormGroup {
        return this.form.get('materialsSection') as UntypedFormGroup;
    }

    get qualityControlFeesSection(): UntypedFormGroup {
        return this.form.get('qualityControlFeesSection') as UntypedFormGroup;
    }

    get summarySection(): UntypedFormGroup {
        return this.form.get('summarySection') as UntypedFormGroup;
    }

    get leadQuestionsArray(): UntypedFormArray {
        return this.form.get('questionsSection.questions') as UntypedFormArray;
    }

    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
                private _formBuilder: UntypedFormBuilder,
                private _serviceRatesHttp: ServiceRatesHttpService,
                private _serviceOperationsStandardizedBudgetsHttp: ServiceOperationsStandardizedBudgetsHttpService,
                private _changeDetectorRef: ChangeDetectorRef,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialog,
                private _budgetEstimationService: BudgetEstimationService) {
    }

    public async ngOnInit(): Promise<void> {
        const [branches, budget] = await Promise.all([this._serviceRatesHttp.getActiveBranches(), this.getBudgetRecord()]);
        this.activeBranches = branches;

        if (this.isErrorState) {
            return;
        }

        if (!budget) {
            await this.initializeEmptyEstimation();
        }
        else {
            await this.initializeExistingEstimation(budget);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.isReadOnly && changes.useSubcontractRatesForDrafts && !changes.useSubcontractRatesForDrafts.firstChange) {
            this.reset();
        }
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
    }

    public async onSave(isSilentSave = false): Promise<boolean> {
        if (this.form.invalid || !await this.confirmFirstSave()) {
            return false;
        }

        this.isSaving = true;
        const isSaved = await this.saveBudgetRecord();

        if (isSaved) {
            this.isExisting = true;
            this._hasUnsavedChanges = false;
        }

        if (!isSilentSave) {
            const key = isSaved ? 'messages.budgetSaved' : 'errors.failedToSaveBudget';
            const message = this.translate.instant(`flynnBudgetTool.budgetEstimation.${key}`);
            this._snackBar.open(message, this.translate.instant('flynnBudgetTool.shared.ok'));
        }

        this._changeDetectorRef.markForCheck();

        this.isSaving = false;
        return isSaved;
    }

    public async onSelectedRatesChange(query: RatesQuery, isLatestRequired = true, isResetRequired = true): Promise<void> {
        this.selectedRatesQuery = query;

        if (isLatestRequired) {
            this.selectedRates = await this._serviceRatesHttp.getLatestServiceRatesRevision(query);
        }
        else {
            this.selectedRates = await this._serviceRatesHttp.getServiceRatesRevision(query);
        }

        if (isResetRequired) {
            this.reset();
        }

        this.reloadLeadQuestions(!isLatestRequired);
        this.reloadEmptyCustomSummaryLineItemExpenses();
        this.branchServiceRate = this.selectedRates.branchRates.find(_ => _.branchCode === this.form.get('branchCode').value);
        this._changeDetectorRef.markForCheck();
    }

    public viewServiceRates(): void {
        const [width, height] = ['75vw', '85vh'];
        const data = { selectedRates: this.selectedRates, branches: this.activeBranches };
        this._dialog.open(ServiceRatesBranchesDisplayComponent, { data, width, height });
    }

    public async onFinalize(): Promise<boolean> {
        const title = this.translate.instant('flynnBudgetTool.budgetEstimation.submitToDynamicsConfirmDialog.title');
        const message = this.translate.instant('flynnBudgetTool.budgetEstimation.submitToDynamicsConfirmDialog.content');
        const submitText = this.translate.instant('flynnBudgetTool.budgetEstimation.submitToDynamicsConfirmDialog.submit');
        const cancelText = this.translate.instant('flynnBudgetTool.shared.cancel');
        const data = new ConfirmationPromptOptions(title, message, submitText, cancelText);
        const dialog = this._dialog.open(ConfirmationPromptComponent, { data });

        if (!await dialog.afterClosed().toPromise()) {
            return false;
        }

        if (!await this.onSave(true)) {
            const snackbarText = this.translate.instant('flynnBudgetTool.budgetEstimation.errors.failedToSaveBudget');
            this._snackBar.open(snackbarText, this.translate.instant('flynnBudgetTool.shared.ok'));
            return false;
        }

        try {
            this.isSaving = true;
            if (!await this.uploadBudgetCallback(this.recordId)) {
                return false;
            }

            if (!await this._serviceOperationsStandardizedBudgetsHttp.finalizeBudgetRecord(this.recordId)) {
                const toast = this.translate.instant('flynnBudgetTool.budgetEstimation.errors.failedToSaveBudget');
                this._snackBar.open(toast, this.translate.instant('flynnBudgetTool.shared.ok'));
                return false;
            }
        }
        finally {
            this.isSaving = false;
        }

        this.isReadOnly = true;
        return true;
    }

    public async onDelete(): Promise<void> {
        const title = this.translate.instant('flynnBudgetTool.budgetEstimation.deleteBudgetConfirmDialog.title');
        const content = this.translate.instant('flynnBudgetTool.budgetEstimation.deleteBudgetConfirmDialog.content');
        const deleteText = this.translate.instant('flynnBudgetTool.budgetEstimation.deleteBudgetConfirmDialog.delete');
        const cancelText = this.translate.instant('flynnBudgetTool.shared.cancel');
        const data = new ConfirmationPromptOptions(title, content, deleteText, cancelText, true);
        const dialog = this._dialog.open(ConfirmationPromptComponent, { data });

        if (!await dialog.afterClosed().toPromise()) {
            return;
        }

        this.isSaving = true;
        const isSuccess = this.isQuote ?
            await this._serviceOperationsStandardizedBudgetsHttp.deleteQuoteBudget(this.recordId) :
            await this._serviceOperationsStandardizedBudgetsHttp.deleteProjectBudget(this.recordId);

        const key = isSuccess ? 'messages.budgetDeleted' : 'errors.failedToDeleteBudget';
        const message = this.translate.instant(`flynnBudgetTool.budgetEstimation.${key}`);
        this._snackBar.open(message, this.translate.instant('flynnBudgetTool.shared.ok'));

        if (isSuccess) {
            this.isExisting = false;
            this.reset();
            await this.initializeEmptyEstimation(); // in case the rates have changed, get latest
        }

        this.isSaving = false;
        this._changeDetectorRef.markForCheck();
    }

    private async initializeEmptyEstimation(): Promise<void> {
        this.initializeForm(new ServiceOperationsBudgetData());
        const metadatas = await this._serviceRatesHttp.getServiceRatesMetadataByCustomerId(this.customerId);
        const activeMetadata = metadatas.filter(metadata => metadata.isActive);
        this.ratesQueries = activeMetadata.map(metadata => new RatesQuery(metadata.groupId, metadata.version, metadata.revisions)).reverse();

        await this.onSelectedRatesChange(this.ratesQueries[0], true, false);
        this._changeDetectorRef.markForCheck();
    }

    private async initializeExistingEstimation(budget: BudgetRecord<ServiceOperationsBudgetData>): Promise<void> {
        this.initializeForm(budget.budgetData);
        await this.onSelectedRatesChange(budget.ratesQuery, false, false);
        this.isExisting = true;
        this._changeDetectorRef.markForCheck();
    }

    private initializeForm(budgetData: ServiceOperationsBudgetData): void {
        this.form = this.createForm(budgetData);
        this.updateLaborSectionStatus();
        this.form.markAllAsTouched();

        this.form.valueChanges.pipe(
            takeWhile(() => this._isComponentActive),
            map(() => {
                this._hasUnsavedChanges = true;
                this._calculationToken = uuidV4(); // Token used to detect if a new request is pending
                return this._calculationToken;
            }),
            debounceTime(500)
        ).subscribe(async(nextCalculationToken: string) => {
            await this.calculate(nextCalculationToken);
        });
    }

    private createForm(budgetData: ServiceOperationsBudgetData): UntypedFormGroup {
        let branchCode = this.branchCodeOverride;

        if (!branchCode) {
            branchCode = budgetData.branchCode ? budgetData.branchCode : RecordIdUtility.getBranchCode(this.recordId);
        }

        return this._formBuilder.group({
            projectType: [budgetData.projectType.identifier ? budgetData.projectType : null, [Validators.required]],
            branchCode: [branchCode, [Validators.required]],
            isSubcontract: [this.isReadOnly ? Boolean(budgetData.isSubcontract) : this.useSubcontractRatesForDrafts],
            laborSection: this._budgetEstimationService.createLaborSection(budgetData.laborSection),
            materialsSection: this._budgetEstimationService.createMaterialsSection(budgetData.materialsSection),
            miscellaneousSection: this._budgetEstimationService.createMiscellaneousSection(budgetData.miscellaneousSection),
            qualityControlFeesSection: this._budgetEstimationService.createQualityControlFeesSection(budgetData.qualityControlFeesSection),
            summarySection: this._budgetEstimationService.createSummarySection(budgetData.summarySection),
            questionsSection: this._budgetEstimationService.createQuestionsSection(budgetData.questionsSection)
        });
    }

    private reset(): void {
        if (this._stepper) {
            this._stepper.reset();
        }

        if (!this.form) {
            return;
        }

        const defaultForm = this.createForm(new ServiceOperationsBudgetData());
        this.form.reset(defaultForm.getRawValue());
        // resetting the form does not clear the form array, we need to do it manually
        for (const key of ['materialsSection.entries', 'miscellaneousSection.entries']) {
            const formArray = this.form.get(key) as UntypedFormArray;
            formArray.clear();
        }

        this.updateLaborSectionStatus();
        this.form.markAllAsTouched();
    }

    private reloadEmptyCustomSummaryLineItemExpenses(): void {
        const formArray = this.form.get('summarySection.customLineItemExpenses') as UntypedFormArray;
        formArray.clear();
        this.selectedRates.customSummaryLineItems.forEach(() => formArray.push(this._budgetEstimationService.createCustomLineItemExpenseField()));
    }

    private updateLaborSectionStatus(): void {
        if (this.form.get('isSubcontract').value) {
            this.laborSection.disable();
        }
        else {
            this.laborSection.enable();
        }
    }

    private async calculate(calculationToken: string): Promise<void> {
        if (!this.form.get('projectType').value) {
            return;
        }

        const currentPayload = this.getBudgetRecordPayload();
        const result = await this._serviceOperationsStandardizedBudgetsHttp.calculate(currentPayload);
        // If both tokens are not equal, it means another request is pending.
        // Discard this result.
        if (this._calculationToken !== calculationToken) {
            return;
        }

        if (result) {
            this.form.patchValue(result, { emitEvent: false });
            this.calculationUpdated$.next();
        }
        else {
            const error = this.translate.instant('flynnBudgetTool.budgetEstimation.errors.failedToCalculateBudget');
            this._snackBar.open(error, this.translate.instant('flynnBudgetTool.shared.ok'));
        }

        const updatedPayload = this.getBudgetRecordPayload();
        this.budgetPayloadUpdate.emit(this.form.valid ? updatedPayload : null);
        this._changeDetectorRef.markForCheck();
    }

    private async getBudgetRecord(): Promise<BudgetRecord<ServiceOperationsBudgetData> | null> {
        if (!this.recordId) {
            return null;
        }

        const result = this.isQuote ?
            await this._serviceOperationsStandardizedBudgetsHttp.getQuoteBudgetRecord(this.recordId) :
            await this._serviceOperationsStandardizedBudgetsHttp.getProjectBudgetRecord(this.recordId);

        if (result.isError) {
            this.isErrorState = true;
            return null;
        }

        this.isReadOnly = result.budget?.status === BudgetStatus.Finalized;

        return (result.budget?.budgetRecords ?? []).slice(-1)[0];
    }

    private async saveBudgetRecord(): Promise<boolean> {
        if (!this.recordId) {
            return false;
        }

        if (this.isQuote) {
            return await this._serviceOperationsStandardizedBudgetsHttp.saveQuoteBudgetRecord(this.recordId, this.getBudgetRecordPayload());
        }

        return await this._serviceOperationsStandardizedBudgetsHttp.saveProjectBudgetRecord(this.recordId, this.getBudgetRecordPayload());
    }

    private getBudgetRecordPayload(): BudgetRecordPayload<ServiceOperationsBudgetData> {
        return new BudgetRecordPayload(this.form.getRawValue(), this.selectedRatesQuery);
    }

    private async confirmFirstSave(): Promise<boolean> {
        if (this.isExisting) {
            return true;
        }

        const title = this.translate.instant('flynnBudgetTool.shared.areYouSure');
        const message = this.translate.instant('flynnBudgetTool.budgetEstimation.messages.rateVersionLockedAfterSave');
        const confirmText = this.translate.instant('flynnBudgetTool.shared.proceed');
        const cancelText = this.translate.instant('flynnBudgetTool.shared.cancel');
        const data = new ConfirmationPromptOptions(title, message, confirmText, cancelText, true);
        const dialog = this._dialog.open(ConfirmationPromptComponent, { data });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private reloadLeadQuestions(isChecked: boolean): void {
        this.leadQuestionsArray.clear();
        this.selectedRates.leadQuestions.forEach(question => {
            const field = this._budgetEstimationService.createLeadQuestionField(question);
            field.get('checked').setValue(isChecked);
            this.leadQuestionsArray.push(field);
        });
    }
}
