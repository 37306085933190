import { Component, Output, EventEmitter, AfterViewInit, Input, OnChanges, SimpleChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { EditorUtilityService } from '../../../../core/services/utility/editor-utility/editor-utility.service';
import { TranslateService } from '@ngx-translate/core';
import getQuoteTemplateEditorButtons from '../../../../shared/components/quote-editor/quote-editor-buttons/quote-template-editor-buttons';
import { QuoteEditorConstants } from '../../../../core/data-models/quote-general-info/quote-editor-constants';

@Component({
    selector: 'quote-template-editor',
    styleUrls: ['./quote-template-editor.component.scss'],
    templateUrl: './quote-template-editor.component.html',
    encapsulation: ViewEncapsulation.None
})
export class QuoteTemplateEditorComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() public content: string;
    @Input() public templateId: string;
    @Output() public onContentUpdate = new EventEmitter<string>();

    public editorId = `quote-template-textarea-${Date.now()}`;
    public customButtonOptions = [];
    private _isInit = false;

    constructor(private _editorUtilityService: EditorUtilityService, private _translate: TranslateService) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.templateId && this._isInit) {
            this._editorUtilityService.setContent(this.editorId, this.content);
        }
    }

    public ngOnInit(): void {
        this.customButtonOptions = getQuoteTemplateEditorButtons(this._translate);
    }

    public ngAfterViewInit(): void {
        this._editorUtilityService.setupEditor(this.editorId, { change: this.saveContent.bind(this) });

        if (this.content) {
            this._editorUtilityService.setContent(this.editorId, this.content);
        }

        this._isInit = true;
    }

    public addPlaceholder(identifier: string, placeholder: string): void {
        const id = `handle-${Date.now()}`;
        const handle = `<span id="${id}"></span>`;
        const value = `<span contenteditable="false" style="${QuoteEditorConstants.Style}" class="${identifier}">$$${placeholder}$$</span>${handle}`;
        const editor = this._editorUtilityService.getEditor(this.editorId);
        editor.exec('inserthtml', { value });
        this._editorUtilityService.focusToEnd(this.editorId, id);
        this.saveContent();
    }

    public saveContent(): void {
        this.content = this._editorUtilityService.getContent(this.editorId);
        this.onContentUpdate.emit(this.content);
    }
}
