<div class="dropdown-button-container">
    <button mat-raised-button
        class="dropdown-button-base"
        [ngClass]="{ 'dropdown-button-open': menuTrigger.menuOpen }"
        [disabled]="disabled"
        [matMenuTriggerFor]="menu"
        #menuTrigger="matMenuTrigger"
        fxLayout="row"
        fxLayoutAlign="center center">

        <mat-icon class="dropdown-button-icon" *ngIf="icon">{{ icon }}</mat-icon>
        <span class="dropdown-button-text">{{ text }}</span>
        <mat-icon class="dropdown-button-arrow">arrow_drop_down</mat-icon>
    </button>

    <mat-menu class="dropdown-button-options" #menu="matMenu" [xPosition]="position" (closed)="onClose()">
        <div *ngFor="let option of options"
            [ngClass]="{ 'dropdown-button-options-disabled': option.disableOption.isDisabled }"
            [matTooltip]="option.disableOption.isDisabled ? option.disableOption.tooltip : ''"
            matTooltipClass="dropdown-button-options-tooltip"
            matTooltipPosition="above">

            <button mat-menu-item
                [ngClass]="{ 'dropdown-button-menu-item-warning': option.isWarning }"
                [disabled]="option.disableOption.isDisabled"
                (click)="optionSelect.emit(option.text)">

                <mat-icon *ngIf="option.icon">{{ option.icon }}</mat-icon>
                {{ option.displayName ? option.displayName : option.text }}
            </button>
        </div>
    </mat-menu>
</div>
