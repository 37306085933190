import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import LeakReportData from '../../../../core/data-models/leak-report/leak-report-data';
import { LeakReportHttpService } from '../../../../core/services/http/leak-report-http/leak-report-http.service';
import { EditorUtilityService } from '../../../../core/services/utility/editor-utility/editor-utility.service';
import { GenericUtilityService } from '../../../../core/services/utility/generic-utility/generic-utility.service';

@Component({
    selector: 'leak-report-card',
    styleUrls: ['./leak-report-card.component.scss'],
    templateUrl: './leak-report-card.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LeakReportCardComponent implements OnChanges, OnInit {
    @Input() public projectId: string;
    @Input() public jobId = 0;
    @Input() public leakIndex = 0;
    @Input() public isSummary = false;
    private _editorId = '';
    private keyupSubject = new Subject<number>();
    private _leakReportName: string;
    private _leakSummaryName: string;

    get editorId(): string {
        return this._editorId;
    }

    get title(): string {
        return this.isSummary? this._leakSummaryName : this._leakReportName;
    }

    get leakReportData(): LeakReportData {
        return {
            projectId: this.projectId,
            jobId: this.jobId,
            leakIndex: this.leakIndex
        } as LeakReportData;
    }

    constructor(private _clipboard: Clipboard,
                private _translate: TranslateService,
                private _leakReportService: LeakReportHttpService,
                private _editorUtility: EditorUtilityService) { }

    public ngOnInit(): void {
        this._editorId = `leak-report-editor-${Date.now()}`;
        this.keyupSubject.pipe(debounceTime(400)).subscribe(() => this.saveContent());
        this._leakSummaryName = this._translate.instant('shared.leakReportCard.autoLeakSummary');
        this._leakReportName = this._translate.instant('shared.leakReportCard.autoLeakReport');
    }

    public async ngOnChanges(): Promise<void> {
        await this.loadContent();
    }

    public ngAfterViewInit(): void {
        const options = { change: this.saveContent.bind(this), keyup: this.onKeyup.bind(this) };
        this._editorUtility.setupEditor(this._editorId, options);
    }

    public onCopy(element: HTMLElement): void {
        const text = this._editorUtility.getContentText(this._editorId);

        if (this._clipboard.copy(text)) {
            const name = 'text-copied';
            element.classList.remove(name);
            setTimeout(() => element.classList.add(name));
        }
    }

    public async resetContent(): Promise<void> {
        const content = await this._leakReportService.resetLeakReport(this.leakReportData, this.isSummary);
        this._editorUtility.setContent(this._editorId, content);
        this.saveContent();
    }

    public printContent(): void {
        const content = this._editorUtility.getContent(this._editorId);
        GenericUtilityService.printHtmlContent(content);
    }

    private async loadContent(): Promise<void> {
        const content = await this._leakReportService.getLeakReport(this.leakReportData, this.isSummary);
        this._editorUtility.setContent(this._editorId, content);
        this.saveContent();
    }

    private async saveContent(): Promise<void> {
        const content = this._editorUtility.getContent(this._editorId);
        const data = { ...this.leakReportData, content };
        await this._leakReportService.saveLeakReport(data, this.isSummary);
    }

    private onKeyup(): void {
        this.keyupSubject.next(new Date().getTime());
    }
}
