import TranslationPair from './generic/translation-pair';
import TemplateFieldMeta from './template-field-meta';

export default class ValueAttributes {
    public type = 'text';
    public unit = '';
    public mediaUri = '';
    public additionalValue = '';
    public options: TranslationPair[] = [];
    public mappingTokens: string[] = [];
    public collectionEntryTemplate: TemplateFieldMeta;
}
