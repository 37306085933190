import DisableOption from './disable-option';

export default class ActionMenuItemOption {
    public text: string;
    public displayName?: string;
    public icon?: string;
    public isWarning?: boolean;
    public disableOption = new DisableOption();
    public callback?: () => void;

    constructor(text: string, icon = '', isWarning = false, callback: () => void = null, displayName = null) {
        this.text = text;
        this.displayName = displayName;
        this.icon = icon;
        this.isWarning = isWarning;
        this.callback = callback;
    }
}
