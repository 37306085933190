import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, OnInit, ViewEncapsulation } from '@angular/core';

import QuoteTemplate from '../../../../core/data-models/quote-template';
import CustomEditorOption from '../../../../core/data-models/custom-editor-option';
import { EditorUtilityService } from '../../../../core/services/utility/editor-utility/editor-utility.service';
import { QuoteEditorService } from '../quote-editor.service';
import { TranslateService } from '@ngx-translate/core';
import getQuoteTemplateEditorButtons from '../quote-editor-buttons/quote-template-editor-buttons';
import FollowUpQuotePlaceholderContainer from '../../../../core/data-models/quote-general-info/follow-up-quote-placeholder-container';
import QuotePlaceholder from '../quote-editor-buttons/quote-placeholder';
import { QuoteEditorConstants } from '../../../../core/data-models/quote-general-info/quote-editor-constants';

@Component({
    selector: 'quote-content-editor',
    styleUrls: ['./quote-content-editor.component.scss'],
    templateUrl: './quote-content-editor.component.html',
    encapsulation: ViewEncapsulation.None
})
export class QuoteContentEditorComponent implements OnChanges, OnInit, AfterViewInit {
    @Input() public amount = 0;
    @Input() public taxRate = 0;
    @Input() public templates: QuoteTemplate[] = [];
    @Input() public description = '';
    @Input() public followUpPlaceholders: FollowUpQuotePlaceholderContainer = new FollowUpQuotePlaceholderContainer();
    @Output() public descriptionChange = new EventEmitter<string>();
    @Output() public templateChange = new EventEmitter<QuoteTemplate>();
    private readonly _editorId = `quote-content-editor-${Date.now()}`;
    private _skipContentSync = false;

    public customButtonOptions: QuotePlaceholder[] = [];

    constructor(
        private _quoteEditorService: QuoteEditorService,
        private _editorUtility: EditorUtilityService,
        private _translate: TranslateService
    ) { }

    get editorId(): string {
        return this._editorId;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.description && !this._skipContentSync) {
            setTimeout(() => this._editorUtility.getEditor(this._editorId).value(this.description));
        }

        this._skipContentSync = false;
    }

    public ngOnInit(): void {
        this.customButtonOptions = getQuoteTemplateEditorButtons(this._translate);

        if (!this.followUpPlaceholders?.recommendationPlaceholder?.recommendation) {
            this.customButtonOptions = this.customButtonOptions.filter(_ => _.identifier !== QuoteEditorConstants.QuoteRecommendationIdentifier);
        }
    }

    public ngAfterViewInit(): void {
        const builtinOptions = {
            change: () => {
                this.description = this._editorUtility.getEditor(this._editorId).value();
                this.descriptionChange.emit(this.description);
                this._skipContentSync = true;
            }
        };

        const customOptions = new CustomEditorOption(this.description);
        this._editorUtility.setupEditor(this._editorId, builtinOptions, { ...customOptions, focusOnLoad: true });
    }

    public addPlaceholder(identifier: string, placeholder: string): void {
        const match = this.customButtonOptions.find(_ => _.identifier === identifier);
        if (!match) {
            return;
        }

        const id = `handle-${Date.now()}`;
        const handle = `<span id="${id}"></span>`;
        const value = `<span contenteditable="false" style="${QuoteEditorConstants.Style}" class="${identifier}">$$$${placeholder}$$$</span>${handle}`;
        const editor = this._editorUtility.getEditor(this._editorId);
        editor.exec('inserthtml', { value });
        this.onContentChange('', false);

        if (!match.isEditable) {
            this._editorUtility.focusToEnd(this._editorId, id);
        }
    }

    public onTemplateSelected(title: string): void {
        const template = this.templates.find(_ => _.title === title);
        this.templateChange.emit(template);
        this.onContentChange((template?.content ?? '').replace(/\^\^dol\^\^/g, '$$'));
    }

    private onContentChange(content = '', removeEmptySpan = true): void {
        const editor = this._editorUtility.getEditor(this._editorId);
        const raw = content ? content : editor.value();
        this.description = this._quoteEditorService.replacePlaceholders(raw, this.amount, this.taxRate, removeEmptySpan);
        this.description = this._quoteEditorService.replaceEditablePlaceholders(this.description, this.followUpPlaceholders, removeEmptySpan);

        editor.value(this.description);
        this.descriptionChange.emit(this.description);
        this._skipContentSync = true;
    }
}
