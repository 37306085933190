<div fxLayout="column" fxFill>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-form-field>
            <mat-label>{{ 'dataReport.quoteReport.searchField.label' | translate }}</mat-label>
            <input matInput [(ngModel)]="query" placeholder="{{ 'dataReport.quoteReport.searchField.placeholder' | translate }}">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'shared.branch' | translate }}</mat-label>
            <mat-select [(ngModel)]="branchId">
                <mat-option value='*'>{{ 'shared.all' | translate }}</mat-option>
                <mat-option *ngFor="let branch of branches" [value]="branch.code">{{branch.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'dataReport.quoteReport.status' | translate }}</mat-label>
            <mat-select [(ngModel)]="selectedStatuses" multiple>
                <mat-option *ngFor="let status of filterableStatuses" [value]="status.original">{{ status.translated }}</mat-option>
            </mat-select>
        </mat-form-field>
        <div>
            <button mat-raised-button color="primary" (click)="loadReport(0)">{{ 'dataReport.quoteReport.loadReport' | translate }}</button>
        </div>
    </div>
    <div fxLayout="row" class="report-grid-div">
        <kendo-grid [kendoGridBinding]="gridViewData" [pageSize]="15" [pageable]="true" class="quotes-report-grid"
        [groupable]="false" sortable="true" filterable="menu" (pageChange)="pageChange($event)">
            <ng-template kendoGridToolbarTemplate>
                <button kendoGridExcelCommand [disabled]="!gridViewData.length">{{ 'shared.exportExcel' | translate }}</button>
                <button class="k-button" (click)="printAllQuotes()" [disabled]="!gridViewData.length">{{ 'dataReport.quoteReport.printAll' | translate }}</button>
            </ng-template>
            <kendo-grid-column field="quoteId" title="{{ 'shared.quoteId' | translate }}" [filterable]="false">
            </kendo-grid-column>
            <kendo-grid-column field="quoteName" title="{{ 'shared.quoteName' | translate }}" [filterable]="false">
            </kendo-grid-column>
            <kendo-grid-column field="custName" title="{{ 'dataReport.quoteReport.customerColumn' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="custSiteName" title="{{ 'dataReport.quoteReport.siteColumn' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quoteBranch" title="{{ 'shared.branch' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-eq-operator></kendo-filter-eq-operator>
                        <kendo-filter-neq-operator></kendo-filter-neq-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quoteSalesCode" title="{{ (isInternal ? 'dataReport.quoteReport.salesCode' : 'dataReport.quoteReport.projectType') | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-eq-operator></kendo-filter-eq-operator>
                        <kendo-filter-neq-operator></kendo-filter-neq-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="origProjId" title="{{ 'dataReport.quoteReport.originProject' | translate }}" [filterable]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="!hasProjectUrl(dataItem.origProjId)">{{dataItem.origProjId}}</span>
                    <a *ngIf="hasProjectUrl(dataItem.origProjId)" href="/projects/{{dataItem.origProjId}}" target="_blank">{{dataItem.origProjId}}</a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="destProjId" title="{{ 'dataReport.quoteReport.destinationProject' | translate }}" [filterable]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="!hasProjectUrl(dataItem.destProjId)">{{dataItem.destProjId}}</span>
                    <a *ngIf="hasProjectUrl(dataItem.destProjId)" href="/projects/{{dataItem.destProjId}}" target="_blank">{{dataItem.destProjId}}</a>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quoteTotal" title="{{ 'shared.quoteTotal' | translate }}" [filterable]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.quoteTotal | currency }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="quoteCreationDate" title="{{ 'shared.creationDate' | translate }}" [filterable]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.quoteCreationDate | branchLocalTime: dataItem.quoteBranch : branches : false}}
                 </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="estimator" title="{{ 'dataReport.quoteReport.estimator' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                        <kendo-filter-eq-operator></kendo-filter-eq-operator>
                        <kendo-filter-neq-operator></kendo-filter-neq-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="customerClass" title="{{ 'dataReport.quoteReport.customerClass' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="eq">
                        <kendo-filter-eq-operator></kendo-filter-eq-operator>
                        <kendo-filter-neq-operator></kendo-filter-neq-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="status" title="{{ 'shared.status' | translate }}" [filterable]="false">
            </kendo-grid-column>
            <kendo-grid-column field="noFollowUp" title="{{ 'dataReport.quoteReport.noFollowUp' | translate }}" [filterable]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{(dataItem.noFollowUp? 'shared.yes' : 'shared.no') | translate }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="followUpLogsCount" title="{{ 'dataReport.quoteReport.followUpAttempts' | translate }}" [filterable]="false">
            </kendo-grid-column>
            <kendo-grid-column field="reportingContact.contactName" title="{{ 'dataReport.quoteReport.contact' | translate }}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="reportingContact.email" title="{{ 'dataReport.quoteReport.email' | translate }}" [filterable]="false">
            </kendo-grid-column>
            <kendo-grid-column field="reportingContact.contactNumber" title="{{ 'dataReport.quoteReport.phone' | translate }}" [filterable]="false">
            </kendo-grid-column>
            <kendo-grid-column>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button *ngIf="canDelete(dataItem)" (click)="deleteQuote(dataItem.quoteId)" mat-icon-button aria-label="delete button">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button (click)="editQuote(dataItem.quoteId)" mat-icon-button aria-label="edit button">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button (click)="printQuote(dataItem)" mat-icon-button aria-label="print button">
                        <mat-icon>print</mat-icon>
                    </button>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel fileName="quotesReportData.xlsx" [fetchData]="allData" filterable="true">
                <kendo-excelexport-column *ngFor="let column of excelExportColumns" [field]="column.field" [title]="column.title">
                </kendo-excelexport-column>
            </kendo-grid-excel>
        </kendo-grid>
    </div>
    </div>
