<dialog-action-card class="name-prompt-dialog-container"
    [icon]="'create'"
    [title]="title"
    [isLoaded]="true"
    (close)="onClose()">

    <div class="name-prompt-dialog-content" fxLayout="row" fxLayoutAlign="center center">
        <mat-form-field>
            <mat-label>{{ 'shared.name' | translate }}</mat-label>
            <input type="text" matInput cdkFocusInitial [formControl]="name" maxlength="40" />

            <mat-error *ngIf="name.invalid && name.errors.empty">
                * {{ 'shared.emptyNameErrorText' | translate }}
            </mat-error>
            <mat-error *ngIf="name.invalid && name.errors.callbackError">
                * {{ errorMessage }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="name-prompt-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="onClose(true)">{{ 'shared.add' | translate }}</button>
    </div>
</dialog-action-card>
