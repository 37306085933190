export enum ReadPermission {
    ReadProjectReport = 0,
    ReadInvoice = 1,
    ReadProjectReview = 2,
    ViewProjects = 3,
    ViewQuotes = 4,
    ViewDataReports = 5,
    ViewLaborReview = 6,
    ViewOnCallSchedules = 7,
    ViewCrmManagement = 8,
    ViewServiceDashboard = 9,
    ViewServiceMap = 10,
    ViewDictionary = 11,
    ReadComments = 12,
    ReadProcessorReview = 13,
    ViewBranchFilter = 14,
    ViewServiceProviderCrewManagement = 15,
    ViewInternationalData = 16,
    ViewBranchPerformanceAcknowledgement = 17,
    ViewMobileScanFiles = 18,
    ViewAllBranchData = 19,
    ViewReportTemplateEditor = 20,
    ViewServiceRates = 21,
    ViewScheduleBoard = 22
}
