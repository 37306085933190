import { Component, OnInit, Input } from '@angular/core';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import ProjectHistory from '../../../core/data-models/project-history';

@Component({
    selector: 'project-history',
    templateUrl: './project-history.component.html',
    styleUrls: ['./project-history.component.scss']
})

export class ProjectHistoryComponent implements OnInit {
    @Input() public activeProjectId = '';
    public gridViewData: ProjectHistory[] = [];
    private _isLoaded = false;

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    public constructor(private _projectService: ProjectHttpService) { }

    public async ngOnInit(): Promise<void> {
        this.gridViewData = await this._projectService.getProjectHistory(this.activeProjectId);
        this._isLoaded = true;
    }
}
