<dialog-action-card [icon]="'business_center'" [title]="translate.instant('projects.dispatchManagement.dispatchNewJob')"
    [isLoaded]="isLoaded" (close)="onClose()">

    <div *ngIf="isLoaded" fxFlexFill>
        <mat-horizontal-stepper fxFlexFill linear #dispatchStepper>
            <mat-step fxFlexFill [label]="foremanSelectionStepConfig.label" [completed]="foremanSelectionStepConfig.completed" [editable]="true">
            <dispatch-report
                (stepClicked)="handleStep($event)"
                (jobTypeChanged)="updateJobTypeRequirements($event)"
                (selectedCrewChanged)="determineIfHandoffRequiredForDispatch($event)"
                [dispatchExistingOptions]="dispatchExistingOptions"
                [handOffMeetingApprovedCrew]="crewReferencedInLastHandOffMeeting"
                [config]="foremanSelectionStepConfig">
            </dispatch-report>
            </mat-step>
            <mat-step [label]="attachmentStepConfig.label" [completed]="attachmentStepConfig.completed">
                <app-attachment-step
                    [config]="attachmentStepConfig"
                    (stepClicked)="handleStep($event)">
                </app-attachment-step>
            </mat-step>
            <mat-step [label]="workOrderStepConfig.label" [completed]="workOrderStepConfig.completed" [editable]="true">
                <div fxLayout="column" fxLayoutAlign="center center">
                    <app-work-order-step
                        [config]="workOrderStepConfig"
                        (stepClicked)="handleStep($event)">
                    </app-work-order-step>
                </div>
            </mat-step>
            <mat-step *ngIf="handOffMeetingNeededOnThisDispatch"
            [completed]="handoffMeetingStepConfig.completed" [editable]="true" [label]="handoffMeetingStepConfig.label">
                <handoff-meeting (stepClicked)="handleStep($event)"
                    [hasFollowUpStep]="true"
                    [foremanName]="foremanSelectionStepConfig?.data?.crew?.foreman?.preferredName"
                    [config]="handoffMeetingStepConfig"
                    [projectId]="dispatchExistingOptions.projectId"
                    [projectName]="dispatchExistingOptions.projectName">
                </handoff-meeting>
            </mat-step>
            <mat-step label="{{ 'projects.dispatchManagement.summary' | translate }}">
                <div fxLayout="column" fxLayoutGap="15px" class="dispatch-summary-content">
                    <div fxLayout="column" fxFlex fxLayoutGap="15px" class="dispatch-summary-main-content">
                        <mat-card *ngIf="foremanSelectionStepConfig.completed">
                            <mat-card-title>
                                {{ 'projects.dispatchManagement.projectInfo' | translate }}
                            </mat-card-title>
                            <mat-card-content>
                                <div>
                                    {{ 'projects.dispatchManagement.creationFragments.fragment1' | translate }}
                                    <span class="summary-info-span">{{ foremanSelectionStepConfig.data.jobType | jobTypeText: user?.language }} </span>
                                    {{ 'projects.dispatchManagement.creationFragments.fragment2' | translate }}
                                    <span class="summary-info-span">{{dispatchExistingOptions.branch}} </span>.
                                    <span>
                                        <span>
                                            {{ 'projects.dispatchManagement.creationFragments.fragment3' | translate }}
                                            <span class="summary-info-span">{{foremanSelectionStepConfig.data.crew?.crewName}}</span>
                                        </span>
                                    </span>
                                </div>
                                <div>
                                {{ 'projects.dispatchManagement.creationFragments.fragment4' | translate }}
                                <span *ngIf="foremanSelectionStepConfig.data.expectedDays">
                                    <span class="summary-info-span">
                                        {{foremanSelectionStepConfig.data.expectedDays}}
                                        {{ 'projects.dispatchManagement.creationFragments.fragment5' | translate }}
                                    </span>
                                </span>
                                </div>
                            </mat-card-content>
                            <mat-card-actions fxLayout="row" fxLayoutAlign="end center" >
                                <button mat-button color="primary" (click)="goToStep(foremanSelectionStepConfig.label)">
                                    {{ 'projects.dispatchManagement.update' | translate }}
                                </button>
                            </mat-card-actions>
                            </mat-card>
                            <mat-card>
                                <mat-card-title>
                                    {{ 'projects.dispatchManagement.attachments' | translate }}
                                </mat-card-title>
                                <mat-card-content>
                                    <label>
                                        {{ 'projects.dispatch.youHaveSelected' | translate }}
                                    </label>
                                    <ul>
                                        <li *ngIf="attachmentStepConfig.attachments.length"><span class="summary-info-span">{{attachmentStepConfig.attachments.length}}</span>
                                            {{ 'projects.dispatchManagement.attachments' | translate }}
                                        </li>
                                        <li *ngIf="!attachmentStepConfig.attachments.length">
                                            {{ 'projects.dispatchManagement.noAttachments' | translate }}
                                        </li>
                                        <li *ngIf="attachmentStepConfig.roofMap">
                                            {{ 'projects.dispatchManagement.roofPlan' | translate }}
                                        </li>
                                    </ul>
                                </mat-card-content>
                                <mat-card-actions fxLayout="row" fxLayoutAlign="end center" >
                                <button mat-button color="primary" (click)="goToStep(attachmentStepConfig.label)">
                                    {{ 'projects.dispatchManagement.update' | translate }}
                                </button>
                                </mat-card-actions>
                            </mat-card>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
                        <button mat-button matStepperPrevious>
                            {{ 'projects.dispatchManagement.back' | translate }}
                        </button>
                        <button mat-flat-button (click)="createDispatchRequest()" [disabled]="dispatching" color="primary">
                            {{ 'projects.dispatchManagement.confirm' | translate }}
                        </button>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>

</dialog-action-card>
