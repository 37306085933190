import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StateService } from '@uirouter/core';

import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';
import { AppEventService } from '../../../core/services/events/app-event/app-event.service';
import { DynamicsHoldStatus } from '../../../core/enums/dynamics-hold-status.enum';
import { environment } from '../../../../environments/environment';
// eslint-disable-next-line
declare const zE: any;

@Component({
    selector: 'top-toolbar',
    styleUrls: ['./top-toolbar.component.scss'],
    templateUrl: './top-toolbar.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TopToolbarComponent implements OnInit, OnDestroy {
    public logoUrl = '';
    public isDynamicsOnHold = false;
    private _showHelpWidget = false;
    private _subscriptions = new Subscription();

    constructor(private _state: StateService,
                private _projectHttp: ProjectHttpService,
                private _appConfigService: AppConfigHttpService,
                private _appEvents: AppEventService) { }

    get siteName(): string {
        return environment.websiteTitle ?? '';
    }

    get toolbarColor(): string {
        return environment.toolbarColor ?? null;
    }

    get showHelpWidget(): boolean {
        return this._showHelpWidget;
    }

    public async ngOnInit(): Promise<void> {
        zE('webWidget:on', 'open', () => {
            zE('webWidget', 'show');
            this._showHelpWidget = true;
        });

        zE('webWidget:on', 'close', () => {
            zE('webWidget', 'hide');
            this._showHelpWidget = false;
        });

        this.logoUrl = await this._appConfigService.getLogoUrl();
        this.isDynamicsOnHold = await this._projectHttp.getDynamicsHoldStatus() === DynamicsHoldStatus.Hold;

        this._subscriptions.add(this._appEvents.dynamicsHoldUpdated.subscribe(_ => {
            this.isDynamicsOnHold = _;
        }));
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public toHomeState(): void {
        this._state.go('projects', null, { reload: true });
    }

    public onHelp(): void {
        zE('webWidget', this._showHelpWidget ? 'close' : 'open');
    }
}
