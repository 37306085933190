<dialog-action-card class="project-stock-log-dialog-container"
    [icon]="'construction'"
    [title]="translate.instant('projects.stockLog.title')"
    [isLoaded]="!isLoading"
    (close)="onClose()">

    <div *ngIf="!isLoading" class="project-stock-log-dialog-content" fxLayout="column" fxLayoutGap="10px">
        <mat-form-field>
            <mat-label>{{ 'projects.stockLog.projectId' | translate }}</mat-label>
            <input matInput [readonly]="true" [value]="stockLog.projectId" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'projects.stockLog.projectName' | translate }}</mat-label>
            <input matInput [readonly]="true" [value]="stockLog.projectName" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'projects.stockLog.currency' | translate }}</mat-label>
            <input matInput [readonly]="true" [value]="stockLog.currency" />
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'projects.stockLog.totalCost' | translate }}</mat-label>
            <input type="number" matInput cdkFocusInitial [formControl]="totalCostInput" min="0" />
            <mat-error *ngIf="totalCostInput.errors?.required">{{ 'projects.stockLog.requiredField' | translate }}</mat-error>
            <mat-error *ngIf="totalCostInput.errors?.min">{{ 'projects.stockLog.negativeError' | translate }}</mat-error>
        </mat-form-field>
    </div>

    <div class="project-stock-log-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="updateStockLog()">{{ 'projects.stockLog.updateStockLog' | translate }}</button>
    </div>
</dialog-action-card>
