<div *ngIf="isLoaded" class="subcontract-general-info-panel-container" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="7px">
    <div class="subcontract-general-info-panel-columns" fxLayout="row" fxLayoutAlign="space-between">
        <div class="subcontract-general-info-panel-column-1">
            <information-grid-card [icon]="'projects'"
                [allowAnimation]="hasAnimation"
                [animationDelay]="0.05"
                [useSvg]="true"
                [title]="translate.instant('projects.detailsSection.projectInformation')"
                [config]="basicInfoCardConfig">
            </information-grid-card>

            <div class="subcontract-general-info-panel-basic-info-actions" fxLayout="column" fxLayoutGap="6px">
                <expandable-button *ngFor="let option of actionOptions"
                    [icon]="option.icon"
                    [text]="option.displayName ? option.displayName : option.text"
                    [isWarning]="option.isWarning"
                    (click)="actionSelect.emit(option)">
                </expandable-button>
            </div>
        </div>

        <div class="subcontract-general-info-panel-column-2" fxLayout="column" fxLayoutAlign="space-between center">
            <information-grid-card [icon]="'supervisor_account'"
                [title]="translate.instant('projects.detailsSection.customerInformation')"
                [hasAdditionalDetails]="!isReadonly && generalInfo.customer?.customerId"
                [allowAnimation]="hasAnimation"
                [animationDelay]="0.1"
                [config]="customerCardConfig"
                (dataUpdate)="customerUpdate.emit($event)"
                (openAdditionalDetails)="showCustomerDetails = true">
            </information-grid-card>

            <information-grid-card [icon]="'local_shipping'"
                [title]="translate.instant('shared.projectGeneralInfoPanel.workOrderTitle')"
                [allowAnimation]="hasAnimation"
                [animationDelay]="0.15"
                [config]="workOrderCardConfig"
                (dataUpdate)="basicInfoUpdate.emit($event)"
                (itemDoubleClick)="onItemDoubleClick($event)">
            </information-grid-card>

            <customer-details-card *ngIf="showCustomerDetails"
                class="customer-details-card"
                [customer]="generalInfo.customer"
                (close)="showCustomerDetails = false">
            </customer-details-card>
        </div>

        <div class="subcontract-general-info-panel-column-3"
            fxLayout="column"
            fxLayoutAlign="space-between center">

            <information-grid-card [icon]="'contact_phone'"
                [title]="translate.instant('projects.detailsSection.reportingContact')"
                [allowAnimation]="hasAnimation"
                [animationDelay]="0.2"
                [config]="reportingContactCardConfig"
                (dataUpdate)="reportingContactUpdate.emit($event)">
            </information-grid-card>

            <information-grid-card [icon]="'contact_phone'"
                [title]="translate.instant('projects.detailsSection.siteContact')"
                [allowAnimation]="hasAnimation"
                [animationDelay]="0.25"
                [config]="siteContactCardConfig"
                (dataUpdate)="siteContactUpdate.emit($event)">
            </information-grid-card>

            <information-grid-card [icon]="'contact_phone'"
                [title]="translate.instant('projects.detailsSection.billingContact')"
                [allowAnimation]="hasAnimation"
                [animationDelay]="0.3"
                [config]="billingContactCardConfig">
            </information-grid-card>
        </div>
    </div>
</div>
