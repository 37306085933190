/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import JsonUtility from '../../utility/json-utility.service';
import QuoteGeneralInfo from '../../../data-models/quote-general-info/quote-general-info';
import CustomerQuotesReport from '../../../data-models/data-report/customer-quotes-report';
import CustomerQuotesReportQuery from '../../../data-models/data-report/customer-quotes-report-query';
import QuoteTemplate from '../../../data-models/quote-template';
import QuoteWithCategoryAndTotal from '../../../data-models/quote-with-categories-and-total';
import QuoteReport from '../../../data-models/data-report/quote-report';
import QuoteFollowUpLogEntry from '../../../data-models/quote-follow-up-log-entry';
import QuoteSummary from '../../../data-models/summary-info/quote-summary';
import SummaryQuery from '../../../data-models/summary-info/summary-query';
import SummaryQueryResult from '../../../data-models/summary-info/summary-query-result';
import QuoteFollowUpLog from '../../../data-models/quote-follow-up-log';
import QuoteFollowUpReminder from '../../../data-models/quote-follow-up-reminder';
import AttachmentModel from '../../../data-models/attachment-model';
import ImageItem from '../../../data-models/job-template-items/image-item';
import UserComment from '../../../data-models/user-comment/user-comment';
import QuoteSaveContext from '../../../data-models/quote-save-context';
import QuoteCounters from '../../../data-models/quote-general-info/quote-counters';
import CodeDescription from '../../../data-models/code-description';
import EmergencyQuoteReportPrintingOptions from '../../../data-models/quote-options/report-printing-options';
import { environment } from '../../../../../environments/environment';
import FollowUpQuotePlaceholderContainer from '../../../data-models/quote-general-info/follow-up-quote-placeholder-container';

@Injectable({
    providedIn: 'root'
})
export class QuoteHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/quote`;

    constructor(
        private _translate: TranslateService,
        private _httpClient: HttpClient) { }

    public async getSummaries(query: SummaryQuery): Promise<SummaryQueryResult<QuoteSummary>> {
        try {
            const endpoint = `${this._api}/get/unattached/quoteList`;
            const data = await this._httpClient.post(endpoint, query).toPromise();

            return { ...JsonUtility.toCamelCaseKeys(data), pageSize: query.pageSize };
        }
        catch {
            return { ...new SummaryQueryResult<QuoteSummary>(), pageSize: query.pageSize };
        }
    }

    public async getQuoteGeneralInfo(quoteId: string): Promise<QuoteGeneralInfo> {
        try {
            const endpoint = `${this._api}/${quoteId}/general-info`;
            const data = await this._httpClient.get<QuoteGeneralInfo>(endpoint).toPromise();
            const transformed: QuoteGeneralInfo = JsonUtility.toCamelCaseKeys(data);
            const { amount, taxRate } = transformed.estimation;
            transformed.estimation.total = amount * (taxRate / 100 + 1);

            return transformed;
        }
        catch {
            return null;
        }
    }

    public async createQuote(context: QuoteSaveContext): Promise<CodeDescription> {
        try {
            const { quote, customerSelection, pendingQuoteAttachments, webBudgetPayload, parentQuoteId } = context;
            const endpoint = `${this._api}/create`;
            const attachments = Array.from(pendingQuoteAttachments.localFiles.keys());
            const copiedAttachments = pendingQuoteAttachments.copiedAttachments;
            const data = new FormData();
            data.append('quote', JSON.stringify(quote));
            data.append('customerSelection', JSON.stringify(customerSelection));
            data.append('attachments', JSON.stringify(attachments));
            data.append('copiedAttachments', JSON.stringify(copiedAttachments));
            data.append('webBudgetPayload', JSON.stringify(webBudgetPayload));
            data.append('parentQuoteId', JSON.stringify(parentQuoteId));

            for (const attachment of attachments) {
                data.append('file', pendingQuoteAttachments.localFiles.get(attachment), attachment.name);
            }

            const id = await this._httpClient.post<string>(endpoint, data).toPromise();
            const sucessMessage = this._translate.instant('core.httpServices.quote.createdQuoteSuccess');
            return new CodeDescription('200', sucessMessage, id);
        }
        catch (error) {
            const { status, error: statusText } = error as HttpErrorResponse;

            return new CodeDescription(String(status), statusText);
        }
    }

    public async updateQuote(context: QuoteSaveContext): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/update`;
            const id = await this._httpClient.put<string>(endpoint, context).toPromise();

            const sucessMessage = this._translate.instant('core.httpServices.quote.updatedQuoteSuccess');
            return new CodeDescription('200', sucessMessage, id);
        }
        catch (error) {
            const { status, error: statusText } = error as HttpErrorResponse;

            return new CodeDescription(String(status), statusText);
        }
    }

    public async getCustomerReportQuotes(query: CustomerQuotesReportQuery) : Promise<CustomerQuotesReport[]> {
        try {
            const endpoint = `${this._api}/get/customerreport/`;
            const data = await this._httpClient.post(endpoint, query).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<CustomerQuotesReport>();
        }
    }

    public async getProjectQuotes(projectId: string): Promise<QuoteWithCategoryAndTotal[]> {
        try {
            const endpoint = `${this._api}/getByProject/${projectId}`;
            const data = await this._httpClient.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<QuoteWithCategoryAndTotal>();
        }
    }

    public async detachQuote(quoteId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/detach/${quoteId}`;
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            return await this._httpClient.post<boolean>(endpoint, {}, { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async attachQuote(quote: QuoteWithCategoryAndTotal): Promise<boolean> {
        try {
            const endpoint = `${this._api}/attach`;

            return await this._httpClient.post<boolean>(endpoint, quote).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getFilteredQuotesWithStatus(branchId: string, status: string, query: string) : Promise<QuoteReport[]> {
        try {
            const endpoint = `${this._api}/getByQuery/${branchId}/${status}`;
            const header = { headers: { 'content-type': 'application/json' } };
            const data = await this._httpClient.post(endpoint, JSON.stringify(query), header).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<QuoteReport>();
        }
    }

    public async getAllowedStatusForDelete() : Promise<string[]> {
        try {
            const endpoint = `${this._api}/status/delete/allowedstatus/`;

            return await this._httpClient.get<string[]>(endpoint).toPromise();
        }
        catch {
            return new Array<string>();
        }
    }

    public async deleteQuote(quoteId: string) : Promise<boolean> {
        try {
            const endpoint = `${this._api}/delete/${quoteId}`;
            await this._httpClient.delete(endpoint).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public getQuotePdfUrl(quoteId: string) : string {
        return `${this._api}/pdf/${quoteId}`;
    }

    public getQuotePdfUrlWithDateQuery(quoteId: string, creationDate: string) : string {
        const url = `${this._api}/pdf/${quoteId}?reportDate=${creationDate}`;
        return url;
    }

    public async generateCombinedPdf(quoteIds: string[]) : Promise<boolean> {
        try {
            const endpoint = `${this._api}/pdf/all`;
            const header = { headers: { 'content-type': 'application/json' } };

            return await this._httpClient.post<boolean>(endpoint, JSON.stringify(quoteIds), header).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getQuotePdfPreview(quote: QuoteSaveContext): Promise<Blob> {
        try {
            const url = `${this._api}/pdf-preview`;
            const data = await this._httpClient.post(url, quote, { responseType: 'arraybuffer' }).toPromise();
            return new Blob([data], { type: 'application/pdf' });
        }
        catch {
            return null;
        }
    }

    public async getFollowUpLogs(quoteId: string): Promise<QuoteFollowUpLogEntry[]> {
        try {
            const endpoint = `${this._api}/getFollowUpLogs/${quoteId}`;
            const data = await this._httpClient.get<QuoteFollowUpLogEntry[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data ?? []);
        }
        catch {
            return new Array<QuoteFollowUpLogEntry>();
        }
    }

    public async addFollowUpLog(quoteId: string, followUpLog: QuoteFollowUpLog): Promise<boolean> {
        try {
            const endpoint = `${this._api}/follow-up-log/${quoteId}`;

            return await this._httpClient.post<boolean>(endpoint, followUpLog).toPromise();
        }
        catch {
            return false;
        }
    }

    public async addFollowUpReminder(quoteId: string, followUpReminder: QuoteFollowUpReminder): Promise<boolean> {
        try {
            const endpoint = `${this._api}/updateReminder/${quoteId}`;

            return await this._httpClient.post<boolean>(endpoint, followUpReminder).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getQuoteTemplates(): Promise<QuoteTemplate[]> {
        try {
            const endpoint = `${this._api}/templates`;
            const data = await this._httpClient.get<QuoteTemplate[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data).sort((a, b) => a.title.localeCompare(b.title));
        }
        catch {
            return null;
        }
    }

    public async createQuoteTemplate(template: QuoteTemplate): Promise<CodeDescription<QuoteTemplate>> {
        try {
            const endpoint = `${this._api}/template/new`;
            const data = await this._httpClient.post<QuoteTemplate>(endpoint, template).toPromise();

            return new CodeDescription<QuoteTemplate>('200', '', JsonUtility.toCamelCaseKeys(data));
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 400:
                case 401:
                case 403:
                    return new CodeDescription<QuoteTemplate>(httpError.status.toString(), httpError.error);

                default:
                    return new CodeDescription<QuoteTemplate>('500', internalErrorMessage);
            }
        }
    }

    public async updateQuoteTemplate(template: QuoteTemplate): Promise<boolean> {
        try {
            const endpoint = `${this._api}/template/update`;
            return await this._httpClient.post<boolean>(endpoint, template).toPromise();
        }
        catch {
            return false;
        }
    }

    public async deleteQuoteTemplate(id: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/template/delete/${id}`;
            return await this._httpClient.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getQuoteAttachments(quoteId: string): Promise<AttachmentModel[]> {
        try {
            const endpoint = `${this._api}/attachments/${quoteId}`;
            const data = await this._httpClient.get<AttachmentModel[]>(endpoint).toPromise();

            data.forEach(_ => {
                _.source = 'server';
            });

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async deleteQuoteAttachment(quoteId: string, attachment: AttachmentModel): Promise<boolean> {
        try {
            const name = encodeURIComponent(attachment.name);
            const endpoint = `${this._api}/attachments/${quoteId}?filename=${name}&type=${attachment.type}`;
            await this._httpClient.delete(endpoint).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async deleteBudget(quoteId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${quoteId}/budget`;
            return await this._httpClient.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getQuotePhotos(quoteId: string): Promise<ImageItem[]> {
        try {
            const endpoint = `${this._api}/attachment/images/${quoteId}`;
            const data = await this._httpClient.get<ImageItem[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getQuoteComments(quoteId: string): Promise<UserComment[]> {
        try {
            const endpoint = `${this._api}/${quoteId}/comments`;
            const data = await this._httpClient.get<UserComment[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async addQuoteComment(quoteId: string, content: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${quoteId}/comments`;
            const headers = { 'content-type': 'application/json' };

            return await this._httpClient.post<boolean>(endpoint, JSON.stringify(content), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async updateQuoteComment(quoteId: string, commentId: string, content: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${quoteId}/comments/${commentId}`;
            const headers = { 'content-type': 'application/json' };

            return await this._httpClient.put<boolean>(endpoint, JSON.stringify(content), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async deleteQuoteComment(quoteId: string, commentId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${quoteId}/comments/${commentId}`;

            return await this._httpClient.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getQuoteBudgetValue(quoteId: string): Promise<number> {
        try {
            const endpoint = `${this._api}/budget/amount/${quoteId}`;
            return await this._httpClient.get<number>(endpoint).toPromise();
        }
        catch {
            return 0;
        }
    }

    public async getQuoteCounters(quoteId: string, option = ''): Promise<QuoteCounters> {
        try {
            const endpoint = `${this._api}/${quoteId}/counters/${option}`;
            const data = await this._httpClient.get<QuoteCounters>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new QuoteCounters();
        }
    }

    public async getQuoteBudgetAsXlsx(quoteId: string): Promise<Blob> {
        try {
            const endpoint = `${this._api}/${quoteId}/budgetfile/xlsx`;
            return await this._httpClient.get(endpoint, { responseType: 'blob' }).toPromise();
        }
        catch {
            return null;
        }
    }

    public async updateDetailsFromEmergencyQuote(emergencyQuoteId: string, quoteId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/update/fromreport/${emergencyQuoteId}/${quoteId}`;
            await this._httpClient.post(endpoint, null).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async quoteHasBudget(quoteId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${quoteId}/has-budget-file`;
            return await this._httpClient.get<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getEmergencyQuoteReportPrintingOptions(quoteId: string): Promise<EmergencyQuoteReportPrintingOptions> {
        try {
            const endpoint = `${this._api}/${quoteId}/options/emergency-quotes-report-printing`;
            const data = await this._httpClient.get<EmergencyQuoteReportPrintingOptions>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new EmergencyQuoteReportPrintingOptions();
        }
    }

    public async getSiteQuotesByQuoteId(quoteId: string): Promise<QuoteWithCategoryAndTotal[]> {
        try {
            const endpoint = `${this._api}/site-quotes-by-quoteId/${quoteId}`;
            const data = await this._httpClient.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<QuoteWithCategoryAndTotal>();
        }
    }

    public async getFollowUpPlaceholdersByProjectId(projectId: string): Promise<FollowUpQuotePlaceholderContainer> {
        try {
            const endpoint = `${this._api}/follow-up-placeholders/${projectId}`;
            const data = await this._httpClient.get<FollowUpQuotePlaceholderContainer>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new FollowUpQuotePlaceholderContainer();
        }
    }

    public async getCustomerIdByQuoteId(quoteId: string): Promise<string> {
        try {
            const endpoint = `${this._api}/${quoteId}/customer-id`;
            return await this._httpClient.get<string>(endpoint).toPromise();
        }
        catch {
            return '';
        }
    }
}
