<div class="leak-report-template-editor-container">
    <div fxLayout="column" class="main-col" fxLayoutGap="20px">
        <div fxFlex="92">
            <textarea [id]="templateId"></textarea>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <button fxFlex="4" mat-raised-button color="primary" (click)="save()">
                {{ 'shared.save' | translate }}
            </button>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
        <button *ngFor="let option of customButtonOptions" (click)="addPlaceholder(option.identifier, option.placeholder)">
            {{ option.name }}
        </button>
    </div>
</div>