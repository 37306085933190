<div fxLayout="column" id="quote-template-component-wrapper" fxLayoutGap="20px">
    <div fxFlex="6.7" fxLayout="row">
        <div class="template-action-container" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex="100">
            <button fxFlex="2" [disabled]="!canDeleteTemplate" mat-raised-button color="warn" (click)="deleteTemplate()"><i class="fa fa-trash fa-lg"></i></button>
            <mat-form-field appearance="outline" fxFlex="35">
                <mat-label>{{ 'shared.quote.templateEditor.selectedTemplate' | translate }}</mat-label>
                <mat-select (selectionChange)="changeSelection($event)" [value]="template">
                    <mat-option [value]="null"></mat-option>
                    <mat-option *ngFor="let option of templates" [value]="option">{{option.title}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="35">
                <mat-label>{{ 'shared.quote.templateEditor.newTemplateName' | translate }}</mat-label>
                <input [disabled]="!canEnterTemplateName" matInput [(ngModel)]="newTemplateName" type="text">
            </mat-form-field>
            <button fxFlex="2" [disabled]="!canAddTemplate" mat-raised-button color="primary" (click)="addTemplateToList()"><i class="fa fa-plus fa-lg"></i></button>
        </div>
    </div>
    <ng-container *ngIf="loaded && template">
        <div fxLayout="row" fxFlex="85">
            <mat-tab-group fxFlex="100" id="quote-template-tab-group" [(selectedIndex)]="selectedIndex" [dynamicHeight]="true">
                <mat-tab label="{{ 'shared.quote.templateEditor.quoteContent' | translate }}">
                    <ng-template matTabContent>
                        <quote-template-editor *ngIf="selectedIndex === 0" [templateId]="template.title" [content]="template.content" (onContentUpdate)="updateContent($event)"></quote-template-editor>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div fxFlex="5" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
            <button mat-stroked-button color="primary" (click)="copyTemplate()" [disabled]="!canCopyTemplate">{{ 'shared.quote.templateEditor.copyTemplate' | translate }}</button>
            <button mat-raised-button color="primary" (click)="saveTemplate()" [disabled]="!canSave">{{ 'shared.quote.templateEditor.saveTemplate' | translate }}</button>
        </div>
    </ng-container>
</div>
