import ImageItem from '../job-template-items/image-item';
import ImageCardConfig from './image-card-config';

export default class PhotoReportImageCard {
    public photoConfig: ImageCardConfig;
    public photo: ImageItem;

    public constructor(photoConfig: ImageCardConfig, photo: ImageItem) {
        this.photoConfig = photoConfig;
        this.photo = photo;
    }
}
