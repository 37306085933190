import { Component, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';

import QuoteFollowUpLog from '../../../core/data-models/quote-follow-up-log';

@Component({
    selector: 'add-follow-up-log',
    templateUrl: './add-follow-up-log.component.html',
    styleUrls: ['./add-follow-up-log.component.scss']
})
export class AddFollowUpLogComponent {
    @Input() public quoteEditorMode = false;
    @Output() public result = new EventEmitter<QuoteFollowUpLog>();
    @ViewChild('logContent', { read: ElementRef }) public logContent: ElementRef;
    public followUpLog = { isFollowUpAttempt: false, followUpText: '' } as QuoteFollowUpLog;

    public add(): void {
        if (this.followUpLog.followUpText) {
            this.result.emit(this.followUpLog);
        }
        else {
            this.logContent.nativeElement.focus();
        }
    }

    public cancel(): void {
        this.result.emit(null);
    }
}
