import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import ActionProgressReporterOption from '../../core/data-models/action-progress-reporter-option';
import CodeDescription from '../../core/data-models/code-description';
import CustomReportTemplate from '../../core/data-models/custom-report-template/custom-report-template';
import CustomReportTemplateEvent from '../../core/data-models/custom-report-template/custom-report-template-event';
import Placeholder from '../../core/data-models/custom-report-template/placeholder';
import PlaceholderEvent from '../../core/data-models/custom-report-template/placeholder-event';
import { ReportPlaceholderEventType } from '../../core/enums/report-placeholder-event-type.enum';
import { CustomReportTemplateHttpService } from '../../core/services/http/custom-report-template-http/custom-report-template-http.service';
import { ActionProgressDialogComponent } from '../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { ReportTemplatePreviewDialogComponent } from './report-template-preview-dialog/report-template-preview-dialog.component';

@Component({
    selector: 'report-template',
    styleUrls: ['./report-template.component.scss'],
    templateUrl: './report-template.component.html'
})
export class ReportTemplateComponent implements OnInit {
    public templates: CustomReportTemplate[] = [];
    public selectedTemplate: CustomReportTemplate;
    public _isLoading = true;
    public placeholderChanges: Subject<PlaceholderEvent> = new Subject<PlaceholderEvent>();
    public initialTemplate: string;
    private _isSaveDisabled = false;
    private _isNewTemplate = true;

    get isSaveDisabled(): boolean {
        return this._isSaveDisabled;
    }

    get isDeleteDisabled(): boolean {
        return this._isNewTemplate || this.selectedTemplate.isBuiltIn;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    public constructor(private _customTemplateService: CustomReportTemplateHttpService,
        private _dialogService: MatDialog,
        private _translate: TranslateService) { }

    public async ngOnInit(): Promise<void> {
        await this.loadTemplates();
    }

    public async deleteTemplate(): Promise<void> {
        this._isSaveDisabled = true;
        const action = this._customTemplateService.deleteCustomReportTemplate(this.selectedTemplate.code);
        const ongoing = this._translate.instant('templateEditor.messages.deleteOngoing');
        const success = this._translate.instant('templateEditor.messages.deleteSuccess');
        const error = this._translate.instant('templateEditor.messages.deleteError');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });
        if (await dialog.afterClosed().toPromise()) {
            await this.loadTemplates();
        }

        this._isSaveDisabled = false;
    }

    public async saveTemplate(): Promise<void> {
        let action: Promise<boolean | CodeDescription>;
        let error = this._translate.instant('templateEditor.messages.saveError');
        const ongoing = this._translate.instant('templateEditor.messages.saveOngoing');
        const success = this._translate.instant('templateEditor.messages.saveSuccess');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const validationResult = this.validateTemplate();

        if (validationResult) {
            action = of(false).toPromise();
            error = validationResult;
        }
        else {
            action = this._isNewTemplate?
                this._customTemplateService.addCustomReportTemplate(this.selectedTemplate) :
                this._customTemplateService.updateCustomReportTemplate(this.selectedTemplate);
        }

        this._isSaveDisabled = true;
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise() && this._isNewTemplate) {
            this.initialTemplate = this.selectedTemplate.name.trim();
            await this.loadTemplates();
        }

        this._isSaveDisabled = false;
    }

    public setSelectedTemplate(event: CustomReportTemplateEvent): void {
        this.selectedTemplate = event.template;
        this._isNewTemplate = event.action === 'new';
    }

    public previewTemplate(): void {
        this._dialogService.open(ReportTemplatePreviewDialogComponent, {
            data: this.selectedTemplate,
            width: '65vw',
            height: '85vh'
        });
    }

    public onPlaceholderChange(event: PlaceholderEvent): void {
        if (event.event === ReportPlaceholderEventType.Add) {
            this.addNewPlaceholderToTemplate(event.placeholder);
        }
        else if (event.event === ReportPlaceholderEventType.Delete) {
            this.deletePlaceholderFromTemplate(event.placeholder);
        }
        else if (event.event === ReportPlaceholderEventType.Update) {
            this.updatePlaceholderInTemplate(event.placeholder);
        }

        this.placeholderChanges.next(event);
    }

    private async loadTemplates(): Promise<void> {
        this._isLoading = true;
        this.templates = await this._customTemplateService.getCustomReportTemplates();
        this._isLoading = false;
    }

    private validateTemplate(): string {
        if (!this.selectedTemplate) {
            return this._translate.instant('templateEditor.messages.noTemplateAvailable');
        }

        if (this._isNewTemplate) {
            if (!this.selectedTemplate.name?.trim()) {
                return this._translate.instant('templateEditor.messages.missingTemplateName');
            }

            if (this.templates.some(_ => _.name.toLowerCase().trim() === this.selectedTemplate.name.toLowerCase().trim())) {
                return this._translate.instant('templateEditor.messages.duplicateTemplateName');
            }
        }

        return null;
    }

    private addNewPlaceholderToTemplate(placeholder: Placeholder): void {
        const currentPlaceholders = this.selectedTemplate.placeholders;
        if (currentPlaceholders && !currentPlaceholders.some(_ => _.identifier === placeholder.identifier)) {
            currentPlaceholders.push(placeholder);
        }
    }

    private deletePlaceholderFromTemplate(placeholder: Placeholder): void {
        this.selectedTemplate.placeholders = this.selectedTemplate.placeholders.filter(_ => _.identifier !== placeholder.identifier);
    }

    private updatePlaceholderInTemplate(placeholder: Placeholder): void {
        const oldPlaceholder = this.selectedTemplate.placeholders?.find(_ => _.identifier === placeholder.identifier);
        if (oldPlaceholder) {
            oldPlaceholder.name = placeholder.name;
        }
    }
}
