<form class="service-rates-questions-options-container" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh">
    <div class="question-entry" *ngFor="let control of questions.controls; let i = index" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2.5vh">
        <mat-form-field fxFlex="100">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.questions.text' | translate }}</mat-label>
            <input type="text" matInput [formControl]="control.get('name')" [readonly]="isReadonly" />

            <mat-error *ngIf="control.get('name').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('name').errors?.emptyName">
                * {{ 'flynnBudgetTool.ratesEditor.errors.emptyText' | translate }}
            </mat-error>

        </mat-form-field>

        <button [disabled]="isReadonly" mat-icon-button color="warn" (click)="questions.removeAt(i)" type="button" (keydown.enter)="$event.preventDefault()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <button class="add-button" mat-stroked-button color="primary" [disabled]="isReadonly" (click)="addQuestionRow()" type="button" (keydown.enter)="$event.preventDefault()">
        {{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.questions.addQuestion' | translate }}
    </button>
</form>
