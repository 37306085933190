<div class="weather-map" *ngIf="showWeatherChart">
    <schedule-board-chart [branchInfo]="branchInfo"></schedule-board-chart>
</div>
<div class="schedule-board-actions" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5em">
    <div class="filters">
        <summary-list-filter
        [showBorderLine]="false"
        [filterConfigs]="filterConfiguration"
        (onKeyWordFilterSearch)="filterSearch($event)">
        </summary-list-filter>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1em">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5em" class="rainy-day-toggle">
        <span [class.active-toggle-text]="!isRainyDay">{{translate.instant('weatherConditions.normal')}}</span>
        <kendo-switch size="large" onLabel=" " offLabel=" " [(ngModel)]="isRainyDay"></kendo-switch>
        <span [class.active-toggle-text]="isRainyDay">{{translate.instant('weatherConditions.weather')}}</span>
    </div>

    <!-- <div #menuTabs>
        <mat-button-toggle-group>
            <mat-button-toggle>
                <mat-icon>place</mat-icon>
                Map
            </mat-button-toggle>
            <mat-button-toggle>
                <mat-icon>menu</mat-icon>
                List
            </mat-button-toggle>
            <mat-button-toggle>
                <mat-icon>view_comfortable</mat-icon>
                Cards
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div> -->
    </div>
</div>
<div #scheduleBoardContainer [ngClass]="showWeatherChart ? 'schedule-board-container' : 'schedule-board-container schedule-board-container-extended-height'">
    <div class="schedule-board-bottom-wrapper" fxLayout="row" fxFill>
    <div [ngClass]="{ 'schedule-board-summary-list-collapsed': !summaryListExpanded }" class="schedule-board-project-list">
        <div *ngIf="summaryListExpanded" class="schedule-board-project-header" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title">{{ translate.instant("scheduleBoard.unassignedProjects") }}</span>
            <schedule-board-filter (filterValues)="onFilter($event)" [isBranchFilterable]="isUserAllowedToFilterBranch" [defaultBranch]="userBranch" [isRainyDay]="isRainyDay"></schedule-board-filter>
        </div>
        <summary-list
            [ngStyle]="summaryListStyle"
            [isLoading]="isSummaryListLoading"
            [queryResult]="queryResult"
            [isSimplified]="true"
            [(isFocusMode)]="hasTargetSummary"
            [isRainyDay]="isRainyDay"
            (isFocusModeChange)="loadSummaries(query.currentPage)"
            (listRefresh)="loadSummaries(query.currentPage)"
            (pageChanged)="loadSummaries($event)"
            (toggleExpanded)="onToggleExpand($event)"
            (summarySelected)="onSummarySelected($event)"
        >
        </summary-list>
    </div>
        <div [ngClass]="{ 'schedule-board-content-area-full': !summaryListExpanded }" class="schedule-board-content-area">
                <div class="schedule-board-no-record"
                    *ngIf="!branchInfo"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    fxLayoutGap="6px"
                    fxFill>
                    <span>{{ 'shared.summaryList.noRecordsAvailable' | translate }}</span>
                    <span>{{ 'shared.summaryList.tryDifferentFilters' | translate }}</span>
                </div>
                <ng-container
                    *ngIf="isRainyDay; then queueView; else calendarView">
                </ng-container>
                <ng-template #queueView><queue-view [branch]="branchInfo"></queue-view></ng-template>
                <ng-template #calendarView><calendar-view [branch]="branchInfo" (projectScheduled)="onProjectScheduled($event)"></calendar-view></ng-template>
        </div>
    </div>
</div>
