import { Component, Input, OnInit } from '@angular/core';
import { InvoiceUploadDialogComponent } from './invoice-upload-dialog/invoice-upload-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import { InvoiceHttpService } from '../../../core/services/http/invoice-http/invoice-http.service';
import { UserPermissionService } from '../../../core/services/user-permission/user-permission.service';
import InvoiceData from '../../../core/data-models/invoice-data';
import ProcessInvoiceData from '../../../core/data-models/process-invoice-data';
import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
// eslint-disable-next-line max-len
import { ActionProgressDialogComponent } from '../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { UserPermissionType } from '../../../core/enums/user-permission-type.enum';
import { WritePermission } from '../../../core/enums/write-permission.enum';
import { ReadPermission } from '../../../core/enums/read-permission.enum';
import ProjectAction from '../../../core/data-models/project-options/project-action';

@Component({
    selector: 'project-invoice',
    styleUrls: ['./project-invoice.component.scss'],
    templateUrl: './project-invoice.component.html'
})
export class ProjectInvoiceComponent implements OnInit {
    @Input() activeProjectId = '';
    public isLoading = true;
    public invoiceUploadAction = new ProjectAction();
    public invoiceUrl: string;
    public hasAnUploadedInvoice: boolean;
    public isProcessedWithInvoice = false;

    constructor(
        private _invoiceHttp: InvoiceHttpService,
        private _permissionService: UserPermissionService,
        private _projectHttp: ProjectHttpService,
        private _dialog: MatDialog,
        private _translate: TranslateService) { }

    public async ngOnInit(): Promise<void> {
        const { invoiceUpload, invoiceViewer: { isAllowed: hasInvoiceViewer } } = await this._projectHttp.getProjectActionOptions(this.activeProjectId);
        const [type, actionCode] = [UserPermissionType.Write, WritePermission.UploadInvoice];
        this.invoiceUploadAction = { ...invoiceUpload };
        this.invoiceUploadAction.isAllowed = this.invoiceUploadAction.isAllowed && await this._permissionService.isActionPermitted(type, actionCode);

        if (hasInvoiceViewer && await this._permissionService.hasReadPermission(ReadPermission.ReadInvoice)) {
            this.isProcessedWithInvoice = await this._projectHttp.isProcessedWithInvoice(this.activeProjectId);
            await this.loadInvoice();
        }

        this.isLoading = false;
    }

    public async launchUploadInvoiceDialog(): Promise<void> {
        const dialog = this._dialog.open(InvoiceUploadDialogComponent, {
            data: this.activeProjectId,
            width: '600px',
            height: '600px'
        });

        const invoiceData = await dialog.afterClosed().toPromise<ProcessInvoiceData>();

        if (!invoiceData) {
            return;
        }

        const { invoiceNumber, draftNumber, grossAmount, invoiceFile } = invoiceData;
        const invoice = { invoiceNumber, draftNumber, grossAmount, invoiceFile } as InvoiceData;

        const action = this._invoiceHttp.uploadInvoice(this.activeProjectId, invoice);

        const ongoing = this._translate.instant('projects.invoice.waitUpload');
        const success = this._translate.instant('projects.invoice.successfulUpload');
        const error = this._translate.instant('projects.invoice.failedUpload');
        const ok = this._translate.instant('shared.ok');
        const gotIt = this._translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const progressDialog = this._dialog.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await progressDialog.afterClosed().toPromise()) {
            await this.loadInvoice();
        }
    }

    private async loadInvoice(): Promise<void> {
        this.isLoading = true;
        this.invoiceUrl = await this._invoiceHttp.getSignedProjectInvoiceUrl(this.activeProjectId);
        this.hasAnUploadedInvoice = Boolean(this.invoiceUrl);
        this.isLoading = false;
    }
}
