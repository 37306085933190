export enum ProjectAction {
    ConvertProject = 'projects.actions.convertProject',
    CreateFollowUpQuote = 'projects.actions.createFollowUpQuote',
    SwapProjectTo312 = 'projects.actions.swapProjectTo312',
    SwapProjectTo315 = 'projects.actions.swapProjectTo315',
    UploadBudget = 'projects.actions.uploadBudget',
    UnmergeProject = 'projects.actions.unmergeProject',
    UpdateProject = 'projects.actions.updateProject',
    MergeProject = 'projects.actions.mergeProject',
    DeleteProject = 'projects.actions.deleteProject',
    ChangeServiceProvider = 'projects.actions.changeServiceProvider'
}
