<div class="subscription-editor-container" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="7.5">
        <mat-radio-group [(ngModel)]="filterValue">
            <mat-radio-button *ngFor="let filter of filters" [value]="filter.type">
                {{ filter.name }}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="subscription-editor-content" fxFlex="92.5">
        <div class="subscription-list-wrapper" [class.mat-elevation-z8]="isCardMode">
            <table mat-table multiTemplateDataRows [dataSource]="visibleTemplates">
                <ng-container matColumnDef="name">
                    <th class="subscription-name" mat-header-cell *matHeaderCellDef>
                        <span>{{ 'admin.rolesAndPermissions.subscriptionEditor.subscriptionHeaderText' | translate }}</span>
                        <span *ngIf="visibleTemplates.length">&nbsp;({{ visibleTemplates.length }})</span>
                    </th>

                    <td class="subscription-name" mat-cell *matCellDef="let template">
                        {{ template.topic | splitCamelCase }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th class="subscription-description" mat-header-cell *matHeaderCellDef>
                        <span>{{ 'admin.rolesAndPermissions.subscriptionEditor.descriptionHeaderText' | translate }}</span>
                    </th>

                    <td class="subscription-description" mat-cell *matCellDef="let template">{{ template.description }}</td>
                </ng-container>

                <ng-container matColumnDef="isGlobal">
                    <th class="subscription-attribute" mat-header-cell *matHeaderCellDef>
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
                            <span>{{ 'admin.rolesAndPermissions.subscriptionEditor.attributeHeaderText' | translate }}</span>

                            <mat-icon fxFlexAlign="start" [matTooltip]="translate.instant('admin.rolesAndPermissions.subscriptionEditor.attributeHint')">
                                help_outline
                            </mat-icon>
                        </div>
                    </th>

                    <td class="subscription-attribute" mat-cell *matCellDef="let template">
                        <mat-checkbox [(ngModel)]="template.ignoreAttributes"
                            [disabled]="!template.isAllowed || isDisabled"
                            (ngModelChange)="onGlobalAttributeToggle(template)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th class="subscription-action" mat-header-cell *matHeaderCellDef>
                        <mat-slide-toggle *ngIf="filterValue === -1"
                            color="primary"
                            [checked]="isMasterToggleOn"
                            [disabled]="isDisabled"
                            (change)="onAllSubscriptionToggle($event.checked)"
                            (click)="$event.stopPropagation()">
                        </mat-slide-toggle>
                    </th>

                    <td class="subscription-action" mat-cell *matCellDef="let template">
                        <mat-slide-toggle color="primary"
                            [disabled]="isDisabled"
                            [(ngModel)]="template.isAllowed"
                            (ngModelChange)="onSubscriptionToggle(template)"
                            (click)="$event.stopPropagation()">
                        </mat-slide-toggle>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>

                <tr [ngClass]="{ 'disabled-row': !row.isAllowed || isDisabled }"
                    mat-row
                    *matRowDef="let row; columns: visibleColumns;">
                </tr>
            </table>
        </div>
        <!-- cover the scrollbar on table header row -->
        <div class="scrollbar-cover"></div>
    </div>
</div>
