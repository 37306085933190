<form class="service-rates-materials-options-container" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.25vh">
    <div class="material-entry" *ngFor="let control of materialRates.controls; let i = index" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2.5vh">
        <mat-form-field fxFlex="62.5">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.materials.type' | translate }}</mat-label>
            <input type="text" matInput [formControl]="control.get('type.name')" />

            <mat-error *ngIf="control.get('type.name').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('type.name').errors?.emptyName">
                * {{ 'flynnBudgetTool.ratesEditor.errors.emptyText' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('type.name').errors?.duplicateNameAndUnits">
                * {{ 'flynnBudgetTool.ratesEditor.errors.duplicateMaterial' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="17.5">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.materials.unitPrice' | translate }}</mat-label>
            <input type="number" matInput [formControl]="control.get('unitPrice')" />

            <mat-error *ngIf="control.get('unitPrice').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('unitPrice').errors?.invalidFormat">
                * {{ 'flynnBudgetTool.ratesEditor.errors.invalidCurrencyFormat' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('unitPrice').errors?.negative || control.get('unitPrice').errors?.min">
                * {{ 'flynnBudgetTool.ratesEditor.errors.negativeValue' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="20">
            <mat-label>{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.materials.unitOfMeasurement' | translate }}</mat-label>
            <input type="text" matInput [formControl]="control.get('unitOfMeasurement')" />

            <mat-error *ngIf="control.get('unitOfMeasurement').errors?.required">
                * {{ 'flynnBudgetTool.ratesEditor.errors.requiredField' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('unitOfMeasurement').errors?.pattern">
                * {{ 'flynnBudgetTool.ratesEditor.errors.emptyText' | translate }}
            </mat-error>

            <mat-error *ngIf="control.get('unitOfMeasurement').errors?.duplicateNameAndUnits">
                * {{ 'flynnBudgetTool.ratesEditor.errors.duplicateMaterial' | translate }}
            </mat-error>
        </mat-form-field>

        <button [disabled]="isReadonly" mat-icon-button color="warn" (click)="materialRates.removeAt(i)" type="button" (keydown.enter)="$event.preventDefault()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <button class="add-button" mat-stroked-button color="primary" [disabled]="isReadonly" (click)="addMaterialRateRow()" type="button" (keydown.enter)="$event.preventDefault()">
        {{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.materials.addMaterial' | translate }}
    </button>
</form>
