export default class LaborEdit {
    public hours: number;
    public zone: boolean;
    public mileage: number;
    public roomAndBoard: boolean;
    public initialAllowance: boolean;
    public returnAllowance: boolean;
    public meal: boolean;
    public reason: string;
}
