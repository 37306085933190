<div class="branch-service-rates-display-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2.5vh">
    <div class="rate-record" *ngFor="let rate of data.selectedRates.branchRates" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3vh">
        <div fxFlex="10" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5vh">
            <div class="branch">{{getBranchName(rate.branchCode)}}</div>
            <div class="branchCode">{{ rate.branchCode }}</div>
        </div>

        <div class="data-collection" fxLayout="column" fxLayoutAlign="start center" fxFlex="90">
            <div class="header" fxLayout="row" fxLayoutAlign="center center">
                <div [fxFlex]="getColumnWidth(rate)">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.laborHourlyRate' | translate }}</div>
                <div [fxFlex]="getColumnWidth(rate)">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.insurancePercentageRate' | translate }}</div>
                <div [fxFlex]="getColumnWidth(rate)">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.indirectsPercentageRate' | translate }}</div>

                <div *ngFor="let grossProfitPercentageRate of rate.grossProfitPercentageRates" [fxFlex]="getColumnWidth(rate)">
                    {{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.grossProfitPercentageRate' | translate: { projectType: getProjectType(grossProfitPercentageRate.typeIdentifier) } }}
                </div>

                <div [fxFlex]="getColumnWidth(rate)">{{ 'flynnBudgetTool.ratesEditor.serviceRatesEditor.tabs.branches.subcontractGrossProfitPercentageRate' | translate }}</div>
            </div>

            <div class="row" fxLayout="row" fxLayoutAlign="center center">
                <div [fxFlex]="getColumnWidth(rate)">${{ rate.laborHourlyRate }}</div>
                <div [fxFlex]="getColumnWidth(rate)">{{ rate.insurancePercentageRate | percent: percentPipeConfig }}</div>
                <div [fxFlex]="getColumnWidth(rate)">{{ rate.indirectsPercentageRate | percent: percentPipeConfig }}</div>
                <div *ngFor="let grossProfitPercentageRate of rate.grossProfitPercentageRates" [fxFlex]="getColumnWidth(rate)">{{ grossProfitPercentageRate.rate | percent: percentPipeConfig }}</div>
                <div [fxFlex]="getColumnWidth(rate)">{{ rate.subcontractGrossProfitPercentageRate | percent: percentPipeConfig }}</div>
            </div>
        </div>
    </div>
</div>
