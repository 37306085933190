import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, takeWhile } from 'rxjs/operators';
import { BranchServiceRate } from '../../../../../flynn-budget-tool/core/data-models/rates/service-rates/branch-service-rate';
import { ValidatorService } from '../../../../../app/core/services/validator-service/validator-service';

@Component({
    selector: 'app-summary-section',
    styleUrls: ['./summary-section.component.scss'],
    templateUrl: './summary-section.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummarySectionComponent implements OnInit, OnDestroy {
    private readonly _translationRoot = 'flynnBudgetTool.budgetEstimation.serviceOperationsStandardizedEstimation.steps.summary';
    @Input() public branchServiceRate!: BranchServiceRate;
    @Input() public form!: UntypedFormGroup;
    @Input() public calculationUpdated$: Subject<void>;
    @Input() public readonly = false;
    public grossProfitOverrideFormControl = new UntypedFormControl();
    private _isComponentActive = true;

    get summarySection(): UntypedFormControl {
        return this.form.get('summarySection') as UntypedFormControl;
    }

    get customLineItemExpenses(): UntypedFormArray {
        return this.form.get('summarySection.customLineItemExpenses') as UntypedFormArray;
    }

    get qualityControlFeesSection(): UntypedFormControl {
        return this.form.get('qualityControlFeesSection') as UntypedFormControl;
    }

    get projectType(): UntypedFormControl {
        return this.form.get('projectType') as UntypedFormControl;
    }

    get isSubcontract(): boolean {
        return this.form.get('isSubcontract').value as boolean;
    }

    get grossProfitPercentage(): string {
        return this.form.get('summarySection.grossProfitPercentageOverride').value as string;
    }

    get grossProfitPercentageParentFormControl(): UntypedFormControl {
        return this.form.get('summarySection.grossProfitPercentageOverride') as UntypedFormControl;
    }

    get grossProfitDecimalToPercentage(): string {
        const grossProfit = this.grossProfitPercentageParentFormControl.value;
        return (grossProfit * 100).toFixed(2);
    }

    get grossProfitPercentageSetByUser(): UntypedFormControl {
        return this.summarySection.get('grossProfitPercentageSetByUser') as UntypedFormControl;
    }

    get qualityControlPercentageRate(): number {
        const hasPercentageCost = this.qualityControlFeesSection.get('hasPercentageCost').value;
        if (hasPercentageCost) {
            return this.qualityControlFeesSection.get('percentageCost.rate').value;
        }
        return 0.00;
    }

    get indirectPercentage(): number {

        if (this.isSubcontract) {
            return 0.00;
        }

        return this.branchServiceRate.indirectsPercentageRate;
    }

    get priceOverrideSetByUser(): UntypedFormControl {
        return this.summarySection.get('priceOverrideSetByUser') as UntypedFormControl;
    }

    constructor(private _translate: TranslateService,
                private _changeDetectorRef: ChangeDetectorRef,
                private _validatorService: ValidatorService) {
    }

    public ngOnInit(): void {

        this.grossProfitOverrideFormControl = new UntypedFormControl(this.grossProfitDecimalToPercentage, [
            this._validatorService.decimalValidator(2, 0),
            Validators.required,
            Validators.min(0),
            Validators.max(100)
        ]);

        this.calculationUpdated$.pipe(
            takeWhile(() => this._isComponentActive)
        ).subscribe(() => {
            this.grossProfitOverrideFormControl.setValue(this.grossProfitDecimalToPercentage, { emitEvent: false, onlySelf: true });
            this._changeDetectorRef.markForCheck();
        });

        this.summarySection.get('priceOverride').valueChanges.subscribe(() => {
            this.priceOverrideSetByUser.setValue(true);
        });

        this.grossProfitOverrideFormControl.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
            this.grossProfitPercentageSetByUser.setValue(true);
            const decimal = Number(value) / 100;
            this.grossProfitPercentageParentFormControl.setValue(decimal.toFixed(4));
        });
    }

    public ngOnDestroy(): void {
        this._isComponentActive = false;
    }

    public localize(token: string): string {
        return this._translate.instant(`${this._translationRoot}.${token}`);
    }
}
