import { ServiceRatesMetadata } from '../rates/service-rates/service-rates-metadata';
import { TimeInfo } from '../generic/time-info';

export class ServiceRatesGroupSummaryDto {
    public name: string;
    public groupId: string;
    public isDefault = false;
    public customerIds: string[] = [];
    public metadata: ServiceRatesMetadata[] = [];
    public timeInfo = new TimeInfo();
}
