<div class="summary-list-filter-container" [class.show-border]="showBorderLine" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <div class="summary-list-text-input" fxFlex="18.5" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field fxFlex>
            <input matInput placeholder="{{ 'shared.summaryList.filter.search' | translate }}" #listKeyWordSearchFilter />
        </mat-form-field>

        <div *ngIf="!hasSearchText" fxLayoutAlign="center center">
            <mat-icon>search</mat-icon>
        </div>

        <div class="summary-list-text-input-clear" *ngIf="hasSearchText" (click)="clearSearch()" fxLayoutAlign="center center">
            <mat-icon>clear</mat-icon>
        </div>
    </div>

    <div fxLayout fxLayoutAlign="space-between center" class="summary-list-filter-filter-wrapper" fxLayoutGap="10px" [fxFlex]="filterFlexValue" *ngFor="let filter of filterConfigs; let i = index">
        <filter-popup-panel fxFill
            [filterConfig]="filter"
            [chipItemsColorClass]="chipItemColors[i]"
            (onFilterItemChecked)="onFilterDropDownChecked.emit($event)">
        </filter-popup-panel>
    </div>

    <div class="summary-filter-checkbox-container" *ngIf="filterCheckBox">
        <mat-checkbox [(ngModel)]="filterCheckBox.checked" (change)="onCheckBoxStatusToggled.emit(filterCheckBox)">{{filterCheckBox.displayText}}</mat-checkbox>
    </div>
</div>
