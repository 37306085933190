import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import MaintenanceSummary from '../../../data-models/maintenance-summary';
import SiteRoofDetail from '../../../data-models/site-roof-detail';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RoofDetailsHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/roof`;

    constructor(private _http: HttpClient) { }

    public async getSiteRoofDetailsByCustomerAndSiteId(customerId: string, siteId: string): Promise<SiteRoofDetail> {
        try {
            const url = `${this._api}/site/details/${customerId}/${siteId}`;

            const siteRoofDetail = await this._http.get<SiteRoofDetail>(url).toPromise();
            return JsonUtility.toCamelCaseKeys(siteRoofDetail);
        }
        catch {
            return null;
        }
    }

    public async getSiteRoofDetailsByProjectId(projectId: string): Promise<SiteRoofDetail> {
        try {
            const url = `${this._api}/site/details/${projectId}`;

            const siteRoofDetail = await this._http.get<SiteRoofDetail>(url).toPromise();
            return JsonUtility.toCamelCaseKeys(siteRoofDetail);
        }
        catch {
            return null;
        }
    }

    public async createMaintenancePlanByProjectId(projectId: string, maintenanceSummary: MaintenanceSummary): Promise<boolean> {
        try {
            const url = `${this._api}/upload/maintenance-plan/${projectId}`;
            const success = await this._http.post<boolean>(url, maintenanceSummary).toPromise();
            return success;
        }
        catch {
            return false;
        }
    }

    public async createMaintenancePlanByCustomerAndSiteId(customerId: string, siteId: string, maintenanceSummary: MaintenanceSummary): Promise<boolean> {
        try {
            const url = `${this._api}/upload/maintenance-plan/${customerId}/${siteId}`;
            const success = await this._http.post<boolean>(url, maintenanceSummary).toPromise();
            return success;
        }
        catch {
            return false;
        }
    }
}
