<form class="summary-section-container" [formGroup]="form" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2.5vh">
    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5"  fxLayoutAlign="end center" fxLayoutGap="0.5vh" >{{ localize('fields.totalCost') }}</span>
        <div fxLayout="column"  fxLayoutAlign="center center" fxLayoutGap="0.5vh" fxFlex="52.5">
            <mat-form-field>
                <mat-label>{{ localize('fields.dollarAmount')}}</mat-label>
                <input type="text" readonly matInput [value]="summarySection.get('totalCostWithoutQualityControlPercentageCost')?.value | number: '0.0-0'" />
            </mat-form-field>
        </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5"  fxLayoutAlign="end center" fxLayoutGap="0.5vh" >{{ localize('fields.qualityControl' )}}</span>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5vh" fxFlex="52.5">
            <mat-form-field>
                <mat-label>{{ localize('fields.dollarAmount')}}</mat-label>
                <input type="text" readonly matInput [value]="summarySection.get('totalQualityControlPercentageCost')?.value | number: '0.0-0'" />
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5vh" fxFlex="30">
            <mat-form-field class="percentage-input">
                <mat-label>{{ localize('fields.percentage')}}</mat-label>
                <input type="text" readonly matInput value="{{ qualityControlPercentageRate | convertToPercentage}}" />
            </mat-form-field>
        </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5"  fxLayoutAlign="end center" fxLayoutGap="0.5vh" >{{ localize('fields.insurance') }}</span>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5vh" fxFlex="52.5">
            <mat-form-field>
                <mat-label>{{ localize('fields.dollarAmount')}}</mat-label>
                <input type="text" readonly matInput [value]="summarySection.get('totalInsuranceCost')?.value | number: '0.0-0'"  />
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5vh" fxFlex="30">
            <mat-form-field class="percentage-input">
                <mat-label>{{ localize('fields.percentage')}}</mat-label>
                <input type="text" readonly matInput value="{{ branchServiceRate.insurancePercentageRate | convertToPercentage }}" />
            </mat-form-field>
        </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5"  fxLayoutAlign="end center" fxLayoutGap="0.5vh" >{{ localize('fields.indirects') }}</span>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5vh" fxFlex="52.5">
            <mat-form-field>
                <mat-label>{{ localize('fields.dollarAmount')}}</mat-label>
                <input type="text" readonly matInput [value]="summarySection.get('totalIndirectCost')?.value | number: '0.0-0'" />
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5vh" fxFlex="30">
            <mat-form-field class="percentage-input">
                <mat-label>{{ localize('fields.percentage')}}</mat-label>
                <input type="text" readonly matInput value="{{ indirectPercentage | convertToPercentage }}" />
            </mat-form-field>
        </div>
    </div>

    <div class="section" *ngFor="let expense of customLineItemExpenses.controls" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5" fxLayoutAlign="end center" fxLayoutGap="0.5vh">{{ expense.get('identifier.name').value }}</span>

        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5vh" fxFlex="52.5">
            <mat-form-field>
                <mat-label>{{ localize('fields.dollarAmount') }}</mat-label>
                <input type="text" readonly matInput [value]="expense.get('totalCost')?.value | number: '0.0-0'" />
            </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5vh" fxFlex="30">
            <mat-form-field class="percentage-input">
                <mat-label>{{ localize('fields.percentage') }}</mat-label>
                <input type="text" readonly matInput [value]="expense.get('percentageRate').value | convertToPercentage" />
            </mat-form-field>
        </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5"  fxLayoutAlign="end center" fxLayoutGap="0.5vh" >{{ localize('fields.grossProfit') }}</span>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5vh" fxFlex="52.5">
            <mat-form-field>
                <mat-label>{{ localize('fields.dollarAmount')}}</mat-label>
                <input type="text" readonly matInput [value]="summarySection.get('totalGrossProfit')?.value | number: '0.0-0'" />
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5vh" fxFlex="30">
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0.5vh" fxFlex>
            <mat-form-field class="percentage-input">
                <mat-label>{{ localize('fields.percentage')}}</mat-label>
                <mat-icon *ngIf="grossProfitPercentageSetByUser.value" [title]="localize('overwritten')" color="warn" matSuffix class="overwritten">help</mat-icon>
                <input type="number" [readonly]="isSubcontract || readonly" [formControl]="grossProfitOverrideFormControl" matInput />
                <mat-error *ngIf="grossProfitOverrideFormControl.invalid">{{ localize('fields.fieldError') }}</mat-error>
            </mat-form-field>
            </div>
            <div fxLayoutAlign="start center" fxFlex="5" fxLayoutGap="0.5vh">
                <button mat-raised-button color="warn" [disabled]="!grossProfitPercentageSetByUser.value || isSubcontract || readonly" (click)="grossProfitPercentageSetByUser.setValue(false)">{{ localize('resetButton') }}</button>
            </div>
        </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5"  fxLayoutAlign="end center" fxLayoutGap="0.5vh" >{{ localize('fields.salePrice') }}</span>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0.5vh" fxFlex="52.5">
            <mat-form-field>
                <mat-label>{{ localize('fields.dollarAmount')}}</mat-label>
                <input type="text" readonly matInput [value]="summarySection.get('totalSalePrice')?.value | number: '0.0-0'"/>
            </mat-form-field>
        </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutGap="10vh">
        <span class="section-title" fxFlex="27.5"  fxLayoutAlign="end center" fxLayoutGap="0.5vh" >{{ localize('fields.priceOverride') }}</span>
        <div fxLayoutAlign="center center" fxLayoutGap="0.5vh" fxFlex="52.5">
            <mat-form-field>
                <mat-label>{{ localize('fields.dollarAmount')}}</mat-label>
                <mat-icon *ngIf="priceOverrideSetByUser.value" [title]="localize('overwritten')" color="warn" matSuffix class="overwritten">help</mat-icon>
                <input type="number" matInput [formControl]="summarySection.get('priceOverride')" [readonly]="readonly"/>
                <mat-error *ngIf="summarySection.get('priceOverride').invalid">{{ localize('fields.fieldError') }}</mat-error>
            </mat-form-field>
        </div>
        <div fxLayoutAlign="start center"  fxFlex="27.5" fxLayoutGap="0.5vh">
            <button mat-raised-button color="warn" [disabled]="!priceOverrideSetByUser.value || readonly" (click)="priceOverrideSetByUser.setValue(false)">{{ localize('clearOverride') }}</button>
        </div>
    </div>
</form>
