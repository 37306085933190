<div class="dialog-action-card-container" fxLayout="column" fxLayoutAlign="start center">
    <div class="dialog-action-card-title-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div fxLayoutAlign="center center">
            <mat-icon>{{ icon }}</mat-icon>
        </div>

        <span>{{ title }}</span>
        <span *ngIf="subTitle" class="dialog-action-card-subtitle">{{ subTitle }}</span>
        <div fxFlex></div>

        <button class="dialog-action-card-close" mat-mini-fab (click)="close.emit()" *ngIf="!isModalDialog">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

    <div class="dialog-action-card-content">
        <div class="dialog-action-card-spinner" *ngIf="!isLoaded" fxLayoutAlign="center center">
            <dots-loader></dots-loader>
        </div>

        <ng-content *ngIf="isLoaded"></ng-content>
    </div>
</div>
