/* eslint-disable max-params */
import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProjectScheduleDialogOptions, ValidateDateFields } from '../../../core/data-models/schedule-board/project-schedule-dialog-options';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidatorFactory } from '../../../core/services/validator-factory/validator-factory.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Crew from '../../../core/data-models/crew';
import { CountryName } from '../../../core/enums/country-name.enum';
import ProjectIdUtility from '../../../core/services/utility/project-id-utility/project-id-utility.service';
import HealthScreeningOption from '../../../core/data-models/health-screening-option';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ScheduleBoardHttpService } from '../../../core/services/http/schedule-board-http/schedule-board-http.service';
import { TranslateService } from '@ngx-translate/core';
import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import * as moment from 'moment-timezone';
import { EditScheduleDto } from '../../../core/data-models/schedule-board/edit-schedule';

@Component({
    selector: 'app-project-schedule-dialog',
    templateUrl: './project-schedule-dialog.component.html',
    styleUrls: ['./project-schedule-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProjectScheduleDialogComponent implements OnInit {
    public isLoaded = false;
    public projectScheduleFormGroup: UntypedFormGroup;
    public integerValidator = this._validatorFactory.createPositiveIntegerValidator();
    @Output() public selectedCrewChanged = new EventEmitter<Crew>();
    public country: CountryName;
    public healthScreeningOption: HealthScreeningOption;
    public actionData: Promise<boolean>;
    public projectScheduleOptions: ProjectScheduleDialogOptions;
    public popupSettings = {
        animate: true,
        popupClass: 'datetimepicker'
    };

    constructor(
      private _snackBar: MatSnackBar,
      private _dialogRef: MatDialogRef<ProjectScheduleDialogComponent>,
      private _formBuilder: UntypedFormBuilder,
      private _validatorFactory: ValidatorFactory,
      private _scheduleBoardHttpService: ScheduleBoardHttpService,
      public translate: TranslateService,
      private _dialogService: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: ProjectScheduleDialogOptions) {
        this.projectScheduleOptions = data;
    }

    public ngOnInit(): Promise<void> {
        this.isLoaded = false;

        if (!this.data) {
            const failedToLoad = this.translate.instant('scheduleProjectDialog.failedToLoad');
            const ok = this.translate.instant('scheduleProjectDialog.ok');
            this._snackBar.open(failedToLoad, ok);
            this.onClose();
            return;
        }

        this.loadFormGroup();
        const { projectId, customerId, siteId } = this.data;
        this.country = ProjectIdUtility.isUsProject(projectId) ? CountryName.US : CountryName.CA;

        if (customerId && siteId) {
            this.healthScreeningOption = new HealthScreeningOption(customerId, siteId);
        }

        this.isLoaded = true;
    }

    public onClose(): void {
        this._dialogRef.close(false);
    }

    private loadFormGroup(): void {
        if (this.data.isEditingSchedule) {
            this.projectScheduleFormGroup = this._formBuilder.group({
                projectId: [this.data.projectId, Validators.required],
                branch: [this.data.branch, Validators.required],
                jobId: [this.data.jobId, Validators.required],
                crew: [this.data.crew || null, Validators.required],
                note: [this.data.note || ''],
                startDate: [this.createFormDate(this.data.startDate), Validators.required],
                endDate: [this.createFormDate(this.data.endDate), Validators.required],
                scheduleDates: [[]]
            });
        }
        else {
            this.projectScheduleFormGroup = this._formBuilder.group({
                projectId: [this.data.projectId, Validators.required],
                branch: [this.data.branch, Validators.required],
                jobId: [this.data.jobId, Validators.required],
                crew: [this.data.crew || null, Validators.required],
                note: [this.data.note || ''],
                startDate: [null, ValidateDateFields],
                endDate: [null, ValidateDateFields],
                scheduleDates: [[]]
            });
        }

        this.projectScheduleFormGroup.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(projectSchedule => {
            this.data = projectSchedule as ProjectScheduleDialogOptions;
        });
    }

    public crewSelected(crew: Crew): void {
        this.projectScheduleFormGroup.get('crew').setValue(crew);
        this.selectedCrewChanged.emit(crew);
    }

    public onScheduleProject() {
        if (this.projectScheduleFormGroup.valid) {
            this.scheduleProject();
        }
        else {
            const helpText = this.translate.instant('scheduleProjectDialog.formFieldHelpText');
            const ok = this.translate.instant('snackbars.ok');
            this._snackBar.open(helpText, ok);
        }
    }

    public onAddScheduledTime() {
        this.projectScheduleFormGroup.controls.scheduleDates.setValue([{
            id: this.projectScheduleFormGroup.value.scheduleDates.length + 1,
            startDate: this.projectScheduleFormGroup.value.startDate,
            endDate: this.projectScheduleFormGroup.value.endDate
        }, ...this.projectScheduleFormGroup.value.scheduleDates]);

        this.projectScheduleFormGroup.controls.startDate.setValue(null);
        this.projectScheduleFormGroup.controls.endDate.setValue(null);
    }

    public onDeleteScheduledDate(id: number) {
        this.projectScheduleFormGroup.controls.scheduleDates.setValue(this.projectScheduleFormGroup.value.scheduleDates.filter(x => x.id !== id));
    }

    public formatDate(date: Date) {
        return moment(date).format('LLLL');
    }

    public async onDeleteSchedule() {
        if (this.projectScheduleOptions.isEditingSchedule) {
            const isScheduleDeleted = this._scheduleBoardHttpService.deleteSchedule(this.projectScheduleOptions.projectId, this.projectScheduleOptions.jobId, this.projectScheduleOptions.scheduleId);
            const ongoing = this.translate.instant('scheduleProjectDialog.ongoing');
            const success = this.translate.instant('scheduleProjectDialog.deleteSuccess');
            const error = this.translate.instant('scheduleProjectDialog.deleteError');
            const ok = this.translate.instant('shared.ok');
            const gotIt = this.translate.instant('shared.gotIt');
            const dialogData = new ActionProgressReporterOption(isScheduleDeleted, ongoing, success, error, ok, gotIt);
            const dialog = this._dialogService.open(ActionProgressDialogComponent, {
                data: dialogData,
                width: '550px',
                height: '120px'
            });

            if (await dialog.afterClosed().toPromise()) {
                this._dialogRef.close(true);
            }
        }
    }

    private async scheduleProject(): Promise<void> {
        if (this.projectScheduleOptions.isEditingSchedule) {
            const editData: EditScheduleDto = {
                projectId: this.projectScheduleOptions.projectId,
                crew: this.projectScheduleFormGroup.value.crew,
                endDate: this.getTimeInBranch(this.projectScheduleFormGroup.value.endDate),
                startDate: this.getTimeInBranch(this.projectScheduleFormGroup.value.startDate),
                branch: this.projectScheduleFormGroup.value.branch,
                jobId: this.projectScheduleOptions.jobId,
                note: this.projectScheduleFormGroup.value.note
            };
            this.actionData = this._scheduleBoardHttpService.editSchedule(this.projectScheduleOptions.scheduleId, editData);
        }
        else {
            const scheduleJobData = { ...this.projectScheduleFormGroup.value };
            if (scheduleJobData.startDate !== null && scheduleJobData.endDate !== null) {
                scheduleJobData.scheduleDates = [...scheduleJobData.scheduleDates, { startDate: scheduleJobData.startDate, endDate: scheduleJobData.endDate }];
            }
            scheduleJobData.scheduleDates = scheduleJobData.scheduleDates.map(x => {
                return {
                    startDate: this.getTimeInBranch(x.startDate),
                    endDate: this.getTimeInBranch(x.endDate)
                };
            });

            this.actionData = this._scheduleBoardHttpService.scheduleJob(scheduleJobData);
        }

        const ongoing = this.translate.instant('scheduleProjectDialog.ongoing');
        const success = this.translate.instant('scheduleProjectDialog.success');
        const error = this.translate.instant('scheduleProjectDialog.error');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(this.actionData, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise()) {
            this._dialogRef.close(true);
        }
    }

    public checkIfDateIsNotValid() {
        const inputStartDate = moment(this.projectScheduleFormGroup.value.startDate);
        const inputEndDate = moment(this.projectScheduleFormGroup.value.endDate);
        if (this.projectScheduleFormGroup.value.scheduleDates.length > 0) {
            return this.projectScheduleFormGroup.value.scheduleDates.some(x => {
                const startDate = moment(x.startDate);
                const endDate = moment(x.endDate);
                return inputStartDate.isBetween(startDate, endDate, 'days', '[]') || inputEndDate.isBetween(startDate, endDate, 'days', '[]');
            }) || inputStartDate.isAfter(inputEndDate) || inputStartDate.isBefore(this.projectScheduleFormGroup.value.scheduleDates[0].endDate);
        }

        return !inputEndDate.isAfter(inputStartDate);
    }

    public getTimeInBranch(date: Date) {
        const dateNoTz = this.dateWithoutTimezone(date);
        return moment.tz(dateNoTz, this.projectScheduleOptions.branchInfo.geoLocation.timeZone.timeZoneId).toISOString();
    }

    public dateWithoutTimezone = (date: Date) => {
        const tzoffset = date.getTimezoneOffset() * 60000;
        const withoutTimezone = new Date(date.valueOf() - tzoffset)
            .toISOString()
            .slice(0, -1);
        return withoutTimezone;
    };

    private createFormDate(date: string) {
        const transformedTime = moment.tz(date, this.projectScheduleOptions.branchInfo.geoLocation.timeZone.timeZoneId).format('YYYY-MM-DDTHH:mm:ss');
        const createdDate = new Date(transformedTime);
        return createdDate;
    }

}
