<ng-container *ngIf="showAbstractView">
    <ui-view></ui-view>
</ng-container>

<ng-container *ngIf="!showAbstractView">
    <ng-container *ngIf="isReady; else loading">
        <top-toolbar></top-toolbar>

        <div *ngIf="showSettingsView" class="settings-content">
            <ui-view></ui-view>
        </div>

        <div *ngIf="!showSettingsView" fxLayout="row">
            <side-menu></side-menu>
            <ui-view class="main-content"></ui-view>
        </div>
    </ng-container>

    <ng-template #loading>
        <div fxLayout="column" fxLayoutAlign="center center" fxFill>
            <dots-loader></dots-loader>
        </div>
    </ng-template>
</ng-container>
