import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TransitionService, Transition, StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';

import NavigationTab from '../../../core/data-models/navigation-tab';
import DispatchDialogOptions from '../../../core/data-models/dispatch/dispatch-dialog-options';
import QuoteEditorOption from '../../../core/data-models/quote-editor-option';
import { UserPermissionService } from '../../../core/services/user-permission/user-permission.service';
import { QuoteEditorComponent } from '../../../shared/components/quote-editor/quote-editor.component';
import { FastRequestsDialogComponent } from '../../dispatch/fast-requests-dialog/fast-requests-dialog.component';
import { DispatchProjectDialogComponent } from '../../dispatch/dispatch-project-dialog/dispatch-project-dialog.component';
import { BudgetTemplatesDialogComponent } from '../../../shared/components/dialogs/budget-templates-dialog/budget-templates-dialog.component';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { ReadPermission } from '../../../core/enums/read-permission.enum';
import { CreatePermission } from '../../../core/enums/create-permission.enum';
import { IntegrationRequestsDialogComponent } from '../../../shared/components/dialogs/integration-requests-dialog/integration-requests-dialog.component';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { environment } from '../../../../environments/environment';

import { TaskSelectionDialogComponent } from './task-selection-dialog/task-selection-dialog.component';
import { WorkCategory } from '../../../core/data-models/work-category';
// eslint-disable-next-line
declare const window: any;

@Component({
    selector: 'side-menu',
    styleUrls: ['./side-menu.component.scss'],
    templateUrl: './side-menu.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SideMenuComponent implements OnInit {
    public navigationTabs: NavigationTab[] = [];
    private _activeState = '';
    private _canCreateProject = false;
    // eslint-disable-next-line max-params
    constructor(private _state: StateService,
                private _transition: TransitionService,
                private _permissionService: UserPermissionService,
                private _featureFlagService: FeatureFlagService,
                private _dialog: MatDialog,
                public translate: TranslateService,
                private _newrelicService: NewRelicUtilityService) { }

    get activeState(): string {
        return this._activeState;
    }

    set activeState(state: string) {
        const currentRoot = (state ? state : '').split('.')[0];

        if (this.navigationTabs.some(_ => _.state === currentRoot)) {
            this._activeState = currentRoot;
        }
    }

    get isInternal(): boolean {
        return this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    get showTaskSelection(): boolean {
        return this.isInternal || this._canCreateProject;
    }

    // eslint-disable-next-line
    public async ngOnInit(): Promise<void> {
        this._canCreateProject = await this._permissionService.hasCreatePermission(CreatePermission.CreateProject);
        const showQuotes = this._featureFlagService.featureFlags[FeatureFlags.UseQuotesFeature];
        const showReports = this._featureFlagService.featureFlags[FeatureFlags.UseReportsFeature];
        const showScheduleBoard = this._featureFlagService.featureFlags[FeatureFlags.ShowScheduleBoard];

        const tabs = [
            {
                name: 'Projects',
                displayName: this.translate.instant('sideMenu.projects'),
                icon: 'projects',
                type: 'svg',
                state: 'projects',
                isVisible: await this._permissionService.hasReadPermission(ReadPermission.ViewProjects)
            },
            {
                name: 'Quotes',
                displayName: this.translate.instant('sideMenu.quotes'),
                icon: 'fa fa-usd',
                type: 'font-awesome',
                state: 'quotes',
                isVisible: showQuotes && await this._permissionService.hasReadPermission(ReadPermission.ViewQuotes)
            },
            {
                name: 'Receipts',
                displayName: this.translate.instant('sideMenu.receipts'),
                icon: 'receipt_long',
                type: 'material',
                state: 'receipts',
                isVisible: this.isInternal && await this._permissionService.hasReadPermission(ReadPermission.ViewMobileScanFiles)
            },
            {
                name: 'Map',
                displayName: this.translate.instant('sideMenu.map'),
                icon: 'place',
                type: 'material',
                state: 'map',
                externalUrl: environment.serviceMapUrl,
                isVisible: this.isInternal && await this._permissionService.hasReadPermission(ReadPermission.ViewServiceMap)
            },
            {
                name: 'ScheduleBoard',
                displayName: this.translate.instant('sideMenu.scheduleBoard'),
                icon: 'calendar_month',
                type: 'material',
                state: 'schedule-board',
                isVisible: showScheduleBoard && await this._permissionService.hasReadPermission(ReadPermission.ViewProjects)
            },
            {
                name: 'Reports',
                displayName: this.translate.instant('sideMenu.reports'),
                icon: 'insert_chart',
                type: 'material',
                state: 'reports',
                isVisible: showReports && await this._permissionService.hasReadPermission(ReadPermission.ViewDataReports)
            },
            {
                name: 'Labour',
                displayName: this.translate.instant('sideMenu.labour'),
                icon: 'timer',
                type: 'material',
                state: 'labor',
                isVisible: this.isInternal && await this._permissionService.hasReadPermission(ReadPermission.ViewLaborReview)
            },
            {
                name: 'On-call',
                displayName: this.translate.instant('sideMenu.onCall'),
                icon: 'phone_in_talk',
                type: 'material',
                state: 'on-call',
                isVisible: this.isInternal && await this._permissionService.hasReadPermission(ReadPermission.ViewOnCallSchedules)
            },
            {
                name: 'Customers',
                displayName: this.translate.instant('sideMenu.customers'),
                icon: 'emoji_emotions',
                type: 'material',
                state: 'customer',
                externalUrl: '/customer',
                isVisible: await this._permissionService.hasReadPermission(ReadPermission.ViewCrmManagement)
            },
            {
                name: 'Dictionary',
                displayName: this.translate.instant('sideMenu.dictionary'),
                icon: 'translate',
                type: 'material',
                state: 'dictionary',
                isVisible: this.isInternal && await this._permissionService.hasReadPermission(ReadPermission.ViewDictionary)
            },
            {
                name: 'Dashboard',
                displayName: this.translate.instant('sideMenu.dashboard'),
                icon: 'fa fa-thumbs-up',
                type: 'font-awesome',
                state: 'dashboard',
                externalUrl: environment.serviceDashboardUrl,
                isVisible: this.isInternal && await this._permissionService.hasReadPermission(ReadPermission.ViewServiceDashboard)
            },
            {
                name: 'Users',
                displayName: this.translate.instant('admin.userEditor.menuTitle'),
                icon: 'fa fa-truck',
                type: 'font-awesome',
                state: 'users',
                isVisible: !this.isInternal && await this._permissionService.hasReadPermission(ReadPermission.ViewServiceProviderCrewManagement)
            },
            {
                name: 'TemplateEditor',
                displayName: this.translate.instant('sideMenu.templateEditor'),
                icon: 'edit_note',
                type: 'material',
                state: 'report-template',
                isVisible: !this.isInternal && await this._permissionService.hasReadPermission(ReadPermission.ViewReportTemplateEditor, null, false)
            },
            {
                name: 'Settings',
                displayName: this.translate.instant('sideMenu.settings'),
                icon: 'settings',
                type: 'material',
                state: 'settings',
                isVisible: await this._permissionService.hasAdminPrivilege()
            }
        ];

        this.navigationTabs = tabs.filter(_ => _.isVisible);
        this.watchActiveState();
    }

    private watchActiveState(): void {
        this.activeState = this._state.$current.name;

        this._transition.onSuccess({}, (transition: Transition) => {
            this.activeState = transition.to().name;
        });
    }

    public isActiveState(state: string): boolean {
        return this.activeState && this.activeState === state;
    }

    public async openTaskSelection(): Promise<void> {
        let task;

        if (this.isInternal) {
            const dialog = this._dialog.open(TaskSelectionDialogComponent, { maxWidth: '100%' });
            task = await dialog.afterClosed().toPromise();
        }
        else {
            task = 'project';
        }

        this._newrelicService.addPageAction('', '', 'TaskSelection', { TaskName: task });

        if (task === 'quote') {
            this._dialog.open(QuoteEditorComponent, {
                data: QuoteEditorOption.getCreateOption(),
                maxWidth: '87.5vw',
                width: '87.5vw',
                height: '95vh'
            });
        }
        else if (task === 'project') {
            this._dialog.open(DispatchProjectDialogComponent, {
                data: {
                    icon: 'edit',
                    title: this.translate.instant('sideMenu.createProject'),
                    workCategory: WorkCategory.InternalJob
                } as DispatchDialogOptions,
                minHeight: '600px',
                maxWidth: '72vw',
                maxHeight: '95vh'
            });
        }
        else if (task === 'subcontract') {
            this._dialog.open(DispatchProjectDialogComponent, {
                data: {
                    icon: 'edit',
                    title: this.translate.instant('sideMenu.createSubcontract'),
                    workCategory: WorkCategory.Subcontract
                } as DispatchDialogOptions,
                minHeight: '600px',
                maxWidth: '72vw',
                maxHeight: '95vh'
            });
        }
        else if (task === 'fast-request') {
            this._dialog.open(FastRequestsDialogComponent, {
                maxWidth: '85vw',
                width: '75vw',
                height: '725px'
            });
        }
        else if (task === 'download') {
            this._dialog.open(BudgetTemplatesDialogComponent, {
                width: '85vw',
                height: '95vh'
            });
        }
        else if (task === 'integration-request') {
            this._dialog.open(IntegrationRequestsDialogComponent, {
                maxWidth: '85vw',
                width: '75vw',
                height: '725px'
            });
        }
    }

    public openTab(tab: NavigationTab): void {
        this._newrelicService.addPageAction('', '', 'SideNavigation', { Name: tab.name });

        if (tab.externalUrl) {
            window.open(tab.externalUrl);
        }
        else if (tab.state && tab.state !== this.activeState) {
            this._state.go(tab.state);
        }
    }
}
