<div *ngIf="generalInfo" class="quote-details-container" fxLayout="column" fxLayoutGap="7px">
    <div class="quote-details-action-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <button mat-raised-button color="primary" (click)="onPrint()" fxLayoutAlign="center center">
            <mat-icon>print</mat-icon>
            <span>{{ 'quote.details.print' | translate }}</span>
        </button>

        <button mat-raised-button color="primary" (click)="onEdit()" fxLayoutAlign="center center">
            <mat-icon>edit</mat-icon>
            <span>{{ 'quote.details.edit' | translate }}</span>
        </button>

        <div fxFlex></div>

        <button mat-raised-button color="warn" (click)="onDelete()" [disabled]="!canDelete" fxLayoutAlign="center center">
            <mat-icon>delete</mat-icon>
            <span>{{ 'quote.details.delete' | translate }}</span>
        </button>
    </div>

    <div *ngIf="!isContentLoading" fxLayout="column" fxLayoutGap="7px">
        <div class="quote-details-content" fxLayout="row" fxLayoutGap="7px">
            <information-grid-card [icon]="'attach_money'"
                [allowAnimation]="allowAnimation"
                [animationDelay]="0.05"
                [title]="translate.instant('quote.details.quoteInformation')"
                [config]="basicInfoCardConfig"
                fxFlex="52.5">
            </information-grid-card>

            <information-grid-card [icon]="'supervisor_account'"
                [allowAnimation]="allowAnimation"
                [animationDelay]="0.1"
                [title]="translate.instant('quote.details.customerInformation')"
                [config]="customerCardConfig"
                fxFlex="25">
            </information-grid-card>

            <information-grid-card [icon]="'calculate'"
                [allowAnimation]="allowAnimation"
                [animationDelay]="0.15"
                [title]="translate.instant('quote.details.estimationInformation')"
                [config]="estimationCardConfig"
                fxFlex="22.5">
            </information-grid-card>
        </div>

        <expandable-textarea-card
            [icon]="'description'"
            [title]="translate.instant('quote.details.quoteDescription')"
            [content]="generalInfo.description"
            [isHtml]="true"
            [allowExpand]="false"
            fxLayout="column">
        </expandable-textarea-card>
    </div>
</div>
