export class RatesQuery {
    public groupId: string;
    public version: string;
    public revision?: number;

    constructor(groupId: string, version: string, revision?: number) {
        this.groupId = groupId;
        this.version = version;
        this.revision = revision;
    }
}
