<div class="user-menu-container">
    <button class="user-menu-button"
        color="primary"
        mat-mini-fab
        aria-label="user-menu"
        [matMenuTriggerFor]="userMenu"
        [matTooltip]="userInfoTooltip">
        {{userInitials}}
    </button>
    <mat-menu #userMenu class="user-settings-menu">
        <div fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column">
                <label>{{ companyName }}</label>
                <span>{{ userBranch }}</span>
            </div>
            <div fxLayout="column" *ngIf="!loading" (click)="stopPropagation($event)">
            <mat-form-field>
                <mat-label>{{ 'admin.userEditor.languageLabel' | translate }}</mat-label>
                <mat-select [value]="userLanguage" (selectionChange)="onLanguageChange($event.value)" (click)="stopPropagation($event)" (blur)="stopPropagation($event)">
                    <mat-option *ngFor="let lang of languages" [value]="lang.language">{{lang.label}}</mat-option>
                </mat-select>
            </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="loading">
                <dots-loader></dots-loader>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutGap="10px">
                <div class="user-settings-avatar" fxLayoutAlign="center center">
                    <span>{{ userInitials }}</span>
                </div>
                <div fxLayout="column">
                    <label>{{userDisplayName}}</label>
                    <span>{{userEmail}}</span>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1vh">
                <button class="action-button" mat-stroked-button color="primary"
                    (click)="showAboutDialog()" *ngIf="showAboutPage">
                    <mat-icon>info</mat-icon>
                    <span>{{ 'shared.userMenu.aboutPage' | translate }}</span>
                </button>

                <a *ngIf="isInternal" class="action-button" mat-stroked-button color="primary" href="https://serviceapp.ideas.aha.io/ideas/new/" target="_blank">
                    <mat-icon>lightbulb</mat-icon>
                    <span>{{ 'shared.userMenu.submitIdea' | translate }}</span>
                </a>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button color="warn" (click)="signOut()">
                    {{ 'shared.userMenu.signOut' | translate }}
                </button>
            </div>
        </div>
    </mat-menu>
</div>
