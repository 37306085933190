/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { SharedModule } from '../../shared/shared.module';
import { DispatchModule } from '../dispatch/dispatch.module';
import { states } from './project.routing';
import { ProjectComponent } from './project.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectDetailsActionBarComponent } from './project-details/project-details-action-bar/project-details-action-bar.component';
import { ProjectAttachmentsComponent } from './project-attachments/project-attachments.component';
import { ProjectAttributesComponent } from './project-attributes/project-attributes.component';
import { RedispatchManagementDialogComponent } from './project-details/project-details-action-bar/dispatch/redispatch-management-dialog/redispatch-management-dialog.component';
import { RedispatchProjectComponent } from './project-details/project-details-action-bar/dispatch/redispatch-management-dialog/redispatch-project-component/redispatch-project.component';
import { DispatchManagementDialogComponent } from './project-details/project-details-action-bar/dispatch/dispatch-management-dialog/dispatch-management-dialog.component';
import { DispatchReportComponent } from './project-details/project-details-action-bar/dispatch/dispatch-management-dialog/dispatch-report-component/dispatch-report.component';
import { HandoffMeetingComponent } from './project-details/project-details-action-bar/dispatch/handoff-meeting/hand-off-meeting-component/handoff-meeting.component';
import { HandOffMeetingQuestionComponent } from './project-details/project-details-action-bar/dispatch/handoff-meeting/hand-off-meeting-question-component/hand-off-meeting-question.component';
import { ProjectTimelineDialogComponent } from './project-details/project-general-info-dialogs/project-timeline-dialog/project-timeline-dialog.component';
import { ProjectMapDialogComponent } from './project-details/project-general-info-dialogs/project-map-dialog/project-map-dialog.component';
import { ProjectReviewDialogComponent } from './project-details/project-general-info-dialogs/project-review-dialog/project-review-dialog.component';
import { ProjectNoteDialogComponent } from './project-details/project-general-info-dialogs/project-note-dialog/project-note-dialog.component';
import { ProjectStockLogDialogComponent } from './project-details/project-general-info-dialogs/project-stock-log-dialog/project-stock-log-dialog.component';
import { ProjectInvoiceComponent } from './project-invoice/project-invoice.component';
import { InvoiceUploadDialogComponent } from './project-invoice/invoice-upload-dialog/invoice-upload-dialog.component';
import { InvoiceUploadComponent } from './project-details/project-details-action-bar/process-project-dialog/invoice-upload/invoice-upload.component';
import { ProjectSafetyComponent } from './project-safety/project-safety.component';
import { ProjectQuotesComponent } from './project-quotes/project-quotes.component';
import { ProjectDetailsDispatchService } from './project-details/services/project-details-disptach/project-details-disptach.service';
import { ProjectHistoryComponent } from './project-history/project-history.component';
import { ProjectExecutiveSummaryComponent } from './project-executive-summary/project-executive-summary.component';
import { UploadBudgetDialogComponent } from './project-details/project-details-action-bar/upload-budget-dialog/upload-budget-dialog.component';
import { KpiChangeRequestDialogComponent } from './project-details/project-details-action-bar/kpi-change-request-dialog/kpi-change-request-dialog.component';
import { RejectionReasonDialogComponent } from './project-details/project-details-action-bar/rejection-reason-dialog/rejection-reason-dialog.component';
import { ReturnToSiteReasonDialogComponent } from './project-details/project-details-action-bar/return-to-site-reason-dialog/return-to-site-reason-dialog.component';
import { ReadyForProcessingWithoutBudgetReasonDialogComponent } from './project-details/project-details-action-bar/ready-for-processing-without-budget-reason-dialog/ready-for-processing-without-budget-reason-dialog.component';
import { ZeroSafetyReasonDialogComponent } from './project-details/project-details-action-bar/zero-safety-reason-dialog/zero-safety-reason-dialog.component';
import { ProcessProjectDialogComponent } from './project-details/project-details-action-bar/process-project-dialog/process-project-dialog.component';
import { AddStockLogDialogComponent } from './project-details/project-details-action-bar/add-stock-log-dialog/add-stock-log-dialog.component';
import { SwapProjectDialogComponent } from './project-details/project-details-action-bar/swap-project-dialog/swap-project-dialog.component';
import { UnmergeProjectDialogComponent } from './project-details/project-details-action-bar/unmerge-project-dialog/unmerge-project-dialog.component';
import { MergeProjectDialogComponent } from './project-details/project-details-action-bar/merge-project-dialog/merge-project-dialog.component';
import { MergeProjectReviewSelectComponent } from './project-details/project-details-action-bar/merge-project-dialog/merge-project-review-select-component/merge-project-review-select.component';
import { MergeProjectReasonComponent } from './project-details/project-details-action-bar/merge-project-dialog/merge-project-reason-component/merge-project-reason.component';
import { UpdateProjectDialogComponent } from './project-details/project-details-action-bar/update-project-dialog/update-project-dialog.component';
import { UpdateSiteInformationComponent } from './project-details/project-details-action-bar/update-project-dialog/update-site-information-component/update-site-information.component';
import { ProjectWorkChecklistComponent } from './project-details/project-work-checklist/project-work-checklist.component';
import { ProjectDailySummaryComponent } from './project-details/project-daily-summary/project-daily-summary.component';
import { ProjectConclusionSummaryComponent } from './project-details/project-conclusion-summary/project-conclusion-summary.component';
import { ProjectWorkflowService } from './project-details/services/project-workflow/project-workflow.service';
import { ProjectActionService } from './project-details/services/project-action/project-action.service';
import { ExecutiveSummaryHttpService } from '../../core/services/http/executive-summary-http/executive-summary-http.service';
import { ProjectLaborPanelComponent } from './project-details/project-labor-panel/project-labor-panel.component';
import { AttachmentTypePipe } from '../../shared/pipes/attachment-type/attachment-type.pipe';
import { ExecutiveSummaryContentComponent } from './project-executive-summary/executive-summary-content/executive-summary-content.component';
import { MergeProjectRulesDialogComponent } from './project-details/project-details-action-bar/merge-project-rules-dialog/merge-project-rules-dialog.component';
import { ChangeServiceProviderDialogComponent } from './project-details/project-details-action-bar/change-service-provider-dialog/change-service-provider-dialog.component';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states }),
        SharedModule,
        DispatchModule
    ],
    providers: [
        ProjectDetailsDispatchService,
        ProjectWorkflowService,
        ProjectActionService,
        ExecutiveSummaryHttpService,
        AttachmentTypePipe
    ],
    declarations: [
        ProjectComponent,
        ProjectDetailsComponent,
        ProjectDetailsActionBarComponent,
        ProjectMapDialogComponent,
        ProjectReviewDialogComponent,
        ProjectNoteDialogComponent,
        RedispatchManagementDialogComponent,
        RedispatchProjectComponent,
        DispatchManagementDialogComponent,
        DispatchReportComponent,
        HandOffMeetingQuestionComponent,
        HandoffMeetingComponent,
        ProjectStockLogDialogComponent,
        ProjectAttachmentsComponent,
        ProjectSafetyComponent,
        ProjectInvoiceComponent,
        InvoiceUploadDialogComponent,
        InvoiceUploadComponent,
        ProjectAttributesComponent,
        ProjectQuotesComponent,
        ProjectHistoryComponent,
        ReturnToSiteReasonDialogComponent,
        ReadyForProcessingWithoutBudgetReasonDialogComponent,
        ZeroSafetyReasonDialogComponent,
        ProcessProjectDialogComponent,
        AddStockLogDialogComponent,
        ProjectTimelineDialogComponent,
        RejectionReasonDialogComponent,
        KpiChangeRequestDialogComponent,
        UploadBudgetDialogComponent,
        SwapProjectDialogComponent,
        UpdateProjectDialogComponent,
        UpdateSiteInformationComponent,
        UnmergeProjectDialogComponent,
        MergeProjectDialogComponent,
        MergeProjectReviewSelectComponent,
        MergeProjectReasonComponent,
        ProjectWorkChecklistComponent,
        ProjectDailySummaryComponent,
        ProjectConclusionSummaryComponent,
        ProjectExecutiveSummaryComponent,
        ProjectLaborPanelComponent,
        ExecutiveSummaryContentComponent,
        MergeProjectRulesDialogComponent,
        ChangeServiceProviderDialogComponent
    ]
})
export class ProjectModule { }
