import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TemplateDataService {

    value1Subject = new BehaviorSubject<number>(0);
    value2Subject = new BehaviorSubject<number>(0);
    originalValue1 = 0;
    originalValue2 = 0;
    value1$ = this.value1Subject.asObservable();
    value2$ = this.value2Subject.asObservable();

    setValue1(value: number) {
        this.value1Subject.next(value);
    }

    setValue2(value: number) {
        this.value2Subject.next(value);
    }
}