/* eslint-disable max-params */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';

import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import QuoteFollowUpLogEntry from '../../../core/data-models/quote-follow-up-log-entry';
import QuoteFollowUpLog from '../../../core/data-models/quote-follow-up-log';
import QuoteFollowUpReminder from '../../../core/data-models/quote-follow-up-reminder';
import DialogHeaderOption from '../../../core/data-models/dialog-header-option';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import { AddFollowUpLogDialogComponent } from '../add-follow-up-log-dialog/add-follow-up-log-dialog.component';
import { AppEventService } from '../../../core/services/events/app-event/app-event.service';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
    selector: 'quote-followup-logs',
    templateUrl: './quote-followup-logs.component.html',
    styleUrls: ['./quote-followup-logs.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuoteFollowupLogsComponent implements OnInit {
    @Input() public activeQuoteId = '';
    @Input() public quoteEditorMode = false;
    public addMode = false;
    public gridViewData: QuoteFollowUpLogEntry[] = [];

    constructor(
        private _quoteHttpService: QuoteHttpService,
        private _appEvents: AppEventService,
        private _dialog: MatDialog,
        private _snackbar: MatSnackBar,
        public translate: TranslateService,
        private overlayContainer: OverlayContainer
    ) {
        this.allData = this.allData.bind(this);
    }

    public async ngOnInit(): Promise<void> {
        this.gridViewData = await this._quoteHttpService.getFollowUpLogs(this.activeQuoteId);
    }

    public showAdd(): Promise<void> {
        if (this.quoteEditorMode) {
            this.addMode = true;
            return;
        }

        const title = this.translate.instant('shared.quoteFollowUpLogs.followUpLog');

        const dialog = this._dialog.open(AddFollowUpLogDialogComponent, {
            data: new DialogHeaderOption('forum', title),
            width: '600px',
            height: '600px'
        });

        dialog.componentInstance.dataSubject.subscribe(val => {
            if (val) {
                this.processFollowUpLog(val);
            }
            try {
                dialog.close();
            }
            catch (e) {
                console.log(e);
            }
        });
    }

    public processFollowUpLog(log: QuoteFollowUpLog): void {

        if (log) {
            const action = this.addFollowUpLog(log);
            const ongoing = this.translate.instant('shared.quoteFollowUpLogs.ongoing');
            const success = this.translate.instant('shared.quoteFollowUpLogs.success');
            const error = this.translate.instant('shared.quoteFollowUpLogs.error');
            const ok = this.translate.instant('shared.ok');
            const gotIt = this.translate.instant('shared.gotIt');
            const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
            this._dialog.open(ActionProgressDialogComponent, {
                data: dialogData,
                width: '550px',
                height: '120px'
            });

        }
        else {
            this.addMode = false;
        }
    }

    public async addFollowUpLog(log: QuoteFollowUpLog): Promise<boolean> {
        if (log) {
            const apiResult = await this._quoteHttpService.addFollowUpLog(this.activeQuoteId, log);
            if (apiResult) {
                this.gridViewData = await this._quoteHttpService.getFollowUpLogs(this.activeQuoteId);
                await this._appEvents.setReloadQuoteCounter();
                this.addMode = false;
                return true;
            }
        }
        this.addMode = false;
        return false;
    }

    public addFollowUpReminder(reminder: QuoteFollowUpReminder = null): void {
        const reminderToAdd = reminder;
        const title = this.translate.instant('shared.quoteFollowUpLogs.followUpReminder');
        const ok = this.translate.instant('snackbars.ok');

        if (!this.quoteEditorMode) {
            const dialog = this._dialog.open(AddFollowUpLogDialogComponent, {
                data: new DialogHeaderOption('timer', title),
                width: '575px',
                height: '900px'
            });

            dialog.componentInstance.dataSubject.subscribe(val => {
                if (val) {
                    this.openSnackbarForReminder(val, ok);
                }
                try {
                    dialog.close();
                    this.cleanupOverlay();
                }
                catch (e) {
                    console.log(e);
                }
            });

            dialog.backdropClick().subscribe(() => {
                dialog.close();
                this.cleanupOverlay();
            });
        }

        if (reminderToAdd) {
            this.openSnackbarForReminder(reminderToAdd, ok);
        }
    }

    private cleanupOverlay() {
        const overlayContainerElement = this.overlayContainer.getContainerElement();
        if (overlayContainerElement) {
            while (overlayContainerElement.firstChild) {
                overlayContainerElement.removeChild(overlayContainerElement.firstChild);
            }
        }
    }

    public async openSnackbarForReminder(reminderToAdd: QuoteFollowUpReminder, ok: string) {
        const errorMessage = this.translate.instant('shared.quoteFollowUpLogs.reminderError');
        const successMessage = this.translate.instant('shared.quoteFollowUpLogs.followUpReminderSuccessfullyAddedOrUpdated');
        const apiResult = await this._quoteHttpService.addFollowUpReminder(this.activeQuoteId, reminderToAdd);
        this._snackbar.open(apiResult ? successMessage : errorMessage, ok);
    }

    public allData(): ExcelExportData {
        const result: ExcelExportData = {
            data: process(this.gridViewData, {}).data
        };

        return result;
    }
}
