import { LineItemExpense } from '../expenses/line-item-expense';
import { TypeRecord } from '../../generic/type-record';

export class ServiceOperationsSummarySection {
    public totalCostWithoutQualityControlPercentageCost = 0;
    public totalQualityControlPercentageCost = 0;
    public totalInsuranceCost = 0;
    public totalIndirectCost = 0;
    public totalGrossProfit = 0;
    public grossProfitPercentageOverride = 0;
    public grossProfitPercentageSetByUser = false;
    public totalSalePrice = 0;
    public priceOverride = 0;
    public priceOverrideSetByUser = false;
    public customLineItemExpenses: LineItemExpense<TypeRecord>[] = [];
}
