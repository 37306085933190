export default class Address {
    // eslint-disable-next-line max-params
    public constructor(addr1 = '', addr2 = '', city = '', state = '', country = '', zip = '') {
        this.addr1 = addr1;
        this.addr2 = addr2;
        this.city = city;
        this.country = country;
        this.state = state;
        this.zip = zip;
    }

    public addr1: string;
    public addr2: string;
    public city: string;
    public country: string;
    public state: string;
    public zip: string;
}
