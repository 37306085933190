export default class QuoteSummary {
    public name = '';
    public quoteId = '';
    public createdTime = '';
    public branch = '';
    public status = '';
    public originProjectId = '';
    public customerId = '';
    public customerName = '';
    public estimator = '';
    public budgetUploaded = false;
    public isUrgent = false;
}
