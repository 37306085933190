import Employee from './employee';
import CrewWorker from './crew-worker';

export default class Crew {
    public crewId = 0;
    public foreman: Employee = new Employee();
    public workers: CrewWorker[] = [];
    public crewName = '';
    public createdBy = '';
}
