<div class="project-summary-card-container" [ngClass]="{ 'active-summary-card': isActive }" fxLayout="row">
    <div *ngIf="isInternal" class="job-type-indicator" [ngStyle]="{ 'background-color': jobTypeColor }"></div>

    <div class="summary-content"
        [ngClass]="{ 'removed-summary': summary.isRemoved }"
        [matRippleDisabled]="summary.isRemoved"
        fxLayout="column"
        matRipple>

        <div class="summary-content-tags"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="6px"
            fxFlex="25.0">

            <span class="summary-content-tag-outline">{{ summary.projectId }}</span>

            <span class="summary-content-tag-outline" [matTooltip]="createdTime" matTooltipPosition="above">
                {{ summary.createdTime | branchLocalTime: summary.branch : branches : false }}
            </span>

            <div fxFlex></div>

            <div *ngIf="!isSimplified" fxLayout="row" fxLayoutGap="2px">
                <mat-icon
                    *ngIf="summary.isProjectScheduled"
                    [matTooltip]="translate.instant('shared.summaryList.projectSummaryCard.hasSchedule')"
                    matTooltipPosition="above">
                    calendar_month
                </mat-icon>
                <mat-icon *ngIf="summary.budgetUploaded"
                    class="budget-upload"
                    [matTooltip]="translate.instant('shared.summaryList.projectSummaryCard.budgetUploaded')"
                    matTooltipPosition="above">

                    monetization_on
                </mat-icon>

                <div *ngIf="isKpiStarsAvailable"
                    class="quality-stars"
                    [ngClass]="{ 'zero-quality-star': !summary.rating }"
                    fxLayout="row"
                    fxLayoutAlign="start end">

                    <mat-icon>grade</mat-icon>
                    <span>x{{ summary.rating }}</span>
                </div>
            </div>
        </div>

        <div class="summary-content-information" fxLayout="column" [fxFlex]="isSimplified ? '64' : '32.5'">
            <span class="project-name">{{ summary.name ? summary.name : 'N/A' }}</span>
            <span class="project-address">{{ summary.address | fullAddress }}</span>
        </div>

        <div *ngIf="!isSimplified" class="summary-content-users" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" fxFlex="32.5">
            <div *ngFor="let user of users.slice(0, visibleViewers); trackBy: trackUser"
                fxLayout="column"
                fxLayoutAlign="center center"
                fxLayoutGap="2px">

                <user-avatar-card [user]="user.user" [allowCopy]="true" [showBorder]="false"></user-avatar-card>
                <span class="summary-content-user-type">{{ user.type }}</span>
            </div>

            <div *ngIf="users.length > visibleViewers"
                class="additional-users"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxFlexAlign="start">

                <span>+{{ users.length - visibleViewers }}</span>
            </div>
        </div>

        <div class="summary-content-tags"
            fxLayout="row"
            fxLayoutAlign="end center"
            fxLayoutGap="6px"
            >

            <span class="summary-content-tag project-branch">{{ summary.branch }}</span>

            <span class="summary-content-tag" [ngClass]="{ 'processed-project': summary.status === 'Processed' }">
                {{ summary.status }}
            </span>
        </div>

        <div *ngIf="summary.isRemoved" class="removed-summary-overlay" fxLayoutAlign="center center">
            <span>{{ 'shared.summaryList.projectSummaryCard.removedOrMerged' | translate }}</span>
        </div>
    </div>
</div>
