import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import DictionaryEntry from '../../../data-models/dictionary-entry';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DictionaryHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/dictionary`;

    constructor(private _http: HttpClient) { }

    public async getDictionaryEntries(): Promise<DictionaryEntry[]> {
        try {
            const endpoint = `${this._api}/all`;
            const data = await this._http.get<DictionaryEntry[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }
}
