import { Pipe, PipeTransform } from '@angular/core';

import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';

@Pipe({
    name: 'jobTypeText'
})
export class JobTypeTextPipe implements PipeTransform {

    constructor(private _salesCodeConfigService: SalesCodeConfigService) { }

    public transform(value: string, language: string): string {
        return this._salesCodeConfigService.getTypeText(value, language);
    }
}
