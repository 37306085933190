<div class="confirmation-prompt-container">
    <h2 class="title" mat-dialog-title>{{ options.title }}</h2>

    <div mat-dialog-content fxLayout="column" fxLayoutGap="2px">
        <span class="message">{{ options.message }}</span>
    </div>

    <div class="actions" mat-dialog-actions fxLayoutAlign="end center">
        <button *ngIf="options.cancelText" mat-button [mat-dialog-close]="false" cdkFocusInitial>
            {{ options.cancelText }}
        </button>

        <button *ngIf="options.confirmText" mat-button [color]="colorType" [mat-dialog-close]="true">
            {{ options.confirmText }}
        </button>
    </div>
</div>
