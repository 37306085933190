import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { initialize, LDClient, LDContext, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import UserInfo from '../../../data-models/user-info';
import { environment } from '../../../../../environments/environment';
import { ApiFeatureFlags } from '../../../enums/feature-flags.enum';
import BudgetToolFeatureDto from '../../../data-models/budget-tool-feature-dto';
import { JsonUtility } from '../../../../../flynn-budget-tool/core/utilities/json/json.utility';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    public readonly _api = `${environment.fswApiUrl}api2/service/feature-flags`;
    public _ldClient: LDClient;
    public _flags: LDFlagSet;
    public _flagChange: Subject<LDFlagSet> = new Subject<LDFlagSet>();

    public get featureFlags(): LDFlagSet {
        return this._flags;
    }

    constructor(private _http: HttpClient) {
    }

    public get featureFlagsChange(): Observable<LDFlagSet> {
        // For some reason Launch Darkly sends the change event twice.
        // We debounce so we only emit to subscribers once.
        return this._flagChange.pipe(debounceTime(100));
    }

    public get clientReady(): Promise<void> {
        return this._ldClient.waitUntilReady();
    }

    public async initializeFeatureFlagClient(user: UserInfo): Promise<void> {
        const userForCheck = { ...user };
        userForCheck.permissions = null;
        userForCheck.groups = null;

        const userForFeatureFlag = {
            kind: 'user',
            firstName: user.name.split(' ')[0],
            lastName: user.name.split(' ').pop(),
            name: user.name,
            key: user.email.toLowerCase(),
            isInternalBuild: environment.isInternalBuild,
            branch: userForCheck.branch,
            role: userForCheck.role
        } as LDContext;

        this._ldClient = initialize(environment.featureFlagKey, userForFeatureFlag);
        this._ldClient.on('ready', this.setFeatures.bind(this));
        this._ldClient.on('change', this.setFeatures.bind(this));
        await this._ldClient.waitForInitialization();
    }

    public async evaluateUseLegacyBudgetToolByOriginProjectId(originProjectId: string, branchCode: string, salesCode: string): Promise<BudgetToolFeatureDto> {
        try {
            const endpoint = `${this._api}/${ApiFeatureFlags.UseLegacyBudgetTool}?originProjectId=${originProjectId}&branchCode=${branchCode}&salesCode=${salesCode}`;
            const result = await this._http.get<BudgetToolFeatureDto>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(result);
        }
        catch {
            return new BudgetToolFeatureDto();
        }
    }

    public async evaluateUseLegacyBudgetToolByProjectId(projectId: string): Promise<BudgetToolFeatureDto> {
        try {
            const endpoint = `${this._api}/${ApiFeatureFlags.UseLegacyBudgetTool}/project/${projectId}`;
            const result = await this._http.get<BudgetToolFeatureDto>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(result);
        }
        catch {
            return new BudgetToolFeatureDto();
        }
    }

    public async evaluateUseLegacyBudgetToolByQuoteId(quoteId: string, branchCodeOverride = '', salesCodeOverride = ''): Promise<BudgetToolFeatureDto> {
        try {
            const endpoint = `${this._api}/${ApiFeatureFlags.UseLegacyBudgetTool}/quote/${quoteId}?branchCode=${branchCodeOverride}&salesCode=${salesCodeOverride}`;
            const result = await this._http.get<BudgetToolFeatureDto>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(result);
        }
        catch {
            return new BudgetToolFeatureDto();
        }
    }

    public setFeatures(): void {
        this._flags = this._ldClient.allFlags();
        this._flagChange.next(this._flags);
    }
}
