<div *ngIf="isLoaded" class="project-attributes-container" fxLayout="column" fxLayoutGap="7px">
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex fxLayoutGap="7px" *ngIf="showGeneralAttributes">
        <project-questions-card class="information-card"
            [projectQuestions]="project.projectQuestions"
            [readOnly]="true"
            [isUSProject]="project.isUSProject" fxFlex="50">
        </project-questions-card>

        <project-attributes-card class="information-card"
            [projectQuestions]="project.projectQuestions"
            [readOnly]="true" fxFlex="50">
        </project-attributes-card>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="project.isUSProject" fxFlex="50"
        fxLayoutGap="7px">
        <project-us-questions-card class="information-card"
            [usEstimateForm]="project.uSEstimateForm"
            [readOnly]="true" fxFlex="50">
        </project-us-questions-card>

        <project-us-tax-questions-card class="information-card"
            [usTaxQuestions]="project.uSTaxQuestions"
            [readOnly]="true" fxFlex="50">
        </project-us-tax-questions-card>
    </div>
</div>
