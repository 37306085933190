import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import CodeMapping from '../../../../../core/data-models/generic/code-mapping';
import SalesCodeConfig from '../../../../../core/data-models/app-configs/sales-code-config';

@Component({
    selector: 'merge-rule-mapping-card',
    styleUrls: ['./merge-rule-mapping-card.component.scss'],
    templateUrl: './merge-rule-mapping-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MergeRuleMappingCardComponent implements OnInit, OnChanges {
    @Input() public mapping: CodeMapping<string>;
    @Input() public allMappings: CodeMapping<string>[] = [];
    @Input() public sourceSalesCodes: SalesCodeConfig[] = [];
    @Input() public allSalesCodes: SalesCodeConfig[] = [];
    @Input() public isDisabled = false;
    @Output() public update = new EventEmitter<[CodeMapping<string>, CodeMapping<string>]>();
    @Output() public delete = new EventEmitter<CodeMapping<string>>();
    public source: UntypedFormControl;
    public target: UntypedFormControl;

    get validSourceSalesCodes(): SalesCodeConfig[] {
        if (this.sourceSalesCodes.some(_ => _.code === this.mapping.source)) {
            return this.sourceSalesCodes;
        }
        // ensure current sales code is visible when it is already mapped to all sales codes
        const current = this.allSalesCodes.find(_ => _.code === this.mapping.source);

        return current ? [current, ...this.sourceSalesCodes] : this.sourceSalesCodes;
    }

    get validTargetSalesCodes(): SalesCodeConfig[] {
        const { source, target } = this.mapping;

        if (!source) {
            return [];
        }

        const mappings = this.allMappings.filter(_ => _.source === source && _.target);
        const used = new Set<string>(mappings.map(_ => _.target));
        const targets = this.allSalesCodes.filter(_ => !used.has(_.code));
        // ensure current sales code is visible when it is already mapped to selected source sales code
        const current = this.allSalesCodes.find(_ => _.code === target);

        return current ? [current, ...targets] : targets;
    }

    public ngOnInit(): void {
        const { source, target } = this.mapping;
        this.source = new UntypedFormControl(source, Validators.required);
        this.target = new UntypedFormControl(target, Validators.required);
        this.checkControlState();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.isDisabled && this.source && this.target) {
            this.checkControlState();
        }
    }

    public onSourceChange(code: string): void {
        const previous = { ...this.mapping };
        this.checkControlState();
        this.target.setValue('');
        this.mapping.source = code;
        this.mapping.target = '';
        this.update.emit([previous, this.mapping]);
    }

    public onTargetChange(code: string): void {
        const previous = { ...this.mapping };
        this.mapping.target = code;
        this.update.emit([previous, this.mapping]);
    }

    private checkControlState(): void {
        if (this.isDisabled) {
            this.source.disable();
        }
        else {
            this.source.enable();
            this.source.markAsTouched();
        }

        if (this.source.disabled || this.source.invalid) {
            this.target.disable();
        }
        else {
            this.target.enable();
            this.target.markAsTouched();
        }
    }
}
