import { Component, ViewChild, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { ExcelExportData, Workbook } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { process } from '@progress/kendo-data-query';

import Branch from '../../../core/data-models/branch';
import UserInfo from '../../../core/data-models/user-info';
import ProjectCovidScreeningResult from '../../../core/data-models/data-report/project-covid-screening-result';
import ProjectCovidScreeningResultQuery from '../../../core/data-models/data-report/project-covid-screening-result-query';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import { UserPermissionService } from '../../../core/services/user-permission/user-permission.service';
import { NewRelicUtilityService } from '../../../core/services/utility/new-relic-utility.service';
import { ReadPermission } from '../../../core/enums/read-permission.enum';

@Component({
    selector: 'project-covid-screening-report',
    styleUrls: ['./project-covid-screening-report.component.scss'],
    templateUrl: './project-covid-screening-report.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectCovidScreeningReportComponent implements OnInit {
    @Input() public branches: Branch[];
    @ViewChild(DataBindingDirective) private _dataBinding: DataBindingDirective;
    public readonly pageSize = 5;
    public user: UserInfo;
    public isLoading = false;
    public data: ProjectCovidScreeningResult[] = [];
    public query = new ProjectCovidScreeningResultQuery();
    public isBranchFilterVisible = false;
    public isRowExpanded = (): boolean => true;
    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
                private _userHttp: UserHttpService,
                private _projectHttp: ProjectHttpService,
                private _permissionService: UserPermissionService,
                private _newRelicUtility: NewRelicUtilityService,
                private _snackbar: MatSnackBar) { }

    public async ngOnInit(): Promise<void> {
        this.getExcelData = this.getExcelData.bind(this);
        this.user = await this._userHttp.getUserInfo();
        this.isBranchFilterVisible = await this._permissionService.hasReadPermission(ReadPermission.ViewAllBranchData);
        this.query.branchName = this.isBranchFilterVisible ? '' : this.user.branchInfo.name;
        // covid screening questions were added on March 17th, 2020
        this.query.timeInterval.startTime = new Date(2020, 2, 17);
    }

    public async loadScreeningResult(): Promise<void> {
        const { customerId, projectId } = this.query;

        if (!customerId?.trim() && !projectId?.trim()) {
            const message = this.translate.instant('dataReport.covidReport.missingCustomerAndProjectId');
            this._snackbar.open(message, this.translate.instant('shared.ok'));

            return;
        }

        this.isLoading = true;
        this._dataBinding.skip = 0;
        this._dataBinding.pageSize = this.pageSize;
        this.data = await this._projectHttp.getCovidScreeningResult(this.query);
        this.isLoading = false;
        this._newRelicUtility.addPageAction('ReportType', 'Covid Screening Report', 'ReportAccess', this.query);
    }

    public getExcelData(): ExcelExportData {
        return { data: process(this.data, {}).data };
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    public async onExcelExport(event: any): Promise<void> {
        event.preventDefault();
        const workbook = event.workbook;
        const rows = workbook.sheets[0].rows;
        const headerOptions = rows[0].cells[0];
        const questionSubheader = this.translate.instant('dataReport.covidReport.excelQuestionSubheader');
        const answerSubheader = this.translate.instant('dataReport.covidReport.excelAnswerSubheader');
        // iterate from end to start as the index will shift when we insert rows
        for (let i = this.data.length - 1; i >= 0; --i) {
            const { results } = this.data[i];

            for (let j = results.length - 1; j >= 0; --j) {
                rows.splice(i + 2, 0, {
                    cells: [
                        {},
                        { value: results[j].question },
                        { value: results[j].answer }
                    ]
                });
            }

            rows.splice(i + 2, 0, {
                cells: [
                    {},
                    { ...headerOptions, value: questionSubheader },
                    { ...headerOptions, value: answerSubheader }
                ]
            });
        }

        const url = await new Workbook(workbook).toDataURL();
        saveAs(url, 'covid_screening_report.xlsx');
    }
}
