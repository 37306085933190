<dialog-action-card class="project-note-dialog-container"
    [icon]="'notes'"
    [title]="translate.instant('projects.notes.title')"
    [isLoaded]="true"
    (close)="onClose()">

    <user-comments-panel [comments]="comments"
        [user]="currentUser"
        [isLoaded]="isLoaded"
        (commentCreate)="onCommentCreate($event)"
        (commentUpdate)="onCommentUpdate($event)"
        (commentDelete)="onCommentDelete($event)">
    </user-comments-panel>
</dialog-action-card>
