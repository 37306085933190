<div class="role-list-viewer-container">
    <div class="mat-elevation-z8 role-list-wrapper">
        <table mat-table [dataSource]="roles">
            <ng-container matColumnDef="name">
                <th class="role-name" mat-header-cell *matHeaderCellDef>
                    <span>{{ 'admin.rolesAndPermissions.roleListViewer.rolesHeaderText' | translate }}</span>
                    <span *ngIf="roles.length">&nbsp;({{ roles.length }})</span>
                </th>

                <td class="role-name" mat-cell *matCellDef="let role">
                    <span>{{ role.name }}</span>

                    <span *ngIf="role.isBuiltIn" class="built-in-text">
                        &nbsp;({{ 'admin.rolesAndPermissions.roleListViewer.builtInIndicator' | translate }})
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="summary">
                <th class="summary-icons" mat-header-cell *matHeaderCellDef></th>

                <td class="summary-icons" mat-cell *matCellDef="let role">
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
                        <div *ngIf="role.permissions" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
                            <mat-icon>admin_panel_settings</mat-icon>
                            <span>{{ role.permissions }}</span>
                        </div>

                        <div *ngIf="role.subscriptions" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
                            <mat-icon>forward_to_inbox</mat-icon>
                            <span>{{ role.subscriptions }}</span>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="lastModified">
                <th class="last-modified" mat-header-cell *matHeaderCellDef>
                    {{ 'admin.rolesAndPermissions.roleListViewer.lastModifiedHeaderText' | translate }}
                </th>

                <td class="last-modified" mat-cell *matCellDef="let role">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>{{ role.timeInfo.lastModified | branchLocalTime: user.branch : branches }}</span>

                        <ng-container *ngIf="!role.isBuiltIn">
                            <button class="role-actions-menu"
                                mat-icon-button
                                [matMenuTriggerFor]="roleActions"
                                (click)="$event.stopPropagation()">

                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #roleActions="matMenu">
                                <button mat-menu-item (click)="deleteRole.emit(role)">
                                    {{ 'admin.rolesAndPermissions.rolesView.deleteRoleButtonText' | translate }}
                                </button>
                            </mat-menu>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: visibleColumns;" (click)="openRole.emit(row)"></tr>
        </table>
    </div>
    <!-- cover the scrollbar on table header row -->
    <div class="scrollbar-cover"></div>

    <button class="add-role" mat-mini-fab color="primary" (click)="addRole.emit()">
        <mat-icon>add</mat-icon>
    </button>
</div>
