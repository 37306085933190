<dialog-action-card [icon]="'settings'"
    [title]="translate.instant('shared.dashboardViewDialog.title')"
    [isLoaded]="true"
    [isModalDialog]="true">

    <div class="dashboard-view-content" fxLayout="column">
        <div fxLayout="row" fxFlex>
            <iframe [src]="serviceDashboardUrl" width="100%" height="100%"></iframe>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxFlex="70px">
            <button mat-flat-button color="primary" [disabled]="disableAcknowledge" (click)="onClose()">Acknowledge</button>
        </div>
    </div>
</dialog-action-card>
