import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'dialog-action-card',
    styleUrls: ['./dialog-action-card.component.scss'],
    templateUrl: './dialog-action-card.component.html'
})
export class DialogActionCardComponent {
    @Input() public icon = '';
    @Input() public title = '';
    @Input() public isLoaded = false;
    @Input() public isModalDialog = false;
    @Input() public subTitle? = '';
    @Output() public close = new EventEmitter();
}
