/* eslint-disable max-lines */
import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

import DispatchDialogOptions from '../../../core/data-models/dispatch/dispatch-dialog-options';
import DispatchStepConfig from '../../../core/data-models/dispatch/dispatch-step-config';
import { DispatchHttpService } from '../../../core/services/http/dispatch-http/dispatch-http.service';
import DynamicsDispatchInfo from '../../../core/data-models/dispatch/dynamics-dispatch-info';
import { BranchHttpService } from '../../../core/services/http/branch-http/branch-http.service';
import DispatchProjectInfoData from '../../../core/data-models/dispatch/dispatch-project-info-data';
import Branch from '../../../core/data-models/branch';
import ProjectQuestions from '../../../core/data-models/project-attributes/project-questions';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';
import ProjectUsEstimateForm from '../../../core/data-models/project-attributes/project-us-estimate-form';
import GeoCoordinate from '../../../core/data-models/geo-coordinate';
import HistoryStepConfig from '../../../core/data-models/dispatch/history-step-config';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import BudgetStepConfig from '../../../core/data-models/dispatch/budget-step-config';
import AttachmentStepConfig from '../../../core/data-models/dispatch/attachment-step-config';
import CustomerSelectionStepConfig from '../../../core/data-models/dispatch/customer-selection-step-config';
import DispatchData from '../../../core/data-models/dispatch/dispatch-data';
import CustomerDto from '../../../core/data-models/customer-dto';
import WorkOrderStepConfig from '../../../core/data-models/dispatch/work-order-step-config';
import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';
import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { DispatchSteps } from '../../../core/enums/dispatch-steps.enum';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { CustomerCreationHttpService } from '../../../core/services/http/customer-creation-http/customer-creation-http.service';
import HealthScreeningOption from '../../../core/data-models/health-screening-option';
import Customer from '../../../core/data-models/customer';
import SOAddress from '../../../core/data-models/so-address';
import UserInfo from '../../../core/data-models/user-info';
import CustomerSelectionDto from '../../../core/data-models/customer-selection-dto';
import CustomerSiteDto from '../../../core/data-models/customer-site-dto';
import CustomerSiteDispatchInfo from '../../../core/data-models/dispatch/customer-site-dispatch-info';
import ContactInfo from '../../../core/data-models/contact-info';
import { QuoteHttpService } from '../../../core/services/http/quote-http/quote-http.service';
import QuoteAwardOptions from '../../../core/data-models/dispatch/quote-award-options';
import { SelectSiteDialogComponent } from '../../../shared/components/dialogs/select-site-dialog/select-site-dialog.component';
import SelectSiteDialogOptions from '../../../core/data-models/select-site-dialog-options';
import FastClientRequest from '../../../core/data-models/dispatch/fast-client-request';
import AdditionalInfoStepConfig from '../../../core/data-models/dispatch/additional-info-step-config';
import UsProjectInfoStepConfig from '../../../core/data-models/dispatch/us-project-info-step.config';
import USProjectInfoModel from '../../../core/data-models/dispatch/us-project-info-model';
import ProjectUsTaxQuestions from '../../../core/data-models/project-us-tax-questions';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import { CountryName } from '../../../core/enums/country-name.enum';
import { SalesCodeConfigService } from '../../../core/services/sales-code-config/sales-code-config.service';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { AppEventService } from '../../../core/services/events/app-event/app-event.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import ProjectRequest from '../../../core/data-models/integration/project-request';
import { WorkCategory } from '../../../core/data-models/work-category';

@Component({
    selector: 'app-dispatch-project-dialog',
    styleUrls: ['./dispatch-project-dialog.component.scss'],
    templateUrl: './dispatch-project-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DispatchProjectDialogComponent implements OnInit {
    public usProject = false;
    public dynamicsDispatchInfo: DynamicsDispatchInfo;
    public branches: Branch[];
    public loading = true;
    public user: UserInfo;
    public projectInfoStep: DispatchStepConfig;
    public historyStepConfig: HistoryStepConfig;
    public budgetStepConfig: BudgetStepConfig;
    public attachmentStepConfig: AttachmentStepConfig;
    public customerSelectionStepConfig: CustomerSelectionStepConfig;
    public workOrderStepConfig: WorkOrderStepConfig;
    public additionalInfoStepConfig: AdditionalInfoStepConfig;
    public usProjectInfoStepConfig: UsProjectInfoStepConfig;
    public healthScreeningOption: HealthScreeningOption;
    public selectedCustomer: CustomerDto;
    public dispatching = false;
    public budgetValue = 0;
    public isConvertingProject = false;
    private handOffMeetingThreshold = 0;
    private _isInternal = false;
    @ViewChild('stepper') public stepper: MatStepper;

    get handOffMeetingRequired(): boolean {
        return this.budgetValue > this.handOffMeetingThreshold &&
            this._salesCodeConfigService.isHandOffMeetingJob(this.projectInfoStep.data.salesCode) &&
            !this.isConvertingProject;
    }

    get sendToLocalQueueText(): string {
        const isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        const key = isInternal ? 'fragment3ForBranch' : 'fragment3ForServiceProvider';

        return this.translate.instant(`dispatch.dispatchProjectDialog.projectInformationSummary.summary.${key}`);
    }

    get selectedHistory(): string[] {
        return this.historyStepConfig.historyList.filter(_ => _.selected).map(_ => _.projectId);
    }

    get isInternal(): boolean {
        return this._isInternal;
    }

    // Temporary: See Aha FSW-3056 - We want to remove 'isSubcontract' etc in favour of configurable flags from the backend.
    get isSubcontract(): boolean {
        return this.options.workCategory === WorkCategory.Subcontract;
    }

    // Temporary: See Aha FSW-3056 - We want to remove 'isSubcontract' etc in favour of configurable flags from the backend.
    get showAdditionalInfoStep(): boolean {
        return this.isInternal && !this.isSubcontract;
    }

    // Temporary: See Aha FSW-3056 - We want to remove 'isSubcontract' etc in favour of configurable flags from the backend.
    get showUSProjectInfoStep(): boolean {
        return this.isInternal && this.usProject;
    }

    //eslint-disable-next-line
    constructor(public translate: TranslateService,
                private _dispatchService: DispatchHttpService,
                private _branchService: BranchHttpService,
                private _projectService: ProjectHttpService,
                private _appSettingService: AppConfigHttpService,
                private _userHttpService: UserHttpService,
                private _dialogService: MatDialog,
                private _dialog: MatDialogRef<DispatchProjectDialogComponent>,
                private _customerCreationService: CustomerCreationHttpService,
                private _quoteService: QuoteHttpService,
                private _salesCodeConfigService: SalesCodeConfigService,
                private _featureFlagService: FeatureFlagService,
                private _appEvents: AppEventService,
                @Inject(MAT_DIALOG_DATA) public options: DispatchDialogOptions) { }

    public async ngOnInit(): Promise<void> {
        this.user = await this._userHttpService.getUserInfo();
        this.projectInfoStep = new DispatchStepConfig(DispatchSteps.ProjectInfoStep);
        this.historyStepConfig = new HistoryStepConfig(DispatchSteps.HistoryStep);
        this.budgetStepConfig = new BudgetStepConfig(DispatchSteps.BudgetStep);
        this.attachmentStepConfig = new AttachmentStepConfig(DispatchSteps.AttachmentsStep);
        this.customerSelectionStepConfig = new CustomerSelectionStepConfig(DispatchSteps.CustomerSelectionStep);
        this.workOrderStepConfig = new WorkOrderStepConfig(DispatchSteps.WorkOrderStep);
        this.additionalInfoStepConfig = new AdditionalInfoStepConfig(DispatchSteps.AdditionalInfoStep);
        this.usProjectInfoStepConfig = new UsProjectInfoStepConfig(DispatchSteps.UsProjectQuestionsStep);
        if (this.isSubcontract) {
            this.setIsSubcontract();
        }

        this._isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures]?? false;
        this._dialog.disableClose = true;
        this.dynamicsDispatchInfo = await this._dispatchService.getDynamicsDispatchInfo(this.options.workCategory);
        this.branches = await this._branchService.getAllBranches();
        this.handOffMeetingThreshold = await this._appSettingService.getHandOffMeetingThreshold();
        this.setProjectInfoData();
        this.initProjectQuestions();
        this.setConvertingProjectData();
        if (this.options.fastClientRequest) {
            await this.fillFastClientRequest();
        }
        else if (this.options.integrationRequest) {
            await this.fillDispatchStepsFromIntegrationRequest();
        }

        if (await this.validateAndSetAwardingQuoteData()) {
            this.loading = false;
        }

        const budgetFeature = this._featureFlagService.featureFlags[FeatureFlags.UseBudgetFeature]?? false;
        if (!budgetFeature) {
            this.projectInfoStep.data.budgetType = 'T&M';
        }
    }

    public handleStep(step: string): void {
        if (step === 'next') {
            this.stepper.next();
        }
        else {
            this.stepper.previous();
        }
    }

    public validateBranchRequirements(branch: Branch): void {
        this.usProject = GenericUtilityService.isUsBranch(branch);
        this.additionalInfoStepConfig.isUsProject = this.usProject;
    }

    public updateBudgetRequirements(budgetType: string): void {
        this.budgetStepConfig = { ...this.budgetStepConfig,
            budgetRequired: budgetType === 'Fixed Price',
            file: null,
            completed: false,
            customerId: this.selectedCustomer?.customerId };
    }

    public async loadSiteHistory(geoCode: google.maps.LatLngLiteral): Promise<void> {
        const siteGeoCode = { latitude: geoCode.lat, longitude: geoCode.lng } as GeoCoordinate;
        this.projectInfoStep.data.geoCode = siteGeoCode;
        this.historyStepConfig.completed = false;
        const numberToPreselect = 3;
        this.historyStepConfig.historyList = (await this._projectService.getHistoryByGeoCode(siteGeoCode))
            .map((history, index) => {
                return { selected: index < numberToPreselect, ...history };
            });
    }

    //eslint-disable-next-line
    public async createProject(): Promise<void> {
        const { site } = this.customerSelectionStepConfig.customerData;
        const { branch } = this.projectInfoStep.data;
        if (!this.validateProjectSiteAndBranch(site, this.projectInfoStep.data.branch)) {
            const action = of(false).toPromise();
            const ongoing = this.translate.instant('dispatch.dispatchProjectDialog.createProjectAction.ongoing');
            const error = branch.address.country === CountryName.CA ?
                this.translate.instant('dispatch.dispatchProjectDialog.createProjectAction.countryMismatchErrorCABranch'):
                this.translate.instant('dispatch.dispatchProjectDialog.createProjectAction.countryMismatchErrorUSBranch');

            const ok = this.translate.instant('shared.ok');
            const gotIt = this.translate.instant('shared.gotIt');

            const dialogData = new ActionProgressReporterOption(action, ongoing, '', error, ok, gotIt);
            const dialog = this._dialogService.open(ActionProgressDialogComponent, {
                data: dialogData,
                width: '550px',
                height: '120px'
            });

            await dialog.afterClosed().toPromise();
            return;
        }

        this.dispatching = true;
        const dispatchData = new DispatchData();
        dispatchData.generalDispatchInfo = this.projectInfoStep.data;
        dispatchData.selectedCustomer = this.customerSelectionStepConfig.customerData;
        dispatchData.siteDispatchInfo = this.customerSelectionStepConfig.customerDispatchInfo;
        dispatchData.siteHistory = this.selectedHistory;
        dispatchData.workCheckList = this.workOrderStepConfig.workCheckList;
        dispatchData.workOrderString = this.workOrderStepConfig.workOrderString;
        dispatchData.handOffMeetingRequired = this.handOffMeetingRequired;
        dispatchData.quoteAwardOptions =
            {
                useQuoteBudget: this.options.quoteToAward ? this.budgetStepConfig.useQuoteBudget : false,
                quoteId: this.options.quoteToAward ? this.options.quoteToAward.basicInfo.quoteId : null,
                usingWebBudget: this.options.quoteToAward ? this.budgetStepConfig.usingWebBudget : false
            } as QuoteAwardOptions;
        dispatchData.fastClientRequestId = this.options.fastClientRequest?.id;
        dispatchData.projectQuestions = this.additionalInfoStepConfig.projectQuestions;
        dispatchData.usProjectInfo = this.usProject ? this.usProjectInfoStepConfig.data : null;
        dispatchData.integrationProjectId = this.options.integrationRequest?.projectId;

        const files = new Map<string, File[]>();
        if (this.attachmentStepConfig.attachments?.length) {
            files.set('attachments', this.attachmentStepConfig.attachments);
        }

        if (this.attachmentStepConfig.roofMap) {
            files.set('roofMap', [this.attachmentStepConfig.roofMap]);
        }

        if (this.budgetStepConfig.file) {
            files.set('budgetFile', [this.budgetStepConfig.file]);
        }

        if (this.isConvertingProject) {
            dispatchData.projectId = this.options.emergencyProjectId;
        }

        const action = this.isConvertingProject?
            this._dispatchService.convertProject(dispatchData, files) :
            this._dispatchService.createNewProject(dispatchData, files);
        const ongoing = this.translate.instant('dispatch.dispatchProjectDialog.convertProjectAction.ongoing');
        const success = this.translate.instant('dispatch.dispatchProjectDialog.convertProjectAction.success');
        const error = this.translate.instant('dispatch.dispatchProjectDialog.convertProjectAction.error');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });
        if (await dialog.afterClosed().toPromise()) {
            this._appEvents.setProjectCreated();
            this._dialog.close();
        }

        this.dispatching = false;
    }

    public updateSelectedCustomer(selection: CustomerSelectionDto): void {
        const { customer, site } = selection;
        this.selectedCustomer = customer;
        this.healthScreeningOption = new HealthScreeningOption(customer.customerId, site.shipToId);
        this.updateBudgetRequirements(this.projectInfoStep.data.budgetType);
    }

    public updateJobTypeRequirements(jobType: string): void {
        this.workOrderStepConfig = { ...this.workOrderStepConfig,
            completed: false,
            jobType } as WorkOrderStepConfig;
    }

    public goToStep(stepName: string): void {
        const localizedName = this.translate.instant(stepName);
        const step = this.stepper.steps.find(_ => _.label === localizedName);
        if (step) {
            step.select();
        }
        else {
            this.stepper.selectedIndex = 0;
        }
    }

    public async onClose(): Promise<void> {
        const firstStepHasData = GenericUtilityService.hasData(this.customerSelectionStepConfig.customerDispatchInfo);

        if (!firstStepHasData || await this.alertUnsavedChanges()) {
            this._dialog.close();
        }
    }

    private setIsSubcontract(): void {
        // Note: 'Additional Info' step do not appear for subcontracts.
        // If that ever changes, ensure to set the flag here.
        this.projectInfoStep.workCategory = WorkCategory.Subcontract;
        this.historyStepConfig.workCategory = WorkCategory.Subcontract;
        this.budgetStepConfig.workCategory = WorkCategory.Subcontract;
        this.attachmentStepConfig.workCategory = WorkCategory.Subcontract;
        this.customerSelectionStepConfig.workCategory = WorkCategory.Subcontract;
        this.workOrderStepConfig.workCategory = WorkCategory.Subcontract;
        this.usProjectInfoStepConfig.workCategory = WorkCategory.Subcontract;
    }

    private setProjectInfoData(): void {
        this.projectInfoStep.data = new DispatchProjectInfoData();
        this.projectInfoStep.branches = this.branches;
        this.projectInfoStep.dynamicsDispatchInfo = this.dynamicsDispatchInfo;
        this.projectInfoStep.budgetTypeOverride = this.options.budgetTypeOverride;
    }

    private initProjectQuestions(): void {
        const projectQuestions = new ProjectQuestions();
        this.additionalInfoStepConfig.projectQuestions = projectQuestions;
        const usProjectInfo = new USProjectInfoModel();
        usProjectInfo.uSEstimateForm = new ProjectUsEstimateForm();
        usProjectInfo.uSProjectTaxQuestions = new ProjectUsTaxQuestions();
        this.usProjectInfoStepConfig.data = usProjectInfo;
    }

    private setConvertingProjectData() {
        this.isConvertingProject = Boolean(this.options.emergencyProjectId);
        if (this.isConvertingProject) {
            this.projectInfoStep.isConvertingProject = true;
            const emergencyCode = this._salesCodeConfigService.getSalesCode(this.options.emergencyProjectId, true);
            this.projectInfoStep.dynamicsDispatchInfo.salesCode =
                this._salesCodeConfigService.getEmergencyConversionTargets(emergencyCode);
        }
    }

    private async validateAndSetAwardingQuoteData(): Promise<boolean> {
        if (!this.options.quoteToAward) {
            return true;
        }

        const quoteCustomer = this.options.quoteToAward.customer;
        const customerInfo = await this._customerCreationService.getCustomerById(quoteCustomer.customerId);
        const siteInfo = customerInfo?.addresses?.find(_ => _.shipToId === quoteCustomer.siteId);
        const billingSite = customerInfo?.addresses?.find(_ => _.shipToId === quoteCustomer.billingSiteId && _.isBillAddress);

        if (!customerInfo || !siteInfo || !billingSite) {
            let errorMessage = this.translate.instant('dispatch.dispatchProjectDialog.validateQuoteAward.errorMessage1');
            if (!siteInfo) {
                errorMessage = this.translate.instant('dispatch.dispatchProjectDialog.validateQuoteAward.errorMessage2');
            }
            else if (!billingSite) {
                errorMessage = this.translate.instant('dispatch.dispatchProjectDialog.validateQuoteAward.errorMessage3');
            }

            const action = of(false).toPromise();
            const error =
                `${errorMessage}, ${this.translate.instant('dispatch.dispatchProjectDialog.validateQuoteAward.errorMessage4')}`;
            const ok = this.translate.instant('shared.ok');
            const gotIt = this.translate.instant('shared.gotIt');
            const dialogData = new ActionProgressReporterOption(action, '', '', error, ok, gotIt, true, 0);
            const dialog = this._dialogService.open(ActionProgressDialogComponent, {
                data: dialogData,
                width: '550px',
                height: '120px'
            });

            await dialog.afterClosed().toPromise();
            this._dialog.close();
            return false;
        }

        this.fillCustomerInfo(customerInfo, siteInfo, billingSite, this.options.quoteToAward.reportingContact);
        await this.fillProjectInfoFromQuote();
        this.workOrderStepConfig.workOrderString = this.options.quoteToAward.description;
        this.workOrderStepConfig.jobType = this.options.quoteToAward.basicInfo.salesCode;

        return true;
    }

    private fillCustomerInfo(customer: Customer, site: SOAddress, billingSite: SOAddress, contact: ContactInfo): void {
        const customerData = new CustomerSelectionDto();
        customerData.customer = new CustomerDto(customer);
        customerData.site = new CustomerSiteDto(site);
        customerData.billingSite = new CustomerSiteDto(billingSite);

        this.customerSelectionStepConfig.customerData = customerData;
        this.customerSelectionStepConfig.customerDispatchInfo = {
            reportingContact: contact.name,
            reportingEmail: contact.email,
            reportingPhone: contact.officePhone
        } as CustomerSiteDispatchInfo;
        this.customerSelectionStepConfig.customerPerSelected = true;
    }

    private async fillProjectInfoFromQuote(): Promise<void> {
        const quoteInfo = this.options.quoteToAward.basicInfo;
        this.projectInfoStep.branches = this.branches.filter(_ => _.name === quoteInfo.branch);
        this.projectInfoStep.data = new DispatchProjectInfoData();
        this.projectInfoStep.data.estimatedHours = this.options.quoteToAward.estimation.hours;
        this.projectInfoStep.data.name = quoteInfo.name;
        this.projectInfoStep.data.salesCode = quoteInfo.salesCode;
        this.projectInfoStep.data.branch = this.projectInfoStep.branches[0];
        const quoteAttachments = await this._quoteService.getQuoteAttachments(quoteInfo.quoteId);
        if (quoteAttachments.some(_ => _.type === 'flynn/budgetfile') || this.options.hasWebBudget) {
            this.projectInfoStep.data.budgetType = 'Fixed Price';
            this.budgetStepConfig.awardingQuoteId = quoteInfo.quoteId;
            this.budgetStepConfig.useQuoteBudget = true;
            this.budgetStepConfig.usingWebBudget = this.options.hasWebBudget;
        }
        else {
            this.projectInfoStep.data.budgetType = 'T&M';
            this.budgetStepConfig.awardingQuoteId = '';
        }
    }

    private async fillFastClientRequest(): Promise<void> {
        const request = this.options.fastClientRequest;
        await this.fillCustomerFromClientRequest(request);
        this.fillProjectInfoAndWorkOrderFromClientRequest(request);
    }

    private fillProjectInfoAndWorkOrderFromClientRequest(request: FastClientRequest): void {
        this.projectInfoStep.data = new DispatchProjectInfoData();
        this.projectInfoStep.data.notToExceedAmount = request.notToExceedAmount;
        this.projectInfoStep.data.name = request.projectName;
        this.workOrderStepConfig.workOrderString = request.projectDetail;
    }

    private async fillCustomerFromClientRequest(request: FastClientRequest): Promise<void> {
        const customerInfo = await this._customerCreationService.getCustomerById(request.customerId);
        const siteInfo = customerInfo?.addresses.find(_ => _.shipToId === request.customerSiteId);
        const billingSites = customerInfo?.addresses.filter(_ => _.isBillAddress);

        if (!customerInfo || !siteInfo || !billingSites?.length) {
            let errorMessage =
                this.translate.instant('dispatch.dispatchProjectDialog.fillCustomerFromClientRequest.errorMessage1');
            if (!siteInfo) {
                errorMessage =
                    this.translate.instant('dispatch.dispatchProjectDialog.fillCustomerFromClientRequest.errorMessage2');
            }
            else if (!billingSites?.length) {
                errorMessage =
                    this.translate.instant('dispatch.dispatchProjectDialog.fillCustomerFromClientRequest.errorMessage3');
            }

            const action = of(false).toPromise();
            const error = `${errorMessage}, ` +
                `${this.translate.instant('dispatch.dispatchProjectDialog.fillCustomerFromClientRequest.errorMessage4')}`;
            const ok = this.translate.instant('shared.ok');
            const gotIt = this.translate.instant('shared.gotIt');
            const dialogData = new ActionProgressReporterOption(action, '', '', error, ok, gotIt, true, 0);
            const dialog = this._dialogService.open(ActionProgressDialogComponent, {
                data: dialogData,
                width: '550px',
                height: '120px'
            });

            await dialog.afterClosed().toPromise();
            this._dialog.close();
            return;
        }

        let billingSite = billingSites[0];
        if (billingSites.length > 1) {
            const selectSiteDialog = this._dialogService.open(SelectSiteDialogComponent, {
                data: {
                    customerDisplayName: customerInfo.name,
                    availableSites: billingSites,
                    title: 'Select Billing Site',
                    icon: 'local_atm',
                    enableClose: false
                } as SelectSiteDialogOptions,
                width: '950px',
                height: '720px'
            });

            billingSite = await selectSiteDialog.afterClosed().toPromise();
        }

        this.fillCustomerInfo(customerInfo, siteInfo, billingSite, new ContactInfo());
    }

    private async alertUnsavedChanges(): Promise<boolean> {
        const title = this.translate.instant('dispatch.dispatchProjectDialog.unsavedChangesAlert.data.title');
        const message = this.translate.instant('dispatch.dispatchProjectDialog.unsavedChangesAlert.message');
        const confirmText = this.translate.instant('dispatch.dispatchProjectDialog.unsavedChangesAlert.data.confirmText');
        const cancelText = this.translate.instant('shared.cancel');

        const data = new ConfirmDialogOption(title, message, true, confirmText, cancelText);
        const dialog = this._dialogService.open(ConfirmDialogComponent, { data });

        return await dialog.afterClosed().toPromise<boolean>();
    }

    private validateProjectSiteAndBranch(site: CustomerSiteDto, branch: Branch): boolean {
        if (!this.isInternal && branch.isDefault) {
            return true;
        }

        return site.country === branch.address.country;
    }

    private async fillDispatchStepsFromIntegrationRequest(): Promise<void> {
        const integrationRequest = this.options.integrationRequest;
        this.customerSelectionStepConfig.customerDispatchInfo = {
            reportingContact: integrationRequest.siteDispatchInfo.reportingContact,
            reportingEmail: integrationRequest.siteDispatchInfo.reportingEmail,
            reportingPhone: integrationRequest.siteDispatchInfo.reportingPhone
        } as CustomerSiteDispatchInfo;
        this.customerSelectionStepConfig.customerForSuggestions = { ...integrationRequest.selectedCustomer };
        this.customerSelectionStepConfig.customerForSuggestions.customer.customerId = integrationRequest.customerId;

        this.projectInfoStep.data = new DispatchProjectInfoData();
        this.projectInfoStep.data.customerPurchaseOrder = integrationRequest.generalDispatchInfo.customerPurchaseOrder;
        this.projectInfoStep.data.dispatcherNotes = integrationRequest.generalDispatchInfo.dispatcherNotes;
        this.projectInfoStep.data.estimatedDays = integrationRequest.generalDispatchInfo.estimatedDays;
        this.projectInfoStep.data.estimatedHours = integrationRequest.generalDispatchInfo.estimatedHours;
        this.projectInfoStep.data.name = integrationRequest.generalDispatchInfo.name;
        this.projectInfoStep.data.notToExceedAmount = integrationRequest.generalDispatchInfo.notToExceedAmount;
        this.projectInfoStep.isIntegrationRequest = true;
        await this.setSalesCodeAndJobType(integrationRequest);

        this.workOrderStepConfig.workOrderString = integrationRequest.workOrderString;
    }

    private async setSalesCodeAndJobType(integrationRequest: ProjectRequest): Promise<void> {
        const mappings = await this._appSettingService.getProjectTypeSalesCodeMapping();
        const salesCode = mappings.find(_ => _.projectType === integrationRequest.generalDispatchInfo.salesCode);
        this.projectInfoStep.data.salesCode = salesCode?.flynnSalesCode;
        this.projectInfoStep.data.jobType = salesCode?.flynnJobType;
    }
}
