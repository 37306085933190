<div class="photo-report-viewer-container" fxLayout="column" fxLayoutGap="7px">

    <div class="photo-report-tool-bar" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="!disableActionBar">
        <dropdown-button *ngIf="!editMode"
            [icon]="'format_list_bulleted'"
            [text]="translate.instant('photos.sorting')"
            [options]="sortingButtons"
            (optionSelect)="processSorting($event)">
        </dropdown-button>

        <button *ngIf="!editMode"
            mat-raised-button
            color="primary"
            (click)="openUploadImage()">

            {{ 'shared.photoReportViewer.upload' | translate }}
        </button>

        <div [matTooltip]="deletedPhotosToolTip">
            <button *ngIf="!editMode"
                mat-raised-button
                color="primary"
                (click)="openDeletedPhoto()"
                [disabled]="!deletedImages.length">

                {{ 'shared.photoReportViewer.deleted' | translate }}
            </button>
        </div>

        <button *ngIf="editMode"
            mat-raised-button
            color="primary"
            (click)="exitEditMode()">

            {{ 'shared.photoReportViewer.backToPhotos' | translate }}
        </button>

        <mat-slide-toggle *ngIf="showPicOrder"
            color="primary"
            [(ngModel)]="picOrder">

            {{ 'shared.photoReportViewer.setPicOrder' | translate }}
        </mat-slide-toggle>

        <button *ngIf="showPicOrder"
            mat-raised-button
            color="warn"
            (click)="resetOrder()">

            {{ 'shared.photoReportViewer.resetOrder' | translate }}
        </button>

        <div fxFlex></div>

        <dropdown-button *ngIf="!editMode"
            [icon]="'widgets'"
            [text]="translate.instant('photos.actions')"
            [options]="actionButtons"
            (optionSelect)="processAction($event)">
        </dropdown-button>
    </div>

    <mat-card fxLayout="column" fxFlex>
        <mat-card-subtitle *ngIf="sortMode">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center" class="sort-photo-hint">
                {{ 'shared.photoReportViewer.instructionsToMovePhoto' | translate }}
            </div>
        </mat-card-subtitle>

        <mat-card-content fxLayout="column" fxFlex [hidden]="loading" class="main-report-content">
            <div *ngIf="editMode" fxLayout="column" fxFill fxLayoutGap="15px">
                <div fxFlex>
                    <app-edit-photo [image]="imageForEdit" [selectedIndex]="selectedIndex" [endIndex]="endIndex" (navigationClick)="navigateImage($event)"
                    (actionClick)="doEditAction($event)" (imageFileUpdate)="uploadNewImage($event)" [hasPageQuestions]="isAudit" [imageTitles]="imageTitles"></app-edit-photo>
                </div>
                <div>
                    <app-photo-carousel [images]="images" (imageSelected)="setImage($event)"></app-photo-carousel>
                </div>
            </div>

            <ng-container *ngIf="!editMode">
                <div class="photo-report-viewer-cards" fxFlex>
                    <div *ngIf="!groupedView" fxLayout="row wrap" fxLayoutGap="7px">
                        <app-photo-card *ngFor="let image of displayImages; trackBy: trackByFunction"
                            [photo]="image.photo"
                            [config]="image.photoConfig"
                            [highlighted]="selectedImageIndex === image.photo.index"
                            (cardEvent)="doPhotoCardAction($event)">
                        </app-photo-card>
                    </div>
                    <div *ngIf="groupedView">
                        <div fxLayout="column" *ngFor="let group of groupedImages;">
                            <mat-card>
                                <h4>{{group.groupName}}</h4>
                            </mat-card>
                            <div fxLayout="row wrap" fxLayoutGap="7px">
                                <app-photo-card *ngFor="let image of group.items; trackBy: trackByFunction"
                                    [photo]="image.photo"
                                    [config]="image.photoConfig"
                                    (cardEvent)="doPhotoCardAction($event)">
                                </app-photo-card>
                            </div>
                        </div>
                    </div>
                </div>

                <mat-paginator [length]="images.length"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="setPageSize($event)"
                    fxFlexAlign="start">
                </mat-paginator>
            </ng-container>
        </mat-card-content>

        <dots-loader [hidden]="!loading" fxFill fxFlexAlign="center"></dots-loader>
    </mat-card>
</div>

