import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BranchHttpService } from '../../../core/services/http/branch-http/branch-http.service';
import { CrewHttpService } from '../../../core/services/http/crew-http/crew-http.service';
import { GeofenceHttpService } from '../../../core/services/http/geofence-http/geofence-http.service';
import Branch from '../../../core/data-models/branch';
import Crew from '../../../core/data-models/crew';
import GeofenceEvent from '../../../core/data-models/geofence-event';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import { TranslateService } from '@ngx-translate/core';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
import GeofenceReportQuery from '../../../core/data-models/data-report/geofence-report-query';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import ActionProgressReporterOption from '../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import CodeDescription from '../../../core/data-models/code-description';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';

@Component({
    selector: 'geofence-reporting-settings',
    styleUrls: ['./geofence-reporting-settings.component.scss'],
    templateUrl: './geofence-reporting-settings.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: POPUP_CONTAINER,
        useFactory: () => {
            return { nativeElement: document.querySelector('.report-grid-div kendo-grid') } as ElementRef;
        }
    },
    PopupService
    ]
})
export class GeofenceReportingSettingsComponent implements OnInit {
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    public filteredProjectNamesAndIds: string[];
    public geofenceEvents: GeofenceEvent[] = [];
    public startDate = new Date();
    public endDate = new Date();
    public getGeofenceReportQuery: GeofenceReportQuery;
    public projectId = '';
    private _branches: Branch[];
    private _crews: Crew[] = [];
    private _projectNamesAndIds: string[];
    private _isLoaded = false;

    // eslint-disable-next-line max-params
    constructor(
        private _branchHttpService: BranchHttpService,
        private _projectHttpService: ProjectHttpService,
        private _crewHttpService: CrewHttpService,
        private _geofenceHttpService: GeofenceHttpService,
        private translate: TranslateService,
        private _dialogService: MatDialog
    ) {
        this.allData = this.allData.bind(this);
    }

    get branches(): Branch[] {
        return this._branches;
    }

    get crews(): Crew[] {
        return this._crews;
    }

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    public async ngOnInit(): Promise<void> {
        this._branches = await this._branchHttpService.getAllBranches();
        this._projectNamesAndIds = await this._projectHttpService.getAllProjectIdsAndNames();

        this.getGeofenceReportQuery = new GeofenceReportQuery();
        this.getGeofenceReportQuery.startDate = this.startDate.toISOString().substring(0, 10);
        this.getGeofenceReportQuery.endDate = this.endDate.toISOString().substring(0, 10);
        this.getGeofenceReportQuery.type = 'all';
        this._isLoaded = true;
    }

    public async getGeofenceEvents(): Promise<void> {
        const action = this.getGeofenceEventsInternal();
        const dialogData = new ActionProgressReporterOption(action);
        dialogData.showSuccess = false;
        dialogData.waitTime = 0.1;
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        await dialog.afterClosed().toPromise();
    }

    public filterProjectNamesAndIds(): void {
        if (!this.projectId || this.projectId.length < 3) {
            this.filteredProjectNamesAndIds = [];
            return;
        }

        const query = this.projectId.toLowerCase();
        this.filteredProjectNamesAndIds = this._projectNamesAndIds.filter(s => s.toLowerCase().includes(query));
    }

    public async reloadCrews(event: MatSelectChange): Promise<void> {
        if (event.value) {
            const branch = {
                name: event.value
            } as Branch;
            this._crews = await this._crewHttpService.getCrewsByBranch(branch);
        }
        else {
            this._crews = [];
        }
    }

    public resetQuery(event: MatSelectChange): void {
        if (event.value === 'Branch') {
            this.getGeofenceReportQuery.projectId = '';
            this.projectId = '';
        }
        else if (event.value === 'Project') {
            this.getGeofenceReportQuery.branch = '';
            this.getGeofenceReportQuery.employeeId = '';
        }
        else {
            this.getGeofenceReportQuery.projectId = '';
            this.getGeofenceReportQuery.branch = '';
            this.getGeofenceReportQuery.employeeId = '';
            this.projectId = '';
        }
    }

    public allData(): ExcelExportData {
        const result: ExcelExportData = {
            data: process(this.geofenceEvents, { }).data
        };

        return result;
    }

    private async getGeofenceEventsInternal(): Promise<CodeDescription> {
        if (this.startDate > this.endDate) {
            const message = this.translate.instant('dataReport.geofenceReport.startDateError');
            return new CodeDescription('400', message);
        }

        this.getGeofenceReportQuery.startDate = this.startDate.toISOString().slice(0, 10);
        this.getGeofenceReportQuery.endDate = this.endDate.toISOString().slice(0, 10);

        if (this.projectId) {
            this.getGeofenceReportQuery.projectId = this.projectId.includes(':') ?
                this.projectId.split(':')[0] :
                this.projectId;

            if (!this._projectNamesAndIds.some(_ => _.split(':')[0] === this.getGeofenceReportQuery.projectId)) {
                const message = this.translate.instant('dataReport.geofenceReport.validProjectIdError');
                return new CodeDescription('400', message);
            }
        }

        this.dataBinding.skip = 0;
        this.geofenceEvents = await this._geofenceHttpService.getGeofenceEvents(this.getGeofenceReportQuery);

        return new CodeDescription('200', '');
    }
}
