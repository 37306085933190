import { Component, OnInit } from '@angular/core';

import DependencyAttribution from '../../../core/data-models/dependency-attribution';
import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';

@Component({
    selector: 'attributions',
    styleUrls: ['./attributions.component.scss'],
    templateUrl: './attributions.component.html'
})
export class AttributionsComponent implements OnInit {
    public attributions: DependencyAttribution[] = [];

    constructor(private _appConfigHttp: AppConfigHttpService) { }

    public async ngOnInit(): Promise<void> {
        this.attributions = await this._appConfigHttp.getDependencyAttributions();
    }
}
