import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneDisplayFormat'
})
export class PhoneDisplayFormatPipe implements PipeTransform {

    public transform(phone: string): string {
        let digits = (phone ? phone : '').replace(/\D/g, '');
        if (digits[0] === '0' || digits[0] === '1') {
            digits = digits.substr(1);
        }
        const formatted = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
        const extension = digits.length > 10 ? `, ${digits.slice(10)}` : '';

        return formatted.replace(/\D+$/, '') + extension;
    }
}
