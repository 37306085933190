import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import {
    animate,
    keyframes,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import PhotoReportNotification from '../../../core/data-models/photo-report-notification';
import { DownloadHttpService } from '../../../core/services/http/download-http/download-http.service';
import ProjectAttachment from '../../../core/data-models/project-attachment';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PdfFileViewerDialogComponent } from '../dialogs/pdf-file-viewer-dialog/pdf-file-viewer-dialog.component';
import { ReportHttpService } from '../../../core/services/http/report-http/report-http.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: '[photo-report-notification]',
    templateUrl: './photo-report-notification.component.html',
    styleUrls: ['./photo-report-notification.component.scss'],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                opacity: 0
            })),
            transition('inactive => active', animate('400ms ease-out', keyframes([
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
                    opacity: 0
                }),
                style({
                    transform: 'skewX(20deg)',
                    opacity: 1
                }),
                style({
                    transform: 'skewX(-5deg)',
                    opacity: 1
                }),
                style({
                    transform: 'none',
                    opacity: 1
                })
            ]))),
            transition('active => removed', animate('400ms ease-out', keyframes([
                style({
                    opacity: 1
                }),
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(30deg)',
                    opacity: 0
                })
            ])))
        ])
    ]
})
export class PhotoReportNotificationComponent extends Toast {

    public notification: PhotoReportNotification;

    // eslint-disable-next-line
    constructor(
    public toastPackage: ToastPackage,
    protected toastrService: ToastrService,
    private downloadService: DownloadHttpService,
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private _reportService: ReportHttpService,
    private _dialog: MatDialog,
    ) {
        super(toastrService, toastPackage);
    }

    ngOnInit(): void {
        this.notification = this.toastPackage.config.payload;
    }

    public async downloadFile(event: Event) {
        event.stopPropagation();
        await this.getAndDownloadFile(this.notification.projectId, this.notification.attachment);
        this.toastPackage.triggerAction();
        return false;
    }

    public async viewReport(event: Event) {
        event.stopPropagation();
        const reports = await this._reportService.photoReportLists(this.notification.projectId);
        this._dialog.open(PdfFileViewerDialogComponent, {
            data: reports,
            height: '90vh',
            width: '75vw'
        });
        this.toastPackage.triggerAction();
        return false;
    }

    public async getAndDownloadFile(projectId: string, attachment: ProjectAttachment) {
        const signedUrl = await this.downloadService.getSignedUrl(projectId, attachment.name, attachment.type);
        let downloaded = false;
        if (signedUrl) {
            downloaded = await this.downloadService.download(signedUrl, attachment.name, attachment.type);
        }
        if (!downloaded) {
            const failedToUpload = this.translate.instant('projects.attachments.failedToDownload');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(`${failedToUpload} ${attachment.name}`, close);
        }
    }
}
