<dialog-action-card [icon]="'business_center'" [title]="translate.instant('shared.customerManagement.title')" [isLoaded]="true" (close)="onClose()">
    <div fxLayout="column" class="customer-management-content" fxFill>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5%" *ngIf="!selectExistingCustomerOption && !createCustomerOption" fxFill>
            <button mat-stroked-button color="primary" (click)="selectAnExistingCustomer()">
                {{"shared.customerManagement.selectExistingCustomer" | translate }}
                <mat-icon>search</mat-icon>
            </button>
            <span fxFlex="5%" fxLayoutAlign="center center"><strong>{{ 'shared.customerManagement.orConjunction' | translate }}</strong></span>
            <button mat-stroked-button color="primary" (click)="createCustomer()">
                {{"shared.customerManagement.createCustomer" | translate }}
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <customer-create-management *ngIf="createCustomerOption" (newCustomerEvent)="createNewCustomer($event)" fxFill>
        </customer-create-management>

        <customer-select-management *ngIf="selectExistingCustomerOption"
            [customerForSuggestions]="customerForSuggestions"
            (customerSelectComplete)="createNewCustomer($event)" fxFill>
        </customer-select-management>
    </div>
</dialog-action-card>
