<div fxLayout="column" fxLayoutGap="10px" *ngIf="template" fxFill>
    <div fxLayout="row wrap" fxLayoutGap="10px">
        <button mat-stroked-button color="primary" (click)="addTodayPlaceholder()">
            <mat-icon>calendar_today</mat-icon>
            {{ 'templateEditor.placeholderList.today' | translate}}
        </button>
        <button mat-stroked-button color="primary" (click)="addJobDatePlaceholder()">
            <mat-icon>edit_calendar</mat-icon>
            {{ 'templateEditor.placeholderList.jobDate' | translate}}
        </button>
        <button mat-stroked-button color="primary" (click)="addProjectIdPlaceholder()">
            <mat-icon>numbers</mat-icon>
            {{ 'templateEditor.placeholderList.projectId' | translate}}
        </button>
        <button mat-stroked-button color="primary" (click)="addProjectAddressPlaceholder()">
            <mat-icon>place</mat-icon>
            {{ 'templateEditor.placeholderList.projectAddress' | translate}}
        </button>
        <button mat-stroked-button color="primary" (click)="addFieldReportPlaceholder()">
            <mat-icon>format_align_left</mat-icon>
            {{ 'templateEditor.placeholderList.fieldReportContent' | translate}}
        </button>
        <button mat-stroked-button color="primary" (click)="addRoofSectionsPlaceholder()">
            <mat-icon>format_list_bulleted</mat-icon>
            {{ 'templateEditor.placeholderList.roofSections' | translate}}
        </button>
        <button mat-stroked-button color="primary" (click)="addTechNamePlaceholder()">
            <mat-icon>person</mat-icon>
            {{ 'templateEditor.placeholderList.techName' | translate}}
        </button>
        <button mat-stroked-button color="primary" (click)="addClientBuildingIdPlaceholder()">
            <mat-icon>apartment</mat-icon>
            {{ 'templateEditor.placeholderList.clientBuildingId' | translate}}
        </button>
        <button mat-stroked-button color="primary" (click)="addTextPlaceholder()">
            <mat-icon>text_fields</mat-icon>
            {{ 'templateEditor.placeholderList.text' | translate}}
        </button>
        <button mat-stroked-button color="primary" (click)="addImagePlaceholder()">
            <mat-icon>image</mat-icon>
            {{ 'templateEditor.placeholderList.image' | translate}}
        </button>
    </div>
    <div fxLayout="column" fxLayoutGap="10px" fxFlex class="placeholder-list-content">
        <div fxLayout="column" fxFill fxLayoutGap="10px" class="main-placeholder-content">
            <div fxLayout="row" *ngFor="let placeholder of template.placeholders" fxLayoutAlign="space-between"
                fxLayoutGap="10px" class="placeholder-content-row">
                <div fxLayout="row" fxLayoutGap="10px" fxFlex>
                    <mat-icon>{{getPlaceholderIcon(placeholder.identifier)}}</mat-icon>
                    <grid-display-item [content]="placeholder.name" [editOptions]="getEditOptions(placeholder)"
                        (contentUpdate)="updatePlaceholder(placeholder, $event)" fxFlex="65">
                    </grid-display-item>
                </div>
                <button mat-stroked-button color="warn" (click)="deletePlaceholder(placeholder)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
