<dialog-action-card class="job-material-edit-container"
    [icon]="'create'"
    [title]="title"
    [isLoaded]="isLoaded"
    (close)="onClose()">

    <div *ngIf="formGroup" [formGroup]="formGroup">
        <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field>
                    <mat-label>{{ 'shared.name' | translate }}</mat-label>
                    <input type="text" matInput cdkFocusInitial formControlName="name" />

                    <mat-error>
                        * {{ 'shared.requiredField' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field>
                    <mat-label>{{'dataReport.materialsReport.unitPrices' | translate}}</mat-label>
                    <input type="number" matInput formControlName="value" />
                    <mat-error>{{ currencyValidator.message }}</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field>
                    <mat-label>{{'dataReport.materialsReport.unit' | translate}}</mat-label>
                    <input type="text" matInput formControlName="unit"/>
                    <mat-error>
                        * {{ 'shared.requiredField' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field>
                    <mat-label>{{'dataReport.materialsReport.jobCodes' | translate}}</mat-label>
                    <mat-chip-list #chipList>
                        <mat-chip *ngFor="let jobCode of currentJobCodes"
                            [selectable]="true"
                            [removable]="true"
                            (removed)="onJobCodeRemoved(jobCode)">
                            {{jobCode.name}}({{jobCode.code}})
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>

                        <input matInput
                            placeholder="{{'dataReport.materialsReport.newJobCode' | translate}}"
                            formControlName="jobCode"
                            [matChipInputFor]="chipList"
                            [matAutocomplete]="auto"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="onChipTokenAdded($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onJobCodeSelected($event)">
                        <mat-option *ngFor="let jobCode of filteredRemainingJobCodes" [value]="jobCode">
                          {{jobCode.name}}({{jobCode.code}})
                        </mat-option>
                      </mat-autocomplete>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field>
                    <mat-label>{{'dataReport.materialsReport.note' | translate}}</mat-label>
                    <textarea matInput formControlName="note"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="job-material-edit-actions" fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button color="primary" (click)="onSave()" [disabled]="saveDisabled">{{ 'shared.save' | translate }}</button>
        </div>
    </div>
</dialog-action-card>
