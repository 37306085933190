export default class NavigationTab {
    public name = '';
    public icon? = '';
    public type? = '';
    public state = '';
    public isWarning? = false;
    public externalUrl? = '';
    public badge? = '';
    public displayName? = '';
    public isDisabled?: boolean = false;
    public disabledTooltip?: string = '';

    // eslint-disable-next-line max-params
    constructor(name: string, icon = '', type = 'material', state = '', displayName = '', isDisabled = false, disabledTooltip = '') {
        this.name = name;
        this.icon = icon;
        this.type = type;
        this.state = state;
        this.displayName = displayName;
        this.isDisabled = isDisabled;
        this.disabledTooltip = disabledTooltip;
    }
}
