import { Injectable } from '@angular/core';

import { UserHttpService } from '../http/user-http/user-http.service';
import { QuoteStatusHttpService } from '../http/quote-status-http/quote-status-http.service';
import { LanguageKey } from '../../enums/language-key.enum';
import LanguageUtility from '../utility/language-utility/language-utility.service';

@Injectable({ providedIn: 'root' })
export class QuoteStatusService {

    constructor(private _userHttpService: UserHttpService, private _quoteStatusHttpService: QuoteStatusHttpService) { }

    public async getStatusTranslationByUserLanguage(englishName: string): Promise<string> {
        const { language } = await this._userHttpService.getUserInfo();
        const statuses = await this._quoteStatusHttpService.getAllStatuses();
        const status = statuses.find(_ => _.names.some(name => name.languageKey === LanguageKey.EN && name.content === englishName));
        const translation = LanguageUtility.getLocalizedContent(language, status?.names ?? []);

        return translation ? translation : englishName;
    }
}
