import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-questions-section',
    styleUrls: ['./questions-section.component.scss'],
    templateUrl: './questions-section.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionsSectionComponent implements OnInit {
    @Input() public form: UntypedFormGroup;
    @Input() public readonly = false;

    get questions(): UntypedFormArray {
        return this.form.get('questionsSection.questions') as UntypedFormArray;
    }

    constructor(private _changeDetectorRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.form.valueChanges.subscribe(() => this._changeDetectorRef.markForCheck());
    }
}
