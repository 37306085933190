import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import ProjectUsEstimateForm from '../../../core/data-models/project-attributes/project-us-estimate-form';
import { ProjectHttpService } from '../../../core/services/http/project-http/project-http.service';
import CodeDesc from '../../../core/data-models/generic/code-description';
import ProjectUSQuestionsValidCheck from '../../../core/data-models/project-us-questions-valid-check';
import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';
import { TranslateService } from '@ngx-translate/core';
import ProjectQuestionCheckbox from '../../../core/data-models/project-questions/project-question-checkbox';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

@Component({
    selector: 'project-us-questions-card',
    styleUrls: ['./project-us-questions-card.component.scss'],
    templateUrl: './project-us-questions-card.component.html'
})
export class ProjectUSQuestionsCardComponent implements OnInit {
    @Input() public usEstimateForm: ProjectUsEstimateForm;
    @Input() public readOnly = true;
    @Output() public result = new EventEmitter<ProjectUSQuestionsValidCheck>();
    public wageCalculationMethods: CodeDesc[] = [];
    public calculationMethod = new UntypedFormControl(null, Validators.required);
    public checkboxes: ProjectQuestionCheckbox[];
    private extraCheckBoxes: ProjectQuestionCheckbox[];

    get checkBoxesLayout(): string {
        return this.readOnly? 'row wrap' : 'column';
    }

    get checkBoxesLayoutAlign(): string {
        return this.readOnly? 'start center' : 'center start';
    }

    constructor(
        private _projectService: ProjectHttpService,
        private _appConfigService: AppConfigHttpService,
        public translate: TranslateService
    ) { }

    public async ngOnInit(): Promise<void> {
        this.checkboxes = [
            {
                model: 'apprenticeParticipation',
                label: this.translate.instant('shared.usQuestions.apprenticeParticipationLabel'),
                toolTip: this.translate.instant('shared.usQuestions.apprenticeParticipationTooltip')
            },
            {
                model: 'certifiedPayroll',
                label: this.translate.instant('shared.usQuestions.certifiedPayrollLabel'),
                toolTip: this.translate.instant('shared.usQuestions.certifiedPayrollTooltip')
            },
            {
                model: 'davisBacon',
                label: this.translate.instant('shared.usQuestions.davisBaconLabel'),
                toolTip: this.translate.instant('shared.usQuestions.davisBaconTooltip')
            },
            {
                model: 'prevailingWage',
                label: this.translate.instant('shared.usQuestions.prevailingWageLabel'),
                toolTip: this.translate.instant('shared.usQuestions.prevailingWageTooltip')
            },
            {
                model: 'generalLiabilityExcempt',
                label: this.translate.instant('shared.usQuestions.generalLiabilityExcemptLabel'),
                toolTip: this.translate.instant('shared.usQuestions.generalLiabilityExcemptTooltip')
            },
            {
                model: 'minorityParticipation',
                label: this.translate.instant('shared.usQuestions.minorityParticipationLabel'),
                toolTip: this.translate.instant('shared.usQuestions.minorityParticipationTooltip')
            },
            {
                model: 'ptoFederalJobs',
                label: this.translate.instant('shared.usQuestions.ptoFederalJobsLabel'),
                toolTip: this.translate.instant('shared.usQuestions.ptoFederalJobsTooltip')
            },
            {
                model: 'workersCompExcempt',
                label: this.translate.instant('shared.usQuestions.workersCompExcemptLabel'),
                toolTip: this.translate.instant('shared.usQuestions.workersCompExcemptTooltip')
            }
        ];

        this.extraCheckBoxes = [
            {
                model: 'nationalMaintenanceAgreement',
                label: this.translate.instant('shared.usQuestions.nationalMaintenanceAgreementLabel'),
                toolTip: this.translate.instant('shared.usQuestions.nationalMaintenanceAgreementTooltip')
            },
            {
                model: 'hazardousWastePremium',
                label: this.translate.instant('shared.usQuestions.hazardousWastePremiumLabel'),
                toolTip: this.translate.instant('shared.usQuestions.hazardousWastePremiumTooltip')
            }
        ];

        if (await this._appConfigService.getFeatureFlag('ProjectAttributesFSW4498')) {
            this.checkboxes = [...this.checkboxes, ...this.extraCheckBoxes];
        }

        if (!this.readOnly) {
            const data = await this._projectService.getProjectAttributesDropdowns('US');
            this.wageCalculationMethods = data.previousWageCalculationMethod;

            if (this.usEstimateForm.prevWgCalcMthd) {
                this.calculationMethod.setValue(this.usEstimateForm.prevWgCalcMthd.codeValue);
            }

            this.checkAndSendResult();
        }
    }

    public checkAndSendResult(): void {
        this.calculationMethod.markAsTouched();
        if (!this.usEstimateForm.prevWgCalcMthd && (this.usEstimateForm.davisBacon || this.usEstimateForm.prevailingWage)) {
            this.result.emit(new ProjectUSQuestionsValidCheck(false, this.usEstimateForm));
            return;
        }

        if (!this.usEstimateForm.davisBacon && !this.usEstimateForm.prevailingWage) {
            this.usEstimateForm.prevWgCalcMthd = null;
            this.calculationMethod.setValue(null);
        }

        this.result.emit(new ProjectUSQuestionsValidCheck(true, this.usEstimateForm));
    }

    public updateCalculationMethod(ev: MatSelectChange): void {
        this.usEstimateForm.prevWgCalcMthd = this.wageCalculationMethods.find(_ => _.codeValue === ev.value);
        this.checkAndSendResult();
    }
}
