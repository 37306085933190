<div class="merge-rule-list-viewer-container">
    <div class="mat-elevation-z8 merge-rule-list-wrapper">
        <table mat-table [dataSource]="rules">
            <ng-container matColumnDef="name">
                <th class="rule-name" mat-header-cell *matHeaderCellDef>
                    <span>{{ 'admin.projectMergeRules.mergeRuleListViewer.rulesHeaderText' | translate }}</span>
                    <span *ngIf="rules.length">&nbsp;({{ rules.length }})</span>
                </th>

                <td class="rule-name" mat-cell *matCellDef="let rule">
                    <span>{{ getRuleName(rule) }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="maintainers">
                <th class="maintainer-avatars" mat-header-cell *matHeaderCellDef>
                    <span>{{ 'admin.projectMergeRules.mergeRuleListViewer.maintainersHeaderText' | translate }}</span>
                </th>

                <td class="maintainer-avatars" mat-cell *matCellDef="let rule">
                    <user-avatars-card [users]="getMaintainers(rule)"></user-avatars-card>
                </td>
            </ng-container>

            <ng-container matColumnDef="summary">
                <th class="summary-icons" mat-header-cell *matHeaderCellDef></th>

                <td class="summary-icons" mat-cell *matCellDef="let rule">
                    <div *ngIf="rule.mappings.length" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
                        <mat-icon>merge_type</mat-icon>
                        <span>{{ rule.mappings.length }}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="lastModified">
                <th class="last-modified" mat-header-cell *matHeaderCellDef>
                    <span>{{ 'admin.projectMergeRules.mergeRuleListViewer.lastModifiedHeaderText' | translate }}</span>
                </th>

                <td class="last-modified" mat-cell *matCellDef="let rule">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>{{ rule.timeInfo.lastModified | branchLocalTime: user.branch : branches }}</span>

                        <button class="rule-actions-menu"
                            mat-icon-button
                            [matMenuTriggerFor]="ruleActions"
                            (click)="$event.stopPropagation()">

                            <mat-icon>more_vert</mat-icon>
                        </button>

                        <mat-menu #ruleActions="matMenu">
                            <button mat-menu-item (click)="deleteRule.emit(rule)">
                                {{ 'admin.projectMergeRules.mergeRuleListViewer.deleteRuleButtonText' | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: visibleColumns;" (click)="openRule.emit(row)"></tr>
        </table>
    </div>
    <!-- cover the scrollbar on table header row -->
    <div class="scrollbar-cover"></div>

    <button class="add-rule" mat-mini-fab color="primary" (click)="addRule.emit()">
        <mat-icon>add</mat-icon>
    </button>
</div>
