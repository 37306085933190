import { Pipe, PipeTransform } from '@angular/core';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';

import CustomerUtility from '../../../core/services/utility/customer-utility/customer-utility.service';

@Pipe({
    name: 'customerIdPlaceholder',
    pure: false
})
export class CustomerIdPlaceholderPipe implements PipeTransform {
    constructor(private _featureFlagService: FeatureFlagService) { }

    public transform(id: string, placeholder = 'Pending'): string {

        const isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];

        if (!id) {
            return '';
        }

        if (isInternal) {
            return CustomerUtility.isConfirmedCustomer(id) ? id : placeholder;
        }

        return id.startsWith('new') ? placeholder : id;
    }
}
