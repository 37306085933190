<div fxLayout="column" *ngIf="selectedPlan" class="maintenance-plan-container" fxLayoutGap="20px">
    <div fxLayout="row">
        <div fxFlex="8"><label>{{ 'shared.conditionReportOptionsDialog.section' | translate }}</label></div>
        <div fxFlex="7"><label>{{ 'projects.executiveSummary.roofArea' | translate }}</label></div>
        <div *ngFor="let year of yearLabels" fxFlex="17" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <label fxFlex="95">{{year}} {{ 'projects.executiveSummary.budget' | translate }}</label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <div fxFlex="54">{{ 'shared.conditionReportOptionsDialog.immediateRepairs' | translate }}</div>
                <div fxFlex="37">{{ 'shared.conditionReportOptionsDialog.maintenance' | translate }}</div>
            </div>
        </div>
    </div>
    <div fxLayout="row" *ngFor="let section of selectedPlan.sections">
        <div fxFlex="8"><label>{{section.title}}</label></div>
        <mat-form-field appearance="outline" fxFlex="7">
            <input matInput type="number" [(ngModel)]="section.area" min="0" [disabled]="isLoading">
        </mat-form-field>

        <div *ngFor="let budgetLine of section.budget" fxFlex="17" fxLayout="row" fxLayoutAlign="space-around center">
            <mat-form-field appearance="outline" fxFlex="54">
                <input matInput type="number" [(ngModel)]="budgetLine.immediate" min="0" [disabled]="isLoading">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="37">
                <input matInput type="number" [(ngModel)]="budgetLine.maintenance" min="0" [disabled]="isLoading">
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="row" *ngIf="displayYearWarning">
        <div class="year-warning" fxFlex="100">{{ 'projects.executiveSummary.yearWarning' | translate }}</div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayoutAlign="end end">
        <button mat-raised-button color="primary" (click)="save()" [disabled]="isLoading">{{ 'projects.executiveSummary.addNewPlan' | translate }}</button>
    </div>
</div>