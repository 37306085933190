<div class="grid-display-item-container" fxLayout="column" fxLayoutAlign="start">
    <div class="grid-display-item-title-bar"
        [ngStyle]="{ height: title ? '20px': '0' }"
        fxLayout="row"
        fxLayoutAlign="start center">

        <div [ngClass]="errorMessageClass" *ngIf="error">* {{ error }}</div>

        <ng-container *ngIf="title">
            <span class="grid-display-item-title">{{ title }}</span>

            <grid-item-actions *ngIf="!isEditModeOn && useTextarea"
                [content]="displayContent | textWithPlaceholder"
                [canEnableEdit]="canEnableEdit"
                (editEnabled)="enableContentEdit()">
            </grid-item-actions>
        </ng-container>
    </div>

    <div class="grid-display-item-content"
        [ngStyle]="{ height: title ? 'calc(100% - 20px)': '100%' }"
        fxLayout="row"
        fxLayoutAlign="start center">

        <input *ngIf="!useTextarea && getPhoneMaskConfig(editOptions?.inputMask)" type="text"
            class="grid-edit-box"
            #editBox
            [ngClass]="inputBoxClasses"
            [disabled]="!isEditModeOn"
            [(ngModel)]="editedContent"
            [maxlength]="editOptions?.maxLength || 60"
            [placeholder]="editOptions?.placeholder || ''"
            (ngModelChange)="contentEdit.emit($event)"
            (blur)="onContentUpdate()"
            (keyup.esc)="onReset()"
            (keyup.enter)="onContentUpdate()"
            [isFax]="getFaxMask(editOptions?.inputMask)" 
            appPhoneMask/>

        <input *ngIf="!useTextarea && !getPhoneMaskConfig(editOptions?.inputMask)" type="text"
            class="grid-edit-box"
            #editBox
            [ngClass]="inputBoxClasses"
            [disabled]="!isEditModeOn"
            [(ngModel)]="editedContent"
            [maxlength]="editOptions?.maxLength || 60"
            [placeholder]="editOptions?.placeholder || ''"
            (ngModelChange)="contentEdit.emit($event)"
            (blur)="onContentUpdate()"
            (keyup.esc)="onReset()"
            (keyup.enter)="onContentUpdate()"
            [mask]="editOptions?.inputMask" />

        <textarea *ngIf="useTextarea"
            class="grid-edit-box"
            #editBox
            [ngClass]="inputBoxClasses"
            [disabled]="!isEditModeOn"
            [(ngModel)]="editedContent"
            [placeholder]="editOptions?.placeholder || ''"
            (ngModelChange)="contentEdit.emit($event)"
            (keyup.esc)="onReset()"
            (blur)="onContentUpdate()">
        </textarea>

        <div *ngIf="!isHtml"
            class="grid-display-box"
            #displayBox
            [ngClass]="displayBoxClasses"
            [matTooltip]="tooltip"
            matTooltipPosition="above"
            (click)="enableContentEdit()">

            {{ displayContent | textWithPlaceholder }}
        </div>

        <div *ngIf="isHtml"
            class="grid-display-box"
            #displayBox
            [innerHTML]="displayContent | textWithPlaceholder"
            [ngClass]="displayBoxClasses"
            [matTooltip]="tooltip"
            matTooltipPosition="above"
            (click)="enableContentEdit()">
        </div>

        <grid-item-actions *ngIf="!isEditModeOn && !useTextarea"
            [content]="displayContent | textWithPlaceholder"
            [canEnableEdit]="canEnableEdit"
            (editEnabled)="enableContentEdit()">
        </grid-item-actions>
    </div>
</div>
