<div class="on-call-add-dialog-container" fxLayout="column" fxLayoutAlign="start stretch" fxFill>
    <div class="title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="25">
        <div fxLayoutAlign="center center">
            <mat-icon>add_circle_outline</mat-icon>
        </div>
        <div>{{ 'onCall.add.addOnCallCrew' | translate }}</div>
        <div fxFlex></div>
        <button class="close-button" mat-mini-fab mat-dialog-close>
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <div class="content" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxFlex>
            <mat-form-field>
                <mat-label>{{ 'onCall.add.startDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" (click)="startDate.open()" readonly [(ngModel)]="start">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'onCall.add.endDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" (click)="endDate.open()" readonly [(ngModel)]="end">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ 'onCall.add.availableTechs' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedCrew">
                    <mat-option *ngFor="let crew of data.crewList" [value]="crew">{{crew.crewName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxFlex="25">
            <button class="add-button" mat-raised-button color="primary" (click)="add()">{{ 'onCall.add.addCrew' | translate }}</button>
        </div>
    </div>
</div>
