import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import UserInfo from '../../../core/data-models/user-info';
import UserRole from '../../../core/data-models/user-role';
import UserRoleDto from '../../../core/data-models/dto-models/user-role-dto';
import CodeDescription from '../../../core/data-models/code-description';
import Branch from '../../../core/data-models/branch';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import UserRoleOption from '../../../core/data-models/user-role-option';
import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';
import { ProjectStatusHttpService } from '../../../core/services/http/project-status-http/project-status-http.service';
import { ProjectMergeHttpService } from '../../../core/services/http/project-merge-http/project-merge-http.service';
import { BranchHttpService } from '../../../core/services/http/branch-http/branch-http.service';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { SubscriptionHttpService } from '../../../core/services/http/subscription-http/subscription-http.service';
import LanguageUtility from '../../../core/services/utility/language-utility/language-utility.service';
import { NamePromptDialogComponent } from '../../../shared/components/dialogs/name-prompt-dialog/name-prompt-dialog.component';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';

@Component({
    selector: 'role-permission',
    styleUrls: ['./role-permission.component.scss'],
    templateUrl: './role-permission.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolePermissionComponent implements OnInit {
    public user: UserInfo;
    public roles: UserRoleDto[] = [];
    public branches: Branch[] = [];
    public permissionParameterOptions: { [key: string]: CodeDescription[] } = {};
    public roleEditorOption: UserRoleOption | null = null;
    public savingState = { isSaving: false, isSaved: true };
    private loadingRole = false;

    // eslint-disable-next-line max-params
    constructor(private _appConfigHttp: AppConfigHttpService,
                private _projectStatusHttp: ProjectStatusHttpService,
                private _projectMergeHttp: ProjectMergeHttpService,
                private _branchHttp: BranchHttpService,
                private _userHttp: UserHttpService,
                private _subscriptionHttp: SubscriptionHttpService,
                private _translate: TranslateService,
                private _changeDetectorRef: ChangeDetectorRef,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialog) { }

    public async ngOnInit(): Promise<void> {
        this.user = await this._userHttp.getUserInfo();
        await this.refreshRoles();
        await this.loadPermissionParameterOptions();
        this._changeDetectorRef.markForCheck();
    }

    public async openNewRolePanel(): Promise<void> {
        const [width, height] = ['400px', '270px'];
        const data = this._translate.instant('admin.rolesAndPermissions.roleCreationPanelTitle');
        const dialog = this._dialog.open(NamePromptDialogComponent, { data, width, height });
        const name = await dialog.afterClosed().toPromise();
        const buttonText = this._translate.instant('snackbars.ok');

        if (!name) {
            return;
        }

        if (this.roles.some(_ => _.name.toLowerCase() === name.toLowerCase())) {
            const key = 'admin.rolesAndPermissions.rolesView.duplicateRoleNameErrorText';
            this._snackBar.open(this._translate.instant(key), buttonText);

            return;
        }

        if (!await this._userHttp.addUserRole(name)) {
            const key = 'admin.rolesAndPermissions.rolesView.failedToAddRoleErrorText';
            this._snackBar.open(this._translate.instant(key), buttonText);
        }
        else {
            await this.refreshRoles();
        }

        this._changeDetectorRef.markForCheck();
    }

    public async openRole(name: string): Promise<void> {
        if (this.loadingRole) {
            return;
        }

        this.loadingRole = true;
        const permissionTemplates = await this._userHttp.getUserPermissionTemplates();
        const subscriptionTemplates = await this._subscriptionHttp.getSubscriptionTopics();
        const mergeRules = await this._projectMergeHttp.getMergeRules();
        const role = await this._userHttp.getUserRoleDetails(name);
        this.roleEditorOption = { role, permissionTemplates, subscriptionTemplates, mergeRules };
        this._changeDetectorRef.markForCheck();
        this.loadingRole = false;
    }

    public async closeRole(): Promise<void> {
        this.roles = [];
        await this.refreshRoles();
        this.savingState = { isSaving: false, isSaved: true };
        this._changeDetectorRef.markForCheck();
    }

    public async updateRole(role: UserRole): Promise<void> {
        const isSaved = await this._userHttp.updateUserRolePrivileges(role);

        if (!isSaved) {
            const key = 'admin.rolesAndPermissions.rolesView.failedToUpdatePrivilegesErrorText';
            const buttonText = this._translate.instant('snackbars.ok');
            this._snackBar.open(this._translate.instant(key), buttonText);
        }

        this.savingState = { isSaving: false, isSaved };
        this._changeDetectorRef.markForCheck();
    }

    public async deleteRole(role: UserRoleDto): Promise<void> {
        const keyPrefix = 'admin.rolesAndPermissions.rolesView.deleteRoleConfirmation';
        const title = this._translate.instant(`${keyPrefix}.title`);
        const message = this._translate.instant(`${keyPrefix}.message`);
        const confirmText = this._translate.instant(`${keyPrefix}.confirmButtonText`);
        const cancelText = this._translate.instant('shared.cancel');
        const data = new ConfirmDialogOption(title, message, true, confirmText, cancelText);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (!await dialog.afterClosed().toPromise()) {
            return;
        }

        if (!await this._userHttp.deleteUserRole(role.name)) {
            const key = 'admin.rolesAndPermissions.rolesView.failedToDeleteRoleErrorText';
            const buttonText = this._translate.instant('snackbars.ok');
            this._snackBar.open(this._translate.instant(key), buttonText);
        }
        else {
            await this.refreshRoles();
        }

        this._changeDetectorRef.markForCheck();
    }

    private async loadPermissionParameterOptions(): Promise<void> {
        this.branches = await this._branchHttp.getAllBranches();
        const statuses = await this._projectStatusHttp.getFilterableProjectStatus();
        const projectType = await this._appConfigHttp.getSalesCodeConfig();

        const allProjectStatuses = statuses.map(_ => {
            const text = LanguageUtility.getLocalizedContent(this.user.language, _.names);

            return { code: `${_.code}`, description: text };
        });

        this.permissionParameterOptions = {
            projectLocation: this.branches.map(_ => ({ code: _.code, description: _.name })),
            projectType: projectType.map(_ => ({ code: _.code, description: LanguageUtility.getLocalizedContent(this.user.language, _.names) })),
            projectStatus: allProjectStatuses.slice(),
            sourceProjectStatus: allProjectStatuses.slice(),
            targetProjectStatus: allProjectStatuses.slice()
        };

        this._changeDetectorRef.markForCheck();
    }

    private async refreshRoles(): Promise<void> {
        this.roleEditorOption = null;
        this.roles = await this._userHttp.getUserRoles();
        this.roles = this.roles?.filter(_ => !_.isHidden);
        this._changeDetectorRef.markForCheck();
    }
}
