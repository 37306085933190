import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import ReturnToSiteReasonDialogOption from '../../../../../core/data-models/return-to-site-reason-dialog-option';

@Component({
    selector: 'return-to-site-reason-dialog',
    styleUrls: ['./return-to-site-reason-dialog.component.scss'],
    templateUrl: './return-to-site-reason-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ReturnToSiteReasonDialogComponent implements OnInit {
    public reasonInput: UntypedFormControl;

    constructor(
        private _dialog: MatDialogRef<ReturnToSiteReasonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public option: ReturnToSiteReasonDialogOption,
        public translate: TranslateService) { }

    public ngOnInit(): void {
        this._dialog.disableClose = true;
        const validators = [Validators.required, Validators.pattern(/\w+/)];
        this.reasonInput = new UntypedFormControl(this.option.reason.comment, validators);
    }

    public onClose(emit = true): void {
        if (!emit) {
            this._dialog.close();
        }
        else if (this.reasonInput.valid) {
            this.option.reason.comment = this.reasonInput.value;
            this._dialog.close(this.option.reason);
        }
    }
}
