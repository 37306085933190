import DispatchBaseStepConfig from './dispatch-base-step-config';
import CustomerSelectionDto from '../customer-selection-dto';
import CustomerSiteDispatchInfo from './customer-site-dispatch-info';

export default class CustomerSelectionStepConfig extends DispatchBaseStepConfig {
    public customerData: CustomerSelectionDto;
    public customerDispatchInfo: CustomerSiteDispatchInfo;
    public customerPerSelected = false;
    public customerForSuggestions: CustomerSelectionDto;
}
