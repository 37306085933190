import { LanguageKey } from '../../enums/language-key.enum';
import Language from '../../data-models/generic/language';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export default class LanguageService {

    constructor(private translate: TranslateService) {
    }

    public getLanguages() : Language[] {
        return [
            { language: LanguageKey.EN, label: this.translate.instant('shared.language.englishLabel') },
            { language: LanguageKey.FR, label: this.translate.instant('shared.language.frenchLabel') }
        ];
    }
}
