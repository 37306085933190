import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import MobileScanFileGroup from '../../core/data-models/mobile-scan-file/mobile-scan-file-group';
import { MobileScanFileHttpService } from '../../core/services/http/mobile-scan-file-http/mobile-scan-file-http.service';
import { UserHttpService } from '../../core/services/http/user-http/user-http.service';
import UserInfo from '../../core/data-models/user-info';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DownloadHttpService } from '../../core/services/http/download-http/download-http.service';
import User from '../../core/data-models/user';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import Branch from '../../core/data-models/branch';
import { PdfFileViewerDialogComponent } from '../../shared/components/dialogs/pdf-file-viewer-dialog/pdf-file-viewer-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'receipt',
    styleUrls: ['./receipt.component.scss'],
    templateUrl: './receipt.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ReceiptComponent implements OnInit {
    @Input() public branches: Branch[];
    public expandedDetailKeys: string[] = [];
    public group: MobileScanFileGroup[];
    public currentUserBranch: string;
    public loading = false;
    private _currentUser: UserInfo;

    // eslint-disable-next-line max-params
    public constructor(
        private _mobileScanFileHttpService: MobileScanFileHttpService,
        private _userService: UserHttpService,
        private _snackbar: MatSnackBar,
        private _translate: TranslateService,
        private _downloadService: DownloadHttpService,
        private _dialog: MatDialog
    ) { }

    public async ngOnInit(): Promise<void> {
        this._currentUser = await this._userService.getUserInfo();
        this.currentUserBranch = this._currentUser.branchInfo.name;
        this.loadReceipts();
    }

    public async downloadAllForTech(user: User): Promise<void> {
        this.loading = true;
        const ok = this._translate.instant('snackbars.ok');
        const blob = await this._mobileScanFileHttpService.getBlobByLocationCodeAndUploaderId(user.locationCode, user.userId);
        const fileName = `receipts_${user.fullName.toLowerCase().replace(' ', '_')}.zip`;

        if (blob) {
            this._downloadService.downloadBlob(blob, fileName);
        }
        else {
            this._snackbar.open(this._translate.instant('receipts.failedToDownload', { fileName }), ok);
        }

        this.loading = false;
    }

    public async downloadByPath(path: string): Promise<void> {
        this.loading = true;
        const ok = this._translate.instant('snackbars.ok');
        const url = await this._mobileScanFileHttpService.getSignedUrl(path);
        const fileName = path.split('/').pop();

        if (url) {
            this._downloadService.download(url, fileName, 'application/pdf');
        }
        else {
            this._snackbar.open(this._translate.instant('receipts.failedToDownload', { fileName }), ok);
        }

        this.loading = false;
    }

    public async previewByPath(path: string): Promise<void> {
        this.loading = true;
        const ok = this._translate.instant('snackbars.ok');
        const fileName = path.split('/').pop();
        const fileUrl = await this._mobileScanFileHttpService.getSignedUrl(path);

        if (fileUrl) {
            this._dialog.open(PdfFileViewerDialogComponent, {
                data: [{
                    fileName,
                    fileUrl,
                    lastModifiedDate: ''
                }],
                height: '90vh',
                width: '75vw'
            });
        }
        else {
            this._snackbar.open(this._translate.instant('receipts.failedToPreview', { fileName }), ok);
        }

        this.loading = false;
    }

    public async deleteByPath(path: string): Promise<void> {
        this.loading = true;
        const success = await this._mobileScanFileHttpService.deleteFileByPath(path);
        const fileName = path.split('/').pop();
        const ok = this._translate.instant('snackbars.ok');

        if (success) {
            this._snackbar.open(this._translate.instant('receipts.successfullyDeleted', { fileName }), ok);
            await this.loadReceipts();
        }
        else {
            this._snackbar.open(this._translate.instant('receipts.failedToDelete', { fileName }), ok);
        }

        this.loading = false;
    }

    public expandDetailsBy = (dataItem: MobileScanFileGroup): string => {
        return dataItem.uploader.userId;
    };

    public clickedRow(event: CellClickEvent): void {
        const actionsTitle = this._translate.instant('receipts.actions');
        if (event.column.title !== actionsTitle && event.dataItem) {
            const group = event.dataItem as MobileScanFileGroup;
            const userId = group.uploader.userId;
            if (this.expandedDetailKeys.includes(userId)) {
                this.expandedDetailKeys = this.expandedDetailKeys.filter(_ => _ !== userId);
            }
            else {
                this.expandedDetailKeys.push(userId);
            }
        }
    }

    private async loadReceipts(): Promise<void> {
        this.group = await this._mobileScanFileHttpService.getFilesByLocationCode(this._currentUser.branchInfo.code);
    }
}
