import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import Chemical from '../../../../core/data-models/chemical';
import ValidatorSet from '../../../../core/data-models/validator-set';
import { ChemicalsHttpService } from '../../../../core/services/http/chemicals-http/chemicals-http.service';
import { ValidatorFactory } from '../../../../core/services/validator-factory/validator-factory.service';

@Component({
    selector: 'chemical-edit',
    styleUrls: ['./chemical-edit.component.scss'],
    templateUrl: './chemical-edit.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ChemicalEditComponent implements OnInit {
    public formGroup: UntypedFormGroup;
    public readonly separatorKeysCodes = [ENTER, COMMA] as const;
    public phoneValidator: ValidatorSet;
    public isLoaded = false;
    private _isCreateNewMode = false;

    // eslint-disable-next-line max-params
    constructor(
        private _dialog: MatDialogRef<ChemicalEditComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _chemicalsHttpService: ChemicalsHttpService,
        private _validatorFactory: ValidatorFactory,
        public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public chemical: Chemical,
    ) {
    }

    get nameControl(): UntypedFormControl {
        return this.formGroup.get('name') as UntypedFormControl;
    }

    get phoneItemControl(): UntypedFormControl {
        return this.formGroup.get('phoneItem') as UntypedFormControl;
    }

    get phoneControl(): UntypedFormControl {
        return this.formGroup.get('phone') as UntypedFormControl;
    }

    get saveDisabled(): boolean {
        return !(this.nameControl.valid && this.chemical.phone?.length);
    }

    public ngOnInit(): void {
        if (!this.chemical) {
            this._isCreateNewMode = true;
            this.chemical = new Chemical();
        }

        this.phoneValidator = this._validatorFactory.createPhoneValidator();
        this.formGroup = this._formBuilder.group({
            name: [this.chemical.name, [Validators.required]],
            phone: [this.chemical.phone],
            phoneItem: ['', [this.phoneValidator.validator]]
        });
        this.isLoaded = true;
    }

    public async onSave(): Promise<void> {
        if (this.formGroup.invalid) {
            return;
        }

        const request = {
            ...this.chemical,
            name: this.formGroup.value.name
        } as Chemical;

        const result = this._isCreateNewMode?
            await this._chemicalsHttpService.addChemical(request) :
            await this._chemicalsHttpService.updateChemical(request);
        this._dialog.close(result);
    }

    public onClose(): void {
        this._dialog.close(null);
    }

    public addPhone(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.phoneItemControl.setValue(value);
        this.phoneControl.setErrors(null);

        if (!value) {
            return;
        }

        if (this.phoneItemControl.invalid) {
            this.phoneControl.setErrors({ invalidPhone: true });
            return;
        }

        this.chemical.phone.push(value);
        this.phoneItemControl.setValue(null);
    }

    public removePhone(phone: string): void {
        this.chemical.phone = this.chemical.phone.filter(p => p !== phone);
        this.phoneControl.updateValueAndValidity();
    }
}
