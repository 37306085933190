import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-quality-control-fees-section',
    styleUrls: ['./quality-control-fees-section.component.scss'],
    templateUrl: './quality-control-fees-section.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualityControlFeesSectionComponent {
    @Input() public form: UntypedFormGroup;
    @Input() public percentageRates: number[] = [];
    @Input() public readonly = false;

    get qualityControlFeesSection(): UntypedFormGroup {
        return this.form.get('qualityControlFeesSection') as UntypedFormGroup;
    }

    get sortedRates(): number[] {
        return this.percentageRates.slice().sort();
    }
}
