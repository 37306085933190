import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import BudgetStepConfig from '../../../../core/data-models/dispatch/budget-step-config';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { BudgetHttpService } from '../../../../core/services/http/budget-http/budget-http.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { QuoteHttpService } from '../../../../core/services/http/quote-http/quote-http.service';
import { UploadHttpService } from '../../../../core/services/http/upload-http/upload-http.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-budget-step',
    styleUrls: ['./budget-step.component.scss'],
    templateUrl: './budget-step.component.html'
})
export class BudgetStepComponent implements OnInit {
    @Input() public config: BudgetStepConfig;
    @Input() public handOffMeetingRequired: boolean;
    @Output() public stepClicked = new EventEmitter<string>();
    @Output() public budgetValueChanged = new EventEmitter<number>();
    public previewFile: Promise<Blob>;
    private quoteBudgetValue = 0;

    public constructor(
        private _translate: TranslateService,
        private _budgetService: BudgetHttpService,
        private _quoteService: QuoteHttpService,
        private _snackBar: MatSnackBar,
        private _uploadService: UploadHttpService) {
    }

    public async ngOnInit(): Promise<void> {
        if (this.config.awardingQuoteId) {
            this.quoteBudgetValue = await this._quoteService.getQuoteBudgetValue(this.config.awardingQuoteId);
            this.previewFile = this._quoteService.getQuoteBudgetAsXlsx(this.config.awardingQuoteId);
        }
    }

    public setCompleteAndMove(): void {
        this.config.completed = this.config.useQuoteBudget || Boolean(this.config.file);
        setTimeout(() => this.stepClicked.emit('next'));
    }

    public addBudgetFile(fileEntry: FileSystemFileEntry): void {
        fileEntry.file(async(file: File) => {
            await this.checkBudgetValue(file);
        });
    }

    public async checkBudgetValue(file: File): Promise<void> {
        const value = await this._budgetService.getBudgetValue(file);
        if (value < 0) {
            this.config.file = null;
            this.previewFile = null;
            this.config.completed = false;
            const message = this._translate.instant('dispatch.budgetStep.snackBarMessage');
            this._snackBar.open(message, this._translate.instant('snackbars.close'));
        }
        else {
            this.config.file = file;
            this.budgetValueChanged.emit(value);
            this.previewFile = this._uploadService.convertXlsmToXlsx(this.config.file);
        }
    }

    public onChangeQuoteBudget(event: MatCheckboxChange): void {
        this.config.file = null;

        this.budgetValueChanged.emit(event.checked? this.quoteBudgetValue : 0);

        if (event.checked) {
            this.previewFile = this._quoteService.getQuoteBudgetAsXlsx(this.config.awardingQuoteId);
        }
        else {
            this.previewFile = null;
        }
    }
}
