import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { ProjectMergeHttpService } from '../../../../../core/services/http/project-merge-http/project-merge-http.service';
import CodeMapping from '../../../../../core/data-models/generic/code-mapping';
import { ProjectStatusHttpService } from '../../../../../core/services/http/project-status-http/project-status-http.service';
import LanguageUtility from '../../../../../core/services/utility/language-utility/language-utility.service';
import { UserHttpService } from '../../../../../core/services/http/user-http/user-http.service';
import UserInfo from '../../../../../core/data-models/user-info';
import { WritePermission } from '../../../../../core/enums/write-permission.enum';
import { UserPermissionType } from '../../../../../core/enums/user-permission-type.enum';
import ProjectStatus from '../../../../../core/data-models/project-status';
import { UserPermissionService } from '../../../../../core/services/user-permission/user-permission.service';

@Component({
    selector: 'merge-project-rules-dialog',
    styleUrls: ['./merge-project-rules-dialog.component.scss'],
    templateUrl: './merge-project-rules-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MergeProjectRulesDialogComponent {
    public userHasMergeRulePermission = true;
    private _salesCodeMappings : CodeMapping<string>[];
    private _sourceProjectStatuses: string[];
    private _targetProjectStatuses: string[];
    private _user: UserInfo;

    get salesCodeMappings(): CodeMapping<string>[] {
        return this._salesCodeMappings;
    }

    get sourceProjectStatuses(): string[] {
        return this._sourceProjectStatuses;
    }

    get targetProjectStatuses(): string[] {
        return this._targetProjectStatuses;
    }

    // eslint-disable-next-line max-params
    constructor(
        private _projectMergeHttp: ProjectMergeHttpService,
        private _projectStatusHttp: ProjectStatusHttpService,
        private _userHttp: UserHttpService,
        private _dialog: MatDialogRef<MergeProjectRulesDialogComponent>,
        public translate: TranslateService,
        private _userPermissionService: UserPermissionService,
        private _snackBar: MatSnackBar
    ) {
    }

    public async ngOnInit(): Promise<void> {
        const canMergeProject = await this._userPermissionService.hasWritePermission(WritePermission.MergeProject);
        this._salesCodeMappings = await this._projectMergeHttp.getConsolidatedMergeRulesMappings();

        this.userHasMergeRulePermission = canMergeProject && this._salesCodeMappings.length > 0;

        if (this.userHasMergeRulePermission) {
            this._user = await this._userHttp.getUserInfo();
            const userMergeRulePermission = this._user.permissions
                .find(p => p.actionCode === WritePermission.MergeProject && p.type === UserPermissionType.Write);

            const projectStatuses = await this._projectStatusHttp.getFilterableProjectStatus();
            this._sourceProjectStatuses = this.getStatusesFromPermissionParameters(projectStatuses, userMergeRulePermission.parameters.sourceProjectStatus);
            this._targetProjectStatuses = this.getStatusesFromPermissionParameters(projectStatuses, userMergeRulePermission.parameters.targetProjectStatus);
        }
        else {
            const message = this.translate.instant('projects.merge.rules.permissionToMergeError');
            this._snackBar.open(message, this.translate.instant('shared.close'));
        }
    }

    private getStatusesFromPermissionParameters(allStatuses: ProjectStatus[], codes: string[]): string[] {
        return codes
            .map(code => {
                const projectStatus = allStatuses.find(x => x.code === Number(code));
                return LanguageUtility.getLocalizedContent(this._user.language, projectStatus?.names);
            });
    }

    public onClose(): void {
        this._dialog.close();
    }
}
