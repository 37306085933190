import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import JsonUtility from '../../utility/json-utility.service';
import HandOffMeetingTemplate from '../../../data-models/handoff-meeting/hand-off-meeting-template';
import HandOffMeetingForm from '../../../data-models/handoff-meeting/hand-off-meeting-form';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HandOffMeetingHttpService {
    private _api = `${environment.fswApiUrl}api2/service/handoffmeeting`;

    constructor(private _http: HttpClient, private _translationService: TranslateService) { }

    public async getHandOffTemplate(): Promise<HandOffMeetingTemplate> {
        const url = `${this._api}/getHandOffTemplate/${this._translationService.currentLang}`;
        try {

            const data = await this._http.get<HandOffMeetingTemplate>(url).toPromise();
            return JsonUtility.toCamelCaseKeys(data);

        }
        catch {
            return null;
        }
    }

    public async getHandOffMeetingByProjectId(projectId: string): Promise<HandOffMeetingForm> {
        const url = `${this._api}/${projectId}`;
        try {
            const data = await this._http.get<HandOffMeetingForm>(url).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }
}
