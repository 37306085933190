import { Injectable } from '@angular/core';
import ImageItem from '../../../data-models/job-template-items/image-item';
import Branch from '../../../data-models/branch';
import FileTypeProperties from '../../../data-models/generic/file-type-properties';
import { environment } from '../../../../../environments/environment';
import Address from '../../../data-models/address';
import ProjectIdUtility from '../project-id-utility/project-id-utility.service';
import QuoteBasicInfo from '../../../data-models/quote-general-info/quote-basic-info';
import ProjectBasicInfo from '../../../data-models/project-general-info/project-basic-info';

@Injectable({
    providedIn: 'root'
})
export class GenericUtilityService {
    /* istanbul ignore next */
    public static getImageUrl(image: ImageItem, thumbnail: boolean): string {
        const folderIndex = image.baseImageUri.indexOf('uploads/');
        let imageUrl = image.baseImageUri;
        if (folderIndex > 0) {
            imageUrl = `${environment.cloudfrontUrl}${image.baseImageUri.substring(folderIndex)}`;
        }

        if (thumbnail) {
            imageUrl = `${imageUrl}&height=200&width=200`;
        }

        if (image.modifiedDate) {
            imageUrl = `${imageUrl}&imageVersion=${image.modifiedDate}`;
        }

        return imageUrl;
    }

    /* istanbul ignore next */
    public static base64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });

        return blob;
    }
    /* istanbul ignore next */
    //eslint-disable-next-line
    public static getFileIconColorByFileType(fileName: string): string {
        const type = fileName.split('.').pop();
        switch (type) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return 'orange';
            case 'pdf':
                return 'red';
            case 'xlsx':
            case 'xlsm':
            case 'csv':
                return 'green';
            case 'docx':
            case 'txt':
                return 'blue';
            case 'msg':
            case 'eml':
                return 'aqua';
            default:
                return 'greenyellow';
        }
    }

    /* istanbul ignore next */
    public static allowedFileTypes(): FileTypeProperties[] {
        return [
            {
                fileType: 'jpg',
                isCustomerEligibleToView: false
            },
            {
                fileType: 'jpeg',
                isCustomerEligibleToView: false
            },
            {
                fileType: 'png',
                isCustomerEligibleToView: false
            },
            {
                fileType: 'gif',
                isCustomerEligibleToView: false
            },
            {
                fileType: 'pdf',
                isCustomerEligibleToView: true
            },
            {
                fileType: 'xlsx',
                isCustomerEligibleToView: true
            },
            {
                fileType: 'docx',
                isCustomerEligibleToView: true
            },
            {
                fileType: 'msg',
                isCustomerEligibleToView: false
            },
            {
                fileType: 'xlsm',
                isCustomerEligibleToView: true
            },
            {
                fileType: 'txt',
                isCustomerEligibleToView: false
            },
            {
                fileType: 'csv',
                isCustomerEligibleToView: true
            },
            {
                fileType: 'eml',
                isCustomerEligibleToView: false
            },
            {
                fileType: 'pptx',
                isCustomerEligibleToView: true
            },
            {
                fileType: 'mp4',
                isCustomerEligibleToView: false
            },
            {
                fileType: 'mov',
                isCustomerEligibleToView: false
            }
        ];
    }

    public static getPlaceholder(text: string, placeholder = 'Pending', patterns = [/^new-.*$/]): string {
        return !text?.trim() || patterns.some(_ => _.test(text)) ? placeholder : text;
    }

    public static isUsBranch(branch: Branch): boolean {
        return /^8/.test(branch?.code);
    }

    /* istanbul ignore next */
    public static blobToBase64(file: Blob, includeType = false): Promise<string> {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise(resolve => {
            reader.onloadend = () => {
                let base64 = reader.result.toString();
                if (!includeType) {
                    base64 = base64.split(',')[1];
                }
                resolve(base64);
            };
        });
    }

    /* istanbul ignore next */
    public static printHtmlContent(content: string): void {
        const popup = window.open('', '_blank', 'width=800, height=1300');
        const css = '<link href="/assets/styles/print.css" media="print" rel="stylesheet" />';
        const body = `<body onload="window.print()">${content}</body>`;
        popup.document.open();
        popup.document.write(`<html><head>${css}</head>${body}</html>`);
        popup.document.close();
    }

    /* istanbul ignore next */
    public static getPhoneMaskString(): string {
        return '(000) 000-0000';
    }

    public static getPhoneMaskStringWithExtension(): string {
        return '(000) 000-0000, 0000';
    }

    /* istanbul ignore next */
    public static hasData<T>(target: T): boolean {
        if (!target) {
            return false;
        }

        return Object.keys(target).some(_ => target[_]);
    }

    /* istanbul ignore next */
    public static removeFalsyValues<T>(object: T): T {
        return Object.entries(object).reduce((previous, [key, value]) => {
            if (value) {
                previous[key] = value;
            }

            return previous;
        }, {} as T);
    }

    public static getQuotePdfName(quoteId: string, quoteName: string, address: Address, projectId: string): string {
        let fileName = `Quote #${quoteId} - ${quoteName}`;

        if (address && address.addr1 && address.city && address.state) {
            fileName = address.addr2 ?
                `${fileName} (${address.addr1}, ${address.addr2}, ${address.city}, ${address.state})` :
                `${fileName} (${address.addr1}, ${address.city}, ${address.state})`;
        }

        const displayProjectId = !projectId || projectId === 'Unawarded' ? '' : `Job# ${ProjectIdUtility.toDisplayFormat(projectId)}`;
        fileName = `${fileName} ${displayProjectId}`.trim();
        return `${fileName}.pdf`;
    }

    public static getQuotePdfNameByQuoteBasicInfo(basicInfo: QuoteBasicInfo): string {
        const { quoteId, name, addressString, geoCode, destinationProjectId: projectId } = basicInfo;
        let fileName = `Quote #${quoteId} - ${name}`;

        if (addressString || geoCode) {
            fileName = `${fileName} (${addressString ? addressString : geoCode})`;
        }

        const displayProjectId = !projectId || projectId === 'Unawarded' ? '' : `Job# ${ProjectIdUtility.toDisplayFormat(projectId)}`;
        fileName = `${fileName} ${displayProjectId}`.trim();
        return `${fileName}.pdf`;
    }

    public static getOrderNameByProjectBasicInfo(basicInfo: ProjectBasicInfo): string {
        const { projectId, projectName, addressString, geoCode } = basicInfo;
        const address = addressString ? addressString.trim() : geoCode;

        const fileName = `Work Order - ${projectName} (${address}) Job# ${projectId}.pdf`;
        return fileName;
    }
}
