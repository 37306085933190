import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PopupSettings, DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { UserHttpService } from '../../../../../../../core/services/http/user-http/user-http.service';
//eslint-disable-next-line
import { HandOffMeetingHttpService } from '../../../../../../../core/services/http/hand-off-meeting-http/hand-off-meeting-http.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import HandoffMeetingStepConfig from '../../../../../../../core/data-models/dispatch/handoff-meeting-step-config';

//eslint-disable-next-line
import { FormControlUtilityService } from '../../../../../../../core/services/utility/form-control-utility-service/form-control-utility.service';
import HandOffMeetingTemplate from '../../../../../../../core/data-models/handoff-meeting/hand-off-meeting-template';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'handoff-meeting',
    templateUrl: './handoff-meeting.component.html',
    styleUrls: ['./handoff-meeting.component.scss'],
    providers: [{
        provide: POPUP_CONTAINER,
        useFactory: () => {
            return { nativeElement: document.querySelector('.hand-off-meeting-date-time-picker') } as ElementRef;
        }
    },
    PopupService
    ]
})

export class HandoffMeetingComponent implements OnInit, OnChanges {

    public handOffMeetingFormGroup: UntypedFormGroup;
    public foremanQuestionFormGroup: UntypedFormGroup;
    public createKendoDialog = false;
    public employeeList: string[];
    public handOffTemplate: HandOffMeetingTemplate;
    public filteredManagers: Observable<string[]>;
    public filteredSuperintendants: Observable<string[]>;
    public filteredEstimator: Observable<string[]>;
    public filteredSafetySpecialist: Observable<string[]>;
    public popupSettings: PopupSettings = { appendTo: 'component' };
    @ViewChild('kendoDateTime') public kendoDateTime: DateTimePickerComponent;
    @Input() projectId: string;
    @Input() projectName: string;
    @Input() hasFollowUpStep: boolean;
    @Input() config: HandoffMeetingStepConfig;
    @Input() foremanName: string;
    @Output() stepClicked = new EventEmitter<string>();

    //eslint-disable-next-line
    constructor(
        private _userHttp: UserHttpService,
        private _handOffHttp: HandOffMeetingHttpService,
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _formService: FormControlUtilityService,
        public translate: TranslateService) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.foremanName && this.foremanQuestionFormGroup) {
            this.foremanQuestionFormGroup.get('comment').setValue(changes.foremanName.currentValue);
        }
    }


    public async ngOnInit(): Promise<void> {

        this.foremanQuestionFormGroup = this._formBuilder.group({
            answer: [''],
            question: ['Foreman Name?'],
            comment: [''],
            responses: this._formBuilder.array([])
        });

        this.createHandoffMeetingForm();
        const employees = await this._userHttp.getUsersForHandoffMeeting();
        this.handOffTemplate = await this._handOffHttp.getHandOffTemplate();

        if (!employees || !this.handOffTemplate) {
            const failedToLoad = this.translate.instant('projects.handOffMeeting.failedToLoadHelpText');
            const ok = this.translate.instant('snackbars.ok');
            this._snackBar.open(failedToLoad, ok);
            return;
        }
        this.handOffMeetingFormGroup.get('templateVersion').setValue(this.handOffTemplate.templateVersion);

        this.employeeList = employees.map(_ => _.preferredName);
        this.filteredManagers = this.autoCompleteFilterSubscription('manager');
        this.filteredSuperintendants = this.autoCompleteFilterSubscription('superintendant');
        this.filteredEstimator = this.autoCompleteFilterSubscription('estimator');
        this.filteredSafetySpecialist = this.autoCompleteFilterSubscription('safetySpecialist');
        this.pushTemplateIntoHandoffFormGroup();
        this.setupSubscriptions();
    }

    public getQuestionFormGroup(i: number, j: number, sectionName: string): UntypedFormControl {
        const sectionsArray = this.handOffMeetingFormGroup.get('sections') as UntypedFormArray;
        const sectionFormGroup = sectionsArray.at(i) as UntypedFormGroup;
        const questionFormArray = sectionFormGroup.get('questions') as UntypedFormArray;

        if (sectionName === 'Crew') {
            return questionFormArray.at(j + 1) as UntypedFormControl;
        }
        return questionFormArray.at(j) as UntypedFormControl;
    }


    public dispatchProject(): void {

        if (!this.handOffMeetingFormGroup.valid) {
            this.handOffMeetingFormGroup.markAllAsTouched();
            const finishFormHelpText = this.translate.instant('projects.handOffMeeting.finishFormHelpText');
            const ok = this.translate.instant('snackbars.ok');
            this._snackBar.open(finishFormHelpText, ok);
            return;
        }

        this.stepClicked.emit('next');
    }

    public isFormControlValid(controlName: string): boolean {
        return this.handOffMeetingFormGroup.get(controlName).invalid;
    }

    public getErrorMessage(controlName: string): string {
        return this._formService.getErrorMessage(this.handOffMeetingFormGroup.get(controlName));
    }

    private autoCompleteFilterSubscription(formName: string): Observable<string[]> {
        return this.handOffMeetingFormGroup.get(formName).valueChanges.pipe(
            startWith(''),
            map(value => this.filter(value))
        );
    }

    private filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        if (filterValue) {
            return this.employeeList.filter(option => option?.toLowerCase()?.startsWith(filterValue));
        }
        return null;
    }

    private setupSubscriptions(): void {
        this.handOffMeetingFormGroup.valueChanges.pipe().subscribe(meeting => {
            this.config.completed = this.handOffMeetingFormGroup.valid;
            this.config.data = { templateId: this.handOffTemplate.id, ...meeting };
        });
    }

    private createHandoffMeetingForm(): void {

        this.handOffMeetingFormGroup = this._formBuilder.group({
            templateVersion: [''],
            meetingTime: [null, Validators.required],
            manager: ['', Validators.required],
            superintendant: ['', Validators.required],
            estimator: ['', Validators.required],
            safetySpecialist: ['', Validators.required],
            notes: [''],
            sections: this._formBuilder.array([
            ])
        });
    }

    private pushTemplateIntoHandoffFormGroup(): void {

        for (const handOffSection of this.handOffTemplate.sections) {
            const sectionFormGroup = this._formBuilder.group({
                name: [handOffSection.name],
                questions: this._formBuilder.array([
                ])
            });

            if (handOffSection.name === 'Crew') {
                const crewQuestionArray = sectionFormGroup.get('questions') as UntypedFormArray;
                crewQuestionArray.push(this.foremanQuestionFormGroup);
            }

            for (const question of handOffSection.questions) {
                const questionForm = this._formBuilder.group({
                    answer: ['', question.responses?.length > 1? Validators.required: null],
                    question: [question.question],
                    comment: ['', Validators.required],
                    responses: this._formBuilder.array([])
                });
                const questionArray = sectionFormGroup.get('questions') as UntypedFormArray;
                questionArray.push(questionForm);
            }
            const sectionsArray = this.handOffMeetingFormGroup.get('sections') as UntypedFormArray;
            sectionsArray.push(sectionFormGroup);
        }

    }

}
