import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

import ActionProgressReporterOption from '../../../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import StockLog from '../../../../../core/data-models/job-materials/stock-log';
import StockLogDialogOptions from '../../../../../core/data-models/job-materials/stock-log-dialog-options';
import { InvoiceHttpService } from '../../../../../core/services/http/invoice-http/invoice-http.service';

@Component({
    selector: 'project-stock-log-dialog',
    styleUrls: ['./project-stock-log-dialog.component.scss'],
    templateUrl: './project-stock-log-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProjectStockLogDialogComponent implements OnInit {
    public totalCostInput: UntypedFormControl;
    private _stockLog: StockLog;
    private _isLoading = false;

    // eslint-disable-next-line max-params
    constructor(private _invoiceHttp: InvoiceHttpService,
                private _dialogService: MatDialog,
                private _dialog: MatDialogRef<ProjectStockLogDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public option: StockLogDialogOptions,
                public translate: TranslateService) { }

    get isLoading(): boolean {
        return !this._stockLog || this._isLoading;
    }

    get stockLog(): StockLog {
        return this._stockLog;
    }

    public async ngOnInit(): Promise<void> {
        this._stockLog = await this._invoiceHttp.getStockLog(this.option.projectId);

        const validators = [Validators.required, Validators.min(0)];
        this.totalCostInput = new UntypedFormControl(this._stockLog?.materialCost, validators);
    }

    public updateStockLog(): void {
        if (this.totalCostInput.invalid) {
            return;
        }

        this._stockLog.materialCost = this.totalCostInput.value;

        const action = this._invoiceHttp.updateStockLog(this._stockLog);
        const ongoing = this.translate.instant('projects.stockLog.ongoing');
        const success = this.translate.instant('projects.stockLog.success');
        const error = this.translate.instant('projects.stockLog.error');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

    }

    public onClose(): void {
        this._dialog.close();
    }
}
