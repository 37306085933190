import Employee from '../employee';
import TimeInfo from '../time-info';
import Placeholder from './placeholder';

export default class CustomReportTemplate {
    public code: number;
    public name: string;
    public isBuiltIn: boolean;
    public content:string;
    public placeholders: Placeholder[] = [];
    public creator: Employee;
    public timeInfo: TimeInfo;

    constructor(content = '', name = '', placeholders: Placeholder[] = []) {
        this.content = content;
        this.name = name;
        this.placeholders = placeholders;
    }
}
