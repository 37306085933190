import CustomerSelectionDto from '../customer-selection-dto';
import ProjectQuestions from '../project-attributes/project-questions';
import CustomerSiteDispatchInfo from './customer-site-dispatch-info';
import DispatchProjectInfoData from './dispatch-project-info-data';
import QuoteAwardOptions from './quote-award-options';
import USProjectInfoModel from './us-project-info-model';

export default class DispatchData {

    public selectedCustomer: CustomerSelectionDto;
    public siteHistory: string[] = [];
    public siteDispatchInfo: CustomerSiteDispatchInfo;
    public generalDispatchInfo: DispatchProjectInfoData;
    public workOrderString: string;
    public workCheckList: string[] =[];
    public handOffMeetingRequired = false;
    public projectId: string;
    public quoteAwardOptions: QuoteAwardOptions;
    public fastClientRequestId: string;
    public projectQuestions: ProjectQuestions;
    public usProjectInfo: USProjectInfoModel;
    public integrationProjectId: string;
}
