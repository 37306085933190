export default class CodeDescription<T = unknown> {
    public code: string;
    public description: string;
    public data?: T;

    public constructor(code: string, description: string, data = null) {
        this.code = code;
        this.description = description;
        this.data = data;
    }
}
