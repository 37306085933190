<div class="template-display-item-card-container" [ngSwitch]="template.valueAttributes.type" fxLayout="row" fxLayoutAlign="start center">
    <div class="template-display-item-checkbox"
        *ngSwitchCase="'check_box'"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">

        <mat-icon *ngIf="template.value === 'true'">check_box</mat-icon>
        <mat-icon *ngIf="template.value !== 'true'">check_box_outline_blank</mat-icon>
        <span>{{ template.nameAttributes.translatedName }}</span>
    </div>

    <div class="template-display-item-radio-button"
        *ngSwitchCase="'radio_button_fsw'"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">

        <mat-icon *ngIf="template.value === 'true'">radio_button_checked</mat-icon>
        <mat-icon *ngIf="template.value !== 'true'">radio_button_unchecked</mat-icon>
        <span>{{ template.nameAttributes.translatedName }}</span>
    </div>

    <div class="template-display-item-radio-button"
        *ngSwitchCase="'radio_button_with_text_fsw'"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">

        <mat-icon>radio_button_checked</mat-icon>
        <span>{{ template.nameAttributes.translatedName }}:</span>
        <span>{{ template.valueAttributes.additionalValue }}</span>
    </div>

    <span *ngSwitchCase="'list_numeric'">
        {{ template.nameAttributes.translatedName }}: {{ getTranslatedValue(template) }}, {{ template.valueAttributes.additionalValue }} {{ template.valueAttributes.unit }}
    </span>

    <div class="template-display-item-weather"
        *ngSwitchCase="'weather_condition_fsw'"
        fxLayoutAlign="start center"
        fxLayoutGap="5px">

        <span>{{ template.nameAttributes.translatedName }}: {{ getTranslatedValue(template) }}</span>
        <mat-icon [svgIcon]="getWeatherIcon(template.value)"></mat-icon>
    </div>

    <div class="template-display-item-text-multiline"
        *ngSwitchCase="'text_multiline_fsw'"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="4px">

        <span>{{ template.nameAttributes.translatedName }}:</span>
        <textarea rows="5" disabled>{{ template.value }}</textarea>
    </div>

    <div class="template-display-item-text-multiline"
        *ngSwitchCase="'text_multiline_with_audio_fsw'"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="4px">

        <span>{{ template.nameAttributes.translatedName }}:</span>

        <div fxLayoutAlign="start start" fxLayoutGap="5px" fxFlexAlign="stretch">
            <textarea rows="5" disabled fxFlex>{{ template.value }}</textarea>

            <button mat-icon-button (click)="playVoiceNote(template.valueAttributes.mediaUri)" [disabled]="!template.valueAttributes.mediaUri">
                <mat-icon>headset</mat-icon>
            </button>
        </div>
    </div>

    <div class="template-display-item-collection"
        *ngSwitchCase="'collection_item_entries_fsw'"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="4px">

        <span>{{ template.nameAttributes.translatedName }}:</span>

        <table>
            <tr>
                <th *ngFor="let field of template.fields[0].fields">{{ field.nameAttributes.translatedName }}</th>
            </tr>

            <tr *ngFor="let displayItem of template.fields">
                <td *ngFor="let field of displayItem.fields">
                    <img *ngIf="field.valueAttributes.type === 'signature'" [src]="field.value" [appImageOverlay]="field.value"/>
                    <span *ngIf="field.valueAttributes.type !== 'signature'">{{ field.value }}</span>

                    <button *ngIf="field.valueAttributes.mediaUri" mat-icon-button (click)="playVoiceNote(field.valueAttributes.mediaUri)">
                        <mat-icon>headset</mat-icon>
                    </button>
                </td>
            </tr>
        </table>
    </div>

    <div *ngSwitchCase="'rating_result'" fxLayoutAlign="start center">
        <span>{{ template.nameAttributes.translatedName }}: </span>

        <span [class]="'rating-result-' + template.valueAttributes.additionalValue + ' rating-results'">
            {{ template.value }}
        </span>
    </div>

    <span *ngSwitchDefault>{{ template.nameAttributes.translatedName }}: {{ getTranslatedValue(template) }}</span>
</div>
