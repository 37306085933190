import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DispatchHttpService } from '../../../core/services/http/dispatch-http/dispatch-http.service';
import DynamicsDispatchInfo from '../../../core/data-models/dispatch/dynamics-dispatch-info';
import ProjectUsTaxQuestions from '../../../core/data-models/project-us-tax-questions';
import ProjectUSTaxQuestionsValidCheck from '../../../core/data-models/project-us-tax-questions-valid-check';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { WorkCategory } from '../../../core/data-models/work-category';
import BudgetStepConfig from '../../../core/data-models/dispatch/budget-step-config';
import { ScopeOfWork } from '../../../core/enums/scope-of-work.enum';
import { ContractType } from '../../../core/enums/contract-type.enum';

@Component({
    selector: 'project-us-tax-questions-card',
    styleUrls: ['./project-us-tax-questions-card.component.scss'],
    templateUrl: './project-us-tax-questions-card.component.html'
})
export class ProjectUSTaxQuestionsCardComponent implements OnInit {
    @Input() public budgetConfig: BudgetStepConfig;
    @Input() public usTaxQuestions: ProjectUsTaxQuestions;
    @Input() public readOnly = true;
    @Output() result = new EventEmitter<ProjectUSTaxQuestionsValidCheck>();
    public dropdowns = new DynamicsDispatchInfo();
    public formGroup: UntypedFormGroup;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _dispatchService: DispatchHttpService,
        public translate: TranslateService
    ) { }

    public async ngOnInit(): Promise<void> {
        if (!this.readOnly) {
            this.initializeFormGroup();
            this.formGroup.markAllAsTouched();
            this.dropdowns = await this._dispatchService.getDynamicsDispatchInfo(WorkCategory.InternalJob);
            const defaultScope = this.dropdowns.scopesOfWork.find(scope => scope.startsWith(ScopeOfWork.Service));
            this.formGroup.get('scopeOfWork').setValue(defaultScope);
        }
    }

    private initializeFormGroup(): void {
        this.formGroup = this._formBuilder.group({
            buildingPermit: [this.usTaxQuestions.buildingPermit, Validators.required],
            contractType: [{ value: this.usTaxQuestions.contractType, disabled: true }, Validators.required],
            repairMaintenance: [this.usTaxQuestions.repairMaintenance, Validators.required],
            scopeOfWork: [{ value: this.usTaxQuestions.scopeOfWork, disabled: true }, Validators.required]
        });

        this.formGroup.valueChanges.subscribe(() => this.syncFormFields());
        this.formGroup.get('contractType').setValue(!this.budgetConfig?.budgetRequired ? ContractType.TM : ContractType.FixedPrice);
    }

    public ngOnChanges(): void {
        if (this.formGroup) {
            this.formGroup.get('contractType').setValue(!this.budgetConfig?.budgetRequired ? ContractType.TM : ContractType.FixedPrice);
        }
    }

    private syncFormFields(): void {
        this.usTaxQuestions.buildingPermit = this.formGroup.get('buildingPermit').value;
        this.usTaxQuestions.contractType = this.formGroup.get('contractType').value;
        this.usTaxQuestions.repairMaintenance = this.formGroup.get('repairMaintenance').value;
        this.usTaxQuestions.scopeOfWork = this.formGroup.get('scopeOfWork').value;
    }

    public checkAndSendResult(): void {
        this.formGroup.markAllAsTouched();
        this.result.emit(new ProjectUSTaxQuestionsValidCheck(!this.formGroup.invalid, this.usTaxQuestions));
    }
}
