import InformationGridRow from './information-grid-row';
// eslint-disable-next-line
export default class InformationGridConfig<T = any> {
    public data: T;
    public rows: InformationGridRow[] = [];

    constructor(data: T, rows: InformationGridRow[]) {
        this.data = data;
        this.rows = rows;
    }
}
