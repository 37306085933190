import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ValidatorFactory } from '../../../../core/services/validator-factory/validator-factory.service';
import { UntypedFormBuilder } from '@angular/forms';
import { EditorUtilityService } from '../../../../core/services/utility/editor-utility/editor-utility.service';
import ExecutiveSummary from '../../../../core/data-models/executive-summary';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'executive-summary-content',
    templateUrl: './executive-summary-content.component.html',
    styleUrls: ['./executive-summary-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExecutiveSummaryContentComponent implements OnInit, AfterViewInit {
    @Input() public summary: ExecutiveSummary;
    @Output() public summarySaved = new EventEmitter<ExecutiveSummary>();
    public nonNegativeDecimal = this._validatorFactory.createNonNegativeValidator();
    public formGroup = this._formBuilder.group({ totalRoofArea: ['', this.nonNegativeDecimal.validator] });
    public editorId = 'executive-summary-content';

    private _activeTab: string;

    public get activeTab(): string {
        return this._activeTab;
    }

    @Input()
    public set activeTab(tab: string) {
        this._activeTab = tab;

        if (this._editorUtilityService.getEditor(this.editorId)) {
            this._editorUtilityService.setContent(this.editorId, this.summary[this._activeTab]);
        }
    }

    public constructor(
        private _formBuilder: UntypedFormBuilder,
        private _validatorFactory: ValidatorFactory,
        private _editorUtilityService: EditorUtilityService,
        private _translate: TranslateService
    ) { }

    public ngOnInit(): void {
        this.formGroup = this._formBuilder.group({
            totalRoofArea: [this.summary.totalRoofArea, this.nonNegativeDecimal.validator]
        });

        this.formGroup.valueChanges.subscribe(() => {
            this.summary.totalRoofArea = this.formGroup.controls.totalRoofArea.value;
        });

        if (!this.summary.siteName) {
            this.summary.siteName = this._translate.instant('projects.executiveSummary.noSiteName');
        }
    }

    public ngAfterViewInit(): void {
        this._editorUtilityService.createEditor(this.editorId, { change: this.updateTabData.bind(this) });
        this._editorUtilityService.setContent(this.editorId, this.summary[this.activeTab]);
    }

    public save(): void {
        this.updateTabData();
        this.summarySaved.emit(this.summary);
    }

    public updateTabData(): void {
        this.summary[this.activeTab] = this._editorUtilityService.getContent(this.editorId);
    }
}
