import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import UserInfo from '../../../../core/data-models/user-info';
import UserRole from '../../../../core/data-models/user-role';
import UserPermission from '../../../../core/data-models/user-permission';
import ProjectMergeRule from '../../../../core/data-models/project-merge-rule';
import PermissionTemplate from '../../../../core/data-models/permission-template';
import SubscribedTopic from '../../../../core/data-models/subscribed-topic';
import SubscriptionTopic from '../../../../core/data-models/subscription-topic';
import CodeDescription from '../../../../core/data-models/code-description';
import ConfirmDialogOption from '../../../../core/data-models/confirm-dialog-option';
import LanguageUtility from '../../../../core/services/utility/language-utility/language-utility.service';
import { ConfirmDialogComponent } from '../../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';

@Component({
    selector: 'role-editor',
    styleUrls: ['./role-editor.component.scss'],
    templateUrl: './role-editor.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleEditorComponent implements OnInit {
    @Input() public user: UserInfo;
    @Input() public role: UserRole;
    @Input() public permissionTemplates: PermissionTemplate[] = [];
    @Input() public permissionParameterOptions: { [key: string]: CodeDescription[] } = {};
    @Input() public subscriptionTemplates: SubscriptionTopic[] = [];
    @Input() public mergeRules: ProjectMergeRule[] = [];
    @Input() public savingState = { isSaving: false, isSaved: true };
    @Output() public update = new EventEmitter<UserRole>();
    @Output() public close = new EventEmitter();
    public isPermissionView = true;

    constructor(private _translate: TranslateService,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar) { }

    public ngOnInit(): void {
        this.checkProjectMergeRule();
    }

    private checkProjectMergeRule(): void {
        const code = this.role.projectMergeRuleCode;

        if (code !== -1 && this.mergeRules.every(_ => _.code !== code)) {
            const message = this._translate.instant('admin.rolesAndPermissions.roleEditor.mergeRuleNotFound');
            this._snackBar.open(message, this._translate.instant('shared.ok'));
            this.onMergeRuleUpdate(-1);
        }
    }

    public getMergeRuleName(rule: ProjectMergeRule): string {
        return LanguageUtility.getLocalizedContent(this.user.language, rule.names);
    }

    public async onClose(): Promise<void> {
        if (this.savingState.isSaved) {
            this.close.emit();

            return;
        }

        const title = this._translate.instant('shared.gridDisplayItem.unsavedChanges');
        const message = this._translate.instant('shared.gridDisplayItem.keepChangesQuestion');
        const buttonText = this._translate.instant('shared.discard');
        const data = new ConfirmDialogOption(title, message, true, buttonText);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (await dialog.afterClosed().toPromise()) {
            this.close.emit();
        }
    }

    public onPermissionUpdate(permissions: UserPermission[]): void {
        this.role.userPermissions = permissions;
        this.savingState.isSaved = false;
    }

    public onSubscriptionUpdate(subscriptions: SubscribedTopic[]): void {
        this.role.subscriptions = subscriptions;
        this.savingState.isSaved = false;
    }

    public onMergeRuleUpdate(code: number): void {
        this.role.projectMergeRuleCode = code;
        this.savingState.isSaved = false;
    }

    public onSave(): void {
        this.savingState.isSaving = true;
        this.update.emit(this.role);
    }
}
