<dialog-action-card class="prompt-dialog-container"
    [icon]="'notification_important'"
    [title]="option.title"
    [isLoaded]="true"
    (close)="onClose(false)">

    <div class="prompt-dialog-content" fxLayout="column">
        <span *ngIf="option.message">{{ option.message }}</span>

        <mat-form-field class="prompt-dialog-input">
            <mat-label>{{ placeholder }}</mat-label>

            <textarea matInput
                cdkFocusInitial
                cdkTextareaAutosize
                cdkAutosizeMinRows="8"
                cdkAutosizeMaxRows="10"
                [formControl]="input"
                required>
            </textarea>

            <mat-error *ngIf="input.invalid">* {{ errorText }}</mat-error>
        </mat-form-field>
    </div>

    <div class="prompt-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button [color]="colorType" (click)="onClose()">
            {{ option.confirmText }}
        </button>
    </div>
</dialog-action-card>
