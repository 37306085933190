import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

import UserRole from '../../../../../core/data-models/user-role';
import UserPermission from '../../../../../core/data-models/user-permission';
import CodeDescription from '../../../../../core/data-models/code-description';
import ParameterSet from '../../../../../core/data-models/parameter-set';
import CheckboxData from '../../../../../core/data-models/checkbox-data';
import PermissionTemplate from '../../../../../core/data-models/permission-template';
import LanguageUtility from '../../../../../core/services/utility/language-utility/language-utility.service';
import { UserPermissionType } from '../../../../../core/enums/user-permission-type.enum';

@Component({
    selector: 'permission-editor',
    styleUrls: ['./permission-editor.component.scss'],
    templateUrl: './permission-editor.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class PermissionEditorComponent implements OnInit {
    @Input() public language = 'en';
    @Input() public isDisabled = false;
    @Input() public role: UserRole;
    @Input() public templates: PermissionTemplate[] = [];
    @Input() public parameterOptions: { [key: string]: CodeDescription[] } = {};
    @Output() public update = new EventEmitter<UserPermission[]>();
    public readonly visibleColumns = ['name', 'summary', 'actions'];
    public expandedTemplate: UserPermission | null;
    public filters: { type: number; name: string }[] = [];
    public filterValue = -1;

    constructor(private _translate: TranslateService) { }

    get visibleTemplates(): PermissionTemplate[] {
        return this.filterValue === -1 ? this.templates.filter(_ => !_.isHidden) : this.templates.filter(_ => _.type === this.filterValue && !_.isHidden);
    }

    get isMasterToggleOn(): boolean {
        return this.visibleTemplates.every(_ => _.isAllowed);
    }

    public ngOnInit(): void {
        const keyPrefix = 'admin.rolesAndPermissions.permissionEditor';

        this.filters = [
            { type: -1, name: this._translate.instant(`${keyPrefix}.filterTypeAll`) },
            { type: UserPermissionType.Read, name: this._translate.instant(`${keyPrefix}.filterTypeRead`) },
            { type: UserPermissionType.Write, name: this._translate.instant(`${keyPrefix}.filterTypeWrite`) },
            { type: UserPermissionType.Create, name: this._translate.instant(`${keyPrefix}.filterTypeCreate`) },
            { type: UserPermissionType.UserDefinedWorkflow, name: this._translate.instant(`${keyPrefix}.filterTypeWorkflows`) }
        ];

        this.initializeTemplates();
    }

    public getPermissionName(permission: UserPermission): string {
        return LanguageUtility.getLocalizedContent(this.language, permission.names);
    }

    public getAnimationState(template: PermissionTemplate): string {
        return this.expandedTemplate === template ? 'expanded' : 'collapsed';
    }

    public getRestrictions(template: PermissionTemplate): string[] {
        const parameters = this.getAllowedPermission(template)?.parameters ?? {};

        return Object.keys(parameters).sort().filter(_ => parameters[_].length);
    }

    public toggleExpand(template: PermissionTemplate): void {
        if (!this.isDisabled && template.isAllowed) {
            this.expandedTemplate = this.expandedTemplate === template ? null : template;
        }
    }

    public onPermissionToggle(template: PermissionTemplate): void {
        if (template.isAllowed) {
            this.update.emit([...this.role.userPermissions, JSON.parse(JSON.stringify(template))]);
        }
        else {
            this.expandedTemplate = this.expandedTemplate === template ? null : this.expandedTemplate;
            this.update.emit(this.role.userPermissions.filter(_ => !UserPermission.areSame(_, template)));
        }
    }

    public onAllPermissionToggle(isToggleOn: boolean): void {
        let permissions = this.role.userPermissions.slice();

        for (const template of this.visibleTemplates) {
            if (!template.isAllowed && isToggleOn) {
                permissions.push(JSON.parse(JSON.stringify(template)));
            }
            else if (template.isAllowed && !isToggleOn) {
                permissions = permissions.filter(_ => !UserPermission.areSame(_, template));
            }

            template.isAllowed = isToggleOn;
        }

        this.expandedTemplate = isToggleOn ? this.expandedTemplate : null;
        this.update.emit(permissions);
    }

    public getParameterSets(template: PermissionTemplate): ParameterSet[] {
        const allowed = this.getAllowedPermission(template);

        if (!allowed) {
            return [];
        }

        const validKeys = Object.keys(template.parameters).sort().filter(_ => this.parameterOptions[_]);

        return validKeys.map(key => {
            const selected = allowed.parameters[key] ?? [];

            const options = this.parameterOptions[key].map(_ => ({
                name: _.code,
                // empty selection means every value is allowed
                checked: selected.length ? selected.includes(_.code) : true,
                displayText: _.description
            } as CheckboxData));

            return { key, options };
        });
    }

    public onParameterChange(template: PermissionTemplate, parameterSet: ParameterSet): void {
        const { key, options } = parameterSet;
        const allowed = this.getAllowedPermission(template);

        if (options.every(_ => _.checked)) {
            // send empty selection to indicate every value is allowed
            allowed.parameters[key] = [];
        }
        else {
            allowed.parameters[key] = options.filter(_ => _.checked).map(_ => _.name);
        }

        this.update.emit(this.role.userPermissions.slice());
    }

    private initializeTemplates(): void {
        this.templates.sort((a, b) => {
            const [nameA, nameB] = [this.getPermissionName(a), this.getPermissionName(b)];

            if (nameA === nameB) {
                return 0;
            }

            return nameA < nameB ? -1 : 1;
        });

        for (const template of this.templates) {
            template.isAllowed = this.role.userPermissions.some(_ => UserPermission.areSame(_, template));
        }
    }

    private getAllowedPermission(template: PermissionTemplate): UserPermission | null {
        return this.role.userPermissions.find(_ => UserPermission.areSame(_, template));
    }
}
