<div class="expandable-button-container">
    <button mat-raised-button
        [ngClass]="{ 'button-expand-left': expandToLeft, 'button-expand-right': !expandToLeft }"
        fxLayout="row"
        fxLayoutAlign="start center"
        [style.background-color]="backgroundColor">

        <mat-icon>{{ icon }}</mat-icon>
        <span class="expandable-button-text">{{ text }}</span>
    </button>
</div>
