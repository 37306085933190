import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import MaterialItem from '../../../data-models/job-materials/material-item';
import JsonUtility from '../../utility/json-utility.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MaterialHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/material`;

    constructor(private _http: HttpClient) { }

    public async getAllMaterials(): Promise<MaterialItem[]> {
        try {
            const endpoint = `${this._api}/all/0/0`;
            const data = await this._http.get<MaterialItem[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async createMaterial(data: MaterialItem): Promise<boolean> {
        try {
            const endpoint = `${this._api}/create`;
            await this._http.post(endpoint, data).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async updateMaterial(data: MaterialItem): Promise<boolean> {
        try {
            const endpoint = `${this._api}/update`;
            await this._http.put(endpoint, data).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async deleteMaterial(id: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/remove/${id}`;
            await this._http.delete(endpoint).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }
}
