export default class QuoteBasicInfo {
    public name = '';
    public quoteId = '';
    public branch = '';
    public salesCode = '';
    public status = '';
    public originProjectId = '';
    public destinationProjectId = '';
    public createdOn = '';
    public noFollowUp = false;
    public isUrgent = false;
    public address1 = '';
    public address2 = '';
    public city = '';
    public state = '';
    public country = '';
    public zip = '';
    public addressString = '';
    public geoCode = '';
}
