<div class="quote-content-editor-container">
    <div fxLayout="column">
        <textarea [id]="editorId"></textarea>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
        <button *ngFor="let option of customButtonOptions" (click)="addPlaceholder(option.identifier, option.placeholder)">
            {{ option.name }}
        </button>

        <mat-select [value]="'unselected'" (selectionChange)="onTemplateSelected($event.value)">
            <mat-option disabled value="unselected">{{ 'shared.quote.editor.selectTemplate' | translate }}</mat-option>

            <mat-option *ngFor="let template of templates" [value]="template.title">
                {{ template.title }}
            </mat-option>
        </mat-select>
    </div>
</div>
