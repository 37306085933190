export default class BudgetToolFeatureDto {
    public useLegacyBudgetTool: boolean;
    public hasExistingLegacyBudget: boolean;
    public hasExistingWebBudget: boolean;
    public useSubcontractRates: boolean;

    constructor(useLegacyBudgetTool = false, hasExistingLegacyBudget = false, hasExistingWebBudget = false, useSubcontractRates = false) {
        this.useLegacyBudgetTool = useLegacyBudgetTool;
        this.hasExistingLegacyBudget = hasExistingLegacyBudget;
        this.hasExistingWebBudget = hasExistingWebBudget;
        this.useSubcontractRates = useSubcontractRates;
    }
}
