/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import KeyValuePair from '../../../data-models/key-value-pair';
import TemplateFieldMeta from '../../../data-models/template-field-meta';
import JsonUtility from '../../utility/json-utility.service';
import ProjectSummary from '../../../data-models/summary-info/project-summary';
import SummaryQuery from '../../../data-models/summary-info/summary-query';
import SummaryQueryResult from '../../../data-models/summary-info/summary-query-result';
import ProjectGeneralInfo from '../../../data-models/project-general-info/project-general-info';
import ProjectAttributesWithUsCheck from '../../../data-models/project-attributes/project-attributes-us-check';
import TempProjectReport from '../../../data-models/data-report/temp-project-report';
import GeoCoordinate from '../../../data-models/geo-coordinate';
import SwapProjectData from '../../../data-models/swap-project-data';
import ProjectQuestions from '../../../data-models/project-attributes/project-questions';
import LaborSummary from '../../../data-models/labor/labor-summary';
import Employee from '../../../data-models/employee';
import KpiChangeRequest from '../../../data-models/kpi-change-request';
import TimelineEvent from '../../../data-models/timeline-event';
import ClientFastData from '../../../data-models/client-fast-data';
import ProjectHistory from '../../../data-models/project-history';
import ProjectRequestBase from '../../../data-models/project-request-base';
import { LaborProjectValidationErrors } from '../../../enums/labor-project-validation-errors.enum';
import { DynamicsProjectStatus } from '../../../enums/dynamics-project-status.enum';
import MergedProjectData from '../../../data-models/merged-project-data';
import ProjectCounters from '../../../data-models/project-counters';
import BranchTimezone from '../../../data-models/branch-timezone';
import ProjectAttributesDropdown from '../../../data-models/project-attributes-dropdowns';
import BranchReview from '../../../data-models/project-review/branch-review';
import ProjectSumDto from '../../../data-models/dto-models/project-sum-dto';
import ProjectIdSummary from '../../../data-models/project-id-summary';
import UserComment from '../../../data-models/user-comment/user-comment';
import UpdateProjectDto from '../../../data-models/dto-models/update-project-dto';
import ProjectSafetyTemplateMetaDto from '../../../data-models/dto-models/project-safety-template-meta-dto';
import CodeDescription from '../../../data-models/code-description';
import WorkOrderUpdateDto from '../../../data-models/dto-models/work-order-update-dto';
import ReportPrintingOptions from '../../../data-models/project-options/report-printing-options';
import ProjectActionOptions from '../../../data-models/project-options/project-action-options';
import ProjectReviewOptions from '../../../data-models/project-options/project-review-options';
import ProjectDispatchOptions from '../../../data-models/project-options/project-dispatch-options';
import { NewRelicUtilityService } from '../../utility/new-relic-utility.service';
import DispatchExistingOptions from '../../../data-models/project-general-info/dispatch-existing-options';
import ProjectCovidScreeningResult from '../../../data-models/data-report/project-covid-screening-result';
import ProjectCovidScreeningResultQuery from '../../../data-models/data-report/project-covid-screening-result-query';
import { DynamicsHoldStatus } from '../../../enums/dynamics-hold-status.enum';
import EditAttributesDto from '../../../data-models/project-attributes/edit-attributes-dto';
import ProjectQuestionsGrid from '../../../data-models/project-attributes/project-questions-grid';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectHttpService {
    private readonly _api = `${environment.fswApiUrl}api2/service/project`;

    constructor(
        private _translate: TranslateService,
        private _http: HttpClient,
        private _newrelicService: NewRelicUtilityService) { }

    public async getSummaries(query: SummaryQuery): Promise<SummaryQueryResult<ProjectSummary>> {
        try {
            const endpoint = `${this._api}/summaries`;
            const data = await this._http.post(endpoint, query).toPromise();

            return { ...JsonUtility.toCamelCaseKeys(data), pageSize: query.pageSize };
        }
        catch {
            return { ...new SummaryQueryResult<ProjectSummary>(), pageSize: query.pageSize };
        }
    }

    public async getProjectSummary(projectId: string): Promise<ProjectSumDto> {
        try {
            const endpoint = `${this._api}/summary/${projectId}`;
            const data = await this._http.get<ProjectSumDto>(endpoint).toPromise();
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async updateProjectInfo(info: UpdateProjectDto, jobId: number): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/update/projectInfo/${jobId}`;
            const result = await this._http.put<Array<number>>(endpoint, info).toPromise();

            if (result?.length === 0) {
                const okMessage = this._translate.instant('core.httpServices.project.ok');
                return new CodeDescription('200', okMessage);
            }

            if (result.some(_ => _ === 0)) {
                const errorMessage = this._translate.instant('core.httpServices.project.invalidData');
                return new CodeDescription('400', errorMessage);
            }
            else if (result.some(_ => _ === 1)) {
                const errorMessage = this._translate.instant('core.httpServices.project.failedToUpdaetSiteAddress');
                return new CodeDescription('400', errorMessage);
            }
            else if (result.some(_ => _ === 5)) {
                const errorMessage = this._translate.instant('core.httpServices.project.dynamicsOnHold');
                return new CodeDescription('400', errorMessage);
            }

            const errorMessage = this._translate.instant('core.httpServices.project.failedToUpdaetBillingAddress');
            return new CodeDescription('400', errorMessage);

        }
        catch (err) {
            await this._newrelicService.addErrorEvent(err);
            const errorMessage = this._translate.instant('core.httpServices.project.failedToUpdaetProcessingRequest');
            return new CodeDescription('500', errorMessage);
        }
    }

    public async getProjectGeneralInfo(projectId: string, jobId = 0): Promise<ProjectGeneralInfo> {
        try {
            const endpoint = `${this._api}/${projectId}/general-info/${jobId}`;
            const data = await this._http.get<ProjectGeneralInfo>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getDispatchExistingOptions(projectId: string, isDispatch = false): Promise<DispatchExistingOptions> {
        try {
            const endpoint = `${this._api}/${projectId}/dispatch-existing-options`;
            const data = await this._http.get<DispatchExistingOptions>(endpoint).toPromise();
            data.isDispatch = isDispatch;
            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async mergeProjects(sourceProjectId: string,
        targetProjectId: string,
        jobReview: BranchReview,
        reasonNotUsingConversion: string
    ): Promise<CodeDescription> {
        try {

            const endpoint = `${this._api}/merge/${sourceProjectId}/${targetProjectId}`;
            const data = { jobReview, reasonNotUsingConversion };

            const mergeSuccess = this._translate.instant('core.httpServices.project.mergeSuccessful');

            await this._http.put<void>(endpoint, data).toPromise();
            return new CodeDescription('200', mergeSuccess);

        }
        catch (error) {
            await this._newrelicService.addErrorEvent(error);
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 404:
                case 406:
                case 400:
                case 403: return new CodeDescription(httpError.status.toString(), httpError.error);
                default: return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async getProjectsCustomerInfo(projectId: string, jobId: number): Promise<UpdateProjectDto> {
        try {

            const endpoint = `${this._api}/project-update-info/${projectId}/${jobId}`;
            const data = await this._http.get<UpdateProjectDto>(endpoint).toPromise();
            return JsonUtility.toCamelCaseKeys(data);

        }
        catch {
            return null;
        }
    }

    public async updateProjectGeneralInfo(projectId: string, data: KeyValuePair): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${projectId}/general-info`;

            return await this._http.put<boolean>(endpoint, data).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getWorkChecklistTemplateField(projectId: string, jobId = -1): Promise<TemplateFieldMeta> {
        return await this.getTemplateField(projectId, jobId, 'work-checklist');
    }

    public async getJobReportTemplateField(projectId: string, jobId = -1): Promise<TemplateFieldMeta> {
        return await this.getTemplateField(projectId, jobId, 'job-report');
    }

    public async getDailySummaryTemplateField(projectId: string, jobId = -1): Promise<TemplateFieldMeta> {
        return await this.getTemplateField(projectId, jobId, 'daily-summary');
    }

    public async getSafetySummaryTemplateField(projectId: string, jobId = -1): Promise<ProjectSafetyTemplateMetaDto> {
        try {
            const endpoint = `${this._api}/${projectId}/template-field/safety-summary/${jobId}`;
            const data = await this._http.get<ProjectSafetyTemplateMetaDto>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;

            switch (httpError.status) {
                case 400: return new ProjectSafetyTemplateMetaDto();
                default: return null;
            }
        }
    }

    public async getSummaryTemplateField(projectId: string, jobId = -1): Promise<TemplateFieldMeta> {
        return await this.getTemplateField(projectId, jobId, 'summary');
    }

    private async getTemplateField(projectId: string, jobId: number, type: string): Promise<TemplateFieldMeta> {
        try {
            const endpoint = `${this._api}/${projectId}/template-field/${type}/${jobId}`;
            const data = await this._http.get<TemplateFieldMeta>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getEditableTemplateField(projectId: string, meta: TemplateFieldMeta): Promise<TemplateFieldMeta> {
        try {
            const endpoint = `${this._api}/${projectId}/editable-template-field`;
            const data = await this._http.post<TemplateFieldMeta>(endpoint, meta).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async updateProjectTemplateField(projectId: string, meta: TemplateFieldMeta): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${projectId}/template-field`;

            return await this._http.put<boolean>(endpoint, meta).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getForemenOnProject(projectId: string): Promise<Employee[]> {
        try {
            const endpoint = `${this._api}/${projectId}/foremen`;
            const data = await this._http.get<Employee[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getProjectGeoLocation(projectId: string): Promise<GeoCoordinate> {
        try {
            const endpoint = `${this._api}/geocode/${projectId}`;
            const data = await this._http.get<GeoCoordinate>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getProjectAttributesUsCheck(projectId: string): Promise<ProjectAttributesWithUsCheck> {
        try {
            const endpoint = `${this._api}/getProjectAttributesUsCheck/${projectId}`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new ProjectAttributesWithUsCheck();
        }
    }

    public async getTempProject(branch : string) : Promise<TempProjectReport[]> {
        try {
            const endpoint = `${this._api}/get/tempprojectreport/${branch}`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch (error) {
            return new Array<TempProjectReport>();
        }
    }

    public async getProjectAttributes(projectId: string) : Promise<ProjectQuestions> {
        try {
            const endpoint = `${this._api}/attributes/${projectId}`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getProjectListForLaborChange(projectId: string) : Promise<ProjectSummary[]> {
        try {
            const endpoint = `${this._api}/list/projectsForLaborChange/${projectId}`;
            const data = await this._http.get(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<ProjectSummary>();
        }
    }

    public async validateLaborChange(source: string, target: string, labor: LaborSummary): Promise<LaborProjectValidationErrors> {
        try {
            const endpoint = `${this._api}/validate/laborChange/${source}/${target}`;

            return await this._http.post<LaborProjectValidationErrors>(endpoint, labor).toPromise();
        }
        catch {
            return LaborProjectValidationErrors.NotFound;
        }
    }

    public async getTimelineEvents(projectId: string): Promise<TimelineEvent[]> {
        try {
            const endpoint = `${this._api}/${projectId}/timeline`;
            const data = await this._http.get<TimelineEvent[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getClientFastData(projectId: string): Promise<ClientFastData> {
        try {
            const endpoint = `${this._api}/clientId/${projectId}`;

            return JsonUtility.toCamelCaseKeys(await this._http.get<ClientFastData>(endpoint).toPromise());
        }
        catch {
            return null;
        }
    }

    public async getProjectHistory(projectId: string): Promise<ProjectHistory[]> {
        try {
            const endpoint = `${this._api}/history/${projectId}`;
            const data = await this._http.get<ProjectHistory[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getProjectHistoryByQuoteId(quoteId: string): Promise<ProjectHistory[]> {
        try {
            const endpoint = `${this._api}/history-by-quote/${quoteId}`;
            const data = await this._http.get<ProjectHistory[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async requestKpiChange(request: KpiChangeRequest): Promise<boolean> {
        try {
            const endpoint = `${environment.fswApiUrl}api2/service/request/approve/${request.projectId}`;
            await this._http.post(endpoint, request).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async willBeAutoApproved(projectId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/willBeAutoApproved/${projectId}`;

            return await this._http.get<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async deleteProject(request: ProjectRequestBase): Promise<boolean> {
        try {
            const endpoint = `${environment.fswApiUrl}api2/service/review/project`;
            await this._http.request('delete', endpoint, { body: request }).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async getDynamicsProjectStatus(projectId: string): Promise<DynamicsProjectStatus> {
        try {
            const endpoint = `${this._api}/dynamics/status/${projectId}`;

            return await this._http.get<DynamicsProjectStatus>(endpoint).toPromise();
        }
        catch {
            return DynamicsProjectStatus.Inactive;
        }
    }

    public async getProjectStatus(projectId: string): Promise<number> {
        try {
            const endpoint = `${this._api}/status/${projectId}`;

            return await this._http.get<number>(endpoint).toPromise();
        }
        catch {
            return null;
        }
    }

    public async getProjectBillWith(projectId: string): Promise<string> {
        try {
            const endpoint = `${this._api}/projectBillWith/${projectId}`;

            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return null;
        }
    }

    public async swapProject(swapData: SwapProjectData): Promise<boolean> {
        try {
            const endpoint = `${this._api}/swapCodes`;
            await this._http.post(endpoint, swapData).toPromise();

            return true;
        }
        catch {
            return false;
        }
    }

    public async getMergedProjects(projectId: string): Promise<MergedProjectData[]> {
        try {
            const endpoint = `${this._api}/merged-projects/${projectId}`;
            const data = await this._http.get<MergedProjectData[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<MergedProjectData>();
        }
    }

    public async unmergeProjects(oldProjectId: string, currentProjectId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/unmerge/projectid/${oldProjectId}/${currentProjectId}`;

            return await this._http.put<boolean>(endpoint, '').toPromise();
        }
        catch {
            return false;
        }
    }

    public async getProjectCounters(projectId: string): Promise<ProjectCounters> {
        try {
            const endpoint = `${this._api}/${projectId}/counters`;
            const data = await this._http.get<ProjectCounters>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new ProjectCounters();
        }
    }

    public async getProjectTimeZone(projectId: string): Promise<BranchTimezone> {
        try {
            const endpoint = `${this._api}/${projectId}/timeZone`;
            const data = await this._http.get<BranchTimezone>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getProjectAttributesDropdowns(country: string): Promise<ProjectAttributesDropdown> {
        try {
            const endpoint = `${this._api}/attributes-dropdown/${country}`;
            const data = await this._http.get<ProjectAttributesDropdown>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new ProjectAttributesDropdown();
        }
    }

    public async getAllProjectIds(customerId: string): Promise<ProjectIdSummary[]> {
        try {
            const endpoint = `${this._api}/dynamics-ids/${customerId}`;
            const data = await this._http.get<ProjectIdSummary[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new Array<ProjectIdSummary>();
        }
    }

    public async getHistoryByGeoCode(geoCode: GeoCoordinate): Promise<ProjectHistory[]> {
        try {
            const latitude = `${geoCode.latitude}`.replace(/\./g, '-dot-');
            const longitude = `${geoCode.longitude}`.replace(/\./g, '-dot-');
            const endpoint = `${this._api}/history/${latitude}/${longitude}`;
            const data = await this._http.get<ProjectHistory[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getProjectComments(projectId: string): Promise<UserComment[]> {
        try {
            const endpoint = `${this._api}/${projectId}/comments`;
            const data = await this._http.get<UserComment[]>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async addProjectComment(projectId: string, content: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${projectId}/comments`;
            const headers = { 'content-type': 'application/json' };

            return await this._http.post<boolean>(endpoint, JSON.stringify(content), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async updateProjectComment(projectId: string, commentId: string, content: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${projectId}/comments/${commentId}`;
            const headers = { 'content-type': 'application/json' };

            return await this._http.put<boolean>(endpoint, JSON.stringify(content), { headers }).toPromise();
        }
        catch {
            return false;
        }
    }

    public async deleteProjectComment(projectId: string, commentId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/${projectId}/comments/${commentId}`;

            return await this._http.delete<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async isProcessedWithInvoice(projectId: string): Promise<boolean> {
        try {
            const endpoint = `${this._api}/processed-with-invoice/${projectId}`;

            return await this._http.get<boolean>(endpoint).toPromise();
        }
        catch {
            return false;
        }
    }

    public async updateWorkOrder(projectId: string, jobId: number, data: WorkOrderUpdateDto): Promise<boolean> {
        try {
            const endpoint = `${this._api}/update-work-order/${projectId}/${jobId}`;

            return await this._http.post<boolean>(endpoint, data).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getMergedProjectGeneralInfo(projectId: string): Promise<ProjectGeneralInfo> {
        try {
            const endpoint = `${this._api}/merged/${projectId}/general-info`;
            const data = await this._http.get<ProjectGeneralInfo>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return null;
        }
    }

    public async getProjectDispatchOptions(projectId: string): Promise<ProjectDispatchOptions> {
        try {
            const endpoint = `${this._api}/${projectId}/options/project-dispatch`;
            const data = await this._http.get<ProjectDispatchOptions>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new ProjectDispatchOptions();
        }
    }

    public async getProjectActionOptions(projectId: string): Promise<ProjectActionOptions> {
        try {
            const endpoint = `${this._api}/${projectId}/options/project-action`;
            const data = await this._http.get<ProjectActionOptions>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new ProjectActionOptions();
        }
    }

    public async getProjectReviewOptions(projectId: string): Promise<ProjectReviewOptions> {
        try {
            const endpoint = `${this._api}/${projectId}/options/project-review`;
            const data = await this._http.get<ProjectReviewOptions>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new ProjectReviewOptions();
        }
    }

    public async getProjectReportPrintingOptions(projectId: string): Promise<ReportPrintingOptions> {
        try {
            const endpoint = `${this._api}/${projectId}/options/report-printing`;
            const data = await this._http.get<ReportPrintingOptions>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return new ReportPrintingOptions();
        }
    }

    public async getCovidScreeningResult(query: ProjectCovidScreeningResultQuery): Promise<ProjectCovidScreeningResult[]> {
        try {
            const endpoint = `${this._api}/covid-screening-result`;
            const data = await this._http.post<ProjectCovidScreeningResult[]>(endpoint, query).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async getDynamicsHoldStatus(): Promise<DynamicsHoldStatus> {
        try {
            const endpoint = `${this._api}/dynamics/entries/status`;
            const isHold = await this._http.get<boolean>(endpoint).toPromise();

            return isHold ? DynamicsHoldStatus.Hold : DynamicsHoldStatus.Released;
        }
        catch {
            return DynamicsHoldStatus.Unavailable;
        }
    }

    public async updateDynamicsHoldStatus(status: boolean): Promise<boolean> {
        try {
            const endpoint = `${this._api}/dynamics/entries/hold/${status}`;

            return await this._http.post<boolean>(endpoint, null).toPromise();
        }
        catch {
            return false;
        }
    }

    public async convertHoldProjects(): Promise<boolean> {
        try {
            const endpoint = `${this._api}/dynamics/entries/convert/projects`;

            return await this._http.post<boolean>(endpoint, null).toPromise();
        }
        catch {
            return false;
        }
    }

    public async getAllProjectIdsAndNames(): Promise<string[]> {
        try {
            const endpoint = `${this._api}/getAllProjectIdsAndNames`;
            const data = await this._http.get<string>(endpoint).toPromise();

            return JsonUtility.toCamelCaseKeys(data);
        }
        catch {
            return [];
        }
    }

    public async addMileage(attribute: ProjectQuestionsGrid, projectId: string, reason: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/AddNewMileage`;
            const result = await this._http.post<boolean>(endpoint, { attribute, projectId, reason }).toPromise();
            return new CodeDescription(result ? '200' : '400', '');
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 400:
                case 403:
                case 404:
                    return new CodeDescription(httpError.status.toString(), httpError.error);
                default:
                    return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async updateMileage(attribute: ProjectQuestionsGrid, projectId: string, reason: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/saveMileage`;
            const result = await this._http.post<boolean>(endpoint, { attribute, projectId, reason }).toPromise();
            return new CodeDescription(result ? '200' : '400', '');
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 400:
                case 403:
                case 404:
                    return new CodeDescription(httpError.status.toString(), httpError.error);
                default:
                    return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async addRoomNBoard(attribute: ProjectQuestionsGrid, projectId: string, reason: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/AddNewRoomNBoard`;
            const result = await this._http.post<boolean>(endpoint, { attribute, projectId, reason }).toPromise();
            return new CodeDescription(result ? '200' : '400', '');
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 400:
                case 403:
                case 404:
                    return new CodeDescription(httpError.status.toString(), httpError.error);
                default:
                    return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async updateRoomNBoard(attribute: ProjectQuestionsGrid, projectId: string, reason: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/saveRoomNBoard`;
            const result = await this._http.post<boolean>(endpoint, { attribute, projectId, reason }).toPromise();
            return new CodeDescription(result ? '200' : '400', '');
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 400:
                case 403:
                case 404:
                    return new CodeDescription(httpError.status.toString(), httpError.error);
                default:
                    return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async addZonePay(attribute: ProjectQuestionsGrid, projectId: string, reason: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/AddNewZonePay`;
            const result = await this._http.post<boolean>(endpoint, { attribute, projectId, reason }).toPromise();
            return new CodeDescription(result ? '200' : '400', '');
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 400:
                case 403:
                case 404:
                    return new CodeDescription(httpError.status.toString(), httpError.error);
                default:
                    return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async updateZonePay(attribute: ProjectQuestionsGrid, projectId: string, reason: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/saveZonePay`;
            const result = await this._http.post<boolean>(endpoint, { attribute, projectId, reason }).toPromise();
            return new CodeDescription(result ? '200' : '400', '');
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 400:
                case 403:
                case 404:
                    return new CodeDescription(httpError.status.toString(), httpError.error);
                default:
                    return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async saveAttributes(attributes: EditAttributesDto, reason: string): Promise<CodeDescription> {
        const payload = {
            projectId: attributes.projectId,
            usProjectInfo: {
                usProjectTaxQuestions: attributes.usTaxQuestions,
                usEstimateForm: attributes.usProjectQuestions
            },
            projectQuestions: attributes.projectQuestions,
            reason
        };

        try {
            const endpoint = `${this._api}/saveSingleAttributes`;
            const result = await this._http.post<boolean>(endpoint, payload).toPromise();
            return new CodeDescription(result ? '200' : '400', '');
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 400:
                case 403:
                case 404:
                    return new CodeDescription(httpError.status.toString(), httpError.error);
                default:
                    return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async changeServiceProvider(projectId: string, newServiceProvider: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/change-service-provider/${projectId}/${newServiceProvider}`;
            const result = await this._http.post<boolean>(endpoint, {}).toPromise();
            return new CodeDescription(result ? '200' : '400', '');
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            const internalErrorMessage = this._translate.instant('core.httpServices.project.internalError');
            switch (httpError.status) {
                case 400:
                    return new CodeDescription(httpError.status.toString(), this._translate.instant('core.httpServices.project.changeServiceProviderNotAllowed'));
                case 403:
                    return new CodeDescription(httpError.status.toString(), this._translate.instant('core.httpServices.project.missingPermission'));
                default:
                    return new CodeDescription('500', internalErrorMessage);
            }
        }
    }

    public async getCustomerIdByProjectId(projectId: string): Promise<string> {
        try {
            const endpoint = `${this._api}/${projectId}/customer-id`;
            return await this._http.get<string>(endpoint).toPromise();
        }
        catch {
            return '';
        }
    }

    public async syncCustomerInfoFromErp(projectId: string): Promise<CodeDescription> {
        try {
            const endpoint = `${this._api}/${projectId}/sync-customer-from-erp`;
            const result = await this._http.post<string>(endpoint, null).toPromise();
            return new CodeDescription('200', result);
        }
        catch (error) {
            const httpError = error as HttpErrorResponse;
            return new CodeDescription(httpError.status.toString(), httpError.error);
        }
    }
}
