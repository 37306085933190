import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { FileSystemFileEntry } from 'ngx-file-drop';
import ProcessInvoiceData from '../../../../core/data-models/process-invoice-data';
import ProjectIdUtility from '../../../../core/services/utility/project-id-utility/project-id-utility.service';
import { FeatureFlagsApi } from '../../../../core/enums/feature-flags-api.enum';
import { FeatureFlagsHttpService } from '../../../../core/services/http/feature-flags-http/feature-flags-http.service';
import { ValidatorService } from '../../../../core/services/validator-service/validator-service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'invoice-upload-dialog',
    styleUrls: ['./invoice-upload-dialog.component.scss'],
    templateUrl: './invoice-upload-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class InvoiceUploadDialogComponent implements OnInit {
    public invoiceNumber: UntypedFormControl;
    public draftNumber: UntypedFormControl;
    public grossAmount: UntypedFormControl;
    public useErpInvoice: boolean;
    public invoiceFile: FileSystemFileEntry = null;

    // eslint-disable-next-line max-params
    constructor(private _dialog: MatDialogRef<InvoiceUploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public projectId: string,
        public translate: TranslateService,
        private _featureFlagHttp: FeatureFlagsHttpService,
        private _validatorService: ValidatorService,
        private _snackBar: MatSnackBar) { }

    get enableUpload(): boolean {
        return this.invoiceNumber.valid && this.draftNumber.valid && (this.grossAmount?.valid ?? true) && Boolean(this.invoiceFile);
    }

    private async setUseErpInvoiceFromFeatureFlagService(): Promise<boolean> {
        try {
            const branchCode = ProjectIdUtility.getBranchCode(this.projectId);
            this.useErpInvoice = await this._featureFlagHttp.evaluateFlagByBranch(FeatureFlagsApi.UseErpInvoice, branchCode);
            return this.useErpInvoice !== null;
        }
        catch {
            return false;
        }
    }

    public async ngOnInit(): Promise<void> {
        this._dialog.disableClose = true;

        if (!await this.setUseErpInvoiceFromFeatureFlagService()) {
            this._snackBar.open(this.translate.instant('projects.processProjectDialog.errGettingFeatureFlag'), this.translate.instant('snackbars.ok'));
            this.onClose(false);

            return;
        }

        this.invoiceNumber = this.useErpInvoice ?
            new UntypedFormControl('', [Validators.required, Validators.pattern(/\w+/)]) :
            new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-9]+$/)]);
        this.draftNumber = this.useErpInvoice ?
            new UntypedFormControl('', [Validators.required, Validators.pattern(/^\w{10,}/)]) :
            new UntypedFormControl('', [Validators.pattern(/^[a-zA-Z0-9]+$/)]);
        this.grossAmount = this.useErpInvoice ?
            null :
            new UntypedFormControl('', [Validators.required, this._validatorService.decimalValidator(2, 0)]);
    }

    public onClose(emit = true): void {
        if (!emit) {
            this._dialog.close();
        }
        else if (this.enableUpload) {
            const data = {
                invoiceNumber: this.invoiceNumber.value,
                draftNumber: this.draftNumber.value,
                grossAmount: this.grossAmount?.value,
                invoiceFile: this.invoiceFile
            } as ProcessInvoiceData;

            this._dialog.close(data);
        }
    }
}
