<mat-card fxFill>
    <mat-card-content fxLayout="column" fxFill>
        <div fxLayout="column" fxFlex *ngIf="isLoaded" class="main-terms-of-service-container">
            <pdf-viewer
                [src]="pdfUrl"
                [render-text]="true"
                [show-borders]="true"
                style="height: inherit">
            </pdf-viewer>
        </div>
        <div fxLayout="column" fxFlex *ngIf="!isLoaded" fxLayoutAlign="center center">
            <dots-loader></dots-loader>
        </div>
    </mat-card-content>
</mat-card>
