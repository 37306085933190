import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { TypeRecord } from '../../../../core/data-models/generic/type-record';
import { BranchServiceRateHistory } from '../../../../core/data-models/rates/service-rates/branch-service-rate-history';

@Component({
    selector: 'app-branch-service-rates-changes-display',
    styleUrls: ['./branch-service-rates-changes-display.component.scss'],
    templateUrl: './branch-service-rates-changes-display.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BranchServiceRatesChangesDisplayComponent {
    public readonly percentPipeConfig = '0.0-2';

    constructor(@Inject(MAT_DIALOG_DATA) public changes: BranchServiceRateHistory[], private _dialogRef: MatDialogRef<BranchServiceRatesChangesDisplayComponent>) { }

    public getColumnWidth(change: BranchServiceRateHistory): number {
        const totalPredefinedFields = 4;

        return 100 / (totalPredefinedFields + change.rates.grossProfitPercentageRates.length);
    }

    public getProjectType(id: string, projectTypes: TypeRecord[]): string {
        return projectTypes.find(_ => _.identifier === id).name;
    }
}
