<div fxLayoutGap="10px" fxFlex>
    <div fxFlexOffset="2" fxFlex="75">
        <textarea [id]="editorId"></textarea>
    </div>
    <div [formGroup]="formGroup" fxLayoutGap="10px" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="20">
        <mat-form-field>
            <mat-label>{{ 'projects.executiveSummary.siteName' | translate }}</mat-label>
            <input matInput [(ngModel)]="summary.siteName" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'projects.executiveSummary.siteAddress' | translate }}</mat-label>
            <input matInput [(ngModel)]="summary.siteAddress" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'projects.executiveSummary.totalRoofArea' | translate }}</mat-label>
            <input type="number" matInput min="0" formControlName="totalRoofArea">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'projects.executiveSummary.estimatedRoofAge' | translate }}</mat-label>
            <input matInput [(ngModel)]="summary.estimatedRoofAge" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <mat-divider fxFlexOffset="95"></mat-divider>
        <div fxLayoutAlign="center center">
            <button mat-raised-button color="primary" (click)="save()">{{ 'projects.executiveSummary.save' | translate }}</button>
        </div>
    </div>
</div>