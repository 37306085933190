import { Component, ChangeDetectionStrategy, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import ProjectBasicInfo from '../../../core/data-models/project-general-info/project-basic-info';
import CustomerInfo from '../../../core/data-models/project-general-info/customer-info';
import ContactInfo from '../../../core/data-models/contact-info';
import JobContactInfo from '../../../core/data-models/project-general-info/job-contact-info';
import ProjectGeneralInfo from '../../../core/data-models/project-general-info/project-general-info';
import KeyValuePair from '../../../core/data-models/key-value-pair';
import TemplateFieldMeta from '../../../core/data-models/template-field-meta';
import GridItemEditOption from '../../../core/data-models/grid-item-edit-option';
import ActionMenuItemOption from '../../../core/data-models/action-menu-item-option';
import InformationGridItem from '../../../core/data-models/information-grid/information-grid-item';
import InformationGridRow from '../../../core/data-models/information-grid/information-grid-row';
import InformationGridConfig from '../../../core/data-models/information-grid/information-grid-config';
import { PhoneDisplayFormatPipe } from '../../pipes/phone-display-format/phone-display-format.pipe';
import { CustomerIdPlaceholderPipe } from '../../pipes/customer-id-placeholder/customer-id-placeholder.pipe';
import { ValidatorFactory } from '../../../core/services/validator-factory/validator-factory.service';
import { FeatureFlagService } from '../../../core/services/events/feature-flags/feature-flag.service';
import { GenericUtilityService } from '../../../core/services/utility/generic-utility/generic-utility.service';
import { FeatureFlags } from '../../../core/enums/feature-flags.enum';

@Component({
    selector: 'subcontract-general-info-panel',
    styleUrls: ['./subcontract-general-info-panel.component.scss'],
    templateUrl: './subcontract-general-info-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubcontractGeneralInfoPanelComponent implements OnInit {
    @Input() public generalInfo: ProjectGeneralInfo;
    @Input() public hasAnimation = true;
    @Input() public isReadonly = false;
    @Input() public actionOptions: ActionMenuItemOption[] = [];
    @Output() public actionSelect = new EventEmitter<ActionMenuItemOption>();
    @Output() public basicInfoUpdate = new EventEmitter<KeyValuePair>();
    @Output() public customerUpdate = new EventEmitter<KeyValuePair>();
    @Output() public dispatchInfoProjectFieldUpdate = new EventEmitter<KeyValuePair>();
    @Output() public reportingContactUpdate = new EventEmitter<KeyValuePair>();
    @Output() public siteContactUpdate = new EventEmitter<TemplateFieldMeta>();
    public basicInfoCardConfig: InformationGridConfig<ProjectBasicInfo>;
    public customerCardConfig: InformationGridConfig<CustomerInfo>;
    public workOrderCardConfig: InformationGridConfig<ProjectBasicInfo>;
    public rejectionReasonCardConfig: InformationGridConfig<{ reason: string }>;
    public reportingContactCardConfig: InformationGridConfig<ContactInfo>;
    public siteContactCardConfig: InformationGridConfig<JobContactInfo>;
    public billingContactCardConfig: InformationGridConfig<ContactInfo>;
    public showCustomerDetails = false;
    private _isLoaded = false;

    //eslint-disable-next-line
    constructor(public translate: TranslateService,
                private _featureFlagService: FeatureFlagService,
                private _validatorFactory: ValidatorFactory,
                private _phoneDisplayFormatPipe: PhoneDisplayFormatPipe,
                private _customerIdPlaceholderPipe: CustomerIdPlaceholderPipe,
                private _changeDetectorRef: ChangeDetectorRef) { }

    get isLoaded(): boolean {
        return this._isLoaded;
    }

    public ngOnInit(): void {
        const { basicInfo, customer } = this.generalInfo;
        const { reportingContact, siteContact, billingContact } = this.generalInfo;
        this.basicInfoCardConfig = this.createBasicInfoCardConfig(basicInfo);
        this.customerCardConfig = this.createCustomerCardConfig(customer);
        this.workOrderCardConfig = this.createWorkOrderCardConfig(basicInfo);
        this.reportingContactCardConfig = this.createContactCardConfig(reportingContact, true, true);
        this.siteContactCardConfig = this.createContactCardConfig(siteContact);
        this.billingContactCardConfig = this.createContactCardConfig(billingContact);
        this._isLoaded = true;
        this._changeDetectorRef.markForCheck();
    }

    public onItemDoubleClick(key: string): void {
        if (key === 'workOrder') {
            this.dispatchInfoProjectFieldUpdate.emit({ key: 'workOrder' } as KeyValuePair);
        }
    }

    private createBasicInfoCardConfig(basicInfo: ProjectBasicInfo): InformationGridConfig<ProjectBasicInfo> {
        const projectNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.projectName');
        const userDefinedProjectIdLabel = this.translate.instant('shared.projectGeneralInfoPanel.userDefinedProjectId');
        const projectIdLabel = this.translate.instant('shared.projectGeneralInfoPanel.projectId');
        const followUpToLabel = this.translate.instant('shared.projectGeneralInfoPanel.followUpTo');
        const originateQuoteLabel = this.translate.instant('shared.projectGeneralInfoPanel.originalQuote');
        const address1Label = this.translate.instant('shared.projectGeneralInfoPanel.address1');
        const address2Label = this.translate.instant('shared.projectGeneralInfoPanel.address2');
        const cityLabel = this.translate.instant('shared.projectGeneralInfoPanel.city');
        const stateLabel = this.translate.instant('shared.projectGeneralInfoPanel.state');
        const countryLabel = this.translate.instant('shared.projectGeneralInfoPanel.country');
        const zipLabel = this.translate.instant('shared.projectGeneralInfoPanel.zip');
        const reviewerNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.reviewerName');
        const reviewerCommentLabel = this.translate.instant('shared.projectGeneralInfoPanel.reviewerComment');
        const processorNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.processorName');
        const processorCommentLabel = this.translate.instant('shared.projectGeneralInfoPanel.processorComment');

        const projectName = new InformationGridItem(projectNameLabel, 'projectName', 53);
        const userDefinedProjectId = new InformationGridItem(userDefinedProjectIdLabel, 'userDefinedProjectId', 53);
        const projectId = new InformationGridItem(projectIdLabel, 'projectId', 46.5);
        const followUpTo = new InformationGridItem(followUpToLabel, 'followUpTo', 53);
        const originateQuote = new InformationGridItem(originateQuoteLabel, 'originateQuote', 46.5);
        const address1 = new InformationGridItem(address1Label, 'address1', 53);
        const address2 = new InformationGridItem(address2Label, 'address2', 46.5);
        const city = new InformationGridItem(cityLabel, 'city', 26.5);
        const state = new InformationGridItem(stateLabel, 'state', 26.5);
        const country = new InformationGridItem(countryLabel, 'country', 23.25);
        const zip = new InformationGridItem(zipLabel, 'zip', 23.25);
        const reviewerName = new InformationGridItem(reviewerNameLabel, 'reviewerName', 29.75);
        const reviewerComment = new InformationGridItem(reviewerCommentLabel, 'reviewerComment', 69.5);
        const processorName = new InformationGridItem(processorNameLabel, 'processorName', 29.75);
        const processorComment = new InformationGridItem(processorCommentLabel, 'processorComment', 69.5);
        const useQuoteFeatures = this._featureFlagService.featureFlags[FeatureFlags.UseQuotesFeature];

        if (!this.isReadonly) {
            userDefinedProjectId.editOptions = new GridItemEditOption();
        }

        return new InformationGridConfig(basicInfo, [
            new InformationGridRow([projectName, projectId]),
            useQuoteFeatures ? new InformationGridRow([followUpTo, originateQuote]) : null,
            new InformationGridRow([address1, address2]),
            new InformationGridRow([city, state, country, zip]),
            new InformationGridRow([reviewerName, reviewerComment]),
            new InformationGridRow([processorName, processorComment])
        ].filter(Boolean));
    }

    private createCustomerCardConfig(customer: CustomerInfo): InformationGridConfig<CustomerInfo> {
        const customerNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.customerName');
        const siteNameLabel = this.translate.instant('shared.projectGeneralInfoPanel.siteName');
        const customerIdLabel = this.translate.instant('shared.projectGeneralInfoPanel.customerId');
        const siteIdLabel = this.translate.instant('shared.projectGeneralInfoPanel.siteId');
        const storeNumberLabel = this.translate.instant('shared.projectGeneralInfoPanel.storeNumber');
        const customerPoLabel = this.translate.instant('shared.projectGeneralInfoPanel.customerPo');

        const customerName = new InformationGridItem(customerNameLabel, 'customerName');
        const siteName = new InformationGridItem(siteNameLabel, 'siteName');
        const customerId = new InformationGridItem(customerIdLabel, 'customerId', 50);
        const siteId = new InformationGridItem(siteIdLabel, 'siteId', 50);
        const storeNumber = new InformationGridItem(storeNumberLabel, 'storeNumber', 50);
        const customerPo = new InformationGridItem(customerPoLabel, 'customerPo', 50);
        customerId.displayPipe = this._customerIdPlaceholderPipe;

        const rows = [
            new InformationGridRow([customerName]),
            new InformationGridRow([siteName]),
            new InformationGridRow([customerId, siteId]),
            new InformationGridRow([customerPo, storeNumber])
        ];

        return new InformationGridConfig(customer, rows);
    }


    private createWorkOrderCardConfig(basicInfo: ProjectBasicInfo): InformationGridConfig<ProjectBasicInfo> {
        const workOrderLabel = this.translate.instant('shared.projectGeneralInfoPanel.workOrder');
        const workOrder = new InformationGridItem(workOrderLabel, 'workOrder', 100, true, true);

        const rows = [
            new InformationGridRow([workOrder], 100)
        ];

        return new InformationGridConfig(basicInfo, rows);
    }

    private createContactCardConfig<T>(contact: T, isEditable = false, isMobileEditable = false): InformationGridConfig<T> {
        const nameLabel = this.translate.instant('shared.projectGeneralInfoPanel.name');
        const emailLabel = this.translate.instant('shared.projectGeneralInfoPanel.email');
        const officePhoneLabel = this.translate.instant('shared.projectGeneralInfoPanel.officePhone');
        const mobilePhoneLabel = this.translate.instant('shared.projectGeneralInfoPanel.mobilePhone');

        const name = new InformationGridItem(nameLabel, 'name');
        const email = new InformationGridItem(emailLabel, 'email');
        const officePhone = new InformationGridItem(officePhoneLabel, 'officePhone', 50);
        const mobilePhone = new InformationGridItem(mobilePhoneLabel, 'mobilePhone', 50);
        const emailValidator = this._validatorFactory.createEmailValidator();
        const phoneValidator = this._validatorFactory.createPhoneValidator();
        officePhone.displayPipe = this._phoneDisplayFormatPipe;
        mobilePhone.displayPipe = this._phoneDisplayFormatPipe;

        if (!this.isReadonly && isEditable) {
            name.editOptions = { maxLength: 40 } as GridItemEditOption;
            email.editOptions = { maxLength: 40, validatorSets: [emailValidator] } as GridItemEditOption;
            officePhone.editOptions = {
                validatorSets: [phoneValidator],
                inputMask: GenericUtilityService.getPhoneMaskStringWithExtension()
            } as GridItemEditOption;
        }

        if (!this.isReadonly && isMobileEditable) {
            mobilePhone.editOptions = {
                validatorSets: [phoneValidator],
                inputMask: GenericUtilityService.getPhoneMaskString()
            } as GridItemEditOption;
        }

        const rows = [
            new InformationGridRow([name]),
            new InformationGridRow([email]),
            new InformationGridRow([officePhone, mobilePhone])
        ];

        return new InformationGridConfig(contact, rows);
    }
}
