import { Component, Output, Input, EventEmitter, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import CustomerSelectionStepConfig from '../../../../core/data-models/dispatch/customer-selection-step-config';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ValidatorFactory } from '../../../../core/services/validator-factory/validator-factory.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CustomerManagementComponent } from '../../../../shared/components/customer-management/customer-management.component';
import { UserHttpService } from '../../../../core/services/http/user-http/user-http.service';
import CustomerSelectionDto from '../../../../core/data-models/customer-selection-dto';
import CustomerSiteDto from '../../../../core/data-models/customer-site-dto';
import { AppConfigHttpService } from '../../../../core/services/http/appconfig-http/appconfig-http.service';
import { GoogleMapsUtilityService } from '../../../../core/services/utility/google-maps-utility/google-maps-utility.service';
import CustomerUtility from '../../../../core/services/utility/customer-utility/customer-utility.service';
import { CustomerHttpService } from '../../../../core/services/http/customer-http/customer-http.service';
import CustomerSiteDispatchInfo from '../../../../core/data-models/dispatch/customer-site-dispatch-info';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import ActionProgressReporterOption from '../../../../core/data-models/action-progress-reporter-option';
import { CustomerIdPlaceholderPipe } from '../../../../shared/pipes/customer-id-placeholder/customer-id-placeholder.pipe';
//eslint-disable-next-line
import { ActionProgressDialogComponent } from '../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagService } from '../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../core/enums/feature-flags.enum';
import { WorkCategory } from '../../../../core/data-models/work-category';

@Component({
    selector: 'app-customer-selection-step',
    styleUrls: ['./customer-selection-step.component.scss'],
    templateUrl: './customer-selection-step.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CustomerSelectionStepComponent implements OnInit {
    @Input() public config: CustomerSelectionStepConfig;
    @Output() public stepClicked = new EventEmitter<string>();
    @Output() public siteSelected = new EventEmitter<google.maps.LatLngLiteral>();
    @Output() public customerSelected = new EventEmitter<CustomerSelectionDto>();
    @ViewChild('streetViewMap', { static: true }) streetViewMap: ElementRef;
    @ViewChild('map', { static: true }) map: ElementRef;
    public mapControl: google.maps.Map;
    public marker: google.maps.Marker;
    public customerInfoForm: UntypedFormGroup;
    public center: google.maps.LatLngLiteral;
    public mapOptions: google.maps.MapOptions;
    public siteGeoCodeOnly = false;
    public allStates: string[] = [];
    public streetViewPanorama: string;
    public confirmedCustomer = false;
    private phoneValidator = this._validatorFactory.createPhoneValidator();
    private emailValidator = this._validatorFactory.createEmailValidator();
    private addressControls = ['address1', 'city', 'state', 'zipCode'];
    private signNumberControls = ['signInOutNumber', 'signInOutCode'];
    private _isInternal = false;

    // Temporary: See Aha FSW-3056 - We want to remove 'isSubcontract' etc in favour of configurable flags from the backend.
    get isSubcontract(): boolean {
        return this.config.workCategory === WorkCategory.Subcontract;
    }

    //eslint-disable-next-line
    public constructor(
        private _translate: TranslateService,
        private _userService: UserHttpService,
        private _appConfigService: AppConfigHttpService,
        private _customerService: CustomerHttpService,
        private _customerIdPlaceholderPipe: CustomerIdPlaceholderPipe,
        private _formBuilder: UntypedFormBuilder,
        private _validatorFactory: ValidatorFactory,
        private _dialog: MatDialog,
        private _googleMapUtility: GoogleMapsUtilityService,
        private _featureFlagService: FeatureFlagService) {
    }

    public async ngOnInit(): Promise<void> {
        this._isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
        this.allStates = await this._appConfigService.getNAStates();
        this.loadCustomerForm();
        await this.initMaps();
        if (this.config.customerPerSelected) {
            const { reportingContact, reportingPhone, reportingEmail } = this.config.customerDispatchInfo;
            await this.loadSelectedCustomerInfo(this.config.customerData);
            this.setReportingContactInfo(reportingContact, reportingPhone, reportingEmail);
        }
        this.finalizeFormLoad();

        if (this.config.customerForSuggestions) {
            const { reportingContact, reportingPhone, reportingEmail } = this.config.customerDispatchInfo;
            await this.openCustomerSelection();
            this.setReportingContactInfo(reportingContact, reportingPhone, reportingEmail);
        }

        if (!this._isInternal) {
            const currentUser = await this._userService.getUserInfo();
            this.setReportingContactInfo(currentUser.name, currentUser.phoneNumber, currentUser.email);
        }
    }

    public setCompleteAndMove(): void {
        this.fillFormData();
        setTimeout(() => this.stepClicked.emit('next'));
    }

    public async openCustomerSelection(): Promise<void> {
        const dialog = this._dialog.open(CustomerManagementComponent,
            {
                data: this.config.customerForSuggestions,
                height: '95vh',
                width: '95vw',
                maxWidth: '95vw'
            });

        const selectedCustomer = await dialog.afterClosed().toPromise();
        if (selectedCustomer) {
            this.config.customerData = selectedCustomer;
            await this.loadSelectedCustomerInfo(selectedCustomer);
        }
    }

    private loadCustomerForm(): void {
        this.customerInfoForm = this._formBuilder.group({
            customerId: [''],
            customerSiteName: [''],
            address1: ['', Validators.required],
            address2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zipCode: ['', Validators.required],
            siteContact: [''],
            contactPhone: ['', this.phoneValidator.validator],
            contactEmail: ['', this.emailValidator.validator],
            billingAttn: [''],
            billingPhone: ['', this.phoneValidator.validator],
            billingEmail: ['', this.emailValidator.validator],
            reportingContact: [''],
            reportingPhone: ['', this.phoneValidator.validator],
            reportingEmail: ['', this.emailValidator.validator],
            signInOutNumber: [''],
            signInOutCode: ['']
        });
    }

    private async initMaps(): Promise<void> {
        const currentUser = await this._userService.getUserInfo();
        const branchGeoLocation = currentUser.branchInfo.geoLocation.location;
        this.center = { lng: branchGeoLocation[0], lat: branchGeoLocation[1] };
        this.streetViewPanorama = await this._googleMapUtility.getPanoramaByLocation(this.center, 50);
        const streetView = new google.maps.StreetViewPanorama(this.streetViewMap.nativeElement);
        streetView.setPano(this.streetViewPanorama);
        this.mapOptions = { zoom: 16, center: this.center, panControl: false, streetView };
        this.mapControl = new google.maps.Map(this.map.nativeElement, this.mapOptions);
        this.marker = new google.maps.Marker();
        this.marker.setPosition(this.center);
        this.marker.setMap(this.mapControl);
    }

    private async loadSelectedCustomerInfo(customerInfo: CustomerSelectionDto): Promise<void> {
        // eslint-disable-next-line no-undefined, max-len
        const customerId = this._customerIdPlaceholderPipe.transform(customerInfo.customer.customerId);
        this.setFormControlValue('customerId', customerId);
        this.setFormControlValue('customerSiteName', customerInfo.site.name);
        this.setFormControlValue('address1', customerInfo.site.address1);
        this.setFormControlValue('address2', customerInfo.site.address2);
        this.setFormControlValue('city', customerInfo.site.city);
        this.setFormControlValue('state', customerInfo.site.state);
        this.setFormControlValue('zipCode', customerInfo.site.zip);
        this.setFormControlValue('siteContact', customerInfo.site.siteAttention);
        this.setFormControlValue('contactPhone', customerInfo.site.sitePhone);
        this.setFormControlValue('contactEmail', customerInfo.site.siteEmail);
        this.setFormControlValue('billingAttn', customerInfo.billingSite.siteAttention);
        this.setFormControlValue('billingPhone', customerInfo.billingSite.sitePhone);
        this.setFormControlValue('billingEmail', customerInfo.billingSite.siteEmail);
        this.setFormControlValue('signInOutNumber', '');
        this.setFormControlValue('signInOutCode', '');

        if (this._isInternal) {
            this.setReportingContactInfo('', '', '');
        }

        this.confirmedCustomer = CustomerUtility.isConfirmedCustomer(customerInfo.customer.customerId);
        if (this.confirmedCustomer && !this.isSubcontract) {
            const signNumber = await this._customerService.getCustomerSignNumber(customerInfo.customer.customerId);
            const signInOutControl = this.getFormControl('signInOutNumber');
            this.setRequiredValidation(this.signNumberControls, Boolean(signNumber));
            signInOutControl.setValidators(signInOutControl.validator?
                [Validators.required, this.phoneValidator.validator] :
                this.phoneValidator.validator);
            this.setFormControlValue('signInOutNumber', signNumber);
        }
        await this.setSiteGeoCode(customerInfo.site);
        this.customerSelected.emit(customerInfo);
    }

    private async setSiteGeoCode(site: CustomerSiteDto): Promise<void> {
        if (site.geoCode && !site.address1) {
            this.siteGeoCodeOnly = true;
            const geoCode = site.geoCode.split(',');
            this.center = { lat: Number.parseFloat(geoCode[0]), lng: Number.parseFloat(geoCode[1]) };
            this.setRequiredValidation(this.addressControls, false);
        }
        else {
            this.setRequiredValidation(this.addressControls, true);
            this.siteGeoCodeOnly = false;
            let siteGeoCode = await this._googleMapUtility.getGeocodeByAddress(this.getSiteAddress(site));
            if (!siteGeoCode) {
                siteGeoCode = await this._googleMapUtility.getGeocodeByAddress(this.getSiteAddress(site, false));
            }

            if (siteGeoCode) {
                this.center = { lat: siteGeoCode.lat(), lng: siteGeoCode.lng() };
            }
            else {
                const action = of(false).toPromise();
                const error = this._translate.instant('dispatch.customerSelectionStep.reselectCustomerError');
                const ok = this._translate.instant('shared.ok');
                const gotIt = this._translate.instant('shared.gotIt');
                const dialogData = new ActionProgressReporterOption(action, '', '', error, ok, gotIt, true, 0);
                const dialog = this._dialog.open(ActionProgressDialogComponent, {
                    data: dialogData,
                    width: '550px',
                    height: '120px'
                });

                await dialog.afterClosed().toPromise();
                this.customerInfoForm.reset();
                return;
            }
        }
        this.streetViewPanorama = await this._googleMapUtility.getPanoramaByLocation(this.center, 50);
        this.mapOptions.streetView.setPano(this.streetViewPanorama);
        this.mapControl.setCenter(this.center);
        this.marker.setPosition(this.center);
        this.siteSelected.emit(this.center);
    }

    private setRequiredValidation(controls: string[], required = true): void {
        controls.forEach(_ => {
            if (required) {
                this.customerInfoForm.get(_).setValidators(Validators.required);
            }
            else {
                this.customerInfoForm.get(_).clearValidators();
            }
            this.customerInfoForm.get(_).updateValueAndValidity();
        });
    }

    private getSiteAddress(site: CustomerSiteDto, useAddress2 = true): string {
        let addressString = useAddress2 && site.address2? `${site.address1}, ${site.address2}` : site.address1;
        addressString = `${addressString}, ${site.city}, ${site.state}, ${site.country}`;

        return addressString;
    }

    private fillFormData(): void {
        const values = this.customerInfoForm.value;
        this.config.customerDispatchInfo = { ...values } as CustomerSiteDispatchInfo;
        this.config.completed = this.customerInfoForm.valid;
    }

    private setFormControlValue(controlName: string, value: string): void {
        const control = this.customerInfoForm.get(controlName);
        control.setValue(value);
        control.updateValueAndValidity();
    }

    private getFormControl(controlName: string): AbstractControl {
        return this.customerInfoForm.get(controlName);
    }

    private finalizeFormLoad(): void {
        this.customerInfoForm.markAllAsTouched();
        this.customerInfoForm.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged())
            .subscribe(() => this.fillFormData());
    }

    private setReportingContactInfo(reportingContact: string, reportingPhone: string, reportingEmail: string): void {
        this.setFormControlValue('reportingContact', reportingContact);
        this.setFormControlValue('reportingPhone', reportingPhone);
        this.setFormControlValue('reportingEmail', reportingEmail);
    }
}
