import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import Branch from '../../../core/data-models/branch';
import UserInfo from '../../../core/data-models/user-info';
import SalesCodeConfig from '../../../core/data-models/app-configs/sales-code-config';
import ProjectMergeRule from '../../../core/data-models/project-merge-rule';
import ConfirmDialogOption from '../../../core/data-models/confirm-dialog-option';
import { UserHttpService } from '../../../core/services/http/user-http/user-http.service';
import { AppConfigHttpService } from '../../../core/services/http/appconfig-http/appconfig-http.service';
import { BranchHttpService } from '../../../core/services/http/branch-http/branch-http.service';
import { ProjectMergeHttpService } from '../../../core/services/http/project-merge-http/project-merge-http.service';
import LanguageUtility from '../../../core/services/utility/language-utility/language-utility.service';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog-component/confirm-dialog.component';
import { NamePromptDialogComponent } from '../../../shared/components/dialogs/name-prompt-dialog/name-prompt-dialog.component';

@Component({
    selector: 'project-merge-rules',
    styleUrls: ['./project-merge-rules.component.scss'],
    templateUrl: './project-merge-rules.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectMergeRulesComponent implements OnInit {
    public user: UserInfo;
    public salesCodes: SalesCodeConfig[] = [];
    public branches: Branch[] = [];
    public rules: ProjectMergeRule[] = [];
    public selectedRule: ProjectMergeRule | null = null;
    public savingState = { isSaving: false, isSaved: true };
    // eslint-disable-next-line max-params
    constructor(private _userHttp: UserHttpService,
                private _appConfigHttp: AppConfigHttpService,
                private _branchHttp: BranchHttpService,
                private _projectMergeHttp: ProjectMergeHttpService,
                private _translate: TranslateService,
                private _changeDetectorRef: ChangeDetectorRef,
                private _snackBar: MatSnackBar,
                private _dialog: MatDialog) { }

    public async ngOnInit(): Promise<void> {
        this.user = await this._userHttp.getUserInfo();
        this.salesCodes = await this._appConfigHttp.getSalesCodeConfig();
        this.branches = await this._branchHttp.getAllBranches();
        await this.refreshRules();
        this._changeDetectorRef.markForCheck();
    }

    public async openNewRulePanel(): Promise<void> {
        const [width, height] = ['400px', '270px'];
        const data = this._translate.instant('admin.projectMergeRules.mergeRuleCreationPanelTitle');
        const dialog = this._dialog.open(NamePromptDialogComponent, { data, width, height });
        const name = await dialog.afterClosed().toPromise<string>();
        const language = this.user.language ?? 'en';
        const buttonText = this._translate.instant('snackbars.ok');

        if (!name) {
            return;
        }

        if (this.rules.some(_ => LanguageUtility.getLocalizedContent(language, _.names).toLowerCase() === name.toLowerCase())) {
            const key = 'admin.projectMergeRules.mergeRulesView.duplicateRuleNameErrorText';
            this._snackBar.open(this._translate.instant(key), buttonText);

            return;
        }

        if (!await this._projectMergeHttp.addMergeRule(name)) {
            const key = 'admin.projectMergeRules.mergeRulesView.failedToAddRuleErrorText';
            this._snackBar.open(this._translate.instant(key), buttonText);
        }
        else {
            await this.refreshRules();
        }

        this._changeDetectorRef.markForCheck();
    }

    public async closeRule(): Promise<void> {
        this.rules = [];
        await this.refreshRules();
        this.savingState = { isSaving: false, isSaved: true };
        this._changeDetectorRef.markForCheck();
    }

    public async replaceRule(rule: ProjectMergeRule): Promise<void> {
        const isSaved = await this._projectMergeHttp.replaceMergeRule(rule);

        if (!isSaved) {
            const key = 'admin.projectMergeRules.mergeRulesView.failedToUpdateRuleErrorText';
            const buttonText = this._translate.instant('snackbars.ok');
            this._snackBar.open(this._translate.instant(key), buttonText);
        }

        this.savingState = { isSaving: false, isSaved };
        this._changeDetectorRef.markForCheck();
    }

    public async deleteRule(rule: ProjectMergeRule): Promise<void> {
        const keyPrefix = 'admin.projectMergeRules.mergeRulesView.deleteRuleConfirmation';
        const title = this._translate.instant(`${keyPrefix}.title`);
        const message = this._translate.instant(`${keyPrefix}.message`);
        const confirmText = this._translate.instant(`${keyPrefix}.confirmButtonText`);
        const cancelText = this._translate.instant('shared.cancel');
        const data = new ConfirmDialogOption(title, message, true, confirmText, cancelText);
        const dialog = this._dialog.open(ConfirmDialogComponent, { data });

        if (!await dialog.afterClosed().toPromise()) {
            return;
        }

        if (!await this._projectMergeHttp.deleteMergeRule(rule.code)) {
            const key = 'admin.projectMergeRules.mergeRulesView.failedToDeleteRuleErrorText';
            const buttonText = this._translate.instant('snackbars.ok');
            this._snackBar.open(this._translate.instant(key), buttonText);
        }
        else {
            await this.refreshRules();
        }

        this._changeDetectorRef.markForCheck();
    }

    private async refreshRules(): Promise<void> {
        this.selectedRule = null;
        this.rules = await this._projectMergeHttp.getMergeRules();
        this._changeDetectorRef.markForCheck();
    }
}
