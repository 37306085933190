import { Component, Input, Output, EventEmitter } from '@angular/core';
import AdditionalInfoStepConfig from '../../../../core/data-models/dispatch/additional-info-step-config';
import ProjectQuestionValidCheck from '../../../../core/data-models/project-questions-valid-check';

@Component({
    selector: 'app-additional-info-step',
    styleUrls: ['./additional-info-step.component.scss'],
    templateUrl: './additional-info-step.component.html'
})
export class AdditionalInfoStepComponent {
    @Input() public config: AdditionalInfoStepConfig;
    @Output() public stepClicked = new EventEmitter<string>();
    private isValidProjectQuestions = true;
    private isValidProjectAttributes = true;

    public onProjectQuestionsResult(input: ProjectQuestionValidCheck): void {
        this.config.projectQuestions = input.projectQuestions;
        this.isValidProjectQuestions = input.valid;
    }

    public onProjectAttributesResult(input: ProjectQuestionValidCheck): void {
        this.config.projectQuestions = input.projectQuestions;
        this.isValidProjectAttributes = input.valid;
    }

    public validateAndMoveNext(): void {
        this.config.isValid = this.isValidProjectAttributes && this.isValidProjectQuestions;
        this.config.completed = !this.config.projectQuestions || this.config.isValid;
        setTimeout(() => this.stepClicked.emit('next'));
    }
}
