export enum ReportPlaceholderReportContent {
    FieldReport = 'field-report',
    RoofSections = 'roof-sections',
    TodayDate = 'today',
    ProjectAddress = 'project-address',
    ProjectId = 'project-id',
    TechName = 'tech-name',
    JobDate = 'job-date',
    ClientBuildingId = 'client-building-id'
}
