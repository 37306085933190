import CodeDesc from '../generic/code-description';

export default class ProjectUsEstimateForm {
    public workersCompExcempt = false;
    public generalLiabilityExcempt = false;
    public certifiedPayroll = false;
    public prevailingWage = false;
    public prevWgCalcMthd: CodeDesc = null;
    public minorityParticipation = false;
    public apprenticeParticipation = false;
    public ptoFederalJobs = false;
    public davisBacon = false;
    public nationalMaintenanceAgreement = false;
    public hazardousWastePremium = false;
}
