import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { ProjectHttpService } from '../../../../../core/services/http/project-http/project-http.service';
import { ProjectReviewHttpService } from '../../../../../core/services/http/project-review-http/project-review-http.service';
import { DynamicsProjectStatus } from '../../../../../core/enums/dynamics-project-status.enum';
import ProjectIdUtility from '../../../../../core/services/utility/project-id-utility/project-id-utility.service';
import ProjectSumDto from '../../../../../core/data-models/dto-models/project-sum-dto';
import BranchReview from '../../../../../core/data-models/project-review/branch-review';
import ActionProgressReporterOption from '../../../../../core/data-models/action-progress-reporter-option';
import { ActionProgressDialogComponent } from '../../../../../shared/components/dialogs/action-progress-dialog/action-progress-dialog.component';
import { ProjectMergeHttpService } from '../../../../../core/services/http/project-merge-http/project-merge-http.service';

@Component({
    selector: 'merge-project-dialog',
    styleUrls: ['./merge-project-dialog.component.scss'],
    templateUrl: './merge-project-dialog.component.html'
})
export class MergeProjectDialogComponent implements OnInit {

    public selectTargetProjectFormGroup: UntypedFormGroup;
    public targetJobReviewForm: UntypedFormGroup;
    public sourceJobReviewForm: UntypedFormGroup;
    public sourceProjectInfo: ProjectSumDto;
    public targetProjectInfo: ProjectSumDto;
    public sourceJobReview: BranchReview;
    public targetJobReview: BranchReview;
    public exceededAutoApproveTime: boolean;
    public acknowledgedAutoApproveRequirement = false;
    public sourceProjectId: string;
    public isSourceProjectInactive = true;
    public targetProjectSelected = false;
    public targetJobReviewSelected = true;
    public targetSalesCodes : string[] = [];
    public isLoaded = false;
    public isEmergencySourceProject: boolean;
    @ViewChild('stepper') stepper: MatStepper;


    // eslint-disable-next-line
    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _projectHttpService: ProjectHttpService,
        private _projectReviewHttpService: ProjectReviewHttpService,
        private _dialogService: MatDialog,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialogRef<MergeProjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { activeProjectId: string },
        public translate: TranslateService,
        private _projectMergeHttp: ProjectMergeHttpService
    ) {
        this.selectTargetProjectFormGroup = this._formBuilder.group({
            targetProjectId: ['', Validators.required],
            reason: ['', Validators.required]
        });

        this.sourceProjectId = data.activeProjectId;
        this.isEmergencySourceProject = ProjectIdUtility.isEmergency(this.sourceProjectId);
    }

    get targetProjectId(): string {
        return this.selectTargetProjectFormGroup.get('targetProjectId').value;
    }

    get onSelectReviewPage(): boolean {
        return this.stepper?.selectedIndex === 1;
    }

    public async ngOnInit(): Promise<void> {

        this.isSourceProjectInactive = await this.sourceProjectIsValid();
        if (this.isSourceProjectInactive) {
            this.exceededAutoApproveTime = await this.hasProjectExceededAutoApproveTime();
            this.sourceProjectInfo = await this._projectHttpService.getProjectSummary(this.sourceProjectId);
            this.sourceJobReview = await this._projectReviewHttpService.getBranchReview(this.sourceProjectId);

            const mappings = await this._projectMergeHttp.getConsolidatedMergeRulesMappings();
            this.targetSalesCodes = mappings
                .filter(code => code.source === this.sourceProjectInfo?.salesCode)
                .map(m => m.target);
        }

        if (!this.isEmergencySourceProject) {
            this.selectTargetProjectFormGroup.get('reason').setValidators(null);
            this.selectTargetProjectFormGroup.get('reason').updateValueAndValidity();
        }
        this.isLoaded = true;
    }

    public async submitMergeRequest(): Promise<void> {

        let selectedJobReview: BranchReview;

        if (this.targetJobReviewSelected) {
            if (!this.targetJobReview) {
                selectedJobReview = this.targetJobReviewForm.value as BranchReview;
            }
            else {
                selectedJobReview = { ...this.targetJobReview, ...this.targetJobReviewForm.value };
                selectedJobReview.contactInfo.storeNumber = this.targetJobReview.contactInfo?.storeNumber;
            }
        }
        else if (!this.sourceJobReview) {
            selectedJobReview = this.sourceJobReviewForm.value as BranchReview;
        }
        else {
            selectedJobReview = { ...this.sourceJobReview, ...this.sourceJobReviewForm.value } ;
            selectedJobReview.contactInfo.storeNumber = this.sourceJobReview.contactInfo?.storeNumber;
        }

        const action = this._projectHttpService.mergeProjects(
            this.sourceProjectId,
            this.targetProjectId,
            selectedJobReview,
            this.selectTargetProjectFormGroup.get('reason').value);

        const ongoing = this.translate.instant('projects.merge.dialog.ongoing');
        const success = this.translate.instant('projects.merge.dialog.success');
        const error = this.translate.instant('projects.merge.dialog.error');
        const ok = this.translate.instant('shared.ok');
        const gotIt = this.translate.instant('shared.gotIt');
        const dialogData = new ActionProgressReporterOption(action, ongoing, success, error, ok, gotIt);
        const dialog = this._dialogService.open(ActionProgressDialogComponent, {
            data: dialogData,
            width: '550px',
            height: '120px'
        });

        if (await dialog.afterClosed().toPromise()) {
            this.onClose(true);
        }

    }

    public async submitReasonForUsingMerge(mergeReasonForm: UntypedFormGroup): Promise<void> {
        if (mergeReasonForm && mergeReasonForm.valid) {
            const mergeIdDto = mergeReasonForm.value;
            this.selectTargetProjectFormGroup.setValue({
                reason: mergeIdDto.conversionReason,
                targetProjectId: mergeIdDto.targetProjectId
            });
            const loadedMergeProjectInfo = await this.getTargetProjectInfo();

            if (loadedMergeProjectInfo) {
                this.nextMatStep(this.stepper.selectedIndex + 1);
            }
            else {
                this.selectTargetProjectFormGroup.setValue({
                    reason: '',
                    targetProjectId: ''
                });
            }
        }
    }

    public targetReviewSelectionChanged(checked: boolean): void {
        this.targetJobReviewSelected = checked;
        if (checked) {
            this.sourceJobReviewForm.disable();
            this.targetJobReviewForm.enable();
        }
        else {
            this.sourceJobReviewForm.enable();
            this.targetJobReviewForm.disable();
        }
    }

    public sourceReviewSelectionChanged(checked: boolean): void {
        this.targetJobReviewSelected = !checked;
        if (checked) {
            this.sourceJobReviewForm.enable();
            this.targetJobReviewForm.disable();
        }
        else {
            this.sourceJobReviewForm.disable();
            this.targetJobReviewForm.enable();
        }
    }

    public sourceJobReviewChanged(jobReviewForm: UntypedFormGroup): void {
        this.sourceJobReviewForm = jobReviewForm;
    }

    public targetJobReviewChanged(jobReviewForm: UntypedFormGroup): void {
        this.targetJobReviewForm = jobReviewForm;
    }

    public reviewSelected(): void {
        this.nextMatStep(this.stepper.selectedIndex + 1);
    }


    public onClose(projectMerged = false): void {
        this._dialog.close(projectMerged);
    }

    private async getTargetProjectInfo(): Promise<boolean> {
        this.targetProjectSelected = false;
        this.targetProjectInfo = await this._projectHttpService.getProjectSummary(this.targetProjectId);
        this.targetJobReview = await this._projectReviewHttpService.getBranchReview(this.targetProjectId);

        if (!this.targetProjectInfo || !this.sourceProjectInfo) {
            const content = this.translate.instant('projects.merge.dialog.getTargetProjectFailed');
            this._snackBar.open(content, this.translate.instant('snackbars.ok'));
            return false;
        }

        const status = this.targetProjectInfo.status;
        const disallowedStatuses = ['Accepted', 'Assigned', 'Signed In', 'Received by tech'];

        if (disallowedStatuses.includes(status)) {
            const content = this.translate.instant('projects.merge.dialog.targetProjectStatusError');
            this._snackBar.open(content, this.translate.instant('snackbars.ok'));
            return false;
        }

        this.targetProjectSelected = true;
        return true;
    }


    private async hasProjectExceededAutoApproveTime(): Promise<boolean> {
        return await this._projectHttpService.willBeAutoApproved(this.sourceProjectId);
    }

    private async sourceProjectIsValid(): Promise<boolean> {
        const isTemporary = ProjectIdUtility.isTemporary(this.sourceProjectId);
        const isEmergency = ProjectIdUtility.isEmergency(this.sourceProjectId);

        if (isTemporary || isEmergency) {
            return true;
        }

        const activeInDynamics = await this._projectHttpService.getDynamicsProjectStatus(this.sourceProjectId);
        return activeInDynamics === DynamicsProjectStatus.Inactive;
    }

    private nextMatStep(nextStep: number): void {
        this.stepper.selectedIndex = nextStep;
    }

}
