import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';

import ActionMenuItemOption from '../../../../core/data-models/action-menu-item-option';

@Component({
    selector: 'dropdown-button',
    styleUrls: ['./dropdown-button.component.scss'],
    templateUrl: './dropdown-button.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownButtonComponent {
    @Input() public icon = '';
    @Input() public text = '';
    @Input() public position = 'after';
    @Input() public options: ActionMenuItemOption[] = [];
    @Input() public disabled = false;
    @Output() public optionSelect = new EventEmitter<string>();

    constructor(private _changeDetectorRef: ChangeDetectorRef) { }

    public onClose(): void {
        this._changeDetectorRef.markForCheck();
    }
}
