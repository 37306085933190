<mat-horizontal-stepper fxLayoutGap="100px" (selectionChange)="checkIfConfirmationPageSelected($event)" class="customer-create-stepper" fxLayout="column" linear fxFill #createCustomerStepper>
    <mat-step style="height: 100%;" [stepControl]="newCustomerFormGroup" [editable]="true"
        [label]="translate.instant('shared.customerCreateManagement.stepperLabels.customer')">
            <customer-create
                (newCustomerInfoUpdated)="mainCustomerInfoUpdated($event)"
                (handleStep)="nextStep($event)">
            </customer-create>
    </mat-step>
    <mat-step [stepControl]="newBillinsgSiteFormGroup"
        [label]="translate.instant('shared.customerCreateManagement.stepperLabels.billing')">
            <customer-site-create #billingSiteCreate
                [forceBilling]="true"
                [inputCustomerAddress]="customerSiteInfo"
                (updateSiteAddress)="newBillingSiteUpdated($event)"
                (handleStep)="nextStep($event)">
            </customer-site-create>
    </mat-step>
    <mat-step [stepControl]="newJobSiteFormGroup"
        [label]="translate.instant('shared.customerCreateManagement.stepperLabels.job')">
            <customer-site-create #jobSiteCreate
                [forceBilling]="false"
                [inputCustomerAddress]="customerSiteInfo"
                [inputAddress]="billingSite"
                (updateSiteAddress)="newJobSiteUpdated($event)"
                (handleStep)="nextStep($event)">
            </customer-site-create>
    </mat-step>
    <mat-step [stepControl]="newJobSiteFormGroup"
        [label]="translate.instant('shared.customerCreateManagement.stepperLabels.summary')">
        <customer-selection-confirm *ngIf="readyToVerifyCustomerInformation"
            [customerInfo]="customerSummaryGridInfo"
            [jobSiteInfo]="jobSiteSummaryGridInfo"
            [billingSiteInfo]="billingSiteSummaryGridInfo"
            (handleStep)="nextStep($event)"
            (confirmSummaryDetails)="confirmCustomerDecision($event)">
        </customer-selection-confirm>
    </mat-step>

</mat-horizontal-stepper>
