import { RatesQuery } from '../rates/rates-query';

export class BudgetRecordPayload<T> {
    public budgetData: T;
    public ratesQuery: RatesQuery;

    constructor(budgetData: T, ratesQuery: RatesQuery) {
        this.budgetData = budgetData;
        this.ratesQuery = ratesQuery;
    }
}
