/* istanbul ignore file */
// note that this is a temporary solution for FSW 4.0 until fully migrated
export default class JsonUtility {

    constructor() {
        console.warn('Usage of this class is only valid during FSW 4.0 migration.');
    }
    // eslint-disable-next-line
    public static toCamelCaseKeys(input: any): any {
        if (Array.isArray(input)) {
            return input.map(_ => this.toCamelCaseKeys(_));
        }

        if (input === null || input.constructor !== Object) {
            return input;
        }

        return Object.keys(input).reduce((result, key) => ({
            ...result,
            [this.toCamelCase(key)]: this.toCamelCaseKeys(input[key])
        }), {});
    }

    private static toCamelCase(key: string): string {
        const keyValue = key ? key : '';

        return keyValue.length ? keyValue[0].toLowerCase() + keyValue.slice(1) : keyValue;
    }

    public static cloneObject<T>(object: T): T {
        return !object? null : JSON.parse(JSON.stringify(object));
    }
}
