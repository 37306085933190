<dialog-action-card [icon]="'place'" [title]="translate.instant('projects.projectMap.title1')" [isLoaded]="isLoaded" (close)="onClose()">
    <google-map [width]="1000"
        [height]="630"
        [center]="center"
        [zoom]="12"
        [options]="options">

        <map-marker [position]="center"
            [title]="translate.instant('projects.projectMap.title2')"
            [options]="{ icon: '../../../../../../assets/images/site_icon_s.png' }">
        </map-marker>
    </google-map>
</dialog-action-card>
