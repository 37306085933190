import * as moment from 'moment-timezone';

import BranchTimezone from '../../../data-models/branch-timezone';

export default class TimeUtility {

    public static toRelativeTime(start: Date): string {
        return moment(start).fromNow(true);
    }

    public static toBranchLocalDateTime(date: Date, branch: BranchTimezone, useFull = true): string {
        if (!date) {
            return '';
        }

        const format = `YYYY-MM-DD ${useFull? 'h:mm:ss A z' : ''}`;
        const dateTime = branch ? moment.tz(date, branch.timeZoneId).format(format) : moment(date).format(format);

        return dateTime?.trim() ?? '';
    }

    /* istanbul ignore next */
    public static async wait(seconds = 0): Promise<void> {
        await new Promise<void>(_ => setTimeout(() => _(), seconds * 1000));
    }
}
