import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input, OnChanges, OnInit,
    Output, PipeTransform, SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import KeyValuePair from '../../../../../core/data-models/key-value-pair';
import GridItemEditOption from '../../../../../core/data-models/grid-item-edit-option';
import TemplateFieldMeta from '../../../../../core/data-models/template-field-meta';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import ConfirmDialogOption from '../../../../../core/data-models/confirm-dialog-option';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog-component/confirm-dialog.component';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'grid-pro-dropdown-item',
    styleUrls: ['./grid-pro-dropdown-item.component.scss'],
    templateUrl: './grid-pro-dropdown-item.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridProDropdownItem implements OnInit, OnChanges, AfterViewInit {
    @Input() public title = '';
    @Input() public content: TemplateFieldMeta;
    @Input() public options: KeyValuePair[];
    @Input() public selected: string;
    @Input() public disabled = false;
    @Input() public transform: (_: unknown) => string;
    @Input() public displayPipe: PipeTransform;
    @Input() public editOptions: GridItemEditOption;
    @Output() public errorFound = new EventEmitter<string>();
    @Output() public errorFixed = new EventEmitter();
    @Output() public contentEdit = new EventEmitter<string>();
    @Output() public contentUpdate = new EventEmitter<string>();
    @Output() public selectedChange = new EventEmitter<string>();
    @ViewChild('editBox') private _editBox: ElementRef;
    @ViewChild('displayBox') private _displayBox: ElementRef;

    public error = '';
    public editedContent = '';
    private _canUpdate = true;
    private _editModeOn = false;
    private _loadedWithError = false;
    private _tooltip: string | null;

    constructor(private _translate: TranslateService,
                private _dialog: MatDialog,
                private _changeDetector: ChangeDetectorRef) {}

    get isEditModeOn(): boolean {
        return this._editModeOn || Boolean(this.error);
    }

    get errorMessageClass(): string {
        return `grid-display-item-error-message${this._loadedWithError ? '' : '-animated'}`;
    }

    get canEnableEdit(): boolean {
        return this.editOptions && !this.isEditModeOn;
    }

    get displayContent(): string {
        const { value } = this.content;

        if (this.transform) {
            return this.transform(value);
        }

        return this.displayPipe ? this.displayPipe.transform(value) : value;
    }

    get tooltip(): string {
        return this._tooltip;
    }

    get inputBoxClasses(): { [key: string]: boolean } {
        return {
            'active-grid-edit-box': this._editModeOn,
            'error-grid-edit-box': Boolean(this.error)
        };
    }

    get displayBoxClasses(): { [key: string]: boolean } {
        return {
            'editable-grid-display-box': this.canEnableEdit,
            'disabled-grid-display-box': this.isEditModeOn
        };
    }

    public onChange(key: string): void {
        this.selectedChange.emit(key);
    }

    public ngOnInit(): void {
        if (this.editOptions) {
            this.editedContent = this.editOptions.editContent ?? this.content.value;
            this.updateErrorMessage(this.editedContent);
            this._loadedWithError = Boolean(this.error);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const hasChange = Object.prototype.hasOwnProperty.call(changes, 'content');

        if (hasChange && (this.content === null || typeof this.content !== 'object')) {
            this.content = new TemplateFieldMeta(String(this.content || ''));
        }
    }

    public ngAfterViewInit(): void {
        if (this._displayBox?.nativeElement) {
            const { offsetWidth, scrollWidth } = this._displayBox.nativeElement;
            this._tooltip = offsetWidth < scrollWidth ? this.displayContent : null;
            this._changeDetector.detectChanges();
        }
    }

    public async onReset(): Promise<void> {
        if (this.editOptions.disableEsc) {
            return;
        }
        let discardChanges = false;
        const hasChanges = this.editedContent !== this.content.value;

        if (hasChanges) {
            this._canUpdate = false;
            const title = this._translate.instant('shared.gridDisplayItem.unsavedChanges');
            const message = this._translate.instant('shared.gridDisplayItem.keepChangesQuestion');
            const discardLabel = this._translate.instant('shared.discard');
            const data = new ConfirmDialogOption(title, message, true, discardLabel);
            const dialog = this._dialog.open(ConfirmDialogComponent, { data });
            discardChanges = await dialog.afterClosed().toPromise();
            this._canUpdate = true;
        }

        if (!hasChanges || discardChanges) {
            this._changeDetector.markForCheck();
            this.editedContent = this.content.value;
            this.disableContentEdit(this.editedContent);
        }
    }

    public onContentUpdate(): void {
        if (!this._canUpdate) {
            return;
        }
        const content = this.editedContent.trim();
        this.disableContentEdit(content);

        if (content !== this.content.value && !this.error) {
            this.contentUpdate.emit(content);
        }
    }

    public enableContentEdit(): void {
        if (this.editOptions) {
            this._editModeOn = true;
            /* istanbul ignore next */
            setTimeout(() => this._editBox?.nativeElement?.focus());
        }
    }

    private disableContentEdit(editedContent: string): void {
        this._editModeOn = false;
        const hadError = Boolean(this.error);
        this.updateErrorMessage(editedContent);

        if (this.error) {
            this.errorFound.emit(this.editedContent);
        }
        else if (hadError) {
            this.errorFixed.emit();
        }
    }

    private updateErrorMessage(content: string): void {
        const validatorSets = this.editOptions.validatorSets ?? [];
        this.error = '';

        for (const { validator, message } of validatorSets) {
            if (validator(new FormControl(content))) {
                this.error = message ? message : this._translate.instant('shared.gridDisplayItem.invalidInput');

                break;
            }
        }
    }
}
