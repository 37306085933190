<div class="add-follow-up-reminder-container" [fxLayout]="columnMode ? 'column' : 'row'" fxLayoutAlign="start stretch" fxFill>
    <span *ngIf="!columnMode && isCurrentUserInvalid" class="row-error-messages">
        {{ 'shared.addFollowUpLogReminder.userNotListedAsEstimatorWarning' | translate }}
        - {{ 'shared.addFollowUpLogReminder.pleaseContactSupport' | translate}}
    </span>

    <div class="content"
        [fxLayout]="columnMode? 'column' : 'row'"
        [fxLayoutAlign]="columnMode ? 'start stretch' : 'space-between center'"
        [fxLayoutGap]="columnMode ? '20px' : '5px'"
        fxFlex>

        <mat-form-field>
            <input type="text"
                #contactTxt
                placeholder="{{ 'shared.quote.editor.searchPlaceholder' | translate }}"
                matInput
                [matAutocomplete]="auto"
                [(ngModel)]="autoCompleteSearch"
                (ngModelChange)="searchList()">
            <mat-error *ngIf="itemsInChipset.length===0">
                {{ 'shared.addFollowUpLogReminder.requiredField' | translate }}
            </mat-error>
            <mat-autocomplete (optionSelected)="contactListSearchItemChange($event)" #auto="matAutocomplete">
                <mat-option *ngFor="let option of autoCompleteList" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div class="chips-wrapper" fxLayout="column" fxLayoutAlign="start stretch" >
            <ng-container *ngIf="columnMode && isCurrentUserInvalid">
                <span class="invalid-user-error">
                    {{ 'shared.addFollowUpLogReminder.userNotListedAsEstimatorWarning' | translate }}
                </span>

                <span class="invalid-user-error">
                    * {{ 'shared.addFollowUpLogReminder.pleaseContactSupport' | translate }}
                </span>
            </ng-container>

            <span *ngIf="columnMode">
                {{ count }} {{ 'shared.addFollowUpLogReminder.recipientSelected' | translate }}
            </span>

            <div fxFlex fxLayoutAlign="start start">
                <mat-chip-list #chipList required>
                    <mat-chip *ngFor="let chipItem of itemsInChipset"
                        [selectable]="true"
                        [removable]="true"
                        (removed)="remove(chipItem)">
                        {{ chipItem }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center start" fxFlex>
            <div #datetimepicker fxFlex="296px">
                <kendo-datetimepicker #kendoDateTime
                    [format]="'yyyy-MM-dd HH:mm'"
                    [cancelButton]="false"
                    [disabledDates]="disabledDates"
                    [(value)]="reminderDate"
                    [popupSettings]="popupSettings">
                </kendo-datetimepicker>
            </div>
        </div>
    </div>
    <div class="action" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="save()" [disabled]="isLoading">{{ 'shared.quote.editor.setReminder' | translate }}</button>
    </div>
</div>
