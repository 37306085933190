export enum QuoteEditorConstants {
    Style = '-webkit-user-select:all;-moz-user-select:all;-ms-user-select:all;user-select:all',
    QuoteAmountIdentifier = 'qa',
    QuoteTaxIdentifier = 'qta',
    QuoteTotalIdentifier = 'qtot',
    QuotePhraseIdentifier = 'qp',
    QuoteTotalPhraseIdentifier = 'qtp',
    QuoteRecommendationIdentifier = 'qtr',

}
