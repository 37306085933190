import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'update-site-information',
    styleUrls: ['./update-site-information.component.scss'],
    templateUrl: './update-site-information.component.html'
})

export class UpdateSiteInformationComponent {

    @Input() siteInformation: UntypedFormGroup;
    @Input() isBillingSite: boolean;

}
