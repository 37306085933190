import ProjectStatus from '../project-status';
import ContactInfo from '../contact-info';

import CustomerInfo from './customer-info';
import JobContactInfo from './job-contact-info';
import DispatchInfo from './dispatch-info';
import ProjectBasicInfo from './project-basic-info';
import JobBasicInfo from './job-basic-info';
import { WorkCategory } from '../work-category';

export default class ProjectGeneralInfo {
    public basicInfo: ProjectBasicInfo;
    public customer: CustomerInfo;
    public dispatchInfo: DispatchInfo;
    public reportingContact: ContactInfo;
    public siteContact: JobContactInfo;
    public billingContact: ContactInfo;
    public rejectionReason = '';
    public status: ProjectStatus;
    public jobs: JobBasicInfo[] = [];
    public isLateFillIn = false;
    public isHandOffMeetingRequired = false;
    public includeBillableHours = false;
    public workOrderText = '';
    public workChecklist = '';
    public workCategory: WorkCategory;
}
