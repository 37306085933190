import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import ImageItem from '../../../../core/data-models/job-template-items/image-item';

@Component({
    selector: 'app-full-view-photo-dialog',
    styleUrls: ['./full-view-photo-dialog.component.scss'],
    templateUrl: './full-view-photo-dialog.component.html'
})
export class FullViewPhotoDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: ImageItem) { }

}
