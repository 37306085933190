import CustomerSiteDto from './customer-site-dto';

export default class SOAddress {
    id: string;
    name: string;
    status: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    custId: string;
    zip: string;
    addresses: SOAddress[];
    shipToId: string;
    storeNumber: string;
    contactName: string;
    contactNumber: string;
    contactEmail: string;
    isBillAddress: boolean;
    fax: string;
    geoCode: string;

    constructor(siteDto: CustomerSiteDto) {
        this.name = siteDto.name;
        this.address1 = siteDto.address1;
        this.address2 = siteDto.address2;
        this.city = siteDto.city;
        this.state = siteDto.state;
        this.country = siteDto.country;
        this.zip = siteDto.zip;
        this.storeNumber = siteDto.storeNumber;
        this.contactName = siteDto.siteAttention;
        this.contactEmail = siteDto.siteEmail;
        this.contactNumber = siteDto.sitePhone;
        this.geoCode = siteDto.geoCode;
        this.isBillAddress = siteDto.isBillInfo;
        this.fax = siteDto.siteFax;
        this.shipToId = siteDto.shipToId;
    }

}
