<form [formGroup]="mergeReasonFormGroup" fxFlexFill fxLayoutAlign="center center">

    <div [fxLayout]="requiresReason?'column':'row'" fxLayoutAlign="start start" [fxLayoutGap]="requiresReason?'40px':'5px'" class="merge-project-reason-container">

        <mat-form-field fxFlexFill [fxFlex]="requiresReason?'20':'80'">
            <mat-label>{{ 'projects.merge.target' | translate }}</mat-label>
            <input type="text" matInput formControlName="targetProjectId">
            <mat-error *ngIf="isFormControlValid('targetProjectId')">{{getErrorMessage('targetProjectId')}}
            </mat-error>
        </mat-form-field>

        <button class="merge-project-reason-search-button" *ngIf="!requiresReason" mat-raised-button color="primary" (click)="submitInfo()">
            {{ 'projects.merge.search' | translate }}
        </button>


        <mat-form-field fxFlexFill fxFlex="20" *ngIf="requiresReason">
            <textarea [placeholder]="translate.instant('projects.merge.reasonHelperText')"
                formControlName="conversionReason" matInput rows="5"></textarea>
            <mat-error *ngIf="isFormControlValid('conversionReason')">{{getErrorMessage('conversionReason')}}
            </mat-error>
        </mat-form-field>

        <div *ngIf="requiresReason" fxFlexFill fxFlex="20" fxLayout fxLayoutAlign="end center">
            <button mat-raised-button color="primary" (click)="submitInfo()">
                {{ 'projects.merge.submit' | translate }}
            </button>
        </div>
    </div>
</form>
