import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import CustomerDto from '../../../../../core/data-models/customer-dto';
import CustomerSiteDto from '../../../../../core/data-models/customer-site-dto';
import CustomerDisplaySummary from '../../../../../core/data-models/customer-selection/customer-display-summary';
import SiteDisplaySummary from '../../../../../core/data-models/customer-selection/site-display-summary';
import InformationGridRow from '../../../../../core/data-models/information-grid/information-grid-row';
import InformationGridItem from '../../../../../core/data-models/information-grid/information-grid-item';
import InformationGridConfig from '../../../../../core/data-models/information-grid/information-grid-config';
import { FeatureFlagService } from '../../../../../core/services/events/feature-flags/feature-flag.service';
import { FeatureFlags } from '../../../../../core/enums/feature-flags.enum';

@Injectable({
    providedIn: 'root'
})
export class CustomerDisplayFormattingService implements OnInit {

    private _isInternal = false;

    constructor(
        private _translate: TranslateService,
        private _featureFlagService: FeatureFlagService
    ) {}

    public ngOnInit(): void {
        this._isInternal = this._featureFlagService.featureFlags[FeatureFlags.UseInternalFeatures];
    }

    public createSiteInfoCardConfig(site: CustomerSiteDto, type: string): InformationGridConfig<SiteDisplaySummary> {

        const siteDisplay = new SiteDisplaySummary();
        siteDisplay.createSiteDisplaySummary(site);
        const shipToIdTitle = `${type} ${this.getTranslation('createSiteInfoCard.siteId')}`;
        const shipToId = new InformationGridItem(shipToIdTitle, 'shipToId', 10);
        const siteNameTitle = `${type} ${this.getTranslation('createSiteInfoCard.siteName')}`;
        const siteName = new InformationGridItem(siteNameTitle, 'name', 20);
        const geoCodeTitle = `${type} ${this.getTranslation('createSiteInfoCard.geoCode')}`;
        const geoCode = new InformationGridItem(geoCodeTitle, 'geoCode', 30);
        const addressTitle = `${type} ${this.getTranslation('createSiteInfoCard.address')}`;
        const address = new InformationGridItem(addressTitle, 'address', 30);
        const siteAttentionTitle = `${type} ${this.getTranslation('createSiteInfoCard.siteAttention')}`;
        const siteAttention = new InformationGridItem(siteAttentionTitle, 'siteAttention', 15);
        const emailTitle = `${type} ${this.getTranslation('createSiteInfoCard.siteEmail')}`;
        const email = new InformationGridItem(emailTitle, 'siteEmail', 15);
        const rows = [
            new InformationGridRow([
                shipToId,
                siteName,
                site.geoCode? geoCode: address,
                siteAttention,
                email
            ])
        ];

        return new InformationGridConfig(siteDisplay, rows);
    }

    public createCustomerInfoCardConfig(newCustomer: CustomerDto, numberOfNewSites: string):
        InformationGridConfig<CustomerDisplaySummary> {

        const customerDisplay = new CustomerDisplaySummary();
        customerDisplay.numberOfNewSites = numberOfNewSites;
        customerDisplay.createCustomerDisplaySummary(newCustomer);

        const customerIdTitle = this.getTranslation('createCustomerInfoCard.customerId');
        const customerId = new InformationGridItem(customerIdTitle, 'customerId', 15);
        const customerNameTitle = this.getTranslation('createCustomerInfoCard.customerName');
        const customerName = new InformationGridItem(customerNameTitle, 'name', 20);
        const numberOfSitesTitle = this.getTranslation('createCustomerInfoCard.numberOfSites');
        const numberOfSites = new InformationGridItem(numberOfSitesTitle, 'numberOfNewSites', 15);
        const addressTitle = this.getTranslation('createCustomerInfoCard.address');
        const address = new InformationGridItem(addressTitle, 'address', 30);
        const phoneTitle = this.getTranslation('createCustomerInfoCard.phone');
        const phone = new InformationGridItem(phoneTitle, 'phone', 15);
        const faxTitle = this.getTranslation('createCustomerInfoCard.fax');
        const fax = new InformationGridItem(faxTitle, 'faxNumber', 15);
        const dataUniversalNumberTitle = this.getTranslation('createCustomerInfoCard.dataUniversalNumber');
        const dataUniversalNumber = new InformationGridItem(dataUniversalNumberTitle, 'dataUniversalNumberingSystemNumber', 10);
        const customerClassTitle = this.getTranslation('createCustomerInfoCard.customerClass');
        const customerClass = new InformationGridItem(customerClassTitle, 'customerClass', 10);
        const currencyTitle = this.getTranslation('createCustomerInfoCard.currency');
        const currency = new InformationGridItem(currencyTitle, 'currency', 15);
        const proposedBusinessTitle = this.getTranslation('createCustomerInfoCard.proposedBusiness');
        const proposedBusinesss = new InformationGridItem(proposedBusinessTitle, 'proposedBusiness', 15);

        let rows: InformationGridRow[];
        const cols = this._isInternal ?
            [dataUniversalNumber, customerClass, currency, proposedBusinesss] : [currency, proposedBusinesss];

        if (newCustomer.customerId.startsWith('new')) {

            rows = [
                new InformationGridRow([customerName, numberOfSites, address, phone, fax]),
                new InformationGridRow(cols)
            ];
        }
        else {
            rows = [
                new InformationGridRow([customerId, customerName, address, phone, fax]),
                new InformationGridRow(cols)
            ];
        }

        return new InformationGridConfig(customerDisplay, rows);
    }

    private getTranslation(extension: string): string {
        return this._translate.instant(`shared.customerDisplayFormatting.${extension}`);
    }

}
