<div class="crew-selector-container" fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start" class="shared-crew-selection-wrapper">
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start start">
            <h4 >{{ 'shared.crewSelector.crew' | translate }}</h4>

            <div *ngIf="selectedCrew && selectedCrew.workers?.length > 0" class="crew-selector-workers-list-container" fxLayout="column" fxLayoutAlign="start start">
                <span class="material-icons crew-selector-material-group-icon">groups</span>
                <div class="box-with-shadow" fxLayout="column" fxLayoutAlign="center center">
                    <ul>
                        <li *ngFor="let worker of selectedCrew?.workers">
                            {{ 'shared.crewSelector.worker' | translate }}: {{ worker.preferredName }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="crew-selector-container" [fxLayout]="horizontalMode? 'row': 'column'" fxLayoutGap="10px" fxLayoutAlign="start start">
            <mat-form-field [ngClass]="horizontalMode ? 'crew-selector-horizontal-mode-branch': 'crew-selector-vertical-mode-branch'">
                <mat-label>{{ branchSelectionLabel }}</mat-label>

                <mat-select [disabled]="branchCodeRestriction.length > 0 || isNotEditable" [(ngModel)]="selectedBranch" (selectionChange)="loadCrewFromSap($event.value)">
                    <mat-option *ngFor="let branch of branchList" [value]="branch">{{branch.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field [ngClass]="horizontalMode ? 'crew-selector-horizontal-mode-crew-input': 'crew-selector-vertical-mode-crew-input'">
                <mat-label>{{ 'shared.crewSelector.crew' | translate }}</mat-label>
                <mat-select [disabled]="isNotEditable" [(ngModel)]="selectedCrew" (selectionChange)="updateCrew($event.value)">
                    <mat-option *ngFor="let crew of crewList" [value]="crew">
                        {{ crew.crewName }}
                        <span *ngIf="crew.createdBy">{{ translate.instant('shared.crewSelector.createdBy') }} {{ crew.createdBy }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <health-screening-requirement-card *ngIf="isInternal" [requirement]="healthScreeningRequirement"></health-screening-requirement-card>
    </div>

    <div *ngIf="branchList?.length < 1" class="shared-crew-selection-error-wrapper">{{ loadingErrorText }}</div>
</div>
