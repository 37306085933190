import DispatchBaseStepConfig from './dispatch-base-step-config';
import USProjectInfoModel from './us-project-info-model';

export default class UsProjectInfoStepConfig extends DispatchBaseStepConfig {
    public data: USProjectInfoModel;

    public constructor(label: string, completed = false, editable = true, hasNext = true, hasPrevious = true) {
        super(label, completed, editable, hasNext, hasPrevious);
    }
}
