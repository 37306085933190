import { StateService, TransitionService } from '@uirouter/core';
import { AuthLockService } from '../../services/auth-lock-service/auth-lock-service';

import { NewRelicUtilityService } from '../../services/utility/new-relic-utility.service';

export function registerHooks(transitionService: TransitionService, stateService: StateService, newRelicUtility: NewRelicUtilityService, authLockService: AuthLockService) {
    return (): void => {
        transitionService.onStart({}, transition => {
            if (/^\/customer\/.*/.test(window.location.pathname)) {
                transition.abort();
                window.open('/customer', '_self');
            }
            else if (transition.targetState().name() !== 'loginPage' && !authLockService.isLoggedIn) {
                const currentState = transition.targetState();
                const currentStateName = currentState.name();
                const currentParameters = currentState.params();
                transition.abort();
                stateService.go('loginPage', { returnState: currentStateName, extra: currentParameters });
            }
        });

        transitionService.onSuccess({ to: 'projects.**' }, _ => {
            newRelicUtility.addPageAction('', '', 'ProjectTabSelected', { TabName: _.to().name });
        });

        transitionService.onSuccess({ to: 'quotes.**' }, _ => {
            newRelicUtility.addPageAction('', '', 'QuoteTabSelected', { TabName: _.to().name });
        });
    };
}
