import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import UserProfile from '../../../data-models/user-profile';
import ClientEvent from '../../../data-models/client-events/client-event';
import ProjectAccessEventData from '../../../data-models/client-events/project-access-event-data';
import UserComment from '../../../data-models/user-comment/user-comment';
import ProjectSummary from '../../../data-models/summary-info/project-summary';
import QuoteSummary from '../../../data-models/summary-info/quote-summary';
import { SignalrHubService } from '../../signalr-hub/signalr-hub.service';
import { UserCommentAction } from '../../../enums/user-comment-action.enum';
import PhotoReportNotification from 'src/app/core/data-models/photo-report-notification';

@Injectable({
    providedIn: 'root'
})
export class AppEventService {
    private _projectViewersChanged = new Subject<[string, UserProfile[]]>();
    private _projectCreated = new Subject<void>();
    private _projectUpdated = new Subject<[string, ProjectSummary]>();
    private _photoUpdated = new Subject<[string, ProjectSummary]>();
    private _attachmentUpdated = new Subject<[string, ProjectSummary]>();
    private _drawingUpdated = new Subject<[string, ProjectSummary]>();
    private _projectCommentChanged = new Subject<[string, UserCommentAction, UserComment]>();
    private _quoteCreated = new Subject<void>();
    private _quoteUpdated = new Subject<[string, QuoteSummary]>();
    private _quoteCommentChanged = new Subject<[string, UserCommentAction, UserComment]>();
    private _dynamicsHoldUpdated = new Subject<boolean>();
    private _reloadTabs = new Subject<void>();
    private _reloadProjectCounter = new Subject<void>();
    private _reloadQuoteCounter = new Subject<void>();
    private _photoReportGenerated = new Subject<[string, PhotoReportNotification]>();

    get serverConnectionChanged(): Observable<boolean> {
        return this._signalrHub.connectionChanged;
    }

    get projectViewersChanged(): Observable<[string, UserProfile[]]> {
        return this._projectViewersChanged.asObservable();
    }

    get projectCreated(): Observable<void> {
        return this._projectCreated.asObservable();
    }

    get projectUpdated(): Observable<[string, ProjectSummary]> {
        return this._projectUpdated.asObservable();
    }

    get photoUpdated(): Observable<[string, ProjectSummary]> {
        return this._photoUpdated.asObservable();
    }

    get drawingUpdated(): Observable<[string, ProjectSummary]> {
        return this._drawingUpdated.asObservable();
    }

    get attachmentUpdated(): Observable<[string, ProjectSummary]> {
        return this._attachmentUpdated.asObservable();
    }

    get projectCommentChanged(): Observable<[string, UserCommentAction, UserComment]> {
        return this._projectCommentChanged.asObservable();
    }

    get quoteCreated(): Observable<void> {
        return this._quoteCreated.asObservable();
    }

    get quoteUpdated(): Observable<[string, QuoteSummary]> {
        return this._quoteUpdated.asObservable();
    }

    get quoteCommentChanged(): Observable<[string, UserCommentAction, UserComment]> {
        return this._quoteCommentChanged.asObservable();
    }

    get dynamicsHoldUpdated(): Observable<boolean> {
        return this._dynamicsHoldUpdated.asObservable();
    }

    get reloadTabs(): Observable<void> {
        return this._reloadTabs.asObservable();
    }

    get reloadProjectCounter(): Observable<void> {
        return this._reloadProjectCounter.asObservable();
    }

    get reloadQuoteCounter(): Observable<void> {
        return this._reloadQuoteCounter.asObservable();
    }

    get photoReportGenerated(): Observable<[string, PhotoReportNotification]> {
        return this._photoReportGenerated.asObservable();
    }

    constructor(private _signalrHub: SignalrHubService) {
        this.subscribeSignalrEvents();
    }

    public notifyProjectAccessClientEvent(name: string, data: ProjectAccessEventData): void {
        if (this._signalrHub.connectionId) {
            const event = new ClientEvent(name, this._signalrHub.connectionId, new Date(), data);
            this._signalrHub.emit('OnProjectAccessChanged', event);
        }
    }

    public setProjectCreated(): void {
        this._projectCreated.next();
    }

    public setReloadTabs(): void {
        this._reloadTabs.next();
    }

    public setReloadProjectCounter(): void {
        this._reloadProjectCounter.next();
    }

    public setReloadQuoteCounter(): void {
        this._reloadQuoteCounter.next();
    }

    private subscribeSignalrEvents(): void {
        this._signalrHub.subscribe('projectViewersChanged', (..._: [string, UserProfile[]]) => this._projectViewersChanged.next(_));
        this._signalrHub.subscribe('projectUpdated', (..._: [string, ProjectSummary]) => this._projectUpdated.next(_));
        this._signalrHub.subscribe('photoUpdated', (..._: [string, ProjectSummary]) => this._photoUpdated.next(_));
        this._signalrHub.subscribe('attachmentUpdated', (..._: [string, ProjectSummary]) => this._attachmentUpdated.next(_));
        this._signalrHub.subscribe('drawingUpdated', (..._: [string, ProjectSummary]) => this._drawingUpdated.next(_));
        this._signalrHub.subscribe('projectCommentChanged', (..._: [string, UserCommentAction, UserComment]) => this._projectCommentChanged.next(_));
        this._signalrHub.subscribe('quoteCreated', () => this._quoteCreated.next());
        this._signalrHub.subscribe('quoteUpdated', (..._: [string, QuoteSummary]) => this._quoteUpdated.next(_));
        this._signalrHub.subscribe('quoteCommentChanged', (..._: [string, UserCommentAction, UserComment]) => this._quoteCommentChanged.next(_));
        this._signalrHub.subscribe('dynamicsHoldUpdated', (_: boolean) => this._dynamicsHoldUpdated.next(_));
        this._signalrHub.subscribe('photoReportGenerated', (..._: [string, PhotoReportNotification]) => this._photoReportGenerated.next(_));
    }
}
