export default class UserProfileEditorOption {
    public username: string;
    public isExisting: boolean;
    public isEditable: boolean;
    public isServiceProvider: boolean;

    constructor(username: string, isExisting: boolean, isEditable = false, isServiceProvider = false) {
        this.username = username;
        this.isExisting = isExisting;
        this.isEditable = isEditable;
        this.isServiceProvider = isServiceProvider;
    }
}
