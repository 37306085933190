import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewEncapsulation, OnInit } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { Moment } from 'moment-timezone';
import { MOMENT_DEFAULT_FORMAT } from '../../../../core/constants/moment-default-format';

import TemplateFieldMeta from '../../../../core/data-models/template-field-meta';
import { TemplateDataService } from './service/template-data.service';

@Component({
    selector: 'template-edit-item-card',
    styleUrls: ['./template-edit-item-card.component.scss'],
    templateUrl: './template-edit-item-card.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MOMENT_DEFAULT_FORMAT }
    ]
})
export class TemplateEditItemCardComponent implements OnInit {
    @Input() public template: TemplateFieldMeta;
    @Output() public valueChange = new EventEmitter<TemplateFieldMeta>();
    count = 0;
    constructor(public dataService: TemplateDataService) { }

    ngOnInit() {
        if (this.template.nameAttributes && this.template.nameAttributes.originalName === 'Technicians') {
            this.dataService.setValue1(Number(this.template.value));
        }
        else if (this.template.nameAttributes && this.template.nameAttributes.originalName === 'Hours/Technician') {
            this.dataService.setValue2(Number(this.template.value));
        }

        if (this.template.nameAttributes && this.template.nameAttributes.originalName === 'Total Hours') {
            this.dataService.value1$.subscribe(_value1 => this.calculateSum());
            this.dataService.value2$.subscribe(_value2 => this.calculateSum());
        }
    }

    calculateSum() {
        if (this.template.nameAttributes && this.template.nameAttributes.originalName === 'Total Hours' && this.count > 1) {
            this.template.value = String(this.dataService.value1Subject.value * this.dataService.value2Subject.value);
            this.valueChange.emit(this.template);
        }
        else {
            this.count++;
        }
    }

    public getEntryTemplates(template: TemplateFieldMeta): TemplateFieldMeta[] {
        return template.valueAttributes.collectionEntryTemplate?.fields ?? [];
    }

    public isDefaultEntryType(template: TemplateFieldMeta): boolean {
        const { type } = template.valueAttributes;

        return !['list', 'numeric', 'text_multiline_fsw'].includes(type);
    }

    public onCheckboxChange(change: MatCheckboxChange): void {
        this.template.value = change.checked.toString();
        this.valueChange.emit(this.template);
    }

    public isRadioButtonSelected(template: TemplateFieldMeta): boolean {
        if (template.value === 'true') {
            return true;
        }

        const { type, additionalValue } = template.valueAttributes;

        return type === 'radio_button_fsw' ? false : Boolean(additionalValue);
    }

    public onRadioButtonSelect(change: MatRadioChange): void {
        if (!(change instanceof MatRadioChange)) {
            return;
        }

        for (const field of this.template.fields) {
            field.value = String(change.value === field.name);

            if (field.valueAttributes.type !== 'radio_button_fsw' && field.value !== 'true') {
                field.valueAttributes.additionalValue = '';
            }
        }
    }

    public onEntryAdd(parent: TemplateFieldMeta): void {
        const template = parent.valueAttributes.collectionEntryTemplate;

        if (template) {
            parent.fields.push(JSON.parse(JSON.stringify(template)));
        }
    }

    public onEntryDelete(parent: TemplateFieldMeta, deleted: TemplateFieldMeta): void {
        parent.fields = parent.fields.filter(_ => _ !== deleted);
    }

    public onDatePickerValueChange(event: MatDatepickerInputEvent<Moment>, field: TemplateFieldMeta) : void {
        if (!event?.value) {
            return;
        }

        field.value = event.value.format('YYYY-MM-DD');
        this.valueChange.emit(field);
    }

    valueChangeEvent() {
        if (this.template.nameAttributes.originalName === 'Technicians') {
            this.dataService.setValue1(Number(this.template.value));
        }
        else if (this.template.nameAttributes.originalName === 'Hours/Technician') {
            this.dataService.setValue2(Number(this.template.value));
        }
        this.valueChange.emit(this.template);
    }
}
