<div class="grid-datepicker-container" fxLayout="column">
    <div class="grid-datepicker-title-bar" fxLayout="row" fxLayoutAlign="start center">
        <span class="grid-datepicker-title">{{ title }}</span>
    </div>

    <div class="grid-datepicker-content" fxLayout="row" fxLayoutAlign="start center">
        <div class="grid-display-box">{{ displayDate | textWithPlaceholder }}</div>
        <input type="text" readonly [matDatepicker]="datePicker" (dateChange)="onDateUpdate($event.value)" />
        <mat-datepicker #datePicker></mat-datepicker>

        <grid-item-actions [content]="displayDate | textWithPlaceholder"
            [canEnableEdit]="true"
            [canClearContent]="displayDate"
            (editEnabled)="datePicker.open()"
            (contentClear)="onDateUpdate(null)">
        </grid-item-actions>
    </div>
</div>
