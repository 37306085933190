<div class="kpi-data-editor-container" fxLayout="row" fxLayoutGap="15px">
    <div class="kpi-edit-project-list" fxFlex="50">
        <kendo-grid [kendoGridBinding]="gridData" [pageSize]="10" [pageable]="true" [style.height.%]="100"
            [selectable]="true" sortable="true" filterable="menu" (selectionChange)="onGridChange($event)">
            <kendo-grid-column field="projectID" title="{{'admin.kpiSetting.kpiUpdate.projectId' | translate}}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="branch" title="{{'admin.kpiSetting.kpiUpdate.branch' | translate}}">
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filter]="filter" [filterService]="filterService" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>

    <div *ngIf="!loading" fxLayout="row" fxLayoutGap="25px" fxFlex="50">
        <div fxLayout="column" *ngIf="editProjectKpi">
            <div fxLayout="row">
                <div>
                    {{"admin.kpiSetting.kpiUpdate.kpiUpdateTitle" | translate}} {{ editProjectKpi }}
                </div>
            </div>

            <div fxLayout="column">
                <mat-checkbox [(ngModel)]="project.contactInfo">{{"admin.kpiSetting.kpiUpdate.contactInfo" | translate}}</mat-checkbox>
                <mat-checkbox [(ngModel)]="project.findings">{{"admin.kpiSetting.kpiUpdate.findings" | translate}}</mat-checkbox>
                <mat-checkbox [(ngModel)]="project.recommendations">{{"admin.kpiSetting.kpiUpdate.recommendations" | translate}}</mat-checkbox>
                <mat-checkbox [(ngModel)]="project.photos">{{"admin.kpiSetting.kpiUpdate.photos" | translate}}</mat-checkbox>

                <mat-checkbox
                    [(ngModel)]="project.safety"
                    [disabled]="disableProjectSafety"
                    (change)="changeSafetyForForeman()">
                    {{"admin.kpiSetting.kpiUpdate.safety" | translate}}
                </mat-checkbox>
            </div>

            <mat-form-field>
                <textarea matInput [(ngModel)]="project.reason" rows="5" placeholder="{{'admin.kpiSetting.kpiUpdate.updateReason' | translate}}">
                </textarea>
            </mat-form-field>

            <div>
                <button mat-flat-button (click)="updateKpiData()" [disabled]="saving" color="primary">
                    {{"admin.kpiSetting.kpiUpdate.updateButton" | translate}}
                </button>
            </div>
        </div>

        <div class="foreman-kpi-edit" *ngIf="project?.foremanData" fxLayout="column">
            <div *ngFor="let foreman of project.foremanData">
                {{"admin.kpiSetting.kpiUpdate.foremanRatingUpdateTitle" | translate}} {{ foreman.foreman }}
                <div class="foreman-kpi-edit-options" fxLayout="column">
                    <mat-checkbox [(ngModel)]="foreman.safety"
                        [disabled]="disableForemanSafety"
                        (change)="changeSafetyForProject()">
                        {{"admin.kpiSetting.kpiUpdate.safety" | translate}}
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="loading">
        <dots-loader></dots-loader>
    </div>
</div>
