<div class="user-comment-card-container">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="7px">
        <div [ngStyle]="{ 'background-color': color }" fxLayoutAlign="center center">
            <span>{{ initials }}</span>
        </div>

        <span>{{ comment.creator.preferredName }}</span>
        <span [matTooltip]="getCreationTime()" matTooltipPosition="above">{{ getCreationTime(false) }}</span>
        <span *ngIf="comment.histories.length" [matTooltip]="lastHistory" matTooltipPosition="above">{{'shared.userComments.card.edited' | translate }}</span>
        <div fxFlex></div>

        <div *ngIf="showActions"
            class="user-comment-card-actions"
            fxLayout="row"
            fxLayoutAlign="end center"
            fxLayoutGap="6px">

            <button *ngIf="canEdit" mat-stroked-button color="primary" (click)="onEditStart()">{{ 'shared.userComments.card.edit' | translate }}</button>
            <button *ngIf="canDelete" mat-stroked-button color="warn" (click)="onDelete()">{{ 'shared.userComments.card.delete' | translate }}</button>
        </div>
    </div>

    <div class="user-comment-card-content" *ngIf="!isEditMode" [innerHTML]="comment.content"></div>

    <user-comment-editor *ngIf="isEditMode"
        [comment]="comment"
        (editSaved)="onEditSave($event)"
        (editCancelled)="onEditCancel()">
    </user-comment-editor>
</div>
