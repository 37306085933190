<div fxFill fxLayout="column">
    <mat-tab-group fxFill>
        <mat-tab [label]="translate.instant('dataReport.materialsReport.title')">
          <job-material-settings></job-material-settings>
        </mat-tab>
        <mat-tab [label]="translate.instant('dataReport.chemicalsReport.title')">
          <chemicals-settings></chemicals-settings>
        </mat-tab>
      </mat-tab-group>
</div>

