import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-dialog-panel',
    styleUrls: ['./dialog-panel.component.scss'],
    templateUrl: './dialog-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogPanelComponent {
    @Input() public icon = '';
    @Input() public title = '';
    @Input() public isLoaded = false;
    @Input() public isModalDialog = true;
    @Output() public close = new EventEmitter();
}
