import MaintenanceSummaryDto from './maintenance-summary-dto';

export default class ConditionReportOptions {
    public projectId: string;
    public jobId: number;
    public clientId: string;
    public preparedFor: string;
    public logoFile: string;
    public sections: MaintenanceSummaryDto[];
    public removeCompanyLogo: boolean;
    public clientLogo: string;
}
