import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';

import BudgetTemplate from '../../../core/data-models/budget-template';
import BudgetTemplatePayload from '../../../core/data-models/budget-template-payload';
import { BudgetHttpService } from '../../../core/services/http/budget-http/budget-http.service';

import { BudgetTemplateUploadDialogComponent } from './budget-template-upload-dialog/budget-template-upload-dialog.component';
import { BudgetTemplateVersionsListComponent } from './budget-template-versions-list/budget-template-versions-list.component';

@Component({
    selector: 'budget-template-management',
    styleUrls: ['./budget-template-management.component.scss'],
    templateUrl: './budget-template-management.component.html'
})
export class BudgetTemplateManagementComponent implements OnInit {
    public isLoading = false;
    private readonly _templates = new Map<string, BudgetTemplate[]>();

    constructor(public translate: TranslateService,
                private _budgetHttp: BudgetHttpService,
                private _dialog: MatDialog,
                private _snackbar: MatSnackBar) { }

    get templateNames(): string[] {
        return Array.from(this._templates.keys()).sort();
    }

    public async ngOnInit(): Promise<void> {
        await this.loadTemplates();
    }

    private async loadTemplates(): Promise<void> {
        this.isLoading = true;
        this._templates.clear();

        for (const template of await this._budgetHttp.getAllTemplates()) {
            const name = template.name.toLowerCase();

            if (!this._templates.has(name)) {
                this._templates.set(name, []);
            }

            this._templates.get(name).push(template);
        }

        for (const name of this.templateNames) {
            this._templates.get(name).sort((a, b) => b.version - a.version);
        }

        this.isLoading = false;
    }

    public getTemplateName(name: string): string {
        return this._templates.get(name)[0].name;
    }

    public getLatestVersionText(name: string): string {
        const templates = this._templates.get(name);
        const version = templates[0].version.toFixed(1);

        if (templates.length === 1) {
            return version;
        }

        const prefix = 'admin.budgetTemplateManagement.latestVersionText';
        const key = `${prefix}.${templates.length > 2 ? 'plural' : 'singular'}`;

        return `${version} (${templates.length - 1} ${this.translate.instant(key)})`;
    }

    public getActiveVersionsText(name: string): string {
        const templates = this._templates.get(name).filter(_ => _.isActive);
        const versions = templates.map(_ => _.version.toFixed(1));
        const visible = versions.slice(0, 2).join(', ');
        const hidden = versions.slice(2);

        if (!hidden.length) {
            return visible;
        }

        const key = 'admin.budgetTemplateManagement.activeVersionsText';

        return this.translate.instant(key, { visible, hidden: hidden.length });
    }

    public getCustomerAssignmentStatusText(name: string): string {
        if (!this._templates.get(name)[0].assignedCustomers.length) {
            return '';
        }

        return this.translate.instant('shared.yes');
    }

    public async onTemplateAdd(): Promise<void> {
        const dialog = this._dialog.open(BudgetTemplateUploadDialogComponent, {
            width: '600px',
            height: '500px'
        });

        const payload = await dialog.afterClosed().toPromise<BudgetTemplatePayload>();

        if (!payload) {
            return;
        }

        const isUploaded = await this._budgetHttp.uploadBudgetTemplate(payload);
        const key = isUploaded ? 'successfullyUploadedTemplate' : 'failedToUploadTemplate';
        const message = this.translate.instant(`admin.budgetTemplateManagement.${key}`);
        this._snackbar.open(message, this.translate.instant('shared.ok'));

        if (isUploaded) {
            await this.loadTemplates();
        }
    }

    public async openVersionsList(name: string): Promise<void> {
        const dialog = this._dialog.open(BudgetTemplateVersionsListComponent, {
            data: this._templates.get(name),
            width: '50vw',
            height: '75vh'
        });

        await dialog.afterClosed().toPromise();
        this.loadTemplates();
    }
}
