import UserInfo from '../user-info';

export default class ProjectAccessEventData {
    public projectId: string;
    public user: UserInfo;

    constructor(projectId: string, user: UserInfo) {
        this.projectId = projectId;
        this.user = user;
    }
}
