import { Injectable } from '@angular/core';
import { Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import ValidatorSet from '../../data-models/validator-set';

const nonnegativeValidator = Validators.pattern(/^0(\.\d+)?$|^[1-9]\d*(\.\d+|\d*)$/);
const decimalValidator = Validators.pattern(/\.\d{1,2}$|^\d+$/);

@Injectable({
    providedIn: 'root'
})
export class ValidatorFactory {
    constructor(private _translate: TranslateService) {}

    public createEmailValidator(errorText = ''): ValidatorSet {
        const expectedEmailFormat = this._translate.instant('core.validatorFactory.expectedEmailFormat');
        return {
            validator: this.isValidEmail,
            message: errorText ? errorText : expectedEmailFormat
        };
    }

    public createPhoneValidator(errorText = ''): ValidatorSet {
        const expectedPhoneFormat = this._translate.instant('core.validatorFactory.expectedPhoneFormat');
        return {
            validator: Validators.pattern(/^(\d{10,14}|\(\d{3}\)\s?\d{3}-\d{4}(, \d{1,4})?)$/), // /^(\d{10,14}|\(\d{3}\)\s?\d{3}-\d{4,8})$/
            message: errorText ? errorText : expectedPhoneFormat
        };
    }

    public createPositiveValidator(errorText = ''): ValidatorSet {
        const expectsPositiveNumber = this._translate.instant('core.validatorFactory.expectsPositiveNumber');
        return {
            validator: (_: AbstractControl) => Number(_.value) > 0 ? null : { positive: true },
            message: errorText ? errorText : expectsPositiveNumber
        };
    }

    public createCurrencyValidator(errorText = ''): ValidatorSet {
        const expectCurrencyFormat = this._translate.instant('core.validatorFactory.expectCurrencyFormat');
        return {
            validator: Validators.compose([nonnegativeValidator, decimalValidator]),
            message: errorText ? errorText : expectCurrencyFormat
        };
    }

    public createPositiveIntegerValidator(errorText = ''): ValidatorSet {
        const expectInteger = this._translate.instant('core.validatorFactory.expectInteger');
        return {
            validator: Validators.pattern(/^[1-9]\d*$/),
            message: errorText ? errorText : expectInteger
        };
    }

    public createNonNegativeValidator(errorText = ''): ValidatorSet {
        const expectedNonNegativeNumber = this._translate.instant('core.validatorFactory.expectedNonNegativeNumber');
        return {
            validator: nonnegativeValidator,
            message: errorText ? errorText : expectedNonNegativeNumber
        };
    }

    public createNonNegativeIntegerValidator(errorText = ''): ValidatorSet {
        return {
            validator: Validators.pattern(/^\d+$/),
            message: errorText ? errorText : this._translate.instant('core.validatorFactory.expectNonNegativeInteger')
        };
    }

    public createDaysValidator(errorText = ''): ValidatorSet {
        const expectPositiveInteger = this._translate.instant('core.validatorFactory.expectPositiveInteger');
        return {
            validator: Validators.pattern(/^[1-9]\d*$/),
            message: errorText ? errorText : expectPositiveInteger
        };
    }

    public createWebsiteValidator(errorText = ''): ValidatorSet {
        const expectedWebsiteFormat = this._translate.instant('core.validatorFactory.expectedWebsiteFormat');
        const validator = Validators.pattern(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i);
        return {
            validator,
            message: errorText ? errorText : expectedWebsiteFormat
        };
    }

    private isValidEmail(control: AbstractControl): ValidationErrors | null {
        const email: string = (control.value ?? '').toLowerCase();
        // when email field is not required we don't want to show validation errors
        if (!email) {
            return null;
        }
        // email must have local and domain part
        if (email.split('@').length !== 2) {
            return { format: true };
        }

        const [local, domain] = email.split('@');

        if (!/^[a-z0-9_-]+(\.[a-z0-9_-]+)*$/.test(local)) {
            return { format: true };
        }
        // every subdomain must start and end with alphanumeric characters and can have hyphens in between
        if (domain.split('.').some(_ => !/^[a-z0-9]([a-z0-9-]*[a-z0-9])?$/.test(_))) {
            return { format: true };
        }
        // top level domain must start and end with alphanumeric characters and at least 2 characters in length
        return /\.[a-z0-9][a-z0-9-]*[a-z0-9]$/.test(email) ? null : { format: true };
    }

    public createNameValidator(errorText = ''): ValidatorSet {
        const expectNoDoubleQuotes = this._translate.instant('core.validatorFactory.expectNoDoubleQuotes');
        return {
            validator: Validators.pattern(/^[^"]*$/),
            message: errorText ? errorText : expectNoDoubleQuotes
        };
    }
}