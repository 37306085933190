export default class ExecutiveSummary {
    public projectId = '';
    public siteName = '';
    public siteAddress = '';
    public totalRoofArea = 0;
    public estimatedRoofAge = '';
    public buildingType = '';
    public roofComposition = '';
    public roofCondition = '';
    public recommendation = '';
    public roofProjectBudget = '';
    public otherNotes = '';
}
