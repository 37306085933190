import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'expandable-button',
    styleUrls: ['./expandable-button.component.scss'],
    templateUrl: './expandable-button.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableButtonComponent {
    @Input() public icon = '';
    @Input() public text = '';
    @Input() public direction = 'left';
    @Input() public isWarning = false;

    get expandToLeft(): boolean {
        return this.direction === 'left';
    }

    get backgroundColor(): string {
        return this.isWarning?
            'var(--context-colors-warning)':
            'var(--secondary-colors-dark)';
    }
}
