<div class="project-us-questions-card-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="40px"
    fxFill>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-icon>help</mat-icon>
        <span>{{ 'shared.usTaxQuestions.questions' | translate }}</span>
    </div>

    <div *ngIf="readOnly" fxLayout="column" fxLayoutGap="10px">
        <grid-display-item [title]="translate.instant('shared.usTaxQuestions.buildingPermit')" [content]="usTaxQuestions.buildingPermit">
        </grid-display-item>
        <grid-display-item [title]="translate.instant('shared.usTaxQuestions.contractType')" [content]="usTaxQuestions.contractType">
        </grid-display-item>
        <grid-display-item [title]="translate.instant('shared.usTaxQuestions.gcOwner')" [content]="usTaxQuestions.repairMaintenance">
        </grid-display-item>
        <grid-display-item [title]="translate.instant('shared.usTaxQuestions.scopesOfWork')" [content]="usTaxQuestions.scopeOfWork">
        </grid-display-item>
    </div>
    <div *ngIf="!readOnly" [formGroup]="formGroup" fxLayout="column">
        <mat-error *ngIf="formGroup.invalid">
            {{ 'shared.usTaxQuestions.requiredField' | translate }}
        </mat-error>
        <mat-form-field>
            <mat-label>{{ 'shared.usTaxQuestions.buildingPermit' | translate }}</mat-label>
            <mat-select required (selectionChange)="checkAndSendResult()" formControlName="buildingPermit">
                <mat-option *ngFor="let item of dropdowns.buildingPermit" [value]="item">
                    {{ item }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'shared.usTaxQuestions.contractType' | translate }}</mat-label>
            <mat-select required (selectionChange)="checkAndSendResult()" formControlName="contractType">
                <mat-option *ngFor="let item of dropdowns.contractType" [value]="item">
                    {{ item }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'shared.usTaxQuestions.gcOwner' | translate }}</mat-label>
            <mat-select required (selectionChange)="checkAndSendResult()" formControlName="repairMaintenance">
                <mat-option *ngFor="let item of dropdowns.gcOrOwner" [value]="item">
                    {{ item }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'shared.usTaxQuestions.scopesOfWork' | translate }}</mat-label>
            <mat-select required (selectionChange)="checkAndSendResult()" formControlName="scopeOfWork">
                <mat-option *ngFor="let item of dropdowns.scopesOfWork" [value]="item">
                    {{ item }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
