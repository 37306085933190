<dialog-action-card class="project-timeline-dialog-container"
    [icon]="'timeline'"
    [title]="translate.instant('projects.timeline.title')"
    [isLoaded]="events"
    (close)="onClose()">

    <div class="project-timeline-content" *ngIf="events" fxLayout="row">
        <div class="project-timeline-timeline" fxFlex>
            <timeline-card [events]="events"></timeline-card>
        </div>
    </div>
</dialog-action-card>
