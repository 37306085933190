<form [formGroup]="siteInformation">

    <div class="update-site-information-form-row" fxLayout="column" fxLayoutAlign="center start">

        <div class="update-site-information-form-row" fxLayout fxLayoutAlign="start center" fxLayoutGap="15px">
            <mat-form-field *ngIf="isBillingSite" fxFlex="40">
                <mat-label>{{ 'projects.update.billingName' | translate }}</mat-label>
                <input matInput formControlName="name" />
                <mat-error *ngIf="siteInformation.get('name').invalid">* {{ 'projects.update.billingNameMaxLengthExceeded' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="15">
                <mat-label>{{ 'projects.update.shipToId' | translate }}</mat-label>
                <input [readonly]="true" matInput formControlName="shipToId">
            </mat-form-field>

        </div>

        <h5 style="margin-bottom: 10px;">{{ 'projects.update.addressInformation' | translate }}</h5>

        <div class="update-site-information-form-row" fxLayout fxLayoutAlign="start center" fxLayoutGap="15px">
            <mat-form-field fxFlex="30">
                <mat-label>{{ 'projects.update.address1' | translate }}</mat-label>
                <input [readonly]="true" matInput formControlName="address1">
            </mat-form-field>

            <mat-form-field fxFlex="20">
                <mat-label>{{ 'projects.update.address2' | translate }}</mat-label>
                <input matInput formControlName="address2">
            </mat-form-field>

            <mat-form-field fxFlex="20">
                <mat-label>{{ 'projects.update.city' | translate }}</mat-label>
                <input [readonly]="true" matInput formControlName="city">
            </mat-form-field>

            <mat-form-field fxFlex="10">
                <mat-label>{{ 'projects.update.provinceState' | translate }}</mat-label>
                <input [readonly]="true" matInput formControlName="state">
            </mat-form-field>

            <mat-form-field fxFlex="10">
                <mat-label>{{ 'projects.update.postalZipCode' | translate }}</mat-label>
                <input [readonly]="true" matInput formControlName="zip">
            </mat-form-field>

            <mat-form-field fxFlex="10">
                <mat-label>{{ 'projects.update.country' | translate }}</mat-label>
                <input [readonly]="true" matInput formControlName="country">
            </mat-form-field>

        </div>

        <h5 style="margin-bottom: 10px;">{{ 'projects.update.contactInformation' | translate }}</h5>

        <div class="update-site-information-form-row" fxLayout fxLayoutAlign="start center" fxLayoutGap="15px">
            <mat-form-field fxFlex="20">
                <mat-label>{{ 'projects.update.contactName' | translate }}</mat-label>
                <input matInput formControlName="siteAttention">
                <mat-error *ngIf="siteInformation.get('siteAttention').invalid">
                    {{ 'projects.update.pleaseSupplyContactName' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="20">
                <mat-label>{{ 'projects.update.phone' | translate }}</mat-label>
                <input matInput formControlName="sitePhone" appPhoneMask>
                <mat-error *ngIf="siteInformation.get('sitePhone').invalid">
                    {{ 'projects.update.pleaseEnterValidNumber' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="20">
                <mat-label>{{ 'projects.update.email' | translate }}</mat-label>
                <input matInput formControlName="siteEmail">
                <mat-error *ngIf="siteInformation.get('siteEmail').invalid">
                    {{ 'projects.update.pleaseEnterValidEmail' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="isBillingSite" fxFlex="20">
                <mat-label>{{ 'projects.update.fax' | translate }}</mat-label>
                <input matInput formControlName="siteFax" [isFax]="true" appPhoneMask>
                <mat-error *ngIf="siteInformation.get('siteFax').invalid">
                    {{ 'projects.update.pleaseEnterValidNumber' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

    </div>
</form>
