<dialog-action-card class="add-stock-log-dialog-container"
    [icon]="'construction'"
    [title]="translate.instant('projects.details.stockLog.materials')"
    [isLoaded]="!isLoading"
    (close)="onClose()">

    <div *ngIf="!isLoading" class="add-stock-log-dialog-content" fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{ 'projects.details.stockLog.name' | translate }}</span>
                <span>{{ 'projects.details.stockLog.unitPrice' | translate }}</span>
                <span>{{ 'projects.details.stockLog.count' | translate }}</span>
            </div>

            <div *ngFor="let material of costs.materials" fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{ material.material.name }}</span>
                <span>{{ material.material.value }}</span>
                <span>{{ material.count }} {{ material.material.unit }}</span>
            </div>
        </div>

        <span class="add-stock-log-dialog-summary-title">{{ 'projects.details.stockLog.summary' | translate }}</span>

        <mat-form-field>
            <mat-label>{{ 'projects.details.stockLog.projectId' | translate }}</mat-label>
            <input matInput [readonly]="true" [value]="option.projectId">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'projects.details.stockLog.projectName' | translate }}</mat-label>
            <input type="text" matInput [(ngModel)]="option.projectName">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'projects.details.stockLog.totalCost' | translate }}</mat-label>
            <input type="number" matInput cdkFocusInitial [(ngModel)]="costs.totalCost">
        </mat-form-field>
    </div>

    <div class="add-stock-log-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="addStockLog()">{{ 'projects.details.stockLog.add' | translate }}</button>
    </div>
</dialog-action-card>
