import BranchTimezone from './branch-timezone';

export default class GeoLocation {
    public accuracy: number;
    public fenceArea: number;
    public range: number;
    public latitude: number;
    public longitude: number;
    public location: [number, number];
    public timeZone: BranchTimezone;
}
